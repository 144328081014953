import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { KeyboardService } from 'app/ts/services/KeyboardService';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-item-editor',
  templateUrl: './consumerItemEditor.html',
  styleUrls: ['./consumerItemEditor.scss'],
})
export class ConsumerItemEditorVm extends BaseVm implements OnDestroy {
  constructor(baseVmService: BaseVmService, keyboardService: KeyboardService) {
    super(baseVmService);
    this.subscribeTo(keyboardService.keyboardEvents, (keyboardEvent) =>
      this.handleKeyboardEvent2(keyboardEvent),
    );
  }

  public item: Client.ConfigurationItem | null = null;

  // TODO: These two fields weren't here before upgrading, so
  // they should probably be initialized somehow
  public materials!: Pickable<Interface_DTO.Material>[];
  public selectedMaterial!: Interface_DTO.Material;

  private _items!: Client.ConfigurationItem[];

  @Input()
  public get items(): Client.ConfigurationItem[] {
    return this._items;
  }

  public set items(items: Client.ConfigurationItem[] | null) {
    if (items == null) {
      return;
    }
    this._items = items;
    if (items.length > 0) {
      this.item = items[0];
    }
  }

  @Output()
  public itemChanged = new EventEmitter<void>();

  @Output()
  public itemDeleted = new EventEmitter<Client.ConfigurationItem>();

  public override ngOnDestroy() {
    super.dispose();
  }

  private handleKeyboardEvent2(evt: KeyboardEvent | null) {
    if (!evt) return;

    if (evt.defaultPrevented) return;

    if (
      evt.code === 'Delete' ||
      evt.key === 'Del' ||
      evt.key === 'Delete' ||
      evt.keyCode === 46
    ) {
      this.delete();
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  // #region adjustmentScrew
  private get _adjustmentScrewVariant(): Client.ItemVariant | undefined {
    if (!this.item) {
      return undefined;
    }
    let result = this.item.UserSelectableItemVariants.filter(
      (i) => i.variant && i.variant.Number === VariantNumbers.AdjustmentScrew,
    )[0];
    return result;
  }

  private get _adjustmentScrewVariantOption():
    | Interface_DTO.VariantOption
    | undefined {
    let variant = this._adjustmentScrewVariant;
    if (!variant) {
      return undefined;
    }
    let vo = variant.variantOption;
    return vo;
  }

  public get showAdjustmentScrewVariant(): boolean {
    return !!this._adjustmentScrewVariant;
  }

  public get adjustmentScrewVariant(): boolean {
    let vo = this._adjustmentScrewVariantOption;
    if (!vo) return false;
    return vo.Number === VariantNumbers.Values.Yes;
  }
  public set adjustmentScrewVariant(val: boolean) {
    if (!this.item) return;
    let variant = this._adjustmentScrewVariant;
    if (!variant) return;

    let number = val ? VariantNumbers.Values.Yes : VariantNumbers.Values.No;
    let variantOption = variant.variant!.VariantOptions.filter(
      (vo) => vo.Number === number,
    )[0];
    if (!variantOption) return;
    this.item.setItemVariant(
      {
        VariantId: variant.VariantId,
        ActualValue: '',
        VariantOptionId: variantOption!.Id,
      },
      false,
    );
    this.setChanged();
  }

  // #endregion adjustmentScrew

  public setChanged() {
    if (this.itemChanged) this.itemChanged.emit();
  }

  public get productName(): string | null {
    if (!this.item) return null;

    let product = this.item.Product;
    if (!product) return null;

    return product.Name;
  }

  public get showMaterialPicker() {
    return false; //UNSILVANIZE
  }

  public delete() {
    if (!this.item) return;
    if (this.itemDeleted) this.itemDeleted.emit(this.item);
    this.setChanged();
  }
}
