import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { VectorHelper } from '../../util/VectorHelper';
import { ConfigurationItemHelper } from '../../util/ConfigurationItemHelper';
import { Constants } from 'app/ts/Constants';
import { ProductHelper } from '../../util/ProductHelper';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';

export class SwingFlexValidationService {
  public static readonly Name = 'swingFlexValidationService';

  constructor() {}

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    if (section.CabinetType !== Interface_Enums.CabinetType.SwingFlex)
      return errorInfos;

    errorInfos.push(...SwingFlexValidationService.validateCorpus(section));
    errorInfos.push(...SwingFlexValidationService.validateDoors(section));

    errorInfos.push(
      ...SwingFlexValidationService.validateCloseDrawers(section),
    );
    errorInfos.push(...SwingFlexValidationService.validateProducts(section));
    errorInfos.push(...SwingFlexValidationService.validateMaterials(section));

    return errorInfos;
  }

  //#region Corpus

  private static validateCorpus(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    errorInfos.push(...SwingFlexValidationService.validateAreas(section));
    errorInfos.push(
      ...SwingFlexValidationService.validateItemsInsideOuterCorpus(section),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateMovableItemsNotExtendingThroughTheBack(
        section,
      ),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateMovableItemsSnapped(section),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateMovableItemsOverlap(section),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateMiddlePanelSupportedByShelf(
        section,
      ),
    );

    return errorInfos;
  }

  private static validateAreas(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    const gableWidth = section.swingFlex.getGableWidth();
    const corpusLeftX = section.corpus.widthLeft;
    const corpusRightX = corpusLeftX + section.corpus.sightWidth;
    const minX = corpusLeftX + gableWidth;
    const maxX = corpusRightX - gableWidth;

    for (let area of section.swingFlex.areas) {
      if (area.insideRect.X < minX || area.insideRect.RightX > maxX) {
        errorInfos.push(
          new Client.ErrorInfo(
            Enums.EditorSection.SwingFlex,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_swingFlexCorpus_totalSectionWidth_short',
              'Sections do not fit inside the cabinet.',
            ),
            new Client.Translatable(
              'validate_swingFlexCorpus_totalSectionWidth_long',
              'The specified sections do not fit inside the specified cabinet dimensions. Please adjust cabinet width or sections.',
            ),
            section,
          ),
        );
      }
    }

    return errorInfos;
  }

  private static validateItemsInsideOuterCorpus(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    const sightRect = section.corpus.sightRect;

    for (let item of section.swingFlex.items) {
      // Ignore grips here.
      // They are only be rotated visually, and therefore they may collide, even if they are actually okay.
      // Doors will fail before grips, so the error is not strictly necessary.
      if (item.isGrip) continue;

      const itemRect = new Client.Rectangle(
        item as Interface_DTO_Draw.Rectangle,
      );
      if (!sightRect.isRectangleFullyInside(itemRect)) {
        errorInfos.push(
          new Client.ErrorInfo(
            Enums.EditorSection.SwingFlex,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_swingFlex_itemNotInside_short',
              'Item is not fully inside the cabinet.',
            ),
            new Client.Translatable(
              'validate_swingFlex_itemNotInside_long',
              '{0} is not fully inside the cabinet.',
              item.Product ? item.Product.Name : item.Description,
            ),
            section,
            item,
          ),
        );
      }
    }

    return errorInfos;
  }

  private static validateMovableItemsNotExtendingThroughTheBack(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    const minimumZ =
      section.swingFlex.productLineProperties.SwingFlexSpaceForBacking;

    for (let item of section.swingFlex.areas.flatMap(
      (area) => area.areaSplitItems,
    )) {
      if (item.Z < minimumZ) {
        errorInfos.push(
          new Client.ErrorInfo(
            Enums.EditorSection.SwingFlex,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_swingFlexCorpus_item_too_deep_short',
              'Item is too deep',
            ),
            new Client.Translatable(
              'validate_swingFlexCorpus_item_too_deep_long',
              '{0} is too deep to fit inside cabinet',
              item.Product ? item.Product.Name : item.Description,
            ),
            section,
            item,
          ),
        );
      }
    }

    return errorInfos;
  }

  private static validateMovableItemsSnapped(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    const movableItems = section.swingFlex.areas.flatMap(
      (area) => area.areaSplitItems,
    );
    const gables = section.swingFlex.areas.flatMap((a) =>
      a.items.filter((item) => item.isGable),
    );
    const drillingPattern = section.swingFlex.getDrillingPattern();

    for (let item of movableItems) {
      if (
        !!item.Product &&
        !ProductHelper.supportsDrillingPattern(item.Product, drillingPattern)
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.SwingFlex,
          Interface_Enums.ErrorLevel.Critical,
          new Client.Translatable(
            'validate_swingFlexCorpus_drillingPattern_short',
            'Drilling patterns do not match!',
          ),
          new Client.Translatable(
            'validate_swingFlexCorpus_drillingPattern_long',
            'Drilling patterns do not match. Please remove the item.',
          ),
          section,
          item,
        );
        errorInfos.push(error);
      }

      let snappedLeft = false;
      let snappedRight = false;

      let itemLeftX = item.leftX;
      let itemRightX = itemLeftX + (item.hasWidth2 ? item.width2 : item.Width);

      for (let gable of gables) {
        if (gable.topY < item.topY) {
          continue;
        }

        if (
          Math.abs(itemLeftX - gable.rightX) <
          Constants.sizeAndPositionTolerance * 2
        )
          snappedLeft = true;
        else if (
          Math.abs(gable.leftX - itemRightX) <
          Constants.sizeAndPositionTolerance * 2
        )
          snappedRight = true;

        if (snappedLeft && snappedRight) break;
      }

      // If the item is not snapped correctly, create the error
      if (!snappedLeft || !snappedRight) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.SwingFlex,
          Interface_Enums.ErrorLevel.Critical,
          'Item not snapped correctly left/right',
          'Item not snapped correctly left and/or right',
          section,
          item,
        );
        errorInfos.push(error);
      }
    }

    return errorInfos;
  }

  private static validateMovableItemsOverlap(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    const itemsCubes = section.swingFlex.items
      .filter((item) => !item.isGable)
      .filter(
        (item) => item.ItemType !== Interface_Enums.ItemType.SwingFlexBacking,
      )
      .map(ConfigurationItemHelper.getItemCube);

    for (let currentItemCube of itemsCubes) {
      // Only validate movable items (they will be validated againt non-movable items)
      if (
        currentItemCube.item.ItemType !==
        Interface_Enums.ItemType.SwingFlexCorpusMovable
      )
        continue;

      for (let otherItemCube of itemsCubes) {
        if (currentItemCube === otherItemCube) continue;

        // External drawers may overlap shelves a bit, depending on the shelf type.
        const thresholdY = section.swingFlex.getAllowedOverlapY(
          currentItemCube.item,
          otherItemCube.item,
        );

        const overlapsMoreThanThreshold =
          VectorHelper.overlapsMoreThanThreshold(
            currentItemCube,
            otherItemCube,
            0,
            thresholdY,
            0,
          );

        if (overlapsMoreThanThreshold) {
          const item = currentItemCube.item;
          const otherItem = otherItemCube.item;

          errorInfos.push(
            new Client.ErrorInfo(
              Enums.EditorSection.SwingFlex,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable(
                'validate_swingFlexCorpus_itemsOverlapping_short',
                'Items overlapping',
              ),
              new Client.Translatable(
                'validate_swingFlexCorpus_itemsOverlapping_long',
                '{0} is overlapping {1}',
                item.Product ? item.Product.Name : item.Description,
                otherItem.Product
                  ? otherItem.Product.Name
                  : otherItem.Description,
              ),
              section,
              item,
            ),
          );
        }
      }
    }

    return errorInfos;
  }

  private static validateMiddlePanelSupportedByShelf(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const errorInfos: Client.ErrorInfo[] = [];

    section.swingFlex.areas.forEach((area) => {
      area.subAreas.forEach((subArea) => {
        if (subArea.hasMiddlePanel && !!subArea.middlePanelHeight) {
          const minX = subArea.insideRect.X;
          const maxX = minX + subArea.insideRect.Width;
          const middlePanelTop =
            subArea.middlePanelHeight + section.swingFlex.getGablePositionY();
          const shelfs = section.interior.items
            .concat(section.swingFlex.areas.flatMap((a) => a.areaSplitItems))
            .filter((i) => i.isShelf && i.centerX > minX && i.centerX < maxX);
          const supportingShelf = shelfs.find(
            (s) => s.topY < middlePanelTop && middlePanelTop - s.topY < 2,
          );
          if (!supportingShelf) {
            let error = new Client.ErrorInfo(
              Enums.EditorSection.SwingFlex,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable(
                'validate_swingFlexCorpus_middlePanelUnsupported_short',
                'Middle panel unsupported',
              ),
              new Client.Translatable(
                'validate_swingFlexCorpus_middlePanelUnsupported_long',
                'Middle panel needs support. Please add a shelf just below the top.',
              ),
              section,
              undefined,
            );
            errorInfos.push(error);
          }
        }
      });
    });

    return errorInfos;
  }

  //#endregion

  //#region Doors

  private static validateDoors(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    errorInfos.push(
      ...SwingFlexValidationService.validatePushToOpenSpot(section),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateShelfBehindDoorTopAndBottomGap(
        section,
      ),
    );
    errorInfos.push(
      ...SwingFlexValidationService.validateDoorsNotBlockedByWall(section),
    );

    return errorInfos;
  }

  /**
   * Validates available push to open spots
   */
  private static validatePushToOpenSpot(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    const pushToOpenDistance =
      section.swingFlex.productLineProperties.SwingFlexPushToOpenDistance;

    const shelfCubes: Client.ItemCube[] = section.interior.items
      .map(ConfigurationItemHelper.getItemCube)
      .filter((cube) => cube.item.isShelf);

    section.swingFlex.areas.forEach((area) => {
      area.subAreas.forEach((subArea) => {
        // A push to open spot needs to be present when:
        // - Opening method is "push to open"
        // - Area has double doors
        // - Door height is greater than 1400mm
        if (
          subArea.openingMethod !== Enums.SwingFlexOpeningMethod.PushToOpen ||
          subArea.doorItems.length < 2 ||
          subArea.doorItems[0].Height < 2 * pushToOpenDistance
        ) {
          return;
        }

        const pushToOpenArea: Interface_DTO_Draw.Rectangle = {
          X: subArea.insideRect.X,
          Y: subArea.doorItems[0].centerY - pushToOpenDistance,
          Width: subArea.insideRect.Width,
          Height: 2 * pushToOpenDistance,
        };

        // Is there a low gable in the required area?
        const gable = area.items.find(
          (item) =>
            item.isGable &&
            VectorHelper.overlaps(
              ConfigurationItemHelper.getItemCube(item),
              pushToOpenArea,
            ),
        );
        if (!!gable) return;

        // Is there a shelf in the required area?
        if (
          shelfCubes.some((shelf) =>
            VectorHelper.overlaps(shelf, pushToOpenArea),
          )
        )
          return;

        // If we get here, no suitable push to open spot was found.
        let error = new Client.ErrorInfo(
          Enums.EditorSection.SwingFlex,
          Interface_Enums.ErrorLevel.Critical,
          'Push to open spot.',
          'There needs to be somewhere to put the Push to open.',
          section,
          undefined,
        );
        errorInfos.push(error);
      });
    });

    return errorInfos;
  }

  private static validateShelfBehindDoorTopAndBottomGap(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    const doors = section.swingFlex.areas.flatMap((a) =>
      a.subAreas.flatMap((sa) => sa.doorItems),
    );
    const topCenters: Interface_DTO_Draw.Vec2d[] = doors.map((d) => {
      return { X: d.centerX, Y: d.topY };
    });
    const bottomCenters: Interface_DTO_Draw.Vec2d[] = doors.map((d) => {
      return { X: d.centerX, Y: d.bottomY };
    });

    const shelves: Client.Rectangle[] = section.swingFlex.areas
      .flatMap((a) => a.items.concat(a.areaSplitItems))
      .concat(section.interior.items)
      .filter(
        (item) =>
          item.isShelf ||
          ProductHelper.isPlinth(item.Product!, section.cabinet.ProductLineId),
      )
      .map((s) => {
        return new Client.Rectangle(s);
      });

    topCenters.forEach((point) => {
      if (!shelves.some((shelf) => shelf.isPointInside(point))) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.SwingFlex,
          Interface_Enums.ErrorLevel.Critical,
          new Client.Translatable(
            'validate_swingFlexDoor_noShelfBehindDoorTop_short',
            'No shelf behind top of door',
          ),
          new Client.Translatable(
            'validate_swingFlexDoor_noShelfBehindDoorTop_long',
            'There must be a shelf behind the top of the door.',
          ),
          section,
          undefined,
        );
        errorInfos.push(error);
      }
    });

    bottomCenters.forEach((point) => {
      if (!shelves.some((shelf) => shelf.isPointInside(point))) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.SwingFlex,
          Interface_Enums.ErrorLevel.Critical,
          new Client.Translatable(
            'validate_swingFlexDoor_noShelfBehindDoorBottom_short',
            'No shelf behind bottom of door',
          ),
          new Client.Translatable(
            'validate_swingFlexDoor_noShelfBehindDoorBottom_long',
            'There must be a shelf behind the bottom of the door.',
          ),
          section,
          undefined,
        );
        errorInfos.push(error);
      }
    });

    return errorInfos;
  }

  /**
   * Validates that there is enough space to open the outer doors fully.
   * If the outer doors are using 155 degree hinges, there should be a distance to the wall of at least 91% of the door width.
   * For 110 degree hinges, the distance should be >= 35% of door width.
   */
  private static validateDoorsNotBlockedByWall(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const result: Client.ErrorInfo[] = [];
    if (!section.swingFlex?.areas?.length) {
      return result;
    }
    const safetyDistanceFractionByHingeType = {
      [Enums.SwingFlexHingeType.Auto]: 0.35,
      [Enums.SwingFlexHingeType.Standard]: 0.35,
      [Enums.SwingFlexHingeType.Special]: 0.91,
    };

    function getSafetyDistanceFraction(
      area: Client.SwingFlexArea,
      hingeTypeSelector: (
        subArea: Client.SwingFlexSubArea,
      ) => Enums.SwingFlexHingeType,
    ): number | undefined {
      let safetyDistanceFractions = area.subAreas
        .map(
          (subArea) =>
            safetyDistanceFractionByHingeType[hingeTypeSelector(subArea)],
        )
        .filter((safetyDistance) => typeof safetyDistance === 'number');
      if (safetyDistanceFractions.length === 0) return undefined;
      return Math.max(...safetyDistanceFractions);
    }

    const points: Interface_DTO_Draw.Vec2d[] = [];
    // left side
    {
      let leftArea = section.swingFlex.areas[0];
      if (leftArea.hingeSide === Enums.HingeSide.Both) {
        let safetyDistanceFraction = getSafetyDistanceFraction(
          leftArea,
          (sa) => sa.actualHingeTypeLeft,
        );
        if (typeof safetyDistanceFraction === 'number') {
          let doorWidth = leftArea.insideWidth / 2;
          points.push({
            X: -doorWidth * safetyDistanceFraction,
            Y: section.Depth + doorWidth,
          });
        }
      } else if (leftArea.hingeSide === Enums.HingeSide.Left) {
        let safetyDistanceFraction = getSafetyDistanceFraction(
          leftArea,
          (sa) => sa.actualHingeType,
        );
        if (typeof safetyDistanceFraction === 'number') {
          let doorWidth = leftArea.insideWidth;
          points.push({
            X: -doorWidth * safetyDistanceFraction,
            Y: section.Depth + doorWidth,
          });
        }
      }
    }

    // right side
    {
      let rightArea =
        section.swingFlex.areas[section.swingFlex.areas.length - 1];
      if (rightArea.hingeSide === Enums.HingeSide.Both) {
        let safetyDistanceFraction = getSafetyDistanceFraction(
          rightArea,
          (sa) => sa.actualHingeTypeRight,
        );
        if (typeof safetyDistanceFraction === 'number') {
          let doorWidth = rightArea.insideWidth / 2;
          points.push({
            X: section.Width + doorWidth * safetyDistanceFraction,
            Y: section.Depth + doorWidth,
          });
        }
      } else if (rightArea.hingeSide === Enums.HingeSide.Right) {
        let safetyDistanceFraction = getSafetyDistanceFraction(
          rightArea,
          (sa) => sa.actualHingeType,
        );
        if (typeof safetyDistanceFraction === 'number') {
          let doorWidth = rightArea.insideWidth;
          points.push({
            X: section.Width + doorWidth * safetyDistanceFraction,
            Y: section.Depth + doorWidth,
          });
        }
      }
    }

    // check for floorPlan wall collision
    const absolutePoints = points.map((p) => section.getAbsolutePoint(p));
    if (
      !GeometryHelper.areAllPointsInPolygon(
        absolutePoints,
        section.cabinet.floorPlan.getPoints(),
      )
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.FloorPlan,
          Interface_Enums.ErrorLevel.Warning,
          new Client.Translatable(
            'validate_swingFlexDoor_wallCollision_short',
            'Door hits wall when opening',
          ),
          new Client.Translatable(
            'validate_swingFlexDoor_wallCollision_long',
            'Door hits wall when opening. Pullout might not work.',
          ),
          section,
        ),
      );
    }

    //check for collision with other cabinets
    for (const otherCabinet of section.cabinet.floorPlan.actualCabinets) {
      if (otherCabinet === section.cabinet) continue;
      let polygon = otherCabinet.getPoints(false, false);
      for (const doorPoint of absolutePoints) {
        if (GeometryHelper.isPointInPolygon(doorPoint, polygon)) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Warning,
              new Client.Translatable(
                'validate_swingFlexDoor_cabinetCollision_short',
                'Door hits other cabinet when opening',
              ),
              new Client.Translatable(
                'validate_swingFlexDoor_cabinetCollision_long',
                'Door hits other cabinet when opening. Pullout might not work.',
              ),
              section,
            ),
          );
          break;
        }
      }
    }

    return result;
  }

  //#endregion

  // #region interior

  /**
   * Checks that drawers are not too close to each other, making them hard to open.
   * This should only be applied to drawers that:
   * - can have a grip (if not, assume they are push-to-open)
   * - don't currently have a grip
   * - have a SwingFlex door in front (making it impossible to mount a customer-supplied grip)
   * - have another item mounted immediately on top
   */
  private static validateCloseDrawers(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const minVerticalDistance = 25.4; //guesstimate
    let problemItems: Client.ConfigurationItem[] = [];

    for (let item of section.interior.items) {
      if (!item.canHaveGrip) continue;
      if (item.gripProduct) continue;

      if (!this.hasSwingFlexDoorInFront(item)) continue;

      let hasItemAbove = section.interior.items
        .concat(
          section.swingFlex.items.filter(
            (sfItem) =>
              sfItem.ItemType ===
              Interface_Enums.ItemType.SwingFlexCorpusMovable,
          ),
        )
        .some(
          (otherItem) =>
            otherItem.X <= item.X &&
            otherItem.rightX >= item.rightX && // do items line up horizontally?
            otherItem.topY > item.topY &&
            otherItem.Y < item.topY + minVerticalDistance,
        );
      if (!hasItemAbove) continue;

      //item is problematic
      problemItems.push(item);
    }

    if (problemItems.length === 0) {
      return [];
    }

    const error = new Client.ErrorInfo(
      Enums.EditorSection.Interior,
      Interface_Enums.ErrorLevel.Warning,
      new Client.Translatable(
        'validate_swingFlex_drawer_hard_to_open_short',
        'Drawer mounted too close to other item',
      ),
      new Client.Translatable(
        'validate_swingFlex_drawer_hard_to_open_long',
        '{0} Drawers have another item mounted directly on top. It might be hard to open the drawers.',
        problemItems.length.toString(),
      ),
      section,
      problemItems[0],
    );

    for (let item of problemItems) {
      item.errors.push(error);
    }
    return [error];
  }

  /** Checks if the interior item has a swingFlex door in front of it */
  public static hasSwingFlexDoorInFront(
    item: Client.ConfigurationItem,
  ): boolean {
    let section = item.cabinetSection;
    let hasDoorInFront = section.swingFlex.items.some(
      (otherItem) =>
        otherItem.ItemType === Interface_Enums.ItemType.SwingFlexDoor &&
        VectorHelper.overlaps2d(item, otherItem),
    );
    return hasDoorInFront;
  }

  // #endregion

  //#region Products

  /**
   * Validates the product for all items
   */
  private static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateProducts(
      section.swingFlex.items,
      Enums.EditorSection.SwingFlex,
      section,
    );
  }

  //#endregion

  //#region Materials

  /**
   * Validates the material for all items
   */
  private static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateMaterials(
      section.swingFlex.items,
      Enums.EditorSection.SwingFlex,
      section,
    );
  }

  //#endregion
}
