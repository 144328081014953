import { Injectable } from '@angular/core';
import Enumerable from 'linq';
import * as App from 'app/ts/app';
import { Constants } from 'app/ts/Constants';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';
import { ObjectHelper } from '@Util/ObjectHelper';
import { ChainSettingHelper } from '@Util/ChainSettingHelper';
import { ProductHelper } from '@Util/ProductHelper';
import * as Client from '@ClientDto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Enums from '@ClientDto/Enums';
import { ProductLineProperties } from 'app/ts/Interface_DTO_ProductLineProperties';
import { SwingFlexSubArea } from '../../clientDto/SwingFlexSubArea';
import { ConfigurationItemHelper } from '@Util/ConfigurationItemHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { Vec3d } from '../../Interface_DTO';
import { Vec2d } from '../../Interface_DTO_Draw';
import { CabinetSectionProperties } from 'app/ts/properties/CabinetSectionProperties';
import { MaterialHelper } from '@Util/MaterialHelper';
import { NotificationService } from '@Services/NotificationService';

@Injectable({ providedIn: 'root' })
export class SwingFlexLogic {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly notificationService: NotificationService,
  ) {}

  public recalculate(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (section.CabinetType !== Interface_Enums.CabinetType.SwingFlex)
      return result;

    if (App.debug.showTimings) console.time('Recalculate SwingFlex');

    const swingFlex = section.swingFlex;

    // Ensure we recalculate everything.
    swingFlex.clearBackingVariables();

    // Clear backing fitting cubes for 3D
    section.backing.fittingCubes.length = 0;

    // Ensure that all indexes are correct, and that the areas are sorted correctly.
    this.updateAreaIndexes(swingFlex);

    // Ensure all general values are set and valid
    this.ensureValuesAreValid(swingFlex);

    // *** The actual calculations start here ***

    // Calculate area dimensions and positions
    this.calculateAreas(swingFlex);

    this.updateAreaHingeTypes(swingFlex);

    this.updateNonGeneratedItemPositionY(swingFlex);

    // Generate configuration items
    this.generateConfigurationItems(swingFlex);

    this.adjustAreaSplitItems(swingFlex);

    this.updateDrawerFronts(swingFlex);

    this.updateDoorVariants(swingFlex);

    this.updateAreaPossibleHingeSides(swingFlex);

    if (App.debug.showTimings) console.timeEnd('Recalculate SwingFlex');

    return result;
  }

  // Validation of values set by user
  //#region

  private ensureValuesAreValid(swingFlex: Client.SwingFlex) {
    if (!this.isCorpusMaterialValid(swingFlex)) {
      this.setDefaultCorpusMaterial(swingFlex);
    }

    if (!this.isGlobalDoorMaterialValid(swingFlex)) {
      this.setDefaultGlobalDoorMaterial(swingFlex);
    }

    this.setDefaultDoorGripsIfInvalid(swingFlex);

    this.setDefaultDoorGripMaterialIfInvalid(swingFlex);

    if (!this.isDrawerGripValid(swingFlex)) {
      this.setDefaultDrawerGrip(swingFlex);
    }

    this.setDefaultDrawerGripMaterialIfInvalid(swingFlex);

    if (!this.isBackingMaterialValid(swingFlex)) {
      this.setDefaultBackingMaterial(swingFlex);
    }
  }

  private isCorpusMaterialValid(swingFlex: Client.SwingFlex): boolean {
    if (
      swingFlex.corpusMaterial === null ||
      swingFlex.corpusMaterial === undefined
    ) {
      return false;
    } else {
      let id = swingFlex.corpusMaterial.Id;
      return swingFlex.pickableCorpusMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultCorpusMaterial(swingFlex: Client.SwingFlex) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultSwingCorpusMaterialNumber(
        swingFlex.editorAssets,
      );
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(swingFlex.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(swingFlex.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    swingFlex.corpusMaterial = material ?? null;
  }

  private isGlobalDoorMaterialValid(swingFlex: Client.SwingFlex): boolean {
    if (
      swingFlex.doorMaterial === null ||
      swingFlex.doorMaterial === undefined
    ) {
      return false;
    } else {
      let id = swingFlex.doorMaterial.Id;
      return swingFlex.pickableDoorMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultGlobalDoorMaterial(swingFlex: Client.SwingFlex) {
    swingFlex.setDoorMaterial(this.getDefaultDoorMaterial(swingFlex), false);
  }

  private getDefaultDoorMaterial(
    swingFlex: Client.SwingFlex,
  ): Client.Material | null {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultSwingDoorMaterialNumber(
        swingFlex.editorAssets,
      );
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(swingFlex.pickableDoorMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(swingFlex.pickableDoorMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    return material ?? null;
  }

  private setDefaultDoorGripsIfInvalid(swingFlex: Client.SwingFlex) {
    for (const area of swingFlex.areas) {
      for (const subArea of area.subAreas) {
        if (subArea.openingMethod !== Enums.SwingFlexOpeningMethod.Grip)
          continue; // valid
        if (!subArea.doorGrip) continue; // valid
        if (
          swingFlex.availableDoorGrips.some(
            (p) => p.Id === subArea.doorGrip!.Id,
          )
        )
          continue; //valid

        // invalid - set opening method to default
        subArea.openingMethod =
          CabinetSectionProperties.SwingFlexSubArea.defaultValue.openingMethod;
      }
    }
  }

  private setDefaultDoorGripMaterialIfInvalid(swingFlex: Client.SwingFlex) {
    function setDefaultIfInvalid(d: {
      doorGrip: Client.Product | null;
      doorGripMaterial: Client.Material | null;
    }) {
      if (!d.doorGrip) {
        d.doorGripMaterial = null;
      } else if (
        !d.doorGrip.materials.some((mat) => mat.Id === d.doorGripMaterial?.Id)
      ) {
        d.doorGripMaterial = MaterialHelper.getDefaultMaterial(
          d.doorGrip.materials,
        );
      }
    }
    setDefaultIfInvalid(swingFlex);

    for (let subArea of swingFlex.subAreas) {
      setDefaultIfInvalid(subArea);
    }
  }

  private isDrawerGripValid(swingFlex: Client.SwingFlex): boolean {
    if (!swingFlex.drawerGrip) {
      return true;
    } else if (
      swingFlex.drawerGrip !== null &&
      swingFlex.drawerGrip !== undefined
    ) {
      let id = swingFlex.drawerGrip.Id;
      if (
        swingFlex.pickableDrawerGrips.some(
          (p) => p.item !== null && p.item.Id === id,
        )
      ) {
        return true;
      }
    }

    return false;
  }
  private setDefaultDrawerGrip(swingFlex: Client.SwingFlex) {
    let pickableGrip = Enumerable.from(
      swingFlex.pickableDrawerGrips,
    ).firstOrDefault();
    swingFlex.drawerGrip = !!pickableGrip ? pickableGrip.item : null;
  }

  private setDefaultDrawerGripMaterialIfInvalid(swingFlex: Client.SwingFlex) {
    if (!swingFlex.drawerGrip) {
      swingFlex.drawerGripMaterial = null;
    } else if (
      !swingFlex.drawerGrip.materials.some(
        (mat) => mat.Id === swingFlex.drawerGripMaterial?.Id,
      )
    ) {
      swingFlex.drawerGripMaterial = MaterialHelper.getDefaultMaterial(
        swingFlex.drawerGrip.materials,
      );
    }
    for (let area of swingFlex.areas) {
      for (let drawer of area.drawers) {
        let drawerProduct = area.getDrawerGripProduct(drawer);
        let drawerMaterial = area.getDrawerGripMaterial(drawer);
        if (!drawerProduct && drawerMaterial) {
          area.setDrawerGripMaterial(drawer, null);
        } else if (
          drawerProduct &&
          !drawerProduct.materials.some((mat) => mat.Id === drawerMaterial?.Id)
        ) {
          area.setDrawerGripMaterial(
            drawer,
            MaterialHelper.getDefaultMaterial(drawerProduct.materials),
          );
        }
      }
    }
  }

  private isBackingMaterialValid(swingFlex: Client.SwingFlex): boolean {
    if (
      swingFlex.backingMaterial === null ||
      swingFlex.backingMaterial === undefined
    ) {
      return false;
    } else {
      let id = swingFlex.backingMaterial.Id;
      return swingFlex.pickableBackingMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultBackingMaterial(swingFlex: Client.SwingFlex) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultSwingBackingMaterialNumber(
        swingFlex.editorAssets,
      );
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(swingFlex.pickableBackingMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(swingFlex.pickableBackingMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    swingFlex.backingMaterial = material ?? null;
  }

  //#endregion

  // Calculations
  //#region

  /**
   * Calculates and updates area dimensions and positions
   */
  private calculateAreas(swingFlex: Client.SwingFlex) {
    swingFlex.clearBackingVariables();

    this.ensureNumberOfAreasIsValid(swingFlex);

    this.setSubSectionDimensions(swingFlex);

    this.setAreaPositions(swingFlex);

    this.calculateMiddlePanels(swingFlex);
  }

  private ensureNumberOfAreasIsValid(swingFlex: Client.SwingFlex) {
    const gableWidth = swingFlex.getGableWidth();

    // Calculate manually adjusted (fixed) areas.
    let numberOfFixedAreas = 0;
    let totalFixedAreaWidth = 0;
    let accumulatedGableWidth = gableWidth;
    for (
      let areaNumber = 0;
      areaNumber < swingFlex.areas.length;
      areaNumber++
    ) {
      const area = swingFlex.areas[areaNumber];

      if (area.widthAdjustedByUser) {
        numberOfFixedAreas++;
        this.ensureDesiredAreaWidthIsValid(
          area,
          swingFlex.productLineProperties,
        );
        totalFixedAreaWidth += area.desiredInsideWidth;
        accumulatedGableWidth += gableWidth;
      }
    }

    // Calculate flexible areas
    const totalWidth = swingFlex.cabinetSection.SightWidth;
    const totalFlexibleAreaWidth =
      totalWidth - totalFixedAreaWidth - accumulatedGableWidth;

    const minWidthInclGable =
      swingFlex.productLineProperties.SwingFlexMinimumSubsectionInsideWidth +
      gableWidth;
    let maxWidthInclGable =
      swingFlex.productLineProperties.SwingFlexMaximumSubsectionInsideWidth +
      gableWidth;

    // On creating cabinet, set subsection maximum width to only require single door. This will avoid Push to open spot validation error
    if (swingFlex.areas.length === 0) {
      maxWidthInclGable =
        swingFlex.productLineProperties
          .SwingFlexDefaultMaximumSubsectionInsideWidth + gableWidth;
    }

    const calculatedMinimumNumberOfFlexibleAreas = Math.ceil(
      totalFlexibleAreaWidth / maxWidthInclGable,
    );
    const calculatedMaximumNumberOfFlexibleAreas = Math.floor(
      totalFlexibleAreaWidth / minWidthInclGable,
    );

    // Ensure minimum one flexible area
    const actualMinimumNumberOfFlexibleAreas = Math.max(
      calculatedMinimumNumberOfFlexibleAreas,
      1,
    );
    const actualMaximumNumberOfFlexibleAreas = Math.max(
      calculatedMaximumNumberOfFlexibleAreas,
      1,
    );

    const newNumberOffAreas = ObjectHelper.clamp(
      actualMinimumNumberOfFlexibleAreas + numberOfFixedAreas,
      swingFlex.desiredNumberOfAreas,
      actualMaximumNumberOfFlexibleAreas + numberOfFixedAreas,
    );

    // Add or remove areas if applicable
    while (swingFlex.areas.length < newNumberOffAreas) {
      let newArea = new Client.SwingFlexArea(
        swingFlex.cabinetSection,
        swingFlex.areas.length,
        [],
      );
      swingFlex.areas.push(newArea);
    }

    while (swingFlex.areas.length > newNumberOffAreas) {
      swingFlex.areas.splice(swingFlex.areas.length - 1, 1);
    }

    swingFlex.numberOfAreas = swingFlex.areas.length;
  }

  private ensureDesiredAreaWidthIsValid(
    area: Client.SwingFlexArea,
    properties: ProductLineProperties,
  ) {
    area.desiredInsideWidth = ObjectHelper.clamp(
      properties.SwingFlexMinimumSubsectionInsideWidth,
      area.desiredInsideWidth,
      properties.SwingFlexMaximumSubsectionInsideWidth,
    );
  }

  /**
   * Sets dimensions on all area in the subSection
   * Width is set to the desired width, or clamped to the closest value, if the desired width is not valid.
   * Height is set based on the cabinet sight height
   */
  private setSubSectionDimensions(swingFlex: Client.SwingFlex) {
    let bottomOffset = swingFlex.cabinetSection.corpus.heightBottom
      ? 0
      : swingFlex.productLineProperties.SwingFlexBottomOffset;
    let height = swingFlex.cabinetSection.SightHeight - bottomOffset;
    let gableWidth = swingFlex.getGableWidth();
    let totalGableWidth = (swingFlex.areas.length + 1) * gableWidth;
    let totalFixedAreaWidth = swingFlex.areas
      .filter((a) => a.widthAdjustedByUser)
      .reduce((sum, area) => sum + area.desiredInsideWidth, 0);
    let totalFlexAreaWidth =
      swingFlex.cabinetSection.SightWidth -
      totalFixedAreaWidth -
      totalGableWidth;

    let flexAreaWidth = Math.floor(
      totalFlexAreaWidth /
        swingFlex.areas.filter((a) => !a.widthAdjustedByUser).length,
    );
    let remainingFlexibleAreaWidth = totalFlexAreaWidth;

    for (
      let areaNumber = 0;
      areaNumber < swingFlex.areas.length;
      areaNumber++
    ) {
      const area = swingFlex.areas[areaNumber];

      if (!area.widthAdjustedByUser) {
        let newDesiredWidth = flexAreaWidth;
        remainingFlexibleAreaWidth -= flexAreaWidth;
        if (remainingFlexibleAreaWidth < flexAreaWidth) {
          newDesiredWidth += remainingFlexibleAreaWidth;
        }

        area.desiredInsideWidth = newDesiredWidth;
      }

      let actualInsideWidth = ObjectHelper.clamp(
        swingFlex.productLineProperties.SwingFlexMinimumSubsectionInsideWidth,
        area.desiredInsideWidth,
        swingFlex.productLineProperties.SwingFlexMaximumSubsectionInsideWidth,
      );

      if (
        area.insideRect.Width > 0 &&
        actualInsideWidth !== area.insideRect.Width
      ) {
        let difference = actualInsideWidth - area.insideRect.Width;

        //areas to the right of this one must be moved in order to correctly move items inside it
        for (
          let otherAreaNumber = areaNumber + 1;
          otherAreaNumber < swingFlex.areas.length;
          otherAreaNumber++
        ) {
          let otherArea = swingFlex.areas[otherAreaNumber];
          otherArea.insideRect.X += difference;
          otherArea.insideRect.Width = actualInsideWidth;
        }
      }

      area.insideRect.Width = actualInsideWidth;
      area.desiredInsideWidth = actualInsideWidth;
      area.insideRect.Height = height;
    }
  }

  private adjustAreaSplitItems(swingFlex: Client.SwingFlex) {
    for (const area of swingFlex.areas) {
      // Adjust swingflex corpus item by finding most optimal product from depth and width. Adjust item width and Z-positions
      for (let splitItem of area.areaSplitItems) {
        let _areaWidth = area.insideRect.Width;
        let _startX = area.insideRect.X;

        let _product = splitItem.Product;
        let _productLineId = splitItem.productLineId;

        if (_product && _productLineId) {
          if (!!area.middlePanelItem) {
            let middlePanel = area.middlePanelItem;

            let _splitItemIsSnappedToMiddleGable =
              splitItem.Y > middlePanel.Y &&
              splitItem.topY < middlePanel.Y + middlePanel.Height;

            if (_splitItemIsSnappedToMiddleGable) {
              _areaWidth = (area.insideRect.Width - middlePanel.Width) / 2;

              if (splitItem.centerX > area.insideRect.CenterX) {
                _startX = area.insideRect.X + _areaWidth + middlePanel.Width;
              }
            }
          }

          const optimalDepth =
            swingFlex.depth -
            swingFlex.productLineProperties.SwingFlexSpaceForBacking -
            splitItem.reductionDepth;

          let newProduct = ProductHelper.getOptimalProduct(
            _product,
            _areaWidth,
            optimalDepth,
            _productLineId,
            swingFlex.getDrillingPattern(),
          );
          if (newProduct) {
            splitItem.Product = newProduct;
            splitItem.X = _startX;
            splitItem.trySetWidth(_areaWidth, false);

            splitItem.trySetDepth(optimalDepth, true);
            splitItem.Z =
              swingFlex.depth - splitItem.reductionDepth - splitItem.Depth;
          }
        }
      }
    }
  }

  /**
   * Calculates and updates X and Y positions for all areas
   * @param swingFlex
   */
  private setAreaPositions(swingFlex: Client.SwingFlex) {
    if (swingFlex.areas.length <= 0) {
      return;
    }

    let gableWidth = swingFlex.getGableWidth();

    let positionX = swingFlex.cabinetSection.corpus.widthLeft;
    let positionY = swingFlex.cabinetSection.corpus.heightBottom;
    if (positionY === 0)
      // If no external corpus bottom, add space for "feet"
      positionY += swingFlex.productLineProperties.SwingFlexBottomOffset;

    for (let i = 0; i < swingFlex.areas.length; i++) {
      let area = swingFlex.areas[i];

      positionX += gableWidth;

      area.insideRect.X = positionX;
      area.insideRect.Y = positionY;

      // Move swingflex corpus items, if they are not fully inside the area
      for (let splitItem of area.areaSplitItems) {
        if (
          splitItem.X < positionX ||
          splitItem.rightX > positionX + area.insideRect.Width
        ) {
          if (splitItem.Width < area.insideRect.Width) {
            // Check if the item is inside middle panel.
            continue;
          }

          splitItem.X = positionX;
        }
      }

      positionX += area.insideRect.Width;
    }
  }

  private calculateMiddlePanels(swingFlex: Client.SwingFlex) {
    if (swingFlex.areas.length <= 0) {
      return;
    }

    const gableWidth = swingFlex.getGableWidth();
    const gableDimensionConstraints =
      this.getMiddlePanelDimensionConstraints(swingFlex);

    for (let i = 0; i < swingFlex.areas.length; i++) {
      const area = swingFlex.areas[i];

      area.subAreas.forEach((subArea) => {
        if (subArea.hasMiddlePanel) {
          // Position
          subArea.middlePanelPostion = area.insideWidth / 2 - gableWidth / 2;

          // Height
          subArea.middlePanelHeight = ObjectHelper.clamp(
            gableDimensionConstraints.min,
            subArea.middlePanelHeight ?? 0,
            gableDimensionConstraints.max,
          );
        } else {
          subArea.middlePanelPostion = null;
          subArea.middlePanelHeight = null;
          area.middlePanelItem = null;
        }
      });
    }
  }

  private updateSubAreas(
    swingFlex: Client.SwingFlex,
    area: Client.SwingFlexArea,
  ) {
    const addOrUpdateSubArea = (
      index: number,
      position: number,
      height: number,
      drawerBelow: boolean,
      drawerAbove: boolean,
    ) => {
      while (area.subAreas.length <= index) {
        area.subAreas.push(new SwingFlexSubArea(area));
      }

      const subArea = area.subAreas[index];
      subArea.insideRect.X = area.insideRect.X;
      subArea.insideRect.Width = area.insideRect.Width;
      subArea.insideRect.Y = position;
      subArea.insideRect.Height = height;

      subArea.hasDrawerBelow = drawerBelow;
      subArea.hasDrawerAbove = drawerAbove;
    };

    area.sortAreaSplitItems();

    if (area.areaSplitItems.length > 0) {
      // We have shelves or drawers, so we also need sub areas
      for (let i = 0; i < area.areaSplitItems.length; i++) {
        let first: boolean = i === 0;
        let last: boolean = i === area.areaSplitItems.length - 1;

        let position = first
          ? 0
          : area.areaSplitItems[i - 1].topY - area.insideRect.Y;
        let height = area.areaSplitItems[i].Y - area.insideRect.Y - position;

        let drawerBelow = !first && area.areaSplitItems[i - 1].isPullout;
        let drawerAbove = area.areaSplitItems[i].isPullout;

        addOrUpdateSubArea(i, position, height, drawerBelow, drawerAbove);

        if (last) {
          position = area.areaSplitItems[i].topY - area.insideRect.Y;
          height = area.insideHeight - position;

          let drawerBelow = area.areaSplitItems[i].isPullout;

          addOrUpdateSubArea(i + 1, position, height, drawerBelow, false);
        }
      }
    } else {
      // No shelves, use the full area...
      let index = 0;
      let position = 0;
      let height = area.insideHeight;
      addOrUpdateSubArea(index, position, height, false, false);
    }

    if (area.subAreas.length > area.areaSplitItems.length + 1) {
      area.subAreas.splice(area.areaSplitItems.length + 1);
    }
  }

  private updateNonGeneratedItemPositionY(swingFlex: Client.SwingFlex) {
    // Calculate corpus bottom change?
    const currentGablePositionY =
      swingFlex.areas.at(0)?.items.find((item) => item.isGable)?.Y ?? 0;
    const newGablePositionY = swingFlex.getGablePositionY();
    const corpusBottomChange = newGablePositionY - currentGablePositionY;

    // Has plinth changed?
    const plinthHasChanged = swingFlex.plinthChanged;
    swingFlex.plinthChanged = false;

    if (corpusBottomChange === 0 && !plinthHasChanged) {
      // Nothing has changed, so we are done here...
      return;
    }

    const plinthHeight = swingFlex.getPlinthHeight();

    for (const area of swingFlex.areas) {
      let plinthChange = 0;
      if (plinthHasChanged) {
        if (swingFlex.plinth) {
          // Plinth must have been added. Adjust items up.
          plinthChange = plinthHeight;
        } else {
          // Plinth may have been removed.
          // If no movable SwingFlex item is positioned below plinth height, there should have been plinth before, and items must be adjusted down.
          if (!area.areaSplitItems.some((item) => item.Y < plinthHeight)) {
            plinthChange = -plinthHeight;
          }
        }
      }

      if (corpusBottomChange === 0 && plinthChange === 0) {
        continue;
      }

      for (let splitItem of area.areaSplitItems) {
        splitItem.Y += corpusBottomChange;
        splitItem.Y += plinthChange;
      }

      for (let interiorItem of SwingFlexLogic.getInteriorItemsInArea(
        swingFlex.cabinetSection,
        area,
      )) {
        interiorItem.Y += corpusBottomChange;
        interiorItem.Y += plinthChange;
      }
    }
  }

  /**
   * Calculates and updates drawer front sizes and positions
   * @param swingFlex
   */
  private updateDrawerFronts(swingFlex: Client.SwingFlex) {
    const widthOption = swingFlex.productLineProperties.WidthVariantOption;

    for (let area of swingFlex.areas) {
      const drawers = area.areaSplitItems.filter((item) => item.isPullout);
      if (drawers.length <= 0) continue;

      const areaLeftX = area.insideRect.X;
      const areaRightX = area.insideRect.X + area.insideRect.Width;

      for (let drawer of drawers) {
        let left =
          swingFlex.areas.indexOf(area) == 0 &&
          Math.abs(areaLeftX - drawer.X) < Constants.sizeAndPositionTolerance;
        let right =
          swingFlex.areas.indexOf(area) == swingFlex.areas.length - 1 &&
          Math.abs(areaRightX - drawer.rightX) <
            Constants.sizeAndPositionTolerance;

        let extraWidthOption: VariantNumbers.DrawerFrontExtraWidthValues;

        if (left) {
          if (right) {
            extraWidthOption =
              VariantNumbers.DrawerFrontExtraWidthValues.LeftAndRight;
          } else {
            extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.Left;
          }
        } else if (right) {
          extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.Right;
        } else {
          extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.None;
        }

        ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
          drawer,
          VariantNumbers.DrawerFrontWidth,
          widthOption,
        );
        ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
          drawer,
          VariantNumbers.DrawerFrontExtraWidth,
          extraWidthOption,
        );
      }
    }
  }

  private updateDoorVariants(swingFlex: Client.SwingFlex) {
    const doorCoversPlinthOption = swingFlex.doorCoversPlinth
      ? VariantNumbers.Values.Yes
      : VariantNumbers.Values.No;

    for (let area of swingFlex.areas) {
      const firstArea = swingFlex.areas.indexOf(area) == 0;
      const lastArea =
        swingFlex.areas.indexOf(area) == swingFlex.areas.length - 1;

      const doors = Enumerable.from(area.items)
        .where(
          (item) => item.ItemType === Interface_Enums.ItemType.SwingFlexDoor,
        )
        .orderBy((item) => item.Y);

      let doorIndex = 0;
      let firstDoorY = 0;

      for (let door of doors) {
        const areaLeftX = area.insideRect.X;
        const areaRightX = area.insideRect.X + area.insideRect.Width;

        const extraWidthLeft = firstArea && door.X < areaLeftX;
        const extraWidthRight = lastArea && door.rightX > areaRightX;

        let extraWidthOption: VariantNumbers.DrawerFrontExtraWidthValues;

        if (extraWidthLeft) {
          if (extraWidthRight) {
            extraWidthOption =
              VariantNumbers.DrawerFrontExtraWidthValues.LeftAndRight;
          } else {
            extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.Left;
          }
        } else if (extraWidthRight) {
          extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.Right;
        } else {
          extraWidthOption = VariantNumbers.DrawerFrontExtraWidthValues.None;
        }

        ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
          door,
          VariantNumbers.DoorExtraWidth,
          extraWidthOption,
        );

        if (
          doors.count() > 1 &&
          doorCoversPlinthOption === VariantNumbers.Values.Yes
        ) {
          if (doorIndex === 0 || door.Y === firstDoorY) {
            // First door or same Y position as first door
            firstDoorY = door.Y;
            ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
              door,
              VariantNumbers.DoorCoversPlinth,
              VariantNumbers.Values.Yes,
            );
          }

          if (doorIndex > 0 && door.Y !== firstDoorY) {
            // Not first door and different Y position than first door
            ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
              door,
              VariantNumbers.DoorCoversPlinth,
              VariantNumbers.Values.No,
            );
          }
        } else {
          const plinthHeight = swingFlex.getPlinthHeight();
          // Check if door is covering plinth. If covering plinth, add variant option
          if (door.Y < plinthHeight) {
            ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
              door,
              VariantNumbers.DoorCoversPlinth,
              doorCoversPlinthOption,
            );
          }
        }

        doorIndex++;
      }
    }
  }

  //#endregion

  // ConfigurationItem generation
  //#region

  private static setGableVariants(
    gableItem: Client.ConfigurationItem,
    gableInfo: CabinetSectionProperties.SwingFlexGableInfo,
  ) {
    for (const variantNumber of Object.keys(gableInfo.variantOptions)) {
      const optionNumber = gableInfo.variantOptions[variantNumber];
      if (typeof optionNumber === 'string')
        ConfigurationItemHelper.addVariantOptionByNumbers(
          gableItem,
          variantNumber,
          optionNumber,
        );
    }
  }

  private generateConfigurationItems(swingFlex: Client.SwingFlex) {
    const productLineProperties = swingFlex.productLineProperties;

    const gableHeight = swingFlex.getGableHeight();
    const outerGableDepth = swingFlex.depth;
    const innerGableDepth =
      swingFlex.depth - productLineProperties.SwingFlexSpaceForBacking;
    const corpusMaterial = !!swingFlex.corpusMaterial
      ? swingFlex.corpusMaterial
      : undefined;

    const bottomShelves = swingFlex.corpusProducts.where(
      (p) =>
        p.ProductGroupingNo ==
        productLineProperties.SwingFlexBottomShelfProductGroupingNumber,
    );
    const topShelves = swingFlex.corpusProducts.where(
      (p) =>
        p.ProductGroupingNo ==
        productLineProperties.SwingFlexTopShelfProductGroupingNumber,
    );
    const plinths = swingFlex.corpusProducts.where((p) =>
      ProductHelper.isPlinth(p, swingFlex.cabinetSection.cabinet.ProductLineId),
    );
    const plinthHeight = swingFlex.getPlinthHeight();

    for (let i = 0; i < swingFlex.areas.length; i++) {
      let area = swingFlex.areas[i];
      area.removeGeneratedItems();

      const first: boolean = i === 0;
      const last: boolean = i === swingFlex.areas.length - 1;

      // Calculate sub areas
      this.updateSubAreas(swingFlex, area);

      //#region Gables, full height

      let gableNeedingTList: Client.ConfigurationItem | null = null;

      // First area, start with a left gable
      if (first) {
        let leftGable = swingFlex.corpusProducts.firstOrDefault(
          (p) =>
            p.ProductGroupingNo ==
              productLineProperties.SwingFlexGableLeftProductGroupingNumber &&
            ProductHelper.supportsDimensions(
              p,
              gableHeight,
              null,
              outerGableDepth,
              corpusMaterial,
            ),
        );
        if (leftGable) {
          const gableItem = this.createGableItem(swingFlex, leftGable, area, {
            depth: outerGableDepth,
            right: false,
            usePlinth: swingFlex.plinth,
            addTList: false,
          });
          if (!!gableItem) {
            const gableInfo = area.leftGableInfo;
            SwingFlexLogic.setGableVariants(gableItem, gableInfo);

            area.addItem(gableItem);
          }
          area.leftGableItem = gableItem ?? undefined;
        }
      }

      // Not last area, insert middle gable to the right
      if (!last) {
        let middleGable = swingFlex.corpusProducts.firstOrDefault(
          (p) =>
            p.ProductGroupingNo ==
              productLineProperties.SwingFlexGableCenterProductGroupingNumber &&
            ProductHelper.supportsDimensions(
              p,
              gableHeight,
              null,
              innerGableDepth,
              corpusMaterial,
            ),
        );
        if (middleGable) {
          const gableItem = this.createGableItem(swingFlex, middleGable, area, {
            depth: innerGableDepth,
            right: true,
            usePlinth: swingFlex.plinth,
            addTList: true,
          });
          if (!!gableItem) {
            let nextArea = swingFlex.areas[i + 1];
            SwingFlexLogic.setGableVariants(gableItem, nextArea.leftGableInfo);
            area.addItem(gableItem);
            gableNeedingTList = gableItem;
            nextArea.leftGableItem = gableItem ?? undefined;
          }
        }
      }
      // Last area, end with a right gable
      else {
        let rightGable = swingFlex.corpusProducts.firstOrDefault(
          (p) =>
            p.ProductGroupingNo ==
              productLineProperties.SwingFlexGableRightProductGroupingNumber &&
            ProductHelper.supportsDimensions(
              p,
              gableHeight,
              null,
              outerGableDepth,
              corpusMaterial,
            ),
        );
        if (rightGable) {
          const gableItem = this.createGableItem(swingFlex, rightGable, area, {
            depth: outerGableDepth,
            right: true,
            usePlinth: swingFlex.plinth,
            addTList: false,
          });
          if (!!gableItem) {
            SwingFlexLogic.setGableVariants(
              gableItem,
              swingFlex.rightGableInfo,
            );
            swingFlex.rightGableItem = gableItem;
            area.addItem(gableItem);
          }
        }
      }

      //#endregion

      //#region Gables, not full height

      let middlePanelItem: Client.ConfigurationItem | null = null;

      for (let i = 0; i < area.subAreas.length; i++) {
        const subArea = area.subAreas[i];

        this.updateSubAreaMiddlePanelChoices(subArea, swingFlex);

        if (subArea.hasMiddlePanel) {
          const middlePanels = swingFlex.corpusProducts.where(
            (p) =>
              p.ProductGroupingNo ===
                productLineProperties.SwingFlexMiddlePanelProductGroupingNumber &&
              ProductHelper.supportsDepth(p, innerGableDepth, corpusMaterial),
          );

          const shelf = swingFlex.corpusProducts
            .where((p) => p.ProductType === Interface_Enums.ProductType.Shelf)
            .firstOrDefault();

          if (middlePanels.count() > 0 && !!shelf) {
            const minPanelHeight = middlePanels.min((mp) =>
              ProductHelper.minHeight(mp, corpusMaterial),
            );
            const maxPanelHeight = middlePanels.max((mp) =>
              ProductHelper.maxHeight(mp, corpusMaterial),
            );
            const verticalSpace = this.getVerticalSpaceForMiddlePanel(
              area,
              swingFlex,
            );

            const heightAboveShelf =
              productLineProperties.SwingFlexMiddlePanelHeightAboveShelf;
            const shelfHeight = ProductHelper.defaultHeight(shelf);
            const shelfSnapPoint = ProductHelper.getSnapOffsetY(shelf);
            const spaceAboveDrilling =
              heightAboveShelf + shelfHeight - shelfSnapPoint;

            const drillStops = swingFlex.getDrillStops(
              0,
              Math.min(verticalSpace, maxPanelHeight),
            );
            subArea.availableMiddlePanelHeights = drillStops
              .map((ds) => ds + spaceAboveDrilling)
              .filter((h) => h >= minPanelHeight && h <= maxPanelHeight);

            if (subArea.availableMiddlePanelHeights.length > 0) {
              if (subArea.middlePanelHeight === null) {
                subArea.middlePanelHeight =
                  subArea.availableMiddlePanelHeights[0];
              }

              const drillStopsAbove = drillStops.filter(
                (ds) => ds >= subArea.middlePanelHeight! - spaceAboveDrilling,
              );
              if (drillStopsAbove.length > 0) {
                subArea.middlePanelHeight =
                  Math.min(...drillStopsAbove) + spaceAboveDrilling;
              } else {
                subArea.middlePanelHeight =
                  Math.max(...drillStops) + spaceAboveDrilling;
              }

              const middlePanel = middlePanels.firstOrDefault((mp) =>
                ProductHelper.supportsDimensions(
                  mp,
                  subArea.middlePanelHeight,
                  null,
                  innerGableDepth,
                  corpusMaterial,
                ),
              );
              if (!!middlePanel) {
                middlePanelItem = this.createMiddlePanelItem(
                  swingFlex,
                  middlePanel,
                  subArea,
                  innerGableDepth,
                );
                if (middlePanelItem) {
                  area.addItem(middlePanelItem);
                }
              }
            }
          }
          area.middlePanelItem = middlePanelItem;
          const _middlePanelItem = area.middlePanelItem;

          if (_middlePanelItem) {
            let shelfsSnappedToMiddlePanel = Enumerable.from(
              area.areaItems,
            ).where(
              (c) =>
                c.isShelf &&
                c.bottomY > _middlePanelItem.bottomY &&
                c.topY <= _middlePanelItem.topY,
            );

            if (shelfsSnappedToMiddlePanel.any()) {
              const _width =
                (area.insideRect.Width - _middlePanelItem.Width) / 2;
              shelfsSnappedToMiddlePanel.forEach((shelf) => {
                shelf.trySetWidth(_width, true, true);
                if (shelf.centerX > subArea.insideRect.CenterX) {
                  //right side of the middle panel
                  shelf.X = _middlePanelItem.rightX;
                }
              });
            }
          }
        }
      }

      //#endregion

      //#region Top and bottom shelves

      const shelfWidth = area.insideWidth;
      const shelfDepth =
        innerGableDepth - productLineProperties.SwingFlexShelfFrontOffset;
      let bottomOffset = this.getGablePositionY(swingFlex);

      let addPlinth = swingFlex.plinth;
      if (addPlinth) {
        if (
          // If the doors are set to cover the plinth, it is possible to place a drawer where the plinth would otherwise be.
          // If that is the case, the plinth is not added in the area.
          swingFlex.doorCoversPlinth &&
          area.subAreas[0].height < plinthHeight &&
          area.areaSplitItems.length > 0 &&
          area.areaSplitItems[0].isPullout
        ) {
          addPlinth = false;
        }
      }
      let shelfPositionY = bottomOffset + (addPlinth ? plinthHeight : 0);

      let insideTop =
        swingFlex.cabinetSection.Height -
        swingFlex.cabinetSection.corpus.heightTop;
      let insideBottom = productLineProperties.SwingFlexBottomOffset;

      // A middle panel causes the bottom shelf to be split in two.
      if (area.subAreas[0].hasMiddlePanel) {
        let gableWidth = swingFlex.getGableWidth();
        let shelfWidthLeft = area.subAreas[0].middlePanelPostion ?? 0;
        let shelfWidthRight = area.insideWidth - (shelfWidthLeft + gableWidth);

        let bottomShelfLeft = bottomShelves.firstOrDefault((p) =>
          ProductHelper.supportsDimensions(
            p,
            null,
            shelfWidthLeft,
            shelfDepth,
            corpusMaterial,
          ),
        );
        if (bottomShelfLeft) {
          const bottomShelfLeftItem = this.createCorpusShelfItem(
            swingFlex,
            bottomShelfLeft,
            area,
            shelfDepth,
            shelfPositionY,
            shelfWidthLeft,
            0,
          );
          if (!!bottomShelfLeftItem) {
            ConfigurationItemHelper.addVariantOptionByNumbers(
              bottomShelfLeftItem,
              VariantNumbers.GableAdaptionToFittingPanel,
              VariantNumbers.Values.Left,
            );
            area.addItem(bottomShelfLeftItem);
            insideBottom = bottomShelfLeftItem.topY;
          }
        }

        let bottomShelfRight = bottomShelves.firstOrDefault((p) =>
          ProductHelper.supportsDimensions(
            p,
            null,
            shelfWidthRight,
            shelfDepth,
            corpusMaterial,
          ),
        );
        if (bottomShelfRight) {
          const bottomShelfRightItem = this.createCorpusShelfItem(
            swingFlex,
            bottomShelfRight,
            area,
            shelfDepth,
            shelfPositionY,
            shelfWidthRight,
            shelfWidthLeft + gableWidth,
          );
          if (!!bottomShelfRightItem) {
            ConfigurationItemHelper.addVariantOptionByNumbers(
              bottomShelfRightItem,
              VariantNumbers.GableAdaptionToFittingPanel,
              VariantNumbers.Values.Right,
            );
            area.addItem(bottomShelfRightItem);
            insideBottom = bottomShelfRightItem.topY;
          }
        }
      }
      // No middle panel results in one bottom shelf
      else {
        let bottomShelf = bottomShelves.firstOrDefault((p) =>
          ProductHelper.supportsDimensions(
            p,
            null,
            shelfWidth,
            shelfDepth,
            corpusMaterial,
          ),
        );
        if (bottomShelf) {
          const bottomShelfItem = this.createCorpusShelfItem(
            swingFlex,
            bottomShelf,
            area,
            shelfDepth,
            shelfPositionY,
          );
          if (!!bottomShelfItem) {
            area.addItem(bottomShelfItem);
            insideBottom = bottomShelfItem.topY;
          }
        }
      }

      let topShelf = topShelves.firstOrDefault((p) =>
        ProductHelper.supportsDimensions(
          p,
          null,
          shelfWidth,
          shelfDepth,
          corpusMaterial,
        ),
      );
      if (topShelf) {
        shelfPositionY =
          swingFlex.cabinetSection.Height -
          swingFlex.cabinetSection.corpus.heightTop -
          ProductHelper.defaultHeight(topShelf);
        const topShelfItem = this.createCorpusShelfItem(
          swingFlex,
          topShelf,
          area,
          shelfDepth,
          shelfPositionY,
        );
        if (!!topShelfItem) {
          area.addItem(topShelfItem);
          insideTop = topShelfItem.bottomY;
        }
      }

      // Adjust inside rectangle
      area.insideRect.Y = insideBottom;
      area.insideRect.Height = insideTop - insideBottom;

      //#endregion

      //#region Plinth

      if (addPlinth) {
        // A middle panel causes the plinth to be split in two.
        if (area.subAreas[0].hasMiddlePanel) {
          const gableWidth = swingFlex.getGableWidth();
          const plinthWidthLeft = area.subAreas[0].middlePanelPostion ?? 0;
          const plinthWidthRight =
            area.insideWidth - (plinthWidthLeft + gableWidth);

          const plinthLeft = plinths.firstOrDefault((p) =>
            ProductHelper.supportsWidth(p, plinthWidthLeft, corpusMaterial),
          );
          if (plinthLeft) {
            const plinthLeftItem = this.createPlinthItem(
              swingFlex,
              plinthLeft,
              area,
              plinthWidthLeft,
              0,
            );
            if (!!plinthLeftItem) {
              area.addItem(plinthLeftItem);
            }
          }

          const plinthRight = plinths.firstOrDefault((p) =>
            ProductHelper.supportsWidth(p, plinthWidthRight, corpusMaterial),
          );
          if (plinthRight) {
            const plinthRightItem = this.createPlinthItem(
              swingFlex,
              plinthRight,
              area,
              plinthWidthRight,
              plinthWidthLeft + gableWidth,
            );
            if (!!plinthRightItem) {
              area.addItem(plinthRightItem);
            }
          }
        } else {
          const plinth = plinths.firstOrDefault((p) =>
            ProductHelper.supportsWidth(p, area.insideWidth, corpusMaterial),
          );
          if (plinth) {
            const plinthItem = this.createPlinthItem(swingFlex, plinth, area);
            if (!!plinthItem) {
              area.addItem(plinthItem);
            }
          }
        }
      }

      area.hasPlinth = addPlinth;
      const plinthOffset =
        addPlinth && swingFlex.doorCoversPlinth ? plinthHeight : 0;

      //#endregion

      // Calculate sub areas again (plinth may have changed them)
      this.updateSubAreas(swingFlex, area);

      //#region Doors

      const tryCreateHinges = (
        doorItem: Client.ConfigurationItem,
        hingeType: Enums.SwingFlexHingeType,
        left: boolean,
        bottomDoor: boolean,
      ): Client.SwingFlexArea.Hinge[] => {
        const hinges: Client.SwingFlexArea.Hinge[] = [];
        const hingeSize = productLineProperties?.SwingFlexHingeSize ?? {
          X: 70,
          Y: 45,
          Z: 70,
        };
        const offsetY =
          bottomDoor && swingFlex.plinth && swingFlex.doorCoversPlinth
            ? swingFlex.getPlinthHeight()
            : 0;
        const hingeLeftX = left
          ? doorItem.leftX
          : doorItem.rightX - hingeSize.X;
        const hingePositionX = hingeLeftX + hingeSize.X / 2;

        let hingePositions: number[] = [];

        // Start adding hinges from the bottom
        for (let positionInfo of productLineProperties.SwingFlexDoorHingePositions) {
          if (doorItem.Height > positionInfo.MinDoorHeight + offsetY) {
            hingePositions.push(positionInfo.HingePosition + offsetY);
          }
        }

        // Top hinge
        hingePositions.push(
          Math.min(
            doorItem.Height -
              productLineProperties.SwingFlexDoorTopHingeDistanceFromTop,
            productLineProperties.SwingFlexDoorTopHingeMaxPosition,
          ),
        );

        // If we have positions for minimum two hinges, we add them.
        // If not, the door must be removed, so we do not add any hinges.
        if (hingePositions.length >= 2) {
          hingePositions.forEach((hingePositionY) => {
            hinges.push({
              hingeType: hingeType,
              centerX: hingePositionX,
              centerY: hingePositionY,
              doorOffset: doorItem.bottomY,

              // Cube
              X: hingeLeftX,
              Y: hingePositionY - hingeSize.Y / 2,
              Z: doorItem.Z - hingeSize.Z,
              Width: hingeSize.X,
              Height: hingeSize.Y,
              Depth: hingeSize.Z,
            });
          });
        }

        return hinges;
      };

      const addHinges = (
        doorItem: Client.ConfigurationItem,
        hinges: Client.SwingFlexArea.Hinge[],
        left: boolean,
      ) => {
        if (hinges.length <= 0) return;

        area.hinges.push(...hinges);
        const hingeType: Enums.SwingFlexHingeType = hinges[0].hingeType;

        //set variantOption
        const hingeTypeVariantOption = (() => {
          switch (hingeType) {
            case Enums.SwingFlexHingeType.Standard:
              return VariantNumbers.Values.Hinge110Deg;
            case Enums.SwingFlexHingeType.Special:
              return VariantNumbers.Values.Hinge155WithBluMotion;
            default:
              throw new Error(
                `Not implemented: No variantOption for hinge type ${hingeType}`,
              );
          }
        })();
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          VariantNumbers.HingeType,
          hingeTypeVariantOption,
        );
        ConfigurationItemHelper.addDimensionVariant(
          doorItem,
          VariantNumbers.HingeCount,
          hinges.length.toString(),
        );
        // hinge side
        const hingeSideVariantOption = left
          ? VariantNumbers.Values.Left
          : VariantNumbers.Values.Right;
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          VariantNumbers.SwingFlexHingeSide,
          hingeSideVariantOption,
        );
      };

      const addDoorGrips = (
        doorItem: Client.ConfigurationItem,
        subArea: SwingFlexSubArea,
        leftSideOfDoor: boolean,
      ) => {
        if (
          subArea.openingMethod !== Enums.SwingFlexOpeningMethod.Grip ||
          !subArea.doorGrip
        ) {
          doorItem.gripProduct = null;
          return;
        }

        let rotate = ProductHelper.rotateOnDoor(subArea.doorGrip);
        const gripDimensions = this.getGripDimensions(
          subArea.doorGrip,
          subArea.doorGripMaterial ?? undefined,
          rotate,
        );
        const gripHeight = gripDimensions.Y;
        const gripWidth = gripDimensions.X;

        const gripPositionX = leftSideOfDoor
          ? doorItem.leftX + productLineProperties.SwingFlexDoorGripSpace
          : doorItem.rightX -
            productLineProperties.SwingFlexDoorGripSpace -
            gripWidth;

        let gripPosition = subArea.gripPosition;
        if (subArea.gripPosition == Enums.SwingFlexGripPosition.Auto) {
          if (
            doorItem.bottomY >=
            productLineProperties.SwingFlexDoorGripDefaultPosition -
              gripHeight / 2 -
              productLineProperties.SwingFlexDoorGripSpace
          )
            gripPosition = Enums.SwingFlexGripPosition.Bottom;
          else if (
            doorItem.topY <=
            productLineProperties.SwingFlexDoorGripDefaultPosition +
              gripHeight / 2 +
              productLineProperties.SwingFlexDoorGripSpace
          )
            gripPosition = Enums.SwingFlexGripPosition.Top;
          else gripPosition = Enums.SwingFlexGripPosition.Auto;
        }

        let gripPositionY;
        switch (gripPosition) {
          case Enums.SwingFlexGripPosition.Auto:
            gripPositionY =
              productLineProperties.SwingFlexDoorGripDefaultPosition -
              gripHeight / 2;
            break;
          case Enums.SwingFlexGripPosition.Top:
            gripPositionY =
              doorItem.topY -
              productLineProperties.SwingFlexDoorGripSpace -
              gripHeight;
            break;
          case Enums.SwingFlexGripPosition.Middle:
            gripPositionY =
              doorItem.bottomY + doorItem.Height / 2 - gripHeight / 2;
            break;
          case Enums.SwingFlexGripPosition.Bottom:
            gripPositionY =
              doorItem.bottomY + productLineProperties.SwingFlexDoorGripSpace;
            break;

          default:
            return;
        }

        let itemX = gripPositionX;
        let itemY = gripPositionY;
        let itemZ = doorItem.frontZ;

        let gripItem = this.createGripItem(
          swingFlex,
          subArea.doorGrip,
          subArea.doorGripMaterial,
          { X: itemX, Y: itemY, Z: itemZ },
        );
        if (rotate) {
          gripItem.rotation = leftSideOfDoor
            ? Enums.ItemRotation.CounterClockwise90DegreesZ
            : Enums.ItemRotation.Clockwise90DegreesZ;
        }
        area.addGripItem(gripItem);
        doorItem.gripProduct = subArea.doorGrip;
      };

      const gableOverlap = productLineProperties.SwingFlexDoorSideOverlap;
      const width = area.insideWidth + 2 * gableOverlap;

      const offsetTop = productLineProperties.SwingFlexTopShelfOverlap;

      area.doorCount = 0;

      for (let i = 0; i < area.subAreas.length; i++) {
        const subArea = area.subAreas[i];
        subArea.doorItems = [];

        const firstSubArea: boolean = i === 0;
        const lastSubArea: boolean = i === area.subAreas.length - 1;

        // Calculate position and height
        let offsetBottom = subArea.position;
        let height = subArea.height;
        if (firstSubArea) {
          height +=
            plinthOffset + productLineProperties.SwingFlexBottomShelfOverlap;
          offsetBottom -=
            productLineProperties.SwingFlexBottomShelfOverlap + plinthOffset;
        } else {
          if (subArea.hasDrawerBelow) {
            height -= productLineProperties.SwingFlexDoorGap;
            offsetBottom += productLineProperties.SwingFlexDoorGap;
          } else {
            height += productLineProperties.SwingFlexMovableShelfTopOverlap;
            offsetBottom -=
              productLineProperties.SwingFlexMovableShelfTopOverlap;
          }
        }
        if (lastSubArea) {
          height += offsetTop;
        } else {
          if (subArea.hasDrawerAbove) {
            height -= productLineProperties.SwingFlexDoorGap;
          } else {
            height += productLineProperties.SwingFlexMovableShelfBottomOverlap;
          }
        }

        subArea.isDoorPossible = false;
        const doorMaterial = subArea.doorMaterial;

        // Try to fit a single door
        const door = swingFlex.doorProducts.firstOrDefault((d) =>
          ProductHelper.supportsDimensions(
            d,
            height,
            width,
            null,
            doorMaterial ?? undefined,
          ),
        );
        if (door) {
          const doorItem = this.createDoorItem(
            swingFlex,
            door,
            doorMaterial,
            subArea,
            height,
            width,
            -gableOverlap,
            offsetBottom,
          );

          let hinges = tryCreateHinges(
            doorItem,
            subArea.actualHingeType,
            area.hingeSide !== Enums.HingeSide.Right,
            firstSubArea,
          );
          if (hinges.length > 0) {
            subArea.isDoorPossible = true;
            if (subArea.hasDoor) {
              area.addItem(doorItem);
              subArea.doorItems.push(doorItem);
              addHinges(
                doorItem,
                hinges,
                area.hingeSide !== Enums.HingeSide.Right,
              );
              addDoorGrips(
                doorItem,
                subArea,
                area.hingeSide === Enums.HingeSide.Right,
              );
              area.doorCount = 1;
            }
          }
        }
        // If a single door does not fit, try
        else {
          const halfDoorWidth =
            (area.insideWidth - productLineProperties.SwingFlexDoorGap) / 2;

          let leftDoorItem;
          let rightDoorItem;

          // Left door
          const widthLeft = halfDoorWidth + gableOverlap;
          const leftDoor = swingFlex.doorProducts.firstOrDefault((d) =>
            ProductHelper.supportsDimensions(
              d,
              height,
              widthLeft,
              null,
              doorMaterial ?? undefined,
            ),
          );
          if (leftDoor) {
            leftDoorItem = this.createDoorItem(
              swingFlex,
              leftDoor,
              doorMaterial,
              subArea,
              height,
              widthLeft,
              -gableOverlap,
              offsetBottom,
            );
          }

          // Right door
          const widthRight = halfDoorWidth + gableOverlap;
          const rightDoor = swingFlex.doorProducts.firstOrDefault((d) =>
            ProductHelper.supportsDimensions(
              d,
              height,
              widthRight,
              null,
              doorMaterial ?? undefined,
            ),
          );
          if (rightDoor) {
            rightDoorItem = this.createDoorItem(
              swingFlex,
              rightDoor,
              doorMaterial,
              subArea,
              height,
              widthRight,
              halfDoorWidth + productLineProperties.SwingFlexDoorGap,
              offsetBottom,
            );
          }

          // We need both doors to be valid
          if (!!leftDoorItem && !!rightDoorItem) {
            let leftHinges = tryCreateHinges(
              leftDoorItem,
              subArea.actualHingeTypeLeft,
              true,
              firstSubArea,
            );
            let rightHinges = tryCreateHinges(
              rightDoorItem,
              subArea.actualHingeTypeRight,
              false,
              firstSubArea,
            );

            // If hinges are also valid, we add the doors and grips.
            if (leftHinges.length > 0 && rightHinges.length > 0) {
              subArea.isDoorPossible = true;
              if (subArea.hasDoor) {
                area.addItem(leftDoorItem);
                subArea.doorItems.push(leftDoorItem);
                addHinges(leftDoorItem, leftHinges, true);
                addDoorGrips(leftDoorItem, subArea, false);
                area.addItem(rightDoorItem);
                subArea.doorItems.push(rightDoorItem);
                addHinges(rightDoorItem, rightHinges, false);
                addDoorGrips(rightDoorItem, subArea, true);
                area.doorCount = 2;
              }
            }
          }
        }
      }

      //#endregion

      //#region Drawer grips

      for (let drawer of area.areaSplitItems.filter((item) => item.isPullout)) {
        const gripItem = this.configurationItemService.createDrawerGripItem(
          drawer,
          Interface_Enums.ItemType.SwingFlexCorpusMovable,
        );
        if (!gripItem) continue;
        area.addGripItem(gripItem);
      }

      //#endregion

      //#region Backing

      let backingHeight = gableHeight;
      let backingWidth =
        area.insideWidth +
        2 * productLineProperties.SwingFlexBackingGableOverlap;

      let backingProduct = swingFlex.backingProducts.firstOrDefault((p) =>
        ProductHelper.supportsDimensions(p, backingHeight, backingWidth, null),
      );

      if (backingProduct) {
        const backingItem = this.createBackingItem(swingFlex, backingProduct);
        if (!!backingItem) {
          // Set dimensions
          let backingDepth = ProductHelper.defaultDepth(
            backingProduct,
            swingFlex.cabinetSection.cabinet.ProductLineId,
          );
          backingItem.Height = backingHeight;
          backingItem.Width = backingWidth;
          backingItem.Depth = backingDepth;

          // Set position
          backingItem.X =
            area.insideRect.X -
            productLineProperties.SwingFlexBackingGableOverlap;
          backingItem.Y = this.getGablePositionY(swingFlex);
          backingItem.Z =
            productLineProperties.SwingFlexSpaceForBacking - backingDepth;

          area.addItem(backingItem);

          // Fitting (T-list) for 3D
          if (!!gableNeedingTList) {
            let depth = backingDepth + Constants.BackingFittingExtraDepth;
            let fittingCube = {
              X: gableNeedingTList.X,
              Y: backingItem.Y,
              Z: gableNeedingTList.Z - depth,
              Height: backingItem.Height,
              Width: gableNeedingTList.Width,
              Depth: depth,
            };

            swingFlex.cabinetSection.backing.fittingCubes.push(fittingCube);
          }
        }
      }

      //#endregion
    }
  }

  private getGripDimensions(
    product: Client.Product,
    material?: Client.Material,
    rotate?: boolean,
  ): Vec2d {
    let height = ProductHelper.defaultHeight(product, material ?? undefined);
    if (height <= 0) height = 50;
    let width = ProductHelper.defaultWidth(product, material ?? undefined);
    if (width <= 0) width = 50;
    return { X: rotate ? height : width, Y: rotate ? width : height };
  }

  /**
   * Creates a ConfidurationItem for a gable, with dimensions and position set.
   * Returns null if the product does not exist.
   */
  private createGableItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    area: Client.SwingFlexArea,
    options: {
      // named options
      depth: number;
      right: boolean;
      usePlinth: boolean;
      addTList: boolean;
    },
  ): Client.ConfigurationItem | null {
    // Create item
    let gableItem = this.createCorpusConfigurationItem(swingFlex, product.Id);

    // Set dimensions
    let height = swingFlex.getGableHeight();
    gableItem.Height = height;
    gableItem.Width = ProductHelper.defaultWidth(product);
    gableItem.Depth = options.depth;

    // Set position
    gableItem.X = options.right
      ? area.insideRect.topRight.X
      : area.insideRect.X - gableItem.Width;
    gableItem.Y = this.getGablePositionY(swingFlex);
    gableItem.Z = swingFlex.depth - options.depth;

    // additional variants
    var variantsToSet: [string, string][] = [
      [
        VariantNumbers.Plinth,
        options.usePlinth
          ? VariantNumbers.Values.Yes
          : VariantNumbers.Values.No,
      ],
      [
        VariantNumbers.TList,
        options.addTList ? VariantNumbers.Values.Yes : VariantNumbers.Values.No,
      ],
      [VariantNumbers.EdgeBandBehind, VariantNumbers.Values.No],
      [VariantNumbers.EdgeBandTopBottom, VariantNumbers.Values.No],
    ];
    for (let [variant, option] of variantsToSet) {
      ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
        gableItem,
        variant,
        option,
      );
    }

    return gableItem;
  }

  /**
   * Creates a ConfidurationItem for a middle panel, with dimensions and position set.
   */
  private createMiddlePanelItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    subArea: Client.SwingFlexSubArea,
    depth: number,
  ): Client.ConfigurationItem | null {
    const productLineProperties = swingFlex.productLineProperties;

    // Create item
    let gableItem = this.createCorpusConfigurationItem(swingFlex, product.Id);

    // Set dimensions
    gableItem.Height =
      subArea.middlePanelHeight ?? ProductHelper.defaultHeight(product);
    gableItem.Width = ProductHelper.defaultWidth(product);
    gableItem.Depth = depth;

    // Set position
    gableItem.X = subArea.insideRect.X + (subArea.middlePanelPostion ?? 0);
    gableItem.Y = this.getGablePositionY(swingFlex);
    gableItem.Z = productLineProperties.SwingFlexSpaceForBacking;

    return gableItem;
  }

  /**
   * Creates a ConfidurationItem for a corpus shelf, with dimensions and position set.
   * Returns null if the product does not exist.
   */
  private createCorpusShelfItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    area: Client.SwingFlexArea,
    depth: number,
    positionY: number,
    width?: number,
    offsetX?: number,
  ): Client.ConfigurationItem | null {
    const productLineProperties = swingFlex.productLineProperties;

    // Create item
    let shelfItem = this.createCorpusConfigurationItem(swingFlex, product.Id);

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    shelfItem.ActualHeight = height;
    shelfItem.Height = height;
    shelfItem.Width = width ?? area.insideRect.Width;
    shelfItem.Depth = depth;

    // Set position
    shelfItem.X = area.insideRect.X + (offsetX ?? 0);
    shelfItem.Y = positionY;
    shelfItem.Z = productLineProperties.SwingFlexSpaceForBacking;

    return shelfItem;
  }

  private createPlinthItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    area: Client.SwingFlexArea,
    width?: number,
    offsetX?: number,
  ) {
    const productLineProperties = swingFlex.productLineProperties;

    let section: Client.CabinetSection = swingFlex.cabinetSection;

    // Create item
    const plinthItem = this.createCorpusConfigurationItem(
      swingFlex,
      product.Id,
    );

    // Set dimensions
    const height = ProductHelper.defaultHeight(product);
    const depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );
    plinthItem.ActualHeight = height;
    plinthItem.Height = height;
    plinthItem.Width = width ?? area.insideRect.Width;
    plinthItem.Depth = depth;

    // Set position
    plinthItem.X = area.insideRect.X + (offsetX ?? 0);
    plinthItem.Y = this.getGablePositionY(swingFlex);
    plinthItem.Z =
      swingFlex.depth -
      (productLineProperties.SwingFlexPlinthFrontOffset + depth);

    return plinthItem;
  }

  /**
   * Creates a corpus item for a specified ProductId
   */
  private createCorpusConfigurationItem(
    swingFlex: Client.SwingFlex,
    productId: number,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingFlexCorpus,
      productId,
      swingFlex.corpusMaterial?.Id ?? null,
      swingFlex.cabinetSection,
    );
  }

  /**
   * Creates a ConfidurationItem for a door, with dimensions and position set.
   */
  private createDoorItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    material: Client.Material | null,
    subArea: Client.SwingFlexSubArea,
    height: number,
    width: number,
    offsetX: number,
    offsetY: number,
  ) {
    const area = subArea.parentArea;
    const section = swingFlex.cabinetSection;

    // Create item
    const doorItem = this.createDoorConfigurationItem(
      section,
      material,
      product.Id,
    );

    doorItem.Height = height;
    doorItem.Width = width ?? area.insideRect.Width;
    doorItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    doorItem.X = area.insideRect.X + offsetX;
    doorItem.Y = area.insideRect.Y + offsetY;
    doorItem.Z =
      swingFlex.depth +
      swingFlex.productLineProperties.SwingFlexSpaceBehindDoors;

    //opening method
    const openingMethodVariantOption = (() => {
      switch (subArea.openingMethod) {
        case Enums.SwingFlexOpeningMethod.Grip:
          return !!subArea.doorGrip
            ? VariantNumbers.Values.DoorOpeningMethod_Grip
            : VariantNumbers.Values.DoorOpeningMethod_None;
        case Enums.SwingFlexOpeningMethod.PushToOpen:
          return VariantNumbers.Values.DoorOpeningMethod_PushToOpen;
        default:
          return undefined;
      }
    })();
    if (openingMethodVariantOption) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        doorItem,
        VariantNumbers.DoorOpeningMethod,
        openingMethodVariantOption,
      );
    }

    return doorItem;
  }

  /**
   * Creates a door item for a specified ProductId
   */
  private createDoorConfigurationItem(
    section: Client.CabinetSection,
    material: Client.Material | null,
    productId: number,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingFlexDoor,
      productId,
      material?.Id ?? null,
      section,
    );
  }

  private createGripItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
    material: Client.Material | null,
    position: Vec3d,
  ): Client.ConfigurationItem {
    let section: Client.CabinetSection = swingFlex.cabinetSection;

    // Create item
    const gripItem = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingFlexDoor,
      product.Id,
      material?.Id ?? null,
      section,
    );

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    let width = ProductHelper.defaultWidth(product);
    let depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );
    gripItem.ActualHeight = height;
    gripItem.Height = height;
    gripItem.Width = width;
    gripItem.Depth = depth;

    // Set position
    gripItem.X = position.X;
    gripItem.Y = position.Y;
    gripItem.Z = position.Z;

    return gripItem;
  }

  /**
   * Creates a backing item for the specified product
   * @param swingFlex
   * @param product
   * @returns
   */
  private createBackingItem(
    swingFlex: Client.SwingFlex,
    product: Client.Product,
  ) {
    let section: Client.CabinetSection = swingFlex.cabinetSection;

    const backingItem = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingFlexBacking,
      product.Id,
      swingFlex.backingMaterial?.Id ?? null,
      section,
    );

    return backingItem;
  }

  //#endregion

  // Helpers
  //#region

  private getGablePositionY(swingFlex: Client.SwingFlex): number {
    let posY = swingFlex.cabinetSection.corpus.heightBottom;
    if (posY <= 0)
      posY += swingFlex.productLineProperties.SwingFlexBottomOffset;
    return posY;
  }

  private getMiddlePanelDimensionConstraints(swingFlex: Client.SwingFlex): {
    min: number;
    max: number;
  } {
    const productLineProperties = swingFlex.productLineProperties;

    const innerGableDepth =
      swingFlex.depth - productLineProperties.SwingFlexSpaceForBacking;

    const middlePanels = swingFlex.corpusProducts.where(
      (p) =>
        p.ProductGroupingNo ===
          productLineProperties.SwingFlexMiddlePanelProductGroupingNumber &&
        ProductHelper.supportsDepth(p, innerGableDepth),
    );

    if (middlePanels.count() <= 0) {
      return { min: 0, max: 0 };
    }

    const max = middlePanels.select((p) => ProductHelper.maxHeight(p)).max();
    const min = middlePanels.select((p) => ProductHelper.minHeight(p)).min();

    return { min, max };
  }

  /**
   * Finds all interior items that are positioned inside a given area.
   * @param section
   * @param area
   */
  public static getInteriorItemsInArea(
    section: Client.CabinetSection,
    area: Client.SwingFlexArea,
  ): Client.ConfigurationItem[] {
    let items = Enumerable.from(section.interior.items).where(
      (i) =>
        i.centerX >= area.insideRect.X &&
        i.centerX <= area.insideRect.topRight.X,
    );
    return items.toArray();
  }

  /**
   * Finds all interior items that are positioned inside a given sub area.
   * @param section
   * @param area
   */
  public static getInteriorItemsInSubArea(
    section: Client.CabinetSection,
    area: Client.SwingFlexSubArea,
  ): Client.ConfigurationItem[] {
    let items = Enumerable.from(section.interior.items).where(
      (i) =>
        i.centerY > area.insideRect.Y &&
        i.centerY < area.insideRect.Y + area.insideRect.Height &&
        i.centerX >= area.insideRect.X &&
        i.centerX <= area.insideRect.topRight.X,
    );
    return items.toArray();
  }

  private getVerticalSpaceForMiddlePanel(
    area: Client.SwingFlexArea,
    swingFlex: Client.SwingFlex,
  ) {
    let maxHeight = area.insideRect.TopY;
    const areaCenterX = area.insideRect.X + area.insideRect.Width / 2;
    const fullWidthMovableItems = area.areaSplitItems.filter(
      (item) =>
        item.X < areaCenterX &&
        item.rightX > areaCenterX &&
        item.Width === area.insideRect.Width,
    );
    if (fullWidthMovableItems.length > 0) {
      const minY = Enumerable.from(fullWidthMovableItems).min(
        (item) => item.bottomY,
      );
      maxHeight = Math.min(area.insideHeight, minY);
    }
    return maxHeight - this.getGablePositionY(swingFlex);
  }

  //#endregion

  // Cleanup
  //#region

  private updateAreaIndexes(swingFlex: Client.SwingFlex) {
    swingFlex.areas.sort((a, b) => {
      return a.index - b.index;
    });
    swingFlex.areas.forEach((a) => (a.index = swingFlex!.areas.indexOf(a)));
  }

  private updateAreaHingeTypes(swingFlex: Client.SwingFlex) {
    for (let i = 0; i < swingFlex.areas.length; i++) {
      let area = swingFlex.areas[i];

      if (area.subAreas) {
        Enumerable.from(area.subAreas).forEach((subArea) => {
          const interiorItems = SwingFlexLogic.getInteriorItemsInSubArea(
            swingFlex.cabinetSection,
            subArea,
          );

          // Left
          if (
            subArea.desiredHingeTypeLeft === Enums.SwingFlexHingeType.Special
          ) {
            // User requested special hinges
            subArea.actualHingeTypeLeft = Enums.SwingFlexHingeType.Special;
          } else if (
            Enumerable.from(interiorItems).any(
              (c) =>
                c.isPullout &&
                !c.snapDepthMiddle &&
                Math.abs(c.leftX - subArea.insideRect.X) < 30,
            )
          ) {
            // Special hinges are required because of pullout items
            subArea.actualHingeTypeLeft = Enums.SwingFlexHingeType.Special;
          } else {
            // Special hinges are neither required nor requested, so standard hinges are used
            subArea.actualHingeTypeLeft = Enums.SwingFlexHingeType.Standard;
          }

          // Right
          if (
            subArea.desiredHingeTypeRight === Enums.SwingFlexHingeType.Special
          ) {
            // User requested special hinges
            subArea.actualHingeTypeRight = Enums.SwingFlexHingeType.Special;
          } else if (
            Enumerable.from(interiorItems).any(
              (c) =>
                c.isPullout &&
                !c.snapDepthMiddle &&
                Math.abs(
                  c.rightX - (subArea.insideRect.X + subArea.insideRect.Width),
                ) < 30,
            )
          ) {
            // Special hinges are required because of pullout items
            subArea.actualHingeTypeRight = Enums.SwingFlexHingeType.Special;
          } else {
            // Special hinges are neither required nor requested, so standard hinges are used
            subArea.actualHingeTypeRight = Enums.SwingFlexHingeType.Standard;
          }
        });
      }
    }
  }

  private updateAreaPossibleHingeSides(swingFlex: Client.SwingFlex) {
    for (let i = 0; i < swingFlex.areas.length; i++) {
      let area = swingFlex.areas[i];

      if (area.doorCount > 1) {
        area.possibleHingeSides = [Enums.HingeSide.Both];
      } else if (area.doorCount === 1) {
        area.possibleHingeSides = [Enums.HingeSide.Left, Enums.HingeSide.Right];
      } else {
        area.possibleHingeSides = [Enums.HingeSide.None];
      }

      if (!area.possibleHingeSides.includes(area.hingeSide)) {
        area.hingeSide = area.possibleHingeSides[0];
      }
    }

    for (const subArea of swingFlex.subAreas) {
      this.updateSubAreaMiddlePanelChoices(subArea, swingFlex);
    }
  }

  /**
   * Updates possible middle panel choices for a sub area.
   */
  private updateSubAreaMiddlePanelChoices(
    subArea: Client.SwingFlexSubArea,
    swingFlex: Client.SwingFlex,
  ) {
    const verticalSpace = this.getVerticalSpaceForMiddlePanel(
      subArea.parentArea,
      swingFlex,
    );

    const minPanelHeight = swingFlex.corpusProducts
      .where(
        (p) =>
          p.ProductGroupingNo ===
          swingFlex.productLineProperties
            .SwingFlexMiddlePanelProductGroupingNumber,
      )
      .min((mp) =>
        ProductHelper.minHeight(mp, swingFlex.corpusMaterial ?? undefined),
      );

    const middlePanelPossible =
      verticalSpace >= minPanelHeight &&
      !subArea.hasDrawerBelow &&
      subArea.parentArea.doorCount > 1 &&
      subArea.parentArea.subAreas.indexOf(subArea) == 0;

    subArea.isMiddlePanelPossible = middlePanelPossible;
    if (!subArea.isMiddlePanelPossible) {
      if (subArea.hasMiddlePanel) {
        this.notificationService.warning(
          'swingflex_middlepanel_notification',
          'Middle panel has been changed. Please note that the change may affect inventory',
        );
      }

      subArea.hasMiddlePanel = false;
      subArea.middlePanelPostion = null;
      subArea.middlePanelHeight = null;
    }
  }

  //#endregion
}
