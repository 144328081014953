import { Component, Input, OnInit } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-price',
  templateUrl: './consumerPrice.html',
})
export class ConsumerPriceVm extends BaseVm implements OnInit {
  //region AngularJS

  @Input()
  public floorPlan!: Client.FloorPlan;
  //endregion

  public price: string = '';
  public currency: string = '';
  public showPrice: boolean = false;
  private numberFormat: Intl.NumberFormatOptions = { maximumFractionDigits: 0 };

  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.subscribeTo(this.floorPlan.pieceList$, (pl) => {
      if (pl && pl.PriceWithVat > 0) {
        this.showPrice = true;
        this.currency = pl.Currency;
        this.price = pl.PriceWithVat.toLocaleString(
          undefined,
          this.numberFormat,
        );
      } else {
        this.showPrice = false;
      }
    });
  }
}
