<div class="row">
  <div class="col">
    <h4 class="right-menu-header" [textContent]="sectionHeader"></h4>
  </div>
</div>

<!-- Section Width -->
<div class="row">
  <label
    for="width"
    class="col col-form-label pb-0"
    [textContent]="'floorplan_label_section_width' | translate: 'Width'"
  >
  </label>
</div>
<div class="row">
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #sectionWidthInput
        type="number"
        class="form-control"
        [value]="round(section.width)"
        (change)="widthChange.emit({ width: sectionWidthInput.valueAsNumber })"
        id="width"
        [disabled]="!isWidthChangeAllowed"
      />
      <span class="input-group-text mm-addon"></span>
    </div>
    <p
      class="input-hint text-secondary mb-0"
      [textContent]="
        'floorplan_label_section_full_width_{0}'
          | translate: 'Including Joints: {0}' : round(fullSectionWidth)
      "
    ></p>
  </div>
</div>

<!-- Dist to Walls -->
<div class="row mt-3">
  <label
    class="col col-form-label pb-0"
    [textContent]="
      'floorplan_label_wall_distance' | translate: 'Distance to walls'
    "
  ></label>
</div>
<div class="row">
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #leftPosition
        type="number"
        class="form-control distance-spinner"
        [value]="round(getDistanceFromLeftWall())"
        (change)="distanceToLeftWallChange.emit(leftPosition.valueAsNumber)"
        [disabled]="!isDistToLeftWallChangeAllowed"
      />
      <span class="input-group-text">
        <i class="bi bi-arrow-bar-right"></i>
      </span>
    </div>
  </div>
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #rightPosition
        type="number"
        class="form-control distance-spinner"
        [value]="round(getDistanceFromRightWall())"
        (change)="distanceToRightWallChange.emit(rightPosition.valueAsNumber)"
        [disabled]="!isDistToRightWallChangeAllowed"
      />
      <span class="input-group-text">
        <i class="bi bi-arrow-bar-left"></i>
      </span>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #topPosition
        type="number"
        class="form-control distance-spinner"
        [value]="round(getDistanceFromTopWall())"
        (change)="distanceToTopWallChange.emit(topPosition.valueAsNumber)"
        [disabled]="!isDistToTopWallChangeAllowed"
      />
      <span class="input-group-text">
        <i class="bi bi-arrow-bar-down"></i>
      </span>
    </div>
  </div>
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #bottomPosition
        type="number"
        class="form-control distance-spinner"
        [value]="round(getDistanceFromBottomWall())"
        (change)="distanceToBottomWallChange.emit(bottomPosition.valueAsNumber)"
        [disabled]="!isDistToBottomWallChangeAllowed"
      />
      <span class="input-group-text">
        <i class="bi bi-arrow-bar-up"></i>
      </span>
    </div>
  </div>
</div>

<!-- Number of Modules -->
<div class="row mt-3">
  <label
    for="number_of_modules"
    class="col col-form-label pb-0"
    [textContent]="'number-of-modules' | translate: 'Number of modules'"
  ></label>
</div>
<div class="row pb-3">
  <div class="col">
    <div class="input-group input-group-sm">
      <input
        #sectionModuleCountInput
        type="number"
        class="form-control"
        [value]="numberOfModules"
        (change)="numberOfModulesOut = sectionModuleCountInput.valueAsNumber"
        [min]="partitionQueryService.getSectionMinNumberOfModules(section.id)"
        [max]="partitionQueryService.getSectionMaxNumberOfModules(section.id)"
        id="number_of_modules"
      />
    </div>
  </div>
</div>

<!-- Rotation and Reverse-->
<div class="row pb-3">
  <div class="col" style="display: flex; flex-direction: column">
    <!-- Label -->
    <span
      [textContent]="'floorplan_rotate_header' | translate: 'Rotate'"
    ></span>

    <!-- Buttons -->
    <div class="btn-group" role="group" style="isolation: isolate">
      <!-- Rotate Left -->
      <button
        type="button"
        class="btn btn-default"
        (click)="rotateSectionLeft.emit()"
        [disabled]="isLeftRotateDisabled"
      >
        <i class="bi bi-arrow-counterclockwise"></i>
      </button>

      <!-- Reverse -->
      <button
        type="button"
        class="btn btn-default"
        [textContent]="'reverse' | translate: 'Reverse'"
        (click)="reverseSection.emit()"
      ></button>

      <!-- Rotate Right -->
      <button
        type="button"
        class="btn btn-default"
        (click)="rotateSectionRight.emit()"
        [disabled]="isRightRotateDisabled"
      >
        <i class="bi bi-arrow-clockwise"></i>
      </button>
    </div>
  </div>
</div>

<!-- Uneven walls -->
<div class="row" [hidden]="!isUnevenLeftWallAllowed">
  <div class="col">
    <div class="form-check">
      <input
        #unevenLeftInp
        type="checkbox"
        class="form-check-input"
        id="rough_left_wall"
        [checked]="unevenLeftWall"
        (change)="
          unevenLeftWall = unevenLeftInp.checked;
          unevenLeftWallChange.emit(unevenLeftInp.checked)
        "
      />
      <label
        class="form-check-label"
        for="rough_left_wall"
        [textContent]="'uneven-left-wall' | translate: 'Uneven left wall'"
      ></label>
    </div>
  </div>
</div>
<div class="row pb-1" [hidden]="!isUnevenRightWallAllowed">
  <div class="col">
    <div class="form-check">
      <input
        #unevenRightInp
        type="checkbox"
        class="form-check-input"
        id="rough_right_wall"
        [checked]="unevenRightWall"
        (change)="
          unevenRightWall = unevenRightInp.checked;
          unevenRightWallChange.emit(unevenRightInp.checked)
        "
      />
      <label
        class="form-check-label"
        for="rough_right_wall"
        [textContent]="'uneven-right-wall' | translate: 'Uneven right wall'"
      ></label>
    </div>
  </div>
</div>

<!-- Snap to both walls -->
<div class="row pb-1" *ngIf="allowSnapToBothWalls">
  <div class="col">
    <button
      class="btn btn-default"
      [textContent]="
        'btn_label_snap_to_walls' | translate: 'Snap To Both Walls'
      "
      (click)="SnapToBothWalls()"
    ></button>
  </div>
</div>

<!-- Snap Partition Twice -->
<div class="row pb-1" *ngIf="allowSnapPartitionTwice">
  <div class="col">
    <button
      class="btn btn-default"
      [textContent]="
        'btn_label_snap_partition_twice' | translate: 'Snap Partition Twice'
      "
      (click)="SnapPartitionTwice()"
    ></button>
  </div>
</div>

<!-- Delete -->
<div class="row">
  <div class="col">
    <button
      class="btn btn-danger btn-semi-block"
      [textContent]="'floorPlan_delete_item_button' | translate: 'Delete'"
      (click)="requestDelete()"
    ></button>
  </div>
</div>

<hr />
