import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class RailSetHelper {
  public static minimumDepth(
    railSet: Client.RailSet,
    productLineId: Interface_Enums.ProductLineId,
    material?: Interface_DTO.Material,
  ): number {
    if (
      !railSet.topProduct ||
      !railSet.topProduct ||
      !railSet.bottomProduct ||
      !railSet.bottomProduct
    )
      return 0;

    let topMinDepth = ProductHelper.minDepth(
      railSet.topProduct,
      productLineId,
      material,
    );
    let bottomMinDepth = ProductHelper.minDepth(
      railSet.bottomProduct,
      productLineId,
      material,
    );
    return Math.max(topMinDepth, bottomMinDepth);
  }

  public static maximumDepth(
    railSet: Client.RailSet,
    productLineId: Interface_Enums.ProductLineId,
    material?: Interface_DTO.Material,
  ): number {
    if (
      !railSet.topProduct ||
      !railSet.topProduct ||
      !railSet.bottomProduct ||
      !railSet.bottomProduct
    )
      return 0;

    let topMaxDepth = ProductHelper.maxDepth(
      railSet.topProduct,
      productLineId,
      material,
    );
    let bottomMaxDepth = ProductHelper.maxDepth(
      railSet.bottomProduct,
      productLineId,
      material,
    );
    return Math.max(topMaxDepth, bottomMaxDepth);
  }

  public static heightTop(railSet: Client.RailSet): number {
    if (!railSet.topProduct || !railSet.topProduct) return 0;
    return ProductHelper.defaultHeight(railSet.topProduct);
  }

  public static heightBottom(railSet: Client.RailSet): number {
    if (!railSet.bottomProduct || !railSet.bottomProduct) return 0;
    return ProductHelper.defaultHeight(railSet.bottomProduct);
  }

  /**
   * Calculates the distance from the front of the rail to the center of the specified track
   * @param railSet
   * @param trackNumber
   */
  public static railTrackCenter(
    railSet: Client.RailSet,
    trackNumber: number,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    if (trackNumber < 1 || trackNumber > railSet.NumberOfTracks) {
      return 0;
    }

    let totalDepth = this.maximumDepth(railSet, productLineId);
    let trackDepth = totalDepth / railSet.NumberOfTracks;
    return trackDepth * (trackNumber - 0.5);
  }

  public static getPickable(railSet: Client.RailSet): Pickable<Client.RailSet> {
    let p = railSet.bottomProduct || railSet.topProduct;
    let imgUrl = p ? ProductHelper.picturePath(p) : undefined;
    return {
      name: p ? p.Name : railSet.DefaultName,
      id: p ? p.ProductNo : '00',
      item: railSet,
      disabledReason: null,
      isSelected: false,
      override: false,
      imageUrl: imgUrl,
    };
  }
}
