<div class="row">
  <div class="col">
    <h4 class="right-menu-header" [textContent]="sectionHeader"></h4>
  </div>
</div>
<div class="row">
  <label
    for="name"
    class="col col-form-label pb-0"
    [textContent]="'floorplan_label_cabinet_name' | translate: 'Name'"
  ></label>
</div>
<div class="row pb-3">
  <div class="col">
    <input
      type="text"
      class="form-control"
      name="name"
      id="name"
      [(ngModel)]="name"
      [ngModelOptions]="modelOptions"
    />
  </div>
</div>
<div class="row">
  <div class="col">
    <button
      class="btn btn-danger btn-semi-block"
      [textContent]="'floorPlan_delete_item_button' | translate: 'Delete'"
      (click)="requestDelete()"
    ></button>
  </div>
</div>

<hr />
