import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ConsumerOrderService } from 'app/ts/services/ConsumerOrderService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { IConsumerPage } from 'app/ts/viewmodels/consumer/pages/IConsumerPage';
import { CabinetSectionService } from './cabinet-section.service';

@Component({
  selector: 'basket-vm',
  templateUrl: './basket.html',
  styleUrls: ['basket.scss'],
})
export class BasketVm extends BaseVm implements IConsumerPage {
  private cache = new Client.Cache<{}>();

  private cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    cabinetSections: CabinetSectionService,
    private readonly floorPlanService: FloorPlanService,
    private readonly floorPlanSaveService: FloorPlanSaveService,
    private readonly loginService: LoginService,
    private readonly modalService: ModalService,
    private readonly consumerOrderService: ConsumerOrderService,
    private readonly notificationService: NotificationService,
    private routing: AppRoutingModule,
  ) {
    super(baseVmService);
    cabinetSections.cabinetSection$
      .pipe(takeUntilDestroyed())
      .subscribe((cabinetSection) => {
        this.cabinetSection = cabinetSection;
        this.subscribeTo(this.floorPlan.floorPlan$, (fp) => this.cache.clear());
      });
  }

  public get floorPlan() {
    return this.cabinetSection.cabinet.floorPlan;
  }

  //region save

  public async save() {
    if (!(await this.tryEnsureLoggedIn())) return;
    let suggestedName = this.translate(
      'consumer_basket_save_suggested_name',
      'My new cabinet',
    );
    let name = await this.modalService.consumerSaveFloorPlan(suggestedName);
    if (!name) {
      return;
    }
    this.floorPlan.Name = name;
    this.floorPlanSaveService.saveFloorPlan(this.floorPlan, {
      deliveryInfoAcceptor: (di, rdw) => Promise.resolve(true),
      deliveryInfoEditor: (di) =>
        Promise.resolve<Client.DeliveryInfoChanges>({
          ...di,
          Name: name!,
          InternalNote: '',
          UserId: -1,
        }),
      showNotifications: false,
    });
  }

  //endregion

  //region order

  public async order() {
    // TODO: This should go the the chains basket address
    this.routing.Consumer_address();
  }

  //endregion

  public async addToBasket() {
    let responsePromise = this.consumerOrderService.addToBasket(this.floorPlan);

    try {
      let response =
        await this.notificationService.loadPromise(responsePromise);
      window.location.href = response.RedirectUrl;
    } catch (ex) {
      this.notificationService.exceptionWithDefaultText(ex);
      //if (e instanceof Interface.Exception.DomainErrorException) {

      //}
      //this.notificationService.systemError("add_to_basket_failed", "Oops, Could not add your solution to the basket. Try again, or save the solution for later.")
    }
  }

  private async tryEnsureLoggedIn(): Promise<boolean> {
    if (this.loginService.consumerUser) return true;
    let loginSuccess = await this.modalService.consumerLogin();
    return loginSuccess;
  }

  private setChanged() {
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public next() {
    //Do nothing
  }
  public previous() {
    this.routing.Consumer_interior();
  }
}
