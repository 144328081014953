import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IdentityAndAccessModule } from 'app/identity-and-access/identity-and-access.module';
import { LoginService } from '@Services/LoginService';
import { DarkSideBarVm } from './DarkSideBarVm';

@NgModule({
  declarations: [DarkSideBarVm],
  imports: [BrowserModule, FormsModule, RouterModule, IdentityAndAccessModule],
  exports: [DarkSideBarVm],
  providers: [LoginService],
})
export class DarkSideBarModule {}
