<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close" (click)="cancel()">&times;</button>
    <h3
      class="modal-title"
      [textContent]="translate('modal_password_reset_title', 'Reset Password')"
    ></h3>
  </div>
  <div class="modal-body">
    <form class="form-like" (submit)="requestResetMail()">
      <div class="input-group input-group-sm">
        <span
          class="input-group-text"
          [textContent]="translate('password_reset_request_email_addon', 'Email')"
        ></span>
        <input
          #usernameInput
          type="email"
          class="form-control"
          tabindex="1"
          autofocus
          [value]="username"
          (input)="username = usernameInput.value"
          [attr.placeholder]="translate('password_reset_request_email_placeholder', 'Email')"
        />
      </div>
    </form>
    <span
      [textContent]="translate('password_reset_error_email_not_found', 'Email was not found. Please check your spelling and try again')"
      style="visibility: hidden"
      [style.visibility]="showEmailNotFoundError ? 'visible' : 'hidden'"
      class="password-reset-error"
    ></span>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!resetEnabled"
        (click)="requestResetMail()"
        [textContent]="translate('password_reset_request_button_text', 'Send Email')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
