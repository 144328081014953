import { Component, Inject } from '@angular/core';
import * as ClientSetting from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { ClientSettingInjector } from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import { PickableMaterial } from 'app/ts/display/PickableMaterial';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import Enumerable from 'linq';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'corpus-vm',
  templateUrl: './corpus.html',
  styleUrls: [
    '../../style/corpus.scss',
    '../../style/editor.scss',
    '../../style/d3.scss',
  ],
  providers: [ClientSetting.clientSettingProvider, DeliveryAddressService],
})
export class CorpusVm extends EditorVm {
  public readonly corpusMaterial: Readonly<
    Pickable<Interface_DTO.Material | null>
  >;
  public readonly lightingMaterial: Readonly<
    Pickable<Interface_DTO.Material | null>
  >;

  public get d3Resizer(): Observable<void> {
    return this._d3Resizer;
  }
  private _d3Resizer = new BehaviorSubject<void>(void 0);

  private _showPanel: boolean = false;
  private _panel: CorpusVm.PanelName = 'top';

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    @Inject(ClientSettingInjector)
    private clientSettings: ClientSetting.ClientSetting,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.corpusClientSetting = settings.corpus;
      }),
    );

    this.corpusMaterial = new PickableMaterial(
      () => this.cabinetSection!.corpus.material,
      () => false,
    );

    this.lightingMaterial = new PickableMaterial(
      () => this.cabinetSection!.corpus.lightingMaterial,
      () => false,
    );

    this.floorPlanChanged();
  }

  private corpusClientSetting: ClientSetting.CorpusSetting | undefined;
  private setCorpusSetting(setting: Partial<ClientSetting.CorpusSetting>) {
    if (this.corpusClientSetting) {
      this.corpusClientSetting = {
        ...this.corpusClientSetting,
        ...setting,
      };

      const allSettings = {
        ...this.clientSettings.data,
      };

      allSettings.corpus = this.corpusClientSetting;

      this.clientSettings.set(allSettings);
    }
  }

  public getPanelName(cp?: Client.CorpusPanel): string {
    if (cp) {
      if (cp.overrideChain) {
        return this.translate(
          'corpus_panel_name_chain_override',
          '!! {0}',
          cp.DefaultName,
        );
      } else {
        return cp.DefaultName;
      }
    } else {
      return this.translate('corpus_panel_type_top_none', 'None');
    }
  }

  public get showDoors3d(): boolean {
    return this.corpusClientSetting
      ? this.corpusClientSetting.displayDoors
      : false;
  }
  public set showDoors3d(val: boolean) {
    this.setCorpusSetting({ displayDoors: val });
  }
  public get showInterior3d(): boolean {
    return this.corpusClientSetting
      ? this.corpusClientSetting.displayInterior
      : false;
  }
  public set showInterior3d(val: boolean) {
    this.setCorpusSetting({ displayInterior: val });
  }
  public get showOtherCabinets3d(): boolean {
    return this.corpusClientSetting
      ? this.corpusClientSetting.displayOtherCabinets
      : true;
  }
  public set showOtherCabinets3d(val: boolean) {
    this.setCorpusSetting({ displayOtherCabinets: val });
  }

  get showPanel() {
    return this._showPanel;
  }
  set showPanel(val: boolean) {
    let resize = val !== this._showPanel;
    this._showPanel = val;
    if (resize) {
      //wait a bit until the view has updated, then update canvas size
      window.setTimeout(() => this._d3Resizer.next(void 0), 200);
    }
  }

  get showLightSelection(): boolean {
    return this.cabinetSection!.corpus.pickableLightingProducts.length > 1; //there's always a "no lights" option
  }

  get panel() {
    return this._panel;
  }
  set panel(val: CorpusVm.PanelName) {
    this.showPanel = true;
    this._panel = val;
  }

  get heightTop(): number {
    return this.cabinetSection!.corpus.heightTop;
  }
  setHeightTop(event: any) {
    this.cabinetSection!.corpus.heightTop = event.target.valueAsNumber;
    this.setChanged();
    event.target.value = this.cabinetSection!.corpus.heightTop;
  }
  get heightTopFixed(): boolean {
    return (
      this.cabinetSection!.corpus.minHeightTop ===
      this.cabinetSection!.corpus.maxHeightTop
    );
  }

  get heightBottom(): number {
    return this.cabinetSection!.corpus.heightBottom;
  }
  set heightBottom(value: number) {
    this.cabinetSection!.corpus.heightBottom = value;
    this.setChanged();
  }
  setHeightBottom(event: any) {
    this.cabinetSection!.corpus.heightBottom = event.target.valueAsNumber;
    this.setChanged();
    event.target.value = this.cabinetSection!.corpus.heightBottom;
  }
  get heightBottomFixed(): boolean {
    return (
      this.cabinetSection!.corpus.minHeightBottom ===
      this.cabinetSection!.corpus.maxHeightBottom
    );
  }

  get widthLeft(): number {
    return this.leftCorpus.widthLeft;
  }

  setWidthLeft(event: any) {
    this.leftCorpus!.widthLeft = event.target.valueAsNumber;
    this.setChanged();
    event.target.value = this.leftCorpus!.widthLeft;
  }

  get widthLeftFixed(): boolean {
    return this.leftCorpus.minWidthLeft === this.leftCorpus.maxWidthLeft;
  }

  get widthRight(): number {
    return this.rightCorpus.widthRight;
  }

  setWidthRight(event: any) {
    this.rightCorpus!.widthRight = event.target.valueAsNumber;
    this.setChanged();
    event.target.value = this.rightCorpus!.widthRight;
  }

  get widthRightFixed(): boolean {
    return this.rightCorpus.minWidthRight === this.rightCorpus.maxWidthRight;
  }

  get cabinetSectionWidth(): number {
    return this.cabinetSection!.Width;
  }
  updateCabinetSectionWidth(event: any) {
    this.cabinetSection!.Width = event.target.valueAsNumber;
    event.target.value = this.cabinetSection!.Width;
    this.setChanged();
  }

  get cabinetSectionHeight(): number {
    return this.cabinetSection!.Height;
  }
  updateCabinetSectionHeight(event: any) {
    this.cabinetSection!.Height = event.target.valueAsNumber;
    event.target.value = this.cabinetSection!.Height;
    this.setChanged();
  }

  get cabinetSectionDepthEditable(): boolean {
    return (
      this.cabinetSection!.CabinetType !== Interface_Enums.CabinetType.Doors &&
      this.cabinetSection!.cabinet.productLine.MinDepth !==
        this.cabinetSection!.cabinet.productLine.MaxDepth
    );
  }

  get cabinetSectionDepth(): number {
    return this.cabinetSection!.Depth;
  }
  updateCabinetSectionDepth(event: any) {
    if (!this.cabinetSection) return;
    this.cabinetSection.Depth = event.target.valueAsNumber;
    this.setChanged();
    event.target.value = this.cabinetSection.Depth;
  }

  //#region Swing Flex

  updateSwingFlexDepth($event: any) {
    if (!this.cabinetSection) return;
    const swingFlex = this.cabinetSection.swingFlex;
    swingFlex.depth = $event.target.valueAsNumber;
    this.setChanged();
    $event.target.value = swingFlex.depth;
  }

  get cabinetIsSwingFlex(): boolean {
    return (
      this.cabinetSection!.CabinetType === Interface_Enums.CabinetType.SwingFlex
    );
  }

  get swingFlexDepth(): number {
    return this.cabinetSection!.swingFlex.depth;
  }

  get swingFlexMinDepth(): number | undefined {
    return this.cabinetSection!.cabinet.minDepth;
  }

  get swingFlexMaxDepth(): number | undefined {
    return this.cabinetSection!.cabinet.maxDepth;
  }

  get coverDoors(): boolean {
    return this.cabinetSection!.swingFlex.corpusCoversDoors;
  }
  set coverDoors(value: boolean) {
    this.cabinetSection!.swingFlex.corpusCoversDoors = value;
    this.setChanged();
  }

  get interiorDepth(): number {
    return this.cabinetSection!.swingFlex.getInteriorDepth();
  }

  //#endregion

  public async selectCorpusMaterial(): Promise<void> {
    let cm = this.cabinetSection!.corpus.pickableMaterials;
    let modal = this.baseVmService.modalService.getPickableSelector(
      cm,
      this.translate('material_selector_title', 'Select Material'),
      'material corpus-material',
    );
    let selection = await modal.result;
    this.cabinetSection!.corpus.material = selection;
    this.setChanged();
  }

  public async selectLightingProduct() {
    let modal = this.baseVmService.modalService.getPickableSelector(
      this.cabinetSection!.corpus.pickableLightingProducts,
      this.translate('lighting_product_selector_header', 'Lighting Product'),
      'product lighting-product',
    );
    let prod: Client.Product | null;
    try {
      prod = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.corpus.lightingProduct = prod;
    this.setChanged();
  }

  public async selectLightingMaterial() {
    let modal = this.baseVmService.modalService.getPickableSelector(
      this.cabinetSection!.corpus.pickableLightingMaterials,
      this.translate('lighting_material_selector_header', 'Lighting Material'),
      'material lighting-material',
    );
    let material: Interface_DTO.Material | null;
    try {
      material = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.corpus.lightingMaterial = material;
    this.setChanged();
  }

  public get itemVariantsTop() {
    if (!this.cache.itemVariantsTop) {
      this.cache.itemVariantsTop = this.getUserSelectableVariants(
        this.cabinetSection!.corpus.itemsTop,
      );
    }
    return this.cache.itemVariantsTop;
  }
  public get itemVariantsBottom() {
    if (!this.cache.itemVariantsBottom) {
      this.cache.itemVariantsBottom = this.getUserSelectableVariants(
        this.cabinetSection!.corpus.itemsBottom,
      );
    }
    return this.cache.itemVariantsBottom;
  }
  public get itemVariantsLeft() {
    if (!this.cache.itemVariantsLeft) {
      this.cache.itemVariantsLeft = this.getUserSelectableVariants(
        this.leftCorpus.itemsLeft,
      );
    }
    return this.cache.itemVariantsLeft;
  }
  public get itemVariantsRight() {
    if (!this.cache.itemVariantsRight) {
      this.cache.itemVariantsRight = this.getUserSelectableVariants(
        this.rightCorpus.itemsRight,
      );
    }
    return this.cache.itemVariantsRight;
  }

  private getUserSelectableVariants(items: Client.ConfigurationItem[]) {
    let showAdminVariants = this.cabinetSection!.editorAssets.fullCatalog;
    let en = Enumerable.from(items)
      .selectMany((i) => i.VariantOptions)
      .where((vo) => !!vo.variant)
      .where((vo) => VariantHelper.isUserSelectable(vo.variant!))
      .where((vo) => vo.variant!.Number !== VariantNumbers.SidePanelLeftRight);
    if (!showAdminVariants) {
      en = en.where((vo) => !vo.OnlyAdmin);
    }
    return en.toArray();
  }

  private cache: Partial<{
    itemVariantsTop: Client.ItemVariant[];
    itemVariantsBottom: Client.ItemVariant[];
    itemVariantsLeft: Client.ItemVariant[];
    itemVariantsRight: Client.ItemVariant[];
  }> = {};
  public override setChanged() {
    this.cache = {};
    return super.setChanged();
  }

  public get pickableLightingProduct(): Pickable<Client.Product | null> {
    return Enumerable.from(
      this.cabinetSection!.corpus.pickableLightingProducts,
    ).first((plp) => plp.isSelected);
  }
  public set pickableLightingProduct(val: Pickable<Client.Product | null>) {
    let setNumLights =
      !this.cabinetSection!.corpus.lightingProduct && !!val.item;
    this.cabinetSection!.corpus.lightingProduct = val.item;
    if (setNumLights) {
      let numLights = this.cabinetSection!.NumberOfDoors;
      numLights = ObjectHelper.clamp(
        this.cabinetSection!.corpus.numberOfLightsMin,
        numLights,
        this.cabinetSection!.corpus.numberOfLightsMax,
      );
      this.cabinetSection!.corpus.numberOfLights = numLights;
    }

    if (!val.item && this.panel === 'lights') {
      this.showPanel = false;
    }
    this.setChanged();
  }

  public get leftCorpus(): Client.Corpus {
    return this.cabinetSection!.cabinet.leftCabinetSection.corpus;
  }

  public get rightCorpus(): Client.Corpus {
    return this.cabinetSection!.cabinet.rightCabinetSection.corpus;
  }

  public get heightReductionLeft(): boolean {
    return this.cabinetSection!.cabinet.leftCabinetSection
      .HeightReductionCorpusLeft;
  }
  public set heightReductionLeft(value: boolean) {
    if (
      value ===
      this.cabinetSection!.cabinet.leftCabinetSection.HeightReductionCorpusLeft
    ) {
      return;
    }

    this.cabinetSection!.cabinet.leftCabinetSection.HeightReductionCorpusLeft =
      value;
    this.setChanged();
  }

  public get heightReductionRight(): boolean {
    return this.cabinetSection!.cabinet.rightCabinetSection
      .HeightReductionCorpusRight;
  }
  public set heightReductionRight(value: boolean) {
    if (
      value ===
      this.cabinetSection!.cabinet.rightCabinetSection
        .HeightReductionCorpusRight
    ) {
      return;
    }

    this.cabinetSection!.cabinet.rightCabinetSection.HeightReductionCorpusRight =
      value;
    this.setChanged();
  }

  public get interiorWidth() {
    return this.cabinetSection!.interior.cube.Width;
  }
  public get interiorHeight() {
    return this.cabinetSection!.interior.cube.Height;
  }
  public get sightWidth() {
    return this.cabinetSection!.SightWidth;
  }
  public get sightHeight() {
    return this.cabinetSection!.SightHeight;
  }

  public get swingFlexTotalWidth() {
    return this.cabinetSectionWidth;
  }
  public get swingFlexSwingWidth() {
    return this.cabinetSection!.SightWidth;
  }
  public get swingFlexTotalHeight() {
    return this.cabinetSectionHeight;
  }
  public get swingFlexSwingHeight() {
    return this.cabinetSection!.SightHeight;
  }

  ///Called from EditorVm when the underlying floorplan has changed
  protected async floorPlanChanged() {}
}

export module CorpusVm {
  export type PanelName = 'top' | 'bottom' | 'left' | 'right' | 'lights';
  export interface Fitting {
    name: string;
    selectedOptionId: number;
    options: { id: number; name: string }[];
  }
}
