import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { D3Service } from 'app/ts/services/D3Service';
import { ErrorService } from 'app/ts/services/ErrorService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ComponentRef, Injectable } from '@angular/core';
import { Canvg } from 'canvg';
import { FloorPlanImageVm } from 'app/floor-plan-editing/FloorPlanImageVm';
import { ScreenshotContainerService } from 'app/screenshooting/screenshot-container.service';
import { DoorImageVm } from 'app/floor-plan-editing/DoorImageVm';
import { Interior2dVm } from 'app/floor-plan-editing/Interior2dVm';
import { BackingImageVm } from 'app/floor-plan-editing/BackingImageVm';
import { SwingImageVm } from 'app/floor-plan-editing/SwingImageVm';
import { BehaviorSubject, Subject } from 'rxjs';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { calculatePlan } from 'app/partition/floor-plan';
import { MeasurementService } from 'app/measurement/measurement.service';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import {
  PartitionPlan,
  PartitionPlanDataService,
} from 'app/partition/partition-plan-data.service';
import { FillingsImageVm } from 'app/floor-plan-editing/fillings/FillingsImageVm';
import { calculateSection } from 'app/partition/fillings/section';
import { calculateModules } from 'app/partition/fillings/module';
import { Section } from 'app/partition/section';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { SwingFlexImageComponent } from 'app/floor-plan-editing/swing-flex/swingFlexImage.component';

@Injectable()
export class ScreenshotService {
  private static readonly defaultSize2d = { X: 1024, Y: 768 };
  private static readonly defaultSize3d = { X: 800, Y: 600 };
  private static readonly imageFormat3d = 'image/png';
  private static readonly imageFormat2d = 'image/png';
  public static readonly Name = 'screenshotService';

  constructor(
    private readonly d3Service: D3Service,
    private readonly errorService: ErrorService,
    private readonly notificationService: NotificationService,
    private readonly screenShotContainer: ScreenshotContainerService,
    private readonly editorType: EditorTypeService,
    private readonly partitionData: PartitionPlanDataService,
    private readonly partitionQuery: PartitionPlanQueryService,
    private readonly partitionCommands: PartitionPlanCommandService,
    private readonly measurementService: MeasurementService,
  ) {}

  public static get allSubjects(): Interface_Enums.ImageSubject[] {
    return [
      Interface_Enums.ImageSubject.FloorPlan,
      Interface_Enums.ImageSubject.FloorPlanPartitionsOnly,

      Interface_Enums.ImageSubject.Doors,
      Interface_Enums.ImageSubject.DoorsWithMeasurements,

      Interface_Enums.ImageSubject.Interior,
      Interface_Enums.ImageSubject.InteriorWithMeasurements,
      Interface_Enums.ImageSubject.InteriorWithDummyItems,

      Interface_Enums.ImageSubject.BackingDrawingWithMeasurements,

      Interface_Enums.ImageSubject.ThreeD,
      Interface_Enums.ImageSubject.ThreeDNoDoors,

      Interface_Enums.ImageSubject.Swing,
      Interface_Enums.ImageSubject.SwingWithMeasurements,
      Interface_Enums.ImageSubject.SwingDoors,

      Interface_Enums.ImageSubject.SwingFlexDoors,
      Interface_Enums.ImageSubject.SwingFlexMeasurements,
      Interface_Enums.ImageSubject.SwingFlexDoorMeasurements,

      Interface_Enums.ImageSubject.PartitionFillings,
      Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements,
    ];
  }

  public getAllScreenshots(
    floorPlan: Client.FloorPlan,
    reloadPartition: boolean = false,
  ): Promise<Interface_DTO.Screenshot>[] {
    if (reloadPartition) {
      this.partitionData.hydratePartition(
        floorPlan.dtoObject.PartitionJsonData,
      );
    }

    let promises = this.getScreenshots(
      floorPlan,
      ScreenshotService.allSubjects,
    );

    if (reloadPartition) {
      Promise.allSettled(promises).then(() => {
        this.partitionData.newPartitionPlan(); //To reset the service
      });
    }

    return promises;
  }

  public getScreenshots(
    floorPlan: Client.FloorPlan,
    subjects: Interface_Enums.ImageSubject[],
  ): Promise<Interface_DTO.Screenshot>[] {
    this.measurementService.deselect();
    this.partitionCommands.deselectSection();

    let singleScreenshotPromises: {
      promise: Promise<Interface_DTO.Screenshot>;
      subject: Interface_Enums.ImageSubject;
      cabinetSection?: Client.CabinetSection;
    }[] = [];

    let fpProm: Promise<Interface_DTO.Screenshot> | undefined = undefined;
    for (let subject of subjects) {
      switch (subject) {
        case Interface_Enums.ImageSubject.ThreeD:
        case Interface_Enums.ImageSubject.ThreeDNoDoors:
        case Interface_Enums.ImageSubject.ThreeDPartition:
          break;
        case Interface_Enums.ImageSubject.FloorPlan:
          fpProm = this.getFloorPlanImage(floorPlan, subject, fpProm);
          singleScreenshotPromises.push({
            promise: fpProm,
            subject: subject,
          });

          break;
        case Interface_Enums.ImageSubject.FloorPlanPartitionsOnly:
          if (
            ScreenshotService.isInterestingPartition(
              this.partitionQuery.plan,
              subject,
            )
          ) {
            fpProm = this.getFloorPlanImage(floorPlan, subject, fpProm);
            singleScreenshotPromises.push({
              promise: fpProm,
              subject: subject,
            });
          }
          break;
        case Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements:
        case Interface_Enums.ImageSubject.PartitionFillings:
          for (let section of this.partitionQuery.getAllSections()) {
            if (
              ScreenshotService.isInterestingPartition(
                this.partitionQuery.plan,
                subject,
              )
            ) {
              let value = this.getPartition2DFillingsImage(
                floorPlan,
                section as Section,
                subject,
              );
              if (value != null) {
                let promise = this.makePromise(value);
                singleScreenshotPromises.push({
                  subject: subject,
                  promise: promise,
                  cabinetSection: undefined,
                });
              }
            }
          }
          break;
        default:
          for (let cabinet of floorPlan.actualCabinets) {
            for (let section of cabinet.cabinetSections) {
              if (
                ScreenshotService.isInteresting(section, subject) &&
                ScreenshotService.isInterestingPartition(
                  this.partitionQuery.plan,
                  subject,
                )
              ) {
                fpProm = this.get2dImage(section, subject, fpProm);
                singleScreenshotPromises.push({
                  subject: subject,
                  promise: fpProm,
                  cabinetSection: section,
                });
              }
            }
          }
          break;
      }
    }

    let errors: {
      subject: Interface_Enums.ImageSubject;
      error: string;
      cabinetIndex?: number;
      cabinetSectionIndex?: number;
    }[] = [];
    let result: Promise<Interface_DTO.Screenshot>[] = [];
    for (let screenShotPromise of singleScreenshotPromises) {
      try {
        let screenshot = screenShotPromise.promise;
        result.push(screenshot);
      } catch (e: any) {
        let extraInfo: any = {
          subject: screenShotPromise.subject,
          error: e.toString(),
        };
        if (screenShotPromise.cabinetSection) {
          let cs = screenShotPromise.cabinetSection;
          extraInfo.cabinetIndex = cs.CabinetIndex;
          extraInfo.cabinetSectionIndex = cs.CabinetSectionIndex;
        }
        errors.push(extraInfo);
      }
    }

    let d3ScreenShots = this.get3dImages(floorPlan, subjects);
    result.push(...d3ScreenShots);

    if (errors.length > 0) {
      let errorIdPromise = this.errorService.reportError(
        'screenshot_generation_failed',
        null,
        {
          floorPlanId: floorPlan.Id,
          subjects: subjects,
          errors: errors,
        },
      );

      errorIdPromise.then((errorId) => {
        if (errorId === undefined) {
          this.notificationService.warning(
            'screenshot_generation_failed_noid',
            'Generating some screenshots failed. Please try again.',
          );
        } else {
          this.notificationService.warning(
            'screenshot_generation_failed_id',
            'Generating some screenshots failed. Please try again. Error ID {0}',
            errorId.toString(),
          );
        }
      });
    }

    return result;
  }

  private async makePromise(val: any): Promise<any> {
    return val;
  }

  public static interestingSubjects(
    section: Client.CabinetSection,
  ): Interface_Enums.ImageSubject[] {
    return ScreenshotService.allSubjects.filter((sub) =>
      ScreenshotService.isInteresting(section, sub),
    );
  }

  public static isInterestingPartition(
    partitionPlan: PartitionPlan,
    subject: Interface_Enums.ImageSubject,
  ) {
    switch (subject) {
      case Interface_Enums.ImageSubject.ThreeDPartition:
      case Interface_Enums.ImageSubject.FloorPlanPartitionsOnly:
      case Interface_Enums.ImageSubject.PartitionFillings:
      case Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements:
        return partitionPlan !== null && partitionPlan.sections.length > 0;

      default:
        return true;
    }
  }

  public static isInteresting(
    section: Client.CabinetSection,
    subject: Interface_Enums.ImageSubject,
  ) {
    switch (subject) {
      case Interface_Enums.ImageSubject.Doors:
      case Interface_Enums.ImageSubject.DoorsWithMeasurements:
        return section.NumberOfDoors > 0;

      case Interface_Enums.ImageSubject.ThreeD:
        return (
          section.NumberOfDoors > 0 ||
          section.CabinetType === Interface_Enums.CabinetType.SwingFlex
        );

      case Interface_Enums.ImageSubject.ThreeDNoDoors:
        return (
          section.interior.items.length > 0 ||
          section.corpus.allItems.length > 0 ||
          section.CabinetType === Interface_Enums.CabinetType.SwingFlex ||
          section.CabinetType == Interface_Enums.CabinetType.Swing
        );

      case Interface_Enums.ImageSubject.Interior:
      case Interface_Enums.ImageSubject.InteriorWithMeasurements:
        return section.interior.items.length > 0;

      case Interface_Enums.ImageSubject.InteriorWithDummyItems:
        return section.interior.items.some((i) => i.isDummy);

      case Interface_Enums.ImageSubject.BackingDrawingWithMeasurements:
        return (
          section.backing.backingType !== Interface_Enums.BackingType.None &&
          section.CabinetType !== Interface_Enums.CabinetType.SwingFlex
        );

      case Interface_Enums.ImageSubject.Swing:
      case Interface_Enums.ImageSubject.SwingWithMeasurements:
      case Interface_Enums.ImageSubject.SwingDoors:
        return section.CabinetType === Interface_Enums.CabinetType.Swing;

      case Interface_Enums.ImageSubject.SwingFlexDoors:
      case Interface_Enums.ImageSubject.SwingFlexMeasurements:
      case Interface_Enums.ImageSubject.SwingFlexDoorMeasurements:
        return section.CabinetType === Interface_Enums.CabinetType.SwingFlex;
      case Interface_Enums.ImageSubject.FloorPlan:
        return (
          section.CabinetIndex === 0 &&
          section.cabinet.floorPlan.actualCabinets.length > 0
        );

      default:
        return true;
    }
  }

  // #region 2d

  private async getFloorPlanImage(
    floorPlan: Client.FloorPlan,
    subject: Interface_Enums.ImageSubject,
    prom: Promise<Interface_DTO.Screenshot> | undefined,
  ): Promise<Interface_DTO.Screenshot> {
    if (prom) await prom;

    let floorPlanAspectRatio = floorPlan.Size.X / floorPlan.Size.Y;
    let imageLength = 2000; //length of the long side of the image, in px
    let imageSize: Interface_DTO_Draw.Vec2d;
    if (floorPlanAspectRatio > 1) {
      imageSize = {
        X: imageLength,
        Y: Math.floor(imageLength / floorPlanAspectRatio),
      };
    } else {
      imageSize = {
        X: Math.floor(imageLength * floorPlanAspectRatio),
        Y: imageLength,
      };
    }

    const [floorPlanImage, componentElement] =
      this.screenShotContainer.createComponent(
        FloorPlanImageVm,
        (floorPlanImage) => {
          floorPlanImage.floorPlan = floorPlan;
          floorPlanImage.showCabinets =
            subject == Interface_Enums.ImageSubject.FloorPlan;
          floorPlanImage.selectedItem$ = new BehaviorSubject<
            Interface_DTO_FloorPlan.Item | undefined
          >(undefined);
          floorPlanImage.showErrorTriangles = false;
          floorPlanImage.partitions = calculatePlan(
            this.partitionQuery.plan,
            floorPlan,
          );
          floorPlanImage.measurements = this.measurementService.measurements;
          floorPlanImage.showMeasurements = true;
        },
      );

    const imageData = await this.getComponentAsDataUrl(componentElement);
    const screenshot: Interface_DTO.Screenshot = {
      CabinetIndex: 0,
      CabinetSectionIndex: 0,
      Image: imageData,
      Subject: subject,
    };

    floorPlanImage.destroy();
    return screenshot;
  }

  public async get2dImage(
    cabinetSection: Client.CabinetSection,
    subject: Interface_Enums.ImageSubject,
    prom: Promise<Interface_DTO.Screenshot> | undefined = undefined,
  ): Promise<Interface_DTO.Screenshot> {
    if (prom) {
      await prom;
    }

    let height = cabinetSection.Height;

    let [componentRef, componentElement, width] = this.CreateComponent(
      subject,
      cabinetSection,
      cabinetSection.Width,
    );

    let aspectRatio = width / height;
    let imageLength = ScreenshotService.defaultSize2d.X; //length of the long side of the image, in px
    let imageSize: Interface_DTO_Draw.Vec2d;
    if (aspectRatio > 1) {
      imageSize = {
        X: imageLength,
        Y: Math.floor(imageLength / aspectRatio),
      };
    } else {
      imageSize = {
        X: Math.floor(imageLength * aspectRatio),
        Y: imageLength,
      };
    }

    const dataUrl = await this.getComponentAsDataUrl(
      componentElement,
      imageSize,
    );

    const screenshot: Interface_DTO.Screenshot = {
      CabinetIndex: cabinetSection.CabinetIndex,
      CabinetSectionIndex: cabinetSection.CabinetSectionIndex,
      Image: dataUrl,
      Subject: subject,
    };

    componentRef.destroy();

    return screenshot;
  }

  public async getPartition2DFillingsImage(
    floorPlan: Client.FloorPlan,
    partitionSection: Section,
    subject: Interface_Enums.ImageSubject,
  ): Promise<Interface_DTO.Screenshot | null> {
    let height = partitionSection.height;
    this.partitionCommands.setSelection(partitionSection.id);
    let [componentRef, componentElement, width] = this.CreatePartitionComponent(
      floorPlan,
      partitionSection,
      partitionSection.width,
      subject,
    ); // Here do something new

    if (componentRef == null || componentElement == null) {
      return null;
    }

    let aspectRatio = width / height;
    let imageLength = ScreenshotService.defaultSize2d.X; //length of the long side of the image, in px
    let imageSize: Interface_DTO_Draw.Vec2d;
    if (aspectRatio > 1) {
      imageSize = {
        X: imageLength,
        Y: Math.floor(imageLength / aspectRatio),
      };
    } else {
      imageSize = {
        X: Math.floor(imageLength * aspectRatio),
        Y: imageLength,
      };
    }

    const dataUrl = await this.getComponentAsDataUrl(
      componentElement,
      imageSize,
    );

    let partition = this.partitionQuery.getPartitionForSection(
      partitionSection.id,
    );
    let partitionCabinetBaseIndex =
      floorPlan.cabinets
        .map((c) => c.CabinetIndex)
        .sort()
        .reverse()[0] + 1;
    const screenshot: Interface_DTO.Screenshot = {
      CabinetIndex:
        partitionCabinetBaseIndex +
        this.partitionQuery
          .getAllPartitions()
          .findIndex((part) => part.id == partition.id),
      CabinetSectionIndex:
        this.partitionQuery
          .getPartitionForSection(partitionSection.id)
          .sections.findIndex((sec) => sec == partitionSection.id) + 1,
      Image: dataUrl,
      Subject: subject,
    };

    componentRef.destroy();

    return screenshot;
  }

  private CreatePartitionComponent(
    floorPlan: Client.FloorPlan,
    section: Section,
    width: number,
    subject: Interface_Enums.ImageSubject,
  ): [ComponentRef<unknown> | null, HTMLElement | null, number] {
    if (section) {
      const [partitionSectionComponent, partitionComponentElement] =
        this.screenShotContainer.createComponent(
          FillingsImageVm,
          (partitionSectionComponent) => {
            partitionSectionComponent.floorPlan = floorPlan;
            partitionSectionComponent.section = calculateSection(
              section.id,
              this.partitionQuery.plan,
              floorPlan,
            );
            partitionSectionComponent.modules = calculateModules(
              section.id,
              this.partitionQuery.plan,
              floorPlan,
              this.partitionQuery.getDoorModuleProductId(),
              this.partitionQuery.getWallModuleProductId(),
            );
            partitionSectionComponent.showRulers =
              subject ==
              Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements;
            partitionSectionComponent.showTopdown =
              subject ==
              Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements;
          },
        );

      return [partitionSectionComponent, partitionComponentElement, width];
    } else {
      return [null, null, 0];
    }
  }

  private CreateComponent(
    subject: Interface_Enums.ImageSubject,
    cabinetSection: Client.CabinetSection,
    width: number,
  ): [ComponentRef<unknown>, HTMLElement, number] {
    switch (subject) {
      case Interface_Enums.ImageSubject.Doors:
      case Interface_Enums.ImageSubject.DoorsWithMeasurements:
        const [doorImage, doorImageComponentElement] =
          this.screenShotContainer.createComponent(DoorImageVm, (doorImage) => {
            doorImage.cabinetSection = cabinetSection;
            doorImage.showPullout = false;
            doorImage.showRulers =
              subject === Interface_Enums.ImageSubject.DoorsWithMeasurements;
            doorImage.showRulers =
              subject === Interface_Enums.ImageSubject.DoorsWithMeasurements;
          });

        if (cabinetSection.doors.numberOfRailTracks > 1) {
          width +=
            cabinetSection.ExtraRailWidthLeft +
            cabinetSection.ExtraRailWidthRight;
        }

        return [doorImage, doorImageComponentElement, width];

      case Interface_Enums.ImageSubject.Interior:
      case Interface_Enums.ImageSubject.InteriorWithMeasurements:
      case Interface_Enums.ImageSubject.InteriorWithDummyItems:
        const [cabinetSectionComponent, componentElement] =
          this.screenShotContainer.createComponent(
            Interior2dVm,
            (cabinetSectionComponent) => {
              cabinetSectionComponent.cabinetSection = cabinetSection;
              cabinetSectionComponent.showRulers =
                subject ===
                Interface_Enums.ImageSubject.InteriorWithMeasurements;
              cabinetSectionComponent.showDummyItems =
                subject === Interface_Enums.ImageSubject.InteriorWithDummyItems;
              cabinetSectionComponent.showWarnings = false;
            },
          );

        return [cabinetSectionComponent, componentElement, width];

      case Interface_Enums.ImageSubject.BackingDrawingWithMeasurements:
        const [backingImage, backingImageElement] =
          this.screenShotContainer.createComponent(
            BackingImageVm,
            (component) => {
              component.cabinetSection = cabinetSection;
              component.showRulers = true;
            },
          );

        return [backingImage, backingImageElement, width];

      case Interface_Enums.ImageSubject.Swing:
      case Interface_Enums.ImageSubject.SwingWithMeasurements:
      case Interface_Enums.ImageSubject.SwingDoors:
        const [swingImage, swingImageElement] =
          this.screenShotContainer.createComponent(
            SwingImageVm,
            (swingImage) => {
              swingImage.cabinetSection = cabinetSection;
              (swingImage.showRulers =
                subject ===
                  Interface_Enums.ImageSubject.SwingWithMeasurements ||
                subject === Interface_Enums.ImageSubject.SwingDoors),
                (swingImage.showDoors =
                  subject === Interface_Enums.ImageSubject.SwingDoors);
              swingImage.selectionObservable = new Subject();
            },
          );

        return [swingImage, swingImageElement, width];

      case Interface_Enums.ImageSubject.SwingFlexDoors:
      case Interface_Enums.ImageSubject.SwingFlexMeasurements:
      case Interface_Enums.ImageSubject.SwingFlexDoorMeasurements:
        const [swingFlexImage, swinfFlexImageElement] =
          this.screenShotContainer.createComponent(
            SwingFlexImageComponent,
            (image) => {
              image.cabinetSection = cabinetSection;
              if (subject === Interface_Enums.ImageSubject.SwingFlexDoors) {
                image.showRulers = false;
                image.showDoors = true;
                image.showCorpusMovable = true;
                image.showTopdown = false;
                image.showHinges = false;
                image.showBackingRulers = false;
              } else if (
                subject === Interface_Enums.ImageSubject.SwingFlexMeasurements
              ) {
                image.showRulers = true;
                image.showDoors = false;
                image.showCorpusMovable = false;
                image.showTopdown = false;
                image.showHinges = false;
                image.showBackingRulers = true;
              } else if (
                subject ===
                Interface_Enums.ImageSubject.SwingFlexDoorMeasurements
              ) {
                image.showRulers = true;
                image.showDoors = true;
                image.showCorpusMovable = true;
                image.showTopdown = true;
                image.showHinges = true;
                image.showBackingRulers = false;
              } else {
                throw new Error('Not Implemented');
              }
              image.selectionObservable = new Subject();
              image.selectionSubAreaObservable = new Subject();
              image.itemSelectionObservable = new Subject();
            },
          );

        return [swingFlexImage, swinfFlexImageElement, width];

      default:
        throw new Error('Not Implemented');
    }
  }

  private async getComponentAsDataUrl(
    componentElement: HTMLElement,
    imageSize?: Interface_DTO_Draw.Vec2d,
  ): Promise<string> {
    ScreenshotService.removeNgAttrs(componentElement);
    const canvasElement = componentElement.parentElement as HTMLCanvasElement;
    const svgElem = componentElement.previousElementSibling
      ?.firstElementChild as SVGElement;
    const imageData = await ScreenshotService.getSvgAsDataURL(
      canvasElement,
      svgElem,
      imageSize,
    );
    return imageData;
  }

  private static removeNgAttrs(elem: HTMLElement) {
    if (elem.attributes)
      for (let i = elem.attributes.length - 1; i >= 0; i--) {
        let attr = elem.attributes[i];
        if (ScreenshotService.shouldRemoveAttr(attr)) {
          elem.removeAttributeNode(attr);
        }
      }
    if (elem.childNodes)
      for (let i = 0; i < elem.childNodes.length; i++) {
        let child = elem.childNodes[i];
        this.removeNgAttrs(<any>child);
      }
  }
  private static shouldRemoveAttr(attr: Attr): boolean {
    if (attr.name.substr(0, 3) === 'ng-') return true;
    if (attr.name.substr(0, 2) === 'on') return true;

    //IE11 hack: IE adds some crazy namespaces, then fails when those namespaces don't have an URI
    if (attr.name.indexOf(':') > 0) {
      if (attr.name.substr(0, 6) === 'xlink:') return false;
      if (attr.name === 'xmlns:xlink') return false;
      return true;
    }
    return false;
  }

  private static async getSvgAsDataURL(
    canvas: HTMLCanvasElement,
    svgElem: SVGElement,
    size?: Interface_DTO_Draw.Vec2d,
  ): Promise<string> {
    if (!size) size = ScreenshotService.defaultSize2d;

    const context = canvas.getContext('2d');
    if (context == null) return '';

    svgElem.setAttribute('width', size.X.toFixed(1));
    svgElem.setAttribute('height', size.Y.toFixed(1));
    canvas.width = size.X;
    canvas.height = size.Y;

    let svgXml = new XMLSerializer().serializeToString(svgElem);
    const svgRenderer = Canvg.fromString(context, svgXml, {
      ignoreMouse: true,
      ignoreAnimation: true,
    });

    await svgRenderer.render();
    const imageData = canvas.toDataURL('image/png');
    return imageData;
  }

  // #endregion 2d

  // #region 3d

  private get3dImages(
    floorPlan: Client.FloorPlan,
    subjects: Interface_Enums.ImageSubject[],
  ): Promise<Interface_DTO.Screenshot>[] {
    let screenshots: Promise<Interface_DTO.Screenshot>[] = [];

    try {
      let images = this.render3DImages(floorPlan, subjects);
      screenshots.push(...images);
    } finally {
    }

    return screenshots;
  }

  private render3DImages(
    floorPlan: Client.FloorPlan,
    subjects: Interface_Enums.ImageSubject[],
  ): Promise<Interface_DTO.Screenshot>[] {
    let results: Promise<Interface_DTO.Screenshot>[] = [];
    let partitionPlan = this.partitionQuery.plan;
    let renderer = this.d3Service.getRenderer(
      floorPlan,
      floorPlan.cabinets[0].cabinetSections[0],
      partitionPlan,
    );
    renderer.setRendererSize(ScreenshotService.defaultSize3d);
    let rendererDonePromise: Promise<any> = Promise.resolve(0);

    try {
      for (let subject of subjects) {
        if (
          subject === Interface_Enums.ImageSubject.ThreeD ||
          subject === Interface_Enums.ImageSubject.ThreeDNoDoors
        ) {
          // Normal cabinets
          for (let cabinet of floorPlan.actualCabinets) {
            let cabinetSection = cabinet.cabinetSections[0];

            if (!ScreenshotService.isInteresting(cabinetSection, subject))
              continue;

            let showDoors =
              subject !== Interface_Enums.ImageSubject.ThreeDNoDoors;

            let screenshotPromise = rendererDonePromise
              .then((_) =>
                renderer!.setSelection(
                  cabinetSection,
                  partitionPlan,
                  floorPlan,
                ),
              )
              .then((_) => {
                if (!renderer) throw new Error('Renderer gone');

                renderer.setVisible('doors', showDoors);
                renderer.setVisible('rails', showDoors);

                renderer.setVisible('otherCabinets', false);
                renderer.setVisible('pullout', false);
                renderer.setVisible('collision', false);

                renderer.setVisible('backing', true);
                renderer.setVisible('corpus', true);
                renderer.setVisible('interior', true);
                renderer.setVisible('modules', true);
                renderer.setVisible('thisCabinet', true);
                renderer.setVisible('rulers', false);
                renderer.setVisible('partitions', false);

                renderer.resetCamera();
                renderer.activateHighQuality();
                renderer.render();

                let dataUrl = renderer.canvas.toDataURL(
                  ScreenshotService.imageFormat3d,
                );
                let screenshot: Interface_DTO.Screenshot = {
                  CabinetIndex: cabinet.CabinetIndex,
                  CabinetSectionIndex: cabinetSection.CabinetSectionIndex,
                  Subject: subject,
                  Image: dataUrl,
                };
                return screenshot;
              });
            rendererDonePromise = screenshotPromise;
            results.push(screenshotPromise);
          }
        } else if (
          subject === Interface_Enums.ImageSubject.ThreeDPartition &&
          ScreenshotService.isInterestingPartition(partitionPlan, subject)
        ) {
          let screenshotPromise = rendererDonePromise
            .then((_) => renderer.setSelection(null, partitionPlan, floorPlan))
            .then((_) => {
              if (!renderer) throw new Error('Renderer gone');

              renderer.setVisible('doors', false);
              renderer.setVisible('rails', false);

              renderer.setVisible('otherCabinets', true);
              renderer.setVisible('pullout', false);
              renderer.setVisible('collision', false);

              renderer.setVisible('backing', false);
              renderer.setVisible('corpus', false);
              renderer.setVisible('interior', false);
              renderer.setVisible('modules', false);
              renderer.setVisible('thisCabinet', true);
              renderer.setVisible('rulers', false);
              renderer.setVisible('partitions', true);

              renderer.resetCamera();
              renderer.setCameraPartition();
              renderer.activateHighQuality();
              renderer.render();

              let dataUrl = renderer.canvas.toDataURL(
                ScreenshotService.imageFormat3d,
              );
              let screenshot: Interface_DTO.Screenshot = {
                CabinetIndex: 0,
                CabinetSectionIndex: 0,
                Subject: subject,
                Image: dataUrl,
              };
              return screenshot;
            });
          rendererDonePromise = screenshotPromise;
          results.push(screenshotPromise);
        }
      }
    } finally {
      if (renderer) renderer.dispose();
    }

    return results;
  }

  // #endregion 3d
}
