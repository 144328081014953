export enum CollisionSpacePosition {
  Top,
  Bottom,
  Side,
}

export const enum CollisionSeverity {
  None = 0,
  Recommended = 1,
  Required = 2,
  Overlap = 3,
}

export enum Dimension {
  X,
  Y,
  Z,
}

export enum PulloutWarningSeverity {
  None = 0,
  Warning = 1, // Moving more than one door is required to pull out a product
  Critical = 2, // Moving more than one door per track is required to pull out a product (not allowed)
}

export enum ProjectionLocation {
  RayA,
  SegmentAB,
  RayB,
}

export enum EditorSection {
  FloorPlan = 1,
  Corpus,
  Doors,
  Fillings,
  Swing,
  SwingFlex,
  Interior,
  Backing,
  D3,
  PieceList,
  Validation,
}

export enum CorpusPanelPosition {
  Top,
  Bottom,
  Left,
  Right,
}

export enum GripPlacement {
  None = '0',
  Left = '2',
  Right = '3',
  Both = '4',
}

export enum BarType {
  Loose = 'L',
  Fixed = 'F',
  Design = 'D',
}

export const enum ItemRotation {
  None,
  /** Item should be rotated 90 degrees around the Z axis. Origin point should remain the same. */
  Clockwise90DegreesZ,
  /** Item should be rotated 90 degrees around the Z axis. Origin point should remain the same. */
  CounterClockwise90DegreesZ,
}

export const enum RecalculationMessageSeverity {
  Info,
  Warning,
  Failure,
  Question,
}

export const enum DoorProfilePosition {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
}

export const enum RecalculationQuestionType {
  //Do not use questionType 0
  RepositionDoorBars = 1,
  RepositionDoorGrips = 2,
}

export const enum InteriorGapClass {
  Empty = 0,
  Flex = 1,
  SemiFlex = 2,
  Fixed = 3,
}

export const enum SwingModuleChange {
  None = 0,
  Remove = 1,
  MoveLeft = 2,
  MoveRight = 3,
}

export const enum PseudoProductLine {
  RoomAccessories = 'RoomAccessories',
}

export const enum HingeSide {
  Left = 'Left',
  Right = 'Right',
  Both = 'Both',
  None = 'None',
}

export const enum SwingFlexOpeningMethod {
  PushToOpen = 'PushToOpen',
  Grip = 'Grip',
}

export const enum SwingFlexHingeType {
  Auto = 'Auto',
  Standard = 'Hinge_110',
  Special = 'Hinge_155',
}

export const enum SwingFlexGripPosition {
  Auto = 'Auto',
  Top = 'Top',
  Middle = 'Middle',
  Bottom = 'Bottom',
}

export const enum SwingPulloutSingle {
  No = 0,
  Yes = 1,
}

export const enum SwingPulloutDouble {
  None = 0,
  Left = 2,
  Right = 3,
  Both = 4,
}

export const enum SwingPulloutPosition {
  Left = 1,
  Right = 2,
  Both = 3,
}

export enum Side {
  Left = 'left',
  Right = 'right',
}

export enum DoorOpenSide {
  Left = 'left',
  Right = 'right',
  Both = 'both',
}

export namespace Side {
  export function inverse(side: Side): Side {
    return side == Side.Left ? Side.Right : Side.Left;
  }
}

export namespace DoorOpenSide {
  export function inverse(side: DoorOpenSide): DoorOpenSide {
    return side == DoorOpenSide.Left ? DoorOpenSide.Right : DoorOpenSide.Left;
  }
}

export const enum PartitionDragType {
  MultiDrag,
  FreeDrag,
  XAxisDrag,
  YAxisDrag,
}
