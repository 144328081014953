import * as Interface_DTO from 'app/ts/Interface_DTO';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { Injectable } from '@angular/core';
import { StoreSettingData } from 'app/functional-core/ambient/stores/StoreSetting';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(private readonly httpClient: PromisingBackendService) {}

  public async getCurrentStoreData(): Promise<StoreSettingData> {
    return this.httpClient.get<StoreSettingData>('api/store/storeData');
  }

  public loadStoreById(id: number): Promise<Interface_DTO.Store> {
    return this.httpClient
      .get<Interface_DTO.Store>(`api/store/${id}`)
      .then((store) => {
        store.SalesChain.LogoPath = 'staticAssets/' + store.SalesChain.LogoPath;
        return store;
      });
  }

  public loadStores(): Promise<Interface_DTO.Store[]> {
    return this.httpClient
      .get<Interface_DTO.Store[]>('api/store')
      .then((stores) =>
        stores.map((store) => {
          store.SalesChain.LogoPath =
            'staticAssets/' + store.SalesChain.LogoPath;
          return store;
        }),
      );
  }

  public async getStoreByKANumber(
    kaNumber: string,
  ): Promise<Interface_DTO.OrderNoSearchResult | null> {
    const httpRequest =
      await this.httpClient.get<Interface_DTO.OrderNoSearchResult | null>(
        'api/store/kaNumber/' + kaNumber,
      );
    const d = httpRequest;
    return d ? d : null;
  }

  async getStoreByFloorPlanId(
    id: number,
  ): Promise<Interface_DTO.OrderNoSearchResult | null> {
    const httpRequest =
      await this.httpClient.get<Interface_DTO.OrderNoSearchResult | null>(
        'api/store/floorPlanId/' + id,
      );
    const d = httpRequest;
    return d ? d : null;
  }
}
