import { BehaviorSubject } from 'rxjs';
import { StoresContainer } from './ambient/stores/StoresContainer';
import { ActiveUserContainer } from './ambient/activeUser/ActiveUserContainer';
import { ClientSettingContainer } from './ambient/clientSetting/ClientSettingContainer';
import { I8nContainer } from './ambient/i8n/I8nContainer';
import { ChainSettingsContainer } from './ambient/chainSettings/ChainSettingsContainer';
import { UserDataContainer } from './ambient/userdata/UserDataContainer';
import { ApplicationState } from './ApplicationState';

/** Ambient state is generally application wide
 * available state.
 */
export class AmbientState {
  constructor() {
    // this._activeUser = null;{
    //     ConsumerUserId : 17,
    //     Expires : "",
    //     Rights: [],
    //     StoreId: 939,
    //     Type: TokenType.Retail,
    //     UserId: 42,
    //     UserLogin: 'tkp@techsoft.dk',
    //     UserName: 'Mr. Legacy'
    // }
  }

  private _activeUser = new ActiveUserContainer();
  public get activeUser() {
    return this._activeUser;
  }

  private _i8n = new I8nContainer();
  public get i8n() {
    return this._i8n;
  }

  private _clientSetting = new ClientSettingContainer(
    this.activeUser.activeUser$,
  );
  public get clientSetting(): ClientSettingContainer {
    return this._clientSetting;
  }

  private _chainsettings = new ChainSettingsContainer();
  public get chainSettings(): ChainSettingsContainer {
    return this._chainsettings;
  }

  private _storeData: StoresContainer = new StoresContainer();
  public get storeData(): StoresContainer {
    return this._storeData;
  }

  private _userData: UserDataContainer = new UserDataContainer();
  public get userData(): UserDataContainer {
    return this._userData;
  }

  private _applicationState: ApplicationState = ApplicationState.Initializing;
  public get applicationState() {
    return this._applicationState;
  }
  private _applicationState$ = new BehaviorSubject<ApplicationState>(
    this._applicationState,
  );
  public get applicationState$() {
    return this._applicationState$.asObservable();
  }

  public set applicationState(state: ApplicationState) {
    this._applicationState = state;
    this._applicationState$.next(this._applicationState);
    if (state != ApplicationState.FinishedInitializing) return;
    this._applicationState$.complete();
  }
}
