import { Bar, BarId } from './bar';
import { Filling, FillingId } from './filling';
import { SectionId } from './section';
import { Defaults } from './defaults';
import { Constants } from 'app/ts/Constants';
import { ProfileId } from './profile';
import { Side, DoorOpenSide } from 'app/ts/clientDto/Enums';
import { BarType } from 'app/ts/Interface_Enums';

/** Represents a single module on a
 * @see Section. Contains the properties
 * common for a Wall and a Door
 */
export class Module {
  constructor(data: ModuleData) {
    this._data = data;
  }

  private _data: ModuleData;
  public get data(): ModuleData {
    return this._data;
  }

  public get isDoor(): boolean {
    return this._data.type == ModuleType.Door;
  }

  public get isWall(): boolean {
    return this._data.type == ModuleType.Wall;
  }

  public get forceFixedBars(): boolean {
    return this._data.forceFixedBars;
  }

  private nextFillingId(): FillingId {
    return this.data.nextFillingId++ as FillingId;
  }

  private nextBarId(): BarId {
    return this.data.nextBarId++ as BarId;
  }

  public get id(): ModuleId {
    return this.data.id;
  }

  public get sectionId(): SectionId {
    return this.data.sectionId;
  }

  public set sectionId(val: SectionId) {
    this.data.sectionId = val;
  }

  /**
   * Relative from section position
   */
  public get posX(): number {
    return this.data.posX;
  }
  public set posX(posX: number) {
    this.data.posX = posX;
  }

  /**
   * Relative from section position
   */
  public get posY(): number {
    return this.data.posY;
  }

  public set posY(posY: number) {
    this.data.posY = posY;
  }

  /**
   * The full width of a module. If the module is one of the end modules the StartStop profile
   * is included in the width. To get the width without the profile use @see getModuleWidthWithoutEndProfile from the query service.
   */
  public get width(): number {
    return this.data.width;
  }

  public set width(width: number) {
    this.data.width = width;
  }

  public get height(): number {
    return this.data.height;
  }

  public set height(height: number) {
    this.data.height = height;
  }

  public get depth(): number {
    return this.data.depth;
  }

  public set depth(newDepth: number) {
    this.data.depth = newDepth;
  }

  public get positionNo(): number {
    return this.data.positionNo;
  }

  public set positionNo(val: number) {
    this.data.positionNo = val;
  }

  public get references(): ModuleIdReferences {
    return this.data.references;
  }

  public hasError: boolean = false;

  /**
   * NOTE: The list goes from bottom filling to top filling in the module
   *
   * The filling with same index as a bar is below the bar and +1 is above
   */
  public get fillings(): readonly Filling[] {
    return [...this.data.fillings];
  }

  public get bars(): Bar[] {
    return [...this.data.bars];
  }

  /**
   * If a default material is provided, it is used. Otherwise, it uses the material from the prior filling.
   * @param defaultMaterialId
   * @returns
   */
  public addFilling(defaultMaterialId?: number): FillingId {
    const lastFilling = this.fillings[this.fillings.length - 1];
    let widthReduction = this.isDoor
      ? Door.fillingWidthReduction
      : Module.fillingWidthReduction;
    const filling = new Filling({
      id: this.nextFillingId(),
      materialId:
        defaultMaterialId != undefined
          ? defaultMaterialId
          : lastFilling.materialId,
      xOffset: widthReduction,
      height: 0, //recalculated afterwards
      width: this.width - widthReduction * 2,
      depth: Constants.fillingDepth,
    });

    this.data.fillings.push(filling);
    return filling.id;
  }

  public removeFilling(fillingId: FillingId) {
    this.data.fillings = this.data.fillings.filter(
      (filling) => filling.id != fillingId,
    );
  }

  public addBar(barHeight: number): BarId {
    let widthReduction =
      Module.fillingInsertionDepth +
      (this.isDoor ? Door.fillingWidthReduction : Module.fillingWidthReduction);
    const bar = {
      id: this.nextBarId(),
      xOffset: widthReduction,
      position: 0,
      bartype: BarType.Fixed,
      width: this.width - widthReduction * 2,
      height: barHeight,
    } as Bar;
    this.data.bars.push(bar);
    return bar.id;
  }

  public removeBars(barId: BarId) {
    this.data.bars = this.data.bars.filter((bar) => bar.id != barId);
  }

  public removeNextSectionLink() {
    this.references.nextSectionModuleId = undefined;
  }

  //#region static vars
  public static get fillingWidthReduction(): number {
    return 6; // 6 from fillings insertion in frame, to frame outside.
  }

  public static get fillingInsertionDepth(): number {
    return 8.5;
  }

  public static get frameDepth(): number {
    return 20.5; // the smallest side of the three of on the frame profiles
  }

  public static get frameTopBottomHeight(): number {
    return 21.5;
  }

  public static get frameLeftRightOffset(): number {
    return 14; // The long tooths on a partition frame profile is 14 mm
  }

  public static get frameTopBottomWidthReduction(): number {
    return this.fillingWidthReduction + this.fillingInsertionDepth;
  }

  public static get fullJointWidth(): number {
    return 41;
  }

  public static get elementSpacing(): number {
    return 5;
  }

  //#endregion
}

export interface CommonModuleData {
  id: ModuleId;
  sectionId: SectionId;
  posX: number;
  posY: number;
  width: number;
  height: number;
  depth: number;
  positionNo: number;
  forceFixedBars: boolean;
  fillings: Filling[];
  bars: Bar[];
  nextFillingId: FillingId;
  nextBarId: BarId;
  references: ModuleIdReferences;
}

interface ModuleIdReferences {
  productId: number;
  verticalBarOptionId: number;
  previousSectionModuleId?: ModuleId;
  nextSectionModuleId?: ModuleId;

  leftProfileId?: ProfileId;
  rightProfileId?: ProfileId;
}

export enum ModuleType {
  Wall,
  Door,
}

export interface WallData extends CommonModuleData {
  type: ModuleType.Wall;
}

export enum DoorPlacement {
  Front = 'front',
  Back = 'back',
}

export interface DoorData extends CommonModuleData {
  type: ModuleType.Door;
  placement: DoorPlacement;
  openDirection: DoorOpenSide;
  leftSlideExtensionAmount: number; // How long the rail of a door extends in the left direction
  rightSlideExtensionAmount: number; // How long the rail of a door extends in the right direction

  hasLeftEndStop: boolean;
  hasRightEndStop: boolean;
}

export type ModuleData = WallData | DoorData;

//
export class Wall extends Module {
  public static createNew(
    id: ModuleId,
    sectionId: SectionId,
    posX: number,
    posY: number,
    width: number,
    height: number,
    depth: number,
    productId: number,
    verticalBarOptionId: number,
    defaultFillingMaterialId: number,
    previousSectionModuleId?: ModuleId,
  ): Wall {
    let module = new Wall({
      type: ModuleType.Wall,
      id,
      sectionId,
      posX,
      posY,
      width,
      height,
      depth,
      positionNo: -1,
      references: {
        productId,
        verticalBarOptionId,
        previousSectionModuleId,
      },

      forceFixedBars: false,
      fillings: [],
      bars: [],
      nextFillingId: 1 as FillingId,
      nextBarId: 1 as BarId,
    });

    module.addFilling(defaultFillingMaterialId);
    return module;
  }

  public static fromDoor(door: Door, defaults: Defaults): Wall {
    let {
      placement,
      openDirection,
      leftSlideExtensionAmount,
      rightSlideExtensionAmount,
      hasLeftEndStop,
      hasRightEndStop,
      type,
      ...data
    } = door.data as DoorData; // this removes the named keys from the object
    let wallData = data as WallData;
    wallData.type = ModuleType.Wall;
    data.references.productId = defaults.moduleWallProductId;
    return new Wall(data as WallData);
  }

  constructor(data: WallData) {
    super(data);
  }
}

export class Door extends Module {
  public static createNew(
    id: ModuleId,
    sectionId: SectionId,
    posX: number,
    posY: number,
    width: number,
    height: number,
    depth: number,
    productId: number,
    verticalBarOptionId: number,
    defaultFillingMaterialId: number,
    previousSectionModuleId?: ModuleId,
  ): Door {
    let module = new Door({
      type: ModuleType.Door,
      id,
      sectionId,
      posX,
      posY,
      width,
      height,
      depth,
      positionNo: -1,
      references: {
        productId,
        verticalBarOptionId,
        previousSectionModuleId,
      },
      placement: DoorPlacement.Front,
      openDirection: DoorOpenSide.Left,
      leftSlideExtensionAmount: width,
      rightSlideExtensionAmount: width,
      hasLeftEndStop: false,
      hasRightEndStop: false,

      forceFixedBars: false,
      fillings: [],
      bars: [],
      nextFillingId: 1 as FillingId,
      nextBarId: 1 as BarId,
    });

    module.addFilling(defaultFillingMaterialId);
    return module;
  }

  public static fromWall(wall: Wall, defaults: Defaults): Door {
    let data = { ...wall.data } as DoorData;
    data.type = ModuleType.Door;
    data.placement = DoorPlacement.Front;
    data.openDirection = DoorOpenSide.Left;
    data.leftSlideExtensionAmount = wall.width;
    data.rightSlideExtensionAmount = wall.width;
    data.references.productId = defaults.moduleDoorProductId;
    return new Door(data as DoorData);
  }

  constructor(data: DoorData) {
    super(data);
  }

  private get doorData(): DoorData {
    return this.data as DoorData;
  }

  public get placement(): DoorPlacement {
    return this.doorData.placement;
  }

  public set placement(val: DoorPlacement) {
    this.doorData.placement = val;
  }

  public get openDirection(): DoorOpenSide {
    return this.doorData.openDirection;
  }

  public set openDirection(value: DoorOpenSide) {
    this.doorData.openDirection = value;
  }

  public get leftSlideExtensionAmount(): number {
    return this.doorData.leftSlideExtensionAmount;
  }

  public set leftSlideExtensionAmount(val: number) {
    this.doorData.leftSlideExtensionAmount = val;
  }

  public get rightSlideExtensionAmount(): number {
    return this.doorData.rightSlideExtensionAmount;
  }

  public set rightSlideExtensionAmount(val: number) {
    this.doorData.rightSlideExtensionAmount = val;
  }

  public get hasLeftEndStop(): boolean {
    return this.doorData.hasLeftEndStop;
  }

  public set hasLeftEndStop(val: boolean) {
    this.doorData.hasLeftEndStop = val;
  }

  public get hasRightEndStop(): boolean {
    return this.doorData.hasRightEndStop;
  }

  public set hasRightEndStop(val: boolean) {
    this.doorData.hasRightEndStop = val;
  }

  public static get doorModuleHeightDifference(): number {
    return 3; //Gotten from partition system subtraction pdf
  }

  public static get doorTotalFillingHeightDifference(): number {
    return 35; //Gotten from partition system subtraction pdf
  }

  public static get extraWidth(): number {
    return 30; // gotten from excel with calculations
  }

  public static override get fillingWidthReduction(): number {
    return 20; // 20 from fillings insertion in frame, to frame outside.
  }

  public static get endStopExtraWidth(): number {
    // includes 5 mm for endstop + 5 mm for 'Anslag'

    return 10; // gotten from excel with calculations
  }

  public static get frameTopHeight(): number {
    return 22.5;
  }

  public static get frameBottomHeight(): number {
    return 56;
  }

  public static override get fillingInsertionDepth(): number {
    return 10;
  }
}

export type ModuleId = number & { _moduleIds: never };
