import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NModule } from 'app/i18n/i18n.module';
import { CalculatePlanPipe } from './floor-plan/calculate-plan.pipe';
import { PartitionPlanCommandService } from './partition-plan-command.service';
import { ModulePropertiesComponent } from './property-sheets/module-properties.component';
import { PartitionPropertiesComponent } from './property-sheets/partition-properties.component';
import { PropertySheetComponent } from './property-sheets/property-sheet.component';
import { PropertySheetContainerComponent } from './property-sheets/property-sheet.container';
import { SectionPropertiesComponent } from './property-sheets/section-properties.component';

@NgModule({
  declarations: [
    CalculatePlanPipe,
    ModulePropertiesComponent,
    PartitionPropertiesComponent,
    PropertySheetComponent,
    PropertySheetContainerComponent,
    SectionPropertiesComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    I18NModule,
  ],
  exports: [CalculatePlanPipe, PropertySheetContainerComponent],
})
export class PartitionModule {}
