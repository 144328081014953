import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { catchError, map, Observable, throwError } from 'rxjs';
import * as Interface_DTO from 'app/ts/exceptions';
import { DomainError, ServerError } from './ts/Interface_DTO';
import { LoginService } from '@Services/LoginService';

/** Parses error responses */
@Injectable()
export class ErrorParseInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse, caught) => {
        this.parseResponse(error, req);
        return throwError(() => error);
      }),
    );
  }

  private parseResponse(error: HttpErrorResponse, req: HttpRequest<any>) {
    let url = error.url != null ? error.url : undefined;

    let errorContent: any;
    try {
      errorContent = JSON.parse(JSON.stringify(error.error)); //Make sure it is a string
    } catch (ex: any) {
      throw new Interface_DTO.ServerException(error.error, url);
    }

    if (
      error.status !== -1 &&
      error.status === 400 &&
      this.isDomainError(errorContent)
    ) {
      throw new Interface_DTO.ServerDomainException(errorContent);
    } else if (
      error.status !== undefined &&
      error.status === 500 &&
      this.isServerError(errorContent)
    ) {
      let se = new Interface_DTO.ServerException(errorContent, url);
      if (se.serverError) {
        console.error('ServerError encountered: ' + se.serverError);
      }
      throw se;
    } else if (error.status !== undefined && error.status === 401) {
      let cookie = document.cookie;
      LoginService.logout();
      window.location.href = window.location.origin;
      throw new Interface_DTO.AuthenticationException(req.method, url, cookie);
    }
  }

  private isDomainError(e: any): e is DomainError {
    return e && typeof e.Type === 'number';
  }

  private isServerError(e: any): e is ServerError {
    return e && typeof e.ErrorId === 'number';
  }
}
