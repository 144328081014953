import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Injectable()
export class ParameterService {
  private routeParams: ParamMap | null = null;
  private queryParams: ParamMap | null = null;

  constructor(private readonly activatedRoute: ActivatedRoute) {
    activatedRoute.paramMap.subscribe((p) => {
      this.routeParams = p;
    });

    activatedRoute.queryParamMap.subscribe((q) => {
      this.queryParams = q;
    });
  }

  /**
   * @param name The name of the parameter for which to retrieve the value
   * @returns The value for the parameter or null if the parameter is not found
   */
  public getParam(name: string): string | null {
    if (this.routeParams == null && this.queryParams == null) return null;

    const queryValue = this.queryParams?.get(name);
    const routeValue = this.routeParams?.get(name);

    if (routeValue != null) return routeValue;

    return queryValue ?? null;
  }
}
