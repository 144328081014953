import { Component, Inject } from '@angular/core';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
} from 'app/functional-core/ambient/userdata/UserData';
import * as Client from 'app/ts/clientDto/index';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { TableVm } from 'app/ts/viewmodels/components/TableVm';

@Component({
  selector: 'projects-table',
  templateUrl: './customersBaseTable.html',
  styleUrls: [
    '../../../../style/briefStyling.scss',
    '../../../../style/overviews.scss',
    '../../../../style/IE-hacks.scss',
    '../../../../style/floorplans.scss',
    '../../../../style/rightMenu.scss',
    '../../../../style/tables.scss',
    '../../../../style/nav.scss',
  ],
  providers: [clientSettingProvider, userDataProvider],
})
export class ProjectsTableVm extends TableVm<Client.Project> {
  public readonly leftClass = 'bi bi-person-fill';
  public readonly rightClass = 'bi bi-geo-fill';
  constructor(
    baseVmService: BaseVmService,
    formattingService: FormattingService,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
    @Inject(UserSettingsInjector) private userData: UserSettings,
  ) {
    super(
      baseVmService,
      ProjectsTableVm.getColumns(
        baseVmService.translationService,
        formattingService,
      ),
    );

    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.selectedColumns = settings.columnsProject;
      }),
    );

    super.ensureUnsubscribe(
      this.userData.subscribe((userData) => {
        this.columns = ProjectsTableVm.getColumns(
          baseVmService.translationService,
          formattingService,
        );
      }),
    );
    this.reverseOrder = true;
  }

  public static getColumns(
    translationService: TranslationService,
    formattingService: FormattingService,
  ): { [name: string]: Readonly<TableColumn<Client.Project>> } {
    return {
      id: {
        displayName: translationService.translate('projects_col_name_id', 'Id'),
        removable: true,
        colClass: 'id-col',
        val: (cust) => cust.Id,
      },
      name: {
        displayName: translationService.translate(
          'projects_col_name_name',
          'Name',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust) => cust.Name,
      },
      numAddrs: {
        displayName: translationService.translate(
          'projects_col_name_num_addrs',
          'Delivery Addresses',
        ),
        removable: true,
        colClass: 'number-col',
        val: (proj) => proj.DeliveryAddresses.length,
      },
      numFloorPlans: {
        displayName: translationService.translate(
          'projects_col_name_num_floorPlans',
          'Floor Plans',
        ),
        removable: true,
        colClass: 'number-col',
        val: (proj) =>
          proj.DeliveryAddresses.map((da) => da.FloorPlans.length).reduce(
            (prev, curr) => prev + curr,
            0,
          ),
      },
      createdDate: {
        displayName: translationService.translate(
          'projects_col_last_change',
          'Last changed date',
        ),
        removable: true,
        colClass: 'datetime-col',
        val: (proj) => {
          let date = DateHelper.fromIsoString(proj.CreatedDate);
          return formattingService.formatDate(date, true);
        },
        sortVal: (proj) => proj.CreatedDate,
      },
      description: {
        displayName: translationService.translate(
          'projects_col_description',
          'Description',
        ),
        removable: true,
        colClass: 'text-col',
        val: (proj) => proj.Description,
      },
    };
  }

  public selectedColumns = ['id', 'name'];

  public order = 'createdDate';
}
