import { Component, Inject, Input } from '@angular/core';
import {
  CustomPriceDate,
  CustomPriceDateInjector,
} from 'app/functional-core/ambient/clientSetting/CustomPriceDate';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { LoginService } from '@Services/LoginService';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import { SalesChainSettingKey } from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { ModalService } from 'app/ts/services/ModalService';
import { NavigationService } from 'app/ts/services/NavigationService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { VersionService } from 'app/ts/services/VersionService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'topbar',
  styleUrls: ['topbar.scss'],
  templateUrl: 'topbar.html',
  providers: [activeStoreProvider],
})
export class TopbarVm extends BaseVm {
  public loggedIn = false;
  public userLogin: string | null = null;
  public userName: string | null = null;
  public logoUrl: string = '';
  public readonly displayDebugButton = App.useDebug;
  // public showFloorPlanTemplate: boolean = false;
  public get showFloorPlanTemplate(): boolean {
    let setting =
      this.loginService.salesChainSettings[
        SalesChainSettingKey.FloorplanTemplateDeliveryAddressId
      ];
    return setting != null && setting.trim().length > 0;
  }

  @Input()
  public allowChangeStore: boolean = false;

  @Input()
  public allowChangeFullCatalog: boolean = false;

  @Input()
  public floorplan: Client.FloorPlan | undefined = undefined;

  @Input()
  public parent!: any;

  constructor(
    baseVmService: BaseVmService,
    private readonly loginService: LoginService,
    private readonly permissions: PermissionService,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService,
    private readonly floorPlanService: FloorPlanSaveService,
    private readonly versionService: VersionService,
    private readonly $window: WindowService,
    private routing: AppRoutingModule,
    private navigate: NavigateService,
    private readonly navigationService: NavigationService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
    @Inject(CustomPriceDateInjector) customPriceDate: CustomPriceDate,
  ) {
    super(baseVmService);

    const token = loginService.getCookieUserToken();
    if (token) {
      this.loggedIn = true;
      this.userLogin = token.UserLogin;
      this.userName = token.UserName;
    } else {
      this.loggedIn = false;
      this.userLogin = null;
      this.userName = null;
    }

    this.ensureUnsubscribe(
      customPriceDate.subscribe((value) => (this.customPriceDate = value)),
    );

    super.ensureUnsubscribe(
      activeStore.subscribe((store) => {
        this.logoUrl = store.SalesChain.LogoPath;
        if (!this.logoUrl) {
          this.logoUrl = this.translate(
            'topbar_default_logo',
            'staticAssets/images/Logos/Default/navbar_logo.png',
          ); // TODO: in some config
        }
      }),
    );

    // this.subscribeTo(this.oldLoginService.settings$, settings => {
    //     this.showFloorPlanTemplate = settings
    //         ? settings.SalesChain.FloorplanTemplateDeliveryAddressId != null
    //         : false;
    // })
  }

  public logout() {
    this.loginService.logout();
    this.notificationService.success(
      'topbar_logout_notification',
      'You are now logged out',
    );
    this.routing.Login();
  }

  public newFloorPlanFromTemplate() {
    let floorPlanPromise = this.modalService
      .getFloorPlanTemplateModal()
      .then((template) =>
        this.floorPlanService.createDefaultFloorPlan(undefined, template),
      );
    return this.loadFloorPlan(floorPlanPromise);
  }

  public async newFloorPlanOnDefaultDeliveryAddress() {
    let floorPlanPromise = this.floorPlanService.createDefaultFloorPlan();
    this.loadFloorPlan(floorPlanPromise);
  }

  private async loadFloorPlan(
    floorPlanPromise: Promise<Client.FloorPlan>,
  ): Promise<void> {
    try {
      this.notificationService.loadPromise(floorPlanPromise);
      let floorPlan = await floorPlanPromise;
      if (floorPlan.Id != null) {
        this.navigate.floorPlan(floorPlan.Id, {
          openedFromOverview: true,
        });
      }
    } catch (e: any) {
      if (
        e === 'cancelled' ||
        e === 'backdrop click' ||
        e === 'escape key press'
      ) {
        return;
      }
      if (e instanceof Exception.DefaultDeliveryAddressNotSet) {
        return;
      } else if (e instanceof Exception.CancelException) {
        return;
      } else {
        this.notificationService.exception(
          'newFloorPlanOnDeliveryAddress_unknown_error',
          e,
          'Unknown error while creating a new floor plan',
        );
      }
    }
  }

  public get canChangeUserSettings(): boolean {
    return this.permissions.canChangeUserSettings;
  }

  public get canChangeStoreSettings(): boolean {
    return this.permissions.canChangeStoreSettings;
  }

  public get canChangeStore(): boolean {
    return this.permissions.canChangeStore;
  }

  public get canSetCustomPriceDate(): boolean {
    return this.permissions.canUseCustomPriceDate;
  }

  public customPriceDate: Date | undefined;

  public setCustomPriceDate() {
    this.modalService.setCustomPriceDate();
  }
  public get displayCreateNew() {
    return this.loggedIn && !this.floorplan;
  }

  public get displayNewAppVersion() {
    return this.versionService.displayHasNewAppVersion;
  }

  public updateApp() {
    console.log('Updating');
    this.$window.location.reload();
  }
}
