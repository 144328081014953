import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { FunctionalCoreService } from './functional-core/functional-core.service';
import { NotificationService } from './ts/services/NotificationService';
import { LoginService } from '@Services/LoginService';
import { ConvertFromUserToken } from './identity-and-access/User';
import { StoreService } from './ts/services/StoreService';
import { Bootstrapper } from './bootstrapping/bootstrapper';
import { ApplicationState } from './functional-core/ApplicationState';
import {
  ActiveUser,
  ActiveUserInjector,
} from './functional-core/ambient/activeUser/ActiveUser';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { TranslationService } from 'app/ts/services/TranslationService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [StoreService],
  standalone: false,
})
export class AppComponent implements OnInit {
  applicationState$: Subscription;
  applicationState: ApplicationState = ApplicationState.Initializing;

  user$: Subscription;

  public get displayLoader() {
    return this.isInitializing || this.notificationService.displayLoader;
  }

  public get isInitializing() {
    return this.applicationState == ApplicationState.Initializing;
  }

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private translationService: TranslationService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationService,
    private readonly bootstrapper: Bootstrapper,
    @Inject(ActiveUserInjector) private activeUser: ActiveUser,
    private data: FunctionalCoreService,
    private title: Title,
  ) {
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe((e) => {
        let titleKey = this.title.getTitle();

        titleKey = 'index_title';
        this.translationService
          .translateAsync(titleKey, 'Cabinet Manager')
          .then((title) => {
            if (title == null || title == '') title = titleKey;
            this.title.setTitle(title);
          });
      });

    const me = this;
    this.user$ = this.activeUser.subscribe(async (user) => {
      if (user != null) {
        await me.bootstrapper.bootstrap(true);
      }
    });

    this.applicationState$ = data.ambient.applicationState$.subscribe({
      next(state: ApplicationState) {
        me.applicationState = state;
      },
      error(msg) {},
      async complete() {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
          preserveFragment: true,
          skipLocationChange: true,
          replaceUrl: false,
        };

        await router.navigate(activatedRoute.snapshot.url, navigationExtras);
      },
    });
  }

  async ngOnInit(): Promise<void> {
    // Support for old # angular links - https://stackoverflow.com/a/49534503
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url != null && event.url.startsWith('/#')) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.addConsumerStyles();

    if (this.loginService.isAuthenticated) {
      await this.loginService.updateUserToken();
      const userToken = this.loginService.getCookieUserToken();
      if (userToken) {
        this.data.ambient.activeUser.activeUser = ConvertFromUserToken(
          userToken,
          userToken.UserLogin,
        );
      }
    } else {
      await this.bootstrapper.bootstrap(false);
    }
  }

  private addConsumerStyles() {
    // Create a link element via Angular's renderer to avoid SSR troubles
    let style = this.renderer.createElement('link') as HTMLLinkElement;

    // Add the style to the head section
    this.renderer.appendChild(this.document.head, style);

    // Set type of the link item and path to the css file
    this.renderer.setProperty(style, 'rel', 'stylesheet');
    this.renderer.setProperty(
      style,
      'href',
      this.consumerFile('style/chainStyle.css'),
    );
  }

  /**
   * Combines this.consumerBaseDir and all pathSegments into a path
   */
  public consumerFile(...pathSegments: string[]): string {
    return this.translationService.getConsumerFilePath(...pathSegments);
  }
}
