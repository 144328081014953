import { UserToken } from 'app/ts/UserToken';
import { Permission } from './Permission';
import { TokenType } from './TokenType';
import { TokenType as OldTokenType } from 'app/ts/Interface_DTO_UserToken';

export interface User {
  ConsumerUserId: number | null;
  Expires: string;
  Permissions: Permission[];
  StoreId: number;
  Type: TokenType;
  UserId: number;
  UserLogin: string;
  UserName: string;
  Token: string;
}

export function ConvertFromUserToken(
  userToken: UserToken,
  username: string,
): User {
  const permissions = userToken.Rights.map((r) => r as number);
  const user: User = {
    UserLogin: username,
    UserName: userToken.UserName,
    ConsumerUserId: userToken.ConsumerUserId,
    Expires: userToken.Expires,
    StoreId: userToken.StoreId,
    Token: userToken.Token,
    Type:
      userToken.Type == OldTokenType.Consumer
        ? TokenType.Consumer
        : TokenType.Retail,
    UserId: userToken.UserId ?? -1,
    Permissions: permissions,
  };

  return user;
}
