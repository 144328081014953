import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { Observable, Subscription } from 'rxjs';

/** Various settings mostly related to UI */
export interface ClientSettingData {
  //version: string;

  columnsCustomer: string[];
  columnsProject: string[];
  columnsDeliveryAddress: string[];
  columnsFloorPlan: string[];

  overrideChain: boolean;
  showExtraOverviewTabs: boolean;

  showAllFloorplansOnEmptySearch: boolean;

  corpus: CorpusSetting;
  doors: DoorSetting;
  interior: InteriorSetting;
  d3: D3Setting;
  backing: BackingSetting;
  swing: SwingSetting;
  swingFlex: SwingFlexSetting;
}

export interface InteriorSetting {
  displayRulers: boolean;
  displayPulloutWarnings: boolean;
  displayCorpus: boolean;
  displayHings?: boolean;
  displaySwingFlexDoors?: boolean;
}

export interface BackingSetting {
  displayRulers: boolean;
}

export interface DoorSetting {
  displayRulers: boolean;
  displayPulloutWarnings: boolean;
  displayTopdown: boolean;
}

export interface CorpusSetting {
  displayDoors: boolean;
  displayInterior: boolean;
  displayOtherCabinets: boolean;
}

export interface D3Setting {
  displayDoors: boolean;
  displayInterior: boolean;
  displayCorpus: boolean;
  displayOtherCabinets: boolean;
}

export interface SwingSetting {
  displayDoors: boolean;
  displayRulers: boolean;
  displayTopdown: boolean;
}

export interface SwingFlexSetting {
  displayDoors: boolean;
  displayRulers: boolean;
  displayCorpus: boolean;
  displayTopdown: boolean;
  displayHinges: boolean;
  displayInterior: boolean;
}

type Mutable<ClientSettingData> = {
  -readonly [P in keyof ClientSettingData]: ClientSettingData[P];
};

export interface ClientSetting {
  get data(): Readonly<ClientSettingData>;

  copy(): Mutable<ClientSettingData>;

  // TODO: Either more specific set methods or more specific ClientSettings
  set(data: ClientSettingData): void;

  /**
   * Subscribes to any change in client settings. The subscriber
   * is called immediately with the current value
   * @param next
   */
  subscribe(next: (value: Readonly<ClientSettingData>) => void): Subscription;

  get data$(): Observable<ClientSettingData>;
}

function clientSettingFactory(data: FunctionalCoreService): ClientSetting {
  return {
    data: data.ambient.clientSetting.clientSetting,
    copy(): Mutable<ClientSettingData> {
      return { ...data.ambient.clientSetting.clientSetting };
    },
    set(value: ClientSettingData) {
      data.ambient.clientSetting.setClientSetting(value);
    },
    subscribe(next: (value: Readonly<ClientSettingData>) => void) {
      return data.ambient.clientSetting.subscribeClientSetting(next);
    },
    data$: data.ambient.clientSetting.clientSetting$,
  };
}

/** Use this together with @Inject() in constructors for Services, e.g.
 *    @Inject(ClientSettingInjector) private readonly clientSettings: ClientSetting
 * NOTE: Life times for those services and therefore also for the injected
 * token are controlled by the components that use the service.
 */
export const ClientSettingInjector = new InjectionToken<ClientSetting>(
  'clientsetting',
  {
    factory() {
      const data = inject(FunctionalCoreService);

      return clientSettingFactory(data);
    },
  },
);

/** Use the provider for components, together with @ClientSettingInjector, to ensure that useFactory is called on each instantiation */
export const clientSettingProvider: Provider = {
  provide: ClientSettingInjector,
  useFactory: clientSettingFactory,
  deps: [FunctionalCoreService],
};
