import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO_Consumer from 'app/ts/Interface_DTO_Consumer';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ConsumerOrderService {
  public static readonly Name = 'consumerOrderService';

  constructor(
    private readonly $http: PromisingBackendService,
    private readonly floorPlanService: FloorPlanService,
    private readonly screenshotService: ScreenshotService,
  ) {}

  public async addToBasket(
    floorPlan: Client.FloorPlan,
  ): Promise<Interface_DTO_Consumer.AddToBasketResponse> {
    let dtoFloorPlan = this.floorPlanService.getCompleteFloorPlan(floorPlan);
    let screenshots = await Promise.all(
      this.screenshotService.getAllScreenshots(floorPlan),
    );
    let request: Interface_DTO_Consumer.AddToBasketRequest = {
      FloorPlan: dtoFloorPlan,
      BasketId: App.basketId ? App.basketId : '',
      Screenshots: screenshots,
    };
    console.debug('basketID out', request.BasketId, window.location.search);
    let response =
      await this.$http.post<Interface_DTO_Consumer.AddToBasketResponse>(
        'api/consumerOrder/addToBasket',
        request,
      );

    return response;
  }
}
