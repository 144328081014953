<form
  class="modal-content object-detail customer-detail"
  autocomplete="off"
  (submit)="save()"
>
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('Customer_detail_modal_create_header', 'New Customer')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <customer-edit [detailObject]="dtoObject"> </customer-edit>

    <div
      [textContent]="translate('Customer_detail_modal_create_additional_text', 'Please ensure that email and phone number fields are correctly filled out')"
    ></div>
  </div>

  <div class="modal-footer">
    <div class="btn-group btn-group-right">
      <button
        class="btn btn-primary-custom"
        type="submit"
        [textContent]="translate('Customer_detail_modal_create_button_save', 'Create')"
      ></button>

      <button
        class="btn btn-default"
        type="button"
        (click)="cancel()"
        [textContent]="translate('Customer_detail_modal_create_button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</form>
