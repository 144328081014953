import * as Client from 'app/ts/clientDto/index';
export class FillingMaterial {
  constructor(
    public readonly material: Client.ProductMaterial,
    public readonly isDesign: boolean,
  ) {}

  public get groupSortOrder() {
    return this.isDesign
      ? this.material.designGroupSortOrder
      : this.material.groupSortOrder;
  }

  public equals(other: FillingMaterial): boolean {
    return (
      this.isDesign === other.isDesign && this.material.Id === other.material.Id
    );
  }
}
