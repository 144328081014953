import { HttpParamSerializerService } from 'app/ng/http-param-serializer.service';
import { ModalService } from 'app/ts/services/ModalService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { LoginService } from 'app/ts/services/LoginService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomerBaseVmService {
  constructor(
    public readonly baseVmService: BaseVmService,
    public readonly $httpParamSerializer: HttpParamSerializerService,
    public readonly modalService: ModalService,
    public readonly customerService: CustomerService,
    public readonly loginService: LoginService,
    public readonly notificationService: NotificationService,
  ) {}
}
