import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from 'app/ts/clientDto/Customer';
import Enumerable from 'linq';

@Pipe({
  name: 'updateCounts',
  pure: false,
})
export class UpdateCountsPipe implements PipeTransform {
  constructor() {}

  transform(customers: Customer[] | null): CustomerCounts {
    return this.updateCounts(customers);
  }

  private updateCounts(customers: Customer[] | null): CustomerCounts {
    if (customers == null) {
      return {
        numCustomers: 0,
        numDeliveryAddresses: 0,
        numFloorPlans: 0,
        numProjects: 0,
        selection: [],
      };
    }

    const numCustomers = customers.length;
    const numProjects = customers
      .map((c) => c.Projects.length)
      .reduce((prevProjLen, currProjLen) => prevProjLen + currProjLen, 0);
    const numDeliveryAddresses = Enumerable.from(customers)
      .selectMany((c) => c.Projects)
      .sum((p) => p.DeliveryAddresses.length);
    const numFloorPlans = Enumerable.from(customers)
      .selectMany((c) => c.Projects)
      .selectMany((p) => p.DeliveryAddresses)
      .sum((da) => da.FloorPlans.length);
    const selection = customers;

    const counts = {
      numCustomers: numCustomers,
      numProjects: numProjects,
      numDeliveryAddresses: numDeliveryAddresses,
      numFloorPlans: numFloorPlans,
      selection: selection,
    };

    return counts;
  }
}

export interface CustomerCounts {
  numCustomers: number;
  numProjects: number;
  numDeliveryAddresses: number;
  numFloorPlans: number;
  selection: Customer[];
}
