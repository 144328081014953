import { Language } from 'app/ts/Interface_DTO';

export class I8nContainer {
  private _languages: Language[] = [];

  public get languages() {
    return this._languages;
  }

  public setLanguages(value: Language[]): void {
    this._languages = value;
  }
}
