<div class="modal-content group-selector">
  <div class="modal-header" style="display: block">
    <div>
      <div style="display: flex; justify-content: space-between">
        <h3 class="modal-title" [textContent]="title"></h3>
        <button type="button" class="close" (click)="cancel()">&times;</button>
      </div>
    </div>

    <div class="modal-body">
      <div class="group-toc" *ngIf="displayTableOfContents">
        <div
          class="group-toc-line"
          *ngFor="let group of groups"
          [textContent]="group.name"
          (click)="goto(group)"
          [ngClass]="{'disabled': !group.anyEnabledMembers}"
        ></div>
      </div>

      <div class="groups">
        <div
          class="pickable-group"
          *ngFor="let group of groups"
          [attr.id]="'group' + group.number"
        >
          <h3 [textContent]="group.name"></h3>
          <hr />
          <pickable
            *ngFor="let pickable of group.members"
            class="pickable-overlay-container"
            (click)="select(pickable)"
            [ngClass]="pickableClass"
            [pickable]="pickable"
          ></pickable>
        </div>
      </div>
    </div>
  </div>
</div>
