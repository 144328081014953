import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'subsection-header',
  standalone: true,
  imports: [NgIf],
  templateUrl: './subsection-header.component.html',
  styleUrl: './subsection-header.component.scss',
})
export class SubsectionHeaderComponent {
  @Input()
  text!: string;

  private _isContentVisible: boolean = false;
  @Input()
  public get isContentVisible(): boolean {
    return this._isContentVisible;
  }
  public set isContentVisible(val: boolean) {
    this._isContentVisible = val;
    this.isContentVisibleChange.emit(val);
  }

  @Output()
  isContentVisibleChange = new EventEmitter<boolean>();
}
