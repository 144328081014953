import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { ProductListVm } from 'app/ts/viewmodels/components/ProductListVm';
import Enumerable from 'linq';
import { EditorTypeService } from '../editor-type.service';

@Component({
  selector: 'add-product-vm',
  templateUrl: './addProductDialog.html',
  styleUrls: [
    '../../../../style/modal.scss',
    '../../../../style/product-list.scss',
  ],
})
export class AddProductVm extends BaseVm implements OnInit {
  public fullCatalog = false;

  public cabinetSection!: Client.CabinetSection;
  public productLines: Interface_DTO.ProductLine[] = [];
  public editorAssets: Client.EditorAssets | null = null;
  private _selectedProductLine!: Interface_DTO.ProductLine;

  private cache: Partial<{
    productCategories: Client.ProductCategory[];
  }> = {};

  constructor(
    baseVmService: BaseVmService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly floorPlanService: FloorPlanService,
    private readonly activeModal: NgbActiveModal,
    private readonly editorTypeService: EditorTypeService,
    private assetService: AssetService,
    private partitionQuery: PartitionPlanQueryService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    if (this.partitionQuery.selectedSection) {
      this.selectedProductLine =
        this.assetService.editorAssets.productLines.find(
          (pl) => pl.Id == ProductLineIds.Partition,
        )!;
      //Just add the product to the shared items section
      this.cabinetSection = this.editorTypeService.floorPlan.cabinets
        .filter(
          (c) => c.CabinetType == Interface_Enums.CabinetType.SharedItems,
        )[0]
        .cabinetSections.filter(
          (cs) => cs.CabinetType == Interface_Enums.CabinetType.SharedItems,
        )[0];
    } else {
      this._selectedProductLine = this.cabinetSection.cabinet.productLine;
    }
    this.subscribeTo(this.assetService.editorAssets$, (ea) => {
      this.editorAssets = ea;
      if (ea.fullCatalog) {
        this.productLines = ea.productLines;
      } else {
        this.productLines = ea.productLines.filter((pl) => !pl.OverrideChain);
      }
      this.fullCatalog = ea.fullCatalog;
      if (this.partitionQuery.selectedSection) {
        this.selectedProductLine = this.productLines.find(
          (pl) => pl.Id == ProductLineIds.Partition,
        )!;
      } else {
        this._selectedProductLine = this.cabinetSection.cabinet.productLine;
      }
    });

    this.quantity = 1;
  }

  public get selectedProductLine() {
    if (!this._selectedProductLine) {
      this.selectedProductLine = this.productLines[0];
    }
    return this._selectedProductLine;
  }
  public set selectedProductLine(val: Interface_DTO.ProductLine) {
    this._selectedProductLine = val;
    this.cache = {};
  }

  public get defaultProductLine(): Interface_DTO.ProductLine {
    return this.selectedProductLine;
  }

  public get productCategories(): Client.ProductCategory[] {
    if (!this.cache.productCategories) {
      this.cache.productCategories = this.editorAssets?.productCategories ?? [];
    }
    return this.cache.productCategories;
  }

  public cancel() {
    this.activeModal.close(void 0);
  }

  public get okPossible(): boolean {
    if (this.selectedItem === null || this.selectedItem.Product === null)
      return false;

    return this.selectedItem.isValid;
  }

  public async ok() {
    if (!this.okPossible) return;

    this.selectedItem!.Quantity = this.quantity;
    let floorPlan = this.cabinetSection.cabinet.floorPlan;
    this.floorPlanService.addManualItem(floorPlan, this.selectedItem!);
    this.selectedItem = null;

    try {
      var searchField = <HTMLInputElement>(
        document.getElementById('plr_product_search')
      );
      searchField.setSelectionRange(0, searchField.value.length);
      searchField.focus();
    } catch (e: any) {
      /* Don't care */
    }

    await this.floorPlanService.setChanged(floorPlan);
  }

  // #region selectedItem
  private _selectedItem: Client.ConfigurationItem | null = null;
  public get selectedItem(): Client.ConfigurationItem | null {
    return this._selectedItem;
  }
  public set selectedItem(val: Client.ConfigurationItem | null) {
    this._selectedItem = val;
  }

  public quantity: number = 1;

  public createItemFromProduct(p: ProductListVm.ProductParams): void {
    const product = p.product;
    if (!product) {
      this.selectedItem = null;
      return;
    }

    let materialId: number | null = null;

    if (this.selectedProductLine.Id != ProductLineIds.Partition) {
      if (this.cabinetSection.InteriorMaterialId) {
        //see if product exists in selected material
        for (let mat of product.materials) {
          if (
            mat.Id === this.cabinetSection.InteriorMaterialId &&
            !mat.isOverride
          ) {
            materialId = mat.Id;
            break;
          }
        }
      }
    }
    if (materialId === null) {
      let bestMaterial = ObjectHelper.best(
        product.materials.filter((mat) => !mat.isOverride),
        (mat) => mat.SortOrder,
      );
      if (!bestMaterial) {
        bestMaterial = ObjectHelper.best(
          product.materials,
          (mat) => mat.SortOrder,
        );
      }
      if (bestMaterial) {
        materialId = bestMaterial.Id;
      }
    }
    let item: Client.ConfigurationItem =
      this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.ManuallyAdded,
        product.Id,
        materialId,
        this.cabinetSection,
        this.quantity,
        p.variants,
      );

    this.selectedItem = item;
  }

  // #endregion selectedItem

  public productFilter: string = '';
}
