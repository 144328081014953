import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { CommandInterpreter } from 'app/imperative-shell/command-interpreter';
import { Subscription } from 'rxjs';
import { SetUseFullCatalog } from './SetUseFullCatalog.command';

export interface UseFullCatalog {
  /**
   * TODO: Should probably be called value
   */
  get data(): Readonly<boolean>;

  set(value: boolean): void;

  subscribe(next: (value: boolean) => void): Subscription;
}

function useFullCatalogFactory(
  data: FunctionalCoreService,
  interpreter: CommandInterpreter,
): UseFullCatalog {
  return new UseFullCatalogInstance(data, interpreter);
}

class UseFullCatalogInstance implements UseFullCatalog {
  constructor(
    private fCore: FunctionalCoreService,
    private interpreter: CommandInterpreter,
  ) {}

  get data(): Readonly<boolean> {
    return this.fCore.ambient.clientSetting.useFullCatalog;
  }
  set(value: boolean): void {
    this.interpreter.execute(new SetUseFullCatalog(value));
  }
  subscribe(next: (value: boolean) => void): Subscription {
    return this.fCore.ambient.clientSetting.subscribeUseFullCatalog(next);
  }
}

/** Use this together with @Inject() in constructors for Services, e.g.
 *    @Inject(UseFullCatalogInjector) private readonly useFullCatalog: UseFullCatalog
 */
export const UseFullCatalogInjector = new InjectionToken<UseFullCatalog>(
  'usefullcatalog',
  {
    factory() {
      const data = inject(FunctionalCoreService);
      const interpreter = inject(CommandInterpreter);

      return useFullCatalogFactory(data, interpreter);
    },
  },
);

/** Use the provider for components, together with UseFullCatalogInjector,
 *  to ensure that useFactory is called on each instantiation */
export const useFullCatalogProvider: Provider = {
  provide: UseFullCatalogInjector,
  useFactory: useFullCatalogFactory,
  deps: [FunctionalCoreService, CommandInterpreter],
};
