import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FillingId } from './filling';
import { Door, Module, ModuleId } from './module';
import { PartitionDefaultsService } from './partition-defaults.service';
import {
  PartitionPlan,
  PartitionPlanData,
  PartitionPlanDataService,
} from './partition-plan-data.service';
import {
  CalculationScope,
  CornerProfile,
  NicheProfile,
  Profile,
  ProfileId,
  ProfileType,
  SectionConnection,
  StartStopProfile,
  TProfile,
} from './profile';
import { Section, SectionId } from './section';
import { AssetService } from 'app/ts/services/AssetService';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { Partition, PartitionId } from './partition';
import { PartitionDragType, Side, DoorOpenSide } from 'app/ts/clientDto/Enums';
import Enumerable from 'linq';
import { Rail, RailId, RailPlacement } from './rail';
import * as Interface_DTO from 'app/ts/Interface_DTO';

/**
 * A 'query-interface' to the partition data
 */
@Injectable()
export class PartitionPlanQueryService {
  constructor(
    private data: PartitionPlanDataService,
    private defaults: PartitionDefaultsService,
    private assetService: AssetService,
  ) {}

  public get plan(): Readonly<PartitionPlan> {
    return this.data.plan;
  }

  public getPlanDataForSave(): PartitionPlanData {
    return this.data.getDataForSave();
  }

  //#region Selection getters
  /**
   * Returns the currently selected @see Section, if any.
   * NOTE: If a Partition is selected, also a Section and Module
   * will be selected.
   */
  public get selectedSection(): Readonly<Section> | undefined {
    return this.data.selectedSection;
  }

  /**
   * Returns the currently selected @see Module, if any.
   */
  public get selectedModule(): Readonly<Module> | undefined {
    return this.data.selectedModule;
  }
  //#endregion
  public getAllPartitions(): Readonly<Partition>[] {
    return [...this.plan.partitions];
  }

  public getPartitionForSection(sectionId: SectionId): Partition {
    return this.plan.partitions.find((pt) => pt.sections.includes(sectionId))!;
  }

  public getAllSectionsForPartiion(partition: Partition) {
    return this.plan.sections.filter((s) => partition.sections.includes(s.id));
  }

  public getPartitionSectionIndex(sectionId: SectionId): number {
    return (
      this.getPartitionForSection(sectionId).sections.findIndex(
        (sec) => sec == sectionId,
      ) + 1
    );
  }

  public isPartitionSelected(partitionId: PartitionId): boolean {
    if (!this.selectedSection) return false;

    return (
      this.getPartitionForSection(this.selectedSection.id).id == partitionId
    );
  }

  public getAllSectionsConnectedToSection(sectionId: SectionId): SectionId[] {
    let checkedSections: SectionId[] = [];
    let sectionsToCheck: SectionId[] = [sectionId];

    while (sectionsToCheck.length > 0) {
      let curSection: SectionId | null = sectionsToCheck.shift()!;
      checkedSections.push(curSection);

      let leftProfile = this.getSectionLeftProfile(curSection);
      if (leftProfile) {
        leftProfile.sections.forEach((secCon) => {
          if (
            !checkedSections.includes(secCon.sectionId) &&
            !sectionsToCheck.includes(secCon.sectionId)
          ) {
            sectionsToCheck.push(secCon.sectionId);
          }
        });
      }

      let rightProfile = this.getSectionRightProfile(curSection);
      if (rightProfile) {
        rightProfile.sections.forEach((secCon) => {
          if (
            !checkedSections.includes(secCon.sectionId) &&
            !sectionsToCheck.includes(secCon.sectionId)
          ) {
            sectionsToCheck.push(secCon.sectionId);
          }
        });
      }
    }

    return checkedSections;
  }

  public getSectionMinNumberOfModules(sectionId: SectionId): number {
    let section = this.getSection(sectionId);
    let moduleProduct =
      this.assetService.editorAssets.productsDict[
        this.defaults.defaults.moduleWallProductId
      ];

    let numMaxSizeModules =
      section.width / ProductHelper.maxWidth(moduleProduct);

    return Math.ceil(numMaxSizeModules);
  }

  public getSectionMaxNumberOfModules(sectionId: SectionId): number {
    let section = this.getSection(sectionId);

    let numMinSizeModules = section.width / this.getModuleMinWidth();

    return Math.floor(numMinSizeModules);
  }

  public getWallModuleProductId(): number {
    return this.defaults.defaults.moduleWallProductId;
  }

  public getDoorModuleProductId(): number {
    return this.defaults.defaults.moduleDoorProductId;
  }

  public getEndStopProductId(): number {
    return this.defaults.defaults.endStopProductId;
  }

  public getModuleMinWidth(): number {
    let moduleProduct =
      this.assetService.editorAssets.productsDict[
        this.defaults.defaults.moduleWallProductId
      ];
    return ProductHelper.minWidth(moduleProduct);
  }

  public getModuleMaxWidth(): number {
    let moduleProduct =
      this.assetService.editorAssets.productsDict[
        this.defaults.defaults.moduleWallProductId
      ];
    return ProductHelper.maxWidth(moduleProduct);
  }

  public getModuleMaxHeight(): number {
    let moduleProduct =
      this.assetService.editorAssets.productsDict[
        this.defaults.defaults.moduleWallProductId
      ];
    return ProductHelper.maxHeight(moduleProduct);
  }

  public getModuleMinHeight(): number {
    let moduleProduct =
      this.assetService.editorAssets.productsDict[
        this.defaults.defaults.moduleWallProductId
      ];
    return ProductHelper.minHeight(moduleProduct) + 40; // To keep door minimum height at 400
  }

  public getPartitionBarData(): Interface_DTO.ProductBarData | undefined {
    let doorData = Enumerable.from(
      this.assetService.editorAssets.productDoorData,
    ).firstOrDefault((pdd) => pdd.ProductId === this.getDoorModuleProductId());
    if (!doorData) return undefined;

    return Enumerable.from(doorData.Bars)
      .where((bar) => !bar.ChainOverride)
      .firstOrDefault();
  }

  public getTotalFillingHeight(
    moduleId: ModuleId,
    fillingIndex: number,
    visibleHeight: number,
  ): number {
    let module = this.getModule(moduleId);
    let barData = this.getPartitionBarData();
    if (!barData) return 0;

    let height = visibleHeight;
    const insertionDepth = module.isDoor
      ? Door.fillingInsertionDepth
      : Module.fillingInsertionDepth;

    if (fillingIndex == 0) {
      height += barData.BarBottom + insertionDepth;
    } else if (fillingIndex == module.fillings.length - 1) {
      height += barData.BarTop + insertionDepth;
    } else {
      height += barData.BarBottom + barData.BarTop;
    }
    return Math.round(height);
  }

  public getVisibleFillingHeight(
    moduleId: ModuleId,
    fillingIndex: number,
  ): number {
    let module = this.getModule(moduleId);
    let barData = this.getPartitionBarData();
    if (!barData) return 0;

    let height = module.fillings[fillingIndex].height;
    const insertionDepth = module.isDoor
      ? Door.fillingInsertionDepth
      : Module.fillingInsertionDepth;

    if (fillingIndex == 0) {
      height -= barData.BarBottom + insertionDepth;
    } else if (fillingIndex == module.fillings.length - 1) {
      height -= barData.BarTop + insertionDepth;
    } else {
      height -= barData.BarBottom + barData.BarTop;
    }
    return Math.round(height);
  }

  public getTotalVisibleFillingsHeightForModule(moduleId: ModuleId): number {
    let module = this.getModule(moduleId);

    return module.fillings
      .map((_, i) => {
        return this.getVisibleFillingHeight(moduleId, i);
      })
      .reduce((acc, val) => acc + val);
  }

  public getRail(railId: RailId): Readonly<Rail> {
    return this.data.plan.rails.filter((rail) => rail.id == railId)[0];
  }

  public getSectionMinWidth(sectionId: SectionId): number {
    let moduleMinWidth = this.getModuleMinWidth();
    let { leftReduction, rightReduction } =
      this.getSectionWidthReductions(sectionId);
    return leftReduction + moduleMinWidth + rightReduction;
  }

  public getSection(sectionId: SectionId): Readonly<Section> {
    return this.plan.sections.filter((sec) => sec.id == sectionId)[0];
  }

  public getAllSections(): Readonly<Section>[] {
    return [...this.plan.sections];
  }

  public getLeftOrTopMostConnectedSection(start: SectionId): Readonly<Section> {
    let section = this.getSection(start);
    let profile = section.isInverted
      ? this.getSectionRightProfile(start)
      : this.getSectionLeftProfile(start);

    while (profile instanceof TProfile) {
      if (profile.leftSection.sectionId == section.id)
        section = this.getSection(profile.rightSection.sectionId);
      else if (profile.rightSection.sectionId == section.id)
        section = this.getSection(profile.leftSection.sectionId);
      else break; // Middle section

      profile = section.isInverted
        ? this.getSectionRightProfile(section.id)
        : this.getSectionLeftProfile(section.id);
    }

    return section;
  }

  public getMultiDragAffectedSections(draggedSection: SectionId): {
    aligned: Section[];
    side1: Omit<SectionConnection, 'profileId'>[];
    side2: Omit<SectionConnection, 'profileId'>[];
  } {
    let section = this.getLeftOrTopMostConnectedSection(draggedSection);

    let alignedSections: Section[] = [section as Section];
    let side1Sections: Omit<SectionConnection, 'profileId'>[] = [];
    let side2Sections: Omit<SectionConnection, 'profileId'>[] = [];

    let startProfile = section.isInverted
      ? this.getSectionRightProfile(section.id)
      : this.getSectionLeftProfile(section.id);
    if (startProfile instanceof TProfile) {
      side1Sections.push(startProfile.leftSection);
      side2Sections.push(startProfile.rightSection);
    } else if (startProfile instanceof CornerProfile) {
      if (startProfile.leftSection.sectionId == section.id)
        side2Sections.push(startProfile.rightSection);
      else if (startProfile.rightSection.sectionId == section.id)
        side1Sections.push(startProfile.leftSection);
    }

    let profile = section.isInverted
      ? this.getSectionLeftProfile(section.id)
      : this.getSectionRightProfile(section.id);
    while (
      profile instanceof TProfile &&
      profile.middleSection.sectionId != section.id
    ) {
      if (profile.leftSection.sectionId == section.id) {
        section = this.getSection(profile.rightSection.sectionId);
        side1Sections.push(profile.middleSection);
      } else if (profile.rightSection.sectionId == section.id) {
        section = this.getSection(profile.leftSection.sectionId);
        side2Sections.push(profile.middleSection);
      }

      alignedSections.push(section as Section);
      profile = section.isInverted
        ? this.getSectionLeftProfile(section.id)
        : this.getSectionRightProfile(section.id);
    }

    if (profile instanceof TProfile) {
      side1Sections.push(profile.rightSection);
      side2Sections.push(profile.leftSection);
    } else if (profile instanceof CornerProfile) {
      if (profile.leftSection.sectionId == section.id)
        side1Sections.push(profile.rightSection);
      else if (profile.rightSection.sectionId == section.id)
        side2Sections.push(profile.leftSection);
    }

    return {
      aligned: alignedSections,
      side1: side1Sections,
      side2: side2Sections,
    };
  }

  public getModule(moduleId: ModuleId): Readonly<Module> {
    return this.data.plan.modules.filter((m) => m.id == moduleId)[0];
  }

  public getNeighborModule(
    moduleId: ModuleId,
    side: Side,
  ): Readonly<Module> | undefined {
    let module = this.getModule(moduleId);

    if (side == Side.Left)
      return module.references.previousSectionModuleId
        ? this.getModule(module.references.previousSectionModuleId)
        : undefined;
    else
      return module.references.nextSectionModuleId
        ? this.getModule(module.references.nextSectionModuleId)
        : undefined;
  }

  public getDoorNeighborModule(
    moduleId: ModuleId,
    side: DoorOpenSide,
  ): Readonly<Module> | undefined {
    let module = this.getModule(moduleId);

    if (side == DoorOpenSide.Left)
      return module.references.previousSectionModuleId
        ? this.getModule(module.references.previousSectionModuleId)
        : undefined;
    else
      return module.references.nextSectionModuleId
        ? this.getModule(module.references.nextSectionModuleId)
        : undefined;
  }

  public getAllModules(): Readonly<Module>[] {
    return this.data.plan.modules;
  }

  public getModulesForSection(sectionId: SectionId): Module[] {
    const headModule = this.getHeadModuleForSection(sectionId);
    if (!headModule) return [];

    const modules = [headModule as Module];

    let next = this.getNeighborModule(headModule.id, Side.Right);
    while (next) {
      modules.push(next as Module);
      next = this.getNeighborModule(next.id, Side.Right);
    }
    return modules;
  }

  /**
   * Get the left outermost module of the section
   * @param sectionId
   * @returns
   */
  public getTailModuleForSection(sectionId: SectionId): Readonly<Module> {
    const allModules = this.getAllModules();
    return allModules
      .filter((md) => md.sectionId == sectionId)!
      .find((md) => md.references.nextSectionModuleId == undefined)!;
  }

  /**
   * Get the right outermost module of the section
   * @param sectionId
   * @returns
   */
  public getHeadModuleForSection(sectionId: SectionId): Readonly<Module> {
    let allModules = this.getAllModules();
    return allModules
      .filter((md) => md.sectionId == sectionId)!
      .find((md) => md.references.previousSectionModuleId == undefined)!;
  }

  public getSectionLeftProfile(
    sectionId: SectionId,
  ): Readonly<Profile> | undefined {
    let leftModule = this.getHeadModuleForSection(sectionId);
    return this.data.plan.profiles.find(
      (prf) => prf.id == leftModule.references.leftProfileId,
    );
  }

  public getSectionRightProfile(
    sectionId: SectionId,
  ): Readonly<Profile> | undefined {
    let rightModule = this.getTailModuleForSection(sectionId);
    return this.data.plan.profiles.find(
      (prf) => prf.id == rightModule.references.rightProfileId,
    );
  }

  public getModuleSectionIndex(moduleId: ModuleId) {
    let module = this.getModule(moduleId);
    let modules = this.getModulesForSection(module.sectionId);

    return modules.findIndex((md) => md.id == moduleId) + 1;
  }

  public getFillingPositionFromBottom(
    moduleId: ModuleId,
    fillingId: FillingId,
  ) {
    let module = this.getModule(moduleId);

    let sum = 0;
    for (let i = 0; i < module.fillings.length; i++) {
      let filling = module.fillings[i];
      if (filling.id == fillingId) break;

      sum += filling.height;
      sum += module.bars[i].height;
    }

    return sum;
  }

  public getSectionWidthReductions(sectionId: SectionId): {
    leftReduction: number;
    rightReduction: number;
  } {
    let headModule = this.getHeadModuleForSection(sectionId);
    let tailModule = this.getTailModuleForSection(sectionId);

    let section = this.getSection(sectionId);
    let leftNicheReduction = section.unevenLeftWall
      ? NicheProfile.distFromWallToStartStop
      : 0;
    let rightNicheReduction = section.unevenRightWall
      ? NicheProfile.distFromWallToStartStop
      : 0;

    return {
      leftReduction:
        this.getModuleWidthReduction(headModule.id, Side.Left) +
        leftNicheReduction,
      rightReduction:
        this.getModuleWidthReduction(tailModule.id, Side.Right) +
        rightNicheReduction,
    };
  }

  public getDoorWidthReduction(sectionId: SectionId): number {
    let allModules = this.getModulesForSection(sectionId);

    let reduction = 0;
    for (let i = 0; i < allModules.length; i++) {
      let module = allModules[i];

      if ((i == 0 || i == allModules.length - 1) && module.isDoor) {
        // is edge door
        reduction += 15;
      } else if (module.isDoor && allModules[i + 1].isDoor) {
        // double door
        reduction += 25;

        //skip second door
        i++;
      }
    }

    return reduction;
  }

  public getSectionRailExtensions(
    sectionId: SectionId,
    placement: RailPlacement,
  ): { leftExtension: number; rightExtension: number } {
    let headModule = this.getHeadModuleForSection(sectionId);
    let tailModule = this.getTailModuleForSection(sectionId);

    return {
      leftExtension: this.getModuleRailExtension(
        headModule.id,
        Side.Left,
        placement,
        CalculationScope.Section,
      ),
      rightExtension: this.getModuleRailExtension(
        tailModule.id,
        Side.Right,
        placement,
        CalculationScope.Section,
      ),
    };
  }

  /**
   * Gets the width of a module without the end profile (Corner/TPiece)
   * @param moduleId
   * @returns
   */
  public getModuleWidthReduction(moduleId: ModuleId, side: Side): number {
    let module = this.plan.modules.find((md) => md.id == moduleId)!;

    let profile: Profile | null = null;
    if (side == Side.Left && module.references.leftProfileId) {
      profile = this.plan.profiles.find(
        (prf) => prf.id == module.references.leftProfileId,
      )!;
    }
    if (side == Side.Right && module.references.rightProfileId) {
      profile = this.plan.profiles.find(
        (prf) => prf.id == module.references.rightProfileId,
      )!;
    }

    return profile ? Profile.getReductionForProfile(profile) : 0;
  }

  public getModuleRailExtension(
    moduleId: ModuleId,
    side: Side,
    railPlacement: RailPlacement,
    calculationScope: CalculationScope = CalculationScope.Module,
  ): number {
    let module = this.plan.modules.find((md) => md.id == moduleId)!;

    let profile: Profile | null = null;
    if (side == Side.Left && module.references.leftProfileId) {
      profile = this.plan.profiles.find(
        (prf) => prf.id == module.references.leftProfileId,
      )!;
    }
    if (side == Side.Right && module.references.rightProfileId) {
      profile = this.plan.profiles.find(
        (prf) => prf.id == module.references.rightProfileId,
      )!;
    }

    return profile
      ? Profile.getRailWidthExtension(
          module,
          profile,
          railPlacement,
          calculationScope,
        )
      : 0;
  }

  public getDoorMinSlideExtensionAmount(moduleId: ModuleId): number {
    let module = this.getModule(moduleId);
    if (!module.isDoor) return -1;

    let door = module as Door;

    let leftNeighbor = this.getNeighborModule(moduleId, Side.Left);
    let leftNeighborNeighbor = leftNeighbor
      ? this.getNeighborModule(leftNeighbor.id, Side.Left)
      : undefined;

    let rightNeighbor = this.getNeighborModule(moduleId, Side.Right);
    let rightNeighborNeighbor = rightNeighbor
      ? this.getNeighborModule(rightNeighbor.id, Side.Right)
      : undefined;

    if (
      (this.hasJointProfile(this.selectedSection!.id, Side.Left) &&
        !leftNeighborNeighbor &&
        (door.openDirection == DoorOpenSide.Left ||
          door.openDirection == DoorOpenSide.Both)) ||
      (this.hasJointProfile(this.selectedSection!.id, Side.Right) &&
        !rightNeighborNeighbor &&
        (door.openDirection == DoorOpenSide.Right ||
          door.openDirection == DoorOpenSide.Both))
    )
      return module.width - Door.extraWidth - StartStopProfile.size / 2;

    return module.width - Door.extraWidth;
  }

  public getDoorMaxSlideExtensionAmount(
    moduleId: ModuleId,
    direction: DoorOpenSide,
  ): number {
    let module = this.getModule(moduleId) as Door;

    let sum = 0;
    let doorOverlap = 11; // Value possibly caused by door overlap with wall
    let curModule: Readonly<Module> | undefined = module;
    let neighborModule = this.getDoorNeighborModule(curModule.id, direction);
    while (curModule && !neighborModule?.isDoor) {
      if (curModule.isDoor) sum -= 15;
      curModule = this.getDoorNeighborModule(curModule.id, direction);
      if (curModule)
        neighborModule = this.getDoorNeighborModule(curModule.id, direction);
      if (neighborModule?.isDoor) {
        let door = neighborModule as Door;
        if (
          door.placement == module.placement &&
          door.openDirection != direction
        )
          sum -=
            this.getNeighborDoorExtensionSlideAmount(
              neighborModule.id,
              DoorOpenSide.inverse(direction),
            ) + doorOverlap;
        else if (
          door.placement == module.placement &&
          door.openDirection == direction
        )
          sum -= Module.elementSpacing + StartStopProfile.size + doorOverlap;
        else if (
          door.placement != module.placement &&
          door.openDirection != direction &&
          !this.getDoorNeighborModule(neighborModule.id, direction)
        )
          sum += Module.elementSpacing + StartStopProfile.size;
      }

      sum += curModule ? curModule.width + Module.elementSpacing : 0;
    }

    sum += this.getNeighborModuleStartStopProfileSize(module, direction);
    let section = this.getSection(module.sectionId);
    let leftNicheReduction = section.unevenLeftWall
      ? NicheProfile.distFromWallToStartStop
      : 0;
    let rightNicheReduction = section.unevenRightWall
      ? NicheProfile.distFromWallToStartStop
      : 0;
    if (direction == DoorOpenSide.Left) {
      sum += leftNicheReduction;
    }
    if (direction == DoorOpenSide.Right) {
      sum += rightNicheReduction;
    }

    let { leftExtension, rightExtension } = this.getSectionRailExtensions(
      module.sectionId,
      RailPlacement.Ceiling,
    );

    if (leftExtension != 0) leftExtension = 2;
    if (rightExtension != 0) rightExtension = 2;

    sum += direction == DoorOpenSide.Left ? leftExtension : rightExtension;

    return sum;
  }
  public hasNicheProfile(): boolean {
    let profile = this.plan.profiles.find((p) => p.type === ProfileType.Niche);
    return profile != null;
  }

  public getNeighborDoorExtensionSlideAmount(
    moduleId: ModuleId,
    direction: DoorOpenSide,
  ): number {
    let door = this.getModule(moduleId) as Door;
    if (door.openDirection == DoorOpenSide.Left) {
      if (direction == DoorOpenSide.Left) return door.leftSlideExtensionAmount;
      else if (direction == DoorOpenSide.Right) return 0;
    }
    if (door.openDirection == DoorOpenSide.Right) {
      if (direction == DoorOpenSide.Left) return 0;
      else if (direction == DoorOpenSide.Right)
        return door.rightSlideExtensionAmount;
    }
    if (door.openDirection == DoorOpenSide.Both) {
      if (direction == DoorOpenSide.Left) return door.leftSlideExtensionAmount;
      else if (direction == DoorOpenSide.Right)
        return door.rightSlideExtensionAmount;
    }

    return 0;
  }

  public getNeighborModuleStartStopProfileSize(
    module: Readonly<Module> | undefined,
    direction: DoorOpenSide,
  ): number {
    if (
      (this.getSectionLeftProfile(module!.sectionId)?.type ==
        ProfileType.StartStop &&
        direction == DoorOpenSide.Left) ||
      (this.getSectionRightProfile(module!.sectionId)?.type ==
        ProfileType.StartStop &&
        direction == DoorOpenSide.Right)
    )
      return Module.elementSpacing + StartStopProfile.size;
    else return 0;
  }

  /**
   * Checks if converting the given module id into a door, results in a double door at the section edge
   * @param moduleId
   */
  public doesCreateOutsideDoubleDoors(moduleId: ModuleId): boolean {
    let module = this.getModule(moduleId);
    if (module == null) return false;

    let leftModule = this.getNeighborModule(module.id, Side.Left);
    let rightModule = this.getNeighborModule(module.id, Side.Right);

    if (!leftModule && rightModule?.isDoor) return true;

    if (
      leftModule &&
      !leftModule.references.previousSectionModuleId &&
      leftModule.isDoor
    )
      return true;

    if (
      rightModule &&
      !rightModule.references.nextSectionModuleId &&
      rightModule.isDoor
    )
      return true;

    if (!rightModule && leftModule?.isDoor) return true;

    return false;
  }

  public get partitionPlan$(): Observable<Readonly<PartitionPlan>> {
    return this.data.partitionPlan$;
  }

  public getAllProfiles(): Readonly<Profile>[] {
    return this.plan.profiles;
  }

  public getProfile(profileId: ProfileId): Readonly<Profile> {
    return this.plan.profiles.find((pf) => pf.id == profileId)!;
  }

  public getModuleAbsoluteLeftOrTopProfile(
    moduleId: ModuleId,
  ): Readonly<Profile> {
    // Left if horizontal, top if vertical
    let module = this.getModule(moduleId);
    let section = this.getSection(module.sectionId);

    if (section.isInverted)
      return this.getProfile(module.references.rightProfileId!);
    else return this.getProfile(module.references.leftProfileId!);
  }

  public getModuleAbsoluteRightOrBottomProfile(
    moduleId: ModuleId,
  ): Readonly<Profile> {
    // Right if horizontal, Bottom if vertical
    let module = this.getModule(moduleId);
    let section = this.getSection(module.sectionId);

    if (section.isInverted)
      return this.getProfile(module.references.leftProfileId!);
    else return this.getProfile(module.references.rightProfileId!);
  }

  public isJointProfile(profileId: ProfileId): boolean {
    let profile = this.getProfile(profileId);
    return profile instanceof CornerProfile || profile instanceof TProfile;
  }

  public hasJointProfile(sectionId: SectionId, side: Side): boolean {
    let profile =
      side == Side.Left
        ? this.getSectionLeftProfile(sectionId)
        : this.getSectionRightProfile(sectionId);

    return profile != undefined && this.isJointProfile(profile.id);
  }

  public isTProfile(profileId: ProfileId): boolean {
    let profile = this.getProfile(profileId);
    return profile instanceof TProfile;
  }

  public hasTProfile(sectionId: SectionId, side: Side): boolean {
    let profile =
      side == Side.Left
        ? this.getSectionLeftProfile(sectionId)
        : this.getSectionRightProfile(sectionId);

    return profile != undefined && this.isTProfile(profile.id);
  }

  public isCornerProfile(profileId: ProfileId): boolean {
    let profile = this.getProfile(profileId);
    return profile instanceof CornerProfile;
  }

  public hasCornerProfile(sectionId: SectionId, side: Side): boolean {
    let profile =
      side == Side.Left
        ? this.getSectionLeftProfile(sectionId)
        : this.getSectionRightProfile(sectionId);

    return profile != undefined && this.isCornerProfile(profile.id);
  }

  public getProfileSize(profileId: ProfileId): number {
    let profile = this.getProfile(profileId);
    if (profile instanceof StartStopProfile) return 15;
    else if (profile instanceof CornerProfile || profile instanceof TProfile)
      return Module.fullJointWidth;
    else if (profile instanceof NicheProfile) return 22;

    return 0;
  }

  public getDragType(sectionId: SectionId): PartitionDragType {
    const profiles = this.getAllProfiles().filter((p) =>
      p.sections.map((secCon) => secCon.sectionId).includes(sectionId),
    );
    const isConnected = profiles.some((p) => {
      if (p instanceof CornerProfile) return true;

      if (p instanceof TProfile) {
        let isLeftSection = p.leftSection.sectionId == sectionId;
        let isRightSection = p.rightSection.sectionId == sectionId;
        return isLeftSection || isRightSection;
      }

      return false;
    });

    if (isConnected) return PartitionDragType.MultiDrag;

    if (
      profiles.some(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
    ) {
      let section = this.getSection(sectionId);
      if (section.isHorizontal) return PartitionDragType.YAxisDrag;
      else if (section.isVertical) return PartitionDragType.XAxisDrag;
    }

    return PartitionDragType.FreeDrag;
  }

  public hasDoorAsNeighborModule(moduleId: ModuleId): boolean {
    let module = this.getModule(moduleId);

    let leftModule = this.getNeighborModule(module.id, Side.Left);
    if (leftModule && leftModule.isDoor) return true;

    let rightModule = this.getNeighborModule(module.id, Side.Right);
    if (rightModule && rightModule.isDoor) return true;

    return false;
  }

  public isEdgdeModule(moduleId: ModuleId): boolean {
    let module = this.getModule(moduleId);

    if (!module.references.previousSectionModuleId) return true;
    else if (!module.references.nextSectionModuleId) return true;

    return false;
  }

  public hasTwoDoorsBeside(moduleId: ModuleId, side: DoorOpenSide): boolean {
    let curModule = this.getModule(moduleId);
    if (curModule == null) return false;

    let count = 0;
    while (count < 2) {
      if (side == DoorOpenSide.Left) {
        if (!curModule.references.previousSectionModuleId) return false;

        curModule = this.getModule(
          curModule.references.previousSectionModuleId,
        );
        if (!curModule.isDoor) return false;
      }

      if (side == DoorOpenSide.Right) {
        if (!curModule.references.nextSectionModuleId) return false;

        curModule = this.getModule(curModule.references.nextSectionModuleId);
        if (!curModule.isDoor) return false;
      }

      count++;
    }

    return true;
  }
}
