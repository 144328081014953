import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
export class BarHeightEx {
  constructor(
    bars: {
      barData: Interface_DTO.ProductBarData;
      product: Client.Product | undefined;
    }[],
  ) {
    if (bars.length === 0)
      throw new Error('BarHeight must have at least one bar');
    this.bars = bars.filter(
      (b) => b.product && b.product.Enabled && b.barData.BarType,
    ) as any;

    this.height = bars[0].barData.Height;
    for (let b of bars) {
      if (this.height !== b.barData.Height)
        throw new Error('All bars must have the same height');
    }
    this.isCompatibleWithVerticalBars = !bars.every(
      (b) => b.barData.BarType === Interface_Enums.BarType.Loose,
    );
    this.isCompatibleWithSplitFillings = !bars.every(
      (b) => b.barData.BarType === Interface_Enums.BarType.Loose,
    );
  }

  public readonly bars: {
    barData: Interface_DTO.ProductBarData;
    product: Client.Product;
  }[];

  public readonly height: number;

  public isValid() {
    return this.bars.length > 0;
  }

  public readonly isCompatibleWithVerticalBars: boolean;

  public readonly isCompatibleWithSplitFillings: boolean;
}
