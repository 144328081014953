import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { Backing as Client_Backing } from 'app/ts/clientDto/SubSectionBacking';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { CorpusLogic } from 'app/ts/services/ConfigurationLogic/CorpusLogic';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BackingLogic {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly corpusLogic: CorpusLogic,
  ) {}

  upgrade(cabinetSection: Client.CabinetSection, ruleSet: number) {
    if (ruleSet < 2) {
      this.upgrade_addBackingThicknessToSectionDepth(cabinetSection);
    }
  }

  upgrade_addBackingThicknessToSectionDepth(section: Client.CabinetSection) {
    let extraDepth: number;
    switch (section.backing.backingType) {
      case Interface_Enums.BackingType.Visible:
        extraDepth = section.backing.frontZ;
        break;
      case Interface_Enums.BackingType.Hidden:
        extraDepth = section.backing.backZ;
        break;
      default:
      case Interface_Enums.BackingType.None:
        return;
    }

    section.Depth += extraDepth;

    if (section.leftNeighbor) {
      section.leftNeighbor.Width += extraDepth;
    }
    if (section.rightNeighbor) {
      section.rightNeighbor.Width += extraDepth;
      for (let item of section.rightNeighbor.interior.items)
        item.X += extraDepth;
    }
    this.corpusLogic.recalculate(section, false);
  }

  public recalculatePartOne(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (
      section.CabinetType === Interface_Enums.CabinetType.Swing ||
      section.CabinetType === Interface_Enums.CabinetType.SwingFlex
    ) {
      // For swing cabinets, backing is handled elsewhere.
      return result;
    }

    if (App.debug.showTimings) console.time('Recalculate Backing part one');

    // Ensure we recalculate everything.
    section.backing.clearBackingVariables();

    // Ensure all values are set and valid
    result.push(...this.ensureValuesAreValid(section));

    // The actual calculations start here...

    this.calculateBackingAreas(section);

    // Calculate where the backing items need to be
    let areas = this.calculateOptimalBackings(section);

    // Generate configuration items
    this.generateConfigurationItems(section, areas);

    if (App.debug.showTimings) console.timeEnd('Recalculate Backing part one');

    return result;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (
      section.CabinetType === Interface_Enums.CabinetType.Swing ||
      section.CabinetType === Interface_Enums.CabinetType.SwingFlex
    ) {
      // For swing cabinets, backing is handled elsewhere.
      return result;
    }

    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Backing part two');

    // Update gable fittings
    this.clearBackingFittingsFromGables(section);
    this.addBackingFittingsToGables(section);
    this.setHeightReduction(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Backing part two');

    return result;
  }

  public recalculatePartThree(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (section.CabinetType !== Interface_Enums.CabinetType.SwingFlex) {
      return result;
    }

    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Backing part three');

    // Update gable fittings
    this.addBackingSpaceToFittingGables(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Backing part three');

    return result;
  }

  //#region Validation of values

  private ensureValuesAreValid(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    if (!this.isBackingTypeValid(section.backing)) {
      this.setDefaultBackingType(section.backing);
      result.push({
        cabinetSection: section,
        editorSection: Enums.EditorSection.Backing,
        severity: Enums.RecalculationMessageSeverity.Warning,
        key: 'recalculation_backing_type_reset',
        defaultValue:
          'The selected backing type is no longer valid. Backing has been removed.',
      });
    }
    if (!BackingLogic.isMaterialValid(section.backing))
      BackingLogic.setDefaultMaterial(section.backing);

    return result;
  }

  private isBackingTypeValid(backing: Client_Backing): boolean {
    return (
      backing.backingType !== Interface_Enums.BackingType.Hidden ||
      backing.allowHidden
    );
  }
  private setDefaultBackingType(backing: Client_Backing) {
    backing.setBackingType(Interface_Enums.BackingType.None, false);
  }

  public static isMaterialValid(backing: Client_Backing): boolean {
    let backingMaterialId = backing.material ? backing.material.Id : null;
    if (backingMaterialId === null) {
      return backing.backingType === Interface_Enums.BackingType.None;
    }
    return backing.availableProducts
      .selectMany((product) => product.materials)
      .any((productMaterial) => productMaterial.Id === backingMaterialId);
  }
  private static setDefaultMaterial(backing: Client_Backing) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultBackingMaterialNumber(backing.editorAssets);
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(backing.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(backing.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    backing.material = material ?? null;
  }

  //#endregion Validation of values

  //#region Calculations

  /**
   * Calculates backing areas, taking enabled statuses on existing areas into account
   * @param section
   */
  private calculateBackingAreas(section: Client.CabinetSection) {
    // Take enabled values from existing areas
    let oldAreas = Enumerable.from(
      section.backing.backingAreas.slice(),
    ).orderBy((ba) => ba.position.X);

    // If the number of areas have changed, use position to determine enabled or not
    let usePosition =
      oldAreas.any() && oldAreas.count() != section.interior.gables.length + 1;

    // Clear existing areas
    section.backing.backingAreas.length = 0;

    let addArea = (left: number, right: number, forceDisable: boolean) => {
      // Get enabled value from old area (or default to true)
      let enabled = true;

      if (usePosition) {
        let center = left + (right - left) / 2;
        let area = oldAreas.lastOrDefault((area) => area.leftX < center);
        if (!!area) {
          enabled = area.enabled;
        }
      } else {
        let index = section.backing.backingAreas.length;
        if (oldAreas.count() > index) {
          enabled = oldAreas.elementAt(index).enabled;
        }
      }

      // Create and add new area
      let newArea = new Client.BackingArea();
      newArea.enabled = !forceDisable && enabled;
      newArea.position = {
        X: left,
        Y: CabinetSectionHelper.getInteriorDeductionBottom(section),
      };
      newArea.size = { X: right - left, Y: section.Height };

      section.backing.backingAreas.push(newArea);
    };

    // Calculate correct areas
    let left: number;
    if (section.leftNeighbor) {
      left = section.leftNeighbor.backing.frontZ;
    } else {
      left = CabinetSectionHelper.getBackingDeductionLeft(section);
    }

    let blockNext = false;

    for (let gable of section.interior.gables) {
      let isModuleParent = !!gable.moduleParent;
      addArea(left, gable.leftX, blockNext || isModuleParent);
      left = gable.rightX;
      blockNext = isModuleParent;
    }

    let right: number;
    if (section.rightNeighbor) {
      right = section.rightNeighbor.backing.frontZ;
    } else {
      right =
        section.Width - CabinetSectionHelper.getBackingDeductionRight(section);
    }
    addArea(left, right, blockNext);
  }

  /**
   * Calculates the optimal backing item areas for a given section
   * @param section
   */
  private calculateOptimalBackings(
    section: Client.CabinetSection,
  ): Client.BackingItemArea[] {
    // Calculate left and right X positions
    let leftX = this.calculateLeftX(section);
    let rightX = this.calculateRightX(section);

    // Try if a single backing can cover the whole section
    if (!section.backing.backingAreas.some((ba) => !ba.enabled)) {
      let itemArea = this.createBackingItemArea(section, leftX, rightX);
      if (itemArea) {
        return [itemArea];
      }
    }

    // If we get here, it meens that one backing can not cover the whole section, and we need to calculate backing items for gables

    let allGables = section.interior.gables;
    let gables = allGables.filter((g) => g.Height >= section.InteriorHeight); // Find full height gables
    let gablesNoInUse = allGables.filter(
      (g) => g.Height < section.InteriorHeight,
    ); // Find gables that are not full height

    let itemAreas: Client.BackingItemArea[] = [];

    // Try with only full height gables
    if (
      this.calculateBackingForGables(section, gables, leftX, rightX, itemAreas)
    )
      return itemAreas;

    // Make a run adding low gables from left to right
    while (gablesNoInUse.length > 0) {
      itemAreas = [];

      let lowGable = gablesNoInUse[0];
      gables.push(lowGable);
      gables.sort((a, b) => a.X - b.X);

      gablesNoInUse.splice(0, 1);

      if (
        this.calculateBackingForGables(
          section,
          gables,
          leftX,
          rightX,
          itemAreas,
        )
      )
        return itemAreas;
    }

    // Make a run adding low gables from right to left

    // Reset gables
    gables = allGables.filter((g) => g.Height >= section.InteriorHeight);
    gablesNoInUse = allGables.filter((g) => g.Height < section.InteriorHeight);

    while (gablesNoInUse.length > 0) {
      itemAreas = [];

      let lowGable = gablesNoInUse[gablesNoInUse.length - 1];
      gables.push(lowGable);
      gables.sort((a, b) => a.X - b.X);

      gablesNoInUse.splice(gablesNoInUse.length - 1, 1);

      if (
        this.calculateBackingForGables(
          section,
          gables,
          leftX,
          rightX,
          itemAreas,
        )
      )
        return itemAreas;
    }

    return [];
  }

  /**
   * Calculates the point furthest to the left, where the backing may start
   * @param section
   */
  private calculateLeftX(section: Client.CabinetSection): number {
    let leftX: number;

    // Handle corner left
    let sectionLeft = CabinetSectionHelper.getSectionLeft(section);
    if (sectionLeft) {
      if (sectionLeft.backing.isEmpty) {
        leftX = 0;
      } else {
        leftX = section.backing.frontZ;
      }
    } else {
      // Position based on corpus
      leftX = CabinetSectionHelper.getBackingDeductionLeft(section);

      // Handle inner gable and overlap
      let noOuterGableLeft = leftX < Constants.backingOverlapForGables;
      if (noOuterGableLeft) {
        let firstGable = Enumerable.from(
          section.interior.gables,
        ).firstOrDefault();
        if (
          !!firstGable &&
          firstGable.leftX < leftX + Constants.sizeAndPositionTolerance
        ) {
          leftX = firstGable.rightX;
        }
      }

      leftX -= Constants.backingOverlapForGables;
    }

    return Math.max(leftX, 0);
  }

  /**
   * Calculates the point furthest to the right, where the backing may end
   * @param section
   */
  private calculateRightX(section: Client.CabinetSection): number {
    let rightX: number;

    // Handle corner right
    if (section.rightNeighbor) {
      rightX = section.Width - section.rightNeighbor.backing.backZ;
    } else {
      // Position based on corpus
      let deductionRight =
        CabinetSectionHelper.getBackingDeductionRight(section);
      rightX = section.Width - deductionRight;

      // Handle inner gable and overlap
      let noOuterGableRight =
        deductionRight < Constants.backingOverlapForGables;
      if (noOuterGableRight) {
        let lastGable = Enumerable.from(
          section.interior.gables,
        ).lastOrDefault();
        if (
          !!lastGable &&
          lastGable.rightX > rightX - Constants.sizeAndPositionTolerance
        ) {
          rightX = lastGable.leftX;
        }
      }

      rightX += Constants.backingOverlapForGables;
      rightX = Math.min(rightX, section.Width);
    }

    return rightX;
  }

  /**
   * Creates a backing item area, indicating where a backing item must be created
   * @param section
   * @param leftx
   * @param rightX
   */
  private createBackingItemArea(
    section: Client.CabinetSection,
    leftx: number,
    rightX: number,
  ): Client.BackingItemArea | null {
    if (
      !section.backing.getProduct(rightX - leftx) ||
      !section.backing.material
    ) {
      return null;
    }

    return new Client.BackingItemArea(leftx, rightX);
  }

  /**
   * Calculates backing item areas based on gables (both corpus and interior gables)
   * @param section
   */
  private calculateBackingForGables(
    section: Client.CabinetSection,
    gableItems: Client.ConfigurationItem[],
    leftX: number,
    rightX: number,
    itemAreas: Client.BackingItemArea[],
  ): boolean {
    let success = true;

    let gables = Enumerable.from(gableItems);
    if (gables.count() < 1) {
      return false;
    }

    let lastIndex =
      gables.first().leftX < Constants.backingOverlapForGables ? 0 : -1;
    let maxBackingWidth = section.backing.maximumItemWidth;

    while (lastIndex < gables.count()) {
      // Can one backing item reach the end of the cabinet?
      if (
        rightX - leftX <= maxBackingWidth &&
        !section.backing.backingAreas.some(
          (ba) => !ba.enabled && ba.position.X > leftX,
        )
      ) {
        let item = this.createBackingItemArea(section, leftX, rightX);
        if (item) {
          itemAreas.push(item);
        }

        // We are done. This ensures that we break out of the loop.
        lastIndex = gables.count();
      } else if (
        section.backing.backingAreas[lastIndex + 1].size.X <
        Constants.backingOverlapForGables
      ) {
        lastIndex++;
      } else if (!section.backing.backingAreas[lastIndex + 1].enabled) {
        // No backing in the next area - Moving on...

        lastIndex++;

        if (lastIndex < gables.count()) {
          leftX =
            gables.elementAt(lastIndex).rightX -
            Constants.backingOverlapForGables;
        }
      } else {
        // Try to find the next gable for a backing
        if (lastIndex > 0) {
          leftX =
            gables.elementAt(lastIndex).rightX -
            Constants.backingOverlapForGables;
        }

        let nextIndex = lastIndex + 1;
        let first = nextIndex == 0;
        let nextGable;

        while (nextIndex < gables.count()) {
          if (!section.backing.backingAreas[nextIndex].enabled) {
            nextIndex = gables.count();
          } else {
            // Can a backing item reach the next gable?
            let potentialGable = gables.elementAt(nextIndex);
            let isGableAgainstRightCorpusPanel =
              potentialGable.rightX + Constants.backingOverlapForGables ==
              rightX;
            if (
              !isGableAgainstRightCorpusPanel &&
              potentialGable != null &&
              potentialGable.leftX > leftX &&
              potentialGable.leftX + Constants.backingOverlapForGables <=
                leftX + maxBackingWidth
            ) {
              nextGable = potentialGable;
              nextIndex++;
            } else nextIndex = gables.count();
          }
        }

        if (!nextGable) {
          nextGable = gables.lastOrDefault(
            (g) =>
              g.leftX > leftX &&
              g.leftX + Constants.backingOverlapForGables <=
                leftX + maxBackingWidth,
          );
        }

        if (nextGable) {
          let newItem = this.createBackingItemArea(
            section,
            leftX,
            nextGable.leftX + Constants.backingOverlapForGables,
          );
          if (newItem) {
            itemAreas.push(newItem);
            leftX = nextGable.rightX - Constants.backingOverlapForGables;
            lastIndex = gables.indexOf(nextGable);
          } else {
            lastIndex = gables.count();
            success = false;
          }
        } else {
          nextGable = gables.firstOrDefault((g) => g.centerX > leftX);
          if (nextGable) {
            leftX = nextGable.rightX - Constants.backingOverlapForGables;
            lastIndex = gables.indexOf(nextGable);
          } else {
            lastIndex = gables.count();
          }
          success = false;
        }
      }
    }

    success = this.areAllEanbledAreasCovered(
      section.backing.backingAreas,
      itemAreas,
    );
    return success;
  }

  private areAllEanbledAreasCovered(
    backingAreas: Client.BackingArea[],
    itemAreas: Client.BackingItemArea[],
  ): boolean {
    let nonCoveredAreas = backingAreas
      .filter((ba) => ba.enabled) //disabled areas are "covered"
      .filter((ba) => ba.leftX < ba.rightX)
      .filter(
        (ba) =>
          !itemAreas.some(
            (ia) =>
              ia.start - Constants.backingOverlapForGables <= ba.leftX &&
              ia.end + Constants.backingOverlapForGables >= ba.rightX,
          ),
      );
    if (nonCoveredAreas.length === 0) return true;
    return false;
  }

  //#endregion Calculations

  //#region ConfigurationItem generation

  /**
   * Generates ConfigurationItems for the backings
   * @param section
   * @param itemAreas
   */
  private generateConfigurationItems(
    section: Client.CabinetSection,
    itemAreas: Client.BackingItemArea[],
  ) {
    // Clear existing items
    section.backing.items.length = 0;

    // BackingType none meens no backing at all
    if (section.backing.backingType === Interface_Enums.BackingType.None) {
      return;
    }

    let material = section.backing.material;
    if (!material) {
      return;
    }

    // Pre calulate some stuff
    let posY = 0;
    let desiredHeight = BackingLogic.backingHeight(section);
    if (
      (section.backing.backingType === Interface_Enums.BackingType.Hidden ||
        section.corpus.panelBottom.IsFullSize) &&
      section.corpus.panelBottom.isFullDepth(section)
    ) {
      let bottomHeight = section.corpus.defaultHeightBottom;
      posY += bottomHeight;
    }

    //let desiredHeight = externalBacking ? section.Height : section.InteriorHeight;
    //let posY = externalBacking ? 0 : CabinetSectionHelper.getInteriorDeductionBottom(section);

    let posZ = Constants.BackingFittingExtraDepth;
    // Create a ConfigurationItem for every item area
    for (let itemArea of itemAreas) {
      let width = itemArea.end - itemArea.start;
      let product = section.backing.getProduct(width);
      if (!product) {
        continue;
      }

      let maxHeight = ProductHelper.maxHeight(product, material || undefined);
      let height = Math.min(desiredHeight, maxHeight);

      // Create item
      let item = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Backing,
        product.Id,
        material.Id,
        section,
      );

      // Set dimensions
      item.HeightReduction = section.HeightReductionBacking;
      item.ActualHeight = height;
      item.Height = height;
      item.Width = width;
      item.Depth = section.backing.thickness;

      // Set position
      item.X = itemArea.start;
      item.Y = posY;
      item.Z = posZ;

      // Add the item to the list
      section.backing.items.push(item);
    }
  }

  public static backingHeight(section: Client.CabinetSection): number {
    let desiredHeight = section.Height;
    if (
      (section.backing.backingType === Interface_Enums.BackingType.Hidden ||
        section.corpus.panelBottom.IsFullSize) &&
      section.corpus.panelBottom.isFullDepth(section)
    ) {
      let bottomHeight = section.corpus.defaultHeightBottom;
      desiredHeight -= bottomHeight;
    }
    if (
      (section.backing.backingType === Interface_Enums.BackingType.Hidden ||
        section.corpus.panelTop.IsFullSize) &&
      section.corpus.panelTop.isFullDepth(section)
    ) {
      let topHeight = section.corpus.defaultHeightTop;
      desiredHeight -= topHeight;
    }
    return desiredHeight;
  }

  /**
   * Removes existing backing fittings from corpus and interior gables
   * @param section
   */
  private clearBackingFittingsFromGables(section: Client.CabinetSection) {
    // Corpus items left/right
    for (let item of section.corpus.itemsLeft) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.RecessBacking,
        VariantNumbers.Values.No,
      );
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.FinishingList,
        VariantNumbers.Values.No,
      );
    }
    for (let item of section.corpus.itemsRight) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.RecessBacking,
        VariantNumbers.Values.No,
      );
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.FinishingList,
        VariantNumbers.Values.No,
      );
    }

    // Interior gables
    for (let gable of section.interior.gables) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        gable,
        VariantNumbers.TList,
        VariantNumbers.Values.No,
      );
      ConfigurationItemHelper.addVariantOptionByNumbers(
        gable,
        VariantNumbers.FinishingList,
        VariantNumbers.Values.No,
      );
    }

    // Clear cubes for 3D
    section.backing.fittingCubes.length = 0;
  }

  /**
   * Adds backing fittings to corpus and interior gables
   * @param section
   */
  private addBackingFittingsToGables(section: Client.CabinetSection) {
    if (section.backing.backingType === Interface_Enums.BackingType.None) {
      // If there is no backing, we need no fittings
      return;
    }

    // Check if a backing item reaches the left corpus item
    if (
      section.backing.items.some(
        (bi) =>
          bi.leftX < CabinetSectionHelper.getBackingDeductionLeft(section),
      )
    ) {
      for (let item of section.corpus.itemsLeft) {
        if (
          section.backing.backingType === Interface_Enums.BackingType.Visible
        ) {
          this.addFitting(section, item, VariantNumbers.FinishingList);
        } else {
          this.addFitting(section, item, VariantNumbers.RecessBacking, false);
        }
      }
    }

    // Check if a backing item reaches the right corpus item
    if (
      section.backing.items.some(
        (bi) =>
          bi.rightX >
          section.Width -
            CabinetSectionHelper.getBackingDeductionRight(section),
      )
    ) {
      for (let item of section.corpus.itemsRight) {
        if (
          section.backing.backingType === Interface_Enums.BackingType.Visible
        ) {
          this.addFitting(section, item, VariantNumbers.FinishingList);
        } else {
          this.addFitting(section, item, VariantNumbers.RecessBacking, false);
        }
      }
    }

    // Check interior gables
    for (let gable of section.interior.gables) {
      // Check if any backing item starts or ends behind the gable
      let hasBackingLeft = section.backing.items.some(
        (bi) => Math.abs(bi.leftX - gable.centerX) <= gable.Width / 2,
      );
      let hasBackingRight = section.backing.items.some(
        (bi) => Math.abs(gable.centerX - bi.rightX) <= gable.Width / 2,
      );

      if (gable.isFittingPanel) {
        hasBackingLeft = false;
        hasBackingRight = false;
      }

      // Add list variants if applicable
      if (hasBackingLeft && hasBackingRight) {
        // Gables with different backing items on both sides need a T list
        this.addFitting(section, gable, VariantNumbers.TList);
      } else if (hasBackingLeft || hasBackingRight) {
        // Gables with a backing items on side only need a finishing list
        this.addFitting(section, gable, VariantNumbers.FinishingList);
      }
    }
  }

  private addBackingSpaceToFittingGables(section: Client.CabinetSection) {
    if (!section.isSwingFlex) {
      // If there is no backing, we need no fittings
      return;
    }

    let fittingSpaceGables = Enumerable.from(section.interior.gables).where(
      (g) => g.isFittingPanel,
    );

    // Check interior gables
    for (let gable of fittingSpaceGables) {
      this.addFittingPanelSpacer(section, gable);
    }
  }

  setHeightReduction(section: Client.CabinetSection) {
    //for (let item of section.backing.items) {
    //    item.HeightReduction = item.isHeightReductionPossible(section.cabinet.ProductLineId)
    //        && section.HeightReductionBacking
    //        ;
    //}
  }

  /**
   * Adds a fitting to an item (in the form of an item variant), and creates a cube for 3D
   * @param section
   * @param gableItem
   * @param variantNumber
   */
  private addFitting(
    section: Client.CabinetSection,
    gableItem: Client.ConfigurationItem,
    variantNumber: string,
    addCube3D: boolean = true,
  ) {
    // Add itemVariant
    let itemVariantAdded = ConfigurationItemHelper.addVariantOptionByNumbers(
      gableItem,
      variantNumber,
      VariantNumbers.Values.Yes,
    );

    // Add cube for 3D, if applicable
    if (itemVariantAdded && addCube3D) {
      let height = gableItem.Height;
      let y = gableItem.Y;
      let backingItem = section.backing.items[0];
      if (backingItem) {
        height = backingItem.Height;
        y = backingItem.Y;
      }

      let depth =
        section.backing.thickness + Constants.BackingFittingExtraDepth;
      let fittingCube = {
        X: gableItem.X,
        Y: y,
        Z: gableItem.Z - depth,
        Height: height,
        Width: gableItem.Width,
        Depth: depth,
      };

      section.backing.fittingCubes.push(fittingCube);
    }
  }

  private addFittingPanelSpacer(
    section: Client.CabinetSection,
    gableItem: Client.ConfigurationItem,
  ): void {
    const _spacerWidth =
      section.swingFlex.productLineProperties.SwingFlexFittingPanelSpacerWidth;

    const _spacerDepth =
      section.swingFlex.productLineProperties.SwingFlexFittingPanelSpacerDepth;

    const _spacerOffset =
      section.swingFlex.productLineProperties.SwingFlexFittingPanelSpacerOffset;

    let _startX: number = gableItem.leftX - _spacerWidth;

    if (gableItem.drilledLeft) {
      _startX = gableItem.rightX;
    }

    let fittingCube: Interface_DTO_Draw.Cube = {
      X: _startX,
      Y: gableItem.bottomY,
      Z: gableItem.Depth - _spacerOffset,
      Height: gableItem.Height,
      Width: _spacerWidth,
      Depth: _spacerDepth,
    };

    section.backing.fittingCubes.push(fittingCube);
  }

  //#endregion ConfigurationItem generation
}
