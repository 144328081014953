import { DoorPlacement, ModuleType } from './module';
import { SectionId } from './section';

export interface Rail {
  id: RailId;

  relativeX: number; // Used for recalculating the position
  relativeY: number; // Used for recalculating the position

  posX: number;
  posY: number;
  positionedFromSection: SectionId;

  width: number;
  depth: number;
  height: number;

  rotation: number;

  productId: number;

  materialId: number; //If a rail does not have materials it is locked to the first one

  placement: RailPlacement;
  doorPlacement?: DoorPlacement;

  moduleType: ModuleType;

  positionNo: number; // position number as assigned on the piecelist
}

export namespace Rail {
  export const railOverhang: number = 4; //precise rounded up
  export const railOverhangPrecise: number = 3.5; //48 (rail) - 41 (module) = 7 / 2 = 3.5
}

export type RailId = number & { _railIds: never };

export enum RailPlacement {
  Ceiling = 'ceiling',
  Floor = 'floor',
}
