import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Exception from 'app/ts/exceptions';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { LoginService } from 'app/ts/services/LoginService';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  ActiveStoreInjector,
  ActiveStore,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { Store } from 'app/ts/Interface_DTO';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Injectable()
export class DeliveryAddressService {
  public static readonly Name = 'deliveryAddressService';
  public static readonly validPhoneRegex = /^\+?[0-9\- \(\)]{8,19}$/;
  public static readonly validEmailRegex = /^[^\s@]+@[^\s@]+$/; //no whitespace, one @,

  private requireEmail: boolean = false;
  private store: Store;
  constructor(
    private readonly $http: PromisingBackendService,
    private readonly customerService: CustomerService,
    readonly loginService: LoginService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    loginService.salesChainSettings$
      .pipe(takeUntilDestroyed())
      .subscribe(
        (settings) =>
          (this.requireEmail =
            settings[
              Interface_Enums.SalesChainSettingKey.RequireEmailWhenOrdering
            ] == '1'),
      );
    this.store = activeStore.data;
    activeStore.data$.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.store = value;
    });
  }

  public getEmptyDeliveryAddress(
    projectId: number,
  ): Interface_DTO.DeliveryAddress {
    let countryCode = this.store ? this.store.Address.CountryCode : '';
    return {
      Id: 0,
      Address1: '',
      Address2: '',
      City: '',
      Country: countryCode,
      Email: '',
      Floor: 0,
      Name: '',
      Phone: '',
      PostalCode: '',
      ProjectId: projectId,
      Unit: '',
      CreatedDate: DateHelper.toIsoString(new Date(Date.now())),
      FloorPlans: [],
      ProjectDeliveryAddressId: -1,
      StoreId: null,
      ERPCode: null,
      UserId: null, //is set server-side
    };
  }

  public createCustomerAddress(
    project: DTO.Project,
    customer: DTO.Customer,
  ): Interface_DTO.DeliveryAddress {
    let emptyAddr = this.getEmptyDeliveryAddress(project.Id);
    return {
      ...emptyAddr,
      Address1: customer.Address1,
      Address2: customer.Address2,
      City: customer.City,
      Country: customer.Country,
      Email: customer.Email,
      Name: customer.Name,
      Phone: customer.Phone,
      PostalCode: customer.PostalCode,
      Floor: customer.Floor,
    };
  }

  public async createDeliveryAddress(
    addr: Interface_DTO.DeliveryAddress,
  ): Promise<Interface_DTO.DeliveryAddress> {
    this.validateDeliveryAddress(addr);
    let newAddress = await this.$http.put<Interface_DTO.DeliveryAddress>(
      'api/deliveryaddresses/',
      addr,
    );

    this.customerService.updateCustomerList();
    return newAddress;
  }

  public async updateDeliveryAddress(
    addr: Interface_DTO.DeliveryAddress,
  ): Promise<Interface_DTO.DeliveryAddress> {
    this.validateDeliveryAddress(addr);
    let copy = ObjectHelper.copy(ObjectHelper.getDtoObject(addr));
    copy.FloorPlans = [];
    let newAddress = await this.$http.post<Interface_DTO.DeliveryAddress>(
      'api/deliveryaddresses/',
      copy,
      { responseType: 'void' },
    );
    this.customerService.updateCustomerList();
    return newAddress;
  }

  public validateDeliveryAddress(addr: Interface_DTO.DeliveryAddress): void {
    let requiredProperties: (keyof Interface_DTO.DeliveryAddress)[] = [
      'Name',
      'Address1',
      'City',
      'PostalCode',
      'Country',
    ];
    for (let propName of requiredProperties) {
      if (!addr[propName]) throw new Exception.PropertyInvalid(addr, propName);
    }

    if (this.requireEmail) {
      if (!DeliveryAddressService.validEmailRegex.test(addr.Email)) {
        throw new Exception.PropertyInvalid(addr, 'Email');
      }
    }

    if (!DeliveryAddressService.validPhoneRegex.test(addr.Phone))
      throw new Exception.PropertyInvalid(addr, 'Phone');
  }

  public async updateBulkDeliveryAddresses(
    bulkAddress: Partial<Interface_DTO.DeliveryAddress>,
    addressIds: number[],
  ) {
    throw new Error('Not implemented');
  }

  public async deleteDeliveryAddresses(projectDeliveryAddresses: number[]) {
    if (projectDeliveryAddresses.length === 0) return;
    await this.$http.post<void>(
      'api/deliveryaddresses/delete',
      projectDeliveryAddresses,
      { responseType: 'void' },
    );

    await this.customerService.updateCustomerList();
  }

  public async copyDeliveryAddress(
    projectDeliveryAddressId: number,
    projectIds: number[],
    numCopies: number,
  ) {
    let copyReqquest: Interface_DTO.DeliveryAddressCopyRequest = {
      ProjectDeliveryAddressId: projectDeliveryAddressId,
      TargetProjectIds: projectIds,
      NumCopies: numCopies,
    };
    let result = await this.$http.post(
      'api/deliveryaddresses/copy',
      copyReqquest,
    );

    await this.customerService.updateCustomerList();
  }

  public getDeliveryAddressForDeliveryInfoId(
    id: number,
  ): Promise<Interface_DTO.DeliveryAddress | undefined> {
    return firstValueFrom(this.customerService.customers$).then((customers) => {
      for (let c of customers) {
        for (let p of c.Projects) {
          for (let da of p.DeliveryAddresses) {
            for (let fpo of da.FloorPlans) {
              if (fpo.DeliveryInfoId == id) {
                return da;
              }
            }
          }
        }
      }
      return undefined;
    });
  }

  public async getDefaultProjectDeliveryAddress(): Promise<number> {
    let result = await this.$http.get<number>(
      'api/deliveryaddresses/defaultProjectDeliveryAddressId',
    );

    await this.customerService.updateCustomerList(true);
    return result;
  }
}
