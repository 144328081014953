<table class="piecelist-table">
  <thead>
    <tr>
      <th width="30px"></th>
      <th [textContent]="translate('piecelist_pos', 'Pos.')"></th>
      <th [textContent]="translate('piecelist_Item_No', 'Item No.')"></th>
      <th
        [textContent]="translate('piecelist_Description', 'Description')"
      ></th>
      <th [textContent]="translate('piecelist_Material', 'Material')"></th>
      <th [textContent]="translate('piecelist_Dimensions', 'Dimensions')"></th>
      <th
        class="rightAlignColumn"
        [textContent]="translate('piecelist_Quantity', 'Quantity')"
      ></th>
      <th
        class="rightAlignColumn"
        [textContent]="translate('piecelist_Price', 'Price')"
      ></th>
      <th
        class="rightAlignColumn"
        [textContent]="translate('piecelist_col_total_excl_VAT', 'Total Excl VAT')"
      ></th>
    </tr>
  </thead>

  <tbody *ngFor="let section of pieceListGroups">
    <tr class="sectionHeadding">
      <td colspan="9" class="sectionHeadding" [textContent]="section.name"></td>
    </tr>

    <tr
      *ngFor="let item of section.items"
      [ngClass]="item.class"
      (click)="rowClicked(item.item)"
    >
      <td (click)="$event.stopPropagation()" class="piecelist-item-expander">
        <a
          [hidden]="!showChevronUp(section.section, item.item)"
          (click)="expand(item.item)"
        >
          <span class="bi bi-chevron-right"></span>
        </a>
        <a
          [hidden]="!showChevronDown(section.section, item.item)"
          (click)="colapse(item.item)"
        >
          <span class="bi bi-chevron-down"></span>
        </a>
      </td>

      <td
        [textContent]="item.position"
        class="piecelist-item-position-number"
      ></td>
      <td [textContent]="item.productNumber" class="piecelist-item-itemno"></td>
      <td
        [textContent]="item.description"
        class="piecelist-item-description"
      ></td>
      <td
        [textContent]="item.material"
        class="piecelist-item-material-name"
      ></td>
      <td
        [textContent]="item.dimensions"
        class="piecelist-item-dimensions"
      ></td>
      <td
        class="rightAlignColumn piecelist-item-amount"
        [textContent]="item.amount"
      ></td>
      <td
        class="rightAlignColumn piecelist-item-unitprice"
        [textContent]="item.unitPrice"
      ></td>
      <td
        class="rightAlignColumn piecelist-item-totalprice"
        [textContent]="item.totalPrice"
      ></td>
    </tr>
  </tbody>

  <tfoot>
    <tr>
      <th></th>
      <th
        colspan="2"
        [textContent]="translate('piecelist_Rounding', 'Rounding')"
      ></th>
      <th
        [textContent]="translate('piecelist_Subject_to_VAT', 'Subject to VAT')"
      ></th>
      <th [textContent]="translate('piecelist_VAT_rate', 'VAT rate')"></th>
      <th [textContent]="translate('piecelist_VAT_amount', 'VAT amount')"></th>
      <th
        colspan="2"
        [textContent]="translate('piecelist_total_incl_VAT', 'Total incl VAT')"
      ></th>
    </tr>

    <tr *ngIf="pieceList">
      <td></td>
      <td colspan="2">
        <span class="currency" [textContent]="pieceList.Currency + ' '"></span>
        <span [textContent]="toMoneyFormat(pieceList.Rounding)"></span>
      </td>
      <td>
        <span class="currency" [textContent]="pieceList.Currency + ' '"></span>
        <span [textContent]="toMoneyFormat(pieceList.PriceWithoutVat)"></span>
      </td>
      <td>
        <span [textContent]="pieceList.VatRate.toFixed(2)"></span>
        %
      </td>
      <td>
        <span class="currency" [textContent]="pieceList.Currency + ' '"></span>
        <span [textContent]="toMoneyFormat(pieceList.Vat)"></span>
      </td>
      <td colspan="3">
        <span class="currency" [textContent]="pieceList.Currency + ' '"></span>
        <span [textContent]="toMoneyFormat(pieceList.PriceWithVat)"></span>
      </td>
    </tr>
  </tfoot>
</table>
