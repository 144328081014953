import { Component, Input } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'error-info',
  templateUrl: 'errorInfo.html',
  styleUrls: ['../../../style/errorInfo.scss'],
})
export class ErrorInfoVm extends BaseVm {
  public static readonly Name: string = 'errorInfo';

  @Input()
  public errorInfo!: Client.ErrorInfo;

  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }
}
