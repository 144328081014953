import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  public error(message: string, x?: any): void {
    console.error(message, x);
  }
}
