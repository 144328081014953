import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
export class DoorHelper {
  /**
   * Gets the right material for when a new filling must be added to the door
   * @param door
   */
  public static getNextFillingMaterial(door: Client.Door) {
    let lastFilling = Enumerable.from(door.fillings).lastOrDefault();
    if (
      lastFilling &&
      lastFilling.material &&
      MaterialHelper.supportsFillingSize(lastFilling.material, door.width)
        .supported
    ) {
      return lastFilling.material;
    }

    return this.getDefaultFillingMaterial(door);
  }

  /**
   * Gets the default material for a new filling
   * @param door
   */
  public static getDefaultFillingMaterial(
    door: Client.Door,
  ): Client.ProductMaterial {
    let profile = door.doorSubSection.profile;
    if (!profile)
      throw new Error('cannot get default filling material for no-door');

    let possibleMaterials = Enumerable.from(profile.materials)
      .where((mat) => mat.Type === Interface_Enums.MaterialType.Normal)
      .where(
        (mat) => MaterialHelper.supportsFillingSize(mat, door.width).supported,
      )
      .orderBy((mat) => (mat.isOverride ? 1 : 0))
      .thenBy((m) => m.SortOrder)
      .thenBy((m) => m.Number);
    let material = possibleMaterials.firstOrDefault(
      (m) =>
        m.Number === Constants.defaultFillingMaterialNumber && !m.isOverride,
    );
    if (!material) {
      material = possibleMaterials.first();
    }

    return material;
  }

  /**
   * Get the difference between door height/width and filling height/width
   * @param material The filling material
   * @param vertical True to get the total space over and under the filling. False to get the total space on the sides of the filling.
   */
  public static getFrameDeductionForFilling(
    door: Client.Door,
    vertical: boolean,
  ): number {
    let frameSize = vertical
      ? this.getFrameSize(door, Interface_Enums.Direction.Top, true) +
        this.getFrameSize(door, Interface_Enums.Direction.Bottom, true)
      : this.getFrameSize(door, Interface_Enums.Direction.Left, true) +
        this.getFrameSize(door, Interface_Enums.Direction.Right, true);

    return frameSize;
  }

  /**
   * Get the size of the frame for a given "direction"
   * @param door
   * @param direction Determines what part of the frame of which to get the width (top/bottom/left/right)
   * @param excludingOverlap False to get the total frame width, true to exclude the part, that overlaps the filling.
   */
  public static getFrameSize(
    door: Client.Door,
    direction: Interface_Enums.Direction,
    excludingOverlap: boolean,
  ): number {
    let frameSize = 0;

    if (door.doorData) {
      switch (direction) {
        case Interface_Enums.Direction.Top:
          frameSize = door.doorData.FrameWidthTop;
          break;

        case Interface_Enums.Direction.Bottom:
          frameSize = door.doorData.FrameWidthBottom;
          break;

        case Interface_Enums.Direction.Left:
          frameSize = door.doorData.FrameWidthLeft;
          break;

        case Interface_Enums.Direction.Right:
          frameSize = door.doorData.FrameWidthRight;
          break;
      }

      if (excludingOverlap) {
        frameSize -= this.getFrameOverlap(door, direction);
      }
    }

    return frameSize;
  }

  /**
   * Get the part of a frame, that overlaps the filling, for a given "direction"
   * @param door
   * @param direction Determines what part of the frame of which to get the overlap (top/bottom/left/right)
   */
  public static getFrameOverlap(
    door: Client.Door,
    direction: Interface_Enums.Direction,
  ): number {
    if (door.doorData) {
      switch (direction) {
        case Interface_Enums.Direction.Top:
          return door.doorData.FrameOverlapTop;

        case Interface_Enums.Direction.Bottom:
          return door.doorData.FrameOverlapBottom;

        case Interface_Enums.Direction.Left:
          return door.doorData.FrameOverlapLeft;

        case Interface_Enums.Direction.Right:
          return door.doorData.FrameOverlapRight;
      }
    }

    return 0;
  }

  /**
   * Gets the ProductBarData for correct bar to place between the two given fillings
   * @param door
   * @param fillingAbove The filling above the potential bar in question
   * @param fillingBelow The filling below the potential bar in question
   */
  public static getCorrectBar(
    door: Client.Door,
    fillingAbove: Client.DoorFilling | null,
    fillingBelow: Client.DoorFilling | null,
  ): Interface_DTO.ProductBarData | null {
    let doorProduct = door.doorSubSection.profile;
    if (doorProduct === null) {
      return null;
    }

    let doorData = door.doorData;
    if (!doorData) {
      return null;
    }
    let fullCatalog =
      door.doorSubSection.editorAssets.fullCatalog &&
      door.doorSubSection.cabinetSection.cabinet.floorPlan
        .FullCatalogAllowOtherProducts;
    let availableBars = Enumerable.from(doorData.Bars).where(
      (bar) => fullCatalog || !bar.ChainOverride,
    );

    let barHeight = door.doorSubSection.barHeight;
    let barType = door.forceFixedBars
      ? Interface_Enums.BarType.Fixed
      : Interface_Enums.BarType.Loose;

    // Do we have two fillings?
    if (
      fillingAbove !== null &&
      fillingBelow !== null &&
      fillingAbove.material !== null &&
      fillingBelow.material !== null
    ) {
      // Is one of them a design filling?
      if (fillingAbove.isDesignFilling || fillingBelow.isDesignFilling) {
        // Does the door support design fillings?
        if (
          availableBars.any(
            (b) =>
              b.BarType === Interface_Enums.BarType.Design &&
              b.Height === barHeight,
          )
        ) {
          barType = Interface_Enums.BarType.Design;
        }
      }
      // If neither filling is a design filling, does the door support fixed bars?
      else if (
        availableBars.any(
          (b) =>
            b.BarType === Interface_Enums.BarType.Fixed &&
            b.Height === barHeight,
        )
      ) {
        // Is the minimum number of filling forced higher than one (by door width or filling material constaints)
        if (door.numberOfFillingsMin > 1) {
          barType = Interface_Enums.BarType.Fixed;
        }
        // ..or is one or both of the fillings rotated?
        else if (fillingAbove.rotated || fillingBelow.rotated) {
          barType = Interface_Enums.BarType.Fixed;
        }
        // ..or are they the different materials?
        else if (
          fillingAbove.material.Number !== fillingBelow.material.Number
        ) {
          barType = Interface_Enums.BarType.Fixed;
        }
        // ..or are they the same material, but the combined height is too high for one filling?
        else if (
          fillingAbove.material.Number === fillingBelow.material.Number &&
          fillingAbove.height + fillingBelow.height > fillingAbove.maxHeight
        ) {
          barType = Interface_Enums.BarType.Fixed;
        } else {
          let maxWidthSingleFilling = ProductHelper.maxWidth(doorProduct);
          if (
            door.fillings.some(
              (f) => f.material !== null && !f.material.IsGlass,
            )
          ) {
            maxWidthSingleFilling = Math.min(
              maxWidthSingleFilling,
              doorData.MaxWidthSingleFillingNonglass,
            );
          }
          if (
            door.fillings.some((f) => f.material !== null && f.material.IsGlass)
          ) {
            maxWidthSingleFilling = Math.min(
              maxWidthSingleFilling,
              doorData.MaxWidthSingleFillingGlass,
            );
          }
          // Is the filling width to big? (and because of that fillings must be rotated...)
          if (door.width > maxWidthSingleFilling) {
            barType = Interface_Enums.BarType.Fixed;
          }
        }
      }
    }

    // Find the ProductBarData with the right type and height
    let barData = availableBars.firstOrDefault(
      (b) => b.BarType === barType && b.Height === barHeight,
    );

    // Some doors may not have loose bars, so we try to find a fixed bar in stead
    if (!barData && barType === Interface_Enums.BarType.Loose) {
      barData = availableBars.firstOrDefault(
        (b) =>
          b.BarType === Interface_Enums.BarType.Fixed && b.Height === barHeight,
      );
    }

    if (!barData) {
      // As a last restort, just return the first ProductBarData in the list, if there is any.
      // This should really never happen, and it suggest bad data...
      barData = availableBars.firstOrDefault();
    }

    if (barData === undefined) return null;
    return barData;
  }

  public static getVisualFillingHeight(
    door: Client.Door,
    fillingIndex: number,
  ) {
    let overlapBelow =
      fillingIndex === 0
        ? DoorHelper.getFrameOverlap(door, Interface_Enums.Direction.Bottom)
        : door.bars[fillingIndex - 1].barTop;

    let overlapAbove =
      fillingIndex < door.fillings.length - 1
        ? door.bars[fillingIndex].barBottom
        : DoorHelper.getFrameOverlap(door, Interface_Enums.Direction.Top);

    return door.fillings[fillingIndex].height - (overlapBelow + overlapAbove);
  }

  public static getTotalVisualFillingHeight(door: Client.Door) {
    let frameSize =
      DoorHelper.getFrameSize(door, Interface_Enums.Direction.Top, false) +
      DoorHelper.getFrameSize(door, Interface_Enums.Direction.Bottom, false);
    let totalHeightWithinFrame = door.height - frameSize;
    return (
      totalHeightWithinFrame -
      (door.doorSubSection.barHeight ? door.doorSubSection.barHeight : 0) *
        door.bars.length
    );
  }

  public static getWidthNumber(
    door: Client.Door,
    standardDoor: boolean,
  ): string {
    const doorWidth = Enumerable.from(door.getAssets().doorWidths)
      .where((dw) => dw.IsStandardDoor === standardDoor)
      .orderBy((dw) => dw.Width)
      .firstOrDefault((dw) => dw.Width >= door.width);
    if (doorWidth) {
      return doorWidth.Number;
    }

    return '';
  }

  public static getMostExpensiveFillingMaterial(door: Client.Door) {
    if (
      door.fillings.length > 0 &&
      !door.fillings.some((f) => f.material === null)
    ) {
      const filling = Enumerable.from(door.fillings)
        .where((f) => !f.isDesignFilling)
        .orderByDescending((f) => f.material !== null && f.material.PriceLevel)
        .firstOrDefault();
      if (filling) {
        return filling.material;
      }
    }

    return null;
  }
}
