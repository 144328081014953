<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()">&times;</button>
    <h3
      class="modal-title"
      [textContent]="translate('consumer_save_floorplan_header', 'Save Solution')"
    ></h3>
  </div>

  <form (submit)="save()">
    <div class="modal-body">
      <div class="input-group">
        <label>
          {{translate('consumer_save_floorplan_name', 'Name')}}
          <input
            type="text"
            [(ngModel)]="name"
            class="form-control"
            autofocus
            required
            autocomplete="floorplan-name"
            id="floorplan-name"
          />
        </label>
      </div>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="close()"
        [textContent]="translate('consumer_save_floorplan_cancel', 'Cancel')"
      ></button>

      <button
        class="btn btn-primary"
        type="submit"
        [textContent]="translate('consumer_save_floorplan_ok', 'Save')"
      ></button>
    </div>
  </form>
</div>
