import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { CabinetSectionValidationService } from 'app/ts/services/Validation/CabinetSectionValidationService';
export class CabinetValidationService {
  public static readonly Name = 'cabinetValidationService';

  constructor() {}

  public accessTest() {
    return 'Cabinet Validation Service access test function';
  }

  public static validate(
    cabinet: Client.Cabinet,
    includeChildren: boolean = true,
  ): Client.ErrorInfo[] {
    let errors: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    errors.push(...CabinetValidationService.validateCornerOpening(cabinet));
    errors.push(...CabinetValidationService.validateProdutLineExpiry(cabinet));
    // errors.push(...CabinetValidationService.validatePriceOfLoaded(cabinet));

    if (includeChildren) {
      for (let section of cabinet.cabinetSections) {
        errors.push(...CabinetSectionValidationService.validate(section));
      }
    }

    return errors;
  }

  /**
   * Validates the opening in corners
   */
  public static validateCornerOpening(
    cabinet: Client.Cabinet,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // Does the cabinet have corners to validate
    if (
      cabinet.CabinetType != Interface_Enums.CabinetType.CornerCabinet &&
      cabinet.CabinetType != Interface_Enums.CabinetType.WalkIn
    ) {
      return result;
    }

    let sections = cabinet.cabinetSections.sort(
      (n1, n2) => n1.CabinetSectionIndex - n2.CabinetSectionIndex,
    );

    for (var i = 0; i < sections.length - 1; i++) {
      let currentSection = sections[i];
      let nextSection = sections[i + 1];

      // Check if sections are the same type (Walk-in has a door section)
      if (currentSection.CabinetType != nextSection.CabinetType) {
        continue;
      }

      let tooSmall = false;
      if (
        CabinetSectionHelper.hasCornerOpeningAffectingGableInCornerLeft(
          currentSection,
        )
      )
        tooSmall = true;
      if (
        CabinetSectionHelper.hasCornerOpeningAffectingGableInCornerRight(
          currentSection,
        )
      )
        tooSmall = true;
      let openingRight =
        CabinetSectionHelper.getPartialCornerOpeningRight(currentSection);
      let openingLeft =
        CabinetSectionHelper.getPartialCornerOpeningLeft(nextSection);
      let totalOpening = openingRight + openingLeft;
      if (totalOpening < Constants.minimumCornerAreaOpening) tooSmall = true;

      if (tooSmall) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Corner area opening',
            'Opening in corner area is too small',
            currentSection,
          ),
        );
      }
    }
    return result;
  }

  static validateProdutLineExpiry(cabinet: Client.Cabinet): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (cabinet.productLine.Expires) {
      let now = new Date();
      let expiresCheck = new Date(cabinet.productLine.Expires);
      expiresCheck.setDate(expiresCheck.getDate() + 1);
      if (now > expiresCheck) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.FloorPlan,
            Interface_Enums.ErrorLevel.Critical,
            'Product line expired',
            new Client.Translatable(
              'validation_product_line_expired_long',
              'The product line {0} is no longer available for purchase',
              cabinet.productLine.Name,
            ),
            cabinet.cabinetSections[0],
          ),
        );
      } else {
        let expiresDisplay = new Date(cabinet.productLine.Expires);
        let dateStr =
          expiresDisplay.getDate() +
          '/' +
          (expiresDisplay.getMonth() + 1) +
          '/' +
          expiresDisplay.getFullYear();
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.FloorPlan,
            Interface_Enums.ErrorLevel.Warning,
            'Product line expires soon',
            new Client.Translatable(
              'validation_product_line_expires_soon_long',
              'The product line {0} expires on {1}. After this date, the solution can no longer be ordered',
              cabinet.productLine.Name,
              dateStr,
            ),
            cabinet.cabinetSections[0],
          ),
        );
      }
    }
    return result;
  }

  /**
   * Validates the price of the loaded cabinet.
   */
  public static validatePriceOfLoaded(
    cabinet: Client.Cabinet,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let priceChanged = Math.random() < 0.05;

    if (priceChanged) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.PieceList,
          Interface_Enums.ErrorLevel.Critical,
          'Price changed',
          'The price has changed',
          cabinet.cabinetSections[0],
        ),
      );
    }

    return result;
  }
}
