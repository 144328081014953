import { Inject, Injectable } from '@angular/core';
import {
  ActiveUser,
  ActiveUserInjector,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  CustomPriceDate,
  CustomPriceDateInjector,
} from 'app/functional-core/ambient/clientSetting/CustomPriceDate';
import { PermissionService } from '../permission.service';

// The UserPanelData can be seen as the view model
// for the UserPanelComponent
@Injectable()
export class UserPanelData {
  constructor(
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(CustomPriceDateInjector) customPriceDate: CustomPriceDate,
    permissions: PermissionService,
  ) {
    this.username = activeUser.data.UserLogin;
    this.displayName = activeUser.data.UserName;
    this.customPriceDate = customPriceDate.data;
    customPriceDate.subscribe((date) => {
      this.customPriceDate = date;
    });

    this.canChangeUserSettings = permissions.canChangeUserSettings;
    this.canChangeStoreSettings = permissions.canChangeStoreSettings;
    this.canChangeStore = permissions.canChangeStore;
    this.canSetCustomPriceDate = permissions.canUseCustomPriceDate;
  }

  public username: string = '';
  public displayName: string = '';
  public canChangeUserSettings: boolean = true;
  public canChangeStoreSettings: boolean = true;
  public canChangeStore: boolean = true;
  public canSetCustomPriceDate: boolean = true;
  public loggedIn: boolean = true;
  public customPriceDate?: Date;
}
