import * as Client from 'app/ts/clientDto/index';
import * as DTO from 'app/ts/interface_dto/index';
import { Collapsible } from 'app/ts/util/Collapsible';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ISelectable } from 'app/ts/util/ISelectable';
import { Copyable } from 'app/ts/interfaces/Copyable';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
export class Customer
  implements
    DTO.Customer,
    Copyable<Customer>,
    ISelectable,
    DtoContainer<DTO.Customer>,
    Collapsible
{
  private _projects: Client.Project[] = [];
  private _childModifiedDate: string = '';

  constructor(public readonly dtoObject: DTO.Customer) {
    if (!dtoObject) throw new Error('ArgumentNull: dto');
    this.Projects = dtoObject.Projects.map(
      (dtoProj) => new Client.Project(dtoProj, this),
    );
  }

  //#region DTO properties

  get Address1() {
    return this.dtoObject.Address1;
  }

  get Address2() {
    return this.dtoObject.Address2;
  }

  get City() {
    return this.dtoObject.City;
  }

  get Country() {
    return this.dtoObject.Country;
  }
  get CreatedDate() {
    return this.dtoObject.CreatedDate;
  }
  get CustomerType() {
    return this.dtoObject.CustomerType;
  }

  get DebitorNo() {
    return this.dtoObject.DebitorNo;
  }

  get Email() {
    return this.dtoObject.Email;
  }

  get Floor() {
    return this.dtoObject.Floor;
  }

  get Id() {
    return this.dtoObject.Id;
  }

  get MajorCustomerId() {
    return this.dtoObject.MajorCustomerId;
  }

  get Name() {
    return this.dtoObject.Name;
  }

  get Phone() {
    return this.dtoObject.Phone;
  }

  get PostalCode() {
    return this.dtoObject.PostalCode;
  }

  get SENumber() {
    return this.dtoObject.SENumber;
  }

  get StoreId() {
    return this.dtoObject.StoreId;
  }

  get Unit() {
    return this.dtoObject.Unit;
  }

  get UserId() {
    return this.dtoObject.UserId;
  }

  //#endregion

  public selected: boolean = false;
  public collapsed: boolean = true;

  public get shortName() {
    return this.Name;
  }

  public get Projects() {
    return this._projects;
  }
  public set Projects(val: Client.Project[]) {
    this._projects = val;
    this.updateDate();
  }

  get ChildModifiedDate() {
    return this._childModifiedDate;
  }

  private updateDate() {
    this._childModifiedDate = DateHelper.maxDate(
      this.dtoObject.CreatedDate,
      ...this._projects.map((proj) => proj.ChildModifiedDate),
    );
  }

  public copy() {
    return new Customer(this.dtoObject);
  }
}
