import { Component, Input } from '@angular/core';
import { FillingsViewBar } from 'app/partition/fillings/bar';
import { FillingsViewModule } from 'app/partition/fillings/module';
import { FillingsViewSection } from 'app/partition/fillings/section';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import * as Client from 'app/ts/clientDto/index';

@Component({
  selector: 'bar-property-sheet',
  templateUrl: './bar-property-sheet.component.html',
  styleUrls: ['./bar-property-sheet.component.scss'],
})
export class BarPropertySheetComponent {
  @Input() selectedModule!: FillingsViewModule;
  @Input() section!: Readonly<FillingsViewSection>;
  @Input() floorPlan!: Client.FloorPlan;
  @Input() selectedBar!: FillingsViewBar;

  constructor(
    private partitionQueryService: PartitionPlanQueryService,
    private partitionCommandService: PartitionPlanCommandService,
  ) {}

  // Returns the index of the module within its section
  public get moduleSectionIndex(): number {
    return this.partitionQueryService.getModuleSectionIndex(
      this.selectedModule.id,
    );
  }

  public get positionY(): number {
    let bar = this.selectedModule.bars.find(
      (bar) => bar.id == this.selectedBar.id,
    )!;

    return Math.round(this.selectedModule.height - bar.position);
  }

  public set positionY(value: number) {
    let newPos = this.selectedModule.height - value;
    newPos = Math.max(0, Math.min(newPos, this.selectedModule.height));
    this.partitionCommandService.updateBarYPosition(
      this.selectedBar.id,
      newPos,
    );
  }

  private get selectedBarIndex(): number {
    return this.selectedModule.bars.findIndex(
      (bar) => bar.id == this.selectedBar.id,
    );
  }

  public get visibleFillingHeightAbove(): number {
    return this.partitionQueryService.getVisibleFillingHeight(
      this.selectedModule.id,
      this.selectedBarIndex + 1,
    );
  }

  public set visibleFillingHeightAbove(value: number) {
    let totalHeight = this.partitionQueryService.getTotalFillingHeight(
      this.selectedModule.id,
      this.selectedBarIndex + 1,
      value,
    );

    this.partitionCommandService.updateFillingHeight(
      this.selectedModule.id,
      this.selectedModule.fillings[this.selectedBarIndex + 1].id,
      this.selectedModule.fillings[this.selectedBarIndex].id,
      totalHeight,
    );
  }

  public get visibleFillingHeightAbovePercent(): number {
    let percent =
      this.visibleFillingHeightAbove /
      this.partitionQueryService.getTotalVisibleFillingsHeightForModule(
        this.selectedModule.id,
      );
    return +(percent * 100).toFixed(1);
  }

  public set visibleFillingHeightAbovePercent(value: number) {
    let normalizedValue = value / 100;
    let visibleHeight =
      normalizedValue *
      this.partitionQueryService.getTotalVisibleFillingsHeightForModule(
        this.selectedModule.id,
      );
    this.visibleFillingHeightAbove = visibleHeight;
  }

  public get visibleFillingHeightBelow(): number {
    return this.partitionQueryService.getVisibleFillingHeight(
      this.selectedModule.id,
      this.selectedBarIndex,
    );
  }

  public set visibleFillingHeightBelow(value: number) {
    let totalHeight = this.partitionQueryService.getTotalFillingHeight(
      this.selectedModule.id,
      this.selectedBarIndex,
      value,
    );

    this.partitionCommandService.updateFillingHeight(
      this.selectedModule.id,
      this.selectedModule.fillings[this.selectedBarIndex].id,
      this.selectedModule.fillings[this.selectedBarIndex + 1].id,
      totalHeight,
    );
  }

  public get visibleFillingHeightBelowPercent(): number {
    let percent =
      this.visibleFillingHeightBelow /
      this.partitionQueryService.getTotalVisibleFillingsHeightForModule(
        this.selectedModule.id,
      );
    return +(percent * 100).toFixed(1);
  }

  public set visibleFillingHeightBelowPercent(value: number) {
    let normalizedValue = value / 100;
    let visibleHeight =
      normalizedValue *
      this.partitionQueryService.getTotalVisibleFillingsHeightForModule(
        this.selectedModule.id,
      );
    this.visibleFillingHeightBelow = visibleHeight;
  }
}
