<div class="overview-content order-confirmer">
  <topbar>
    <ul class="nav navbar-nav">
      <li>
        <form class="navbar-form">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-input"
              name="searchTerm"
              [(ngModel)]="searchTerm"
              [attr.placeholder]="translate('order_confirmer_search_placeholder', 'Search')"
            />
          </div>
          <select
            class="form-select search-select"
            name="selectedChain"
            [(ngModel)]="selectedChain"
            *ngIf="canChangeChain"
          >
            <option
              *ngFor="let chain of selectableChains"
              [ngValue]="chain.value"
            >
              {{chain.name}}
            </option>
          </select>
          <select
            class="form-select search-select"
            name="selectedSalesChain"
            [(ngModel)]="selectedSalesChain"
            *ngIf="canChangeSalesChain"
            [disabled]="!selectedChain"
          >
            <option
              *ngFor="let salesChain of selectableSalesChains"
              [ngValue]="salesChain.value"
            >
              {{salesChain.name}}
            </option>
          </select>
          <select
            class="form-select search-select"
            name="selectedStore"
            [(ngModel)]="selectedStore"
            *ngIf="canChangeStore"
            [disabled]="!selectedSalesChain"
          >
            <option
              *ngFor="let store of selectableStores"
              [ngValue]="store.value"
            >
              {{store.name}}
            </option>
          </select>
          <select
            class="form-select search-select"
            name="selectedSearchStatus"
            [(ngModel)]="selectedSearchStatus"
          >
            <option
              *ngFor="let status of selectableSearchStatuses"
              [ngValue]="status"
            >
              {{status.name}}
            </option>
          </select>
          <button
            class="btn btn-default"
            type="submit"
            [textContent]="translate('order_confirmer_search_button', 'Search')"
            (click)="search(true)"
          >
            Søg
          </button>
        </form>
      </li>
    </ul>
  </topbar>

  <div class="overview-minus-topbar">
    <dark-side-bar [activeLink]="'orderConfirmer'"></dark-side-bar>

    <div class="overview-minus-dark-side-bar">
      <table class="main-table">
        <colgroup>
          <col class="text-col" />
          <col class="short-text-col" />
          <col class="short-text-col" />
          <col class="short-text-col" />
          <col class="number-col" />
          <col class="datetime-col" />
          <col class="text-col" />
          <col class="short-text-col" />
          <col class="short-text-col" />
          <col class="datetime-col" />
        </colgroup>
        <thead [ngClass]="{'sort-reverse': sortReverse}">
          <tr>
            <th
              [textContent]="translate('order_confirmer_col_FloorPlanName', 'Solution name')"
              (click)="sort('FloorPlanName')"
              [ngClass]="{'order-col': sortColumn === 'FloorPlanName'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_OrderNumber', 'Order Number')"
              (click)="sort('OrderNumber')"
              [ngClass]="{'order-col': sortColumn === 'OrderNumber'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_Reference', 'Reference')"
              (click)="sort('Reference')"
              [ngClass]="{'order-col': sortColumn === 'Reference'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_DeliveryStatus', 'Status')"
              (click)="sort('DeliveryStatus')"
              [ngClass]="{'order-col': sortColumn === 'DeliveryStatus'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_DeliveryWeek', 'Delivery week')"
              (click)="sort('DeliveryWeek')"
              [ngClass]="{'order-col': sortColumn === 'DeliveryWeek'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_CorrectionDeadlineDate', 'Correction Deadline')"
              (click)="sort('CorrectionDeadlineDate')"
              [ngClass]="{'order-col': sortColumn === 'CorrectionDeadlineDate'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_StoreName', 'Store Name')"
              (click)="sort('StoreName')"
              [ngClass]="{'order-col': sortColumn === 'StoreName'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_StoreNumber', 'Store Number')"
              (click)="sort('StoreNumber')"
              [ngClass]="{'order-col': sortColumn === 'StoreNumber'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_ModifiedDate', 'Last modified')"
              (click)="sort('ModifiedDate')"
              [ngClass]="{'order-col': sortColumn === 'ModifiedDate'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_TotalPrice', 'Total price ex VAT')"
              (click)="sort('TotalPrice')"
              [ngClass]="{'order-col': sortColumn === 'TotalPrice'}"
              class="bi"
            ></th>
            <th
              [textContent]="translate('order_confirmer_col_DeliveryAddressName', 'Delivery address')"
              (click)="sort('DeliveryAddressName')"
              [ngClass]="{'order-col': sortColumn === 'DeliveryAddressName'}"
              class="bi"
            ></th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let order of orders"
            (click)="selectedOrder = order"
            [ngClass]="{'selected': order === selectedOrder}"
          >
            <td [textContent]="order.FloorPlanName"></td>
            <td [textContent]="order.OrderNumber"></td>
            <td [textContent]="order.Reference"></td>
            <td [textContent]="translateStatus(order.DeliveryStatus)"></td>
            <td [textContent]="order.DeliveryWeek"></td>
            <td [textContent]="formatDate(order.CorrectionDeadlineDate)"></td>
            <td
              [textContent]="joinNonEmpty(order.StoreName, order.StoreAddress, order.StorePostalCode, order.StoreCity)"
            ></td>
            <td [textContent]="order.StoreNumber"></td>
            <td [textContent]="formatDate(order.ModifiedDate)"></td>
            <td>{{order.TotalPrice | number : '2.2-2'}}</td>
            <td
              [textContent]="joinNonEmpty(order.DeliveryAddressName, order.DeliveryAddressAddress, order.DeliveryAddressPostalCode, order.DeliveryAddressCity)"
            ></td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex">
        <div class="flex-spacer"></div>
        <div class="btn-group btn-group">
          <button
            class="btn btn-default"
            (click)="previousPage()"
            [textContent]="translate('order_confirmer_btn_previous', 'Previous')"
            [disabled]="pageNumber == 0"
          ></button>
          <button
            class="btn btn-default"
            (click)="nextPage()"
            [textContent]="translate('order_confirmer_btn_next', 'Next')"
            [disabled]="!hasNextPage"
          ></button>
        </div>
        <div class="flex-spacer"></div>
      </div>

      <div class="right-menu wide" *ngIf="selectedOrder">
        <div class="title">
          <span
            [textContent]="selectedOrder.FloorPlanName"
            style="text-align: center"
          ></span>
          <button class="close title-right" (click)="selectedOrder = null">
            X
          </button>
        </div>
        <div class="body">
          <div>
            <h5
              [textContent]="translate('order_confirmer_rightmenu_header_order', 'Order')"
            ></h5>
            <ul>
              <li [textContent]="selectedOrder.OrderNumber || '-' "></li>
              <li
                [textContent]="translate('order_confirmer_rightmenu_status', 'Status: {0}', translateStatus(selectedOrder.DeliveryStatus))"
              ></li>
              <li
                [textContent]="translate('order_confirmer_rightmenu_delivery_week', 'delivery: week {0}', selectedOrder.DeliveryWeek)"
              ></li>
            </ul>
            <h5
              [textContent]="translate('order_confirmer_rightmenu_store', 'Store')"
            ></h5>
            <ul>
              <li [textContent]="selectedOrder.StoreName"></li>
              <li [textContent]="selectedOrder.StoreAddress"></li>
              <li [textContent]="selectedOrder.StorePostalCode"></li>
              <li [textContent]="selectedOrder.StoreCity"></li>
              <li>
                <a
                  [href]="getMailtoForOrder(selectedOrder, selectedOrder.StoreEmail)"
                  [textContent]="selectedOrder.StoreEmail"
                ></a>
              </li>
              <li>
                <a
                  [href]="'tel:'+selectedOrder.StorePhone"
                  [textContent]="selectedOrder.StorePhone"
                ></a>
              </li>
            </ul>
            <h5
              [textContent]="translate('order_confirmer_rightmenu_deliveryAddress', 'Delivery Address')"
            ></h5>
            <ul>
              <li [textContent]="selectedOrder.DeliveryAddressName"></li>
              <li [textContent]="selectedOrder.DeliveryAddressAddress"></li>
              <li [textContent]="selectedOrder.DeliveryAddressPostalCode"></li>
              <li [textContent]="selectedOrder.DeliveryAddressCity"></li>
              <li>
                <a
                  [href]="getMailtoForOrder(selectedOrder, selectedOrder.DeliveryAddressEmail)"
                  [textContent]="selectedOrder.DeliveryAddressEmail"
                ></a>
              </li>
              <li>
                <a
                  [href]="'tel:'+selectedOrder.DeliveryAddressPhone"
                  [textContent]="selectedOrder.DeliveryAddressPhone"
                ></a>
              </li>
            </ul>
            <h5
              [textContent]="translate('order_confirmer_rightmenu_user', 'Sales')"
            ></h5>
            <ul>
              <li [textContent]="selectedOrder.UserName"></li>
              <li>
                <a
                  [href]="getMailtoForOrder(selectedOrder, selectedOrder.UserEmail)"
                  [textContent]="selectedOrder.UserEmail"
                ></a>
              </li>
              <li>
                <a
                  [href]="'tel:'+selectedOrder.UserPhone"
                  [textContent]="selectedOrder.UserPhone"
                ></a>
              </li>
            </ul>
          </div>
          <div style="height: 20px"></div>
          <div
            *ngIf="canChangeStatus"
            class="input-group"
            style="margin-top: 20px; margin-bottom: 20px"
          >
            <select
              [(ngModel)]="selectedOrderStatus"
              class="form-select"
              (ngModelChange)="changeSelectedOrderStatus = true"
            >
              <option
                *ngFor="let status of selectableOrderStatuses"
                [ngValue]="status"
              >
                {{"order_confirmer_status_name_"+status | translate:
                getDeliveryStatusName(status) }}
              </option>
            </select>
            <button
              type="button"
              class="btn btn-warning"
              [disabled]="!isOrderChanged"
              (click)="changeOrderStatus()"
              [textContent]="translate('order_confirmer_btn_change_status', 'Change Status')"
            ></button>
          </div>

          <table style="width: 100%">
            <tr>
              <td
                [textContent]="translate('order_confirmer_well_delivery_week', 'Delivery week')"
              ></td>
              <td>
                <input
                  type="number"
                  class="form-control"
                  min="1"
                  max="53"
                  [(ngModel)]="selectedOrderWeek"
                  (ngModelChange)="changeSelectedOrderWeek = true"
                />
              </td>
              <td>
                <input type="checkbox" [(ngModel)]="changeSelectedOrderWeek" />
              </td>
            </tr>
            <tr>
              <td
                [textContent]="translate('order_confirmer_well_delivery_reference', 'Reference')"
              ></td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedOrderReference"
                  (ngModelChange)="changeSelectedOrderReference = true"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="changeSelectedOrderReference"
                />
              </td>
            </tr>
            <tr *ngIf="showMarketOrderNumber">
              <td
                [textContent]="translate('order_confirmer_well_market_order', 'Market order number')"
              ></td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selectedOrderMarketOrder"
                  (ngModelChange)="changeSelectedOrderMarketOrder = true"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="changeSelectedOrderMarketOrder"
                />
              </td>
            </tr>
          </table>

          <div class="flex-spacer"></div>

          <div class="btn-group btn-group-right" style="margin-top: 20px">
            <button
              type="button"
              class="btn btn-success"
              (click)="confirmOrder()"
              *ngIf="isSelectedOrderConfirmable"
              [textContent]="translate('order_confirmer_btn_confirm_order', 'Confirm order')"
            ></button>
            <button
              type="button"
              class="btn btn-success"
              (click)="changeOrder()"
              *ngIf="!isSelectedOrderConfirmable"
              [textContent]="translate('order_confirmer_btn_change_order', 'Change order')"
              [disabled]="!isSelectedOrderChangeable"
            ></button>
            <button
              type="button"
              class="btn btn-danger"
              (click)="cancelOrder()"
              [disabled]="!isSelectedOrderCancelable"
              [textContent]="translate('order_confirmer_btn_cancel_order', 'Cancel order')"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
