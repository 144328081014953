import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
@Directive()
export abstract class TableVm<T extends { Id: number }>
  extends BaseVm
  implements OnInit
{
  constructor(
    baseVmService: BaseVmService,
    columns: { [colName: string]: TableColumn<T> },
  ) {
    super(baseVmService);
    this.columns = columns;
  }

  //set by AngularJS efter constructor, before ngOnInit

  @Output()
  onItemSelected = new EventEmitter<T>();

  @Output()
  onForward = new EventEmitter<T>();

  @Output()
  onBackward = new EventEmitter<T>();

  @Input()
  public objects: T[] = [];
  @Input()
  public _displayNavigationLeft: boolean = true;
  @Input()
  public _displayNavigationRight: boolean = true;
  @Input()
  public _showRadioButton: boolean = false;
  @Input()
  public _showCheckBox: boolean = false;

  public onClick(o: T) {
    this.onItemSelected.emit(o);
  }

  public onLeft(o: T) {
    this.onBackward.emit(o);
  }

  public onRight(o: T) {
    this.onForward.emit(o);
  }

  @Input()
  public highlightId: number | null = null;
  @Input()
  public selectionMarker: 'radio' | 'checkbox' | undefined;

  public ngOnInit() {
    this.showRadio = this.selectionMarker == 'radio';
    this.showCheckbox = this.selectionMarker == 'checkbox';
  }

  public getCellValue(columnName: string, object: any): string {
    const column = this.columns[columnName];
    return column.val(object).toString();
  }

  public getColumnTitle(columnName: string, object: any): string {
    const column = this.columns[columnName];
    return column.titleVal != null ? column.titleVal(object).toString() : '';
  }

  public getCellClass(columnName: string, object: any): string {
    const column = this.columns[columnName];
    return (
      columnName + ' ' + (column.cellClass ? column.cellClass(object) : '')
    );
  }

  public getSortedObjects(): T[] {
    const sortedObjects = this.objects.sort((a: T, b: T) => {
      var valueA = this.getRowSortValue(a);
      var valueB = this.getRowSortValue(b);

      let sortDirection = this.reverseOrder ? -1 : 1;
      if (valueA < valueB) return -1 * sortDirection;
      else if (valueA > valueB) return 1 * sortDirection;
      return 0;
    });

    return sortedObjects;
  }

  public showCheckbox: boolean = false;
  public showRadio: boolean = false;
  public reverseOrder: boolean = false;

  public setOrder(order: string) {
    if (this.order === order) {
      this.reverseOrder = !this.reverseOrder;
    } else {
      this.reverseOrder = false;
    }
    this.order = order;
  }

  get displayNavigationLeft() {
    return this._displayNavigationLeft;
  }

  get displayNavigationRight() {
    return this._displayNavigationRight;
  }

  @Input()
  set displayNavigationRight(showNavi: boolean) {
    this._displayNavigationLeft = showNavi;
  }

  @Input()
  set displayNavigationLeft(showNavi: boolean) {
    this._displayNavigationRight = showNavi;
  }

  get showRadioButton() {
    return this._showRadioButton;
  }

  @Input()
  set showRadioButton(showRadio: boolean) {
    this._showRadioButton = showRadio;
  }

  get showCheckBox() {
    return this._showCheckBox;
  }

  @Input()
  set showCheckBox(showCheckBox: boolean) {
    this._showCheckBox = showCheckBox;
  }

  public getRowSortValue(obj: T): string | number {
    let col = this.columns[this.order];
    if (!col) {
      console.error('Could not find column ', this.order, this.columns);
      return 0;
    }
    if (col.sortVal) {
      return col.sortVal(obj);
    } else {
      return col.val(obj);
    }
  }

  public shouldHighlight(obj: T): boolean {
    return this.highlightId !== null && obj.Id === this.highlightId;
  }

  protected columns: { [name: string]: Readonly<TableColumn<T>> };

  abstract selectedColumns: string[];

  abstract readonly leftClass: string;

  abstract readonly rightClass: string;

  abstract order: string;
}
