import { Component, Inject, OnInit } from '@angular/core';
import {
  ActiveUser,
  ActiveUserInjector,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as Interface_Constants from 'app/ts/InterfaceConstants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_OrderConfirmer from 'app/ts/Interface_DTO_OrderConfirmer';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { OrderConfirmerService } from 'app/ts/services/OrderConfirmerService';
import { OrderService } from 'app/ts/services/OrderService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';

@Component({
  //selector: 'order-confirmer-vm',
  templateUrl: 'orderConfirmer.html',
  providers: [OrderConfirmerService, DeliveryAddressService],
  styleUrls: [
    '../../../style/overviews.scss',
    '../../../style/tables.scss',
    '../../../style/orderConfirmer.scss',
    '../../../style/rightMenu.scss',
    '../../../app/ts/viewmodels/components/topbar.scss',
  ],
})
export class OrderConfirmerVm extends BaseVm implements OnInit {
  private _selectedChain: Interface_DTO_OrderConfirmer.Chain | null = null;
  private _selectedSalesChain: Interface_DTO_OrderConfirmer.SalesChain | null =
    null;
  private _selectedStore: Interface_DTO_OrderConfirmer.Store | null = null;

  public selectableChains: OrderConfirmerVm.ComboValue<Interface_DTO_OrderConfirmer.Chain | null>[] =
    [];
  public selectableSalesChains: OrderConfirmerVm.ComboValue<Interface_DTO_OrderConfirmer.SalesChain | null>[] =
    [];
  public selectableStores: OrderConfirmerVm.ComboValue<Interface_DTO_OrderConfirmer.Store | null>[] =
    [];

  private _selectedOrder: Interface_DTO_OrderConfirmer.OrderDetail | null =
    null;

  public searchTerm: string = '';

  public orders: Interface_DTO_OrderConfirmer.OrderDetail[] = [];

  public sortColumn: keyof Interface_DTO_OrderConfirmer.OrderDetail =
    'ModifiedDate';
  public sortReverse = true;
  public pageNumber = 0;
  public hasNextPage = false;

  public canChangeStore = false;
  public canChangeSalesChain = false;
  public canChangeChain = false;
  public canChangeStatus = false;

  public readonly selectableOrderStatuses: Interface_Enums.DeliveryStatus[] = [
    Interface_Enums.DeliveryStatus.Quote,
    Interface_Enums.DeliveryStatus.PendingConfirmation,
    Interface_Enums.DeliveryStatus.Order,
    Interface_Enums.DeliveryStatus.Cancel,
    Interface_Enums.DeliveryStatus.Cancelled,
    Interface_Enums.DeliveryStatus.Confirmed,
    Interface_Enums.DeliveryStatus.Manufactured,
    Interface_Enums.DeliveryStatus.Received,
  ];

  public readonly selectableSearchStatuses: {
    name: string;
    status: Interface_Enums.DeliveryStatus | null;
  }[] = [
    { name: '', status: null },
    { name: '', status: Interface_Enums.DeliveryStatus.Quote },
    { name: '', status: Interface_Enums.DeliveryStatus.PendingConfirmation },
    { name: '', status: Interface_Enums.DeliveryStatus.Confirmed },
    { name: '', status: Interface_Enums.DeliveryStatus.Cancelled },
    { name: '', status: Interface_Enums.DeliveryStatus.Manufactured },
    { name: '', status: Interface_Enums.DeliveryStatus.Delivered },
  ];

  public selectedSearchStatus = this.selectableSearchStatuses[0];

  public getDeliveryStatusName(status: Interface_Enums.DeliveryStatus): string {
    return Interface_Enums.DeliveryStatus[status];
  }

  selectedOrderWeek: number | null = null;
  selectedOrderReference: string | null = null;
  selectedOrderStatus: Interface_Enums.DeliveryStatus | null = null;
  selectedOrderMarketOrder: string | null = null;

  changeSelectedOrderWeek = false;
  changeSelectedOrderReference = false;
  changeSelectedOrderStatus = false;
  changeSelectedOrderMarketOrder = false;

  showMarketOrderNumber: boolean = false;

  constructor(
    baseVmService: BaseVmService,
    private readonly orderConfirmerService: OrderConfirmerService,
    private readonly notificationService: NotificationService,
    private readonly loginService: LoginService,
    private permissions: PermissionService,
    private readonly modalService: ModalService,
    private readonly orderService: OrderService,
    private readonly formattingService: FormattingService,
    private readonly deliveryAddressService: DeliveryAddressService,
    @Inject(ActiveUserInjector) readonly activeUser: ActiveUser,
  ) {
    super(baseVmService);
    this.subscribeTo(this.orderConfirmerService.chains$, (chains) =>
      this.initChains(chains),
    );
    super.ensureUnsubscribe(
      this.activeUser.subscribe((user) => {
        this.canChangeStore = false;
        this.canChangeSalesChain = false;
        this.canChangeChain = false;
        this.canChangeStatus = false;
        if (!user) {
          return;
        }
        this.canChangeStore = this.permissions.canSeeSalesChainStores;
        this.canChangeSalesChain = this.permissions.canSeeChainStores;
        this.canChangeChain = this.permissions.canSeeAllStores;
        this.canChangeStatus = this.permissions.canSetManualDeliveryStatus;
      }),
    );

    this.subscribeTo(this.loginService.salesChainSettings$, (settings) => {
      this.showMarketOrderNumber =
        settings[Interface_Enums.SalesChainSettingKey.ShowMarketOrderNumber] ==
        '1';
    });
  }

  ngOnInit() {
    for (let status of this.selectableSearchStatuses) {
      if (status.status === null) {
        status.name = this.translate(
          'order_confirmer_search_status_any',
          'Any status',
        );
      } else {
        status.name = this.translateStatus(status.status);
      }
    }
  }

  private initChains(chains: Interface_DTO_OrderConfirmer.Chain[]) {
    this.selectableChains = [
      {
        name: this.translate('order_confirmer_select_chain_all', 'All Chains'),
        value: null,
      },
    ];
    this.selectableChains.push(
      ...chains.map((chain) => ({
        name: chain.Name,
        value: chain,
      })),
    );
    this._selectedChain = null;
    this._selectedSalesChain = null;
    this._selectedStore = null;
    if (chains.length === 1) {
      this.selectedChain = chains[0];
    }
    if (chains.length > 0) this.search(true);
  }

  public get selectedChain() {
    return this._selectedChain;
  }
  public set selectedChain(val: Interface_DTO_OrderConfirmer.Chain | null) {
    this._selectedChain = val;
    if (val) {
      this.selectableSalesChains = [
        {
          name: this.translate(
            'order_confirmer_select_salesChain_all',
            'All sales Chains',
          ),
          value: null,
        },
      ];
      this.selectableSalesChains.push(
        ...val.SalesChains.map((salesChain) => ({
          name: salesChain.Name,
          value: salesChain,
        })),
      );

      if (val.SalesChains.length === 1) {
        this.selectedSalesChain = val.SalesChains[0];
      } else {
        this.selectedSalesChain = null;
      }
    } else {
      this.selectableSalesChains = [];
      this.selectedSalesChain = null;
    }
  }

  public get selectedSalesChain() {
    return this._selectedSalesChain;
  }
  public set selectedSalesChain(
    val: Interface_DTO_OrderConfirmer.SalesChain | null,
  ) {
    this._selectedSalesChain = val;
    if (val) {
      this.selectableStores = [
        {
          name: this.translate(
            'order_confirmer_select_store_all',
            'All Stores',
          ),
          value: null,
        },
      ];
      this.selectableStores.push(
        ...val.Stores.map((store) => ({
          name: store.Name,
          value: store,
        })),
      );

      if (val.Stores.length === 1) {
        this.selectedStore = val.Stores[0];
      } else {
        this.selectedStore = null;
      }
    } else {
      this.selectableStores = [];
      this.selectedStore = null;
    }
  }

  public get selectedStore() {
    return this._selectedStore;
  }
  public set selectedStore(val: Interface_DTO_OrderConfirmer.Store | null) {
    this._selectedStore = val;
  }

  public get selectedOrder() {
    return this._selectedOrder;
  }
  public set selectedOrder(
    val: Interface_DTO_OrderConfirmer.OrderDetail | null,
  ) {
    this._selectedOrder = val;
    this.resetOrderChanges();
  }

  public get isOrderChanged(): boolean {
    return (
      this.changeSelectedOrderReference ||
      this.changeSelectedOrderStatus ||
      this.changeSelectedOrderWeek
    );
  }

  public get isSelectedOrderConfirmable() {
    return (
      this.selectedOrder &&
      (this.selectedOrder.DeliveryStatus ===
        Interface_Enums.DeliveryStatus.PendingConfirmation ||
        this.selectedOrder.DeliveryStatus ===
          Interface_Enums.DeliveryStatus.Quote)
    );
  }

  public get isSelectedOrderCancelable() {
    return (
      this.selectedOrder &&
      this.orderService.isCancelable([
        {
          CurrentStatus: this.selectedOrder.DeliveryStatus,
          CorrectionDeadlineDate: this.selectedOrder.CorrectionDeadlineDate,
        },
      ])
    );
  }

  public isSelectedOrderChangeable() {
    return (
      this.isSelectedOrderCancelable &&
      !this.isSelectedOrderConfirmable &&
      (this.changeSelectedOrderWeek || this.changeSelectedOrderReference)
    );
  }

  public previousPage() {
    this.pageNumber = Math.max(0, this.pageNumber - 1);
    return this.search(false);
  }
  public nextPage() {
    this.pageNumber++;
    this.search(false);
  }

  public formatDate(dateString: string | null | undefined): string {
    if (!dateString) return '';
    var date = DateHelper.fromIsoString(dateString);
    return this.formattingService.formatDate(date, false);
  }

  public async search(resetPage: boolean) {
    this.orders = [];
    this.selectedOrder = null;
    if (resetPage) {
      this.pageNumber = 0;
    }
    let searchParams: Interface_DTO_OrderConfirmer.OrderSearch = {
      SearchTerm: this.searchTerm || null,
      DeliveryStatus: this.selectedSearchStatus.status,
      ChainId: null,
      SalesChainId: null,
      StoreId: null,
      OrderColumn: this.sortColumn,
      OrderReverse: this.sortReverse,
      Page: this.pageNumber,
    };
    if (this.selectedStore) {
      searchParams.StoreId = this.selectedStore.Id;
    } else if (this.selectedSalesChain) {
      searchParams.SalesChainId = this.selectedSalesChain.Id;
    } else if (this.selectedChain) {
      searchParams.ChainId = this.selectedChain.Id;
    }
    this.orders = await this.notificationService.loadPromise(
      this.orderConfirmerService.search(searchParams),
    );
    this.hasNextPage =
      this.orders.length === Interface_Constants.OrderConfirmerPageSize;
  }

  public sort(columnName: keyof Interface_DTO_OrderConfirmer.OrderDetail) {
    if (this.sortColumn === columnName) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortColumn = columnName;
      this.sortReverse = false;
    }
    this.search(true);
  }

  public async confirmOrder() {
    if (!this.selectedOrder) return;
    if (!this.isSelectedOrderConfirmable) return;
    await this.confirmOrder2();
  }

  private async confirmOrder2() {
    if (!this.selectedOrder) return;
    let order = this.selectedOrder;
    try {
      const deliveryAddressConfirm =
        await this.deliveryAddressService.getDeliveryAddressForDeliveryInfoId(
          this.selectedOrder.DeliveryInfoId,
        );

      let orderChangeRequest: Interface_DTO_OrderConfirmer.OrderChangeRequest =
        {
          DeliveryInfoId: this.selectedOrder.DeliveryInfoId,
          DeliveryWeek: this.changeSelectedOrderWeek
            ? this.selectedOrderWeek
            : null,
          Reference: this.changeSelectedOrderReference
            ? this.selectedOrderReference
            : null,
          MarketOrderNumber: this.changeSelectedOrderMarketOrder
            ? this.selectedOrderMarketOrder
            : '' || null,
        };

      let userAcceptPromise: Promise<boolean>;
      if (this.selectedOrder.FloorPlanId) {
        userAcceptPromise = this.orderConfirmerService.confirmOrder(
          orderChangeRequest,
          this.selectedOrder.FloorPlanId,
          (deliveryInfo: Interface_DTO.DeliveryInfo) => {
            let wasDeliveryWeekChanged =
              this.selectedOrder!.DeliveryWeek != deliveryInfo.DeliveryWeek;
            return this.modalService.acceptDeliveryInfo(
              deliveryInfo,
              order.FloorPlanName,
              wasDeliveryWeekChanged,
              order.DeliveryStatus,
              deliveryAddressConfirm,
            );
          },
        );
      } else if (this.selectedOrder.RoomId) {
        userAcceptPromise = this.orderConfirmerService.confirmOldOrder(
          orderChangeRequest,
          this.selectedOrder.RoomId,
          (deliveryInfo: Interface_DTO.DeliveryInfo) => {
            let wasDeliveryWeekChanged =
              this.selectedOrder!.DeliveryWeek != deliveryInfo.DeliveryWeek;
            return this.modalService.acceptDeliveryInfo(
              deliveryInfo,
              order.FloorPlanName,
              wasDeliveryWeekChanged,
              order.DeliveryStatus,
              deliveryAddressConfirm,
            );
          },
        );
      } else {
        throw new Error(
          'Unknow order type; neither floorPlanId nor RoomId is set',
        );
      }
      let userAccept = await userAcceptPromise;
      if (!userAccept) return;
      this.notificationService.success(
        'order_confirmer_order_confirm_success',
        'Order Confirmed',
      );
      this.search(false);
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
  }

  public async changeOrder() {
    if (!this.isSelectedOrderChangeable) return;
    this.confirmOrder2();
  }

  public async changeOrderStatus() {
    if (!this.selectedOrder) return;
    if (!this.changeSelectedOrderStatus || this.selectedOrderStatus === null)
      return;

    try {
      await this.notificationService.loadPromise(
        this.orderConfirmerService.changeOrderStatus(
          this.selectedOrder.DeliveryInfoId,
          this.selectedOrderStatus,
        ),
      );
      this.notificationService.success(
        'order_confirmer_change_status_',
        'Order status changed',
      );
      this.search(false);
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
  }

  public async cancelOrder() {
    if (!this.selectedOrder) return;
    if (!this.isSelectedOrderCancelable) return;
    let cancelPromise = this.orderConfirmerService.cancelOrder(
      this.selectedOrder.DeliveryInfoId,
    );
    this.notificationService.loadPromise(cancelPromise);
    try {
      await cancelPromise;
      this.notificationService.success(
        'order_confirmer_order_cancel_success',
        'Order Cancelled',
      );
      this.search(false);
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
  }

  public translateStatus(
    deliveryStatus: Interface_Enums.DeliveryStatus,
  ): string {
    return this.baseVmService.translationService.translate(
      'order_confirmer_status_name_' + deliveryStatus,
      this.baseVmService.translationService.translateDeliveryStatus(
        deliveryStatus,
      ),
    );
  }

  public joinNonEmpty(...strs: string[]): string {
    let nonEmptyStrings = strs.filter((s) => !!s);
    return nonEmptyStrings.join(' ');
  }

  public getMailtoForOrder(
    order: Interface_DTO_OrderConfirmer.OrderDetail,
    recepient: string,
  ): string {
    const subject = this.translate(
      'order_confirmer_email_subject',
      "Regarding '{0}' (order {1})",
      order.FloorPlanName,
      order.OrderNumber,
    );

    const body = this.translate(
      'order_confirmer_email_body',
      "Regarding KA order {1} - '{2}'\nhttps://kaonline.dk/floorplan/{0}",
      order.FloorPlanId,
      order.OrderNumber,
      order.FloorPlanName,
    );

    const result = `mailto:${recepient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    return result;
  }

  private resetOrderChanges() {
    this.changeSelectedOrderReference = false;
    this.changeSelectedOrderStatus = false;
    this.changeSelectedOrderWeek = false;
    this.changeSelectedOrderMarketOrder = false;

    if (this.selectedOrder) {
      this.selectedOrderReference = this.selectedOrder.Reference;
      this.selectedOrderWeek = this.selectedOrder.DeliveryWeek;
      this.selectedOrderStatus = this.selectedOrder.DeliveryStatus;
      this.selectedOrderMarketOrder = this.selectedOrder.MarketOrderNumber;
    } else {
      this.selectedOrderReference = null;
      this.selectedOrderWeek = null;
      this.selectedOrderStatus = null;
      this.selectedOrderMarketOrder = null;
    }
  }
}
export module OrderConfirmerVm {
  export interface ComboValue<T> {
    name: string;
    value: T;
  }
}
