import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { BarType } from 'app/ts/clientDto/Enums';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import { DoorFilling } from 'app/ts/clientDto/DoorFilling';
import { DoorBar } from 'app/ts/clientDto/DoorBar';
import { DoorBarVertical } from 'app/ts/clientDto/DoorBarVertical';
import { DoorProfile } from 'app/ts/clientDto/DoorProfile';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { EditorAssets } from 'app/ts/clientDto/EditorAssets';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { DoorHelper } from 'app/ts/util/DoorHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';

export class Door {
  //#region New stuff - should be moved to a better place in this file

  private _softClose: VariantNumbers.SoftCloseValues =
    VariantNumbers.SoftCloseValues.SoftClose_None;

  private _size: Interface_DTO_Draw.Vec3d = { X: 0, Y: 0, Z: 0 };
  private _numberOfFillings = 1;
  private _fillings: DoorFilling[] = [];
  private _bars: DoorBar[] = [];
  private _verticalBars: DoorBarVertical[] = [];
  private _profiles: DoorProfile[] = [];

  private _gripItemsFront: Client.ConfigurationItem[] =
    new Array<Client.ConfigurationItem>();
  private _gripItemsBack: Client.ConfigurationItem[] =
    new Array<Client.ConfigurationItem>();

  public mustSpreadBarsEvenly: boolean = false;
  public mustCenterGrips: boolean = false;

  //#endregion New stuff - should be moved to a better place in this file

  //#region Local cache

  //#endregion Local cache

  private _barDesign: Client.BarDesign | undefined;

  private forceDesignFilling: boolean = false;
  private _fallBackMaterial: Interface_DTO.Material | undefined;

  //#region Availables, backing variables

  private _minNumberOfFillings: number | undefined;
  private _maxNumberOfFillings: number | undefined;

  private _minGripHeight: number = -1;
  private _maxGripHeight: number = -1;

  private _availableCornerCuts: Interface_DTO.VariantOption[] | undefined;

  //#endregion Availables, backing variables

  //#region Actual values, backing variables

  private _position: Interface_DTO_Draw.Vec3d = { X: 0, Y: 0, Z: 0 };

  private _gripPlacementFront: Interface_DTO.VariantOption | undefined;
  private _gripPlacementBack: Interface_DTO.VariantOption | undefined;

  private _gripHeight: number = -1;

  private _cornerCut: Interface_DTO.VariantOption | undefined;

  private _forceFixedBars: boolean = false;

  private _verticalBarOption: Interface_DTO.VariantOption | undefined;

  //#endregion Actual values, backing variables

  constructor(
    public readonly doorSubSection: Client.Doors,
    private readonly editorAssets: EditorAssets,
  ) {
    let doorProduct = this.doorSubSection.profile;
    if (!doorProduct) {
      return;
    }

    this.height = doorSubSection.height;
    this.depth = ProductHelper.defaultDepth(
      doorProduct,
      doorSubSection.cabinetSection.cabinet.ProductLineId,
    );
    let lastDoor = doorSubSection.doors[doorSubSection.doors.length];
    if (lastDoor) {
      //copy properties from last existing door

      this._fillings = lastDoor.fillings.map((dp) => {
        let f = new DoorFilling(this, editorAssets);
        f.material = dp.material;

        return f;
      });
    }
  }

  public loadFromItem(item: Interface_DTO.ConfigurationItem) {
    this.reset();

    let doorProduct = this.doorSubSection.profile;
    if (!doorProduct) {
      return;
    }

    let productLineId =
      this.doorSubSection.cabinetSection.cabinet.ProductLineId;

    this._softClose = VariantHelper.getItemVariantValue(
      doorProduct.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.SoftClose,
    ) as VariantNumbers.SoftCloseValues;

    // Size and position
    this.width = ConfigurationItemHelper.getDoorWidth(
      item,
      doorProduct,
      productLineId,
    );
    //this.height = ConfigurationItemHelper.getDoorHeight(item, doorProduct);
    this.depth = ProductHelper.defaultDepth(
      doorProduct,
      this.doorSubSection.cabinetSection.cabinet.ProductLineId,
    );
    this._position = { X: item.X, Y: item.Y, Z: item.Z };

    // CornerCut
    let cornerCutOptionNumber =
      ConfigurationItemHelper.getDoorCornerCutOptionNumber(
        item,
        doorProduct,
        productLineId,
      );
    if (cornerCutOptionNumber === '') cornerCutOptionNumber = '0'; // The option "0" is the standard, so we default to that
    this._cornerCut = Enumerable.from(this.availableCornerCuts).first(
      (vo) => vo.Number === cornerCutOptionNumber,
    );

    // Forced fixed bars
    this._forceFixedBars = ConfigurationItemHelper.hasForcedFixedBars(
      item,
      doorProduct,
      productLineId,
    );

    // Number of fillings and bars
    let fillingCount = ConfigurationItemHelper.getDoorFillingCount(
      item,
      doorProduct,
      productLineId,
    );
    let designFillingCount = ConfigurationItemHelper.getDoorDesignFillingCount(
      item,
      doorProduct,
      productLineId,
    );
    let looseBarCount = ConfigurationItemHelper.getDoorLooseBarCount(
      item,
      doorProduct,
      productLineId,
    );
    let maxBarPlacements =
      fillingCount - 1 - designFillingCount + looseBarCount;
    this._numberOfFillings = fillingCount + looseBarCount;

    let extraInfoList = Enumerable.from(item.VariantOptions)
      .where((iv) => iv.VariantOptionId <= VariantNumbers.ExtraInfoOffset)
      .orderByDescending((iv) => iv.VariantOptionId)
      .toArray();
    let endUser = extraInfoList.some(
      (iv) => iv.VariantOptionId === VariantNumbers.EndUserMark,
    );

    if (endUser) {
      for (let i = 0; i < this._numberOfFillings; i++) {
        // Filling
        let filling = new DoorFilling(this, this.editorAssets);
        filling.materialId = item.MaterialId;
        this.fillings.push(filling);

        // Bar
        if (i > 0) {
          this._bars.push(new DoorBar(this));
        }
      }

      this.mustSpreadBarsEvenly = true;
    } else {
      // Grip placement, front
      let frontOptionNumber =
        ConfigurationItemHelper.getGripPlacementFrontOptionNumber(
          item,
          doorProduct,
          productLineId,
        );
      this.gripPlacementFront =
        Enumerable.from(
          this.doorSubSection.availableGripPlacementsFront,
        ).firstOrDefault((vo) => vo.Number === frontOptionNumber) || undefined;

      // Grip placement, back
      let backOptionNumber =
        ConfigurationItemHelper.getGripPlacementBackOptionNumber(
          item,
          doorProduct,
          productLineId,
        );
      this.gripPlacementBack =
        Enumerable.from(
          this.doorSubSection.availableGripPlacementsBack,
        ).firstOrDefault((vo) => vo.Number === backOptionNumber) || undefined;

      // Vertical bars
      let verticalBarOptionNumber =
        ConfigurationItemHelper.getVerticalBarOptionNumber(
          item,
          doorProduct,
          productLineId,
        );
      this.verticalBarOption =
        Enumerable.from(
          this.doorSubSection.availableVerticalBarOptions,
        ).firstOrDefault((vo) => vo.Number === verticalBarOptionNumber) ||
        undefined;

      // Fillings
      let skipNext = false;
      let barPlacementCount = 0;
      let extraInfoIndex = 0;

      for (let i = 0; i < this._numberOfFillings; i++) {
        let filling = new DoorFilling(this, this.editorAssets);

        if (skipNext) {
          skipNext = false;
        } else if (i > 0 && barPlacementCount < maxBarPlacements) {
          let type = ConfigurationItemHelper.getBarType(
            item,
            doorProduct,
            i - 1,
            productLineId,
          );
          if (type === BarType.Fixed) {
            type = ConfigurationItemHelper.getBarPlacementTypeByNumber(
              item,
              doorProduct,
              barPlacementCount,
              productLineId,
            );
            if (type === BarType.Design) {
              filling.isDesignFilling = true;
              skipNext = true;
            }
          }

          barPlacementCount++;
        }

        filling.materialNumber = extraInfoList[extraInfoIndex++].ActualValue;
        filling.height = Number(extraInfoList[extraInfoIndex++].ActualValue);
        filling.positionYAbsolute = Number(
          extraInfoList[extraInfoIndex++].ActualValue,
        );

        this.fillings.push(filling);
      }

      // Bars
      for (let i = 0; i < this._numberOfFillings - 1; i++) {
        let newBar = new DoorBar(this);
        this._bars.push(newBar);
        newBar.desiredCenterY =
          ConfigurationItemHelper.getBarPlacementPositionByIndex(
            item,
            doorProduct,
            i,
            productLineId,
          );
      }
    }

    // Standard door fallback! Lots of missing variants on standard doors, so we try to compensate.
    if (
      doorProduct.ProductType === Interface_Enums.ProductType.DoorStandard &&
      this._numberOfFillings === 0
    ) {
      this.width = item.Width;
      this.height = item.Height;
      //this.depth = ProductHelper.defaultDepth(doorProduct);

      this._numberOfFillings = 1;

      let filling = new DoorFilling(this, this.editorAssets);
      filling.materialId = item.MaterialId;
      this.fillings.push(filling);
    }
  }

  private clearLocalCache() {}

  public clearBackingVariables() {
    this.clearLocalCache();

    this.forceDesignFilling = false;

    this._minNumberOfFillings = undefined;
    this._maxNumberOfFillings = undefined;

    this._availableCornerCuts = undefined;

    this.fillings.forEach((f) => f.clearBackingVariables());
    this.bars.forEach((f) => f.clearBackingVariables());
  }

  private reset() {
    this._fillings = [];
    this._bars = [];
    this._verticalBars = [];
    this._profiles = [];
  }

  //#region Availables and min/max/default values, public getters

  get numberOfFillingsMin(): number {
    if (this._minNumberOfFillings === undefined) {
      this.calculateMinMaxNumberOfFillings();
    }
    if (this._minNumberOfFillings === undefined) {
      return 1;
    }

    return this._minNumberOfFillings;
  }
  get numberOfFillingsMax(): number {
    if (this._maxNumberOfFillings === undefined) {
      this.calculateMinMaxNumberOfFillings();
    }
    if (this._maxNumberOfFillings === undefined) {
      return 1;
    }

    return this._maxNumberOfFillings;
  }

  get availableCornerCuts(): Interface_DTO.VariantOption[] {
    if (this._availableCornerCuts === undefined) {
      this._availableCornerCuts = [];

      let doorProduct = this.doorSubSection.profile;
      if (doorProduct) {
        var cornerCutVariant = doorProduct
          .getVariants(this.doorSubSection.cabinetSection.cabinet.ProductLineId)
          .filter((v) => v.Number === VariantNumbers.DoorCornerCut)[0];
        if (cornerCutVariant) {
          let cornerCuts = this._availableCornerCuts;
          cornerCutVariant.VariantOptions.forEach((vo) => cornerCuts.push(vo));
        }
      }
    }

    return this._availableCornerCuts;
  }

  //#endregion Availables and min/max/default values, public getters

  //#region Actual values, public properties

  /**
    The needed number of visible fillings.        
    **/
  public get numberOfFillings(): number {
    if (!this._numberOfFillings) {
      this._numberOfFillings = 1;
    }
    return this._numberOfFillings;
  }
  public set numberOfFillings(value: number) {
    if (!value) {
      this._numberOfFillings = 1;
    }
    this._numberOfFillings = value;
  }

  get bars(): DoorBar[] {
    return this._bars;
  }
  get verticalBars(): DoorBarVertical[] {
    return this._verticalBars;
  }
  get fillings(): DoorFilling[] {
    return this._fillings;
  }
  get actualFillings(): DoorFilling[] {
    let actualFillings = new Array<DoorFilling>();
    if (this.fillings.length > 0) {
      let previousFilling = this.fillings[0].clone();
      actualFillings.push(previousFilling);

      for (var i = 1; i < this.fillings.length; i++) {
        let currentFilling = this.fillings[i].clone();

        if (this.bars[i - 1].barType === Interface_Enums.BarType.Loose) {
          previousFilling.height += currentFilling.height;
        } else {
          actualFillings.push(currentFilling);
          previousFilling = currentFilling;
        }
      }
    }

    return actualFillings;
  }
  get profiles(): DoorProfile[] {
    return this._profiles;
  }
  get gripItemsFront(): Client.ConfigurationItem[] {
    return this._gripItemsFront;
  }
  get gripItemsBack(): Client.ConfigurationItem[] {
    return this._gripItemsBack;
  }

  get position(): Interface_DTO_Draw.Vec3d {
    return this._position;
  }
  set position(value: Interface_DTO_Draw.Vec3d) {
    this._position = value;
  }

  get gripPlacementFront(): Interface_DTO.VariantOption | undefined {
    if (
      !this._gripPlacementFront &&
      !!this.doorSubSection.availableGripPlacementsFront &&
      this.doorSubSection.availableGripPlacementsFront.length > 0
    ) {
      this._gripPlacementFront =
        this.doorSubSection.availableGripPlacementsFront[0];
    }

    return this._gripPlacementFront;
  }
  set gripPlacementFront(value: Interface_DTO.VariantOption | undefined) {
    this._gripPlacementFront = value;
  }

  get gripPlacementBack(): Interface_DTO.VariantOption | undefined {
    if (
      !this._gripPlacementBack &&
      !!this.doorSubSection.availableGripPlacementsBack &&
      this.doorSubSection.availableGripPlacementsBack.length > 0
    ) {
      this._gripPlacementBack =
        this.doorSubSection.availableGripPlacementsBack[0];
    }

    return this._gripPlacementBack;
  }
  set gripPlacementBack(value: Interface_DTO.VariantOption | undefined) {
    this._gripPlacementBack = value;
  }

  get cornerCut(): Interface_DTO.VariantOption | undefined {
    if (
      !this._cornerCut &&
      !!this.availableCornerCuts &&
      this.availableCornerCuts.length > 0
    ) {
      this._cornerCut = this.availableCornerCuts[0];
    }
    return this._cornerCut;
  }
  set cornerCut(value: Interface_DTO.VariantOption | undefined) {
    this._cornerCut = value;
  }

  public get forceFixedBars(): boolean {
    return this._forceFixedBars;
  }
  public set forceFixedBars(value: boolean) {
    this._forceFixedBars = this.mayForceFixedBars && value;
  }

  get verticalBarOption(): Interface_DTO.VariantOption | undefined {
    if (
      !this._verticalBarOption &&
      !!this.doorSubSection.availableVerticalBarOptions &&
      this.doorSubSection.availableVerticalBarOptions.length > 0
    ) {
      this._verticalBarOption =
        this.doorSubSection.availableVerticalBarOptions[0];
    }

    return this._verticalBarOption;
  }
  set verticalBarOption(value: Interface_DTO.VariantOption | undefined) {
    this._verticalBarOption = value;
  }
  public get numberOfVerticalBars(): number {
    return this.mayHaveVerticalBars && this.verticalBarOption
      ? parseInt(this.verticalBarOption.Number)
      : 0;
  }

  //#endregion Actual values, public properties

  //#region Calculated public properties

  public get frameHeightTop(): number {
    return DoorHelper.getFrameSize(this, Interface_Enums.Direction.Top, false);
  }
  public get frameHeightBottom(): number {
    return DoorHelper.getFrameSize(
      this,
      Interface_Enums.Direction.Bottom,
      false,
    );
  }
  public get frameWidthLeft(): number {
    return DoorHelper.getFrameSize(this, Interface_Enums.Direction.Left, false);
  }
  public get frameWidthRight(): number {
    return DoorHelper.getFrameSize(
      this,
      Interface_Enums.Direction.Right,
      false,
    );
  }

  public get fillingPositionZ(): number {
    if (this.doorData && this.doorData.FillingPositionZ !== null) {
      return this.doorData.FillingPositionZ;
    } else if (this.frameHeightTop < 3) {
      return 0;
    } else {
      return (this.depth - Constants.fillingDepth) / 2;
    }
  }

  public get backZ(): number {
    return this.position.Z;
  }
  public get frontZ(): number {
    return this.position.Z + this.depth;
  }

  public get gripsAvailable(): boolean {
    // Some doors have available grip placements for the front (but not for the back), even though it can not have grips.
    return (
      !!this.doorSubSection.availableGripPlacementsBack &&
      this.doorSubSection.availableGripPlacementsBack.length > 0
    );
  }

  public get hasGrip(): boolean {
    return !!this.doorSubSection.grip;
  }
  public get hasGripsFront(): boolean {
    return (
      !!this.gripPlacementFront &&
      this.gripPlacementFront.Number !== VariantNumbers.Values.Grip_None
    );
  }
  public get hasGripsBack(): boolean {
    return (
      !!this.gripPlacementBack &&
      this.gripPlacementBack.Number !== VariantNumbers.Values.Grip_None
    );
  }

  public get leftX(): number {
    return this.position.X;
  }
  public get rightX(): number {
    return this.position.X + this.width;
  }

  public get gripImagePath(): string | null {
    if (!this.doorSubSection.grip) {
      return null;
    }

    return this.doorSubSection.grip.getModel2DPath();
  }

  public get mayForceFixedBars(): boolean {
    if (
      this.doorData &&
      this.doorData.Bars &&
      this.doorData.Bars.some(
        (b) =>
          b.BarType === Interface_Enums.BarType.Fixed &&
          b.Height === this.doorSubSection.barHeight,
      )
    ) {
      // There are fixed bars available
      return true;
    }

    if (
      this.doorSubSection.profile &&
      this.doorSubSection.profile
        .getVariants(this.doorSubSection.cabinetSection.cabinet.ProductLineId)
        .some(
          (v) =>
            v.Number === VariantNumbers.ForceFixedBars &&
            (!v.OnlyAdmin ||
              this.doorSubSection.cabinetSection.cabinet.floorPlan
                .FullCatalogAllowOtherProducts),
        )
    ) {
      // The door does have the fixed bars variant
      return true;
    }

    return false;
  }

  public get mayHaveVerticalBars(): boolean {
    return this.doorSubSection.availableVerticalBarOptions.length > 1;
  }

  //#endregion Calculated public properties

  public get doorData(): Interface_DTO.ProductDoorData | null {
    return this.doorSubSection.doorData;
  }

  public hasSoftClose(): boolean {
    return (
      this._softClose !== VariantNumbers.SoftCloseValues.SoftClose_None &&
      this._softClose !== VariantNumbers.SoftCloseValues.SoftClose_Unused
    );
  }

  /**
    The doors index in the doors list on the section (one based)
    **/
  get index(): number {
    let index = this.doorSubSection.doors.indexOf(this);
    return index + 1;
  }

  get size(): Readonly<Interface_DTO_Draw.Vec3d> {
    return this._size;
  }

  get height(): number {
    return this.size.Y;
  }
  set height(value: number) {
    if (value !== this._size.Y) {
      this._size.Y = value;
      this.clearBackingVariables();
    }
  }

  get width(): number {
    return this.size.X;
  }
  set width(value: number) {
    if (value !== this._size.X) {
      this._size.X = value;
      this.clearBackingVariables();
    }
  }

  get depth(): number {
    return this.size.Z;
  }
  set depth(value: number) {
    if (value !== this._size.Z) {
      this._size.Z = value;
      this.clearBackingVariables();
    }
  }

  public get fallBackMaterial(): Interface_DTO.Material {
    if (!this._fallBackMaterial) {
      let firstMaterial = this.fillings[0].material;
      if (firstMaterial !== null) {
        this._fallBackMaterial = firstMaterial;
      } else {
        this._fallBackMaterial = DoorHelper.getDefaultFillingMaterial(this);
      }
    }

    return this._fallBackMaterial;
  }
  public setFallBackMaterial(material: Interface_DTO.Material | undefined) {
    this._fallBackMaterial = material;
  }

  //#region Public functions

  public getAssets(): EditorAssets {
    return this.editorAssets;
  }

  public designAllowedForFilling(filling: Client.DoorFilling): boolean {
    if (
      !this.doorData ||
      !this.doorData.Bars ||
      !this.doorData.Bars.some(
        (b) =>
          b.BarType === Interface_Enums.BarType.Design &&
          b.Height === this.doorSubSection.barHeight,
      )
    ) {
      // There are no design filling bars available
      return false;
    }

    let index = this.fillings.indexOf(filling);

    if (index <= 0 || index >= this.fillings.length - 1) {
      // The first and last fillings may not be design fillings.
      return false;
    }

    if (
      this.fillings[index - 1].isDesignFilling ||
      this.fillings[index + 1].isDesignFilling
    ) {
      // Design fillings are not allowed next to other design fillings
      return false;
    }

    return true;
  }

  public setCopyFrom() {
    let index = this.doorSubSection.doors.indexOf(this);
    this.doorSubSection.copyDoor = index;
  }

  /**
   * Calculates both minimum and maximum fillings possible for the door
   */
  public calculateMinMaxNumberOfFillings() {
    this._minNumberOfFillings = Constants.absoluteMinNumberOfFillings;
    this._maxNumberOfFillings = Constants.absoluteMaxNumberOfFillings;

    let barData = DoorHelper.getCorrectBar(this, null, null);

    if (barData === null) {
      this._maxNumberOfFillings = 1;
      return;
    }

    let fixedBars = barData.BarType == Interface_Enums.BarType.Fixed;

    let nextMaterial = DoorHelper.getNextFillingMaterial(this);
    if (nextMaterial) {
      let totalSpaceForFilling =
        this.height - DoorHelper.getFrameDeductionForFilling(this, true);
      let minFillingHeight = Math.max(
        nextMaterial.MinFillingHeight,
        Constants.absoluteMinimumFillingHeight,
      );
      this._maxNumberOfFillings = Math.floor(
        totalSpaceForFilling / (minFillingHeight + barData.BarMiddel),
      ); // This is not 100% accurate, but it was like this in the old system.

      let maxWidthSingleFilling = this.getMaxWidthSingleFilling();

      let firstFillingMaxHeight = totalSpaceForFilling;
      if (this.fillings.length > 0) {
        maxWidthSingleFilling = Math.min(
          maxWidthSingleFilling,
          Enumerable.from(this.fillings).min((f) => f.maxWidth),
        );
        firstFillingMaxHeight = this.fillings[0].maxHeight;
      }

      let newMinNumberOfFillings = 1;
      if (
        this.width > maxWidthSingleFilling ||
        firstFillingMaxHeight < totalSpaceForFilling
      ) {
        // Calculate the new minimum number of fillings
        newMinNumberOfFillings = 0;

        let totalFillingHeight = totalSpaceForFilling;
        for (let filling of this.fillings) {
          let actualFillingHeight = fixedBars
            ? filling.height
            : totalFillingHeight;

          if (filling.material) {
            var supportResult = MaterialHelper.supportsFillingSize(
              filling.material,
              this.width,
              actualFillingHeight,
            );
            if (supportResult.supported) {
              filling.rotated = supportResult.mustBeRotated;
            }
          }

          totalFillingHeight -= filling.maxHeight;
          newMinNumberOfFillings++;

          if (totalFillingHeight <= 0) {
            break;
          }
        }

        while (
          totalFillingHeight > 0 &&
          newMinNumberOfFillings < Constants.absoluteMaxNumberOfFillings
        ) {
          totalFillingHeight -=
            this.fillings[this.fillings.length - 1].maxHeight;
          newMinNumberOfFillings++;
        }

        // Handle situations, where a fixed bar is needed, but only design filling bars are available - must be at least 3 fillings to accomodate a design filling
        this.forceDesignFilling =
          this.doorData !== null &&
          !this.doorData.Bars.some(
            (b) => b.BarType === Interface_Enums.BarType.Fixed,
          );
        if (this.forceDesignFilling && newMinNumberOfFillings < 3)
          newMinNumberOfFillings++;

        if (newMinNumberOfFillings < 2) newMinNumberOfFillings++;
      } else {
        this.forceDesignFilling = false;

        if (totalSpaceForFilling > nextMaterial.MaxFillingHeight) {
          newMinNumberOfFillings = 2;
        }
      }

      this._minNumberOfFillings = newMinNumberOfFillings;
    }

    // Ensure values are always valid.
    if (this._minNumberOfFillings < Constants.absoluteMinNumberOfFillings) {
      this._minNumberOfFillings = Constants.absoluteMinNumberOfFillings;
    }

    if (this._minNumberOfFillings > Constants.absoluteMaxNumberOfFillings) {
      this._minNumberOfFillings = Constants.absoluteMaxNumberOfFillings;
    }

    if (this._maxNumberOfFillings > Constants.absoluteMaxNumberOfFillings) {
      this._maxNumberOfFillings = Constants.absoluteMaxNumberOfFillings;
    }

    if (this._maxNumberOfFillings < this._minNumberOfFillings) {
      this._maxNumberOfFillings = this._minNumberOfFillings;
    }
  }

  private getMaxWidthSingleFilling(): number {
    let useWideDoors =
      this.editorAssets.fullCatalog &&
      this.doorSubSection.cabinetSection.cabinet.floorPlan.FullCatalogWideDoors;

    if (useWideDoors) return Number.MAX_VALUE;

    if (this.doorSubSection.profile === null) {
      return 0;
    }

    let doorProduct = this.doorSubSection.profile;
    if (this.doorData === null) {
      return 0;
    }

    let maxWidthSingleFilling = ProductHelper.maxWidth(doorProduct);
    if (this.fillings.some((f) => f.material !== null && !f.material.IsGlass)) {
      maxWidthSingleFilling = Math.min(
        maxWidthSingleFilling,
        this.doorData.MaxWidthSingleFillingNonglass,
      );
    }
    if (this.fillings.some((f) => f.material !== null && f.material.IsGlass)) {
      maxWidthSingleFilling = Math.min(
        maxWidthSingleFilling,
        this.doorData.MaxWidthSingleFillingGlass,
      );
    }

    return maxWidthSingleFilling;
  }

  //#endregion Private helpers

  setBarDesign(design: Client.BarDesign) {
    if (design.fillings.length < this.numberOfFillingsMin) return;
    if (design.fillings.length > this.numberOfFillingsMax) return;

    let material = this.fillings[0].material;
    if (!material) {
      return;
    }
    this.mustSpreadBarsEvenly = false;
    let totalPercent = 0;
    let totalAbsHeight = 0;
    for (let f of design.fillings) {
      if (f.unit === 'fr') {
        totalPercent += f.height;
      } else if (f.unit === 'mm') {
        totalAbsHeight += f.height;
      }
    }
    const availableHeight = this.height - totalAbsHeight;
    const mmPerFraction = availableHeight / totalPercent;

    this.numberOfFillings = design.fillings.length;
    this.fillings.length = 0;
    for (let df of design.fillings) {
      const newFilling = new DoorFilling(this, this.editorAssets);
      const height = df.unit === 'mm' ? df.height : df.height * mmPerFraction;

      newFilling.height = height;
      newFilling.material = material;
      this.fillings.push(newFilling);
    }

    this.bars.length = 0;
    let previousBarHeight = 0;
    for (let i = 0; i < design.fillings.length - 1; i++) {
      //add one bar per filling - 1
      const bar = new DoorBar(this);
      this.bars.push(bar);

      const df = design.fillings[i];
      const fillingHeight =
        df.unit === 'mm' ? df.height : df.height * mmPerFraction;
      bar.position = previousBarHeight + fillingHeight;
      previousBarHeight += fillingHeight;
    }

    this._barDesign = design;
  }

  public get barDesign() {
    return this._barDesign;
  }
}
