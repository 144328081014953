<div class="modal-content end-user-import">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('enduser_import_modal_header', 'Import from end user setup')"
    ></h3>
    <button type="button" class="close" style="float: right" (click)="cancel()">
      &times;
    </button>
  </div>

  <div class="modal-body">
    <form>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          style="font-size: 14px !important"
          name="searchQuery"
          [attr.placeholder]="translate('enduser_import_modal_search_placeholder', 'Email')"
          [(ngModel)]="searchQuery"
          autofocus
        />
        <button
          class="btn btn-default btn-sm"
          type="submit"
          [textContent]="translate('enduser_import_modal_search_button', 'Search')"
          (click)="search()"
          [disabled]="!isSearchEnabled"
        ></button>
      </div>
    </form>

    <div
      class="result-container"
      style="padding-top: 1rem; padding-left: 0.5rem"
    >
      <div *ngIf="searchState === 'uninitialized'"></div>

      <div *ngIf="searchState === 'searching'">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Searching...</span>
        </div>
      </div>

      <div *ngIf="searchState === 'done'">
        <div *ngIf="endUsers.length <= 0">No results</div>
        <div *ngIf="endUsers.length > 0">
          <div
            *ngFor="let endUser of endUsers"
            class="end-user"
            [ngClass]="{'selected': endUser.selected}"
          >
            <span
              class="bi bi-chevron-right not-selected-marker"
              (click)="endUser.selected = !endUser.selected"
            ></span>
            <span
              class="bi bi-chevron-down selected-marker"
              (click)="endUser.selected = !endUser.selected"
            ></span>

            <span
              [textContent]="endUser.Email"
              (click)="endUser.selected = !endUser.selected"
            ></span>
            <div class="end-user-setups" *ngIf="endUser.selected">
              <div
                *ngFor="let setup of setupsOrderedByModifiedDate(endUser.Email)"
                class="end-user-setup selectable"
                [ngClass]="{'selected': setup === selectedSetup}"
                (click)="selectedSetup = setup"
              >
                <span
                  class="end-user-setup-name"
                  [textContent]="setup.Name"
                ></span>
                <span
                  class="end-user-setup-date"
                  [textContent]="setup.ModifiedDate | date : 'd MMM yy HH:mm'"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-group btn-group-right">
      <button
        class="btn btn-primary-custom"
        type="submit"
        (click)="save()"
        [disabled]="!isSelectEnabled"
        [textContent]="translate('end_user_modal_button_import', 'Import')"
      ></button>

      <button
        class="btn btn-default"
        type="button"
        (click)="cancel()"
        [textContent]="translate('modal_button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
