import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { AddressService } from 'app/ts/services/AddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Observable } from 'app/ts/util/Observable';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'phone-input',
  templateUrl: './phoneInput.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class PhoneInputVm extends BaseVm implements OnInit {
  constructor(baseVmService: BaseVmService, addressService: AddressService) {
    super(baseVmService);
    addressService.countryPromises.then((countries) => {
      this.countries = countries.filter(
        (c) => !ObjectHelper.isNullOrWhitespace(c.PhonePrefix),
      );
      this.externalChange(
        this.phoneNumberObservable ? this.phoneNumberObservable.value : null,
      );
    });
  }

  public countries: Interface_DTO.Country[] | null = null;
  private _phonePrefix: string = '';
  private _phoneMain: string = '';
  private ignoreExternal = false;

  @Input()
  public phoneNumberObservable: Observable<string | null> | undefined;
  @Input()
  public required: boolean | undefined;
  @Input()
  public isDisabled: boolean | undefined;
  @Input()
  public defaultPrefix: string | undefined;

  @Output()
  public phoneNumberChange = new EventEmitter<string>();

  // TODO: This is a temporary solution
  // The real solution should probably be to
  // have it in the Country table in the database.
  public get phoneMaxLength(): string {
    if (this.phonePrefix == '+49') return '12';
    else return '9';
  }

  public get phonePrefix(): string {
    return this._phonePrefix;
  }
  public set phonePrefix(val: string) {
    this._phonePrefix = val;
    this.internalChange();
  }

  public get phoneMain(): string {
    return this._phoneMain;
  }
  public set phoneMain(val: string) {
    this._phoneMain = val;
    this.internalChange();
  }

  public ngOnInit() {
    if (this.phoneNumberObservable) {
      this.subscribeToOld(this.phoneNumberObservable, (phoneNumber) => {
        this.externalChange(phoneNumber);
      });
    }
  }

  // Called whenever prefix or phone number is changed by the UI
  // Used to update the actual phone number (phoneNumberObservable)
  // which is to be saved
  private internalChange() {
    let phoneNumber: string;
    if (ObjectHelper.isNullOrWhitespace(this.phoneMain)) {
      phoneNumber = '';
    } else {
      let stripFirstPlus = !ObjectHelper.isNullOrWhitespace(this.phonePrefix);
      phoneNumber = this.strip(this.phoneMain, stripFirstPlus);
    }

    phoneNumber =
      this.phonePrefix + this.sanitizeSwedishPhoneNumber(phoneNumber);

    this.phoneNumberChange.emit(phoneNumber);

    if (this.phoneNumberObservable) {
      try {
        this.ignoreExternal = true;
        this.phoneNumberObservable.value = phoneNumber;
      } finally {
        this.ignoreExternal = false;
      }
    }
  }

  private sanitizeSwedishPhoneNumber(phoneNumber: string): string {
    /*
     * Swedes sometimes write their phone number starting with a '0'.
     * This is not compatible with intl prefixes, so any swedish number
     * starting with a 0 should have the 0 removed
     * */

    let wrongSwedishPhoneNumberPrefix = '+460';
    if (!phoneNumber) return phoneNumber;

    if (phoneNumber.length < wrongSwedishPhoneNumberPrefix.length)
      return phoneNumber;

    if (
      phoneNumber.substr(0, wrongSwedishPhoneNumberPrefix.length) !==
      wrongSwedishPhoneNumberPrefix
    )
      return phoneNumber;

    return '+46' + phoneNumber.substr(wrongSwedishPhoneNumberPrefix.length);
  }

  // Removes any non-digits. Optionally keeps a plus at position 0
  private strip(s: string | null | undefined, stripFirstPlus: boolean): string {
    if (!s) return '';
    let reAddPlus = false;
    if (!stripFirstPlus && s.charAt(0) === '+') {
      s = s.substr(1);
      reAddPlus = true;
    }

    s = s.replace(/[^\d]/g, '');
    if (reAddPlus) {
      s = '+' + s;
    }
    return s;
  }

  public externalChange(newNumber: string | null) {
    if (this.ignoreExternal) {
      return;
    }

    if (this.countries == null) return;

    let countryFound = false;
    if (newNumber) {
      for (let country of this.countries) {
        if (
          newNumber.substr(0, country.PhonePrefix.length) ===
          country.PhonePrefix
        ) {
          this._phonePrefix = country.PhonePrefix;
          let phoneMain = newNumber.substr(country.PhonePrefix.length);
          this._phoneMain = this.strip(phoneMain, true);
          countryFound = true;
          break;
        }
      }
      if (!countryFound) {
        this._phoneMain = this.strip(newNumber, false);
        this._phonePrefix = '';
      }
    } else {
      this._phoneMain = '';
      this._phonePrefix = this.defaultPrefix || '';
    }
  }
}
