import { Router } from '@angular/router';
import { AppPaths } from './app-paths';

export class Navigate {
  constructor(private router: Router) {}

  public home(): void {
    this.router.navigate([AppPaths.Home]);
  }

  public customers(query: object): void {
    let url = `${AppPaths.Customers}?${this.asQueryString(query)}`;
    this.router.navigateByUrl(url);
  }

  public deliveryAddresses(query: object): void {
    let url = `${AppPaths.DeliveryAddresses}?${this.asQueryString(query)}`;
    this.router.navigateByUrl(url);
  }

  public floorPlans(query: object): void {
    let url = `${AppPaths.FloorPlans}?${this.asQueryString(query)}`;
    this.router.navigateByUrl(url);
  }

  public projects(query: object): void {
    let url = `${AppPaths.Projects}?${this.asQueryString(query)}`;
    this.router.navigateByUrl(url);
  }

  public to(path: string, query?: object) {
    let url: string = path;
    if (query != null) {
      url = url + `?${this.asQueryString(query)}`;
    }
    this.router.navigateByUrl(url);
  }

  private asQueryString(params: object): string {
    const searchParams = new URLSearchParams('');

    for (const [key, value] of Object.entries(params)) {
      if (value != null) {
        searchParams.set(key, value.toString());
      }
    }

    return searchParams.toString();
  }
}
