import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  templateUrl: 'propertySelector.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class PropertySelectorVm<T extends { [id: string]: string }>
  extends BaseVm
  implements OnInit
{
  public readonly propertyNames!: T;

  public selectedKeys!: (keyof T)[];

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }
  ngOnInit(): void {
    this.recalc();
  }

  public selectableKeys: (keyof T)[] = [];

  public addIndex(index: number) {
    this.selectedKeys.push(this.selectableKeys[index]);
    this.recalc();
  }

  public removeIndex(index: number) {
    this.selectedKeys.splice(index, 1);
    this.recalc();
  }

  private recalc() {
    this.selectableKeys = Object.keys(this.propertyNames).filter(
      (key) => this.selectedKeys.indexOf(key) < 0,
    );
  }

  public save() {
    this.activeModal.close(this.selectedKeys);
  }

  public cancel() {
    this.activeModal.dismiss('user clicked close button');
  }
}
