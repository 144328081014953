<div class="row">
  <div class="folder-pane col-md-2">
    <folder
      (folderClicked)="linkBrowserSelectedFolder = $event"
      *ngFor="let folder of rootFolderContents"
      [startFolder]="folder"
      [selectedFolder]="linkBrowserSelectedFolder"
    ></folder>
  </div>

  <div class="link-pane col-md-10">
    <ka-link *ngFor="let link of linkBrowserLinks" [link]="link"></ka-link>
  </div>
</div>
