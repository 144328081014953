import { Copyable } from 'app/ts/interfaces/Copyable';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
export class ObjectHelper {
  public static readonly moneyNumberFormat: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  private static readonly dayNames = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

  /**
   * Generates a deep copy of an object, using the object's copy() method
   */
  public static copy<T extends Copyable<T>>(source: T): T;
  /**
   * Generates a deep copy of an object.
   * If object contains, or can contain a circular reference, it should implement Copyable<T>
   */
  public static copy<T>(source: T): T;

  public static copy<T>(source: T): T {
    if (source === null) {
      return source;
    } else if (typeof source === 'undefined') {
      return source;
    } else if (typeof source !== 'object') {
      return source;
    } else if (Array.isArray(source)) {
      let result = [];
      for (let i = 0; i < source.length; i++) {
        result.push(ObjectHelper.copy(source[i]));
      }
      return <any>result;
    } else if (ObjectHelper.isCopyable<T>(source)) {
      return source.copy();
    } else {
      let result = <T>{};
      for (let paramName in source) {
        result[paramName] = ObjectHelper.copy(source[paramName]);
      }
      return result;
    }
  }

  public static equals<T>(a: T, b: T): boolean {
    return this.equalsWithDepth(a, b, 0);
  }

  public static equalsWithDepth<T>(a: T, b: T, currentDepth: number): boolean {
    if (currentDepth > 25) return false;

    if (a === b) return true;
    if (typeof a != typeof b) {
      return false;
    }
    if (a === null || b === null) {
      return false;
    }
    if (a === undefined || b === undefined) {
      return false;
    }

    if (typeof a === 'object' && a !== null) {
      if (b === null) {
        return false;
      }

      if (Array.isArray(a) && Array.isArray(b)) {
        if ((<any>a).length !== (<any>b).length) {
          return false;
        } else {
          for (let i = 0; i < (<any>a).length; i++) {
            if (!ObjectHelper.equalsWithDepth(a[i], b[i], currentDepth + 1)) {
              return false;
            }
          }
          return true;
        }
      } else {
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        if (aProps.length != bProps.length) {
          return false;
        }

        for (var i = 0; i < aProps.length; i++) {
          var propName = aProps[i];

          if (
            !ObjectHelper.equalsWithDepth(
              (<any>a)[propName],
              (<any>b)[propName],
              currentDepth + 1,
            )
          ) {
            return false;
          }
        }

        return true;
      }
    } else {
      return false;
    }
  }

  public static toFormInput(obj: any, prefix?: string): HTMLInputElement[] {
    let result: HTMLInputElement[] = [];

    for (let fieldName in obj) {
      let field = obj[fieldName];
      let inputName = prefix ? prefix + '[' + fieldName + ']' : fieldName;
      let inp: HTMLInputElement | null = document.createElement('input');
      inp.type = 'hidden';
      inp.name = inputName;
      if (typeof field === 'number') {
        inp.value = field.toString(10);
      } else if (typeof field === 'object') {
        inp = null;
        let subs = ObjectHelper.toFormInput(field, inputName);
        result.push(...subs);
      } else {
        inp.value = field;
      }

      if (inp) result.push(inp);
    }

    return result;
  }

  public static isCopyable<T>(item: any): item is Copyable<T> {
    if (typeof item.copy !== 'function') return false;
    return item.copy.length === 0;
  }

  public static getDtoObject<T>(obj: T | DtoContainer<T>): T {
    let containerObj = obj as DtoContainer<T>;
    if (containerObj.dtoObject !== undefined) {
      return containerObj.dtoObject;
    }

    return obj as T;
  }

  public static filterParams<T>(paramList: (keyof T)[], obj: T): Partial<T> {
    let result: Partial<T> = {};
    for (let param of paramList) {
      result[param] = obj[param];
    }
    return result;
  }

  public static isNullOrWhitespace(str: string | null | undefined): boolean {
    if (!str) return true;
    let regex = /[^\s]/m;
    return !regex.test(str);
  }

  public static toDict<TIn, TVal>(
    items: TIn[],
    keySelector: (item: TIn) => number,
    valueSelector: (item: TIn) => TVal,
  ): { [key: number]: TVal } {
    let result: { [key: number]: TVal } = {};
    for (let item of items) {
      let k = keySelector(item);
      if (result.hasOwnProperty(k.toString())) {
        throw new Error('Duplicate key');
      }
      result[k] = valueSelector(item);
    }
    return result;
  }

  public static toLookup<TIn, TVal>(
    items: TIn[],
    keySelector: (item: TIn) => number,
    valueSelector: (item: TIn) => TVal,
  ): { [key: number]: TVal[] } {
    let result: { [key: number]: TVal[] } = {};
    for (let item of items) {
      let key = keySelector(item);
      let values = result[key];
      if (!values) {
        values = [];
        result[key] = values;
      }
      values.push(valueSelector(item));
    }
    return result;
  }

  public static hasFlag<T extends number>(
    flaggedEnumObj: T,
    flaggedEnumType: T,
  ): boolean {
    return (flaggedEnumObj & flaggedEnumType) === flaggedEnumType;
  }

  /**
   * Gets the best item in the list ( the item with the lowest score, determined by sorter)
   */
  public static best<T>(items: [T], sorter: (i: T) => number): T;
  /**
   * Gets the best item in the list ( the item with the lowest score, determined by sorter)
   */
  public static best<T>(items: T[], sorter: (i: T) => number): T | undefined;
  public static best<T>(items: T[], sorter: (i: T) => number): T | undefined {
    if (items.length < 1) return undefined;

    let bestN = sorter(items[0]);
    let bestI = 0;

    for (let i = 1; i < items.length; i++) {
      let n = sorter(items[i]);
      if (n < bestN) {
        bestN = n;
        bestI = i;
      }
    }
    return items[bestI];
  }

  public static clamp(min: number, candidate: number, max: number): number {
    if (min > max) {
      let tmp = min;
      min = max;
      max = tmp;
    }
    return Math.min(max, Math.max(min, candidate));
  }

  public static getWeekDays(deliveryDays: string): string[] {
    let isDeliveredOnDay = deliveryDays.split(',').map((day) => day == '1');
    let activeDayNames: string[] = [];
    for (let i = 0; i < isDeliveredOnDay.length; i++) {
      if (isDeliveredOnDay[i])
        activeDayNames.push(ObjectHelper.dayNames[i] || i.toString());
    }
    return activeDayNames;
  }

  public static roundMoney(n: number): string {
    return n.toLocaleString(undefined, ObjectHelper.moneyNumberFormat);
  }

  public static truncateString(s: string, maxLength: number): string;
  public static truncateString(
    s: string | null,
    maxLength: number,
  ): string | null;
  public static truncateString(
    s: string | null | undefined,
    maxLength: number,
  ): string | null | undefined;
  public static truncateString(
    s: string | null | undefined,
    maxLength: number,
  ): string | null | undefined {
    return s ? s.substr(0, Math.min(s.length, maxLength)) : s;
  }

  public static positiveModulo(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  public static sanitizeString(str: string): string {
    return str.replace(';', '|');
  }

  public static getGuid() {
    //taken from https://stackoverflow.com/a/8809472/2595554
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  }
}
