import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
export class Rectangle implements Interface_DTO_Draw.Rectangle {
  public dto: Interface_DTO_Draw.Rectangle;
  constructor(dto?: Interface_DTO_Draw.Rectangle) {
    this.dto = dto || { X: 0, Y: 0, Width: 0, Height: 0 };
    this.topRight = new DynamicVec2d(
      () => this.X + this.Width,
      (x: number) => (this.X = x - this.Width),
      () => this.Y + this.Height,
      (y: number) => (this.Y = y - this.Height),
    );
    this.middle = new DynamicVec2d(
      () => this.X + this.Width / 2,
      (x: number) => (this.X = x - this.Width / 2),
      () => this.Y + this.Height / 2,
      (y: number) => (this.Y = y - this.Height / 2),
    );
  }

  public get X() {
    return this.dto.X;
  }
  public get CenterX() {
    return this.X + this.Width / 2;
  }
  public get RightX() {
    return this.X + this.Width;
  }
  public get Y() {
    return this.dto.Y;
  }
  public get TopY() {
    return this.Y + this.Height;
  }
  public get Width() {
    return this.dto.Width;
  }
  public get Height() {
    return this.dto.Height;
  }

  public set X(val: number) {
    this.dto.X = val;
  }
  public set Y(val: number) {
    this.dto.Y = val;
  }
  public set Width(val: number) {
    this.dto.Width = val;
  }
  public set Height(val: number) {
    this.dto.Height = val;
  }

  public readonly topRight: Interface_DTO_Draw.Vec2d;
  public readonly middle: Interface_DTO_Draw.Vec2d;

  public isPointInside(point: Interface_DTO_Draw.Vec2d): boolean {
    if (point.X === undefined || point.Y === undefined) return false;

    if (point.X < this.X) return false;

    if (point.Y < this.Y) return false;

    if (point.X > this.X + this.Width) return false;

    if (point.Y > this.Y + this.Height) return false;

    return true;
  }

  public isRectangleFullyInside(otherRect: Rectangle): boolean {
    return (
      this.isPointInside({ X: otherRect.X, Y: otherRect.Y }) &&
      this.isPointInside({ X: otherRect.X, Y: otherRect.TopY }) &&
      this.isPointInside({ X: otherRect.RightX, Y: otherRect.TopY }) &&
      this.isPointInside({ X: otherRect.RightX, Y: otherRect.Y })
    );
  }
}

export class DynamicVec2d implements Interface_DTO_Draw.Vec2d {
  constructor(
    private readonly getX: () => number,
    private readonly setX: (x: number) => void,
    private readonly getY: () => number,
    private readonly setY: (y: number) => void,
  ) {}

  public get X() {
    return this.getX();
  }
  public get Y() {
    return this.getY();
  }

  public set X(val: number) {
    this.setX(val);
  }
  public set Y(val: number) {
    this.setY(val);
  }

  public get dto() {
    return {
      X: this.X,
      Y: this.Y,
    };
  }
}
