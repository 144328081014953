import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { SwingPulloutDouble } from 'app/ts/clientDto/Enums';
import { SwingPulloutSingle } from 'app/ts/clientDto/Enums';
import { SubSection } from 'app/ts/clientDto/SubSection';
import * as ArrayHelper from 'app/ts/util/ArrayHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { SwingArea } from 'app/ts/clientDto/SwingArea';
import { Material } from 'app/ts/clientDto/Material';
import { Product } from 'app/ts/clientDto/Product';
import { SwingTemplate } from 'app/ts/clientDto/SwingTemplate';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class Swing implements SubSection {
  constructor(
    public readonly cabinetSection: Client.CabinetSection,
    private dtoSection: Interface_DTO.CabinetSection,
  ) {
    //this.loadFrom(dtoSection);
  }

  //#region Private variables

  private cache: {
    availableSwingTemplates?: SwingTemplate[];

    availableGrips?: Client.Product[];
    pickableGrips?: Pickable<Client.Product | null>[];

    pickableCorpusMaterials?: Pickable<Client.Material>[];

    pickableDoorMaterials?: Pickable<Client.Material>[];
    items?: Client.ConfigurationItem[];
  } = {};

  private _grip: Client.Product | null = null;
  private _areas: Client.SwingArea[] = [];

  private _doorMaterial: Client.Material | null = null;

  //#endregion Private variables

  //#region Public properties

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  public get areas(): Client.SwingArea[] {
    return this._areas;
  }

  public get items(): Client.ConfigurationItem[] {
    if (!this.cache.items) {
      this.cache.items = Enumerable.from(this.areas)
        .selectMany((area) => area.items)
        .orderBy((item) => Swing.itemTypeOrder.indexOf(item.ItemType))
        .thenByDescending((item) => item.topY)
        .thenBy((item) => item.X)
        .toArray();
    }
    return this.cache.items;
  }

  private static readonly placementOrder: Interface_Enums.SwingModuleProductPlacement[] =
    [
      Interface_Enums.SwingModuleProductPlacement.Door,
      Interface_Enums.SwingModuleProductPlacement.LeftGable,
      Interface_Enums.SwingModuleProductPlacement.RightGable,
      Interface_Enums.SwingModuleProductPlacement.MiddleGable,
      Interface_Enums.SwingModuleProductPlacement.TopShelf,
      Interface_Enums.SwingModuleProductPlacement.BottomShelf,
      Interface_Enums.SwingModuleProductPlacement.SubModuleGable,
      Interface_Enums.SwingModuleProductPlacement.SubModuleTopShelf,
      Interface_Enums.SwingModuleProductPlacement.SubModuleExtraItem,
      Interface_Enums.SwingModuleProductPlacement.InteriorShelf,
      Interface_Enums.SwingModuleProductPlacement.InteriorCoatHanger,
      Interface_Enums.SwingModuleProductPlacement.Backing,
    ];
  private static readonly itemTypeOrder: Interface_Enums.ItemType[] = [
    Interface_Enums.ItemType.SwingDoor,
    Interface_Enums.ItemType.SwingCorpus,
    Interface_Enums.ItemType.SwingBacking,
  ];

  public get availableGrips(): Client.Product[] {
    if (this.cache.availableGrips === undefined) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.availableGrips = Enumerable.from(this.editorAssets.products)
        .where(
          (p) =>
            p.ProductType === Interface_Enums.ProductType.Grip &&
            p.ProductLineIds.some(
              (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
            ) &&
            (fullCatalog || !p.overrideChain),
        )
        .toArray();
    }

    return this.cache.availableGrips;
  }
  public get pickableGrips(): Pickable<Client.Product | null>[] {
    if (this.cache.pickableGrips === undefined) {
      this.cache.pickableGrips = this.availableGrips
        .map<Pickable<Product>>((product, index) => {
          let pickable: Pickable<Product> = {
            ...ProductHelper.getPickable(product),
            isSelected: this.grip ? this.grip.Id === product.Id : false,
            disabledReason: null,
            groupName: this.editorAssets.translationService.translate(
              'pickable_grip_group',
              'Grips',
            ),
          };

          return pickable;
        })
        .filter((pp) => pp !== undefined);

      // Insert "None" Pickable at the start of the list
      this.cache.pickableGrips.unshift(this.noGripPickable);
    }
    return this.cache.pickableGrips;
  }

  public get noGripPickable(): Pickable<null> {
    return {
      name: this.editorAssets.translationService.translate(
        'pickable_grip_name_none',
        'No Grip',
      ),
      disabledReason: null,
      groupName: this.editorAssets.translationService.translate(
        'pickable_grip_group_none',
        'No Grip',
      ),
      isSelected: true,
      item: null,
      override: false,
    };
  }

  public get grip(): Client.Product | null {
    return this._grip;
  }
  public set grip(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this._grip)) {
      return;
    }

    this._grip = value;
  }

  public get pickableCorpusMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableCorpusMaterials === undefined) {
      this.cache.pickableCorpusMaterials = [];

      let navTemplate = Enumerable.from(
        this.editorAssets.SwingModuleProducts,
      ).firstOrDefault();
      if (!!navTemplate) {
        let productNo = navTemplate.TemplateNo;
        let templateProduct = Enumerable.from(
          this.editorAssets.products,
        ).firstOrDefault((p) => p.ProductNo === productNo);
        if (!!templateProduct) {
          let fullCatalog =
            this.editorAssets.fullCatalog &&
            this.cabinetSection.cabinet.floorPlan
              .FullCatalogAllowOtherMaterials;

          let corpusMaterials = Enumerable.from(
            templateProduct.PossibleMaterialIds,
          )
            .where(
              (matId) =>
                fullCatalog ||
                (matId.IsEnabled && (fullCatalog || !matId.IsOverride)),
            )
            .select((matId) => matId.Id)
            .distinct()
            .select((matId) => this.editorAssets.materialsDict[matId]);

          let corpusPickables = corpusMaterials
            .select((mat) => MaterialHelper.getPickable(mat))
            .toArray();

          for (let pickable of corpusPickables) {
            pickable.isSelected =
              this.cabinetSection.CorpusMaterialId === pickable.item.Id;
          }
          this.cache.pickableCorpusMaterials = corpusPickables;

          MaterialHelper.sortPickableMaterials(
            this.cache.pickableCorpusMaterials,
          );
        }
      }
    }
    return this.cache.pickableCorpusMaterials;
  }
  public get corpusMaterial(): Client.Material | null {
    if (this.cabinetSection.CorpusMaterialId === null) return null;
    return this.editorAssets.materialsDict[
      this.cabinetSection.CorpusMaterialId
    ];
  }
  public set corpusMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this.corpusMaterial)) {
      return;
    }

    this.cabinetSection.cabinet.setCorpusMaterial(this.cabinetSection, value);
  }

  public get pickableDoorMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableDoorMaterials === undefined) {
      this.cache.pickableDoorMaterials = [];

      let navTemplates = Enumerable.from(this.editorAssets.SwingModuleProducts);
      let templateDoor = navTemplates.firstOrDefault(
        (nt) =>
          nt.Placement === Interface_Enums.SwingModuleProductPlacement.Door,
      );
      if (!!templateDoor) {
        let productNo = templateDoor.ProductNo;

        let products = Enumerable.from(this.editorAssets.products);
        let door = products.firstOrDefault((p) => p.ProductNo === productNo);
        if (!!door) {
          let fullCatalog =
            this.editorAssets.fullCatalog &&
            this.cabinetSection.cabinet.floorPlan
              .FullCatalogAllowOtherMaterials;

          let doorMaterials = Enumerable.from(door.PossibleMaterialIds)
            .where(
              (matId) =>
                fullCatalog ||
                (matId.IsEnabled && (fullCatalog || !matId.IsOverride)),
            )
            .select((matId) => matId.Id)
            .distinct()
            .select((matId) => this.editorAssets.materialsDict[matId]);

          let doorPickables = doorMaterials
            .select((mat) => MaterialHelper.getPickable(mat))
            .toArray();

          for (let pickable of doorPickables) {
            pickable.isSelected =
              !!this.doorMaterial && this.doorMaterial.Id === pickable.item.Id;
          }
          this.cache.pickableDoorMaterials = doorPickables;

          MaterialHelper.sortPickableMaterials(
            this.cache.pickableDoorMaterials,
          );
        }
      }
    }
    return this.cache.pickableDoorMaterials;
  }
  public get doorMaterial(): Client.Material | null {
    return this._doorMaterial;
  }
  public set doorMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._doorMaterial)) {
      return;
    }

    this._doorMaterial = value;
    this.areas.forEach((area) => (area.doorMaterial = this._doorMaterial));
  }

  public get defaultDoorMaterial(): Client.Material | null {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultSwingCorpusMaterialNumber(this.editorAssets);
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(this.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(this.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    return material ?? null;
  }

  //#endregion Public properties

  //#region Private functions

  private loadSwingArea(
    moduleItem: Interface_DTO.ConfigurationItem,
    doorMaterial: Material,
  ) {
    let swingTemplates = Enumerable.from(this.editorAssets.swingTemplates);
    let swingTemplate = swingTemplates.firstOrDefault(
      (st) =>
        st.startModule.product.Id === moduleItem.ProductId ||
        st.extendModule.product.Id === moduleItem.ProductId,
    );

    if (!swingTemplate) {
      return;
    }

    let newArea = new SwingArea();
    newArea.justAddedByUser = false;
    newArea.swingTemplate = swingTemplate;
    newArea.index = this.areas.length;
    newArea.moduleNumber =
      this.areas.length === 0
        ? swingTemplate.startModule.ModuleProductNo
        : swingTemplate.extendModule.ModuleProductNo;
    newArea.desiredInsideWidth = moduleItem.Width;
    newArea.insideRect.Width = moduleItem.Width;
    newArea.insideRect.X = moduleItem.X;
    newArea.insideRect.Y = 0;
    newArea.doorMaterial = doorMaterial;

    var moduleProduct = this.editorAssets.productsDict[moduleItem.ProductId];
    if (!!moduleProduct) {
      if (
        swingTemplate.possibleHingeSides.some(
          (hs) => hs === Enums.HingeSide.Both,
        )
      ) {
        // The area has two doors

        // Hinge side
        newArea.hingeSide = Enums.HingeSide.Both;

        // Sub (pullOut) module
        let pullOutString = VariantHelper.getVariantOptionNumber(
          moduleProduct.getVariants(this.cabinetSection.cabinet.ProductLineId),
          moduleItem.VariantOptions,
          VariantNumbers.SwingPulloutDouble,
        );
        if (pullOutString === VariantNumbers.Values.SwingPulloutDouble_Left) {
          newArea.pullOut = SwingPulloutDouble.Left;
        } else if (
          pullOutString === VariantNumbers.Values.SwingPulloutDouble_Right
        ) {
          newArea.pullOut = SwingPulloutDouble.Right;
        } else if (
          pullOutString === VariantNumbers.Values.SwingPulloutDouble_Both
        ) {
          newArea.pullOut = SwingPulloutDouble.Both;
        } else {
          newArea.pullOut = SwingPulloutDouble.None;
        }
      } else {
        // The area has one door

        // Hinge side
        let hingeSideString = VariantHelper.getVariantOptionNumber(
          moduleProduct.getVariants(this.cabinetSection.cabinet.ProductLineId),
          moduleItem.VariantOptions,
          VariantNumbers.HingeSide,
        );
        if (hingeSideString === VariantNumbers.Values.HingeSide_Left) {
          newArea.hingeSide = Enums.HingeSide.Left;
        } else if (hingeSideString === VariantNumbers.Values.HingeSide_Right) {
          newArea.hingeSide = Enums.HingeSide.Right;
        }

        // Sub (pullOut) module
        let pullOutString = VariantHelper.getVariantOptionNumber(
          moduleProduct.getVariants(this.cabinetSection.cabinet.ProductLineId),
          moduleItem.VariantOptions,
          VariantNumbers.SwingPulloutSingle,
        );
        if (pullOutString === VariantNumbers.Values.Yes) {
          newArea.pullOut = SwingPulloutSingle.Yes;
        } else {
          newArea.pullOut = SwingPulloutSingle.No;
        }
      }
    }

    this.areas.push(newArea);
  }

  //#endregion Private functions

  //#region Public functions

  public clearBackingVariables() {
    this.cache = {};
  }

  public loadFrom(dtoSection: Interface_DTO.CabinetSection): void {
    this.clearBackingVariables();

    this.areas.length = 0;

    let swingModuleItems = Enumerable.from(dtoSection.SwingItems)
      .where((item) => item.ItemType === Interface_Enums.ItemType.SwingModule)
      .orderBy((item) => item.X)
      .toArray();

    let swingDoorItems = Enumerable.from(dtoSection.SwingItems)
      .where((item) => item.ItemType === Interface_Enums.ItemType.SwingDoor)
      .orderBy((item) => item.X)
      .toArray();

    let doorIndex = 0;
    for (let i = 0; i < swingModuleItems.length; i++) {
      let doorMaterialId = swingDoorItems[doorIndex].MaterialId;
      let doorMaterial = this.editorAssets.materialsDict[doorMaterialId];

      if (i === 0) {
        this.doorMaterial = doorMaterial;
        let corpusMaterial =
          this.editorAssets.materialsDict[swingModuleItems[i].MaterialId];
        this.corpusMaterial = corpusMaterial;
      }

      this.loadSwingArea(swingModuleItems[i], doorMaterial);

      doorIndex++;
      if (
        this.areas[i].swingTemplate?.possibleHingeSides.some(
          (hs) => hs === Enums.HingeSide.Both,
        )
      ) {
        // The new area has two doors, so we skip a loaded door, when loading door materials
        doorIndex++;
      }
    }

    // Grip
    let gripItem = Enumerable.from(dtoSection.SwingItems).firstOrDefault(
      (item) =>
        item.ItemType === Interface_Enums.ItemType.SwingExtra &&
        this.availableGrips.some((grip) => grip.Id === item.ProductId),
    );

    if (!!gripItem) {
      this.grip = Enumerable.from(this.availableGrips).first(
        (grip) => grip.Id === gripItem!.ProductId,
      );
    }
  }

  public saveTo(dtoSection: Interface_DTO.CabinetSection): void {
    dtoSection.SwingItems = this.items.map((i) => i.save());
  }

  public addSwingArea(swingTemplate: SwingTemplate): Client.SwingArea {
    console.warn('SWING - addSwingArea may not be implemented correctly...');

    let newArea = new SwingArea();
    newArea.justAddedByUser = true;
    newArea.swingTemplate = swingTemplate;
    newArea.index = this.areas.length;
    newArea.moduleNumber =
      this.areas.length === 0
        ? swingTemplate.startModule.ModuleProductNo
        : swingTemplate.extendModule.ModuleProductNo;
    newArea.desiredInsideWidth = 0;
    newArea.insideRect.Width = 0;
    newArea.hingeSide = Enums.HingeSide.Left;
    newArea.insideRect.X =
      this.items.length > 0
        ? Math.max(...this.items.map((item) => item.rightX))
        : 0;
    newArea.insideRect.Y = 0;
    newArea.doorMaterial = this.doorMaterial;
    this.areas.push(newArea);

    return newArea;
  }

  public get canFitSectionWidthToContents(): boolean {
    let effectiveWidth = this.effectiveWidth;
    return !!effectiveWidth && this.cabinetSection.Width !== effectiveWidth;
  }
  public fitSectionWidthToContents() {
    if (this.effectiveWidth) {
      this.cabinetSection.Width = this.effectiveWidth;
    }
  }

  private get effectiveWidth(): number | undefined {
    let items = Enumerable.from(this.areas).selectMany((area) => area.items);
    if (!items.any()) {
      return;
    }
    let maxX = items.max((item) => item.rightX);
    return maxX;
  }

  public get canfitContentsToSectionWidth(): boolean {
    let effectiveWidth = this.effectiveWidth;
    if (!effectiveWidth) return false;
    if (effectiveWidth === this.cabinetSection.Width) return false;
    let flexAreas = this.areas.filter((area) => area.swingTemplate?.isFlex);
    if (flexAreas.length < 1) return false;

    let availableFlexes = flexAreas
      .filter((area) => area.swingTemplate != null)
      .map((area) => area.swingTemplate!.maxWidth - area.insideRect.Width);
    let totalAvailableFlex = ArrayHelper.sum(...availableFlexes);

    return totalAvailableFlex + effectiveWidth >= this.cabinetSection.Width;
  }
  public fitContentsToSectionWidth() {
    if (!this.canfitContentsToSectionWidth) return;
    let effectiveWidth = this.effectiveWidth;
    if (!effectiveWidth) return;

    // To be honest we have no idea wheter it is actually necessary to filter
    // null templates, but just to err on the safe side...
    let swingTemplateAreas = this.areas.filter(
      (area) => area.swingTemplate != null,
    );

    let totalInsideWidth = ArrayHelper.sum(
      ...this.areas.map((a) => a.insideRect.Width),
    );
    let totalCorpusWidth = effectiveWidth - totalInsideWidth;
    let totalMinInsideWidth = ArrayHelper.sum(
      ...swingTemplateAreas.map((a) => a.swingTemplate!.minWidth),
    );
    let targetInsideWidth = this.cabinetSection.Width - totalCorpusWidth;

    let availableFlexes = swingTemplateAreas.map(
      (area) => area.swingTemplate!.maxWidth - area.swingTemplate!.minWidth,
    );
    let totalAvailableFlex = ArrayHelper.sum(...availableFlexes);
    let neededFlex = targetInsideWidth - totalMinInsideWidth;
    let neededRatio = neededFlex / totalAvailableFlex;

    let rounding = 0;
    for (let flexArea of swingTemplateAreas.filter(
      (a) => a.swingTemplate!.isFlex,
    )) {
      let availableFlex =
        flexArea.swingTemplate!.maxWidth - flexArea.swingTemplate!.minWidth;
      let newWidth =
        flexArea.swingTemplate!.minWidth + availableFlex * neededRatio;
      let newWidthRounded = Math.floor(newWidth);
      rounding += newWidthRounded - newWidth;
      if (rounding <= -1) {
        newWidthRounded += 1;
        rounding += 1;
      }
      flexArea.desiredInsideWidth = newWidthRounded;
    }
  }

  //#endregion Public functions
}
