<div class="right-menu full wide" *ngIf="customer">
  <div class="title">
    <div class="title-left"></div>
    <div class="title-center">
      <span
        [textContent]="
          translate(
            'customers_menu_single_header_{0}',
            'Customer: {0}',
            customer.Name
          )
        "
      ></span>
    </div>
    <div class="title-right">
      <button class="close" (click)="close()">&times;</button>
    </div>
  </div>
  <div class="body">
    <form
      class="object-detail customer-detail"
      name="customerDetailForm"
      autocomplete="off"
    >
      <customer-edit [detailObject]="customer"> </customer-edit>

      <div style="display: flex; flex-direction: row; margin-top: 10px">
        <label style="margin-top: 6px">
          <input
            type="checkbox"
            name="shouldUpdateDeliveryAddress"
            [(ngModel)]="shouldUpdateDeliveryAddress"
            [disabled]="!canUpdateDeliveryAddress"
          />
          <span
            [textContent]="
              translate(
                'customers_should_update_delivery_address',
                'Also update delivery address'
              )
            "
          ></span>
        </label>
        <div class="flex-spacer"></div>
        <div class="btn-group btn-group-right">
          <button
            class="btn btn-primary"
            type="submit"
            (click)="save()"
            [textContent]="translate('detail_button_save', 'Save')"
          ></button>

          <button
            class="btn btn-secondary"
            type="button"
            (click)="close()"
            [textContent]="translate('detail_button_cancel', 'Cancel')"
          ></button>
        </div>
      </div>
    </form>

    <button
      class="btn btn-primary"
      (click)="showChildren()"
      *ngIf="showShowChildren"
      [textContent]="
        translate('customers_button_view_projects', 'view projects')
      "
    ></button>

    <button
      class="btn-danger"
      (click)="delete()"
      [attr.title]="translate('customers_button_delete_selected', 'Delete')"
    >
      <span class="bi bi-trash3"></span>
    </button>
  </div>
</div>
