import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { PromiseHelper } from 'app/ts/util/PromiseHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  templateUrl: './PasswordResetRequest.html',
  styleUrls: [
    '../../../../style/form-like.scss',
    '../../../../style/modal.scss',
  ],
})
export class PasswordResetRequestVm extends BaseVm {
  public username = '';
  public resetEnabled = true;
  public showEmailNotFoundError = false;

  constructor(
    baseVmService: BaseVmService,
    private readonly $http: PromisingBackendService,
    private readonly notificationService: NotificationService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public async requestResetMail() {
    if (!this.username) return;
    if (!this.resetEnabled) return;

    this.resetEnabled = false;
    this.showEmailNotFoundError = false;

    try {
      let success = await this.$http.post<boolean>(
        'api/passwordReset/request',
        {},
        { params: { emailAddress: this.username } },
      );

      /*
            let success = await this.notificationService.loadPromise(
                this.$http.post<HttpResponse<unknown>>("api/passwordReset/request", {}, { params: { emailAddress: this.username } })
                    .then(PromiseHelper.getDataOrFail)
            );*/

      if (success) {
        this.notificationService.success(
          'password_reset_request_success_notification',
          'We have sent you an email. Please check your inbox',
        );
        this.activeModal.close(undefined);
      } else {
        this.showEmailNotFoundError = true;
        this.username = '';
      }
    } catch (e: any) {
      if (
        e instanceof Exception.ServerDomainException &&
        e.Type === Interface_DTO_DomainError.ErrorType.MultipleUsers
      ) {
        this.notificationService.systemError(
          'password_reset_error_multiple_users',
          'Cannot reset password for this user - please contact support.',
        );
      } else this.notificationService.exceptionWithDefaultText(e);
    } finally {
      this.resetEnabled = true;
    }
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }
}
