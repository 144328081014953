import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FloorPlanDetailEditVm } from './FloorPlanDetailEditVm';
import { PhoneInputModule } from './phone-input.module';

@NgModule({
  declarations: [FloorPlanDetailEditVm],
  exports: [FloorPlanDetailEditVm],
  providers: [],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputModule,
  ],
})
export class FloorplanDetailEditModule {}
