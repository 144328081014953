<div class="modal-content consumer">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>

  <div class="modal-body">
    <div *ngFor="let err of errors">
      <div
        class="alert clickable"
        (click)="select(err)"
        [ngClass]="{
                    'alert-info': err.level === 5,
                    'alert-warning': err.level === 10,
                    'alert-danger': err.level === 20
                 }"
      >
        <div>
          <span class="bi bi-exclamation-triangle-fill"></span>
          <strong [textContent]="err.shortDescription"></strong>
        </div>
        <span class="translation" [textContent]="err.longDescription"></span>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="close()">
      <span [textContent]="translate('btn_close', 'Close')"></span>
    </button>
  </div>
</div>
