<div class="container-fluid">
  <div class="row">
    <h4>{{ "floorplan_headers_measurement" | translate: "Measurement" }}</h4>
  </div>

  <!-- Rotation toggles -->
  <div class="row">
    <div class="col">
      <div class="form-check" style="padding-left: 0">
        <input
          clas="form-check-input"
          type="radio"
          name="measurement_orientation"
          id="measurement_orientation_1"
          [checked]="selectedMeasurement.horizontal"
          (change)="orientationChanged('1')"
        />
        <label
          class="form-check-label"
          for="measurement_orientation_1"
          [textContent]="
            'measurement_orientation_option_horizontal'
              | translate: 'Horizontal'
          "
        ></label>
      </div>
      <div class="form-check" style="padding-left: 0">
        <input
          clas="form-check-input"
          type="radio"
          name="measurement_orientation"
          id="measurement_orientation_2"
          [checked]="!selectedMeasurement.horizontal"
          (change)="orientationChanged('0')"
        />
        <label
          class="form-check-label"
          for="measurement_orientation_2"
          [textContent]="
            'measurement_orientation_option_vertical' | translate: 'Vertical'
          "
        ></label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button
        style="margin-left: 0"
        class="btn btn-danger btn-semi-block"
        [textContent]="'floorPlan_delete_item_button' | translate: 'Delete'"
        (click)="requestDelete()"
      ></button>
    </div>
  </div>
</div>
