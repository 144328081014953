export const debug = {
  noDoorFillings: false,
  noDoorWheels: false,
  alwaysNagAboutAutosave: false,
  showTimings: false,
  showRecalculationMessages: false,
  showD3AxisHelpers: false,
  showVmConstructors: false,
  showRawTranslationKeys: false,
  showSnapInfo: false,
  showSelectionChanges: true,
  showTouchMessages: true,
  showKeyboardEvents: false,
  showBoundingBoxes: false,
  useRoundedCornerShelves: false,
  useTwoPartCornerShelves: false,
  disableTrackers: true,
  useFullCatalogByDefault: false,
  disableAllProducts: false,
  enableAllProducts: false,
  disableAllMaterials: false,
  enableAllMaterials: false,
  askForFloorPlanJSON: false,
  outputFloorPlanJSONOnLoad: false,
  d3RoundedEdges: false, //Doesn't work properly with gables
  displayUpgradeNotification: false,
  showIntersectBoxes: false,
  showD3ChangeReasons: false,
  supressAskForReload: true,
  use3dInInterior: false,
  useDefaultTranslationsAlways: false,

  useHq3d_lights: true,
  useHq3d_mats: true,
  useDatGui: false,
  useAppLog: true,
};

// export var Name = 'dooritretailhtml5view';
export var useDebug = location.hostname == 'localhost';
// export var injector: ng.auto.IInjectorService;
export var started: Date;
export var basketId: string | undefined = undefined;

// //debug flags are moved to ts/appSetup/debug.ts

// export function run() {
//     if (useDebug) {

//         console.warn("App is in debug mode");
//     }
//     else {
//         for (let key in App.debug) {
//             (App.debug as any)[key] = false;
//         }
//     }
//     {
//         let debugFlagsStr = window.sessionStorage.getItem("debugFlags");
//         if (debugFlagsStr) {
//             let debugFlags = JSON.parse(debugFlagsStr);
//             console.warn("Using debugFlags: ", debugFlags);
//             for (let key in debugFlags) {
//                 let flagValue = (debugFlags)[key];
//                 (App.debug as any)[key] = flagValue;
//             }
//         }
//     }

//     rememberBasketId();

//     started = new Date();
//     let module = angular.module(Name, Config.modules);
//     configure(module)
//         .configureRoutes(module);

//     module.run(preloadTemplates);
//     module.run(activateTrackers);

//     injector = angular.bootstrap(document, [Name]);
//     return injector;
// }

// function rememberBasketId() {
//     let search = window.location.search;
//     let urlParams = new URLSearchParams(search);
//     if (urlParams.has("bid")) {
//         basketId = urlParams.get("bid")!;
//         console.debug("saving basketId", basketId);
//         window.history.replaceState({}, "",
//             window.location.protocol + "//" + window.location.host + "/" + window.location.hash);
//     }
// }

// export function configure(module: ModuleService) {

//     for (let svc of Config.services) {
//         module.service(svc.Name, svc);
//     }
//     for (let vm of Config.viewModels) {
//         module.controller(vm.Name, vm);
//     }
//     for (let component of Config.components) {
//         module.component(component.Name, component);
//     }

//     module.factory("$exceptionHandler", ErrorService.getExceptionHandler);

//     module.config(setAuthHeadersOnAllRequests);
//     module.config(translateHttpErrors);

//     module.config(($locationProvider: LocationProviderService) => {
//         $locationProvider.html5Mode(false);
//         $locationProvider.hashPrefix("");
//     });

//     return App;
// }

export function log(...stuff: any[]) {
  if (debug.useAppLog) {
    console.debug(...stuff);
  }
}

// export function configureRoutes(module: ModuleService) {

//     for (let routeName in Config.routes) {
//         let route = Config.routes[routeName];

//         module.config(($routeProvider: RouteProviderService) => configureRoute(route, $routeProvider));
//     }

//     module.config(($routeProvider: RouteProviderService) => $routeProvider.otherwise(Config.routes['unknownPage']));

//     return App;
// }

// function configureRoute(route: Route, $routeProvider: RouteProviderService) {
//     if (!route.resolve)
//         route.resolve = {};
//     if (!route.controllerAs)
//         route.controllerAs = "routeVm";

//     if (route.requireRights && route.requireRights.length > 0) {
//         route.resolve["userRights"] = getUserRightCheckPromiseFunction(route.requireRights);
//     }
//     else if (route.requireAuth) {
//         route.resolve["auth"] = getUserRightCheckPromiseFunction([]);
//     }

//     $routeProvider.when(route.path, route);
// }

// function getUserRightCheckPromiseFunction(rights: Interface_Enums.UserRight[]): ((...p: any[]) => Promise<void>) {
//     return async (
//         $location: LocationService,
//         loginService: LoginService
//     ) => {
//         let valid = false;
//         let missingRight: Interface_Enums.UserRight | null = null;
//         let token = await loginService.userTokenObservable.ready();
//         if (token !== null) {
//             valid = true;
//             for (let right of rights) {
//                 if (token.Rights.indexOf(right) < 0) {
//                     valid = false;
//                     missingRight = right;
//                     break;
//                 }
//             }
//         }

//         if (!valid) {
//             history.replaceState("", "", '#/login' + $location.url());
//             let errorText = missingRight == null
//                 ? "auth needed"
//                 : "right needed: " + missingRight;

//             console.error(errorText);
//             throw new Error(errorText);
//         }

//     }
// }

// function setAuthHeadersOnAllRequests($httpProvider: HttpProviderService): void {
//     $httpProvider.interceptors.push(() => ({
//         request: (config: ng.IRequestConfig) => LoginService.decorateRequestConfig(config)
//     }));
// }

// function translateHttpErrors($httpProvider: HttpProviderService): void {
//     $httpProvider.interceptors.push(() => ({
//         responseError: (e: ng.IHttpPromiseCallbackArg<any>) => {
//             let url = e.config ? e.config.url : undefined;
//             let method = e.config ? e.config.method : undefined;
//             if (e.status !== undefined && e.status === 400 && isDomainError(e.data)) {
//                 throw new Exception.ServerDomainException(e.data);
//             }
//             else if (e.status !== undefined && e.status === 500 && isServerError(e.data)) {
//                 let se = new Exception.ServerException(e.data, url);
//                 if (se.serverError) {
//                     console.error("ServerError encountered: " + se.serverError);
//                 }
//                 throw se;
//             }
//             else if (e.status !== undefined && e.status === 401) {
//                 let cookie = document.cookie;
//                 LoginService.logout();
//                 window.location.href = window.location.origin;
//                 throw new Exception.AuthenticationException(method, url, cookie);
//             }
//             return e;
//         },
//     }))
// }

// function activateTrackers(trackerService: TrackerService) {
//     trackerService.activateTrackers();
// }

// function isDomainError(e: any): e is Interface_DTO.DomainError {
//     return (typeof e.Type) === "number";
// }
// function isServerError(e: any): e is Interface_DTO.ServerError {
//     let se = <Interface_DTO.ServerError>e;
//     return typeof se.ErrorId === "number";
// }

// function preloadTemplates($http: PromisingBackendService, $templateCache: any, $q: QService, $timeout: TimeoutService): void {
//     let timeString = "Preloading templates";
//     let timeBeforePreloading = 5000;
//     let timeBetweenPreloads = 0;

//     let urls: string[] = [];

//     for (let routeName in Config.routes) {
//         let route = Config.routes[routeName];
//         let templateUrl = route.templateUrl;
//         if (typeof templateUrl === "string") {
//             urls.push(templateUrl);

//         }
//     }
//     for (let componentName in Config.components) {
//         let comp = Config.components[componentName];
//         if (typeof comp.templateUrl === "string") {
//             urls.push(comp.templateUrl);
//         }
//     }

//     let promise: Promise<any> = $timeout.setTimeout(timeBeforePreloading).then(() => console.time(timeString));
//     for (let url of urls) {

//         promise = promise
//             .then(() => $http.get(url, { cache: $templateCache }))
//             .catch(e => { return;/*Some template failed to load, just carry on with the next */ });

//         if (timeBetweenPreloads > 0)
//             promise = promise.then(() => $timeout.setTimeout(timeBetweenPreloads));
//     }

//     if (App.useDebug && App.debug.showTimings)
//         promise.then(() => console.timeEnd(timeString));

// }

// export function clearAll() {
//     for (let storage of [localStorage, sessionStorage]) {
//         storage.clear();
//     }
//     document.cookie = "";
//     location.reload(true);
//     return "OK";
// }

// export function setDebugFlag(name: string, value = true) {
//     let oldDebugFlagsStr = window.sessionStorage.getItem("debugFlags") || '{}';
//     let debugFlags = JSON.parse(oldDebugFlagsStr);
//     debugFlags[name] = value;
//     let newDebugFlagsStr = JSON.stringify(debugFlags);
//     window.sessionStorage.setItem("debugFlags", newDebugFlagsStr);
//     console.log("Debug flags:", debugFlags);
//     return "Press F5 to use the new debugFlags";
// }
