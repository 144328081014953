<div class="vertical">
  <div class="category-list">
    <div class="product-search">
      <div class="input-group">
        <input
          type="text"
          id="productfilter"
          [(ngModel)]="productFilter"
          class="form-control search-input"
          [attr.placeholder]="translate('productlist_search_placeholder', 'Search')"
          aria-describedby="basic-addon2"
          keydown="$event.keyCode === 13 && searchEnter()"
        />
        <button
          class="btn btn-default"
          id="basic-addon2"
          onclick="var inp = document.getElementById('productfilter'); inp.focus()"
          (click)="productFilter = ''"
        >
          <span class="bi bi-search"></span>
        </button>
      </div>
    </div>

    <select
      class="form-select product-line"
      [(ngModel)]="selectedProductLineId"
      *ngIf="showProductLineSelector"
    ></select>

    <select
      [(ngModel)]="selectedCategoryId"
      class="form-select product-category"
    >
      <option
        *ngFor="let category of filteredProductCategories"
        [ngValue]="category.Id"
        [textContent]="category.Name"
      ></option>
    </select>

    <div
      class="product-sub-category-list btn-group btn-group-sm btn-group-vertical"
    >
      <button
        class="product-sub-category btn btn-default"
        *ngFor="let subCategory of subCategories"
        (click)="selectedSubCategory = subCategory "
        [textContent]="subCategory.Name"
        [ngClass]="{'active': subCategory.Id === selectedSubCategory?.Id}"
      ></button>
    </div>
  </div>

  <div class="product-list">
    <pickable
      class="product clickable"
      [ngClass]="{'draggable': isItemsDraggable}"
      *ngFor="let pickable of productPickables"
      (mousedown)="startDrag($event, pickable.item)"
      (mouseup)="stopDrag($event, pickable.item)"
      (touchstart)="touchStart($event, pickable.item)"
      (touchend)="touchEnd($event)"
      (touchmove)="touchMove($event)"
      (click)="clickItem(pickable.item)"
      [pickable]="pickable"
    ></pickable>
  </div>
</div>
