import { Injectable } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { UserMessage } from 'app/ts/Interface_DTO';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService {
  private _userMessages$: BehaviorSubject<UserMessage[]> = new BehaviorSubject<
    UserMessage[]
  >([]);

  constructor(private readonly promisingBackend: PromisingBackendService) {}

  public loadUserMessages() {
    this.promisingBackend
      .get<UserMessage[]>('api/userMessage')
      .then((messages) => this._userMessages$.next(messages));
  }

  public get userMessages$(): Observable<UserMessage[]> {
    return this._userMessages$;
  }
}
