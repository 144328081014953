<div
  class="editor-all editor-interior"
  [ngClass]="{'no-right-menu': !displayRightMenu}"
  *ngIf="editorType.ready"
>
  <editor-topbar
    [vm]="this"
    [selection]="'interior'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div
    class="tool-menu left-tool-menu with-ribbon"
    style="display: flex; flex-flow: column nowrap"
  >
    <product-list
      class="vertical"
      (onProductClick)="createItemFromProduct($event)"
      (onCategoryChange)="categoryChanged($event)"
      [cabinetSection]="cabinetSection!"
    ></product-list>
  </div>

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block visibility">
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{translate('editor_label_show_rulers', 'Rulers')}}
      </label>
      <label *ngIf="showShowPullout">
        <input type="checkbox" [(ngModel)]="showPullout" />
        {{translate('editor_label_show_pullout', 'Pullout Warnings')}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showCorpus" />
        {{translate('editor_label_show_corpus', 'Corpus')}}
      </label>
      <label *ngIf="showShowHinges">
        <input type="checkbox" [(ngModel)]="showHinges" />
        {{translate('editor_swing_show_hinges', 'Show hinges')}}
      </label>
      <label *ngIf="showShowDoors">
        <input type="checkbox" [(ngModel)]="showSwingFlexDoors" />
        {{translate('editor_swing_show_doors', 'Show doors')}}
      </label>
    </div>

    <div class="ribbon-block material">
      <pickable
        (click)="selectInteriorMaterial()"
        [pickable]="interiorMaterial"
        class="material interior-material modal-opener"
      ></pickable>
    </div>

    <div class="ribbon-block checkbox-ribbon-block" *ngIf="showDepthAdjustment">
      <label
        [ngStyle]="{visibility: isHeightReductionAllowed ? 'visible' : 'hidden'}"
      >
        <input type="checkbox" [(ngModel)]="heightReduction" />
        {{translate('editor_label_height_reduction', 'Height reduction')}}
      </label>
      <div class="input-group input-group-sm" [attr.title]="depthTooltip">
        <span
          class="input-group-text"
          [textContent]="translate('editor_interior_interior_depth', 'Depth')"
        ></span>
        <input
          class="form-control"
          id="interior-depth"
          type="number"
          [value]="depth"
          (change)="updateInteriorDepth($event, cabinetSection!)"
          [attr.min]="minDepth"
          [attr.max]="maxDepth"
          [disabled]="minDepth === maxDepth"
          [class.invalid]="isDepthInvalid()"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>

    <div class="ribbon-block favourites" *ngIf="showFavourites">
      <div class="btn-group" ngbDropdown [open]="isFavoritesMenuOpen">
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_interior_title_select_favourites', 'Favourites')"
          ></span>
        </button>
        <ul class="dropdown-menu" ngbDropdownMenu>
          <li role="menuitem">
            <a
              [textContent]="translate('editor_interior_title_select_favourites', 'Load favourite')"
              (click)="selectFavourites()"
            ></a>
          </li>
          <li role="menuitem">
            <a
              (click)="saveAsFavourite()"
              [textContent]="translate('editor_interior_title_save_favourite', 'Save as favourite')"
            ></a>
          </li>
        </ul>
      </div>
      <div class="btn-group" ngbDropdown [open]="isAdvancedMenuOpen">
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_interior_ribbon_advanced', 'Advanced')"
          ></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <li role="menuitem">
            <a
              (click)="selectAll()"
              [textContent]="translate('editor_interior_ribbon_advanced_select_all', 'Select All')"
            ></a>
          </li>
          <li role="menuitem" [ngClass]="{'disabled': !canChangeItemDepths}">
            <a
              (click)="changeItemDepths()"
              [textContent]="translate('editor_interior_ribbon_advanced_change_depth', 'Change selected item depths')"
            ></a>
          </li>
          <li role="menuitem">
            <a
              (click)="changeHerringBonePosition()"
              [textContent]="translate('editor_interior_ribbon_advanced_herring_position', 'Change herringbone position')"
            ></a>
          </li>
          <li role="menuitem">
            <a
              (click)="setFreeSpace()"
              [textContent]="translate('editor_interior_ribbon_advanced_free_space', 'Change free space')"
            ></a>
          </li>
          <li role="menuitem">
            <a
              (click)="fitToWidth()"
              [textContent]="translate('editor_interior_ribbon_advanced_fit_to_width', 'Fit to width')"
            ></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex-spacer"></div>
    <div class="ribbon-block right-menu-visibility-ribbon-block">
      <button
        class="btn btn-default"
        (click)="displayRightMenu = !displayRightMenu"
      >
        <span
          class="glyphicon"
          [ngClass]="displayRightMenu ? 'bi-chevron-right' : 'bi-chevron-left'"
        ></span>
      </button>
    </div>
  </div>

  <interior2d
    *ngIf="!use3d"
    class="editor-center center-svg"
    [cabinetSection]="cabinetSection!"
    [showRulers]="showRulers"
    [showPullout]="showPullout"
    [showCorpus]="showCorpus"
    [showHeightReduction]="heightReduction"
    [showDummyItems]="true"
    [showWarnings]="true"
    [showHinges]="showHinges"
    [showSwingFlexDoors]="showSwingFlexDoors"
  ></interior2d>

  <div class="tool-menu right-tool-menu" [hidden]="!displayRightMenu">
    <div class="tool-menu-body">
      <item-editor
        [item]="selectedItem"
        (itemChanged)="selectedItemChanged()"
        *ngIf="selectedItem"
        class="item-editor"
      ></item-editor>

      <button
        class="btn btn-danger btn-block"
        (click)="deleteSelectedItem()"
        *ngIf="isSelectedItemDeletable"
        [textContent]="translate('interior_item_delete_button_text', 'Delete')"
      ></button>
    </div>
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
