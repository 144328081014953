<div class="consumer-page consumer-corpus">
  <div class="consumer-nav">
    <navbar current-id="'corpus'"></navbar>
  </div>
  <div class="consumer-left">
    <div>
      <div>
        <item-picker
          [items]="availableCorpusMaterials"
          [(selectedItem)]="selectedCorpusMaterial"
          [name]="translate('consumer_corpus_exterior_color', 'Exterior Material')"
          class="item-picker"
        >
        </item-picker>
      </div>

      <div class="block-header">
        <span
          class="h4"
          [textContent]="translate('consumer_corpus_alignment_header', 'Placement')"
        ></span>
        <help-bubble
          [text]="translate('consumer_corpus_alignment_help','help text for Placement')"
          [header]="translate('consumer_corpus_alignment_header', 'Placement')"
        >
        </help-bubble>
      </div>

      <div class="block-header">
        <item-picker
          [items]="availableAlignmens"
          [(selectedItem)]="alignment"
          display-header="false"
          class="item-picker"
        >
        </item-picker>
      </div>

      <div *ngIf="!showAdvanced">
        <div class="block-header">
          <span
            class="h4"
            [textContent]="translate('consumer_corpus_features_header', 'Uneven surfaces')"
          ></span>
          <help-bubble
            [text]="translate('consumer_corpus_features_help','help text for uneven surfaces')"
            [header]="translate('consumer_corpus_features_header', 'Uneven surfaces')"
          >
          </help-bubble>
        </div>

        <div>
          <label for="corpus-uneven-floor" class="radio-check-label">
            <input
              type="checkbox"
              [(ngModel)]="unevenFloor"
              id="corpus-uneven-floor"
            />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_corpus_uneven_floor', 'Uneven floor')"
            ></span>
          </label>
        </div>
        <div>
          <label for="corpus-use-roof" class="radio-check-label">
            <input type="checkbox" [(ngModel)]="useRoof" id="corpus-use-roof" />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_corpus_use_roof', 'Use roof')"
            ></span>
          </label>
        </div>
        <div>
          <label for="corpus-uneven-wall-left" class="radio-check-label">
            <input
              type="checkbox"
              [(ngModel)]="unevenWallLeft"
              id="corpus-uneven-wall-left"
              [disabled]="!canUnevenWallLeft"
            />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_corpus_uneven_wall_left', 'Uneven wall left')"
            ></span>
          </label>
        </div>
        <div>
          <label for="corpus-uneven-wall-right" class="radio-check-label">
            <input
              type="checkbox"
              [(ngModel)]="unevenWallRight"
              id="corpus-uneven-wall-right"
              [disabled]="!canUnevenWallRight"
            />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_corpus_uneven_wall_right', 'Uneven wall right')"
            ></span>
          </label>
        </div>

        <button (click)="showAdvanced = true" class="link-button">
          {{translate('consumer_corpus_btn_show_advanced','Show advanced
          settings')}}
        </button>

        <div *ngIf="canUseHeightReduction" class="checkbox-container">
          <label for="corpus-height-reduction" class="radio-check-label">
            <input
              type="checkbox"
              [(ngModel)]="useHeightReduction"
              id="corpus-height-reduction"
            />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_basket_heightReduction_desc', 'Height reduction')"
            ></span>
          </label>
          <help-bubble
            [header]="translate('consumer_basket_heightReduction_desc', 'Height reduction')"
            [text]="translate('consumer_corpus_heightReduction_help','help text for heightReduction')"
          >
          </help-bubble>
        </div>

        <div *ngIf="canDrillHoles" class="checkbox-container">
          <label for="corpus-drill-holes" class="radio-check-label">
            <input
              type="checkbox"
              [(ngModel)]="drillHoles"
              id="corpus-drill-holes"
            />
            <span class="checkmark"></span>
            <span
              class="translation"
              [textContent]="translate('consumer_basket_drillHoles_desc', 'Drill holes')"
            ></span>
          </label>
          <help-bubble
            [header]="translate('consumer_basket_drillHoles_desc', 'Drill holes')"
            [text]="translate('consumer_corpus_drillHoles_help','help text for hole drilling')"
          >
          </help-bubble>
        </div>
      </div>

      <div *ngIf="showAdvanced">
        <div>
          <h6>{{translate('consumer_corpus_advanced_roof', 'Roof')}}</h6>
          <select
            class="form-control"
            [(ngModel)]="cabinetSection.corpus.panelTop"
            (ngModelChange)="setChanged(); "
          >
            <option
              *ngFor="let type of cabinetSection.corpus.availablePanelsTop"
              [ngValue]="type"
            >
              {{getPanelName(type)}}
            </option>
          </select>
        </div>

        <div>
          <h6>{{translate('consumer_corpus_advanced_floor', 'Floor')}}</h6>
          <select
            class="form-control"
            [(ngModel)]="cabinetSection.corpus.panelBottom"
            (ngModelChange)="setChanged(); "
          >
            <option
              *ngFor="let type of cabinetSection.corpus.availablePanelsBottom"
              [ngValue]="type"
            >
              {{getPanelName(type)}}
            </option>
          </select>
        </div>

        <div>
          <h6>{{translate('consumer_corpus_advanced_left', 'Left')}}</h6>
          <select
            class="form-control"
            [(ngModel)]="cabinetSection.corpus.panelLeft"
            (ngModelChange)="setChanged();"
          >
            <option
              *ngFor="let type of cabinetSection.corpus.availablePanelsLeft"
              [ngValue]="type"
            >
              {{getPanelName(type)}}
            </option>
          </select>
        </div>
        <div>
          <h6>{{translate('consumer_corpus_advanced_right', 'Right')}}</h6>
          <select
            class="form-control"
            [(ngModel)]="cabinetSection.corpus.panelRight"
            (ngModelChange)="setChanged();"
          >
            <option
              *ngFor="let type of cabinetSection.corpus.availablePanelsRight"
              [ngValue]="type"
            >
              {{getPanelName(type)}}
            </option>
          </select>
        </div>

        <button
          (click)="showAdvanced = false"
          class="link-button"
          style="margin-top: 10px"
        >
          {{translate('consumer_corpus_btn_reset_advanced','Reset advanced
          settings')}}
        </button>
      </div>
    </div>
  </div>

  <div class="consumer-main">
    <d3-display
      [cabinetSection]="cabinetSection"
      [showDoors]="false"
      [showInterior]="true"
      [showOtherCabinets]="false"
      [showCorpus]="true"
      [showRulers]="'toggle'"
      [showUndoRedo]="true"
      [allowMoveInterior]="false"
    ></d3-display>
  </div>

  <div class="consumer-bottom">
    <div class="btn-group prev-next-btns">
      <button class="btn btn-secondary btn-prev btn-prev" (click)="previous()">
        {{translate('consumer_btn_prev', 'Back')}}
      </button>

      <button (click)="next()" class="btn btn-primary btn-next">
        {{translate('consumer_btn_next', 'Next')}}
      </button>
    </div>

    <consumer-price [floorPlan]="floorPlan"></consumer-price>
  </div>

  <consumer-logo></consumer-logo>
</div>
