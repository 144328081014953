<div class="modal-content store-selector">
  <div class="modal-header" style="display: block">
    <div>
      <div style="display: flex; justify-content: space-between">
        <h3
          class="modal-title"
          [textContent]="translate('select_store_modal_header', 'Select store')"
        ></h3>
        <button
          type="button"
          class="close"
          style="float: right"
          (click)="cancel()"
        >
          &times;
        </button>
      </div>
    </div>

    <div>
      <input
        #searchBox
        class="form-control search-box"
        type="text"
        autofocus
        [attr.placeholder]="translate('select_store_modal_search_placeholder', 'Search')"
        [(ngModel)]="searchString"
      />
    </div>
  </div>

  <div class="modal-body" style="overflow-y: auto; max-height: 75vh">
    <div class="load-area" [ngClass]="{'loading': !ready}">
      <div class="load-spinner"></div>
    </div>

    <div
      class="store"
      *ngFor="let store of stores"
      (click)="selectedStore = store"
      (dblclick)="selectedStore = store; ok()"
      [ngClass]="{'active': selectedStore && selectedStore.Id === store.Id}"
      [attr.id]="'store_' + store.Id"
    >
      <div class="store-name" [textContent]="store.Name"></div>
      <div class="store-info">
        <div
          class="store-address1 address1"
          [textContent]="store.Address.Address1"
        ></div>
        <div
          class="store-address2 address2"
          [textContent]="store.Address.Address2"
        ></div>
        <div class="store-complete-city">
          <span
            class="store-country"
            [textContent]="store.Address.CountryCode"
          ></span>
          <span
            class="store-postalCode space"
            [textContent]="store.Address.PostalCode"
          ></span>
          <span
            class="store-city space"
            [textContent]="store.Address.City"
          ></span>
        </div>
        <div class="store-phone" [textContent]="store.Address.Phone"></div>
      </div>
    </div>
    <button
      class="btn btn-default btn-block"
      *ngIf="moreStoresAvailable"
      [textContent]="translate('select_store_modal_show_more_stores', 'Show more')"
      (click)="showMoreStores()"
    ></button>
  </div>

  <div class="modal-footer">
    <div
      style="
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      "
    >
      <div style="text-align: left; display: flex; flex-direction: column">
        <label *ngIf="allowFullCatalog">
          <input type="checkbox" [(ngModel)]="fullCatalog" />
          <span
            [textContent]="translate('select_store_use_full_catalog', 'Use full Catalog')"
          ></span>
        </label>
        <br />
        <label>
          <input type="checkbox" [(ngModel)]="changeLanguage" />
          <span
            [textContent]="translate('select_store_change_language', 'Use the store\'s language')"
          ></span>
        </label>
      </div>

      <div class="btn-group sm-group">
        <button
          class="btn btn-default"
          (click)="reset()"
          [textContent]="translate('select_store_modal_own_store', 'reset')"
        ></button>

        <button
          class="btn btn-primary-custom"
          (click)="ok()"
          [textContent]="translate('modal_button_ok', 'OK')"
        ></button>

        <button
          class="btn btn-default"
          (click)="cancel()"
          [textContent]="translate('modal_button_cancel', 'Cancel')"
        ></button>
      </div>
    </div>
  </div>
</div>
