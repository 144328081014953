import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
export class SalesChainSettingHelper {
  /**
   * Determines whether to force only doors (no rails) on 'Doors only' selection?
   * @param editorAssets
   */
  public static forceOnlyDoors(editorAssets: Client.EditorAssets): boolean {
    return (
      editorAssets.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ForceOnlyDoors
      ] === '1'
    );
  }

  /**
   * Determines whether it should be possible to adjust door widths individually (by moving door overlaps)
   * @param editorAssets
   */
  public static allowIndividualDoorWidths(
    editorAssets: Client.EditorAssets,
  ): boolean {
    return (
      editorAssets.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.AllowIndividualDoorWidths
      ] === '1'
    );
  }
}
