export const ProductLineIds = {
  Mm19: 1,
  Mm22: 2,
  Mm19light: 4,
  WalkIn: 5,
  Swing: 6,
  mm19ReferencePlus: 7,
  mm19Premium: 8,

  Partition: 9,
  Swing22: 10,
  Swing19: 11,
};

export const EventLogName: string = 'KA-Retail HTML5';
export const LogSourceServer: string = 'server side error';
export const LogSourceClient: string = 'client side error';
export const LogSourceServerReport: string = 'server info';
export const OverrideChainHttpHeaderName: string = 'X-KA-OverrideChainId';
export const UseFullCatalogHttpHeaderName: string = 'X-KA-UseFullCatalog';
export const CustomPriceDateHeaderName: string = 'X-KA-CustomPriceDate';
export const LanguageHttpHeaderName: string = 'X-KA-Language';
export const KAOrderNoRegex: string = '^KAd{7}$';
export const ProductDatatypeName: string = 'text/ka-ProductDragInfo';
export const LoginCookieName: string = 'loginToken';
export const CountryCodeExcemptFromZipPrefix: string = 'dk';
export const OrderConfirmerPageSize: number = 50;
export const CurrentRuleSet: number = 2;
