import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { User } from 'app/identity-and-access/User';
import { Observable, Subscription } from 'rxjs';

export interface ActiveUser {
  get data(): User;
  /**
   * Subscribes to changes in active user. The subscriber
   * will be called immediately with the current value, if any
   * @param next
   * @returns
   */
  subscribe: (next: (value: User | null) => void) => Subscription;

  get data$(): Observable<User | null>;
}

function activeUserFactory(data: FunctionalCoreService): ActiveUser {
  return {
    data: data.ambient.activeUser.activeUser!,
    subscribe(next: (value: User | null) => void) {
      return data.ambient.activeUser.subscribeActiveUser(next);
    },
    data$: data.ambient.activeUser.activeUser$,
  };
}

export const ActiveUserInjector = new InjectionToken<ActiveUser>('ActiveUser', {
  factory() {
    const data = inject(FunctionalCoreService);

    return activeUserFactory(data);
  },
});

export const activeUserProvider: Provider = {
  provide: ActiveUserInjector,
  useFactory: activeUserFactory,
  deps: [FunctionalCoreService],
};
