<div class="modal-content user-settings">
  <div class="modal-header">
    <h3 class="modal-title" [textContent]="title"></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <form>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('store_settings_label_terms', 'Terms')"
            ></span>
          </div>
          <div class="col-md-6">
            <textarea
              [(ngModel)]="storeData.Terms"
              class="form-control"
              rows="2"
              name="terms"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('store_settings_label_offer_validity', 'Offer validity')"
            ></span>
          </div>

          <div class="col-md-6">
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                min="0"
                step="1"
                [(ngModel)]="storeData.NumberOfDaysConfigurationIsValid"
                name="NumberOfDaysConfigurationIsValid"
              />
              <span
                class="input-group-text"
                [textContent]="translate('store_settings_offer_validity_unit', 'Days')"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="save()"
        [textContent]="translate('button_save', 'Save')"
      ></button>

      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
