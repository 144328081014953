import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'app/floor-plan-editing/pending-changes.guard';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/FloorPlan';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { Observable, Subject } from 'rxjs';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';

@Component({ template: '' })
export abstract class EditorVm
  extends BaseVm
  implements ComponentCanDeactivate
{
  @HostListener('document:keydown', ['$event'])
  private handleKeyDown(event: KeyboardEvent) {
    this.handleKeyboardEvent(event);
  }

  public isSaving: boolean = false;
  private isSureAboutLeaving: boolean = false;

  private _floorplanLoaded$ = new Subject<Client.FloorPlan>();
  protected get floorplanLoaded$(): Observable<Client.FloorPlan> {
    return this._floorplanLoaded$;
  }

  protected editorType!: EditorTypeService;
  protected changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected router: Router = inject(Router);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public partitions: PartitionPlanQueryService;

  constructor(
    baseVmService: BaseVmService,
    protected readonly $window: WindowService,
    protected readonly floorPlanService: FloorPlanService,
    protected readonly floorPlanSaveService: FloorPlanSaveService,
    private readonly routeService: RouteService,
    protected readonly routing: AppRoutingModule,
    private readonly deliveryAddressService: DeliveryAddressService,
  ) {
    super(baseVmService);

    this.editorType = inject(EditorTypeService);
    this.partitions = inject(PartitionPlanQueryService);
    this.ensureUnsubscribe(
      this.editorType.ready$.subscribe((ready) => {
        if (ready) {
          this.init();
          this._floorplanLoaded$.next(this.floorPlan);
        }
      }),
    );
  }

  async init(): Promise<void> {
    if (!this.floorPlan) return;
    this.$window.onbeforeunload = () => {
      if (App.debug.supressAskForReload) {
        this.floorPlanService.temporarilyDisableAutosave(this.floorPlan);
        return undefined;
      }
      //This is triggered when you leave the site completely - IE by closing the tab or going to another domain
      if (this.isDirty) {
        this.floorPlanService.temporarilyDisableAutosave(this.floorPlan);
        return this.translate(
          'editor_leave_dialog_question',
          'You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.',
        );
      } else {
        return undefined;
      }
    };

    this.checkAutosave();
  }

  public get isDirty(): boolean {
    return this.floorPlan?.isDirty;
  }

  public get floorPlan() {
    return this.editorType.floorPlan;
  }

  public get cabinetSection() {
    if (this.editorType?.cabinetSection) return this.editorType.cabinetSection;

    return undefined;
    throw new Error('No cabinet section specified');
  }

  public async save() {
    if (this.isSaving) return;

    if (!this.floorPlan.isAddressSet) {
      let shouldContinue = await this.displaySaveAsDialog();
      if (!shouldContinue) return;
    }

    await this.baseVmService.notificationService.loadPromise(this.saveAsync());
  }

  private async saveAsync() {
    try {
      this.isSaving = true;

      let deliveryInfoEditor = (di: Interface_DTO.DeliveryInfo) =>
        this.baseVmService.modalService.editDeliveryInfo({
          ...di,
          InternalNote: this.floorPlan.InternalNote,
          UserId: this.floorPlan.UserId,
          Name: this.floorPlan.Name,
        });
      const deliveryInfoAcceptor = async (
        deliveryInfo: Interface_DTO.DeliveryInfo,
        requestedDeliveryWeek: number,
      ) => {
        const deliveryAddress =
          await this.deliveryAddressService.getDeliveryAddressForDeliveryInfoId(
            deliveryInfo.Id,
          );
        return this.baseVmService.modalService.acceptDeliveryInfo(
          deliveryInfo,
          this.floorPlan.Name,
          deliveryInfo.DeliveryWeek != requestedDeliveryWeek,
          this.floorPlan.DeliveryStatus,
          deliveryAddress,
        );
      };
      await this.floorPlanSaveService.saveFloorPlan(this.floorPlan, {
        deliveryInfoEditor,
        deliveryInfoAcceptor,
        showNotifications: true,
      });
    } catch (e: any) {
      let errorHandled = false;
      if (e instanceof Exception.CorrectionDeadlineExceeded) {
        this.baseVmService.notificationService.userError(
          'floorplan_save_failed_deadline_exceeded',
          'The correction deadline is exceeded, no changes are possible',
        );
        errorHandled = true;
      } else if (
        e instanceof Exception.CancelException ||
        e === Constants.userCancelled
      ) {
        this.baseVmService.notificationService.warning(
          'floorplan_save_cancelled',
          'Saving the floorplan was cancelled',
        );
        errorHandled = true;
      } else if (e instanceof Exception.ServerDomainException) {
        if (
          e.Type === Interface_DTO_DomainError.ErrorType.FloorPlanNotOrderable
        ) {
          this.baseVmService.notificationService.userError(
            'floorplan_save_failed_not_orderable',
            'The floorplan is not valid and could not be ordered.',
          );
          errorHandled = true;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.OrderIsEmpty
        ) {
          this.baseVmService.notificationService.userError(
            'floorplan_order_failed_order_empty',
            'The floorplan is empty and cannot be ordered',
          );
          errorHandled = true;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.OptimisticLockFailed
        ) {
          this.baseVmService.notificationService.userError(
            'floorplan_save_failed_double_upgrade',
            'The floorplan cannot be saved. It might be open on another computer. Reload the page and try again.',
          );
          errorHandled = true;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.SalesTypeInvalid
        ) {
          this.baseVmService.notificationService.userError(
            'floorplan_save_failed_salesType_invalid',
            'The sales type is no longer valid.',
          );
          errorHandled = true;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.FloorPlanPriceChanged
        ) {
          this.baseVmService.notificationService.userError(
            'order_change_failed_price_changed',
            'Cannot update order because the total price has changed',
          );
          errorHandled = true;
        } else if (
          e.Type ===
          Interface_DTO_DomainError.ErrorType.ActiveOrderNotUpgradeable
        ) {
          this.baseVmService.notificationService.userError(
            'order_change_failed_not_upgradeable',
            'Cannot change an old order. Use v1 to change the order',
          );
          errorHandled = true;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.FloorOptionNotAllowed
        ) {
          this.baseVmService.notificationService.userError(
            'floorplan_save_failed_floor_option_invalid',
            'Floor option is not allowed for this zip code',
          );
          errorHandled = true;
        } else if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          errorHandled = true;
          this.baseVmService.notificationService.userError(
            'floorplan_save_failed_active_order',
            'This order is currently being produced and can no longer be changed.',
          );
        }
      }
      if (!errorHandled) {
        this.baseVmService.notificationService.exception(
          'floorplan_save_failed_noti',
          e,
          'Saving the floor plan failed',
        );
      }
    } finally {
      this.isSaving = false;
    }
  }

  private async displaySaveAsDialog(): Promise<boolean> {
    try {
      let newDeliveryAddress =
        await this.baseVmService.modalService.displaySaveAsDialog(
          this.floorPlan,
        );
      this.routeService.setCustomerIdForLastSearch(
        newDeliveryAddress.project.customer.Id,
      );
      return true;
    } catch (e: any) {
      //User cancelled
      return false;
    }
  }

  // #region undo

  public get isUndoEnabled(): boolean {
    return this.floorPlanService.isUndoPossible(this.floorPlan);
  }

  public undo() {
    if (!this.isUndoEnabled) return;
    this.floorPlanService.undo(this.floorPlan);
    this.floorPlanChanged();
    this.changeDetector.detectChanges();
  }

  public get isRedoEnabled(): boolean {
    return this.floorPlanService.isRedoPossible(this.floorPlan);
  }

  public redo() {
    if (!this.isRedoEnabled) return;
    this.floorPlanService.redo(this.floorPlan);
    this.floorPlanChanged();
    this.changeDetector.detectChanges();
  }

  // #endregion undo

  @HostListener('window:unload', ['$event'])
  unloadHandler($event: any) {
    this.unloadNotification($event);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = this.leaveMessage;
    }
  }

  canDeactivate(): boolean {
    if (this.isSureAboutLeaving) {
      console.warn('EditorVm.isSureAboutLeaving was used here');
      return true;
    }
    if (this.isDirty) {
      const shouldLeave = window.confirm(this.leaveMessage);
      if (shouldLeave) {
        this.isSureAboutLeaving = true;
        this.discardUnsavedChanges();
      } else {
        return false;
      }
    } else {
      this.discardUnsavedChanges();
    }

    return true;
  }

  protected get leaveMessage(): string {
    return this.translate(
      'editor_leave_dialog_question',
      'You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.',
    );
  }

  private async discardUnsavedChanges(): Promise<void> {
    await this.floorPlanService.discardUnsavedChanges(this.floorPlan);
  }

  protected handleKeyboardEvent(evt: KeyboardEvent) {
    if (evt.defaultPrevented) return;

    if (evt.ctrlKey && !evt.shiftKey && !evt.altKey && !evt.metaKey) {
      //ctrl key only
      let preventDefault = true;
      switch (evt.key) {
        case 's':
          this.save();
          break;
        case 'z':
          this.undo();
          break;
        case 'y':
          this.redo();
          break;
        default:
          preventDefault = false;
      }
      if (preventDefault) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    }
  }

  public async setChanged() {
    let success = await this.floorPlanService.setChanged(this.floorPlan);
    if (success) {
      this.floorPlanChanged();
    }
    return success;
  }

  private async checkAutosave(): Promise<void> {
    if (this.floorPlan.isAutosaveChecked) return;
    this.floorPlan.isAutosaveChecked = true;

    let autosaveDate = this.floorPlanService.getAutosaveDate(this.floorPlan);
    if (!autosaveDate) return;

    let restoreAutosave =
      await this.baseVmService.modalService.getRestoreAutosaveModal(
        this.floorPlan.lastEdit,
        autosaveDate,
      ).result;

    this.floorPlanService.restoreAutosave(this.floorPlan, restoreAutosave);
  }

  protected override dispose() {
    super.dispose();

    this.$window.onbeforeunload = () => null;
  }

  protected abstract floorPlanChanged(): any;
}
