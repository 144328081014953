import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppVersionVm } from './AppVersionVm';

@NgModule({
  declarations: [AppVersionVm],
  exports: [AppVersionVm],
  imports: [CommonModule],
})
export class AppVersionModule {}
