import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';

import { Material } from 'app/ts/clientDto/Material';
export class ProductMaterial extends Material {
  constructor(
    material: Client.Material,
    private readonly materialId: Interface_DTO.MaterialId,
    public readonly productId: number,
  ) {
    super(material.dto, material.materialGroup, material.designMaterialGroup);
    this.isDiscontinued = !materialId.IsEnabled;
    this.designType = materialId.DesignType;
  }

  public readonly designType: Interface_Enums.MaterialDesignType;
  public get isOverride(): boolean {
    if (App.debug.disableAllMaterials) {
      return true;
    } else if (App.debug.enableAllMaterials) {
      return false;
    }
    return this.materialId.IsOverride;
  }
  public readonly isDiscontinued: boolean;
}
