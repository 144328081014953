import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO_OrderConfirmer from 'app/ts/Interface_DTO_OrderConfirmer';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { NotificationService } from 'app/ts/services/NotificationService';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OrderConfirmerService implements OnInit {
  public readonly chains$ = new BehaviorSubject<
    Interface_DTO_OrderConfirmer.Chain[]
  >([]);

  public constructor(
    public readonly httpClient: PromisingBackendService,
    private readonly notificationService: NotificationService,
  ) {
    const chains = this.httpClient
      .get<Interface_DTO_OrderConfirmer.Chain[]>('api/orderConfirmer/chain')
      .then((chains) => {
        this.chains$.next(chains);
      });
  }

  async ngOnInit(): Promise<void> {}

  public search(
    orderSearch: Interface_DTO_OrderConfirmer.OrderSearch,
  ): Promise<Interface_DTO_OrderConfirmer.OrderDetail[]> {
    return this.httpClient.get<Interface_DTO_OrderConfirmer.OrderDetail[]>(
      'api/orderConfirmer/order',
      { params: orderSearch },
    );
  }

  public async changeOrderStatus(
    deliveryInfoId: number,
    deliveryStatus: Interface_Enums.DeliveryStatus,
  ): Promise<void> {
    return this.httpClient.post<void>(
      'api/orderConfirmer/order/' + deliveryInfoId + '/status',
      deliveryStatus,
      { responseType: 'void', timeout: 60000 },
    );
  }

  public async confirmOrder(
    orderChangeRequest: Interface_DTO_OrderConfirmer.OrderChangeRequest,
    floorPlanId: number,
    orderAcceptor: (
      deliveryInfo: Interface_DTO.DeliveryInfo,
    ) => Promise<boolean>,
  ): Promise<boolean> {
    let saveRequest: Interface_DTO.BulkDeliveryInfoEditRequest = {
      DeliveryInfoIds: [orderChangeRequest.DeliveryInfoId],
      DeliveryWeek: orderChangeRequest.DeliveryWeek,
      Reference: orderChangeRequest.Reference,
      MarketOrderNumber: orderChangeRequest.MarketOrderNumber,
      DriverInformation: null,
      IntegrationIdentifier: null,
      IntegrationIdentifier2: null,
      IntegrationIdentifier3: null,
      InternalNote: null,
      Name: null,
      ModifiedDate: new Date(),
      SalesTypeCaseNumber: null,
      SalesTypeNumber: null,
      ShippingAgentCode: null,
      SmsNumber: null,
      SupporterId: null,
      UserId: null,
      ForceOrder: true,
      LogisticsComment: null,
      OrderComment: null,
      ManualOrderHandling: false,
      SendReminderEmails: false,
    };

    let deliveryInfo = await this.notificationService.loadPromise(
      this.httpClient.post<Interface_DTO.DeliveryInfo>(
        'api/order/revalidateDeliveryInfo/' + floorPlanId,
        saveRequest,
        { timeout: 60000 },
      ),
    );

    let userAccept = await orderAcceptor(deliveryInfo);

    if (!userAccept) return false;
    await this.notificationService.loadPromise(
      this.httpClient.post<void>(
        'api/order/recommitDeliveryInfo',
        saveRequest,
        { responseType: 'void', timeout: 60000 },
      ),
    );
    return true;
  }

  public async confirmOldOrder(
    orderChangeRequest: Interface_DTO_OrderConfirmer.OrderChangeRequest,
    oldCabinetId: number,
    orderAcceptor: (
      deliveryInfo: Interface_DTO.DeliveryInfo,
    ) => Promise<boolean>,
  ): Promise<boolean> {
    let deliveryInfo = await this.notificationService.loadPromise(
      this.httpClient.post<Interface_DTO.DeliveryInfo>(
        'api/order/oldOrder/' + oldCabinetId + '/validate',
        orderChangeRequest,
      ),
    );

    let userAccept = await orderAcceptor(deliveryInfo);
    if (!userAccept) return false;
    await this.notificationService.loadPromise(
      this.httpClient.post(
        'api/order/oldOrder/' + oldCabinetId + '/commit',
        orderChangeRequest,
      ),
    );
    return true;
  }

  public cancelOrder(deliveryInfoId: number): Promise<void> {
    return this.httpClient.post<void>(
      'api/order/' + deliveryInfoId + '/cancel',
      {},
      { responseType: 'void' },
    );
  }
}
