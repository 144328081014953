<!-- Move order(s) to another deliveryaddress -->
<div class="modal-header">
  <table>
    <tr>
      <h4 [textContent]="currentHeader"></h4>
    </tr>
    <tr>
      <div class="ribbon-menu">
        <div class="ribbon-block">
          <button
            class="btn btn-default"
            [ngClass]="{'disabled': !isBackEnabled}"
            (click)="back()"
          >
            <span class="bi bi-caret-left-fill"></span>
          </button>
        </div>
        <div class="ribbon-block">
          <button
            class="btn btn-default"
            (click)="newCustomer()"
            *ngIf="!selectedCustomer"
          >
            <span class="bi bi-person-fill"></span>
            <span
              [textContent]="translate('modal_select_delivery_address_create_customer', 'Create New Customer')"
            ></span>
          </button>

          <button
            class="btn btn-default"
            *ngIf="selectedCustomer && !selectedProject"
            (click)="newProject()"
          >
            <span class="bi bi-folder2-open"></span>
            <span
              [textContent]="translate('modal_select_delivery_address_create_project', 'Create New Project')"
            ></span>
          </button>

          <button
            class="btn btn-default"
            *ngIf="selectedCustomer && selectedProject"
            (click)="newDeliveryAddress()"
          >
            <span class="bi bi-geo-alt-fill"></span>
            <span
              [textContent]="translate('modal_select_delivery_address_create_delivery_address', 'Create New Delivery Address')"
            ></span>
          </button>
        </div>

        <div class="ribbon-block">
          <form name="searchForm">
            <div class="input-group">
              <input
                type="search"
                name="searchString"
                class="form-control"
                style="width: 250px"
                [(ngModel)]="quickSearchString"
                [ngModelOptions]="quickSearchModelOptions"
                [attr.placeholder]="translate('modal_select_delivery_address_quick_search_placeholder', 'Search')"
                autofocus
              />

              <button
                class="btn btn-default"
                type="submit"
                (click)="search()"
                [textContent]="translate('modal_select_delivery_address_search', 'Search')"
              ></button>
            </div>
          </form>
        </div>
      </div>
    </tr>
  </table>
</div>

<div class="modal-body">
  <!-- customers-table, projects-table and delivery-addresses-table are also used by the functions copy order and move order. In these functions there 
    should be no navigation-buttons hence displayNavigationRight and displayNavigationLeft -->
  <customers-table
    [objects]="customers"
    [_displayNavigationLeft]="displayNavigationLeft"
    [_displayNavigationRight]="displayNavigationRight"
    *ngIf="!selectedCustomer"
    (onItemSelected)="selectCustomer($event)"
  >
  </customers-table>
  <projects-table
    [objects]="selectedCustomer.Projects"
    [_displayNavigationLeft]="displayNavigationLeft"
    [_displayNavigationRight]="displayNavigationRight"
    *ngIf="selectedCustomer && !selectedProject"
    (onItemSelected)="selectProject($event)"
  >
  </projects-table>
  <delivery-addresses-table
    [objects]="selectedProject.DeliveryAddresses"
    [_displayNavigationLeft]="displayNavigationLeft"
    [_displayNavigationRight]="displayNavigationRight"
    *ngIf="selectedCustomer && selectedProject"
    (onItemSelected)="selectDeliveryAddress($event)"
    [showRadioButton]=" _showRadioButton"
  >
  </delivery-addresses-table>
</div>
<div class="modal-footer">
  <div class="btn-group">
    <button
      class="btn btn-Nav"
      (click)="ok()"
      [ngClass]="{'disabled': selectedDeliveryAddreses.length === 0}"
      [textContent]="translate('button_ok', 'OK')"
    ></button>
    <button
      class="btn btn-secondary"
      (click)="cancel()"
      [textContent]="translate('button_cancel', 'Cancel')"
    ></button>
  </div>
</div>
