import { Section } from 'app/partition/floor-plan';
import { Cabinet, CabinetSection, FloorPlan } from 'app/ts/clientDto';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import { WallSection } from 'app/ts/Interface_DTO_FloorPlan';
import { CabinetType } from 'app/ts/Interface_Enums';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { MeasurementData } from './Measurement';
import { Profile, ProfileType } from 'app/partition/profile';
import { Module } from 'app/partition/module';
import { Rail } from 'app/partition/rail';

export interface Line {
  start?: Vec2d;
  end?: Vec2d;
}

export function calculateMeasurementLength(
  measurement: MeasurementData,
  floorPlan: FloorPlan,
  partitionSections: Section[],
  partitionProfiles: Profile[],
): Line {
  let lines = getAllLinesOnFloorPlan(
    floorPlan,
    partitionSections,
    partitionProfiles,
  );
  let sortedIntersectionPoints = findAndSortIntersectionPoints(
    lines,
    measurement,
    floorPlan,
  );
  let line = findClosestPointOnEachSide(measurement, sortedIntersectionPoints);

  if (!line.end) line.end = line.start;

  if (!line.start) line.start = line.end;

  return line;
}

//#region Finding Lines
function getAllLinesOnFloorPlan(
  floorPlan: FloorPlan,
  partitionSections: Section[],
  partitionProfiles: Profile[],
): Line[] {
  let lines = getWallLines(floorPlan.walls);
  floorPlan.cabinets.forEach((cabinet) => {
    if (cabinet.CabinetType != CabinetType.SharedItems)
      lines = lines.concat(getCabinetLines(cabinet));
  });
  partitionSections.forEach((section) => {
    lines = lines.concat(getPartitionSectionLines(section));
  });

  partitionProfiles.forEach((profile) => {
    if (profile.type == ProfileType.Corner)
      lines = lines.concat(getPartitionCornerProfileLines(profile));
    if (profile.type == ProfileType.TPiece)
      lines = lines.concat(getPartitionTProfileLines(profile));
  });

  return lines;
}

export function getWallLines(walls: WallSection[]): Line[] {
  return walls.map((wall) => {
    let lineUnitVec = {
      X: wall.End.X - wall.Begin.X,
      Y: wall.End.Y - wall.Begin.Y,
    };
    lineUnitVec = VectorHelper.getNormalizedVector(lineUnitVec);

    //Extending lines by 2 in each end, to avoid collisions on endpoint of lines
    return {
      start: {
        X: wall.Begin.X - lineUnitVec.X * 2,
        Y: wall.Begin.Y - lineUnitVec.Y * 2,
      },
      end: {
        X: wall.End.X + lineUnitVec.X * 2,
        Y: wall.End.Y + lineUnitVec.Y * 2,
      },
    };
  });
}

function getCabinetLines(cabinet: Cabinet): Line[] {
  let lines: Line[] = [];
  cabinet.cabinetSections.forEach((section) => {
    lines = lines.concat(getCabinetSectionLines(section));
  });
  return lines;
}

function getCabinetSectionLines(cabinetSection: CabinetSection): Line[] {
  let [topLeft, topRight, bottomRight, bottomLeft] =
    cabinetSection.getPoints(false);

  return [
    { start: topLeft, end: topRight },
    { start: bottomLeft, end: bottomRight },
    { start: topLeft, end: bottomLeft },
    { start: topRight, end: bottomRight },
  ];
}

function getPartitionSectionLines(section: Section): Line[] {
  let [topLeft, topRight, bottomRight, bottomLeft] =
    getPartitionSectionPoints(section);

  return [
    { start: topLeft, end: topRight },
    { start: bottomLeft, end: bottomRight },
    { start: topLeft, end: bottomLeft },
    { start: topRight, end: bottomRight },
  ];
}

function getPartitionCornerProfileLines(profile: Profile): Line[] {
  let [topLeft, topRight, topRightCorner, rightTop, bottomRight, bottomLeft] =
    getPartitionCornerProfilePoints(profile);

  // *   Rotation 0
  // * *

  return [
    { start: topLeft, end: topRight },
    { start: topRight, end: topRightCorner },
    { start: topRightCorner, end: rightTop },
    { start: rightTop, end: bottomRight },
    { start: bottomRight, end: bottomLeft },
    { start: bottomLeft, end: topLeft },
  ];
}

function getPartitionTProfileLines(profile: Profile): Line[] {
  let [
    topLeft,
    topRight,
    topRightCorner,
    rightTop,
    rightBottom,
    bottomRightCorner,
    bottomRight,
    bottomLeft,
  ] = getPartitionTProfilePoints(profile);

  // *   Rotation 0
  // * *
  // *

  return [
    { start: topLeft, end: topRight },
    { start: topRight, end: topRightCorner },
    { start: topRightCorner, end: rightTop },
    { start: rightTop, end: rightBottom },
    { start: rightBottom, end: bottomRightCorner },
    { start: bottomRightCorner, end: bottomRight },
    { start: bottomRight, end: bottomLeft },
    { start: bottomLeft, end: topLeft },
  ];
}
function getPartitionSectionPoints(section: Section) {
  var topSideRailOverhang;
  var bottomSideRailOverhang;

  //Done to keep rail outside positions consistent, even though they can't align with the mm grid
  if (section.isVertical) {
    topSideRailOverhang = section.isInverted
      ? Math.floor(-Rail.railOverhangPrecise)
      : Math.floor(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = section.isInverted
      ? Math.ceil(Rail.railOverhangPrecise)
      : Math.ceil(Rail.railOverhangPrecise);
  } else {
    topSideRailOverhang = section.isInverted
      ? Math.floor(-Rail.railOverhangPrecise)
      : Math.floor(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = section.isInverted
      ? Math.ceil(Rail.railOverhangPrecise)
      : Math.ceil(Rail.railOverhangPrecise);
  }

  return [
    rotatePartitionSectionPoint(section, { X: 0, Y: topSideRailOverhang }),
    rotatePartitionSectionPoint(section, {
      X: section.width,
      Y: topSideRailOverhang,
    }),
    rotatePartitionSectionPoint(section, {
      X: section.width,
      Y: section.modules[0].depth + bottomSideRailOverhang,
    }),
    rotatePartitionSectionPoint(section, {
      X: 0,
      Y: section.modules[0].depth + bottomSideRailOverhang,
    }),
  ];
}

function getPartitionCornerProfilePoints(profile: Profile) {
  //Done to keep rail outside positions consistent, even though they can't align with the mm grid
  var leftSideRailOverhang: number = 0;
  var bottomSideRailOverhang: number = 0;

  if (profile.rotation == 0) {
    leftSideRailOverhang = Math.floor(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = Math.ceil(Rail.railOverhangPrecise);
  } else if (profile.rotation == 90) {
    leftSideRailOverhang = Math.floor(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = Math.ceil(Rail.railOverhangPrecise);
  } else if (profile.rotation == 180) {
    leftSideRailOverhang = Math.ceil(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = Math.ceil(Rail.railOverhangPrecise);
  } else if (profile.rotation == 270) {
    leftSideRailOverhang = Math.ceil(-Rail.railOverhangPrecise);
    bottomSideRailOverhang = Math.ceil(Rail.railOverhangPrecise);
  }

  return [
    rotatePartitionProfilePoint(profile, {
      X: leftSideRailOverhang,
      Y: 0 - Module.elementSpacing,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth,
      Y: 0 - Module.elementSpacing,
    }),
    rotatePartitionProfilePoint(profile, { X: Module.fullJointWidth, Y: 0 }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth + Module.elementSpacing,
      Y: 0,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth + Module.elementSpacing,
      Y: Module.fullJointWidth + bottomSideRailOverhang,
    }),
    rotatePartitionProfilePoint(profile, {
      X: leftSideRailOverhang,
      Y: Module.fullJointWidth + bottomSideRailOverhang,
    }),
  ];
}

function getPartitionTProfilePoints(profile: Profile) {
  //Done to keep rail outside positions consistent, even though they can't align with the mm grid
  var leftSideRailOverhang: number = 0;

  if (profile.rotation == 0) {
    leftSideRailOverhang = Math.ceil(-Rail.railOverhangPrecise);
  } else if (profile.rotation == 90) {
    leftSideRailOverhang = Math.floor(-Rail.railOverhangPrecise);
  } else if (profile.rotation == 180) {
    leftSideRailOverhang = Math.ceil(-Rail.railOverhangPrecise);
  } else if (profile.rotation == 270) {
    leftSideRailOverhang = Math.ceil(-Rail.railOverhangPrecise);
  }
  return [
    rotatePartitionProfilePoint(profile, {
      X: leftSideRailOverhang,
      Y: 0 - Module.elementSpacing,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth,
      Y: 0 - Module.elementSpacing,
    }),
    rotatePartitionProfilePoint(profile, { X: Module.fullJointWidth, Y: 0 }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth + Module.elementSpacing,
      Y: 0,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth + Module.elementSpacing,
      Y: Module.fullJointWidth,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth,
      Y: Module.fullJointWidth,
    }),
    rotatePartitionProfilePoint(profile, {
      X: Module.fullJointWidth,
      Y: Module.fullJointWidth + Module.elementSpacing,
    }),
    rotatePartitionProfilePoint(profile, {
      X: leftSideRailOverhang,
      Y: Module.fullJointWidth + Module.elementSpacing,
    }),
  ];
}

function rotatePartitionSectionPoint(section: Section, point: Vec2d): Vec2d {
  let rotationRads = section.rotation * (Math.PI / 180);
  let rotatedPoint = VectorHelper.rotate2d(point, rotationRads);
  return VectorHelper.add(rotatedPoint, { X: section.posX, Y: section.posY });
}

function rotatePartitionProfilePoint(profile: Profile, point: Vec2d): Vec2d {
  let rotationRads = profile.rotation * (Math.PI / 180);
  let rotatedPoint = VectorHelper.rotate2d(point, rotationRads);
  return VectorHelper.add(rotatedPoint, { X: profile.posX, Y: profile.posY });
}

function getMeasurementLine(
  measurement: MeasurementData,
  floorPlan: FloorPlan,
): Line {
  if (measurement.horizontal) {
    return {
      start: { X: -100, Y: measurement.posY },
      end: { X: floorPlan.Size.X + 100, Y: measurement.posY },
    };
  } else {
    return {
      start: { X: measurement.posX, Y: -100 },
      end: { X: measurement.posX, Y: floorPlan.Size.Y + 100 },
    };
  }
}
//#endregion

function findAndSortIntersectionPoints(
  allLines: Line[],
  measurement: MeasurementData,
  floorPlan: FloorPlan,
) {
  let measurementLine = getMeasurementLine(measurement, floorPlan);

  let intersetionPoints: Vec2d[] = allLines
    .map((line) => GeometryHelper.lineIntersectionPoint(measurementLine, line))
    .filter((point) => point != null) as Vec2d[];

  // Sort points in order of which is closest
  let measurementOrigin: Vec2d = { X: measurement.posX, Y: measurement.posY };
  intersetionPoints.sort((a: Vec2d, b: Vec2d) => {
    let aDist = VectorHelper.dist(measurementOrigin, a);
    let bDist = VectorHelper.dist(measurementOrigin, b);

    return aDist - bDist;
  });

  return intersetionPoints;
}

function findClosestPointOnEachSide(
  measurement: MeasurementData,
  points: Vec2d[],
): Line {
  if (measurement.horizontal) {
    return {
      start: points.find((pt) => pt.X <= measurement.posX),
      end: points.find((pt) => pt.X >= measurement.posX),
    };
  } else {
    return {
      start: points.find((pt) => pt.Y <= measurement.posY),
      end: points.find((pt) => pt.Y >= measurement.posY),
    };
  }
}
