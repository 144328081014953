<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('modal_fpt_title', 'Select Template')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <div *ngIf="!templates">
      <span [textContent]="translate('modal_fpt_loading', 'Loading')"></span>
    </div>

    <div class="pickable-group">
      <div
        *ngFor="let pickable of templates"
        class="pickable-overlay-container template"
      >
        <pickable
          (click)="selectTemplate(pickable.item)"
          style="cursor: pointer"
          class="template"
          [pickable]="pickable"
        ></pickable>
      </div>
    </div>
  </div>
</div>
