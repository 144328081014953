//IE has a slow Css engine...
export function hasSlowCssEngine(): boolean {
  return isIE();
}

export function supportsSvgForeignObject(): boolean {
  return !isIE();
}

function isIE(): boolean {
  return (
    !!(window as any).MSInputMethodContext && !!(document as any).documentMode
  );
}
