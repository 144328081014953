import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
  UserSettingsValue,
} from 'app/functional-core/ambient/userdata/UserData';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NavigationService } from 'app/ts/services/NavigationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import {
  Cabinet,
  EditorTopbarVm,
  Path,
  Section,
} from 'app/ts/viewmodels/components/EditorTopbarVm';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { SectionId, Section as partitionSection } from 'app/partition/section';
import { CustomerService } from 'app/ts/services/CustomerService';

@Component({
  selector: 'floorplan-topbar',
  templateUrl: './floorPlanTopbar.html',
  styleUrls: [
    'topbar.scss',
    '../../../../style/smallScreenHacks.scss',
    '../../../../style/errorInfo.scss',
  ],
  providers: [userDataProvider, activeStoreProvider],
})
export class FloorPlanTopbarVm extends BaseVm implements OnInit, OnChanges {
  @Input()
  public cabinet?: Client.Cabinet;

  @Input()
  public floorPlan!: Client.FloorPlan;

  @Input()
  public vm!: EditorVm;

  @Input()
  public selection!: string;

  public paths: Path[] | null = null;
  public sections: Section[] | null = null;
  public cabinets: Cabinet[] | null = null;
  public sectionSelectorOpen = false;
  public cabinetSelectorOpen = false;
  public pathSelectorOpen = false;
  public priceWithVat: string = '';
  public floorPlanId: string;
  public store: Interface_DTO.Store | null = null;
  public showPrice = false;
  public price: string = '';
  public priceCurrency: string = '';

  private userSettingsValue!: UserSettingsValue;

  constructor(
    baseVmService: BaseVmService,
    private readonly permissions: PermissionService,
    private readonly customerService: CustomerService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private navigationService: NavigationService,
    private navigator: NavigateService,
    @Inject(UserSettingsInjector) private userSettings: UserSettings,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    super(baseVmService);
    if (this.floorPlan?.Id) this.floorPlanId = this.floorPlan.Id.toString();
    else this.floorPlanId = '';

    super.ensureUnsubscribe(
      activeStore.subscribe((store) => {
        this.store = store;
      }),
    );
    if (this.cabinet) {
      this.floorPlan = this.cabinet.floorPlan;
    }
  }

  public ngOnInit() {
    if (this.cabinet) {
      this.floorPlan = this.cabinet.floorPlan;
    }

    if (this.floorPlan?.Id) this.floorPlanId = this.floorPlan.Id.toString();
    else if (this.floorPlan?.oldCabinetId)
      this.floorPlanId = 'o-' + this.floorPlan.oldCabinetId;
    else this.floorPlanId = '';

    this.subscribeTo(this.floorPlan.floorPlan$, (fp) => {
      this.setChildVms(fp);
    });

    this.ensureUnsubscribe(
      this.userSettings.subscribe((value) => {
        this.userSettingsValue = value;
        this.showPrice =
          this.userSettingsValue.DisplayTotalPriceInEditor ?? false;
      }),
    );

    this.subscribeTo(this.floorPlan.pieceList$, (pieceList) => {
      if (pieceList != null) {
        this.price = ObjectHelper.roundMoney(pieceList?.PriceWithVat);
        this.priceCurrency = pieceList?.Currency;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setChildVms(this.floorPlan);
  }

  private setChildVms(fp: Client.FloorPlan) {
    if (this.partitionQueryService.selectedSection) {
      this.paths = EditorTopbarVm.getPartitionPaths(
        this.floorPlanId,
        fp,
        this.partitionQueryService.selectedSection!.id,
        this.selection,
      );
      this.cabinets = this.getPartitionCabinets();
      let selectedpartition = this.partitionQueryService.getPartitionForSection(
        this.partitionQueryService.selectedSection?.id,
      );
      let holding = this.cabinets.filter(
        (c) => c.id == selectedpartition.id.toString(),
      )[0];
      let selection = {
        Id: holding.id,
        Name: holding.name,
        Path:
          '/floorplan/' +
          this.floorPlanId +
          '/partition/' +
          holding.id.toString() +
          '/fillings',
        MaxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
        CabinetType: Interface_Enums.CabinetType.Partition,
        floorPlan: this.floorPlan,
      } as unknown as Client.Cabinet;
      let partition = this.partitionQueryService.getPartitionForSection(
        this.partitionQueryService.selectedSection.id,
      );
      let parSecs =
        this.partitionQueryService.getAllSectionsForPartiion(partition);
      let partitionSections = parSecs.map((s) => {
        let parSec = {
          Id: s.id,
          CabinetIndex: s.id as number,
          Name:
            this.translate('Section', 'Section') +
            ' ' +
            this.partitionQueryService.getPartitionSectionIndex(s.id),
          Path: 'partition/' + s.id.toString() + '/fillings',
          CabinetType: Interface_Enums.CabinetType.Partition,
        };
        return parSec;
      }) as unknown as Client.CabinetSection[];

      selection.cabinetSections = partitionSections;
      this.cabinet = selection;
      let sections = EditorTopbarVm.getSections(
        this.baseVmService.translationService,
        this.floorPlanId,
        this.cabinet,
        this.selection,
      );
      if (sections != null && sections?.length > 1) {
        this.sections = sections;
      }
    } else {
      let cabinets = this.cabinet
        ? this.cabinet.cabinetSections.filter((section) => {
            if (section.CabinetType === Interface_Enums.CabinetType.None)
              return false;
            if (section.CabinetType === Interface_Enums.CabinetType.SharedItems)
              return false;
            return true;
          })[0]
        : undefined;
      if (cabinets?.CabinetType == Interface_Enums.CabinetType.Partition) {
        this.paths = EditorTopbarVm.getPartitionPaths(
          this.floorPlanId,
          fp,
          cabinets.Id as SectionId,
          this.selection,
        );
      } else {
        this.paths = EditorTopbarVm.getCabinetPaths(
          this.floorPlanId,
          fp,
          cabinets,
          this.selection,
        );
      }
    }

    let partitions = this.partitionQueryService.getAllPartitions();
    let cabinetPartitions = partitions.map((p) => {
      let parCab = {
        id: p.id.toString(),
        name: p.name,
        path: 'partition/' + p.id.toString() + '/fillings',
        maxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
      };
      return parCab;
    });

    this.cabinets = this.getCabinets();
    this.cabinets.push(...(cabinetPartitions as Cabinet[])); // This is just experimental...
    let sections = EditorTopbarVm.getSections(
      this.baseVmService.translationService,
      this.floorPlanId,
      this.cabinet,
    );
    if (sections != null && sections?.length > 1) {
      this.sections = sections;
    }
    //this.sections = EditorTopbarVm.getSections(this.baseVmService.translationService, this.floorPlanId, this.cabinet);
    //this.sections?.push(...(partitionSections as Section[])) // This is just experimental...
  }

  public async gotoOverview() {
    await this.customerService.updateCustomerList(true);
    this.navigator.floorPlans(this.navigationService.getLastSearch());
  }

  public get floorPlanOrderStateClass() {
    return EditorTopbarVm.getFloorPlanOrderStateClass(this.floorPlan);
  }

  public get canSeeOverview(): boolean {
    return this.permissions.canSeeOrders;
  }

  public get saveButtonClasses(): string {
    let results: string[] = [];
    if (this.vm.isDirty) {
      results.push('btn-alt-primary');
    } else {
      results.push('btn-default');
    }
    if (this.vm.isSaving) {
      results.push('btn-busy');
    }
    results.push(this.floorPlanOrderStateClass);
    return results.join(' ');
  }

  public get saveButtonText(): string {
    if (this.floorPlanOrderStateClass === 'ordered-editable') {
      return this.translate('topbar_save_reorder', 'Reorder');
    }
    return this.translate('floorPlan_save_button', 'Save');
  }

  private static getHighestErrorLevel(
    editorSection: Enums.EditorSection,
    errorInfos: Client.ErrorInfo[],
  ) {
    return Math.max(
      Interface_Enums.ErrorLevel.None,
      ...errorInfos
        .filter((ei) => ei.editorSection === editorSection)
        .map((ei) => ei.level),
    );
  }

  private getCabinets(): Cabinet[] {
    return this.floorPlan.actualCabinets.map((clientCabinet) => {
      let selection =
        clientCabinet.CabinetType == Interface_Enums.CabinetType.Swing
          ? 'swing'
          : 'corpus';

      return {
        id: clientCabinet.CabinetIndex.toString(),
        maxErrorLevel: clientCabinet.maxErrorLevel,
        name: clientCabinet.Name,
        path:
          '/floorplan/' +
          this.floorPlan.Id?.toString() +
          '/' +
          clientCabinet.CabinetIndex +
          '/1/' +
          selection,
      };
    });
  }

  private getPartitionCabinets(): Cabinet[] {
    let partitions = this.partitionQueryService.getAllPartitions();
    let cabinetPartitions = partitions.map((p) => {
      let parCab = {
        id: p.id.toString(),
        name: p.name,
        path:
          '/floorplan/' +
          this.floorPlanId +
          '/partition/' +
          p.id.toString() +
          '/fillings',
        maxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
        CabinetType: Interface_Enums.CabinetType.Partition,
      };
      return parCab;
    });
    return cabinetPartitions as unknown as Cabinet[];
  }

  public get showMainDropdown(): boolean {
    return this.cabinets != null && this.cabinets.length > 0;
  }

  public get selectedSectionName(): string {
    let partitionSection = this.partitionQueryService
      .selectedSection as partitionSection;
    if (partitionSection) {
      let section = this.sections!.find(
        (sec) => sec.index == partitionSection.id,
      );
      if (section) return section.name;
    }

    return this.sections![0].name;
  }
}
