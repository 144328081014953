import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { DoorHelper } from 'app/ts/util/DoorHelper';

export class DoorBar {
  public static readonly type: 'doorBar' = 'doorBar';
  public readonly type = DoorBar.type;

  //#region Backing variables

  private _position: number = 500;
  private _desiredPosition: number | undefined;

  private cache: {
    barData?: Interface_DTO.ProductBarData | null;
    product?: Client.Product | null;

    positionMin?: number;
    positionMax?: number;

    aboveHeightMin?: number;
    aboveHeightMax?: number;
    aboveHeightPercentMin?: number;
    aboveHeightPercentMax?: number;

    belowHeightMin?: number;
    belowHeightMax?: number;
    belowHeightPercentMin?: number;
    belowHeightPercentMax?: number;
  } = {};

  //#endregion Backing variables

  constructor(public readonly door: Client.Door) {
    this.positionX = this.door.frameWidthLeft;
  }

  private get barData() {
    if (this.cache.barData === undefined) {
      let index = this.door.bars.indexOf(this);
      this.cache.barData = DoorHelper.getCorrectBar(
        this.door,
        this.door.fillings[index],
        this.door.fillings[index + 1],
      );
    }

    return this.cache.barData;
  }

  public get product(): Client.Product | null {
    if (this.cache.product === undefined) {
      if (this.barData) {
        this.cache.product =
          this.door.doorSubSection.editorAssets.productsDict[
            this.barData.ProductId
          ];
      }
      if (!this.cache.product) {
        this.cache.product = null;
      }
    }
    return this.cache.product;
  }

  //#region Public properties

  get height(): number {
    return this.barData ? this.barData.Height : 0;
  }
  get width(): number {
    return (
      this.door.width - this.door.frameWidthLeft - this.door.frameWidthRight
    );
  }
  get depth(): number {
    return this.door.depth;
  }

  public get material() {
    return this.door.doorSubSection.profileMaterial;
  }

  public get cube(): Interface_DTO_Draw.Cube {
    return {
      Height: this.height,
      Depth: this.depth,
      Width: this.width,
      X: this.positionX,
      Y: this.position,
      Z: this.positionZ,
    };
  }

  public get model3dPath(): string | null {
    if (this.product) {
      return this.product.model3dPath;
    } else {
      return null;
    }
  }

  public get barType(): Interface_Enums.BarType {
    return this.barData ? this.barData.BarType : Interface_Enums.BarType.Loose;
  }

  public barTypeString(forBarPlacementPositionType: boolean): string {
    let typeString = '';

    switch (this.barType) {
      case Interface_Enums.BarType.Loose:
        typeString = 'L';
        break;

      case Interface_Enums.BarType.Fixed:
        typeString = 'F';
        break;

      case Interface_Enums.BarType.Design:
        typeString = forBarPlacementPositionType ? 'F' : 'D';
        break;
    }

    return typeString;
  }

  public get barTop(): number {
    return this.barData ? this.barData.BarTop : 0;
  }
  public get barMiddle(): number {
    return this.barData ? this.barData.BarMiddel : 0;
  }
  public get barBottom(): number {
    return this.barData ? this.barData.BarBottom : 0;
  }

  /**
    The bars index in the bars list on the door (one based)
    **/
  get index(): number {
    let index = this.door.bars.indexOf(this);
    return index + 1;
  }

  get positionMin(): number {
    if (!this.cache.positionMin) {
      this.calculatePositionRestrictions();
    }

    return this.cache.positionMin ? this.cache.positionMin : 0;
  }
  get positionMax(): number {
    if (!this.cache.positionMax) {
      this.calculatePositionRestrictions();
    }

    return this.cache.positionMax ? this.cache.positionMax : 0;
  }

  get centerYMin(): number {
    return Math.round(this.positionMin + this.height / 2);
  }
  get centerYMax(): number {
    return Math.round(this.positionMax + this.height / 2);
  }

  get aboveHeightMin(): number {
    if (!this.cache.aboveHeightMin) {
      this.calculatePositionRestrictions();
    }

    return this.cache.aboveHeightMin ? this.cache.aboveHeightMin : 0;
  }
  get aboveHeightMax(): number {
    if (!this.cache.aboveHeightMax) {
      this.calculatePositionRestrictions();
    }

    return this.cache.aboveHeightMax ? this.cache.aboveHeightMax : 0;
  }
  get aboveHeightPercentMin(): number {
    if (!this.cache.aboveHeightPercentMin) {
      this.calculatePositionRestrictions();
    }

    return this.cache.aboveHeightPercentMin
      ? this.cache.aboveHeightPercentMin
      : 0;
  }
  get aboveHeightPercentMax(): number {
    if (!this.cache.aboveHeightPercentMax) {
      this.calculatePositionRestrictions();
    }

    return this.cache.aboveHeightPercentMax
      ? this.cache.aboveHeightPercentMax
      : 0;
  }

  get belowHeightMin(): number {
    if (!this.cache.belowHeightMin) {
      this.calculatePositionRestrictions();
    }

    return this.cache.belowHeightMin ? this.cache.belowHeightMin : 0;
  }
  get belowHeightMax(): number {
    if (!this.cache.belowHeightMax) {
      this.calculatePositionRestrictions();
    }

    return this.cache.belowHeightMax ? this.cache.belowHeightMax : 0;
  }
  get belowHeightPercentMin(): number {
    if (!this.cache.belowHeightPercentMin) {
      this.calculatePositionRestrictions();
    }

    return this.cache.belowHeightPercentMin
      ? this.cache.belowHeightPercentMin
      : 0;
  }
  get belowHeightPercentMax(): number {
    if (!this.cache.belowHeightPercentMax) {
      this.calculatePositionRestrictions();
    }

    return this.cache.belowHeightPercentMax
      ? this.cache.belowHeightPercentMax
      : 0;
  }

  /**
    The relative Y-position of the bar in relation to the door.
    **/
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    if (value === this._position) {
      return;
    }

    this._position = value;
    this._desiredPosition = this._position;
    this.clearBackingVariables();
  }

  readonly positionX: number;
  public get positionZ(): number {
    if (this.barType !== Interface_Enums.BarType.Loose) {
      let p = this.product;

      if (p) {
        let productData = p.getProductData();
        if (productData) {
          let barDepth = productData.Model3DDepth;
          if (barDepth <= 0) {
            barDepth = this.depth;
          }
          let fillingCenter =
            this.door.fillingPositionZ + Constants.fillingDepth / 2;
          return fillingCenter - barDepth / 2;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
    return this.door.fillingPositionZ + Constants.fillingDepth;
  }

  public get centerY(): number {
    return this.position + this.height / 2;
  }

  /**
    The desired position of the bar (relative to the door).
    **/
  get desiredPosition(): number {
    if (!this._desiredPosition) {
      this._desiredPosition = this._position;
    }

    return this._desiredPosition;
  }
  set desiredPosition(value: number) {
    if (value === this._position) {
      return;
    }

    this._desiredPosition = value;
    this.clearBackingVariables();
  }

  get desiredCenterY(): number {
    return Math.round(this.desiredPosition + this.height / 2);
  }
  set desiredCenterY(value: number) {
    this.desiredPosition = value - this.height / 2;
  }

  /**
    The absolute position of the bar in relation to the floor.
    **/
  get positionAbsolute(): number {
    return this.door.position.Y + this.position;
  }
  set positionAbsolute(value: number) {
    this.position = value + this.door.position.Y;
  }

  /**
    The height of the filling above this bar
    **/
  public get aboveHeight(): number {
    let index = this.door.bars.indexOf(this) + 1;
    if (this.door.fillings[index].isDesignFilling) {
      index++;
    }
    return Math.round(DoorHelper.getVisualFillingHeight(this.door, index));
  }
  public set aboveHeight(value: number) {
    let delta = this.aboveHeight - value;
    this.desiredPosition = this.position + Math.round(delta);
  }

  /**
    The height of the filling above this bar, in percent of the total visible filling height
    **/
  public get aboveHeightPercent(): number {
    let totalVisualFillingHeight = DoorHelper.getTotalVisualFillingHeight(
      this.door,
    );
    let percent = (this.aboveHeight / totalVisualFillingHeight) * 100;
    return +percent.toFixed(1);
  }
  public set aboveHeightPercent(value: number) {
    let totalVisualFillingHeight = DoorHelper.getTotalVisualFillingHeight(
      this.door,
    );
    let newFillingHeight = (totalVisualFillingHeight * value) / 100;
    this.aboveHeight = newFillingHeight;
  }

  /**
    The height of the filling below this bar
    **/
  public get belowHeight(): number {
    let index = this.door.bars.indexOf(this);
    if (this.door.fillings[index].isDesignFilling) {
      index--;
    }
    return Math.round(DoorHelper.getVisualFillingHeight(this.door, index));
  }
  public set belowHeight(value: number) {
    let delta = value - this.belowHeight;
    this.desiredPosition = this.position + Math.round(delta);
  }

  /**
    The height of the filling below this bar, in percent of the total visible filling height
    **/
  public get belowHeightPercent(): number {
    let totalVisualFillingHeight = DoorHelper.getTotalVisualFillingHeight(
      this.door,
    );
    let percent = (this.belowHeight / totalVisualFillingHeight) * 100;
    return +percent.toFixed(1);
  }
  public set belowHeightPercent(value: number) {
    let totalVisualFillingHeight = DoorHelper.getTotalVisualFillingHeight(
      this.door,
    );
    let newFillingHeight = (totalVisualFillingHeight * value) / 100;
    this.belowHeight = newFillingHeight;
  }

  //#endregion Public properties

  //#region Public functions

  public clearDesired() {
    this._desiredPosition = undefined;
  }

  //#region Public functions

  public clearBackingVariables() {
    this.cache = {};
  }

  private calculatePositionRestrictions() {
    // Calculate possible moves
    let index = this.door.bars.indexOf(this);
    let fillingBelow = this.door.fillings[index];
    if (fillingBelow.isDesignFilling) {
      fillingBelow = this.door.fillings[index - 1];
    }
    let fillingAbove = this.door.fillings[index + 1];
    if (fillingAbove.isDesignFilling) {
      fillingAbove = this.door.fillings[index + 2];
    }

    let maxMoveUpAbove = fillingAbove.height - fillingAbove.minHeight;
    let maxMoveUpBelow = fillingBelow.maxHeight - fillingBelow.height;
    let possibleMoveUp = Math.min(maxMoveUpAbove, maxMoveUpBelow);

    let maxMoveDownAbove = fillingAbove.maxHeight - fillingAbove.height;
    let maxMoveDownBelow = fillingBelow.height - fillingBelow.minHeight;
    let possibleMoveDown = Math.min(maxMoveDownAbove, maxMoveDownBelow);

    // Set all min/max values based on possible moves
    this.cache.positionMin = Math.ceil(this.position - possibleMoveDown);
    this.cache.positionMax = Math.floor(this.position + possibleMoveUp);

    this.cache.aboveHeightMin = Math.ceil(this.aboveHeight - possibleMoveUp);
    this.cache.aboveHeightMax = Math.floor(this.aboveHeight + possibleMoveDown);

    this.cache.belowHeightMin = Math.ceil(this.belowHeight - possibleMoveDown);
    this.cache.belowHeightMax = Math.floor(this.belowHeight + possibleMoveUp);

    // Calculate percent min/max
    let totalVisualFillingHeight = DoorHelper.getTotalVisualFillingHeight(
      this.door,
    );

    this.cache.aboveHeightPercentMin =
      Math.ceil((this.cache.aboveHeightMin / totalVisualFillingHeight) * 1000) /
      10;
    this.cache.aboveHeightPercentMax =
      Math.floor(
        (this.cache.aboveHeightMax / totalVisualFillingHeight) * 1000,
      ) / 10;

    this.cache.belowHeightPercentMin =
      Math.ceil((this.cache.belowHeightMin / totalVisualFillingHeight) * 1000) /
      10;
    this.cache.belowHeightPercentMax =
      Math.floor(
        (this.cache.belowHeightMax / totalVisualFillingHeight) * 1000,
      ) / 10;
  }
}
