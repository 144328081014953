import {
  CabinetSection,
  Cabinet,
  Product,
  ConfigurationItem,
} from '../ts/clientDto';
import { CabinetType } from '../ts/Interface_Enums';
import {
  JointOtherSectionPositionLeft,
  JointOtherSectionPositionRight,
  JointSizeLeft,
  JointSizeRight,
  Values,
} from '../ts/VariantNumbers';
import { CabinetSectionHelper } from '../ts/util/CabinetSectionHelper';
import { ConfigurationItemHelper } from '../ts/util/ConfigurationItemHelper';
import { ProductHelper } from '../ts/util/ProductHelper';
import { CabinetHelper } from 'app/ts/util/CabinetHelper';

export function calculateDovetailJointVariants(cabinet: Cabinet) {
  if (
    cabinet.CabinetType == CabinetType.CornerCabinet ||
    cabinet.CabinetType == CabinetType.WalkIn
  ) {
    // Clear all variants before recalculating
    removeJointVariants(cabinet);

    //The idea is to iterate through each pair of sections making up a joint
    let actualSections = cabinet.cabinetSections.filter(
      (cs) => cs.CabinetType != CabinetType.SharedItems,
    );

    for (let i = 0; i < actualSections.length - 1; i++) {
      let leftSection = actualSections[i];
      let rightSection = actualSections[i + 1];

      if (leftSection.corpus.itemsTop.length > 0) {
        calculateAndSetVariantsForJoint(leftSection, rightSection, true);
      }

      if (leftSection.corpus.itemsBottom.length > 0) {
        calculateAndSetVariantsForJoint(leftSection, rightSection, false);
      }
    }
  }
}

function removeJointVariants(cabinet: Cabinet) {
  for (let i = 0; i < cabinet.cabinetSections.length - 1; i++) {
    let section = cabinet.cabinetSections[i];

    if (section.corpus.itemsTop.length > 0) {
      let topItem = section.corpus.itemsTop[0];
      ConfigurationItemHelper.removeItemVariantsByVariantNumbers(topItem, [
        JointOtherSectionPositionLeft,
        JointOtherSectionPositionRight,
        JointSizeLeft,
        JointSizeRight,
      ]);
    }

    if (section.corpus.itemsBottom.length > 0) {
      let bottomItem = section.corpus.itemsBottom[0];
      ConfigurationItemHelper.removeItemVariantsByVariantNumbers(bottomItem, [
        JointOtherSectionPositionLeft,
        JointOtherSectionPositionRight,
        JointSizeLeft,
        JointSizeRight,
      ]);
    }
  }
}

function calculateAndSetVariantsForJoint(
  leftSection: CabinetSection,
  rightSection: CabinetSection,
  top: boolean,
) {
  let leftSectionItems = top
    ? leftSection.corpus.itemsTop
    : leftSection.corpus.itemsBottom;
  let rightSectionItems = top
    ? rightSection.corpus.itemsTop
    : rightSection.corpus.itemsBottom;

  let relativePositions = calculateJointRelativePosition(leftSection, top);

  if (
    leftSectionItems.length > 0 &&
    leftSectionItems[0].Product != null &&
    hasJointVariants(leftSectionItems[0].Product, false)
  ) {
    setJointRelativePosition(
      leftSectionItems,
      leftSectionItems[0].Product,
      relativePositions.section1OptionNumber,
      false,
      top,
    );
  }

  if (
    rightSectionItems.length > 0 &&
    rightSectionItems[0].Product != null &&
    hasJointVariants(rightSectionItems[0].Product, true)
  ) {
    setJointRelativePosition(
      rightSectionItems,
      rightSectionItems[0].Product,
      relativePositions.section2OptionNumber,
      true,
      top,
    );
  }
}

function calculateJointRelativePosition(
  leftSection: CabinetSection,
  top: boolean,
): { section1OptionNumber: string; section2OptionNumber: string } {
  let topCornerOwners = CabinetHelper.getCornerOwners(leftSection.cabinet, top);
  let corner = topCornerOwners[leftSection.CabinetSectionIndex - 1];

  if (corner.ownedByRightSection) {
    // Section2 has the corner
    return {
      section1OptionNumber: Values.JointPositionSide,
      section2OptionNumber: Values.JointPositionFront,
    };
  } else {
    // Section 1 has the corner
    return {
      section1OptionNumber: Values.JointPositionFront,
      section2OptionNumber: Values.JointPositionSide,
    };
  }
}

function hasJointVariants(product: Product, left: boolean): boolean {
  var JointOtherSectionPosition = left
    ? JointOtherSectionPositionLeft
    : JointOtherSectionPositionRight;

  // All sections have the same top and bottom type
  // It has a product AND it has the required variant
  if (
    product == null ||
    !ProductHelper.hasVariant(product, JointOtherSectionPosition)
  )
    return false;

  return true;
}

function setJointRelativePosition(
  sectionItems: ConfigurationItem[],
  product: Product,
  variantOptionNumber: string,
  left: boolean,
  top: boolean,
) {
  let JointOtherSectionPosition = left
    ? JointOtherSectionPositionLeft
    : JointOtherSectionPositionRight;

  // Set option for section
  let option = ProductHelper.getVariantOptionByNumber(
    product,
    JointOtherSectionPosition,
    variantOptionNumber,
  );
  if (option) {
    ConfigurationItemHelper.addVariantOption(sectionItems[0], option);
    sectionItems[0].setDirty();
  }

  if (variantOptionNumber == Values.JointPositionFront) {
    setJointSize(sectionItems[0], left, top);
  }
}

function setJointSize(item: ConfigurationItem, left: boolean, top: boolean) {
  let JointSizeVariant = left ? JointSizeLeft : JointSizeRight;

  let neighbor = left
    ? item.cabinetSection.leftNeighbor!
    : item.cabinetSection.rightNeighbor!;
  let depth = top ? neighbor.corpus.depthTop : neighbor.corpus.depthBottom;

  ConfigurationItemHelper.addDimensionVariant(
    item,
    JointSizeVariant,
    depth.toString(),
  );
}
