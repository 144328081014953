import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  UseFullCatalog,
  UseFullCatalogInjector,
  useFullCatalogProvider,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import {
  Stores,
  StoresInjector,
  storesProvider,
} from 'app/functional-core/ambient/stores/Stores';
import {
  StoreSetting,
  StoreSettingInjector,
} from 'app/functional-core/ambient/stores/StoreSetting';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
} from 'app/functional-core/ambient/userdata/UserData';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as App from 'app/ts/app';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ModalService } from 'app/ts/services/ModalService';
import { StoreService } from 'app/ts/services/StoreService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { UserMessageService } from 'app/user-messages/user-message.service';
import Enumerable from 'linq';

@Component({
  templateUrl: 'storeSelector.html',
  styleUrls: [
    '../../../../style/StoreSelector.scss',
    '../../../../style/modal.scss',
    '../../../../../node_modules/bootstrap/scss/bootstrap.scss',
  ],
  providers: [
    activeStoreProvider,
    activeUserProvider,
    storesProvider,
    userDataProvider,
    useFullCatalogProvider,
  ],
})
export class StoreSelectorVm extends BaseVm implements OnInit, AfterViewInit {
  private readonly orderNoRegex = new RegExp('^KA\\d{7}$');
  private readonly floorPlanIdRegex = new RegExp('^ID(\\d{3,})$');
  private readonly storesPerPage = 30;

  public ready: boolean = false;
  private storesUnpaginated: Interface_DTO.Store[] = [];
  public stores: Interface_DTO.Store[] = [];
  private _selectedStore: Interface_DTO.Store | null = null;
  public fullCatalog: boolean = false;
  public changeLanguage: boolean = true;

  private allStores: Interface_DTO.Store[] = [];
  private _searchString: string = '';
  private floorPlanId: number | null = null;

  @ViewChild('searchBox') searchBox!: ElementRef;

  constructor(
    baseVmService: BaseVmService,
    private readonly storeService: StoreService,
    private readonly activeModal: NgbActiveModal,
    private readonly permissions: PermissionService,
    private readonly userMessageService: UserMessageService,
    private readonly modalService: ModalService,
    @Inject(UseFullCatalogInjector) readonly useFullCatalog: UseFullCatalog,
    @Inject(StoresInjector) readonly storesContainer: Stores,
    @Inject(ActiveStoreInjector) readonly activeStore: ActiveStore,
    @Inject(ActiveUserInjector) readonly activeUser: ActiveUser,
    @Inject(UserSettingsInjector) readonly userSettings: UserSettings,
    @Inject(StoreSettingInjector) private readonly storeSettings: StoreSetting,
  ) {
    super(baseVmService);
    this.changeLanguage = true;
  }

  ngOnInit() {
    const userStoreId = this.activeUser.data.StoreId;

    this.allStores = Enumerable.from(this.storesContainer.stores)
      .orderBy((store) => {
        return userStoreId === store.Id
          ? 0 //show user's own store first
          : this.activeStore.data.Id == store.Id
            ? 1 //then the currently active store
            : 2; //then the rest
      })
      .thenBy((store) => store.Name) // -alphabetically
      .toArray();

    this.filter();
  }

  ngAfterViewInit(): void {
    this.searchBox.nativeElement.focus();
  }

  public get selectedStore(): Interface_DTO.Store | null {
    return this._selectedStore;
  }
  public set selectedStore(val: Interface_DTO.Store | null) {
    this._selectedStore = val;
    if (App.debug.showSelectionChanges) {
      console.debug(
        'App.debug.showSelectionChanges StoreSelectorVm selectedStore: ',
        val,
      );
    }
  }

  public get searchString() {
    return this._searchString;
  }
  public set searchString(val: string) {
    this.floorPlanId = null;
    this._searchString = val;
    this.filter();
  }

  private filter() {
    let needle = this.searchString.toUpperCase();
    if (this.orderNoRegex.test(needle)) {
      this.filterKANumber(needle);
    } else if (this.floorPlanIdRegex.test(needle)) {
      this.filterFloorPlanId(needle);
    } else {
      //break up search string into parts separated by space, then find the stores that match all parts
      let needleParts = needle.split(/\s+/);

      this.storesUnpaginated = this.allStores.filter((store) => {
        return needleParts.every(
          (needlePart) =>
            store.SearchName.indexOf(needlePart) >= 0 ||
            store.Name.toUpperCase().indexOf(needlePart) >= 0 ||
            store.DebitorNo.toUpperCase().indexOf(needlePart) === 0,
        );
      });
    }

    this.stores = [];
    this.showMoreStores();
  }

  public get moreStoresAvailable(): boolean {
    return this.stores.length < this.storesUnpaginated.length;
  }

  public showMoreStores() {
    let newLength = Math.min(
      this.storesUnpaginated.length,
      this.stores.length + this.storesPerPage,
    );
    this.stores = this.storesUnpaginated.slice(0, newLength);
  }

  private async filterKANumber(kaNumber: string) {
    try {
      this.ready = false;
      let searchResult = await this.storeService.getStoreByKANumber(kaNumber);
      if (!searchResult) {
        this.stores = [];
      } else {
        this.stores = this.allStores.filter(
          (store) => store.Id === searchResult!.StoreId,
        );
        this.floorPlanId = searchResult.FloorPlanId;
      }
    } finally {
      this.ready = true;
    }
  }

  private async filterFloorPlanId(needle: string) {
    try {
      this.ready = false;
      let idStr = this.floorPlanIdRegex.exec(needle)![1];
      let id = Number(idStr);
      let searchResult = await this.storeService.getStoreByFloorPlanId(id);
      if (!searchResult) {
        this.stores = [];
      } else {
        let storeId = searchResult.StoreId;
        this.stores = this.allStores.filter((store) => store.Id === storeId);
        this.floorPlanId = searchResult.FloorPlanId;
      }
    } finally {
      this.ready = true;
    }
  }

  public get allowFullCatalog(): boolean {
    return this.permissions.canUseFullCatalog;
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public async ok() {
    if (this.activeStore.data.Id == this.selectedStore?.Id) {
      this.selectedStore = null;
    }

    if (this.selectedStore != null) {
      const store = await this.storeService.loadStoreById(
        this.selectedStore.Id,
      );
      await this.activeStore.set(store);
      let storeSettingData = await this.storeService.getCurrentStoreData();
      await this.storeSettings.set(storeSettingData);
    }

    if (!this.allowFullCatalog) {
      this.fullCatalog = false;
    }

    this.useFullCatalog.set(this.fullCatalog);

    if (this.changeLanguage && this.selectedStore) {
      const newUserSettings = {
        ...this.userSettings.value,
      };
      newUserSettings.LanguageCode = this.selectedStore.LanguageCode;
      this.userSettings.set(newUserSettings);
    }

    setTimeout(() => this.userMessageService.loadUserMessages(), 1000);

    this.activeModal.close({
      floorPlanId: this.floorPlanId,
    });
  }

  public reset() {
    const possibleStore = this.stores.filter(
      (s) => s.Id == this.activeUser.data.StoreId,
    );
    if (possibleStore != null) this.selectedStore = possibleStore[0];
    else this.selectedStore = null;

    this.fullCatalog = false;
    this.floorPlanId = null;
    this.ok();
  }
}

export module StoreSelectorVm {
  export interface ReturnType {
    floorPlanId: number | null;
  }
}
