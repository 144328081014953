import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NModule } from 'app/i18n/i18n.module';
import { TopbarModule } from 'app/ts/viewmodels/components/topbar.module';
import { CustomerTopBarComponent } from './customer.topbar.component';

@NgModule({
  declarations: [CustomerTopBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18NModule,
    //IdentityAndAccessModule,
    TopbarModule,
    NgbDropdownModule,
  ],
  exports: [CustomerTopBarComponent],
})
export class CustomerTopbarModule {}
