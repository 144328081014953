import { NgModule } from '@angular/core';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionPlanCommandService } from '../partition/partition-plan-command.service';
import { ScreenshotContainerComponent } from './screenshot-container.component';
import { ScreenshotService } from './screenshot.service';

@NgModule({
  declarations: [ScreenshotContainerComponent],
  exports: [ScreenshotContainerComponent],
  imports: [],
  providers: [
    ScreenshotService,
    PartitionPlanCommandService,
    PartitionPlanDataService,
    PartitionPlanQueryService,
  ],
})
export class ScreenshootingModule {}
