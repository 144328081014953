import { Component, Inject } from '@angular/core';
import { WindowService } from 'app/ng/window.service';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';

import {
  BackingSetting,
  ClientSetting,
  ClientSettingData,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { LoginService } from 'app/ts/services/LoginService';
import { RouteService } from 'app/ts/services/RouteService';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';

@Component({
  selector: 'backing-vm',
  templateUrl: 'backing.component.html',
  styleUrls: ['backing.component.scss'],
  providers: [clientSettingProvider, DeliveryAddressService],
})
export class BackingComponent extends EditorVm {
  private clientSettingsData!: ClientSettingData;

  private get backingClientSetting() {
    return this.clientSettingsData.backing;
  }

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    private readonly loginService: LoginService,
    routing: AppRoutingModule,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.clientSettingsData = settings;
      }),
    );
  }

  public async selectBackingType(): Promise<void> {
    try {
      let backingSettings =
        await this.baseVmService.modalService.getSelectBackingModal(
          this.cabinetSection!,
        );
      this.cabinetSection!.backing.setBackingType(
        backingSettings.backingType,
        backingSettings.adjustSectionDepth,
      );
      this.setChanged();
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
  }

  public async selectBackingMaterial(): Promise<void> {
    let modal = this.baseVmService.modalService.getPickableSelector(
      this.cabinetSection!.backing.pickableMaterials,
      this.translate('material_selector_title', 'Select Material'),
      'material backing-material',
    );
    let backingMaterial = await modal.result;
    this.cabinetSection!.backing.material = backingMaterial;
    this.setChanged();
  }

  public backingArea!: Client.BackingArea;

  public get heightReduction() {
    return this.cabinetSection!.HeightReductionBacking;
  }
  public set heightReduction(val: boolean) {
    this.cabinetSection!.HeightReductionBacking = val;
    this.setChanged();
  }

  public get showRulers() {
    return this.backingClientSetting
      ? this.backingClientSetting.displayRulers
      : false;
  }
  public set showRulers(val: boolean) {
    this.setBackingSetting({ displayRulers: val });
  }

  private setBackingSetting(settings: Partial<BackingSetting>) {
    const newBackingSettings = {
      ...this.backingClientSetting,
      ...settings,
    };

    const newSettings = {
      ...this.clientSettingsData,
      backing: newBackingSettings,
    };

    this.clientSettings.set(newSettings);
  }

  public get backingMaterial(): Pickable<Interface_DTO.Material | null> {
    return this.cabinetSection!.backing.pickableMaterials.filter(
      (pm) => pm.isSelected,
    )[0];
  }

  public get backingType(): Pickable<Interface_Enums.BackingType> {
    return Enumerable.from(this.cabinetSection!.backing.pickableTypes).first(
      (bt) => bt.isSelected,
    );
  }

  public onBackingAreaClick(backingArea: Client.BackingArea) {
    backingArea.enabled = !backingArea.enabled;
    this.setChanged();
  }

  ///Called from EditorVm when the underlying floorplan has changed
  protected floorPlanChanged() {}
}
