import { Subject, Observable, Subscription } from 'rxjs';
import { UserSettingsValue } from './UserData';

export class UserDataContainer {
  private _userData: UserSettingsValue | undefined;
  public get userData(): UserSettingsValue | undefined {
    return this._userData;
  }

  public setUserData(value: UserSettingsValue) {
    this._userData = value;
    if (this._userData != null) this._userData$.next(this._userData);
  }

  private _userData$ = new Subject<UserSettingsValue>();
  public get userData$(): Observable<UserSettingsValue> {
    return this._userData$;
  }

  public subscribeUserData(
    next: (user: UserSettingsValue) => void,
  ): Subscription {
    const subscription = this.userData$.subscribe(next);
    if (this.userData) next(this.userData);
    return subscription;
  }
}
