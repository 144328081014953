import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Client from '@ClientDto/index';
import * as Enums from '@ClientDto/Enums';
import { CabinetSectionProperties } from 'app/ts/properties/CabinetSectionProperties';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { BaseVmService } from '@Services/BaseVmService';
import { DeliveryAddressService } from '@Services/DeliveryAddressService';
import { FloorPlanSaveService } from '@Services/FloorPlanSaveService';
import { FloorPlanService } from '@Services/FloorPlanService';
import { RouteService } from '@Services/RouteService';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';

@Component({
  selector: 'swing-flex-gable-editor',
  templateUrl: './swing-flex-gable-editor.component.html',
  styleUrl: './swing-flex-gable-editor.component.scss',
})
export class SwingFlexGableEditorComponent
  extends EditorVm
  implements OnChanges
{
  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,

    private readonly permissions: PermissionService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
  }

  protected override floorPlanChanged() {
    this.cache = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cache = {};
  }

  private cache: Partial<{
    variants: VariantInfo[];
  }> = {};

  @Input()
  public gableItem?: Client.ConfigurationItem;

  @Input()
  public gableInfo?: CabinetSectionProperties.SwingFlexGableInfo;
  @Output()
  public readonly gableInfoChange =
    new EventEmitter<CabinetSectionProperties.SwingFlexGableInfo>();

  public get variants(): VariantInfo[] {
    if (this.cache.variants === undefined) {
      this.cache.variants =
        this.gableItem?.UserSelectableItemVariants.filter(
          (iv) => !iv.OnlyAdmin || this.permissions.canShowOnlyAdminVariants,
        ).map((iv) => {
          const variantNumber = iv.variant?.Number ?? '';
          const options: OptionInfo[] =
            iv.variant?.VariantOptions.filter(
              (vo) => vo.Enabled || vo === iv.variantOption,
            ).map((vo) => ({
              name: vo.DefaultName,
              number: vo.Number,
            })) ?? [];
          const selectedVariantOptionNumber =
            this.gableInfo?.variantOptions?.[variantNumber];
          const variantInfo: VariantInfo = {
            name: iv.variant?.DefaultName ?? ' -- ',
            number: iv?.variant?.Number ?? '',
            onlyAdmin: iv.OnlyAdmin,
            options: options,
            selectedOption:
              options.find(
                (opt) => opt.number === selectedVariantOptionNumber,
              ) ??
              options.find((opt) => opt.number === iv.variantOption?.Number),
          };
          return variantInfo;
        }) ?? [];
    }
    return this.cache.variants;
  }

  public setVariantOption(variantNumber: string, optionNumber: string) {
    const newGableInfo: CabinetSectionProperties.SwingFlexGableInfo = {
      ...(this.gableInfo ??
        CabinetSectionProperties.SwingFlexGableInfo.defaultValue),
    };
    newGableInfo.variantOptions = {
      ...newGableInfo.variantOptions,
    };
    newGableInfo.variantOptions[variantNumber] = optionNumber;
    this.gableInfo = newGableInfo;
    console.log(newGableInfo);
    this.gableInfoChange.emit(this.gableInfo);
  }
}

type VariantInfo = {
  name: string;
  number: string;
  onlyAdmin: boolean;
  options: OptionInfo[];
  selectedOption: OptionInfo | undefined;
};

type OptionInfo = {
  name: string;
  number: string;
};
