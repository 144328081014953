import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as ArrayHelper from 'app/ts/util/ArrayHelper';
export class SwingTemplate {
  public readonly minWidth: number;
  public readonly maxWidth: number;
  public readonly isFlex: boolean;

  public readonly name: string = 'unknown swing template';
  public readonly productNo: string;

  constructor(
    public readonly startModule: Client.SwingModuleSetup,
    public readonly extendModule: Client.SwingModuleSetup,
    public readonly subModules: {
      [variantOptionNumber: number]: Client.SwingModuleSetup[];
    },
  ) {
    this.productNo = startModule.ModuleProductNo;
    if (startModule.Type !== Interface_Enums.SwingModuleType.StartModule) {
      throw new Error('ArgumentError: wrong module type for startModule');
    }
    if (extendModule.Type !== Interface_Enums.SwingModuleType.ExtendModule) {
      throw new Error('ArgumentError: wrong module type for extendModule');
    }

    this.minWidth = ProductHelper.minWidth(startModule.product);
    this.maxWidth = ProductHelper.maxWidth(startModule.product);
    this.isFlex = this.minWidth !== this.maxWidth;

    let numDoors = ArrayHelper.sum(
      ...startModule.subProducts[
        Interface_Enums.SwingModuleProductPlacement.Door
      ].map((p) => p.Quantity),
    );
    let doorsStr = numDoors === 1 ? 'Single Door ' : numDoors + ' Doors ';
    if (this.isFlex) {
      this.name = doorsStr + this.minWidth + '-' + this.maxWidth + ' mm';
    } else {
      this.name = doorsStr + this.minWidth + ' mm';
    }
  }

  public get possibleHingeSides() {
    return this.startModule.possibleHingeSides;
  }
}
