import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecretService {
  private _isRunning$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  public get isRunning$(): BehaviorSubject<boolean> {
    return this._isRunning$;
  }

  public setIsRunning(val: boolean) {
    this._isRunning$.next(val);
  }
}
