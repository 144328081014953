import { Component, Inject, Input } from '@angular/core';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from '../../../functional-core/ambient/activeUser/ActiveUser';

@Component({
  selector: 'corner-name-bubble',
  templateUrl: 'corner-name-bubble.component.html',
  styleUrls: ['../../../../style/widgets.scss', '../../../../style/index.scss'],
  providers: [activeStoreProvider, activeUserProvider],
})
export class CornerNameBubbleComponent extends BaseVm {
  constructor(
    baseVmService: BaseVmService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
    @Inject(ActiveUserInjector) readonly activeUser: ActiveUser,
  ) {
    super(baseVmService);

    super.ensureUnsubscribe(
      activeStore.subscribe((store) => {
        this.store = store;
        this.isStoreOverridden = this.activeUser.data.StoreId != store.Id;
      }),
    );
    this.store = activeStore.data;
    this.isStoreOverridden = this.activeUser.data.StoreId != this.store?.Id;
  }

  // #region angularJS Bindings

  @Input()
  public floorPlan?: Client.FloorPlan;

  // #endregion angularJS bindings

  public store: Interface_DTO.Store | null = null;
  public isStoreOverridden: boolean = false;

  public get floorPlanName() {
    if (!this.floorPlan) return null;
    return this.floorPlan.Name;
  }
  public get floorPlanNumber() {
    if (!this.floorPlan) return null;
    if (!this.floorPlan.deliveryInfo) return null;
    if (!this.floorPlan.deliveryInfo.OrderNumber) return null;
    return this.floorPlan.deliveryInfo.OrderNumber;
  }
  public get postalCode(): string {
    if (this.store?.DeliveryAddresses == null) return '';
    return this.store?.DeliveryAddresses[0]?.PostalCode;
  }

  public get city(): string {
    if (this.store?.DeliveryAddresses == null) return '';
    return this.store?.DeliveryAddresses[0]?.City;
  }
}
