import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { ModelOptions } from 'app/ng/ModelOptions';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as App from 'app/ts/app';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { SwingTemplate } from 'app/ts/clientDto/SwingTemplate';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { RouteService } from 'app/ts/services/RouteService';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
//import { Observable } from 'app/ts/util/Observable'
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { Subject } from 'rxjs';
import {
  ClientSettingInjector,
  ClientSetting,
  ClientSettingData,
  SwingSetting,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';

@Component({
  selector: 'swing-vm',
  templateUrl: './swing.html',
  styleUrls: [
    '../../style/editor.scss',
    '../../style/smallScreenHacks.scss',
    '../../style/widgets.scss',
  ],
  providers: [clientSettingProvider, DeliveryAddressService],
})
export class SwingVm extends EditorVm implements OnInit, AfterViewInit {
  private clientSettingsData!: ClientSettingData;

  public selectionObservable: Subject<Client.SwingArea | null>;
  private _selectedSwingArea: Client.SwingArea | null = null;

  public get selectedSwingArea() {
    return this._selectedSwingArea;
  }
  public isAdvancedMenuOpen: boolean = false;
  public itemPropertyModelOptions: ModelOptions = {
    ...this.modelOptions,
  };

  private selected: string = '';

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    private readonly modalService: ModalService,
    @Inject(ClientSettingInjector) private clientSetting: ClientSetting,
    deliveryAddressService: DeliveryAddressService,
    private readonly navigate: NavigateService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );

    this.selectionObservable = new Subject();

    super.ensureUnsubscribe(
      clientSetting.subscribe((next) => {
        this.clientSettingsData = next;
      }),
    );

    let lastSelection: Client.SwingArea | null = null;
    this.ensureUnsubscribe(
      this.selectionObservable.subscribe((newSelection) => {
        if (lastSelection !== newSelection) {
          this.clearSelectionCache();
          if (App.debug.showSelectionChanges) {
            console.debug('SwingVm selectionChange: ', newSelection);
          }
        }
        lastSelection = newSelection;

        this._selectedSwingArea = newSelection;
      }),
    );
  }

  ngOnInit(): void {
    this.subscribeTo(this.cabinetSection!.cabinet.floorPlan.floorPlan$, (fp) =>
      this.clearCache(),
    );
  }

  ngAfterViewInit(): void {
    this.ensureUnsubscribe(
      this.activatedRoute.queryParamMap.subscribe((query) => {
        this.selected = query.get('selected') ?? '';
        this.setSelectionFromQuery();
      }),
    );
  }

  private cache: Partial<{
    swingTemplatePickables: Pickable<SwingTemplate>[];
    corpusMaterialPickable: Pickable<Client.Material | null>;
    doorMaterialPickable: Pickable<Client.Material | null>;
    gripPickable: Pickable<Client.Product | null>;
  }> = {};

  private selectionCache: Partial<{
    areaDoorMaterialPickable: Pickable<Client.Material | null>;
    availableSwingAreaSubModules: SubModule[];
  }> = {};

  private clearCache() {
    this.clearSelectionCache();
    this.cache = {};
  }
  private clearSelectionCache() {
    this.selectionCache = {};
  }

  protected floorPlanChanged() {
    //do nothing
  }

  @HostListener('window:keydown', ['$event'])
  protected override handleKeyboardEvent(evt: KeyboardEvent | null) {
    if (!evt) return;

    if (evt.defaultPrevented) return;

    if (evt.code === 'Delete' || evt.key === 'Del') {
      this.deleteSelectedItem();
      evt.stopPropagation();
      evt.preventDefault();
    }

    super.handleKeyboardEvent(evt);
  }

  public override undo() {
    if (this.isUndoEnabled) this.selectionObservable.next(null);

    super.undo();
  }

  public override redo() {
    if (this.isRedoEnabled) this.selectionObservable.next(null);

    super.undo();
  }

  private setSelectionFromQuery() {
    try {
      let areaIndexOneBased = parseInt(this.selected);
      if (isNaN(areaIndexOneBased)) {
        return;
      }

      let area = this.cabinetSection!.swing.areas[areaIndexOneBased - 1];
      this.selectionObservable.next(area || null);
    } catch (e: any) {
      //parse failed, do nothing
      //this.navigate.setQuery({}, this.activatedRoute)
    }
  }

  private deleteSelectedItem() {
    if (!this._selectedSwingArea) {
      return;
    } else if (this._selectedSwingArea instanceof Client.SwingArea) {
      this.deleteSwingArea(this._selectedSwingArea);
    } else {
      console.error('unknown item selected - cannot delete it');
    }
  }

  public get swingTemplatePickables(): Pickable<SwingTemplate>[] {
    if (!this.cache.swingTemplatePickables) {
      let fullCatalog =
        this.cabinetSection!.editorAssets.fullCatalog &&
        this.cabinetSection!.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.swingTemplatePickables =
        this.cabinetSection!.editorAssets.swingTemplates.map<
          Pickable<SwingTemplate>
        >((st) => {
          let numAreas = this.cabinetSection!.swing.areas.length;
          let module = numAreas > 0 ? st.extendModule : st.startModule;
          return {
            ...ProductHelper.getPickable(module.product),
            item: st,
            override: module.chainOverride,
          };
        }).filter((stp) => fullCatalog || !stp.override);
    }
    return this.cache.swingTemplatePickables;
  }

  public get corpusMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.corpusMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swing.corpusMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swing_no_corpus_material_selected',
            'Select Corpus Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      pickable.overlay = this.translate(
        'editor_swing_corpus_material_overlay',
        'Corpus',
      );
      this.cache.corpusMaterialPickable = pickable;
    }
    return this.cache.corpusMaterialPickable;
  }

  public get doorMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.cache.doorMaterialPickable) {
      let pickable: Pickable<Client.Material | null>;
      let material = this.cabinetSection!.swing.doorMaterial;
      if (material) {
        pickable = MaterialHelper.getPickable(material);
      } else {
        pickable = {
          item: null,
          name: this.translate(
            'editor_swing_no_door_material_selected',
            'Select Door Material',
          ),
          isSelected: true,
          override: false,
          disabledReason: null,
        };
      }
      pickable.overlay = this.translate(
        'editor_swing_door_material_overlay',
        'Door',
      );
      this.cache.doorMaterialPickable = pickable;
    }
    return this.cache.doorMaterialPickable;
  }

  public get gripPickable(): Pickable<Client.Product | null> {
    if (!this.cache.gripPickable) {
      let pickable: Pickable<Client.Product | null>;
      let product = this.cabinetSection!.swing.grip;
      if (product) {
        pickable = ProductHelper.getPickable(product);
      } else {
        pickable = this.cabinetSection!.swing.noGripPickable;
      }
      pickable.overlay = this.translate('editor_swing_grip_overlay', 'Grip');

      this.cache.gripPickable = pickable;
    }
    return this.cache.gripPickable;
  }

  public translateHingeSide(hingeSide: Enums.HingeSide): string {
    return this.translate('editor_swing_hinge_side_' + hingeSide, hingeSide);
  }

  public async addSwingTemplate(swingTemplate: SwingTemplate) {
    let area = this.cabinetSection!.swing.addSwingArea(swingTemplate);
    let success = await this.setChanged();
    if (success) {
      this.selectionObservable.next(area);
    } else {
      this.selectionObservable.next(null);
    }
  }

  public deleteSwingArea(swingArea: Client.SwingArea) {
    swingArea.desiredChange = Enums.SwingModuleChange.Remove;
    this.setChanged();
    this.selectionObservable.next(null);
  }

  public moveSwingAreaLeft(swingArea: Client.SwingArea) {
    swingArea.desiredChange = Enums.SwingModuleChange.MoveLeft;
    this.setChanged();
  }
  public moveSwingAreaRight(swingArea: Client.SwingArea) {
    swingArea.desiredChange = Enums.SwingModuleChange.MoveRight;
    this.setChanged();
  }

  public get selectedAreaHingeSide(): Enums.HingeSide | null {
    return this._selectedSwingArea && this._selectedSwingArea.hingeSide;
  }
  public set selectedAreaHingeSide(val: Enums.HingeSide | null) {
    if (val === this.selectedAreaHingeSide) {
      return;
    }
    if (!val) {
      return;
    }
    let ssa = this._selectedSwingArea;
    if (!ssa) {
      return;
    }
    ssa.hingeSide = val;
    this.setChanged();
  }

  public get selectedAreaWidth(): number {
    return (
      (this._selectedSwingArea && this._selectedSwingArea.desiredInsideWidth) ||
      0
    );
  }
  public set selectedAreaWidth(val: number) {
    if (!this._selectedSwingArea) {
      return;
    }
    this._selectedSwingArea.desiredInsideWidth = val;
    this.setChanged();
  }

  public get canFitSectionWidthToContents() {
    return this.cabinetSection!.swing.canFitSectionWidthToContents;
  }

  public fitSectionWidthToContents() {
    this.cabinetSection!.swing.fitSectionWidthToContents();
    this.setChanged();
  }

  public get canfitContentsToSectionWidth() {
    return this.cabinetSection!.swing.canfitContentsToSectionWidth;
  }

  public fitContentsToSectionWidth() {
    this.cabinetSection!.swing.fitContentsToSectionWidth();
    this.setChanged();
  }

  public get cabinetSectionWidth() {
    return this.cabinetSection!.Width;
  }
  public set cabinetSectionWidth(val: number) {
    this.cabinetSection!.Width = val;
    this.setChanged();
  }

  public get cabinetSectionHeight() {
    return this.cabinetSection!.Height;
  }
  public set cabinetSectionHeight(val: number) {
    this.cabinetSection!.Height = val;
    this.setChanged();
  }

  private setSwingSetting(swingSetting: Partial<SwingSetting>) {
    const newSwingSettings = {
      ...this.clientSettingsData.swing,
      ...swingSetting,
    };
    const newSettings = {
      ...this.clientSetting.data,
      swing: newSwingSettings,
    };
    this.clientSetting.set(newSettings);
  }

  public async selectCorpusMaterial() {
    let pickables = this.cabinetSection!.swing.pickableCorpusMaterials;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_corpus_material',
        'Select corpus material',
      ),
      'material corpus-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this.cabinetSection!.swing.corpusMaterial = material;
    this.setChanged();
  }

  public async selectDoorMaterial(swingArea?: Client.SwingArea) {
    let pickables = this.cabinetSection!.swing.pickableDoorMaterials;

    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate(
        'editor_swing_select_door_material',
        'Select door material',
      ),
      'material door-material',
    );
    let material;
    try {
      material = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    if (swingArea) {
      swingArea.doorMaterial = material;
    } else {
      this.cabinetSection!.swing.doorMaterial = material;
    }
    this.setChanged();
  }

  public async selectGrip() {
    let pickables = this.cabinetSection!.swing.pickableGrips;
    let modal = this.modalService.getPickableSelector(
      pickables,
      this.translate('editor_swing_select_grip', 'Select Grip type'),
      'product grip-product',
      true,
    );
    let grip;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //modal was cancelled
      return;
    }
    this.cabinetSection!.swing.grip = grip;
    this.setChanged();
  }

  public get showRulers(): boolean {
    return this.clientSettingsData.swing.displayRulers;
  }
  public set showRulers(val: boolean) {
    this.setSwingSetting({ displayRulers: val });
  }

  public get showDoors(): boolean {
    return this.clientSettingsData.swing.displayDoors;
  }
  public set showDoors(val: boolean) {
    this.setSwingSetting({ displayDoors: val });
  }

  public get showTopdown() {
    return this.clientSettingsData.swing.displayTopdown;
  }
  public set showTopdown(val: boolean) {
    this.setSwingSetting({ displayTopdown: val });
  }

  public override setChanged() {
    this.clearCache();
    return super.setChanged();
  }

  public get selectedSwingAreaDoorMaterialPickable(): Pickable<Client.Material | null> {
    if (!this.selectionCache.areaDoorMaterialPickable) {
      let mat = this._selectedSwingArea
        ? this._selectedSwingArea.doorMaterial
        : null;
      let pickable;
      if (mat) {
        pickable = MaterialHelper.getPickable(mat);
      } else {
        pickable = {
          disabledReason: null,
          isSelected: false,
          item: null,
          name: this.translate(
            'editor_swing_select_door_material',
            'Select door material',
          ),
          override: false,
        };
      }
      this.selectionCache.areaDoorMaterialPickable = pickable;
    }
    return this.selectionCache.areaDoorMaterialPickable;
  }

  public get availableSwingAreaSubModules(): SubModule[] {
    if (!this.selectionCache.availableSwingAreaSubModules) {
      let variantOptionNumber = 0;
      let availables: SubModule[] = [
        {
          name: this.translate(
            'editor_swing_select_sub_module_name_' + variantOptionNumber,
            'Submodule ' + variantOptionNumber,
          ),
          val: variantOptionNumber,
        },
      ];
      if (this._selectedSwingArea?.swingTemplate != null) {
        let fullCatalog =
          this.cabinetSection!.editorAssets.fullCatalog &&
          this.cabinetSection!.cabinet.floorPlan.FullCatalogAllowOtherProducts;
        for (let variantOptionNumber in this._selectedSwingArea.swingTemplate
          .subModules) {
          let subModules =
            this._selectedSwingArea.swingTemplate.subModules[
              variantOptionNumber
            ];
          if (!fullCatalog && subModules.some((sm) => sm.chainOverride)) {
            continue;
          }
          availables.push({
            name: this.translate(
              'editor_swing_select_sub_module_name_' + variantOptionNumber,
              'Submodule ' + variantOptionNumber,
            ),
            val: parseInt(variantOptionNumber),
          });
        }
      }
      this.selectionCache.availableSwingAreaSubModules = availables;
    }
    return this.selectionCache.availableSwingAreaSubModules;
  }
}
export type SubModule = { val: number; name: string };
