import { inject, Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestConfig } from './RequestConfig';
import {
  CustomPriceDateHeaderName,
  OverrideChainHttpHeaderName,
  UseFullCatalogHttpHeaderName,
} from 'app/ts/InterfaceConstants';
import { ResponseType } from './Options';
import { ActiveStoreInjector } from 'app/functional-core/ambient/stores/ActiveStore';
import { Store } from 'app/ts/Interface_DTO';
import { CustomPriceDateInjector } from 'app/functional-core/ambient/clientSetting/CustomPriceDate';
import { UseFullCatalogInjector } from 'app/functional-core/ambient/clientSetting/UseFullCatalog';

@Injectable()
export class BackendService {
  private activeStore: Store;
  private customPriceDate: string | undefined;

  private useFullCatalog: boolean;

  constructor(
    private httpClient: HttpClient,
    @Inject('BACKEND_URL') private baseUrl: string,
  ) {
    if (!baseUrl.endsWith('/')) this.baseUrl = this.baseUrl + '/';

    const store = inject(ActiveStoreInjector);
    this.activeStore = store.data;
    store.subscribe((store) => {
      this.activeStore = store;
    });

    const customPriceDate = inject(CustomPriceDateInjector);
    this.customPriceDate = customPriceDate.asString(customPriceDate.data);
    customPriceDate.subscribe((value) => {
      this.customPriceDate = customPriceDate.asString(value);
    });

    const useFullCatalog = inject(UseFullCatalogInjector);
    this.useFullCatalog = useFullCatalog.data;
    useFullCatalog.subscribe((value) => {
      this.useFullCatalog = value;
    });
  }

  public delete<T>(relativeUrl: string): Observable<HttpResponse<T>> {
    return this.httpClient.delete<T>(this.baseUrl + relativeUrl, {
      observe: 'response',
      withCredentials: true,
    });
  }

  public get<T>(
    relativeUrl: string,
    config?: RequestConfig,
  ): Observable<HttpResponse<T | string | Blob>> {
    const url = this.baseUrl + relativeUrl;
    const responseType = this.calcResponseType(config);

    if (responseType == 'blob') {
      return this.httpClient.get(url, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    if (responseType == 'json') {
      return this.httpClient.get<T>(url, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    return this.httpClient.get(url, {
      observe: 'response',
      withCredentials: true,
      responseType: 'text',
      headers: this.buildHeaders(),
    });
  }

  public post<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Observable<HttpResponse<T | string | Blob>> {
    const url = this.baseUrl + relativeUrl;
    const responseType = this.calcResponseType(config);

    if (responseType == 'blob') {
      return this.httpClient.post(url, body, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    if (responseType == 'json') {
      return this.httpClient.post<T>(url, body, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    return this.httpClient.post(url, body, {
      observe: 'response',
      withCredentials: true,
      params: this.asHttpParams(config?.params),
      responseType: 'text',
      headers: this.buildHeaders(),
    });
  }

  public put<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Observable<HttpResponse<T | string | Blob>> {
    const url = this.baseUrl + relativeUrl;
    const responseType = this.calcResponseType(config);

    if (responseType == 'blob') {
      return this.httpClient.put(url, body, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    if (responseType == 'json') {
      return this.httpClient.put<T>(url, body, {
        observe: 'response',
        withCredentials: true,
        params: this.asHttpParams(config?.params),
        responseType: responseType,
        headers: this.buildHeaders(),
      });
    }

    return this.httpClient.put(url, body, {
      observe: 'response',
      withCredentials: true,
      params: this.asHttpParams(config?.params),
      responseType: 'text',
      headers: this.buildHeaders(),
    });
  }

  private calcResponseType(config?: RequestConfig): ResponseType {
    if (config?.responseType == null) return 'json';

    return config.responseType;
  }

  private buildHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    if (this.activeStore?.Id)
      headers = headers.set(
        OverrideChainHttpHeaderName,
        this.activeStore.Id.toString(),
      );

    if (this.customPriceDate)
      headers = headers.set(CustomPriceDateHeaderName, this.customPriceDate);

    if (this.useFullCatalog)
      headers = headers.set(UseFullCatalogHttpHeaderName, '1');

    return headers;
  }

  private asHttpParams(params: object): HttpParams | undefined {
    if (params == null) return undefined;

    let httpParams = new HttpParams();

    for (const [key, value] of Object.entries(params)) {
      if (value != null) {
        httpParams = httpParams.set(key, value);
      }
    }

    return httpParams;
  }
}
