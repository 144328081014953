<div class="modal-content consumer">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('consumer_login_header', 'Login')"
    ></h3>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <form (submit)="login()">
    <div class="modal-body">
      <div class="input-group">
        <label>
          {{translate('consumer_login_username', 'User name')}}
          <input
            type="text"
            [(ngModel)]="username"
            class="form-control"
            autofocus
            required
            autocomplete="username"
            id="username"
          />
        </label>
      </div>
      <div class="input-group">
        <label>
          {{translate('consumer_login_password', 'Password')}}
          <input
            type="password"
            [(ngModel)]="password"
            class="form-control"
            autofocus
            required
            id="current-password"
            autocomplete="current-password"
          />
        </label>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary" type="button" (click)="newUser()">
        {{translate('consumer_login_newUser', 'Create New User..')}}
      </button>
      <button class="btn btn-primary" type="submit" [disabled]="isActive">
        {{translate('consumer_login_submit', 'Login')}}
      </button>
      <div *ngIf="showError">
        <div
          class="alert alert-warning"
          style="margin: 10px 0 0; text-align: left"
        >
          {{translate('consumer_login_error', 'Could not log you in - user name
          or password is incorrect.')}}
        </div>
      </div>
    </div>
  </form>
</div>
