import { Component, Input, OnInit } from '@angular/core';
import * as ClientSetting from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { WindowService } from 'app/ng/window.service';
import {
  calculateModules,
  FillingsViewModule,
} from 'app/partition/fillings/module';
import {
  calculateSection,
  FillingsViewSection,
} from 'app/partition/fillings/section';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { BarHeightEx } from 'app/ts/Client';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';
import Enumerable from 'linq';
import { Selection } from '../../partition/fillings/selection';
import { EditorVm } from '../EditorVm';
import * as Client from 'app/ts/clientDto/index';
import { ModalService } from 'app/ts/services/ModalService';
import { Pickable } from 'app/ts/interfaces';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { AssetService } from 'app/ts/services/AssetService';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import * as Interface_Enums from 'app/ts/Interface_Enums';

@Component({
  selector: 'fillings-vm',
  templateUrl: './fillings.html',
  styleUrls: ['../../../style/editor.scss', './fillings.scss'],
  providers: [ClientSetting.clientSettingProvider, DeliveryAddressService],
})
export class FillingsVm extends EditorVm implements OnInit {
  private _modules!: Readonly<FillingsViewModule[]>;
  private _section!: Readonly<FillingsViewSection>;

  public showRulers: boolean = true;
  public showTopdown: boolean = true;

  public readonly railSet: Pickable<Client.RailSet | null>;
  // Updated by event in HTML template
  public selection: Selection = {};

  public readonly selectedProfileMaterial: Pickable<Interface_DTO.Material | null>;

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,

    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly modalService: ModalService,
    private assetService: AssetService,
    private translateService: TranslateService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );

    this.railSet = new Client.ConverterPickable(
      () => assetService.editorAssets.railSetsDict[this.section.doorRailsetId],
      (rs) => {
        return {
          ...RailSetHelper.getPickable(rs),
          isSelected: true,
        };
        // TODO: What to do if config in db is screwed up, and no railset is listed for partition door?
      },
    );

    this.selectedProfileMaterial =
      new Client.ConverterPickable<Client.Material | null>(
        () => this.section.profileMaterial,
        (mat) => {
          return mat
            ? {
                ...MaterialHelper.getPickable(mat),
                isSelected: true,
              }
            : {
                name: translateService.translate(
                  'fillings_filling_no_material_name',
                  'No filling',
                ),
                disabledReason: null,
                item: null,
                isSelected: true,
                override: false,
              };
        },
      );
  }

  ngOnInit(): void {
    this.editorType.ready$.subscribe((ready) => {
      if (ready && this.partitionQueryService.selectedSection != null) {
        this._modules = calculateModules(
          this.partitionQueryService.selectedSection!.id,
          this.partitionQueryService.plan,
          this.editorType.floorPlan,
          this.partitionQueryService.getDoorModuleProductId(),
          this.partitionQueryService.getWallModuleProductId(),
        );
        this._section = calculateSection(
          this.partitionQueryService.selectedSection!.id,
          this.partitionQueryService.plan,
          this.editorType.floorPlan,
        );
      }
    });

    this.partitionQueryService.partitionPlan$.subscribe((plan) => {
      if (this.partitionQueryService.selectedSection != null) {
        this._modules = calculateModules(
          this.partitionQueryService.selectedSection!.id,
          plan,
          this.editorType.floorPlan,
          this.partitionQueryService.getDoorModuleProductId(),
          this.partitionQueryService.getWallModuleProductId(),
        );
        this._section = calculateSection(
          this.partitionQueryService.selectedSection!.id,
          this.partitionQueryService.plan,
          this.editorType.floorPlan,
        );
      }
    });
  }

  public get modules(): Readonly<FillingsViewModule[]> {
    return this._modules;
  }

  public get section(): FillingsViewSection {
    return this._section as FillingsViewSection;
  }

  public get selectedModule(): FillingsViewModule {
    return this._modules.find(
      (md) => md.id == this.partitionQueryService.selectedModule!.id,
    )!;
  }

  public override undo() {
    let selected = this.partitionQueryService.selectedSection;
    let selectedModule = this.partitionQueryService.selectedModule;
    super.undo();
    this.partitionCommandService.setSelection(selected!.id, selectedModule?.id);
  }

  public override redo() {
    let selected = this.section;
    super.redo();
    this.partitionCommandService.setSelection(selected.id);
  }

  protected floorPlanChanged() {
    //implemented from EditorVm
  }

  public isModuleSelected(): boolean {
    return this.partitionQueryService.selectedModule != undefined;
  }

  public isFillingSelected(): boolean {
    return this.selection.filling != undefined;
  }

  public isBarSelected(): boolean {
    return this.selection.bar != undefined;
  }

  public updateSectionBarHeight(newHeight: string) {
    let height = parseInt(newHeight);
    this.partitionCommandService.updateSectionBarHeight(
      this.section.id,
      height,
    );

    this.setChanged();
  }

  public get availableBars(): BarHeightEx[] {
    let floorPlan = this.editorType.floorPlan;
    let fullCatalog =
      floorPlan.editorAssets.fullCatalog &&
      floorPlan.FullCatalogAllowOtherProducts;

    let doorData = Enumerable.from(
      floorPlan.editorAssets.productDoorData,
    ).firstOrDefault(
      (pdd) =>
        pdd.ProductId === this.partitionQueryService.getDoorModuleProductId(),
    );

    if (doorData) {
      let bd1 = Enumerable.from(doorData.Bars)
        .where((barData) => fullCatalog || !barData.ChainOverride)
        .groupBy((barData) => barData.Height)
        .select(
          (barGroup) =>
            new BarHeightEx(
              barGroup
                .select((barData) => ({
                  barData: barData,
                  product:
                    floorPlan.editorAssets.productsDict[barData.ProductId],
                }))
                .toArray(),
            ),
        )
        .where(
          (barHeight) =>
            barHeight.isValid() &&
            (fullCatalog ||
              !barHeight.bars.some((b) => b.product.overrideChain)),
        )
        .orderByDescending((bde) => bde.height)
        .toArray();

      return Enumerable.from(bd1)
        .distinct((bde) => bde.height)
        .toArray();
    } else {
      return [];
    }
  }

  public barHeightDisabledReason(
    barHeight: BarHeightEx,
  ): Client.Translatable | null {
    // TODO: Revisit later to check logic
    let isVerticalBarsUsed = this.modules.some(
      (md) => md.verticalBarOption?.Number != '0',
    ); //this.cabinetSection!.doors.doors.some(d => d.numberOfVerticalBars > 0);
    if (isVerticalBarsUsed) {
      if (!barHeight.isCompatibleWithVerticalBars) {
        return new Client.Translatable(
          'doors_bar_loose_unavailable_vertical_bars',
          'Loose bars are not available when using vertical bars',
        );
      }
    }
    // let requiresSplitFillings = this.cabinetSection!.doors.doors.some(door =>
    //     door.numberOfFillingsMin > 1);
    // if (requiresSplitFillings) {
    //     if (!barHeight.isCompatibleWithSplitFillings) {
    //         return new Client.Translatable(
    //             "doors_bar_loose_unavailable_split_fillings",
    //             "Loose bars are not available when using very large doors"
    //         );
    //     }
    // }

    return null;
  }

  public async selectPartitionDoorProfile() {
    let ProductNos: string[] = ['1928', '19282', '2028', '20999-28']; // This is all the Partition railsets I have been able to find in the DB

    let modal = this.modalService.getPickableSelector(
      this.getPickableRailSet(
        this.assetService.editorAssets.railSets.filter((rs) => {
          let product = rs.bottomProduct || rs.topProduct;
          if (product) {
            if (ProductNos.includes(product.ProductNo)) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }),
      ),
      this.translate('partition_railset_selector_header', 'Rail sets'),
      'railset',
    );

    let railset: Client.RailSet | null;
    try {
      railset = await modal.result;
    } catch (e: any) {
      //User most likely canceled, don't do anything.
      return;
    }
    if (railset != null) {
      this.partitionCommandService.updateDoorRailSetOnSelectedSection(
        railset.Id,
      );
    } else {
    }
    this.setChanged();
  }

  private getPickableRailSet(
    availableRailSet: Client.RailSet[],
  ): Pickable<Client.RailSet>[] {
    let returnPickable = availableRailSet.map<Pickable<Client.RailSet>>(
      (railset) => {
        let product = railset.bottomProduct || railset.topProduct;
        let imgageUrl = product
          ? ProductHelper.picturePath(product)
          : undefined;

        let pickable: Pickable<Client.RailSet> = {
          name: product ? product.Name : railset.DefaultName,
          id: product ? product.ProductNo : '00',
          item: railset,
          disabledReason: null,
          isSelected: false,
          override: false,
          imageUrl: imgageUrl,
        };

        return pickable;
      },
    );

    return returnPickable;
  }

  public async selectProfileMaterial() {
    let modal = this.modalService.getPickableSelector(
      this.pickableProfileMaterials,
      this.translateService.translate(
        'fillings_profile_material_selector_header',
        'Section Profile Material',
      ),
      'material fillings-profile',
    );
    let material: Client.Material;
    try {
      material = await modal.result;
      this.partitionCommandService.updatePartitionProfileMaterial(
        this.section.id,
        material.Id,
      );
      this.floorPlanService.setChanged(this.floorPlan);
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
  }

  private get pickableProfileMaterials(): Pickable<Client.Material>[] {
    let fullCatalog =
      this.floorPlan.editorAssets.fullCatalog &&
      this.floorPlan.FullCatalogAllowOtherMaterials;

    let allProductMaterials = this.section.profileProduct.materials;

    let availableProfileMaterials = allProductMaterials.filter(
      (m) => m.Type === Interface_Enums.MaterialType.Frame,
    );
    availableProfileMaterials.sort((m1, m2) => m1.SortOrder - m2.SortOrder);

    let profileMaterials = availableProfileMaterials
      .filter((pm) => !pm.isDiscontinued && (fullCatalog || !pm.isOverride))
      .map((mat) => {
        let pickable: Pickable<Client.ProductMaterial> = {
          ...MaterialHelper.getPickable(mat),
          isSelected: this.section.profileMaterial.Id === mat.Id,
          override: mat.isOverride,
        };

        return pickable;
      });

    MaterialHelper.sortPickableMaterials(profileMaterials);
    return profileMaterials;
  }
}
