import { Inject, Injectable } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { NavigationEnd, ParamMap, Router, UrlSegment } from '@angular/router';
import { EditorSection } from 'app/ts/clientDto/Enums';
import { CabinetType } from '../Interface_Enums';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SectionId } from 'app/partition/section';
import { MeasurementService } from 'app/measurement/measurement.service';
import {
  UseFullCatalog,
  UseFullCatalogInjector,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';

/**
 * Looks at the url and determines what editor type is applicable.
 * The url might look something like this:
 * /floorplan/3185/1/1/doors
 * This means that the floor plan with id 3185 should be loaded
 * and cabinet section 1 in cabinet 1 is the active section.
 * Furthermore the active Editor should be the @see DoorVm editor.
 *
 * The service is providedIn='root' because it should not
 * needlessly load floor plans.
 */
@Injectable({
  providedIn: 'root',
})
export class EditorTypeService {
  private _editorType: EditorSection | undefined;

  private _defaultEditorType: EditorSection | undefined;

  /**
   * Returns the calculated editor type. Can e.g. be used
   * by an actual editor to validate whether it is the right one
   * instantiated
   */
  public get editorType() {
    return this._editorType;
  }

  /**
   * The floor plan applicable for the active url
   * @deprecated Type declaration is lying - value might be null!
   */

  public get floorPlan(): Client.FloorPlan {
    return this._currentFloorPlan!;
  }
  private _currentFloorPlan: Client.FloorPlan | null = null;

  /**
   * The relevant CabinetSection, if any.
   */
  public get cabinetSection(): Client.CabinetSection | undefined {
    return this._cabinetSection;
  }
  private _cabinetSection?: Client.CabinetSection;

  /**
   * Triggers when the "ready"-state of the EditorTypeService changes.
   * E.g. when a floor plan has been loaded.
   */
  public get ready$(): Observable<boolean> {
    return this._ready$;
  }
  private _ready$ = new BehaviorSubject<boolean>(false);

  private _ready: boolean = false;
  public get ready(): boolean {
    return this._ready;
  }

  constructor(
    private readonly floorPlans: FloorPlanService,
    private readonly partitionData: PartitionPlanDataService,
    private readonly measurementService: MeasurementService,
    private readonly router: Router,
    @Inject(UseFullCatalogInjector) private useFullCatalog: UseFullCatalog,
  ) {
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        filter((e) => (e as NavigationEnd).url.startsWith('/floorplan/')), //only in editor part of the app
        takeUntilDestroyed(),
      )
      .subscribe((e) => {
        // The last child in the route tree is the current route, so it seems
        let route = router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }

        this._ready = false;
        this._ready$.next(false);
        this.init(route.snapshot.paramMap, route.snapshot.url);
      });
  }

  private async init(paramMap: ParamMap, url: UrlSegment[]) {
    this._ready$.next(false);
    const floorplanIdParam = paramMap.get('floorplanid');

    const cabinetIndexParam = paramMap.get('cabinetindex');
    const cabinetSectionIndexParam = paramMap.get('cabinetsectionindex');

    let navState = this.router.getCurrentNavigation()?.extras?.state;
    let openedFromOverview = false;
    if (navState) {
      openedFromOverview =
        navState['openedFromOverview'] != undefined &&
        navState['openedFromOverview'];
    }

    if (
      (openedFromOverview ||
        this._currentFloorPlan == null ||
        this._currentFloorPlan == undefined) &&
      floorplanIdParam != null
    ) {
      this._currentFloorPlan =
        await this.floorPlans.loadFloorplan(floorplanIdParam);
      this.partitionData.hydratePartition(
        this._currentFloorPlan?.dtoObject.PartitionJsonData,
      );
      this.measurementService.hydrateData(
        this._currentFloorPlan?.dtoObject.MeasurementJsonData,
      );

      if (this._currentFloorPlan) {
        this.floorPlans.beginUndoStack(this._currentFloorPlan);
        this.floorPlans.addToUndoStack(this._currentFloorPlan);
      }
    }

    if (this._currentFloorPlan == null) {
      throw new Error('Failed to load floor plan');
    }

    this.useFullCatalog.subscribe((val) => {
      if (this._currentFloorPlan) this._currentFloorPlan.UsesFullCatalog = val;
    });

    const isPartitionPage = url.some((segment) => segment.path === 'partition');
    if (isPartitionPage) {
      const sectionIdStr = paramMap.get('sectionindex');

      if (sectionIdStr != null) {
        try {
          const sectionId = parseInt(sectionIdStr);
          this.partitionData.setSelection(sectionId as SectionId);
        } catch {
          this.router.navigateByUrl(`/floorplan/${floorplanIdParam}`);
        }

        if (this.partitionData.selectedSection == undefined) {
          this.router.navigateByUrl(`/floorplan/${floorplanIdParam}`);
        }

        const defaultEditorType = EditorSection.Fillings;
        const editorType = getEditorTypeFromUrl(url, defaultEditorType);

        let ValidEditor = isValidEditor(
          editorType,
          CabinetType.Partition,
          false,
        );
        if (ValidEditor) {
          this._editorType = editorType;
          this._defaultEditorType = defaultEditorType;
          this._cabinetSection = undefined;
        }
      }
    } else {
      const isJustFloorplan =
        floorplanIdParam != null &&
        cabinetIndexParam == null &&
        cabinetSectionIndexParam == null;
      let cabinetIndex: number;
      let cabinetSectionIndex: number;
      if (isJustFloorplan) {
        this._editorType = EditorSection.FloorPlan;
        this._defaultEditorType = EditorSection.FloorPlan;
        cabinetIndex = 0; //0 or 1 indexed?
        cabinetSectionIndex = 0; //0 or 1 indexed?
      } else {
        cabinetIndex = parseIndexParameter(cabinetIndexParam);
        cabinetSectionIndex = parseIndexParameter(cabinetSectionIndexParam);
      }

      const section = findSection(
        this._currentFloorPlan,
        cabinetIndex,
        cabinetSectionIndex,
      );
      if (section != null) {
        this.partitionData.selectedSection = undefined;
        this.partitionData.selectedModule = undefined;

        const defaultEditorType = getDefaultEditorType(section);
        const editorType = getEditorTypeFromUrl(url, defaultEditorType);

        let ValidEditor = isValidEditor(
          editorType,
          section.CabinetType,
          section.backing.availableProducts.count() > 0,
        );
        if (ValidEditor) {
          this._editorType = editorType;
          this._defaultEditorType = defaultEditorType;
          this._cabinetSection = section;
        } else {
          let editorName = mapEditorTypeToName(defaultEditorType);
          this.router.navigateByUrl(
            `/floorplan/${floorplanIdParam}/${cabinetIndexParam}/${cabinetSectionIndexParam}/${editorName}`,
          );
        }
      } else {
        this._editorType = EditorSection.FloorPlan;

        if (!isJustFloorplan)
          this.router.navigateByUrl(`/floorplan/${floorplanIdParam}`);
      }
    }

    this._ready = true;
    this._ready$.next(true);
  }
}

export function mapEditorType(urlEditorSegment: string): EditorSection {
  switch (urlEditorSegment.toLowerCase()) {
    case 'backing':
      return EditorSection.Backing;
    case 'corpus':
      return EditorSection.Corpus;
    case 'd3':
      return EditorSection.D3;
    case 'doors':
      return EditorSection.Doors;
    case 'floorplan':
      return EditorSection.FloorPlan;
    case 'interior':
      return EditorSection.Interior;
    case 'piecelist':
      return EditorSection.PieceList;
    case 'swing':
      return EditorSection.Swing;
    case 'validation':
      return EditorSection.Validation;
    default:
      return EditorSection.Corpus;
  }
}

export function mapEditorTypeToName(type: EditorSection): string {
  switch (type) {
    case EditorSection.Corpus:
      return 'corpus';
    case EditorSection.Swing:
      return 'swing';
    case EditorSection.Doors:
      return 'doors';

    default:
      return '';
  }
}

function getValidEditors(
  sectionType: CabinetType,
  hasBackingProducts: boolean,
): EditorSection[] {
  let editorSections: EditorSection[] = [EditorSection.Validation];

  switch (sectionType) {
    case CabinetType.Doors:
      editorSections.push(EditorSection.Corpus);
      editorSections.push(EditorSection.Doors);
      editorSections.push(EditorSection.D3);
      editorSections.push(EditorSection.PieceList);
      break;

    case CabinetType.WalkIn:
      editorSections.push(EditorSection.Corpus);
      editorSections.push(EditorSection.Interior);
      if (hasBackingProducts) {
        editorSections.push(EditorSection.Backing);
      }
      editorSections.push(EditorSection.D3);
      editorSections.push(EditorSection.PieceList);
      break;

    case CabinetType.Swing:
      editorSections.push(EditorSection.Swing);
      editorSections.push(EditorSection.Interior);
      editorSections.push(EditorSection.D3);
      editorSections.push(EditorSection.PieceList);
      break;

    case CabinetType.SharedItems:
      editorSections.push(EditorSection.PieceList);
      break;
    default:
      editorSections.push(EditorSection.Corpus);
      //if (hasBackingProducts) {
      editorSections.push(EditorSection.Doors);
      //}
      editorSections.push(EditorSection.Interior);
      if (hasBackingProducts) {
        editorSections.push(EditorSection.Backing);
      }
      editorSections.push(EditorSection.D3);
      editorSections.push(EditorSection.PieceList);
      break;
  }

  return editorSections;
}

function parseIndexParameter(index: string | null): number {
  if (index == null) return 1;

  const value = parseInt(index);

  if (isNaN(value)) return 1;

  return value;
}

function getDefaultEditorType(section: Client.CabinetSection): EditorSection {
  if (section.cabinet.CabinetType === CabinetType.Doors) {
    return EditorSection.Doors;
  } else if (section.cabinet.CabinetType === CabinetType.Swing) {
    return EditorSection.Swing;
  }

  return EditorSection.Corpus;
}

function isValidEditor(
  editorType: EditorSection,
  cabinetType: CabinetType,
  hasBackingProducts: boolean,
) {
  const validEditors = getValidEditors(cabinetType, hasBackingProducts);
  return validEditors.indexOf(editorType) >= 0;
}

function getEditorTypeFromUrl(
  url: UrlSegment[],
  defaultEditorType: EditorSection,
): EditorSection {
  return url.length == 5 ? mapEditorType(url[4].path) : defaultEditorType;
}

function findSection(
  floorPlan: Client.FloorPlan,
  cabinetIndex: number,
  cabinetSectionIndex: number,
): Client.CabinetSection | null {
  for (let cabinet of floorPlan.cabinets) {
    if (cabinet.CabinetIndex === cabinetIndex) {
      for (let section of cabinet.cabinetSections) {
        if (section.CabinetSectionIndex === cabinetSectionIndex) {
          return section;
        }
      }
    }
  }

  return null;
}
