import { AssetService } from 'app/ts/services/AssetService';
import { Inject, Injectable } from '@angular/core';
import {
  UserSettings,
  UserSettingsInjector,
} from 'app/functional-core/ambient/userdata/UserData';
@Injectable({ providedIn: 'root' })
export class FormattingService {
  private shortDateTImeFormat: Intl.DateTimeFormat | undefined;
  private longDateTImeFormat: Intl.DateTimeFormat | undefined;

  constructor(
    assetService: AssetService,
    @Inject(UserSettingsInjector)
    public readonly userSettings: UserSettings | undefined,
  ) {
    this.setDateFormat('en');
    assetService.languages$.subscribe((languages) => {
      if (this.userSettings != null) {
        const lang = languages.filter(
          (l) => l.NavisionCode == this.userSettings?.value.LanguageCode,
        )[0];
        const cultureCode = lang ? lang.Culture : 'en-GB';
        this.setDateFormat(cultureCode);
      }
    });
  }

  public formatDate(date: Date, includeTime: boolean): string {
    let dtf = includeTime ? this.longDateTImeFormat : this.shortDateTImeFormat;
    if (dtf != null) {
      return dtf.format(date);
    }

    return date.toDateString();
  }

  private setDateFormat(cultureCode: string): void {
    this.shortDateTImeFormat = new Intl.DateTimeFormat(cultureCode, {
      //weekday: "short",
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    this.longDateTImeFormat = new Intl.DateTimeFormat(cultureCode, {
      //weekday: "short",
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }
}
