<div class="consumer-doors consumer-page consumer consumer-new">
  <div class="consumer-nav">
    <navbar current-id="'doors'"></navbar>
  </div>

  <div class="consumer-left">
    <div>
      <div>
        <span
          class="h4"
          [textContent]="translate('consumer_doors_profile', 'Profile')"
        ></span>

        <img
          [src]="consumerFile('icons/info.png')"
          class="help-bubble--circle"
          (click)="openDoorProfileModal()"
        />
      </div>
      <item-picker
        [items]="availableDoorProfiles"
        [(selectedItem)]="selectedDoorProfile"
        display-header="false"
      >
      </item-picker>
    </div>

    <div *ngIf="showHelp" class="help-text">
      {{translate('consumer_doors_nodoors_help', 'Click a door profile above to
      see more info.')}}
    </div>

    <div *ngIf="showNumberOfDoors">
      <item-picker
        [items]="availableNumberOfDoors"
        [(selectedItem)]="numberOfDoors"
        [name]="translate('consumer_doors_numdoors', 'Number of doors')"
        [displayId]="true"
      >
      </item-picker>
    </div>

    <div *ngIf="showFillingMaterials">
      <item-picker
        [items]="availableFillingMaterials"
        [(selectedItem)]="selectedFillingMaterial"
        [name]="translate('consumer_doors_filling_mat', 'Filling Material')"
      >
      </item-picker>
    </div>

    <div *ngIf="showBarDesigns">
      <item-picker
        [items]="availableBarDesigns"
        [(selectedItem)]="selectedBarDesign"
        [name]="translate('consumer_doors_bardesign', 'Design')"
      >
      </item-picker>
    </div>

    <div *ngIf="showToggleAdvancedButton">
      <div *ngIf="!showAllDoors">
        <button class="link-button" (click)="showAllDoors = true">
          <!--(click)="showAllDoors = true" click was removed from button as it caused a memory leak-->
          {{translate("consumer_doors_showAllDoors", "Design individual
          doors")}}
        </button>
      </div>
    </div>

    <div *ngIf="showAllDoors">
      <div *ngIf="showToggleAdvancedButton">
        <button class="link-button" (click)="showAllDoors = false">
          <!--(click)="showAllDoors = false" was removed from button as it caused a memory leak-->
          {{translate("consumer_doors_showAllDoors_on", "Design all doors")}}
        </button>
      </div>

      <div *ngFor="let door of doors">
        <h4>{{door.name}}</h4>

        <div *ngIf="showFillingMaterials">
          <item-picker
            [items]="this.door.availableFillingMaterials"
            [(selectedItem)]="this.door.selectedFillingMaterial"
            display-header="false"
            class="item-picker--small"
            [name]="translate('consumer_doors_filling_mat', 'Filling Material')"
          >
          </item-picker>
        </div>

        <div *ngIf="showBarDesigns">
          <item-picker
            [items]="door.availableBarDesigns"
            [(selectedItem)]="door.selectedBarDesign"
            display-header="false"
            class="item-picker--small"
            [name]="translate('consumer_doors_bardesign', 'Design')"
          >
          </item-picker>
        </div>
      </div>
    </div>

    <div *ngIf="showProfileMaterialSelector">
      <item-picker
        [items]="availableProfileMaterials"
        [(selectedItem)]="selectedProfileMaterial"
        [name]="translate('consumer_doors_profile_mat', 'Profile Material')"
      >
      </item-picker>
    </div>

    <div *ngIf="showRailsetSelector">
      <item-picker
        [items]="availableRailSets"
        [(selectedItem)]="selectedRailSet"
        [name]="translate('consumer_doors_railset', 'Rail Set')"
      >
      </item-picker>
    </div>

    <div *ngIf="showRailMaterials">
      <item-picker
        [items]="availableRailMaterials"
        [(selectedItem)]="selectedRailMaterial"
        [name]="translate('consumer_doors_rail_mat', 'Rail Colour')"
      >
      </item-picker>
    </div>

    <div *ngIf="showUseGalleryLamps">
      <label class="radio-check-label">
        <input type="checkbox" [(ngModel)]="useGalleryLamps" />
        <span class="checkmark"></span>
        <span
          [textContent]="translate('consumer_doors_gallery_lamp', 'Gallery Lamp')"
        ></span>
      </label>
    </div>

    <div *ngIf="showUseSoftClose">
      <label class="radio-check-label">
        <input type="checkbox" [(ngModel)]="useSoftclose" />
        <span class="checkmark"></span>
        <span
          [textContent]="translate('consumer_doors_soft_close', 'Soft Close')"
        ></span>
      </label>
    </div>
  </div>

  <div class="consumer-main">
    <d3-display
      [cabinetSection]="cabinetSection"
      [showDoors]="true"
      [showInterior]="true"
      [showOtherCabinets]="false"
      [showCorpus]="true"
      [showRulers]="'toggle'"
      [showUndoRedo]="true"
      [allowMoveInterior]="false"
    ></d3-display>
  </div>

  <div class="consumer-bottom">
    <div class="btn-group prev-next-btns">
      <button class="btn btn-secondary btn-prev" (click)="previous()">
        {{translate('consumer_btn_prev', 'Back')}}
      </button>

      <button (click)="next()" class="btn btn-primary btn-next">
        {{translate('consumer_btn_next', 'Next')}}
      </button>
    </div>

    <consumer-price [floorPlan]="floorPlan"></consumer-price>
  </div>

  <consumer-logo></consumer-logo>
</div>
