import { LogService } from 'app/ng/log.service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as App from 'app/ts/app';
import * as Exception from 'app/ts/exceptions';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  public static readonly maxErrorsReportedPerSession = 20;

  private numErrors = 0;

  constructor(private readonly $http: PromisingBackendService) {}

  public async reportError(
    errorName: string,
    error: any,
    extraInfo?: any,
  ): Promise<number | undefined> {
    try {
      if (error instanceof Exception.CancelException) {
        return;
      }

      let stack: string | undefined = undefined;
      if (!error) {
        stack = new Error().stack;
      } else if (
        error instanceof Exception.ServerException ||
        error instanceof Exception.AuthenticationException
      ) {
        stack = error.exception.stack;
      } else if (typeof (<any>error).stack === 'string') {
        stack = (<any>error).stack;
      }

      if (!stack) {
        try {
          stack =
            '-- No client stack trace. Full error:\r\n' + JSON.stringify(error);
        } catch (serializeError) {
          stack = '-- No client stack trace. Error not serializable --';
        }
      }

      let clientError: Interface_DTO.ClientError = {
        Name: errorName,
        StackTrace: stack,
        Optionals: 'no optionals',
        Path: window.location.href,
        ServerErrorId: null,
      };
      let optionals: any = {};
      let navigatorProps = [
        'platform',
        'vendor',
        'product',
        'languages',
        'onLine',
        'userAgent',
        'maxTouchPoints',
        'cookieEnabled',
        'doNotTrack',
        'deviceMemory',
      ];
      for (let navigatorProp of navigatorProps) {
        optionals['navigator_' + navigatorProp] = (<any>window.navigator)[
          navigatorProp
        ];
      }

      //Deperecated and not supported on all browsers.
      //Should be changed to new API - performance.measureUserAgentSpecificMemory() (EXPERIMENTAL)
      try {
        optionals.window_performance_memory_usedJSHeapSize = (<any>(
          window.performance
        )).memory.usedJSHeapSize;
        optionals.window_performance_memory_jsHeapSizeLimit = (<any>(
          window.performance
        )).memory.jsHeapSizeLimit;
      } catch (e) {}

      optionals.App_started = App && App.started && App.started.toISOString();
      if (error instanceof Exception.ServerException) {
        optionals.ServerError = error.serverError;
        clientError.ServerErrorId = error.serverError.ErrorId;
      }
      if (error instanceof Error) {
        optionals.errorType = error.message;
      }
      if (error instanceof Exception.AuthenticationException) {
        optionals.cookie = error.cookieString;
      }
      if (typeof error === 'string') {
        optionals.errorString = error;
      }
      if (extraInfo) {
        optionals.extraInfo = extraInfo;
      }
      let errorClassName =
        (error && error.constructor && (<any>error.constructor).name) ||
        undefined;
      if (errorClassName) {
        optionals.errorClassName = errorClassName;
      }

      if (optionals.errorType === 'Request was cancelled') {
        return undefined;
      }

      try {
        clientError.Optionals = JSON.stringify(optionals);
      } catch (e: any) {
        clientError.Optionals = 'optionals not serializable';
      }

      try {
        this.numErrors++;
        if (this.numErrors > ErrorService.maxErrorsReportedPerSession) {
          throw new Error('Max error reports exceeded');
        }
        const errorId = await this.$http.put<number>('api/error', clientError, {
          timeout: 4000,
        });
        //.then(request => request.data);
        return errorId;
      } catch (e: any) {
        console.error('Failed to report error to server ', e, clientError);
        return undefined;
      }
    } catch (e: any) {
      //Nothing
      return undefined;
    }
  }

  public static getExceptionHandler(
    $log: LogService,
    errorService: ErrorService,
  ) {
    return async (exception: any, cause: any) => {
      let errorId: number | undefined = undefined;
      try {
        errorId = await errorService.reportError(
          'uncaughtException',
          exception,
          { cause: cause },
        );
      } catch (e: any) {
        $log.error('exception while logging error to server: ', e);
      }
      if (errorId !== undefined) {
        $log.error('KA Error ID: ' + errorId);
      }
      if (App.useDebug) {
        $log.error(exception, cause);
      }
    };
  }
}
