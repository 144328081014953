import * as Interface_Enums from 'app/ts/Interface_Enums';

export class VariantTypeNumbers {
  static readonly FrameMaterial = '1';
  static readonly NormalMaterial = '2';
  static readonly GripMaterial = '68';

  static readonly materialTypeDict: Readonly<{
    [variantTypeNumber: string]: Interface_Enums.MaterialType | undefined;
  }> = {
    [VariantTypeNumbers.NormalMaterial]: Interface_Enums.MaterialType.Normal,
    [VariantTypeNumbers.FrameMaterial]: Interface_Enums.MaterialType.Frame,
    [VariantTypeNumbers.GripMaterial]: Interface_Enums.MaterialType.Grip,
  };
}
