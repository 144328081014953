<h3 (click)="isContentVisible = !isContentVisible; $event.preventDefault()">
  <span
    *ngIf="!isContentVisible"
    class="bi bi-plus-lg collapse-indicator collapse-indicator-collapsed"
  ></span>
  <span
    *ngIf="isContentVisible"
    class="bi bi-dash-lg collapse-indicator collapse-indicator-open"
  ></span>
  <div class="text">
    {{ text }}
  </div>
</h3>
<ng-content *ngIf="isContentVisible"></ng-content>
