<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('modal_item_depth_title', 'Change item depth')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
    <!-- TODO set title -->
  </div>
  <div class="modal-body">
    <form (ngSubmit)="ok()">
      <div class="input-group">
        <span
          class="input-group-text"
          [textContent]="translate('modal_item_depth_new_depth', 'Depth')"
        ></span>
        <input
          type="number"
          min="0"
          [(ngModel)]="newDepth"
          class="form-control"
          autofocus
          name="depth"
        />
        <span class="input-group-text">mm</span>
      </div>
      <label>
        <input
          type="checkbox"
          [(ngModel)]="overrideItemLock"
          name="overrideItemLock"
        />
        <span
          [textContent]="translate('modal_item_depth_override_lock', 'Override item lock')"
        ></span>
      </label>
    </form>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="ok()"
        [textContent]="translate('button_save', 'Save')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
