import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as ArrayHelper from 'app/ts/util/ArrayHelper';
import { LoginService } from '@Services/LoginService';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { SwingTemplate } from 'app/ts/clientDto/SwingTemplate';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Inject, Injectable } from '@angular/core';
import {
  UseFullCatalog,
  UseFullCatalogInjector,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import { BehaviorSubject, Observable, firstValueFrom, takeWhile } from 'rxjs';
import { ProductLineIds } from '../InterfaceConstants';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import * as ProductLineConstants from '../ProductLineConstants';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private _folders: Interface_DTO.Folder[] = [];
  public get folders(): Interface_DTO.Folder[] {
    return this._folders;
  }

  public get salesTypes(): Interface_DTO.SalesType[] {
    return this._salesTypes;
  }
  private _salesTypes: Interface_DTO.SalesType[] = [];

  public get salesTypes$(): Observable<Interface_DTO.SalesType[]> {
    return this._salesTypes$;
  }
  private _salesTypes$ = new BehaviorSubject<Interface_DTO.SalesType[]>([]);

  private _salesChainShippingAgents$ = new BehaviorSubject<
    Interface_DTO.SalesChainShippingAgent[]
  >([]);
  public get salesChainShippingAgents$(): Observable<
    Interface_DTO.SalesChainShippingAgent[]
  > {
    return this._salesChainShippingAgents$;
  }

  private _users$ = new BehaviorSubject<Interface_DTO.User[]>([]);
  public get users$(): Observable<Interface_DTO.User[]> {
    return this._users$;
  }

  private _languages$ = new BehaviorSubject<Readonly<Interface_DTO.Language>[]>(
    [],
  );
  public get languages$(): Observable<Readonly<Interface_DTO.Language>[]> {
    return this._languages$;
  }

  public readonly floorPlanProducts: { [id: number]: Client.FloorPlanProduct } =
    {
      102: {
        name: 'Window',
        size: { X: 100, Y: 1300 },
        displayOffset: { X: -75, Y: 0 },
        canFlipDirection: false,
        canFlipSide: false,
        canEditWidth: true,
        positioning: Client.Positioning.OnWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: null,
        itemType: Interface_DTO_FloorPlan.ItemType.Window,
        picturePath: 'staticAssets/images/floorplan-products/window.jpg',
      },
      101: {
        name: 'Door',
        size: { X: 900, Y: 900 },
        displayOffset: { X: 0, Y: 0 },
        imageUrl: 'staticAssets/images/floorplan-products/door-topdown.png',
        canFlipDirection: true,
        canFlipSide: true,
        canEditWidth: true,
        positioning: Client.Positioning.OnWall,
        depthFollowsLength: true,
        spawnable: true,
        cabinetType: null,
        itemType: Interface_DTO_FloorPlan.ItemType.Door,
        picturePath: 'staticAssets/images/floorplan-products/door.jpg',
      },

      0: {
        name: 'Doors',
        size: { X: 250, Y: 3000 },
        displayOffset: { X: 0, Y: 0 },
        canFlipDirection: false,
        canFlipSide: false,
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.Doors,
        itemType: Interface_DTO_FloorPlan.ItemType.CabinetDoors,
        picturePath: 'staticAssets/images/floorplan-products/doors.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowDoorOnlyConfiguration,
      },
      1: {
        name: 'Cabinet',
        size: { X: 680, Y: 3000 },
        displayOffset: { X: 0, Y: 0 },
        canFlipDirection: false,
        canFlipSide: false,
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.SingleCabinet,
        itemType: Interface_DTO_FloorPlan.ItemType.SingleCabinet,
        picturePath: 'staticAssets/images/floorplan-products/cabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowSingleCabinetConfiguration,
      },
      2: {
        name: 'L-shaped cabinet',
        size: { X: 2500, Y: 2500 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.CornerCabinet,
        itemType: Interface_DTO_FloorPlan.ItemType.CornerCabinet,
        picturePath: 'staticAssets/images/floorplan-products/l-cabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowCornerCabinetConfiguration,
      },
      3: {
        name: 'U-shaped cabinet',
        size: { X: 2000, Y: 3500 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.WalkIn,
        itemType: Interface_DTO_FloorPlan.ItemType.WalkIn,
        picturePath: 'staticAssets/images/floorplan-products/u-cabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowWalkInConfiguration,
      },
      5: {
        name: 'Swing',
        size: { X: 680, Y: 3000 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.Swing,
        itemType: Interface_DTO_FloorPlan.ItemType.Swing,
        picturePath: 'staticAssets/images/floorplan-products/swing.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowSingleCabinetConfiguration,
      },
      15: {
        name: 'SwingFlex cabinet',
        size: { X: 680, Y: 3000 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.SwingFlex,
        itemType: Interface_DTO_FloorPlan.ItemType.SwingFlex,
        picturePath:
          'staticAssets/images/floorplan-products/swingFlex_singlecabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowSingleCabinetConfiguration,
      },
      16: {
        name: 'SwingFlex corner cabinet',
        size: { X: 680, Y: 3000 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.SwingFlexCornerCabinet,
        itemType: Interface_DTO_FloorPlan.ItemType.SwingFlexCornerCabinet,
        picturePath:
          'staticAssets/images/floorplan-products/swingFlex_cornercabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowCornerCabinetConfiguration,
      },
      17: {
        name: 'SwingFlex U-shaped cabinet',
        size: { X: 680, Y: 3000 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.AlongWall,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.SwingFlexUShapedCabinet,
        itemType: Interface_DTO_FloorPlan.ItemType.SwingFlexUShapedCabinet,
        picturePath:
          'staticAssets/images/floorplan-products/swingFlex_ushapedcabinet.jpg',
        visibilitySettingKey:
          Interface_Enums.SalesChainSettingKey.AllowWalkInConfiguration,
      },
      500: {
        name: 'Partition',
        size: { X: 44, Y: 1500 },
        canFlipDirection: false,
        canFlipSide: false,
        displayOffset: { X: 0, Y: 0 },
        canEditWidth: false,
        positioning: Client.Positioning.InsideRoom,
        depthFollowsLength: false,
        spawnable: true,
        cabinetType: Interface_Enums.CabinetType.Partition,
        itemType: Interface_DTO_FloorPlan.ItemType.Partition,
        picturePath: 'staticAssets/images/floorplan-products/roomdivider.jpg',
      },
    };

  private _editorAssets$ = new BehaviorSubject<Client.EditorAssets>(
    this.getEmptyEditorAssets(),
  );

  public get editorAssets$(): Observable<Client.EditorAssets> {
    return this._editorAssets$;
  }

  /**
   * Returns the current value from editorAssets$
   */
  public get editorAssets(): Client.EditorAssets {
    return this._editorAssets$.value;
  }

  constructor(
    private readonly $http: PromisingBackendService,
    private readonly loginService: LoginService,
    private readonly translationService: TranslationService,
    private data: FunctionalCoreService,
    @Inject(UseFullCatalogInjector)
    private readonly useFullCatalog: UseFullCatalog,
  ) {
    useFullCatalog.subscribe((val) => {
      let newAssets: Client.EditorAssets = {
        ...this.editorAssets,
        fullCatalog: val,
      };
      this._editorAssets$.next(newAssets);
    });
  }

  public async loadEditorAssets(): Promise<Client.EditorAssets | null> {
    if (this.data.ambient.activeUser.activeUser) {
      const languages =
        await this.$http.get<Interface_DTO.Language[]>('api/language');
      this._languages$.next(languages);

      const users = await this.$http.get<Interface_DTO.User[]>('api/user');
      this._users$.next(users);

      const salesChainShippingAgents = await this.$http.get<
        Interface_DTO.SalesChainShippingAgent[]
      >('api/salesChainShippingAgent');
      this._salesChainShippingAgents$.next(salesChainShippingAgents);
    } else {
      return null;
    }

    const assetsPromise = this.$http
      .get<Interface_DTO.EditorAssets>('api/editorAssets')
      .then((assets) =>
        this.mapEditorAssets(
          assets,
          this.useFullCatalog.data,
          this.loginService.chainSettings,
          this.loginService.salesChainSettings,
        ),
      );

    this.data.ambient.userData.subscribeUserData(async (user) => {
      if (this.data.ambient.userData.userData) {
        let folders = (
          await this.$http.get<Interface_DTO.Folder[]>('api/folder')
        ).map((folder) => this.setPathOnFolder(folder));
        this._folders = folders;

        const salesTypes =
          await this.$http.get<Interface_DTO.SalesType[]>('api/salesType');
        this._salesTypes = salesTypes;
        this._salesTypes$.next(salesTypes);
      }
    });

    let assets = await assetsPromise;
    this.floorPlanProducts[500].size.Y =
      assets.productLines.find((pl) => pl.Id == ProductLineIds.Partition)
        ?.DefaultWidth ?? 1500;
    this._editorAssets$.next(assets);

    this._languages$.next(this._languages$.value);

    return assetsPromise;
  }

  private setPathOnFolder(folder: Interface_DTO.Folder): Interface_DTO.Folder {
    return {
      ...folder,
      Subfolders: folder.Subfolders.map((subFolder) =>
        this.setPathOnFolder(subFolder),
      ),
      Links: folder.Links.map((link) => {
        return {
          ...link,
          Url: AssetService.prefixPath(link.Url),
        };
      }),
    };
  }

  private mapMaterial(
    mat: Interface_DTO.Material,
    dtoMatGroups: { [id: number]: Interface_DTO.MaterialGroup },
  ): Client.Material {
    mat = ObjectHelper.copy(mat);
    mat.ImagePath = AssetService.prefixPath(mat.ImagePath);
    return new Client.Material(
      mat,
      dtoMatGroups[mat.MaterialGroupId],
      dtoMatGroups[mat.DesignMaterialGroupId],
    );
  }
  private mapProduct(
    products: Interface_DTO.Product[],
    materialDict: { [id: number]: Client.Material },
    variantDict: { [id: number]: Interface_DTO.Variant },
    priceDict: { [id: number]: Interface_DTO.ProductMinimumPrice },
  ): Client.Product[] {
    let productGroups = Enumerable.from(products)
      .select((dtoProd) => dtoProd.ProductGroupingNo)
      .distinct()
      .toDictionary(
        (productGroupingNo) => productGroupingNo,
        (productGroupingNo) => {
          return <Client.Product[]>[];
        },
      );
    return products.map((dtoProduct) => {
      dtoProduct = ObjectHelper.copy(dtoProduct);
      for (let pd of dtoProduct.ProductDataList) {
        pd.PicturePath = AssetService.prefixPath(pd.PicturePath);
        pd.Model2DPath = AssetService.prefixPath(pd.Model2DPath);
      }
      let productGroup =
        dtoProduct.ProductGroupingNo === ''
          ? []
          : productGroups.get(dtoProduct.ProductGroupingNo);
      let clientProduct = new Client.Product(
        dtoProduct,
        variantDict,
        dtoProduct.PossibleMaterialIds.map(
          (matId) =>
            new Client.ProductMaterial(
              materialDict[matId.Id],
              matId,
              dtoProduct.Id,
            ),
        ),
        productGroup,
        priceDict[dtoProduct.Id],
      );
      productGroup.push(clientProduct);
      return clientProduct;
    });
  }
  private mapProductGroups(products: Client.Product[]) {
    let productGroups: { [productGroupingNo: string]: Client.Product[] } = {};
    for (let prod of products) {
      let group = productGroups[prod.ProductGroupingNo];
      if (!group) {
        group = [];
        productGroups[prod.ProductGroupingNo] = group;
      }
      group.push(prod);
    }
    for (let groupNo in productGroups) {
      productGroups[groupNo] = productGroups[groupNo].sort(
        (a, b) => a.SortOrder - b.SortOrder,
      );
    }
    return productGroups;
  }

  private mapCorpusPanel(
    dtoPanel: Interface_DTO.CorpusPanel,
    productsDict: { [id: number]: Client.Product },
    variantDict: { [id: number]: Interface_DTO.Variant },
  ): Client.CorpusPanel {
    return new Client.CorpusPanel(
      dtoPanel,
      dtoPanel.CorpusPanelProducts.filter(
        (panelProduct) => productsDict[panelProduct.ProductId] !== undefined,
      ).map((panelProduct) => productsDict[panelProduct.ProductId]),
      dtoPanel.LightingProducts.filter(
        (lightingProduct) =>
          productsDict[lightingProduct.ProductId] !== undefined,
      ).map(
        (lightingProduct) =>
          new Client.LightingProduct(
            productsDict[lightingProduct.ProductId],
            variantDict,
            lightingProduct.SpotType,
          ),
      ),
    );
  }

  private mapRailSet(
    doorRailSet: Interface_DTO.DoorRailSet,
    productsDict: { [id: number]: Client.Product },
  ): Client.RailSet {
    return new Client.RailSet(
      doorRailSet,
      doorRailSet.TopProductId === null
        ? null
        : productsDict[doorRailSet.TopProductId] || null,
      doorRailSet.BottomProductId === null
        ? null
        : productsDict[doorRailSet.BottomProductId] || null,
    );
  }

  private mapProductCategories(
    dtoCategories: Interface_DTO.ProductCategory[],
    products: Client.Product[],
  ): Client.ProductCategory[] {
    let productLookup = Enumerable.from(products)
      .where(
        (prod) =>
          prod.ProductGroupingNo === prod.ProductNo ||
          prod.ProductNo === '' ||
          prod.ProductGroupingNo === '',
      )
      .toLookup((prod) => prod.ProductType);

    return dtoCategories.map(
      (dtoProdCat) =>
        new Client.ProductCategory(dtoProdCat, productLookup, false),
    );
  }

  private mapProductCategories3(
    dtoCategories: Interface_DTO.ProductCategory[],
    products: Client.Product[],
    productLines: Interface_DTO.ProductLine[],
  ): { [productLineId: number]: Client.ProductCategory[] } {
    let result: { [productLineId: number]: Client.ProductCategory[] } = {};
    for (let productLine of productLines) {
      let productLookup = Enumerable.from(products)
        .where((prod) => prod.ProductLineIds.indexOf(productLine.Id) >= 0)
        .where(
          (prod) =>
            prod.ProductGroupingNo === prod.ProductNo ||
            prod.ProductNo === '' ||
            prod.ProductGroupingNo === '',
        )
        .toLookup((prod) => prod.ProductType);

      let categories = dtoCategories
        .filter(
          (dtoProdCat) =>
            dtoProdCat.Number !==
            Constants.ExtraAccessoriesProductCategoryNumber,
        )
        .map(
          (dtoProdCat) =>
            new Client.ProductCategory(dtoProdCat, productLookup, true),
        );
      result[productLine.Id] = categories;
    }
    return result;
  }

  private mapProductCategories2(
    dtoCategories: Interface_DTO.ProductCategory[],
    products: Client.Product[],
    productLines: Interface_DTO.ProductLine[],
    pctes: Interface_DTO.ProductConfigurationTypeExclusion[],
  ): {
    [productLineId: number]: {
      [cabinetType: number]: Client.ProductCategory[];
    };
  } {
    let cabinetTypeExclusionDict: {
      [productNo: string]: Interface_Enums.CabinetType[];
    } = {};

    for (let excl of pctes) {
      if (!excl.ProductNo) continue;
      let list = cabinetTypeExclusionDict[excl.ProductNo];
      if (!list) {
        list = [];
        cabinetTypeExclusionDict[excl.ProductNo] = list;
      }
      list.push(excl.CabinetType);
    }

    let result: {
      [productLineId: number]: {
        [cabinetType: number]: Client.ProductCategory[];
      };
    } = {};
    for (let productLine of productLines) {
      result[productLine.Id] = {};

      for (let cabinetType of [
        Interface_Enums.CabinetType.CornerCabinet,
        Interface_Enums.CabinetType.Doors,
        Interface_Enums.CabinetType.SingleCabinet,
        Interface_Enums.CabinetType.WalkIn,
        Interface_Enums.CabinetType.WalkThrough,
        Interface_Enums.CabinetType.SharedItems,
        Interface_Enums.CabinetType.Swing,
        Interface_Enums.CabinetType.SwingFlex,
      ]) {
        let productLookup = Enumerable.from(products)
          .where(
            (prod) =>
              !cabinetTypeExclusionDict[prod.ProductNo] ||
              cabinetTypeExclusionDict[prod.ProductNo].indexOf(cabinetType) < 0,
          )
          .where((prod) => prod.ProductLineIds.indexOf(productLine.Id) >= 0)
          .where(
            (prod) =>
              prod.ProductGroupingNo === prod.ProductNo ||
              prod.ProductNo === '' ||
              prod.ProductGroupingNo === '',
          )
          .toLookup((prod) => prod.ProductType);

        let cliProdCats = dtoCategories
          .filter(
            (dtoProdCat) =>
              dtoProdCat.Number !==
              Constants.ExtraAccessoriesProductCategoryNumber,
          )
          .map(
            (dtoProdCat) =>
              new Client.ProductCategory(dtoProdCat, productLookup, false),
          );

        for (let cat of cliProdCats) {
          this.includeInNumber(cat, cliProdCats);
        }

        result[productLine.Id][cabinetType] = cliProdCats;
      }
    }
    return result;
  }

  private includeInNumber(
    cat: Client.ProductCategory,
    otherCats: Client.ProductCategory[],
  ) {
    if (cat.IncludeInNumber > 0) {
      this.includeInNumber2(cat, otherCats);
    }

    for (let childCat of cat.Children) {
      this.includeInNumber(childCat, otherCats);
    }
  }

  private includeInNumber2(
    cat: Client.ProductCategory,
    otherCats: Client.ProductCategory[],
  ): boolean {
    if (cat.IncludeInNumber > 0) {
      for (let targetCat of otherCats) {
        if (targetCat.Number === cat.IncludeInNumber) {
          targetCat.products.push(...cat.products);
          targetCat.hasMultipleParents = true;
          return true;
        }
        if (this.includeInNumber2(cat, targetCat.Children)) {
          return true;
        }
      }
    }
    return false;
  }

  private createDoorGripMap(mapItems: Interface_DTO.DoorGripMapItem[]): {
    [doorProductId: number]: { [variantNumber: string]: number };
  } {
    let map: { [doorProductId: number]: { [variantNumber: string]: number } } =
      {};

    let enumerableMap = Enumerable.from(mapItems);
    let productIds = enumerableMap
      .select((mapItem) => mapItem.DoorProductId)
      .distinct()
      .toArray();
    for (let doorProductId of productIds) {
      map[doorProductId] = {};

      let mapData = enumerableMap
        .where((mapItem) => mapItem.DoorProductId === doorProductId)
        .toArray();
      for (let item of mapData) {
        map[doorProductId][item.VariantOptionNumber] = item.GripProductId;
      }
    }
    return map;
  }

  private mapProductLine(
    dtoProductLine: Interface_DTO.ProductLine,
  ): Client.ProductLine {
    const plProps =
      ProductLineConstants.productLinePropertiesByProductLineId[
        dtoProductLine.Id
      ] ?? {};
    return {
      ...dtoProductLine,
      Properties: {
        ...ProductLineConstants.defaultProductLineProperties,
        ...plProps,
      },
    };
  }

  private static prefixPath(path: string): string;
  private static prefixPath(path: string | null): string | null;
  private static prefixPath(path: string | null): string | null {
    if (!path) return path;

    let absolutePath = /^(http:|https:|\/\/|ftp:|mailto:|www\.)/;
    if (absolutePath.test(path)) return path;
    return './staticAssets' + path;
  }

  private mapEditorAssets(
    dtoEditorAssets: Interface_DTO.EditorAssets | null,
    fullCatalog: boolean,
    chainSettings: { [chainSettingKey: number]: string | undefined },
    salesChainSettings: { [salesChainSettingKey: number]: string | undefined },
  ): Client.EditorAssets {
    if (!dtoEditorAssets) {
      return this.getEmptyEditorAssets();
    }

    let materialGroupsDict = this.listToDict(
      dtoEditorAssets.MaterialGroups,
      (mg) => mg.Id,
    );
    let materials = dtoEditorAssets.Materials.map((mat) =>
      this.mapMaterial(mat, materialGroupsDict),
    );
    let materialsDict = this.listToDict(materials, (mat) => mat.Id);
    let variantsDict = this.listToDict(
      dtoEditorAssets.Variants,
      (vari) => vari.Id,
    );
    let pricesDict = this.listToDict(
      dtoEditorAssets.Prices,
      (price) => price.ProductId,
    );
    let products = this.mapProduct(
      dtoEditorAssets.Products,
      materialsDict,
      variantsDict,
      pricesDict,
    );
    let productsDict = this.listToDict(products, (p) => p.Id);
    let productNosDict = ArrayHelper.toDict(products, (p) => p.ProductNo);
    let corpusPanels = dtoEditorAssets.CorpusPanels.map((cp) =>
      this.mapCorpusPanel(cp, productsDict, variantsDict),
    );
    let railSets = dtoEditorAssets.DoorRailSets.map((rs) =>
      this.mapRailSet(rs, productsDict),
    );
    let productCategories = this.mapProductCategories(
      dtoEditorAssets.ProductCategories,
      products,
    );
    let swingModuleProducts = dtoEditorAssets.NavTemplates.map(
      (navTemplate) =>
        new Client.SwingModuleProduct(
          navTemplate,
          productNosDict[navTemplate.ProductNo],
        ),
    );
    let swingModuleProductsByModule = ArrayHelper.toLookup(
      swingModuleProducts,
      (smp) => smp.TemplateNo,
    );
    let swingModuleSetups = dtoEditorAssets.SwingModuleSetups.map(
      (sms) =>
        new Client.SwingModuleSetup(
          sms,
          productNosDict[sms.ModuleProductNo],
          ArrayHelper.toLookup(
            swingModuleProductsByModule[sms.ModuleProductNo],
            (nt) => nt.Placement,
          ),
        ),
    );
    let interiorProductCategories = this.mapInteriorProductCategory(
      dtoEditorAssets.InteriorProductCategories,
      productsDict,
    );

    return {
      storeId: dtoEditorAssets.StoreId,
      productLines: dtoEditorAssets.ProductLines.map((pl) =>
        this.mapProductLine(pl),
      ),
      materialGroups: dtoEditorAssets.MaterialGroups,
      materialGroupsDict: materialGroupsDict,
      materials: materials,
      materialsDict: materialsDict,

      variants: dtoEditorAssets.Variants,
      variantsDict: variantsDict,
      variantsByNumber: this.listToDict(
        dtoEditorAssets.Variants,
        (variant) => variant.Number,
      ),

      products: products,
      productsDict: productsDict,
      productGroupsDict: this.mapProductGroups(products),

      corpusPanels: corpusPanels,
      corpusPanelsDict: this.listToDict(corpusPanels, (cp) => cp.Id),

      railSets: railSets,
      railSetsDict: this.listToDict(railSets, (rs) => rs.Id),
      productConfigurationTypeExclusions:
        dtoEditorAssets.productConfigurationTypeExclusions,

      productDoorData: dtoEditorAssets.ProductDoorDatas,
      productCategories: productCategories,
      productCategoriesByProductLine: this.mapProductCategories3(
        dtoEditorAssets.ProductCategories,
        products,
        dtoEditorAssets.ProductLines,
      ),
      productCategoriesByProductLineAndCabinetType: this.mapProductCategories2(
        dtoEditorAssets.ProductCategories,
        products,
        dtoEditorAssets.ProductLines,
        dtoEditorAssets.productConfigurationTypeExclusions,
      ),

      doorWidths: dtoEditorAssets.DoorWidths,
      doorGripItems: dtoEditorAssets.DoorGripMapItems,
      doorGripMap: this.createDoorGripMap(dtoEditorAssets.DoorGripMapItems),

      translationService: this.translationService,
      floorPlanProducts: this.floorPlanProducts,
      fullCatalog: fullCatalog,
      chainSettings: chainSettings,
      salesChainSettings: salesChainSettings,

      SwingModuleProducts: swingModuleProducts,
      swingModuleSetups: swingModuleSetups,
      swingTemplates: this.createSwingTemplates(
        swingModuleSetups,
        dtoEditorAssets.SwingSubModuleSetups,
      ),
      swingSubModuleSetups: dtoEditorAssets.SwingSubModuleSetups,

      interiorProductCategories: interiorProductCategories,
    };
  }

  private mapInteriorProductCategory(
    interiorProductCategories: Interface_DTO.InteriorProductCategory[],
    productsDict: { [id: number]: Client.Product },
  ): Client.InteriorProductCategory[] {
    return interiorProductCategories
      .map(
        (ipc) =>
          new Client.InteriorProductCategory(
            ipc.Id,
            ipc.Name,
            ipc.ImageUrl,
            ipc.ProductIds.map((pId) => productsDict[pId]).filter(
              (p) => !!p && !p.overrideChain,
            ),
          ),
      )
      .filter((ipc) => ipc.products.length > 0);
  }

  private createSwingTemplates(
    swingModuleSetups: Client.SwingModuleSetup[],
    swingSubModuleSetups: Interface_DTO.SwingSubModuleSetup[],
  ): SwingTemplate[] {
    //let en = Enumerable.from(swingModuleSetups);
    let ssmsLookup = Enumerable.from(swingSubModuleSetups).toLookup(
      (ssms) => ssms.MainModuleProductNo,
    );
    //let moduleDict = en.toDictionary(m => m.ModuleProductNo);
    let moduleDict = ArrayHelper.toDict(
      swingModuleSetups,
      (m) => m.ModuleProductNo,
    );
    return swingModuleSetups
      .filter((m) => m.Type === Interface_Enums.SwingModuleType.StartModule)
      .map(
        (m) =>
          new SwingTemplate(
            m,
            moduleDict[m.dto.AlternateModuleProductNo],
            ArrayHelper.toLookup(
              ssmsLookup.get(m.ModuleProductNo).toArray(),
              (ssms) => ssms.VariantOptionNumber,
              (ssms) => moduleDict[ssms.SubModuleProductNo],
            ),
          ),
      );
  }

  private listToDict<T>(
    list: T[],
    idSelector: (t: T) => number,
  ): { [id: number]: T };
  private listToDict<T>(
    list: T[],
    idSelector: (t: T) => string,
  ): { [id: string]: T };
  private listToDict<T>(list: T[], idSelector: (t: T) => any): any {
    let result: { [id: number]: T } = {};
    if (list == null) return result;

    for (let t of list) {
      let id = idSelector(t);
      result[id] = t;
    }
    return result;
  }

  private getEmptyEditorAssets(): Client.EditorAssets {
    return {
      storeId: -1,
      chainSettings: [],
      corpusPanels: [],
      corpusPanelsDict: {},
      doorGripItems: [],
      doorGripMap: {},
      doorWidths: [],
      floorPlanProducts: {},
      fullCatalog: false,
      materialGroups: [],
      materialGroupsDict: {},
      materials: [],
      materialsDict: {},
      productCategories: [],
      productCategoriesByProductLine: {},
      productCategoriesByProductLineAndCabinetType: {},
      productConfigurationTypeExclusions: [],
      productDoorData: [],
      productGroupsDict: {},
      productLines: [],
      products: [],
      productsDict: {},
      railSets: [],
      railSetsDict: {},
      salesChainSettings: [],
      translationService: this.translationService,
      variants: [],
      variantsByNumber: {},
      variantsDict: [],
      SwingModuleProducts: [],
      swingModuleSetups: [],
      swingTemplates: [],
      swingSubModuleSetups: [],
      interiorProductCategories: [],
    };
  }

  public async editorAssetsPromise(): Promise<Client.EditorAssets> {
    return firstValueFrom(this._editorAssets$.pipe(takeWhile((ea) => true)));
  }
}
