import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { FloorPlan } from 'app/ts/clientDto';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { Measurement, MeasurementData } from '../Measurement';
import { MeasurementService } from '../measurement.service';

@Component({
  selector: 'measurement-property-sheet',
  templateUrl: './measurement-property-sheet.component.html',
  styleUrls: ['./measurement-property-sheet.component.scss'],
})
export class MeasurementPropertySheetComponent {
  @Input()
  public measurements!: readonly Measurement[] | null;

  @Input()
  public floorPlan!: FloorPlan;

  @Output()
  public onDelete = new EventEmitter<void>();

  constructor(
    private measurementService: MeasurementService,
    private translateService: TranslateService,
    private floorPlanService: FloorPlanService,
  ) {}

  public get selectedMeasurement(): MeasurementData {
    return this.measurementService.selectedMeasurement!;
  }

  public orientationChanged(value: string) {
    if (value === '0')
      this.measurementService.updateMeasurementOrientation(
        this.measurementService.selectedMeasurement!.id,
        false,
      );
    else
      this.measurementService.updateMeasurementOrientation(
        this.measurementService.selectedMeasurement!.id,
        true,
      );

    this.floorPlanService.setChanged(this.floorPlan);
  }

  public requestDelete() {
    let sure = confirm(
      this.translateService.translate(
        'floorPlan_confirm_delete_question',
        'Are you sure you want to delete this?',
      ),
    );
    if (!sure) return;

    this.measurementService.deleteSelected();
    this.floorPlanService.setChanged(this.floorPlan);
    this.onDelete.emit();
  }
}
