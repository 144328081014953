import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Client from 'app/ts/clientDto/index';
import * as SnapService from 'app/ts/services/snap';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { Injectable } from '@angular/core';
import { CabinetType } from 'app/ts/Interface_Enums';

@Injectable({ providedIn: 'root' })
export class InteriorService {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  /**
   * Gets a snapInfoService, valid for the current drag
   * @param cabinetSection the section to drag into
   * @param items the items to move. May or may not be part of the cabinetSection
   * @param pos the starting position of the mouse, in the cabinet section's coordinate system
   */
  public getSnapInfoService(
    cabinetSection: Client.CabinetSection,
    items: Client.ConfigurationItem[],
    pos: Interface_DTO_Draw.Vec2d,
  ): SnapService.ISnapInfoService {
    if (items.length < 1) {
      throw new Error("Can't drag no items");
    }
    let items2 = items as [Client.ConfigurationItem];

    let bestServiceSoFar: SnapService.ISnapInfoService =
      new SnapService.DumbSnapService(cabinetSection, items2, pos);

    if (
      items.length > 1 &&
      cabinetSection.CabinetType !== CabinetType.SwingFlex
    ) {
      // snap services are arranged here from worst to best

      if (SnapService.MultipleVerticalSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.MultipleVerticalSnapService(
          cabinetSection,
          items2,
          pos,
        );
      }
      if (SnapService.MultipleHorizontalSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.MultipleHorizontalSnapService(
          this.configurationItemService,
          cabinetSection,
          items2,
          pos,
        );
      }
    }

    if (
      items.length === 1 ||
      cabinetSection.CabinetType === CabinetType.SwingFlex
    ) {
      let item = items[0];

      //snap services are arranged here from worst to best

      if (SnapService.GableSnapService.supportsItems(items)) {
        //gableSnapService has no fallback service, so it's safe to throw away the DumbSnapService
        bestServiceSoFar = new SnapService.GableSnapService(
          this.configurationItemService,
          cabinetSection,
          item,
          pos,
        );
      }
      if (SnapService.SnapLeftRightService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.SnapLeftRightService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.SnapSideService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.SnapSideService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.SnapUnderService.supportsItem(item)) {
        bestServiceSoFar = new SnapService.SnapUnderService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.SnapAboveGableService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.SnapAboveGableService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.CornerItemSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.CornerItemSnapService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.CornerItemSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.CornerItemSnapService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.FittingPanelGableSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.FittingPanelGableSnapService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      if (SnapService.VerticalDividerSnapService.supportsItems(items)) {
        bestServiceSoFar = new SnapService.VerticalDividerSnapService(
          cabinetSection,
          item,
          pos,
          bestServiceSoFar,
        );
      }
      //add more snap services here
    }

    return bestServiceSoFar;
  }
}
