import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';

export class ProductCategory {
  public readonly Children: Client.ProductCategory[];
  public readonly products: Client.Product[];
  public hasMultipleParents: boolean = false;

  constructor(
    private readonly dtoObject: Interface_DTO.ProductCategory,
    allProductsByProductType: Enumerable.ILookup<number, Client.Product>,
    createSubCategoryForTopLevelCategories: boolean,
  ) {
    this.products = allProductsByProductType
      .get(this.Number)
      .orderBy((prod) => prod.SortOrder)
      .toArray();

    for (let product of this.products) {
      product.productCategory = this;
      for (let altProduct of product.productGroup) {
        altProduct.productCategory = this;
      }
    }

    let productLineCategories: {
      [productLineId: number]: Interface_DTO.ProductLineCategory;
    } = {};
    for (let plc of dtoObject.ProductLineCategories) {
      productLineCategories[plc.ProductLineId] = plc;
    }
    this.productLineCategories = productLineCategories;

    this.Children = this.dtoObject.Children.map(
      (dtoChild) =>
        new Client.ProductCategory(
          dtoChild,
          allProductsByProductType,
          createSubCategoryForTopLevelCategories,
        ),
    );
    if (
      createSubCategoryForTopLevelCategories &&
      this.ParentCategoryId === -1 &&
      this.products.length > 0
    ) {
      let dtoSelfCopy = ObjectHelper.copy(dtoObject);
      dtoSelfCopy.ParentCategoryId = this.Id;
      let clientSelfCopy = new Client.ProductCategory(
        dtoSelfCopy,
        allProductsByProductType,
        createSubCategoryForTopLevelCategories,
      );
      this.Children.push(clientSelfCopy);
    }

    this.Children.sort((a, b) => a.Number - b.Number);
  }

  //region DTO
  get Enabled() {
    return this.dtoObject.Enabled;
  }
  get Id() {
    return this.dtoObject.Id;
  }
  get IncludeInNumber() {
    return this.dtoObject.IncludeInNumber;
  }
  get IncludeOnPieceList() {
    return this.dtoObject.IncludeOnPieceList;
  }
  get Name() {
    return this.dtoObject.Name;
  }
  get Number() {
    return this.dtoObject.Number;
  }
  get ParentCategoryId() {
    return this.dtoObject.ParentCategoryId;
  }
  //endregion DTO

  get hasChildProducts(): boolean {
    return (
      this.products.length > 0 ||
      this.Children.some((subCat) => subCat.hasChildProducts)
    );
  }

  private readonly productLineCategories: {
    [productLineId: number]: Readonly<Interface_DTO.ProductLineCategory>;
  };

  public fittingsEnabled(
    productLineId: Interface_Enums.ProductLineId,
  ): boolean {
    let settings = this.productLineCategories[productLineId];
    return settings ? settings.EnableFittings : true; //Default value in case there's no value from db
  }

  public anyProduct(
    predicate: (
      product: Client.Product,
      category: Client.ProductCategory,
    ) => boolean,
  ): boolean {
    for (var i = 0; i < this.products.length; i++) {
      let product = this.products[i];
      if (predicate(product, this)) return true;
    }
    return this.Children.some((childCategory) =>
      childCategory.anyProduct(predicate),
    );
  }

  public anyChildCategory(
    predicate: (productCategory: Client.ProductCategory) => boolean,
  ): boolean {
    for (let cat of this.Children) {
      if (predicate(cat)) return true;
    }
    return this.Children.some((cat) => cat.anyChildCategory(predicate));
  }
}
