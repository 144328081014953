import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
export class DumbSnapService implements ISnapInfoService {
  private lastSnapInfo: Client.SnapInfo | undefined;
  private readonly anyGables: boolean;
  private readonly otherItems: Client.ConfigurationItem[];

  constructor(
    private readonly cabinetSection: Client.CabinetSection,
    private readonly items: [Client.ConfigurationItem],
    private readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
  ) {
    this.anyGables = items.some((item) => item.isGable);
    this.otherItems = cabinetSection.interior.items.filter(
      (i) => items.indexOf(i) < 0,
    );
    if (cabinetSection.isSwing) {
      this.otherItems.push(
        ...cabinetSection.swing.items.filter(
          (swingItem) =>
            swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus,
        ),
      );
    }
  }

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    let dropOffset: Interface_DTO_Draw.Vec2d;
    if (this.anyGables) {
      //don't move away from floor
      dropOffset = {
        X: pos.X - this.dragStartPoint.X,
        Y: 0,
      };
    } else {
      dropOffset = VectorHelper.subtract(pos, this.dragStartPoint);
    }

    let minHeight = Math.min(
      ...this.items.map((item) => item.Y + dropOffset.Y),
    );
    let maxHeight = Math.max(
      ...this.items.map((item) => item.topY + dropOffset.Y),
    );
    let horizontalGuides = [maxHeight];
    if (minHeight > 0) horizontalGuides.push(minHeight);

    let minX = Math.min(...this.items.map((i) => i.X)) + dropOffset.X;
    let maxX = Math.max(...this.items.map((i) => i.rightX)) + dropOffset.X;
    let midX = (minX + maxX) / 2;
    let rulers: Client.Ruler[] = [];

    let otherItemsInLine = this.otherItems.filter(
      (oi) => oi.rightX > minX && oi.X < maxX,
    );

    let otherItemsBelow = otherItemsInLine.filter((oi) => oi.topY < minHeight);
    let baseY = this.cabinetSection.interior.cube.Y;

    let bottomRulerBottomY = Math.max(
      baseY,
      ...otherItemsBelow.map((oi) => oi.topY),
    );

    // Check if bottom item is edge grip. If edge grip, move ruler to attached drawer
    if (otherItemsBelow?.length > 0) {
      let bottomEdgeGripItem = Enumerable.from(otherItemsBelow).maxBy(
        (oi) => oi.topY,
      );

      if (
        bottomEdgeGripItem &&
        bottomEdgeGripItem.ProductData &&
        bottomEdgeGripItem.ProductData.Type ===
          Interface_Enums.ProductDataType.GripForDrawer
      ) {
        const drawerAttached = Enumerable.from(otherItemsBelow).firstOrDefault(
          (oi) => oi.isPullout && oi.gripProduct === bottomEdgeGripItem.Product,
        );
        if (drawerAttached && drawerAttached.gripProduct) {
          bottomRulerBottomY = drawerAttached.topY;
        }
      }
    }

    if (minHeight > baseY) {
      rulers.push(
        new Client.Ruler(
          true,
          { X: midX, Y: bottomRulerBottomY },
          minHeight - bottomRulerBottomY,
          this.cabinetSection.Height,
          false,
        ),
      );
    }

    let otherItemsAbove = otherItemsInLine.filter((oi) => oi.Y > maxHeight);
    let interiorTopY =
      this.cabinetSection.interior.cube.Y +
      this.cabinetSection.interior.cube.Height;
    let topRulerTopY = Math.min(
      interiorTopY,
      ...otherItemsAbove.map((oi) => oi.Y),
    );
    if (maxHeight < interiorTopY) {
      rulers.push(
        new Client.Ruler(
          true,
          { X: midX, Y: maxHeight },
          topRulerTopY - maxHeight,
          this.cabinetSection.Height,
          false,
        ),
      );
    }

    let z = 0;
    let firstItem = this.items[0];
    if (!!firstItem && firstItem.isDummy) {
      let depth = this.cabinetSection.InteriorDepth;
      let gables = Enumerable.from(this.cabinetSection.interior.gables).orderBy(
        (g) => g.centerX,
      );
      let leftGable = gables.lastOrDefault((g) => g.rightX < firstItem.centerX);
      if (!!leftGable) {
        depth = Math.min(depth, leftGable.Depth);
      }
      let rightGable = gables.firstOrDefault(
        (g) => g.leftX > firstItem.centerX,
      );
      if (!!rightGable) {
        depth = Math.min(depth, rightGable.Depth);
      }

      if (firstItem.snapDepthMiddle) {
        z = depth / 2 - firstItem.Depth / 2;
      } else {
        z = depth - (firstItem.Depth + firstItem.depthReduction);
      }
    }

    let result: Client.SnapInfo = {
      dropOffset: {
        ...dropOffset,
        Z: z,
      },
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: rulers,
      suggestedItemOffsets: [],
      mirror: false,
      horizontalGuidelineHeights: horizontalGuides,
      snapsTo: [],
      snappingItems: this.items,
    };
    this.lastSnapInfo = result;
    return result;
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (App.debug.showSnapInfo) {
      console.debug('Snapped by me: ', this);
    }

    if (this.lastSnapInfo) {
      for (let item of this.items) {
        item.X += this.lastSnapInfo.dropOffset.X;
        item.Y += this.lastSnapInfo.dropOffset.Y;
        item.X = Math.round(item.X);
        item.Y = Math.round(item.Y);
        item.Z = Math.round(this.lastSnapInfo.dropOffset.Z);

        if (this.cabinetSection.interior.items.indexOf(item) < 0) {
          this.cabinetSection.interior.items.push(item);
        }
      }
    }
    return { items: this.items, recalculationMessages: [] };
  }

  public dispose() {}
}
