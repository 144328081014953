<div
  class="editor-all floorplan-editor"
  [ngClass]="{'no-right-menu': !displayRightMenu}"
  *ngIf="editorType.ready"
>
  <floorplan-topbar
    [vm]="this"
    [selection]="'floorPlan'"
    [floorPlan]="floorPlan"
    [cabinet]="selectedCabinet"
  ></floorplan-topbar>
  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>
  <div class="tool-menu editor-menu left-tool-menu">
    <h3
      [textContent]="translate('floorplan_headers_other_elements', 'Other Elements')"
    ></h3>
    <div class="other-element-container">
      <!-- Measurement -->
      <svg
        (mousedown)="measurementMouseDown($event)"
        (mouseup)="productMouseUp($event)"
        (touchstart)="touchStart($event)"
        (touchmove)="touchMove($event)"
        (touchend)="touchEnd($event)"
        fill="#b328d0"
        width="100%"
        height="80px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1024.08 512.04"
        xml:space="preserve"
        stroke="#b328d0"
      >
        <g id="SVGRepo_iconCarrier">
          <g transform="translate(200, 0)">
            <path
              d="M508.933,248.353L402.267,141.687c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827 l88.427,88.427H36.4l88.427-88.427c4.053-4.267,3.947-10.987-0.213-15.04c-4.16-3.947-10.667-3.947-14.827,0L3.12,248.353 c-4.16,4.16-4.16,10.88,0,15.04L109.787,370.06c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827 L36.4,266.593h439.147L387.12,355.02c-4.267,4.053-4.373,10.88-0.213,15.04c4.053,4.267,10.88,4.373,15.04,0.213 c0.107-0.107,0.213-0.213,0.213-0.213l106.667-106.667C513.093,259.34,513.093,252.513,508.933,248.353z"
            ></path>
          </g>
        </g>
      </svg>
    </div>

    <h3
      [textContent]="translate('floorplan_product_lines', 'Product Lines')"
    ></h3>
    <pickable
      [pickable]="selectedProductLinePickable"
      (click)="selectProductLine()"
      class="product-line clickable modal-opener"
    ></pickable>

    <h3
      [textContent]="translate('floorplan_cabinet_types_header', 'Cabinet types')"
    ></h3>
    <div class="floorplan-draggables">
      <pickable
        *ngFor="let pickable of productPickables"
        [pickable]="pickable"
        (mousedown)="productMouseDown(pickable.item, $event)"
        (mouseup)="productMouseUp($event)"
        (touchstart)="touchStart($event, pickable.item)"
        (touchmove)="touchMove($event)"
        (touchend)="touchEnd($event)"
        class="product floorplan-product draggable"
      ></pickable>
    </div>
  </div>

  <div class="ribbon-menu">
    <div class="ribbon-block">
      <button
        class="btn btn-default btn-block"
        [ngClass]="{'active': showFloorPlanDetails}"
        (click)="showFloorPlanDetails = !showFloorPlanDetails"
        [textContent]="translate('floorplan_edit_dimensions', 'Edit room')"
      ></button>
    </div>
    <div class="ribbon-block">
      <div ngbDropdown *ngIf="showFullCatalogFeatures" autoClose="outside">
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('floorplan_full_catalog_features', 'Settings for full catalog')"
          ></span>
          <span class="caret"></span>
        </button>

        <ul
          class="dropdown-menu"
          ngbDropdownMenu
          style="width: 260px; padding: 8px"
        >
          <li ngbDropdownItem role="menuitem">
            <label
              [attr.title]="translate('floorplan_full_catalog_tall_cabinets_tooltip', 'Allow cabinet height above 2750mm')"
            >
              <input type="checkbox" [(ngModel)]="fullCatalogTallCabinets" />
              <span
                [textContent]="translate('floorplan_full_catalog_tall_cabinets', 'Unlimited cabinet height')"
              ></span>
            </label>
          </li>
          <li ngbDropdownItem role="menuitem">
            <label
              [attr.title]="translate('floorplan_full_catalog_wide_doors_tooltip', 'Allow wide doors')"
            >
              <input type="checkbox" [(ngModel)]="fullCatalogWideDoors" />
              <span
                [textContent]="translate('floorplan_full_catalog_wide_doors', 'Unlimited door width')"
              ></span>
            </label>
          </li>
          <li ngbDropdownItem role="menuitem">
            <label
              [attr.title]="translate('floorplan_full_catalog_mute_errors_tooltip', 'Makes it possible to order a solution even when it contains critical errors')"
            >
              <input type="checkbox" [(ngModel)]="fullCatalogMuteErrors" />
              <span
                [textContent]="translate('floorplan_full_catalog_mute_errors', 'Mute Errors')"
              ></span>
            </label>
          </li>
          <li ngbDropdownItem role="menuitem">
            <label
              [attr.title]="translate('floorplan_full_catalog_unlicensed_products_tooltip', 'Allow selection of unlicensed products, and products from other product lines')"
            >
              <input
                type="checkbox"
                [(ngModel)]="fullCatalogAllowOtherProducts"
              />
              <span
                [textContent]="translate('floorplan_full_catalog_unlicensed_products', 'Use unlicensed products')"
              ></span>
            </label>
          </li>
          <li ngbDropdownItem role="menuitem">
            <label
              [attr.title]="translate('floorplan_full_catalog_unlicensed_materials_tooltip', 'Allow selection of unlicensed materials')"
            >
              <input
                type="checkbox"
                [(ngModel)]="fullCatalogAllowOtherMaterials"
              />
              <span
                [textContent]="translate('floorplan_full_catalog_unlicensed_materials', 'Use unlicensed materials')"
              ></span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="ribbon-block" *ngIf="displayImportEndUser">
      <button
        class="btn btn-default"
        (click)="endUserImport()"
        [textContent]="translate('floorPlan_edit_import_enduser', 'Import from end user...')"
      ></button>
    </div>
    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showMeasurements" />
        {{'editor_label_show_measurements' | translate: 'Show Measurements'}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showOrigin" />
        {{'editor_label_show_partition_origin' | translate: 'Show Origin'}}
      </label>
    </div>
    <button
      class="btn btn-default"
      (click)="clearPlan()"
      [textContent]="clearPlanText"
      hidden
    ></button>

    <div class="flex-spacer"></div>
    <div class="ribbon-block right-menu-visibility-ribbon-block">
      <button
        class="btn btn-default"
        (click)="displayRightMenu = !displayRightMenu"
      >
        <span class="bi bi-chevron-left" [hidden]="!!displayRightMenu"></span>
        <span class="bi bi-chevron-right" [hidden]="!displayRightMenu"></span>
      </button>
    </div>
  </div>

  <floor-plan-image
    class="editor-center"
    [floorPlan]="floorPlan"
    [partitions]="partitions.partitionPlan$ | async | calculatePlan: floorPlan"
    [measurements]="(measurementService.measurementList$ | async) || []"
    [selectedItem$]="selectedItem$"
    [currentFloorPlanDragInfo]="currentFloorPlanDragInfo"
    [showErrorTriangles]="true"
    [showMeasurements]="showMeasurements"
    [showOrigin]="showOrigin"
    [showFloorPlanDetails$]="showFloorPlanDetails$"
    (isDragging)="isDragging = $event"
  ></floor-plan-image>
  <secret
    *ngIf="showClearPlan"
    class="editor-center"
    style="background: transparent"
  />

  <div
    class="tool-menu editor-menu right-tool-menu"
    [hidden]="!displayRightMenu"
  >
    <partition-property-sheet
      *ngIf="selectedItem?.partition"
      [floorPlan]="floorPlan"
      [partitions]="partitions.partitionPlan$ | async | calculatePlan: floorPlan"
    />

    <measurement-property-sheet
      *ngIf="selectedItem?.measurement"
      [measurements]="measurementService.measurementList$ | async"
      [floorPlan]="floorPlan"
      (onDelete)="selectedItem$.next(undefined)"
    />

    <floor-plan-properties
      *ngIf="showFloorPlanDetails"
      [(floorPlanName)]="floorPlanName"
      [(floorPlanSizeX)]="floorPlanSizeX"
      [(floorPlanSizeY)]="floorPlanSizeY"
      [(floorPlanSizeZ)]="floorPlanSizeZ"
      [corners]="corners"
    ></floor-plan-properties>

    <div *ngIf="isCabinetSelected" class="floorplan-item-details">
      <h4 [textContent]="selectedProductName" *ngIf="!selectedCabinetVm"></h4>
      <div class="form-group form-group-sm" *ngIf="selectedCabinetVm">
        <label
          for="rm-floorplan-cabinet-name"
          [textContent]="translate('floorplan_label_cabinet_name', 'Name')"
        ></label>
        <input
          type="text"
          class="form-control"
          id="rm-floorplan-cabinet-name"
          maxlength="maxCabinetNameLength"
          [(ngModel)]="selectedCabinetVm.name"
          [ngModelOptions]="modelOptions"
        />
      </div>

      <div
        class="form-group form-group-sm"
        *ngIf="selectedProduct?.canEditWidth"
      >
        <label>
          <span
            [textContent]="translate('floorplan_label_selected_item_length', 'Length')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              id="rm-floorplan-item-length"
              [(ngModel)]="selectedItem!.Width"
              (ngModelChange)="selectedItemChanged()"
            />
          </span>
        </label>
      </div>
      <label *ngIf="selectedProduct?.canFlipSide">
        <input
          type="checkbox"
          [(ngModel)]="selectedItem!.FlipSide"
          (ngModelChange)="selectedItemChanged()"
        />
        {{translate('floorplan_label_selected_item_flip_side', 'Flip side')}}
      </label>
      <br />
      <label *ngIf="selectedProduct?.canFlipDirection">
        <input
          type="checkbox"
          [(ngModel)]="selectedItem!.FlipDirection"
          (ngModelChange)="selectedItemChanged()"
        />
        {{translate('floorplan_label_selected_item_flip_direction', 'Flip
        direction')}}
      </label>
      <div>
        <button
          class="btn btn-danger btn-semi-block"
          (click)="deleteSelectedItem()"
          [textContent]="translate('floorPlan_delete_item_button', 'Delete')"
        ></button>
      </div>

      <div *ngIf="selectedCabinetVm" class="vflex">
        <button
          class="btn btn-primary btn-semi-block"
          (click)="editSelectedCabinet()"
          [textContent]="translate('floorPlan_edit_cabinet_button', 'Edit')"
        ></button>

        <h4 [textContent]="translate('floorplan_rotate_header', 'Rotate')"></h4>
        <div class="btn-group btn-group-rotate-cabinet">
          <button
            class="btn btn-default"
            (click)="selectedCabinetVm.rotateCCW()"
            [attr.title]="translate('floorPlan_rotate_ccw_title', 'Rotate counter-clockwise')"
          >
            <span class="bi bi-arrow-left-circle-fill"></span>
          </button>
          <button
            class="btn btn-default"
            (click)="selectedCabinetVm.rotateCW()"
            [attr.title]="translate('floorPlan_rotate_cw_title', 'Rotate clockwise')"
          >
            <span class="bi bi-arrow-right-circle-fill"></span>
          </button>
        </div>

        <label *ngIf="selectedCabinetVm.isMirrorable">
          <!--CabinetType.CornerCabinet-->
          {{translate('floorPlan_label_rotate_corner_cabinet', 'Rotate 90
          degrees')}}
          <input type="checkbox" [(ngModel)]="selectedCabinetVm.isMirrored" />
        </label>

        <ng-container *ngIf="selectedCabinetVm">
          <div
            *ngFor="let section of selectedCabinetVm.sections; trackBy: trackCabinetSectionByIndex"
          >
            <h4
              [textContent]="translate('floorPlan_section_header_{0}', 'Section {0}', section.cabinetSectionIndex)"
            ></h4>

            <div class="form-group form-group-sm">
              <label
                for="rm-floorplan-section-width"
                [textContent]="translate('floorplan_label_section_width', 'Width')"
              ></label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  id="rm-floorplan-section-width"
                  [value]="section.width"
                  (change)="updateSectionWidth($event, section)"
                />
                <span class="input-group-text">mm</span>
              </div>
            </div>

            <div class="form-group form-group-sm">
              <label
                for="rm-floorplan-section-height"
                [textContent]="translate('floorplan_label_section_height', 'Height')"
              ></label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  id="rm-floorplan-section-height"
                  [value]="section.height"
                  (change)="updateSectionHeight($event, section)"
                />
                <span class="input-group-text">mm</span>
              </div>
            </div>

            <div class="form-group form-group-sm">
              <label
                for="rm-floorplan_section_depth"
                [textContent]="translate('floorplan_label_section_depth', 'Depth')"
              ></label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  id="rm-floorplan_section_depth"
                  [value]="section.depth"
                  (change)="updateSectionDepth($event, section)"
                  [attr.min]="section.minDepth"
                  [attr.max]="section.maxDepth"
                  [disabled]="!section.isDepthUserAdjustable || section.minDepth >= section.maxDepth"
                />
                <span class="input-group-text">mm</span>
              </div>
            </div>
            <div
              class="form-group form-group-sm"
              *ngIf="selectedCabinetVm.showInteriorDepth"
            >
              <label
                for="rm-floorplan-section-interior-depth"
                [textContent]="translate('floorplan_label_section_interior_depth', 'Interior Depth')"
              ></label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  id="rm-floorplan-section-interior-depth"
                  [value]="section.interiorDepth"
                  (change)="updateSectionInteriorDepth($event, section)"
                  [attr.min]="section.minInteriorDepth"
                  [attr.max]="section.maxInteriorDepth"
                />
                <span class="input-group-text">mm</span>
              </div>
            </div>
            <!-- Dist to Walls -->
            <div class="row mt-3">
              <label
                class="col col-form-label pb-0"
                [textContent]="'floorplan_label_wall_distance' | translate: 'Distance to walls'"
              ></label>
            </div>
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm">
                  <input
                    #leftPosition
                    type="number"
                    class="form-control distance-spinner"
                    [value]="valueForLeft(selectedItem!, section)"
                    (change)="updateCabinetPositionLeft(leftPosition.valueAsNumber, section)"
                  />
                  <span class="input-group-text">
                    <i class="bi bi-arrow-bar-right"></i>
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="input-group input-group-sm">
                  <input
                    #rightPosition
                    type="number"
                    class="form-control distance-spinner"
                    [value]="valueForRight(selectedItem!, section)"
                    (change)="updateCabinetPositionRight(rightPosition.valueAsNumber, section)"
                  />
                  <span class="input-group-text">
                    <i class="bi bi-arrow-bar-left"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="input-group input-group-sm">
                  <input
                    #topPosition
                    type="number"
                    class="form-control distance-spinner"
                    [value]="valueForTop(selectedItem!, section)"
                    (change)="updateCabinetPositionTop(topPosition.valueAsNumber, section)"
                  />
                  <span class="input-group-text">
                    <i class="bi bi-arrow-bar-down"></i>
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="input-group input-group-sm">
                  <input
                    #bottomPosition
                    type="number"
                    class="form-control distance-spinner"
                    [value]="valueForBottom(selectedItem!, section)"
                    (change)="updateCabinetPositionBottom(bottomPosition.valueAsNumber, section)"
                  />
                  <span class="input-group-text">
                    <i class="bi bi-arrow-bar-up"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
