<!-- vm is of type EditorTopbarVm -->

<topbar
  [parent]="this"
  [allowChangeStore]="false"
  [allowChangeFullCatalog]="false"
  [floorplan]="floorPlan"
>
  <ul class="nav navbar-nav">
    <li>
      <div class="btn-group" *ngIf="canSeeOverview">
        <button
          class="btn btn-default"
          [textContent]="translate('editor_nav_back_to_overview', 'Back')"
          (click)="gotoOverview()"
        ></button>
      </div>

      <div class="btn-group">
        <a class="btn" (click)="vm.save()" [ngClass]="saveButtonClasses">
          <span class="bi bi-save2"></span>
          <span [textContent]="saveButtonText"></span>
        </a>
      </div>

      <div class="btn-group">
        <a
          class="btn btn-default"
          (click)="vm.undo()"
          [attr.title]="translate('editor_nav_undo', 'Undo')"
          [ngClass]="{'disabled': !vm.isUndoEnabled }"
        >
          <span class="bi bi-arrow-counterclockwise"></span>
        </a>

        <a
          class="btn btn-default"
          (click)="vm.redo()"
          [attr.title]="translate('editor_nav_redo', 'Redo')"
          [ngClass]="{'disabled': !vm.isRedoEnabled }"
        >
          <span class="bi bi-arrow-clockwise"></span>
        </a>
      </div>
    </li>
  </ul>
  <ol class="breadcrumb nav navbar-nav">
    <li>
      <div class="btn-group">
        <a
          [routerLink]="'/floorplan/' + floorPlanId"
          class="btn btn-default active"
          [textContent]="translate('editor_nav_floorPlan', 'Floor Plan')"
        ></a>
      </div>
    </li>

    <li *ngIf="showMainDropdown">
      <div class="btn-group" ngbDropdown [open]="cabinetSelectorOpen">
        <button
          class="btn btn-default"
          type="button"
          ngbDropdownToggle
          *ngIf="cabinet"
          [ngClass]="'errorLevel-' + cabinet.maxErrorLevel"
        >
          <span [textContent]="cabinet.Name"></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <button
          class="btn btn-default"
          type="button"
          ngbDropdownToggle
          *ngIf="!cabinet"
        >
          <span
            [textContent]="translate('cabinet_selector_select_cabinet', 'Select Cabinet')"
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>

        <div class="dropdown-menu cabinet-dropdown" ngbDropdownMenu role="menu">
          <button
            role="menuitem"
            ngbDropdownItem
            *ngFor="let cabinet of cabinets"
          >
            <div
              [ngClass]="'errorLevel-' + cabinet.maxErrorLevel"
              [routerLink]="cabinet.path"
            >
              <a [textContent]="cabinet.name"></a>
            </div>
          </button>
        </div>
      </div>
    </li>

    <li *ngIf="cabinet &amp;&amp; sections">
      <div class="btn-group" ngbDropdown [open]="sectionSelectorOpen">
        <button
          class="btn btn-default"
          type="button"
          [ngClass]="'errorLevel-' + cabinet.maxErrorLevel"
          ngbDropdownToggle
        >
          <span [textContent]="selectedSectionName"></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>

        <ul
          class="dropdown-menu cabinet-section-dropdown"
          ngbDropdownMenu
          role="menu"
        >
          <li role="menuitem" ngbDropdownItem *ngFor="let section of sections">
            <a
              [ngClass]="'errorLevel-' + section.maxErrorLevel"
              [routerLink]="section.path"
            >
              <img [src]="section.imageUrl" />
              <span [textContent]="section.name"></span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li *ngIf="paths">
      <div class="btn-group editor-select-big big-only" role="group">
        <a
          class="btn btn-default editor-section-link logo floorplan-errorlevel-cell floorplan-errorlevel-cell-{{path.errorLevel}}"
          [attr.error-level]="path.errorLevel"
          [attr.section-id]="path.id"
          [ngClass]="{'active': path.active }"
          *ngFor="let path of paths"
          [routerLink]="path.path"
        >
          <span
            class="editor-section-name"
            [textContent]="translate('editor_nav_' + path.id, path.defaultName)"
          ></span>
          <span *ngIf="path.id == 'piecelist'" class="bi bi-cart3"></span>
        </a>
      </div>

      <div class="editor-select-small small-only btn-group" ngbDropdown>
        <button
          class="btn btn-default editor-section-link"
          type="button"
          [attr.section-id]="selection"
          ngbDropdownToggle
        >
          <span
            class="editor-section-name"
            [textContent]="translate('editor_nav_' + selection, selection)"
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <ul class="dropdown-menu editor-dropdown" ngbDropdownMenu role="menu">
          <li
            role="menuitem"
            *ngFor="let path of paths"
            [ngClass]="{'active': path.active}"
          >
            <a
              [routerLink]="path.path"
              class="editor-section-link"
              [attr.section-id]="path.id"
              [ngClass]="'errorLevel-' + path.errorLevel"
            >
              <span
                class="editor-section-name"
                [textContent]="translate('editor_nav_' + path.id, path.defaultName)"
              ></span>
              <span *ngIf="path.id == 'piecelist'" class="bi bi-cart3"></span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ol>

  <ul class="nav navbar-nav">
    <li *ngIf="showPrice">
      <a
        class="price-with-vat price"
        [attr.title]="translate('editor_nav_total_price_title', 'Total price including VAT')"
      >
        <span class="currency" [textContent]="priceCurrency + ' '"></span>
        <span class="amount" [textContent]="price"></span>
      </a>
    </li>
  </ul>
</topbar>
