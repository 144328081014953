import { Material, Product, RailSet } from 'app/ts/clientDto';
import { ModuleId } from '../module';
import { Profile } from '../profile';
import { SectionId } from '../section';
import { Door } from './door';
import { Wall } from './wall';

export class Section {
  constructor(
    private _id: SectionId,
    private _posX: number,
    private _posY: number,
    private _rotation: number,
    private _width: number,
    private _height: number,
    private _unevenLeftWall: boolean,
    private _unevenRightWall: boolean,
    private _profileProduct: Product,
    private _profileMaterial: Material,
    private _railSet: RailSet,
    private _walls: Wall[],
    private _doors: Door[],

    private _hasError: boolean,
  ) {}

  public get id(): SectionId {
    return this._id;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get rotation(): number {
    return this._rotation;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get unevenLeftWall(): boolean {
    return this._unevenLeftWall;
  }

  public get unevenRightWall(): boolean {
    return this._unevenRightWall;
  }

  public get profileProduct(): Product {
    return this._profileProduct;
  }

  public get profileMaterial(): Material {
    return this._profileMaterial;
  }

  public get railSet(): RailSet {
    return this._railSet;
  }

  public get walls(): Wall[] {
    return this._walls;
  }

  public get doors(): Door[] {
    return this._doors;
  }

  public get hasError(): boolean {
    return this._hasError;
  }

  public get numberOfModules(): number {
    return this.walls.length + this.doors.length;
  }

  public get modules(): Module[] {
    return [...this._walls, ...this._doors];
  }

  public get isHorizontal(): boolean {
    return this.rotation == 0 || this.rotation == 180;
  }

  public get isVertical(): boolean {
    return this.rotation == 90 || this.rotation == 270;
  }

  public get isInverted(): boolean {
    return this.rotation == 180 || this.rotation == 270;
  }
}

export interface Module {
  id: ModuleId;
  sectionId: SectionId;

  posX: number;
  posY: number;
  width: number;
  depth: number;

  hasError: boolean;
}
