<div class="container-fluid">
  <div class="row">
    <div
      class="col-lg-12"
      [textContent]="'modal_restoreAutosave_header' | translate: 'There is an autosave on the computer. Do you want to restore the autosave or use the server version?'"
    ></div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-lg-12">
          <h4
            [textContent]="'modal_restoreAutosave_server_version' | translate: 'Server version'"
          ></h4>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-12"
          [textContent]="('modal_restoreAutosave_last_change' | translate: 'Last change: ') + (serverDate | date : 'medium')"
        ></div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            class="btn btn-default"
            type="button"
            (click)="answer(false)"
            [textContent]="'modal_restoreAutosave_use_server_version' | translate: 'Use server version'"
          ></button>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="row">
        <div class="col-lg-12">
          <h4
            [textContent]="'modal_restoreAutosave_local_version' | translate: 'Local version'"
          ></h4>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-12"
          [textContent]="('modal_restoreAutosave_last_change' | translate: 'Last change: ') + (localDate | date : 'medium')"
        ></div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            class="btn btn-default"
            type="button"
            (click)="answer(true)"
            [textContent]="'modal_restoreAutosave_use_local_version' | translate: 'Use local version'"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
