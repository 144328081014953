export function intersect(a: any[], b: any[]) {
  var t;
  if (b.length > a.length) (t = b), (b = a), (a = t); // indexOf to loop over shorter

  return a
    .filter(function (element) {
      return b.indexOf(element) > -1;
    })
    .filter(function (element, index, array) {
      // extra step to remove duplicates
      return array.indexOf(element) === index;
    });
}

export function sum(...numbers: number[]): number {
  let result = 0;
  for (let n of numbers) {
    result += n;
  }
  return result;
}

export function toDict<TIn>(
  list: TIn[],
  keySelector: (t: TIn) => number,
): { [key: number]: TIn };
export function toDict<TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string,
): { [key: string]: TIn };
export function toDict<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => number,
  valSelector: (tin: TIn) => TVal,
): { [key: number]: TVal };
export function toDict<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string,
  valSelector: (tin: TIn) => TVal,
): { [key: string]: TVal };
export function toDict<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string | number,
  valSelector?: (tin: TIn) => TVal,
): { [key: string]: TVal } | { [key: number]: TVal } {
  if (!valSelector) {
    valSelector = (tin: TIn) => <TVal>(<any>tin);
  }
  let result: { [key: string]: TVal };
  result = {};
  for (let t of list) {
    let key = keySelector(t);
    result[key] = valSelector(t);
  }
  return result;
}

export function toLookup<TIn>(
  list: TIn[],
  keySelector: (t: TIn) => number,
): { [key: number]: TIn[] };
export function toLookup<TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string,
): { [key: string]: TIn[] };
export function toLookup<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => number,
  valSelector: (tin: TIn) => TVal,
): { [key: number]: TVal[] };
export function toLookup<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string,
  valSelector: (tin: TIn) => TVal,
): { [key: string]: TVal[] };
export function toLookup<TIn, TVal = TIn>(
  list: TIn[],
  keySelector: (t: TIn) => string | number,
  valSelector?: (tin: TIn) => TVal,
): { [key: string]: TVal[] } | { [key: number]: TVal[] } {
  if (!valSelector) {
    valSelector = (tin: TIn) => <TVal>(<any>tin);
  }
  let result: { [key: string]: TVal[] };
  result = {};
  for (let t of list) {
    let key = keySelector(t);
    let valList: TVal[] | undefined;
    valList = result[key];
    if (!valList) {
      valList = [];
      result[key] = valList;
    }
    valList.push(valSelector(t));
  }
  return result;
}
