import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Constants from 'app/ts/InterfaceConstants';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
export class FloorPlanHelper {
  private static getExtraStart(corner: Interface_DTO_FloorPlan.Corner): number {
    switch (corner.CornerType) {
      case Interface_Enums.CornerType.Normal:
        return 0;
      case Interface_Enums.CornerType.Half:
        return corner.Size.X;
      case Interface_Enums.CornerType.Inverted:
        return corner.Size.Y;
      default:
        throw new Error('Unknown corner type: ' + JSON.stringify(corner));
    }
  }
  private static getExtraEnd(corner: Interface_DTO_FloorPlan.Corner): number {
    switch (corner.CornerType) {
      case Interface_Enums.CornerType.Normal:
        return 0;
      case Interface_Enums.CornerType.Half:
      case Interface_Enums.CornerType.Inverted:
        return corner.Size.X;
      default:
        throw new Error('Unknown corner type: ' + JSON.stringify(corner));
    }
  }

  public static updateWalls(
    sfp: Partial<Interface_DTO.FloorPlan>,
  ): Interface_DTO.FloorPlan {
    if (!sfp.Size) throw new Error('size is undefined');

    if (!sfp.BottomLeftCorner) throw new Error('corner is undefined');
    if (!sfp.BottomRightCorner) throw new Error('corner is undefined');
    if (!sfp.TopLeftCorner) throw new Error('corner is undefined');
    if (!sfp.TopRightCorner) throw new Error('corner is undefined');

    let top = (sfp.TopWall = {
      Begin: { X: 0, Y: 0 },
      End: { X: sfp.Size.X, Y: 0 },
      Items: sfp.TopWall ? sfp.TopWall.Items : [],
      ExtraBegin: { X: 0, Y: 0 },
      ExtraEnd: { X: sfp.Size.X, Y: 0 },
    });
    let bottom = (sfp.BottomWall = {
      Begin: { X: sfp.Size.X, Y: sfp.Size.Y },
      End: { X: 0, Y: sfp.Size.Y },
      Items: sfp.BottomWall ? sfp.BottomWall.Items : [],
      ExtraBegin: { X: sfp.Size.X, Y: sfp.Size.Y },
      ExtraEnd: { X: 0, Y: sfp.Size.Y },
    });
    let left = (sfp.LeftWall = {
      Begin: { X: 0, Y: sfp.Size.Y },
      End: { X: 0, Y: 0 },
      Items: sfp.LeftWall ? sfp.LeftWall.Items : [],
      ExtraBegin: { X: 0, Y: sfp.Size.Y },
      ExtraEnd: { X: 0, Y: 0 },
    });
    let right = (sfp.RightWall = {
      Begin: { X: sfp.Size.X, Y: 0 },
      End: { X: sfp.Size.X, Y: sfp.Size.Y },
      Items: sfp.RightWall ? sfp.RightWall.Items : [],
      ExtraBegin: { X: sfp.Size.X, Y: 0 },
      ExtraEnd: { X: sfp.Size.X, Y: sfp.Size.Y },
    });

    //Top left
    let corner = sfp.TopLeftCorner;
    let leftoverItems = FloorPlanHelper.updateCornerItems(corner);
    sfp.TopWall.Items.push(...leftoverItems);
    if (corner.CornerType == Interface_Enums.CornerType.Half) {
      corner.Wall1!.Begin = { X: 0, Y: corner.Size.X };
      corner.Wall1!.End = { X: corner.Size.X, Y: 0 };
      top.Begin.X = corner.Size.X;
      left.End.Y = corner.Size.X;
    } else if (corner.CornerType == Interface_Enums.CornerType.Inverted) {
      corner.Wall1!.Begin = { X: 0, Y: corner.Size.Y };
      corner.Wall1!.End = { X: corner.Size.X, Y: corner.Size.Y };
      corner.Wall2!.Begin = { X: corner.Size.X, Y: corner.Size.Y };
      corner.Wall2!.End = { X: corner.Size.X, Y: 0 };
      top.Begin.X = corner.Size.X;
      left.End.Y = corner.Size.Y;
    }

    //Top Right
    corner = sfp.TopRightCorner;
    leftoverItems = FloorPlanHelper.updateCornerItems(corner);
    sfp.RightWall.Items.push(...leftoverItems);
    if (corner.CornerType === Interface_Enums.CornerType.Half) {
      corner.Wall1!.Begin = { X: sfp.Size.X - corner.Size.X, Y: 0 };
      corner.Wall1!.End = { X: sfp.Size.X, Y: corner.Size.X };
      top.End.X = sfp.Size.X - corner.Size.X;
      right.Begin.Y = corner.Size.X;
    } else if (corner.CornerType == Interface_Enums.CornerType.Inverted) {
      corner.Wall1!.Begin = { X: sfp.Size.X - corner.Size.X, Y: 0 };
      corner.Wall1!.End = { X: sfp.Size.X - corner.Size.X, Y: corner.Size.Y };
      corner.Wall2!.Begin = { X: sfp.Size.X - corner.Size.X, Y: corner.Size.Y };
      corner.Wall2!.End = { X: sfp.Size.X, Y: corner.Size.Y };
      top.End.X = sfp.Size.X - corner.Size.X;
      right.Begin.Y = corner.Size.Y;
    }

    //Bottom Left
    corner = sfp.BottomLeftCorner;
    leftoverItems = FloorPlanHelper.updateCornerItems(corner);
    sfp.LeftWall.Items.push(...leftoverItems);
    if (corner.CornerType === Interface_Enums.CornerType.Half) {
      corner.Wall1!.Begin = { X: corner.Size.X, Y: sfp.Size.Y };
      corner.Wall1!.End = { X: 0, Y: sfp.Size.Y - corner.Size.X };
      bottom.End.X = corner.Size.X;
      left.Begin.Y = sfp.Size.Y - corner.Size.X;
    } else if (corner.CornerType == Interface_Enums.CornerType.Inverted) {
      corner.Wall1!.Begin = { X: corner.Size.X, Y: sfp.Size.Y };
      corner.Wall1!.End = { X: corner.Size.X, Y: sfp.Size.Y - corner.Size.Y };
      corner.Wall2!.Begin = { X: corner.Size.X, Y: sfp.Size.Y - corner.Size.Y };
      corner.Wall2!.End = { X: 0, Y: sfp.Size.Y - corner.Size.Y };
      bottom.End.X = corner.Size.X;
      left.Begin.Y = sfp.Size.Y - corner.Size.Y;
    }

    //Bottom Right
    corner = sfp.BottomRightCorner;
    leftoverItems = FloorPlanHelper.updateCornerItems(corner);
    sfp.BottomWall.Items.push(...leftoverItems);
    if (corner.CornerType === Interface_Enums.CornerType.Half) {
      corner.Wall1!.Begin = { X: sfp.Size.X, Y: sfp.Size.Y - corner.Size.X };
      corner.Wall1!.End = { X: sfp.Size.X - corner.Size.X, Y: sfp.Size.Y };
      bottom.Begin.X = sfp.Size.X - corner.Size.X;
      right.End.Y = sfp.Size.Y - corner.Size.X;
    } else if (corner.CornerType == Interface_Enums.CornerType.Inverted) {
      corner.Wall1!.Begin = { X: sfp.Size.X, Y: sfp.Size.Y - corner.Size.Y };
      corner.Wall1!.End = {
        X: sfp.Size.X - corner.Size.X,
        Y: sfp.Size.Y - corner.Size.Y,
      };
      corner.Wall2!.Begin = {
        X: sfp.Size.X - corner.Size.X,
        Y: sfp.Size.Y - corner.Size.Y,
      };
      corner.Wall2!.End = { X: sfp.Size.X - corner.Size.X, Y: sfp.Size.Y };
      bottom.Begin.X = sfp.Size.X - corner.Size.X;
      right.End.Y = sfp.Size.Y - corner.Size.Y;
    }

    return <Interface_DTO.FloorPlan>sfp;
  }

  private static updateCornerItems(
    corner: Interface_DTO_FloorPlan.Corner,
  ): Interface_DTO_FloorPlan.Item[] {
    if (corner.CornerType == Interface_Enums.CornerType.Half) {
      if (!corner.Wall1) {
        corner.Wall1 = FloorPlanHelper.createStandardWall();
      }
      corner.Wall1.Items = FloorPlanHelper.getItems([
        corner.Wall1,
        corner.Wall2,
      ]);
      corner.Wall2 = null;
      return [];
    } else if (corner.CornerType == Interface_Enums.CornerType.Inverted) {
      if (!corner.Wall1) {
        corner.Wall1 = FloorPlanHelper.createStandardWall();
      }
      if (!corner.Wall2) {
        corner.Wall2 = FloorPlanHelper.createStandardWall();
      }
      corner.Wall2.Items = FloorPlanHelper.getItems([corner.Wall2]);
      return [];
    } else {
      let result = FloorPlanHelper.getItems([corner.Wall1, corner.Wall2]);
      corner.Wall1 = null;
      corner.Wall2 = null;
      return result;
    }
  }

  public static createStandardFloorPlan(): Interface_DTO.FloorPlan {
    let emptyWall: Interface_DTO_FloorPlan.WallSection = {
      Begin: { X: 0, Y: 0 },
      End: { X: 0, Y: 0 },
      Items: [],
      ExtraBegin: null,
      ExtraEnd: null,
    };
    var result: Interface_DTO.FloorPlan = {
      PartitionJsonData: '',
      MeasurementJsonData: '',
      Size: {
        X: 5000,
        Y: 4000,
        Z: 2400,
      },
      TopLeftCorner: FloorPlanHelper.createStandardCorner(),
      TopRightCorner: FloorPlanHelper.createStandardCorner(),
      BottomLeftCorner: FloorPlanHelper.createStandardCorner(),
      BottomRightCorner: FloorPlanHelper.createStandardCorner(),
      CreatedDate: DateHelper.toIsoString(new Date()),
      CorrectionDeadline: DateHelper.toIsoString(
        new Date(2099, 11, 24, 13, 33, 37),
      ),
      InternalNote: '',
      DeliveryStatus: Interface_Enums.DeliveryStatus.Quote,
      BottomWall: ObjectHelper.copy(emptyWall),
      TopWall: ObjectHelper.copy(emptyWall),
      LeftWall: ObjectHelper.copy(emptyWall),
      RightWall: ObjectHelper.copy(emptyWall),
      Cabinets: [],
      DeliveryInfoId: 0,
      Id: null,
      MaxErrorLevel: 0,
      Name: '',
      ProjectDeliveryAddressId: 0,
      SupporterId: null,
      UserId: 0,
      StoreId: -1,
      UsesFullCatalog: false,
      FullCatalogTallCabinets: false,
      FullCatalogMuteErrors: false,
      FullCatalogAllowOtherProducts: false,
      FullCatalogAllowOtherMaterials: false,
      FullCatalogWideDoors: false,
      RuleSet: Interface_Constants.CurrentRuleSet,
      ControlGuid: ObjectHelper.getGuid(),
    };

    return FloorPlanHelper.updateWalls(result);
  }

  public static createStandardCorner(): Interface_DTO_FloorPlan.Corner {
    return {
      CornerType: Interface_Enums.CornerType.Normal,
      Size: { X: 500, Y: 500 },
      Wall1: FloorPlanHelper.createStandardWall(),
      Wall2: FloorPlanHelper.createStandardWall(),
    };
  }

  private static createStandardWall(): Interface_DTO_FloorPlan.WallSection {
    return {
      Begin: { X: 0, Y: 0 },
      End: { X: 0, Y: 0 },
      Items: [],
      ExtraBegin: null,
      ExtraEnd: null,
    };
  }

  public static getNearestWall(
    walls: Interface_DTO_FloorPlan.WallSection[],
    point: Interface_DTO_Draw.Vec2d,
    minWallLength: number,
  ): Interface_DTO_FloorPlan.WallSection | null {
    let result = null;
    let minDist = Number.MAX_VALUE;
    for (let i = 0; i < walls.length; i++) {
      let wall = walls[i];
      if (FloorPlanHelper.wallLength(wall) < minWallLength) continue;
      let dist = FloorPlanHelper.distanceToWall(wall, point);
      if (dist < minDist) {
        minDist = dist;
        result = wall;
      }
    }
    return result;
  }

  public static getPosOnWall(
    wall: Interface_DTO_FloorPlan.WallSection,
    distFromBegin: number,
  ): Interface_DTO_Draw.Vec2d {
    let rotation = FloorPlanHelper.getRotation(wall);
    return {
      X: wall.Begin.X + Math.sin(rotation) * distFromBegin,
      Y: wall.Begin.Y - Math.cos(rotation) * distFromBegin,
    };
  }

  public static angle(wall: Interface_DTO_FloorPlan.WallSection): number {
    let a = VectorHelper.subtract(wall.End, wall.Begin);
    return Math.atan2(a.Y, a.X);
  }

  public static distanceToWall(
    wall: Interface_DTO_FloorPlan.WallSection,
    point: Interface_DTO_Draw.Vec2d,
  ): number {
    return VectorHelper.distToSegment(point, wall.Begin, wall.End);
  }

  public static wallLength(wall: Interface_DTO_FloorPlan.WallSection): number {
    return Math.sqrt(
      (wall.Begin.X - wall.End.X) * (wall.Begin.X - wall.End.X) +
        (wall.Begin.Y - wall.End.Y) * (wall.Begin.Y - wall.End.Y),
    );
  }

  public static getRotation(wall: Interface_DTO_FloorPlan.WallSection): number {
    return (
      Math.atan2(wall.End.Y - wall.Begin.Y, wall.End.X - wall.Begin.X) +
      Math.PI / 2
    );
  }

  private static getItems(
    walls?: (Interface_DTO_FloorPlan.WallSection | null)[],
  ): Interface_DTO_FloorPlan.Item[] {
    let result: Interface_DTO_FloorPlan.Item[] = [];
    if (walls) {
      for (let wall of walls) {
        if (!wall) continue;
        result = result.concat(wall.Items);
      }
    }
    return result;
  }
}
