export class ConstrainedNumber {
  private minVal: number = Number.NEGATIVE_INFINITY;
  private actVal: number = 0;
  private maxVal: number = Number.POSITIVE_INFINITY;

  constructor(value: number, minValue?: number, maxValue?: number) {
    if (minValue) this.minValue = minValue;

    if (maxValue) this.maxValue = maxValue;

    this.actualValue = value;
  }

  get minValue(): number {
    return this.minVal;
  }
  set minValue(val: number) {
    this.minVal = val;
    if (this.minVal > this.maxVal) {
      this.maxVal = this.minVal;
    }

    this.actualValue = this.actVal;
  }

  get maxValue(): number {
    return this.maxVal;
  }
  set maxValue(val: number) {
    this.maxVal = val;
    if (this.maxVal < this.minVal) {
      this.minVal = this.maxVal;
    }

    this.actualValue = this.actVal;
  }

  get actualValue(): number {
    return this.actVal;
  }
  set actualValue(val: number) {
    this.actVal = Math.min(Math.max(val, this.minVal), this.maxVal);
  }
}
