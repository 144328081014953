import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
export class ConfigurationItemHelper {
  //#region Get values from ItemVariant

  public static getFrameColorNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.FrameColor,
    );
  }

  public static getBarHeight(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getVariantOptionValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.BarHeight,
    );
  }

  public static getGripVariantOptionNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    let gripVariant = product
      .getVariants(productLineId)
      .filter((v) => v.Number.indexOf(VariantNumbers.Grip) === 0)[0];
    if (!gripVariant) return '';
    let civo = item.VariantOptions.filter(
      (vo) => vo.VariantId === gripVariant.Id,
    )[0];
    if (!civo) return '';
    let variantOption = gripVariant.VariantOptions.filter(
      (vo) => vo.Id === civo.VariantOptionId,
    )[0];
    if (!variantOption) return '';
    return variantOption.Number;
  }

  public static getGripPlacementHeight(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.GripPlacementHeight,
    );
  }

  public static getDoorWidth(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.DoorWidth,
    );
  }

  public static getDoorHeight(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.DoorHeight,
    );
  }

  public static getDoorFillingCount(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.DoorFillingCount,
    );
  }

  public static getDoorDesignFillingCount(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.DoorDesignFillingCount,
    );
  }

  public static getDoorLooseBarCount(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.LooseBarCount,
    );
  }

  public static getDoorFixedBarCount(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.FixedBarCount,
    );
  }

  public static getGripPlacementFrontOptionNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.GripPlacementFrontside,
    );
  }

  public static getGripPlacementBackOptionNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.GripPlacementBackside,
    );
  }

  public static getDoorCornerCutOptionNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.DoorCornerCut,
    );
  }

  public static getBarType(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    index: number,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    if (index < 0 || index >= VariantNumbers.BarPlacementPositionTypes.length)
      return '';

    return VariantHelper.getItemVariantValue(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.BarPlacementPositionTypes[index],
    );
  }

  public static getBarPlacementTypeByNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    optionNumber: number,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionValueByNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.BarPlacement,
      optionNumber,
    );
  }

  public static getBarPlacementPositionByIndex(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    index: number,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    return VariantHelper.getItemVariantValueAsNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.BarPlacementPositions[index],
    );
  }

  public static hasDrilling(item: Client.ConfigurationItem): boolean {
    if (!item.Product) return false;
    return (
      VariantHelper.getVariantOptionNumber(
        item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
        item.VariantOptions,
        VariantNumbers.Drilling,
      ) === VariantNumbers.Values.Yes
    );
  }

  public static hasEdgingTop(item: Client.ConfigurationItem): boolean {
    if (!item.Product) return false;
    return (
      VariantHelper.getVariantOptionNumber(
        item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
        item.VariantOptions,
        VariantNumbers.EdgingTop,
      ) === VariantNumbers.Values.EdgingTop_LeftAndRight
    );
  }

  public static hasEdgingBottom(item: Client.ConfigurationItem): boolean {
    if (!item.Product) return false;
    return (
      VariantHelper.getVariantOptionNumber(
        item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
        item.VariantOptions,
        VariantNumbers.EdgingBottom,
      ) === VariantNumbers.Values.EdgingBottom_LeftAndRight
    );
  }

  public static hasForcedFixedBars(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): boolean {
    if (!product) return false;
    return (
      VariantHelper.getVariantOptionNumber(
        product.getVariants(productLineId),
        item.VariantOptions,
        VariantNumbers.ForceFixedBars,
      ) === VariantNumbers.Values.Yes
    );
  }

  public static getVerticalBarOptionNumber(
    item: Interface_DTO.ConfigurationItem,
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): string {
    return VariantHelper.getVariantOptionNumber(
      product.getVariants(productLineId),
      item.VariantOptions,
      VariantNumbers.VerticalBarCount,
    );
  }

  //#endregion Get values from ItemVariant

  //#region Save value as ItemVariant

  /**
   * Adds an ItemVariant, where the ActualValue is the only needed value.
   * Takes a number as the value, which is converted to a string (digits can be set)
   * @param item
   * @param variantNumber
   * @param value
   * @param digits
   */
  public static addDimensionVariantByNumber(
    item: Client.ConfigurationItem,
    variantNumber: string,
    value: number,
    digits: number = 0,
  ) {
    let valueAsString = value.toLocaleString('en-GB', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: false,
    });
    this.addDimensionVariant(item, variantNumber, valueAsString);
  }
  /**
   * Adds an ItemVariant, where the ActualValue is the only needed value.
   * @param item
   * @param variantNumber
   * @param value
   */
  public static addDimensionVariant(
    item: Client.ConfigurationItem,
    variantNumber: string,
    value: string,
  ) {
    if (!item.Product) return;
    let variant = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).firstOrDefault((v) => v.Number === variantNumber);
    if (variant) {
      let itemVariant = {
        VariantId: variant.Id,
        VariantOptionId: -1,
        ActualValue: value,
      };
      item.setItemVariant(itemVariant, true);
    }
  }

  /**
   * Adds an ItemVariant, where the VariantOption is the only needed value.
   * @param item
   * @param variantNumber
   * @param optionNumber
   */
  public static addVariantOptionByNumbers(
    item: Client.ConfigurationItem,
    variantNumber: string,
    optionNumber: string,
  ): boolean {
    if (!item.Product) return false;
    let variant = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).firstOrDefault((v) => v.Number === variantNumber);
    if (variant) {
      let option = Enumerable.from(variant.VariantOptions).firstOrDefault(
        (vo) => vo.Number === optionNumber,
      );
      if (option) {
        this.addVariantOption(item, option);
        return true;
      }
    }
    return false;
  }

  /**
   * Adds an ItemVariant, where the VariantOption is the only needed value.
   * @param item
   * @param variantNumber
   * @param optionNumber
   */
  public static addVariantOptionByNumbersWithValue(
    item: Client.ConfigurationItem,
    variantNumber: string,
    optionNumber: string,
  ): boolean {
    if (!item.Product) return false;
    let variant = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).firstOrDefault((v) => v.Number === variantNumber);
    if (variant) {
      let option = Enumerable.from(variant.VariantOptions).firstOrDefault(
        (vo) => vo.Number === optionNumber,
      );
      if (option) {
        this.addVariantOptionWithValue(item, option);
        return true;
      }
    }
    return false;
  }

  /**
   * Adds an ItemVariant, where all of the VariantOption is used.
   * It is not clear whether addVariantOption below simply could be changed
   * to do the same, so I won't.
   * @param item
   * @param option
   */
  public static addVariantOptionWithValue(
    item: Client.ConfigurationItem,
    option: Interface_DTO.VariantOption,
  ) {
    // Create ItemVariant
    let itemVariant = {
      VariantId: option.VariantId,
      VariantOptionId: option.Id,
      ActualValue: option.Number,
    };

    // Add ItemVariant
    item.setItemVariant(itemVariant, true);
  }

  /**
   * Adds an ItemVariant, where the VariantOption is the only needed value.
   * @param item
   * @param option
   */
  public static addVariantOption(
    item: Client.ConfigurationItem,
    option: Interface_DTO.VariantOption,
  ) {
    // Create ItemVariant
    let itemVariant = {
      VariantId: option.VariantId,
      VariantOptionId: option.Id,
      ActualValue: '', // Should this be option.Number ???
    };

    // Add ItemVariant
    item.setItemVariant(itemVariant, true);
  }

  /**
   * Adds an ItemVariant, where the combination of VariantOption and ActualValue is the needed value.
   * Takes a number as the value, which is converted to a string (digits can be set)
   * @param item
   * @param variantNumber
   * @param optionNumber
   * @param value
   * @param digits
   */
  public static addDimensionVariantOptionByNumber(
    item: Client.ConfigurationItem,
    variantNumber: string,
    optionNumber: number,
    value: number,
    digits: number = 0,
  ) {
    let valueAsString = value.toLocaleString('en-GB', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: false,
    });
    this.addDimensionVariantOption(
      item,
      variantNumber,
      optionNumber,
      valueAsString,
    );
  }
  /**
   * Adds an ItemVariant, where the combination of VariantOption and ActualValue is the needed value.
   * @param item
   * @param variantNumber
   * @param optionNumber
   * @param value
   */
  public static addDimensionVariantOption(
    item: Client.ConfigurationItem,
    variantNumber: string,
    optionNumber: number,
    value: string,
  ) {
    if (!item.Product) return;
    let variant = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).firstOrDefault((v) => v.Number === variantNumber);
    if (variant) {
      if (variant.VariantOptions.length >= optionNumber) {
        let option = variant.VariantOptions[optionNumber];
        if (option) {
          let itemVariant = {
            VariantId: variant.Id,
            VariantOptionId: option.Id,
            ActualValue: value,
          };
          item.setDimensionVariantOption(itemVariant);
        }
      }
    }
  }

  //#endregion Save value as ItemVariant

  //#region Remove ItemVariant

  public static removeItemVariantByVariantNumber(
    item: Client.ConfigurationItem,
    variantNumber: string,
  ) {
    if (!item.Product) return;
    let variant = Enumerable.from(
      item.Product.getVariants(item.cabinetSection.cabinet.ProductLineId),
    ).firstOrDefault((v) => v.Number === variantNumber);
    if (variant) {
      item.removeVariantOptionByVariantId(variant.Id);
    }
  }

  public static removeItemVariantsByVariantNumbers(
    item: Client.ConfigurationItem,
    variantNumbers: string[],
  ) {
    for (let variantNumber of variantNumbers) {
      this.removeItemVariantByVariantNumber(item, variantNumber);
    }
  }

  //#endregion Remove ItemVariant

  //#region Extra info

  /**
   * Removes all ItemVariants with an Id below (and including) the given number.
   * @param item
   * @param belowNumber
   */
  public static removeExtraInfoBelowNumber(
    item: Client.ConfigurationItem,
    belowNumber: number,
  ) {
    item.removeVariantOptionBelowNumber(belowNumber);
  }

  /**
   * Adds an ItemVariant with the given id and value
   * Used only for extra info
   * @param item
   * @param id
   * @param value
   */
  public static addExtraInfo(
    item: Client.ConfigurationItem,
    id: number,
    value: string,
  ) {
    // Create ItemVariant
    let itemVariant = {
      VariantId: 0,
      VariantOptionId: id,
      ActualValue: value,
    };

    // Add ItemVariant
    item.setItemVariant(itemVariant, true);
  }

  //#endregion Extra info

  public static getItemCube(item: Client.ConfigurationItem): Client.ItemCube {
    return {
      X: item.X,
      Y: item.Y,
      Z: item.Z,
      Width: item.Width,
      Height: item.Height,
      Depth: item.Depth,
      item: item,
    };
  }

  /**
   * Removes items without products from the list. Returns the removed items
   */
  public static removeItemsWithoutProducts(
    items: Client.ConfigurationItem[],
  ): Client.ConfigurationItem[] {
    let result: Client.ConfigurationItem[] = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.ProductId > 0 && !item.Product) {
        let removedItem = items.splice(i, 1);
        result.push(...removedItem);
        i--;
      }
    }
    return result;
  }
}
