<div class="flex-all vflex route-reset-password public-page">
  <div class="hflex">
    <div class="vflex">
      <div class="flex-center">
        <form class="form-like">
          <div class="container">
            <div class="col-2"></div>
            <div class="col-8">
              <div class="cards p-2">
                <div class="row">
                  <div class="col-12">
                    <div class="company-logo"></div>
                  </div>
                </div>
                <div class="row spaced-row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <span class="bi bi-person-fill input-group-text"></span>
                      <input
                        type="email"
                        name="username"
                        autocomplete="email"
                        disabled
                        tabindex="0"
                        class="form-control"
                        [(ngModel)]="username"
                      />
                    </div>
                  </div>
                </div>

                <div class="row spaced-row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <span class="bi bi-lock-fill input-group-text"></span>
                      <input
                        type="password"
                        name="password"
                        class="form-control"
                        autocomplete="new-password"
                        tabindex="1"
                        autofocus
                        [(ngModel)]="newPassword"
                        [attr.placeholder]="'password_reset_landing_password_placeholder' | translate: 'Password'"
                      />
                    </div>
                  </div>
                </div>

                <div class="row spaced-row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <span class="bi bi-lock-fill input-group-text"></span>
                      <input
                        type="password"
                        name="password-check"
                        class="form-control"
                        autocomplete="new-password"
                        tabindex="2"
                        autofocus
                        [(ngModel)]="confirmPassword"
                        [attr.placeholder]="'password_reset_landing_confirm_password_placeholder' | translate: 'Confirm password'"
                      />
                    </div>
                  </div>
                </div>

                <div class="row spaced-row login-button-row">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <button
                      class="btn btn-primary btn-block"
                      type="submit"
                      tabindex="3"
                      (click)="resetPassword()"
                      [textContent]="'password_reset_landing_reset_button_text' | translate: 'Change password'"
                    ></button>
                  </div>
                  <div class="col-2"></div>
                </div>

                <div class="row spaced-row login-button-row">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <a
                      class="btn btn-primary btn-block"
                      [routerLink]="'/login'"
                      tabindex="3"
                      [textContent]="'password_reset_landing_login_link' | translate: 'Back to login'"
                    ></a>
                  </div>
                  <div class="col-2"></div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div><!--Spacer--></div>
      </div>
    </div>
  </div>
</div>
