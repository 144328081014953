import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { CommandInterpreter } from 'app/imperative-shell/command-interpreter';
import { Subscription } from 'rxjs';
import { SetCustomPriceDate } from './SetCustomPriceDate.command';

export interface CustomPriceDate {
  get data(): Date | undefined;

  asString(date: Date | undefined): string | undefined;
  set(value: Date | undefined): void;

  subscribe(next: (value: Date | undefined) => void): Subscription;
}

function asString(date: Date | undefined) {
  if (!date) return undefined;

  return date.toISOString();
}

function customPriceDateFactory(
  data: FunctionalCoreService,
  interpreter: CommandInterpreter,
): CustomPriceDate {
  return {
    data: data.ambient.clientSetting.customPriceDate,
    asString: (date) => asString(date),
    set(value: Date | undefined) {
      interpreter.execute(new SetCustomPriceDate(value));
    },
    subscribe(next: (value: Date | undefined) => void) {
      return data.ambient.clientSetting.customPriceDate$.subscribe(next);
    },
  };
}

/** Use this together with @Inject() in constructors for Services, e.g.
 *    @Inject(CustomPriceDateInjector) private readonly customPriceDate: CustomPriceDate
 */
export const CustomPriceDateInjector = new InjectionToken<CustomPriceDate>(
  'custompricedate',
  {
    factory() {
      const data = inject(FunctionalCoreService);
      const interpreter = inject(CommandInterpreter);

      return customPriceDateFactory(data, interpreter);
    },
  },
);

/** Use the provider for components, together with CustomPriceDataInjector,
 *  to ensure that useFactory is called on each instantiation */
export const customPriceDateProvider: Provider = {
  provide: CustomPriceDateInjector,
  useFactory: customPriceDateFactory,
  deps: [FunctionalCoreService, CommandInterpreter],
};
