import { Component, ElementRef, HostListener } from '@angular/core';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { SecretService } from '../secret.service';

@Component({
  selector: 'secret',
  templateUrl: './secret.component.html',
  styleUrls: ['./secret.component.scss'],
})
export class SecretComponent {
  private batSpacingFromWall: number = 200;

  private p1: Player = {
    x: this.batSpacingFromWall,
    pos: 0,
    movingUp: false,
    movingDown: false,
    score: 0,
  };
  private p2: Player = {
    x: 0,
    pos: 0,
    movingUp: false,
    movingDown: false,
    score: 0,
  };
  private moveSpeed: number = 30;

  public batWidth: number = 100;
  public batThickness!: number;

  public ballPos: Vec2d = { X: 0, Y: 0 };
  public ballSize!: number;
  private ballSpeed: number = 40;
  private ballDir: Vec2d = { X: 0, Y: 0 };

  private frameTime: number = 18; //ms
  private updateTimerId: number = -1;

  public showCountDown: boolean = false;
  public countDownCount: number = 0;
  private countDownTimerId: number = -1;

  constructor(
    private editorTypeService: EditorTypeService,
    private secretService: SecretService,
  ) {
    this.batThickness = Math.round(editorTypeService.floorPlan.Size.X / 50);

    secretService.isRunning$.subscribe((state) => {
      if (state) this.start();
      else this.stop();
    });
  }

  private start() {
    this.batWidth = Math.round(this.editorTypeService.floorPlan.Size.Y / 5);
    this.p1.pos = this.floorPlanHeight / 2;
    this.p1.score = 0;
    this.p2.pos = this.floorPlanHeight / 2;
    this.p2.score = 0;
    this.p2.x =
      this.floorPlanWidth - this.batSpacingFromWall - this.batThickness;

    this.ballSize = this.batWidth / 20;

    this.startRound();
    this.updateTimerId = setInterval(this.update.bind(this), this.frameTime);
  }

  private startRound() {
    this.ballPos = {
      X: this.floorPlanWidth / 2,
      Y: this.floorPlanHeight / 2,
    };
    this.ballDir = { X: 0, Y: 0 };
    this.ballSpeed = 40;

    let startAngle = Math.random() * Math.PI * 2;
    let ballStartDir = {
      X: Math.cos(startAngle),
      Y: Math.sin(startAngle),
    };

    this.countDownCount = 3;
    this.showCountDown = true;
    this.countDownTimerId = setInterval(
      this.doCountDown.bind(this),
      1000,
      ballStartDir,
    );
  }

  private doCountDown(startDir: Vec2d) {
    this.countDownCount--;

    if (this.countDownCount == 0) {
      clearInterval(this.countDownTimerId);
      this.showCountDown = false;
      this.ballDir = startDir;
    }
  }

  private stop() {
    clearInterval(this.updateTimerId);
  }

  private update() {
    this.updatePlayer(this.p1);
    this.updatePlayer(this.p2);

    this.checkBallCollidingWithPlayer(this.p1);
    this.checkBallCollidingWithPlayer(this.p2);

    if (this.ballPos.Y < this.ballSize)
      // Hit top wall
      this.ballDir.Y *= -1;
    else if (this.ballPos.Y > this.floorPlanHeight - this.ballSize)
      // Hit bottom wall
      this.ballDir.Y *= -1;

    this.ballPos.X += this.ballDir.X * this.ballSpeed;
    this.ballPos.Y += this.ballDir.Y * this.ballSpeed;

    if (this.ballPos.X < this.batSpacingFromWall) {
      // p2 scored
      this.p2.score++;
      this.startRound();
    } else if (this.ballPos.X > this.floorPlanWidth - this.batSpacingFromWall) {
      // p1 scored
      this.p1.score++;
      this.startRound();
    }
  }

  private updatePlayer(player: Player) {
    if (player.movingUp) {
      player.pos -= this.moveSpeed;
    }
    if (player.movingDown) {
      player.pos += this.moveSpeed;
    }

    player.pos = Math.min(
      Math.max(this.batWidth / 2, player.pos),
      this.floorPlanHeight - this.batWidth / 2,
    );
  }

  private checkBallCollidingWithPlayer(p: Player) {
    let playerY = p.pos - this.batWidth / 2;
    if (
      this.isBallCollidingWithRect(
        p.x,
        playerY,
        this.batThickness,
        this.batWidth,
      )
    ) {
      let playerCenterPoint: Vec2d = {
        X: p.x + this.batThickness / 2,
        Y: p.pos,
      };

      let ballCalcPos: Vec2d = {
        X: this.floorPlanWidth / 2,
        Y: this.ballPos.Y,
      };

      let dir = VectorHelper.subtract(ballCalcPos, playerCenterPoint);
      dir.Y *= 2;

      this.ballSpeed += 2;

      this.ballDir = VectorHelper.getNormalizedVector(dir);
    }
  }

  private isBallCollidingWithRect(
    x: number,
    y: number,
    width: number,
    height: number,
  ): boolean {
    let closestX = this.ballPos.X;
    let closestY = this.ballPos.Y;

    // which edge is closest?
    if (this.ballPos.X < x)
      closestX = x; // test left edge
    else if (this.ballPos.X > x + width) closestX = x + width; // right edge

    if (this.ballPos.Y < y)
      closestY = y; // top edge
    else if (this.ballPos.Y > y + height) closestY = y + height; // bottom edge

    // get squared distance from closest edges
    let distX = this.ballPos.X - closestX;
    let distY = this.ballPos.Y - closestY;
    let distanceSqr = distX * distX + distY * distY;

    // if the distance is less than the radius, collision!
    if (distanceSqr <= this.ballSize * this.ballSize) {
      return true;
    }
    return false;
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyDown(event: KeyboardEvent) {
    if (event.key == 'w') this.p1.movingUp = true;
    else if (event.key == 's') this.p1.movingDown = true;
    else if (event.key == 'ArrowUp') this.p2.movingUp = true;
    else if (event.key == 'ArrowDown') this.p2.movingDown = true;
  }

  @HostListener('document:keyup', ['$event'])
  private handleKeyUp(event: KeyboardEvent) {
    if (event.key == 'w') this.p1.movingUp = false;
    else if (event.key == 's') this.p1.movingDown = false;
    else if (event.key == 'ArrowUp') this.p2.movingUp = false;
    else if (event.key == 'ArrowDown') this.p2.movingDown = false;
  }

  public get show(): boolean {
    return this.secretService.isRunning$.value;
  }

  public get overlayWidth(): number {
    return this.floorPlanWidth + 600;
  }

  public get overlayHeight(): number {
    return this.floorPlanHeight + 600;
  }

  public get floorPlanWidth(): number {
    return this.editorTypeService.floorPlan.Size.X;
  }

  public get floorPlanHeight(): number {
    return this.editorTypeService.floorPlan.Size.Y;
  }

  public get p1X(): number {
    return this.batSpacingFromWall;
  }

  public get p1Y(): number {
    return this.p1.pos - this.batWidth / 2;
  }

  public get p1Score(): number {
    return this.p1.score;
  }

  public get p2X(): number {
    return this.floorPlanWidth - this.batSpacingFromWall - this.batThickness;
  }

  public get p2Y(): number {
    return this.p2.pos - this.batWidth / 2;
  }

  public get p2Score(): number {
    return this.p2.score;
  }
}

interface Player {
  x: number;
  pos: number;
  movingUp: boolean;
  movingDown: boolean;
  score: number;
}
