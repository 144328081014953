import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
  RouterModule,
  Routes,
  TitleStrategy,
  UrlSerializer,
} from '@angular/router';
import { DeliveryAddressesVm } from 'app/ts/viewmodels/DeliveryAddressesVm';
import { PieceListTopVm } from 'app/ts/viewmodels/PieceListTopVm';
import { IsAuthenticatedGuard } from '../identity-and-access/is-authenticated.guard';
import { LoginComponent } from '../identity-and-access/login.component';
import { FloorPlansModule } from '../ts/viewmodels/floorplans.module';
import { FloorPlansVm } from '../ts/viewmodels/FloorPlansVm';
import { OrderConfirmerVm } from '../ts/viewmodels/OrderConfirmerVm';
import { AppPaths } from './app-paths';
import { checkAccess, Area } from './check-access';
import { LowerCaseUrlSerializer } from './lower-case-url-serializer';
import { Navigate } from './navigate';
import { PageTitleStrategy } from './title-strategy';
import { CustomersVm } from 'app/ts/viewmodels/CustomersVm';
import { ProjectsVm } from 'app/ts/viewmodels/ProjectsVm';
import { BackingComponent } from 'app/floor-plan-editing/backing.component';
import { FloorPlanVm } from 'app/floor-plan-editing/FloorPlanVm';
import { CorpusVm } from 'app/floor-plan-editing/CorpusVm';
import { D3Vm } from 'app/floor-plan-editing/D3Vm';
import { DoorVm } from 'app/floor-plan-editing/DoorVm';
import { InteriorVm } from 'app/floor-plan-editing/InteriorVm';
import { SwingVm } from 'app/floor-plan-editing/SwingVm';
import { ValidationVm } from 'app/floor-plan-editing/ValidationVm';
import {
  BasketVm,
  DimensionsVm,
  DoorsVm,
} from 'app/ts/viewmodels/consumer/pages';
import { CorpusVm as ConsumerCorpusVm } from 'app/ts/viewmodels/consumer/pages/CorpusVm';
import { InteriorVm as ConsumerInteriorVm } from 'app/ts/viewmodels/consumer/pages/InteriorVm';
import { pendingChangesGuard } from 'app/floor-plan-editing/pending-changes.guard';
import { FillingsVm } from 'app/floor-plan-editing/fillings/FillingsVm';
import { PasswordResetLandingVm } from 'app/ts/viewmodels/PasswordResetLandingVm';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { SwingFlexComponent } from '../floor-plan-editing/swing-flex/swing-flex.component';

// NOTE! Old angular links including #, is handled in app.component.ts -> ngOnInit()

// TODO: Some routes, like forgot password or such should probably be
// defined in the IdentityAndAccess module.
const routes: Routes = [
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  {
    path: AppPaths.Home,
    component: FloorPlansVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: AppPaths.ConfirmOrders,
    component: OrderConfirmerVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard, () => checkAccess(Area.ConfirmOrders)],
  },
  {
    path: AppPaths.Customers,
    component: CustomersVm,
    pathMatch: 'prefix',
    canActivate: [IsAuthenticatedGuard, () => checkAccess(Area.Customers)],
  },
  {
    path: AppPaths.DeliveryAddresses,
    component: DeliveryAddressesVm,
    pathMatch: 'prefix',
    canActivate: [
      IsAuthenticatedGuard,
      () => checkAccess(Area.DeliveryAddresses),
    ],
  },
  {
    path: AppPaths.Projects,
    component: ProjectsVm,
    pathMatch: 'prefix',
    canActivate: [IsAuthenticatedGuard, () => checkAccess(Area.Projects)],
  },

  // Partition editors
  {
    path: 'floorplan/:floorplanid/partition/:sectionindex/fillings',
    component: FillingsVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/partition/:sectionindex/d3',
    component: D3Vm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/partition/:sectionindex/piecelist',
    component: PieceListTopVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/partition/:sectionindex/validation',
    component: ValidationVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },

  {
    path: 'floorplan/:floorplanid',
    component: FloorPlanVm,
    pathMatch: 'prefix',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/backing',
    component: BackingComponent,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/corpus',
    component: CorpusVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/doors',
    component: DoorVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/d3',
    component: D3Vm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/interior',
    component: InteriorVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/piecelist',
    component: PieceListTopVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/swing',
    component: SwingVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/validation',
    component: ValidationVm,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },
  {
    path: 'floorplan/:floorplanid/:cabinetindex/:cabinetsectionindex/swing-flex',
    component: SwingFlexComponent,
    pathMatch: 'full',
    canActivate: [IsAuthenticatedGuard],
    canDeactivate: [pendingChangesGuard],
  },

  { path: 'dimensions', component: DimensionsVm, pathMatch: 'full' },
  { path: 'corpus', component: ConsumerCorpusVm, pathMatch: 'full' },
  { path: 'doors', component: DoorsVm, pathMatch: 'full' },
  { path: 'interior', component: ConsumerInteriorVm, pathMatch: 'full' },
  { path: 'basket', component: BasketVm, pathMatch: 'full' },

  {
    path: 'passwordreset/:username/:tokenString',
    component: PasswordResetLandingVm,
    pathMatch: 'full',
  },

  { title: 'Hang on...', path: '**', redirectTo: AppPaths.Home },
];

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
    FloorPlansModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
  ],
})
export class AppRoutingModule {
  private routeParams: ParamMap | null = null;
  private queryParams: ParamMap | null = null;

  private _navigate: Navigate;

  constructor(
    private router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = (
      future,
      curr,
    ): boolean => {
      return false;
    };

    activatedRoute.paramMap.subscribe((p) => {
      this.routeParams = p;
    });

    activatedRoute.queryParamMap.subscribe((q) => {
      this.queryParams = q;
    });

    this._navigate = new Navigate(router);
  }

  public get navigate(): Navigate {
    return this._navigate;
  }

  /**
   *
   * @param name The name of the parameter for which to retrieve the value
   * @returns The value for the parameter or null if the parameter is not found
   */
  public getParam(name: string): string | null {
    if (this.routeParams == null && this.queryParams == null) return null;

    const queryValue = this.queryParams?.get(name);
    const routeValue = this.routeParams?.get(name);

    if (routeValue != null) return routeValue;

    return queryValue ?? null;
  }

  /**
   * Reloads the current route.
   */
  public reload(): void {
    // TODO: Implement
    console.log('reload: Implement');
  }

  // TODO Move these to a Navigate class - or something...?
  // The general idea is that each component should not rely
  // heavily on being in a web browser or in a specific place
  // in the component hierarchy
  // So a component would write something:
  // router.Naviate.Home()
  public Home(): void {
    this.router.navigate([AppPaths.Home]);
  }

  public Login(): void {
    this.router.navigate([AppPaths.Login]);
  }

  public FloorPlans(query: string): void {
    let url = `${AppPaths.FloorPlans}?${query}`;
    this.router.navigateByUrl(url);
  }

  public Consumer_address() {
    console.log('Consumer_address: Implement');
  }

  public Consumer_basket() {
    this.router.navigate(['/basket']);
  }

  public Consumer_corpus() {
    this.router.navigate([AppPaths.ConsumerCorpus]);
  }

  public Consumer_dimensions() {
    this.router.navigate([AppPaths.ConsumerDimensions]);
  }

  public Consumer_doors() {
    this.router.navigate(['/doors']);
  }

  public Consumer_interior() {
    this.router.navigate([AppPaths.ConsumerInterior]);
  }

  public Consumer_load() {
    this.router.navigate([AppPaths.ConsumerLoad]);
  }
}
