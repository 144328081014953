import { AnchorScrollService } from 'app/ng/anchor-scroll.service';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ModalService } from 'app/ts/services/ModalService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BaseVmService {
  public constructor(
    public readonly translationService: TranslationService,
    public readonly $anchorScroll: AnchorScrollService,
    public readonly notificationService: NotificationService,
    public readonly modalService: ModalService,
  ) {}
}
