import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { IdentityAndAccessModule } from './identity-and-access/identity-and-access.module';
import { BackendService } from './backend-service/backend-service';
import { FloorPlansModule } from 'app/ts/viewmodels/floorplans.module';
import { LegacyTsModule } from './ts/legacy-ts.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { InitializingComponent } from './initializing.component';
import { RouterModule } from '@angular/router';
import { FunctionalCoreModule } from './functional-core/functional-core.module';
import { ScreenshootingModule } from './screenshooting/screenshooting.module';
import { FloorPlanSaveService } from './ts/services/FloorPlanSaveService';
import { UserMessagesModule } from './user-messages/user-messages.module';
import { ErrorParseInterceptor } from './error-parsing-interceptor';

@NgModule({
  declarations: [AppComponent, InitializingComponent],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    AppRoutingModule,
    FloorPlansModule,
    FunctionalCoreModule,
    IdentityAndAccessModule,
    LegacyTsModule,
    ScreenshootingModule,
    UserMessagesModule,
  ],
  providers: [
    BackendService,
    PromisingBackendService,
    FloorPlanSaveService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorParseInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor() {}
}
