import { Component, HostListener, Inject } from '@angular/core';
import {
  ClientSetting,
  ClientSettingData,
  ClientSettingInjector,
  clientSettingProvider,
  InteriorSetting,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import { ParameterService } from 'app/routing/parameter.service';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { RouteService } from 'app/ts/services/RouteService';
import { TemplateService } from 'app/ts/services/TemplateService';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ProductListVm } from 'app/ts/viewmodels/components/ProductListVm';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import Enumerable from 'linq';
import { SelectedItemsService } from './selected-items.service';

@Component({
  selector: 'interior-vm',
  templateUrl: './interior.html',
  styleUrls: [
    '../../style/editor.scss',
    '../../../node_modules/bootstrap/scss/bootstrap.scss',
  ],
  providers: [clientSettingProvider, DeliveryAddressService, ParameterService],
})
export class InteriorVm extends EditorVm {
  public readonly interiorMaterial: Pickable<Interface_DTO.Material | null>;
  public isAdvancedMenuOpen: boolean = false;
  public isFavoritesMenuOpen: boolean = false;
  public minDepth: number | null = null;
  public maxDepth: number | null = null; //set by categoryChanged()
  public use3d: boolean = App.debug.use3dInInterior;

  private selected: string = '';

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly modalService: ModalService,
    private readonly templateService: TemplateService,
    private readonly navigate: NavigateService,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
    deliveryAddressService: DeliveryAddressService,
    private readonly selectedItems: SelectedItemsService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );

    this.interiorMaterial = new Client.ConverterPickable(
      () => this.cabinetSection!.interior.material,
      (mat) => {
        return mat
          ? {
              ...MaterialHelper.getPickable(mat),
              isSelected: true,
              description: this.translate(
                'interior_material_help_title',
                'Select interior material',
              ),
              override: false,
            }
          : {
              name: this.translate(
                'interior_no_material_name',
                'No standard material selected',
              ),
              disabledReason: null,
              item: null,
              isSelected: true,
              description: this.translate(
                'interior_material_help_title',
                'Select interior material',
              ),
              override: false,
            };
      },
    );

    this.ensureUnsubscribe(
      clientSettings.subscribe((settings) => {
        this.clientSettingsData = settings;
      }),
    );

    this.ensureUnsubscribe(
      this.activatedRoute.queryParamMap.subscribe((query) => {
        this.selected = query.get('selected') ?? '';
        if (this.selected != null) {
          let configItemIndexes: number[] = [];
          //should be a list of ints, seperated by comma
          try {
            configItemIndexes = this.selected
              .split(/\,\s*/)
              .filter((s) => s != null && s.length > 0)
              .map((s) => parseInt(s));
          } catch (e: any) {
            //wrong format, do nothing
          }
          let selectedItems: Client.ConfigurationItem[] = [];
          if (this.cabinetSection) {
            for (let idx of configItemIndexes) {
              for (let item of this.cabinetSection!.interior.items) {
                if (item.ConfigurationItemIndex === idx) {
                  selectedItems.push(item);
                  break;
                }
              }
            }
            this.selectedItems.value = selectedItems;
          }
        }
      }),
    );
  }

  public async selectInteriorMaterial() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.interior.pickableMaterials,
      this.translate('interior_material_selector_header', 'Interior material'),
      'material interior-material',
      true,
    );

    let mat: Client.Material;
    try {
      mat = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.interior.setMaterial(mat, true);
    this.setChanged();
  }

  ///Called from EditorVm when the underlying floorplan has changed
  protected floorPlanChanged() {}

  @HostListener('window:keydown', ['$event'])
  private handleKeyboardEvent2(evt: KeyboardEvent | null) {
    if (!evt) return;

    if (evt.defaultPrevented) return;

    if (
      evt.code === 'Delete' ||
      evt.key === 'Del' ||
      evt.key === 'Delete' ||
      evt.keyCode === 46
    ) {
      this.deleteSelectedItem();
      evt.stopPropagation();
      evt.preventDefault();
    } else if (evt.key === 'a' && evt.ctrlKey) {
      //select all items
      this.selectAll();
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  //These makes sure no item is selected after undoing/redoing
  public override undo() {
    super.undo();
    this.selectedItems.value = [];
  }

  public override redo() {
    this.selectedItems.value = [];
    super.redo();
  }

  public selectAll() {
    this.selectedItems.value = [...this.cabinetSection!.interior.items];
  }

  public fitToWidth() {
    this.cabinetSection!.interior.mustAdaptToWidth = true;
    this.cabinetSection!.interior.hasEmptyGapLeft = false;
    this.cabinetSection!.interior.hasEmptyGapRight = false;
    this.setChanged();
  }

  public get depth() {
    return this.cabinetSection!.InteriorDepth;
  }
  public set depth(val: number) {
    this.cabinetSection!.InteriorDepth = val;
    this.setChanged();
  }

  public get heightReduction(): boolean {
    return this.cabinetSection!.interior.heightReduction;
  }
  public set heightReduction(val: boolean) {
    this.cabinetSection!.interior.heightReduction = val;
  }

  public get isHeightReductionAllowed(): boolean {
    return this.cabinetSection!.interior.isHeightReductionAllowed;
  }

  public get showFavourites(): boolean {
    return !this.cabinetSection!.isSwing && !this.cabinetSection!.isSwingFlex;
  }

  public get showDepthAdjustment(): boolean {
    return !this.cabinetSection!.isSwing && !this.cabinetSection!.isSwingFlex;
  }

  public get depthTooltip(): string {
    if (this.minDepth && this.maxDepth) {
      return this.translate(
        'interior_depth_tooltip_min{0}_max{1}',
        'interior depth for this product category is {0}-{1} mm',
        this.minDepth.toString(),
        this.maxDepth.toString(),
      );
    } else {
      return '';
    }
  }

  // #region displayProps

  private clientSettingsData!: ClientSettingData;
  private setInteriorSetting(settings: Partial<InteriorSetting>) {
    const newInteriorSettings = {
      ...this.clientSettingsData.interior,
      ...settings,
    };

    const newSettings = {
      ...this.clientSettings.data,
      interior: newInteriorSettings,
    };

    this.clientSettings.set(newSettings);
  }

  public get showRulers(): boolean {
    return this.clientSettingsData.interior.displayRulers;
  }
  public set showRulers(val: boolean) {
    this.setInteriorSetting({ displayRulers: val });
  }

  public get showShowPullout(): boolean {
    return !this.cabinetSection!.isSwing && !this.cabinetSection!.isSwingFlex;
  }
  public get showPullout(): boolean {
    return this.clientSettingsData.interior.displayPulloutWarnings;
  }
  public set showPullout(val: boolean) {
    this.setInteriorSetting({ displayPulloutWarnings: val });
  }

  public get showCorpus(): boolean {
    return this.clientSettingsData.interior.displayCorpus;
  }
  public set showCorpus(val: boolean) {
    this.setInteriorSetting({ displayCorpus: val });
  }

  public get showShowHinges(): boolean {
    return this.cabinetSection!.isSwingFlex;
  }

  public get showShowDoors(): boolean {
    return this.cabinetSection!.isSwingFlex;
  }

  public get showHinges(): boolean {
    if (this.cabinetSection && this.clientSettingsData.interior?.displayHings) {
      return (
        this.cabinetSection.isSwingFlex &&
        this.clientSettingsData.interior.displayHings
      );
    }

    return false;
  }

  public set showHinges(val: boolean) {
    this.setInteriorSetting({ displayHings: val });
  }

  public get showSwingFlexDoors(): boolean {
    if (
      this.cabinetSection &&
      this.clientSettingsData.interior?.displaySwingFlexDoors
    ) {
      return (
        this.cabinetSection.isSwingFlex &&
        this.clientSettingsData.interior.displaySwingFlexDoors
      );
    }

    return false;
  }
  public set showSwingFlexDoors(val: boolean) {
    this.setInteriorSetting({ displaySwingFlexDoors: val });
  }

  // #endregion displayProps

  public displayRightMenu: boolean = true;

  public get canChangeItemDepths() {
    return this.selectedItems.value.some((item) => item.isFlexDepth);
  }
  public async changeItemDepths() {
    if (!this.canChangeItemDepths) return;
    let newDepth;
    try {
      newDepth = await this.modalService.getItemDepths(
        this.selectedItems.value,
      );
    } catch (e: any) {
      //user cancelled
      return;
    }

    this.setChanged();
  }

  public async changeHerringBonePosition() {
    try {
      await this.modalService.setJointPositions(this.cabinetSection!);
    } catch (e: any) {
      //user cancelled
      return;
    }
    this.setChanged();
  }

  public async setFreeSpace() {
    try {
      await this.modalService.setFreeSpace(this.cabinetSection!);
    } catch (e: any) {
      //user cancelled
      return;
    }
    this.setChanged();
  }

  // #region selectedItem

  public get selectedItem(): Client.ConfigurationItem | null {
    let items = this.selectedItems.value;
    if (!items || items.length !== 1) return null;
    return items[0];
  }

  public product!: Client.Product;
  public variants!: Interface_DTO.VariantOption[];

  public category!: Client.ProductCategory;

  public createItemFromProduct(
    productParams: ProductListVm.ProductParams,
  ): void {
    const product = productParams.product;
    const variants = productParams.variants;

    if (!product) {
      this.selectedItems.value = [];
      return;
    }

    let materialId: number | null = null;

    if (this.cabinetSection!.InteriorMaterialId) {
      //see if product exists in selected material
      for (let mat of product.materials) {
        if (mat.Id === this.cabinetSection!.InteriorMaterialId) {
          materialId = mat.Id;
          break;
        }
      }
    }
    if (materialId === null) {
      if (product.materials.length > 0) {
        materialId = product.materials[0].Id;
      }
    }

    let item: Client.ConfigurationItem =
      this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Interior,
        product.Id,
        materialId,
        this.cabinetSection!,
        1,
        variants,
      );

    this.selectedItems.value = [item];
  }

  public selectedItemChanged() {
    if (this.areAllItemsInCurrentSection(this.selectedItems.value)) {
      this.setChanged();
    }
  }

  public get isSelectedItemDeletable(): boolean {
    return (
      this.selectedItems.value.length > 0 &&
      this.areAllItemsInCurrentFloorplan(this.selectedItems.value)
    );
  }

  public deleteSelectedItem() {
    if (!this.isSelectedItemDeletable) return;

    this.configurationItemService.deleteInteriorItems(this.selectedItems.value);
    this.selectedItems.value = [];
    this.setChanged();
  }

  private areAllItemsInCurrentSection(
    items: Client.ConfigurationItem[],
  ): boolean {
    for (let item of items) {
      if (
        this.cabinetSection!.interior.items.indexOf(
          item as Client.ConfigurationItem,
        ) < 0
      )
        return false;
    }
    return true;
  }

  private areAllItemsInCurrentFloorplan(
    items: Client.ConfigurationItem[],
  ): boolean {
    let floorplanItems = Enumerable.from(
      this.cabinetSection!.cabinet.floorPlan.actualCabinets,
    )
      .selectMany((cab) => cab.cabinetSections)
      .selectMany((sec) => sec.interior.items);
    return items.every((i) => floorplanItems.contains(i));
  }

  // #endregion selectedItem

  public categoryChanged(category: Client.ProductCategory) {
    let gable = InteriorVm.getFirstGable(category);
    if (gable) {
      this.minDepth = ProductHelper.minDepth(
        gable,
        this.cabinetSection!.cabinet.ProductLineId,
      );
      this.maxDepth = ProductHelper.maxDepth(
        gable,
        this.cabinetSection!.cabinet.ProductLineId,
      );
    } else {
      this.minDepth = null;
      this.maxDepth = null;
    }
  }

  isDepthInvalid(): boolean {
    return (
      (this.minDepth !== null && this.depth < this.minDepth) ||
      (this.maxDepth !== null && this.depth > this.maxDepth)
    );
  }

  public static getFirstGable(
    category: Client.ProductCategory | undefined,
  ): Client.Product | null {
    if (!category) {
      return null;
    }
    for (let product of category.products) {
      if (ProductHelper.isGable(product)) return product;
    }

    for (let childCategory of category.Children) {
      let result = InteriorVm.getFirstGable(childCategory);
      if (result) return result;
    }
    return null;
  }

  // #region favourites and modules

  public async selectFavourites(): Promise<void> {
    let modal = this.modalService.getTemplateSelector(this.cabinetSection!);
    let template: Interface_DTO.Template;
    try {
      template = await modal.result;
    } catch (e: any) {
      //user cancelled
      return;
    }

    this.templateService.applyTemplate(
      this.cabinetSection!,
      template,
      this.depth,
    );
    this.setChanged();
  }

  public saveAsFavourite(): Promise<void> {
    let modal = this.modalService.getTemplateSaver(this.cabinetSection!);
    return modal.result;
  }

  // #endregion favourites

  public updateInteriorDepth(event: any, section: Client.CabinetSection) {
    section.InteriorDepth = event.target.valueAsNumber;
    event.target.value = section.InteriorDepth;
  }
}
