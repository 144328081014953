import {
  Component,
  DoCheck,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AddressService } from 'app/ts/services/AddressService';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { OrderService } from 'app/ts/services/OrderService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Observable } from 'app/ts/util/Observable';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { FavouriteSelectorVm } from '../modals/FavouriteSelectorVm';

@Component({
  selector: 'floor-plan-detail-edit',
  templateUrl: './floorPlanDetailEdit.html',
  styleUrls: [
    '../../../../style/tables.scss',
    '../../../../style/nav.scss',
    '../../../../style/rightMenu.scss',
  ],
  providers: [DeliveryAddressService],
})
export class FloorPlanDetailEditVm extends BaseVm implements OnInit, DoCheck {
  public readonly maxfloorPlanNameLength = Constants.maxfloorPlanNameLength;

  constructor(
    baseVmService: BaseVmService,
    private readonly orderService: OrderService,
    assetService: AssetService,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService,
    loginService: LoginService,
    private readonly permissions: PermissionService,
    private readonly customerService: CustomerService,
    private readonly addressService: AddressService,
    private readonly deliveryAddressService: DeliveryAddressService,
    private readonly floorPlanService: FloorPlanSaveService,
  ) {
    super(baseVmService);
    this.subscribeTo(assetService.salesTypes$, (sts) => {
      this.allSalesTypes = sts;
      this.setSalesTypes();
    });

    this.subscribeTo(assetService.salesChainShippingAgents$, (sts) => {
      if (sts != null && sts.length > 0) {
        let blankShippingAgent: Interface_DTO.SalesChainShippingAgent = {
          Id: -1,
          SalesChainId: -1,
          ShippingAgentCode: '',
          ShippingAgentName: this.translate(
            'Integration_direct_delivery',
            'Direct delivery',
          ),
        };
        if (sts[0].Id != -1) sts.splice(0, 0, blankShippingAgent);
        this.showShippingAgents = true;
      }
      this.salesChainShippingAgents = sts;
    });

    this.updateUserRights();
    this.subscribeTo(loginService.userTokenObservable, (token) =>
      this.updateUserRights(),
    );

    this.subscribeTo(
      assetService.users$,
      (users) =>
        (this.users = users.map((user) => {
          return { id: user.Id, name: user.Name };
        })),
    );
    this.subscribeTo(loginService.salesChainSettings$, (settings) => {
      this.requireSmsOnOrdering =
        settings[Interface_Enums.SalesChainSettingKey.RequireSMSWhenOrdering] ==
        '1';
      this.requireReferenceOnOrdering =
        settings[
          Interface_Enums.SalesChainSettingKey.RequireRequisitionWhenOrdering
        ] == '1';
      this.requireMarketOrderOnOrdering =
        settings[Interface_Enums.SalesChainSettingKey.RequireMarketOrder] ==
        '1';
      this.showSmsField =
        settings[Interface_Enums.SalesChainSettingKey.ShowSms] == '1';
      this.showIntegrationInputFields =
        settings[
          Interface_Enums.SalesChainSettingKey.ShowIntegrationInputFields
        ] == '1';
      this.showMarketOrderNumber =
        settings[Interface_Enums.SalesChainSettingKey.ShowMarketOrderNumber] ==
        '1';
      this.canEditReference =
        settings[Interface_Enums.SalesChainSettingKey.ReferenceFieldReadOnly] !=
        '1';
    });

    this.subscribeToOld(this.smsObservable, (smsNumber) => {
      if (this.dtoObject) {
        this.dtoObject.SmsNumber = smsNumber;
      }
      this.fieldChanged('SmsNumber');
    });
  }

  private updateUserRights() {
    this.showLogisticsComment = this.permissions.canEditLogisticsComment;
    this.showOrderComment = this.permissions.canEditOrderComment;
    this.showManualOrderHandling =
      this.permissions.canEditUseManualOrderHandling;
    this.showSendReminderEmails = this.permissions.canEditSendReminderEmails;
    this.canEditOwner = this.permissions.canEditFloorplanOwner;
  }

  //component property - set by angular after constructor, but before ngOnInit.
  //When changed, $onChanges is called
  @Input()
  public floorPlanOverviews: Client.FloorPlanOverview[] | null = null;
  private oldFloorPlanOverviews: Client.FloorPlanOverview[] | null = null;
  public floorplans: Client.FloorPlan[] | null = null;
  private allSalesTypes: Interface_DTO.SalesType[] = [];
  public salesTypes: Interface_DTO.SalesType[] = [];
  public salesChainShippingAgents: Interface_DTO.SalesChainShippingAgent[] = [];
  public canUpdateOrder = false;
  public canCancelOrder = false;
  public canSave = false;
  public isNotCompatible = false;
  public showActiveLegacyOrderWarning = false;
  public orderStatus: Interface_Enums.DeliveryStatus | null = null;
  public saveButtonActive = false;
  public orderButtonActive = false;
  public updateOrderButtonActive = false;
  public cancelButtonActive = false;

  public requireSmsOnOrdering = false;
  public requireReferenceOnOrdering = false;
  public requireMarketOrderOnOrdering = false;
  public showShippingAgents = false;
  public showIntegrationInputFields = false;
  public showSmsField = false;
  public showOrderComment = false;
  public showLogisticsComment = false;
  public showManualOrderHandling = false;
  public showSendReminderEmails = false;

  public showMarketOrderNumber = false;

  public canEdit: boolean = true;
  public canEditReference: boolean = true;
  public canEditOwner: boolean = false;

  public defaultPhonePrefix: string = '';
  public floor: number | null = null;

  public dtoObject: Interface_DTO.BulkDeliveryInfoEditRequest | null = null;
  public overwrite: Partial<
    Record<keyof Interface_DTO.BulkDeliveryInfoEditRequest, boolean>
  > = {};

  public readonly smsObservable: Observable<string | null> = new Observable<
    string | null
  >(null);

  public get deliveryDateIsFarInFuture(): boolean {
    if (
      this.overwrite &&
      this.overwrite.DeliveryWeek &&
      this.dtoObject &&
      this.dtoObject.DeliveryWeek !== null
    ) {
      return FloorPlanDetailEditVm.weekNumberIsFarInFuture(
        this.dtoObject.DeliveryWeek,
      );
    } else if (this.floorPlanOverviews) {
      return this.floorPlanOverviews.some((fpo) =>
        FloorPlanDetailEditVm.weekNumberIsFarInFuture(fpo.DeliveryWeek),
      );
    } else {
      return false;
    }
  }

  public get isFilledOutCorrectly(): boolean {
    if (!this.dtoObject) return false;
    if (this.requireReferenceOnOrdering) {
      if (ObjectHelper.isNullOrWhitespace(this.dtoObject.Reference))
        return false;
    }
    if (this.requireSmsOnOrdering) {
      if (!this.isValidSmsNumber(this.dtoObject.SmsNumber)) return false;
    }
    return true;
  }

  private isValidSmsNumber(smsNumber: string | undefined | null): boolean {
    if (!smsNumber) return false;
    let regex = /^\+\d{4,}$/; //a + followed by 4 or more digits
    return regex.test(smsNumber);
  }

  public get needsSave(): boolean {
    for (let prop in this.overwrite) {
      if (
        this.overwrite[prop as keyof Interface_DTO.BulkDeliveryInfoEditRequest]
      ) {
        return true;
      }
    }
    return false;
  }

  public get selectedSalesType(): Interface_DTO.SalesType | undefined {
    if (!this.dtoObject) return;
    let stn = this.dtoObject.SalesTypeNumber;
    return (
      Enumerable.from(this.salesTypes).firstOrDefault(
        (st) => st.Number === stn,
      ) || undefined
    );
  }

  public override debug(str: string) {
    console.debug('debug: ' + str);
  }

  public fieldChanged(
    fieldName?: keyof Interface_DTO.BulkDeliveryInfoEditRequest,
  ) {
    if (fieldName) {
      this.overwrite[fieldName] = true;
    }
    this.truncateFields();
    this.forceBlankOrNumbers();
  }

  public truncateFields(): void {
    this.truncate('Name', 50);
    this.truncate('DriverInformation', 50);
    this.truncate('Reference', 20);
    this.truncate('SmsNumber', 20);
  }

  private truncate(
    field: keyof Interface_DTO.BulkDeliveryInfoEditRequest,
    maxLength: number,
  ) {
    if (this.dtoObject) {
      let fieldValue = this.dtoObject[field];
      if (typeof fieldValue === 'string') {
        //I don't know why I need to cast this, but I do
        (<any>this.dtoObject)[field] = ObjectHelper.truncateString(
          fieldValue,
          maxLength,
        );
      }
    }
  }

  public forceBlankOrNumbers(): void {
    this.forceNumber('IntegrationIdentifier');
    this.forceNumber('IntegrationIdentifier2');
    this.forceNumber('IntegrationIdentifier3');
  }

  private forceNumber(field: keyof Interface_DTO.BulkDeliveryInfoEditRequest) {
    if (this.dtoObject) {
      let fieldValue = this.dtoObject[field];
      if (typeof fieldValue === 'string') {
        let number = parseInt(fieldValue.trim());
        if (isNaN(number))
          //I don't know why I need to cast this, but I do
          (<any>this.dtoObject)[field] = '';
        //I don't know why I need to cast this, but I do
        else (<any>this.dtoObject)[field] = number;
      }
    }
  }

  public ngOnInit(): void {
    this.onChanges();
  }

  ngDoCheck(): void {
    if (this.floorPlanOverviews != null && this.oldFloorPlanOverviews == null) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.Id),
        this.oldFloorPlanOverviews.map((fp) => fp.Id),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.CurrentStatus),
        this.oldFloorPlanOverviews.map((fp) => fp.CurrentStatus),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.DeliveryWeek),
        this.oldFloorPlanOverviews.map((fp) => fp.DeliveryWeek),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.UserId),
        this.oldFloorPlanOverviews.map((fp) => fp.UserId),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.Name),
        this.oldFloorPlanOverviews.map((fp) => fp.Name),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.Reference),
        this.oldFloorPlanOverviews.map((fp) => fp.Reference),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.SmsNumber),
        this.oldFloorPlanOverviews.map((fp) => fp.SmsNumber),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.SalesTypeNumber),
        this.oldFloorPlanOverviews.map((fp) => fp.SalesTypeNumber),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.SalesTypeCaseNumber),
        this.oldFloorPlanOverviews.map((fp) => fp.SalesTypeCaseNumber),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.ManualOrderHandling),
        this.oldFloorPlanOverviews.map((fp) => fp.ManualOrderHandling),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.SendReminderEmails),
        this.oldFloorPlanOverviews.map((fp) => fp.SendReminderEmails),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.DriverInformation),
        this.oldFloorPlanOverviews.map((fp) => fp.DriverInformation),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.InternalNote),
        this.oldFloorPlanOverviews.map((fp) => fp.InternalNote),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.OrderComment),
        this.oldFloorPlanOverviews.map((fp) => fp.OrderComment),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }

    if (
      this.floorPlanOverviews != null &&
      this.oldFloorPlanOverviews != null &&
      !ObjectHelper.equals(
        this.floorPlanOverviews.map((fp) => fp.LogisticsComment),
        this.oldFloorPlanOverviews.map((fp) => fp.LogisticsComment),
      )
    ) {
      this.oldFloorPlanOverviews = this.floorPlanOverviews;
      this.onChanges();
    }
  }

  public onChanges(): void {
    // this.overwrite = {};
    if (!this.floorPlanOverviews || this.floorPlanOverviews.length === 0) {
      this.dtoObject = null;
    } else {
      //find any values which are the same across all selected FPOs
      //values that differ are set to null
      let fpos = this.floorPlanOverviews;
      let sameOrNull = FloorPlanDetailEditVm.sameOrNull;
      this.dtoObject = {
        DeliveryInfoIds: this.floorPlanOverviews.map(
          (fpo) => fpo.DeliveryInfoId,
        ),
        DeliveryWeek: sameOrNull(fpos, (fpo) => fpo.DeliveryWeek),
        DriverInformation: sameOrNull(fpos, (fpo) => fpo.DriverInformation),
        InternalNote: sameOrNull(fpos, (fpo) => fpo.InternalNote),
        Name: sameOrNull(fpos, (fpo) => fpo.Name),
        Reference: sameOrNull(fpos, (fpo) => fpo.Reference),
        SalesTypeNumber: sameOrNull(fpos, (fpo) => fpo.SalesTypeNumber),
        SalesTypeCaseNumber: sameOrNull(fpos, (fpo) => fpo.SalesTypeCaseNumber),
        SmsNumber: sameOrNull(fpos, (fpo) => fpo.SmsNumber),
        UserId: sameOrNull(fpos, (fpo) => fpo.UserId),
        SupporterId: sameOrNull(fpos, (fpo) => fpo.SupporterId),
        ModifiedDate: new Date(),
        ShippingAgentCode: sameOrNull(fpos, (fpo) => fpo.ShippingAgentCode),
        IntegrationIdentifier: sameOrNull(
          fpos,
          (fpo) => fpo.IntegrationIdentifier,
        ),
        IntegrationIdentifier2: sameOrNull(
          fpos,
          (fpo) => fpo.IntegrationIdentifier2,
        ),
        IntegrationIdentifier3: sameOrNull(
          fpos,
          (fpo) => fpo.IntegrationIdentifier3,
        ),
        ForceOrder: false,
        LogisticsComment: this.showLogisticsComment
          ? sameOrNull(fpos, (fpo) => fpo.LogisticsComment)
          : null,
        OrderComment: this.showOrderComment
          ? sameOrNull(fpos, (fpo) => fpo.OrderComment)
          : null,
        ManualOrderHandling: this.showManualOrderHandling
          ? sameOrNull(fpos, (fpo) => fpo.ManualOrderHandling)
          : null,
        MarketOrderNumber: sameOrNull(fpos, (fpo) => fpo.MarketOrderNumber),
        SendReminderEmails: this.showSendReminderEmails
          ? sameOrNull(fpos, (fpo) => fpo.SendReminderEmails)
          : null,
      };
      this.floor = sameOrNull(fpos, (fpo) => fpo.deliveryAddress.Floor);
      this.smsObservable.value = this.dtoObject.SmsNumber;
      this.canCancelOrder = this.orderService.isCancelable(fpos);
      if (this.isFloorsPlanCompatible(this.floorPlanOverviews)) {
        this.orderStatus = sameOrNull(fpos, (fpo) => fpo.CurrentStatus);
        this.canSave = this.orderService.isSavable(
          this.floorPlanOverviews || [],
        );
        this.canUpdateOrder =
          this.orderService.isCancelable(this.floorPlanOverviews || []) &&
          !sameOrNull(fpos, (fpo) => fpo.IsLegacyFloorPlan);
        this.canEdit = this.canSave || this.canUpdateOrder;
        this.isNotCompatible = false;
      } else {
        this.isNotCompatible = true;
      }
      this.showActiveLegacyOrderWarning =
        this.floorPlanOverviews.length === 1 &&
        this.canCancelOrder &&
        this.floorPlanOverviews[0].IsLegacyFloorPlan;

      this.resetKeys();

      if (
        sameOrNull(this.floorPlanOverviews, (fpo) => fpo.ActualStatus) ===
        Interface_Enums.DeliveryStatus.Quote
      ) {
        var salesTypeNumber = sameOrNull(
          this.floorPlanOverviews,
          (fpo) => fpo.SalesTypeNumber,
        );
        if (salesTypeNumber != null) {
          if (
            this.salesTypes.length > 0 &&
            !this.allSalesTypes.some(
              (st) => st.Enabled && st.Number === salesTypeNumber,
            )
          ) {
            this.dtoObject.SalesTypeNumber = this.salesTypes[0].Number;
            this.overwrite.SalesTypeNumber = true;
            this.overwrite.SalesTypeCaseNumber = true;
            this.setDefaultSalesTypeCaseNumber();
          }
        }
      }

      let countryCode = sameOrNull(fpos, (fpo) => fpo.deliveryAddress.Country);
      if (countryCode) {
        this.defaultPhonePrefix =
          this.addressService.getPhonePrefix(countryCode) || '';
      }
    }
    this.setSalesTypes();
  }

  public onChanges2(): void {
    // this.overwrite = {};
    if (!this.floorPlanOverviews || this.floorPlanOverviews.length === 0) {
      this.dtoObject = null;
    } else {
      //find any values which are the same across all selected FPOs
      //values that differ are set to null
      let fpos = this.floorPlanOverviews;
      let sameOrNull = FloorPlanDetailEditVm.sameOrNull;
      this.floor = sameOrNull(fpos, (fpo) => fpo.deliveryAddress.Floor);
      this.canCancelOrder = this.orderService.isCancelable(fpos);
      if (this.isFloorsPlanCompatible(this.floorPlanOverviews)) {
        this.orderStatus = sameOrNull(fpos, (fpo) => fpo.CurrentStatus);
        this.canSave = this.orderService.isSavable(
          this.floorPlanOverviews || [],
        );
        this.canUpdateOrder =
          this.orderService.isCancelable(this.floorPlanOverviews || []) &&
          !sameOrNull(fpos, (fpo) => fpo.IsLegacyFloorPlan);
        this.canEdit = this.canSave || this.canUpdateOrder;
        this.isNotCompatible = false;
      } else {
        this.isNotCompatible = true;
      }
      this.showActiveLegacyOrderWarning =
        this.floorPlanOverviews.length === 1 &&
        this.canCancelOrder &&
        this.floorPlanOverviews[0].IsLegacyFloorPlan;

      this.resetKeys();

      if (
        sameOrNull(this.floorPlanOverviews, (fpo) => fpo.ActualStatus) ===
        Interface_Enums.DeliveryStatus.Quote
      ) {
        var salesTypeNumber = sameOrNull(
          this.floorPlanOverviews,
          (fpo) => fpo.SalesTypeNumber,
        );
        if (salesTypeNumber != null) {
          if (
            this.salesTypes.length > 0 &&
            !this.allSalesTypes.some(
              (st) => st.Enabled && st.Number === salesTypeNumber,
            )
          ) {
            this.overwrite.SalesTypeNumber = true;
            this.overwrite.SalesTypeCaseNumber = true;
            this.setDefaultSalesTypeCaseNumber();
          }
        }
      }

      let countryCode = sameOrNull(fpos, (fpo) => fpo.deliveryAddress.Country);
      if (countryCode) {
        this.defaultPhonePrefix =
          this.addressService.getPhonePrefix(countryCode) || '';
      }
    }
    this.setSalesTypes();
  }

  private resetKeys() {
    for (let key in this.dtoObject) {
      this.overwrite[key as keyof Interface_DTO.BulkDeliveryInfoEditRequest] =
        false;
    }
  }

  private setSalesTypes(): void {
    let salesTypeNumber = this.dtoObject
      ? this.dtoObject.SalesTypeNumber
      : null;
    this.salesTypes = this.allSalesTypes.filter(
      (st) => st.Enabled || st.Number === salesTypeNumber,
    );
  }

  public setDefaultSalesTypeCaseNumber() {
    if (this.dtoObject) {
      let salesType = Enumerable.from(this.salesTypes).firstOrDefault(
        (st) => st.Number === this.dtoObject!.SalesTypeNumber,
      );

      this.dtoObject.SalesTypeCaseNumber =
        salesType && salesType.SalesTypeCases[0]
          ? salesType.SalesTypeCases[0].Number
          : '';
    }
  }

  public get canOrder(): boolean {
    if (!this.isOrderable(this.floorPlanOverviews || [])) {
      return false;
    }
    for (let key in this.overwrite) {
      if ((this.overwrite as any)[key]) return false;
    }
    return true;
  }

  private isOrderable(fpos: Client.FloorPlanOverview[]): boolean {
    for (let fpo of fpos) {
      try {
        this.orderService.assertIsOrderable(fpo);
      } catch (e: any) {
        return false;
      }
    }
    return true;
  }

  public isFloorsPlanCompatible(fp: Client.FloorPlanOverview[]): boolean {
    for (let fpo of fp) {
      try {
        if (
          !(fpo.AppCompatibility & Interface_Enums.AppCompatibility.RetailHtml5)
        )
          return false;
      } catch (e: any) {
        return false;
      }
    }
    return true;
  }

  private static weekNumberIsFarInFuture(weekNumber: number): boolean {
    if (weekNumber === 0) return false;
    let now = new Date();
    let nowWeek = DateHelper.GetIsoWeek(now);

    let fpoDate = new Date();

    if (weekNumber < nowWeek.weekNumber) {
      fpoDate.setFullYear(fpoDate.getFullYear() + 1);
    }
    fpoDate.setDate(fpoDate.getDate() + 7 * (weekNumber - nowWeek.weekNumber));

    let diffMs = fpoDate.valueOf() - now.valueOf();
    if (diffMs > Constants.overview.orderFarInFutureMs) {
      return true;
    }
    return false;
  }

  private static sameOrNull<TIn, TResult>(
    fpos: TIn[],
    func: (fpo: TIn) => TResult | null,
  ): TResult | null {
    if (!fpos || fpos.length < 1) return null;

    return fpos.map(func).reduce((prev, curr) => (prev === curr ? prev : null));
  }

  public users: { id: number; name: string }[] = [];

  public async save() {
    try {
      let successTask = this.save2();

      let success = await this.notificationService.loadPromise(successTask);
      //let success = await successTask;
      if (success) {
        this.notificationService.success(
          'floorplan_order_edit_success',
          'Edit success',
        );
        this.resetKeys();
        this.onChanges(); // Maybe Hacky hack hack
      }
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (
          e.Type ===
          Interface_DTO_DomainError.ErrorType.IntegrationNumbersInvalid
        ) {
          this.notificationService.userError(
            'integration_numbers_invalid',
            'Ingtegration numbers are invalid.',
          );
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.ShippingAgentRequired
        ) {
          this.notificationService.userError(
            'integration_shipping_agent_required',
            'Integration shipping agent is required.',
          );
        }
      } else
        this.notificationService.exception(
          'floorPlan_order_edit_failed',
          e,
          'Save failed',
        );
    }
  }

  private async save2(): Promise<boolean> {
    if (this.saveButtonActive) return false;

    if (!this.dtoObject) throw new Error('cannot save null');
    let saveObject = ObjectHelper.copy(this.dtoObject);

    if (!this.floorPlanOverviews)
      throw new Error('ArgumentNull: this.floorPlanOverviews');

    this.overwrite.SalesTypeCaseNumber = this.overwrite.SalesTypeNumber;
    if (
      this.overwrite.ShippingAgentCode &&
      saveObject.ShippingAgentCode === null
    )
      saveObject.ShippingAgentCode = '';

    let keys = Object.keys(saveObject);
    for (let key of keys) {
      if (key === 'DeliveryInfoIds' || key === 'ForceOrder') continue;
      if (
        !this.overwrite[key as keyof Interface_DTO.BulkDeliveryInfoEditRequest]
      )
        (<any>saveObject)[key] = null;
    }
    if (saveObject.Name) {
      saveObject.Name = ObjectHelper.sanitizeString(saveObject.Name);
    }

    if (!this.showOrderComment) {
      saveObject.OrderComment = null;
    }
    if (!this.showManualOrderHandling) {
      saveObject.ManualOrderHandling = null;
    }
    if (!this.showLogisticsComment) {
      saveObject.LogisticsComment = null;
    }

    try {
      await this.orderService.saveDeliveryInfo(saveObject);
    } finally {
      this.saveButtonActive = false;
    }
    return true;
  }

  public cancel() {
    console.debug('dtoObject: ', this);
    this.onChanges();
  }

  public async order() {
    if (this.orderButtonActive) return;
    if (!this.canOrder) return;

    if (this.needsSave) {
      return;
    }
    if (!this.floorPlanOverviews)
      throw new Error('NullReferenceException: this.floorPlanOverviews');

    // Is any of the floorplans empty
    if (
      this.floorPlanOverviews.some(
        (fpo) => fpo.NumCabinets == 0 && fpo.NumManualItems == 0,
      )
    ) {
      this.notificationService.userError(
        'floorplan_order_failed_order_empty',
        'The floorplan is empty and cannot be ordered',
      );
      return;
    }

    let count = 0;
    let orderPromises: Promise<void>[] = [];
    try {
      this.orderButtonActive = true;

      for (let fpo of this.floorPlanOverviews) {
        this.deliveryAddressService.validateDeliveryAddress(
          fpo.deliveryAddress,
        );
        let deliveryInfo = await this.notificationService.loadPromise(
          this.orderService.getCorrectDeliveryInfo(fpo),
        );
        if (!deliveryInfo.CorrectionDeadlineDate) {
          this.notificationService.systemError(
            'order_deliveryinfo_missing_correctionDeadline',
            'Could not determine delivery deadline.',
          );
          return;
        }
        let wasDeliveryWeekChanged =
          fpo.DeliveryWeek !== deliveryInfo.DeliveryWeek;
        const deliveryAddress =
          await this.deliveryAddressService.getDeliveryAddressForDeliveryInfoId(
            deliveryInfo.Id,
          );
        let userAccepted = await this.modalService.acceptDeliveryInfo(
          deliveryInfo,
          fpo.Name,
          wasDeliveryWeekChanged,
          fpo.CurrentStatus,
          deliveryAddress,
        );
        if (userAccepted) {
          let orderPromise = this.orderFpo(fpo, () => count++);
          orderPromises.push(orderPromise);
        } else {
          this.notificationService.warning(
            'floorPlan_order_cancelled',
            'Floor plan not ordered',
          );
          break;
        }
      }
      await this.notificationService.loadPromise(Promise.all(orderPromises));
    } catch (e: any) {
      let errorHandled = false;
      if (e instanceof Exception.PropertyInvalid) {
        if (e.object instanceof Client.DeliveryAddress) {
          this.notificationService.userError(
            'floorplan_order_failed_delivery_address_invalid',
            'The delivery address is not valid',
          );
          errorHandled = true;
        } else {
          //do nothing - browser will display error when the form is invalid
          errorHandled = true;
        }
      } else if (e instanceof Exception.ServerDomainException) {
        this.baseVmService.notificationService.exceptionWithDefaultText(e);
        errorHandled = true;
      }
      if (!errorHandled) {
        this.notificationService.exception(
          'floorplan_order_failed',
          e,
          'order failed',
        );
      }
    } finally {
      this.orderButtonActive = false;
      await this.customerService.updateCustomerList();
      if (count === 1) {
        this.notificationService.success(
          'floorplan_ordered_single',
          'Floor Plan Ordered',
        );
      } else if (count > 1) {
        this.notificationService.success(
          'floorplan_ordered_num_{0}',
          '{0} floor plans ordered',
          count.toString(),
        );
      }
    }
  }

  private async orderFpo(
    fpo: Interface_DTO.FloorPlanOverview,
    done: () => void,
  ) {
    await this.orderService.sendToOrder(fpo);
    await this.floorPlanService.sendScreenshots(fpo.Id);
    done();
  }

  public async updateOrder() {
    if (this.updateOrderButtonActive) return;
    if (!this.canUpdateOrder) return;
    if (!this.floorPlanOverviews) return;
    if (!this.dtoObject) return;

    let count = 0;
    try {
      this.updateOrderButtonActive = true;
      for (let fpo of this.floorPlanOverviews) {
        if (
          this.overwrite.ShippingAgentCode &&
          this.dtoObject.ShippingAgentCode === null
        )
          this.dtoObject.ShippingAgentCode = '';
        let deliveryInfo = await this.notificationService.loadPromise(
          this.orderService.getUpdatedDeliveryInfo(fpo, this.dtoObject),
        );
        let wasDeliveryWeekChanged =
          this.dtoObject.DeliveryWeek !== deliveryInfo.DeliveryWeek;
        const deliveryAddress =
          await this.deliveryAddressService.getDeliveryAddressForDeliveryInfoId(
            deliveryInfo.Id,
          );
        let userAccepted = await this.modalService.acceptDeliveryInfo(
          deliveryInfo,
          fpo.Name,
          wasDeliveryWeekChanged,
          fpo.CurrentStatus,
          deliveryAddress,
        );

        if (userAccepted) {
          try {
            await this.notificationService.loadPromise(
              this.orderService.updateOrder(fpo, this.dtoObject),
            );
          } catch (e: any) {
            this.notificationService.exception(
              'floorplan_reorder_failed',
              e,
              'Order could not be updated',
            );
            break;
          }
          count++;
        } else {
          this.notificationService.warning(
            'floorPlan_reorder_cancelled',
            'Floor plan not updated',
          );
          break;
        }
      }
    } catch (e: any) {
      let errorHandled = false;
      if (e instanceof Exception.ServerDomainException) {
        if (
          e.Type ===
          Interface_DTO_DomainError.ErrorType.IntegrationNumbersInvalid
        ) {
          errorHandled = true;
          this.notificationService.userError(
            'integration_numbers_invalid',
            'Ingtegration numbers are invalid.',
          );
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.ShippingAgentRequired
        ) {
          errorHandled = true;
          this.notificationService.userError(
            'integration_shipping_agent_required',
            'Integration shipping agent is required.',
          );
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.FloorOptionNotAllowed
        ) {
          errorHandled = true;
          this.notificationService.userError(
            'floorplan_save_failed_floor_option_invalid',
            'Floor option is not allowed for this zip code',
          );
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.FloorPlanPriceChanged
        ) {
          errorHandled = true;
          this.notificationService.userError(
            'order_change_failed_price_changed',
            'Cannot update order because the total price has changed',
          );
        }
      }
      if (!errorHandled) {
        this.notificationService.exception(
          'floorplan_order_update_failed',
          e,
          'order update failed',
        );
      }
    } finally {
      this.updateOrderButtonActive = false;
      await this.customerService.updateCustomerList();
      if (count > 0) {
        if (count === 1) {
          this.notificationService.success(
            'floorplan_reordered_single',
            'Floor plan updated',
          );
        } else {
          this.notificationService.success(
            'floorplan_reordered_num_{0}',
            '{0} floor plans updated',
          );
        }
      }
    }
  }

  public async cancelOrder() {
    if (this.cancelButtonActive) return;
    if (!this.canCancelOrder) return;
    if (!this.floorPlanOverviews) return;

    let count = 0;
    try {
      this.cancelButtonActive = true;

      for (let fpo of this.floorPlanOverviews) {
        await this.orderService.cancelOrder(fpo);
        count++;
      }
    } catch (e: any) {
      this.notificationService.exception(
        'floorplan_order_cancel_failed',
        e,
        'Cancelling the order failed',
      );
    } finally {
      await this.customerService.updateCustomerList();
      if (count > 0) {
        this.notificationService.success(
          'floorplan_order_cancelled_num_{0}',
          '{0} orders cancelled',
          count.toString(),
        );
      }
      this.cancelButtonActive = false;
    }
  }

  protected phoneNumberChange(phoneNumber: string) {
    this.smsObservable.value = phoneNumber;
  }
}
export class SalesChainShippingAgentClass
  implements Interface_DTO.SalesChainShippingAgent
{
  Id: number = 0;
  SalesChainId: number = 0;
  ShippingAgentCode: string | null = null;
  ShippingAgentName: string = '';
}
