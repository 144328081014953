import { Copyable } from 'app/ts/interfaces';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Module } from './module';

export class Filling implements Copyable<Filling> {
  constructor(private _data: FillingData) {}

  public get data(): FillingData {
    return this._data;
  }

  public get id(): FillingId {
    return this.data.id;
  }

  public get xOffset(): number {
    return this.data.xOffset;
  }

  public set xOffset(val: number) {
    this.data.xOffset = val;
  }

  public get width(): number {
    return this.data.width;
  }

  public set width(val: number) {
    this.data.width = val;
  }

  public get height(): number {
    return this.data.height;
  }

  public set height(val: number) {
    this.data.height = val;
  }

  public get depth(): number {
    return this.data.depth;
  }

  public get materialId(): number {
    return this.data.materialId;
  }

  public set materialId(val: number) {
    this.data.materialId = val;
  }

  copy(): Filling {
    return new Filling(ObjectHelper.copy(this.data));
  }
}

export interface FillingData {
  id: FillingId;
  materialId: number;
  xOffset: number;
  width: number;
  height: number;
  depth: number;
}

export type FillingId = number & { _fillingIds: never };
