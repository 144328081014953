<div class="modal-content custom-price-date-modal">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('custom_price_date_modal_title', 'Custom Price Date')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <label style="gap: 0.5rem">
            <input type="radio" [(ngModel)]="useCustomDate" value="0" />

            <span
              [textContent]="translate('custom_price_date_modal_use_current_date', 'Use current date')"
            ></span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <label style="gap: 0.5rem">
            <input type="radio" [(ngModel)]="useCustomDate" value="1" />
            <span
              [textContent]="translate('custom_price_date_modal_use_specific_date', 'Use date: ')"
            ></span>
            <input
              type="datetime-local"
              [ngModel]="date | date:'yyyy-MM-ddTHH:mm'"
              (ngModelChange)="useCustomDate = '1'; updateDate($event)"
            />
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="close()"
        [textContent]="translate('button_save', 'Save')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
