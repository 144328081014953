<div
  class="editor-all editor-validation no-ribbon name no-left-menu"
  *ngIf="editorType.ready"
>
  <editor-topbar
    [vm]="this"
    [selection]="'validation'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div class="editor-center scrollable">
    <ng-container *ngIf="floorPlan.errorInfos.length > 0">
      <div class="floor-plan floor-plan-validation">
        <h3
          [textContent]="translate('validation_floorplan_header', 'Floor Plan')"
        ></h3>
        <error-info
          *ngFor="let errorInfo of floorPlan.errorInfos"
          [errorInfo]="errorInfo"
        ></error-info>
      </div>
    </ng-container>

    <ng-container *ngFor="let cabinet of floorPlan.cabinets">
      <div class="cabinet cabinet-validation" *ngIf="showCabinet(cabinet)">
        <h4
          [textContent]="translate('validation_cabinet_{0}_header2', '{1}', cabinet.CabinetIndex, cabinet.Name)"
        ></h4>
        <error-info
          *ngFor="let errorInfo of cabinet.errorInfos"
          [errorInfo]="errorInfo"
        ></error-info>

        <ng-container *ngIf="hasCabinetSectionErrors(cabinet)">
          <div
            class="cabinet-section cabinet-section-validation"
            *ngFor="let cabinetSection of cabinet.cabinetSections"
          >
            <ng-container
              *ngIf="cabinetSection.cabinet.cabinetSections.length > 1 && cabinetSection.errorInfos.length > 0"
            >
              <h5
                [textContent]="translate('validation_cabinet_{0}_section_{1}__header', 'Cabinet Section {1}', 
                    cabinet.CabinetIndex, 
                    cabinetSection.CabinetSectionIndex,
                    cabinet.Name,
                    cabinetSection.Name
                    )"
              ></h5>
            </ng-container>
            <error-info
              *ngFor="let errorInfo of cabinetSection.errorInfos"
              [errorInfo]="errorInfo"
            ></error-info>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div
      class="no-validation-errors"
      [textContent]="translate('validation_no_errors', 'Everything is good')"
      *ngIf="!anyErrors"
    ></div>
  </div>

  <div class="tool-menu right-tool-menu">
    <div class="panel">
      <h4 [textContent]="translate('validation_legend_header', 'Legend')"></h4>
      <div class="legend-item error-info" errorlevel="20">
        <div class="description">
          <span
            [textContent]="translate('validation_text_20', 'Critical error. Cannot be ordered.')"
          ></span>
        </div>
        <div
          class="logo floorplan-errorlevel-cell floorplan-errorlevel-cell-20"
        ></div>
      </div>
      <div class="legend-item error-info" errorlevel="10">
        <div class="description">
          <span
            [textContent]="translate('validation_text_10', 'Issue that might be a problem. Can be ordered, but issues should be considered.')"
          ></span>
        </div>
        <div
          class="logo floorplan-errorlevel-cell floorplan-errorlevel-cell-10"
        ></div>
      </div>
      <div class="legend-item error-info" errorlevel="5">
        <div class="description">
          <span
            [textContent]="translate('validation_text_5', 'Suggestion.')"
          ></span>
        </div>
        <div
          class="logo floorplan-errorlevel-cell floorplan-errorlevel-cell-5"
        ></div>
      </div>
    </div>
  </div>
  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
