import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';

export class CorpusValidationService {
  public static readonly Name = 'corpusValidationService';

  constructor() {}

  public accessTest() {
    return 'Corpus Validation Service access test function';
  }

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    if (
      section.CabinetType === Interface_Enums.CabinetType.Swing ||
      section.CabinetType === Interface_Enums.CabinetType.SwingFlex ||
      section.CabinetType === Interface_Enums.CabinetType.SharedItems
    )
      return errorInfos;

    errorInfos.push(
      ...CorpusValidationService.validateHasCorpusMaterial(section),
    );
    errorInfos.push(
      ...CorpusValidationService.validateHasValidCorpusMaterial(section),
    );

    errorInfos.push(
      ...CorpusValidationService.validateSidePanelHeight(section),
    );
    errorInfos.push(...CorpusValidationService.validateSidePanelDepth(section));

    errorInfos.push(
      ...CorpusValidationService.validateGableDrillingPosition(section),
    );
    errorInfos.push(...CorpusValidationService.validateGableDrilled(section));
    errorInfos.push(
      ...CorpusValidationService.validateEdgingTopAndBottom(section),
    );

    errorInfos.push(...CorpusValidationService.validateRoofSupport(section));
    errorInfos.push(
      ...CorpusValidationService.validateRoofJointSupport(section),
    );

    errorInfos.push(...CorpusValidationService.validateProducts(section));
    errorInfos.push(...CorpusValidationService.validateMaterials(section));

    return errorInfos;
  }

  public static validateHasCorpusMaterial(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (
      section.corpus.panelTop ||
      section.corpus.panelLeft ||
      section.corpus.panelRight ||
      section.corpus.panelBottom
    ) {
      if (!section.corpus.material) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Critical,
            'No corpus material selected',
            'You need to select a corpus material',
            section,
          ),
        );
      }
    }

    return result;
  }

  public static validateHasValidCorpusMaterial(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    if (!section.corpus.material) return [];
    let selectedMaterialId = section.corpus.material.Id;

    let corpusProducts = Enumerable.from(section.corpus.allCorpusItems)
      .select((item) => item.Product!)
      .where((product) => !!product)
      .distinct((product) => product.Id)
      .toArray();

    let fullCatalog =
      section.editorAssets.fullCatalog &&
      section.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    for (let product of corpusProducts) {
      let matId = Enumerable.from(product.PossibleMaterialIds).firstOrDefault(
        (m) => m.Id === selectedMaterialId,
      );
      if (!matId || !matId.IsEnabled || (!fullCatalog && matId.IsOverride)) {
        return [
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Critical,
            'Invalid corpus material',
            'One or more corpus panels is not available in the selected material',
            section,
          ),
        ];
      }
    }
    return [];
  }

  /**
   * Validates the height of the side panels
   * If a side panel may not be joined and it is higher than product max height, it will fail.
   */
  public static validateSidePanelHeight(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let cabinetHeight = section.Height;

    if (!section.corpus.material) {
      return result;
    }

    let sides: { panel: Client.CorpusPanel; deduction: number }[] = [
      {
        //left side
        panel: section.corpus.panelLeft,
        deduction:
          section.corpus.leftDeductionTop + section.corpus.leftDeductionBottom,
      },
      {
        //right side
        panel: section.corpus.panelRight,
        deduction:
          section.corpus.rightDeductionTop +
          section.corpus.rightDeductionBottom,
      },
    ];

    let productLineId = section.cabinet.ProductLineId;
    for (let side of sides) {
      if (side.panel.isNone) continue;
      let panelHeight = cabinetHeight - side.deduction;
      if (panelHeight > side.panel.maxHeight(section.corpus.material)) {
        //if all products in the panel can be joined together, do that.
        if (
          !side.panel.products.every((p) =>
            p
              .getVariants(productLineId)
              .some((v) => v.Number === VariantNumbers.JointPosition),
          )
        ) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.Corpus,
              Interface_Enums.ErrorLevel.Critical,
              'Item not high enough.',
              'Side item is not tall enough for the cabinet height.',
              section,
            ),
          );
        }
      }
    }

    return result;
  }

  /**
   * Validates the depth of the corpus panels
   */
  public static validateSidePanelDepth(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (!section.corpus.material) return result;

    // Left
    if (
      section.corpus.panelLeft &&
      section.corpus.panelLeft.isFlexDepth2(section.cabinet.ProductLineId) &&
      section.corpus.depthLeft >
        section.corpus.panelLeft.maxDepth(
          section.corpus.material,
          section.cabinet.ProductLineId,
        )
    )
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Corpus,
          Interface_Enums.ErrorLevel.Critical,
          'Product depth is not valid',
          'Side item is not valid in this depth.',
          section,
        ),
      );

    // Right
    if (
      section.corpus.panelRight &&
      section.corpus.panelRight.isFlexDepth2(section.cabinet.ProductLineId) &&
      section.corpus.depthRight >
        section.corpus.panelRight.maxDepth(
          section.corpus.material,
          section.cabinet.ProductLineId,
        )
    )
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Corpus,
          Interface_Enums.ErrorLevel.Critical,
          'Product depth is not valid',
          'Side item is not valid in this depth.',
          section,
        ),
      );

    return result;
  }

  /**
   * Validates side panel drilling positions.
   */
  public static validateGableDrillingPosition(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // Drilled outer gable + not same Y-position as inner gables + no heightadjustment
    if (!section.corpus.panelBottom) {
      return result;
    }

    let drilledLeft = section.corpus.isDrilledLeft;
    let drilledRight = section.corpus.isDrilledRight;

    if (
      (drilledLeft || drilledRight) &&
      !section.corpus.panelBottom.isFullSize() &&
      section.corpus.panelBottom.isFullDepth(section)
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Corpus,
          Interface_Enums.ErrorLevel.Warning,
          'Gable adjustment needed.',
          'Outer gables must be adjusted, so drilled holes match interior gable holes.',
          section,
        ),
      );

      if (
        (drilledLeft && section.HeightReductionCorpusLeft) ||
        (drilledRight && section.HeightReductionCorpusRight)
      )
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Critical,
            'Height reduction width drilling and bottom.',
            'Height reduction, drilling and full-depth-bottom are not possible at the same time.',
            section,
          ),
        );
    }
    return result;
  }

  /**
   * Validates if non drilled gable have interior items snapped to them
   */
  public static validateGableDrilled(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    let requiresDrilling = false;
    let requiresManualDrilling = false;
    // Left
    if (
      section.corpus.panelLeft &&
      section.corpus.panelLeft.isFullDepth(section) &&
      !section.corpus.isDrilledLeft
    ) {
      let corpusItem = Enumerable.from(section.corpus.itemsLeft)
        .orderBy((item) => item.rightX)
        .lastOrDefault();
      let itemIsTouchingGable = section.interior.items.some(
        (item) =>
          !item.isGable &&
          Math.abs(item.leftX - corpusItem!.rightX) <
            Constants.sizeAndPositionTolerance,
      );
      if (corpusItem != null && itemIsTouchingGable) {
        requiresDrilling = true;

        requiresManualDrilling =
          (corpusItem.Product!.getProductData()!.Type &
            Interface_Enums.ProductDataType.RequiresManualDrilling) ===
          Interface_Enums.ProductDataType.RequiresManualDrilling;
      }
    }

    // Right
    if (
      section.corpus.panelRight &&
      section.corpus.panelRight.isFullDepth(section) &&
      !section.corpus.isDrilledRight
    ) {
      let corpusItem = Enumerable.from(section.corpus.itemsRight)
        .orderBy((item) => item.rightX)
        .firstOrDefault();
      if (
        corpusItem != null &&
        section.interior.items.some(
          (item) =>
            !item.isGable &&
            Math.abs(item.rightX - corpusItem!.leftX) <
              Constants.sizeAndPositionTolerance,
        )
      ) {
        requiresDrilling = true;
        requiresManualDrilling =
          requiresManualDrilling ||
          (corpusItem.Product!.getProductData()!.Type &
            Interface_Enums.ProductDataType.RequiresManualDrilling) ===
            Interface_Enums.ProductDataType.RequiresManualDrilling;
      }
    }

    if (requiresDrilling) {
      if (requiresManualDrilling) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Warning,
            'Gable is not drilled, factory drilling not available.',
            'Outer gables should be drilled, if interior items are positioned against them. Drilling cannot be done from factory.',
            section,
          ),
        );
      } else {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Warning,
            'Gable is not drilled.',
            'Outer gables should be drilled, if interior items are positioned against them.',
            section,
          ),
        );
      }
    }

    return result;
  }

  /**
   * Validates edging on top/bottom items, where edges are visible.
   * If the ends of an item is visible, edging should be used.
   */
  public static validateEdgingTopAndBottom(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (
      section.corpus.panelTop.isFullSize() &&
      section.corpus.itemsTop.some(
        (item) => !ConfigurationItemHelper.hasEdgingTop(item),
      )
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Corpus,
          Interface_Enums.ErrorLevel.Info,
          'Edging should be added',
          'Edging should be added with this type of top panel.',
          section,
        ),
      );
    }

    if (
      section.corpus.panelBottom.isFullSize() &&
      section.corpus.itemsBottom.some(
        (item) => !ConfigurationItemHelper.hasEdgingBottom(item),
      )
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Corpus,
          Interface_Enums.ErrorLevel.Info,
          'Edging should be added',
          'Edging should be added with this type of bottom panel.',
          section,
        ),
      );
    }

    return result;
  }

  /**
   * Validates roof for needed support
   * Roof should be supported by gables for each 1500 mm
   */
  public static validateRoofSupport(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (
      section.corpus.itemsTop.length > 0 &&
      section.corpus.panelTop.isFullDepth(section)
    ) {
      let roofSupportError: boolean = false;

      let supportGables = section.interior.items.filter(
        (ii) => ii.isGable && ii.Height >= section.InteriorHeight,
      );
      supportGables.sort((a, b) => a.X - b.X);

      let position: number = 0;
      let currentX: number = 0;
      let nextX: number = 0;

      for (let gable of supportGables) {
        if (gable.X - currentX > Constants.roofSupportDistance) {
          roofSupportError = true;
          break;
        }

        currentX = gable.rightX;
      }

      // Check distance from the last gable to the end of the top corpus panel
      if (
        !roofSupportError &&
        section.corpus.itemsTop[0].rightX - currentX >
          Constants.roofSupportDistance
      ) {
        roofSupportError = true;
      }

      if (roofSupportError) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Warning,
            'Roof support',
            new Client.Translatable(
              'validation_missing_roof_support',
              'Roof should be supported by flex height gables for each {0} mm',
              Constants.roofSupportDistance.toString(),
            ),
            section,
          ),
        );
      }
    }

    return result;
  }

  /**
   * Validates roof joints for needed support
   */
  public static validateRoofJointSupport(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // Roof joints should be covered by gables
    if (
      section.corpus.itemsTop.length > 0 &&
      section.corpus.panelTop.isFullDepth(section)
    ) {
      let supportGables = section.interior.items.filter(
        (ii) => ii.isGable && ii.Height >= section.InteriorHeight,
      );

      for (let joint of section.corpus.jointsTop) {
        // Check if the center of a gable is within 5mm of the joint.
        if (
          !supportGables.some(
            (gable) =>
              Math.abs(
                gable.centerX - (section.corpus.topDeductionLeft + joint),
              ) <= 5,
          )
        ) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.Corpus,
              Interface_Enums.ErrorLevel.Warning,
              'Corpus panel joints, top',
              'Corpus panel joints could not be placed over a gable with flex height.',
              section,
            ),
          );
          break;
        }
      }
    }
    return result;
  }

  /**
   * Validates the product for all corpus items
   */
  public static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateProducts(
      section.corpus.allItems,
      Enums.EditorSection.Corpus,
      section,
    );
  }

  /**
   * Validates the material for all corpus items
   */
  public static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateMaterials(
      section.corpus.allItems,
      Enums.EditorSection.Corpus,
      section,
    );
  }
}
