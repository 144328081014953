import { Injectable } from '@angular/core';
import { Defaults } from './defaults';
import { AssetService } from 'app/ts/services/AssetService';
import { PartitionModule } from './partition.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MaterialType } from 'app/ts/Interface_Enums';
import { filter } from 'rxjs';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ProductLineIds } from 'app/ts/InterfaceConstants';

/**
 * Setups and retrieves various defaults for partition
 * editing
 */
@Injectable({
  providedIn: PartitionModule,
})
export class PartitionDefaultsService {
  constructor(editorAssets: AssetService) {
    editorAssets.editorAssets$
      .pipe(
        filter((editorAssets) => editorAssets.products.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe((editorAssets) => {
        const lookupProductLine = (lineId: number) => {
          return editorAssets.productLines.find((pl) => pl.Id == lineId);
        };

        const lookupProductId = (productNo: string) => {
          return editorAssets.products.filter(
            (p) => p.ProductNo === productNo,
          )[0]?.Id;
        };

        const lookupMaterialId = (
          productNo: string,
          materialType: MaterialType,
        ) => {
          let productId = lookupProductId(productNo);
          let materials = editorAssets.productsDict[productId].materials
            .filter((m) => m.Type == materialType)
            .sort((a, b) => {
              function comparer(
                a: string | number,
                b: string | number,
              ): number {
                if (a > b) return 1;
                if (b > a) return -1;
                return 0;
              }

              return (
                comparer(a.groupSortOrder, b.groupSortOrder) ||
                comparer(a.SortOrder, b.SortOrder) ||
                comparer(a.Number, b.Number)
              );
            });

          return materials[0].Id;
        };

        const lookupVariantOptionId = (
          variantNumber: string,
          optionNumber: string,
        ) => {
          if (editorAssets.variants.length == 0) return -1;
          return editorAssets.variants
            .filter((variant) => variant.Number === variantNumber)[0]
            .VariantOptions.filter(
              (option) => option.Number === optionNumber,
            )[0].Id;
        };

        const lookupRailSetId = (productId: number, type: number) => {
          return editorAssets.railSets.filter(
            (rs) => rs.ProductId == productId && rs.Type == type,
          )[0].Id;
        };

        this._defaults = {
          numberOfModulesInSection: 3,
          sectionWidth:
            lookupProductLine(ProductLineIds.Partition)?.DefaultWidth ?? 1500,
          wallDepth: 41,
          partitionNamePrefix: 'Partition ',
          fillingMaterialId: lookupMaterialId('282', MaterialType.Normal),
          moduleWallProductId: lookupProductId('282'),
          moduleDoorProductId: lookupProductId('28'),
          endStopProductId: lookupProductId('9628'),
          sectionProfileMaterialId: lookupMaterialId('282', MaterialType.Frame),
          sectionBarHeight: 30,
          verticalBarOptionId: lookupVariantOptionId('SPROS_LODR', '0'),
          wallRailSetId: lookupRailSetId(lookupProductId('282'), 2),
          doorRailSetId: lookupRailSetId(lookupProductId('28'), 2),

          joinProfileProductIds: {
            startStop: lookupProductId('281'),
            corner: lookupProductId('286'),
            tpiece: lookupProductId('285'),
            niche: lookupProductId('284'),
          },
        };
      });
  }

  public get defaults(): Defaults {
    return this._defaults;
  }

  private _defaults!: Defaults;
}
