import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { Interior as Client_Interior } from 'app/ts/clientDto/SubSectionInterior';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { Injectable } from '@angular/core';
import { BackingType } from 'app/ts/Interface_Enums';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';

@Injectable({ providedIn: 'root' })
export class InteriorLogic {
  public static readonly Name = 'interiorLogic';

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  // #region recalculation

  public recalculatePartOne(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Interior part one');

    let result: Client.RecalculationMessage[] = [];

    result.push(...this.removeUnknownProducts(section));

    // Ensure all general values are set and valid
    this.ensureValuesAreValid(section);

    if (!section.isSwing) {
      this.adjustYPositions(section);
    }

    this.adjustGableHeight(section);

    this.adjustYPositionsToDrillStop(section);

    this.moveModulesInside(section);

    this.recalculateModuleChildrenPositions(section);

    if (!section.isSwing && !section.isSwingFlex) {
      result.push(...this.adaptToWidth(section)); // Adjust to gable width changes
    }

    this.adjustVerticalDivider(section);

    if (section.isSwingFlex) {
      this.adjustFittingPanelXPositions(section);
      this.adjustFittingPanelYZPositions(section);
      this.adjustItemToGable(section);
      this.adjustYPositionsToDrillStop(section);
    }

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Interior part one');

    return result;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Interior part two');

    let result: Client.RecalculationMessage[] = [];

    this.adjustGableZPositions(section);

    if (!section.isSwingFlex) {
      // SwingFlex interior items are already adjusted in part one under adjustItemToGable
      this.adjustItemPositionsAndWidths(section);
    }

    for (let item of section.interior.items) {
      this.moveAllItemsInsideInteriorCube(item, 'X', true);
    }
    //for (let item of section.interior.items) {
    //    this.moveAllItemsInsideInteriorCube(item, "Y", true)
    //}
    for (let item of section.interior.items) {
      if (!ProductHelper.isStyling(item.Product)) continue;
      this.moveAllItemsInsideInteriorCube(item, 'Z', false);
    }

    this.setHasEmptyGaps(section);
    this.updatePreviousInteriorCube(section);

    result.push(...this.setCornerItemDim2(section));

    // Add joint variants
    this.setVerticalJointVariants(section);

    this.setFittingPanelConnections(section);

    this.replaceAutoGeneratedItems(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Interior part two');

    return result;
  }

  private setFittingPanelConnections(section: Client.CabinetSection): void {
    const fittingPanels = section.interior.items.filter(
      (item) => item.isFittingPanel,
    );
    for (let item of section.interior.items) {
      if (
        !item.Product ||
        (!ProductHelper.hasVariant(
          item.Product,
          VariantNumbers.AdaptionToFittingPanelAboveBelow,
        ) &&
          !ProductHelper.hasVariant(
            item.Product,
            VariantNumbers.GableAdaptionToFittingPanel,
          ))
      ) {
        continue;
      }

      const connecedFittingPanelsAbove = fittingPanels.filter(
        (fp) =>
          fp.X >= item.X && fp.rightX <= item.rightX && fp.Y === item.topY,
      );
      const connecedFittingPanelsBelow = fittingPanels.filter(
        (fp) =>
          fp.X >= item.X && fp.rightX <= item.rightX && fp.topY === item.Y,
      );
      let fittingPanelVertical = VariantNumbers.Values.FittingPanel_None;
      if (connecedFittingPanelsAbove.length > 0) {
        fittingPanelVertical = VariantNumbers.Values.FittingPanel_Above;
      } else if (connecedFittingPanelsBelow.length > 0) {
        fittingPanelVertical = VariantNumbers.Values.FittingPanel_Below;
      }
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.AdaptionToFittingPanelAboveBelow,
        fittingPanelVertical,
      );

      const connectedFittingPanels = connecedFittingPanelsBelow.concat(
        connecedFittingPanelsAbove,
      );
      const fittingDrilledRight = connectedFittingPanels.some(
        (fp) => fp.drilledRight,
      );
      const fittingDrilledLeft = connectedFittingPanels.some(
        (fp) => fp.drilledLeft,
      );
      const fittingPanelHorisontal =
        fittingDrilledRight && fittingDrilledLeft
          ? VariantNumbers.Values.Both
          : fittingDrilledRight
            ? VariantNumbers.Values.Left
            : fittingDrilledLeft
              ? VariantNumbers.Values.Right
              : VariantNumbers.Values.None;
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.GableAdaptionToFittingPanel,
        fittingPanelHorisontal,
      );
    }
  }

  private removeUnknownProducts(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let removedItems = ConfigurationItemHelper.removeItemsWithoutProducts(
      section.interior.items,
    );

    if (removedItems.length > 0) {
      return [
        {
          cabinetSection: section,
          editorSection: Enums.EditorSection.Interior,
          severity: Enums.RecalculationMessageSeverity.Warning,
          key: 'interior_items_removed_discontinued',
          defaultValue:
            'Some interior items are discontinued and have been removed. Please ensure the solution is as expected.',
        },
      ];
    } else {
      return [];
    }
  }

  private ensureValuesAreValid(section: Client.CabinetSection) {
    // Material
    if (!this.isMaterialValid(section.interior)) {
      this.setDefaultMaterial(section.interior);
    }
  }

  private isMaterialValid(interior: Client_Interior): boolean {
    if (interior.material === null) {
      return false;
    } else {
      let id = interior.material.Id;
      return interior.pickableMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }

  private setDefaultMaterial(interior: Client_Interior) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultInteriorMaterialNumber(
        interior.editorAssets,
      );

    if (defaultMaterialNumber) {
      material = Enumerable.from(interior.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === defaultMaterialNumber);
    }

    if (!material) {
      material = Enumerable.from(interior.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    if (material != null) {
      interior.setMaterial(material, false);
    }
  }

  private adjustYPositions(section: Client.CabinetSection) {
    // Adjust the Y position of all items, that are not a module parent or part of a module
    let adjustment = 0;
    let gables = section.interior.items.filter(
      (item) =>
        item.isGable &&
        item.moduleParent === null &&
        !item.IsLocked &&
        !item.isFittingPanel &&
        !item.isVerticalDivider,
    );

    for (let gable of gables) {
      let difference = section.interior.cube.Y - gable.Y;
      if (Math.abs(difference) > Math.abs(adjustment)) {
        adjustment = difference;
      }
    }

    const nonTemplateItems = section.interior.items.filter(
      (item) => item.moduleParent === null && !item.isTemplate,
    );

    for (let item of nonTemplateItems) {
      item.Y += adjustment;
    }

    // Adjust module parent and child Y positions...
    for (let module of section.interior.items.filter(
      (item) => item.isTemplate,
    )) {
      let difference = section.interior.cube.Y - module.Y;
      if (Math.abs(difference) > Constants.sizeAndPositionTolerance) {
        module.Y += difference;
        module.moduleChildren.forEach((child) => (child.Y += difference));

        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );
        itemsInModule.forEach((child) => (child.Y += difference));
      }
    }
  }

  private adjustGableHeight(section: Client.CabinetSection) {
    for (let gable of section.interior.items.filter(
      (i) =>
        i.isGable &&
        !i.moduleParent &&
        !i.isFittingPanel &&
        !i.isVerticalDivider,
    )) {
      gable.trySetHeight(section.interior.cube.Height);
      if (gable.Height > section.interior.cube.Height) {
        //gable was probably locked. But cube height trumps item lock.
        gable.Height = section.interior.cube.Height;
      }
    }
    for (let gable of section.interior.items.filter(
      (i) =>
        i.isGable &&
        !!i.moduleParent &&
        !i.isFittingPanel &&
        !i.isVerticalDivider,
    )) {
      if (gable.moduleParent!.Height < gable.moduleParent!.minHeight) {
        gable.moduleParent!.Height = gable.moduleParent!.minHeight;
      }

      gable.trySetHeight(gable.moduleParent!.Height);
    }
  }

  private moveModulesInside(section: Client.CabinetSection) {
    for (let module of section.interior.items.filter(
      (item) => item.isTemplate,
    )) {
      // Too far right?
      let difference = module.rightX - section.interior.cubeRightX;
      if (difference > Constants.sizeAndPositionTolerance) {
        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );

        module.X -= difference;
        module.moduleChildren.forEach((child) => (child.X -= difference));

        itemsInModule.forEach((child) => (child.X -= difference));
      }

      // Too far left?
      difference = section.interior.cube.X - module.X;
      if (difference > Constants.sizeAndPositionTolerance) {
        let itemsInModule = section.interior.items.filter(
          (item) =>
            item.moduleParent === null &&
            !item.isTemplate &&
            item.X > module.X &&
            item.rightX < module.rightX,
        );

        module.X += difference;
        module.moduleChildren.forEach((child) => (child.X += difference));

        itemsInModule.forEach((child) => (child.X += difference));
      }
    }
  }

  private adjustGableZPositions(section: Client.CabinetSection) {
    let gables = section.interior.items.filter(
      (item) =>
        item.isGable && !(item.isFittingPanel || item.isVerticalDivider),
    );

    // Adjust the Z position of all gables, except snapfront gables
    for (let gable of gables.filter(
      (item) => item.isGable && !item.snapFront,
    )) {
      gable.Z = section.interior.cube.Z;
    }

    // Adjust Z position of snapfront gables (low support gables for use in modules)
    for (let gable of gables.filter((item) => item.isGable && item.snapFront)) {
      let closestModuleGable = Enumerable.from(
        gables.filter(
          (item) =>
            item.isGable && (item.drilling600Left || item.drilling600Right),
        ),
      )
        .orderBy((mg) => Math.abs(mg.X - gable.X))
        .firstOrDefault();
      if (!!closestModuleGable) {
        let newZ =
          closestModuleGable.frontZ - gable.depthReduction - gable.Depth;
        gable.Z = newZ;
      } else {
        gable.Z = section.interior.cube.Z;
      }
    }
  }

  private adjustItemPositionsAndWidths(section: Client.CabinetSection) {
    // Adjust items, so they fit in their "gaps"
    let gaps = this.getGapsBetweenGablesOrModules(section);
    gaps.forEach((gap) => gap.fitItems());
  }

  private moveAllItemsInsideInteriorCube(
    item: Client.ConfigurationItem,
    dimension: 'X' | 'Y' | 'Z',
    resize: boolean,
  ) {
    let cube = item.cabinetSection.interior.cube;

    let size: 'Width' | 'Height' | 'Depth';
    let minSize: 'minWidth' | 'minHeight' | 'minDepth';
    if (dimension === 'X') {
      size = 'Width';
      minSize = 'minWidth';
    } else if (dimension === 'Y') {
      size = 'Height';
      minSize = 'minHeight';
    } else {
      size = 'Depth';
      minSize = 'minDepth';
    }

    if (item[dimension] < cube[dimension]) {
      item[dimension] = cube[dimension];
    }

    if (item[dimension] + item[size] > cube[dimension] + cube[size]) {
      //move item if it's off to the right
      item[dimension] = cube[dimension] + cube[size] - item[size];
    }

    let itemMaxSize = cube[dimension] + cube[size] - item[dimension];
    if (resize && itemMaxSize < item[size]) {
      //resize item if it's off to the left
      let newTargetSize = Math.max(item[minSize], itemMaxSize);
      if (item[size] !== newTargetSize) {
        item[size] = newTargetSize;
      }
    }
  }

  private adjustYPositionsToDrillStop(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      if (!(item.snapLeft || item.snapRight || item.snapLeftAndRight)) {
        continue;
      }

      if (item.isFittingPanel || item.isVerticalDivider) {
        continue;
      }

      let gableGap = InteriorLogic.getGableGap(section, item);

      if (section.isSwingFlex) {
        let fittingPanelGaps = InteriorLogic.getFittingPanelGaps(section);

        const fittingPanelGapsInArea = fittingPanelGaps.filter(
          (c) =>
            (c.leftGable &&
              c.leftGable.swingFlexAreaIndex === item.swingFlexAreaIndex) ||
            (c.rightGable &&
              c.rightGable.swingFlexAreaIndex === item.swingFlexAreaIndex),
        );

        if (fittingPanelGapsInArea) {
          const leftGable = fittingPanelGapsInArea.find(
            (c) =>
              c.leftGable &&
              c.leftGable.isFittingPanel &&
              c.leftGable.Y < item.Y &&
              c.leftGable.topY > item.topY,
          );
          const rightGable = fittingPanelGapsInArea.find(
            (c) =>
              c.rightGable &&
              c.rightGable.isFittingPanel &&
              c.rightGable.Y < item.Y &&
              c.rightGable.topY > item.topY,
          );

          if (leftGable) {
            gableGap = leftGable;
          } else if (rightGable) {
            gableGap = rightGable;
          }
        }
      }

      if (gableGap && gableGap.drillStops.length > 0) {
        let currentY = item.Y;

        let bestDrillStop = ObjectHelper.best(
          gableGap.drillStops,
          (drillStop) => Math.abs(drillStop - item.Y - item.snapOffsetY),
        );

        if (
          bestDrillStop !== undefined &&
          bestDrillStop - item.snapOffsetY !== currentY
        ) {
          item.Y = bestDrillStop - item.snapOffsetY;
        }
      }
    }
  }

  private recalculateModuleChildrenPositions(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      if (item.Product && item.isTemplate) {
        item.Z = section.interior.cube.Z;

        for (
          let childI = 0;
          childI < item.Product.ModuleItems.length;
          childI++
        ) {
          let moduleItem = item.Product.ModuleItems[childI];
          let childItem = item.moduleChildren[childI];
          if (moduleItem && childItem) {
            childItem.X = moduleItem.X + item.X;
            childItem.Y = moduleItem.Y + item.Y;
            childItem.Z = moduleItem.Z + item.Z;
          }
        }

        let itemsEnumerable = Enumerable.from(section.interior.items);

        let itemsAddedToModule = itemsEnumerable
          .where(
            (i) =>
              i.X > item.X &&
              i.rightX < item.rightX &&
              item.moduleChildren.indexOf(i) < 0,
          )
          .toArray();

        for (let index = 0; index < itemsAddedToModule.length; index++) {
          let addedItem = itemsAddedToModule[index];

          // Find closest gable
          let gable = itemsEnumerable.lastOrDefault(
            (i) =>
              i.isGable &&
              Math.abs(i.rightX - addedItem.X) <
                Constants.sizeAndPositionTolerance,
          );
          if (gable === null) {
            gable = itemsEnumerable.firstOrDefault(
              (i) =>
                i.isGable &&
                Math.abs(i.X - addedItem.rightX) <
                  Constants.sizeAndPositionTolerance,
            );
          }

          if (!!gable) {
            addedItem.Z =
              gable.frontZ - addedItem.depthReduction - addedItem.Depth;
          }
        }
      }
    }
  }

  private moveUnsnappedItemsInside(section: Client.CabinetSection) {
    for (let item of section.interior.items.filter((i) => !i.isGable)) {
      if (
        item.X > section.interior.cube.X &&
        item.rightX < section.interior.cubeRightX
      ) {
        continue;
      }

      if (!section.interior.isItemSnappedToGable(item)) {
        item.X = Math.max(item.X, section.interior.cube.X);
        item.X =
          Math.min(item.rightX, section.interior.cubeRightX) - item.Width;
      }
    }
  }

  private adaptToWidth(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let messages: Client.RecalculationMessage[] = [];

    if (section.interior.items.length <= 0) {
      return messages;
    }

    this.moveUnsnappedItemsInside(section);

    let gaps = this.getGapsBetweenGablesOrModules(section);

    let spreadOutToFill = section.interior.mustAdaptToWidth;
    section.interior.mustAdaptToWidth = false;
    let spreadToFillLeft = false;
    let spreadToFillRight = false;

    if (!spreadOutToFill) {
      // If the interior width has changed, and there was not already an empty gap at the right side, we must adapt to the new width

      let widthChange =
        section.interior.cube.Width - section.interior.previousCubeWidth;
      if (widthChange > Constants.sizeAndPositionTolerance) {
        if (gaps.length === 0) {
          spreadToFillLeft = false;
          spreadToFillRight = false;
        } else {
          if (
            section.interior.cube.X < section.interior.previousCubeX &&
            !gaps[0].isRightGableLocked
          ) {
            spreadToFillLeft = true;
          }
          if (
            section.interior.cube.X + section.interior.cube.Width >
              section.interior.previousCubeX +
                section.interior.previousCubeWidth &&
            !gaps[gaps.length - 1].isRightGableLocked
          ) {
            spreadToFillRight = true;
          }
        }
      }
    }

    // Local function for adjusting gap width
    let adjustGapWidth = (gap: Client.InteriorGap, change: number) => {
      let newWidth = gap.width - change;

      let otherGaps = gaps
        .filter((g) => g.rightX > gap.rightX)
        .sort((a, b) => {
          return a.position - b.position;
        });
      if (gap.resize(newWidth)) {
        otherGaps.forEach((g) => {
          g.moveBy(-change);
          if (otherGaps.indexOf(g) === otherGaps.length - 1) {
            g.resize(g.width - change);
          }
        });
      }
    };

    // Ensure no items or gaps are outside the cabinet to the left
    if (gaps.some((gap) => gap.position < section.interior.cube.X)) {
      let minGapPos = Math.min(...gaps.map((g) => g.position));
      let moveAmount = section.interior.cube.X - minGapPos;

      gaps.forEach((g) => g.moveBy(moveAmount));
    }

    if (gaps.length > 1) {
      // Remove empty gap left, if it is not wider than the threshold
      let firstGap = gaps[0];
      if (
        firstGap &&
        firstGap.class === Enums.InteriorGapClass.Empty &&
        firstGap.width > Constants.sizeAndPositionTolerance &&
        firstGap.width <= Constants.minimumInteriorSideGapWidth
      ) {
        if (firstGap.rightGable && !firstGap.rightGable.IsLocked) {
          spreadToFillLeft = true;
          console.log('Interior - Removing narrow empty space left.');
        }
      }

      // Remove empty gap right, if it is not wider than the threshold (if not already set to do so)
      if (!spreadOutToFill) {
        let lastGap = gaps[gaps.length - 1];
        if (
          lastGap &&
          lastGap.class === Enums.InteriorGapClass.Empty &&
          lastGap.width > Constants.sizeAndPositionTolerance &&
          lastGap.width < Constants.minimumInteriorSideGapWidth
        ) {
          if (lastGap.leftGable && !lastGap.leftGable.IsLocked) {
            spreadToFillRight = true;
            console.log('Interior - Removing narrow empty space right.');
          }
        }
      }
    }

    if (gaps.some((gap) => gap.rightX > section.interior.cubeRightX)) {
      // Too narrow (items outside the cabinet to the right):
      // - Make room for the items by adjusting empty, flexible or semiflexible areas

      gaps.reverse(); // Start from the right...

      let neededSpace =
        Math.max(...gaps.map((g) => g.rightX), 0) - section.interior.cubeRightX;

      // First try empty space
      let emptyGaps = gaps.filter(
        (g) => g.class === Enums.InteriorGapClass.Empty && g.width > 0,
      );
      if (emptyGaps.length > 0) {
        let totalEmptySpace = emptyGaps
          .map((g) => g.width)
          .reduce((a, b) => a + b);

        while (neededSpace > 0 && totalEmptySpace > 0 && emptyGaps.length > 0) {
          let currentGap = emptyGaps.splice(0, 1)[0];
          let change = Math.min(currentGap.width, neededSpace);
          adjustGapWidth(currentGap, change);
          neededSpace -= change;
        }
      }

      if (neededSpace > 0) {
        // If that was not enough, try flexible gaps
        let flexGaps = gaps.filter(
          (g) => g.class === Enums.InteriorGapClass.Flex && g.width > 0,
        );
        if (flexGaps.length > 0) {
          let totalFlexSpace = flexGaps
            .map((g) => g.maxContraction)
            .reduce((a, b) => a + b);
          // Adjusting by factor may not be possible, because of gap min width constraints...
          // So we adjust one gap at a time

          while (neededSpace > 0 && totalFlexSpace > 0 && flexGaps.length > 0) {
            let currentGap = flexGaps.splice(0, 1)[0];
            let change = Math.min(currentGap.maxContraction, neededSpace);
            adjustGapWidth(currentGap, change);
            neededSpace -= change;
          }
        }
      }

      if (neededSpace > 0) {
        // If that was still not enough, try semiflexible gaps
        let semiFlexGaps = gaps.filter(
          (g) => g.class === Enums.InteriorGapClass.SemiFlex && g.width > 0,
        );
        if (semiFlexGaps.length > 0) {
          let totalSemiFlexSpace = semiFlexGaps
            .map((g) => g.maxContraction)
            .reduce((a, b) => a + b);

          while (
            neededSpace > 0 &&
            totalSemiFlexSpace > 0 &&
            semiFlexGaps.length > 0
          ) {
            let currentGap = semiFlexGaps.splice(0, 1)[0];
            let change = Math.min(currentGap.maxContraction, neededSpace);
            adjustGapWidth(currentGap, change);
            neededSpace -= change;
          }
        }
      }

      if (neededSpace > 0) {
        // If we still need more space when we get here, we are out of luck.
        // There is simply nowhere to take it from.
        // Later in the recalculation, items that are still outside the cabinet are pushed inside.

        let message: Client.RecalculationMessage = {
          cabinetSection: section,
          editorSection: Enums.EditorSection.Interior,
          key: 'AdjustInteriorToLessAvailableSpace',
          defaultValue:
            'It was not possible to adjust interior to the available space.',
          severity: Enums.RecalculationMessageSeverity.Info,
        };
        messages.push(message);
      }
    } else if (spreadOutToFill) {
      console.log('Interior space to wide.');

      // Handle excess space to the left
      if (!section.interior.hasEmptyGapLeft) {
        let firstGap = gaps[0];

        if (firstGap.width > 0) {
          if (firstGap.class === Enums.InteriorGapClass.Empty) {
            // There is an empty area  on the left.
            // It must be resized to zero width, and all other gaps moved accordingly.
            adjustGapWidth(firstGap, firstGap.width);
          } else if (!firstGap.allItemsFit) {
            let gapIndex = this.findGapIndex(
              gaps,
              firstGap.class,
              false,
              Constants.sizeAndPositionTolerance,
            );

            // If we found a suitable gap, do some adjusting and moving
            if (gapIndex > 0) {
              let newWidth = firstGap.maxItemWidth;
              let change = newWidth - firstGap.width;
              let otherGaps = gaps
                .filter(
                  (g) =>
                    g.rightX > firstGap.rightX && gaps.indexOf(g) < gapIndex,
                )
                .sort((a, b) => {
                  return a.position - b.position;
                });

              if (firstGap.resize(newWidth)) {
                otherGaps.forEach((g) => {
                  g.moveBy(change);
                  if (otherGaps.indexOf(g) === otherGaps.length - 1) {
                    g.fitItems();
                  }
                });
              }
            } else {
              firstGap.fitItems();
            }
          }
        }
      }

      // Handle excess space to the right
      let lastGap = gaps[gaps.length - 1];
      if (
        lastGap &&
        lastGap.class === Enums.InteriorGapClass.Empty &&
        lastGap.width > 0 &&
        !section.rightNeighbor
      ) {
        // Too wide (space to the right of the last gable):
        // - Remove the empty area by adjusting flexible or semiflexible areas

        let excessSpaceRight = lastGap.width; //section.interior.cubeRightX - Math.max(...gaps.map(g => g.rightX), 0);

        let adjustGapByFactor = (gap: Client.InteriorGap, factor: number) => {
          let newWidth = gap.width * factor;
          let change = newWidth - gap.width;

          adjustGapWidth(gap, -change);
          excessSpaceRight -= change;
        };

        // First try empty space
        if (!section.interior.hasEmptyGapRight) {
          let emptyGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.Empty &&
              g.width > 0 &&
              g !== lastGap,
          );
          if (emptyGaps.length > 0) {
            let totalEmptySpace = emptyGaps
              .map((g) => g.width)
              .reduce((a, b) => a + b);
            //let preferredEmptySpace = totalEmptySpace + excessSpaceRight;
            let factor = (totalEmptySpace + excessSpaceRight) / totalEmptySpace;

            console.log('Adjusting empty gaps by factor ' + factor);

            emptyGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        // From here on, adjusting by factor may not be correct, because of gap width constraints.
        // This may actually result in validation errors, if gaps are adjusted beyond their limits.
        // To avoid this, we could adjust one gap at time, but the old system did it like this, and now so does this. (For now, anyway...)

        if (excessSpaceRight >= 1) {
          // Try adjusting flexible gaps

          let flexGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.Flex &&
              g.width > 0 &&
              g.maxExpansion > 0,
          );
          if (flexGaps.length > 0) {
            let totalFlexSpace = flexGaps
              .map((g) => g.width)
              .reduce((a, b) => a + b);
            let factor = (totalFlexSpace + excessSpaceRight) / totalFlexSpace;

            console.log('Adjusting flexible gaps by factor ' + factor);

            flexGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        if (excessSpaceRight >= 1) {
          // Try adjusting semiflexible gaps

          let semiFlexGaps = gaps.filter(
            (g) =>
              g.class === Enums.InteriorGapClass.SemiFlex &&
              g.width > 0 &&
              g.maxExpansion > 0,
          );
          if (semiFlexGaps.length > 0) {
            let totalSemiFlexSpace = semiFlexGaps
              .map((g) => g.maxContraction)
              .reduce((a, b) => a + b);
            let factor =
              (totalSemiFlexSpace + excessSpaceRight) / totalSemiFlexSpace;

            console.log('Adjusting semi flexible gaps by factor ' + factor);

            semiFlexGaps.forEach((g) => adjustGapByFactor(g, factor));
          }
        }

        if (excessSpaceRight >= 1) {
          // If we still have some excess space when we get here, we are out of luck.
          // There is simply nowhere to put it.
          // All we can do, is let the user know.

          let message: Client.RecalculationMessage = {
            cabinetSection: section,
            editorSection: Enums.EditorSection.Interior,
            key: 'AdjustInteriorToFillAllAvailableSpace',
            defaultValue:
              'It was not possible to adjust interior to fill all available space.',
            severity: Enums.RecalculationMessageSeverity.Info,
          };
          messages.push(message);
        }
      }
    } else {
      if (spreadToFillLeft) {
        let firstGap = gaps[0];
        let gapIndex = this.findGapIndex(
          gaps,
          firstGap.class > 0 ? firstGap.class : Enums.InteriorGapClass.Fixed,
          false,
          section.interior.hasEmptyGapRight
            ? 0
            : Constants.sizeAndPositionTolerance,
        );

        // If we found a suitable gap, do some adjusting and moving
        if (gapIndex > 0 && !section.leftNeighbor) {
          let newWidth = firstGap.maxItemWidth;
          let change = newWidth - firstGap.actualWidth;

          // Get all gaps to the right of the first gap, that are not vertical dividers
          let otherGaps = gaps
            .filter(
              (g) =>
                g.rightX > firstGap.rightX &&
                gaps.indexOf(g) < gapIndex &&
                !(
                  g.rightGable?.isVerticalDivider ||
                  g.leftGable?.isVerticalDivider
                ),
            )
            .sort((a, b) => {
              return a.position - b.position;
            });

          if (firstGap.resize(newWidth)) {
            otherGaps.forEach((g) => {
              g.moveBy(change);
              if (otherGaps.indexOf(g) === otherGaps.length - 1) {
                g.fitItems();
              }
            });

            let nextGap = gaps[gapIndex];
            nextGap.updateWidth(
              section.interior.cube.X,
              section.interior.cubeRightX,
            );
          }
        }
      }
      if (spreadToFillRight) {
        let lastGap = gaps[gaps.length - 1];
        let gapIndex = this.findGapIndex(
          gaps,
          lastGap.class > 0 ? lastGap.class : Enums.InteriorGapClass.Fixed,
          true,
          section.interior.hasEmptyGapLeft
            ? 0
            : Constants.sizeAndPositionTolerance,
        );

        // If we found a suitable gap, do some adjusting and moving
        if (gapIndex > 0) {
          let newWidth = lastGap.maxItemWidth;
          let change = newWidth - lastGap.actualWidth;

          // Get all gaps to the left of the last gap, that are not vertical dividers
          let otherGaps = gaps
            .filter(
              (g) =>
                g.position < lastGap.position &&
                gaps.indexOf(g) >= gapIndex &&
                !(
                  g.rightGable?.isVerticalDivider ||
                  g.leftGable?.isVerticalDivider
                ),
            )
            .sort((a, b) => {
              return a.position - b.position;
            });

          for (let i = 0; i < otherGaps.length; i++) {
            let otherGap = otherGaps[i];
            if (i === 0) {
              lastGap.moveBy(-change);
              otherGap.resize(otherGap.actualWidth - change);
            } else {
              otherGap.moveBy(-change);
            }
          }

          lastGap.resize(newWidth);
        }
      }
    }

    return messages;
  }

  private findGapIndex(
    gaps: Client.InteriorGap[],
    excludeClass: Enums.InteriorGapClass,
    fromRight: boolean,
    minWidth: number,
  ): number {
    let gapIndex = -1;

    // Find the first empty gap (without passing a gap with locked right gable)
    let emptyGap = this.findGapOfClass(
      gaps,
      Enums.InteriorGapClass.Empty,
      fromRight,
      minWidth,
      true,
    );
    if (emptyGap) {
      gapIndex = gaps.indexOf(emptyGap);
    }

    // If we did not find an empty gap, find the first flex gap (without passing a gap with locked right gable)
    if (gapIndex <= 0 && excludeClass > Enums.InteriorGapClass.Flex) {
      let flexGap = this.findGapOfClass(
        gaps,
        Enums.InteriorGapClass.Flex,
        fromRight,
        minWidth,
        true,
      );
      if (flexGap) {
        gapIndex = gaps.indexOf(flexGap);
      }
    }

    // If we did not find a flex gap, find the first semiFlex gap (without passing a gap with locked right gable)
    if (gapIndex <= 0 && excludeClass > Enums.InteriorGapClass.SemiFlex) {
      let semiFlexGap = this.findGapOfClass(
        gaps,
        Enums.InteriorGapClass.SemiFlex,
        fromRight,
        minWidth,
        true,
      );
      if (semiFlexGap) {
        gapIndex = gaps.indexOf(semiFlexGap);
      }
    }

    return gapIndex;
  }

  private findGapOfClass(
    gaps: Client.InteriorGap[],
    gapClass: Enums.InteriorGapClass,
    reverseOrder: boolean,
    minWidth: number,
    ignoreFirst: boolean,
  ): Client.InteriorGap | undefined {
    let orderedGaps = gaps.slice().sort((a, b) => {
      if (!reverseOrder) return a.position - b.position;
      else return b.position - a.position;
    });

    for (var i = ignoreFirst ? 1 : 0; i < orderedGaps.length; i++) {
      let gap = orderedGaps[i];

      if (reverseOrder && gap.isRightGableLocked) {
        return undefined;
      }

      if (gap.class === gapClass && gap.width >= minWidth) {
        return gap;
      }
    }

    return undefined;
  }

  private getGapsBetweenGablesOrModules(
    section: Client.CabinetSection,
  ): Client.InteriorGap[] {
    let gaps: Client.InteriorGap[] = [];

    let gablesAndModules: Client.ConfigurationItem[] = [];
    gablesAndModules.push(
      ...section.interior.items.filter(
        (item) =>
          item.isGable && item.moduleParent === null && !item.isVerticalDivider,
      ),
    );
    gablesAndModules.push(
      ...section.interior.items.filter((item) => item.isTemplate),
    );
    if (section.isSwing) {
      gablesAndModules.push(
        ...section.swing.items.filter((item) => item.isGable),
      );
    }

    gablesAndModules.sort((a, b) => {
      return a.X - b.X;
    });

    let otherItems: Client.ConfigurationItem[] = section.interior.items.filter(
      (item) => !item.isGable && !item.isTemplate && !item.isDummy,
    );

    let startX = Math.min(section.interior.cube.X, section.interior.minItemX);

    for (let gapIndex = 0; gapIndex < gablesAndModules.length; gapIndex++) {
      let leftGable = gapIndex > 0 ? gablesAndModules[gapIndex - 1] : null;
      let rightGable = gablesAndModules[gapIndex];
      let endX = rightGable.X;

      let gap: Client.InteriorGap = new Client.InteriorGap(
        leftGable,
        rightGable,
        otherItems.filter(
          (item) =>
            (leftGable === null || item.Y < leftGable.topY) &&
            (rightGable === null || item.Y < rightGable.topY) &&
            item.centerX >= startX &&
            item.centerX <= endX,
        ),
        startX, // Skal denne opdateres for hver tur rundt i løkken??
        endX - startX,
      );

      let previousFarRightGable: Client.ConfigurationItem | undefined =
        undefined;

      for (
        let gableIndex = gapIndex;
        gableIndex <= gablesAndModules.length;
        gableIndex++
      ) {
        let farRightGable: Client.ConfigurationItem | undefined =
          gablesAndModules[gableIndex];
        //if (!farRightGable)
        //    break;
        if (
          farRightGable &&
          previousFarRightGable &&
          previousFarRightGable.topY >= farRightGable.topY
        )
          break;

        for (let item of otherItems) {
          if (item.drilling600Left || item.drilling600Right) continue;
          if (farRightGable && item.topY > farRightGable.topY) continue;
          if (previousFarRightGable && item.Y < previousFarRightGable.topY)
            continue;
          if (leftGable && item.Y > leftGable.topY) continue;
          if (farRightGable && item.X > farRightGable.X) continue;
          if (leftGable && item.rightX < leftGable.rightX) continue;
          if (item.snapUnder) continue;

          gap.multiGapItems.push({
            item: item,
            gable: gablesAndModules.filter(
              (g) => g.X > item.centerX && g.topY > item.Y,
            )[0],
          });
        }
        previousFarRightGable = farRightGable;
      }
      gaps.push(gap);

      startX = rightGable.rightX;
    }

    // Gap to the right of the last gable
    {
      let endX = Math.max(
        section.interior.cube.X + section.interior.cube.Width,
        section.interior.maxItemRightX,
      );
      let leftGable =
        gablesAndModules.length > 0
          ? gablesAndModules[gablesAndModules.length - 1]
          : null;

      let gap: Client.InteriorGap = new Client.InteriorGap(
        leftGable,
        null,
        otherItems.filter(
          (item) =>
            item.centerX >= startX &&
            item.centerX <= endX &&
            (!leftGable || item.Y < leftGable.topY),
        ),
        startX,
        endX - startX,
      );
      gaps.push(gap);
    }

    return gaps;
  }

  private setHasEmptyGaps(section: Client.CabinetSection) {
    section.interior.hasEmptyGapLeft = !section.interior.items.some(
      (ii) =>
        ii.X < section.interior.cube.X + Constants.sizeAndPositionTolerance,
    );
    section.interior.hasEmptyGapRight = !section.interior.items.some(
      (ii) =>
        ii.rightX >
        section.interior.cubeRightX - Constants.sizeAndPositionTolerance,
    );
  }

  private updatePreviousInteriorCube(section: Client.CabinetSection) {
    section.interior.previousCubeWidth = section.interior.cube.Width;
    section.interior.previousCubeX = section.interior.cube.X;
  }

  // #endregion recalculation

  /**
   * Sets vertical joint variants on items, where the actual height is bigger than their max height
   * @param section
   */
  private setVerticalJointVariants(section: Client.CabinetSection) {
    for (let item of section.interior.items) {
      let maxHeight = item.maxHeight;

      // Reset any joint positions
      ConfigurationItemHelper.removeItemVariantByVariantNumber(
        item,
        VariantNumbers.JointPosition,
      );

      if (item.ActualHeight > maxHeight && maxHeight > 0) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.Yes,
        );

        let numberOfJoints = Math.floor(item.ActualHeight / maxHeight);
        let rest = item.ActualHeight % maxHeight;
        let minHeight = item.minHeight;

        for (var i = 0; i < numberOfJoints; i++) {
          let pos = (i + 1) * maxHeight;
          if (i == numberOfJoints - 1) {
            //last joint
            // Make sure the last part is not too small
            if (rest < minHeight) pos -= minHeight - rest;
          }

          ConfigurationItemHelper.addDimensionVariantOptionByNumber(
            item,
            VariantNumbers.JointPosition,
            i,
            pos,
          );
        }
      } else {
        // Set joint variant to "no"
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.No,
        );
      }
    }
  }

  private setCornerItemDim2(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    if (!section.rightNeighbor) return result;

    let neighborGaps = InteriorLogic.getGableGaps(
      section.rightNeighbor,
      [],
      true,
    ).filter((gap) => !gap.leftGable);

    for (let item of section.interior.items) {
      if (!item.isCorner2WayFlex && !item.isCornerDiagonalCut) {
        continue;
      }
      if (item.IsLocked) continue;

      let gap = neighborGaps.filter((g) => g.Y + g.Height >= item.Y)[0];
      if (!gap) {
        //result.push({
        //    cabinetSection: section.rightNeighbor,
        //    key: "cornerShelf_missing_gable_right",
        //    defaultValue: "corner shelf has no right gable to snap to",
        //    editorSection: Enums.EditorSection.Interior,
        //    item: item,
        //    severity: Enums.RecalculationMessageSeverity.Warning,

        //});
        continue;
      }
      let width2 = gap.Width;
      item.width2 = ObjectHelper.clamp(item.minWidth, width2, item.maxWidth);
      let depth2 = gap.rightGable
        ? gap.rightGable.Depth
        : section.rightNeighbor.InteriorDepth;

      if (item.ProductData) {
        depth2 -= item.ProductData.ReductionDepth;
      }

      item.depth2 = ObjectHelper.clamp(item.minDepth, depth2, item.maxDepth);
    }
    return result;
  }

  public static getCollidingCollisionAreas(
    item1: Client.ConfigurationItem,
    isMirrored: boolean,
    otherItems: Client.ItemCube[],
    item1AlternativePosition?: Interface_DTO_Draw.Cube,
  ): Client.Collision[] {
    let position = item1AlternativePosition || {
      X: item1.X,
      Y: item1.Y,
      Z: item1.Z,
      Width: item1.Width,
      Height: item1.Height,
      Depth: item1.Depth,
    };

    function shouldIgnore(i: Client.ConfigurationItem): boolean {
      if (i.isDummy) return true;
      if (i.isTemplate) return true;
      if (i.isGrip && i.Product && ProductHelper.edgeMounted(i.Product))
        return true;
      return false;
    }

    if (shouldIgnore(item1)) return [];

    let productLineProperties =
      item1.cabinetSection.swingFlex.productLineProperties;

    let collisionAreas = Enumerable.from(
      this.getCollisionAreas(position, item1, isMirrored),
    );

    let collisions = otherItems.map((otherItemCube) => {
      if (shouldIgnore(otherItemCube.item)) return null;
      if (otherItemCube.item === item1) return null;
      if (item1.isCoatHanger && otherItemCube.item.snapCoatHanger) {
        return null;
      }
      if (otherItemCube.item.isCoatHanger && item1.snapCoatHanger) {
        return null;
      }

      const thresholdY = item1.cabinetSection.isSwingFlex
        ? item1.cabinetSection.swingFlex.getAllowedOverlapY(
            item1,
            otherItemCube.item,
          )
        : 0;

      let collisionArea = collisionAreas.firstOrDefault((ca) =>
        VectorHelper.overlapsMoreThanThreshold(
          ca,
          otherItemCube,
          0,
          thresholdY,
          0,
        ),
      );
      if (!collisionArea) return null;

      let result: Client.Collision = {
        ...collisionArea,
        offender: otherItemCube.item,
      };
      return result;
    });

    let boundingBoxCollisionArea = collisionAreas.firstOrDefault((ca) =>
      item1.cabinetSection.interior.boundingBoxes.some((bound) =>
        VectorHelper.overlaps(ca, bound),
      ),
    );

    let boundingBoxCollision: Client.Collision | null;

    if (boundingBoxCollisionArea) {
      boundingBoxCollision = {
        ...boundingBoxCollisionArea,
        offender: null,
      };
    } else {
      boundingBoxCollision = null;
    }

    return collisions
      .concat(boundingBoxCollision)
      .filter(<(c: any) => c is Client.Collision>((c) => !!c)) //remove nulls
      .filter((c, index, arr) => arr.indexOf(c) === index); //remove duplicates
  }

  public static getCollisionAreas(
    baseCube: Interface_DTO_Draw.Cube,
    owner: Client.ConfigurationItem,
    mirrored: boolean,
  ): Client.CollisionArea[] {
    let collisionAreas: Client.CollisionArea[] = [];
    let product = owner.Product;
    if (!product) return collisionAreas;

    collisionAreas.push({
      ...baseCube,
      owner: owner,
      severity: Enums.CollisionSeverity.Overlap,
      space: null,
    });
    for (let recommended of [false, true]) {
      let severity = recommended
        ? Enums.CollisionSeverity.Recommended
        : Enums.CollisionSeverity.Required;

      let topSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Top,
      );
      if (topSpace > 0) {
        collisionAreas.push({
          X: baseCube.X,
          Y: baseCube.Y + baseCube.Height,
          Z: baseCube.Z,
          Width: baseCube.Width,
          Height: topSpace,
          Depth: baseCube.Depth,
          severity: severity,
          space: Enums.CollisionSpacePosition.Top,
          owner: owner,
        });
      }
      let bottomSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Bottom,
      );
      if (bottomSpace > 0) {
        collisionAreas.push({
          X: baseCube.X,
          Y: baseCube.Y - bottomSpace,
          Z: baseCube.Z,
          Width: baseCube.Width,
          Height: bottomSpace,
          Depth: baseCube.Depth,
          severity: severity,
          space: Enums.CollisionSpacePosition.Bottom,
          owner: owner,
        });
      }
      let sideSpace = ProductHelper.getCollisionSpace(
        product,
        recommended,
        Enums.CollisionSpacePosition.Side,
      );
      if (sideSpace > 0) {
        if (mirrored) {
          //get collision space for left side
          collisionAreas.push({
            X: baseCube.X - sideSpace,
            Y: baseCube.Y,
            Z: baseCube.Z,
            Depth: baseCube.Depth,
            Width: sideSpace,
            Height: baseCube.Height,
            severity: severity,
            space: Enums.CollisionSpacePosition.Side,
            owner: owner,
          });
        } else {
          //get collision space for right side
          collisionAreas.push({
            X: baseCube.X + baseCube.Width,
            Y: baseCube.Y,
            Z: baseCube.Z,
            Depth: baseCube.Depth,
            Width: sideSpace,
            Height: baseCube.Height,
            severity: severity,
            space: Enums.CollisionSpacePosition.Side,
            owner: owner,
          });
        }
      }
    }
    return collisionAreas;
  }

  public static getGableGap(
    section: Client.CabinetSection,
    item: Client.ConfigurationItem,
  ): Client.GableGap {
    let gaps = this.getGableGaps(section, [item], true);
    let result = gaps.find(
      (g) =>
        g.X === item.X &&
        g.Width === item.Width &&
        g.Y <= item.Y &&
        g.Height + g.Y >= item.topY,
    );
    if (result) {
      return result;
    }

    let gables = Enumerable.from(section.interior.gables)
      .concat(section.swing.items.filter((i) => i.isGable))
      .where((g) => g.isGable && !g.isFittingPanel && !g.isVerticalDivider)
      .orderBy((g) => g.X);
    let leftGable =
      gables.lastOrDefault((g) => g.centerX < item.X && g.topY >= item.topY) ??
      null;
    let rightGable =
      gables.firstOrDefault(
        (g) => g.centerX > item.rightX && g.topY >= item.topY,
      ) ?? null;

    let gapHeight = section.interior.cube.Height;
    let gapBottom = 0;
    if (gables.any()) {
      gapBottom = Math.min(...gables.select((g) => g.Y).toArray());
    }

    let gapLeftX = leftGable ? leftGable.rightX : section.interior.cube.X;
    let gapRightX = rightGable
      ? rightGable.X
      : section.interior.cube.X + section.interior.cube.Width;

    let rect: Interface_DTO_Draw.Rectangle = {
      X: gapLeftX,
      Y: section.interior.cube.Y,
      Width: gapRightX - gapLeftX,
      Height: gapHeight,
    };

    let gap: Client.GableGap = {
      ...rect,
      pulloutSeverity: this.getMaxSeverity(
        section.doors.pulloutWarningAreas,
        rect.X,
        rect.X + rect.Width,
      ),
      leftGable: leftGable,
      rightGable: rightGable,
      drillStops: this.getDrillStops(
        section.cabinet.productLine,
        gapBottom,
        gapBottom + gapHeight,
      ),
      startsAtBottom: true,
    };

    return gap;
  }

  public static getFreeGableGaps(
    items: Client.ConfigurationItem[],
    excludeItems: Client.ConfigurationItem[],
    productLine: Interface_DTO.ProductLine,
  ): Client.GableGap[] {
    let result: Client.GableGap[] = [];
    let includedItems = items.filter((i) => excludeItems.indexOf(i) < 0);
    let gables = includedItems
      .filter((i) => i.isGable)
      .sort((i1, i2) => i1.X - i2.X);

    if (gables.length < 1) {
      return [];
    }

    let lastLeftGables: {
      X: number;
      TopY: number;
      leftGable: Client.ConfigurationItem | null;
    }[] = [
      {
        X: gables[0].rightX,
        TopY: gables[0].topY,
        leftGable: gables[0],
      },
    ];

    for (let rightGable of gables.slice(1)) {
      let lastY = Math.min(rightGable.Y, lastLeftGables[0].leftGable!.Y);
      let startsAtBottom = true;
      for (let gableInfo of lastLeftGables) {
        let pos = {
          X: gableInfo.X,
          Y: startsAtBottom
            ? Math.min(rightGable.Y, gableInfo.leftGable!.Y)
            : lastY,
        };
        let size = {
          X: rightGable.X - gableInfo.X,
          Y: Math.min(gableInfo.TopY, rightGable.Y + rightGable.Height),
        };

        result.push({
          ...pos,
          Width: size.X,
          Height: size.Y,
          pulloutSeverity: Enums.PulloutWarningSeverity.None,
          leftGable: gableInfo.leftGable,
          rightGable: rightGable,
          drillStops: this.getDrillStops(
            productLine,
            Math.min(...gables.map((g) => g.Y)),
            size.Y,
            lastY,
          ),
          startsAtBottom: startsAtBottom,
        });
        lastY = gableInfo.TopY;
        startsAtBottom = false;
      }
      let newTopY = rightGable.topY;
      lastLeftGables = lastLeftGables.filter((llg) => llg.TopY > newTopY); //remove gables that are smaller than current gable
      lastLeftGables.push({
        X: rightGable.rightX,
        TopY: rightGable.topY,
        leftGable: rightGable,
      });
      lastLeftGables = lastLeftGables.sort((ga, gb) => ga.TopY - gb.TopY);
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);
    return result;
  }

  public static getGableGaps(
    section: Client.CabinetSection,
    excludeItems: Client.ConfigurationItem[],
    ignorePulloutSeverity: boolean = false,
  ): Client.GableGap[] {
    if (section.CabinetType === Interface_Enums.CabinetType.Swing) {
      return InteriorLogic.getFreeGableGaps(
        section.swing.items
          .filter(
            (swingItem) =>
              swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus ||
              swingItem.ItemType === Interface_Enums.ItemType.SwingFlexCorpus,
          )
          .concat(section.interior.items),
        excludeItems,
        section.cabinet.productLine,
      );
    } else if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      return InteriorLogic.getFreeSwingFlexGableGaps(
        section,
        excludeItems,
        section.cabinet.productLine,
      );
    }

    let result: Client.GableGap[] = [];
    let includedItems = section.interior.items.filter(
      (i) => excludeItems.indexOf(i) < 0,
    );

    let gables = includedItems
      .filter((i) => i.isGable && !i.isVerticalDivider)
      .sort((i1, i2) => i1.X - i2.X);

    // a list of the gables that are reachable from the next gable in the list.
    // A gable is reachable if it's visible from the top of the next gable - or
    // in other words if there are no taller gables between the two
    let lastLeftGables: {
      X: number;
      TopY: number;
      leftGable: Client.ConfigurationItem | null;
    }[] = [
      {
        X: section.interior.cube.X,
        TopY: section.interior.cube.Y + section.interior.cube.Height,
        leftGable: null,
      },
    ];

    let productLine = section.cabinet.productLine;

    //get gaps at ground level
    for (let gable of gables) {
      let lastY = section.interior.cube.Y;
      let startsAtBottom = true;

      for (let gableInfo of lastLeftGables) {
        let pos = {
          X: gableInfo.X,
          Y: lastY,
        };
        let size = {
          X: gable.X - gableInfo.X,
          Y: Math.min(gableInfo.TopY, gable.Y + gable.Height),
        };
        let end = pos.X + size.X;
        result.push({
          ...pos,
          Width: size.X,
          Height: size.Y,
          pulloutSeverity: ignorePulloutSeverity
            ? Enums.PulloutWarningSeverity.None
            : this.getMaxSeverity(
                section.doors.pulloutWarningAreas,
                pos.X,
                end,
              ),
          leftGable: gableInfo.leftGable,
          rightGable: gable,
          drillStops: this.getDrillStops(
            productLine,
            section.interior.cube.Y,
            size.Y,
            lastY,
          ),
          startsAtBottom: startsAtBottom,
        });
        lastY = gableInfo.TopY;
        startsAtBottom = false;
      }
      let newTopY = gable.Y + gable.Height;
      lastLeftGables = lastLeftGables.filter((llg) => llg.TopY > newTopY); //remove gables that are smaller than current gable
      lastLeftGables.push({
        X: gable.rightX,
        TopY: gable.topY,
        leftGable: gable,
      });
      lastLeftGables = lastLeftGables.sort((ga, gb) => ga.TopY - gb.TopY);
    }
    let lastY = section.interior.cube.Y;
    let startsAtBottom = true;
    for (let gableInfo of lastLeftGables) {
      //do the space between the last gable and
      let pos = {
        X: gableInfo.X,
        Y: lastY,
      };
      let size = {
        X: section.interior.cube.X + section.interior.cube.Width - gableInfo.X,
        Y: Math.min(
          gableInfo.TopY,
          section.interior.cube.Y + section.interior.cube.Height,
        ),
      };
      let lastEnd = section.interior.cube.X + section.interior.cube.Width;

      result.push({
        ...pos,
        Width: size.X,
        Height: size.Y,
        pulloutSeverity: this.getMaxSeverity(
          section.doors.pulloutWarningAreas,
          pos.X,
          lastEnd,
        ),
        leftGable: gableInfo.leftGable,
        rightGable: null,
        drillStops: this.getDrillStops(
          productLine,
          section.interior.cube.Y,
          size.Y,
          lastY,
        ),
        startsAtBottom: startsAtBottom,
      });
      lastY = gableInfo.TopY;
      startsAtBottom = false;
    }

    //for (let gap of result) {
    //    for (let pulloutWarningArea of section.doors.pulloutWarningAreas) {
    //        if (pulloutWarningArea.overlaps(gap.Position.X, gap.Position.X + gap.Width)) {
    //            gap.pulloutSeverity = Math.max(gap.pulloutSeverity, pulloutWarningArea.severity);
    //        }
    //    }
    //}

    result = result.filter((r) => r.Width > 0 && r.Height > 0);

    return result;
  }

  public static getFreeSwingFlexGableGaps(
    section: Client.CabinetSection,
    excludeItems: Client.ConfigurationItem[],
    productLine: Interface_DTO.ProductLine,
  ): Client.GableGap[] {
    const swingFlexBottomOffset: number = section.swingFlex.getGablePositionY();

    let items = Enumerable.from(section.swingFlex.items)
      .concat(section.interior.items)
      .toArray() as Client.ConfigurationItem[];

    let result: Client.GableGap[] = [];
    let includedItems = items.filter((i) => excludeItems.indexOf(i) < 0);

    let gables = includedItems
      .filter((i) => i.isGable && !i.isVerticalDivider && !i.isFittingPanel)
      .sort((i1, i2) => i1.X - i2.X);

    let lastLeftGables: {
      X: number;
      TopY: number;
      leftGable: Client.ConfigurationItem | null;
    }[] = [
      {
        X: section.interior.cube.X,
        TopY: section.interior.cube.Y + section.interior.cube.Height,
        leftGable: null,
      },
    ];

    //get gaps at ground level
    for (let gable of gables.filter((e) => !e.isVerticalDivider)) {
      let lastY = section.interior.cube.Y;
      let startsAtBottom = true;
      let _isFittingPanel = gable.isFittingPanel;

      for (let gableInfo of lastLeftGables) {
        let pos = {
          X: gableInfo.X,
          Y: lastY,
        };
        let size = {
          X: gable.X - gableInfo.X,
          Y: Math.min(gableInfo.TopY, gable.Y + gable.Height),
        };
        let end = pos.X + size.X;
        result.push({
          ...pos,
          Width: size.X,
          Height: size.Y,
          pulloutSeverity: false
            ? Enums.PulloutWarningSeverity.None
            : this.getMaxSeverity(
                section.doors.pulloutWarningAreas,
                pos.X,
                end,
              ),
          leftGable: gableInfo.leftGable,
          rightGable: gable,
          drillStops: this.getDrillStops(
            productLine,
            swingFlexBottomOffset + productLine.GableDrillingSpacing,
            size.Y,
            lastY,
          ),
          startsAtBottom: startsAtBottom,
          isFittingPanel: _isFittingPanel,
        });
        lastY = gableInfo.TopY;
        startsAtBottom = false;
      }
      let newTopY = gable.Y + gable.Height;
      lastLeftGables = lastLeftGables.filter((llg) => llg.TopY > newTopY); //remove gables that are smaller than current gable
      lastLeftGables.push({
        X: gable.rightX,
        TopY: gable.topY,
        leftGable: gable,
      });
      lastLeftGables = lastLeftGables.sort((ga, gb) => ga.TopY - gb.TopY);
    }
    let lastY = section.interior.cube.Y;
    let startsAtBottom = true;

    for (let gableInfo of lastLeftGables) {
      //do the space between the last gable and the outer right corpus
      let pos = {
        X: gableInfo.X,
        Y: lastY,
      };
      let size = {
        X: section.interior.cube.X + section.interior.cube.Width - gableInfo.X,
        Y: Math.min(
          gableInfo.TopY,
          section.interior.cube.Y + section.interior.cube.Height,
        ),
      };
      let lastEnd = section.interior.cube.X + section.interior.cube.Width;

      result.push({
        ...pos,
        Width: size.X,
        Height: size.Y,
        pulloutSeverity: this.getMaxSeverity(
          section.doors.pulloutWarningAreas,
          pos.X,
          lastEnd,
        ),
        leftGable: gableInfo.leftGable,
        rightGable: null,
        drillStops: this.getDrillStops(
          productLine,
          section.interior.cube.Y +
            swingFlexBottomOffset +
            productLine.GableDrillingSpacing,
          size.Y,
          lastY,
        ),
        startsAtBottom: startsAtBottom,
        isFittingPanel: gableInfo.leftGable?.isFittingPanel,
      });
      lastY = gableInfo.TopY;
      startsAtBottom = false;
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);

    return result;
  }

  public static getFittingPanelGaps(section: Client.CabinetSection) {
    let result: Client.GableGap[] = [];

    let gables = section.interior.items
      .filter((i) => i.isGable && i.isFittingPanel)
      .sort((i1, i2) => i1.X - i2.X);

    let productLine = section.cabinet.productLine;

    let gableBetween = Enumerable.from(section.swingFlex.areas)
      .selectMany((a) => a.items)
      .firstOrDefault((c) => c.isGable && c.drilledRight && c.drilledLeft);

    section.swingFlex.areas.forEach((area) => {
      const swingFlexBottomOffset: number =
        section.swingFlex.getGablePositionY();

      area.subAreas.forEach((subArea) => {
        let gableInsideArea = Enumerable.from(gables).where(
          (c) =>
            c.X >= subArea.insideRect.X &&
            c.X <= subArea.insideRect.X + subArea.insideRect.Width,
        );

        let gableInSameHeight = gableInsideArea.groupBy((c) => c.Y);

        gableInSameHeight.forEach((gable) => {
          let leftFittingGable =
            gable.firstOrDefault((c) => c.drilledRight) ?? null;
          let rightFittingGable =
            gable.firstOrDefault((c) => c.drilledLeft) ?? null;

          let pos = undefined;
          let size = undefined;
          let lastY = area.insideRect.Y;

          if (leftFittingGable && rightFittingGable) {
            pos = {
              X: leftFittingGable.rightX,
              Y: leftFittingGable.bottomY,
            };
            size = {
              X: rightFittingGable.leftX - leftFittingGable.rightX,
              Y: leftFittingGable.topY,
            };
            lastY = leftFittingGable.Y;
          } else if (leftFittingGable && !rightFittingGable) {
            pos = {
              X: leftFittingGable.rightX,
              Y: leftFittingGable.bottomY,
            };
            size = {
              X:
                subArea.insideRect.Width -
                (leftFittingGable.rightX - subArea.insideRect.X),
              Y: leftFittingGable.topY,
            };
            lastY = leftFittingGable.Y;
          } else if (!leftFittingGable && rightFittingGable) {
            pos = {
              X: subArea.insideRect.X,
              Y: rightFittingGable.Y,
            };
            size = {
              X: rightFittingGable.X - subArea.insideRect.X,
              Y: rightFittingGable.topY,
            };
            lastY = rightFittingGable.Y;
          }

          if (!pos || !size) {
            return;
          }

          if (!leftFittingGable) {
            leftFittingGable = gableBetween ?? null;
          }

          if (!rightFittingGable) {
            rightFittingGable = gableBetween ?? null;
          }

          result.push({
            ...pos,
            Width: size.X,
            Height: size.Y,
            pulloutSeverity: Enums.PulloutWarningSeverity.None,
            leftGable: leftFittingGable,
            rightGable: rightFittingGable,
            drillStops: this.getDrillStops(
              productLine,
              swingFlexBottomOffset,
              size.Y,
              lastY,
            ),
            startsAtBottom: true,
            isFittingPanel: true,
          });
        });
      });
    });

    return result;
  }

  public static getDrillStops(
    productLine: Interface_DTO.ProductLine,
    startOffset: number,
    maxHeight: number,
    minHeight = 0,
  ): number[] {
    const result: number[] = [];
    const startY =
      productLine.GableDrillingStart +
      startOffset -
      productLine.GableDrillingSpacing;

    for (
      let y = startY;
      y <= maxHeight;
      y += productLine.GableDrillingSpacing
    ) {
      if (y > minHeight) result.push(y);
    }

    return result;
  }

  private static getMaxSeverity(
    pulloutAreas: Client.PulloutWarningArea[],
    start: number,
    end: number,
  ): Enums.PulloutWarningSeverity {
    let relvantAreas = pulloutAreas.filter((pa) => pa.overlaps(start, end));
    return Math.max(
      Enums.PulloutWarningSeverity.None,
      ...relvantAreas.map((pa) => pa.severity),
    );
  }

  /** Most gaps supports pullout, but they are not supported inside Swing cabinets
   * unless they are inside a pullout module.
   * Pullout items would hit the side of the door if not placed in a pullout submodule.*/
  public static supportsPullout(
    gap: Client.GableGap | Client.InteriorGap,
  ): boolean {
    for (let gable of [gap.leftGable, gap.rightGable]) {
      if (!gable) return true;
      if (!gable.isSwingCorpusGable) return true;
    }
    return false;
  }

  private adjustFittingPanelYZPositions(section: Client.CabinetSection) {
    // Adjust the Y position and Height of all FittingPanels

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(section.swingFlex.items)
      .concat(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _topShelf = Enumerable.from(section.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderByDescending((i) => i.Y)
      .firstOrDefault();

    section.interior.items
      .filter((i) => i.isFittingPanel)
      .forEach((gable) => {
        const area = Enumerable.from(section.swingFlex.areas).firstOrDefault(
          (area) => area.index === gable.swingFlexAreaIndex,
        );

        if (!area) return;

        let _findShelfInArea = _shelfs.where(
          (s) => s.swingFlexAreaIndex === gable.swingFlexAreaIndex,
        );
        if (_findShelfInArea.any()) {
          let shelfClosestToBottom = _findShelfInArea.minBy((c) =>
            Math.abs(c.centerY - gable.bottomY),
          );
          if (shelfClosestToBottom) {
            const shelfClosestToTop = _findShelfInArea.minBy((c) =>
              Math.abs(c.bottomY - gable.topY),
            );

            if (shelfClosestToTop && shelfClosestToBottom) {
              gable.Y = shelfClosestToBottom.topY;

              gable.trySetHeight(
                shelfClosestToTop.bottomY - shelfClosestToBottom.topY,
              );

              if (_topShelf) {
                gable.Z = _topShelf.Z;
                gable.trySetDepth(_topShelf.Depth);
              }
            }
          }
        }
      });
  }

  private adjustFittingPanelXPositions(section: Client.CabinetSection) {
    // Adjust the X position of all FittingsPanels
    const _fittingPanelSpacerWidth =
      section.swingFlex.productLineProperties.SwingFlexFittingPanelSpacerWidth;

    let _fittingPanels = Enumerable.from(section.interior.items)
      .where((i) => i.isFittingPanel)
      .orderBy((i) => i.X)
      .asEnumerable();

    _fittingPanels.forEach((gable) => {
      if (gable.drilledLeft) {
        let _closestGable = this.findClosestAreaGable(
          section,
          gable,
          Enums.Side.Right,
          undefined,
          true,
        );
        if (_closestGable) {
          gable.X =
            _closestGable.leftX - (gable.Width + _fittingPanelSpacerWidth);
        }
      } else if (gable.drilledRight) {
        let _closestGable = this.findClosestAreaGable(
          section,
          gable,
          Enums.Side.Left,
          undefined,
          true,
        );
        if (_closestGable) {
          gable.X = _closestGable.rightX + _fittingPanelSpacerWidth;
        }
      }
    });

    if (section.backing.backingType === BackingType.None) {
      section.backing.setBackingType(BackingType.Visible, false);
    }
  }

  private adjustItemToGable(section: Client.CabinetSection) {
    const spaceForBacking =
      section.swingFlex.productLineProperties.SwingFlexSpaceForBacking;
    const areaDepth = section.swingFlex.depth - spaceForBacking;
    const fittingPanelSpacerWidth =
      section.swingFlex.productLineProperties.SwingFlexFittingPanelSpacerWidth;

    let _items = Enumerable.from(section.interior.items)
      .orderBy((i) => i.X)
      .asEnumerable();

    _items.forEach((item) => {
      if (!item.Product) {
        return;
      }
      let productGroup = Enumerable.from(item.Product.productGroup);

      let productListOrderByMax = productGroup
        .orderByDescending((prod) =>
          ProductHelper.maxDepth(prod, item.productLineId),
        )
        .thenByDescending((prod) => ProductHelper.maxWidth(prod));

      if (item.swingFlexAreaIndex !== undefined) {
        let swingFlexArea = section.swingFlex.areas[item.swingFlexAreaIndex];

        // Find the closest left gable to the swing flex area
        let closestGable = Enumerable.from(section.swingFlex.items)
          .concat(section.interior.items)
          .firstOrDefault(
            (i) =>
              i.isGable &&
              !i.isVerticalDivider &&
              !i.isFittingPanel &&
              Math.abs(i.centerX - swingFlexArea.insideRect.X) < 20,
          );

        if (closestGable) {
          const frontZ = closestGable.frontZ;
          const desiredItemDepth = areaDepth - item.depthReduction;

          if (item.isFittingPanel || item.isVerticalDivider) {
            let productClosestDepth = this.findProductClosestDepth(
              productListOrderByMax.toArray(),
              item.productLineId,
              desiredItemDepth,
            );
            if (productClosestDepth) {
              let maxDepth = ProductHelper.maxDepth(
                productClosestDepth,
                item.ProductId,
              );

              item.Product = productClosestDepth;

              if (item.productLineId) {
                item.trySetDepth(
                  desiredItemDepth < maxDepth &&
                    ProductHelper.isFlexDepth(
                      productClosestDepth,
                      item.productLineId,
                    )
                    ? desiredItemDepth
                    : maxDepth,
                );
              }

              item.Z = frontZ - item.depthReduction - item.Depth;
            }

            if (item.isFittingPanel) {
              if (item.drilledLeft) {
                item.X =
                  swingFlexArea.insideRect.X +
                  swingFlexArea.insideRect.Width -
                  (fittingPanelSpacerWidth + item.Width);
              } else if (item.drilledRight) {
                item.X = swingFlexArea.insideRect.X + fittingPanelSpacerWidth;
              }
            }

            return;
          }

          let gableDistance = swingFlexArea.insideRect.Width;
          let rightX = swingFlexArea.insideRect.X;

          if (
            swingFlexArea.middlePanelItem &&
            item.Y < swingFlexArea.middlePanelItem.topY
          ) {
            if (item.centerX > swingFlexArea.middlePanelItem.centerX) {
              gableDistance =
                swingFlexArea.insideRect.RightX -
                swingFlexArea.middlePanelItem.rightX;
            } else {
              gableDistance =
                swingFlexArea.middlePanelItem.X - swingFlexArea.insideRect.X;
            }
          }

          const fittingPanelsInArea = _items.where(
            (c) =>
              c.swingFlexAreaIndex === swingFlexArea.index &&
              c.isFittingPanel &&
              c.Y < item.Y &&
              c.topY > item.topY,
          );
          if (fittingPanelsInArea.any()) {
            const fittingPanelLeft = fittingPanelsInArea.firstOrDefault(
              (c) => c.drilledRight,
            );

            if (fittingPanelLeft) {
              rightX = fittingPanelLeft.rightX;
            }

            const fittingPanelRight = fittingPanelsInArea.firstOrDefault(
              (c) => c.drilledLeft,
            );

            if (fittingPanelRight && fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelLeft.Width) * 2;
            } else if (fittingPanelRight && !fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelRight.Width);
            } else if (!fittingPanelRight && fittingPanelLeft) {
              gableDistance =
                swingFlexArea.insideRect.Width -
                (fittingPanelSpacerWidth + fittingPanelLeft.Width);
            }
          }

          let productListWithinWidth = productListOrderByMax.where((prod) =>
            ProductHelper.supportsWidth(prod, gableDistance),
          );
          if (productListWithinWidth.any()) {
            let drillingPattern = section.isSwingFlex
              ? section.swingFlex.getDrillingPattern()
              : undefined;
            let productClosestDepth = this.findProductClosestDepth(
              productListWithinWidth.toArray(),
              item.productLineId,
              desiredItemDepth,
              drillingPattern,
            );
            if (productClosestDepth) {
              let maxDepth = ProductHelper.maxDepth(
                productClosestDepth,
                item.ProductId,
              );

              item.Product = productClosestDepth;

              if (item.productLineId) {
                item.trySetDepth(
                  desiredItemDepth < maxDepth &&
                    ProductHelper.isFlexDepth(
                      productClosestDepth,
                      item.productLineId,
                    )
                    ? desiredItemDepth
                    : maxDepth,
                );
              }

              item.Z = frontZ - item.depthReduction - item.Depth;

              if (swingFlexArea.middlePanelItem) {
                if (item.X < swingFlexArea.middlePanelItem.X) {
                  rightX = swingFlexArea.insideRect.X;
                } else {
                  rightX = swingFlexArea.middlePanelItem.rightX;
                }
              }

              item.X = rightX;
              item.trySetWidth(gableDistance, false, true);
            }
          }
        }
      }
    });
  }

  private adjustVerticalDivider(section: Client.CabinetSection) {
    let _shelfs = Enumerable.from(section.interior.items)
      .concat(section.swingFlex.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    section.interior.items
      .filter((i) => i.isVerticalDivider)
      .forEach((verticalDivider) => {
        let _findShelfInArea = _shelfs.where(
          (s) =>
            s.leftX <= verticalDivider.centerX &&
            s.rightX >= verticalDivider.centerX,
        );
        if (_findShelfInArea.any()) {
          let shelfClosestToBottom = _findShelfInArea
            .where((s) => s.topY < section.Height)
            .orderBy((s) => Math.abs(s.topY - verticalDivider.bottomY))
            .firstOrDefault();

          let shelfClosestToTop = _findShelfInArea
            .where(
              (s) =>
                s.topY > verticalDivider.bottomY && s !== shelfClosestToBottom,
            )
            .orderBy((s) => Math.abs(s.bottomY - verticalDivider.topY))
            .firstOrDefault();

          if (!shelfClosestToBottom && shelfClosestToTop) {
            verticalDivider.Y = section.interior.cube.Y;
            verticalDivider.trySetHeight(
              shelfClosestToTop.bottomY - section.interior.cube.Y,
              true,
            );
          } else if (shelfClosestToBottom && shelfClosestToTop) {
            verticalDivider.Y = shelfClosestToBottom.topY;
            verticalDivider.trySetHeight(
              shelfClosestToTop.bottomY - shelfClosestToBottom.topY,
              true,
            );
          }

          if (!verticalDivider.Product) {
            return;
          }

          let productGroup = Enumerable.from(
            verticalDivider.Product.productGroup,
          );

          let areaDepth = section.InteriorDepth;
          const desiredItemDepth = areaDepth - verticalDivider.depthReduction;

          let productListOrderByMax = productGroup
            .orderByDescending((prod) =>
              ProductHelper.maxDepth(prod, verticalDivider.productLineId),
            )
            .thenByDescending((prod) => ProductHelper.maxWidth(prod));

          let productClosestDepth = this.findProductClosestDepth(
            productListOrderByMax.toArray(),
            verticalDivider.productLineId,
            desiredItemDepth,
          );
          if (productClosestDepth) {
            let maxDepth = ProductHelper.maxDepth(
              productClosestDepth,
              verticalDivider.ProductId,
            );

            verticalDivider.Product = productClosestDepth;
            verticalDivider.Z = section.interior.cube.Z;

            if (verticalDivider.productLineId) {
              verticalDivider.trySetDepth(
                desiredItemDepth < maxDepth &&
                  ProductHelper.isFlexDepth(
                    productClosestDepth,
                    verticalDivider.productLineId,
                  )
                  ? desiredItemDepth
                  : maxDepth,
              );
            }
          }
        }
      });
  }

  findProductClosestDepth(
    productList: Client.Product[],
    productLineId: Interface_Enums.ProductLineId | null,
    desiredDepth: number,
    drillingPattern?: Interface_Enums.DrillingPattern,
  ): Client.Product | undefined {
    let product: Client.Product | undefined;

    if (!productLineId) {
      return;
    }

    let filteredProducts = !!drillingPattern
      ? productList.filter((p) =>
          ProductHelper.supportsDrillingPattern(p, drillingPattern),
        )
      : productList;

    for (let prod of filteredProducts) {
      if (ProductHelper.defaultDepth(prod, productLineId) === desiredDepth) {
        product = prod;
        break;
      }
    }

    if (product) {
      return product;
    }

    let closestDepth = Number.MAX_VALUE;

    for (let prod of filteredProducts) {
      if (!ProductHelper.isFlexDepth(prod, productLineId)) {
        let depthDiff =
          desiredDepth - ProductHelper.defaultDepth(prod, productLineId);
        if (depthDiff > 0 && depthDiff < closestDepth) {
          closestDepth =
            desiredDepth - ProductHelper.defaultDepth(prod, productLineId);
          product = prod;
        }
      }
    }

    if (product) {
      return product;
    }

    let closestDiff = Number.MAX_VALUE;

    for (let prod of filteredProducts) {
      let maxDepth = ProductHelper.maxDepth(prod, productLineId);
      let minDepth = ProductHelper.minDepth(prod, productLineId);

      let minMaxDiff = Math.min(
        Math.abs(desiredDepth - maxDepth),
        Math.abs(desiredDepth - minDepth),
      );
      if (minMaxDiff < closestDiff) {
        closestDiff = minMaxDiff;
        product = prod;
      }
    }

    return product;
  }

  private findClosestAreaGable(
    section: Client.CabinetSection,
    gable: Client.ConfigurationItem,
    direction: Enums.Side,
    currentItem: Client.ConfigurationItem | undefined,
    skipFittingPanel: boolean = false,
  ): Client.ConfigurationItem | undefined {
    let _gable: Client.ConfigurationItem | undefined = undefined;
    let distance = 9999;

    if (direction === Enums.Side.Left) {
      let _leftGables = Enumerable.from(section.swingFlex.items)
        .concat(section.interior.items)
        .where(
          (i) =>
            i.isGable && i.drilledRight && !i.isVerticalDivider && i != gable,
        )
        .orderBy((i) => i.X)
        .asEnumerable();

      _leftGables.forEach((g) => {
        if (skipFittingPanel && g.isFittingPanel) {
          return;
        }

        let _distance = Math.abs(gable.leftX - g.rightX);
        if (_distance < distance) {
          distance = _distance;
          _gable = g;
        }
      });
    } else if (direction === Enums.Side.Right) {
      let _rightGables = Enumerable.from(section.swingFlex.items)
        .concat(section.interior.items)
        .where(
          (i) =>
            i.isGable &&
            i.drilledLeft &&
            !i.isVerticalDivider &&
            i != gable &&
            i.X > gable.X,
        )
        .orderBy((i) => i.X)
        .asEnumerable();

      _rightGables.forEach((g) => {
        if (skipFittingPanel && g.isFittingPanel) {
          return;
        }

        if (
          currentItem &&
          (g.topY < currentItem.bottomY || g.bottomY > currentItem.topY)
        ) {
          return;
        }

        let _distance = Math.abs(gable.rightX - g.leftX);
        if (_distance < distance) {
          distance = _distance;
          _gable = g;
        }
      });
    }

    return _gable;
  }

  private static findClosestDrillStop(
    gableBetweenDrillStops: number[],
    p0: number,
  ): number {
    let _closestDrillStop = gableBetweenDrillStops.reduce((prev, curr) => {
      return Math.abs(curr - p0) < Math.abs(prev - p0) ? curr : prev;
    });

    return _closestDrillStop;
  }

  private replaceAutoGeneratedItems(section: Client.CabinetSection) {
    this.replaceGrips(section);
  }

  private replaceGrips(section: Client.CabinetSection) {
    section.interior.items = section.interior.items.filter(
      (item) => !item.isGrip,
    );

    const grips: Client.ConfigurationItem[] = [];
    for (const item of section.interior.items) {
      const grip = this.configurationItemService.createDrawerGripItem(
        item,
        Interface_Enums.ItemType.Interior,
      );
      if (!grip) continue;
      if (!grip.isGrip) {
        console.error('Interior item produced a grip that is not a grip', {
          item,
          grip,
        });
        continue;
      }
      grips.push(grip);
    }
    section.interior.items.push(...grips);
  }
}
