import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/index';
export class SwingModuleProduct implements Readonly<Interface_DTO.NavTemplate> {
  constructor(
    public readonly dto: Interface_DTO.NavTemplate,
    public readonly product: Client.Product,
  ) {}

  public get Placement() {
    return this.dto.Placement;
  }

  public get ProductNo() {
    return this.dto.ProductNo;
  }
  public get Quantity() {
    return this.dto.Quantity;
  }
  public get SortOrder() {
    return this.dto.SortOrder;
  }
  public get TemplateNo() {
    return this.dto.TemplateNo;
  }
}
