<div id="index-notification-holder">
  <div
    class="index-notification alert alert-danger"
    *ngFor="let notification of notifications.activeNotifications"
    [ngClass]="{
      'alert-success': notification.type == 0,
      'alert-info': notification.type == 1,
      'alert-warning': notification.type == 2,
      'alert-danger': notification.type == 4 || notification.type == 3,
    }"
    (click)="notifications.remove(notification)"
  >
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div
      class="index-notification-body"
      [textContent]="notification.text"
    ></div>
  </div>
</div>
