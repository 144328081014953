import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';
import { BackingLogic } from 'app/ts/services/ConfigurationLogic/BackingLogic';
export class BackingValidationService {
  public static readonly Name = 'backingValidationService';

  constructor() {}

  public accessTest() {
    return 'Backing Validation Service access test function';
  }

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    if (section.CabinetType !== Interface_Enums.CabinetType.SharedItems) {
      errorInfos.push(
        ...BackingValidationService.validateBackingEndBehindFullHeightGable(
          section,
        ),
      );
      errorInfos.push(
        ...BackingValidationService.validateBackingJointBehindFlexHeightGable(
          section,
        ),
      );
      errorInfos.push(
        ...BackingValidationService.validateBackingHeight(section),
      );
      errorInfos.push(
        ...BackingValidationService.validateBackingOverlap(section),
      );

      errorInfos.push(
        ...BackingValidationService.validateVisibleBackingByWall(section),
      );

      errorInfos.push(...BackingValidationService.validateProducts(section));
      errorInfos.push(...BackingValidationService.validateMaterials(section));
    }

    return errorInfos;
  }

  /**
   * Validates if both ends of the backing are behind a full height gable
   */
  public static validateBackingEndBehindFullHeightGable(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (section.backing.items.length <= 0) {
      return result;
    }

    let gables = Enumerable.from(section.interior.items)
      .where((ii) => ii.isGable && ii.topY < section.InteriorHeight)
      .orderBy((g) => g.centerX);
    if (gables.any()) {
      let errors = 0;

      let backingItems = Enumerable.from(section.backing.items).orderBy(
        (bi) => bi.leftX,
      );
      let firstBacking = backingItems.first();
      let lastBacking = backingItems.last();

      let firstGable = gables.first();
      let lastGable = gables.last();

      if (
        firstGable.leftX < firstBacking.leftX &&
        firstGable.rightX > firstBacking.leftX
      )
        errors++;

      if (
        lastGable.leftX < lastBacking.rightX &&
        lastGable.rightX > lastBacking.rightX
      )
        errors++;

      for (let i = 0; i < errors; i++) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Backing,
            Interface_Enums.ErrorLevel.Info,
            'Backing end',
            'Backing end behind gable, that is not full height.',
            section,
          ),
        );
      }
    }
    return result;
  }

  /**
   * Validates all joints between backing items are behind a flex height gable
   */
  public static validateBackingJointBehindFlexHeightGable(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (section.backing.items.length <= 0) {
      return result;
    }

    let flexGableCenterXs = Enumerable.from(section.interior.items)
      .where(
        (ii) =>
          ii.isGable && ii.isFlexHeight && ii.topY >= section.InteriorHeight,
      )
      .select((ii) => ii.centerX);
    let backingItems = section.backing.items.sort(
      (n1, n2) => n1.leftX - n2.leftX,
    );

    for (let i = 0; i < backingItems.length - 1; i++) {
      if (
        !flexGableCenterXs.any(
          (x) => Math.abs(x - backingItems[i].rightX) < 10,
        ) ||
        !flexGableCenterXs.any(
          (x) => Math.abs(x - backingItems[i + 1].leftX) < 10,
        )
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Backing,
            Interface_Enums.ErrorLevel.Info,
            'Backing joint',
            'Backing joints should be behind full height gables.',
            section,
          ),
        );
      }
    }
    return result;
  }

  /**
   * Validates if the backing material supports the required height for the backing items
   */
  public static validateBackingHeight(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (section.backing.items.length <= 0) {
      return result;
    }

    let actualBackingHeight = section.backing.items[0].Height;
    let expectedBackingHeight = BackingLogic.backingHeight(section);

    if (actualBackingHeight < expectedBackingHeight) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Backing,
          Interface_Enums.ErrorLevel.Critical,
          'Backing height',
          'The selected backing material does not support the backing height.',
          section,
        ),
      );
    }
    return result;
  }

  /**
   * Validates if any of the backing items overlap
   */
  public static validateBackingOverlap(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    let backingItems = section.backing.items.sort(
      (n1, n2) => n1.leftX - n2.leftX,
    );

    for (let i = 0; i < backingItems.length - 1; i++) {
      if (backingItems[i].rightX > backingItems[i + 1].leftX) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Backing,
            Interface_Enums.ErrorLevel.Info,
            'Overlapping backings',
            'Backings overlap...',
            section,
          ),
        );
      }
    }
    return result;
  }

  /**
   * Validates if a section with visible backing is positioned directly up against a wall
   * @param section
   */
  public static validateVisibleBackingByWall(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (section.backing.backingType !== Interface_Enums.BackingType.Visible) {
      return result;
    }

    if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      return result;
    }

    let centerBackPoint = section.getBackCenterPoint();
    let wallPoints = section.cabinet.floorPlan.getPoints();
    let minDistance = GeometryHelper.distanceToClosestSideOfPolygon(
      centerBackPoint,
      wallPoints,
    );

    if (minDistance < section.backing.thickness) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Backing,
          Interface_Enums.ErrorLevel.Info,
          'Visible backing by wall',
          'The cabinet has visible backing up against a wall. Consider choosing hidden backing.',
          section,
        ),
      );
    }

    return result;
  }

  /**
   * Validates the product for all backing items
   */
  private static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    if (
      !section.backing.availableProducts.any() &&
      section.backing.items.length > 0
    ) {
      return [
        new Client.ErrorInfo(
          Enums.EditorSection.Backing,
          Interface_Enums.ErrorLevel.Critical,
          'Invalid backing selection',
          'Backing is no longer available for your cabinet. Please disable backing.',
          section,
        ),
      ];
    }
    return ItemValidationService.validateProducts(
      section.backing.items,
      Enums.EditorSection.Backing,
      section,
    );
  }

  /**
   * Validates the material for all backing items
   */
  private static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateMaterials(
      section.backing.items,
      Enums.EditorSection.Backing,
      section,
    );
  }
}
