<label *ngFor="let itemVariant of variants">
  <span
    *ngIf="itemVariant.onlyAdmin"
    [attr.title]="
      translate(
        'editor_variant_only_admin',
        'This variant can only be changed by support'
      )
    "
    class="bi bi-exclamation-triangle-fill"
    style="color: goldenrod"
  ></span>
  <span [textContent]="itemVariant.name"></span>
  <select
    class="form-select"
    (ngModelChange)="setVariantOption(itemVariant.number, $event.number)"
    [(ngModel)]="itemVariant.selectedOption"
  >
    <option *ngFor="let opt of itemVariant.options" [ngValue]="opt">
      {{ opt.name }}
    </option>
  </select>
</label>
