import * as Interface_DTO from 'app/ts/Interface_DTO';
import { DeliveryAddress } from 'app/ts/clientDto/DeliveryAddress';
import { ISelectable } from 'app/ts/util/ISelectable';
import { Copyable } from 'app/ts/interfaces/Copyable';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
export class FloorPlanOverview
  implements
    Interface_DTO.FloorPlanOverview,
    Copyable<FloorPlanOverview>,
    ISelectable,
    DtoContainer<Interface_DTO.FloorPlanOverview>
{
  public readonly typename: 'FloorPlanOverview' = 'FloorPlanOverview';
  public get isFloorPlan() {
    return !this.dtoObject.IsLegacyFloorPlan;
  }

  constructor(
    public readonly dtoObject: Interface_DTO.FloorPlanOverview,
    public readonly deliveryAddress: DeliveryAddress,
  ) {
    if (!dtoObject) throw new Error('ArgumentNull dtoObject');
    if (!deliveryAddress) throw new Error('ArgumentNull deliveryAddress');
  }

  get CorrectionDeadlineDate() {
    return this.dtoObject.CorrectionDeadlineDate;
  }
  get CreatedDate() {
    return this.dtoObject.CreatedDate;
  }
  get CurrentStatus() {
    return this.dtoObject.CurrentStatus;
  }
  get DeliveryInfoId() {
    return this.dtoObject.DeliveryInfoId;
  }
  get DeliveryModifiedDate() {
    return this.dtoObject.DeliveryModifiedDate;
  }
  get DeliveryWeek() {
    return this.dtoObject.DeliveryWeek;
  }
  get DriverInformation() {
    return this.dtoObject.DriverInformation;
  }
  get ExpectedDeliveryDays() {
    return this.dtoObject.ExpectedDeliveryDays;
  }
  get Id() {
    return this.dtoObject.Id;
  }
  get InternalNote() {
    return this.dtoObject.InternalNote;
  }
  get IsLegacyFloorPlan() {
    return this.dtoObject.IsLegacyFloorPlan;
  }
  get Name() {
    return this.dtoObject.Name;
  }
  get NumCabinets() {
    return this.dtoObject.NumCabinets;
  }
  get NumManualItems() {
    return this.dtoObject.NumManualItems;
  }
  get OwnedByCurrentUser() {
    return this.dtoObject.OwnedByCurrentUser;
  }
  get OrderDate() {
    return this.dtoObject.OrderDate;
  }
  get OrderNo() {
    return this.dtoObject.OrderNo;
  }
  get PlannedDeliveryDate() {
    return this.dtoObject.PlannedDeliveryDate;
  }
  get ProjectDeliveryAddressId() {
    return this.dtoObject.ProjectDeliveryAddressId;
  }
  get Reference() {
    return this.dtoObject.Reference;
  }
  get SmsNumber() {
    return this.dtoObject.SmsNumber;
  }
  get UserId() {
    return this.dtoObject.UserId;
  }
  get UserName() {
    return this.dtoObject.UserName;
  }
  get SalesTypeNumber() {
    return this.dtoObject.SalesTypeNumber;
  }
  get SalesTypeCaseNumber() {
    return this.dtoObject.SalesTypeCaseNumber;
  }
  get ImageUrl() {
    return this.dtoObject.ImageUrl;
  }
  get UsesFullCatalog() {
    return this.dtoObject.UsesFullCatalog;
  }
  get MaxErrorLevel() {
    return this.dtoObject.MaxErrorLevel;
  }
  get TotalFreightAndFees() {
    return this.dtoObject.TotalFreightAndFees;
  }
  get TotalPrice() {
    return this.dtoObject.TotalPrice;
  }
  get TotalPriceInclVat() {
    return this.dtoObject.TotalPriceInclVat;
  }
  get SupporterId() {
    return this.dtoObject.SupporterId;
  }
  get SupporterName() {
    return this.dtoObject.SupporterName;
  }
  get ActualStatus() {
    return this.dtoObject.ActualStatus;
  }
  get AppCompatibility() {
    return this.dtoObject.AppCompatibility;
  }
  get ShippingAgentCode() {
    return this.dtoObject.ShippingAgentCode;
  }
  get IntegrationIdentifier() {
    return this.dtoObject.IntegrationIdentifier;
  }
  get IntegrationIdentifier2() {
    return this.dtoObject.IntegrationIdentifier2;
  }
  get IntegrationIdentifier3() {
    return this.dtoObject.IntegrationIdentifier3;
  }
  get LogisticsComment() {
    return this.dtoObject.LogisticsComment;
  }
  get OrderComment() {
    return this.dtoObject.OrderComment;
  }
  get ManualOrderHandling() {
    return this.dtoObject.ManualOrderHandling;
  }
  get MarketOrderNumber() {
    return this.dtoObject.MarketOrderNumber;
  }
  get SendReminderEmails() {
    return this.dtoObject.SendReminderEmails;
  }

  public selected: boolean = false;

  public copy() {
    return new FloorPlanOverview(this.dtoObject, this.deliveryAddress);
  }
}
