import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
@Component({
  selector: 'order-status',
  templateUrl: 'orderStatus.html',
  styleUrls: [
    '../../../../style/orderStatus.scss',
    '../../../../style/rightMenu.scss',
    '../../../../style/tables.scss',
  ],
})
export class OrderStatusVm extends BaseVm implements OnChanges {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  @Input()
  public floorPlans: Interface_DTO.FloorPlanOverview[] | null = null;

  public orderStatusClass: string = 'order-status-mixed';
  public orderStatusName: string = '';
  public orderStatus: Interface_Enums.DeliveryStatus | null = null;

  public ngOnChanges(changes: SimpleChanges): void {
    this.orderStatus = !!this.floorPlans
      ? this.floorPlans
          .map(
            (fpo) => fpo.CurrentStatus as Interface_Enums.DeliveryStatus | null,
          )
          .reduce((prev, curr) => (prev === curr ? prev : null))
      : null;

    if (!!this.floorPlans && this.floorPlans.length > 0)
      this.orderStatus = this.floorPlans
        .map(
          (fpo) => fpo.CurrentStatus as Interface_Enums.DeliveryStatus | null,
        )
        .reduce((prev, curr) => (prev === curr ? prev : null));
    else this.orderStatus = null;

    this.orderStatusClass =
      this.orderStatus === null
        ? 'order-status-mixed'
        : 'order-status-' + this.orderStatus;
    this.orderStatusName =
      this.orderStatus === null
        ? this.translate('floorplan_status_name_mixed', 'Mixed statuses')
        : this.translate(
            'floorplan_status_name_' + this.orderStatus,
            'Status ' + this.orderStatus,
          );
  }
}
