import * as Client from 'app/ts/clientDto/index';
export class BaseCustomer {
  public constructor(c: Client.Customer) {
    this.id = c.Id;
    this.name =
      c.Name + ' | ' + c.Address1 + ' | ' + c.PostalCode + ' ' + c.City;
  }
  id: number;
  name: string;
}
