import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';

export class Material implements Interface_DTO.Material {
  constructor(
    public readonly dto: Readonly<Interface_DTO.Material>,
    public readonly materialGroup: Interface_DTO.MaterialGroup,
    public readonly designMaterialGroup: Interface_DTO.MaterialGroup,
  ) {}

  get ColorCode(): string {
    return this.dto.ColorCode;
  }
  get CorrespondingNumber(): string {
    return this.dto.CorrespondingNumber;
  }
  get DefaultName(): string {
    return this.dto.DefaultName;
  }
  get DesignGroupName(): string {
    return this.dto.DesignGroupName;
  }
  get DesignMaterialGroupId(): number {
    return this.dto.DesignMaterialGroupId;
  }
  get DesignPriceGroup(): number {
    return this.dto.DesignPriceGroup;
  }
  get Gloss(): number {
    return this.dto.Gloss;
  }
  get GroupName(): string {
    return this.dto.GroupName;
  }
  get Id(): number {
    return this.dto.Id;
  }
  get ImagePath(): string | null {
    return this.dto.ImagePath;
  }
  get IsGlass(): boolean {
    return this.dto.IsGlass;
  }
  get MaterialGroupId(): number {
    return this.dto.MaterialGroupId;
  }
  get MaterialGroupIsDesign(): boolean {
    return this.dto.MaterialGroupIsDesign;
  }
  get MaxFillingHeight(): number {
    return this.dto.MaxFillingHeight;
  }
  get MaxFillingWidth(): number {
    return this.dto.MaxFillingWidth;
  }
  get MinFillingHeight(): number {
    return this.dto.MinFillingHeight;
  }
  get Number(): string {
    return this.dto.Number;
  }
  get Opacity(): number {
    return this.dto.Opacity;
  }
  get PriceGroup(): number {
    return this.dto.PriceGroup;
  }
  get PriceLevel(): number {
    return this.dto.PriceLevel;
  }
  get Rotatable(): boolean {
    return this.dto.Rotatable;
  }
  get SortOrder(): number {
    return this.dto.SortOrder;
  }
  get Tile(): boolean {
    return this.dto.Tile;
  }
  get Type(): Interface_Enums.MaterialType {
    return this.dto.Type;
  }
  get TexturePath() {
    return this.dto.TexturePath;
  }
  get TextureHeightMm() {
    return this.dto.TextureHeightMm;
  }
  get TextureWidthMm() {
    return this.dto.TextureWidthMm;
  }

  public get groupSortOrder() {
    return this.materialGroup ? this.materialGroup.Number : '';
  }

  public get designGroupSortOrder() {
    return this.designMaterialGroup ? this.designMaterialGroup.Number : '';
  }
}
