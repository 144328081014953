import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NModule } from 'app/i18n/i18n.module';
import { IdentityAndAccessModule } from 'app/identity-and-access/identity-and-access.module';
import { ErrorInfoVm } from './ErrorInfoVm';

@NgModule({
  declarations: [ErrorInfoVm],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,

    I18NModule,
    IdentityAndAccessModule,
  ],
  exports: [ErrorInfoVm],
})
export class ErrorInfoModule {}
