import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { Language } from 'app/ts/Interface_DTO';

export interface Languages {
  get data(): Language[];
}

function languagesFactory(data: FunctionalCoreService): Languages {
  return {
    data: data.ambient.i8n.languages,
  };
}

export const LanguagesInjector = new InjectionToken<Languages>('languages', {
  factory() {
    const data = inject(FunctionalCoreService);

    return languagesFactory(data);
  },
});

export const languagesProvider: Provider = {
  provide: LanguagesInjector,
  useFactory: languagesFactory,
  deps: [FunctionalCoreService],
};
