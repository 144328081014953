export class AuthenticationException {
  public readonly exception: Error;

  constructor(
    public readonly method: string | undefined,
    public readonly url: string | undefined,
    public readonly cookieString: string | undefined,
  ) {
    this.exception = new Error(
      'Authentication error: ' +
        (method || 'unknown method') +
        (url || 'Unknown url'),
    );
  }
}
