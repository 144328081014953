import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'joint-positions-vm',
  templateUrl: './jointPositions.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class JointPositionsVm extends BaseVm implements OnInit {
  public topJoints: JointPositionsVm.JointSetter[] = [];
  public bottomJoints: JointPositionsVm.JointSetter[] = [];

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly floorPlanService: FloorPlanService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.rebuildJointSetters();
  }

  public readonly cabinetSection!: Client.CabinetSection;

  public get automaticPositioning(): boolean {
    return !this.cabinetSection.ManualFishJointPositioning;
  }

  public set automaticPositioning(val: boolean) {
    this.cabinetSection.ManualFishJointPositioning = !val;
    this.setChanged();
  }

  public close(): void {
    this.activeModal.close(undefined);
  }

  private async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
    this.rebuildJointSetters();
  }

  private rebuildJointSetters() {
    this.topJoints = this.cabinetSection.corpus.manualJointPositionsTop.map(
      (constrainedNumber) =>
        new JointPositionsVm.JointSetter(constrainedNumber, () =>
          this.setChanged(),
        ),
    );

    this.bottomJoints =
      this.cabinetSection.corpus.manualJointPositionsBottom.map(
        (constrainedNumber) =>
          new JointPositionsVm.JointSetter(constrainedNumber, () =>
            this.setChanged(),
          ),
      );
  }
}

export module JointPositionsVm {
  export class JointSetter {
    constructor(
      private readonly n: Client.ConstrainedNumber,
      private readonly changedCallback: () => void,
    ) {
      this.minValue = n.minValue;
      this.maxValue = n.maxValue;
    }

    public readonly minValue: number;
    public readonly maxValue: number;

    public get actualValue(): number {
      return this.n.actualValue;
    }
    public set actualValue(val: number) {
      this.n.actualValue = val;
      this.changedCallback();
    }
  }
}
