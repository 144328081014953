import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';
import { SwingLogic } from 'app/ts/services/ConfigurationLogic/SwingLogic';
export class SwingValidationService {
  public static readonly Name = 'swingValidationService';

  constructor() {}

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    if (section.CabinetType !== Interface_Enums.CabinetType.Swing)
      return errorInfos;

    errorInfos.push(...SwingValidationService.validateAreaTotalWidth(section));
    errorInfos.push(
      ...SwingValidationService.validateAreaStructuralSupport(section),
    );

    errorInfos.push(...SwingValidationService.validateProducts(section));
    errorInfos.push(...SwingValidationService.validateMaterials(section));

    return errorInfos;
  }

  /**
   * Validates if the areas fill the total section width.
   * @param section
   */
  private static validateAreaTotalWidth(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errors: Client.ErrorInfo[] = [];

    let rightX = Math.max(...section.swing.items.map((i) => i.rightX));
    if (rightX < section.Width) {
      let error = new Client.ErrorInfo(
        Enums.EditorSection.Swing,
        Interface_Enums.ErrorLevel.Info,
        'Unused space',
        'There is still some unused space.',
        section,
        undefined,
      );

      this.addError(error, errors, []);
    }

    return errors;
  }

  /**
   * Validates if all areas have some structural support spanning the entire inside width (Shelf, pullout or regular)
   * @param section
   */
  private static validateAreaStructuralSupport(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errors: Client.ErrorInfo[] = [];

    section.swing.areas.forEach((area) => {
      if (
        area.pullOut !== Enums.SwingPulloutSingle.Yes &&
        area.pullOut !== Enums.SwingPulloutDouble.Both
      ) {
        let items = SwingLogic.getInteriorItemsInArea(section, area);
        let shelves = items.filter(
          (item) => item.snapLeftAndRight && item.Depth > 100,
        );

        if (shelves.length < 1) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Warning,
            'Structural support advised',
            'A shelf or full width pullout module(s) is advised for structural support.',
            section,
            undefined,
          );

          this.addError(error, errors, []);
        }
      }
    });

    return errors;
  }

  /**
   * Validates the product for all door and rail items
   */
  private static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateProducts(
      section.swing.items,
      Enums.EditorSection.Swing,
      section,
    );
  }

  /**
   * Validates the material for all door and rail items
   */
  private static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateMaterials(
      section.swing.items,
      Enums.EditorSection.Swing,
      section,
    );
  }

  /**
   * Private helper for adding an error
   * @param error The new error
   * @param errors The list of existing errore
   * @param items The items that the error relates to
   */
  private static addError(
    error: Client.ErrorInfo,
    errors: Client.ErrorInfo[],
    items: Client.ConfigurationItem[],
  ) {
    errors.push(error);
    items.forEach((item) => item.errors.push(error));
  }
}
