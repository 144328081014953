<!-- vm is in Vm.Consumer.ConsumerItemEditorVm -->
<div class="consumer-item-editor">
  <div class="header">
    <h6 [textContent]="productName"></h6>
    <button
      class="btn btn-primary"
      (click)="delete()"
      [textContent]="translate('consumer_interior_item_delete_btn', 'Delete')"
    ></button>
  </div>

  <div
    class="item-variant picker-variant material-variant"
    *ngIf="showMaterialPicker"
  >
    <item-picker
      [items]="materials"
      [(selectedItem)]="selectedMaterial"
      [name]="translate('consumer_interior_material', 'Interior Material')"
    >
    </item-picker>
  </div>

  <div
    class="item-variant boolean-variant adjust-screw-variant"
    *ngIf="showAdjustmentScrewVariant"
  >
    <label class="radio-check-label">
      <input type="checkbox" [(ngModel)]="adjustmentScrewVariant" />
      <span class="checkmark"></span>
      <span
        class="translation"
        [textContent]="translate(
                      'consumer_interior_item_variant_adjustment_screw', 
                      'Adjustment screw')"
      ></span>
    </label>
  </div>

  <div class="item-alerts" *ngIf="item">
    <div
      class="item-alert"
      *ngFor="let err of item.errors"
      [ngClass]="{
                'al-danger': err.level === 20,
                'al-warning': err.level === 10,
                'al-info': err.level === 5}
             "
    >
      <span class="bi bi-exclamation-triangle-fill" aria-hidden="true"></span>
      <div class="description">
        <div
          [textContent]="err.shortDescription"
          class="short-description translation"
        ></div>
        <div
          [textContent]="err.longDescription"
          class="long-description translation"
        ></div>
      </div>
    </div>
  </div>
</div>
