<div class="overview-content">
  <customer-topbar [parent]="this"></customer-topbar>

  <div class="overview-minus-topbar">
    <dark-side-bar></dark-side-bar>

    <div class="overview-minus-dark-side-bar">
      <customer-nav
        [selection]="'projects'"
        [customerCounts]="customers$ | async | updateCounts"
      ></customer-nav>

      <div class="overview-minus-customer-nav">
        <button
          class="btn btn-light"
          (click)="newObject()"
          *ngIf="parentObject"
          [attr.title]="translate('projects_button_new_project', 'New Project')"
        >
          <span class="bi bi-plus"></span>
          <span class="bi bi-person-fill"></span>
          <span
            [textContent]="translate('projects_button_new_project', 'New Project')"
          ></span>
        </button>
        <button
          class="btn btn-light"
          (click)="selectColumns()"
          [attr.title]="translate('projects_button_select_columns', 'Columns')"
        >
          <span class="bi bi-funnel-fill"></span>
        </button>

        <projects-table
          [objects]="objects"
          (onItemSelected)="setSelected($event)"
          (onBackward)="showAncestor($event)"
          (onForward)="showChildren($event)"
          [highlightId]="highlightId"
        >
        </projects-table>
      </div>
    </div>
  </div>
  <corner-name-bubble></corner-name-bubble>
</div>

<project-edit-menu [project]="editingProject" (onClose)="closeRightMenu()">
</project-edit-menu>
