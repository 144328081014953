<form id="order-detail-body-form">
  <div class="ftable" *ngIf="dtoObject">
    <table class="detail-table form-body">
      <tr
        class="detail-property detail-property-owner-name"
        [attr.title]="translate('floorplan_detail_title_owner_name', 'Owner')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_owner_name', 'Owner')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="UserId"
            [(ngModel)]="dtoObject.UserId"
            [disabled]="!canEdit || !canEditOwner"
            (ngModelChange)="fieldChanged('UserId')"
          >
            <option *ngFor="let user of users" [ngValue]="user.id">
              {{user.name}}
            </option>
          </select>
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteUserId"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.UserId"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-name"
        [attr.title]="translate('floorplan_detail_title_name', 'Name')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_name', 'Name')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="dtoObjectName"
            type="text"
            [disabled]="!canEdit"
            maxlength="maxfloorPlanNameLength"
            [(ngModel)]="dtoObject.Name"
            (ngModelChange)="fieldChanged('Name')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwritedtoObjectName"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.Name"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-delivery-week"
        [attr.title]="translate('floorplan_detail_title_delivery_week', 'Delivery Week')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_delivery_week', 'Delivery Week')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="DeliveryWeek"
            type="number"
            [disabled]="!canEdit"
            [(ngModel)]="dtoObject.DeliveryWeek"
            (ngModelChange)="fieldChanged('DeliveryWeek')"
          />
        </td>

        <!-- test
                    <input class="form-control" type="text" [outerText]=dtoObject.Name/> -->

        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteDeliveryWeek"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.DeliveryWeek"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-reference"
        [attr.title]="translate('floorplan_detail_title_reference', 'Reference')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_reference', 'Reference')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="Reference"
            type="text"
            [(ngModel)]="dtoObject.Reference"
            [required]="requireReferenceOnOrdering"
            [disabled]="!canEdit || !canEditReference"
            (ngModelChange)="fieldChanged('Reference')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteReference"
            tabindex="-1"
            *ngIf="canEditReference"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.Reference"
          />
        </td>
      </tr>

      <tr
        class="detail-property"
        [attr.title]="translate('floorplan_detail_title_market_order_number', 'Market Order Number')"
        *ngIf="showMarketOrderNumber"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_market_order_number', 'Market Order Number')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="MarketOrderNumber"
            type="text"
            [required]="requireMarketOrderOnOrdering"
            [(ngModel)]="dtoObject.MarketOrderNumber"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('MarketOrderNumber')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteMarketOrderNumber"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.MarketOrderNumber"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-sms-phone"
        [attr.title]="translate('floorplan_detail_title_sms_phone', 'SMS Phone')"
        *ngIf="showSmsField"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sms_phone', 'SMS Phone')"
        ></td>
        <td class="detail-value">
          <phone-input
            [phoneNumberObservable]="smsObservable"
            [isDisabled]="!canEdit"
            [defaultPrefix]="defaultPhonePrefix"
            [required]="requireSmsOnOrdering"
            (phoneNumberChange)="phoneNumberChange($event)"
          >
          </phone-input>
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="SmsNumber"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.SmsNumber"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier', 'Integration Identifier')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier', 'Integration Identifier')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="IntegrationIdentifier"
            type="text"
            ng-trim="false"
            [(ngModel)]="dtoObject.IntegrationIdentifier"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('IntegrationIdentifier')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteIntegrationIdentifier"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.IntegrationIdentifier"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier2"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier2', 'Integration Identifier2')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier2', 'Integration Identifier2')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="IntegrationIdentifier2"
            type="text"
            ng-trim="false"
            [(ngModel)]="dtoObject.IntegrationIdentifier2"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('IntegrationIdentifier2')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteIntegrationIdentifier2"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.IntegrationIdentifier2"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier3"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier3', 'Integration Identifier3')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier3', 'Integration Identifier3')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="IntegrationIdentifier3"
            type="text"
            ng-trim="false"
            [(ngModel)]="dtoObject.IntegrationIdentifier3"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('IntegrationIdentifier3')"
          />
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteIntegrationIdentifier3"
            tabindex="-1"
            [disabled]="!canEdit"
            [attr.title]="translate('floorplan_detail_overwrite', 'Overwrite')"
            [(ngModel)]="overwrite.IntegrationIdentifier3"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-shipping_agent"
        *ngIf="showShippingAgents"
        [attr.title]="translate('floorplan_detail_title_shipping_agent', 'Shipping Agent')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_shipping_agent', 'Shipping Agent')"
        ></td>
        <td class="detail-value">
          <select
            class="form-control"
            name="ShippingAgentCode"
            [(ngModel)]="dtoObject.ShippingAgentCode"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('ShippingAgentCode')"
          >
            <option
              *ngFor="let salesChainShippingAgent of salesChainShippingAgents"
              [ngValue]="salesChainShippingAgent.ShippingAgentCode"
            >
              {{salesChainShippingAgent.ShippingAgentName}}
            </option>
          </select>
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteShippingAgentCode"
            tabindex="-1"
            [disabled]="!canEdit"
            [(ngModel)]="overwrite.ShippingAgentCode"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-sales-type"
        [attr.title]="translate('floorplan_detail_title_sales_type', 'Sales Type')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sales_type', 'Sales Type')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="SalesTypeNumber"
            [disabled]="!canEdit"
            [(ngModel)]="dtoObject.SalesTypeNumber"
            (ngModelChange)="setDefaultSalesTypeCaseNumber(); fieldChanged('SalesTypeNumber')"
          >
            <option
              *ngFor="let salesType of salesTypes"
              [ngValue]="salesType.Number"
            >
              {{salesType.Name}}
            </option>
          </select>
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteSalesTypeNumber"
            tabindex="-1"
            [disabled]="!canEdit"
            [(ngModel)]="overwrite.SalesTypeNumber"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-sales-type-case"
        [attr.title]="translate('floorplan_detail_title_sales_type_case', 'Sales Type Case')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sales_type_case', 'Sales Type Case')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="SalesTypeCaseNumber"
            [disabled]="!canEdit || !selectedSalesType || selectedSalesType.SalesTypeCases.length < 2"
            [(ngModel)]="dtoObject.SalesTypeCaseNumber"
            (ngModelChange)="fieldChanged('SalesTypeNumber')"
          >
            <option
              *ngFor="let salesTypeCase of selectedSalesType?.SalesTypeCases"
              [ngValue]="salesTypeCase.Number"
            >
              {{salesTypeCase.Name}}
            </option>
          </select>
        </td>
        <td class="detail-changed">
          <input
            type="checkbox"
            name="OverwriteSalesTypeCaseNumber"
            tabindex="-1"
            [disabled]="!canEdit"
            [(ngModel)]="overwrite.SalesTypeNumber"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-manual-order-handling"
        *ngIf="showManualOrderHandling"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_manual_order_handling', 'Manual order handling')"
        ></td>
        <td class="detail-value">
          <input
            type="checkbox"
            name="ManualOrderHandling"
            [(ngModel)]="dtoObject.ManualOrderHandling"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('ManualOrderHandling')"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-send-reminder-email"
        *ngIf="showSendReminderEmails"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_send_reminder_emails', 'Fremsend reminder email')"
        ></td>
        <td class="detail-value">
          <input
            type="checkbox"
            name="SendReminderEmails"
            [(ngModel)]="dtoObject.SendReminderEmails"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('SendReminderEmails')"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-driver-information detail-property-multiline"
        [attr.title]="translate('floorplan_detail_title_driver_information', 'Driver information')"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_driver_information', 'Driver information')"
          ></span>
          <textarea
            class="detail-value form-control"
            name="DriverInformation"
            maxlength="50"
            rows="2"
            [(ngModel)]="dtoObject.DriverInformation"
            [disabled]="!canEdit"
            (ngModelChange)="fieldChanged('DriverInformation')"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-internal-note"
        [attr.title]="translate('floorplan_detail_title_internal_note', 'Internal Note')"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_internal_note', 'Internal Note')"
          ></span>
          <textarea
            class="form-control detail-value"
            name="InternalNote"
            rows="2"
            [disabled]="!canEdit"
            [(ngModel)]="dtoObject.InternalNote"
            (ngModelChange)="fieldChanged('InternalNote')"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-order-comment"
        [attr.title]="translate('floorplan_detail_title_order_comment', 'Order Comment')"
        *ngIf="showOrderComment"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_order_comment', 'Order Comment')"
          ></span>
          <textarea
            class="form-control detail-value"
            name="OrderComment"
            rows="2"
            [disabled]="!canEdit"
            [(ngModel)]="dtoObject.OrderComment"
            (ngModelChange)="fieldChanged('OrderComment')"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-logisitcs-comment"
        [attr.title]="translate('floorplan_detail_title_logistics_comment', 'Logistics Comment')"
        *ngIf="showLogisticsComment"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_logistics_comment', 'Logistics Comment')"
          ></span>
          <textarea
            class="form-control detail-value"
            name="LogisticsComment"
            maxlength="30"
            rows="2"
            [disabled]="!canEdit"
            [(ngModel)]="dtoObject.LogisticsComment"
            (ngModelChange)="fieldChanged('LogisticsComment')"
          ></textarea>
        </td>
      </tr>
    </table>
  </div>

  <div class="form-footer">
    <div class="order-warnings">
      <div
        class="order-warning order-warning-delivery-week-far-future"
        [textContent]="translate('order_warning_delivery_week_far_future','Please note: Delivery date is more than 200 days in future')"
        *ngIf="deliveryDateIsFarInFuture"
      ></div>
    </div>

    <div class="order-infos">
      <div
        class="order-warning order-warning-delivery-floor"
        [textContent]="translate('order_warning_delivery_floor_0', 'We deliver to ground floor, no carry up.')"
        *ngIf="floor === 0"
      ></div>

      <div
        class="order-warning order-warning-delivery-floor"
        [textContent]="translate('order_warning_delivery_floor_x', 'We deliver to floor {0}, including carry up.', floor)"
        *ngIf="floor ?? 0 > 0"
      ></div>

      <div
        class="order-warning"
        [textContent]="translate('order_warning_v1_order_upgrade', 'To change delivery info for this order, please upgrade it by opening it in the editor and reordering it.')"
        *ngIf="showActiveLegacyOrderWarning"
      ></div>
    </div>

    <div
      class="btn-group btn-group-right"
      *ngIf="!isNotCompatible && (canOrder || canSave)"
    >
      <button
        class="btn btn-secondary btn-alt-primary"
        type="submit"
        formnovalidate
        (click)="save()"
        [ngClass]="{'active': saveButtonActive, 'btn-alt-primary': needsSave}"
        [textContent]="(translate('button_save', 'Save'))"
      ></button>
      <button
        class="btn btn-success"
        [ngClass]="{'active': orderButtonActive}"
        [disabled]="!canOrder"
        [textContent]="translate('button_commit_order3', 'Order')"
        (click)="order()"
      ></button>
    </div>

    <div
      class="btn-group btn-group-right"
      *ngIf="(!isNotCompatible && canUpdateOrder) || canCancelOrder"
    >
      <button
        class="btn btn-success"
        *ngIf="!isNotCompatible && canUpdateOrder"
        [ngClass]="{'active': updateOrderButtonActive}"
        [textContent]="translate('button_update_order', 'Update order')"
        (click)="updateOrder()"
      ></button>
      <button
        class="btn btn-danger"
        *ngIf="canCancelOrder"
        [ngClass]="{'active': cancelButtonActive }"
        [textContent]="translate('button_cancel_order', 'Cancel order')"
        (click)="cancelOrder()"
      ></button>
    </div>
  </div>
</form>
