import { PulloutWarningSeverity } from 'app/ts/clientDto/Enums';
export class PulloutWarningArea {
  constructor(
    public start: number,
    public end: number,
    public readonly severity: PulloutWarningSeverity,
  ) {}

  /**
   * Determines if a pullout warning area overlaps two given values
   */
  public overlaps(val1: number, val2: number): boolean {
    if (val1 < this.start && val2 < this.start) {
      return false;
    }

    if (val1 > this.end && val2 > this.end) {
      return false;
    }

    return true;
  }

  public surrounds(val1: number, val2: number): boolean {
    if (val1 < this.start || val2 < this.start) {
      return false;
    }

    if (val1 > this.end || val2 > this.end) {
      return false;
    }

    return true;
  }

  public get width(): number {
    return this.end - this.start;
  }
}
