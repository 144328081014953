import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationItem } from 'app/ts/clientDto/ConfigurationItem';
import { Constants } from 'app/ts/Constants';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'item-depth-vm',
  templateUrl: './ItemDepth.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class ItemDepthVm extends BaseVm implements OnInit {
  public newDepth: number = 0;
  public overrideItemLock: boolean = true;

  public readonly items!: ConfigurationItem[];

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.newDepth = Math.max(...this.items.map((i) => i.Depth));
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public ok() {
    for (let item of this.items) {
      if (!this.overrideItemLock && item.IsLocked) continue;
      item.Depth = this.newDepth - item.depthReduction;
      item.IsLocked = true;
    }

    this.activeModal.close(undefined);
  }
}
