import { Injectable, OnDestroy } from '@angular/core';
import { ModelOptions } from 'app/ng/ModelOptions';
import { Translatable } from 'app/ts//clientDto/Translatable';
import * as App from 'app/ts/app';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { IObservable, ISubscription } from 'app/ts/util/Observable';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export abstract class BaseVm implements OnDestroy {
  private debugVm: any | null = null;

  constructor(protected readonly baseVmService: BaseVmService) {
    if (App.debug.showVmConstructors) {
      console.debug('App.debug.showVmConstructors: ', this);
    }
  }
  ngOnDestroy(): void {
    this.dispose();
  }

  protected readonly oldSubscriptions: ISubscription[] = [];
  protected readonly subscriptions: Subscription[] = [];

  private _disposed = false;
  protected get disposed() {
    return this._disposed;
  }

  protected subscribeToOld<T>(
    observable: IObservable<T>,
    func: (obj: T) => any,
  ) {
    if (observable == null) return;
    let sub = observable.observe(func);
    this.oldSubscriptions.push(sub);
  }

  protected ensureUnsubscribe(subscription: Subscription | undefined) {
    if (subscription) this.subscriptions.push(subscription);
  }

  protected subscribeTo<T>(
    observable: Observable<T> | undefined,
    nextValue: (value: T) => void,
  ) {
    if (!observable) return;

    const subscription = observable.subscribe({
      next(value: T) {
        nextValue(value);
      },
    });

    this.subscriptions.push(subscription);
    return subscription;
  }

  protected dispose() {
    this._disposed = true;
    for (let sub of this.oldSubscriptions) {
      sub.dispose();
    }
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  // public translate(key: null, defaultValue?: string, ...args: (string|number|null)[]): string;
  // public translate(translation: Translatable | null, defaultValue?: string, ...args: (string|number|null)[]): string;
  // public translate(key:string, defaultValue?: string, ...args: (string|number|null)[]): string;
  public translate(
    key: string | Translatable | null,
    defaultValue?: string,
    ...args: (string | number | null)[]
  ) {
    return this.baseVmService.translationService.translate(
      key,
      defaultValue,
      ...args,
    );
  }

  /**
   * Combines this.consumerBaseDir and all pathSegments into a path
   */
  public consumerFile(...pathSegments: string[]): string {
    return this.baseVmService.translationService.getConsumerFilePath(
      ...pathSegments,
    );
  }

  public debug(str?: string) {
    this.debugVm = this;
    if (str) {
      alert(str);
    } else {
      console.debug('debugVm = ', this);
    }
  }

  public range(
    fromIncl: number = 0,
    toExcl: number,
    step: number = 0,
  ): number[] {
    let result: number[] = [];

    if (fromIncl < toExcl) {
      if (step <= 0) step = 1;
      for (let i = fromIncl; i < toExcl; i += step) result.push(i);
    } else if (fromIncl > toExcl) {
      if (step >= 0) step = -1;
      for (let i = fromIncl; i > toExcl; i += step) result.push(i);
    }

    return result;
  }

  public readonly modelOptions: ModelOptions = {
    updateOn: 'change',
  };

  public readonly modelOptionsBlur: ModelOptions = {
    updateOn: 'blur',
  };
}
