import { Injectable } from '@angular/core';
import { AmbientState } from './Ambient';

@Injectable({ providedIn: 'root' })
/** This basically acts as the store for the application
 * And even though it is called FunctionalCore it is
 * to some extent mutable 🧐, so yeah I know the naming
 * is probably not the best, but for now...
 * Also note that a lot of the data that is currently
 * spread all over the application in various "services"
 * should be collected here.
 * Most likely there should also be something resembling
 * a domain model
 */
export class FunctionalCoreService {
  private _ambient: AmbientState = new AmbientState();

  public get ambient(): AmbientState {
    return this._ambient;
  }
}
