import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(
    private readonly $http: PromisingBackendService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly screenshotService: ScreenshotService,
    private readonly notificationService: NotificationService,
  ) {}

  public async getTemplates(
    section: Client.CabinetSection,
  ): Promise<Interface_DTO.Template[]> {
    let req: Interface_DTO.TemplateRequest = {
      CabinetType: section.CabinetType,
      Height: section.Height,
      Width: section.interior.cube.Width,
      Depth: section.InteriorDepth,
      ProductLineId: section.cabinet.ProductLineId,
    };
    let response = this.$http.get<Interface_DTO.Template[]>('api/template', {
      params: req,
    });

    return response;
  }

  public applyTemplate(
    section: Client.CabinetSection,
    template: Interface_DTO.Template,
    interiorDepth: number,
  ) {
    let newItems: Client.ConfigurationItem[] = [];
    section.interior.items = [];
    let topIndex = Math.max(
      ...section.configurationItems.map((ci) => ci.ConfigurationItemIndex),
      1,
    );
    let offsetY = CabinetSectionHelper.interiorOffset(
      section,
      Interface_Enums.Direction.Bottom,
    );
    section.interior.template = template;

    // Some times people create templates from af section with corpus items, even though they were told not to.
    // This compensates for that.
    let offsetX =
      template.Items.length > 0
        ? Math.min(...template.Items.map((item) => item.X))
        : 0;

    for (let dtoItem of template.Items) {
      if (dtoItem.ParentModuleItemIndex) continue;

      try {
        let clientItems = this.configurationItemService.createInteriorItem(
          dtoItem.ProductId,
          dtoItem.MaterialId,
          section,
          topIndex + 1,
        ).items;
        for (let clientItem of clientItems) {
          let dtoIVs = ObjectHelper.copy(dtoItem.VariantOptions);
          for (let dtoIV of dtoIVs) {
            clientItem.setItemVariant(dtoIV, true);
          }

          // Items are moved later, if they are not fully inside the interior cube
          clientItem.X = dtoItem.X + section.interior.cube.X - offsetX;
          clientItem.Y = dtoItem.Y + offsetY;
          clientItem.Z = dtoItem.Z;

          clientItem.trySetHeight(dtoItem.Height);
          clientItem.trySetWidth(dtoItem.Width);

          clientItem.trySetDepth(dtoItem.Depth);
          clientItem.trySetDepth(interiorDepth - clientItem.depthReduction);

          if (section.interior.material) {
            if (
              clientItem.Product &&
              clientItem.Product.materials
                .map((mat) => mat.Id)
                .indexOf(section.interior.material.Id) >= 0
            ) {
              clientItem.MaterialId = section.interior.material.Id;
            }
          }
        }
        topIndex = Math.max(
          topIndex,
          ...clientItems.map((ci) => ci.ConfigurationItemIndex),
        );
        newItems.push(...clientItems);
      } catch (e: any) {
        this.notificationService.warning(
          'template_item_generation_failed',
          'Template item could not be created. Description: {0}',
          dtoItem.Description,
        );
      }
    }
    section.interior.items = newItems;
    if (newItems.length > 0) {
      //section.interior.mustAdaptToWidth = true;
      section.interior.previousCubeWidth =
        Math.max(...newItems.map((i) => i.rightX)) - section.interior.cube.X;
      section.interior.hasEmptyGapLeft = false;
      section.interior.hasEmptyGapRight = false;
    }
  }

  public async getSaveRequest(
    section: Client.CabinetSection,
  ): Promise<Interface_DTO.TemplateSaveRequest> {
    let result = await this.getSaveRequestWithoutImage(section);
    result.ThumbnailImage = (
      await this.screenshotService.get2dImage(
        section,
        Interface_Enums.ImageSubject.InteriorWithDummyItems,
      )
    ).Image;
    return result;
  }

  public async getSaveRequestWithoutImage(
    section: Client.CabinetSection,
  ): Promise<Interface_DTO.TemplateSaveRequest> {
    let dtoItems = section.saveTo().InteriorItems;
    let depthRange = Constants.interiorDepthRanges.filter(
      (range) =>
        range.min <= section.InteriorDepth &&
        range.max >= section.InteriorDepth,
    )[0];
    if (!depthRange)
      depthRange =
        Constants.interiorDepthRanges[Constants.interiorDepthRanges.length - 1];
    let result: Interface_DTO.TemplateSaveRequest = {
      Items: dtoItems,
      MaxHeight: Math.max(section.Height, 2750),
      MinHeight: Math.min(section.Height, 2100),
      MaxWidth: section.Width + 500,
      MinWidth: section.Width - 200,
      MinDepth: depthRange.min,
      MaxDepth: depthRange.max,
      Name: section.cabinet.floorPlan.Name,
      NumberOfDoors: section.doors.numberOfDoors,
      ProductLineId: section.cabinet.ProductLineId,
      ConfigurationCategory: Interface_Enums.ConfigurationCategory.None,
      CabinetSectionId: null,
      ThumbnailImage: '', // (await this.screenshotService.get2dImage(section, Interface_Enums.ImageSubject.InteriorWithDummyItems)).Image
    };
    if (section.interior.template) {
      let t = section.interior.template;
      result.Name = t.Name;
      result.MaxHeight = t.MaxHeight;
      result.MaxWidth = t.MaxWidth;
      result.MaxDepth = t.MaxDepth;
      result.MinHeight = t.MinHeight;
      result.MinWidth = t.MinWidth;
      result.MinDepth = t.MinDepth;
      result.ConfigurationCategory = t.Category;
    }
    return result;
  }

  public async deleteTemplate(template: Interface_DTO.Template): Promise<void> {
    await this.$http.delete('api/template/' + template.Id);
  }

  public async saveAsTemplate(
    section: Client.CabinetSection,
    saveRequest: Interface_DTO.TemplateSaveRequest,
  ) {
    this.$http.post<void>('api/template', saveRequest, {
      responseType: 'void',
    });
  }
}
