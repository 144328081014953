import { Component } from '@angular/core';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'new-vm',
  templateUrl: './new.html',
})
export class NewVm extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  get value() {
    return 'Hello, world!';
  }
}
