import { Injectable } from '@angular/core';

// https://docs.angularjs.org/api/ng/service/$httpParamSerializer
@Injectable({
  providedIn: 'root',
})
export class HttpParamSerializerService {
  public serialize(object: object): string {
    console.log('IMPLEMENT: HttpParamSerializerService');
    return 'Remember to serialize!';
  }
}
