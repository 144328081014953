import * as Enums from 'app/ts/clientDto/Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
export class InteriorGap {
  public multiGapItems: {
    item: Client.ConfigurationItem;
    gable: Client.ConfigurationItem | null;
  }[] = [];

  constructor(
    public readonly leftGable: Client.ConfigurationItem | null,
    public readonly rightGable: Client.ConfigurationItem | null,
    public readonly items: Client.ConfigurationItem[],
    public position: number,
    public width: number,
  ) {}

  /**
   * Resize the InteriorGap
   * SnapLeftAndRight items are resized
   * Mirrored items (snapped right) are moved
   * @param newWidth
   */
  public resize(newWidth: number): boolean {
    if (this.isRightGableLocked) {
      return false;
    }

    let difference = this.width - newWidth;

    for (let item of this.items) {
      if (item.snapLeftAndRight) {
        item.trySetWidth(newWidth);
      } else if (item.isMirrored) {
        item.X -= difference;
      }
    }

    if (this.rightGable) {
      this.rightGable.X -= difference;
      this.rightGable.moduleChildren.forEach(
        (child) => (child.X -= difference),
      );
    }

    this.width = newWidth;

    return true;
  }

  /**
   * Move all gables and items along the X axis by the same amount
   * @param offsetX
   */
  public moveBy(offsetX: number) {
    if (this.rightGable) {
      this.rightGable.X += offsetX;
      this.rightGable.moduleChildren.forEach((child) => (child.X += offsetX));
    }

    for (let item of this.items) {
      item.X += offsetX;
    }

    this.position += offsetX;
  }

  public fitItems() {
    let firstItem = this.items[0];
    if (!firstItem) {
      let mgi = this.multiGapItems[0];
      if (mgi) firstItem = mgi.item;
    }
    if (!firstItem) {
      return;
    }

    let backZ = firstItem.cabinetSection.interior.cube.Z;
    let frontZ = backZ + firstItem.cabinetSection.InteriorDepth;

    if (!!this.leftGable && this.leftGable.drilledRight) {
      backZ = this.leftGable.Z;
      frontZ = this.leftGable.Z + this.leftGable.snapDepthRight;
    } else if (!!this.rightGable && this.rightGable.drilledLeft) {
      backZ = this.rightGable.Z;
      frontZ = this.rightGable.Z + this.rightGable.snapDepthLeft;
    }

    for (let item of this.items) {
      if (!!item.moduleParent) {
        continue;
      }
      //if (!item.snapLeft
      //    && !item.snapRight
      //    && !item.snapLeftAndRight)
      //    continue;

      // Set correct depth
      if (item.isFlexDepth && !item.IsLocked) {
        let newDepth = frontZ - backZ - item.depthReduction;
        item.trySetDepth(newDepth);
      }

      // Set correct Z position
      if (item.snapDepthMiddle) {
        item.Z = (frontZ + backZ) / 2 - item.Depth / 2;
      } else if (item.snapCoatHanger || item.snapUnder) {
        let snapTargets = this.items.filter(
          (target) =>
            target.Y > item.Y &&
            target.X <= item.X &&
            target.rightX >= item.rightX &&
            ((item.snapUnder && target.isSnapUnderTarget) ||
              (item.snapCoatHanger && target.isCoatHanger)),
        );
        var snapTarget = ObjectHelper.best(snapTargets, (t) => t.Y);
        if (snapTarget) {
          if (item.isFlexDepth && !item.IsLocked) {
            item.trySetDepth(snapTarget.Depth - item.reductionDepth);
          }
          item.Z = snapTarget.frontZ - (item.Depth + item.reductionDepth);
        } else {
          item.Z = item.Z;
        }
      } else if (item.snapLeft && !item.isMirrored && !!this.leftGable) {
        item.Z =
          backZ +
          this.leftGable.snapDepthRight -
          item.depthReduction -
          item.Depth;
      } else if (item.snapRight && item.isMirrored && !!this.rightGable) {
        item.Z =
          backZ +
          this.rightGable.snapDepthLeft -
          item.depthReduction -
          item.Depth;
      } else {
        item.Z = frontZ - item.depthReduction - item.Depth;
      }

      // Set correct width
      if (item.snapLeftAndRight && !item.IsLocked) {
        if (!item.isPullout || InteriorLogic.supportsPullout(this)) {
          item.trySetWidth(this.width);
        }
      }

      // Set correct X position
      if (item.snapUnder || item.snapCoatHanger) {
        //item.X = this.position + (this.width / 2) - (item.Width / 2);
      } else if (!item.isMirrored) {
        item.X = this.position;
      } else {
        item.X = this.position + this.width - item.Width;
      }
    }

    for (let multiGapItem of this.multiGapItems) {
      // Set correct depth
      if (multiGapItem.item.isFlexDepth && !multiGapItem.item.IsLocked) {
        let newDepth = frontZ - backZ - multiGapItem.item.depthReduction;
        multiGapItem.item.trySetDepth(newDepth);
      }

      // Set correct Z position
      let newZ = backZ;
      if (multiGapItem.item.snapDepthMiddle) {
        newZ = (frontZ + backZ) / 2 - multiGapItem.item.Depth / 2;
      } else {
        newZ =
          frontZ - multiGapItem.item.depthReduction - multiGapItem.item.Depth;
      }
      multiGapItem.item.Z = newZ;

      // Set correct width and X position

      if (
        !multiGapItem.item.snapLeft &&
        !multiGapItem.item.snapRight &&
        !multiGapItem.item.snapLeftAndRight
      )
        continue;

      if (multiGapItem.item.rightX < this.position) continue;

      let rightX = !!multiGapItem.gable
        ? multiGapItem.gable.X
        : multiGapItem.item.cabinetSection.interior.cubeRightX;

      if (multiGapItem.item.snapLeftAndRight && !multiGapItem.item.IsLocked) {
        multiGapItem.item.trySetWidth(rightX - this.position);
      }

      if (!multiGapItem.item.isMirrored) {
        multiGapItem.item.X = this.position;
      } else {
        multiGapItem.item.X = rightX - multiGapItem.item.Width;
      }
    }
  }

  public updateWidth(absoluteMinX: number, absoluteMaxX: number) {
    let leftX = this.leftGable ? this.leftGable.rightX : absoluteMinX;
    let rightX = this.rightGable ? this.rightGable.X : absoluteMaxX;

    this.width = rightX - leftX;
  }

  public get isRightGableLocked(): boolean {
    return !!this.rightGable && this.rightGable.IsLocked;
  }

  public get allItemsFit(): boolean {
    for (let item of this.items) {
      if (item.Width > this.width) {
        return false;
      }
      if (item.snapLeftAndRight) {
        if (
          Math.abs(item.Width - this.width) > Constants.sizeAndPositionTolerance
        )
          return false;
      }
    }
    return true;
  }

  public get wasResizedUp(): boolean {
    for (let item of this.items) {
      if (item.Width > this.width) {
        return false;
      }
      if (
        item.snapLeftAndRight &&
        this.width - item.Width > Constants.sizeAndPositionTolerance
      ) {
        return true;
      }
    }
    return false;
  }

  public get class(): Enums.InteriorGapClass {
    if (this.items.length < 1) {
      return Enums.InteriorGapClass.Empty;
    }
    if (this.items.some((item) => item.IsLocked)) {
      return Enums.InteriorGapClass.Fixed;
    }
    if (
      this.items.every((item) => item.isFlexWidth || !item.snapLeftAndRight)
    ) {
      return Enums.InteriorGapClass.Flex;
    }
    if (
      this.items.every(
        (item) =>
          item.isSemiFlexWidth || item.isFlexWidth || !item.snapLeftAndRight,
      )
    ) {
      return Enums.InteriorGapClass.SemiFlex;
    }

    return Enums.InteriorGapClass.Fixed;
  }

  public get minWidth(): number {
    let minGapWidth = 0;

    if (this.items.length > 0) {
      minGapWidth = Math.max(
        minGapWidth,
        Enumerable.from(this.items).max((item) => item.minWidth),
      );
    }

    return minGapWidth;
  }

  public get maxWidth(): number {
    let maxGapWidth = 9999;

    if (this.items.length < 1) return maxGapWidth;

    let snapLeftRightItems = Enumerable.from(this.items).where(
      (item) => item.snapLeftAndRight,
    );
    if (snapLeftRightItems.any()) {
      maxGapWidth = Math.min(
        maxGapWidth,
        snapLeftRightItems.min((item) => item.maxWidth),
      );
    }

    return maxGapWidth;
  }

  public get maxContraction(): number {
    return this.width - this.minWidth;
  }

  public get maxExpansion(): number {
    return this.maxWidth - this.width;
  }

  public get maxItemWidth(): number {
    if (this.items.length === 0) return 0;

    let itemsMax = Math.max(0, ...this.items.map((i) => i.Width));
    return itemsMax;
  }

  public get rightX(): number {
    return this.position + this.width;
  }

  public get actualWidth(): number {
    if (this.leftGable && this.rightGable)
      return this.rightGable.X - this.leftGable.rightX;
    return this.width;
  }
}
