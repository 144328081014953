import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CabinetService } from 'app/ts/services/CabinetService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { IConsumerPage } from 'app/ts/viewmodels/consumer/pages/IConsumerPage';
import { CabinetSectionService } from './cabinet-section.service';

@Component({
  selector: 'corpus-vm',
  templateUrl: './corpus.html',
  styleUrls: ['corpus.scss', 'common.scss', '../../../../../style/d3.scss'],
})
export class CorpusVm extends BaseVm implements IConsumerPage {
  private cache = new Client.Cache<{
    availableCorpusMaterials: Pickable<Client.Material>[];
    availableAlignments: Pickable<Interface_Enums.CabinetAlignment>[];

    canUseHeightReduction: boolean;
    useHeightReduction: boolean;
    heightReductionPrice: string;

    canDrillHoles: boolean;
    drillHoles: boolean;
    drillHolesPrice: string;
  }>();

  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    public readonly cabinetSections: CabinetSectionService,
    private readonly floorPlanService: FloorPlanService,
    private readonly cabinetService: CabinetService,
    private routing: AppRoutingModule,
  ) {
    super(baseVmService);
    this.cabinetSections.cabinetSection$
      .pipe(takeUntilDestroyed())
      .subscribe((cabinetSection) => {
        this.cabinetSection = cabinetSection;
        this.subscribeTo(this.floorPlan.floorPlan$, (fp) => this.cache.clear());
      });
  }

  public get availableCorpusMaterials(): Pickable<Client.Material>[] {
    return this.cache.get('availableCorpusMaterials', () =>
      this.cabinetSection.corpus.pickableMaterials.filter(
        (cm) => cm.disabledReason === null,
      ),
    );
  }

  public get selectedCorpusMaterial() {
    return this.cabinetSection.corpus.material || null;
  }
  public set selectedCorpusMaterial(val) {
    this.cabinetSection.corpus.material = val;
    this.setChanged();
  }

  public getPanelName(cp?: Client.CorpusPanel): string {
    if (cp) {
      return cp.DefaultName;
    } else {
      return this.translate('corpus_panel_type_top_none', 'None');
    }
  }

  public get availableAlignmens(): Pickable<Interface_Enums.CabinetAlignment>[] {
    return this.cache.get('availableAlignments', () => [
      {
        item: Interface_Enums.CabinetAlignment.FreeStanding,
        name: this.translate('consumer_corpus_align_free', 'Freestanding'),
        imageUrl: this.consumerFile('placements/none.png'),
        isSelected:
          this.alignment === Interface_Enums.CabinetAlignment.FreeStanding,
        disabledReason: null,
        override: false,
      },
      {
        item: Interface_Enums.CabinetAlignment.RightAligned,
        name: this.translate('consumer_corpus_align_right', 'Right'),
        imageUrl: this.consumerFile('placements/right.png'),
        isSelected:
          this.alignment === Interface_Enums.CabinetAlignment.RightAligned,
        disabledReason: null,
        override: false,
      },
      {
        item: Interface_Enums.CabinetAlignment.LeftAligned,
        name: this.translate('consumer_corpus_align_left', 'Left'),
        imageUrl: this.consumerFile('placements/left.png'),
        isSelected:
          this.alignment === Interface_Enums.CabinetAlignment.LeftAligned,
        disabledReason: null,
        override: false,
      },
      {
        item: Interface_Enums.CabinetAlignment.WallToWall,
        name: this.translate('consumer_corpus_align_wall', 'Wall-to-wall'),
        imageUrl: this.consumerFile('placements/both.png'),
        isSelected:
          this.alignment === Interface_Enums.CabinetAlignment.WallToWall,
        disabledReason: null,
        override: false,
      },
    ]);
  }

  public get alignment() {
    return this.cabinetSection.cabinet.Alignment;
  }
  public set alignment(val: Interface_Enums.CabinetAlignment) {
    this.cabinetService.setAlignment(this.cabinetSection.cabinet, val);
    this.setChanged();
  }

  public get unevenFloor() {
    return this.cabinetSection.cabinet.UnevenFloor || false;
  }
  public set unevenFloor(val: boolean) {
    this.cabinetService.setUnevenFloor(this.cabinetSection.cabinet, val);
    this.setChanged();
  }

  public get useRoof() {
    return this.cabinetSection.cabinet.UseRoof || false;
  }
  public set useRoof(val: boolean) {
    this.cabinetService.setUseRoof(this.cabinetSection.cabinet, val);
    this.setChanged();
  }

  public get canUnevenWallLeft() {
    return (
      this.alignment == Interface_Enums.CabinetAlignment.LeftAligned ||
      this.alignment == Interface_Enums.CabinetAlignment.WallToWall
    );
  }

  public get unevenWallLeft() {
    return this.cabinet.UnevenLeftWall || false;
  }
  public set unevenWallLeft(val: boolean) {
    let val2 = val && this.canUnevenWallLeft;
    this.cabinetService.setUnevenWallLeft(this.cabinetSection.cabinet, val2);
    this.setChanged();
  }

  public get canUnevenWallRight() {
    return (
      this.alignment == Interface_Enums.CabinetAlignment.RightAligned ||
      this.alignment == Interface_Enums.CabinetAlignment.WallToWall
    );
  }

  public get unevenWallRight() {
    return this.cabinet.UnevenRightWall || false;
  }
  public set unevenWallRight(val: boolean) {
    let val2 = val && this.canUnevenWallRight;
    this.cabinetService.setUnevenWallRight(this.cabinetSection.cabinet, val2);
    this.setChanged();
  }

  private _showAdvanced = false;
  public get showAdvanced() {
    return this._showAdvanced;
  }
  public set showAdvanced(val: boolean) {
    this._showAdvanced = val;
    if (val) {
      this.cabinet.UnevenFloor = null;
      this.cabinet.UnevenLeftWall = null;
      this.cabinet.UnevenRightWall = null;
      this.cabinet.UseRoof = null;
    } else {
      this.cabinetService.resetCorpusFeatures(this.cabinetSection.cabinet);
    }
    this.setChanged();
  }

  //region HeightReduction

  public get canUseHeightReduction(): boolean {
    return this.cache.get(
      'canUseHeightReduction',
      () =>
        this.cabinetSection.corpus.heightReductionLeftPossible ||
        this.cabinetSection.corpus.heightReductionRightPossible,
    );
  }

  public get useHeightReduction(): boolean {
    return this.cache.get(
      'useHeightReduction',
      () =>
        this.cabinetSection.HeightReductionCorpusLeft ||
        this.cabinetSection.HeightReductionCorpusRight,
    );
  }
  public set useHeightReduction(val: boolean) {
    this.cabinetSection.HeightReductionCorpusLeft =
      this.cabinetSection.corpus.heightReductionLeftPossible && val;
    this.cabinetSection.HeightReductionCorpusRight =
      this.cabinetSection.corpus.heightReductionRightPossible && val;
    this.setChanged();
  }

  //endregion

  //region drill holes

  public get canDrillHoles(): boolean {
    return this.cache.get('canDrillHoles', () =>
      this.cabinetSection.configurationItems.some((ci) =>
        ci.VariantOptions.some(
          (vo) =>
            !!vo.variant && vo.variant!.Number === VariantNumbers.Drilling,
        ),
      ),
    );
  }

  public get drillHoles(): boolean {
    return this.cache.get('drillHoles', () =>
      this.cabinetSection.configurationItems.some((ci) =>
        ci.VariantOptions.some(
          (vo) =>
            !!vo.variant &&
            vo.variant!.Number === VariantNumbers.Drilling &&
            vo.ActualValue === VariantNumbers.Values.Yes,
        ),
      ),
    );
  }
  public set drillHoles(val: boolean) {
    const voValue = val ? VariantNumbers.Values.Yes : VariantNumbers.Values.No;
    for (let ci of this.cabinetSection.configurationItems) {
      for (let vo of ci.VariantOptions) {
        if (!vo.variant) continue;
        if (vo.variant.Number !== VariantNumbers.Drilling) continue;
        vo.ActualValue = voValue;
      }
    }
    this.setChanged();
  }
  //endregion

  public async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
    this.cache.clear();
  }

  public get floorPlan() {
    return this.cabinetSection.cabinet.floorPlan;
  }
  public get cabinet() {
    return this.cabinetSection.cabinet;
  }

  public next() {
    this.routing.Consumer_doors();
  }
  public previous() {
    this.routing.Consumer_dimensions();
  }
}
