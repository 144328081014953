import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PhoneInputModule } from './phone-input.module';
import { FloorplanOverviewBoxVm } from './FloorplanOverviewBoxVm';

//Used in floorPlanOverviewBox.html

@NgModule({
  declarations: [FloorplanOverviewBoxVm],
  exports: [FloorplanOverviewBoxVm],
  providers: [],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneInputModule,
  ],
})
export class FloorplanOverviewBoxModule {}
