import { ProductLineProperties } from './Interface_DTO_ProductLineProperties';
import { ProductLineId } from './Interface_Enums';

export const defaultProductLineProperties: ProductLineProperties = {
  SwingFlexMinimumSubsectionInsideWidth: 284,
  SwingFlexMaximumSubsectionInsideWidth: 1168,
  SwingFlexDefaultMaximumSubsectionInsideWidth: 586,
  SwingFlexBottomOffset: 15,
  SwingFlexSpaceForBacking: 25,
  SwingFlexBackingGableOverlap: 7,
  SwingFlexShelfFrontOffset: 1,
  SwingFlexGableLeftProductGroupingNumber: '51010',
  SwingFlexGableRightProductGroupingNumber: '51110',
  SwingFlexGableCenterProductGroupingNumber: '51210',
  SwingFlexMiddlePanelProductGroupingNumber: '51310',
  SwingFlexTopShelfProductGroupingNumber: '5201',
  SwingFlexBottomShelfProductGroupingNumber: '5211',
  SwingFlexPlinthFrontOffset: 22,
  SwingFlexTopShelfOverlap: 18,
  SwingFlexBottomShelfOverlap: 16,
  SwingFlexMovableShelfTopOverlap: 9,
  SwingFlexMovableShelfBottomOverlap: 9,
  SwingFlexDoorSideOverlap: 9,
  SwingFlexDoorGap: 4,
  SwingFlexDoorGripSpace: 35,
  SwingFlexDoorGripDefaultPosition: 1000,
  SwingFlexDoorTopHingeDistanceFromTop: 77,
  SwingFlexDoorTopHingeMaxPosition: 2507,
  SwingFlexDoorHingePositions: [
    { MinDoorHeight: 600, HingePosition: 75 },
    { MinDoorHeight: 759, HingePosition: 523 },
    { MinDoorHeight: 1623, HingePosition: 1419 },
    { MinDoorHeight: 2167, HingePosition: 1963 },
  ],
  SwingFlexFittingPanelSpacerWidth: 78,
  SwingFlexFittingPanelSpacerDepth: 19,
  SwingFlexFittingPanelSpacerOffset: 89.5,
  SwingFlexFittingPanelGableDrillingStartOffset: 43,
  SwingFlexPushToOpenDistance: 700,
  SwingFlexHingeSize: { X: 70, Y: 45, Z: 70 },
  SwingFlexSpaceBehindDoors: 3,
  SwingFlexMiddlePanelHeightAboveShelf: 1,
  SwingFlexSwithcDrillingPatternBelowInteriorDepth: 471,
  WidthVariantOption: '22',
};

export const productLinePropertiesByProductLineId: {
  [id: number]: ProductLineProperties | undefined;
} = {
  [ProductLineId.SwingFlex22]: {
    SwingFlexMinimumSubsectionInsideWidth: 284,
    SwingFlexMaximumSubsectionInsideWidth: 1168,
    SwingFlexDefaultMaximumSubsectionInsideWidth: 586,
    SwingFlexBottomOffset: 15,
    SwingFlexSpaceForBacking: 25,
    SwingFlexBackingGableOverlap: 7,
    SwingFlexShelfFrontOffset: 1,
    SwingFlexGableLeftProductGroupingNumber: '51010',
    SwingFlexGableRightProductGroupingNumber: '51110',
    SwingFlexGableCenterProductGroupingNumber: '51210',
    SwingFlexMiddlePanelProductGroupingNumber: '51310',
    SwingFlexTopShelfProductGroupingNumber: '5201',
    SwingFlexBottomShelfProductGroupingNumber: '5211',
    SwingFlexPlinthFrontOffset: 22,
    SwingFlexTopShelfOverlap: 18,
    SwingFlexBottomShelfOverlap: 16,
    SwingFlexMovableShelfTopOverlap: 9,
    SwingFlexMovableShelfBottomOverlap: 9,
    SwingFlexDoorSideOverlap: 9,
    SwingFlexDoorGap: 4,
    SwingFlexDoorGripSpace: 35,
    SwingFlexDoorGripDefaultPosition: 1000,
    SwingFlexDoorTopHingeDistanceFromTop: 77,
    SwingFlexDoorTopHingeMaxPosition: 2507,
    SwingFlexDoorHingePositions: [
      { MinDoorHeight: 600, HingePosition: 75 },
      { MinDoorHeight: 759, HingePosition: 523 },
      { MinDoorHeight: 1623, HingePosition: 1419 },
      { MinDoorHeight: 2167, HingePosition: 1963 },
    ],
    SwingFlexFittingPanelSpacerWidth: 78,
    SwingFlexFittingPanelSpacerDepth: 19,
    SwingFlexFittingPanelSpacerOffset: 89.5,
    SwingFlexFittingPanelGableDrillingStartOffset: 43,
    SwingFlexPushToOpenDistance: 700,
    SwingFlexHingeSize: { X: 70, Y: 45, Z: 70 },
    SwingFlexSpaceBehindDoors: 3,
    SwingFlexMiddlePanelHeightAboveShelf: 1,
    SwingFlexSwithcDrillingPatternBelowInteriorDepth: 471,
    WidthVariantOption: '22',
  },

  [ProductLineId.SwingFlex19]: {
    SwingFlexMinimumSubsectionInsideWidth: 270,
    SwingFlexMaximumSubsectionInsideWidth: 1168,
    SwingFlexDefaultMaximumSubsectionInsideWidth: 586,
    SwingFlexBottomOffset: 15,
    SwingFlexSpaceForBacking: 25,
    SwingFlexBackingGableOverlap: 5.5,
    SwingFlexShelfFrontOffset: 1,
    SwingFlexGableLeftProductGroupingNumber: '31010',
    SwingFlexGableRightProductGroupingNumber: '31110',
    SwingFlexGableCenterProductGroupingNumber: '31210',
    SwingFlexMiddlePanelProductGroupingNumber: '31310',
    SwingFlexTopShelfProductGroupingNumber: '32011',
    SwingFlexBottomShelfProductGroupingNumber: '32111',
    SwingFlexPlinthFrontOffset: 22,
    SwingFlexTopShelfOverlap: 15,
    SwingFlexBottomShelfOverlap: 13,
    SwingFlexMovableShelfTopOverlap: 6,
    SwingFlexMovableShelfBottomOverlap: 9,
    SwingFlexDoorSideOverlap: 7.5,
    SwingFlexDoorGap: 4,
    SwingFlexDoorGripSpace: 35,
    SwingFlexDoorGripDefaultPosition: 1000,
    SwingFlexDoorTopHingeDistanceFromTop: 77,
    SwingFlexDoorTopHingeMaxPosition: 2507,
    SwingFlexDoorHingePositions: [
      { MinDoorHeight: 600, HingePosition: 75 },
      { MinDoorHeight: 759, HingePosition: 523 },
      { MinDoorHeight: 1623, HingePosition: 1419 },
      { MinDoorHeight: 2167, HingePosition: 1963 },
    ],
    SwingFlexFittingPanelSpacerWidth: 78,
    SwingFlexFittingPanelSpacerDepth: 19,
    SwingFlexFittingPanelSpacerOffset: 89.5,
    SwingFlexFittingPanelGableDrillingStartOffset: 43,
    SwingFlexPushToOpenDistance: 700,
    SwingFlexHingeSize: { X: 70, Y: 45, Z: 70 },
    SwingFlexSpaceBehindDoors: 3,
    SwingFlexMiddlePanelHeightAboveShelf: 1,
    SwingFlexSwithcDrillingPatternBelowInteriorDepth: 471,
    WidthVariantOption: '19',
  },
};
