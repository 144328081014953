import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { activeUserProvider } from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { useFullCatalogProvider } from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import { userDataProvider } from 'app/functional-core/ambient/userdata/UserData';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { first, map, tap } from 'rxjs';

@Component({
  selector: 'customer-edit-menu',
  templateUrl: 'customer-edit-menu.component.html',
  providers: [
    clientSettingProvider,
    useFullCatalogProvider,
    userDataProvider,
    activeUserProvider,
    DeliveryAddressService,
  ],
  styleUrls: ['../../../../style/rightMenu.scss'],
})
export class CustomerEditMenuComponent extends BaseVm {
  public showShowChildren: boolean = false;

  private _shouldUpdateDeliveryAddress: boolean = true;
  private _customer: DTO.Customer | undefined = undefined;
  private _matchingDeliveryAddresss: Interface_DTO.DeliveryAddress | undefined =
    undefined;
  private searchParams: CustomerSearchParams;

  constructor(
    baseVmService: BaseVmService,
    private readonly customerService: CustomerService,
    private readonly notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private readonly deliveryAddressService: DeliveryAddressService,
    private routing: AppRoutingModule,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
  ) {
    super(baseVmService);
    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.showShowChildren = settings.showExtraOverviewTabs;
      }),
    );
    this.activatedRoute.paramMap
      .pipe(
        first(),
        map((params) => CustomerSearchParams.FromParams(params)),
        tap((params) => {
          this.searchParams = params;
        }),
      )
      .subscribe();
    const snapShot = this.activatedRoute.snapshot.queryParamMap;
    this.searchParams = CustomerSearchParams.FromParams(snapShot);
  }

  @Input()
  public get customer() {
    return this._customer;
  }
  public set customer(val: DTO.Customer | undefined) {
    this._customer = val;
    this._matchingDeliveryAddresss =
      val && this.getMatchingDeliveryAddress(val);
  }

  @Output()
  public onClose = new EventEmitter<void>();

  public get shouldUpdateDeliveryAddress(): boolean {
    return (
      this._shouldUpdateDeliveryAddress && !!this._matchingDeliveryAddresss
    );
  }
  public set shouldUpdateDeliveryAddress(val: boolean) {
    this._shouldUpdateDeliveryAddress = val;
  }
  public get canUpdateDeliveryAddress(): boolean {
    return !!this._matchingDeliveryAddresss;
  }

  private getMatchingDeliveryAddress(
    customer: DTO.Customer,
  ): Interface_DTO.DeliveryAddress | undefined {
    for (let proj of customer.Projects) {
      for (let da of proj.DeliveryAddresses) {
        if (CustomerEditMenuComponent.match(customer, da)) return da;
      }
    }
    return;
  }

  private static readonly matchPropertyNames: (keyof DTO.Customer &
    keyof Interface_DTO.DeliveryAddress)[] = [
    'Name',
    'Address1',
    'Address2',
    'PostalCode',
    'City',
    'Country',
    'Floor',
    'Phone',
    'Email',
  ];
  private static match(
    customer: DTO.Customer,
    deliveryAddress: Interface_DTO.DeliveryAddress,
  ): boolean {
    for (let prop of CustomerEditMenuComponent.matchPropertyNames) {
      if (customer[prop] !== deliveryAddress[prop]) return false;
    }
    return true;
  }

  public async save() {
    if (!this.customer) {
      return;
    }
    let success = false;
    try {
      let updatePromise = this.customerService.updateCustomer(this.customer);
      this.notificationService.loadPromise(updatePromise);
      await updatePromise;
      success = true;
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
    if (success) {
      this.notificationService.success(
        'customers_update_complete',
        'Customer saved',
      );

      let deliveryAddressChanged = await this.updateDeliveryAddress();

      this.close();
      if (!deliveryAddressChanged) this.customerService.updateCustomerList();
    }
  }

  private async updateDeliveryAddress(): Promise<boolean> {
    if (!this.shouldUpdateDeliveryAddress) return false;
    if (!this._matchingDeliveryAddresss) return false;
    if (!this.customer) return false;

    try {
      let newAddress: Interface_DTO.DeliveryAddress = {
        ...this._matchingDeliveryAddresss,
      };
      for (let prop of CustomerEditMenuComponent.matchPropertyNames) {
        (<any>newAddress[prop]) = this.customer[prop];
      }
      let updatePromise =
        this.deliveryAddressService.updateDeliveryAddress(newAddress);
      this.notificationService.loadPromise(updatePromise);
      await updatePromise;
      this.notificationService.success(
        'deliveryaddresses_update_complete',
        'DeliveryAddress saved',
      );
      return true;
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
    return false;
  }

  public async delete() {
    if (!this.customer) {
      return;
    }
    let sure = confirm(
      this.translate(
        'customer_confirm_delete',
        'Are you sure you want to delete this customer?',
      ),
    );
    if (!sure) return;
    try {
      let deleteTask = this.customerService.delete([this.customer]);
      this.notificationService.loadPromise(deleteTask);
      await deleteTask;
      this.close();
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          this.notificationService.userError(
            'customer_delete_fail_active_order',
            'Customer cannot be deleted because there are active orders',
          );
          return;
        }
      }
      this.notificationService.exception(
        'customer_delete_unknown_error',
        e,
        'Unknown error',
        { customerId: this.customer.Id },
      );
    }
  }

  public close() {
    this.onClose.emit();
  }

  public showChildren() {
    if (!this.customer) return;

    this.routing.navigate.projects({
      ...this.searchParams,
      customerId: this.customer.Id.toString(),
    });
  }
}
