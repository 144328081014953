import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'add-fitting-vm',
  templateUrl: './addFittingDialog.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class addFittingVm extends BaseVm implements OnInit {
  public ready: boolean = true;
  private floorPlanId: number | null = null;

  public valueType: string = '5';
  public description: string = '';
  public price: number | undefined = 0.0;
  public position: number | null = null;
  public showDelete: boolean = false;
  public action: number = 0; // 0 = create 1 =update 2 = delete

  public showPercentOption!: Boolean;
  public piecelistItem!: Client.ConfigurationItem | undefined;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    if (this.piecelistItem != undefined) {
      this.action = 1;
      if (this.piecelistItem.ItemType == Interface_Enums.ItemType.Custom) {
        this.valueType = '5';
      } else {
        this.showPercentOption = true;
        this.valueType = '8';
      }

      this.price = this.piecelistItem.ActualHeight;
      this.description = this.piecelistItem.Description;
      this.position = this.piecelistItem.PositionNumber;
      this.showDelete = true;
    }
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public get okPossible(): boolean {
    return !(!this.description || !this.price);
  }

  public ok() {
    if (!this.okPossible) return;

    let i: Interface_DTO.FittingItem = {
      Value: '' + this.price,
      ItemType: parseInt(this.valueType),
      Description: this.description,
      action: this.action,
      Position: this.position,
    };
    this.activeModal.close(i);
  }

  public delete() {
    if (!this.okPossible) return;

    let i: Interface_DTO.FittingItem = {
      Value: '' + this.price,
      ItemType: parseInt(this.valueType),
      Description: this.description,
      action: 2,
      Position: this.position,
    };
    this.activeModal.close(i);
  }
}
