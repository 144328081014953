import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FloorPlan } from 'app/ts/clientDto';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { Section } from '../floor-plan';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { PartitionPlanQueryService } from '../partition-plan-query.service';
import { PartitionPlanGeometryQueryService } from '../partition-plan-geometry-query.service';
import { PartitionDragType, Side } from 'app/ts/clientDto/Enums';
import { PartitionPlanCommandService } from '../partition-plan-command.service';
import { Module } from '../module';
import { CornerProfile, ProfileType, TProfile } from '../profile';
import { AssetService } from 'app/ts/services/AssetService';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as Partition from 'app/partition/floor-plan';
import { Line } from 'app/measurement/measurement-length-calculations';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import {
  PartitionSnapService,
  PartitionSnapType,
  SnapContext,
  SnappedSide,
} from '../partition-snap.service';
import { Rail } from '../rail';
import { SectionId } from '../section';

@Component({
  selector: 'section-properties',
  templateUrl: 'section-properties.component.html',
  styleUrls: ['../../../style/editor.scss', 'property-sheet.scss'],
})
export class SectionPropertiesComponent implements OnInit {
  @Input()
  public floorPlan!: FloorPlan;

  @Input()
  public partitions!: Partition.Plan;

  @Input()
  public section!: Readonly<Section>;

  @Output()
  public widthChange = new EventEmitter<{ width: number }>();

  @Input({ required: true })
  public get unevenLeftWall(): boolean {
    return this._unevenLeftWall;
  }
  public set unevenLeftWall(value: boolean) {
    this._unevenLeftWall = value;
  }
  private _unevenLeftWall!: boolean;

  @Output()
  public unevenLeftWallChange = new EventEmitter<boolean>();

  public get isUnevenLeftWallAllowed(): boolean {
    let leftModule = this.partitionQueryService.getHeadModuleForSection(
      this.section.id,
    );
    return (
      !leftModule.isDoor &&
      this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
        this.section.id,
      ) < 1
    );
  }

  public get isUnevenRightWallAllowed(): boolean {
    let rightModule = this.partitionQueryService.getTailModuleForSection(
      this.section.id,
    );
    return (
      !rightModule.isDoor &&
      this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
        this.section.id,
      ) < 1
    );
  }

  @Input({ required: true })
  public get unevenRightWall(): boolean {
    return this._unevenRightWall;
  }
  public set unevenRightWall(value: boolean) {
    this._unevenRightWall = value;
  }
  private _unevenRightWall!: boolean;

  @Output()
  public unevenRightWallChange = new EventEmitter<boolean>();

  @Input({ required: true })
  public numberOfModules!: number;

  public set numberOfModulesOut(value: number) {
    if (
      value <
      this.partitionQueryService.getSectionMinNumberOfModules(this.section.id)
    ) {
      this._numberOfModules =
        this.partitionQueryService.getSectionMinNumberOfModules(
          this.section.id,
        );
    } else if (
      value >
      this.partitionQueryService.getSectionMaxNumberOfModules(this.section.id)
    ) {
      this._numberOfModules =
        this.partitionQueryService.getSectionMaxNumberOfModules(
          this.section.id,
        );
    } else {
      this._numberOfModules = value;
    }

    this.numberOfModulesChange.emit(this._numberOfModules);
  }
  private _numberOfModules!: number;

  @Output()
  public numberOfModulesChange = new EventEmitter<number>();

  @Output()
  public deleteSection = new EventEmitter<void>();

  @Output()
  public rotateSectionLeft = new EventEmitter<void>();

  @Output()
  public rotateSectionRight = new EventEmitter<void>();

  public get isReverseAllowed(): boolean {
    return (
      !this.partitionQueryService.hasJointProfile(this.section.id, Side.Left) &&
      !this.partitionQueryService.hasJointProfile(this.section.id, Side.Right)
    );
  }

  @Output()
  public reverseSection = new EventEmitter<void>();

  public sectionHeader: string;
  constructor(
    private translateService: TranslateService,
    public partitionCommands: PartitionPlanCommandService,
    public partitionQueryService: PartitionPlanQueryService,
    private partitionGeometryQuery: PartitionPlanGeometryQueryService,
    private partitionSnapService: PartitionSnapService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.sectionHeader = translateService.translate(
      'floorPlan_headers_section',
      'Section',
    );
  }

  ngOnInit(): void {
    this.translateService.translationsUpdated$.subscribe(() => {
      this.sectionHeader = this.translateService.translate(
        'floorPlan_headers_section',
        'Section',
      );
      this.changeDetector.detectChanges();
    });
  }

  public requestDelete() {
    let sure = confirm(
      this.translateService.translate(
        'floorPlan_confirm_delete_question',
        'Are you sure you want to delete this?',
      ),
    );
    if (!sure) return;

    this.deleteSection.emit();
  }

  // Get positions
  public getDistanceFromLeftWall() {
    return this.partitionGeometryQuery.getDistanceFromLeftWallToLeftSide(
      this.section.id,
    );
  }

  public getDistanceFromRightWall() {
    return this.partitionGeometryQuery.getDistanceFromRightWallToRightSide(
      this.section.id,
    );
  }

  public getDistanceFromTopWall() {
    return this.partitionGeometryQuery.getDistanceFromTopWallToTopSide(
      this.section.id,
    );
  }

  public getDistanceFromBottomWall() {
    return this.partitionGeometryQuery.getDistanceFromBottomWallToBottomSide(
      this.section.id,
    );
  }

  @Output()
  public distanceToLeftWallChange = new EventEmitter<number>();
  @Output()
  public distanceToRightWallChange = new EventEmitter<number>();
  @Output()
  public distanceToTopWallChange = new EventEmitter<number>();
  @Output()
  public distanceToBottomWallChange = new EventEmitter<number>();

  public SnapToBothWalls() {
    if (
      this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
        this.section.id,
      ) < 1
    ) {
      var width =
        this.section.width +
        this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
          this.section.id,
        );
      this.widthChange.emit({ width });
    } else if (
      this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
        this.section.id,
      ) < 1
    ) {
      var width =
        this.section.width +
        this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
          this.section.id,
        );
      this.widthChange.emit({ width: width });
    }
  }

  public SnapPartitionTwice() {
    let lines = [] as Line[];
    let line: Line;
    let sectionToSnapTo: Section | undefined;
    let measurementLine = this.getMeasurementLine(this.section, this.floorPlan);

    let sectionsWithLines = this.partitions.sections.map((section) => {
      lines = lines.concat(this.getPartitionSectionLines(section));

      return { section, lines };
    });

    let sectionsWithPoints = sectionsWithLines.flatMap((section) => {
      let intersectionPoints: Vec2d[] = section.lines
        .map((line) =>
          GeometryHelper.lineIntersectionPoint(measurementLine, line),
        )
        .filter((point) => point != null) as Vec2d[];

      let measurementOrigin: Vec2d = {
        X: this.section.posX,
        Y: this.section.posY,
      };
      intersectionPoints.sort((a: Vec2d, b: Vec2d) => {
        let aDist = VectorHelper.dist(measurementOrigin, a);
        let bDist = VectorHelper.dist(measurementOrigin, b);

        return aDist - bDist;
      });

      line = this.findClosestPointOnEachSide(this.section, intersectionPoints);

      return intersectionPoints.map((point) => {
        return { section: section.section, intersectionPoint: point };
      });
    });

    sectionToSnapTo = sectionsWithPoints.find(
      (swp) => swp.intersectionPoint.X == line.start?.X,
    )?.section;
    if (!sectionToSnapTo)
      sectionToSnapTo = sectionsWithPoints.find(
        (swp) => swp.intersectionPoint.X == line.end?.X,
      )?.section;
    let pointToSnapTo = sectionsWithPoints.find(
      (swp) => swp.section == sectionToSnapTo,
    )?.intersectionPoint;
    let context = {
      snapType: PartitionSnapType.TPiece,
      snapPosition: { X: pointToSnapTo?.X, Y: pointToSnapTo?.Y },
      snappedSectionId: this.section.id,
    } as SnapContext;

    if (
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Right)
    ) {
      if (sectionToSnapTo && pointToSnapTo) {
        context.section1 = { id: sectionToSnapTo.id, snapSide: Side.Right };
        context.section2 = { id: this.section.id, snapSide: Side.Left };
        if (this.section.isHorizontal) {
          if (sectionToSnapTo.rotation == 90) {
            if (this.section.rotation == 0) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (this.section.posX - pointToSnapTo.X) +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2 +
                  1,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset = pointToSnapTo.Y - sectionToSnapTo.posY;
            } else if (this.section.rotation == 180) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (sectionToSnapTo.posX - this.section.posX) +
                  Rail.railOverhang,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset =
                pointToSnapTo.Y - sectionToSnapTo.posY - Module.fullJointWidth;
            }
          } else if (sectionToSnapTo.rotation == 270) {
            if (this.section.rotation == 0) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (this.section.posX - pointToSnapTo.X) +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2 +
                  1,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset =
                sectionToSnapTo.posY - pointToSnapTo.Y - Module.fullJointWidth;
            } else if (this.section.rotation == 180) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (sectionToSnapTo.posX - this.section.posX) +
                  Module.fullJointWidth +
                  Rail.railOverhang,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset = sectionToSnapTo.posY - pointToSnapTo.Y;
            }
          }
        } else if (this.section.isVertical) {
          if (sectionToSnapTo.rotation == 0) {
            if (this.section.rotation == 90) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (this.section.posY - pointToSnapTo.Y) +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset =
                pointToSnapTo.X - sectionToSnapTo.posX - Module.fullJointWidth;
            } else if (this.section.rotation == 270) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (sectionToSnapTo.posY - this.section.posY) +
                  Module.fullJointWidth +
                  Rail.railOverhang,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset = pointToSnapTo.X - sectionToSnapTo.posX;
            }
          } else if (sectionToSnapTo.rotation == 180) {
            if (this.section.rotation == 90) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (this.section.posY - sectionToSnapTo.posY) +
                  Module.fullJointWidth +
                  Rail.railOverhang,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset = sectionToSnapTo.posX - pointToSnapTo.X;
            } else if (this.section.rotation == 270) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.width +
                  (sectionToSnapTo.posY - this.section.posY) +
                  Rail.railOverhang,
                Side.Right,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset =
                sectionToSnapTo.posX - pointToSnapTo.X - Module.fullJointWidth;
            }
          }
        }
      }
    } else if (
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Left)
    ) {
      if (sectionToSnapTo && pointToSnapTo) {
        context.section1 = { id: sectionToSnapTo.id, snapSide: Side.Right };
        context.section2 = { id: this.section.id, snapSide: Side.Right };
        if (this.section.isHorizontal) {
          if (sectionToSnapTo.rotation == 90) {
            if (this.section.rotation == 0) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                pointToSnapTo.X -
                  this.section.posX +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset = pointToSnapTo.Y - sectionToSnapTo.posY;
            } else if (this.section.rotation == 180) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.posX -
                  pointToSnapTo.X +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2 +
                  1,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset =
                pointToSnapTo.Y - sectionToSnapTo.posY - Module.fullJointWidth;
            }
          }
          if (sectionToSnapTo.rotation == 270) {
            if (this.section.rotation == 0) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                pointToSnapTo.X -
                  this.section.posX +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset =
                sectionToSnapTo.posY - pointToSnapTo.Y - Module.fullJointWidth;
            } else if (this.section.rotation == 180) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.posX -
                  pointToSnapTo.X +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2 +
                  1,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset = sectionToSnapTo.posY - pointToSnapTo.Y;
            }
          }
        } else if (this.section.isVertical) {
          if (sectionToSnapTo.rotation == 0) {
            if (this.section.rotation == 90) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                pointToSnapTo.Y -
                  this.section.posY +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset =
                pointToSnapTo.X - sectionToSnapTo.posX - Module.fullJointWidth;
            } else if (this.section.rotation == 270) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.posY -
                  pointToSnapTo.Y +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset = pointToSnapTo.X - sectionToSnapTo.posX;
            }
          } else if (sectionToSnapTo.rotation == 180) {
            if (this.section.rotation == 90) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                pointToSnapTo.Y -
                  this.section.posY +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Bottom;
              context.offset = sectionToSnapTo.posX - pointToSnapTo.X;
            } else if (this.section.rotation == 270) {
              this.partitionCommands.setSectionWidth(
                this.section.id,
                this.section.posY -
                  pointToSnapTo.Y +
                  Module.fullJointWidth +
                  Rail.railOverhang * 2,
                Side.Left,
              );
              context.snappedToSide = SnappedSide.Top;
              context.offset =
                sectionToSnapTo.posX - pointToSnapTo.X - Module.fullJointWidth;
            }
          }
        }
      }
    }

    let allTargetedSections =
      this.partitionQueryService.getAllSectionsConnectedToSection(
        this.section.id,
      );
    this.partitionSnapService.executeSnap(context);

    if (sectionToSnapTo)
      this.copyPartitionPropertiesToAllSections(
        sectionToSnapTo.id,
        allTargetedSections,
      );
  }

  private copyPartitionPropertiesToAllSections(
    source: SectionId,
    destinations: SectionId[],
  ) {
    for (let dest of destinations) {
      this.partitionCommands.copyPartitionProperties(source, dest);
    }
  }

  public round(val: number): number {
    return Math.round(val);
  }

  public get isLeftRotateDisabled(): boolean {
    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    );
    let rightProfile = this.partitionQueryService.getSectionRightProfile(
      this.section.id,
    );

    if (
      (leftProfile &&
        this.partitionQueryService.isJointProfile(leftProfile.id)) ||
      (rightProfile &&
        this.partitionQueryService.isJointProfile(rightProfile.id))
    )
      return true;

    let distance = this.partitionGeometryQuery.getDistanceFromRelativeTopSide(
      this.section.id,
    );
    if (
      distance < this.partitionQueryService.getSectionMinWidth(this.section.id)
    )
      return true;

    return false;
  }

  public get isRightRotateDisabled(): boolean {
    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    );
    let rightProfile = this.partitionQueryService.getSectionRightProfile(
      this.section.id,
    );

    if (
      (leftProfile &&
        this.partitionQueryService.isJointProfile(leftProfile.id)) ||
      (rightProfile &&
        this.partitionQueryService.isJointProfile(rightProfile.id))
    )
      return true;

    let distance =
      this.partitionGeometryQuery.getDistanceFromRelativeBottomSide(
        this.section.id,
      );
    if (
      distance < this.partitionQueryService.getSectionMinWidth(this.section.id)
    )
      return true;

    return false;
  }

  public get allowSnapToBothWalls(): boolean {
    if (
      this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
        this.section.id,
      ) < 1 && // can be greater than zero, because module width, not always align with whole numbers
      this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
        this.section.id,
      ) < 1
    )
      return false;

    // Snapped to one side and no joint
    return (
      (this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
        this.section.id,
      ) < 1 &&
        !this.partitionQueryService.hasJointProfile(
          this.section.id,
          Side.Right,
        )) ||
      // Snapped to other side and no joint
      (this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
        this.section.id,
      ) < 1 &&
        !this.partitionQueryService.hasJointProfile(this.section.id, Side.Left))
    );
  }

  public get allowSnapPartitionTwice(): boolean {
    let isAllowed = false;
    let lines = [] as Line[];
    let line: Line;
    let intersectionPoints = [] as Vec2d[];
    let sectionToSnapTo: Section | undefined;
    let measurementLine = this.getMeasurementLine(this.section, this.floorPlan);

    let sectionsWithLines = this.partitions.sections.map((section) => {
      lines = lines.concat(this.getPartitionSectionLines(section));

      return { section, lines };
    });

    let sectionsWithPoints = sectionsWithLines.flatMap((section) => {
      intersectionPoints = section.lines
        .map((line) =>
          GeometryHelper.lineIntersectionPoint(measurementLine, line),
        )
        .filter((point) => point != null) as Vec2d[];

      let measurementOrigin: Vec2d = {
        X: this.section.posX,
        Y: this.section.posY,
      };
      intersectionPoints.sort((a: Vec2d, b: Vec2d) => {
        let aDist = VectorHelper.dist(measurementOrigin, a);
        let bDist = VectorHelper.dist(measurementOrigin, b);

        return aDist - bDist;
      });

      line = this.findClosestPointOnEachSide(this.section, intersectionPoints);

      return intersectionPoints.map((point) => {
        return { section: section.section, intersectionPoint: point };
      });
    });

    sectionToSnapTo = sectionsWithPoints.find(
      (swp) => swp.intersectionPoint.X == line.start?.X,
    )?.section;
    if (!sectionToSnapTo)
      sectionToSnapTo = sectionsWithPoints.find(
        (swp) => swp.intersectionPoint.X == line.end?.X,
      )?.section;
    let pointToSnapTo = sectionsWithPoints.find(
      (swp) => swp.section == sectionToSnapTo,
    )?.intersectionPoint;

    if (
      this.partitionGeometryQuery.getDistanceFromRelativeLeftSide(
        this.section.id,
      ) < 1 ||
      this.partitionGeometryQuery.getDistanceFromRelativeRightSide(
        this.section.id,
      ) < 1
    )
      return false;

    if (
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Right) &&
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Left)
    )
      return false;

    if (sectionToSnapTo) {
      let minXPos: number;
      let maxXPos: number;
      let minYPos: number;
      let maxYPos: number;
      switch (sectionToSnapTo.rotation) {
        case 0:
        case 180: {
          minXPos =
            sectionToSnapTo.rotation == 180
              ? sectionToSnapTo.posX - sectionToSnapTo.width
              : sectionToSnapTo.posX;
          maxXPos =
            sectionToSnapTo.rotation == 180
              ? sectionToSnapTo.posX
              : sectionToSnapTo.posX + sectionToSnapTo.width;
          let addAmount =
            this.section.rotation == 90
              ? this.partitionGeometryQuery.getSectionDepth(this.section.id)
              : 0;
          let reduceAmount =
            this.section.rotation == 90
              ? 0
              : this.partitionGeometryQuery.getSectionDepth(this.section.id);
          minXPos =
            minXPos +
            this.partitionQueryService.getSectionMinWidth(sectionToSnapTo.id) +
            addAmount;
          maxXPos =
            maxXPos -
            this.partitionQueryService.getSectionMinWidth(sectionToSnapTo.id) -
            reduceAmount;
          break;
        }
        case 90:
        case 270: {
          minYPos =
            sectionToSnapTo.rotation == 270
              ? sectionToSnapTo.posY - sectionToSnapTo.width
              : sectionToSnapTo.posY;
          maxYPos =
            sectionToSnapTo.rotation == 270
              ? sectionToSnapTo.posY
              : sectionToSnapTo.posY + sectionToSnapTo.width;
          let addAmount =
            this.section.rotation == 180
              ? this.partitionGeometryQuery.getSectionDepth(this.section.id)
              : 0;
          let reduceAmount =
            this.section.rotation == 180
              ? 0
              : this.partitionGeometryQuery.getSectionDepth(this.section.id);
          minYPos =
            minYPos +
            this.partitionQueryService.getSectionMinWidth(sectionToSnapTo.id) +
            addAmount;
          maxYPos =
            maxYPos -
            this.partitionQueryService.getSectionMinWidth(sectionToSnapTo.id) -
            reduceAmount;
          break;
        }
      }

      intersectionPoints.forEach((point) => {
        if (
          this.partitionQueryService.hasJointProfile(
            this.section.id,
            Side.Right,
          )
        ) {
          if (point.X == line.start?.X && this.section.isHorizontal) {
            isAllowed = true;
            if (
              line.start.X <= 0 ||
              (line.end && line.end.X >= this.floorPlan.Size.X)
            )
              isAllowed = false;
            if (pointToSnapTo!.Y < minYPos || pointToSnapTo!.Y > maxYPos)
              isAllowed = false;
          } else if (point.Y == line.start?.Y && !this.section.isHorizontal) {
            isAllowed = true;
            if (
              line.start.Y <= 0 ||
              (line.end && line.end.X >= this.floorPlan.Size.Y)
            )
              isAllowed = false;
            if (pointToSnapTo!.X < minXPos || pointToSnapTo!.X > maxXPos)
              isAllowed = false;
          }
        } else if (
          this.partitionQueryService.hasJointProfile(this.section.id, Side.Left)
        ) {
          if (point.X == line.end?.X && this.section.isHorizontal) {
            isAllowed = true;
            if (
              (line.start && line.start.X <= 0) ||
              line.end?.X >= this.floorPlan.Size.X
            )
              isAllowed = false;
            if (pointToSnapTo!.Y < minYPos || pointToSnapTo!.Y > maxYPos)
              isAllowed = false;
          } else if (point.Y == line.end?.Y && !this.section.isHorizontal) {
            isAllowed = true;
            if (
              (line.start && line.start.Y <= 0) ||
              line.end.Y >= this.floorPlan.Size.Y
            )
              isAllowed = false;
            if (pointToSnapTo!.X < minXPos || pointToSnapTo!.X > maxXPos)
              isAllowed = false;
          }
        }
      });
    }
    return isAllowed;
  }

  public getPartitionSectionLines(section: Section): Line[] {
    let [topLeft, topRight, bottomRight, bottomLeft] =
      this.getPartitionSectionPoints(section);

    return [
      { start: topLeft, end: topRight },
      { start: bottomLeft, end: bottomRight },
      { start: topLeft, end: bottomLeft },
      { start: topRight, end: bottomRight },
    ];
  }

  public getPartitionSectionPoints(section: Section) {
    return [
      this.rotatePartitionSectionPoint(section, { X: 0, Y: -4 }),
      this.rotatePartitionSectionPoint(section, { X: section.width, Y: -4 }),
      this.rotatePartitionSectionPoint(section, {
        X: section.width,
        Y: section.modules[0].depth + 4,
      }),
      this.rotatePartitionSectionPoint(section, {
        X: 0,
        Y: section.modules[0].depth + 4,
      }),
    ];
  }

  public rotatePartitionSectionPoint(section: Section, point: Vec2d): Vec2d {
    let rotationRads = section.rotation * (Math.PI / 180);
    let rotatedPoint = VectorHelper.rotate2d(point, rotationRads);
    return VectorHelper.add(rotatedPoint, { X: section.posX, Y: section.posY });
  }

  public getMeasurementLine(
    section: Readonly<Section>,
    floorPlan: FloorPlan,
  ): Line {
    if (section.isHorizontal) {
      return {
        start: { X: -100, Y: section.posY },
        end: { X: floorPlan.Size.X + 100, Y: section.posY },
      };
    } else {
      return {
        start: { X: section.posX, Y: -100 },
        end: { X: section.posX, Y: floorPlan.Size.Y + 100 },
      };
    }
  }

  public findClosestPointOnEachSide(
    section: Readonly<Section>,
    points: Interface_DTO_Draw.Vec2d[],
  ): Line {
    switch (section.rotation) {
      case 90: {
        return {
          start: points.find((pt) => pt.Y <= section.posY - 100),
          end: points.find((pt) => pt.Y >= section.posY + section.width + 100),
        };
      }
      case 180: {
        return {
          start: points.find((pt) => pt.X >= section.posX + 100),
          end: points.find((pt) => pt.X <= section.posX - section.width - 100),
        };
      }
      case 270: {
        return {
          start: points.find((pt) => pt.Y >= section.posY + 100),
          end: points.find((pt) => pt.Y <= section.posY - section.width - 100),
        };
      }
      default: {
        // case 0
        return {
          start: points.find((pt) => pt.X <= section.posX - 100),
          end: points.find((pt) => pt.X >= section.posX + section.width + 100),
        };
      }
    }
  }

  public get isWidthChangeAllowed(): boolean {
    return !(
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Left) &&
      this.partitionQueryService.hasJointProfile(this.section.id, Side.Right)
    );
  }

  public get isDistToLeftWallChangeAllowed(): boolean {
    if (this.section.isHorizontal) {
      let leftProfile = this.partitionQueryService.getSectionLeftProfile(
        this.section.id,
      )!;
      let rightProfile = this.partitionQueryService.getSectionRightProfile(
        this.section.id,
      )!;

      let relativeLeftprofile =
        this.section.rotation == 0 ? leftProfile : rightProfile;
      if (
        relativeLeftprofile &&
        this.partitionQueryService.isJointProfile(relativeLeftprofile.id)
      )
        return false;
    }

    return true;
  }

  public get isDistToRightWallChangeAllowed(): boolean {
    if (this.section.isHorizontal) {
      let leftProfile = this.partitionQueryService.getSectionLeftProfile(
        this.section.id,
      )!;
      let rightProfile = this.partitionQueryService.getSectionRightProfile(
        this.section.id,
      )!;

      let relativeRightProfile =
        this.section.rotation == 0 ? rightProfile : leftProfile;
      if (
        relativeRightProfile &&
        this.partitionQueryService.isJointProfile(relativeRightProfile.id)
      )
        return false;
    }

    return true;
  }

  public get isDistToTopWallChangeAllowed(): boolean {
    if (this.section.isVertical) {
      let leftProfile = this.partitionQueryService.getSectionLeftProfile(
        this.section.id,
      )!;
      let rightProfile = this.partitionQueryService.getSectionRightProfile(
        this.section.id,
      )!;

      let relativeLeftprofile =
        this.section.rotation == 90 ? leftProfile : rightProfile;
      if (
        relativeLeftprofile &&
        this.partitionQueryService.isJointProfile(relativeLeftprofile.id)
      )
        return false;
    }

    return true;
  }

  public get isDistToBottomWallChangeAllowed(): boolean {
    if (this.section.isVertical) {
      let leftProfile = this.partitionQueryService.getSectionLeftProfile(
        this.section.id,
      )!;
      let rightProfile = this.partitionQueryService.getSectionRightProfile(
        this.section.id,
      )!;

      let relativeRightProfile =
        this.section.rotation == 90 ? rightProfile : leftProfile;
      if (
        relativeRightProfile &&
        this.partitionQueryService.isJointProfile(relativeRightProfile.id)
      )
        return false;
    }

    return true;
  }

  public get fullSectionWidth(): number {
    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    );
    let leftProfileSize = 0;
    if (
      leftProfile &&
      (leftProfile.type == ProfileType.Corner ||
        leftProfile.type == ProfileType.TPiece)
    ) {
      leftProfileSize =
        Module.elementSpacing +
        this.partitionQueryService.getProfileSize(leftProfile.id);

      if (
        leftProfile instanceof CornerProfile ||
        (leftProfile instanceof TProfile &&
          leftProfile.middleSection.sectionId == this.section.id)
      ) {
        leftProfileSize +=
          (this.section.railSet.topProduct!.model3dDepth! -
            Module.fullJointWidth) /
          2;
      }
    }

    let rightProfile = this.partitionQueryService.getSectionRightProfile(
      this.section.id,
    );
    let rightProfileSize = 0;
    if (
      rightProfile &&
      (rightProfile.type == ProfileType.Corner ||
        rightProfile.type == ProfileType.TPiece)
    ) {
      rightProfileSize =
        Module.elementSpacing +
        this.partitionQueryService.getProfileSize(rightProfile.id);

      if (
        rightProfile instanceof CornerProfile ||
        (rightProfile instanceof TProfile &&
          rightProfile.middleSection.sectionId == this.section.id)
      ) {
        rightProfileSize +=
          (this.section.railSet.topProduct!.model3dDepth! -
            Module.fullJointWidth) /
          2;
      }
    }

    return leftProfileSize + this.section.width + rightProfileSize;
  }
}
