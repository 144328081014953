import { ApplicationState } from 'app/functional-core/ApplicationState';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { AssetService } from 'app/ts/services/AssetService';
import { AddressService } from 'app/ts/services/AddressService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { StoreService } from 'app/ts/services/StoreService';
import { LoginService as OldLoginService } from 'app/ts/services/LoginService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';

/** Orchestrating the loading of data
 * and other initialization tasks
 */
@Injectable({
  providedIn: 'root',
})
export class Bootstrapper {
  private isBootstrapped: boolean = false;
  constructor(
    //private loginService: LoginService,
    private oldLoginService: OldLoginService,
    private assetService: AssetService,
    private addressService: AddressService,
    private translationService: TranslationService,
    private customerService: CustomerService,
    private storeService: StoreService,
    private data: FunctionalCoreService,
  ) {}

  public async bootstrap(isAuthenticated: boolean): Promise<void> {
    if (this.isBootstrapped) return;

    const translationsPromise = this.translationService.loadTranslations();
    const chainSettingsPromise = this.oldLoginService.loadChainSettings();

    const assetPromise = this.assetService.loadEditorAssets();

    if (!isAuthenticated) {
      await translationsPromise;
      this.data.ambient.applicationState =
        ApplicationState.ReadyForAuthentication;
      return;
    }

    const clientSettings =
      this.data.ambient.clientSetting.loadClientSettingFromLocalStorage(
        this.data.ambient.activeUser.activeUser!.UserId,
      );
    this.data.ambient.clientSetting.setClientSetting(clientSettings);

    const store = await this.storeService.loadStoreById(
      this.data.ambient.activeUser.activeUser!.StoreId,
    );
    this.data.ambient.storeData.setActiveStore(store);

    const currentStoreData = await this.storeService.getCurrentStoreData();
    this.data.ambient.storeData.setStoreSetting(currentStoreData);

    const salesChainSettingsPromise =
      this.oldLoginService.loadSalesChainSettings();

    const userDataPromise = this.oldLoginService.loadUserData();
    const customersPromise = this.customerService.loadCustomers();
    const storesPromise = this.storeService.loadStores();

    await translationsPromise;

    const languagesPromise = this.translationService.loadLanguages();
    await this.addressService.loadCountries();

    await customersPromise;
    await chainSettingsPromise;
    await salesChainSettingsPromise;

    this.data.ambient.userData.setUserData(await userDataPromise);
    this.data.ambient.storeData.setStores(await storesPromise);

    this.data.ambient.i8n.setLanguages(await languagesPromise);

    await assetPromise;
    this.data.ambient.applicationState = ApplicationState.FinishedInitializing;

    this.isBootstrapped = true;
    this.watch();
  }

  public watch() {
    let ignoreFirstUserDataDefaultTrigger = true;

    this.data.ambient.userData.subscribeUserData(async (usersettings) => {
      if (ignoreFirstUserDataDefaultTrigger) {
        ignoreFirstUserDataDefaultTrigger = false;
        return;
      }

      await this.translationService.loadTranslations();
      await this.assetService.loadEditorAssets();
      this.translationService.loadLanguages();
    });

    let ignoreFirstStoreDefaultTrigger = true;
    this.data.ambient.storeData.subscribeActiveStore(async (store) => {
      if (ignoreFirstStoreDefaultTrigger) {
        ignoreFirstStoreDefaultTrigger = false;
        return;
      }

      await this.translationService.loadTranslations();
      const chainSettingsPromise = this.oldLoginService.loadChainSettings();
      const salesChainSettingsPromise =
        this.oldLoginService.loadSalesChainSettings();
      const assetPromise = this.assetService.loadEditorAssets();
      await this.customerService.loadCustomers();

      Promise.all([
        chainSettingsPromise,
        salesChainSettingsPromise,
        assetPromise,
      ]);
    });
    return;
  }
}
