import { Pickable } from 'app/ts/interfaces/Pickable';
export class DynamicPickable<T> implements Pickable<T> {
  constructor(
    private readonly adapter: {
      [K in keyof Pickable<T>]: () => Pickable<T>[K];
    },
  ) {}

  get description() {
    return this.adapter.description && this.adapter.description();
  }
  get disabledReason() {
    return this.adapter.disabledReason();
  }
  get groupName() {
    return this.adapter.groupName && this.adapter.groupName();
  }
  get id() {
    return this.adapter.id && this.adapter.id();
  }
  get imageUrl() {
    return this.adapter.imageUrl && this.adapter.imageUrl();
  }
  get item() {
    return this.adapter.item();
  }
  get isSelected() {
    return this.adapter.isSelected();
  }
  get name() {
    return this.adapter.name();
  }
  get override() {
    return this.adapter.override();
  }
}
