<div class="container-fluid">
  <div class="row">
    <div class="col-sm">
      <nav class="nav nav-tabs breadcrumb">
        <a
          [routerLink]="'/' + selection"
          class="nav-link"
          [textContent]="translate('customer_breadcrumb_all', 'All')"
        ></a>

        <a
          *ngIf="customer"
          [routerLink]="'/' + selection + '?customerId=' + customer.Id"
          class="nav-link"
        >
          <span class="bi bi-person-fill"></span>
          <span [textContent]="customer.shortName"></span>
        </a>

        <a
          *ngIf="project"
          [routerLink]="'/' + selection + '?projectId=' + project.Id"
          class="nav-link"
        >
          <span class="bi bi-folder2-open"></span>
          <span [textContent]="project.shortName"></span>
        </a>

        <a
          *ngIf="deliveryAddress"
          [routerLink]="'/' + selection + '?deliveryAddressId=' + deliveryAddress.ProjectDeliveryAddressId"
          class="nav-link"
        >
          <span class="bi bi-globe"></span>
          <span [textContent]="deliveryAddress.shortName"></span>
        </a>
      </nav>
    </div>
    <div class="col-sm">
      <nav class="nav nav-tabs justify-content-end">
        <a
          [routerLink]="'/customers'"
          [queryParams]="httpParams"
          class="nav-link"
          [ngClass]="selection === 'customers' ? 'active' : ''"
          *ngIf="showExtraOverviewTabs"
        >
          <span class="bi bi-person-fill"></span>
          <span
            [textContent]="translate('customer_breadcrumb_customers_{0}', 'Customers ({0})', customerCounts.numCustomers)"
          ></span>
        </a>

        <a
          [routerLink]="'/projects'"
          [queryParams]="httpParams"
          class="nav-link"
          [ngClass]="selection === 'projects' ? 'active' : ''"
          *ngIf="showExtraOverviewTabs"
        >
          <span class="bi bi-folder2-open"></span>
          <span
            [textContent]="translate('customer_breadcrumb_projects_{0}', 'Projects ({0})', customerCounts.numProjects)"
          ></span>
        </a>

        <a
          [routerLink]="'/deliveryaddresses'"
          [queryParams]="httpParams"
          class="nav-link"
          [ngClass]="selection === 'deliveryAddresses' ? 'active' : ''"
          *ngIf="showExtraOverviewTabs"
        >
          <span class="bi bi-geo-alt-fill"></span>
          <span
            [textContent]="translate('customer_breadcrumb_addresses_{0}', 'Addresses ({0})', customerCounts.numDeliveryAddresses)"
          ></span>
        </a>

        <a
          [routerLink]="'/floorplans'"
          [queryParams]="httpParams"
          class="nav-link"
          [ngClass]="selection === 'floorPlans' ? 'active' : ''"
        >
          <span class="bi bi-pencil-square"></span>
          <span
            [textContent]="translate('customer_breadcrumb_floorplans', 'Floor Plans ({0})', customerCounts.numFloorPlans)"
          ></span>
        </a>
      </nav>
    </div>
  </div>
</div>
