<ng-container>
  <a
    class="btn btn-dark-side btn-new-floorplan"
    *ngIf="showOverviewButton"
    [routerLink]="'/floorplans'"
    [ngClass]="{'active': activeLink == 'floorPlans'}"
    [attr.title]="translate('dark_side_bar_title_floor_overview', 'Configuration overview')"
  >
    <span class="bi bi-card-heading"></span>
  </a>

  <a
    class="btn btn-dark-side"
    [routerLink]="'/confirmorders'"
    [ngClass]="{'active': activeLink == 'orderConfirmer'}"
    *ngIf="showConfirmOrdersButton"
    [attr.title]="translate('dark_side_bar_title_order_confirmer', 'Confirm orders')"
  >
    <span class="bi bi-globe-americas"></span>
  </a>

  <button
    class="btn btn-dark-side btn-help"
    (click)="openLinkBrowser()"
    [attr.title]="translate('dark_side_bar_title_help', 'Guides')"
  >
    <span class="bi bi-question-circle"></span>
  </button>

  <div class="flex-spacer"></div>

  <button
    class="btn-change-store btn btn-dark-side"
    *ngIf="canChangeStore"
    (click)="changeStore()"
    [ngClass]="{'active': changeStoreActive}"
    [attr.title]="translate('dark_side_bar_title_change_store', 'Change store')"
  >
    <span class="bi bi-shop"></span>
  </button>

  <button
    class="btn btn-dark-side btn-activate-full-catalog"
    *ngIf="canActivateFullCatalog && !isFullCatalogActivated"
    (click)="isFullCatalogActivated = true"
    [attr.title]="translate('dark_side_bar_title_activete_full_catalog', 'Activate full catalog')"
  >
    <span class="bi bi-asterisk"></span>
  </button>

  <button
    class="btn btn-dark-side btn-activate-full-catalog active warn"
    *ngIf="canActivateFullCatalog && isFullCatalogActivated"
    (click)="isFullCatalogActivated = false"
    [attr.title]="translate('dark_side_bar_title_deactivete_full_catalog', 'Deactivate full catalog')"
  >
    <span class="bi bi-asterisk"></span>
  </button>

  <button
    class="btn btn-dark-side btn-import-from-web"
    *ngIf="showImportFromWebButton"
    (click)="importEnduserConfigOnDefault()"
    [attr.title]="translate('dark_side_bar_title_import_end_user_config', 'Import End-user configuration')"
  >
    <span class="bi bi-cloud-arrow-down-fill"></span>
  </button>

  <button
    class="btn btn-dark-side btn-user-settings"
    (click)="openUserSettings()"
    *ngIf="showUserSettingsButton"
    [attr.title]="translate('dark_side_bar_title_user_settings', 'User settings')"
  >
    <span class="bi bi-gear-fill"></span>
  </button>
  <a
    class="btn btn-dark-side btn-teamviewer-support"
    href="https://get.teamviewer.com/j5896hw"
    target="_blank"
    [attr.title]="translate('dark_side_bar_title_teamviewer_support', 'Teamviewer support')"
  >
    <span class="bi bi-file-font"></span>
  </a>
</ng-container>
