import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PickableModule } from 'app/floor-plan-editing/pickable.module';
import { ProductListVm } from './ProductListVm';

@NgModule({
  declarations: [ProductListVm],
  exports: [ProductListVm],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbModule,

    PickableModule,
  ],
  providers: [],
})
export class ProductListModule {}
