import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { SubSection } from 'app/ts/clientDto/SubSection';
import { BarHeightEx } from 'app/ts/Client/BarHeightEx';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { DoorSetup } from 'app/ts/clientDto/index';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { Door } from 'app/ts/clientDto/Door';
import { DoorOverlap } from 'app/ts/clientDto/DoorOverlap';
import { RailSet } from 'app/ts/clientDto/RailSet';
import { Product } from 'app/ts/clientDto/Product';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
import { SalesChainSettingHelper } from 'app/ts/util/SalesChainSettingHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { FilterService } from 'app/ts/services/FilterService';
export class Doors implements SubSection {
  public get allowIndividualDoorWidths(): boolean {
    if (this.cache.allowIndividualDoorWidths === undefined) {
      this.cache.allowIndividualDoorWidths =
        (this.editorAssets.fullCatalog ||
          SalesChainSettingHelper.allowIndividualDoorWidths(
            this.editorAssets,
          )) &&
        !!this.profile &&
        this.profile.ProductType !== Interface_Enums.ProductType.DoorStandard &&
        this.numberOfDoors > 0;
    }
    return this.cache.allowIndividualDoorWidths;
  }

  private _manualOverlapPositioning = false;
  public get manualOverlapPositioning(): boolean {
    return this.allowIndividualDoorWidths && this._manualOverlapPositioning;
  }
  public set manualOverlapPositioning(value: boolean) {
    if (this._manualOverlapPositioning === value) {
      return;
    }

    if (!value) this.mustResetOverlapPosition = true;

    this._manualOverlapPositioning = value;
  }

  public mustResetOverlapPosition: boolean = true;
  public mustResetOverlapWidth = false;

  public overlaps: Client.DoorOverlap[] = [];

  public suppressNumDoorsChangeWarning = false;

  public mustCalculateOptimalExtraRailWidths: boolean = false;
  public mustResetNumPositionStops: boolean = false;

  public get doorData(): Interface_DTO.ProductDoorData | null {
    if (!this.cache.doorData) {
      if (this.profile === null) {
        this.cache.doorData = null;
      } else {
        let doorProduct = this.profile;
        this.cache.doorData = Enumerable.from(
          this.editorAssets.productDoorData,
        ).firstOrDefault((pdd) => pdd.ProductId === doorProduct.Id);
      }
    }
    return this.cache.doorData ?? null;
  }

  private _addSoftClose: boolean = false; // Needs to be a property. Sync all sections in same cabinet...
  get canAddSoftclose(): boolean {
    if (this.doorData) {
      //check if there's any door that supports softclose
      let doorData = this.doorData;
      let softCloseProducts = this.doors.map(
        (door) =>
          doorData.SoftCloseProducts.filter(
            (scp) => scp.MinWidth <= door.width,
          )[0],
      );
      return softCloseProducts.some((scp) => !!scp && scp.ProductId !== null);
    }
    return false;
  }

  private _barHeight: number | undefined;
  private _desiredBarHeight: number | undefined;

  public get barHeight() {
    return this._barHeight;
  }
  public set barHeight(value: number | undefined) {
    if (value === this._barHeight) {
      return;
    }

    this._barHeight = value;
    this._desiredBarHeight = value;
    this.clearBackingVariables();
  }

  public get desiredBarHeight(): number | undefined {
    if (!this._desiredBarHeight) {
      this._desiredBarHeight = this._barHeight;
    }
    return this._desiredBarHeight;
  }
  public set desiredBarHeight(value: number | undefined) {
    if (value === this._desiredBarHeight) {
      return;
    }

    this._desiredBarHeight = value;
    this.clearBackingVariables();
  }

  onlyDoor: boolean = false;

  public copyDoor: number | undefined;

  doorItems: Client.ConfigurationItem[] = new Array<Client.ConfigurationItem>();
  railItems: Client.ConfigurationItem[] = new Array<Client.ConfigurationItem>();

  doors: Door[] = [];
  cornerMoulding: Client.ConfigurationItem | null = null;

  //#region Actual values, backing variables

  private _height: number = -1;

  private _profile: Client.Product | null = null;
  private _profileMaterial: Client.Material | null = null;

  private _railSet: Client.RailSet | null = null;
  private _railMaterialTop: Client.Material | null = null;
  private _railMaterialBottom: Client.Material | null = null;

  private _numberOfSoftClose: number = 0;
  private _numberOfPositionStops: number = 0;

  private _grip: Client.Product | null = null;
  private _gripPlacementHeight: number = 200;

  private cache: Partial<{
    availableProfiles: Client.Product[];
    pickableProfiles: Pickable<Client.Product | null>[];
    availableProfileMaterials: Client.ProductMaterial[];
    pickableProfileMaterials: Pickable<Client.ProductMaterial | null>[];

    availableCornerMouldingMaterials: Client.Material[];

    availableRailsets: Client.RailSet[];
    pickableRailsets: Pickable<Client.RailSet | null>[];

    availableRailTopMaterials: Client.ProductMaterial[];
    pickableRailTopMaterials: Pickable<Client.ProductMaterial | null>[];

    availableRailBottomMaterials: Client.ProductMaterial[];
    pickableRailBottomMaterials: Pickable<Client.ProductMaterial | null>[];

    availableFillingMaterials: Client.Material[];

    availableGrips: Client.Product[];
    pickableGrips: Pickable<Client.Product | null>[];

    availableGripPlacementsFront: Interface_DTO.VariantOption[];
    availableGripPlacementsBack: Interface_DTO.VariantOption[];

    availableVerticalBarOptions: Interface_DTO.VariantOption[];

    availableBars: BarHeightEx[];

    doorData: Interface_DTO.ProductDoorData | null;

    cornerMouldingProduct: Client.Product | null;

    allowIndividualDoorWidths: boolean;

    validDoorSetups: DoorSetup[];

    optimalDoorSetup: DoorSetup;
  }> = {};

  //#endregion Availables + pickables, backing variables

  constructor(public readonly cabinetSection: Client.CabinetSection) {}

  public clearBackingVariables() {
    this.cache = {};

    this.doors.forEach((d) => d.clearBackingVariables());
  }

  private resetAllButProfile() {
    this._profileMaterial = null;

    this.barHeight = undefined;

    this._railSet = null;
    this._railMaterialTop = null;
    this._railMaterialBottom = null;

    this._numberOfSoftClose = 0;
    this._numberOfPositionStops = 0;

    this.grip = null;

    this.doors.length = 0;
    this.doorItems.length = 0;
    this.railItems.length = 0;

    this.mustResetOverlapPosition = true;
    this.mustResetNumPositionStops = true;
    this.mustCalculateOptimalExtraRailWidths = true;

    this.clearBackingVariables();
  }

  private setDefaultValues() {
    this.overlapWidth = this.overlapWidthDefault;
  }

  //#region Actual values, public properties

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  public get height(): number {
    return this._height;
  }
  public set height(value: number) {
    if (value === this._height) {
      return;
    }

    this._height = value;
    this.clearBackingVariables();
  }

  public get profile(): Client.Product | null {
    return this._profile;
  }
  public set profile(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this._profile)) {
      return;
    }

    this.cabinetSection.cabinet.setDoorProfile(this.cabinetSection, value);
  }
  public setProfile(newProfile: Client.Product | null, reset: boolean = true) {
    this._profile = newProfile;
    if (reset) {
      this.resetAllButProfile();
      this.setDefaultValues();
    }
  }

  public get cornerMouldingProduct(): Client.Product | null {
    if (this.cache.cornerMouldingProduct === undefined) {
      if (this.doorData) {
        let doorData = this.doorData;
        this.cache.cornerMouldingProduct = Enumerable.from(
          this.editorAssets.products,
        ).firstOrDefault((p) => p.Id === doorData.CornerMouldingProductId);
      } else {
        this.cache.cornerMouldingProduct = null;
      }
    }

    return this.cache.cornerMouldingProduct ?? null;
  }

  public get profileMaterial(): Client.Material | null {
    return this._profileMaterial;
  }
  public set profileMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._profileMaterial)) {
      return;
    }

    this.cabinetSection.cabinet.setDoorMaterial(this.cabinetSection, value);
  }
  public setProfileMaterial(newMaterial: Client.Material | null) {
    this._profileMaterial = newMaterial;
    this.clearBackingVariables();
  }

  public get railSet(): Client.RailSet | null {
    return this._railSet;
  }
  public set railSet(value: Client.RailSet | null) {
    if (ObjectHelper.equals(value, this._railSet)) {
      return;
    }

    this.cabinetSection.cabinet.setDoorRailset(this.cabinetSection, value);
  }
  public setRailSet(newRailSet: Client.RailSet | null) {
    // If number of tracks changes from or to one, extra rail widths must be calculated
    if (
      this._railSet &&
      newRailSet &&
      (this._railSet.NumberOfTracks === 1) !== (newRailSet.NumberOfTracks === 1)
    ) {
      this.mustCalculateOptimalExtraRailWidths = true;
    }

    if (
      this._railSet &&
      this._railSet.NumberOfTracks === 1 &&
      newRailSet &&
      newRailSet.NumberOfTracks > 1
    ) {
      this.mustResetOverlapWidth = true;
    }

    this._railSet = newRailSet;
    this.clearBackingVariables();
  }

  public get railMaterialTop(): Client.Material | null {
    return this._railMaterialTop;
  }
  public set railMaterialTop(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._railMaterialTop)) {
      return;
    }

    this.cabinetSection.cabinet.setRailMaterialTop(this.cabinetSection, value);
  }
  public setRailMaterialTop(newMaterial: Client.Material | null) {
    this._railMaterialTop = newMaterial;
    this.clearBackingVariables();
  }

  public get railMaterialBottom(): Client.Material | null {
    return this._railMaterialBottom;
  }
  public set railMaterialBottom(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._railMaterialBottom)) {
      return;
    }

    this.cabinetSection.cabinet.setRailMaterialBottom(
      this.cabinetSection,
      value,
    );
  }
  public setRailMaterialBottom(newMaterial: Client.Material | null) {
    this._railMaterialBottom = newMaterial;
    this.clearBackingVariables();
  }

  public get numberOfDoors(): number {
    return this.cabinetSection.NumberOfDoors;
  }
  public set numberOfDoors(value: number) {
    if (value === null || value === this.cabinetSection.NumberOfDoors) {
      return;
    }
    this.mustResetNumPositionStops = true;

    // If number of doors changes from or to one, extra rail widths must be calculated
    if (this.cabinetSection.NumberOfDoors === 1 || value === 1) {
      this.mustCalculateOptimalExtraRailWidths = true;
    }

    this.cabinetSection.NumberOfDoors = value;
    this.clearBackingVariables();
  }

  public get numberOfOverlaps(): number {
    return this.cabinetSection.NumberOfOverlaps;
  }
  public set numberOfOverlaps(value: number) {
    if (value === null || value === this.cabinetSection.NumberOfOverlaps) {
      return;
    }

    this.cabinetSection.NumberOfOverlaps = value;
    this.clearBackingVariables();
  }

  public get doorSetup(): DoorSetup {
    let doorSetup = Enumerable.from(this.validDoorSetups).firstOrDefault(
      (ds) =>
        ds.doors === this.numberOfDoors &&
        ds.overlaps === this.numberOfOverlaps,
    );

    if (!doorSetup) {
      doorSetup = Enumerable.from(this.validDoorSetups).firstOrDefault(
        (ds) => ds.doors === this.numberOfDoors,
      );
    }

    if (!doorSetup) {
      doorSetup = Enumerable.from(this.validDoorSetups).first();
    }

    if (!!doorSetup) {
      this.numberOfDoors = doorSetup.doors;
      this.numberOfOverlaps = doorSetup.overlaps;
    }

    return doorSetup;
  }

  public get overlapWidth(): number {
    return this.cabinetSection.OverlapWidth;
  }
  public set overlapWidth(value: number) {
    if (value === this.cabinetSection.OverlapWidth) {
      return;
    }

    this.cabinetSection.OverlapWidth = value;
    this.clearBackingVariables();
  }

  public get addSoftClose(): boolean {
    return this._addSoftClose;
  }
  public set addSoftClose(value: boolean) {
    if (value === this._addSoftClose) {
      return;
    }

    this.cabinetSection.cabinet.setAddSoftClose(this.cabinetSection, value);
  }
  public setAddSoftClose(newValue: boolean) {
    let val = newValue && this.canAddSoftclose;
    this._addSoftClose = val;
  }

  public get numberOfPositionStops(): number {
    return this._numberOfPositionStops;
  }
  public set numberOfPositionStops(value: number) {
    if (value === this._numberOfPositionStops) {
      return;
    }

    this._numberOfPositionStops = value;
    this.clearBackingVariables();
  }

  public get canHavePositionStop(): boolean {
    if (!this.doorData) return false;
    if (!this.doorData.PositionStopProductId) return false;
    return (
      this.doorData.SoftCloseType === Interface_Enums.SoftCloseType.Magnetic
    );
  }

  public get extraRailWidthLeft(): number {
    return this.cabinetSection.ExtraRailWidthLeft;
  }
  public set extraRailWidthLeft(value: number) {
    if (value === this.cabinetSection.ExtraRailWidthLeft) {
      return;
    }

    this.cabinetSection.ExtraRailWidthLeft = value;
    this.clearBackingVariables();
  }

  public get extraRailWidthRight(): number {
    return this.cabinetSection.ExtraRailWidthRight;
  }
  public set extraRailWidthRight(value: number) {
    if (value === this.cabinetSection.ExtraRailWidthRight) {
      return;
    }

    this.cabinetSection.ExtraRailWidthRight = value;
    this.clearBackingVariables();
  }
  public get actualExtraRailWidthRight(): number {
    if (this.numberOfRailTracks !== 1 || this.onlyDoor) {
      return this.extraRailWidthRight;
    }

    let railWidth = CabinetSectionHelper.getRailWidth(this.cabinetSection);
    return (
      railWidth -
      this.cabinetSection.ExtraRailWidthLeft -
      this.cabinetSection.ExtraRailWidthRight
    );
  }

  public get grip(): Client.Product | null {
    return this._grip;
  }
  public set grip(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this._grip)) {
      return;
    }

    if (!this._grip && !!value) {
      this.gripPlacementHeight = this.defaultGripPlacementHeight;
    }

    this.cabinetSection.cabinet.setGrip(this.cabinetSection, value);
  }
  public setGrip(newGrip: Client.Product | null) {
    this._grip = newGrip;
    this.clearBackingVariables();
  }

  public get gripPlacementHeight(): number {
    if (isNaN(this._gripPlacementHeight)) {
      this._gripPlacementHeight = this.defaultGripPlacementHeight;
    }

    return this._gripPlacementHeight;
  }
  public set gripPlacementHeight(value: number) {
    if (value === undefined || value === this._gripPlacementHeight) {
      return;
    }

    if (isNaN(value)) {
      value = this.defaultGripPlacementHeight;
    }

    let newValue = ObjectHelper.clamp(
      this.minGripPlacementHeight,
      value,
      this.maxGripPlacementHeight,
    );
    this.cabinetSection.cabinet.setGripHeight(this.cabinetSection, newValue);
  }
  public setGripHeight(newHeight: number) {
    this._gripPlacementHeight = newHeight;
    this.clearBackingVariables();
  }

  public pulloutWarningAreas: Client.PulloutWarningArea[] = [];

  //#endregion Actual values, public properties

  //#region Availables and min/max/default values, public getters

  public get availableProfiles(): Client.Product[] {
    if (this.cache.availableProfiles === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let includeStandardDoors =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let doorProducts = this.editorAssets.products.filter(
        (p) =>
          p.ProductType === Interface_Enums.ProductType.Door ||
          (includeStandardDoors &&
            p.ProductType === Interface_Enums.ProductType.DoorStandard),
      );
      doorProducts = FilterService.getFilteredProducts(
        doorProducts,
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        fullCatalog,
      );

      // For corner cabinets, remove doors without corner mouldings
      if (
        this.cabinetSection.CabinetType ===
        Interface_Enums.CabinetType.CornerCabinet
      ) {
        doorProducts = doorProducts.filter((dp) => {
          const doorData = Enumerable.from(
            this.editorAssets.productDoorData,
          ).firstOrDefault((pdd) => pdd.ProductId === dp.Id);
          return doorData != null && doorData.CornerMouldingProductId > 0;
        });
      }

      // Remove doors with no railsets
      this.cache.availableProfiles = doorProducts.filter((dp) => {
        return this.getAvailableDoorRailSets(dp).length > 0;
      });
    }

    return this.cache.availableProfiles;
  }
  public get pickableProfiles(): Pickable<Client.Product | null>[] {
    if (this.cache.pickableProfiles === undefined) {
      let doorsOnly =
        this.cabinetSection.CabinetType === Interface_Enums.CabinetType.Doors;
      let freeInteriorDepth =
        this.cabinetSection.interior.items.length > 0
          ? CabinetSectionHelper.getDifferenceBetweenSectionDepthAndFrontOfInterior(
              this.cabinetSection,
            )
          : this.cabinetSection.Depth;

      // Validate available door types and map to Pickable
      this.cache.pickableProfiles = this.availableProfiles
        .map<Pickable<Product>>((product, index) => {
          let railSets = this.getAvailableDoorRailSets(product);

          // If there is not enough space behind the door, it must be disabled
          let spaceForRail =
            freeInteriorDepth -
            CabinetSectionHelper.getTopRailDistanceFromFront(
              this.cabinetSection,
            );
          let notEnoughSpace =
            !doorsOnly &&
            Enumerable.from(railSets).min((r) =>
              RailSetHelper.minimumDepth(
                r,
                this.cabinetSection.cabinet.ProductLineId,
              ),
            ) > spaceForRail;

          let pickable: Pickable<Product> = {
            ...ProductHelper.getPickable(product),
            isSelected: this.profile ? this.profile.Id === product.Id : false,
            disabledReason: notEnoughSpace
              ? 'door_disabled_not_enough_space'
              : null,
            groupName: '',
          };

          return pickable;
        })
        .filter((pp) => pp !== undefined);

      // Insert "None" Pickable at the start of the list
      this.cache.pickableProfiles.unshift({
        name: this.editorAssets.translationService.translate(
          'pickable_door_name_none',
          'No Doors',
        ),
        disabledReason: null,
        groupName: this.editorAssets.translationService.translate(
          'pickable_door_group_none',
          'No Doors',
        ),
        isSelected: this.profile === null,
        item: null,
        override: false,
      });
    }
    return this.cache.pickableProfiles;
  }

  public get availableProfileMaterials(): Client.ProductMaterial[] {
    if (this.cache.availableProfileMaterials === undefined) {
      if (this.profile === null) {
        this.cache.availableProfileMaterials = [];
      } else {
        let mats = this.profile.materials.filter(
          (m) => m.Type === Interface_Enums.MaterialType.Frame,
        );
        mats.sort((m1, m2) => m1.SortOrder - m2.SortOrder);
        this.cache.availableProfileMaterials = mats;
      }
    }

    return this.cache.availableProfileMaterials;
  }
  public get pickableProfileMaterials(): Pickable<Client.ProductMaterial | null>[] {
    if (this.cache.pickableProfileMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
      this.cache.pickableProfileMaterials = this.availableProfileMaterials
        .filter((pm) => !pm.isDiscontinued && (fullCatalog || !pm.isOverride))
        .map((mat) => {
          let pickable: Pickable<Client.ProductMaterial> = {
            ...MaterialHelper.getPickable(mat),
            isSelected: this.profileMaterial
              ? this.profileMaterial.Id === mat.Id
              : false,
            override: mat.isOverride,
          };

          return pickable;
        });

      MaterialHelper.sortPickableMaterials(this.cache.pickableProfileMaterials);
    }

    return this.cache.pickableProfileMaterials;
  }

  public get availableCornerMouldingMaterials(): Client.Material[] {
    if (this.cache.availableCornerMouldingMaterials === undefined) {
      var mouldingProduct = this.cornerMouldingProduct;
      this.cache.availableCornerMouldingMaterials =
        mouldingProduct !== null
          ? mouldingProduct.materials.filter(
              (m) => m.Type === Interface_Enums.MaterialType.Frame,
            )
          : [];
    }

    return this.cache.availableCornerMouldingMaterials;
  }

  public get availableRailsets(): Client.RailSet[] {
    if (this.cache.availableRailsets === undefined) {
      this.cache.availableRailsets =
        this.profile !== null
          ? this.getAvailableDoorRailSets(this.profile)
          : [];
    }

    return this.cache.availableRailsets;
  }
  public get pickableRailsets(): Pickable<Client.RailSet | null>[] {
    if (this.cache.pickableRailsets === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.pickableRailsets = this.availableRailsets
        .filter((railset) => {
          for (let p of [railset.bottomProduct, railset.topProduct]) {
            if (p) {
              if (!p.Enabled) return false;
              if (!fullCatalog && p.overrideChain) {
                return false;
              }
            }
          }
          return true;
        })
        .map<Pickable<Client.RailSet>>((railset) => {
          let pickable: Pickable<Client.RailSet> = {
            ...RailSetHelper.getPickable(railset),
            isSelected: this.railSet ? this.railSet.Id === railset.Id : false,
          };

          return pickable;
        });
    }

    return this.cache.pickableRailsets;
  }

  public get availableRailTopMaterials(): Client.ProductMaterial[] {
    if (this.cache.availableRailTopMaterials === undefined) {
      this.cache.availableRailTopMaterials =
        this.railSet != null && this.railSet.topProduct != null
          ? this.railSet.topProduct.materials.filter(
              (m) => m.Type === Interface_Enums.MaterialType.Frame,
            )
          : [];
    }

    return this.cache.availableRailTopMaterials;
  }
  public get pickableRailTopMaterials(): Pickable<Client.ProductMaterial | null>[] {
    if (this.cache.pickableRailTopMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
      this.cache.pickableRailTopMaterials = this.availableRailTopMaterials
        .filter(
          (mat) => !mat.isDiscontinued && (!mat.isOverride || fullCatalog),
        )
        .map((mat) => {
          let pickable: Pickable<Client.ProductMaterial> = {
            ...MaterialHelper.getPickable(mat),
            isSelected: this.railMaterialTop
              ? this.railMaterialTop.Id === mat.Id
              : false,
            override: mat.isOverride,
          };

          return pickable;
        });

      MaterialHelper.sortPickableMaterials(this.cache.pickableRailTopMaterials);
    }

    return this.cache.pickableRailTopMaterials;
  }

  public get availableRailBottomMaterials(): Client.ProductMaterial[] {
    if (this.cache.availableRailBottomMaterials === undefined) {
      this.cache.availableRailBottomMaterials =
        this.railSet != null && this.railSet.bottomProduct != null
          ? this.railSet.bottomProduct.materials.filter(
              (m) => m.Type === Interface_Enums.MaterialType.Frame,
            )
          : [];
    }

    return this.cache.availableRailBottomMaterials;
  }
  public get pickableRailBottomMaterials(): Pickable<Client.ProductMaterial | null>[] {
    if (this.cache.pickableRailBottomMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
      this.cache.pickableRailBottomMaterials = this.availableRailBottomMaterials
        .filter(
          (mat) => !mat.isDiscontinued && (!mat.isOverride || fullCatalog),
        )
        .map((mat) => {
          let pickable: Pickable<Client.ProductMaterial> = {
            ...MaterialHelper.getPickable(mat),
            isSelected: this.railMaterialBottom
              ? this.railMaterialBottom.Id === mat.Id
              : false,
            override: mat.isOverride,
          };

          return pickable;
        });

      MaterialHelper.sortPickableMaterials(
        this.cache.pickableRailBottomMaterials,
      );
    }

    return this.cache.pickableRailBottomMaterials;
  }

  //rail materials that are available for both top and bottom
  public getAvailableRailMaterials(): Client.RailMaterial[] {
    let railset = this.railSet;
    if (!railset) return [];
    let bottomMats = railset.bottomProduct
      ? railset.bottomProduct.materials
      : [];
    let topMats = railset.topProduct ? railset.topProduct.materials : [];
    let result: Client.RailMaterial[] = [];
    for (let i = 0; i < bottomMats.length; i++) {
      let bm = bottomMats[i];
      let tm = topMats.filter((m) => m.Id === bm.Id)[0];
      if (tm) {
        result.push(new Client.RailMaterial(tm, bm));
      }
    }
    return result;
  }

  public get availableFillingMaterials(): Client.Material[] {
    if (this.cache.availableFillingMaterials === undefined) {
      this.cache.availableFillingMaterials =
        this.profile !== null
          ? this.profile.materials.filter(
              (m) => m.Type === Interface_Enums.MaterialType.Normal,
            )
          : new Array<Client.Material>();
    }

    return this.cache.availableFillingMaterials;
  }

  public get availableNumberOfDoors(): number[] {
    return this.getAvailableNumberOfDoors();
  }
  public get optimalNumberOfDoors(): number {
    let optimalSetup = this.getOptimalDoorSetup();
    if (optimalSetup !== undefined) {
      return optimalSetup.doors;
    }

    return 0;
  }

  public get availableNumberOfOverlaps(): number[] {
    return this.getAvailableNumberOfOverlaps();
  }
  public get optimalNumberOfOverlaps(): number {
    if (this.availableNumberOfOverlaps.length > 0) {
      let optimalSetup = this.getOptimalDoorSetup();
      if (optimalSetup !== undefined) {
        let optimalOverlaps = optimalSetup.overlaps;
        if (
          this.availableNumberOfOverlaps.some((num) => num === optimalOverlaps)
        ) {
          return optimalOverlaps;
        }
      }

      return this.availableNumberOfOverlaps[0];
    }

    return 0;
  }

  public get overlapWidthMin(): number {
    if (this._profile !== null) {
      let profileId = this._profile.Id;
      if (this.doorData !== null) {
        return this.doorData.MinOverlap;
      }
    }

    return 0;
  }
  public get overlapWidthMax(): number {
    if (this._profile !== null) {
      let profileId = this._profile.Id;
      if (this.doorData !== null) {
        return this.doorData.MaxOverlap;
      }
    }

    return 0;
  }
  public get overlapWidthDefault(): number {
    let overlapWidth = 0;

    if (this._profile !== null) {
      let profileId = this._profile.Id;
      if (this.doorData !== null) {
        overlapWidth = this.doorData.Overlap;
      }
    }

    return Math.min(
      Math.max(overlapWidth, this.overlapWidthMin),
      this.overlapWidthMax,
    );
  }

  public get softCloseMin(): number {
    return 0;
  }
  public get softCloseMax(): number {
    return 2;
  }

  public get numberOfPositionStopsMin(): number {
    return 0;
  }
  public get numberOfPositionStopsMax(): number {
    if (!this.canHavePositionStop) return 0;
    return this.numberOfDoors * 2;
  }

  public get isEmpty(): boolean {
    return this.numberOfDoors === 0;
  }

  public get availableBars(): BarHeightEx[] {
    if (!this.cache.availableBars) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      if (this.doorData && this.doorData.Bars) {
        let bd1 = Enumerable.from(this.doorData.Bars)
          .where((barData) => fullCatalog || !barData.ChainOverride)
          .groupBy((barData) => barData.Height)
          .select(
            (barGroup) =>
              new BarHeightEx(
                barGroup
                  .select((barData) => ({
                    barData: barData,
                    product: this.editorAssets.productsDict[barData.ProductId],
                  }))
                  .toArray(),
              ),
          )
          .where(
            (barHeight) =>
              barHeight.isValid() &&
              (fullCatalog ||
                !barHeight.bars.some((b) => b.product.overrideChain)),
          )
          .orderByDescending((bde) => bde.height)
          .toArray();
        this.cache.availableBars = Enumerable.from(bd1)
          .distinct((bde) => bde.height)
          .toArray();
      } else {
        this.cache.availableBars = [];
      }
    }
    return this.cache.availableBars;
  }

  public get currentBarHeight() {
    return Enumerable.from(this.availableBars).firstOrDefault(
      (b) => b.height === this.desiredBarHeight,
    );
  }

  public get extraRailWidthLeftMin(): number {
    return Constants.absoluteMinExtraRailWidth;
  }
  public get extraRailWidthLeftMax(): number {
    if (this.numberOfRailTracks === 1 && this.profile !== null) {
      let railWidth = CabinetSectionHelper.getRailWidth(this.cabinetSection);
      return (
        railWidth - CabinetSectionHelper.getSightWidth(this.cabinetSection)
      );
    }

    return CabinetSectionHelper.hasCornerLeft(this.cabinetSection)
      ? this.extraRailWidthLeftMin
      : Constants.absoluteMaxExtraRailWidth;
  }

  public get extraRailWidthRightMin(): number {
    if (this.numberOfRailTracks === 1 && this.profile !== null) {
      return ProductHelper.minWidth(this.profile) * this.numberOfDoors;
    }

    return Constants.absoluteMinExtraRailWidth;
  }
  public get extraRailWidthRightMax(): number {
    if (this.numberOfRailTracks === 1 && this.profile !== null) {
      let railWidth = CabinetSectionHelper.getRailWidth(this.cabinetSection);
      let maxTotalDoorWidth =
        ProductHelper.maxWidth(this.profile) * this.numberOfDoors;

      return Math.min(railWidth, maxTotalDoorWidth);
    }

    return CabinetSectionHelper.hasCornerRight(this.cabinetSection)
      ? this.extraRailWidthRightMin
      : Constants.absoluteMaxExtraRailWidth;
  }

  public get availableGrips(): Client.Product[] {
    if (this.cache.availableGrips === undefined) {
      this.cache.availableGrips = [];
      if (this.profile) {
        let gripVariant = Enumerable.from(
          this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
        ).firstOrDefault((v) => v.Number.indexOf(VariantNumbers.Grip) === 0);
        if (gripVariant) {
          var gripMap = this.editorAssets.doorGripMap[this.profile.Id];
          for (let variantOption of gripVariant.VariantOptions) {
            var gripProductId = gripMap[variantOption.Number];
            let gripProduct = this.editorAssets.productsDict[gripProductId];
            if (gripProduct) {
              if (
                !gripProduct.OverrideChain ||
                this.cabinetSection.cabinet.floorPlan
                  .FullCatalogAllowOtherProducts
              )
                this.cache.availableGrips.push(gripProduct);
            }
          }
        }
      }
    }
    return this.cache.availableGrips;
  }
  public get pickableGrips(): Pickable<Client.Product | null>[] {
    if (this.cache.pickableGrips === undefined) {
      this.cache.pickableGrips = this.availableGrips
        .map<Pickable<Product>>((product, index) => {
          let pickable: Pickable<Product> = {
            ...ProductHelper.getPickable(product),
            isSelected: this.grip ? this.grip.Id === product.Id : false,
            disabledReason: null,
          };

          return pickable;
        })
        .filter((pp) => pp !== undefined);

      // Insert "None" Pickable at the start of the list
      this.cache.pickableGrips.unshift({
        name: this.editorAssets.translationService.translate(
          'pickable_grip_name_none',
          'No Grip',
        ),
        disabledReason: null,
        groupName: this.editorAssets.translationService.translate(
          'pickable_grip_group_none',
          'No Grip',
        ),
        isSelected: true,
        item: null,
        override: false,
      });
    }
    return this.cache.pickableGrips;
  }

  /**
   * Array of variant options
   * Show "DefaultName" in GUI
   **/
  public get availableGripPlacementsFront(): Interface_DTO.VariantOption[] {
    if (this.cache.availableGripPlacementsFront === undefined) {
      this.cache.availableGripPlacementsFront = [];
      if (this.profile) {
        let variant = Enumerable.from(
          this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
        ).firstOrDefault(
          (v) => v.Number === VariantNumbers.GripPlacementFrontside,
        );
        if (variant) {
          this.cache.availableGripPlacementsFront = variant.VariantOptions;
        }
      }
    }

    return this.cache.availableGripPlacementsFront;
  }

  /**
   * Array of variant options
   * Show "DefaultName" in GUI
   **/
  public get availableGripPlacementsBack(): Interface_DTO.VariantOption[] {
    if (this.cache.availableGripPlacementsBack === undefined) {
      this.cache.availableGripPlacementsBack = [];
      if (this.profile) {
        let variant = Enumerable.from(
          this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
        ).firstOrDefault(
          (v) => v.Number === VariantNumbers.GripPlacementBackside,
        );
        if (variant) {
          this.cache.availableGripPlacementsBack = variant.VariantOptions;
        }
      }
    }

    return this.cache.availableGripPlacementsBack;
  }

  public get minGripPlacementHeight(): number {
    return Constants.gripMinDistanceFromTopAndBottom;
  }
  public get maxGripPlacementHeight(): number {
    return this.height - Constants.gripMinDistanceFromTopAndBottom;
  }

  public get defaultGripPlacementHeight(): number {
    let placementSpan =
      this.maxGripPlacementHeight - this.minGripPlacementHeight;
    return this.minGripPlacementHeight + placementSpan / 2;
  }

  public get availableVerticalBarOptions(): Interface_DTO.VariantOption[] {
    if (this.cache.availableVerticalBarOptions === undefined) {
      this.cache.availableVerticalBarOptions = [];
      if (this.profile) {
        let variant = Enumerable.from(
          this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
        ).firstOrDefault((v) => v.Number === VariantNumbers.VerticalBarCount);
        if (variant) {
          this.cache.availableVerticalBarOptions = variant.VariantOptions;
        }
      }
    }

    return this.cache.availableVerticalBarOptions;
  }

  //#endregion Availables and min/max values, public getters

  public get verticalBarVariantText(): string {
    if (this.profile) {
      let variant = Enumerable.from(
        this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
      ).firstOrDefault((v) => v.Number === VariantNumbers.VerticalBarCount);
      if (variant) return variant.DefaultName;
    }

    return '';
  }

  public get forceFixedBarVariantText(): string {
    if (this.profile) {
      let variant = Enumerable.from(
        this.profile.getVariants(this.cabinetSection.cabinet.ProductLineId),
      ).firstOrDefault((v) => v.Number === VariantNumbers.ForceFixedBars);
      if (variant) return variant.DefaultName;
    }

    return '';
  }

  public get barsAvailable(): boolean {
    return this.availableBars.length > 0;
  }

  public get numberOfRailTracks(): number {
    return this._railSet !== null ? this._railSet.NumberOfTracks : 0;
  }

  //#region Private properties

  private get validDoorSetups(): DoorSetup[] {
    if (this.cache.validDoorSetups === undefined) {
      this.cache.validDoorSetups = new Array<DoorSetup>();

      if (this.profile !== null && (this.onlyDoor || this.railSet !== null)) {
        let possibleDoorSetups = this.onlyDoor
          ? Constants.possibleDoorSetupsDoorOnly
          : Constants.possibleDoorSetups;

        let tracks =
          this.onlyDoor || this.railSet === null
            ? 1
            : this.railSet.NumberOfTracks;
        let sightWidth = CabinetSectionHelper.getSightWidth(
          this.cabinetSection,
        );

        let useWideDoors =
          this.editorAssets.fullCatalog &&
          this.cabinetSection.cabinet.floorPlan.FullCatalogWideDoors;

        // Standard doors only...
        if (
          this.profile.ProductType === Interface_Enums.ProductType.DoorStandard
        ) {
          this.cache.validDoorSetups = possibleDoorSetups.filter(
            (ds) =>
              ds.rails === tracks &&
              ds.supportsWidthForStandardDoors(
                sightWidth,
                this.editorAssets.doorWidths,
                this.overlapWidthMin,
                this.overlapWidthMax,
              ),
          );
        }
        // ... or all but standard doors
        else {
          let minDoorWidth = ProductHelper.minWidth(this.profile);
          let maxDoorWidth = useWideDoors
            ? Number.MAX_VALUE
            : ProductHelper.maxWidth(this.profile);

          let fullCatalog =
            this.editorAssets.fullCatalog &&
            this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
          if (this.doorData && this.doorData.Bars) {
            let availableBars = Enumerable.from(this.doorData.Bars).where(
              (bar) => fullCatalog || !bar.ChainOverride,
            );
            let looseBarsOnly = availableBars.all(
              (bar) => bar.BarType === Interface_Enums.BarType.Loose,
            );
            if (looseBarsOnly && !useWideDoors) {
              maxDoorWidth = Math.max(
                this.doorData.MaxWidthSingleFillingGlass,
                this.doorData.MaxWidthSingleFillingNonglass,
              );
            }
          }

          let maxFillingWidth = 0;
          if (this.availableFillingMaterials.length > 0) {
            let absoluteMaxFillingWidth = Enumerable.from(
              this.availableFillingMaterials,
            ).max((mat) => MaterialHelper.absoluteMaxFillingWidth(mat));
            let absoluteMaxDoorWidth = Enumerable.from(
              this.editorAssets.doorWidths,
            ).max((dw) => dw.Width);
            maxFillingWidth = Math.min(
              absoluteMaxFillingWidth,
              absoluteMaxDoorWidth,
            );
          }

          maxDoorWidth = Math.min(maxDoorWidth, maxFillingWidth);
          let ignoreMaxWidth = tracks === 1 && !this.onlyDoor;

          this.cache.validDoorSetups = possibleDoorSetups.filter(
            (ds) =>
              ds.rails === tracks &&
              ds.supportsWidth(
                sightWidth,
                minDoorWidth,
                maxDoorWidth,
                this.overlapWidth,
                ignoreMaxWidth,
              ),
          );
        }

        // For corner sections, convert or remove invalid doorsetups
        if (CabinetSectionHelper.hasCorner(this.cabinetSection)) {
          for (var i = this.cache.validDoorSetups.length - 1; i >= 0; i--) {
            let doorSetup = this.cache.validDoorSetups[i];

            if (
              CabinetSectionHelper.hasCornerLeft(this.cabinetSection) &&
              !doorSetup.validForCornerLeft
            ) {
              if (doorSetup.validForCornerRight) {
                this.cache.validDoorSetups[i] = doorSetup.reversedClone();
              } else {
                this.cache.validDoorSetups.splice(i, 1);
              }
            } else if (
              CabinetSectionHelper.hasCornerRight(this.cabinetSection) &&
              !doorSetup.validForCornerRight
            ) {
              if (doorSetup.validForCornerLeft) {
                this.cache.validDoorSetups[i] = doorSetup.reversedClone();
              } else {
                this.cache.validDoorSetups.splice(i, 1);
              }
            }
          }
        }
      }
    }

    return this.cache.validDoorSetups;
  }

  //#endregion Private properties

  //#region Public functions

  public loadFrom(dtoSection: Interface_DTO.CabinetSection) {
    // Slightly hacky: Remember this for later, as it will be reset to default when the door profil is set...
    let overlapWidth = Math.max(0, dtoSection.OverlapWidth);

    // Doors
    let foundFirstDoor = false;
    let nonDoorItems: Interface_DTO.ConfigurationItem[] = [];
    for (let doorItem of Enumerable.from(dtoSection.DoorItems)
      .orderBy((d) => d.X)
      .toArray()) {
      let foundProfile = Enumerable.from(this.availableProfiles).firstOrDefault(
        (p) => p.Id === doorItem.ProductId,
      );
      if (foundProfile) {
        // It is a door
        if (!foundFirstDoor) {
          this.loadProfile(doorItem, foundProfile);
          this.loadProfileMaterial(doorItem, foundProfile);
          this.loadBarHeight(doorItem, foundProfile);
          this.height = ConfigurationItemHelper.getDoorHeight(
            doorItem,
            foundProfile,
            this.cabinetSection.cabinet.ProductLineId,
          );
          this.doors = [];
          foundFirstDoor = true;
        }

        // Grips may be on some doors, even if there is no grip on the first door
        if (!this.grip) {
          this.loadGripTypeAndPlacement(doorItem, foundProfile);
        }

        // Do the door stuff, that is not the same for all doors...
        let newDoor = new Door(this, this.editorAssets);
        this.doors.push(newDoor);
        newDoor.loadFromItem(doorItem);
        newDoor.mustSpreadBarsEvenly = false;
      } else {
        // It is not a door. Store it for later processing.
        nonDoorItems.push(doorItem);
      }
    }

    if (!foundFirstDoor) {
      // No door was found, so we set the profile to null to reset everything.
      this.profile = null;
    }

    // Set values, that require a door profile
    if (this.profile) {
      this.loadSoftCloseAndPositionStops(nonDoorItems);
    }

    let doorWidth = 0;
    for (let door of this.doors) {
      if (doorWidth > 0 && door.width !== doorWidth) {
        //not all doors are equaliy wide - turn on manual overlaps
        this.loadManualOverlaps();
        break;
      }
      doorWidth = door.width;
    }

    //Rails
    let railsFound = this.loadRails(dtoSection.RailItems);
    this.onlyDoor = !railsFound && this.doors.length > 0;
    if (this.onlyDoor) {
      this.cache.availableRailsets = undefined;
      this.railSet =
        Enumerable.from(this.availableRailsets).firstOrDefault() ?? null;
    }

    // Set the overlap width to the loaded value...
    this.overlapWidth = overlapWidth;
  }

  public saveTo(dtoSection: Interface_DTO.CabinetSection): void {
    try {
      for (let i = 0; i < this.doors.length; i++) {
        let door = this.doors[i];
        let doorItem = this.doorItems[i];
        if (!!door && !!doorItem) {
          doorItem.disableChangeTracking();
          doorItem.Width = Math.ceil(door.width);
          doorItem.enableChangeTracking();
        }
      }
    } catch (e: any) {
      //do nothing for now
    }
    dtoSection.DoorItems = this.doorItems.map((i) => i.save());
    dtoSection.RailItems = this.railItems.map((i) => i.save());
  }

  private loadManualOverlaps() {
    this.overlaps = [];
    this.manualOverlapPositioning = true;
    this.mustResetOverlapPosition = false;
    for (let i = 1; i < this.doors.length; i++) {
      let leftDoor = this.doors[i - 1];
      let rightDoor = this.doors[i];
      let isReal = leftDoor.backZ !== rightDoor.backZ;
      let centerX = (leftDoor.rightX + rightDoor.leftX) / 2;
      let overlap = new DoorOverlap(centerX, this.overlapWidth, i, isReal);
      this.overlaps.push(overlap);
    }
  }

  public getAdjacentDoorOnSameTrack(door: Door, left: boolean): Door | null {
    let doorIndex = this.doors.indexOf(door);
    if (doorIndex < 0) {
      return null;
    }

    let track = this.doorSetup.railPositions[doorIndex];

    let index = doorIndex + (left ? -1 : 1);
    let condition = () => {
      return left ? index >= 0 : index < this.doorSetup.railPositions.length;
    };
    let step = () => {
      left ? index-- : index++;
    };

    for (index; condition(); step()) {
      let tempTrack = this.doorSetup.railPositions[index];
      if (tempTrack === track) {
        return this.doors[index] || null;
      }
    }

    return null;
  }

  public getOtherDoorsOnSameTrack(door: Door, left: boolean): Door[] {
    let otherDoors = new Array<Door>();

    let doorIndex = this.doors.indexOf(door);
    if (doorIndex >= 0) {
      let track = this.doorSetup.railPositions[doorIndex];
      let index = doorIndex + (left ? -1 : 1);
      let condition = () => {
        return left ? index >= 0 : index < this.doorSetup.railPositions.length;
      };
      let step = () => {
        left ? index-- : index++;
      };

      for (index; condition(); step()) {
        let tempTrack = this.doorSetup.railPositions[index];
        if (tempTrack === track) {
          otherDoors.push(this.doors[index]);
        }
      }
    }

    return otherDoors;
  }

  //#endregion Public functions

  //#region Private helper functions

  //#region Load

  /**
   * Loads the door profile, and sets values, that are common for all doors
   * @param doorItem
   * @param doorProduct
   */
  private loadProfile(
    doorItem: Interface_DTO.ConfigurationItem,
    doorProduct: Product,
  ) {
    this.setProfile(doorProduct, false);
  }

  /**
   * Loads the profile material
   * @param doorItem
   * @param doorProduct
   */
  private loadProfileMaterial(
    doorItem: Interface_DTO.ConfigurationItem,
    doorProduct: Product,
  ) {
    let frameColor = ConfigurationItemHelper.getFrameColorNumber(
      doorItem,
      doorProduct,
      this.cabinetSection.cabinet.ProductLineId,
    );
    if (frameColor) {
      let foundMaterial = Enumerable.from(
        this.editorAssets.materials,
      ).firstOrDefault(
        (mat) =>
          mat.Number === frameColor &&
          mat.Type === Interface_Enums.MaterialType.Frame,
      );
      this.profileMaterial = foundMaterial ?? null;
    }
  }

  /**
   * Loads the bar height
   * @param doorItem
   * @param doorProduct
   */
  private loadBarHeight(
    doorItem: Interface_DTO.ConfigurationItem,
    doorProduct: Product,
  ) {
    this._barHeight = ConfigurationItemHelper.getBarHeight(
      doorItem,
      doorProduct,
      this.cabinetSection.cabinet.ProductLineId,
    );
  }

  /**
   * Loads grips and their placement height
   * @param doorItem
   * @param doorProduct
   */
  private loadGripTypeAndPlacement(
    doorItem: Interface_DTO.ConfigurationItem,
    doorProduct: Product,
  ) {
    // Grip type
    var gripMap = this.editorAssets.doorGripMap[doorProduct.Id];
    if (gripMap) {
      // Grips are available for the door product
      var gripNumber = ConfigurationItemHelper.getGripVariantOptionNumber(
        doorItem,
        doorProduct,
        this.cabinetSection.cabinet.ProductLineId,
      );
      var gripProductId = gripMap[gripNumber];
      this.grip = this.editorAssets.productsDict[gripProductId];
    }

    // Grip placement height
    if (this.grip) {
      this.gripPlacementHeight = ConfigurationItemHelper.getGripPlacementHeight(
        doorItem,
        doorProduct,
        this.cabinetSection.cabinet.ProductLineId,
      );
    }
  }

  /**
   * Loads railset and materials for top and bottom rail
   * @param items The potential railset items
   */
  private loadRails(items: Interface_DTO.ConfigurationItem[]): boolean {
    let railsetFound = false;
    let materialTopFound = false;
    let materialBottomFound = false;

    let railsetCandidates = this.availableRailsets
      .filter(
        (rs) => items.filter((i) => i.ProductId === rs.TopProductId).length > 0,
      )
      .filter(
        (rs) =>
          items.filter((i) => i.ProductId === rs.BottomProductId).length > 0,
      );
    if (railsetCandidates.length > 1) {
      console.warn(
        'More than one possible railset',
        items,
        this.availableRailsets,
      );
    }

    let railSet: RailSet | undefined = railsetCandidates[0];
    if (railSet) {
      this.railSet = railSet;
      railsetFound = true;
      let bottomRail: Interface_DTO.ConfigurationItem | undefined =
        items.filter((i) => i.ProductId === railSet!.BottomProductId)[0];
      if (bottomRail) {
        this.railMaterialBottom =
          this.editorAssets.materialsDict[bottomRail.MaterialId];
        materialBottomFound = true;
      }

      let topRail: Interface_DTO.ConfigurationItem | undefined = items.filter(
        (i) => i.ProductId === railSet!.TopProductId,
      )[0];
      if (topRail) {
        this.railMaterialTop =
          this.editorAssets.materialsDict[topRail.MaterialId];
        materialTopFound = true;
      }
    }
    if (railsetFound && materialTopFound && materialBottomFound) {
      // We are done
      return true;
    }

    // If we get here, we did not find everything, so we may need to set some default values
    if (
      !railsetFound &&
      this.cabinetSection.CabinetType !== Interface_Enums.CabinetType.Doors
    ) {
      // Set default railset
      let defaultRailSet = this.availableRailsets[0];
      if (defaultRailSet) {
        this.railSet = defaultRailSet;
        railsetFound = true;
      }
    }

    if (railsetFound) {
      if (!materialTopFound) {
        this.railMaterialTop =
          Enumerable.from(this.availableRailTopMaterials).firstOrDefault() ??
          null;
      }
      if (!materialBottomFound) {
        this.railMaterialBottom =
          Enumerable.from(this.availableRailBottomMaterials).firstOrDefault() ??
          null;
      }
    }

    return railsetFound;
  }

  // Added as a work-around because variant values for softclose has
  // not previously been set correctly
  private allSoftCloseProductIds = [349, 1055, 1183, 1184, 1185, 1186, 1187];

  /**
   * Loads soft close and position stops
   * @param items The potential soft close and position stop items
   */
  private loadSoftCloseAndPositionStops(
    items: Interface_DTO.ConfigurationItem[],
  ) {
    if (this.profile) {
      let softClosefound = false;
      let positionStopFound = false;
      if (this.doorData) {
        let softCloseProductIds = this.doorData.SoftCloseProducts.map(
          (scp) => scp.ProductId,
        );

        for (let item of items) {
          if (softCloseProductIds.indexOf(item.ProductId) >= 0) {
            softClosefound = true;
            this.addSoftClose = true;
            this._numberOfSoftClose = item.Quantity;
          } else if (this.doorData.PositionStopProductId === item.ProductId) {
            positionStopFound = true;
            this.addSoftClose = true;
            this._numberOfPositionStops = item.Quantity;
          } else if (this.doors.some((door) => door.hasSoftClose())) {
            softClosefound = true;
            this.addSoftClose = true;
          } else {
            if (this.allSoftCloseProductIds.indexOf(item.ProductId) >= 0) {
              softClosefound = true;
              this.addSoftClose = true;
              this._numberOfSoftClose = item.Quantity;
            }
          }

          if (softClosefound && positionStopFound) {
            // We are done...
            return;
          }
        }
      }
    }
  }

  //#endregion Load

  private getAvailableDoorRailSets(doorProduct: Product): RailSet[] {
    // Find railsets for the product.
    let railSets = this.onlyDoor
      ? [this.getDummyRailset()]
      : this.editorAssets.railSets.filter((set) =>
          set.isValidForDoorProduct(doorProduct),
        );

    // Rails with one track are only valid for doors only configurations
    if (this.cabinetSection.CabinetType !== Interface_Enums.CabinetType.Doors) {
      railSets = railSets.filter((set) => {
        return set.NumberOfTracks > 1;
      });
    }

    return railSets;
  }

  private getDummyRailset(): RailSet {
    let dummyDto: Interface_DTO.DoorRailSet = {
      Id: 0,
      DefaultName: 'No rail',
      NumberOfTracks: 1,
      Type: 0,
      ProductId: 0,
      DoorReductionTop: 0,
      DoorReductionBottom: 0,
      TopProductId: null,
      BottomProductId: null,
    };

    let dummyRailset = new RailSet(dummyDto, null, null);
    return dummyRailset;
  }

  private getAvailableNumberOfDoors(): number[] {
    return this.profile !== null
      ? Enumerable.from(this.validDoorSetups)
          .select((ds) => ds.doors)
          .distinct()
          .orderBy((ds) => ds)
          .toArray()
      : [0];
  }

  private getOptimalDoorSetup(): DoorSetup | undefined {
    if (this.profile === null) {
      return new DoorSetup([]);
    }

    if (this.cache.optimalDoorSetup === undefined) {
      let validDoorSetups = Enumerable.from(this.validDoorSetups);
      let prioritizedDoorSetups = Enumerable.from(this.availableNumberOfDoors)
        .select((num) => validDoorSetups.first((ds) => ds.doors == num))
        .toArray();
      let minDifference = Number.MAX_VALUE;
      let onlyNegative = false;

      for (let doorSetup of prioritizedDoorSetups) {
        let totalOverlapWidth = doorSetup.overlaps * this.overlapWidth;
        let doorWidth =
          (CabinetSectionHelper.getSightWidth(this.cabinetSection) +
            totalOverlapWidth) /
          doorSetup.doors;
        let difference = doorWidth - Constants.optimalDoorWidth;

        // Door widths below the optimal width is preferred
        // If any valid door setup makes for doors not wider than optimal width, we only accept doors not wider than optimal width.
        if (difference < 0) {
          if (!onlyNegative) {
            minDifference = Number.MAX_VALUE;
            onlyNegative = true;
          }
          difference *= -1;
        } else if (onlyNegative) {
          continue;
        }

        if (difference < minDifference) {
          minDifference = difference;
          this.cache.optimalDoorSetup = doorSetup;
        }
      }
    }

    return this.cache.optimalDoorSetup;
  }

  private getAvailableNumberOfOverlaps(): number[] {
    return Enumerable.from(this.validDoorSetups)
      .where(
        (ds) =>
          ds.rails === this.numberOfRailTracks &&
          ds.doors === this.numberOfDoors,
      )
      .select((ds) => ds.overlaps)
      .distinct()
      .orderBy((ds) => ds)
      .toArray();
  }

  //#endregion Private helper functions

  //#region BarDesign

  private static readonly _barDesigns = Client.BarDesign.getDefaultDesigns();
  public get availableBarDesigns(): Client.BarDesign[] {
    return Doors._barDesigns;
  }

  public setBarDesign(design: Client.BarDesign) {
    for (let door of this.doors) {
      door.setBarDesign(design);
    }
  }

  //#endregion BarDesign
}
