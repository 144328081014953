import { Component, Inject } from '@angular/core';
import * as ClientSetting from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { ClientSettingInjector } from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';

@Component({
  selector: 'd3-vm',
  templateUrl: './d3.html',
  styleUrls: ['../../style/editor.scss', '../../style/d3.scss'],
  providers: [ClientSetting.clientSettingProvider, DeliveryAddressService],
})
export class D3Vm extends EditorVm {
  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    @Inject(ClientSettingInjector)
    private clientSettings: ClientSetting.ClientSetting,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );

    super.ensureUnsubscribe(
      clientSettings.subscribe((settings) => {
        this.d3ClientSetting = settings.d3;
      }),
    );
  }

  private d3ClientSetting: ClientSetting.D3Setting | undefined;

  setD3ClientSetting(setting: Partial<ClientSetting.D3Setting>) {
    if (this.d3ClientSetting) {
      this.d3ClientSetting = {
        ...this.d3ClientSetting,
        ...setting,
      };
      const allSettings = {
        ...this.clientSettings.data,
      };
      allSettings.d3 = this.d3ClientSetting;

      this.clientSettings.set(allSettings);
    }
  }

  public get showInterior(): boolean {
    return this.d3ClientSetting ? this.d3ClientSetting.displayInterior : false;
  }
  public set showInterior(val: boolean) {
    this.setD3ClientSetting({ displayInterior: val });
  }
  public get showDoors(): boolean {
    return this.d3ClientSetting ? this.d3ClientSetting.displayDoors : false;
  }
  public set showDoors(val: boolean) {
    this.setD3ClientSetting({ displayDoors: val });
  }
  public get showCorpus(): boolean {
    return this.d3ClientSetting ? this.d3ClientSetting.displayCorpus : false;
  }
  public set showCorpus(val: boolean) {
    this.setD3ClientSetting({ displayCorpus: val });
  }
  public get showOtherCabinets() {
    return this.d3ClientSetting
      ? this.d3ClientSetting.displayOtherCabinets
      : false;
  }
  public set showOtherCabinets(val: boolean) {
    this.setD3ClientSetting({ displayOtherCabinets: val });
  }

  ///Called from EditorVm when the underlying floorplan has changed
  protected floorPlanChanged() {}
}
