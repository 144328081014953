import Enumerable from 'linq';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from '@ClientDto/index';
import { SubSection } from '@ClientDto/SubSection';
import { Product } from '@ClientDto/Product';
import * as Dto from '../Interface_DTO';
import { Pickable } from '@Interfaces/Pickable';
import { ObjectHelper } from '@Util/ObjectHelper';
import { ProductHelper } from '@Util/ProductHelper';
import { MaterialHelper } from '@Util/MaterialHelper';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { ProductLineProperties } from '../Interface_DTO_ProductLineProperties';
import { CabinetSectionProperties } from '../properties/CabinetSectionProperties';
import { PartialDeep } from '@Interfaces/PartialDeep';
import * as ProductLineConstants from 'app/ts/ProductLineConstants';

export class SwingFlex implements SubSection {
  // Private variables
  //#region

  private static readonly itemTypeOrder: Readonly<Interface_Enums.ItemType[]> =
    [
      Interface_Enums.ItemType.SwingFlexDoor,
      Interface_Enums.ItemType.SwingFlexCorpus,
      Interface_Enums.ItemType.SwingFlexBacking,
    ];

  private static readonly ownedItemTypes: Readonly<Interface_Enums.ItemType[]> =
    [
      Interface_Enums.ItemType.SwingFlexCorpus,
      Interface_Enums.ItemType.SwingFlexCorpusMovable,
      Interface_Enums.ItemType.SwingFlexBacking,
      Interface_Enums.ItemType.SwingFlexDoor,
    ];

  private cache: {
    productLineProperties?: ProductLineProperties;

    corpusProducts?: Enumerable.IEnumerable<Client.Product>;
    doorProducts?: Enumerable.IEnumerable<Client.Product>;
    backingProducts?: Enumerable.IEnumerable<Client.Product>;

    pickableCorpusMaterials?: Pickable<Client.Material>[];
    pickableDoorMaterials?: Pickable<Client.Material>[];
    pickableBackingMaterials?: Pickable<Client.Material>[];

    availableDoorGrips?: Client.Product[];

    pickableGripsDoorsAndDrawers?: Pickable<Client.Product | null>[];

    pickableDoorGrips?: Pickable<
      Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
    >[];
    pickableDoorGripMaterials?: Pickable<Client.Material>[];

    pickableDrawerGrips?: Pickable<Client.Product | null>[];
    pickableDrawerGripMaterials?: Pickable<Client.Material>[];

    items?: Client.ConfigurationItem[];

    drillingPattern?: Interface_Enums.DrillingPattern;
  } = {};

  private _doorGrip: Client.Product | null = null;
  private _drawerGrip: Client.Product | null = null;
  private _doorOpeningMethod: Enums.SwingFlexOpeningMethod =
    Enums.SwingFlexOpeningMethod.PushToOpen;

  private _areas: Client.SwingFlexArea[] = [];

  private _corpusMaterial: Client.Material | null = null;
  private _doorMaterial: Client.Material | null = null;
  private _backingMaterial: Client.Material | null = null;
  private _doorGripMaterial: Client.Material | null = null;
  private _drawerGripMaterial: Client.Material | null = null;

  private _desiredNumberOffAreas: number = 1;

  private _plinth: boolean = false;
  private _doorCoversPlinth: boolean = false;
  private _corpusCoversDoors: boolean = true;

  //#endregion

  constructor(public readonly cabinetSection: Client.CabinetSection) {}

  // Public properties
  //#region

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  public get productLineProperties(): ProductLineProperties {
    if (!this.cache.productLineProperties) {
      let productLine = Enumerable.from(this.editorAssets.productLines).single(
        (pl) => pl.Id === this.cabinetSection.cabinet.ProductLineId,
      );

      this.cache.productLineProperties =
        productLine?.Properties ??
        ProductLineConstants.defaultProductLineProperties;
    }

    return this.cache.productLineProperties;
  }

  rightGableInfo: CabinetSectionProperties.SwingFlexGableInfo = {
    ...CabinetSectionProperties.SwingFlexGableInfo.defaultValue,
  };
  rightGableItem?: Client.ConfigurationItem;

  public get depth(): number {
    const doorDepth = this.corpusCoversDoors
      ? this.getDoorDepth({ includeSpacingBehindDoors: true })
      : 0;

    return this.cabinetSection.Depth - doorDepth;
  }
  public set depth(val: number) {
    const doorDepth = this.corpusCoversDoors
      ? this.getDoorDepth({ includeSpacingBehindDoors: true })
      : 0;
    this.cabinetSection.Depth = val + doorDepth;
  }

  public get corpusProducts(): Enumerable.IEnumerable<Client.Product> {
    if (!this.cache.corpusProducts) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.corpusProducts = Enumerable.from(
        this.editorAssets.products,
      ).where(
        (p) =>
          (p.ProductType === Interface_Enums.ProductType.SwingCorpus ||
            (p.ProductType === Interface_Enums.ProductType.Shelf &&
              ProductHelper.isProductDataType(
                Interface_Enums.ProductDataType.SwingFlexDoorBreaker,
                p,
              ))) &&
          p.ProductLineIds.some(
            (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
          ) &&
          (fullCatalog || !p.overrideChain),
      );
    }
    return this.cache.corpusProducts;
  }

  public get doorProducts(): Enumerable.IEnumerable<Client.Product> {
    if (this.cache.doorProducts === undefined) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.doorProducts = Enumerable.from(
        this.editorAssets.products,
      ).where(
        (p) =>
          p.ProductType === Interface_Enums.ProductType.SwingDoor &&
          p.ProductLineIds.some(
            (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
          ) &&
          (fullCatalog || !p.overrideChain),
      );
    }
    return this.cache.doorProducts;
  }

  public get backingProducts(): Enumerable.IEnumerable<Client.Product> {
    if (this.cache.backingProducts === undefined) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.backingProducts = Enumerable.from(
        this.editorAssets.products,
      ).where(
        (p) =>
          p.ProductType === Interface_Enums.ProductType.Backing &&
          p.ProductLineIds.some(
            (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
          ) &&
          (fullCatalog || !p.overrideChain),
      );
    }
    return this.cache.backingProducts;
  }

  public get areas(): Client.SwingFlexArea[] {
    return this._areas;
  }
  public get subAreas(): Client.SwingFlexSubArea[] {
    return this.areas.flatMap((area) => area.subAreas);
  }

  public get items(): Client.ConfigurationItem[] {
    if (!this.cache.items) {
      this.cache.items = Enumerable.from(this.areas)
        .selectMany((area) =>
          area.items.concat(area.areaSplitItems).concat(area.gripItems),
        )
        .orderBy((item) => SwingFlex.itemTypeOrder.indexOf(item.ItemType))
        .thenByDescending((item) => item.topY)
        .thenBy((item) => item.X)
        .toArray();
    }
    return this.cache.items;
  }

  public get availableDoorGrips(): Client.Product[] {
    if (this.cache.availableDoorGrips === undefined) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.availableDoorGrips = Enumerable.from(
        this.editorAssets.products,
      )
        .where(
          (p) =>
            p.ProductType === Interface_Enums.ProductType.Grip &&
            p.ProductLineIds.some(
              (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
            ) &&
            (fullCatalog || !p.overrideChain) &&
            ProductHelper.isGripForDoor(p),
        )
        .toArray();
    }

    return this.cache.availableDoorGrips;
  }

  public get pickableDoorGrips(): Pickable<
    Client.Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
  >[] {
    if (this.cache.pickableDoorGrips === undefined) {
      const actualGrips: Pickable<
        Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
      >[] = this.availableDoorGrips
        .map((product) => {
          let pickable: Pickable<Product> = {
            ...ProductHelper.getPickable(product),
            isSelected: this.doorGrip ? this.doorGrip.Id === product.Id : false,
            disabledReason: null,
            groupName: this.editorAssets.translationService.translate(
              'pickable_grip_group',
              'Grips',
            ),
          };

          return pickable;
        })
        .filter((pp) => pp !== undefined);

      let noGripPickable = { ...this.cabinetSection.interior.noGripPickable };
      noGripPickable.overlay = this.editorAssets.translationService.translate(
        'editor_swingFlex_door_grip_overlay',
        'Door Grip Material',
      );

      const noneGrips: Pickable<
        Product | Enums.SwingFlexOpeningMethod.PushToOpen | null
      >[] = [noGripPickable, this.pushToOpenPickable];

      this.cache.pickableDoorGrips = noneGrips.concat(actualGrips);
    }
    return this.cache.pickableDoorGrips;
  }

  public get pickableDrawerGrips(): Pickable<Client.Product | null>[] {
    if (this.cache.pickableDrawerGrips === undefined) {
      this.cache.pickableDrawerGrips =
        this.cabinetSection.interior.availableDrawerGrips
          .map<Pickable<Product>>((product) => {
            let pickable: Pickable<Product> = {
              ...ProductHelper.getPickable(product),
              isSelected: this.drawerGrip
                ? this.drawerGrip.Id === product.Id
                : false,
              disabledReason: null,
              groupName: this.editorAssets.translationService.translate(
                'pickable_grip_group',
                'Grips',
              ),
            };

            return pickable;
          })
          .filter((pp) => pp !== undefined);

      // Insert "None" Pickable at the start of the list
      let noGripPickable = { ...this.cabinetSection.interior.noGripPickable };
      noGripPickable.overlay = this.editorAssets.translationService.translate(
        'editor_swingFlex_drawer_grip_overlay',
        'Drawer Grip',
      );

      this.cache.pickableDrawerGrips.unshift(noGripPickable);
    }
    return this.cache.pickableDrawerGrips;
  }

  public get pushToOpenPickable(): Pickable<Enums.SwingFlexOpeningMethod.PushToOpen> {
    const pto = Enums.SwingFlexOpeningMethod.PushToOpen;
    return {
      name: this.editorAssets.translationService.translate(
        'editor_swingflex_opening_method_' + pto,
        pto,
      ),
      disabledReason: null,
      groupName: this.editorAssets.translationService.translate(
        'pickable_grip_group_none',
        'No Grip',
      ),
      isSelected: false,
      item: pto,
      override: false,
    };
  }

  public get doorGrip(): Client.Product | null {
    return this._doorGrip;
  }
  public set doorGrip(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this._doorGrip)) {
      return;
    }
    this._doorGrip = value;
    this.cache.pickableDoorGripMaterials = undefined;
    this.doorGripMaterial = value?.materials[0] ?? null;
    this.subAreas.forEach((subArea) => (subArea.doorGrip = this._doorGrip));
  }

  public get drawerGrip(): Client.Product | null {
    return this._drawerGrip;
  }
  public set drawerGrip(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this._drawerGrip)) {
      return;
    }

    this._drawerGrip = value;
    this.cache.pickableDrawerGripMaterials = undefined;
    this.areas.forEach((area) => area.setDrawerGripProduct(undefined, value));
  }

  public get doorOpeningMethod(): Enums.SwingFlexOpeningMethod {
    return this._doorOpeningMethod;
  }
  public set doorOpeningMethod(value: Enums.SwingFlexOpeningMethod) {
    this._doorOpeningMethod = value;
    for (const subArea of this.subAreas) {
      subArea.openingMethod = value;
    }
  }

  public getPickableDrawerGripMaterials(
    gripProduct: Client.Product | null,
    currentMaterial: Client.Material | null,
  ): Pickable<Client.Material>[] {
    let fullCatalog =
      this.editorAssets.fullCatalog &&
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    let gripProducts: Client.Product[] = [];
    if (gripProduct) gripProducts.push(gripProduct);

    let gripPickablesEnumerable: Enumerable.IEnumerable<
      Pickable<Client.Material>
    > = this.getMaterialPickables(gripProducts, currentMaterial, fullCatalog);

    if (!fullCatalog) {
      gripPickablesEnumerable = gripPickablesEnumerable.where(
        (pickableMat) => pickableMat.isSelected || !pickableMat.override,
      );
    }

    let pickableGripMaterials = gripPickablesEnumerable.toArray();
    MaterialHelper.sortPickableMaterials(pickableGripMaterials);
    return pickableGripMaterials;
  }

  public getPickableGripMaterials(
    gripProduct: Client.Product | null,
    currentMaterial: Client.Material | null,
  ): Pickable<Client.Material>[] {
    let fullCatalog =
      this.editorAssets.fullCatalog &&
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    let gripProducts: Client.Product[] = [];
    if (gripProduct != null) gripProducts.push(gripProduct);

    let gripPickablesEnumerable: Enumerable.IEnumerable<
      Pickable<Client.Material>
    > = this.getMaterialPickables(gripProducts, currentMaterial, fullCatalog);

    if (!fullCatalog) {
      gripPickablesEnumerable = gripPickablesEnumerable.where(
        (pickableMat) => pickableMat.isSelected || !pickableMat.override,
      );
    }

    let pickableGripMaterials = gripPickablesEnumerable.toArray();
    MaterialHelper.sortPickableMaterials(pickableGripMaterials);
    return pickableGripMaterials;
  }

  public get pickableCorpusMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableCorpusMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let corpusPickablesEnumerable: Enumerable.IEnumerable<
        Pickable<Client.Material>
      > = this.getMaterialPickables(
        this.corpusProducts.toArray(),
        this.corpusMaterial,
        fullCatalog,
      );

      if (!fullCatalog) {
        corpusPickablesEnumerable = corpusPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }

      this.cache.pickableCorpusMaterials = corpusPickablesEnumerable.toArray();
      MaterialHelper.sortPickableMaterials(this.cache.pickableCorpusMaterials);
    }
    return this.cache.pickableCorpusMaterials;
  }

  public get corpusMaterial(): Client.Material | null {
    return this._corpusMaterial;
  }
  public set corpusMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this.corpusMaterial)) {
      return;
    }

    this._corpusMaterial = value;

    // Set material on shelves
    if (!!value) {
      this.items
        .filter((item) => item.isShelf)
        .forEach((shelf) => (shelf.MaterialId = value.Id));
    }
  }

  public get pickableDoorMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableDoorMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let doorProducts = this.editorAssets.products.filter(
        (p) => p.ProductType === Interface_Enums.ProductType.SwingDoor,
      );

      let doorPickablesEnumerable: Enumerable.IEnumerable<
        Pickable<Client.Material>
      > = this.getMaterialPickables(
        doorProducts,
        this.doorMaterial,
        fullCatalog,
      );

      if (!fullCatalog) {
        doorPickablesEnumerable = doorPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }

      this.cache.pickableDoorMaterials = doorPickablesEnumerable.toArray();
      MaterialHelper.sortPickableMaterials(this.cache.pickableDoorMaterials);
    }
    return this.cache.pickableDoorMaterials;
  }

  public get doorMaterial(): Client.Material | null {
    return this._doorMaterial;
  }
  public setDoorMaterial(
    value: Client.Material | null,
    applyToChildren: boolean,
  ) {
    this._doorMaterial = value;

    if (!applyToChildren) return;

    for (const area of this.areas) {
      for (const subArea of area.subAreas) {
        subArea.doorMaterial = value;
      }
    }

    // Set material on drawers
    if (!!value) {
      this.items
        .filter((item) => item.isPullout)
        .forEach((drawer) => (drawer.MaterialId = value.Id));
    }
  }

  public get pickableDoorGripMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableDrawerGripMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let gripProducts: Client.Product[] = [];
      if (this.doorGrip != null) gripProducts.push(this.doorGrip);

      let gripPickablesEnumerable: Enumerable.IEnumerable<
        Pickable<Client.Material>
      > = this.getMaterialPickables(
        gripProducts,
        this.doorGripMaterial,
        fullCatalog,
      );

      if (!fullCatalog) {
        gripPickablesEnumerable = gripPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }

      this.cache.pickableDrawerGripMaterials =
        gripPickablesEnumerable.toArray();
      MaterialHelper.sortPickableMaterials(
        this.cache.pickableDrawerGripMaterials,
      );
    }
    return this.cache.pickableDrawerGripMaterials;
  }

  public get pickableDrawerGripMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableDrawerGripMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let gripProducts: Client.Product[] = [];
      if (this.drawerGrip != null) gripProducts.push(this.drawerGrip);

      let gripPickablesEnumerable: Enumerable.IEnumerable<
        Pickable<Client.Material>
      > = this.getMaterialPickables(
        gripProducts,
        this.drawerGripMaterial,
        fullCatalog,
      );

      if (!fullCatalog) {
        gripPickablesEnumerable = gripPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }

      this.cache.pickableDrawerGripMaterials =
        gripPickablesEnumerable.toArray();
      MaterialHelper.sortPickableMaterials(
        this.cache.pickableDrawerGripMaterials,
      );
    }
    return this.cache.pickableDrawerGripMaterials;
  }

  public get doorGripMaterial(): Client.Material | null {
    return this._doorGripMaterial;
  }
  public set doorGripMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._doorGripMaterial)) {
      return;
    }

    this._doorGripMaterial = value;
    this.subAreas.forEach(
      (subArea) => (subArea.doorGripMaterial = this._doorGripMaterial),
    );
  }
  public get drawerGripMaterial(): Client.Material | null {
    return this._drawerGripMaterial;
  }
  public set drawerGripMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._drawerGripMaterial)) {
      return;
    }

    this._drawerGripMaterial = value;
    this.areas.forEach((area) => area.setDrawerGripMaterial(undefined, value));
  }

  public get pickableBackingMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableBackingMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let backingProducts = this.editorAssets.products.filter(
        (p) =>
          p.ProductLineIds.some(
            (plid) => plid === this.cabinetSection.cabinet.ProductLineId,
          ) && p.ProductType === Interface_Enums.ProductType.Backing,
      );

      let backingPickablesEnumerable = this.getMaterialPickables(
        backingProducts,
        this.backingMaterial,
        fullCatalog,
      );

      if (!fullCatalog) {
        backingPickablesEnumerable = backingPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }

      this.cache.pickableBackingMaterials =
        backingPickablesEnumerable.toArray();
      MaterialHelper.sortPickableMaterials(this.cache.pickableBackingMaterials);
    }
    return this.cache.pickableBackingMaterials;
  }

  public get backingMaterial(): Client.Material | null {
    return this._backingMaterial;
  }
  public set backingMaterial(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this._backingMaterial)) {
      return;
    }

    this._backingMaterial = value;
  }

  public get numberOfAreas() {
    return this.areas.length;
  }
  public set numberOfAreas(val: number) {
    this._desiredNumberOffAreas = val;
  }

  public get desiredNumberOfAreas() {
    return this._desiredNumberOffAreas;
  }

  public get plinth(): boolean {
    return this._plinth;
  }
  public set plinth(value: boolean) {
    if (value !== this._plinth) {
      this.plinthChanged = true;
    }
    this._plinth = value;
  }

  public plinthChanged = false;

  public get doorCoversPlinth(): boolean {
    return this._doorCoversPlinth;
  }
  public set doorCoversPlinth(value: boolean) {
    this._doorCoversPlinth = value;
  }

  public get corpusCoversDoors(): boolean {
    return this._corpusCoversDoors;
  }
  public set corpusCoversDoors(value: boolean) {
    // try to maintain swingFlex depth while changing this property
    const oldSwingFlexDepth = this.depth;
    this._corpusCoversDoors = value;
    this.depth = oldSwingFlexDepth;
  }

  //#endregion

  // Private functions
  //#region

  private static isSwingFlexItem(item: Dto.ConfigurationItem): boolean {
    return this.ownedItemTypes.indexOf(item.ItemType) >= 0;
  }

  private getMaterialPickables(
    products: Client.Product[],
    material: Client.Material | null,
    fullCatalog: boolean,
  ): Enumerable.IEnumerable<Pickable<Client.Material>> {
    return Enumerable.from(products)
      .where((product) => !!product)
      .where((product) =>
        product.ProductLineIds.some(
          (plid) => plid === this.cabinetSection.cabinet.ProductLineId,
        ),
      )
      .where((product) =>
        product.PossibleMaterialIds.some(
          (pm) => pm.IsEnabled && (fullCatalog || !pm.IsOverride),
        ),
      )
      .selectMany((product) => product.PossibleMaterialIds)
      .where((matId) => matId.IsEnabled)
      .groupBy((matId) => matId.Id)
      .select((matGrp) => ({
        ...MaterialHelper.getPickable(
          this.editorAssets.materialsDict[matGrp.key()],
          undefined,
          matGrp.any((matId) => matId.IsOverride || !matId.IsEnabled),
        ),
        isSelected: material ? matGrp.key() === material.Id : false,
      }));
  }

  //#endregion

  // Public functions
  //#region

  public getDrillStops(
    startOffset: number,
    maxHeight: number,
    minHeight = 0,
  ): number[] {
    const productLine = this.cabinetSection.cabinet.productLine;
    const startY = productLine.GableDrillingStart + startOffset;

    const result: number[] = [];

    for (
      let y = startY;
      y <= maxHeight;
      y += productLine.GableDrillingSpacing
    ) {
      if (y > minHeight) result.push(y);
    }

    return result;
  }

  public clearBackingVariables() {
    this.cache = {};
  }

  public loadFrom(
    dtoSection: Dto.CabinetSection,
    properties: PartialDeep<CabinetSectionProperties>,
  ): void {
    if (properties.swingFlex) {
      this._corpusMaterial = properties.swingFlex.corpusMaterialId
        ? (this.pickableCorpusMaterials
            .map((pickable) => pickable.item)
            .find(
              (corpusMaterial) =>
                corpusMaterial.Id === properties.swingFlex?.corpusMaterialId,
            ) ?? null)
        : null;

      this._doorMaterial = properties.swingFlex.doorMaterialId
        ? (this.pickableDoorMaterials
            .map((pickable) => pickable.item)
            .find(
              (doorMaterial) =>
                doorMaterial.Id === properties.swingFlex?.doorMaterialId,
            ) ?? null)
        : null;

      this._backingMaterial = properties.swingFlex.backingMaterialId
        ? (this.pickableBackingMaterials
            .map((pickable) => pickable.item)
            .find(
              (backingMaterial) =>
                backingMaterial.Id === properties.swingFlex?.backingMaterialId,
            ) ?? null)
        : null;

      this._doorGrip = properties.swingFlex.doorGripId
        ? (this.availableDoorGrips.find(
            (grip) => grip?.Id === properties.swingFlex?.doorGripId,
          ) ?? null)
        : null;

      this._doorGripMaterial = properties.swingFlex.doorGripMaterialId
        ? (this.pickableDoorGripMaterials
            .map((p) => p.item)
            .find(
              (mat) => mat?.Id === properties.swingFlex?.doorGripMaterialId,
            ) ?? null)
        : null;

      this._doorOpeningMethod =
        properties.swingFlex.doorOpeningMethod ??
        Enums.SwingFlexOpeningMethod.PushToOpen;

      this._drawerGrip = properties.swingFlex.drawerGripId
        ? (this.pickableDrawerGrips
            .map((pickable) => pickable.item)
            .find((grip) => grip?.Id === properties.swingFlex?.drawerGripId) ??
          null)
        : null;

      this.cache.pickableDrawerGripMaterials = undefined;
      this._drawerGripMaterial = properties.swingFlex.drawerGripMaterialId
        ? (this.pickableDrawerGripMaterials
            .map((pickable) => pickable.item)
            .find(
              (mat) => mat?.Id === properties.swingFlex?.drawerGripMaterialId,
            ) ?? null)
        : null;

      this.rightGableInfo = properties.swingFlex
        ? { ...properties.swingFlex.rightGable }
        : { ...CabinetSectionProperties.SwingFlexGableInfo.defaultValue };

      this._corpusCoversDoors = properties.swingFlex.corpusCoversDoors;

      this._plinth = properties.swingFlex.plinth ?? false;
      this._doorCoversPlinth = properties.swingFlex.doorCoversPlinth ?? false;

      if (properties.swingFlex.areas) {
        this._desiredNumberOffAreas = properties.swingFlex.areas.length;
        let swingFlexItems = dtoSection.ConfigurationItems.filter((i) =>
          SwingFlex.isSwingFlexItem(i),
        ).map((i) => new Client.ConfigurationItem(i, this.cabinetSection));
        this._areas = properties.swingFlex.areas.map((sfa, index) => {
          let areaItems = swingFlexItems.filter(
            (i) => i.swingFlexAreaIndex === index,
          );
          return new Client.SwingFlexArea(
            this.cabinetSection,
            index,
            areaItems,
          );
        });
      }
    }
  }

  public saveTo(
    dtoSection: Dto.CabinetSection,
    properties: PartialDeep<CabinetSectionProperties>,
  ): void {
    if (
      this.cabinetSection.CabinetType !== Interface_Enums.CabinetType.SwingFlex
    ) {
      properties.swingFlex = undefined;
      return;
    }

    dtoSection.ConfigurationItems = dtoSection.ConfigurationItems.filter(
      (i) => !SwingFlex.isSwingFlexItem(i),
    ).concat(this.items.map((i) => i.save()));
    properties.swingFlex = {
      areas: this.areas.map((a) => a.save()),
      corpusMaterialId: this.corpusMaterial?.Id ?? null,
      doorMaterialId: this.doorMaterial?.Id ?? null,
      backingMaterialId: this.backingMaterial?.Id ?? null,
      doorGripId: this.doorGrip?.Id ?? null,
      doorGripMaterialId: this.doorGripMaterial?.Id ?? null,
      doorOpeningMethod: this.doorOpeningMethod,
      drawerGripId: this.drawerGrip?.Id ?? null,
      drawerGripMaterialId: this.drawerGripMaterial?.Id ?? null,
      rightGable: { ...this.rightGableInfo },
      corpusCoversDoors: this._corpusCoversDoors,
      plinth: this._plinth,
      doorCoversPlinth: this._doorCoversPlinth,
    };
  }

  public getGableGaps(
    excludeItems: Client.ConfigurationItem[],
  ): Client.GableGap[] {
    let result: Client.GableGap[] = [];

    let includedItems = this.items.filter((i) => excludeItems.indexOf(i) < 0);

    const gables = includedItems
      .filter((i) => i.isGable)
      .sort((i1, i2) => i1.X - i2.X);

    if (gables.length < 1) {
      return [];
    }

    for (let i = 0; i < gables.length - 1; i++) {
      let leftGable = gables[i];
      let rightGable = gables[i + 1];

      let position = {
        X: leftGable.rightX,
        Y: leftGable.Y,
      };

      result.push({
        ...position,
        Width: rightGable.X - leftGable.rightX,
        Height: rightGable.Height,
        pulloutSeverity: Enums.PulloutWarningSeverity.None,
        leftGable: leftGable,
        rightGable: rightGable,
        drillStops: this.getDrillStops(
          leftGable.Y,
          Math.min(leftGable.Height, rightGable.Height) + leftGable.Y,
          leftGable.Y,
        ),
        startsAtBottom: true,
      });

      // Handle the space above a middle gable
      if (i > 0 && leftGable.Height < rightGable.Height) {
        const farLeftGable = gables[i - 1];

        const position = {
          X: farLeftGable.rightX,
          Y: farLeftGable.Y,
        };

        result.push({
          ...position,
          Width: rightGable.X - farLeftGable.rightX,
          Height: rightGable.Height,
          pulloutSeverity: Enums.PulloutWarningSeverity.None,
          leftGable: farLeftGable,
          rightGable: rightGable,
          drillStops: this.getDrillStops(
            farLeftGable.Y,
            Math.min(farLeftGable.Height, rightGable.Height) + farLeftGable.Y,
            leftGable.topY,
          ),
          startsAtBottom: false,
        });
      }
    }

    result = result.filter((r) => r.Width > 0 && r.Height > 0);

    return result;
  }

  public getCollidingCollisionAreas(
    item1: Client.ConfigurationItem,
    isMirrored: boolean,
    otherItems: Client.ItemCube[],
    item1AlternativePosition?: Interface_DTO_Draw.Cube,
  ): Client.Collision[] {
    return [];
  }

  public getAreaContainingItem(
    item: Client.ConfigurationItem,
  ): Client.SwingFlexArea | undefined {
    let area = this.areas.find(
      (area) =>
        area.items.includes(item) ||
        area.areaSplitItems.includes(item) ||
        area.gripItems.includes(item),
    );
    return area;
  }

  public getPlinthHeight(): number {
    const plinth = this.corpusProducts.firstOrDefault((p) =>
      ProductHelper.isPlinth(p, this.cabinetSection.cabinet.ProductLineId),
    );

    if (plinth) return ProductHelper.defaultHeight(plinth);

    return 0;
  }

  public getCorpusShelfHeight(): number {
    const shelf = this.corpusProducts.firstOrDefault((p) =>
      ProductHelper.isShelf(p, this.cabinetSection.cabinet.ProductLineId),
    );

    if (shelf) return ProductHelper.defaultHeight(shelf);

    return 0;
  }

  public getGableHeight(): number {
    let gableHeight = this.cabinetSection.SightHeight;
    if (this.cabinetSection.corpus.heightBottom <= 0)
      gableHeight -= this.productLineProperties.SwingFlexBottomOffset;
    return gableHeight;
  }

  public getGableWidth(): number {
    let gable = this.corpusProducts.firstOrDefault(
      (p) => ProductHelper.isGable(p) && !ProductHelper.isFittingPanel(p),
    );
    if (gable) return ProductHelper.defaultWidth(gable);

    return 0;
  }

  public getDoorDepth(
    opts: { includeSpacingBehindDoors?: boolean } = {},
  ): number {
    opts = {
      includeSpacingBehindDoors: false, //set defaults
      ...opts, //use any non-default values instead of the defaults
    };

    let doorProduct = this.doorProducts.firstOrDefault();
    if (doorProduct) {
      let result = ProductHelper.defaultDepth(
        doorProduct,
        this.cabinetSection.cabinet.ProductLineId,
      );
      if (opts.includeSpacingBehindDoors) {
        result += this.productLineProperties.SwingFlexSpaceBehindDoors;
      }
      return result;
    }
    return 0;
  }

  public getInteriorDepth(): number {
    return this.depth - this.productLineProperties.SwingFlexSpaceForBacking;
  }

  public getDrillingPattern(): Interface_Enums.DrillingPattern {
    if (!this.cache.drillingPattern) {
      const switchPoint =
        this.productLineProperties
          .SwingFlexSwithcDrillingPatternBelowInteriorDepth;

      if (this.getInteriorDepth() < switchPoint) {
        this.cache.drillingPattern = Interface_Enums.DrillingPattern._37_261;
      } else {
        this.cache.drillingPattern = Interface_Enums.DrillingPattern._37_453;
      }
    }

    return this.cache.drillingPattern;
  }

  public getGablePositionY(): number {
    let posY = this.cabinetSection.corpus.heightBottom;
    if (posY <= 0) posY += this.productLineProperties.SwingFlexBottomOffset;
    return posY;
  }

  public getAllowedOverlapY(
    currentItem: Client.ConfigurationItem,
    otherItem: Client.ConfigurationItem,
    currentItemY?: number,
  ): number {
    let thresholdY = 0;

    // External drawers may overlap shelves a bit.
    if (otherItem.isPullout != currentItem.isPullout) {
      // Drawers may overlap top, bottom and movable shelves by different amounts.
      // For movable shelves, the overlap depends on whether the shelf is above or below the drawer.

      if (currentItemY === undefined) {
        currentItemY = currentItem.Y;
      }

      if (
        currentItem.isPullout &&
        currentItem.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable
      ) {
        if (
          otherItem.Product?.ProductGroupingNo ==
          this.productLineProperties.SwingFlexTopShelfProductGroupingNumber
        ) {
          // Top shelf
          thresholdY = this.productLineProperties.SwingFlexTopShelfOverlap;
        } else if (
          otherItem.Product?.ProductGroupingNo ==
          this.productLineProperties.SwingFlexBottomShelfProductGroupingNumber
        ) {
          // Bottom shelf
          thresholdY = this.productLineProperties.SwingFlexBottomShelfOverlap;
        } else {
          // Movable shelf
          if (currentItemY > otherItem.Y) {
            // Drawer above shelf
            thresholdY =
              this.productLineProperties.SwingFlexMovableShelfTopOverlap;
          } else {
            // Drawer below shelf
            thresholdY =
              this.productLineProperties.SwingFlexMovableShelfBottomOverlap;
          }
        }
      } else if (
        otherItem.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable
      ) {
        if (currentItemY > otherItem.Y) {
          // Shelf above drawer
          thresholdY =
            this.productLineProperties.SwingFlexMovableShelfBottomOverlap;
        } else {
          // Shelf below drawer
          thresholdY =
            this.productLineProperties.SwingFlexMovableShelfTopOverlap;
        }
      }
    }

    return thresholdY;
  }

  //#endregion
}
