import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/index';
import { IItemVariant } from 'app/ts/interfaces/IItemVariant';
export class ItemVariant implements Interface_DTO.ItemVariant, IItemVariant {
  constructor(
    public readonly dto: Interface_DTO.ItemVariant,
    public readonly variant?: Client.ProductVariant,
    private readonly onChange?: () => void,
  ) {}

  public get variantOption(): Interface_DTO.VariantOption | undefined {
    if (this.variant) {
      for (let opt of this.variant.VariantOptions) {
        if (opt.Id === this.dto.VariantOptionId) return opt;
      }
    }
    return undefined;
  }

  public set variantOption(opt: Interface_DTO.VariantOption | undefined) {
    if (opt) {
      this.dto.VariantOptionId = opt.Id;
      this.dto.ActualValue = opt.Number;
    }
    if (this.onChange) this.onChange();
  }

  public get ActualValue() {
    return this.dto.ActualValue;
  }

  public set ActualValue(val: string) {
    this.dto.ActualValue = val;
    if (this.onChange) this.onChange();
  }

  public get VariantId() {
    return this.dto.VariantId;
  }

  public get VariantOptionId() {
    return this.dto.VariantOptionId;
  }
  public set VariantOptionId(val: number) {
    this.dto.VariantOptionId = val;
    if (this.onChange) this.onChange();
  }

  public setVariantOptionIdSilent(val: number) {
    this.dto.VariantOptionId = val;
  }

  public get OnlyAdmin(): boolean {
    return (this.variant && this.variant.OnlyAdmin) || false;
  }
}
