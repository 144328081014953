import { Component, Input } from '@angular/core';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AddressService } from 'app/ts/services/AddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { LoginService } from 'app/ts/services/LoginService';
import { AddressEditVm } from 'app/ts/viewmodels/components/AddressEditVm';

@Component({
  selector: 'delivery-address-edit',
  templateUrl: 'deliveryAddressEdit.html',
  styleUrls: ['../../../../style/tables.scss', '../../../../style/modal.scss'],
})
export class DeliveryAddressEditVm extends AddressEditVm<Interface_DTO.DeliveryAddress> {
  public isEmailRequired = false;
  public readonly validPhoneRegex = DeliveryAddressService.validPhoneRegex;

  @Input()
  public notEnabled = false;

  @Input()
  public get detailObject(): Interface_DTO.DeliveryAddress {
    return this._detailObject;
  }
  public set detailObject(value: Interface_DTO.DeliveryAddress | null) {
    if (value == null) return;
    this._detailObject = value;
  }

  constructor(
    baseVmService: BaseVmService,
    addressService: AddressService,
    loginService: LoginService,
  ) {
    super(baseVmService, loginService, addressService);

    this.subscribeTo(loginService.salesChainSettings$, (settings) => {
      this.isEmailRequired =
        settings[
          Interface_Enums.SalesChainSettingKey.RequireEmailWhenOrdering
        ] == '1';
    });
  }

  public async countryChanged() {
    this.detailObject.PostalCode = '';
    this.detailObject.City = '';
  }
}
