import { Component, Input } from '@angular/core';
import { Module } from 'app/partition/module';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/index';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { FillingsViewFilling } from 'app/partition/fillings/filling';
import { ModalService } from 'app/ts/services/ModalService';
import { Pickable } from 'app/ts/interfaces';
import { FillingsViewModule } from 'app/partition/fillings/module';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { FillingsViewSection } from 'app/partition/fillings/section';
import { NotificationService } from 'app/ts/services/NotificationService';

/**
 * In this class selectedModule and selectedFillings is guaranteed to not be undefined
 */
@Component({
  selector: 'filling-property-sheet',
  templateUrl: './filling-property-sheet.component.html',
  styleUrls: ['./filling-property-sheet.component.scss'],
})
export class FillingPropertySheetComponent {
  @Input() selectedSection!: FillingsViewSection;
  @Input() selectedModule!: FillingsViewModule;
  @Input() floorPlan!: Client.FloorPlan;
  @Input() selectedFilling!: FillingsViewFilling;

  public selectedFillingMaterial: Pickable<Interface_DTO.Material | null>;

  constructor(
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly floorPlanService: FloorPlanService,
    private readonly notificationService: NotificationService,
  ) {
    this.selectedFillingMaterial =
      new Client.ConverterPickable<Client.Material | null>(
        () => this.selectedFilling.material,
        (mat) => {
          return mat
            ? {
                ...MaterialHelper.getPickable(mat),
                isSelected: true,
              }
            : {
                name: translateService.translate(
                  'fillings_filling_no_material_name',
                  'No filling',
                ),
                disabledReason: null,
                item: null,
                isSelected: true,
                override: false,
              };
        },
      );
  }

  public get numberOfFillings(): number {
    return this.selectedModule.fillings.length;
  }

  public set numberOfFillings(value: number) {
    if (value > 7) return;

    const result =
      this.partitionCommandService.setNumberOfFillingsForSelectedModule(value);
    if (value !== result) {
      this.notificationService.warning(
        'partition_number_of_fillings_failed_height',
        'Failed to set number of fillings. Max/Min height of filling exceeded',
      );
    }

    this.floorPlanService.setChanged(this.floorPlan);
  }

  // Returns the index of the module within its section
  public get moduleSectionIndex(): number {
    return this.partitionQueryService.getModuleSectionIndex(
      this.selectedModule.id,
    );
  }

  public useSelectedFillingOnAll() {
    for (let filling of this.selectedModule.fillings) {
      this.partitionCommandService.updateFillingMaterial(
        filling.id,
        this.selectedFillingMaterial.item!.Id,
        this.selectedFilling.material.MaterialGroupIsDesign,
      );
    }
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public spreadFillingsEvenly() {
    this.partitionCommandService.spreadFillingsEvenlyOnModule(
      this.selectedModule.id,
    );
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public copyModuleConfigurationToOthers() {
    let section = this.partitionQueryService.selectedSection;
    if (section != null) {
      let modules = this.partitionQueryService.getModulesForSection(
        section?.id,
      );
      let selectedModule = this.partitionQueryService.getModule(
        this.selectedModule.id,
      ) as Module;
      for (let module of modules) {
        if (module.id == this.selectedModule.id) {
          continue;
        }
        this.partitionCommandService.copyModuleConfigurationToOtherModule(
          selectedModule,
          module,
        );
      }
    }
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public async selectFillingMaterial() {
    let material: Client.FillingMaterial;
    let modal = this.modalService.getPickableSelector(
      this.getModulePickableMaterials(),
      this.translateService.translate(
        'fillings_filling_material_pickable_header',
        'Filling material',
      ),
      'material partition-filling',
    );
    try {
      material = await modal.result;
    } catch (e: any) {
      //User cancelled - do nothing
      return;
    }
    this.partitionCommandService.updateFillingMaterial(
      this.selectedFilling.id,
      material.material.Id,
      material.material.MaterialGroupIsDesign,
    );

    this.selectedFillingMaterial =
      new Client.ConverterPickable<Client.Material | null>(
        () => material.material,
        (mat) => {
          return mat
            ? {
                ...MaterialHelper.getPickable(mat),
                isSelected: true,
              }
            : {
                name: this.translateService.translate(
                  'fillings_filling_no_material_name',
                  'No filling',
                ),
                disabledReason: null,
                item: null,
                isSelected: true,
                override: false,
              };
        },
      );

    this.floorPlanService.setChanged(this.floorPlan);
  }

  private getModulePickableMaterials(): Pickable<Client.FillingMaterial>[] {
    let fullCatalog =
      this.floorPlan.editorAssets.fullCatalog &&
      this.floorPlan.FullCatalogAllowOtherMaterials;

    if (this.selectedModule.product) {
      let allowDesign = this.selectedModule.isDesignTypeAllowedForFilling(
        this.selectedSection,
        this.selectedFilling.id,
      );

      let pickablesNormal = new Array<Pickable<Client.FillingMaterial>>();
      let pickablesDesign = new Array<Pickable<Client.FillingMaterial>>();

      for (let productMaterial of this.selectedModule.product.materials) {
        if (productMaterial.Type !== Interface_Enums.MaterialType.Normal) {
          continue;
        }
        if (productMaterial.isDiscontinued) {
          continue;
        }
        if (!fullCatalog && productMaterial.isOverride) {
          continue;
        }
        // Normal filling material
        if (
          productMaterial.designType ===
            Interface_Enums.MaterialDesignType.Normal ||
          productMaterial.designType ===
            Interface_Enums.MaterialDesignType.DesignAndNormal
        ) {
          let pickable = MaterialHelper.getPickableForFilling(productMaterial);

          if (this.selectedFilling.material != null) {
            pickable.isSelected =
              this.selectedFilling.material.Id === productMaterial.Id;
          } else {
            pickable.isSelected = false;
          }

          pickable.override = productMaterial.isOverride;

          //TODO below should be revisited, when we know if there are rules for what materials can be seleteced based on height.
          if (
            !MaterialHelper.supportsFillingSize(
              productMaterial,
              this.selectedModule.width,
              undefined,
            ).supported
          ) {
            pickable.disabledReason =
              'The module is too big for this material.';
          }

          pickablesNormal.push(pickable);
        }

        // Design filling material
        if (
          productMaterial.designType ===
            Interface_Enums.MaterialDesignType.Design ||
          productMaterial.designType ===
            Interface_Enums.MaterialDesignType.DesignAndNormal
        ) {
          let disabledReason = allowDesign
            ? undefined
            : 'Design filling not allowed here.';

          //let pickable = MaterialHelper.getPickableForFilling(productMaterial, true, disabledReason);
          let pickable = MaterialHelper.getPickableForFilling(
            productMaterial,
            true,
            disabledReason,
          );
          pickable.isSelected =
            this.selectedFilling.material.Id === productMaterial.Id;
          pickablesDesign.push(pickable);
        }
      }

      MaterialHelper.sortPickableFillingMaterials(pickablesNormal);
      MaterialHelper.sortPickableFillingMaterials(pickablesDesign);

      return pickablesNormal.concat(pickablesDesign);
    } else {
      return [];
    }
  }

  public get selectedModuleVerticalBarOptionNumber() {
    return this.selectedModule.verticalBarOption
      ? this.selectedModule.verticalBarOption.Number
      : '0';
  }

  public set selectedModuleVerticalBarOptionNumber(val: string | undefined) {
    let newOption = this.selectedModule.availableVerticalBarOptions.find(
      (opt) => opt.Number === val,
    )!;
    this.partitionCommandService.setVerticalBarsOptionForSelectedModule(
      newOption.Id,
    );
    this.floorPlanService.setChanged(this.floorPlan);
  }
}
