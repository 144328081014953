import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanTemplateService } from 'app/ts/services/FloorPlanTemplateService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  templateUrl: 'FloorPlanTemplate.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class FloorPlanTemplateVm extends BaseVm {
  public templates: Pickable<Interface_DTO.FloorPlanTemplate>[] | null = null;
  constructor(
    baseVmService: BaseVmService,
    floorPlanTemplateService: FloorPlanTemplateService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
    floorPlanTemplateService.getFloorPlanTemplates().then((templates) => {
      this.templates = templates.map(
        (t) =>
          <Pickable<Interface_DTO.FloorPlanTemplate>>{
            disabledReason: null,
            isSelected: false,
            item: t,
            name: t.Name,
            override: false,
            imageUrl: t.ImageUrl,
          },
      );
    });
  }

  public selectTemplate(template: Interface_DTO.FloorPlanTemplate) {
    this.activeModal.close(template);
  }

  public cancel() {
    this.activeModal.dismiss('cancelled');
  }
}
