import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Exception from 'app/ts/exceptions';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { CustomerService } from 'app/ts/services/CustomerService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { LoginService } from 'app/ts/services/LoginService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OrderService {
  private requireSms = false;
  private requiresReference = false;
  private requiresMarketOrder = false;

  constructor(
    loginService: LoginService,
    private readonly $http: PromisingBackendService,
    private readonly customerService: CustomerService,
    private readonly floorPlanService: FloorPlanService,
  ) {
    loginService.salesChainSettings$.subscribe((settings) => {
      this.requireSms =
        settings[Interface_Enums.SalesChainSettingKey.RequireSMSWhenOrdering] ==
        '1';
      this.requiresReference =
        settings[
          Interface_Enums.SalesChainSettingKey.RequireRequisitionWhenOrdering
        ] == '1';
      this.requiresMarketOrder =
        settings[Interface_Enums.SalesChainSettingKey.RequireMarketOrder] ==
        '1';
    });
  }

  public async saveDeliveryInfo(
    editRequest: Interface_DTO.BulkDeliveryInfoEditRequest,
  ): Promise<void> {
    await this.$http.post<void>('api/order/bulk', editRequest, {
      responseType: 'void',
    });
    await this.customerService.updateCustomerList();
  }

  public async getCorrectDeliveryInfo(
    floorPlanOverview: Interface_DTO.FloorPlanOverview,
  ): Promise<Interface_DTO.DeliveryInfo> {
    this.assertIsOrderable(floorPlanOverview);
    let deliveryInfo = await this.$http.get<Interface_DTO.DeliveryInfo>(
      'api/order/validate/' + floorPlanOverview.Id,
    );

    return deliveryInfo;
  }

  public async getUpdatedDeliveryInfo(
    fpo: Interface_DTO.FloorPlanOverview,
    editRequest: Interface_DTO.BulkDeliveryInfoEditRequest,
  ): Promise<Interface_DTO.DeliveryInfo> {
    this.assertIsCancellable(fpo, new Date());
    let newEditRequest: Interface_DTO.BulkDeliveryInfoEditRequest = {
      ...editRequest,
      DeliveryInfoIds: [fpo.DeliveryInfoId],
    };
    return await this.$http.post<Interface_DTO.DeliveryInfo>(
      'api/order/revalidateDeliveryInfo/' + fpo.Id,
      newEditRequest,
    );
  }
  public async updateOrder(
    fpo: Interface_DTO.FloorPlanOverview,
    editRequest: Interface_DTO.BulkDeliveryInfoEditRequest,
  ) {
    this.floorPlanService.removeFromCache(fpo.Id);
    this.assertIsCancellable(fpo, new Date());
    let newEditRequest: Interface_DTO.BulkDeliveryInfoEditRequest = {
      ...editRequest,
      DeliveryInfoIds: [fpo.DeliveryInfoId],
    };
    return await this.$http.post<void>(
      'api/order/recommitDeliveryInfo/' + fpo.Id,
      newEditRequest,
      { responseType: 'void', timeout: 60000 },
    );
  }

  public async sendToOrder(floorPlanOverview: Interface_DTO.FloorPlanOverview) {
    this.assertIsOrderable(floorPlanOverview);
    this.floorPlanService.removeFromCache(floorPlanOverview.Id);
    return await this.$http.post<void>(
      'api/order/commit',
      floorPlanOverview.Id,
      { responseType: 'void' },
    );
  }

  public isOrderable(
    floorPlanOverviews: Interface_DTO.FloorPlanOverview[],
  ): boolean {
    try {
      for (let fpo of floorPlanOverviews) {
        this.assertIsOrderable(fpo);
      }
      return true;
    } catch (e: any) {
      return false;
    }
  }

  public isSavable(
    floorPlanOverviews: Interface_DTO.FloorPlanOverview[],
  ): boolean {
    try {
      for (let fpo of floorPlanOverviews) {
        this.assertIsSavable(fpo);
      }
      return true;
    } catch (e: any) {
      return false;
    }
  }

  private assertIsSavable(fpo: Interface_DTO.FloorPlanOverview): void {
    if (fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Quote)
      throw new Exception.OrderWrongDeliveryStatus();
  }

  public assertIsOrderable(fpo: Interface_DTO.FloorPlanOverview): void {
    this.assertIsSavable(fpo);

    if (fpo.MaxErrorLevel >= Interface_Enums.ErrorLevel.Critical)
      throw new Exception.OrderFloorPlanError();

    if (this.requireSms) {
      if (!fpo.SmsNumber || fpo.SmsNumber.length < 4) {
        throw new Exception.PropertyInvalid(fpo, 'SmsNumber');
      }
    }
    if (this.requiresReference) {
      if (ObjectHelper.isNullOrWhitespace(fpo.Reference)) {
        throw new Exception.PropertyInvalid(fpo, 'Reference');
      }
    }
    if (this.requiresMarketOrder) {
      if (ObjectHelper.isNullOrWhitespace(fpo.MarketOrderNumber))
        throw new Exception.PropertyInvalid(fpo, 'MarketOrderNumber');
    }
  }

  public isCancelable(
    fpos: Pick<
      Interface_DTO.FloorPlanOverview,
      'CorrectionDeadlineDate' | 'CurrentStatus'
    >[],
  ): boolean {
    for (let fpo of fpos) {
      let now = new Date();
      try {
        this.assertIsCancellable(fpo, now);
      } catch (e: any) {
        return false;
      }
    }
    return true;
  }

  private assertIsCancellable(
    fpo: Pick<
      Interface_DTO.FloorPlanOverview,
      'CorrectionDeadlineDate' | 'CurrentStatus'
    >,
    now: Date,
  ): void {
    if (!fpo.CorrectionDeadlineDate)
      throw new Exception.OrderWrongDeliveryStatus();
    let correctionDeadline = DateHelper.fromIsoString(
      fpo.CorrectionDeadlineDate,
    );
    if (now > correctionDeadline)
      throw new Exception.CorrectionDeadlineExceeded(correctionDeadline);

    if (
      fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Order &&
      fpo.CurrentStatus !==
        Interface_Enums.DeliveryStatus.PendingConfirmation &&
      fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Confirmed &&
      fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Received &&
      fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Blocked
    ) {
      throw new Exception.OrderWrongDeliveryStatus();
    }
  }

  public async cancelOrder(
    floorPlanOverview: Interface_DTO.FloorPlanOverview,
  ): Promise<void> {
    this.assertIsCancellable(floorPlanOverview, new Date());
    await this.$http.post<void>(
      'api/order/' + floorPlanOverview.DeliveryInfoId + '/cancel',
      {},
      { responseType: 'void' },
    );
  }
}
