import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CustomerNavVm } from './CustomerNavVm';
import { UpdateCountsPipe } from './update-counts.pipe';

@NgModule({
  declarations: [CustomerNavVm, UpdateCountsPipe],
  imports: [BrowserModule, FormsModule, RouterModule],

  exports: [CustomerNavVm, UpdateCountsPipe],
})
export class CustomerNavModule {}
