import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BackendServiceModule } from 'app/backend-service/backend-service.module';
import { CustomersTableVm } from './viewmodels/components/CustomersTableVm';
import { FolderVm } from './viewmodels/components/FolderVm';
import { PostalAddressEditVm } from './viewmodels/components/PostalAddressEditVm';
import { ProductCategoryVm } from './viewmodels/components/ProductCategoryVm';
import { PieceListVm } from './viewmodels/components/PieceListVm';
import { ProductListRecursiveVm } from './viewmodels/components/ProductListRecursiveVm';
import { AppVersionVm } from './viewmodels/components/AppVersionVm';
import { CustomersVm } from './viewmodels/CustomersVm';
import { DeliveryAddressesVm } from './viewmodels/DeliveryAddressesVm';
import { PasswordResetLandingVm } from './viewmodels/PasswordResetLandingVm';
import { OrderConfirmerVm } from './viewmodels/OrderConfirmerVm';
import { ProjectsVm } from './viewmodels/ProjectsVm';
import { PieceListTopVm } from './viewmodels/PieceListTopVm';
import { BasketVm } from './viewmodels/consumer/pages';
import { DimensionsVm } from './viewmodels/consumer/pages';
import { InteriorVm } from './viewmodels/consumer/pages/InteriorVm';
import { CorpusVm as ConsumerCorpusVm } from './viewmodels/consumer/pages';
import { NewVm } from './viewmodels/consumer/pages';
import { LoadVm } from './viewmodels/consumer/pages';
import { DoorsVm } from './viewmodels/consumer/pages';
import { KaLinkVm } from './viewmodels/components/KaLinkVm';
import { LinkBrowserVm } from './viewmodels/components/LinkBrowserVm';
import { RightMenuVm } from './viewmodels/components/RightMenuVm';
import { TabbedMenuTabVm } from './viewmodels/components/TabbedMenuTabVm';
import { TabbedMenuVm } from './viewmodels/components/TabbedMenuVm';
import { I18NModule } from 'app/i18n/i18n.module';
import { NgProxyModule } from 'app/ng/ng-proxy.module';
import {
  ConsumerItemEditorVm,
  HelpBubbleVm,
  InteriorProductVm,
  ItemPickerVm,
  NavbarVm,
} from './viewmodels/consumer/components';
import { ConsumerPieceListTableVm } from './viewmodels/consumer/components';
import { ConsumerPriceVm } from './viewmodels/consumer/components';
import { InteriorProductPicker } from './viewmodels/consumer/components';
import { DeliveryAddressesTableVm } from './viewmodels/components/DeliveryAddressesTableVm';
import { ProjectsTableVm } from './viewmodels/components/ProjectsTableVm';
import { EmptyVm } from './viewmodels/EmptyVm';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { AcceptDeliveryInfoVm } from './viewmodels/modals/AcceptDeliveryInfoVm';
import { addFittingVm } from './viewmodels/modals/addFittingVm';
import { AddProductVm } from './viewmodels/modals/AddProductVm';
import { CustomPriceDateVm } from './viewmodels/modals/CustomPriceDateVm';
import { DeliveryAddressSelectorVm } from './viewmodels/modals/DeliveryAddressSelectorVm';
import { DeliveryInfoEditorVm } from './viewmodels/modals/DeliveryInfoEditorVm';
import { EditManualItemVm } from './viewmodels/modals/EditManualItemVm';
import { FavouriteSelectorVm } from './viewmodels/modals/FavouriteSelectorVm';
import { FreeSpaceVm } from './viewmodels/modals/FreeSpaceVm';
import { GroupSelectorVm } from './viewmodels/modals/GroupSelectorVm';
import { ItemDepthVm } from './viewmodels/modals/ItemDepthVm';
import { JointPositionsVm } from './viewmodels/modals/JointPositionsVm';
import { LinkBrowserModalVm } from './viewmodels/modals/LinkBrowserModalVm';
import { ProjectSelectorVm } from './viewmodels/modals/ProjectSelectorVm';
import { PropertySelectorVm } from './viewmodels/modals/PropertySelectorVm';
import { SelectBackingVm } from './viewmodels/modals/SelectBackingVm';
import { StoreSelectorVm } from './viewmodels/modals/StoreSelectorVm';
import { StoreSettingVm } from './viewmodels/modals/StoreSettingVm';
import { TemplateSaveVm } from './viewmodels/modals/TemplateSaveVm';
import { UserSettingVm } from './viewmodels/modals/UserSettingVm';
import { PrintDialogVm } from './viewmodels/modals/PrintDialogVm';
import { ConsumerSaveFloorPlanVm } from './viewmodels/consumer/modals/ConsumerSaveFloorPlanVm';
import { NotificationService } from './services/NotificationService';
import { CustomerNavModule } from './viewmodels/components/customer-nav.module';
import { DarkSideBarModule } from './viewmodels/components/dark-sidebar-module';
import { TopbarModule } from './viewmodels/components/topbar.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslatePipe } from 'app/i18n/translate.pipe';
import { FunctionalCoreModule } from 'app/functional-core/functional-core.module';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PhoneInputModule } from './viewmodels/components/phone-input.module';
import { ModalsModule } from './viewmodels/modals/modals.module';
import { DeliveryAddressEditMenuModule } from './viewmodels/components/deliveryAddressEditMenu.module';
import { ProjectEditMenuModule } from './viewmodels/components/projectEditMenu.module';
import { CustomerEditModule } from './viewmodels/components/customer-edit.module';
import { CornerNameBubbleModule } from './viewmodels/components/corner-name-bubble.module';
import { CustomerTopbarModule } from '../customer-topbar/customer.topbar.module';
import { PartitionModule } from 'app/partition/partition.module';
import { FloorPlanEditingModule } from 'app/floor-plan-editing/floor-plan-editing.module';
import { PickableModule } from 'app/floor-plan-editing/pickable.module';
import { ProductListModule } from './viewmodels/components/product-list.module';
import { ConsumerLoginVm } from './viewmodels/consumer/modals/ConsumerLoginVm';
import { ConsumerDoorProfileVm } from './viewmodels/consumer/modals/ConsumerDoorProfileVm';
import { ConsumerErrorsModalVm } from './viewmodels/consumer/modals/ConsumerErrorsModalVm';
import { ConsumerNewUserVm } from './viewmodels/consumer/modals/ConsumerNewUserVm';
import { FloorPlanTemplateVm } from './viewmodels/modals/FloorPlanTemplateVm';
import { AppVersionModule } from './viewmodels/components/app-version.module';
import { OnlyNumberDirective } from './util/OnlyNumbersDirective';

@NgModule({
  declarations: [
    AcceptDeliveryInfoVm,
    addFittingVm,
    AddProductVm,
    BasketVm,
    ConsumerCorpusVm,
    ConsumerDoorProfileVm,
    ConsumerErrorsModalVm,
    ConsumerItemEditorVm,
    ConsumerNewUserVm,
    ConsumerPieceListTableVm,
    ConsumerPriceVm,
    ConsumerSaveFloorPlanVm,
    CustomersTableVm,
    CustomersVm,
    CustomPriceDateVm,
    DeliveryAddressSelectorVm,
    DeliveryAddressesTableVm,
    DeliveryAddressesVm,
    DeliveryInfoEditorVm,
    DimensionsVm,
    DoorsVm,
    EmptyVm,
    EditManualItemVm,
    FavouriteSelectorVm,
    FloorPlanTemplateVm,
    FolderVm,
    FreeSpaceVm,
    GroupSelectorVm,
    HelpBubbleVm,
    InteriorProductPicker,
    InteriorProductVm,
    InteriorVm,
    ItemDepthVm,
    ItemPickerVm,
    JointPositionsVm,
    KaLinkVm,
    LinkBrowserModalVm,
    LinkBrowserVm,
    LoadVm,
    NavbarVm,
    NewVm,
    OrderConfirmerVm,
    PasswordResetLandingVm,
    PieceListTopVm,
    PieceListVm,
    PostalAddressEditVm,
    PrintDialogVm,
    ProductCategoryVm,
    ProductListRecursiveVm,
    ProjectSelectorVm,
    ProjectsTableVm,
    ProjectsVm,
    PropertySelectorVm,
    RightMenuVm,
    SelectBackingVm,
    StoreSelectorVm,
    StoreSettingVm,
    TabbedMenuTabVm,
    TabbedMenuVm,
    TemplateSaveVm,
    UserSettingVm,
    ConsumerLoginVm,
    OnlyNumberDirective,
  ],
  exports: [],
  providers: [AsyncPipe, NotificationService, TranslatePipe],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    NgProxyModule,
    RouterModule,
    AppVersionModule,

    AppRoutingModule,
    BackendServiceModule,
    CornerNameBubbleModule,
    CustomerEditModule,
    CustomerNavModule,
    CustomerTopbarModule,
    DarkSideBarModule,
    DeliveryAddressEditMenuModule,
    FloorPlanEditingModule,
    FunctionalCoreModule,
    I18NModule,
    ModalsModule,
    PartitionModule,
    PhoneInputModule,
    PickableModule,
    ProductListModule,
    ProjectEditMenuModule,
    TopbarModule,
  ],
})
export class LegacyTsModule {}
