import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as App from 'app/ts/app';
import * as Client from 'app/ts/clientDto/index';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CabinetService } from 'app/ts/services/CabinetService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { FloorPlanHelper } from 'app/ts/util/FloorPlanHelper';
import { ProperSvgElement } from 'app/ts/util/ProperSvgElement';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import * as Partition from 'app/partition/floor-plan';
import Enumerable from 'linq';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import {
  Measurement,
  MeasurementData,
  MeasurementId,
} from 'app/measurement/Measurement';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LeftMenuDragInfo } from './FloorPlanVm';
import { MeasurementService } from 'app/measurement/measurement.service';
import { calculateMeasurementLength } from 'app/measurement/measurement-length-calculations';
import { Section, SectionId } from 'app/partition/section';
import { Section as FloorPlanSection } from 'app/partition/floor-plan/section';
import { DoorPlacement, Module } from 'app/partition/module';
import {
  CornerProfile,
  Profile,
  ProfileType,
  TProfile,
} from 'app/partition/profile';
import {
  PartitionSnapService,
  SnapContext,
} from 'app/partition/partition-snap.service';
import { PartitionDragType } from 'app/ts/clientDto/Enums';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { PartitionPlanGeometryQueryService } from 'app/partition/partition-plan-geometry-query.service';
import { Door } from 'app/partition/floor-plan/door';

@Component({
  selector: 'floor-plan-image',
  templateUrl: './floorPlanImage.svg',
})
export class FloorPlanImageVm extends BaseVm implements OnInit {
  private static readonly minSectionDepthForCenteredText = 280;
  private static readonly minDragPixels = 6;
  private static whyDidIWriteItThisWay_BetterRewriteItFromScratch_Count = 6;

  constructor(
    baseVmService: BaseVmService,
    private readonly floorPlanService: FloorPlanService,
    private readonly cabinetService: CabinetService,
    assetService: AssetService,
    private partitionCommands: PartitionPlanCommandService,
    private partitionQuery: PartitionPlanQueryService,
    private partitionGeometryQuery: PartitionPlanGeometryQueryService,
    private measurementService: MeasurementService,
    private partitionSnapService: PartitionSnapService,
  ) {
    super(baseVmService);
    this.products = assetService.floorPlanProducts;
  }

  public showCabinets: boolean = true;

  @Input()
  public partitions!: Partition.Plan;

  @Input()
  public measurements!: readonly Measurement[];

  @Input()
  public showMeasurements!: boolean;
  @Input()
  public showOrigin!: boolean;

  @Input()
  public showFloorPlanDetails$!: Subject<boolean>;

  /** SVG */
  @Input()
  public floorPlan!: Client.FloorPlan;

  @Input()
  public selectedItem$!: BehaviorSubject<
    Interface_DTO_FloorPlan.Item | undefined
  >;

  @Input()
  public currentFloorPlanDragInfo:
    | Observable<LeftMenuDragInfo | undefined>
    | undefined;

  /** SVG */
  @Input()
  public showErrorTriangles: boolean = false;

  @Output()
  public isDragging: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  private svgCanvas!: ProperSvgElement;
  private svgMousePoint!: SVGPoint;

  public selectedItemWall: Interface_DTO_FloorPlan.WallSection | null = null;
  public selectedProduct: Client.FloorPlanProduct | null = null;
  public selectedSection: Client.CabinetSection | null = null;
  public corners!: { [id: number]: Interface_DTO_FloorPlan.Corner };

  /** SVG */
  public walls: Interface_DTO_FloorPlan.WallSection[] = [];

  /** SVG */
  public wallRulers: Client.Ruler[] = [];
  public cabinetDragInfo: FloorPlanImageVm.DragInfo | null = null;
  public partitionDragInfo: FloorPlanImageVm.PartitionDragInfo | null = null;
  public measurementDragInfo: FloorPlanImageVm.MeasurementDragInfo | null =
    null;
  public snapInfo: FloorPlanImageVm.SnapInfo | null = null;
  public readonly wallThickness = 80;

  public guidelines: {
    start: Interface_DTO_Draw.Vec2d;
    end: Interface_DTO_Draw.Vec2d;
    major: boolean;
    vertical: boolean;
  }[] = [];

  public dragOverlays: {
    svgTransformString: string;
    width: number;
    height: number;
    wall: Interface_DTO_FloorPlan.WallSection;
    item: Interface_DTO_FloorPlan.Item;
    section?: Client.CabinetSection;
  }[] = [];

  public cornerTypes = [
    Interface_Enums.CornerType.Normal,
    Interface_Enums.CornerType.Half,
    Interface_Enums.CornerType.Inverted,
  ];

  public products: { [id: number]: Client.FloorPlanProduct } = {};

  public ngOnInit() {
    this.svgCanvas = window.document.getElementById('floor-plan-canvas') as any;
    this.svgMousePoint = this.svgCanvas.createSVGPoint();
    if (!this.selectedItem$) {
      this.selectedItem$ = new BehaviorSubject<
        Interface_DTO_FloorPlan.Item | undefined
      >(undefined);
    }

    this.subscribeTo(this.floorPlan.floorPlan$, (fp) => {
      this.floorPlanChanged();
    });

    if (this.currentFloorPlanDragInfo) {
      this.currentFloorPlanDragInfo.subscribe((fpdi) => {
        if (!fpdi) {
          this.cabinetDragInfo = null;
          this.partitionDragInfo = null;
          this.measurementDragInfo = null;
          return;
        }

        if (this.isProductDragInfo(fpdi)) this.handleFloorPlanDragInfo(fpdi);
        else this.handleFloorPlanMeasurementDragInfo(fpdi);

        if (fpdi.touchObservable) {
          //event is a touch event - subscribe to touch events from the initializing object
          if (App.debug.showTouchMessages) {
            console.debug('FloorPlanImage touchStart');
          }
          let lastEvent = fpdi.touchObservable.value;

          let subscription = fpdi.touchObservable.subscribe((evt) => {
            if (App.debug.showTouchMessages) {
              console.debug('FloorPlanImage touchMove', evt);
            }
            if (evt) {
              let modelPos = this.getModelPos(evt);
              if (modelPos.X > 0) {
                this.mouseMove(evt);
                lastEvent = evt;
              }
            } else {
              subscription.unsubscribe();
              if (lastEvent) this.mouseUp(lastEvent);
            }
          });
        }
      });
    }
  }

  public isProductDragInfo(
    dragInfo: LeftMenuDragInfo,
  ): dragInfo is Client.FloorPlanProductDragInfo {
    return Object.keys(dragInfo).includes('product');
  }

  public errorTriangleSize(section: Client.CabinetSection): number {
    return Math.min(section.Depth, 200);
  }

  public async floorPlanWallsChanged() {
    this.floorPlan.isDirty = true;
    this.setSectionsChanged();
    this.measurementService.recalculateMeasurements(this.floorPlan);
    await this.floorPlanService.setChanged(this.floorPlan);
    this.floorPlanChanged();
  }

  protected floorPlanChanged() {
    this.corners = {
      0: this.floorPlan.TopLeftCorner,
      1: this.floorPlan.TopRightCorner,
      2: this.floorPlan.BottomLeftCorner,
      3: this.floorPlan.BottomRightCorner,
    };

    let fp = this.floorPlan;
    this.walls = <Interface_DTO_FloorPlan.WallSection[]>(
      [
        fp.TopWall,
        fp.TopRightCorner.Wall1,
        fp.TopRightCorner.Wall2,
        fp.RightWall,
        fp.BottomRightCorner.Wall1,
        fp.BottomRightCorner.Wall2,
        fp.BottomWall,
        fp.BottomLeftCorner.Wall1,
        fp.BottomLeftCorner.Wall2,
        fp.LeftWall,
        fp.TopLeftCorner.Wall1,
        fp.TopLeftCorner.Wall2,
      ].filter((wall) => !!wall)
    );

    this.wallRulers = this.getWallRulers();

    let gridSize = 200;
    let majorGrid = 1000;
    this.guidelines = [];
    for (let i = gridSize; i < fp.Size.Y; i += gridSize) {
      let major = i % majorGrid === 0;
      this.guidelines.push({
        start: { X: 0, Y: i },
        end: { X: fp.Size.X, Y: i },
        major: major,
        vertical: false,
      });
    }

    for (let i = gridSize; i < fp.Size.X; i += gridSize) {
      let major = i % majorGrid === 0;
      this.guidelines.push({
        start: { X: i, Y: 0 },
        end: { X: i, Y: fp.Size.Y },
        major: major,
        vertical: true,
      });
    }

    this.setDragOverlays();

    this.measurementService.recalculateMeasurements(this.floorPlan);
  }

  private setSectionsChanged() {
    for (let cabinet of this.floorPlan.cabinets) {
      for (let section of cabinet.cabinetSections) {
        section.isDirty = true;
      }
    }
    this.setDragOverlays();
  }

  private setDragOverlays() {
    this.dragOverlays = [];

    for (let wall of this.walls) {
      for (let item of wall.Items) {
        let cabinet = this.getCabinet(item);
        if (cabinet) {
          for (let section of cabinet.actualCabinetSections) {
            this.dragOverlays.push({
              svgTransformString:
                this.getWallTransformString(wall) +
                ' ' +
                this.getItemTransformString(item) +
                ' ' +
                this.getCabinetSectionTransformString(section),
              height: section.Depth,
              width: section.Width,
              wall: wall,
              item: item,
              section: section,
            });
          }
        } else {
          this.dragOverlays.push({
            width: item.Width,
            height: item.Height,
            svgTransformString:
              this.getWallTransformString(wall) +
              ' ' +
              this.getItemTransformString(item),
            wall: wall,
            item: item,
          });
        }
      }
    }
  }

  private getModelPos(evt: Event | MouseEvent | TouchEvent) {
    let pos = this.getPosFromEvent(evt);
    this.svgMousePoint.x = pos.X;
    this.svgMousePoint.y = pos.Y;
    let pt = this.svgMousePoint.matrixTransform(
      this.svgCanvas.getScreenCTM().inverse(),
    );
    let result = {
      X: pt.x,
      Y: pt.y,
    };
    return result;
  }

  public setCanvasId(id: string) {
    this.svgCanvas = <any>document.getElementById(id);
    if (this.svgCanvas) {
      this.svgMousePoint = this.svgCanvas.createSVGPoint();
    }
  }

  private getPosFromEvent(evt: Event | MouseEvent | TouchEvent) {
    if (evt instanceof MouseEvent)
      return {
        X: evt.clientX,
        Y: evt.clientY,
      };
    else if (evt instanceof TouchEvent)
      return {
        X: evt.changedTouches[0].clientX,
        Y: evt.changedTouches[0].clientY,
      };
    else {
      console.error('strange event: ', evt);
      throw new Error('event not recognized');
    }
  }

  public get selectedItem(): Interface_DTO_FloorPlan.Item | undefined {
    return this.selectedItem$.value;
  }
  public set selectedItem(val: Interface_DTO_FloorPlan.Item | undefined) {
    this.selectedItem$.next(val);
  }

  public getOutlineStyle(cabinet: Client.Cabinet, section: any) {
    if (this.selectedSection) {
      if (cabinet) {
        if (cabinet.CabinetIndex === this.selectedSection.CabinetIndex) {
          return 'fill:white; stroke: red; stroke-width: 15px;';
        }
      }
    }
    return 'fill:white; stroke: grey; stroke-width: 15px;';
  }

  public clickProduct(
    product: Client.FloorPlanProduct,
    evt: MouseEvent | TouchEvent,
  ) {
    this.cabinetDragInfo = {
      item: {
        FlipDirection: false,
        FlipSide: false,
        X: 0,
        Y: 0,
        Width: product.size.Y,
        Height: product.size.X,
        ItemType: product.itemType,
        CabinetIndex: null,
        CabinetType: product.cabinetType,
      },
      snapOutline: null,
      dragActive: true,
      product: product,
      startPos: this.getPosFromEvent(evt),
      sourceWall: null,
      mouseOffset: {
        X: (product.size.X + product.displayOffset.X) / 2,
        Y: (product.size.Y + product.displayOffset.Y) / 2,
      },
      cabinet: null,
    };
    this.unselect();
  }

  public clickMeasurement(
    measurement: MeasurementData,
    evt: Event | MouseEvent | TouchEvent,
  ) {
    this.unselect();

    this.selectedItem = {
      FlipDirection: false,
      FlipSide: false,
      ItemType: Interface_DTO_FloorPlan.ItemType.Measurement,
      measurement: measurement.id,
    } as Interface_DTO_FloorPlan.Item;

    this.measurementDragInfo = {
      dragActive: false,
      mouseOffset: this.getMeasurementMouseOffset(measurement, evt),
      startPos: this.getPosFromEvent(evt),
      item: measurement.id,
    };

    this.measurementService.selectMeasurement(measurement.id);

    evt.preventDefault();
    this.floorPlan.triggerUpdate();
  }

  public clickPartitionWall(
    wall: Partition.Module,
    evt: Event | MouseEvent | TouchEvent,
  ) {
    this.unselect();
    this.partitionCommands.setSelection(wall.sectionId, wall.id);

    // let item = {
    //     CabinetIndex: wall.sectionId as number,
    //     CabinetType: Interface_Enums.CabinetType.Partition,
    //     FlipDirection: false,
    //     ItemType: Interface_DTO_FloorPlan.ItemType.Partition
    // } as Interface_DTO_FloorPlan.Item
    // this.selectedItem = item;

    let section = this.partitionQuery.getSection(wall.sectionId);
    let mouseOffset = this.getPartitionMouseOffset(evt);

    this.selectedItem = {
      CabinetIndex: wall.sectionId as number,
      CabinetType: Interface_Enums.CabinetType.Partition,
      FlipDirection: false,
      FlipSide: false,
      ItemType: Interface_DTO_FloorPlan.ItemType.Partition,
      partition: {
        section: wall.sectionId,
        module: wall.id,
      } as Partition.Selection,

      X: section.posX,
      Y: section.posY,
    } as Interface_DTO_FloorPlan.Item;

    this.selectedProduct =
      this.products[Interface_DTO_FloorPlan.ItemType.Partition];

    this.partitionDragInfo = {
      item: this.selectedItem,
      product: this.products[Interface_DTO_FloorPlan.ItemType.Partition],
      snapOutline: null,
      dragActive: false,
      mouseOffset: mouseOffset,
      startPos: this.getPosFromEvent(evt),
    };
    evt.preventDefault();
    this.floorPlan.triggerUpdate();
  }

  public clickItem(
    item: Interface_DTO_FloorPlan.Item,
    wall: Interface_DTO_FloorPlan.WallSection,
    evt: Event | MouseEvent | TouchEvent,
    section?: Client.CabinetSection,
  ): void {
    this.unselect();
    this.selectedItem = item;
    this.selectedItemWall = wall;
    this.selectedProduct = this.products[item.ItemType];
    this.selectedSection = section ? section : null;

    //if (section) {
    //    item = new FloorPlanVm.CabinetItem(item, section.clientSection.cabinet);
    //}

    this.cabinetDragInfo = {
      item: item,
      sourceWall: wall,
      product: this.products[item.ItemType],
      snapOutline: null,
      dragActive: false,
      startPos: this.getPosFromEvent(evt),
      mouseOffset: this.getMouseOffset(evt, item, wall),
      cabinet: section ? section.cabinet : null,
    };
    evt.preventDefault();
  }

  public unselect() {
    this.selectedItem = undefined;
    this.selectedItemWall = null;
    this.selectedProduct = null;
    this.selectedSection = null;
    this.partitionCommands.deselectSection();
    this.measurementService.deselect();

    this.showFloorPlanDetails$.next(true);
    this.floorPlan.triggerUpdate();
  }

  private handleFloorPlanMeasurementDragInfo(
    dragInfo: Client.FloorPlanMeasurementDragInfo,
  ) {
    if (App.debug.showTouchMessages) {
      console.debug('handleFloorPlanDragInfo');
    }
    if (this.measurementDragInfo) return;

    this.measurementDragInfo = {
      dragActive: false,
      startPos: { X: 0, Y: 0 },
      mouseOffset: { X: 0, Y: 0 },
      item: this.measurementService.addMeasurement(0, 0),
    };
  }
  private handleFloorPlanDragInfo(dragInfo: Client.FloorPlanProductDragInfo) {
    if (App.debug.showTouchMessages) {
      console.debug('handleFloorPlanDragInfo');
    }
    if (this.cabinetDragInfo || this.partitionDragInfo) {
      return;
    }

    let product = dragInfo.product;
    let productLine =
      typeof dragInfo.productLine === 'object'
        ? dragInfo.productLine
        : undefined;
    let maxWallLength = Math.max(
      ...this.walls.map((w) => VectorHelper.dist(w.End, w.Begin)),
    );
    this.cabinetDragInfo = {
      item: {
        FlipDirection: false,
        FlipSide: false,
        Width: Math.min(product.size.Y, maxWallLength),
        Height: product.size.X,
        X: 0,
        Y: 0,
        ItemType: product.itemType,
        CabinetIndex: null,
        CabinetType: product.cabinetType,
      },
      snapOutline: null,
      dragActive: false,
      product: product,
      startPos: { X: 0, Y: 0 },
      sourceWall: null,
      mouseOffset: {
        X: (product.size.X + product.displayOffset.X) / -2,
        Y: (product.size.Y + product.displayOffset.Y) / 2,
      },
      cabinet: null,
      productLine: productLine,
    };
  }

  public mouseMove(evt: Event | MouseEvent | TouchEvent) {
    if (
      !this.cabinetDragInfo &&
      !this.partitionDragInfo &&
      !this.measurementDragInfo
    )
      return;

    if (this.cabinetDragInfo) this.dragInfo(evt);

    if (this.partitionDragInfo) this.partitionInfo(evt);

    if (this.measurementDragInfo) this.measurementInfo(evt);

    this.measurementService.recalculateMeasurements(this.floorPlan);
  }

  public measurementInfo(evt: Event | MouseEvent | TouchEvent) {
    evt.preventDefault();

    const modelPos = VectorHelper.add(this.getModelPos(evt));

    if (!this.measurementDragInfo!.dragActive) {
      let currentPos = this.getPosFromEvent(evt);
      if (
        VectorHelper.dist(this.measurementDragInfo!.startPos, currentPos) >
        FloorPlanImageVm.minDragPixels
      ) {
        this.measurementDragInfo!.dragActive = true;
      } else {
        return;
      }
    }

    //Limit item to inside floorplan
    let posX = Math.min(Math.max(0, modelPos.X), this.floorPlan.Size.X);
    let posY = Math.min(Math.max(0, modelPos.Y), this.floorPlan.Size.Y);

    this.measurementService.updateMeasurementPosition(
      this.measurementDragInfo!.item,
      posX - this.measurementDragInfo!.mouseOffset.X,
      posY - this.measurementDragInfo!.mouseOffset.Y,
    );
  }

  public partitionInfo(evt: Event | MouseEvent | TouchEvent) {
    evt.preventDefault();

    let section = this.partitionQuery.getSection(
      this.partitionDragInfo!.item.partition!.section,
    );
    const modelPos = this.getModelPos(evt);
    if (!this.partitionDragInfo!.dragActive) {
      let currentPos = this.getPosFromEvent(evt);
      if (
        VectorHelper.dist(this.partitionDragInfo!.startPos, currentPos) >
        FloorPlanImageVm.minDragPixels
      ) {
        this.partitionDragInfo!.dragActive = true;
      } else {
        return;
      }
    }

    this.partitionDragInfo!.prevItemPosition = {
      X: this.partitionDragInfo!.item.X,
      Y: this.partitionDragInfo!.item.Y,
    };

    let dragType = this.partitionQuery.getDragType(section.id);

    let snapContext: SnapContext | null = null;
    if (dragType == PartitionDragType.FreeDrag) {
      snapContext = this.snapToWalls(modelPos);
      if (snapContext == null) snapContext = this.snapToPartitions(modelPos);
    } else if (dragType == PartitionDragType.MultiDrag) {
      this.handleMultiDrag(section, modelPos);
      return;
    }

    if (snapContext) {
      this.partitionDragInfo!.item.X = Math.round(snapContext.snapPosition.X);
      this.partitionDragInfo!.item.Y = Math.round(snapContext.snapPosition.Y);
      this.partitionDragInfo!.snapContext = snapContext;
    } else {
      this.clampDragging(section, modelPos, dragType);

      this.partitionDragInfo!.snapContext = undefined;
    }

    this.partitionCommands.updateSectionPosition(
      section.id,
      this.partitionDragInfo!.item.X,
      this.partitionDragInfo!.item.Y,
    );
    this.partitionCommands.updateProfilePositionAndRotation(section.id);

    if (
      dragType == PartitionDragType.XAxisDrag ||
      dragType == PartitionDragType.YAxisDrag
    ) {
      let diff =
        dragType == PartitionDragType.XAxisDrag
          ? this.partitionDragInfo!.item.X -
            this.partitionDragInfo!.prevItemPosition.X
          : this.partitionDragInfo!.item.Y -
            this.partitionDragInfo!.prevItemPosition.Y;

      this.partitionCommands.adjustTPieceSectionWidths(section.id, diff);
    }
  }

  private handleMultiDrag(
    section: Readonly<Section>,
    modelPos: Interface_DTO_Draw.Vec2d,
  ) {
    if (section.isVertical) {
      if (!this.partitionDragInfo!.multiDragRange)
        this.partitionDragInfo!.multiDragRange =
          this.partitionGeometryQuery.calculateMultiDragMoveRangeX(section.id);

      let newX = modelPos.X - this.partitionDragInfo!.mouseOffset.X;

      newX = ObjectHelper.clamp(
        this.partitionDragInfo!.multiDragRange.min ??
          this.partitionGeometryQuery.getSectionMinAllowedX(section.id),
        newX,
        this.partitionDragInfo!.multiDragRange.max ??
          this.partitionGeometryQuery.getSectionMaxAllowedX(section.id),
      );

      this.partitionDragInfo!.item.X = Math.round(newX);
      this.partitionCommands.handleMultiDrag(
        section.id,
        Math.round(newX) - section.posX,
        0,
      );
    } else if (section.isHorizontal) {
      if (!this.partitionDragInfo!.multiDragRange)
        this.partitionDragInfo!.multiDragRange =
          this.partitionGeometryQuery.calculateMultiDragMoveRangeY(section.id);

      let newY = modelPos.Y - this.partitionDragInfo!.mouseOffset.Y;

      newY = ObjectHelper.clamp(
        this.partitionDragInfo!.multiDragRange.min ??
          this.partitionGeometryQuery.getSectionMinAllowedY(section.id),
        newY,
        this.partitionDragInfo!.multiDragRange.max ??
          this.partitionGeometryQuery.getSectionMaxAllowedY(section.id),
      );

      this.partitionDragInfo!.item.Y = Math.round(newY);
      this.partitionCommands.handleMultiDrag(
        section.id,
        0,
        Math.round(newY) - section.posY,
      );
    }
  }

  private snapToWalls(
    mousePosition: Interface_DTO_Draw.Vec2d,
  ): SnapContext | null {
    let leftWalls = [this.floorPlan.LeftWall];
    let rightWalls = [this.floorPlan.RightWall];
    let topWalls = [this.floorPlan.TopWall];
    let bottomWalls = [this.floorPlan.BottomWall];

    if (
      this.floorPlan.TopLeftCorner.CornerType ==
      Interface_Enums.CornerType.Inverted
    ) {
      leftWalls.push(this.floorPlan.TopLeftCorner.Wall2!);
      topWalls.push(this.floorPlan.TopLeftCorner.Wall1!);
    }
    if (
      this.floorPlan.TopRightCorner.CornerType ==
      Interface_Enums.CornerType.Inverted
    ) {
      topWalls.push(this.floorPlan.TopRightCorner.Wall2!);
      rightWalls.push(this.floorPlan.TopRightCorner.Wall1!);
    }
    if (
      this.floorPlan.BottomRightCorner.CornerType ==
      Interface_Enums.CornerType.Inverted
    ) {
      rightWalls.push(this.floorPlan.BottomRightCorner.Wall2!);
      bottomWalls.push(this.floorPlan.BottomRightCorner.Wall1!);
    }
    if (
      this.floorPlan.BottomLeftCorner.CornerType ==
      Interface_Enums.CornerType.Inverted
    ) {
      bottomWalls.push(this.floorPlan.BottomLeftCorner.Wall2!);
      leftWalls.push(this.floorPlan.BottomLeftCorner.Wall1!);
    }

    let regions = this.partitionSnapService.calculateFloorPlanWallSnapRegions(
      leftWalls,
      rightWalls,
      topWalls,
      bottomWalls,
    );

    for (let region of regions) {
      if (
        mousePosition.X >= region.rect.X &&
        mousePosition.X <= region.rect.X + region.rect.Width &&
        mousePosition.Y >= region.rect.Y &&
        mousePosition.Y <= region.rect.Y + region.rect.Height
      ) {
        return region.calculateSectionPosition(
          this.partitionQuery.selectedSection!.id,
          mousePosition,
          this.partitionDragInfo!.mouseOffset,
        );
      }
    }

    return null;
  }

  private snapToPartitions(
    mousePosition: Interface_DTO_Draw.Vec2d,
  ): SnapContext | null {
    let otherSections = this.partitionQuery
      .getAllSections()
      .filter((sec) => sec.id != this.partitionQuery.selectedSection!.id);

    for (let section of otherSections) {
      let regions = [
        ...this.partitionSnapService.calculateSectionRelativeBottomSnapRegions(
          section.id,
        ),
        ...this.partitionSnapService.calculateSectionRelativeTopSnapRegions(
          section.id,
        ),
      ];

      for (let region of regions) {
        if (
          mousePosition.X >= region.rect.X &&
          mousePosition.X <= region.rect.X + region.rect.Width &&
          mousePosition.Y >= region.rect.Y &&
          mousePosition.Y <= region.rect.Y + region.rect.Height
        ) {
          return region.calculateSectionPosition(
            this.partitionQuery.selectedSection!.id,
            mousePosition,
            this.partitionDragInfo!.mouseOffset,
          );
        }
      }
    }

    return null;
  }

  private clampDragging(
    section: Readonly<Section>,
    modelPos: Interface_DTO_Draw.Vec2d,
    dragType: PartitionDragType,
  ) {
    if (
      dragType == PartitionDragType.XAxisDrag ||
      dragType == PartitionDragType.FreeDrag
    ) {
      let newX = modelPos.X - this.partitionDragInfo!.mouseOffset.X;

      if (dragType == PartitionDragType.XAxisDrag) {
        newX = ObjectHelper.clamp(
          this.partitionGeometryQuery.calculateTPieceMiddleSectionMinX(
            section.id,
          ),
          newX,
          this.partitionGeometryQuery.calculateTPieceMiddleSectionMaxX(
            section.id,
          ),
        );
      }

      //Clamp inside floorplan
      let min = this.partitionGeometryQuery.getSectionMinAllowedX(section.id);
      let max = this.partitionGeometryQuery.getSectionMaxAllowedX(section.id);
      newX = Math.min(Math.max(min, newX), max);

      this.partitionDragInfo!.item.X = Math.round(newX);
    }
    if (
      dragType == PartitionDragType.YAxisDrag ||
      dragType == PartitionDragType.FreeDrag
    ) {
      let newY = modelPos.Y - this.partitionDragInfo!.mouseOffset.Y;

      if (dragType == PartitionDragType.YAxisDrag) {
        newY = ObjectHelper.clamp(
          this.partitionGeometryQuery.calculateTPieceMiddleSectionMinY(
            section.id,
          ),
          newY,
          this.partitionGeometryQuery.calculateTPieceMiddleSectionMaxY(
            section.id,
          ),
        );
      }

      //Clamp inside floorplan
      let min = this.partitionGeometryQuery.getSectionMinAllowedY(section.id);
      let max = this.partitionGeometryQuery.getSectionMaxAllowedY(section.id);
      newY = Math.min(Math.max(min, newY), max);

      this.partitionDragInfo!.item.Y = Math.round(newY);
    }
  }

  public dragInfo(evt: Event | MouseEvent | TouchEvent): void {
    if (!this.cabinetDragInfo) return;
    evt.preventDefault(); //prevent default to avoid selecting text while dragging

    if (evt instanceof MouseEvent && evt.which === 0) {
      //No mousebutton is pressed
      this.cancelMove();
      return;
    }
    if (!this.cabinetDragInfo.dragActive) {
      let currentPos = this.getPosFromEvent(evt);
      if (
        VectorHelper.dist(this.cabinetDragInfo.startPos, currentPos) >
        FloorPlanImageVm.minDragPixels
      ) {
        //start drag here

        if (this.cabinetDragInfo.sourceWall && this.cabinetDragInfo.item) {
          //remove item from original wall
          let itemIndex = this.cabinetDragInfo.sourceWall.Items.indexOf(
            this.cabinetDragInfo.item,
          );
          if (itemIndex > -1)
            this.cabinetDragInfo.sourceWall.Items.splice(itemIndex, 1);
        }
        this.cabinetDragInfo.dragActive = true;
      } else return;
    }
    const modelPos = VectorHelper.add(this.getModelPos(evt));

    let product = this.cabinetDragInfo.product;
    let itemWidth = this.getCurrentItemWidth(this.cabinetDragInfo);
    let itemDepth = this.getCurrentItemDepth(this.cabinetDragInfo);

    this.cabinetDragInfo.item.Width = itemWidth;
    const wallPos = this.getNearestPositionOnWall(
      modelPos,
      itemWidth,
      this.cabinetDragInfo.mouseOffset,
      product.positioning,
      this.cabinetDragInfo.sourceWall,
    );
    if (!wallPos) return;

    const targetWall =
      wallPos.wall || this.cabinetDragInfo.sourceWall || this.walls[0];

    const snapOutline: FloorPlanImageVm.Outline = this.calculateSnapOutline(
      modelPos,
      this.cabinetDragInfo.item,
      wallPos,
      targetWall,
      itemDepth,
      itemWidth,
    );

    if (
      this.cabinetDragInfo?.item.ItemType !=
      Interface_DTO_FloorPlan.ItemType.Partition
    ) {
      this.cabinetDragInfo.item.X = wallPos.X;
      this.cabinetDragInfo.item.Y = wallPos.Y;
    }

    if (
      this.cabinetDragInfo?.item.ItemType ==
      Interface_DTO_FloorPlan.ItemType.Partition
    ) {
      modelPos.X = ObjectHelper.clamp(
        0,
        modelPos.X,
        this.floorPlan.Size.X - itemWidth,
      );
      modelPos.Y = ObjectHelper.clamp(
        0,
        modelPos.Y,
        this.floorPlan.Size.Y - itemDepth,
      );
      this.cabinetDragInfo.item.X = modelPos.X;
      this.cabinetDragInfo.item.Y = modelPos.Y;
      snapOutline.X = modelPos.X;
      snapOutline.Y = modelPos.Y;
    }

    this.cabinetDragInfo.snapOutline = snapOutline;
  }

  private calculateSnapOutline(
    modelPos: Interface_DTO_Draw.Vec2d,
    item: Interface_DTO_FloorPlan.Item,
    wallPos: FloorPlanImageVm.WallPos,
    targetWall: Interface_DTO_FloorPlan.WallSection,
    itemDepth: number,
    itemWidth: number,
  ): FloorPlanImageVm.Outline {
    if (item.ItemType == Interface_DTO_FloorPlan.ItemType.Partition) {
      return {
        X: modelPos.X,
        Y: modelPos.Y,
        wall: targetWall,
        size: {
          X: itemDepth,
          Y: itemWidth,
        },
        svgTransformString: this.getItemTransformString(item),
      };
    }

    return {
      ...wallPos,
      wall: targetWall,
      size: {
        X: itemDepth,
        Y: itemWidth,
      },
      svgTransformString:
        this.getWallTransformString(targetWall) +
        ' translate( 0 ' +
        (wallPos.Y - item.Y) +
        ' ) ' +
        this.getItemTransformString(item),
    };
  }

  private cancelMove() {
    if (!this.cabinetDragInfo) return;
    if (!this.cabinetDragInfo.sourceWall) return;
    if (!this.cabinetDragInfo.dragActive) return;
    this.cabinetDragInfo.item.X = 0;
    this.cabinetDragInfo.sourceWall.Items.push(this.cabinetDragInfo.item);
    this.cabinetDragInfo = null;
  }

  public async mouseClick(evt: MouseEvent) {
    console.log('Click: ', this.getModelPos(evt));
  }

  public mousedownInsideCanvas = false;
  public clickCanvas() {
    this.mousedownInsideCanvas = true;
    this.isDragging.emit(true);
  }

  public mouseUp(evt: Event | MouseEvent | TouchEvent) {
    if (
      this.cabinetDragInfo == null &&
      this.partitionDragInfo == null &&
      this.measurementDragInfo == null &&
      this.mousedownInsideCanvas
    )
      this.unselect();
    this.mousedownInsideCanvas = false;

    if (this.cabinetDragInfo) this.cabinetMouseUp(evt);

    if (this.partitionDragInfo) this.partitionMouseUp(evt);

    if (this.measurementDragInfo) this.measurementMouseUp(evt);

    this.measurementService.recalculateMeasurements(this.floorPlan);
    this.isDragging.emit(false);
  }

  public async measurementMouseUp(evt: Event | MouseEvent | TouchEvent) {
    if (!this.measurementDragInfo!.dragActive) {
      this.measurementDragInfo = null;
      return;
    }

    this.measurementService.selectMeasurement(this.measurementDragInfo!.item);
    this.selectedItem = {
      FlipDirection: false,
      FlipSide: false,
      ItemType: Interface_DTO_FloorPlan.ItemType.Measurement,
      measurement: this.measurementDragInfo!.item,
    } as Interface_DTO_FloorPlan.Item;

    this.measurementDragInfo = null;
    await this.floorPlanService.setChanged(this.floorPlan);
  }

  public async partitionMouseUp(evt: Event | MouseEvent | TouchEvent) {
    if (!this.partitionDragInfo?.dragActive) {
      this.partitionDragInfo = null;
      return;
    }

    const item = this.partitionDragInfo!.item;
    let posX = Math.round(item.X);
    let posY = Math.round(item.Y);
    this.partitionCommands.updateSectionPosition(
      item.partition!.section,
      posX,
      posY,
    );

    if (this.partitionDragInfo.snapContext) {
      this.partitionSnapService.executeSnap(this.partitionDragInfo.snapContext);
    }

    this.partitionDragInfo = null;
    await this.floorPlanService.setChanged(this.floorPlan);
  }

  /**
   * Called especially on drag end when the mouse is released
   */
  public async cabinetMouseUp(evt: Event | MouseEvent | TouchEvent) {
    if (!this.cabinetDragInfo) return;
    if (!this.cabinetDragInfo.dragActive) {
      this.cabinetDragInfo = null;
      return;
    }
    const modelPos = this.getModelPos(evt);
    const item = this.cabinetDragInfo.item;
    const length = item ? item.Width : this.selectedProduct!.size.X;

    const posOnWall = this.getNearestPositionOnWall(
      modelPos,
      length,
      this.cabinetDragInfo.mouseOffset,
      this.cabinetDragInfo.product.positioning,
      this.cabinetDragInfo.sourceWall,
    );
    if (!posOnWall) return;

    item.X = posOnWall.X;
    item.Y = posOnWall.Y;

    if (this.cabinetDragInfo.productLine?.Id == ProductLineIds.Partition) {
      if (this.floorPlan.Size.Z > this.partitionQuery.getModuleMaxHeight())
        this.floorPlan.Size.Z = this.partitionQuery.getModuleMaxHeight();

      // Currently there is only one kind of Partition
      item.X = modelPos.X;
      item.Y = modelPos.Y;
      let section = this.partitionCommands.addSection(
        modelPos.X < 0 ? 0 : modelPos.X,
        modelPos.Y < 0 ? 0 : modelPos.Y,
        this.floorPlan.Size.Z,
      );

      //Clamp inside floorplan
      let minX = this.partitionGeometryQuery.getSectionMinAllowedX(section.id);
      let maxX = this.partitionGeometryQuery.getSectionMaxAllowedX(section.id);
      let newX = Math.min(Math.max(minX, modelPos.X), maxX);

      let minY = this.partitionGeometryQuery.getSectionMinAllowedY(section.id);
      let maxY = this.partitionGeometryQuery.getSectionMaxAllowedY(section.id);
      let newY = Math.min(Math.max(minY, modelPos.Y), maxY);

      this.partitionCommands.updateSectionPosition(section.id, newX, newY);

      this.partitionCommands.setSelection(section.id, section.modules[0]);
      item.partition = {
        section: section.id,
        module: section.modules[0],
      };
      item.CabinetIndex = section.id;
      this.selectedItem$.next(item);
    } else {
      let targetWall =
        posOnWall.wall || this.cabinetDragInfo.sourceWall || this.walls[0];
      targetWall.Items.push(item);

      if (
        this.cabinetDragInfo.product.cabinetType !== null &&
        !item.CabinetIndex &&
        this.cabinetDragInfo.productLine
      ) {
        this.cabinetService.createCabinet(
          this.floorPlan,
          item,
          this.cabinetDragInfo.productLine.Id,
        );
      }
    }

    this.selectedItem$.next(item);
    this.floorPlan.isDirty = true;
    this.cabinetDragInfo = null;
    this.setSectionsChanged();
    await this.floorPlanService.setChanged(this.floorPlan);
  }

  private getNearestPositionOnWall(
    modelPos: Interface_DTO_Draw.Vec2d,
    itemLength: number,
    mouseOffset: Interface_DTO_Draw.Vec2d,
    positioning: Client.Positioning,
    sourceWall: Interface_DTO_FloorPlan.WallSection | null,
  ): FloorPlanImageVm.WallPos | null {
    let nearestWall = FloorPlanHelper.getNearestWall(
      this.walls,
      modelPos,
      itemLength,
    );
    if (!nearestWall) return null;
    let targetWall = nearestWall;
    let wallPos = this.getPositionOnWall(
      targetWall,
      modelPos,
      itemLength,
      mouseOffset,
      positioning,
    );

    let snapDist = 300;
    if (sourceWall && wallPos.Y > snapDist) {
      targetWall = sourceWall;
      wallPos = this.getPositionOnWall(
        sourceWall,
        modelPos,
        itemLength,
        mouseOffset,
        positioning,
        snapDist,
      );
    } else {
      wallPos.Y = 0;
      wallPos.X = Math.max(wallPos.X, 0);
    }

    return {
      ...wallPos,
      wall: targetWall,
    };
  }

  private getPositionOnWall(
    wall: Interface_DTO_FloorPlan.WallSection,
    modelPos: Interface_DTO_Draw.Vec2d,
    itemLength: number,
    mouseOffset: Interface_DTO_Draw.Vec2d,
    positioning: Client.Positioning,
    snapDist?: number,
  ): Interface_DTO_Draw.Vec2d {
    const wallRotation = FloorPlanHelper.getRotation(wall);
    const rotatedMouseOffset = VectorHelper.rotate2d(mouseOffset, wallRotation);
    const offsetModelPos = VectorHelper.add(modelPos, rotatedMouseOffset);
    let begin, end;
    if (snapDist && wall.ExtraBegin && wall.ExtraEnd) {
      begin = wall.ExtraBegin;
      end = wall.ExtraEnd;
    } else {
      begin = wall.Begin;
      end = wall.End;
    }

    const closestPointOnWall = VectorHelper.getClosestPointOnLineSegment(
      begin,
      end,
      offsetModelPos,
    );
    const xStartDistOffset = wall.ExtraBegin
      ? VectorHelper.dist(wall.ExtraBegin, wall.Begin)
      : 0;
    const x = VectorHelper.dist(begin, closestPointOnWall);
    const xMax = VectorHelper.dist(begin, end) - itemLength;

    let wallPosX: number;
    if (snapDist) {
      wallPosX = Math.min(x - xStartDistOffset, xMax - xStartDistOffset);
    } else {
      wallPosX = Math.min(x, xMax);
    }

    let wallPosY: number;
    if (positioning != Client.Positioning.OnWall) {
      let wallOffset = VectorHelper.subtract(
        closestPointOnWall,
        offsetModelPos,
      );
      let normalizedWallOffset = VectorHelper.rotate2d(
        wallOffset,
        -wallRotation,
      );
      wallPosY = -normalizedWallOffset.X;
    } else {
      wallPosY = 0;
    }

    return {
      X: wallPosX,
      Y: wallPosY,
    };
  }

  // #region wall path

  public getWallsPath() {
    let insideWallsPoints = FloorPlanImageVm.getInsideWallsPoints(this.walls);
    let outsidePoints = FloorPlanImageVm.getOutsideWallsPoints(
      this.walls,
      this.wallThickness,
    );
    let allPoints = [
      ...outsidePoints,
      outsidePoints[0],
      ...insideWallsPoints,
      insideWallsPoints[0],
    ];
    return 'M' + this.getPathsString(allPoints).join(' ') + ' Z';
  }

  public getInsideWallsPath() {
    return (
      'M' +
      this.getPathsString(
        FloorPlanImageVm.getInsideWallsPoints(this.walls),
      ).join(' ') +
      ' Z'
    );
  }

  private static getInsideWallsPoints(
    walls: Interface_DTO_FloorPlan.WallSection[],
  ): Interface_DTO_Draw.Vec2d[] {
    return walls.map((wall) => wall.Begin);
  }

  private static getOutsideWallsPoints(
    walls: Interface_DTO_FloorPlan.WallSection[],
    wallThickness: number,
  ): Interface_DTO_Draw.Vec2d[] {
    let offset = { X: 0, Y: -wallThickness };
    let points = Enumerable.from(walls).selectMany((wall) => {
      let wallAngle = FloorPlanHelper.angle(wall);
      let rotatedOffset = VectorHelper.rotate2d(offset, wallAngle);
      let begin = VectorHelper.add(wall.Begin, rotatedOffset);
      let end = VectorHelper.add(wall.End, rotatedOffset);
      return [begin, end];
    });
    return points.toArray();
  }
  private getPathsString(points: Interface_DTO_Draw.Vec2d[]): string[] {
    let result: string[] = [];
    let first = true;
    for (let point of points) {
      if (!first) result.push('L');
      try {
        result.push(point.X.toString(), point.Y.toString());
      } catch (e: any) {
        //errors.push({ e, point });
        //just ignore
      }
      first = false;
    }
    return result;
  }

  // #endregion wall path

  public getWallTransformString(
    wall: Interface_DTO_FloorPlan.WallSection,
  ): string {
    let result = ' translate( ' + wall.Begin.X + ', ' + wall.Begin.Y + ' )';
    result +=
      'rotate( ' + (FloorPlanHelper.angle(wall) / Math.PI) * 180 + ', 0, 0 )';
    return result;
  }

  public getWallLength(wall: Interface_DTO_FloorPlan.WallSection): number {
    return VectorHelper.dist(wall.Begin, wall.End);
  }

  public getPartitionSectionTransformString(section: Partition.Section) {
    if (this.isSectionSelected(section) && this.partitionDragInfo?.dragActive) {
      let item = this.partitionDragInfo!.item;
      let result = ` translate( ${item.X} ${item.Y} ) `;
      result += ` rotate(${section.rotation})`;
      return result;
    } else {
      return ` translate(${section.posX} ${section.posY}) rotate(${section.rotation}) `;
    }
  }

  public getPartitionDoorYOffset(door: Door): number {
    return door.placement == DoorPlacement.Front
      ? Module.fullJointWidth
      : -Module.fullJointWidth;
  }

  public getPartitionProfileTranformString(profile: Profile) {
    let result = ` rotate(${profile.rotation} ${profile.posX} ${profile.posY}) `;
    return result;
  }

  public getPartitionProfileWidth(profile: Profile): number {
    return this.partitionQuery.isJointProfile(profile.id)
      ? this.partitionJointSize
      : 17.5;
  }

  public getItemTransformString(
    item: Interface_DTO_FloorPlan.Item | null,
  ): string {
    let result = '';
    if (item) {
      result += ' translate( ' + item.X + ' ' + item.Y + ' ) ';
      if (item.FlipSide)
        result += ' scale( -1 1 ) translate( ' + -item.Width + ' ) ';
      if (item.FlipDirection) result += ' scale ( 1 -1 ) ';
      let product = this.products[item.ItemType];
      if (product) {
        result +=
          ' translate( ' +
          product.displayOffset.Y +
          ' ' +
          product.displayOffset.X +
          ' ) ';
      }
    }
    return result;
  }

  public get selectedPartitionSection(): Readonly<Section> | undefined {
    return this.partitionQuery.selectedSection;
  }
  public isSectionSelected(section: Partition.Section) {
    return section.id == this.partitionDragInfo?.item.partition?.section;
  }

  public isModuleSelected(module: Partition.Module) {
    return this.partitionQuery.selectedModule?.id == module.id;
  }

  public isMeasurementSelected(measurement: MeasurementData) {
    return measurement.id == this.measurementService.selectedMeasurement?.id;
  }

  public doesModuleHaveError(module: Partition.Module) {
    let section = this.partitionQuery.getSection(module.sectionId);

    return module.hasError || section.hasError;
  }

  public getCabinetSectionTransformString(
    cabinetSection: Client.CabinetSection,
  ): string {
    return (
      ' rotate( ' +
      -cabinetSection.Rotation +
      ' ' +
      cabinetSection.PositionX +
      ' ' +
      cabinetSection.PositionY +
      ' ) ' +
      ' translate( ' +
      cabinetSection.PositionX +
      ' ' +
      cabinetSection.PositionY +
      ' ) '
    );
  }

  //Rotates text 180 degrees if it's upside-down
  public getTextRotateTransformString(section: Client.CabinetSection): string {
    let rotateMin = Math.PI * 0.6;
    let rotateMax = Math.PI * 1.4;
    let tau = 2 * Math.PI;
    let x = section.Width / 2;
    let y = section.Depth / 2;
    let rotationRad = ((section.floorPlanRotation % tau) + tau) % tau;
    if (rotationRad > rotateMin && rotationRad < rotateMax) {
      return 'rotate( 180 ' + x + ' ' + y + ' ) ';
    } else {
      return '';
    }
  }

  /** SVG */
  private _product!: Client.FloorPlanProduct;
  public get product(): Client.FloorPlanProduct {
    return this._product;
  }

  public updateProduct(item: Interface_DTO_FloorPlan.Item): void {
    this._product = this.products[item.ItemType];
  }

  /** SVG */
  private _cabinet: Client.Cabinet | undefined;
  public get cabinet(): Client.Cabinet | undefined {
    return this._cabinet;
  }

  public updateCabinet(item: Interface_DTO_FloorPlan.Item): void {
    this._cabinet = this.getCabinet(item);
  }

  public getCabinet(
    item: Interface_DTO_FloorPlan.Item,
  ): Client.Cabinet | undefined {
    return item.CabinetIndex !== null
      ? this.floorPlan.cabinets.filter(
          (cab) => cab.CabinetIndex == item.CabinetIndex,
        )[0]
      : undefined;
  }
  public getCabinetSectionName(section: Client.CabinetSection): string {
    if (section.cabinet.cabinetSections.length > 1) {
      if (this.displayProductLine(section)) {
        return section.cabinet.Name + ' - ' + section.CabinetSectionIndex;
      } else {
        return '' + section.CabinetSectionIndex;
      }
    } else {
      return section.cabinet.Name;
    }
  }

  public displayProductLine(section: Client.CabinetSection): boolean {
    switch (section.cabinet.CabinetType) {
      case Interface_Enums.CabinetType.WalkIn:
        return section.CabinetSectionIndex === 2;
      default:
        return section.CabinetSectionIndex === 1;
    }
  }

  public getNamePosX(section: Client.CabinetSection): number {
    if (section.Depth < FloorPlanImageVm.minSectionDepthForCenteredText) {
      return section.Width / 4;
    }
    return section.Width / 2;
  }
  public getNamePosY(section: Client.CabinetSection): number {
    if (section.Depth < FloorPlanImageVm.minSectionDepthForCenteredText) {
      return section.Depth;
    }
    return section.Depth / 2;
  }
  public getProductLinePosX(section: Client.CabinetSection): number {
    if (section.Depth < FloorPlanImageVm.minSectionDepthForCenteredText) {
      return (section.Width / 4) * 3;
    }
    return section.Width / 2;
  }
  public getProductLinePosY(section: Client.CabinetSection): number {
    //if (section.Depth < FloorPlanImageVm.minSectionDepthForCenteredText) {

    //}
    return (section.Depth / 4) * 3;
  }

  public getPartitionSectionIndex(section: FloorPlanSection): number {
    return this.partitionQuery.getPartitionSectionIndex(section.id);
  }

  private getCurrentItemWidth(dragInfo: FloorPlanImageVm.DragInfo): number {
    if (dragInfo.item) {
      if (dragInfo.item.CabinetIndex !== null) {
        let backwallSection = Enumerable.from(this.floorPlan.cabinets).first(
          (cab) => cab.CabinetIndex === dragInfo.item.CabinetIndex,
        ).backwallSection;
        if (((backwallSection.Rotation % 180) + 180) % 180 === 90) {
          return backwallSection.Depth;
        } else {
          return backwallSection.Width;
        }
      } else {
        return dragInfo.item.Width;
      }
    } else {
      return dragInfo.product.size.Y;
    }
  }

  private getCurrentItemDepth(dragInfo: FloorPlanImageVm.DragInfo): number {
    if (dragInfo.item) {
      if (dragInfo.item.CabinetIndex !== null) {
        let backwallSection = Enumerable.from(this.floorPlan.cabinets).first(
          (cab) => cab.CabinetIndex === dragInfo.item.CabinetIndex,
        ).backwallSection;
        if (((backwallSection.Rotation % 180) + 180) % 180 === 90) {
          return backwallSection.Width;
        } else {
          return backwallSection.Depth;
        }
      } else {
        return dragInfo.item.Height;
      }
    } else {
      return dragInfo.product.size.X;
    }
  }

  private getMouseOffset(
    evt: Event,
    item: Interface_DTO_FloorPlan.Item,
    wall: Interface_DTO_FloorPlan.WallSection,
  ): Interface_DTO_Draw.Vec2d {
    let mouseModelPos = this.getModelPos(evt);
    let itemPos = FloorPlanHelper.getPosOnWall(wall, item.X);
    let offset = VectorHelper.subtract(itemPos, mouseModelPos);
    let rotation = FloorPlanHelper.getRotation(wall);
    let result = VectorHelper.rotate2d(offset, -rotation);
    result.X += item.Y;
    return result;
  }

  private getPartitionMouseOffset(evt: Event): Interface_DTO_Draw.Vec2d {
    let mouseModelPos = this.getModelPos(evt);
    let sectionPos = {
      X: this.partitionQuery.selectedSection!.posX,
      Y: this.partitionQuery.selectedSection!.posY,
    };
    let offset = VectorHelper.subtract(mouseModelPos, sectionPos);

    return offset;
  }

  private getMeasurementMouseOffset(
    measurement: MeasurementData,
    evt: Event,
  ): Interface_DTO_Draw.Vec2d {
    let mouseModelPos = this.getModelPos(evt);
    let itemPos = { X: measurement.posX, Y: measurement.posY };
    let offset = VectorHelper.subtract(mouseModelPos, itemPos);
    return offset;
  }

  private getWallRulers(): Client.Ruler[] {
    let rulerHeight = 60;
    let topmostRuler = new Client.Ruler(
      false,
      { X: 0, Y: this.wallThickness + rulerHeight },
      this.floorPlan.Size.X,
      0,
      false,
    );
    let leftmostRuler = new Client.Ruler(
      true,
      { X: -this.wallThickness - rulerHeight, Y: 0 },
      this.floorPlan.Size.Y,
      this.floorPlan.Size.Y,
      false,
    );

    return [topmostRuler, leftmostRuler];
  }

  public round(val: number): number {
    return Math.round(val);
  }

  public get partitionJointSize(): number {
    return Module.fullJointWidth;
  }
}

export module FloorPlanImageVm {
  export interface DragInfo extends BaseDragInfo {
    cabinet: Client.Cabinet | null;
    sourceWall: Interface_DTO_FloorPlan.WallSection | null;
  }

  export interface BaseDragInfo extends SimpleDragInfo {
    item: Interface_DTO_FloorPlan.Item;
    product: Client.FloorPlanProduct;
    snapOutline: Outline | null;
    productLine?: Interface_DTO.ProductLine;
  }

  export interface PartitionDragInfo extends BaseDragInfo {
    snapContext?: SnapContext;
    prevItemPosition?: Interface_DTO_Draw.Vec2d;
    multiDragRange?: { min?: number; max?: number };
  }

  export interface MeasurementDragInfo extends SimpleDragInfo {
    item: MeasurementId;
  }

  export interface SimpleDragInfo {
    startPos: Interface_DTO_Draw.Vec2d;
    mouseOffset: Interface_DTO_Draw.Vec2d;
    dragActive: boolean;
  }

  export interface Outline extends Interface_DTO_Draw.Vec2d {
    size: Interface_DTO_Draw.Vec2d;
    wall?: Interface_DTO_FloorPlan.WallSection;
    svgTransformString: string;
  }

  export interface Rectangle {
    position: Interface_DTO_Draw.Vec2d;
    size: Interface_DTO_Draw.Vec2d;
  }

  export interface SnapPos {
    position: Interface_DTO_Draw.Vec2d;
    rotation: number;
    wall: Interface_DTO_FloorPlan.WallSection;
    flipSide: boolean | null;
    flipDirection: boolean | null;
  }

  export interface SnapInfo {
    snappedToSide: boolean;
    snappedToCorner: boolean;
    snappedTo: string;
    otherSection?: SectionId;
  }

  export interface WallPos extends Interface_DTO_Draw.Vec2d {
    wall: Interface_DTO_FloorPlan.WallSection;
  }
}
