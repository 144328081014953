import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { ModelOptions } from 'app/ng/ModelOptions';
import { Section } from '../floor-plan';
import { AssetService } from 'app/ts/services/AssetService';
import { ProductLineIds } from 'app/ts/InterfaceConstants';

@Component({
  selector: 'partition-properties',
  templateUrl: 'partition-properties.component.html',
  styleUrls: ['../../../style/editor.scss', 'property-sheet.scss'],
})
export class PartitionPropertiesComponent implements OnInit {
  @Input()
  public section!: Readonly<Section>;

  @Input({ required: true })
  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
    this.nameChange.emit(name);
  }
  private _name: string = '';

  @Output()
  public nameChange = new EventEmitter<string>();

  @Output()
  public deletePartition = new EventEmitter<void>();

  public sectionHeader: string;

  constructor(
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private assetService: AssetService,
  ) {
    var partitionProductLine = this.assetService.editorAssets.productLines.find(
      (pl) => pl.Id == ProductLineIds.Partition,
    ) ?? { Name: 'Partition' };
    this.sectionHeader = this.translateService.translate(
      'partition {0}',
      '{0}',
      partitionProductLine.Name,
    );
  }

  ngOnInit(): void {
    this.translateService.translationsUpdated$.subscribe(() => {
      var partitionProductLine =
        this.assetService.editorAssets.productLines.find(
          (pl) => pl.Id == ProductLineIds.Partition,
        ) ?? { Name: 'Partition' };
      this.sectionHeader = this.translateService.translate(
        'partition {0}',
        '{0}',
        partitionProductLine.Name,
      );
      this.changeDetector.detectChanges();
    });
  }

  public requestDelete() {
    let sure = confirm(
      this.translateService.translate(
        'floorPlan_confirm_delete_question',
        'Are you sure you want to delete this?',
      ),
    );
    if (!sure) return;

    this.deletePartition.emit();
  }

  public readonly modelOptions: ModelOptions = {
    updateOn: 'blur',
  };
}
