<div class="modal-content consumer">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]='translate("consumer_doorProfile_modal_title", "Select Door Profile")'
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <item-picker
      [items]="doorProfiles"
      [(selectedItem)]="currentProfile"
      [maxItems]="99"
      [name]="translate('consumer_doors_profile', 'Profile')"
    >
    </item-picker>

    <div class="door-profile-description">
      <div class="thumbnail" *ngIf="currentProfileDescription">
        <img [src]="currentProfileDescription.bigImageUrl" />
        <h3>{{currentProfileDescription.header}}</h3>
        <p style="white-space: pre-line">
          {{currentProfileDescription.longText}}
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-secondary"
        (click)="cancel()"
        [textContent]="translate('consumer_doorProfile_modal_cancel','Close')"
      ></button>
      <button
        class="btn btn-primary"
        (click)="select()"
        [textContent]="translate('consumer_doorProfile_modal_ok','Select')"
      ></button>
    </div>
  </div>
</div>
