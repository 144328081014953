<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('modal_joint_position_title', 'Edit Joint Positions')"
    ></h3>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="close()">
      <fieldset>
        <label>
          <input
            type="checkbox"
            [(ngModel)]="automaticPositioning"
            name="automaticPositioning"
          />
          <span
            [textContent]="translate('modal_joint_position_automatic', 'Automatic positioning')"
          ></span>
        </label>

        <div
          class="joint-positions top-joint-positions"
          *ngIf="topJoints.length > 0"
        >
          <h4 [textContent]="translate('modal_joint_header_top', 'Top')"></h4>
          <div
            class="input-group"
            *ngFor="let joint of topJoints; let i = index"
            [attr.title]="translate('modal_joint_position_title_min{0}_max{1}', '{0} - {1} mm', joint.minValue, joint.maxValue)"
          >
            <span
              class="input-group-text"
              [textContent]="translate('modal_joint_position', 'Position')"
            ></span>
            <input
              type="number"
              name="top-joint-position-{{i}}"
              class="form-control"
              [disabled]="automaticPositioning"
              [(ngModel)]="joint.actualValue"
              [ngModelOptions]="modelOptionsBlur"
              [attr.min]="joint.minValue"
              [attr.max]="joint.maxValue"
            />
            <span class="input-group-text">mm</span>
          </div>
        </div>

        <div
          class="joint-positions bottom-joint-positions"
          *ngIf="bottomJoints.length > 0"
        >
          <h4
            [textContent]="translate('modal_joint_header_bottom', 'Bottom')"
          ></h4>
          <div
            class="input-group"
            *ngFor="let joint of bottomJoints; let i = index"
            [attr.title]="translate('modal_joint_position_title_min{0}_max{1}', '{0} - {1} mm', joint.minValue, joint.maxValue)"
          >
            <span
              class="input-group-text"
              [textContent]="translate('modal_joint_position', 'Position')"
            ></span>
            <input
              type="number"
              name="bottom-joint-position-{{i}}"
              class="form-control"
              [disabled]="automaticPositioning"
              [(ngModel)]="joint.actualValue"
              [ngModelOptions]="modelOptionsBlur"
              [attr.min]="joint.minValue"
              [attr.max]="joint.maxValue"
            />
            <span class="input-group-text">mm</span>
          </div>
        </div>
      </fieldset>
    </form>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="close()"
        [textContent]="translate('button_close', 'Close')"
      ></button>
    </div>
  </div>
</div>
