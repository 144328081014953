import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { SubSection } from 'app/ts/clientDto/SubSection';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { BackingArea } from 'app/ts/clientDto/BackingArea';
import { Product } from 'app/ts/clientDto/Product';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { BackingService } from 'app/ts/services/ConfigurationLogic/BackingService';
import { FilterService } from 'app/ts/services/FilterService';
export class Backing implements SubSection {
  constructor(
    private readonly cabinetSection: Client.CabinetSection,
    private dtoSection: Interface_DTO.CabinetSection,
  ) {}

  public fittingCubes: Interface_DTO_Draw.Cube[] = [];

  items: Client.ConfigurationItem[] = new Array<Client.ConfigurationItem>();

  public backingAreas: Client.BackingArea[] = [];

  //#region Availables, backing variables

  private cache: {
    availableProducts?: Enumerable.IEnumerable<Product>;
    availableTypes?: Interface_Enums.BackingType[];
    pickableTypes?: Pickable<Interface_Enums.BackingType>[];
    pickableMaterials?: Pickable<Client.Material>[];
    material?: Interface_DTO.Material | null;
    backingType?: Interface_Enums.BackingType;
    fittingMaterial?: Interface_DTO.Material;
    thickness?: number;
    frontZ?: number;
  } = {};

  //#endregion Availables, backing variables

  public loadFrom(dtoSection: Interface_DTO.CabinetSection): void {
    this.dtoSection = dtoSection;

    this.backingAreas = [];

    if (dtoSection.BackingItems.length > 0) {
      let firstItem = dtoSection.BackingItems[0];

      // Backing type (visible or hidden)
      //var hidden = firstItem.Z > -1;
      //this.backingType = hidden && this.allowHidden
      //    ? Interface_Enums.BackingType.Hidden
      //    : Interface_Enums.BackingType.Visible;

      // Areas
      let gables = this.cabinetSection.interior.gables;
      for (var i = 0; i <= gables.length; i++) {
        let leftX: number;
        if (i === 0) {
          leftX = CabinetSectionHelper.getBackingDeductionLeft(
            this.cabinetSection,
          );
          if (this.cabinetSection.leftNeighbor) {
            leftX += this.cabinetSection.leftNeighbor.backing.frontZ;
          }
        } else {
          leftX = gables[i - 1].rightX;
        }

        let rightX =
          i < gables.length
            ? gables[i].leftX
            : this.cabinetSection.Width -
              CabinetSectionHelper.getBackingDeductionRight(
                this.cabinetSection,
              );

        let distance = rightX - leftX;
        if (distance > 5) {
          let center = leftX + distance / 2;
          let enabled = dtoSection.BackingItems.some(
            (bi) => bi.X < center && bi.X + bi.Width > center,
          );

          let newArea = new BackingArea();
          newArea.enabled = enabled;
          newArea.position = { X: leftX, Y: 0 };
          newArea.size = { X: distance, Y: this.cabinetSection.Height };
          this.backingAreas.push(newArea);
        }
      }
    } else {
      this.setBackingType(Interface_Enums.BackingType.None, false);
    }

    this.clearBackingVariables();
  }

  public saveTo(dtoSection: Interface_DTO.CabinetSection): void {
    dtoSection.BackingItems = this.items.map((i) => i.save());
  }

  //#region Actual values, private properties

  public getProduct(width: number): Product | null {
    let bestProduct = this.bestWidthProduct(
      this.availableProducts.toArray(),
      width,
    );
    if (!bestProduct) {
      //No valid product available. Try again with all products
      let allBackingProducts = this.editorAssets.products.filter(
        (p) => p.ProductType === Interface_Enums.ProductType.Backing,
      );
      bestProduct = this.bestWidthProduct(allBackingProducts, width);
    }
    return bestProduct;
  }

  private bestWidthProduct(
    products: Client.Product[],
    width: number,
  ): Product | null {
    let material = !!this.material ? this.material : undefined;
    products = products.filter((p) =>
      ProductHelper.supportsWidth(p, width, material),
    );
    let result = products.filter((p) => !ProductHelper.isFlexWidth(p))[0];

    if (!result) {
      // No fixed width product available. Try again with all products.
      result = products[0];
    }
    if (!result) return null;
    return result;
  }

  //#endregion Actual values, private properties

  //#region Actual values, public properties

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  public get backingType(): Interface_Enums.BackingType {
    return this.dtoSection.BackingType;
  }
  public setBackingType(
    value: Interface_Enums.BackingType,
    adjustSectionDepth: boolean,
  ) {
    if (value === this.backingType) {
      return;
    }
    let oldBackingType = this.backingType;
    let oldStandardDepth = BackingService.getStandardSectionDepth(
      oldBackingType,
      this.cabinetSection.backing.thickness,
    );
    this.dtoSection.BackingType = value;
    if (adjustSectionDepth) {
      this.clearBackingVariables();
      let newStandardDepth = BackingService.getStandardSectionDepth(
        value,
        this.cabinetSection.backing.thickness,
      );
      let depthDiff = newStandardDepth - oldStandardDepth;
      this.cabinetSection.Depth += depthDiff;
    }

    this.backingAreas = [];
    this.cabinetSection.cabinet.clearAllSectionCaches();
  }

  public get isHidden(): boolean {
    return this.backingType === Interface_Enums.BackingType.Hidden;
  }

  public get frontZ(): number {
    if (this.cache.frontZ === undefined) {
      if (this.backingType === Interface_Enums.BackingType.None) {
        this.cache.frontZ = 0;
      } else {
        this.cache.frontZ = this.thickness + Constants.BackingFittingExtraDepth;
      }
    }

    return this.cache.frontZ;
  }

  public get backZ(): number {
    return this.backingType === Interface_Enums.BackingType.None
      ? 0
      : Constants.BackingFittingExtraDepth;
  }

  public get material(): Interface_DTO.Material | null {
    if (!this.cache.material) {
      let mat: Interface_DTO.Material | undefined | null;
      if (this.dtoSection.BackingMaterialId === null) {
        mat = null;
      } else {
        mat =
          this.editorAssets.materialsDict[this.dtoSection.BackingMaterialId];
        if (!mat) {
          let firstPickableMat = this.pickableMaterials[0];
          if (firstPickableMat) {
            mat = firstPickableMat.item;
          } else {
            mat = this.editorAssets.materials[0];
          }
        }
      }
      this.cache.material = mat;
    }
    return this.cache.material;
  }
  public set material(value: Interface_DTO.Material | null) {
    this.dtoSection.BackingMaterialId = value ? value.Id : null;

    this.clearBackingVariables();
  }

  //#endregion Actual values, public properties

  //#region Availables, public getters

  public get availableProducts(): Enumerable.IEnumerable<Product> {
    if (this.cache.availableProducts === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let allBackingProducts = this.editorAssets.products.filter(
        (p) => p.ProductType === Interface_Enums.ProductType.Backing,
      );
      let filteredProducts = FilterService.getFilteredProducts(
        allBackingProducts,
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        fullCatalog,
        this.cabinetSection.CabinetType,
      );
      this.cache.availableProducts = Enumerable.from(filteredProducts);
    }

    return this.cache.availableProducts;
  }

  public get availableTypes(): Interface_Enums.BackingType[] {
    if (this.cache.availableTypes === undefined) {
      this.cache.availableTypes = [
        Interface_Enums.BackingType.None,
        Interface_Enums.BackingType.Visible,
        Interface_Enums.BackingType.Hidden,
      ];
    }

    return this.cache.availableTypes;
  }

  public get pickableTypes(): Pickable<Interface_Enums.BackingType>[] {
    if (this.cache.pickableTypes === undefined) {
      this.cache.pickableTypes = [
        {
          groupName: ' ',
          imageUrl: 'staticAssets/images/backingTypes/backingNone.png',
          item: Interface_Enums.BackingType.None,
          isSelected: this.backingType === Interface_Enums.BackingType.None,
          name: this.editorAssets.translationService.translate(
            'backing_type_none1',
            'No Backing',
          ),
          disabledReason: null,
          override: false,
        },
        {
          groupName: '  ',
          imageUrl: 'staticAssets/images/backingTypes/backingHidden.png',
          item: Interface_Enums.BackingType.Hidden,
          isSelected: this.backingType === Interface_Enums.BackingType.Hidden,
          name: this.editorAssets.translationService.translate(
            'backing_type_hidden1',
            'Hidden backing sides',
          ),
          disabledReason: this.allowHidden
            ? null
            : this.editorAssets.translationService.translate(
                'backing_hidden_joint_not_possible',
                'Hidden backing not possible in this section.',
              ),
          override: false,
        },
        {
          groupName: '  ',
          imageUrl: 'staticAssets/images/backingTypes/backingVisible.png',
          item: Interface_Enums.BackingType.Visible,
          isSelected: this.backingType === Interface_Enums.BackingType.Visible,
          name: this.editorAssets.translationService.translate(
            'backing_type_visible1',
            'Visible backing sides',
          ),
          disabledReason: null,
          override: false,
        },
      ];
    }
    return this.cache.pickableTypes;
  }

  public get pickableMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableMaterials === undefined) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
      let activeMatGrps = this.availableProducts
        .selectMany((product) => product.materials)
        .groupBy((proMat) => proMat.Id)
        .where((proMatGrp) => !proMatGrp.any((pm) => pm.isDiscontinued))
        .select((proMatGrp) => {
          return {
            material: proMatGrp.first() as Client.Material,
            isOverride: proMatGrp.any((pm) => pm.isOverride),
          };
        });
      if (!fullCatalog) {
        activeMatGrps = activeMatGrps.where((matGrp) => !matGrp.isOverride);
      }

      this.cache.pickableMaterials = activeMatGrps
        .select<Pickable<Client.Material>>((matGrp) => {
          return {
            ...MaterialHelper.getPickable(matGrp.material),
            isSelected:
              this.dtoSection.BackingMaterialId === matGrp.material.Id,
            override: matGrp.isOverride,
          };
        })
        .toArray();

      MaterialHelper.sortPickableMaterials(this.cache.pickableMaterials);
    }

    return this.cache.pickableMaterials;
  }

  //#endregion Availables, public getters

  //#region Public getters

  public get minimumItemWidth(): number {
    return this.availableProducts.any()
      ? this.availableProducts.min((p) =>
          ProductHelper.minWidth(
            p,
            !!this.material ? this.material : undefined,
          ),
        )
      : 0;
  }

  public get maximumItemWidth(): number {
    if (this.availableProducts.any()) {
      return this.availableProducts.max((p) =>
        ProductHelper.maxWidth(p, !!this.material ? this.material : undefined),
      );
    } else {
      return Enumerable.from(
        this.editorAssets.products.filter(
          (p) => p.ProductType === Interface_Enums.ProductType.Backing,
        ),
      ).max((p) =>
        ProductHelper.maxWidth(p, !!this.material ? this.material : undefined),
      );
    }
  }

  public get thickness(): number {
    if (this.cache.thickness === undefined) {
      let backingProduct = this.availableProducts.firstOrDefault();
      if (!backingProduct)
        backingProduct = this.editorAssets.products.filter(
          (p) => p.ProductType === Interface_Enums.ProductType.Backing,
        )[0];
      this.cache.thickness = backingProduct
        ? ProductHelper.defaultDepth(
            backingProduct,
            this.cabinetSection.cabinet.ProductLineId,
            null,
          )
        : 0; //no backing products
    }

    return this.cache.thickness;
  }

  public get fittingMaterial(): Interface_DTO.Material {
    if (!this.cache.fittingMaterial) {
      let materials = Enumerable.from(this.editorAssets.materials);

      let fittingMaterial = materials.firstOrDefault(
        (mat) =>
          mat.Type === Interface_Enums.MaterialType.Frame &&
          mat.Number === '02',
      );
      if (!fittingMaterial) {
        fittingMaterial = materials.first();
      }

      this.cache.fittingMaterial = fittingMaterial;
    }

    return this.cache.fittingMaterial;
  }

  public get isEmpty(): boolean {
    return this.backingType === Interface_Enums.BackingType.None;
  }

  //#endregion Public getters

  //allowed if there are no full-depth corpus panels, or if at least one panel supports recessed backing
  public get allowHidden(): boolean {
    let corpus = this.cabinetSection.corpus;
    if (
      !corpus.panelLeft.isFullDepth(this.cabinetSection) &&
      !corpus.panelRight.isFullDepth(this.cabinetSection)
    )
      return true;
    let items = corpus.itemsLeft.concat(corpus.itemsRight);
    let variantFound = items.some(
      (item) =>
        !!item.Product &&
        item.Product.getVariants(
          this.cabinetSection.cabinet.ProductLineId,
        ).some((variant) => variant.Number === VariantNumbers.RecessBacking),
    );
    return variantFound;
  }

  public clearBackingVariables() {
    this.cache = {};
  }
}
