<div class="modal-header" style="display: block">
  <div>
    <div style="display: flex; justify-content: space-between">
      <h3
        class="modal-title"
        [textContent]="translate('Link_browser_modal_header', 'Link browser')"
      ></h3>
      <button
        type="button"
        class="close"
        style="float: right"
        (click)="cancel()"
      >
        &times;
      </button>
    </div>
  </div>
</div>

<div class="modal-body" style="min-height: 350px; max-height: 50vh">
  <link-browser></link-browser>
</div>

<div class="modal-footer">
  <div class="btn-group pull-right">
    <button
      class="btn btn-primary"
      [textContent]="translate('modal_close', 'Close')"
      (click)="cancel()"
    ></button>
  </div>
</div>
