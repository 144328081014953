import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'initializing',
  templateUrl: 'initializing.component.html',
  styleUrls: ['initializing.component.scss', '../style/spinner.scss'],
})
export class InitializingComponent {
  constructor() {}
}
