import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
export class BackingArea {
  public enabled: boolean = true;
  public position: Interface_DTO_Draw.Vec2d = { X: -1, Y: -1 };
  public size: Interface_DTO_Draw.Vec2d = { X: -1, Y: -1 };

  public get leftX(): number {
    return this.position.X;
  }
  public get rightX(): number {
    return this.position.X + this.size.X;
  }
}
