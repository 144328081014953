<div
  (mousedown)="startDrag($event)"
  (mouseup)="stopDrag($event)"
  (touchstart)="touchStart($event)"
  (touchend)="touchEnd($event)"
  (touchmove)="touchMove($event)"
  class="interior-product"
  data-product-id="product.Id"
  [attr.title]="productName"
>
  <div class="interior-product--img" [ngStyle]="imgStyle"></div>
  <div class="interior-product--name">{{productName}}</div>
  <div class="interior-product--min-price price" *ngIf="showMinPrice">
    {{translate('consumer_interior_item_minPrice', 'From {0} DKK', minPrice)}}
  </div>
</div>
