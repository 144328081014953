import { Component, Input } from '@angular/core';
import { FloorPlan } from 'app/ts/clientDto';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { PartitionPlanCommandService } from '../partition-plan-command.service';
import { PartitionPlanQueryService } from '../partition-plan-query.service';
import * as Partition from 'app/partition/floor-plan';
import { Section } from '../floor-plan/section';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { PartitionPlanGeometryQueryService } from '../partition-plan-geometry-query.service';
import { PartitionDragType, Side } from 'app/ts/clientDto/Enums';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { MeasurementService } from 'app/measurement/measurement.service';
import { Constants } from 'app/ts/Constants';

/**
 * The container component for partition.
 * A container component handles all communication
 * with the world around the underlying component.
 * It usually does this using some kind of store, here
 * that is represented by the @see PartitionPlanService and
 * the @see PartitionPlanCommandsService, effectively resulting
 * in [CQS - command/query separation](https://en.wikipedia.org/wiki/Command%E2%80%93query_separation).
 */
@Component({
  selector: 'partition-property-sheet',
  templateUrl: 'property-sheet.container.html',
})
export class PropertySheetContainerComponent {
  @Input()
  public get partitions(): Partition.Plan {
    return this._partitions;
  }
  public set partitions(partitions: Partition.Plan) {
    this._partitions = partitions;
  }
  private _partitions!: Partition.Plan;

  @Input() floorPlan!: FloorPlan;

  constructor(
    public query: PartitionPlanQueryService,
    public queryGeometry: PartitionPlanGeometryQueryService,
    public command: PartitionPlanCommandService,
    private floorPlanService: FloorPlanService,
    private measurementService: MeasurementService,
  ) {}

  public get partitionName(): string {
    return this.query.getPartitionForSection(this.selectedSection.id).name;
  }

  public get selectedSection(): Section {
    return this.partitions.sections.find(
      (sec) => sec.id == this.query.selectedSection!.id,
    )!;
  }

  public nameChange(newName: string) {
    if (newName.trim().length > 0) {
      if (newName.trim().length > Constants.maxPartitionNameLength)
        newName = newName.substring(0, Constants.maxPartitionNameLength);

      if (
        this.query.getPartitionForSection(this.query.selectedSection!.id)
          .name != newName
      )
        this.floorPlanService.setChanged(this.floorPlan);

      this.command.updatePartitionName(this.query.selectedSection!.id, newName);
    }
  }

  public sectionWidthChange(event: { width: number }) {
    let extendFrom =
      this.queryGeometry.getDistanceFromRelativeRightSide(
        this.selectedSection.id,
      ) == 0 && !this.query.hasJointProfile(this.selectedSection.id, Side.Left)
        ? Side.Right
        : undefined;

    this.command.updateSectionWidth(
      this.selectedSection.id,
      event.width,
      extendFrom,
    );
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public numberOfModulesChange(numberOfModules: number) {
    this.command.setNumberOfModulesForSection(
      this.selectedSection.id,
      numberOfModules,
    );
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public deletePartition() {
    this.command.deleteSelectedPartition();
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public deleteSection() {
    this.command.deleteSection(this.selectedSection.id);
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public rotateSectionLeft() {
    this.command.rotateSelectedSectionLeft();
    this.command.ensureSectionWithinFloorPlan(this.selectedSection.id, true);
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public rotateSectionRight() {
    this.command.rotateSelectedSectionRight();
    this.command.ensureSectionWithinFloorPlan(this.selectedSection.id, true);
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public reverseSection() {
    this.command.reverseSelectedSection();
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public unevenLeftWall(state: boolean) {
    this.command.setUnevenLeftWall(this.query.selectedSection!.id, state);
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public unevenRightWall(state: boolean) {
    this.command.setUnevenRightWall(this.query.selectedSection!.id, state);
    this.floorPlanService.setChanged(this.floorPlan);
  }

  public updateDistanceToLeftWall(newDist: number) {
    this.command.updateDistanceToLeftWall(this.selectedSection.id, newDist);
    this.floorPlanService.setChanged(this.floorPlan);
    this.measurementService.recalculateMeasurements(this.floorPlan);
  }

  public updateDistanceToRightWall(newDist: number) {
    this.command.updateDistanceToRightWall(this.selectedSection.id, newDist);
    this.floorPlanService.setChanged(this.floorPlan);
    this.measurementService.recalculateMeasurements(this.floorPlan);
  }

  public updateDistanceToTopWall(newDist: number) {
    this.command.updateDistanceToTopWall(this.selectedSection.id, newDist);
    this.floorPlanService.setChanged(this.floorPlan);
    this.measurementService.recalculateMeasurements(this.floorPlan);
  }

  public updateDistanceToBottomWall(newDist: number) {
    this.command.updateDistanceToBottomWall(this.selectedSection.id, newDist);
    this.floorPlanService.setChanged(this.floorPlan);
    this.measurementService.recalculateMeasurements(this.floorPlan);
  }
}
