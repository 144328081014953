import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { SwingFlexValidationService } from './SwingFlexValidationService';

export class ItemValidationService {
  public static readonly Name = 'itemValidationService';

  constructor() {}

  public accessTest() {
    return 'Item Validation Service access test function';
  }
  public static validateProducts(
    items: Client.ConfigurationItem[],
    editorSection: Enums.EditorSection,
    cabinetSection: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    result.push(
      ...this.validateMustHaveGrip(items, editorSection, cabinetSection),
    );
    result.push(
      ...this.validateDiscontinuedProducts(
        items,
        editorSection,
        cabinetSection,
      ),
    );

    return result;
  }

  private static validateMustHaveGrip(
    items: Client.ConfigurationItem[],
    editorSection: Enums.EditorSection,
    cabinetSection: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let problemItems: Client.ConfigurationItem[] = [];

    for (let item of items) {
      if (!item?.canHaveGrip) continue;
      if (item.gripProduct) continue;
      if (
        item.VariantOptions.find(
          (vo) => vo.variant?.Number === VariantNumbers.DoorOpeningMethod,
        )?.variantOption?.Number ===
        VariantNumbers.Values.DoorOpeningMethod_PushToOpen
      )
        continue;

      // ensure there is enough space to install a grip
      if (SwingFlexValidationService.hasSwingFlexDoorInFront(item)) continue;

      problemItems.push(item);
    }

    if (problemItems.length === 0) return [];

    let error = new Client.ErrorInfo(
      editorSection,
      Interface_Enums.ErrorLevel.Info,
      new Client.Translatable(
        'val_err_item_missing_grips_short',
        'Items are missing Grips',
        problemItems.length.toString(),
      ),
      new Client.Translatable(
        'val_err_item_missing_grips_long',
        '{0} items are missing Grips. Customer must provide their own grips.',
        problemItems.length.toString(),
      ),
      cabinetSection,
      problemItems[0],
    );
    for (let item of problemItems) {
      item.errors.push(error);
    }
    return [error];
  }

  private static validateDiscontinuedProducts(
    items: Client.ConfigurationItem[],
    editorSection: Enums.EditorSection,
    cabinetSection: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];

    for (let item of items) {
      if (!item.Product || !item.ProductData) continue;

      let productDataDiscontinued =
        item.ProductData.Status ===
        Interface_Enums.ProductDataStatus.Discontinued;
      let fullCatalog =
        cabinetSection.editorAssets.fullCatalog &&
        cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let productUnlicensed = !fullCatalog && item.Product.overrideChain;

      if (
        productDataDiscontinued ||
        !item.Product.Enabled ||
        productUnlicensed
      ) {
        let prefix = item.Product.Name + ' (' + item.ItemNo + ')';
        let error = new Client.ErrorInfo(
          editorSection,
          Interface_Enums.ErrorLevel.Critical,
          new Client.Translatable(
            'val_err_short_discontinued_product_{0}',
            'Product has been discontinued',
            prefix,
          ),
          new Client.Translatable(
            'val_err_full_discontinued_product_{0}',
            'The product "{0}" has been discontinued, please select another one.',
            prefix,
          ),
          cabinetSection,
          item,
        );
        errorInfos.push(error);
        item.errors.push(error);
      }
    }

    return errorInfos;
  }

  public static validateMaterials(
    items: Client.ConfigurationItem[],
    editorSection: Enums.EditorSection,
    cabinetSection: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];
    let fullCatalog =
      cabinetSection.editorAssets.fullCatalog &&
      cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    for (let item of items) {
      if (item.Product && item.Material) {
        if (
          item.Material.isDiscontinued ||
          (item.Material.isOverride && !fullCatalog)
        ) {
          let error = new Client.ErrorInfo(
            editorSection,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validation_discontinued_material_short_{0}_{1}_{2}_{3}',
              'Material has been discontinued',
              item.Material.DefaultName,
              item.Material.Number,
              item.Product.Name,
              item.Product.ProductNo,
            ),
            new Client.Translatable(
              'validation_discontinued_material_long_{0}_{1}_{2}_{3}',
              'The material "{0}" ({1}) has been discontinued for product "{2}" ({3}), please select another one.',
              item.Material.DefaultName,
              item.Material.Number,
              item.Product.Name,
              item.Product.ProductNo,
            ),
            cabinetSection,
            item,
          );
          errorInfos.push(error);
          item.errors.push(error);
        }
      }
    }

    return errorInfos;
  }
}
