import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as DTO from 'app/ts/interface_dto/index';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ActiveUserInjector,
  ActiveUser,
} from 'app/functional-core/ambient/activeUser/ActiveUser';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  public readonly projectsById: Observable<{ [id: number]: Client.Project }>;

  constructor(
    private readonly $http: PromisingBackendService,
    private readonly translationService: TranslationService,
    private readonly customerService: CustomerService,
    @Inject(ActiveUserInjector) public readonly activeUser: ActiveUser,
  ) {
    this.projectsById = customerService.getAll().pipe(
      map((customers) => {
        let projects: { [id: number]: Client.Project } = {};
        for (let cust of customers) {
          for (let proj of cust.Projects) {
            projects[proj.Id] = proj;
          }
        }
        return projects;
      }),
    );
  }

  public getEmptyProject(customer: DTO.Customer): DTO.Project {
    return {
      CustomerId: customer.Id,
      DeliveryAddresses: [],
      Description: '',
      Name: this.translationService.translate(
        'new_project_name_{0}',
        'New Project for {0}',
        customer.Name,
      ),
      Id: 0,
      UserId: this.activeUser.data.UserId,
      CreatedDate: DateHelper.toIsoString(new Date(Date.now())),
    };
  }

  public async createProject(project: DTO.Project): Promise<DTO.Project> {
    this.validateProject(project);
    let newProject = await this.$http.put<DTO.Project>(
      'api/projects/',
      project,
    );
    this.customerService.updateCustomerList();
    return newProject;
  }

  public async updateProject(project: DTO.Project): Promise<DTO.Project> {
    let copy = ObjectHelper.copy(ObjectHelper.getDtoObject(project));
    this.validateProject(copy);
    copy.DeliveryAddresses = [];
    let newProject = await this.$http.post<DTO.Project>('api/projects/', copy);

    this.customerService.updateCustomerList();
    return newProject;
  }

  private validateProject(project: DTO.Project) {
    let requiredProperties: (keyof DTO.Project)[] = ['Name'];
    for (let propName of requiredProperties) {
      if (!project[propName])
        throw new Exception.PropertyInvalid(project, propName);
    }
  }

  public async deleteProjects(projectIds: number[]) {
    if (projectIds.length === 0) return;

    await this.$http.post<void>('api/projects/delete', projectIds, {
      responseType: 'void',
    });

    this.customerService.updateCustomerList();
  }
}
