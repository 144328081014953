import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ScreenshotContainerService } from './screenshot-container.service';

/**
 * The container for rendering/creating components
 * into when needing to create a screenshot of
 * them.
 */
@Component({
  selector: 'screen-shot',
  templateUrl: 'screenshot-container.component.html',
  styles: ['canvas { display: none; }'],
})
export class ScreenshotContainerComponent implements AfterViewInit {
  @ViewChild('screenshotcontainer', { read: ViewContainerRef, static: true })
  screenShotContainer!: ViewContainerRef;

  constructor(private screenShooter: ScreenshotContainerService) {}

  ngAfterViewInit() {
    this.screenShooter.setScreenShotContainer(this.screenShotContainer);
  }
}
