<form class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('delivery_info_editor_header', 'Confirm delivery info')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <table class="detail-table form-body" style="width: 100%">
      <tr
        class="detail-property detail-property-owner-name"
        [attr.title]="translate('floorplan_detail_title_owner_name', 'Owner')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_owner_name', 'Owner')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="UserId"
            [(ngModel)]="deliveryInfo.UserId"
          >
            <option *ngFor="let user of users" [ngValue]="user.id">
              {{user.name}}
            </option>
          </select>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-name"
        [attr.title]="translate('floorplan_detail_title_name', 'Name')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_name', 'Name')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="DeliveryInfoName"
            type="text"
            maxlength="maxfloorPlanNameLength"
            [(ngModel)]="deliveryInfo.Name"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-delivery-week"
        [attr.title]="translate('floorplan_detail_title_delivery_week', 'Delivery Week')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_delivery_week', 'Delivery Week')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="DeliveryWeek"
            type="number"
            [(ngModel)]="deliveryInfo.DeliveryWeek"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-reference"
        [attr.title]="translate('floorplan_detail_title_reference', 'Reference')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_reference', 'Reference')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="Reference"
            type="text"
            [(ngModel)]="deliveryInfo.Reference"
            [required]="requireReferenceOnOrdering"
            [disabled]="isReferenceFieldReadOnly"
          />
        </td>
      </tr>

      <tr
        class="detail-property"
        [attr.title]="translate('floorplan_detail_title_market_order_number', 'Market Order Number')"
        *ngIf="showMarketOrderNumber"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_market_order_number', 'Market Order Number')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="MarketOrderNumber"
            type="text"
            [required]="requireMarketOrderOnOrdering"
            [(ngModel)]="deliveryInfo.MarketOrderNumber"
            [disabled]="!canEdit"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-sms-phone"
        [attr.title]="translate('floorplan_detail_title_sms_phone', 'SMS Phone')"
        *ngIf="showSmsField"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sms_phone', 'SMS Phone')"
        ></td>
        <td class="detail-value">
          <phone-input
            [phoneNumberObservable]="smsObservable"
            [isDisabled]="!canEdit"
            [defaultPrefix]="defaultPhonePrefix"
            [required]="requireSmsOnOrdering"
            (phoneNumberChange)="phoneNumberChange($event)"
          ></phone-input>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier', 'Integration Identifier')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier', 'Integration Identifier')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            type="text"
            ng-trim="false"
            name="IntegrationIdentifier"
            [(ngModel)]="deliveryInfo.IntegrationIdentifier"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier2"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier2', 'Integration Identifier2')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier2', 'Integration Identifier2')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="IntegrationIdentifier2"
            type="text"
            ng-trim="false"
            [(ngModel)]="deliveryInfo.IntegrationIdentifier2"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-integration-identifier3"
        *ngIf="showIntegrationInputFields"
        [attr.title]="translate('floorplan_detail_title_integration_identifier3', 'Integration Identifier3')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_integration_identifier3', 'Integration Identifier3')"
        ></td>
        <td class="detail-value">
          <input
            class="form-control"
            name="IntegrationIdentifier3"
            type="text"
            ng-trim="false"
            [(ngModel)]="deliveryInfo.IntegrationIdentifier3"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-shipping_agent"
        *ngIf="showShippingAgents"
        [attr.title]="translate('floorplan_detail_title_shipping_agent', 'Shipping Agent')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_shipping_agent', 'Shipping Agent')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="ShippingAgentCode"
            [(ngModel)]="deliveryInfo.ShippingAgentCode"
          >
            <option
              *ngFor="let salesChainShippingAgent of salesChainShippingAgents"
              [ngValue]="salesChainShippingAgent.ShippingAgentCode"
            >
              {{salesChainShippingAgent.ShippingAgentName}}
            </option>
          </select>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-sales-type"
        [attr.title]="translate('floorplan_detail_title_sales_type', 'Sales Type')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sales_type', 'Sales Type')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="SalesTypeNumber"
            [(ngModel)]="deliveryInfo.SalesTypeNumber"
            (ngModelChange)="setDefaultSalesTypeCaseNumber();"
          >
            <option
              *ngFor="let salesType of salesTypes"
              [ngValue]="salesType.Number"
            >
              {{salesType.Name}}
            </option>
          </select>
        </td>
      </tr>
      <tr
        class="detail-property detail-property-sales-type-case"
        [attr.title]="translate('floorplan_detail_title_sales_type_case', 'Sales Type Case')"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_sales_type_case', 'Sales Type Case')"
        ></td>
        <td class="detail-value">
          <select
            class="form-select"
            name="SalesTypeCaseNumber"
            [disabled]="!selectedSalesType || selectedSalesType.SalesTypeCases.length < 2"
            [(ngModel)]="deliveryInfo.SalesTypeCaseNumber"
          >
            <option
              *ngFor="let salesTypeCase of selectedSalesType?.SalesTypeCases"
              [ngValue]="salesTypeCase.Number"
            >
              {{salesTypeCase.Name}}
            </option>
          </select>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-manual-order-handling"
        *ngIf="showManualOrderHandling"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_manual_order_handling', 'Manual order handling')"
        ></td>
        <td class="detail-value">
          <input
            type="checkbox"
            name="ManualOrderHandling"
            [(ngModel)]="deliveryInfo.ManualOrderHandling"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-send-reminder-email"
        *ngIf="showSendReminderEmails"
      >
        <td
          class="detail-type"
          [textContent]="translate('floorplan_detail_title_send_reminder_emails', 'Fremsend reminder email')"
        ></td>
        <td class="detail-value">
          <input
            type="checkbox"
            name="SendReminderEmails"
            [(ngModel)]="deliveryInfo.SendReminderEmails"
          />
        </td>
      </tr>

      <tr
        class="detail-property detail-property-driver-information detail-property-multiline"
        [attr.title]="translate('floorplan_detail_title_driver_information', 'Driver information')"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            nam="DriverInformation"
            [textContent]="translate('floorplan_detail_title_driver_information', 'Driver information')"
          ></span>
          <textarea
            class="detail-value form-control"
            [(ngModel)]="deliveryInfo.DriverInformation"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-internal-note"
        [attr.title]="translate('floorplan_detail_title_internal_note', 'Internal Note')"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_internal_note', 'Internal Note')"
          ></span>
          <textarea
            class="form-control detail-value"
            nam="InternalNote"
            [(ngModel)]="deliveryInfo.InternalNote"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-order-comment"
        [attr.title]="translate('floorplan_detail_title_order_comment', 'Order Comment')"
        *ngIf="showOrderComment"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_order_comment', 'Order Comment')"
          ></span>
          <textarea
            class="form-control detail-value"
            name="OrderComment"
            [(ngModel)]="deliveryInfo.OrderComment"
          ></textarea>
        </td>
      </tr>

      <tr
        class="detail-property detail-property-multiline detail-property-logisitcs-comment"
        [attr.title]="translate('floorplan_detail_title_logistics_comment', 'Logistics Comment')"
        *ngIf="showLogisticsComment"
      >
        <td class="detail-all" colspan="3">
          <span
            class="detail-type"
            [textContent]="translate('floorplan_detail_title_logistics_comment', 'Logistics Comment')"
          ></span>
          <textarea
            class="form-control detail-value"
            name="LogisticsComment"
            maxlength="30"
            [(ngModel)]="deliveryInfo.LogisticsComment"
          ></textarea>
        </td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
    <div class="btn-group btn-group-right">
      <button
        class="btn btn-primary"
        type="submit"
        [textContent]="translate('delivery_info_editor_ok','Ok')"
        (click)="ok()"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        [textContent]="translate('delivery_info_editor_cancel','Cancel')"
        (click)="cancel()"
      ></button>
    </div>
  </div>
</form>
