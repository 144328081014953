<div class="modal-content group-selector">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('template_modal_title', 'Select favourite')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="groups">
      <div class="pickable-group">
        <div
          *ngFor="let pickable of templatePickables"
          class="pickable-overlay-container template"
        >
          <pickable
            (click)="select(pickable.item)"
            class="template"
            id="template"
            [pickable]="pickable"
          ></pickable>

          <div
            class="pickable-overlay pickable-delete-button-overlay"
            [attr.title]="translate('template_modal_delete_template_tooltip','Delete favourite')"
          >
            <span
              class="bi bi-trash"
              (click)="delete(pickable.item)"
              *ngIf="pickable.item.Deletable"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
