<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('edit_manual_item_modal_title', 'Edit Item')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <item-editor
      [item]="item"
      [showPosition]="false"
      *ngIf="item"
    ></item-editor>
  </div>
  <div
    class="modal-footer"
    style="display: flex; flex-flow: column nowrap; align-items: flex-end"
  >
    <div class="input-group" style="justify-content: flex-end">
      <button
        class="btn btn-danger"
        [textContent]="translate('modal_button_remove', 'remove')"
        (click)="delete()"
      ></button>
    </div>
    <div
      class="input-group"
      style="margin-top: 5px; display: flex; width: auto"
    >
      <input
        type="number"
        [(ngModel)]="quantity"
        min="1"
        style="width: 60px; height: 32px"
        class="form-control"
      />
      <button
        class="btn btn-primary-custom"
        [ngClass]="{'disabled': !okPossible}"
        [textContent]="translate('edit_manual_item_modal_ok','Update')"
        (click)="ok()"
      ></button>
      <button
        class="btn btn-light"
        style="margin-left: 0"
        [textContent]="translate('edit_manual_item_modal_cancel','Cancel')"
        (click)="cancel()"
      ></button>
    </div>
  </div>
</div>
