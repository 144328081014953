import { Injectable } from '@angular/core';
import * as App from 'app/ts/app';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeyboardService {
  private _keyboardEvents = new BehaviorSubject<KeyboardEvent | null>(null);

  public get keyboardEvents(): Observable<KeyboardEvent | null> {
    return this._keyboardEvents;
  }

  public report(evt: KeyboardEvent) {
    if (App.debug.showKeyboardEvents) {
      console.debug('Keyboard event reported:', evt);
    }
    this._keyboardEvents.next(evt);
    this._keyboardEvents.next(null);
  }
}
