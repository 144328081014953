import { FloorPlan } from 'app/ts/clientDto';
import { PartitionPlan } from '../partition-plan-data.service';
import { RailId } from '../rail';
import { SectionId } from '../section';
import {
  calculateModules,
  D3ViewDoor,
  D3ViewModule,
  D3ViewWall,
} from './module';

export class D3ViewSection {
  constructor(
    private _id: SectionId,
    private _posX: number,
    private _posY: number,
    private _width: number,
    private _rotation: number,
    private _wallRailSetId: number,
    private _profileMaterialId: number,

    private _barHeight: number,

    private _modules: D3ViewModule[],

    private _rails: readonly RailId[],
  ) {}

  public get id(): SectionId {
    return this._id;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get rotation(): number {
    return this._rotation;
  }

  public get wallRailSetId(): number {
    return this._wallRailSetId;
  }

  public get profileMaterialId(): number {
    return this._profileMaterialId;
  }

  public get barHeight(): number {
    return this._barHeight;
  }

  public get walls(): D3ViewWall[] {
    return this._modules.filter((md) => md instanceof D3ViewWall);
  }

  public get doors(): D3ViewDoor[] {
    return this._modules.filter(
      (md) => md instanceof D3ViewDoor,
    ) as D3ViewDoor[];
  }

  public get rails(): readonly RailId[] {
    return this._rails;
  }
}

export function calculateSections(
  partitionPlan: Readonly<PartitionPlan>,
  floorPlan: FloorPlan,
  doorModuleProductId: number,
  wallModuleProductId: number,
) {
  return partitionPlan.sections.map(
    (section) =>
      new D3ViewSection(
        section.id,
        section.posX,
        section.posY,
        section.width,
        section.rotation,
        section.references.wallRailSetId,
        section.references.profileMaterialId,
        section.barHeight,
        calculateModules(
          section.id,
          partitionPlan,
          floorPlan,
          doorModuleProductId,
          wallModuleProductId,
        ),
        section.rails,
      ),
  );
}
