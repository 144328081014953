import { Component } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Interface_DTO_Consumer from 'app/ts/Interface_DTO_Consumer';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'load-vm',
  templateUrl: './load.html',
})
export class LoadVm extends BaseVm {
  private _floorPlanInfos:
    | Interface_DTO_Consumer.ConsumerFloorplanInfo[]
    | undefined;

  public isLoadingFloorPlan = false;

  constructor(
    baseVmService: BaseVmService,
    private readonly $http: PromisingBackendService,
    private readonly floorPlanService: FloorPlanService,
    private routing: AppRoutingModule,
  ) {
    super(baseVmService);
    this.loadInfos();
  }

  public get showLoadSpinner() {
    return this.isLoading;
  }

  public get showTable() {
    return !!this._floorPlanInfos && this._floorPlanInfos.length > 0;
  }

  public get floorPlanInfos():
    | Interface_DTO_Consumer.ConsumerFloorplanInfo[]
    | undefined {
    return this._floorPlanInfos;
  }

  public get isLoading() {
    return this._floorPlanInfos === undefined;
  }

  public getCreatedDate(
    floorPlan: Interface_DTO_Consumer.ConsumerFloorplanInfo,
  ) {
    let d = new Date(floorPlan.Created);
    return d.toLocaleDateString();
  }

  public async load(floorPlan: Interface_DTO_Consumer.ConsumerFloorplanInfo) {
    this.isLoadingFloorPlan = true;
    try {
      await this.floorPlanService.loadConsumerFloorPlan(floorPlan.FloorPlanId);
      this.routing.Consumer_corpus();
    } finally {
      this.isLoadingFloorPlan = false;
    }
  }

  private async loadInfos() {
    window.setTimeout(async () => {
      this._floorPlanInfos = await this.$http.get<
        Interface_DTO_Consumer.ConsumerFloorplanInfo[]
      >('/api/consumerOrder/load');
    }, 200);
  }
}
