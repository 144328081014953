import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import { RecalculationQuestion } from 'app/ts/clientDto/RecalculationMessage';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ErrorService } from 'app/ts/services/ErrorService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly notificationDelayMs: { [id: number]: number } = {
    0: 4000,
    1: 4000,
    2: 12000,
    3: 20000,
    4: 20000,
  };

  private loadingPromises: Promise<any>[] = [];

  public constructor(
    private readonly translationService: TranslationService,
    private readonly errorService: ErrorService,
  ) {}

  public get displayLoader(): boolean {
    return this.loadingPromises.length > 0;
  }

  public readonly activeNotifications: Notification[] = [];

  public success(
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ) {
    return this.notify(
      NotificationType.Success,
      textKey,
      defaultValue,
      ...translationParams,
    );
  }

  public info(
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ) {
    return this.notify(
      NotificationType.Info,
      textKey,
      defaultValue,
      ...translationParams,
    );
  }

  public warning(
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ) {
    return this.notify(
      NotificationType.Warning,
      textKey,
      defaultValue,
      ...translationParams,
    );
  }

  public userError(
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ) {
    return this.notify(
      NotificationType.UserError,
      textKey,
      defaultValue,
      ...translationParams,
    );
  }

  public systemError(
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ) {
    return this.notify(
      NotificationType.SystemError,
      textKey,
      defaultValue,
      ...translationParams,
    );
  }

  public unknownException(exception: any, extraInfo?: any) {
    return this.exception(
      'notification_unknown_error',
      exception,
      'Unknown error',
      extraInfo,
    );
  }

  public async exception(
    textKey: string,
    exception: any,
    defaultValue: string,
    extraInfo?: any,
  ): Promise<Notification | undefined> {
    if ((<any>exception).message === 'Request was cancelled') return;
    if (exception instanceof Exception.AuthenticationException) {
      //User should already be redirected to login page, no need to display an error
      return;
    }

    extraInfo = ObjectHelper.copy(extraInfo || {});
    extraInfo.reported = 'reported through notificationService';
    if (exception instanceof Exception.ServerDomainException) {
      extraInfo.serverDomainException = exception;
    }

    console.error(
      'notificationService exception: ',
      textKey,
      exception,
      extraInfo,
    );

    let errorId = await this.errorService.reportError(
      textKey,
      exception,
      extraInfo,
    );

    if (typeof errorId === 'number') {
      let innerTranslation = this.translationService.translate(
        textKey,
        defaultValue,
      );
      return this.systemError(
        'notification_exception_errorMsg_{0}_eventId_{1}',
        'Unexpected error: {0}\nError ID: {1}',
        innerTranslation,
        errorId.toString(),
      );
    } else {
      return this.systemError(textKey, defaultValue);
    }
  }

  public async exceptionWithDefaultText(e: any) {
    if (e instanceof Exception.ServerDomainException) {
      switch (e.Type) {
        case Interface_DTO_DomainError.ErrorType.IntegrationNumbersInvalid:
          return this.userError(
            'integration_numbers_invalid',
            'Ingtegration numbers are invalid.',
          );
        case Interface_DTO_DomainError.ErrorType.ShippingAgentRequired:
          return this.userError(
            'integration_shipping_agent_required',
            'Integration shipping agent is required.',
          );
        case Interface_DTO_DomainError.ErrorType.DeliveryLeadtimeTooShort:
          return this.userError(
            'floorplan_order_unknown_delivery_time',
            'Could not book delivery for the requested delivery week. Try requesting another delivery week or contact support.',
          );
        case Interface_DTO_DomainError.ErrorType.OrderIsEmpty:
          return this.userError(
            'floorplan_order_failed_order_empty',
            'The floorplan is empty and cannot be ordered',
          );
        case Interface_DTO_DomainError.ErrorType.FloorPlanNotOrderable:
          return this.userError(
            'floorplan_order_failed_not_orderable',
            'The floorplan is not valid and cannot be ordered',
          );
        case Interface_DTO_DomainError.ErrorType.DeliveryAddressInvalid:
          return this.userError(
            'floorplan_order_failed_delivery_address_invalid',
            'The delivery address is not valid',
          );
        case Interface_DTO_DomainError.ErrorType.SalesTypeInvalid:
          return this.userError(
            'floorplan_save_failed_salesType_invalid',
            'The sales type is no longer valid.',
          );
        case Interface_DTO_DomainError.ErrorType.FloorOptionNotAllowed:
          return this.userError(
            'floorplan_save_failed_floor_option_invalid',
            'Floor option is not allowed for this zip code',
          );
        case Interface_DTO_DomainError.ErrorType.MultipleUsers:
          return this.userError(
            'change_password_failed_user_with_same_email_password_exists',
            'You already have another user with that password. Please choose another password',
          );
        case Interface_DTO_DomainError.ErrorType.ActiveOrder:
          return this.userError(
            'delivery_address_edit_error_locked',
            'Could not change delivery address because it has active orders',
          );
        case Interface_DTO_DomainError.ErrorType.ReadOnlyObject:
          return this.userError(
            'delivery_address_edit_fail_read_only',
            'Delivery address cannot be changed because it is read-only. Is it a store delivery address?',
          );
        case Interface_DTO_DomainError.ErrorType.FloorPlanPriceChanged:
          return this.userError(
            'delivery_address_edit_fail_price_changed',
            'Delivery address cannot be changed because it changes the price of active orders. ',
          );
        case Interface_DTO_DomainError.ErrorType.NotSupportedOnLegacyFloorplans:
          return this.userError(
            'delivery_address_edit_fail_contains_legacy_floorplans',
            'Delivery address cannot be changed because it contains legacy solutions.',
          );
        case Interface_DTO_DomainError.ErrorType.ProductLineExpired:
          return this.userError(
            'solutions_contains_expired_productlines',
            'Solution cannot be ordered because it contains expired product lines.',
          );
        case Interface_DTO_DomainError.ErrorType.ExternalIntegrationFailure:
          return this.userError(
            'basket_integration_failed_with_reason',
            'Oops, Could not add your solution to the basket.\nTry again, or save the solution for later.\n{0}',
            e.Text,
          );
        default:
      }
    } else if (e instanceof Exception.PropertyInvalid) {
      this.userError(
        'delivery_address_edit_error_invalid_property_' + e.propertyName,
        'Invalid property: {0}',
        e.propertyName,
      );
    }
    return await this.unknownException(e);
  }

  public displayRecalulationMessages(
    messages: Client.RecalculationMessage[],
  ): boolean {
    let notices = messages.filter(
      (msg) => !Client.RecalculationMessage.isQuestion(msg),
    );

    for (let msg of notices) {
      let notificationType: NotificationType;
      if (msg.severity === Enums.RecalculationMessageSeverity.Info)
        notificationType = NotificationType.Info;
      else if (msg.severity === Enums.RecalculationMessageSeverity.Warning)
        notificationType = NotificationType.Warning;
      else if (msg.severity === Enums.RecalculationMessageSeverity.Failure)
        notificationType = NotificationType.UserError;
      else throw new Error('Unknown relcalculation message severity');

      this.notify(
        notificationType,
        msg.key,
        msg.defaultValue,
        ...(msg.params || []),
      );
    }

    let questions = messages.filter((msg) =>
      Client.RecalculationMessage.isQuestion(msg),
    ) as RecalculationQuestion[];
    let questionTypes = Enumerable.from(questions).groupBy((q) => q.question);
    questionTypes.forEach((questionGroup) => {
      let firstQ = questionGroup.first();
      let questionText = this.translationService.translate(
        firstQ.key,
        firstQ.defaultValue,
      );

      let answer = confirm(questionText);
      questionGroup.forEach((q) => q.action(answer));
    });

    let testbool = questions.length > 0;
    return testbool;
  }

  private notify(
    type: NotificationType,
    textKey: string,
    defaultValue: string,
    ...translationParams: string[]
  ): Notification {
    const translation = this.translationService.translate(
      textKey,
      defaultValue,
      ...translationParams,
    );

    const notification: Notification = new Notification(type, translation);

    this.activeNotifications.push(notification);
    setTimeout(() => this.remove(notification), this.notificationDelayMs[type]);
    return notification;
  }

  public async loadPromise<T>(promise: Promise<T>): Promise<T> {
    this.loadingPromises.push(promise);
    try {
      return await promise;
    } finally {
      setTimeout(() => {
        let i = this.loadingPromises.indexOf(promise);
        if (i >= 0) {
          this.loadingPromises.splice(i, 1);
        }
      });
    }
  }

  public remove(notification: Notification): void {
    var index = this.activeNotifications.indexOf(notification);

    if (index > -1) {
      this.activeNotifications.splice(index, 1);
    }
  }

  /// Runs the provided action. If the action throws an exception, an appropriate notification is displayed.
  /// Returns true if no exceptions were caught (success)
  /// or false if any exception is thrown (failure)
  public displayExceptionMessages(action: () => any): boolean {
    try {
      action();
      return true;
    } catch (e: any) {
      if (e instanceof Exception.PropertyInvalid) {
        this.userError(
          'delivery_address_edit_error_invalid_property_' +
            e.propertyName.toString(),
          'Invalid property: {0}',
          e.propertyName.toString(),
        );
      }
    }

    return false;
  }

  /// Runs the provided action. If the action throws an exception, an appropriate notification is displayed.
  /// Returns true if no exceptions were caught (success)
  /// or false if any exception is thrown (failure)
  public async displayExceptionMessagesAsync(
    action: () => Promise<any>,
  ): Promise<boolean> {
    try {
      await action();
      return true;
    } catch (e: any) {
      if (e instanceof Exception.PropertyInvalid) {
        this.userError(
          'delivery_address_edit_error_invalid_property_' +
            e.propertyName.toString(),
          'Invalid property: {0}',
          e.propertyName.toString(),
        );
      }
    }

    return false;
  }
}

export class Notification {
  public constructor(
    public readonly type: NotificationType,
    public readonly text: string,
  ) {}
}

export const enum NotificationType {
  Success = 0,
  Info = 1,
  Warning = 2,
  SystemError = 3,
  UserError = 4,
}
