import { FillingId } from '../filling';
import { Wall, Module, ModuleId, DoorPlacement, Door } from '../module';
import { PartitionPlan } from '../partition-plan-data.service';
import { Section, SectionId } from '../section';
import { D3ViewFilling } from './filling';
import { D3ViewBar } from './bar';
import { ProductBarData } from 'app/ts/Interface_DTO';
import { FloorPlan } from 'app/ts/clientDto';
import Enumerable from 'linq';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';

export class D3ViewModule {
  constructor(
    private _moduleId: ModuleId,
    private _posX: number,
    private _posY: number,
    private _fillings: D3ViewFilling[],
    private _bars: D3ViewBar[],
    private _productId: number,
    private _width: number,
    private _height: number,
    private _depth: number,
    private _verticalBarOptionId: number,
    private _isDoor: boolean,

    private _previousModule?: D3ViewModule,
    private _nextModule?: D3ViewModule,
  ) {}

  public get id(): ModuleId {
    return this._moduleId;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get depth(): number {
    return this._depth;
  }

  public get isDoor(): boolean {
    return this._isDoor;
  }

  public get previousModule(): D3ViewModule | undefined {
    return this._previousModule;
  }

  public set previousModule(value: D3ViewModule | undefined) {
    this._previousModule = value;
  }

  public get nextModule(): D3ViewModule | undefined {
    return this._nextModule;
  }

  public set nextModule(value: D3ViewModule | undefined) {
    this._nextModule = value;
  }

  public get productId(): number {
    return this._productId;
  }

  public get verticalBarOptionId(): number {
    return this._verticalBarOptionId;
  }

  public get fillings(): Readonly<D3ViewFilling[]> {
    return this._fillings;
  }

  public get bars(): Readonly<D3ViewBar[]> {
    return this._bars;
  }
}

export class D3ViewWall extends D3ViewModule {}

export class D3ViewDoor extends D3ViewModule {
  constructor(
    _moduleId: ModuleId,
    _posX: number,
    _posY: number,
    _fillings: D3ViewFilling[],
    _bars: D3ViewBar[],
    _productId: number,
    _width: number,
    _height: number,
    _depth: number,
    _verticalBarOptionId: number,
    _isDoor: boolean,

    private _placement: DoorPlacement,

    _previousModule?: D3ViewModule,
    _nextModule?: D3ViewModule,
  ) {
    super(
      _moduleId,
      _posX,
      _posY,
      _fillings,
      _bars,
      _productId,
      _width,
      _height,
      _depth,
      _verticalBarOptionId,
      _isDoor,
      _previousModule,
      _nextModule,
    );
  }

  public get placement(): DoorPlacement {
    return this._placement;
  }
}

export function calculateModules(
  sectionId: SectionId,
  partitionPlan: Readonly<PartitionPlan>,
  floorPlan: FloorPlan,
  doorModuleProductId: number,
  wallModuleProductId: number,
): D3ViewModule[] {
  let section = partitionPlan.sections.find((sec) => sec.id == sectionId)!;
  let barData = getBarData(section, floorPlan, doorModuleProductId)!;

  let mappedModules = partitionPlan.modules
    .filter((md) => md.sectionId == sectionId)
    .map((md) => {
      let fillings = md.fillings.map((fl) => {
        if (md.isWall) {
          return new D3ViewFilling(
            fl.id,
            md.id,
            fl.materialId,
            fl.xOffset,
            calculateFillingY(
              md,
              fl.id,
              barData,
              floorPlan,
              wallModuleProductId,
            ),
            fl.width,
            fl.height,
          );
        } else {
          return new D3ViewFilling(
            fl.id,
            md.id,
            fl.materialId,
            fl.xOffset,
            calculateFillingY(
              md,
              fl.id,
              barData,
              floorPlan,
              doorModuleProductId,
            ),
            fl.width,
            fl.height,
          );
        }
      });

      let bars = md.bars.map((bar) => {
        return new D3ViewBar(
          bar.id,
          md.id,
          bar.position,
          bar.width,
          bar.height,
          bar.xOffset,
          md.height -
            bar.position -
            bar.height / 2 +
            Door.doorModuleHeightDifference,
        );
      });

      if (md instanceof Wall)
        return new D3ViewWall(
          md.id,
          md.posX,
          md.posY,
          fillings,
          bars,
          md.references.productId,
          md.width,
          md.height,
          md.depth,
          md.references.verticalBarOptionId,
          md.isDoor,
        );
      else {
        let door = md as Door;
        return new D3ViewDoor(
          md.id,
          md.posX,
          md.posY,
          fillings,
          bars,
          md.references.productId,
          md.width,
          md.height,
          md.depth,
          md.references.verticalBarOptionId,
          md.isDoor,
          door.placement,
        );
      }
    });

  // Map module links
  mappedModules.forEach((md) => {
    let module = partitionPlan.modules.find((dmd) => dmd.id == md.id)!;

    let prev = partitionPlan.modules.find(
      (dmd) => dmd.id == module.references.previousSectionModuleId,
    );
    if (prev)
      md.previousModule = mappedModules.find((mmd) => mmd.id == prev!.id)!;

    let next = partitionPlan.modules.find(
      (dmd) => dmd.id == module.references.nextSectionModuleId,
    );
    if (next) md.nextModule = mappedModules.find((mmd) => mmd.id == next!.id)!;
  });

  return mappedModules;
}

function getBarData(
  section: Section,
  floorPlan: FloorPlan,
  doorModuleProductId: number,
): Interface_DTO.ProductBarData | undefined {
  let fullCatalog =
    floorPlan.editorAssets.fullCatalog &&
    floorPlan.FullCatalogAllowOtherProducts;

  let doorData = Enumerable.from(
    floorPlan.editorAssets.productDoorData,
  ).firstOrDefault((pdd) => pdd.ProductId === doorModuleProductId);
  if (!doorData) return undefined;

  let availableBars = Enumerable.from(doorData.Bars).where(
    (bar) => fullCatalog || !bar.ChainOverride,
  );

  let barHeight = section.barHeight;
  let barType = Interface_Enums.BarType.Fixed; //door.forceFixedBars ? Interface_Enums.BarType.Fixed : Interface_Enums.BarType.Loose;

  // Find the ProductBarData with the right type and height
  return availableBars.firstOrDefault(
    (b) => b.BarType === barType && b.Height === barHeight,
  );
}

function calculateFillingY(
  module: Module,
  fillingId: FillingId,
  barData: ProductBarData,
  floorPlan: FloorPlan,
  moduleProductId: number,
): number {
  let doorOffset = module.isDoor ? 36 : 0;

  let sum =
    floorPlan.editorAssets.productDoorData.find(
      (pdd) => pdd.ProductId == moduleProductId,
    )?.FrameWidthTop! -
    Module.fillingInsertionDepth +
    doorOffset;
  for (let i = 0; i < module.fillings.length; i++) {
    let filling = module.fillings[i];
    if (filling.id == fillingId) break;

    sum += filling.height;
    sum += barData.BarMiddel;
  }

  return sum;
}
