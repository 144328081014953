import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { activeUserProvider } from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { first, firstValueFrom, map, tap } from 'rxjs';

@Component({
  selector: 'delivery-address-edit-menu',
  templateUrl: './deliveryAddressEditMenu.html',
  styleUrls: [
    '../../../../style/rightMenu.scss',
    '../../../../style/modal.scss',
  ],
  providers: [
    clientSettingProvider,
    activeUserProvider,
    DeliveryAddressService,
    CustomerSearchParams,
  ],
})
export class DeliveryAddressEditMenuVm extends BaseVm implements OnChanges {
  public numCopies: number = 1;
  public showShowChildren: boolean = false;
  public deliveryAddress: Interface_DTO.DeliveryAddress | undefined = undefined;
  public filterParams: CustomerSearchParams = {};
  private readonly actions: { [action: string]: boolean } = {
    copy: false,
    save: false,
    delete: false,
  };

  constructor(
    baseVmService: BaseVmService,
    private readonly customerService: CustomerService,
    private readonly deliveryAddressService: DeliveryAddressService,
    private readonly notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService,
    private routing: AppRoutingModule,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
  ) {
    super(baseVmService);
    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.showShowChildren = settings.showExtraOverviewTabs;
      }),
    );
    super.ensureUnsubscribe(
      this.activatedRoute.paramMap
        .pipe(
          first(),
          map((params) => CustomerSearchParams.FromParams(params)),
          tap((params) => {
            this.filterParams = params;
          }),
        )
        .subscribe(),
    );

    const snapShot = this.activatedRoute.snapshot.queryParamMap;
    this.filterParams = CustomerSearchParams.FromParams(snapShot);
  }

  // #region angularJS Bindings

  @Input()
  public clientDeliveryAddress: Client.DeliveryAddress | undefined;
  @Output()
  public onClose = new EventEmitter<void>();
  // #endregion

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.clientDeliveryAddress) {
      this.deliveryAddress = ObjectHelper.copy(
        this.clientDeliveryAddress.dtoObject,
      );
    } else {
      this.deliveryAddress = undefined;
    }
  }

  public async save() {
    if (!this.deliveryAddress) {
      return;
    }
    if (this.isActionInProgress) return;
    let deliveryAddress = this.deliveryAddress;
    try {
      this.actions['save'] = true;
      let customers = await firstValueFrom(this.customerService.customers$);
      let activeOrders = Enumerable.from(customers)
        .selectMany((cust) => cust.Projects)
        .selectMany((proj) => proj.DeliveryAddresses)
        .where((da) => da.Id === deliveryAddress.Id)
        .selectMany((da) => da.FloorPlans)
        .where((fp) => fp.CurrentStatus > Interface_Enums.DeliveryStatus.Quote);
      let now = new Date();
      let lockedOrders = activeOrders.where(
        (fp) =>
          !!fp.CorrectionDeadlineDate &&
          DateHelper.fromIsoString(fp.CorrectionDeadlineDate) < now,
      );

      if (lockedOrders.any()) {
        this.notificationService.userError(
          'delivery_address_edit_error_locked',
          'Could not change delivery address because it has active orders',
        );
        return;
      }

      let activeOrderCount = activeOrders.count();
      if (activeOrderCount > 0) {
        let userAccept = confirm(
          this.translate(
            'delivery_address_edit_question_recalculate',
            'This delivery address has {0} active orders. Editing the delivery address will recalculate edit deadlines and freight prices. Do you want to continue?',
            activeOrderCount.toString(),
          ),
        );
        if (!userAccept) {
          return;
        }
      }

      let promise =
        this.deliveryAddressService.updateDeliveryAddress(deliveryAddress);
      this.notificationService.loadPromise(promise);
      await promise;
      this.notificationService.success(
        'deliveryaddresses_update_complete',
        'DeliveryAddress saved',
      );
      this.close();
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    } finally {
      this.actions['save'] = false;
    }
  }

  public async delete() {
    if (this.isActionInProgress) return;
    if (!this.clientDeliveryAddress) return;

    let sure = confirm(
      this.translate(
        'delivery_address_confirm_delete',
        'Are you sure you want to delete this address?',
      ),
    );
    if (!sure) return;

    try {
      this.actions['delete'] = true;
      let deleteTask = this.deliveryAddressService.deleteDeliveryAddresses([
        this.clientDeliveryAddress.ProjectDeliveryAddressId,
      ]);
      this.notificationService.loadPromise(deleteTask);
      await deleteTask;
      this.close();
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          this.notificationService.userError(
            'delivery_address_delete_fail_active_order',
            'Delivery address cannot be deleted because it has active orders',
          );
          return;
        } else if (
          e.Type === Interface_DTO_DomainError.ErrorType.ReadOnlyObject
        ) {
          this.notificationService.userError(
            'delivery_address_delete_fail_read_only',
            'Delivery address cannot be deleted because it is read-only. Is it a store delivery address?',
          );
          return;
        }
      }
      this.notificationService.exception(
        'delivery_address_unknown_error',
        e,
        'Unknown error while deleting delivery address',
      );
    }
    this.actions['delete'] = false;
  }

  public get isActionInProgress(): boolean {
    for (let action in this.actions) {
      if (this.actions[action]) {
        return true;
      }
    }
    return false;
  }

  public async copy() {
    if (this.isActionInProgress) return;
    if (!this.deliveryAddress) return;
    if (!this.clientDeliveryAddress) return;
    try {
      this.actions['copy'] = true;
      let startProjectId = this.clientDeliveryAddress.project.customer.Id;
      let projects = await this.modalService.getProjectSelector(
        startProjectId,
        true,
        false,
        false,
      );
      try {
        let copyPromise = this.deliveryAddressService.copyDeliveryAddress(
          this.clientDeliveryAddress.ProjectDeliveryAddressId,
          projects.map((proj) => proj.Id),
          this.numCopies,
        );
        this.notificationService.loadPromise(copyPromise);
        await copyPromise;
        this.numCopies = 1;
      } catch (e: any) {
        this.notificationService.exception(
          'delivery_address_copy_failed_unknown',
          e,
          'Copy failed for unknown reasons',
        );
      }
    } catch (e: any) {
      //user cancelled modal - do nothing
    } finally {
      this.actions['copy'] = false;
    }
  }

  public close() {
    this.onClose.emit();
  }

  public showChildren() {
    if (!this.deliveryAddress) {
      return;
    }

    this.routing.navigate.floorPlans({
      ...this.filterParams,
      deliveryAddressId:
        this.deliveryAddress.ProjectDeliveryAddressId.toString(),
    });
  }
}
