import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CustomPriceDate,
  CustomPriceDateInjector,
  customPriceDateProvider,
} from 'app/functional-core/ambient/clientSetting/CustomPriceDate';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  selector: 'custom-price-date',
  templateUrl: 'customPriceDate.html',
  styleUrls: ['../../../../style/modal.scss'],
  providers: [customPriceDateProvider],
})
export class CustomPriceDateVm extends BasicModalVm<undefined> {
  constructor(
    baseVmService: BaseVmService,
    activeModal: NgbActiveModal,
    @Inject(CustomPriceDateInjector)
    private readonly customPriceDate: CustomPriceDate,
  ) {
    super(baseVmService, activeModal);

    let date = new Date();
    date.setMilliseconds(0);
    date.setSeconds(0);
    this.date = date;

    this.date = customPriceDate.data ?? new Date();
    this.useCustomDate = this.isToday(this.date) ? '0' : '1';
  }

  public useCustomDate: '1' | '0' = '0';
  public date: Date | undefined;

  private isToday(date: Date) {
    var today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  public updateDate(dateStr: string) {
    this.date = new Date(dateStr);
  }

  public override close() {
    let d: Date | undefined;
    if (this.useCustomDate === '0') {
      d = undefined;
    } else {
      d = this.date;
    }

    this.customPriceDate.set(d);
    super.close(undefined);
  }
}
