import { Injectable } from '@angular/core';
import { ArgumentException } from 'app/library/argument.exception';
import { BarId } from './bar';
import { Defaults } from './defaults';
import { FillingId } from './filling';
import {
  Door,
  DoorPlacement,
  Module,
  ModuleId,
  ModuleType,
  Wall,
} from './module';
import { PartitionPlanDataService } from './partition-plan-data.service';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import { Section, SectionId } from './section';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import {
  CornerProfile,
  NicheProfile,
  Profile,
  ProfileId,
  ProfileType,
  SectionConnection,
  StartStopProfile,
  TProfile,
} from './profile';
import { AssetService } from 'app/ts/services/AssetService';
import { PartitionDragType, Side, DoorOpenSide } from 'app/ts/clientDto/Enums';
import Enumerable from 'linq';
import { Partition } from './partition';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import { PartitionRailService } from './partition-rail.service';
import { BarType, ProductLineId } from 'app/ts/Interface_Enums';
import { PartitionPlanGeometryQueryService } from './partition-plan-geometry-query.service';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { Rail } from './rail';

@Injectable()
export class PartitionPlanCommandService {
  constructor(
    private data: PartitionPlanDataService,
    private query: PartitionPlanQueryService,
    private queryGeometry: PartitionPlanGeometryQueryService,
    private railService: PartitionRailService,
    private assetService: AssetService,
    private editorTypeService: EditorTypeService,
  ) {}

  private static trivialRestAmount = 20; //used for extra rail length calculation (represent the trivial min amount we accept as the gap between rail and wall. Less than this we set the length to the max lenght)

  public addPartitionWithSection(sectionId: SectionId) {
    let id = this.data.nextPartitionId();

    let partitionProductLine = this.assetService.editorAssets.productLines.find(
      (pl) => pl.Id == ProductLineId.Partition,
    );
    let name = partitionProductLine?.Name ?? 'Partition';

    let partition = {
      id,
      name: `${name} ${id}`,
      sections: [sectionId],
    };

    this.data.plan.partitions.push(partition);
  }

  public moveToPartition(sectionId: SectionId, partition: Partition | null) {
    let prevPartition = this.query.getPartitionForSection(sectionId);

    // Remove from old
    prevPartition.sections = prevPartition.sections.filter(
      (secId) => secId !== sectionId,
    );
    if (prevPartition.sections.length == 0)
      this.data.plan.partitions = this.data.plan.partitions.filter(
        (prt) => prt.id != prevPartition.id,
      );

    // Add to new, if not null
    if (partition != null) partition.sections.push(sectionId);
    else {
      // Assumption - partition only null when we delete a section
      this.remakePartitions(sectionId);
    }
  }

  public remakePartitions(deletedSectionId: SectionId) {
    let connectedSections = this.data.plan.sections
      .filter((sec) => sec.id != deletedSectionId)
      .map((sec) => this.query.getAllSectionsConnectedToSection(sec.id).sort());

    // Remove duplicates
    let stringifiedConnections = connectedSections.map((ls) =>
      JSON.stringify(ls),
    );
    let dupeFreeList = new Set(stringifiedConnections);
    let unique = [...dupeFreeList].map((str) => JSON.parse(str));

    let nameDict: { [key: string]: number } = {};

    let partitions: Partition[] = unique.map((un, idx) => {
      let oldPartition = this.query.getPartitionForSection(un[0]);

      let name = oldPartition.name;
      if (name in nameDict) {
        let newName = name + ' ' + nameDict[name];
        nameDict[name] += 1;
        name = newName;
      } else {
        nameDict[name] = 1;
      }

      return {
        id: idx,
        name: name,
        sections: un,
      } as Partition;
    });

    this.data.setPartitionIdCounter(partitions.length + 1);

    this.data.plan.partitions = partitions;
  }

  public updatePartitionName(sectionId: SectionId, newName: string) {
    this.query.getPartitionForSection(sectionId).name = newName;
  }

  /**
   * NOTE: Right now we have no idea to handle how to add a
   * section on existing partition/section
   * @param posX
   * @param posY
   * @returns
   */
  public addSection(
    posX: number,
    posY: number,
    roomHeight: number,
    rotation?: number,
    addLeftStartStop: boolean = true,
    addRightStartStop: boolean = true,
  ): Section {
    if (posX < 0) throw new ArgumentException('x');
    if (posY < 0) throw new ArgumentException('y');

    const plan = this.data.plan;
    const defaults = this.data.defaults;
    const defaultSectionWidth = defaults.sectionWidth;
    const sectionId = this.data.nextSectionId();

    const section = new Section(
      sectionId,
      posX,
      posY,
      defaultSectionWidth,
      roomHeight,
      defaults.sectionBarHeight,
      rotation ?? 0,
      [],
      {
        profileMaterialId: defaults.sectionProfileMaterialId,
        wallRailSetId: defaults.wallRailSetId,
        doorRailSetId: defaults.doorRailSetId,
      },
      false,
      false,
    );

    let prevModule: Module | undefined = undefined;
    for (
      let moduleCount = 0;
      moduleCount < defaults.numberOfModulesInSection;
      moduleCount++
    ) {
      prevModule = this.createWall(section, defaults, roomHeight, prevModule);
    }

    let profileHeight = roomHeight - Profile.profileRailReduction;

    if (addLeftStartStop) {
      let leftProfileId = this.addStartStopProfile(
        sectionId,
        Side.Left,
        posX,
        posY,
        profileHeight,
        0,
      );
      let leftModule = this.query.getHeadModuleForSection(sectionId) as Module;
      leftModule.references.leftProfileId = leftProfileId;
    }

    if (addRightStartStop) {
      let rightProfileId = this.addStartStopProfile(
        sectionId,
        Side.Right,
        posX + defaultSectionWidth,
        posY + Module.fullJointWidth,
        profileHeight,
        180,
      );
      let rightModule = this.query.getTailModuleForSection(sectionId) as Module;
      rightModule.references.rightProfileId = rightProfileId;
    }

    plan.sections.push(section);
    this.addPartitionWithSection(sectionId);
    this.updateModulesWidthAndPosition(section.id);
    this.railService.recalculateSectionRails(section.id);
    return section;
  }

  private createWall(
    section: Section,
    defaults: Defaults,
    roomHeight: number,
    prevModule?: Module,
  ): Module {
    let moduleHeight =
      roomHeight -
      Section.spacingFromCeilingToTopModuleFrame -
      Section.spacingFromFloorToBottomModuleFrame(ModuleType.Wall);

    const wall = Wall.createNew(
      this.data.nextModuleId(),
      section.id,
      0,
      0,
      1000, // does not matter gets recalculated afterwards
      moduleHeight,
      defaults.wallDepth,
      defaults.moduleWallProductId,
      defaults.verticalBarOptionId,
      defaults.fillingMaterialId,
      prevModule?.id,
    );

    if (prevModule) prevModule.references.nextSectionModuleId = wall.id;

    section.addWall(wall.id);

    const plan = this.data.plan;
    plan.modules.push(wall);
    this.recalculateFillingHeights(wall.id);
    return wall;
  }

  public updateSectionPosition(
    sectionId: SectionId,
    posX: number,
    posY: number,
  ) {
    const section = this.query.getSection(sectionId) as Section;
    section.posX = posX;
    section.posY = posY;

    this.updateModulesWidthAndPosition(sectionId);
    this.updateProfilePositionAndRotation(sectionId);

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);

    this.railService.recalculateRailPositions(sectionId);

    this.data.triggerNextPlan();
  }

  public handleMultiDrag(
    draggedSectionId: SectionId,
    moveX: number,
    moveY: number,
  ) {
    let { aligned, side1, side2 } =
      this.query.getMultiDragAffectedSections(draggedSectionId);

    //Move aligned sections
    aligned.forEach((sec) => {
      this.updateSectionPosition(sec.id, sec.posX + moveX, sec.posY + moveY);
      this.updateProfilePositionAndRotation(sec.id);
    });

    //Stretch or shrink non aligned sections
    side1.forEach((sec) => {
      let section = this.query.getSection(sec.sectionId);

      if (moveX != 0) {
        this.setSectionWidth(
          sec.sectionId,
          section.width + moveX,
          Side.inverse(sec.side),
        );
      } else if (moveY != 0) {
        this.setSectionWidth(
          sec.sectionId,
          section.width - moveY,
          Side.inverse(sec.side),
        );
      }
    });

    side2.forEach((sec) => {
      let section = this.query.getSection(sec.sectionId);

      if (moveX != 0) {
        this.setSectionWidth(
          sec.sectionId,
          section.width - moveX,
          Side.inverse(sec.side),
        );
      } else if (moveY != 0) {
        this.setSectionWidth(
          sec.sectionId,
          section.width + moveY,
          Side.inverse(sec.side),
        );
      }
    });
  }

  private removeModuleFromSection(sectionId: SectionId) {
    let section = this.data.plan.sections.filter((s) => s.id == sectionId)[0];
    if (!section) {
      return;
    }

    let tailModule = this.query.getTailModuleForSection(section.id);

    if (!tailModule) return;

    if (this.query.selectedModule?.id == tailModule.id)
      this.setSelectedModule(tailModule.references.previousSectionModuleId!);

    if (tailModule.isWall) {
      section.removeWall(tailModule.id);
      this.data.plan.modules = this.data.plan.modules.filter(
        (wl) => wl.id != tailModule!.id,
      );
    } else if (tailModule.isDoor) {
      if (tailModule.references.rightProfileId) {
        let profile = this.query.getProfile(
          tailModule.references.rightProfileId,
        );
        let sectionConnection = profile.sections.filter(
          (s) => s.profileId != null && s.sectionId == tailModule.sectionId,
        )[0];
        if (sectionConnection) {
          this.removeProfile(sectionConnection.profileId!);
          sectionConnection.profileId = undefined;
        }
      }
      section.removeDoor(tailModule.id);
      this.data.plan.modules = this.data.plan.modules.filter(
        (dr) => dr.id != tailModule!.id,
      );
    }

    // Can't get previous module on first module
    if (tailModule.references.previousSectionModuleId) {
      let module = this.query
        .getAllModules()
        .find(
          (md) => md.id == tailModule.references.previousSectionModuleId,
        )! as Module;
      module.references.nextSectionModuleId = undefined;

      // TailModule has right start/stop profile
      if (
        tailModule.references.rightProfileId &&
        !this.query.isJointProfile(tailModule.references.rightProfileId)
      ) {
        module.references.rightProfileId = tailModule.references.rightProfileId;
      }

      // TailModule is wall has 2 start/stop profile with left door
      if (
        tailModule.isWall &&
        tailModule.references.rightProfileId &&
        tailModule.references.leftProfileId &&
        module.isDoor &&
        !this.query.isJointProfile(tailModule.references.rightProfileId) &&
        !module.references.leftProfileId &&
        module.references.previousSectionModuleId &&
        this.query.getModule(module.references.previousSectionModuleId).isWall
      ) {
        this.removeProfile(tailModule.references.rightProfileId);
        this.removeProfile(tailModule.references.leftProfileId);
        module.references.rightProfileId = undefined;
        tailModule.references.rightProfileId = undefined;
        this.setDoorOpenDirection(module.id, 'left' as DoorOpenSide);
      }

      // TailModule with left door which has left joint profile
      if (
        module.isDoor &&
        this.query.isJointProfile(module.references.leftProfileId!)
      ) {
        this.convertDoorToWall(module);
        this.removeProfile(tailModule.references.leftProfileId!);
        tailModule.references.leftProfileId = undefined;
      }

      // TailModule has right profile
      if (tailModule.references.rightProfileId) {
        // TailModule is door has right joint profile
        if (
          tailModule.isDoor &&
          module.references.rightProfileId &&
          this.query.isJointProfile(tailModule.references.rightProfileId)
        ) {
          this.removeProfile(module.references.rightProfileId);
          module.references.rightProfileId = undefined;
        }

        // TailModule is wall has right joint profile
        if (this.query.isJointProfile(tailModule.references.rightProfileId!)) {
          module.references.rightProfileId =
            tailModule.references.rightProfileId;
        }

        // TailModule is wall has 2 start/stop profile with left door (Modules > 2)
        if (
          tailModule.isWall &&
          module.isDoor &&
          !this.query.isJointProfile(tailModule.references.rightProfileId) &&
          tailModule.references.leftProfileId &&
          module.references.previousSectionModuleId &&
          this.query.getModule(module.references.previousSectionModuleId).isWall
        ) {
          this.removeProfile(tailModule.references.rightProfileId);
          this.removeProfile(tailModule.references.leftProfileId);
          tailModule.references.rightProfileId = undefined;
          tailModule.references.leftProfileId = undefined;
        }

        // TailModule is wall has right joint profile but (Modules = 2)
        if (
          module.isDoor &&
          tailModule.references.rightProfileId &&
          this.query.isJointProfile(tailModule.references.rightProfileId) &&
          !module.references.previousSectionModuleId
        ) {
          module.references.leftProfileId = tailModule.references.leftProfileId;
          this.convertDoorToWall(module);
        }

        // TailModule is wall has right joint profile with left door (Modules > 2)
        else if (
          module.isDoor &&
          tailModule.references.rightProfileId &&
          this.query.isJointProfile(tailModule.references.rightProfileId) &&
          module.references.previousSectionModuleId &&
          this.query.getModule(module.references.previousSectionModuleId).isWall
        ) {
          let profile = this.query.getProfile(
            tailModule.references.rightProfileId,
          );
          let getProfile = profile.sections.filter(
            (s) => s.profileId == null && s.sectionId == tailModule.sectionId,
          )[0];
          getProfile.profileId = tailModule.references.leftProfileId;
          this.setDoorOpenDirection(module.id, 'left' as DoorOpenSide);
        }

        // TailModule is wall with left doubledoors (Modules > 2)
        else if (
          module.isDoor &&
          tailModule.references.leftProfileId &&
          module.references.previousSectionModuleId &&
          !this.query.getModule(module.references.previousSectionModuleId)
            .isDoor
        ) {
          this.removeProfile(tailModule.references.leftProfileId);
          tailModule.references.leftProfileId = undefined;
        }
      }

      // TailModule is wall with left doubledoors when modules > 2 or with left door when modules is 2
      if (
        (tailModule.isWall &&
          module.isDoor &&
          module.references.previousSectionModuleId &&
          this.query.getModule(module.references.previousSectionModuleId)
            .isDoor) ||
        (module.isDoor && !module.references.previousSectionModuleId)
      ) {
        module.references.leftProfileId = tailModule.references.leftProfileId;
        this.convertDoorToWall(module);
      }
    }
    this.updateProfilePositionAndRotation(sectionId);
  }

  public setSectionWidth(
    sectionId: SectionId,
    newWidth: number,
    extendFrom: Side,
  ) {
    if (newWidth < this.query.getSectionMinWidth(sectionId))
      newWidth = this.query.getSectionMinWidth(sectionId);

    let section = this.query.getSection(sectionId) as Section;
    let { leftReduction, rightReduction } =
      this.query.getSectionWidthReductions(sectionId);

    let numModules = this.query.getModulesForSection(sectionId).length;
    let totalSpacing = (numModules - 1) * Module.elementSpacing;
    let widthReductionFromDoors = this.query.getDoorWidthReduction(section.id);
    let totalModuleWidth =
      newWidth -
      leftReduction -
      rightReduction -
      totalSpacing -
      widthReductionFromDoors;

    let moduleWidth = totalModuleWidth / numModules;

    let changedNumberOfModules = false;
    while (moduleWidth < this.query.getModuleMinWidth()) {
      numModules--;
      moduleWidth = totalModuleWidth / numModules;
      changedNumberOfModules = true;
    }

    while (moduleWidth > this.query.getModuleMaxWidth()) {
      numModules++;
      moduleWidth = totalModuleWidth / numModules;
      changedNumberOfModules = true;
    }

    if (changedNumberOfModules)
      this.setNumberOfModulesForSection(sectionId, numModules);

    let actualNewWidth =
      moduleWidth * numModules +
      leftReduction +
      rightReduction +
      totalSpacing +
      widthReductionFromDoors;
    let diff = section.width - actualNewWidth;
    if (extendFrom == Side.Right) {
      let newPos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
        sectionId,
        diff,
        0,
      );
      this.updateSectionPosition(sectionId, newPos.X, newPos.Y);
    }

    section.width = actualNewWidth;
    this.updateModulesWidthAndPosition(sectionId);
    this.updateProfilePositionAndRotation(sectionId);
    this.railService.recalculateSectionRails(sectionId);
    this.data.triggerNextPlan();
  }

  updateSectionWidth(
    sectionId: SectionId,
    newWidth: number,
    overrideSide?: Side,
  ) {
    let leftProfile = this.query.getSectionLeftProfile(sectionId);
    let rightProfile = this.query.getSectionRightProfile(sectionId);

    if (
      leftProfile &&
      this.query.isJointProfile(leftProfile.id) &&
      rightProfile &&
      this.query.isJointProfile(rightProfile.id)
    )
      return;

    let extendFrom: Side | undefined = overrideSide;
    if (!extendFrom) {
      //Default logic
      extendFrom = Side.Left;

      if (rightProfile && this.query.isJointProfile(rightProfile.id))
        extendFrom = Side.Right;
    }

    let section = this.query.getSection(sectionId);
    let maxWidth = section.width;
    if (extendFrom == Side.Left)
      maxWidth +=
        this.queryGeometry.getDistanceFromRelativeRightSide(sectionId);
    else
      maxWidth += this.queryGeometry.getDistanceFromRelativeLeftSide(sectionId);

    newWidth = Math.min(newWidth, maxWidth);

    this.setSectionWidth(sectionId, newWidth, extendFrom);

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);
  }

  updatePartitionHeights(newHeight: number) {
    this.data.plan.sections.forEach((sec) => (sec.height = newHeight));

    let moduleHeight =
      newHeight -
      Section.spacingFromCeilingToTopModuleFrame -
      Section.spacingFromFloorToBottomModuleFrame(ModuleType.Wall);
    this.data.plan.modules.forEach((md) => {
      md.height = md.isDoor
        ? moduleHeight - Door.doorModuleHeightDifference
        : moduleHeight;

      this.recalculateFillingHeights(md.id);
      this.recalculateBarPositions(md.id);
    });

    let profileHeight = newHeight - Profile.profileRailReduction;
    this.data.plan.profiles.forEach((pfl) => {
      pfl.height = profileHeight;
    });
  }

  setNumberOfModulesForSection(sectionId: SectionId, numberOfModules: number) {
    let section = this.query.getSection(sectionId) as Section;
    let sectionModulesCount = this.query.getModulesForSection(
      section.id,
    ).length;
    let increase = sectionModulesCount < numberOfModules;

    while (sectionModulesCount != numberOfModules) {
      if (increase) {
        let tailModule = this.query.getTailModuleForSection(
          section.id,
        ) as Module;
        let newWall = this.createWall(
          section,
          this.data.defaults,
          section.height,
          tailModule,
        );

        if (tailModule.isDoor) {
          let profileHeight = section.height - Profile.profileRailReduction;
          newWall.references.leftProfileId = this.addStartStopProfile(
            sectionId,
            Side.Left,
            0,
            0,
            profileHeight,
            section.rotation,
          );
          if (
            tailModule.references.rightProfileId &&
            this.query.isJointProfile(tailModule.references.rightProfileId)
          ) {
            newWall.references.rightProfileId =
              tailModule.references.rightProfileId;
            let profile = this.query.getProfile(
              tailModule.references.rightProfileId,
            );
            let sectionConnection = profile.sections.filter(
              (s) => s.profileId != null && s.sectionId == tailModule.sectionId,
            )[0];
            if (sectionConnection) {
              this.removeProfile(sectionConnection.profileId!);
              sectionConnection.profileId = undefined;
            }
          } else {
            newWall.references.rightProfileId = this.addStartStopProfile(
              sectionId,
              Side.Right,
              0,
              0,
              profileHeight,
              (section.rotation + 180) % 360,
            );
          }
        } else {
          newWall.references.rightProfileId =
            tailModule.references.rightProfileId;
          tailModule.references.rightProfileId = undefined;
        }

        sectionModulesCount++;
      } else {
        this.removeModuleFromSection(section.id);
        sectionModulesCount--;
      }
    }

    this.updateModulesWidthAndPosition(section.id);

    this.updateProfilePositionAndRotation(section.id);
    this.railService.recalculateSectionRails(section.id);
    this.data.triggerNextPlan();
  }

  public adjustTPieceSectionWidths(sectionId: SectionId, widthDiff: number) {
    // Function is only called, on middle section
    this.query
      .getAllProfiles()
      .filter(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
      .forEach((p) => {
        let profile = p as TProfile;

        let leftSection = this.query.getSection(profile.leftSection.sectionId);
        let inverseFactor =
          profile.rotation == 0 || profile.rotation == 270 ? -1 : 1;
        let leftNewWidth = leftSection.width + widthDiff * inverseFactor;
        let leftSectionExtendFrom = Side.inverse(profile.leftSection.side);
        this.setSectionWidth(
          leftSection.id,
          leftNewWidth,
          leftSectionExtendFrom,
        );

        let rightSection = this.query.getSection(
          profile.rightSection.sectionId,
        );
        let rightNewWidth = rightSection.width - widthDiff * inverseFactor;
        let rightSectionExtendFrom = Side.inverse(profile.rightSection.side);
        this.setSectionWidth(
          rightSection.id,
          rightNewWidth,
          rightSectionExtendFrom,
        );
      });
  }

  updateModulesWidthAndPosition(sectionId: SectionId) {
    const section = this.query.getSection(sectionId);
    const modules = this.query.getModulesForSection(sectionId);
    const { leftReduction, rightReduction } =
      this.query.getSectionWidthReductions(sectionId);

    const totalSpacing = (modules.length - 1) * Module.elementSpacing;
    let widthReductionFromDoors = this.query.getDoorWidthReduction(section.id);
    const totalReduction =
      leftReduction + rightReduction + totalSpacing + widthReductionFromDoors;
    const newModuleWidth = (section.width - totalReduction) / modules.length;

    let posX = leftReduction; // first module should start the same amount offset

    modules.forEach((m, i, arr) => {
      if (i < arr.length - 1 && m.isDoor && arr[i + 1].isDoor) {
        posX += 12.5;
      } else if (m.isDoor && m.references.nextSectionModuleId == undefined) {
        posX += 15;
      }

      m.width = newModuleWidth + (m.isDoor ? Door.extraWidth : 0);
      m.posX = posX;
      posX += newModuleWidth + Module.elementSpacing;

      if (m instanceof Door) {
        /*Moved this
        if (m.openDirection == DoorOpenSide.Left)
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.leftSlideExtensionAmount,
            DoorOpenSide.Left,
          );
        else if (m.openDirection == DoorOpenSide.Right)
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.rightSlideExtensionAmount,
            DoorOpenSide.Right,
          );
        else if (m.openDirection == DoorOpenSide.Both) {
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.leftSlideExtensionAmount,
            DoorOpenSide.Left,
          );
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.rightSlideExtensionAmount,
            DoorOpenSide.Right,
          );
        }
        */
        this.ensureCorrectDoorPosition(m);
      }

      if (i > 0 && arr[i - 1].isDoor && m.isDoor) {
        posX += 12.5;
      } else if (
        m.isDoor &&
        m.references.previousSectionModuleId == undefined
      ) {
        posX += 15;
      }

      let fillingWidthReduction = m.isDoor
        ? Door.fillingWidthReduction
        : Module.fillingWidthReduction;
      m.fillings.forEach((filling) => {
        filling.width = m.width - fillingWidthReduction * 2;
      });

      let reduction =
        (m.isDoor ? Door.fillingWidthReduction : Module.fillingWidthReduction) +
        Module.fillingInsertionDepth;
      m.bars.forEach((bar) => {
        bar.xOffset = reduction;
        bar.width =
          newModuleWidth - reduction * 2 + (m.isDoor ? Door.extraWidth : 0);
      });
    });

    modules.forEach((m, i, arr) => {
      if (m instanceof Door) {
        if (m.openDirection == DoorOpenSide.Left)
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.leftSlideExtensionAmount,
            DoorOpenSide.Left,
          );
        else if (m.openDirection == DoorOpenSide.Right)
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.rightSlideExtensionAmount,
            DoorOpenSide.Right,
          );
        else if (m.openDirection == DoorOpenSide.Both) {
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.leftSlideExtensionAmount,
            DoorOpenSide.Left,
          );
          this.updateDoorSlideExtensionAmount(
            m.id,
            m.rightSlideExtensionAmount,
            DoorOpenSide.Right,
          );
        }
      }
    });
  }

  private ensureCorrectDoorPosition(door: Door) {
    let leftModule = this.query.getNeighborModule(door.id, Side.Left) as Module;
    if (leftModule && leftModule.isDoor) {
      // Double door case, where door is on right
      door.posX -= Door.extraWidth / 2 - 12.5;
    }

    let rightModule = this.query.getNeighborModule(
      door.id,
      Side.Right,
    ) as Module;
    let rightProfile = this.query.getSectionRightProfile(door.sectionId);
    if (!rightModule && !rightProfile) {
      // Door at section right edge
      door.posX -= Door.extraWidth;
    } else if (rightModule && rightModule.isDoor) {
      // Double door case, where door is on left
      door.posX -= Door.extraWidth / 2 + 12.5;
    } else if (
      !rightModule &&
      rightProfile &&
      this.query.isJointProfile(rightProfile.id)
    ) {
      // Door at right with joint profile
      door.posX -= Door.extraWidth;
    }

    // Normal case
    if (
      leftModule &&
      !leftModule.isDoor &&
      rightModule &&
      !rightModule.isDoor
    ) {
      door.posX -= Door.extraWidth / 2;
    }
  }

  public deleteSelectedPartition() {
    let partition = this.query.getPartitionForSection(
      this.query.selectedSection!.id,
    );

    for (let sectionId of partition.sections) {
      this.deleteSection(sectionId);
    }

    let partitionProfiles = this.query.plan.profiles.filter((pfl) =>
      pfl.sections.every((pSec) => partition.sections.includes(pSec.sectionId)),
    );
    for (let profile of partitionProfiles) {
      this.removeProfile(profile.id);
    }

    this.data.triggerNextPlan();
  }

  public copyPartitionProperties(
    sourceSectionId: SectionId,
    destinationSectionId: SectionId,
  ) {
    let sourceSection = this.query.getSection(sourceSectionId);
    let destinationSection = this.query.getSection(
      destinationSectionId,
    ) as Section;

    destinationSection.references.profileMaterialId =
      sourceSection.references.profileMaterialId;

    let sourcePartition = this.query.getPartitionForSection(sourceSectionId);
    this.moveToPartition(destinationSectionId, sourcePartition);
  }

  public joinSections(
    leftSectionId: SectionId,
    rightSectionId: SectionId,
    inverted: boolean,
  ) {
    let rightSectionModules = this.query.getModulesForSection(rightSectionId);

    this.query
      .getAllProfiles()
      .filter((pfl) =>
        pfl.sections.map((secCon) => secCon.sectionId).includes(rightSectionId),
      )
      .forEach((pfl) => {
        (pfl as Profile).sections = pfl.sections.map((secCon) =>
          secCon.sectionId == rightSectionId
            ? { sectionId: leftSectionId, side: secCon.side }
            : secCon,
        );
      });

    if (inverted) {
      let leftSectionHeadModule =
        this.query.getHeadModuleForSection(leftSectionId);
      let rightSectionTailModule =
        this.query.getTailModuleForSection(rightSectionId);

      if (leftSectionHeadModule.references.leftProfileId) {
        this.removeProfile(leftSectionHeadModule.references.leftProfileId);
        leftSectionHeadModule.references.leftProfileId = undefined;
      }
      if (rightSectionTailModule.references.rightProfileId) {
        this.removeProfile(rightSectionTailModule.references.rightProfileId);
        rightSectionTailModule.references.rightProfileId = undefined;
      }

      if (leftSectionHeadModule.isDoor && rightSectionTailModule.isWall) {
        let leftSection = this.query.getSection(leftSectionId);
        let profileHeight = leftSection.height - Profile.profileRailReduction;
        let profileId = this.addStartStopProfile(
          leftSectionId,
          Side.Left,
          0,
          0,
          profileHeight,
          leftSection.rotation,
        );
        rightSectionTailModule.references.rightProfileId = profileId;
      }

      if (leftSectionHeadModule.isWall && rightSectionTailModule.isDoor) {
        let leftSection = this.query.getSection(leftSectionId);
        let profileHeight = leftSection.height - Profile.profileRailReduction;
        let profileId = this.addStartStopProfile(
          leftSectionId,
          Side.Left,
          0,
          0,
          profileHeight,
          leftSection.rotation,
        );
        leftSectionHeadModule.references.leftProfileId = profileId;
      }

      leftSectionHeadModule.references.previousSectionModuleId =
        rightSectionTailModule.id;
      rightSectionTailModule.references.nextSectionModuleId =
        leftSectionHeadModule.id;
    } else {
      let leftSectionTailModule =
        this.query.getTailModuleForSection(leftSectionId);
      let rightSectionHeadModule =
        this.query.getHeadModuleForSection(rightSectionId);

      if (leftSectionTailModule.references.rightProfileId) {
        this.removeProfile(leftSectionTailModule.references.rightProfileId);
        leftSectionTailModule.references.rightProfileId = undefined;
      }
      if (rightSectionHeadModule.references.leftProfileId) {
        this.removeProfile(rightSectionHeadModule.references.leftProfileId);
        rightSectionHeadModule.references.leftProfileId = undefined;
      }

      if (leftSectionTailModule.isDoor && rightSectionHeadModule.isWall) {
        let leftSection = this.query.getSection(leftSectionId);
        let profileHeight = leftSection.height - Profile.profileRailReduction;
        let profileId = this.addStartStopProfile(
          leftSectionId,
          Side.Left,
          0,
          0,
          profileHeight,
          leftSection.rotation,
        );
        rightSectionHeadModule.references.leftProfileId = profileId;
      }

      if (leftSectionTailModule.isWall && rightSectionHeadModule.isDoor) {
        let leftSection = this.query.getSection(leftSectionId);
        let profileHeight = leftSection.height - Profile.profileRailReduction;
        let profileId = this.addStartStopProfile(
          leftSectionId,
          Side.Left,
          0,
          0,
          profileHeight,
          leftSection.rotation,
        );
        leftSectionTailModule.references.rightProfileId = profileId;
      }

      leftSectionTailModule.references.nextSectionModuleId =
        rightSectionHeadModule.id;
      rightSectionHeadModule.references.previousSectionModuleId =
        leftSectionTailModule.id;
    }

    rightSectionModules.forEach((md) => (md.sectionId = leftSectionId));
    let leftSection = this.query.getSection(leftSectionId);
    let rightSection = this.query.getSection(rightSectionId);
    leftSection.modules.push(...rightSection.modules);
    rightSection.modules.splice(0, rightSection.modules.length);
  }

  public rotateSelectedSectionRight() {
    if (this.data.selectedSection != null) {
      const section = this.query.getSection(
        this.data.selectedSection.id,
      ) as Section;

      section.rotation = (section.rotation + 90) % 360;

      this.updateProfilePositionAndRotation(section.id);
      this.railService.recalculateRailPositions(this.data.selectedSection.id);
      this.data.triggerNextPlan();
    }
  }

  public rotateSelectedSectionLeft() {
    if (this.data.selectedSection != null) {
      const section = this.query.getSection(
        this.data.selectedSection.id,
      ) as Section;

      section.rotation -= 90;
      if (section.rotation < 0) {
        section.rotation += 360;
      }

      this.updateProfilePositionAndRotation(section.id);
      this.railService.recalculateRailPositions(this.data.selectedSection.id);
      this.data.triggerNextPlan();
    }
  }

  public reverseSelectedSection() {
    // To reverse, rotate 180 and reverse list of modules
    let section = this.query.selectedSection as Section | undefined;
    if (!section) return;

    if (section.unevenLeftWall && !section.unevenRightWall) {
      this.setUnevenLeftWall(section.id, false);
      this.setUnevenRightWall(section.id, true);
    } else if (section.unevenRightWall && !section.unevenLeftWall) {
      this.setUnevenLeftWall(section.id, true);
      this.setUnevenRightWall(section.id, false);
    }

    section.rotation = (section.rotation + 180) % 360;
    if (section.rotation == 0) {
      section.posX -= section.width;
      section.posY -= this.queryGeometry.getSectionDepth(section.id);
    } else if (section.rotation == 90) {
      section.posX += this.queryGeometry.getSectionDepth(section.id);
      section.posY -= section.width;
    } else if (section.rotation == 180) {
      section.posX += section.width;
      section.posY += this.queryGeometry.getSectionDepth(section.id);
    } else if (section.rotation == 270) {
      section.posX -= this.queryGeometry.getSectionDepth(section.id);
      section.posY += section.width;
    }

    this.query.getModulesForSection(section.id).forEach((md) => {
      //reverse linked list
      let prevModuleId = md.references.previousSectionModuleId;
      md.references.previousSectionModuleId = md.references.nextSectionModuleId;
      md.references.nextSectionModuleId = prevModuleId;

      //Reverse profiles
      let leftProfileId = md.references.leftProfileId;
      md.references.leftProfileId = md.references.rightProfileId;
      md.references.rightProfileId = leftProfileId;

      //Reverse door open direction
      if (md.isDoor) {
        let door = md as Door;
        if (door.openDirection == DoorOpenSide.Both) {
          let extensionHolder = door.leftSlideExtensionAmount;
          door.leftSlideExtensionAmount = door.rightSlideExtensionAmount;
          door.rightSlideExtensionAmount = extensionHolder;
        } else door.openDirection = DoorOpenSide.inverse(door.openDirection);
      }
    });

    this.query
      .getAllProfiles()
      .filter((pfl) =>
        pfl.sections.map((secCon) => secCon.sectionId).includes(section!.id),
      )
      .forEach((pfl) => {
        let connection = pfl.sections.find(
          (secCon) => secCon.sectionId == section!.id,
        );
        connection!.side = Side.inverse(connection!.side);
      });

    this.updateModulesWidthAndPosition(section.id);

    this.railService.recalculateSectionRails(section.id);
    this.updateProfilePositionAndRotation(section.id);
    this.data.triggerNextPlan();
  }

  public updateDistanceToLeftWall(
    sectionId: SectionId,
    newDist: number,
    forceStretch: boolean = false,
  ) {
    let section = this.query.getSection(sectionId);
    let leftProfile = this.query.getSectionLeftProfile(sectionId)!;
    let rightProfile = this.query.getSectionRightProfile(sectionId)!;

    let min = this.queryGeometry.getSectionMinAllowedX(sectionId);
    let max = this.queryGeometry.getSectionMaxAllowedX(sectionId);

    if (section.isHorizontal) {
      let relativeLeftprofile =
        section.rotation == 0 ? leftProfile : rightProfile;
      let relativeRightprofile =
        section.rotation == 0 ? rightProfile : leftProfile;

      // Locked by joint to the left side
      if (
        relativeLeftprofile &&
        this.query.isJointProfile(relativeLeftprofile.id)
      )
        return;

      // Locked by joint at right side -> stretch
      if (
        (relativeRightprofile &&
          this.query.isJointProfile(relativeRightprofile.id)) ||
        forceStretch
      ) {
        // Limits stretch
        let maxXPos =
          this.queryGeometry.calculateTPieceMiddleSectionMaxX(sectionId);
        newDist = Math.min(Math.max(0, newDist), maxXPos);

        let diff =
          newDist - this.queryGeometry.getSectionGlobalLeftSideX(sectionId);
        let extendfrom = section.rotation == 0 ? Side.Right : Side.Left;
        this.setSectionWidth(sectionId, section.width - diff, extendfrom);
      } else {
        // Free to move
        let newPos = this.queryGeometry.calculateSectionPosXFromGlobalLeft(
          sectionId,
          newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);
        this.updateSectionPosition(sectionId, newPos, section.posY);
      }
    } else if (section.isVertical) {
      let dragType = this.query.getDragType(sectionId);
      newDist += Rail.railOverhang; //Added one side of top rail

      if (dragType == PartitionDragType.FreeDrag) {
        let newPos = this.queryGeometry.calculateSectionPosXFromGlobalLeft(
          sectionId,
          newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);
        this.updateSectionPosition(sectionId, newPos, section.posY);
      } else if (dragType == PartitionDragType.XAxisDrag) {
        //Clamp inside floorplan
        newDist = Math.min(
          Math.max(0, newDist),
          this.editorTypeService.floorPlan.Size.X -
            this.queryGeometry.getSectionDepth(sectionId),
        );

        //Clamp according to t profile drag min/max
        newDist = ObjectHelper.clamp(
          this.queryGeometry.calculateTPieceMiddleSectionMinX(sectionId),
          newDist,
          this.queryGeometry.calculateTPieceMiddleSectionMaxX(sectionId),
        );

        let diff = newDist - section.posX;
        this.updateSectionPosition(sectionId, newDist, section.posY);
        this.adjustTPieceSectionWidths(sectionId, diff);
      } else if (dragType == PartitionDragType.MultiDrag) {
        let range = this.queryGeometry.calculateMultiDragMoveRangeX(section.id);

        newDist = ObjectHelper.clamp(
          range.min ?? this.queryGeometry.getSectionMinAllowedX(section.id),
          this.queryGeometry.calculateSectionPosXFromGlobalLeft(
            section.id,
            newDist,
          ),
          range.max ?? this.queryGeometry.getSectionMaxAllowedX(section.id),
        );

        this.handleMultiDrag(section.id, Math.round(newDist) - section.posX, 0);
      }
    }

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);
  }

  public updateDistanceToRightWall(
    sectionId: SectionId,
    newDist: number,
    forceStretch: boolean = false,
  ) {
    let section = this.query.getSection(sectionId);
    let leftProfile = this.query.getSectionLeftProfile(sectionId)!;
    let rightProfile = this.query.getSectionRightProfile(sectionId)!;

    let min = this.queryGeometry.getSectionMinAllowedX(sectionId);
    let max = this.queryGeometry.getSectionMaxAllowedX(sectionId);

    if (section.isHorizontal) {
      let relativeLeftProfile =
        section.rotation == 0 ? leftProfile : rightProfile;
      let relativeRightProfile =
        section.rotation == 0 ? rightProfile : leftProfile;

      // Locked by joint to the right side
      if (
        relativeRightProfile &&
        this.query.isJointProfile(relativeRightProfile.id)
      )
        return;

      // Locked by joint at left side -> stretch
      if (
        (relativeLeftProfile &&
          this.query.isJointProfile(relativeLeftProfile.id)) ||
        forceStretch
      ) {
        // Limits stretch
        let minXPos =
          this.queryGeometry.calculateTPieceMiddleSectionMinX(sectionId);
        newDist = Math.min(
          Math.max(minXPos, this.editorTypeService.floorPlan.Size.X - newDist),
          this.editorTypeService.floorPlan.Size.X,
        );

        let diff =
          newDist - this.queryGeometry.getSectionGlobalRightSideX(sectionId);
        let extendfrom = section.rotation == 0 ? Side.Left : Side.Right;
        this.setSectionWidth(sectionId, section.width + diff, extendfrom);
      } else {
        // Free to move
        let newPos = this.queryGeometry.calculateSectionPosXFromGlobalRight(
          sectionId,
          this.editorTypeService.floorPlan.Size.X - newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);

        this.updateSectionPosition(sectionId, newPos, section.posY);
      }
    } else if (section.isVertical) {
      let dragType = this.query.getDragType(sectionId);
      newDist += Rail.railOverhang; //Added one side of top rail

      if (dragType == PartitionDragType.FreeDrag) {
        let newPos = this.queryGeometry.calculateSectionPosXFromGlobalRight(
          sectionId,
          this.editorTypeService.floorPlan.Size.X - newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);
        this.updateSectionPosition(sectionId, newPos, section.posY);
      } else if (dragType == PartitionDragType.XAxisDrag) {
        //Clamp inside floorplan
        newDist = Math.min(
          Math.max(0, newDist),
          this.editorTypeService.floorPlan.Size.X -
            this.queryGeometry.getSectionDepth(sectionId),
        );

        //Clamp according to t profile drag min/max
        newDist = ObjectHelper.clamp(
          this.queryGeometry.calculateTPieceMiddleSectionMinX(sectionId),
          this.editorTypeService.floorPlan.Size.X - newDist,
          this.queryGeometry.calculateTPieceMiddleSectionMaxX(sectionId),
        );

        let diff = newDist - section.posX;
        this.updateSectionPosition(sectionId, newDist, section.posY);
        this.adjustTPieceSectionWidths(sectionId, diff);
      } else if (dragType == PartitionDragType.MultiDrag) {
        let range = this.queryGeometry.calculateMultiDragMoveRangeX(section.id);

        newDist = ObjectHelper.clamp(
          range.min ?? this.queryGeometry.getSectionMinAllowedX(section.id),
          this.queryGeometry.calculateSectionPosXFromGlobalRight(
            sectionId,
            this.editorTypeService.floorPlan.Size.X - newDist,
          ),
          range.max ?? this.queryGeometry.getSectionMaxAllowedX(section.id),
        );

        this.handleMultiDrag(section.id, Math.round(newDist) - section.posX, 0);
      }
    }

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);
  }

  public updateDistanceToTopWall(
    sectionId: SectionId,
    newDist: number,
    forceStretch: boolean = false,
  ) {
    let section = this.query.getSection(sectionId);
    let leftProfile = this.query.getSectionLeftProfile(sectionId)!;
    let rightProfile = this.query.getSectionRightProfile(sectionId)!;

    let min = this.queryGeometry.getSectionMinAllowedY(sectionId);
    let max = this.queryGeometry.getSectionMaxAllowedY(sectionId);

    if (section.isVertical) {
      let relativeLeftprofile =
        section.rotation == 90 ? leftProfile : rightProfile;
      let relativeRightprofile =
        section.rotation == 90 ? rightProfile : leftProfile;

      // Locked by joint to the left side
      if (
        relativeLeftprofile &&
        this.query.isJointProfile(relativeLeftprofile.id)
      )
        return;

      // Locked by joint at right side -> stretch
      if (
        (relativeRightprofile &&
          this.query.isJointProfile(relativeRightprofile.id)) ||
        forceStretch
      ) {
        // Limits stretch
        let maxYPos =
          this.queryGeometry.calculateTPieceMiddleSectionMaxY(sectionId);
        newDist = Math.min(Math.max(0, newDist), maxYPos);

        let diff =
          newDist - this.queryGeometry.getSectionGlobalTopSideY(sectionId);
        let extendfrom = section.rotation == 90 ? Side.Right : Side.Left;
        this.setSectionWidth(sectionId, section.width - diff, extendfrom);
      } else {
        // Free to move
        let newPos = this.queryGeometry.calculateSectionPosYFromGlobalTop(
          sectionId,
          newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);

        this.updateSectionPosition(sectionId, section.posX, newPos);
      }
    } else if (section.isHorizontal) {
      let dragType = this.query.getDragType(sectionId);
      newDist += Rail.railOverhang; //Added one side of top rail

      if (dragType == PartitionDragType.FreeDrag) {
        let newPos = this.queryGeometry.calculateSectionPosYFromGlobalTop(
          sectionId,
          newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);
        this.updateSectionPosition(sectionId, section.posX, newPos);
      } else if (dragType == PartitionDragType.YAxisDrag) {
        //Clamp inside floorplan
        newDist = Math.min(
          Math.max(0, newDist),
          this.editorTypeService.floorPlan.Size.Y -
            this.queryGeometry.getSectionDepth(sectionId),
        );

        //Clamp according to t profile drag min/max
        newDist = ObjectHelper.clamp(
          this.queryGeometry.calculateTPieceMiddleSectionMinY(sectionId),
          newDist,
          this.queryGeometry.calculateTPieceMiddleSectionMaxY(sectionId),
        );

        let diff = newDist - section.posY;
        this.updateSectionPosition(sectionId, section.posX, newDist);
        this.adjustTPieceSectionWidths(sectionId, diff);
      } else if (dragType == PartitionDragType.MultiDrag) {
        let range = this.queryGeometry.calculateMultiDragMoveRangeY(section.id);

        newDist = ObjectHelper.clamp(
          range.min ?? this.queryGeometry.getSectionMinAllowedY(section.id),
          this.queryGeometry.calculateSectionPosYFromGlobalTop(
            section.id,
            newDist,
          ),
          range.max ?? this.queryGeometry.getSectionMaxAllowedY(section.id),
        );

        this.handleMultiDrag(section.id, 0, Math.round(newDist) - section.posY);
      }
    }

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);
  }

  public updateDistanceToBottomWall(
    sectionId: SectionId,
    newDist: number,
    forceStretch: boolean = false,
  ) {
    let section = this.query.getSection(sectionId);
    let leftProfile = this.query.getSectionLeftProfile(sectionId)!;
    let rightProfile = this.query.getSectionRightProfile(sectionId)!;

    let min = this.queryGeometry.getSectionMinAllowedY(sectionId);
    let max = this.queryGeometry.getSectionMaxAllowedY(sectionId);

    if (section.isVertical) {
      let relativeLeftProfile =
        section.rotation == 90 ? leftProfile : rightProfile;
      let relativeRightProfile =
        section.rotation == 90 ? rightProfile : leftProfile;

      // Locked by joint to the right side
      if (
        relativeRightProfile &&
        this.query.isJointProfile(relativeRightProfile.id)
      )
        return;

      // Locked by joint at left side -> stretch
      if (
        (relativeLeftProfile &&
          this.query.isJointProfile(relativeLeftProfile.id)) ||
        forceStretch
      ) {
        // Limits stretch
        let minYPos =
          this.queryGeometry.calculateTPieceMiddleSectionMinY(sectionId);
        newDist = Math.min(
          Math.max(minYPos, this.editorTypeService.floorPlan.Size.Y - newDist),
          this.editorTypeService.floorPlan.Size.Y,
        );

        let diff =
          newDist - this.queryGeometry.getSectionGlobalBottomSideY(sectionId);
        let extendfrom = section.rotation == 90 ? Side.Left : Side.Right;
        this.setSectionWidth(sectionId, section.width + diff, extendfrom);
      } else {
        // Free to move
        let newPos = this.queryGeometry.calculateSectionPosYFromGlobalBottom(
          sectionId,
          this.editorTypeService.floorPlan.Size.Y - newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);

        this.updateSectionPosition(sectionId, section.posX, newPos);
      }
    } else if (section.isHorizontal) {
      let dragType = this.query.getDragType(sectionId);
      newDist += Rail.railOverhang; //Added one side of top rail

      if (dragType == PartitionDragType.FreeDrag) {
        let newPos = this.queryGeometry.calculateSectionPosYFromGlobalBottom(
          sectionId,
          this.editorTypeService.floorPlan.Size.Y - newDist,
        );
        newPos = Math.min(Math.max(min, newPos), max);
        this.updateSectionPosition(sectionId, section.posX, newPos);
      } else if (dragType == PartitionDragType.YAxisDrag) {
        //Clamp inside floorplan
        newDist = Math.min(
          Math.max(0, newDist),
          this.editorTypeService.floorPlan.Size.Y -
            this.queryGeometry.getSectionDepth(sectionId),
        );

        //Clamp according to t profile drag min/max
        newDist = ObjectHelper.clamp(
          this.queryGeometry.calculateTPieceMiddleSectionMinY(sectionId),
          this.editorTypeService.floorPlan.Size.Y - newDist,
          this.queryGeometry.calculateTPieceMiddleSectionMaxY(sectionId),
        );

        let diff = newDist - section.posY;
        this.updateSectionPosition(sectionId, section.posX, newDist);
        this.adjustTPieceSectionWidths(sectionId, diff);
      } else if (dragType == PartitionDragType.MultiDrag) {
        let range = this.queryGeometry.calculateMultiDragMoveRangeY(section.id);

        newDist = ObjectHelper.clamp(
          range.min ?? this.queryGeometry.getSectionMinAllowedY(section.id),
          this.queryGeometry.calculateSectionPosYFromGlobalBottom(
            sectionId,
            this.editorTypeService.floorPlan.Size.Y - newDist,
          ),
          range.max ?? this.queryGeometry.getSectionMaxAllowedY(section.id),
        );

        this.handleMultiDrag(section.id, 0, Math.round(newDist) - section.posY);
      }
    }

    this.checkForLeftWallUnSnap(sectionId);
    this.checkForRightWallUnSnap(sectionId);
  }

  public ensureSectionWithinFloorPlan(
    sectionId: SectionId,
    forceStretch: boolean = false,
  ) {
    if (this.queryGeometry.getDistanceFromLeftWallToLeftSide(sectionId) < 0)
      this.updateDistanceToLeftWall(sectionId, 0, forceStretch);
    if (this.queryGeometry.getDistanceFromRightWallToRightSide(sectionId) < 0)
      this.updateDistanceToRightWall(sectionId, 0, forceStretch);
    if (this.queryGeometry.getDistanceFromTopWallToTopSide(sectionId) < 0)
      this.updateDistanceToTopWall(sectionId, 0, forceStretch);
    if (this.queryGeometry.getDistanceFromBottomWallToBottomSide(sectionId) < 0)
      this.updateDistanceToBottomWall(sectionId, 0, forceStretch);
  }

  public setUnevenLeftWall(sectionId: SectionId, value: boolean) {
    let section = this.query.getSection(sectionId) as Section;
    section.unevenLeftWall = value;

    if (section.unevenLeftWall) {
      let leftModule = this.query.getHeadModuleForSection(sectionId);
      let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
        leftModule.id,
        -(StartStopProfile.size + Module.elementSpacing),
      );
      section.references.leftNicheProfileId = this.addNicheProfile(
        sectionId,
        Side.Left,
        relativeLeftPoint.X,
        relativeLeftPoint.Y - (44 - Module.fullJointWidth) / 2, // 44 is width of 3d model, should maybe be gotten from product data
        section.height - NicheProfile.heightReduction,
        section.rotation,
      );
    } else {
      this.removeProfile(section.references.leftNicheProfileId!);
      section.references.leftNicheProfileId = undefined;
    }

    this.updateModulesWidthAndPosition(sectionId);
    this.updateProfilePositionAndRotation(sectionId);
    this.railService.recalculateSectionRails(sectionId);
    this.data.triggerNextPlan();
  }

  public checkForLeftWallUnSnap(sectionId: SectionId) {
    if (this.queryGeometry.getDistanceFromRelativeLeftSide(sectionId) > 0)
      this.setUnevenLeftWall(sectionId, false);
  }

  public setUnevenRightWall(sectionId: SectionId, value: boolean) {
    let section = this.query.getSection(sectionId) as Section;
    section.unevenRightWall = value;

    if (section.unevenRightWall) {
      let rightModule = this.query.getTailModuleForSection(sectionId);
      let relativeRightPoint = this.queryGeometry.getModuleRelativeRightPoint(
        rightModule.id,
        StartStopProfile.size + Module.elementSpacing,
      );
      section.references.rightNicheProfileId = this.addNicheProfile(
        sectionId,
        Side.Right,
        relativeRightPoint.X,
        relativeRightPoint.Y + (44 - Module.fullJointWidth) / 2,
        section.height - NicheProfile.heightReduction,
        (section.rotation + 180) % 360,
      );
    } else {
      this.removeProfile(section.references.rightNicheProfileId!);
      section.references.rightNicheProfileId = undefined;
    }

    this.updateModulesWidthAndPosition(sectionId);
    this.updateProfilePositionAndRotation(sectionId);
    this.railService.recalculateSectionRails(sectionId);
    this.data.triggerNextPlan();
  }

  public checkForRightWallUnSnap(sectionId: SectionId) {
    if (this.queryGeometry.getDistanceFromRelativeRightSide(sectionId) > 0)
      this.setUnevenRightWall(sectionId, false);
  }

  public deleteSection(sectionId: SectionId) {
    const plan = this.data.plan;
    const section = this.query.getSection(sectionId);

    this.query.getModulesForSection(sectionId).forEach((md) => {
      if (md.references.leftProfileId) {
        let leftProfile = this.query.getProfile(md.references.leftProfileId);
        this.deleteProfile(leftProfile.id, sectionId);
      }

      if (md.references.rightProfileId) {
        let rightProfile = this.query.getProfile(md.references.rightProfileId);
        this.deleteProfile(rightProfile.id, sectionId);
      }
    });

    // Remove Niche profiles
    if (section.references.leftNicheProfileId)
      this.removeProfile(section.references.leftNicheProfileId);
    if (section.references.rightNicheProfileId)
      this.removeProfile(section.references.rightNicheProfileId);

    //Remove section and modules
    plan.sections = plan.sections.filter((s) => s.id != sectionId);
    plan.modules = plan.modules.filter((m) => m.sectionId != sectionId);

    // Remove section from partition
    this.moveToPartition(sectionId, null);

    section.rails.forEach((railId) => {
      let index = this.query.plan.rails.findIndex((rail) => rail.id == railId);
      if (index != -1) this.query.plan.rails.splice(index, 1);
    });

    this.deselectSection();
    this.data.triggerNextPlan();
  }

  public deleteProfile(profileId: ProfileId, sectionId: SectionId) {
    let profile = this.query.getProfile(profileId)!;

    profile.sections.forEach((secCon) => {
      if (secCon.profileId) this.removeProfile(secCon.profileId);
    });

    if (profile.type == ProfileType.StartStop) this.removeProfile(profileId);
    else if (
      profile instanceof CornerProfile &&
      profile.leftSection.sectionId == sectionId
    )
      this.deleteCornerProfileLeftSection(profileId);
    else if (
      profile instanceof CornerProfile &&
      profile.rightSection.sectionId == sectionId
    )
      this.deleteCornerProfileRightSection(profileId);
    else if (
      profile instanceof TProfile &&
      profile.leftSection.sectionId == sectionId
    )
      this.deleteTProfileLeftSection(profileId);
    else if (
      profile instanceof TProfile &&
      profile.middleSection.sectionId == sectionId
    )
      this.deleteTProfileMiddleSection(profileId);
    else if (
      profile instanceof TProfile &&
      profile.rightSection.sectionId == sectionId
    )
      this.deleteTProfileRightSection(profileId);
  }

  public deleteCornerProfileLeftSection(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as CornerProfile;
    const section = this.query.getSection(profile.rightSection.sectionId);

    let module =
      profile.rightSection.side == Side.Left
        ? this.query.getHeadModuleForSection(profile.rightSection.sectionId)
        : this.query.getTailModuleForSection(profile.rightSection.sectionId);

    let profileHeight = section.height - Profile.profileRailReduction;

    let leftModule = this.query.getHeadModuleForSection(
      profile.rightSection.sectionId,
    );
    let rightModule = this.query.getTailModuleForSection(
      profile.rightSection.sectionId,
    );
    if (profile.rightSection.side == Side.Left) {
      if (module.isDoor) leftModule.references.leftProfileId = undefined;
      else {
        let newProfileId = this.addStartStopProfile(
          profile.rightSection.sectionId,
          profile.rightSection.side,
          0,
          0,
          profileHeight,
          section.rotation,
        );
        leftModule.references.leftProfileId = newProfileId;
      }
    } else {
      if (module.isDoor) rightModule.references.rightProfileId = undefined;
      else {
        let newProfileId = this.addStartStopProfile(
          profile.rightSection.sectionId,
          profile.rightSection.side,
          0,
          0,
          profileHeight,
          section.rotation,
        );
        rightModule.references.rightProfileId = newProfileId;
      }
    }

    this.removeProfile(profileId);
    let extendSide = Side.inverse(profile.rightSection.side);
    this.updateSectionWidth(
      profile.rightSection.sectionId,
      section.width + CornerProfile.size + Module.elementSpacing,
      extendSide,
    );
    this.updateProfilePositionAndRotation(profile.rightSection.sectionId);
  }

  public deleteCornerProfileRightSection(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as CornerProfile;
    const section = this.query.getSection(profile.leftSection.sectionId);

    let module =
      profile.leftSection.side == Side.Left
        ? this.query.getHeadModuleForSection(profile.leftSection.sectionId)
        : this.query.getTailModuleForSection(profile.leftSection.sectionId);

    let profileHeight = section.height - Profile.profileRailReduction;

    let leftModule = this.query.getHeadModuleForSection(
      profile.leftSection.sectionId,
    );
    let rightModule = this.query.getTailModuleForSection(
      profile.leftSection.sectionId,
    );
    if (profile.leftSection.side == Side.Left) {
      if (module.isDoor) leftModule.references.leftProfileId = undefined;
      else {
        let newProfileId = this.addStartStopProfile(
          profile.leftSection.sectionId,
          profile.leftSection.side,
          0,
          0,
          profileHeight,
          section.rotation,
        );
        leftModule.references.leftProfileId = newProfileId;
      }
    } else {
      if (module.isDoor) rightModule.references.rightProfileId = undefined;
      else {
        let newProfileId = this.addStartStopProfile(
          profile.leftSection.sectionId,
          profile.leftSection.side,
          0,
          0,
          profileHeight,
          section.rotation,
        );
        rightModule.references.rightProfileId = newProfileId;
      }
    }

    this.removeProfile(profileId);
    let extendSide = Side.inverse(profile.leftSection.side);
    this.updateSectionWidth(
      profile.leftSection.sectionId,
      section.width + CornerProfile.size + Module.elementSpacing,
      extendSide,
    );
    this.updateProfilePositionAndRotation(profile.leftSection.sectionId);
  }

  public deleteTProfileLeftSection(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as TProfile;

    let newProfileId = this.addCornerProfile(
      profile.middleSection.sectionId,
      profile.middleSection.side,
      profile.rightSection.sectionId,
      profile.rightSection.side,
      profile.posX,
      profile.posY,
      profile.height,
      profile.rotation,
    );

    if (profile.middleSection.side == Side.Left) {
      let leftModule = this.query.getHeadModuleForSection(
        profile.middleSection.sectionId,
      );
      leftModule.references.leftProfileId = newProfileId;
    } else {
      let rightModule = this.query.getTailModuleForSection(
        profile.middleSection.sectionId,
      );
      rightModule.references.rightProfileId = newProfileId;
    }

    if (profile.rightSection.side == Side.Left) {
      let leftModule = this.query.getHeadModuleForSection(
        profile.rightSection.sectionId,
      );
      leftModule.references.leftProfileId = newProfileId;
    } else {
      let rightModule = this.query.getTailModuleForSection(
        profile.rightSection.sectionId,
      );
      rightModule.references.rightProfileId = newProfileId;
    }

    this.updateProfilePositionAndRotation(profile.middleSection.sectionId);
    this.railService.recalculateSectionRails(profile.middleSection.sectionId);
    this.railService.recalculateSectionRails(profile.rightSection.sectionId);
    this.removeProfile(profileId);
  }

  public deleteTProfileMiddleSection(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as TProfile;

    let leftSection = this.query.getSection(profile.leftSection.sectionId);
    let rightSection = this.query.getSection(profile.rightSection.sectionId);

    if (profile.leftSection.side == Side.Right) {
      let leftSectionRightModule = this.query.getTailModuleForSection(
        profile.leftSection.sectionId,
      );

      let rightSectionLeftModule = this.query.getHeadModuleForSection(
        profile.rightSection.sectionId,
      );
      rightSectionLeftModule.references.leftProfileId = undefined;

      // update reference on profile
      if (leftSectionRightModule.references.rightProfileId) {
        let otherProfile = this.query.getProfile(
          leftSectionRightModule.references.rightProfileId,
        ) as Profile;
        otherProfile.sections = otherProfile.sections.map((sec) =>
          sec.sectionId == rightSection.id
            ? { sectionId: leftSection.id, side: sec.side }
            : sec,
        );
      }

      this.joinSections(leftSection.id, rightSection.id, false);
      this.deleteSection(rightSection.id);
    } else {
      let leftSectionLeftModule = this.query.getHeadModuleForSection(
        profile.leftSection.sectionId,
      );
      let rightSectionRightModule = this.query.getTailModuleForSection(
        profile.rightSection.sectionId,
      );
      rightSectionRightModule.references.rightProfileId = undefined;

      // update reference on profile
      if (leftSectionLeftModule.references.leftProfileId) {
        let otherProfile = this.query.getProfile(
          leftSectionLeftModule.references.leftProfileId,
        ) as Profile;
        otherProfile.sections = otherProfile.sections.map((sec) =>
          sec.sectionId == rightSection.id
            ? { sectionId: leftSection.id, side: sec.side }
            : sec,
        );
      }

      this.joinSections(leftSection.id, rightSection.id, true);
      this.updateSectionPosition(
        profile.leftSection.sectionId,
        rightSection.posX,
        rightSection.posY,
      );
      this.deleteSection(rightSection.id);
    }

    let newSectionWidth =
      leftSection.width +
      rightSection.width +
      Module.fullJointWidth +
      Module.elementSpacing * 2;
    this.setSectionWidth(
      profile.leftSection.sectionId,
      newSectionWidth,
      Side.Left,
    );

    this.removeProfile(profileId);
  }

  public deleteTProfileRightSection(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as TProfile;

    let newProfileId = this.addCornerProfile(
      profile.leftSection.sectionId,
      profile.leftSection.side,
      profile.middleSection.sectionId,
      profile.middleSection.side,
      profile.posX,
      profile.posY,
      profile.height,
      (profile.rotation + 90) % 360,
    );

    if (profile.middleSection.side == Side.Left) {
      let leftModule = this.query.getHeadModuleForSection(
        profile.middleSection.sectionId,
      );
      leftModule.references.leftProfileId = newProfileId;
    } else {
      let rightModule = this.query.getTailModuleForSection(
        profile.middleSection.sectionId,
      );
      rightModule.references.rightProfileId = newProfileId;
    }

    if (profile.leftSection.side == Side.Left) {
      let leftModule = this.query.getHeadModuleForSection(
        profile.leftSection.sectionId,
      );
      leftModule.references.leftProfileId = newProfileId;
    } else {
      let rightModule = this.query.getTailModuleForSection(
        profile.leftSection.sectionId,
      );
      rightModule.references.rightProfileId = newProfileId;
    }

    this.updateProfilePositionAndRotation(profile.middleSection.sectionId);
    this.railService.recalculateSectionRails(profile.leftSection.sectionId);
    this.railService.recalculateSectionRails(profile.middleSection.sectionId);
    this.removeProfile(profileId);
  }

  public setSelection(sectionId: SectionId, moduleId?: ModuleId) {
    if (moduleId) {
      const sectionModules = this.query.getAllModules();
      this.data.selectedModule = sectionModules.filter(
        (m) => m.id == moduleId,
      )[0] as Module;
    }

    this.data.selectedSection = this.data.plan.sections.filter(
      (s) => s.id == sectionId,
    )[0];

    this.data.triggerNextPlan();
  }

  public setSelectedModule(moduleId: ModuleId | null) {
    if (moduleId == null) {
      this.data.selectedModule = undefined;
      return;
    }

    const modules = this.data.plan.modules;

    this.data.selectedModule = modules.filter((m) => m.id == moduleId)[0];

    this.data.triggerNextPlan();
  }

  public deselectSection() {
    this.data.selectedSection = undefined;
    this.data.selectedModule = undefined;
  }

  public addFillingToSelectedModule(
    materialId: number | undefined = undefined,
  ): FillingId {
    const module = this.query.getModule(this.query.selectedModule!.id);
    return module.addFilling(
      materialId ?? this.data.defaults.fillingMaterialId,
    );
  }

  public removeFillingFromSelectedModule(fillingId: FillingId) {
    let module = this.query.getModule(this.query.selectedModule!.id);
    module.removeFilling(fillingId);
  }

  public addBarToSelectedModule(): BarId {
    let module = this.query.getModule(this.query.selectedModule!.id);
    let section = this.query.getSection(this.query.selectedSection!.id);
    return module.addBar(section.barHeight);
  }

  public removeBarFromSelectedModule(barId: BarId) {
    let module = this.query.getModule(this.query.selectedModule!.id);
    module.removeBars(barId);
  }

  /**
   * Adds or removes fillings and bars from the selected module to match the specified amount.
   * If the recalculation of filling heights fails, it will retry with the previous amount.
   * Updates filling heights and bar positions after modification.
   * @param amount The desired number of fillings
   * @param retry Whether this is a retry attempt after a failed recalculation. Used to avoid infinite loops.
   */
  public setNumberOfFillingsForSelectedModule(
    amount: number,
    retry: boolean = false,
  ): number {
    let module = this.data.selectedModule;
    if (!module) return amount;

    const previousFillingAmount = module.fillings.length;

    let increase = module.fillings.length < amount;
    while (module.fillings.length != amount) {
      if (increase) {
        let previousFillingMaterialId =
          module.fillings[module.fillings.length - 1].materialId;
        this.addFillingToSelectedModule();
        this.addBarToSelectedModule();
        let newFilling = module.fillings[module.fillings.length - 1];
        newFilling.materialId = previousFillingMaterialId;
      } else {
        let lastFillings = module.fillings[module.fillings.length - 1];
        this.removeFillingFromSelectedModule(lastFillings.id);
        let lastBar = module.bars[module.bars.length - 1];
        this.removeBarFromSelectedModule(lastBar.id);
      }
    }

    const result = this.recalculateFillingHeights(module.id);
    if (!result && !retry) {
      this.setNumberOfFillingsForSelectedModule(previousFillingAmount, true);
    }

    this.recalculateBarPositions(module.id);
    this.data.triggerNextPlan();

    return module.fillings.length;
  }

  public spreadFillingsEvenlyOnModule(moduleId: ModuleId) {
    this.recalculateFillingHeights(moduleId);
    this.recalculateBarPositions(moduleId);
    this.data.triggerNextPlan();
  }

  /**
   * Calculates the fillings to be evenly sized
   * @param moduleId
   */
  public recalculateFillingHeights(moduleId: ModuleId): boolean {
    let module = this.query.getModule(moduleId);

    let doorData = Enumerable.from(
      this.assetService.editorAssets.productDoorData,
    ).firstOrDefault(
      (pdd) => pdd.ProductId === this.query.getDoorModuleProductId(),
    );
    if (!doorData) return true;

    let availableBars = Enumerable.from(doorData.Bars).where(
      (bar) => !bar.ChainOverride,
    );
    if (!availableBars) return true;

    let totalHiddenHeight = 0;
    if (module.bars.length > 0) {
      for (let i = 0; i < module.bars.length; i++) {
        totalHiddenHeight += module.bars[i].height; //availableBars.where(bar => bar.Height == module.bars[i].height).firstOrDefault()!.BarMiddel
      }
    }

    //Add frame
    totalHiddenHeight += module.isDoor
      ? this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getDoorModuleProductId(),
        )!.FrameWidthTop +
        this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getDoorModuleProductId(),
        )!.FrameWidthBottom
      : this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
        )!.FrameWidthTop +
        this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
        )!.FrameWidthBottom;

    let totalVisibleFillingsHeight = module.height - totalHiddenHeight;
    let uniformVisibleHeight =
      totalVisibleFillingsHeight / module.fillings.length;

    for (let i = 0; i < module.fillings.length; i++) {
      let fillingHeight = uniformVisibleHeight;

      if (i == 0) {
        // bottom filling
        fillingHeight += module.isDoor
          ? Door.fillingInsertionDepth
          : Module.fillingInsertionDepth;
      }
      if (i == module.fillings.length - 1) {
        // top filling
        fillingHeight += module.isDoor
          ? Door.fillingInsertionDepth
          : Module.fillingInsertionDepth;
      }

      if (module.fillings.length > 1 && i > 0) {
        fillingHeight += availableBars
          .where((bar) => bar.Height == module.bars[i - 1].height)
          .firstOrDefault()!.BarTop;
      }
      if (module.fillings.length > 1 && i < module.fillings.length - 1) {
        fillingHeight += availableBars
          .where((bar) => bar.Height == module.bars[i].height)
          .firstOrDefault()!.BarBottom;
      }

      const fillingMaterial =
        this.assetService.editorAssets.materialsDict[
          module.fillings[i].materialId
        ];

      if (
        fillingMaterial.MaxFillingHeight < fillingHeight ||
        fillingMaterial.MinFillingHeight > fillingHeight
      ) {
        return false;
      }

      module.fillings[i].height = fillingHeight;

      // if (module.isDoor && i == 0) {
      //     module.fillings[i].height -= 36 //gotten from partition system subtract pdf TODO: Refactor
      // }
    }

    return true;
  }

  /**
   * Calculates the positions to be evenly positioned
   * @param moduleId
   */
  public recalculateBarPositions(moduleId: ModuleId) {
    let module = this.query.getModule(moduleId);

    let barData = this.query.getPartitionBarData();
    if (!barData) return;

    for (let i = 0; i < module.bars.length; i++) {
      let sum = module.isWall
        ? this.assetService.editorAssets.productDoorData.find(
            (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
          )!.FrameWidthTop - Module.fillingInsertionDepth
        : this.assetService.editorAssets.productDoorData.find(
            (pdd) => pdd.ProductId == this.query.getDoorModuleProductId(),
          )!.FrameWidthTop - Door.fillingInsertionDepth;

      for (let j = module.bars.length - 1; j >= 0; j--) {
        sum += module.fillings[j + 1].height;

        let bar = module.bars[j];
        sum += barData.BarMiddel;

        if (bar.id == module.bars[i].id) {
          sum -= barData.BarMiddel / 2;
          break;
        }
      }

      module.bars[i].position = sum;
    }
  }

  public updateFillingMaterial(
    fillingId: FillingId,
    materialId: number,
    isDesign: boolean,
  ) {
    let module = this.data.selectedModule;
    if (!module) return;

    let filling = module.fillings.find((fl) => fl.id == fillingId);
    if (!filling) return;

    filling.materialId = materialId;

    if (isDesign) {
      let fillingIndex = module.fillings.findIndex((fl) => fl.id == fillingId);
      if (fillingIndex < module.fillings.length - 1) {
        let bar = module.bars[fillingIndex + 1];
        bar.bartype = isDesign ? BarType.Design : BarType.Fixed;
      }

      if (fillingIndex > 0) {
        let bar = module.bars[fillingIndex];
        bar.bartype = isDesign ? BarType.Design : BarType.Fixed;
      }
    }

    let material = this.assetService.editorAssets.materialsDict[materialId];

    if (filling.height > material.MaxFillingHeight) {
      this.addFillingToSelectedModule(materialId);
      this.addBarToSelectedModule();
      this.recalculateFillingHeights(module.id);
      this.recalculateBarPositions(module.id);
    }

    this.data.triggerNextPlan();
  }

  public updateSectionBarHeight(sectionId: SectionId, barHeight: number) {
    let section = this.query.getSection(sectionId) as Section;
    section.barHeight = barHeight;

    let modules = this.query.getModulesForSection(sectionId);
    modules.forEach((md) => {
      md.bars.forEach((bar) => {
        bar.height = barHeight;
      });
    });

    this.data.triggerNextPlan();
  }

  public updatePartitionProfileMaterial(
    sectionId: SectionId,
    materialId: number,
  ) {
    let partition = this.query.getPartitionForSection(sectionId);

    partition.sections.forEach((secId) => {
      let section = this.query.getSection(secId) as Section;
      if (!section) return;

      section.references.profileMaterialId = materialId;
    });

    this.data.plan.rails.forEach((rail) => {
      let isSectionMaterialValid = this.railService.isMaterialValidForRail(
        sectionId,
        rail.productId,
      );
      rail.materialId = isSectionMaterialValid
        ? materialId
        : this.railService.getDefaultRailMaterial().Id;
    });

    this.railService.recalculateSectionRails(sectionId);
    this.data.triggerNextPlan();
  }

  public updateSelectedModuleType(moduleType: string) {
    let module = this.data.selectedModule;
    if (!module) {
      return;
    }

    let section = this.query.getSection(module.sectionId);

    if (moduleType == 'Door') {
      this.convertWallToDoor(module);
    } else if (moduleType == 'Wall') {
      this.convertDoorToWall(module);
    }

    this.updateModulesWidthAndPosition(section.id);
    this.recalculateFillingHeights(module.id);
    this.recalculateBarPositions(module.id);
    this.updateProfilePositionAndRotation(module.sectionId);
    this.railService.recalculateSectionRails(module.sectionId);
    this.data.triggerNextPlan();
  }

  private convertWallToDoor(module: Module) {
    const index = this.data.plan.modules.findIndex(
      (wall) => wall.id == module.id,
    );
    const door = Door.fromWall(module as Wall, this.data.defaults);
    this.data.plan.modules[index] = door;

    let section = this.query.getSection(door.sectionId);
    let profileHeight = section.height - Profile.profileRailReduction;

    //Remove niche if exists
    if (!door.references.previousSectionModuleId && section.unevenLeftWall)
      this.setUnevenLeftWall(section.id, false);
    else if (!door.references.nextSectionModuleId && section.unevenRightWall)
      this.setUnevenRightWall(section.id, false);

    //Remove profile on module if any
    if (door.references.leftProfileId) {
      if (!this.query.isJointProfile(door.references.leftProfileId)) {
        this.removeProfile(door.references.leftProfileId);
        door.references.leftProfileId = undefined;
      } else {
        // Insert start stop on joint
        let jointProfile = this.query.getProfile(door.references.leftProfileId);
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == door.sectionId,
        )!;
        let rotation =
          (connection.side == Side.Left
            ? section.rotation + 180
            : section.rotation) % 360;

        let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
          door.id,
          StartStopProfile.size + Module.elementSpacing,
          Module.fullJointWidth,
        );
        connection.profileId = this.addStartStopProfile(
          door.sectionId,
          Side.Right,
          relativeLeftPoint.X,
          relativeLeftPoint.Y,
          profileHeight,
          rotation,
        );
      }
    }
    if (door.references.rightProfileId) {
      if (!this.query.isJointProfile(door.references.rightProfileId)) {
        this.removeProfile(door.references.rightProfileId);
        door.references.rightProfileId = undefined;
      } else {
        // Insert start stop on joint
        let jointProfile = this.query.getProfile(
          door.references.rightProfileId,
        );
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == door.sectionId,
        )!;
        let rotation =
          (connection.side == Side.Left
            ? section.rotation + 180
            : section.rotation) % 360;

        let relativeRightPoint = this.queryGeometry.getModuleRelativeRightPoint(
          door.id,
          -(StartStopProfile.size + Module.elementSpacing),
          -Module.fullJointWidth,
        );
        connection.profileId = this.addStartStopProfile(
          door.sectionId,
          Side.Right,
          relativeRightPoint.X,
          relativeRightPoint.Y,
          profileHeight,
          rotation,
        );
      }
    }

    let nextToOtherDoor: boolean = false;

    if (door.references.previousSectionModuleId) {
      let leftModule = this.query.getNeighborModule(door.id, Side.Left);
      if (leftModule instanceof Wall) {
        let relativeRightPoint = this.queryGeometry.getModuleRelativeRightPoint(
          leftModule.id,
          StartStopProfile.size + Module.elementSpacing,
        );
        let profileId = this.addStartStopProfile(
          door.sectionId,
          Side.Right,
          relativeRightPoint.X,
          relativeRightPoint.Y,
          profileHeight,
          (section.rotation + 180) % 360,
        );
        leftModule.references.rightProfileId = profileId;
      } else if (leftModule instanceof Door) {
        leftModule.openDirection = DoorOpenSide.Left;
        door.openDirection = DoorOpenSide.Right;
        door.placement = leftModule.placement;

        nextToOtherDoor = true;
      }
    } else {
      door.openDirection = DoorOpenSide.Right;
    }

    if (door.references.nextSectionModuleId) {
      let rightModule = this.query.getNeighborModule(door.id, Side.Right);
      if (rightModule instanceof Wall) {
        let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
          rightModule.id,
          -(StartStopProfile.size + Module.elementSpacing),
        );
        let profileId = this.addStartStopProfile(
          door.sectionId,
          Side.Left,
          relativeLeftPoint.X,
          relativeLeftPoint.Y,
          profileHeight,
          section.rotation,
        );
        rightModule.references.leftProfileId = profileId;
      } else if (rightModule instanceof Door) {
        rightModule.openDirection = DoorOpenSide.Right;
        door.openDirection = DoorOpenSide.Left;
        door.placement = rightModule.placement;

        nextToOtherDoor = true;
      }
    } else {
      door.openDirection = DoorOpenSide.Left;
    }

    let wallCount = this.query
      .getModulesForSection(section.id)
      .map((md) => (md.isDoor ? 0 : 1) as number)
      .reduce((acc, i) => acc + i);
    let doors = this.query
      .getModulesForSection(section.id)
      .filter((md) => md.isDoor);

    if (!nextToOtherDoor && doors.length > wallCount) {
      let otherDoor = doors.find((md) => md.id != door.id)! as Door;
      door.placement =
        otherDoor.placement == DoorPlacement.Front
          ? DoorPlacement.Back
          : DoorPlacement.Front;
    }

    door.fillings.forEach((fl) => {
      fl.xOffset = Door.fillingWidthReduction;
    });

    door.height -= Door.doorModuleHeightDifference; //Gotten from partition system subtraction pdf

    this.railService.recalculateSectionRails(door.sectionId);
    this.setSelectedModule(door.id);
    this.updateDoorPlacement(module);
    this.updateSectionWidth(section.id, section.width);
    this.data.triggerNextPlan();
  }

  private convertDoorToWall(module: Module) {
    const index = this.data.plan.modules.findIndex(
      (wall) => wall.id == module.id,
    );
    const wall = Wall.fromDoor(module as Door, this.data.defaults);
    let section = this.query.getSection(wall.sectionId);
    this.data.plan.modules[index] = wall;

    //Remove extra profiles
    let leftModule = this.query.getNeighborModule(wall.id, Side.Left);
    if (leftModule instanceof Wall) {
      this.removeProfile(leftModule.references.rightProfileId!);
      leftModule.references.rightProfileId = undefined;
    }

    let rightModule = this.query.getNeighborModule(wall.id, Side.Right);
    if (rightModule instanceof Wall) {
      this.removeProfile(rightModule.references.leftProfileId!);
      rightModule.references.leftProfileId = undefined;
    }

    if (
      module.references.leftProfileId != null &&
      this.query.isJointProfile(module.references.leftProfileId)
    ) {
      let profile = this.query.getProfile(module.references.leftProfileId);
      let sectionConnection = profile.sections.filter(
        (s) => s.profileId != null && s.sectionId == module.sectionId,
      )[0];
      if (sectionConnection) {
        this.removeProfile(sectionConnection.profileId!);
        sectionConnection.profileId = undefined;
      }
    }

    let profileHeight = section.height - Profile.profileRailReduction;

    //Add extra profiles if needed
    if (
      !wall.references.previousSectionModuleId ||
      leftModule instanceof Door
    ) {
      if (!wall.references.leftProfileId) {
        let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
          wall.id,
          -(StartStopProfile.size + Module.elementSpacing),
        );
        let profileId = this.addStartStopProfile(
          wall.sectionId,
          Side.Left,
          relativeLeftPoint.X,
          relativeLeftPoint.Y,
          profileHeight,
          section.rotation,
        );
        wall.references.leftProfileId = profileId;
      } else {
        let jointProfile = this.query.getProfile(wall.references.leftProfileId);
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == wall.sectionId,
        )!;
        this.removeProfile(connection.profileId!);
      }
    }
    if (!wall.references.nextSectionModuleId || rightModule instanceof Door) {
      if (!wall.references.rightProfileId) {
        let relativeRightPoint = this.queryGeometry.getModuleRelativeRightPoint(
          wall.id,
          StartStopProfile.size + Module.elementSpacing,
        );
        let profileId = this.addStartStopProfile(
          wall.sectionId,
          Side.Right,
          relativeRightPoint.X,
          relativeRightPoint.Y,
          profileHeight,
          (section.rotation + 180) % 360,
        );
        wall.references.rightProfileId = profileId;
      } else {
        let jointProfile = this.query.getProfile(
          wall.references.rightProfileId,
        );
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == wall.sectionId,
        )!;
        this.removeProfile(connection.profileId!);
      }
    }

    wall.fillings.forEach((fl) => {
      fl.xOffset = Module.fillingWidthReduction;
    });

    wall.height += Door.doorModuleHeightDifference;

    this.railService.recalculateSectionRails(wall.sectionId);
    this.setSelectedModule(wall.id);
    this.data.triggerNextPlan();
  }

  public copyModuleConfigurationToOtherModule(
    module1: Module,
    module2: Module,
  ) {
    module2.data.bars = ObjectHelper.copy(module1.data.bars);
    module2.data.fillings = ObjectHelper.copy(module1.data.fillings);
    module2.references.verticalBarOptionId =
      module1.references.verticalBarOptionId;
    module2.data.nextFillingId = module1.data.nextFillingId;
    module2.data.nextBarId = module1.data.nextBarId;

    // correct last filling & bars
    if (module1.isWall && module2.isDoor) {
      module2.fillings[0].height -= Door.doorTotalFillingHeightDifference;
    } else if (module1.isDoor && module2.isWall) {
      module2.fillings[0].height += Door.doorTotalFillingHeightDifference;
    }

    this.updateModulesWidthAndPosition(module1.sectionId);

    this.data.triggerNextPlan();
  }

  public setVerticalBarsOptionForSelectedModule(optionId: number) {
    let module = this.data.selectedModule;
    if (!module) return;

    module.references.verticalBarOptionId = optionId;
    this.data.triggerNextPlan();
  }

  public updateBarYPosition(barId: BarId, newPosition: number) {
    let module = this.data.selectedModule;
    if (!module) return;

    let barIndex = module.bars.findIndex((bar) => bar.id == barId)!;
    let fillingAbove = module.fillings[barIndex];
    let fillingBelow = module.fillings[barIndex + 1];

    // Get clamp range
    let minPosition = this.getMinimumBarPosition(barIndex);
    let maxPosition = this.getMaximumBarPosition(barIndex);

    newPosition = ObjectHelper.clamp(
      module.height - maxPosition,
      newPosition,
      module.height - minPosition,
    ); // the range inversion is needed since the position property is also inverted

    let diff = newPosition - module.bars[barIndex].position;
    module.bars[barIndex].position = newPosition;

    let newFillingHeightAbove = fillingAbove.height - diff;

    this.updateFillingHeight(
      module.id,
      fillingAbove.id,
      fillingBelow.id,
      newFillingHeightAbove,
    );
    this.data.triggerNextPlan();
  }

  private getMinimumBarPosition(barIndex: number): number {
    let module = this.data.selectedModule;
    if (!module) return 0;

    let barData = this.query.getPartitionBarData();
    if (!barData) return 0;

    let fillingBelow = module.fillings[barIndex + 1];
    let belowMaterial =
      this.assetService.editorAssets.materialsDict[fillingBelow.materialId];
    let frame = module.isWall
      ? this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
        )!.FrameWidthTop - Module.fillingInsertionDepth
      : this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getDoorModuleProductId(),
        )!.FrameWidthTop - Module.fillingInsertionDepth;

    if (barIndex == 0) {
      return frame + belowMaterial.MinFillingHeight + barData.BarMiddel / 2;
    } else {
      let prevBar = module.bars[barIndex - 1];

      return (
        module.height -
        prevBar.position +
        belowMaterial.MinFillingHeight +
        barData.BarMiddel
      ); // full bar middel is used, since one half for prev bar and one half for current bar
    }
  }

  private getMaximumBarPosition(barIndex: number): number {
    let module = this.data.selectedModule;
    if (!module) return 0;

    let barData = this.query.getPartitionBarData();
    if (!barData) return 0;

    let fillingAbove = module.fillings[barIndex];
    let aboveMaterial =
      this.assetService.editorAssets.materialsDict[fillingAbove.materialId];
    let frame = module.isWall
      ? this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
        )!.FrameWidthBottom - Module.fillingInsertionDepth
      : this.assetService.editorAssets.productDoorData.find(
          (pdd) => pdd.ProductId == this.query.getWallModuleProductId(),
        )!.FrameWidthBottom - Module.fillingInsertionDepth;

    if (barIndex == module.bars.length - 1) {
      return (
        module.height -
        (frame + aboveMaterial.MinFillingHeight + barData.BarMiddel / 2)
      );
    } else {
      let nextBar = module.bars[barIndex + 1];

      return (
        module.height -
        nextBar.position -
        aboveMaterial.MinFillingHeight -
        barData.BarMiddel
      ); // full bar middel is used, since one half for prev bar and one half for current bar
    }
  }

  public updateFillingHeight(
    moduleId: ModuleId,
    fillingId: FillingId,
    otherFillingId: FillingId,
    newTotalHeight: number,
  ) {
    let module = this.data.selectedModule;
    if (!module) return;

    let filling = module.fillings.find((filling) => filling.id == fillingId)!;
    let otherFilling = module.fillings.find(
      (filling) => filling.id == otherFillingId,
    )!;
    let material =
      this.assetService.editorAssets.materialsDict[filling.materialId];
    let otherMaterial =
      this.assetService.editorAssets.materialsDict[otherFilling.materialId];

    newTotalHeight = Math.max(
      material.MinFillingHeight,
      Math.min(newTotalHeight, material.MaxFillingHeight),
    );
    let diff = newTotalHeight - filling.height;

    let otherHeight = otherFilling.height - diff;
    let otherHeightClamped = Math.max(
      otherMaterial.MinFillingHeight,
      Math.min(otherHeight, otherMaterial.MaxFillingHeight),
    );
    if (otherHeight != otherHeightClamped) {
      let totalHeight = filling.height + otherFilling.height;
      newTotalHeight = totalHeight - otherHeightClamped;
      diff = newTotalHeight - filling.height;
    }

    filling.height = newTotalHeight;
    otherFilling.height -= diff;

    this.recalculateBarPositions(moduleId);
    this.data.triggerNextPlan();
  }

  public setDoorOpenDirection(moduleId: ModuleId, direction: DoorOpenSide) {
    let door = this.query.getModule(moduleId) as Door;

    if (!door.references.previousSectionModuleId)
      direction = DoorOpenSide.Right;
    else if (!door.references.nextSectionModuleId)
      direction = DoorOpenSide.Left;

    door.openDirection = direction;

    //Clamp extensions
    if (door.openDirection == DoorOpenSide.Left)
      this.updateDoorSlideExtensionAmount(
        door.id,
        door.leftSlideExtensionAmount,
        DoorOpenSide.Left,
      );
    else if (door.openDirection == DoorOpenSide.Right)
      this.updateDoorSlideExtensionAmount(
        door.id,
        door.rightSlideExtensionAmount,
        DoorOpenSide.Right,
      );
    else if (door.openDirection == DoorOpenSide.Both) {
      this.updateDoorSlideExtensionAmount(
        door.id,
        door.leftSlideExtensionAmount,
        DoorOpenSide.Left,
      );
      this.updateDoorSlideExtensionAmount(
        door.id,
        door.rightSlideExtensionAmount,
        DoorOpenSide.Right,
      );
    }

    this.railService.recalculateSectionRails(door.sectionId);
    this.updateDoorPlacement(door);
    this.data.triggerNextPlan();
  }

  public setDoorPlacement(moduleId: ModuleId, placement: DoorPlacement) {
    let door = this.query.getModule(moduleId) as Door;
    door.placement = placement;

    let leftModule = this.query.getNeighborModule(door.id, Side.Left);
    let rightModule = this.query.getNeighborModule(door.id, Side.Right);
    if (leftModule instanceof Door) leftModule.placement = placement;
    else if (rightModule instanceof Door) rightModule.placement = placement;

    this.updateDoorPlacement(door);
    this.updateModulesWidthAndPosition(door.sectionId);
    this.railService.recalculateSectionRails(door.sectionId);
    this.data.triggerNextPlan();
  }

  public updateDoorPlacement(module: Module) {
    let door = this.query.getModule(module.id) as Door;
    let leftModule = this.query.getNeighborModule(module.id, Side.Left);
    let rightModule = this.query.getNeighborModule(module.id, Side.Right);
    let leftNeighbor;
    if (leftModule)
      leftNeighbor = this.query.getNeighborModule(leftModule.id, Side.Left);
    let rightNeighbor;
    if (rightModule)
      rightNeighbor = this.query.getNeighborModule(rightModule.id, Side.Right);
    let leftNeighNeighbor;
    if (leftNeighbor)
      leftNeighNeighbor = this.query.getNeighborModule(
        leftNeighbor.id,
        Side.Left,
      );
    let rightNeighNeighbor;
    if (rightNeighbor)
      rightNeighNeighbor = this.query.getNeighborModule(
        rightNeighbor.id,
        Side.Right,
      );
    if (leftNeighbor && leftNeighbor.isDoor) {
      let leftNeighDoor = leftNeighbor as Door;
      if (
        door.openDirection == DoorOpenSide.Right &&
        leftNeighDoor.openDirection == DoorOpenSide.Left
      )
        return;
      this.switchDoorPlacement(door.id);
    } else if (rightNeighbor && rightNeighbor.isDoor) {
      let rightNeighDoor = rightNeighbor as Door;
      if (
        door.openDirection == DoorOpenSide.Left &&
        rightNeighDoor.openDirection == DoorOpenSide.Right
      )
        return;
      this.switchDoorPlacement(door.id);
    }
    if (leftNeighNeighbor && leftNeighNeighbor.isDoor) {
      let leftNeighDoor = leftNeighNeighbor as Door;
      if (
        (door.openDirection == DoorOpenSide.Left &&
          leftNeighDoor.openDirection == DoorOpenSide.Right) ||
        (door.openDirection == DoorOpenSide.Left &&
          leftNeighDoor.openDirection == DoorOpenSide.Both) ||
        (door.openDirection == DoorOpenSide.Both &&
          leftNeighDoor.openDirection == DoorOpenSide.Right) ||
        (door.openDirection == DoorOpenSide.Both &&
          leftNeighDoor.openDirection == DoorOpenSide.Both)
      )
        this.switchDoorPlacement(door.id);
    } else if (rightNeighNeighbor && rightNeighNeighbor.isDoor) {
      let rightNeighDoor = rightNeighNeighbor as Door;
      if (
        (door.openDirection == DoorOpenSide.Right &&
          rightNeighDoor.openDirection == DoorOpenSide.Left) ||
        (door.openDirection == DoorOpenSide.Right &&
          rightNeighDoor.openDirection == DoorOpenSide.Both) ||
        (door.openDirection == DoorOpenSide.Both &&
          rightNeighDoor.openDirection == DoorOpenSide.Left) ||
        (door.openDirection == DoorOpenSide.Both &&
          rightNeighDoor.openDirection == DoorOpenSide.Both)
      )
        this.switchDoorPlacement(door.id);
    }
  }

  public switchDoorPlacement(moduleId: ModuleId) {
    let door = this.query.getModule(moduleId) as Door;
    let doors = this.query
      .getModulesForSection(door.sectionId)
      .filter((md) => md.isDoor);
    let otherDoor = doors.find((md) => md.id != door.id)! as Door;
    otherDoor.placement =
      door.placement == DoorPlacement.Front
        ? DoorPlacement.Back
        : DoorPlacement.Front;
  }

  public updateDoorSlideExtensionAmount(
    moduleId: ModuleId,
    newAmount: number,
    side: DoorOpenSide,
  ) {
    let module = this.query.getModule(moduleId);
    if (!module.isDoor) return;

    let door = module as Door;
    let min = Math.round(this.query.getDoorMinSlideExtensionAmount(moduleId));
    let max = Math.round(
      this.query.getDoorMaxSlideExtensionAmount(moduleId, side),
    );
    if (max < min) max = min;
    if (
      newAmount > min &&
      max - newAmount < PartitionPlanCommandService.trivialRestAmount
    )
      newAmount = max; //set the rail to go all the way to the wall
    if (side == DoorOpenSide.Left)
      door.leftSlideExtensionAmount = Math.min(Math.max(min, newAmount), max);
    else if (side == DoorOpenSide.Right)
      door.rightSlideExtensionAmount = Math.min(Math.max(min, newAmount), max);
    this.railService.recalculateSectionRails(module.sectionId);
    this.data.triggerNextPlan();
  }

  public setDoorEndStop(moduleId: ModuleId, side: Side, state: boolean) {
    let door = this.query.getModule(moduleId) as Door;

    if (side == Side.Left) door.hasLeftEndStop = state;
    else door.hasRightEndStop = state;

    this.railService.recalculateSectionRails(door.sectionId);
    this.data.triggerNextPlan();
  }

  public addNicheProfile(
    section: SectionId,
    side: Side,
    posX: number,
    posY: number,
    height: number,
    rotation: number,
  ): ProfileId {
    const profileId = this.data.nextProfileId();
    const product =
      this.assetService.editorAssets.productsDict[
        this.data.defaults.joinProfileProductIds.niche
      ];
    const productData = product.getProductData()!;
    let nicheProfile = NicheProfile.createNew(
      profileId,
      { sectionId: section, side },
      posX,
      posY,
      productData.DefaultWidth,
      height,
      productData.DefaultDepth,
      rotation,
      this.data.defaults.joinProfileProductIds.niche,
    );
    this.data.plan.profiles.push(nicheProfile);
    return nicheProfile.id;
  }

  public addStartStopProfile(
    section: SectionId,
    side: Side,
    posX: number,
    posY: number,
    height: number,
    rotation: number,
  ): ProfileId {
    const profileId = this.data.nextProfileId();
    const product =
      this.assetService.editorAssets.productsDict[
        this.data.defaults.joinProfileProductIds.startStop
      ];
    const productData = product.getProductData()!;
    let startStopProfile = StartStopProfile.createNew(
      profileId,
      { sectionId: section, side },
      posX,
      posY,
      productData.DefaultWidth,
      height,
      productData.DefaultDepth,
      rotation,
      this.data.defaults.joinProfileProductIds.startStop,
    );
    this.data.plan.profiles.push(startStopProfile);
    return startStopProfile.id;
  }

  public addTProfile(
    leftSection: SectionId,
    leftSectionside: Side,
    middleSection: SectionId,
    middleSectionside: Side,
    rightSection: SectionId,
    rightSectionside: Side,
    posX: number,
    posY: number,
    height: number,
    rotation: number,
  ): ProfileId {
    const profileId = this.data.nextProfileId();
    const product =
      this.assetService.editorAssets.productsDict[
        this.data.defaults.joinProfileProductIds.tpiece
      ];
    const productData = product.getProductData()!;
    let tProfile = TProfile.createNew(
      profileId,
      { sectionId: leftSection, side: leftSectionside },
      { sectionId: middleSection, side: middleSectionside },
      { sectionId: rightSection, side: rightSectionside },
      posX,
      posY,
      productData.DefaultWidth,
      height,
      productData.DefaultDepth,
      rotation,
      this.data.defaults.joinProfileProductIds.tpiece,
    );
    this.data.plan.profiles.push(tProfile);
    return tProfile.id;
  }

  public addCornerProfile(
    leftSection: SectionId,
    leftSectionside: Side,
    rightSection: SectionId,
    rightSectionside: Side,
    posX: number,
    posY: number,
    height: number,
    rotation: number,
  ): ProfileId {
    const profileId = this.data.nextProfileId();
    const product =
      this.assetService.editorAssets.productsDict[
        this.data.defaults.joinProfileProductIds.corner
      ];
    const productData = product.getProductData()!;
    let cornerProfile = CornerProfile.createNew(
      profileId,
      { sectionId: leftSection, side: leftSectionside },
      { sectionId: rightSection, side: rightSectionside },
      posX,
      posY,
      productData.DefaultWidth,
      height,
      productData.DefaultDepth,
      rotation,
      this.data.defaults.joinProfileProductIds.corner,
    );
    this.data.plan.profiles.push(cornerProfile);
    return cornerProfile.id;
  }

  public removeProfile(profileId: ProfileId) {
    const plan = this.data.plan;
    plan.profiles = plan.profiles.filter((p) => p.id != profileId);
    this.data.triggerNextPlan();
  }

  public updateDoorRailSetOnSelectedSection(railSetId: number) {
    if (!this.data.selectedSection) {
      return;
    }
    this.data.selectedSection.doorRailSetId = railSetId;

    this.railService.recalculateSectionRails(this.data.selectedSection.id);
    this.data.triggerNextPlan();
  }

  public updateProfilePositionAndRotation(sectionId: SectionId) {
    const section = this.query.getSection(sectionId);

    this.query.getModulesForSection(sectionId).forEach((md) => {
      if (md.references.leftProfileId) {
        let profile = this.query.getProfile(
          md.references.leftProfileId,
        ) as Profile;

        if (profile.type == ProfileType.StartStop) {
          let leftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
            md.id,
            -(StartStopProfile.size + Module.elementSpacing),
          );
          profile!.posX = leftPoint.X;
          profile!.posY = leftPoint.Y;
          profile!.rotation = section.rotation;
        } else if (profile.type == ProfileType.Corner)
          this.updateCornerProfilePosition(profile.id);
        else if (profile.type == ProfileType.TPiece)
          this.updateTProfilePosition(profile.id);

        this.updateProfileProfilesPosition(profile);
      }

      if (md.references.rightProfileId) {
        let profile = this.query.getProfile(
          md.references.rightProfileId,
        ) as Profile;

        if (profile.type == ProfileType.StartStop) {
          let rightPoint = this.queryGeometry.getModuleRelativeRightPoint(
            md.id,
            StartStopProfile.size + Module.elementSpacing,
          );
          profile.posX = rightPoint.X;
          profile.posY = rightPoint.Y;
          profile!.rotation = (section.rotation + 180) % 360;
        } else if (profile.type == ProfileType.Corner)
          this.updateCornerProfilePosition(profile.id);
        else if (profile.type == ProfileType.TPiece)
          this.updateTProfilePosition(profile.id);

        this.updateProfileProfilesPosition(profile);
      }
    });

    let nicheOffsetFromModule =
      NicheProfile.distFromWallToStartStop +
      StartStopProfile.size +
      Module.elementSpacing;
    if (section.references.leftNicheProfileId) {
      let profile = this.query.getProfile(
        section.references.leftNicheProfileId,
      ) as Profile;
      let leftModule = this.query.getHeadModuleForSection(sectionId);

      let nicheDefaultDepth = ProductHelper.defaultDepth(
        this.assetService.editorAssets.productsDict[profile.productId],
        ProductLineIds.Partition,
      );
      let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
        leftModule.id,
        -nicheOffsetFromModule,
        -(nicheDefaultDepth - Module.fullJointWidth) / 2,
      );

      profile.posX = relativeLeftPoint.X;
      profile.posY = relativeLeftPoint.Y;
      profile.rotation = section.rotation;
    }

    if (section.references.rightNicheProfileId) {
      let profile = this.query.getProfile(
        section.references.rightNicheProfileId,
      ) as Profile;
      let rightModule = this.query.getTailModuleForSection(sectionId);

      let nicheDefaultDepth = ProductHelper.defaultDepth(
        this.assetService.editorAssets.productsDict[profile.productId],
        ProductLineIds.Partition,
      );
      let relativeRightPoint = this.queryGeometry.getModuleRelativeRightPoint(
        rightModule.id,
        nicheOffsetFromModule,
        (nicheDefaultDepth - Module.fullJointWidth) / 2,
      );

      profile.posX = relativeRightPoint.X;
      profile.posY = relativeRightPoint.Y;
      profile.rotation = (section.rotation + 180) % 360;
    }

    this.data.triggerNextPlan();
  }

  private updateProfileProfilesPosition(profile: Profile) {
    profile.sections.forEach((secCon) => {
      if (secCon.profileId) {
        let relativePoint: Vec2d;
        if (secCon.side == Side.Left) {
          let headModule = this.query.getHeadModuleForSection(secCon.sectionId);
          relativePoint = this.queryGeometry.getModuleRelativeLeftPoint(
            headModule.id,
            StartStopProfile.size,
            Module.fullJointWidth,
          );
        } else {
          let tailModule = this.query.getTailModuleForSection(secCon.sectionId);
          relativePoint = this.queryGeometry.getModuleRelativeRightPoint(
            tailModule.id,
            -StartStopProfile.size,
            -Module.fullJointWidth,
          );
        }

        let profile = this.query.getProfile(secCon.profileId) as Profile;
        if (profile) {
          profile.posX = relativePoint.X;
          profile.posY = relativePoint.Y;
        }
      }
    });
  }

  private updateCornerProfilePosition(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as CornerProfile;
    let leftModule = this.query.getHeadModuleForSection(
      profile.leftSection.sectionId,
    );
    let rightModule = this.query.getTailModuleForSection(
      profile.leftSection.sectionId,
    );

    if (leftModule.references.leftProfileId == profileId) {
      let leftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
        leftModule.id,
        -(CornerProfile.size + Module.elementSpacing),
      );
      profile!.posX = leftPoint.X;
      profile!.posY = leftPoint.Y;
    } else {
      // else it is on the right
      let rightPoint = this.queryGeometry.getModuleRelativeRightPoint(
        rightModule.id,
        CornerProfile.size + Module.elementSpacing,
      );
      profile.posX = rightPoint.X;
      profile.posY = rightPoint.Y;
    }
  }

  private updateTProfilePosition(profileId: ProfileId) {
    let profile = this.query.getProfile(profileId) as TProfile;
    let leftModule = this.query.getHeadModuleForSection(
      profile.middleSection.sectionId,
    );
    let rightModule = this.query.getTailModuleForSection(
      profile.middleSection.sectionId,
    );

    if (leftModule.references.leftProfileId == profileId) {
      let leftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
        leftModule.id,
        -(CornerProfile.size + Module.elementSpacing),
      );
      profile!.posX = leftPoint.X;
      profile!.posY = leftPoint.Y;
    } else {
      // else it is on the right
      let rightPoint = this.queryGeometry.getModuleRelativeRightPoint(
        rightModule.id,
        CornerProfile.size + Module.elementSpacing,
      );
      profile.posX = rightPoint.X;
      profile.posY = rightPoint.Y;
    }
  }
}
