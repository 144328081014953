import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Interior2dVm } from 'app/floor-plan-editing/Interior2dVm';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'backing-image',
  templateUrl: './backingImage.svg',
  styleUrls: ['../../style/d2.scss'],
})
export class BackingImageVm extends BaseVm implements OnInit {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  private cache: Partial<{
    corpusItems: Client.ConfigurationItem[];
    corpusJoints: Interior2dVm.Joint[];
  }> = {};

  private clearCache() {
    this.cache = {};
  }

  // #region AngularJS bindings

  @Input()
  public cabinetSection!: Client.CabinetSection;

  @Input()
  public showRulers: boolean = false;

  @Output() backingArea = new EventEmitter<Client.BackingArea>();
  onBackingAreaClick(value: Client.BackingArea) {
    this.backingArea.emit(value);
  }

  // #endregion

  public rulers: Client.Ruler[] = [];
  public gables: Client.ConfigurationItem[] = [];
  public readonly itemRulerBackgroundWidth = 200;
  public readonly itemRulerBackgroundHeight = 70;

  public ngOnInit() {
    if (this.cabinetSection == null) return;

    this.subscribeTo(
      this.cabinetSection!.cabinet.floorPlan.floorPlan$,
      (fp) => {
        this.gables =
          this.cabinetSection?.interior.items.filter(
            (i) => i.isGable && !(i.isVerticalDivider || i.isFittingPanel),
          ) ?? [];
        this.rulers = this.buildRulers();
        this.clearCache();
      },
    );
  }

  // #region svg-specific strings
  public get canvasViewBoxString(): string {
    let start = this.viewBoxStart;
    let size = this.viewBoxSize;
    return start.X + ' ' + start.Y + ' ' + size.X + ' ' + size.Y;
  }

  private get viewBoxStart(): Interface_DTO_Draw.Vec2d {
    let margin = Constants.canvasMargin;
    let result: Interface_DTO_Draw.Vec2d = {
      X: -margin,
      Y: -margin,
    };

    if (this.showRulers) {
      result.X -= 2 * Constants.rulerWidth;
      result.Y -= 2 * Constants.rulerWidth;
    }

    return result;
  }

  private get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let margin = Constants.canvasMargin;

    let result: Interface_DTO_Draw.Vec2d = {
      X: this.cabinetSection!.Width,
      Y: this.cabinetSection!.Height,
    };
    result = VectorHelper.subtract(result, this.viewBoxStart);

    if (this.showRulers) {
      result.X += 2 * Constants.rulerWidth;
      result.Y += 2 * Constants.rulerWidth;
    }

    result.X += margin;
    result.Y += margin;

    return result;
  }

  public getItemTransformString(item: Client.ConfigurationItem): string {
    let x = item.X;
    let y = this.cabinetSection!.Height - item.Y - item.Height;
    return 'translate( ' + x + ' , ' + y + ' )';
  }

  public getTransformString(
    pos: Interface_DTO_Draw.Vec2d,
    height: number,
  ): string {
    let y = this.cabinetSection!.Height - pos.Y - height;
    return 'translate( ' + pos.X + ' , ' + y + ' )';
  }

  // #endregion

  // #region corpus stuff

  public get corpusItems(): Client.ConfigurationItem[] {
    if (!this.cache.corpusItems) {
      this.cache.corpusItems = this.cabinetSection!.corpus.allCorpusItems;
    }
    return this.cache.corpusItems;
  }

  public get corpusJoints(): Interior2dVm.Joint[] {
    if (!this.cache.corpusJoints) {
      let topJoints = this.cabinetSection!.corpus.jointsTop.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection!.corpus.itemsTop.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection!.corpus.topDeductionLeft,
          Y: this.cabinetSection!.Height - height,
          height: height,
        };
      });
      let bottomJoints = this.cabinetSection!.corpus.jointsBottom.map((j) => {
        let height = Math.max(
          0,
          ...this.cabinetSection!.corpus.itemsBottom.map((i) => i.Height),
        );
        return <Interior2dVm.Joint>{
          X: j + this.cabinetSection!.corpus.bottomDeductionLeft,
          Y: 0,
          height: height,
        };
      });
      this.cache.corpusJoints = topJoints.concat(...bottomJoints);
    }
    return this.cache.corpusJoints;
  }

  // #endregion corpus stuff

  public get backingMaterialUrl() {
    return this.cabinetSection!.backing.material
      ? this.cabinetSection!.backing.material.ImagePath
      : '';
  }

  public get backingItems() {
    return this.cabinetSection!.backing.items;
  }

  public get backingAreas() {
    return this.cabinetSection!.backing.backingAreas;
  }

  private buildRulers(): Client.Ruler[] {
    let rulerDict = Client.Ruler.GetRulers(this.cabinetSection);
    let result = [
      rulerDict.cabinetHeightRuler,
      rulerDict.cabinetSightHeightRuler,
      rulerDict.cabinetWidthRuler,
    ];

    let backingItemRulerPosY = -Constants.rulerWidth * 2;
    for (let backingItem of this.cabinetSection!.backing.items.sort(
      (a, b) => a.X - b.X,
    )) {
      let ruler = new Client.Ruler(
        false,
        { X: backingItem.X, Y: backingItemRulerPosY },
        backingItem.Width,
        this.cabinetSection!.Height,
        false,
      );
      result.push(ruler);
    }

    {
      let interiorColumnRulerPosY = -Constants.rulerWidth;
      let startX = this.cabinetSection!.interior.cube.X;

      for (let gable of this.cabinetSection!.interior.gables.filter(
        (g) => !(g.isVerticalDivider || g.isFittingPanel),
      )) {
        let length = gable.X - startX;
        let ruler = new Client.Ruler(
          false,
          { X: startX, Y: interiorColumnRulerPosY },
          length,
          this.cabinetSection!.Height,
          false,
        );
        result.push(ruler);
        startX = gable.rightX;
      }

      let endX = this.cabinetSection!.interior.cubeRightX;
      result.push(
        new Client.Ruler(
          false,
          { X: startX, Y: interiorColumnRulerPosY },
          endX - startX,
          this.cabinetSection!.Height,
          false,
        ),
      );
    }

    return result;
  }
}
