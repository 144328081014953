import { Pickable } from 'app/ts/interfaces/Pickable';
export class Group<T> {
  public readonly anyEnabledMembers: boolean;

  constructor(
    public readonly name: string,
    public readonly members: Pickable<T>[],
    public readonly number: number,
  ) {
    this.anyEnabledMembers = this.members.some(
      (m) => m.disabledReason === null,
    );
  }
}
