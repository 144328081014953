import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  UseFullCatalog,
  UseFullCatalogInjector,
  useFullCatalogProvider,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { User } from 'app/identity-and-access/User';
import { NavigateService } from 'app/routing/navigate-service';
import { CancelException } from 'app/ts/exceptions/CancelException';
import { DefaultDeliveryAddressNotSet } from 'app/ts/exceptions/DefaultDeliveryAddressNotSet';
import { Store } from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { EndUserImportService } from 'app/ts/services/EndUserImportService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'dark-side-bar',
  styleUrls: ['./DarkSideBar.scss'],
  templateUrl: 'DarkSideBar.html',
  providers: [
    useFullCatalogProvider,
    activeUserProvider,
    activeStoreProvider,
    PermissionService,
  ],
})
export class DarkSideBarVm extends BaseVm {
  public showImportFromWebButton: boolean = false;
  public storeHasOrderConfirmer = false;
  private activeStore!: Store;
  private useFullCatalogData = false;

  public activeUser!: User;

  public constructor(
    baseVmService: BaseVmService,
    private readonly loginService: LoginService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly endUserImportService: EndUserImportService,
    private readonly permissions: PermissionService,
    private readonly router: Router,
    private navigate: NavigateService,
    @Inject(UseFullCatalogInjector) private useFullCatalog: UseFullCatalog,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(ActiveStoreInjector) activeStore: ActiveStore,
  ) {
    super(baseVmService);

    // subscribe on store changes
    super.ensureUnsubscribe(
      activeStore.subscribe((store) => {
        this.activeStore = store;
      }),
    );

    // subscribe on useFullCatalog changes
    super.ensureUnsubscribe(
      useFullCatalog.subscribe((val) => {
        this.useFullCatalogData = val;
        this.isFullCatalogActivated = val;
      }),
    );

    // subscribe on user  changes
    super.ensureUnsubscribe(
      activeUser.subscribe((user) => {
        if (user != null) this.activeUser = user;
      }),
    );

    super.subscribeTo(loginService.salesChainSettings$, (settings) => {
      this.storeHasOrderConfirmer =
        settings[Interface_Enums.SalesChainSettingKey.AllowOrderConfirmer] ==
        '1';
      this.showImportFromWebButton =
        settings[Interface_Enums.SalesChainSettingKey.AllowWebImport] == '1';
    });
  }

  @Input()
  public floorplan: any | undefined;

  @Input()
  public activeLink: 'floorPlans' | 'orderConfirmer' | undefined = undefined;

  public async importEnduserConfigOnDefault() {
    let endUserSetup = await this.modalService.importEndUserSetup();
    try {
      let floorPlan = await this.notificationService.loadPromise(
        this.endUserImportService.createDefaultEnduserSetup(endUserSetup),
      );
      this.navigate.floorPlan(floorPlan.Id!, {
        openedFromOverview: true,
      });
    } catch (e: any) {
      if (e instanceof DefaultDeliveryAddressNotSet) {
        return;
      } else if (e instanceof CancelException) {
        return;
      } else {
        this.notificationService.exception(
          'newFloorPlanOnDeliveryAddress_unknown_error',
          e,
          'Unknown error while creating a new floor plan',
        );
      }
    }
  }

  public async openUserSettings() {
    try {
      let modal = this.modalService.getEditUserSettingsModal();
      await modal.result;
    } catch (e: any) {
      //user cancelled, throw away that error
    }
  }

  public openLinkBrowser() {
    this.modalService.getLinkBrowser();
  }

  public get showOverviewButton(): boolean {
    return this.permissions.canSeeOrders;
  }

  public get showConfirmOrdersButton() {
    return this.storeHasOrderConfirmer && this.permissions.canUseOrderConfirmer;
  }

  public get showUserSettingsButton(): boolean {
    return this.permissions.canChangeUserSettings;
  }

  public get canActivateFullCatalog(): boolean {
    return this.permissions.canUseFullCatalog;
  }

  public get isFullCatalogActivated(): boolean {
    return this.useFullCatalogData;
  }

  public set isFullCatalogActivated(val: boolean) {
    if (this.useFullCatalogData === val) return;

    const isOnFrontPage = this.floorplan == null;
    if (isOnFrontPage) {
      this.useFullCatalog.set(val);
      return;
    }

    // Floorplan is open
    if (!val) {
      this.notificationService.userError(
        'full_catalog_deactivate_not_possible',
        "You can't disable full catalog while a solution is open.",
      );
      return;
    }

    if (this.floorplan.isDirty) {
      this.notificationService.userError(
        'full_catalog_must_save_before_activating',
        'You must save your changes before activating full catalog.',
      );
      return;
    }

    this.useFullCatalog.set(true);

    this.floorplan.UsesFullCatalog = true;
  }

  public get canChangeStore(): boolean {
    return this.permissions.canChangeStore && this.floorplan == null; // you can't change store if there's a floorplan open
  }

  public get changeStoreActive(): boolean {
    return this.activeUser.StoreId != this.activeStore?.Id;
  }

  public async changeStore() {
    // DomHelper.blurCurrentElement();
    await this.modalService.selectStore();
  }
}
