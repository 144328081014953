import { Component, Input, OnInit } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-piece-list-table',
  templateUrl: './ConsumerPieceListTable.html',
  styleUrls: ['./consumerPieceListTable.scss'],
})
export class ConsumerPieceListTableVm extends BaseVm implements OnInit {
  constructor(
    baseVmService: BaseVmService,
    private readonly notificationService: NotificationService,
  ) {
    super(baseVmService);
  }

  private static readonly dimensionNumberFormat: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  };

  //#region angular bindings
  @Input()
  public floorPlan!: Client.FloorPlan;

  //#endregion angular bindings

  public items: ConsumerPieceListTableVm.PieceListLine[] = [];
  public pieceList: Interface_DTO.PieceList | null = null;

  public async ngOnInit() {
    try {
      this.subscribeTo(this.floorPlan.pieceList$, async (pieceList) => {
        this.setItems(pieceList ?? null);
      });
    } catch (e: any) {
      this.notificationService.exception(
        'piecelist_load_error',
        e,
        'Could not load piecelist',
      );
    }
  }

  public formatDimension(item: Interface_DTO.PieceListItem): string {
    let numbers1 = [
      item.Dimensions.X,
      item.Dimensions.Y,
      item.Dimensions.Z,
    ].filter((n) => n > 0);
    if (numbers1.length === 0) {
      return '';
    }

    let numbers2 = [item.Dimensions2.X, item.Dimensions2.Y, item.Dimensions2.Z];

    let formattedDimensions: string[] = [];
    for (var i = 0; i < numbers1.length; i++) {
      let dimString = numbers1[i].toLocaleString(
        undefined,
        ConsumerPieceListTableVm.dimensionNumberFormat,
      );

      if (numbers2[i] > 0) {
        dimString +=
          '/' +
          numbers2[i].toLocaleString(
            undefined,
            ConsumerPieceListTableVm.dimensionNumberFormat,
          );
      }

      formattedDimensions.push(dimString);
    }

    return formattedDimensions.join(' x ') + ' mm';
  }

  public get rounding() {
    if (!this.pieceList) return this.toMoneyFormat(0);
    let roundingExclVat = this.pieceList.Rounding;
    let rounding = this.addVat(roundingExclVat, 1, this.pieceList);
    return this.toMoneyFormat(rounding);
  }

  public toMoneyFormat(num: number): string {
    if (!num) num = 0;
    return ObjectHelper.roundMoney(num);
  }

  private setItems(pieceList: Interface_DTO.PieceList | null) {
    this.pieceList = pieceList;
    if (!pieceList) {
      this.items = [];
      return;
    }
    this.items = this.flatten(
      pieceList.Groups,
      (g) => g.Children,
      (g) => g.Items,
    ).map((line) => ({
      productNumber: line.ItemNo,
      description: line.Description,
      item: line,
      amount: line.Amount,
      material: line.NaterialName,
      dimensions: this.formatDimension(line),
      unitPrice: this.toMoneyFormat(this.addVat(line.UnitPrice, 1, pieceList)),
      totalPrice: this.toMoneyFormat(
        this.addVat(line.UnitPrice, line.Amount, pieceList),
      ),
    }));
  }

  private addVat(
    unitPrice: number,
    amount: number,
    pieceList: Interface_DTO.PieceList,
  ): number {
    let totalExVat = unitPrice * amount;
    let totalInclVat = (pieceList.VatRate / 100 + 1) * totalExVat;
    return totalInclVat;
  }

  private flatten<T, TLeaf>(
    ts: T[],
    childSelector: (t: T) => T[],
    leafSelector: (t: T) => TLeaf[],
  ): TLeaf[] {
    let result: TLeaf[] = [];
    for (let t of ts) {
      let children = childSelector(t);
      let childLeafs = this.flatten(children, childSelector, leafSelector);
      result.push(...childLeafs);
      let leafs = leafSelector(t);
      result.push(...leafs);
    }
    return result;
  }
}

export module ConsumerPieceListTableVm {
  export interface PieceListLine {
    item: Interface_DTO.PieceListItem;
    productNumber: string;
    description: string;
    material: string;
    dimensions: string;
    amount: number | string;
    unitPrice: string;
    totalPrice: string;
  }
}
