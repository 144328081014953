import { ParamMap, Params, convertToParamMap } from '@angular/router';

export class CustomerSearchParams {
  quickSearch?: string;
  orderStatus?: string;
  owner?: string;

  customerId?: string;
  projectId?: string;
  deliveryAddressId?: string;

  page?: string;

  showAll?: string;

  selected?: string[];

  public static AsQueryString(params: CustomerSearchParams): string {
    const searchParams = new URLSearchParams();
    let key: keyof typeof params;
    for (key in params) {
      const value = params[key];
      if (value != null) {
        if (typeof value == 'string') {
          searchParams.set(key, value);
        } else if (typeof value == 'object' && value.length > 0) {
          searchParams.set(key, value.join(','));
        }
      }
    }

    return searchParams.toString();
  }

  public static FromParams(params: ParamMap): CustomerSearchParams {
    return {
      quickSearch: params.get('quicksearch') ?? undefined,
      orderStatus: params.get('orderstatus') ?? undefined,
      owner: params.get('owner') ?? undefined,
      customerId: params.get('customerid') ?? undefined,
      projectId: params.get('projectid') ?? undefined,
      deliveryAddressId: params.get('deliveryAddressid') ?? undefined,
      page: params.get('page') ?? undefined,
      showAll: params.get('showall') ?? undefined,
      selected: params.getAll('selected'),
    };
  }

  public static ToQueryParams(params: CustomerSearchParams): Params {
    const query: Params = {};
    if (params == null) return query;

    if (params.customerId) query['customerid'] = params.customerId;
    if (params.deliveryAddressId)
      query['deliveryaddressid'] = params.deliveryAddressId;
    if (params.orderStatus) query['orderstatus'] = params.orderStatus;
    if (params.owner) query['owner'] = params.owner;
    if (params.page) query['page'] = params.page;
    if (params.projectId) query['projectid'] = params.projectId;
    if (params.quickSearch) query['quicksearch'] = params.quickSearch;
    if (params.showAll) query['showall'] = params.showAll;
    if (params.selected && params.selected.length > 0)
      query['selected'] = params.selected.join(',');
    return query;
  }
}
