import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';

@NgModule({
  declarations: [TranslatePipe],
  exports: [TranslatePipe],
  imports: [],
  providers: [],
})
export class I18NModule {}
