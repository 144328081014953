<div class="ka-link">
  <div
    [attr.type]="linkVm.type"
    *ngIf="linkVm && linkVm.type === 'YoutubeLink'"
  >
    <span class="name" [textContent]="linkVm.name"></span>
    <iframe
      class="youtube-embed"
      src=""
      [attr.src]="linkVm.src"
      frameborder="0"
      allow="encrypted-media"
      allowfullscreen
    ></iframe>
  </div>
  <div [attr.type]="linkVm.type" *ngIf="linkVm && linkVm.type === 'VimeoLink'">
    <span class="name" [textContent]="linkVm.name"></span>
    <iframe
      class="vimeo-embed"
      src=""
      [attr.src]="linkVm.src"
      frameborder="0"
      allow="autoplay; fullscreen; encryptedmedia"
      allowfullscreen
    ></iframe>
  </div>
  <div [attr.type]="linkVm.type" *ngIf="linkVm && linkVm.type === 'IPaperLink'">
    <span class="name" [textContent]="linkVm.name"></span>
    <iframe
      src=""
      [attr.src]="linkVm.src"
      scrolling="no"
      frameborder="0"
    ></iframe>
  </div>
  <div [attr.type]="linkVm.type" *ngIf="linkVm && linkVm.type === 'NormalLink'">
    <a
      [attr.href]="linkVm.href"
      [textContent]="linkVm.name"
      class="name"
      target="_blank"
      href=""
    ></a>
  </div>
</div>
