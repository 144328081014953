<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('modal_free_space_title', 'Interior free space')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="ok()">
      <label
        ><span
          [textContent]="translate('modal_free_space_left', 'Free space left')"
        ></span
      ></label>
      <label>
        <span class="input-group">
          <input
            type="text"
            onlyNumber
            [(ngModel)]="freeSpaceLeft"
            class="form-control"
            autofocus
            onfocus="this.select()"
            name="freeSpaceLeft"
          />
          <span class="input-group-text">mm</span>
        </span>
      </label>
      <label
        ><span
          [textContent]="translate('modal_free_space_right', 'Free space right')"
        ></span
      ></label>
      <label>
        <span class="input-group">
          <input
            type="text"
            onlyNumber
            [(ngModel)]="freeSpaceRight"
            class="form-control"
            name="freeSpaceRight"
          />
          <span class="input-group-text">mm</span>
        </span>
      </label>
    </form>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="ok()"
        [textContent]="translate('button_save', 'Save')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
