import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TemplateService } from 'app/ts/services/TemplateService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'template-save-vm',
  templateUrl: './templateSave.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class TemplateSaveVm extends BaseVm implements OnInit {
  public saveRequest!: Interface_DTO.TemplateSaveRequest;
  public canSave: boolean = false;
  public canSaveInCategory: boolean = false;
  public categories: { key: number; value: string }[] = [];
  public selectedCategories: { [key: number]: boolean | undefined } = {};
  public showOverwriteTemplate = false;
  public overwriteTemplate = false;

  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly templateService: TemplateService,
    private readonly notificationService: NotificationService,
    private readonly permissions: PermissionService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.templateService
      .getSaveRequestWithoutImage(this.cabinetSection)
      .then((saveRequest) => {
        this.saveRequest = saveRequest;
        let cats = this.getCategories();
        for (let cat of cats) {
          let isBitSet = !!(cat.key & saveRequest.ConfigurationCategory);
          if (isBitSet) {
            this.selectedCategories[cat.key] = true;
          }
        }
      });

    this.templateService
      .getSaveRequest(this.cabinetSection)
      .then((saveRequest) => {
        if (this.saveRequest != null)
          this.saveRequest.ThumbnailImage = saveRequest.ThumbnailImage;
      })
      .then((a) => {
        this.CanSave();
      });

    this.canSaveInCategory = this.permissions.canSaveFavouriteInGroup;
    this.categories = this.getCategories();
    this.showOverwriteTemplate =
      this.permissions.canOverwriteFavourites &&
      !!this.cabinetSection.interior.template;
    this.overwriteTemplate = this.showOverwriteTemplate;
  }

  private CanSave() {
    this.canSave = true;
  }

  private getCategories(): { key: number; value: string }[] {
    let cats = [
      { key: Interface_Enums.ConfigurationCategory.Bedroom, value: 'Bedroom' },
      { key: Interface_Enums.ConfigurationCategory.Kitchen, value: 'Kitchen' },
      { key: Interface_Enums.ConfigurationCategory.Utility, value: 'Utility' },
      {
        key: Interface_Enums.ConfigurationCategory.KidsRoom,
        value: 'KidsRoom',
      },
      { key: Interface_Enums.ConfigurationCategory.Office, value: 'Office' },
      {
        key: Interface_Enums.ConfigurationCategory.DoorsOnly,
        value: 'DoorsOnly',
      },
      {
        key: Interface_Enums.ConfigurationCategory.Utility2,
        value: 'Utility2',
      },
      {
        key: Interface_Enums.ConfigurationCategory.Utility3,
        value: 'Utility3',
      },
      {
        key: Interface_Enums.ConfigurationCategory.Utility4,
        value: 'Utility4',
      },
      {
        key: Interface_Enums.ConfigurationCategory.Utility5,
        value: 'Utility5',
      },
    ];
    return cats.map((c) => {
      return {
        key: c.key,
        value: this.translate(
          'template_save_modal_category_' + c.value,
          c.value,
        ),
      };
    });
  }

  public async save() {
    if (this.saveRequest == null) return;

    let catFlags = 0;
    for (let catKey in this.selectedCategories) {
      if (this.selectedCategories[catKey]) {
        catFlags += parseInt(catKey);
      }
    }
    this.saveRequest.ConfigurationCategory = catFlags;
    this.saveRequest.CabinetSectionId =
      this.overwriteTemplate && this.cabinetSection.interior.template
        ? this.cabinetSection.interior.template.CabinetSectionId
        : null;
    try {
      await this.templateService.saveAsTemplate(
        this.cabinetSection,
        this.saveRequest,
      );
    } catch (e: any) {
      this.notificationService.exception(
        'template_modal_save_error',
        e,
        'Could not save template',
        { templateSaveRequest: this.saveRequest },
      );
      return;
    }
    this.activeModal.close(undefined);
  }

  public cancel(): void {
    this.activeModal.dismiss('user pressed cancel');
  }
}
