import { Component, Inject } from '@angular/core';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
} from 'app/functional-core/ambient/userdata/UserData';
import * as Client from 'app/ts/clientDto/index';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { TableVm } from 'app/ts/viewmodels/components/TableVm';

@Component({
  selector: 'delivery-addresses-table',
  templateUrl: './customersBaseTable.html',
  styleUrls: [
    '../../../../style/briefStyling.scss',
    '../../../../style/overviews.scss',
    '../../../../style/IE-hacks.scss',
    '../../../../style/floorplans.scss',
    '../../../../style/rightMenu.scss',
    '../../../../style/tables.scss',
    '../../../../style/nav.scss',
    '../../../../style/overviews.scss',
  ],
  providers: [userDataProvider, clientSettingProvider],
})
export class DeliveryAddressesTableVm extends TableVm<Client.DeliveryAddress> {
  constructor(
    baseVmService: BaseVmService,
    formattingService: FormattingService,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
    @Inject(UserSettingsInjector) private userData: UserSettings,
  ) {
    super(
      baseVmService,
      DeliveryAddressesTableVm.getColumns(
        baseVmService.translationService,
        formattingService,
      ),
    );

    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.selectedColumns = settings.columnsDeliveryAddress;
      }),
    );
    super.ensureUnsubscribe(
      this.userData.subscribe((userData) => {
        this.columns = DeliveryAddressesTableVm.getColumns(
          baseVmService.translationService,
          formattingService,
        );
      }),
    );
    this.reverseOrder = true;
  }

  public static getColumns(
    translationService: TranslationService,
    formattingService: FormattingService,
  ): { [name: string]: Readonly<TableColumn<Client.DeliveryAddress>> } {
    return {
      id: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_id',
          'Id',
        ),
        removable: true,
        colClass: 'id-col',
        val: (da) => da.ProjectDeliveryAddressId,
      },
      name: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_name',
          'Name',
        ),
        removable: true,
        colClass: 'text-col',
        val: (da) => da.Name,
      },
      address: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_address1',
          'Address',
        ),
        removable: true,
        colClass: 'text-col',
        val: (da) => da.Address1 + (da.Address2 ? ' | ' + da.Address2 : ''),
      },
      postalCodeCity: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_postalCodeCity',
          'City',
        ),
        removable: true,
        colClass: 'text-col',
        val: (da) => {
          let result = da.Country;
          result +=
            result && da.PostalCode ? '-' + da.PostalCode : da.PostalCode || '';
          result += result && da.City ? ' ' + da.City : da.City || '';
          return result;
        },
      },
      phone: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_phone',
          'Phone nr',
        ),
        removable: true,
        colClass: 'text-col',
        val: (da) => da.Phone,
      },
      numFloorPlans: {
        displayName: translationService.translate(
          'deliveryaddresses_col_name_num_floorplans',
          'Floor Plans',
        ),
        removable: true,
        colClass: 'number-col',
        val: (da) => da.FloorPlans.length,
      },
      createdDate: {
        displayName: translationService.translate(
          'deliveryaddresses_col_last_change',
          'Last changed date',
        ),
        removable: true,
        colClass: 'datetime-col',
        val: (da) => {
          let date = DateHelper.fromIsoString(da.CreatedDate);
          return formattingService.formatDate(date, true);
        },
        sortVal: (da) => da.CreatedDate,
      },
      floor: {
        displayName: translationService.translate(
          'deliveryAddresses_col_floor',
          'Floor',
        ),
        removable: true,
        colClass: 'number-col',
        val: (da) => da.Floor.toString(),
        sortVal: (da) => da.Floor,
      },
    };
  }

  public override shouldHighlight(obj: Client.DeliveryAddress): boolean {
    return (
      this.highlightId !== null &&
      obj.ProjectDeliveryAddressId === this.highlightId
    );
  }

  public selectedColumns = [
    'id',
    'name',
    'address',
    'postalCodeCity',
    'numFloorPlans',
  ];

  public order = 'createdDate';

  public readonly leftClass = 'bi bi-folder-fill';
  public readonly rightClass = 'bi bi-pencil-square';
}
