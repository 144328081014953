import { BarType } from 'app/ts/Interface_Enums';
import { BarId } from '../bar';
import { ModuleId } from '../module';

export class FillingsViewBar {
  constructor(
    private _id: BarId,
    private _moduleId: ModuleId,
    private _position: number,
    private _bartype: BarType,
    private _width: number,
    private _height: number,
    private _posY: number,
  ) {}

  public get id(): BarId {
    return this._id;
  }

  public get moduleId(): ModuleId {
    return this._moduleId;
  }

  public get position(): number {
    return this._position;
  }

  public get barType(): BarType {
    return this._bartype;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }
}
