import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EndUser } from 'app/ts/EndUser';
import { EndUserSetupOverview } from 'app/ts/EndUserSetupOverview';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { EndUserImportService } from 'app/ts/services/EndUserImportService';
import { ISelectable } from 'app/ts/util/ISelectable';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';

@Component({
  templateUrl: './EndUserSetupImport.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class EndUserSetupImportVm extends BaseVm {
  public selectedSetup: EndUserSetupOverview | null = null;

  public endUsers: (EndUser & ISelectable)[] = [];
  public searchState: 'uninitialized' | 'searching' | 'done' = 'uninitialized';
  public searchQuery: string = '';
  private queryNum = 0;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly endUserImportService: EndUserImportService,
  ) {
    super(baseVmService);
  }

  public get isSearchEnabled(): boolean {
    return this.searchQuery.length >= 3;
  }

  public get isSelectEnabled(): boolean {
    return !!this.selectedSetup;
  }

  public async search() {
    if (!this.isSearchEnabled) return;

    this.queryNum++;
    let thisQ = this.queryNum;
    this.searchState = 'searching';

    let result = await this.endUserImportService.search(this.searchQuery);

    if (this.queryNum === thisQ) {
      this.endUsers = result.map((eu) => {
        return { ...eu, selected: false };
      });
      this.searchState = 'done';
      this.selectedSetup = null;
    }
  }

  public save(): void {
    if (!this.selectedSetup) return;
    this.activeModal.close(this.selectedSetup);
  }

  public cancel(): void {
    this.activeModal.dismiss('user pressed cancel');
  }

  public setupsOrderedByModifiedDate(
    email: string,
  ): EndUserSetupOverview[] | undefined {
    const selectedUser = Enumerable.from(this.endUsers).where(
      (e) => e.Email == email,
    );
    return selectedUser
      .firstOrDefault()
      ?.Setups.sort(
        (s1, s2) => +new Date(s2.ModifiedDate) - +new Date(s1.ModifiedDate),
      );
  }
}
