import { Injectable } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
//import { EndUserSetupOverview } from 'app/ts/Interface_DTO'
//import { Cabinet as DtoCabinet } from 'app/ts/Interface_DTO'
import { Cabinet as DtoCabinet } from 'app/ts/Interface_DTO';
import { FloorPlan } from 'app/ts/clientDto/FloorPlan';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { EndUserSetupOverview } from '../EndUserSetupOverview';
import { Cabinet } from '../clientDto/Cabinet';
import { FloorPlanSaveService } from './FloorPlanSaveService';

@Injectable({ providedIn: 'root' })
export class EndUserImportService {
  constructor(
    private readonly $http: PromisingBackendService,
    private readonly floorPlanService: FloorPlanService,
    private readonly floorPlanSaveService: FloorPlanSaveService,
  ) {}

  public search(query: string): Promise<any[]> {
    return this.$http.get<any[]>('api/enduser', { params: { query: query } });
  }

  public async importIntoFloorPlan(
    floorPlan: any,
    setup: any,
  ): Promise<Cabinet> {
    const cabinet = await this.$http.get<DtoCabinet>(
      'api/enduser/import/' + setup.Id,
    );

    const clientCabinet: Cabinet =
      this.floorPlanService.insertCabinetIntoFloorPlan(floorPlan, cabinet);
    for (let section of clientCabinet.actualCabinetSections) {
      for (let door of section.doors.doors) {
        door.mustSpreadBarsEvenly = true;
      }
    }
    this.floorPlanService.recalculate(floorPlan, false);
    return clientCabinet;
  }

  public async createDefaultEnduserSetup(
    endUserSetup: EndUserSetupOverview,
  ): Promise<FloorPlan> {
    let floorPlan = await this.floorPlanSaveService.createDefaultFloorPlan(
      endUserSetup.Name,
    );
    let cabinet = await this.importIntoFloorPlan(floorPlan, endUserSetup);
    return floorPlan;
  }
}
