import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SwingLogic {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  public recalculate(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (section.CabinetType !== Interface_Enums.CabinetType.Swing)
      return result;

    if (App.debug.showTimings) console.time('Recalculate Swing');

    // Ensure we recalculate everything.
    section.swing.clearBackingVariables();

    // Ensure that all indexes are correct, and that the areas are sorted correctly.
    this.updateAreaIndexes(section);

    // Ensure all general values are set and valid
    this.ensureValuesAreValid(section);

    // *** The actual calculations start here ***

    // Calculate area dimensions and positions
    this.calculateAreas(section);

    // Move or remove area(s) if applicable
    this.moveOrRemoveAreas(section);

    // Generate configuration items
    this.generateConfigurationItems(section);

    this.throwIfSectionTooNarrow(section);

    // Do some cleanup
    this.resetAreaProperties(section);
    section.clearBackingVariables();

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Swing');

    return result;
  }

  //#region Validation of values set by user

  private ensureValuesAreValid(section: Client.CabinetSection) {
    if (!this.isCorpusMaterialValid(section.swing)) {
      this.setDefaultCorpusMaterial(section.swing);
    }

    if (!this.isGlobalDoorMaterialValid(section.swing)) {
      this.setDefaultGlobalDoorMaterial(section.swing);
    }

    section.swing.areas.forEach((area) => {
      if (!this.isAreaDoorMaterialValid(area, section.swing)) {
        this.setDefaultAreaDoorMaterial(area, section.swing);
      }
    });

    section.swing.areas.forEach((area) => {
      this.ensureAreaHingeSideIdValid(area, section.swing);
    });

    if (!this.isGripValid(section.swing)) {
      this.setDefaultGrip(section.swing);
    }

    this.ensureModuleNumbersAndTypesAreCorrect(section.swing);
  }

  private isCorpusMaterialValid(swing: Client.Swing): boolean {
    if (swing.corpusMaterial === null || swing.corpusMaterial === undefined) {
      return false;
    } else {
      let id = swing.corpusMaterial.Id;
      return swing.pickableCorpusMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultCorpusMaterial(swing: Client.Swing) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultSwingCorpusMaterialNumber(
        swing.editorAssets,
      );
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(swing.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(swing.pickableCorpusMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    swing.corpusMaterial = material ?? null;
  }

  private isGlobalDoorMaterialValid(swing: Client.Swing): boolean {
    if (swing.doorMaterial === null || swing.doorMaterial === undefined) {
      return false;
    } else {
      let id = swing.doorMaterial.Id;
      return swing.pickableDoorMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultGlobalDoorMaterial(swing: Client.Swing) {
    swing.doorMaterial = swing.defaultDoorMaterial;
  }

  private isAreaDoorMaterialValid(
    area: Client.SwingArea,
    swing: Client.Swing,
  ): boolean {
    if (area.doorMaterial === null || area.doorMaterial === undefined) {
      return false;
    } else {
      let id = area.doorMaterial.Id;
      return swing.pickableDoorMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  private setDefaultAreaDoorMaterial(
    area: Client.SwingArea,
    swing: Client.Swing,
  ) {
    area.doorMaterial = swing.defaultDoorMaterial;
  }

  private ensureAreaHingeSideIdValid(
    area: Client.SwingArea,
    swing: Client.Swing,
  ) {
    let navTemplates = Enumerable.from(swing.editorAssets.SwingModuleProducts);
    let areaTemplates = navTemplates.where(
      (nt) => nt.TemplateNo === area.moduleNumber,
    );
    let doorTemplate = areaTemplates.firstOrDefault(
      (t) => t.Placement === Interface_Enums.SwingModuleProductPlacement.Door,
    );

    if (!doorTemplate) {
      return;
    }

    if (doorTemplate.Quantity === 1) {
      if (
        area.hingeSide !== Enums.HingeSide.Left &&
        area.hingeSide !== Enums.HingeSide.Right
      ) {
        area.hingeSide = Enums.HingeSide.Left;
      }
    } else {
      area.hingeSide = Enums.HingeSide.Both;
    }
  }

  private isGripValid(swing: Client.Swing): boolean {
    if (swing.grip !== null && swing.grip !== undefined) {
      let id = swing.grip.Id;
      if (
        swing.pickableGrips.some((p) => p.item !== null && p.item.Id === id)
      ) {
        return true;
      }
    }

    return false;
  }
  private setDefaultGrip(swing: Client.Swing) {
    let pickableGrip = Enumerable.from(swing.pickableGrips).firstOrDefault();
    swing.grip = !!pickableGrip ? pickableGrip.item : null;
  }

  private ensureModuleNumbersAndTypesAreCorrect(swing: Client.Swing) {
    let swingModuleSetups = Enumerable.from(
      swing.editorAssets.swingModuleSetups,
    );
    let products = Enumerable.from(swing.editorAssets.products);
    let first = true; // Keep track of the first module (The left most module may be set to be removed, and then the next module is the first)

    for (let i = 0; i < swing.areas.length; i++) {
      let area = swing.areas[i];
      let swingModuleSetup = swingModuleSetups.firstOrDefault(
        (sms) => sms.ModuleProductNo === area.moduleNumber,
      );
      let product = products.firstOrDefault(
        (p) => p.ProductNo === area.moduleNumber,
      );

      if (!swingModuleSetup || !product) {
        // If the areas moduleNumber is not valid, set the area to be removed.
        area.desiredChange = Enums.SwingModuleChange.Remove;
      } else if (
        !swingModuleSetup ||
        !product ||
        (swingModuleSetup.Type !==
          Interface_Enums.SwingModuleType.StartModule &&
          swingModuleSetup.Type !==
            Interface_Enums.SwingModuleType.ExtendModule)
      ) {
        // If the module is neither a start module nor an extend module, set it to be removed.
        area.desiredChange = Enums.SwingModuleChange.Remove;
      } else {
        // If the first module is not a start module, or any other module is not an extend module, change the module number
        if (first) {
          first = false;
          if (
            swingModuleSetup.Type !==
            Interface_Enums.SwingModuleType.StartModule
          ) {
            area.moduleNumber = swingModuleSetup.AlternateModuleProductNo;
          }
        } else if (
          swingModuleSetup.Type !== Interface_Enums.SwingModuleType.ExtendModule
        ) {
          area.moduleNumber = swingModuleSetup.AlternateModuleProductNo;
        }
      }
    }
  }

  //#endregion Validation of values set by user

  //#region Calculations

  private calculateAreas(section: Client.CabinetSection) {
    this.setModuleDimensions(section.swing);

    this.calculateAreaPositions(section.swing);
  }

  /**
   * Set the areas width to the desired width, or clamps it to the closest value, if the desired width is not valid.
   * @param swing
   */
  private setModuleDimensions(swing: Client.Swing) {
    let products = Enumerable.from(swing.editorAssets.products);
    let height = swing.cabinetSection.Height - Constants.swingBottomOffset;

    for (let areaNumber = 0; areaNumber < swing.areas.length; areaNumber++) {
      let area = swing.areas[areaNumber];

      let product = products.firstOrDefault(
        (p) => p.ProductNo === area.moduleNumber,
      );
      if (product) {
        let minWidth = ProductHelper.minWidth(product);
        let maxWidth = ProductHelper.maxWidth(product);

        let actualInsideWidth = ObjectHelper.clamp(
          minWidth,
          area.desiredInsideWidth,
          maxWidth,
        );

        if (actualInsideWidth !== area.insideRect.Width) {
          let difference = actualInsideWidth - area.insideRect.Width;

          //items in any areas to the right of this one must be moved
          let rightInteriorItems = swing.cabinetSection.interior.items.filter(
            (i) => i.X > area.insideRect.topRight.X,
          );
          for (let item of rightInteriorItems) {
            item.X += difference;
          }

          //areas to the right of this one must be moved in order to correctly move items inside it
          for (
            let otherAreaNumber = areaNumber + 1;
            otherAreaNumber < swing.areas.length;
            otherAreaNumber++
          ) {
            let otherArea = swing.areas[otherAreaNumber];
            otherArea.insideRect.X += difference;
          }

          let interiorItems = SwingLogic.getInteriorItemsInArea(
            swing.cabinetSection,
            area,
          );

          // Items that snap to both sides of the area must have their width changed
          let fullWidthItems = interiorItems.filter((i) => i.snapLeftAndRight);
          fullWidthItems.forEach((item) => {
            item.Width += difference;
          });

          // Items that snap to the right of the area must be moved
          let rightSnappedItems = interiorItems.filter(
            (i) => i.X > area.insideRect.X + Constants.sizeAndPositionTolerance,
          );
          rightSnappedItems.forEach((item) => {
            item.X += difference;
          });
        }

        area.insideRect.Width = actualInsideWidth;
        area.desiredInsideWidth = actualInsideWidth;

        area.insideRect.Height = height;
      }
    }
  }

  private throwIfSectionTooNarrow(section: Client.CabinetSection) {
    let rightX = Math.max(...section.swing.items.map((i) => i.rightX));
    if (rightX > section.Width) {
      throw new Client.RecalculationError({
        cabinetSection: section,
        editorSection: Enums.EditorSection.Swing,
        severity: Enums.RecalculationMessageSeverity.Failure,
        key: 'recalc_error_swing_section_too_narrow',
        defaultValue: 'There is not enough room for all the swing modules.',
      });
    }
  }

  /**
   * Calculates and sets X and Y positions for all areas
   * @param swing
   */
  private calculateAreaPositions(swing: Client.Swing) {
    if (swing.areas.length <= 0) {
      return;
    }

    let gableWidth = this.getGableWidth(swing.areas[0], swing.cabinetSection);
    let positionX = 0;
    let positionY = Constants.swingBottomOffset;

    for (let i = 0; i < swing.areas.length; i++) {
      let area = swing.areas[i];

      positionX += gableWidth;

      if (positionX !== area.insideRect.X) {
        let difference = positionX - area.insideRect.X;

        let interiorItems = SwingLogic.getInteriorItemsInArea(
          swing.cabinetSection,
          area,
        );
        interiorItems.forEach((item) => {
          item.X += difference;
        });
      }

      area.insideRect.X = positionX;
      area.insideRect.Y = positionY;

      positionX += area.insideRect.Width;
    }
  }

  /**
   * Moves or removes any area, that have been set to be moved or removed by the user
   * @param section
   */
  private moveOrRemoveAreas(section: Client.CabinetSection) {
    section.swing.areas.forEach((area) => {
      switch (area.desiredChange) {
        case Enums.SwingModuleChange.Remove:
          this.removeArea(section, area.index);
          area.desiredChange = Enums.SwingModuleChange.None;
          break;

        case Enums.SwingModuleChange.MoveLeft:
          this.moveArea(section, area.index, false);
          area.desiredChange = Enums.SwingModuleChange.None;
          break;

        case Enums.SwingModuleChange.MoveRight:
          this.moveArea(section, area.index, true);
          area.desiredChange = Enums.SwingModuleChange.None;
          break;

        default:
          break;
      }
    });
  }

  /**
   * Removes an area and any interior items, that may be in it.
   * Areas and interior items to the right of the are moved left to close the gap.
   * @param section
   * @param areaIndex
   */
  private removeArea(section: Client.CabinetSection, areaIndex: number) {
    // Find area
    let areaToRemove = Enumerable.from(section.swing.areas).firstOrDefault(
      (area) => area.index === areaIndex,
    );
    if (!!areaToRemove) {
      // Find the area to the right of the area to be removed
      let nextArea = Enumerable.from(section.swing.areas).firstOrDefault(
        (area) => area.index === areaIndex + 1,
      );
      let movement = !!nextArea
        ? nextArea.insideRect.X - areaToRemove.insideRect.X
        : 0;

      // Find and remove interior items inside area
      this.removeItemsInArea(section, areaToRemove);

      // Remove the area
      section.swing.areas.splice(section.swing.areas.indexOf(areaToRemove), 1);

      // Adjust indexes of areas to the right of the removed area, and move them and their interior items
      section.swing.areas.forEach((area) => {
        if (area.index > areaIndex) {
          area.index--;

          // Find interior items inside area
          let itemsInArea = SwingLogic.getInteriorItemsInArea(section, area);

          // Move the items
          itemsInArea.forEach((item) => (item.X -= movement));

          // Move the area
          area.insideRect.X -= movement;
        }
      });

      this.updateAreaIndexes(section);
      this.ensureModuleNumbersAndTypesAreCorrect(section.swing);
    }
  }

  /**
   * Moves an area by swapping with another area.
   * @param section
   * @param areaIndex
   * @param moveRight
   */
  private moveArea(
    section: Client.CabinetSection,
    areaIndex: number,
    moveRight: boolean,
  ) {
    // Find areas
    let leftIndex = moveRight ? areaIndex : areaIndex - 1;
    let leftArea = section.swing.areas[leftIndex];
    let rightArea = section.swing.areas[leftIndex + 1];

    if (!leftArea || !rightArea) {
      return;
    }

    // Calculate each areas movement in the X direction
    let gableWidth = this.getGableWidth(leftArea, section);
    let leftAreaMovement = rightArea.insideRect.Width + gableWidth;
    let rightAreaMovement = -(rightArea.insideRect.X - leftArea.insideRect.X);

    // Find interior items inside each area
    let itemsInLeftArea = SwingLogic.getInteriorItemsInArea(section, leftArea);
    let itemsInRightArea = SwingLogic.getInteriorItemsInArea(
      section,
      rightArea,
    );

    // Move the interior items
    itemsInLeftArea.forEach((item) => (item.X += leftAreaMovement));
    itemsInRightArea.forEach((item) => (item.X += rightAreaMovement));

    // Move the areas
    leftArea.insideRect.X += leftAreaMovement;
    rightArea.insideRect.X += rightAreaMovement;

    // Swap the indexes of the areas
    leftArea.index++;
    rightArea.index--;

    this.updateAreaIndexes(section);
    this.ensureModuleNumbersAndTypesAreCorrect(section.swing);
  }

  //#endregion Calculations

  //#region Helpers

  /**
   * Finds all interior items that are positioned inside a given area.
   * @param section
   * @param area
   */
  public static getInteriorItemsInArea(
    section: Client.CabinetSection,
    area: Client.SwingArea,
  ): Client.ConfigurationItem[] {
    let items = Enumerable.from(section.interior.items).where(
      (i) =>
        i.centerX >= area.insideRect.X &&
        i.centerX <= area.insideRect.topRight.X,
    );
    return items.toArray();
  }

  /**
   * Removes all interior items that are inside a given area
   * @param section
   * @param area
   */
  private removeItemsInArea(
    section: Client.CabinetSection,
    area: Client.SwingArea,
  ) {
    // Find interior items inside area
    let itemsInArea = SwingLogic.getInteriorItemsInArea(section, area);

    // Remove the interior items
    itemsInArea.forEach((item) => {
      let itemIndex = section.interior.items.indexOf(item);
      if (itemIndex >= 0) {
        section.interior.items.splice(itemIndex, 1);
      }
    });
  }

  /**
   * Finds the width of a gable for the area.
   * Assumes that all possible gables in the area are the same width.
   * @param area
   * @param section
   */
  private getGableWidth(
    area: Client.SwingArea,
    section: Client.CabinetSection,
  ): number {
    let navTemplates = Enumerable.from(
      section.editorAssets.SwingModuleProducts,
    );
    let templateGable = navTemplates.firstOrDefault(
      (nt) =>
        nt.TemplateNo === area.moduleNumber &&
        (nt.Placement ===
          Interface_Enums.SwingModuleProductPlacement.LeftGable ||
          nt.Placement ===
            Interface_Enums.SwingModuleProductPlacement.RightGable ||
          nt.Placement ===
            Interface_Enums.SwingModuleProductPlacement.MiddleGable),
    );

    if (!!templateGable) {
      let productNo = templateGable.ProductNo;
      let products = Enumerable.from(section.editorAssets.products);
      let gable = products.firstOrDefault((p) => p.ProductNo === productNo);
      if (!!gable) {
        return ProductHelper.defaultWidth(gable);
      }
    }

    return 0;
  }

  /**
   * Finds the width of a gable for the area.
   * Assumes that all possible gables in the area are the same depth.
   * @param area
   * @param section
   */
  private getGableDepth(
    area: Client.SwingArea,
    section: Client.CabinetSection,
  ): number {
    let navTemplates = Enumerable.from(
      section.editorAssets.SwingModuleProducts,
    );
    let templateGable = navTemplates.firstOrDefault(
      (nt) =>
        nt.TemplateNo === area.moduleNumber &&
        (nt.Placement ===
          Interface_Enums.SwingModuleProductPlacement.LeftGable ||
          nt.Placement ===
            Interface_Enums.SwingModuleProductPlacement.RightGable ||
          nt.Placement ===
            Interface_Enums.SwingModuleProductPlacement.MiddleGable),
    );

    if (!!templateGable) {
      let productNo = templateGable.ProductNo;
      let products = Enumerable.from(section.editorAssets.products);
      let gable = products.firstOrDefault((p) => p.ProductNo === productNo);
      if (!!gable) {
        return ProductHelper.defaultDepth(gable, section.cabinet.ProductLineId);
      }
    }

    return 0;
  }

  /**
   * Finds the height of the bottom shelf for the area.
   * @param area
   * @param section
   */
  private getBottomShelfHeight(
    area: Client.SwingArea,
    section: Client.CabinetSection,
  ): number {
    let navTemplates = Enumerable.from(
      section.editorAssets.SwingModuleProducts,
    );
    let template = navTemplates.firstOrDefault(
      (nt) =>
        nt.TemplateNo === area.moduleNumber &&
        nt.Placement ===
          Interface_Enums.SwingModuleProductPlacement.BottomShelf,
    );

    if (!!template) {
      let productNo = template.ProductNo;
      let products = Enumerable.from(section.editorAssets.products);
      let shelf = products.firstOrDefault((p) => p.ProductNo === productNo);
      if (!!shelf) {
        return ProductHelper.defaultHeight(shelf);
      }
    }

    return 0;
  }

  /**
   * Finds the depth of the backing in the area
   * @param area
   * @param section
   */
  private getBackingThickness(
    area: Client.SwingArea,
    section: Client.CabinetSection,
  ) {
    let navTemplates = Enumerable.from(
      section.editorAssets.SwingModuleProducts,
    );
    let templateBacking = navTemplates.firstOrDefault(
      (nt) =>
        nt.TemplateNo === area.moduleNumber &&
        nt.Placement === Interface_Enums.SwingModuleProductPlacement.Backing,
    );

    if (!!templateBacking) {
      let productNo = templateBacking.ProductNo;
      let products = Enumerable.from(section.editorAssets.products);
      let backingProduct = products.firstOrDefault(
        (p) => p.ProductNo === productNo,
      );
      if (!!backingProduct) {
        return ProductHelper.defaultDepth(
          backingProduct,
          section.cabinet.ProductLineId,
        );
      }
    }

    return 0;
  }

  //#endregion Helpers

  //#region Options for later implementation (if they order it...)

  private fitAreasToWidth(section: Client.CabinetSection) {
    // The function is an option to the original requirements.
    // It has not been ordered yet, and therfore it is not implemented.
    console.log('SWING - fitAreasToWidth not implemented.');
  }

  private fitWidthToAreas(section: Client.CabinetSection) {
    // The function is an option to the original requirements.
    // It has not been ordered yet, and therfore it is not implemented.
    console.log('SWING - fitWidthToAreas not implemented.');
  }

  //#endregion Options for later implementation (if they order it...)

  //#region ConfigurationItem generation

  private generateConfigurationItems(section: Client.CabinetSection) {
    let areas = section.swing.areas;
    let corpusMaterial = !!section.swing.corpusMaterial
      ? section.swing.corpusMaterial
      : undefined;
    let products = Enumerable.from(section.swing.editorAssets.products);
    let navTemplates = Enumerable.from(
      section.swing.editorAssets.SwingModuleProducts,
    );
    let rightOutsideGableTemplate: Client.SwingModuleProduct | null = null;

    for (let i = 0; i < areas.length; i++) {
      let area = areas[i];
      area.items.length = 0;
      let areaTemplates = navTemplates.where(
        (nt) => nt.TemplateNo === area.moduleNumber,
      );

      //#region Gable(s)

      let gableTemplate;
      if (i === 0) {
        gableTemplate = areaTemplates.firstOrDefault(
          (t) =>
            t.Placement ===
            Interface_Enums.SwingModuleProductPlacement.LeftGable,
        );
        rightOutsideGableTemplate =
          areaTemplates.firstOrDefault(
            (t) =>
              t.Placement ===
              Interface_Enums.SwingModuleProductPlacement.RightGable,
          ) ?? null;
      } else {
        gableTemplate = areaTemplates.firstOrDefault(
          (t) =>
            t.Placement ===
            Interface_Enums.SwingModuleProductPlacement.MiddleGable,
        );
      }
      if (!!gableTemplate) {
        let gableItem = this.createGableItem(
          section,
          gableTemplate,
          area,
          false,
        );
        if (!!gableItem) {
          area.items.push(gableItem);
        }
      }
      if (i === areas.length - 1 && !!rightOutsideGableTemplate) {
        // Add the right outer gable
        let gableItem = this.createGableItem(
          section,
          rightOutsideGableTemplate,
          area,
          true,
        );
        if (!!gableItem) {
          area.items.push(gableItem);
        }
      }

      //#endregion Gable(s)

      //#region Shelves

      let bottomShelfTemplate = areaTemplates.firstOrDefault(
        (t) =>
          t.Placement ===
          Interface_Enums.SwingModuleProductPlacement.BottomShelf,
      );
      if (!!bottomShelfTemplate) {
        let shelfItem = this.createShelfItem(
          section,
          bottomShelfTemplate,
          area,
          true,
        );
        if (!!shelfItem) {
          area.items.push(shelfItem);
        }
      }

      let topShelfTemplate = areaTemplates.firstOrDefault(
        (t) =>
          t.Placement === Interface_Enums.SwingModuleProductPlacement.TopShelf,
      );
      if (!!topShelfTemplate) {
        let shelfItem = this.createShelfItem(
          section,
          topShelfTemplate,
          area,
          false,
        );
        if (!!shelfItem) {
          area.items.push(shelfItem);
        }
      }

      //#endregion Shelves

      //#region Door(s)

      let doorQuantity = 0;
      let doorTemplate = areaTemplates.firstOrDefault(
        (t) => t.Placement === Interface_Enums.SwingModuleProductPlacement.Door,
      );
      if (!!doorTemplate) {
        let doorItems = this.createDoorItems(
          section,
          doorTemplate,
          area,
          doorTemplate.Quantity,
        );
        for (let i = 0; i < doorItems.length; i++) {
          area.items.push(doorItems[i]);
        }
        doorQuantity = doorItems.length;
      }

      //#endregion Door(s)

      //#region Backing

      let backingTemplate = areaTemplates.firstOrDefault(
        (t) =>
          t.Placement === Interface_Enums.SwingModuleProductPlacement.Backing,
      );
      if (!!backingTemplate) {
        let backingItems = this.createBackingItems(
          section,
          backingTemplate,
          area,
          backingTemplate.Quantity,
        );
        area.items.push(...backingItems);
      }

      //#endregion Backing

      //#region SubModule

      let subModuleItems = this.createSubModuleItems(area, section);
      area.items.push(...subModuleItems);

      //#endregion SubModule

      //#region Grips

      if (section.swing.grip !== null && !section.swing.grip.isNone) {
        let gripItems = this.createGripItems(
          section,
          section.swing.grip,
          area,
          doorQuantity,
        );
        area.items.push(...gripItems);
      }

      //#endregion Grips

      //#region InteriorItems

      // The template may contain interior items. If so, they are added here.
      // As they may be removed later by the user, they are only added the first time the user adds the area.
      if (area.justAddedByUser) {
        let interiorShelfTemplate = areaTemplates.firstOrDefault(
          (t) =>
            t.Placement ===
            Interface_Enums.SwingModuleProductPlacement.InteriorShelf,
        );
        if (!!interiorShelfTemplate) {
          let interiorShelfItem = this.createInteriorItem(
            section,
            interiorShelfTemplate,
            area,
            true,
          );
          if (!!interiorShelfItem) {
            section.interior.items.push(interiorShelfItem);
          }
        }

        let interiorCoatHangerTemplate = areaTemplates.firstOrDefault(
          (t) =>
            t.Placement ===
            Interface_Enums.SwingModuleProductPlacement.InteriorCoatHanger,
        );
        if (!!interiorCoatHangerTemplate) {
          let interiorCoatHangerItem = this.createInteriorItem(
            section,
            interiorCoatHangerTemplate,
            area,
            false,
          );
          if (!!interiorCoatHangerItem) {
            section.interior.items.push(interiorCoatHangerItem);
          }
        }

        area.justAddedByUser = false;
      }

      //#endregion InteriorItems

      //#region Module

      let moduleItem = this.createModuleItem(section, area);
      if (!!moduleItem) {
        area.items.push(moduleItem);
      }

      //#endregion Module
    }
  }

  /**
   * Creates a ConfidurationItem for a gable, with dimensions and position set.
   * Returns null if the product does not exist.
   * @param section
   * @param productNo
   * @param area
   * @param right
   */
  private createGableItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    right: boolean,
  ):
    | (Client.ConfigurationItem & {
        swingModuleProduct: Client.SwingModuleProduct;
      })
    | null {
    let product = swingModuleProduct.product;
    if (!product) {
      return null;
    }

    // Create item
    let gableItem = this.createCorpusConfigurationItem(section, product.Id);

    // Set dimensions
    let height = section.Height - Constants.swingBottomOffset;
    gableItem.ActualHeight = height;
    gableItem.Height = height;
    gableItem.Width = ProductHelper.defaultWidth(product);
    gableItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    gableItem.X = right
      ? area.insideRect.topRight.X
      : area.insideRect.X - gableItem.Width;
    gableItem.Y = Constants.swingBottomOffset;
    gableItem.Z = 0;

    gableItem.swingModuleProduct = swingModuleProduct;
    return gableItem as Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    };
  }

  /**
   * Creates a ConfidurationItem for a top or bottom shelf, with dimensions and position set.
   * Returns null if the product does not exist.
   * @param section
   * @param productNo
   * @param area
   * @param bottom
   */
  private createShelfItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    bottom: boolean,
  ):
    | (Client.ConfigurationItem & {
        swingModuleProduct: Client.SwingModuleProduct;
      })
    | null {
    let product = swingModuleProduct.product;

    // Create item
    let shelfItem = this.createCorpusConfigurationItem(section, product.Id);

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    shelfItem.ActualHeight = height;
    shelfItem.Height = height;
    shelfItem.Width = area.insideRect.Width;
    shelfItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    shelfItem.X = area.insideRect.X;
    shelfItem.Y = bottom
      ? Constants.swingBottomOffset
      : section.Height - height;
    shelfItem.Z = this.getBackingThickness(area, section);
    shelfItem.swingModuleProduct = swingModuleProduct;

    return shelfItem as Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    };
  }

  /**
   * Creates ConfigurationItem(s) for door(s), with dimensions and position set.
   * @param section
   * @param productNo
   * @param area
   * @param quantity Valid quantities are 1 and 2
   */
  private createDoorItems(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    quantity: number,
  ): (Client.ConfigurationItem & {
    swingModuleProduct: Client.SwingModuleProduct;
  })[] {
    let items: (Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    })[] = [];

    let product = swingModuleProduct.product;

    let gableWidth = this.getGableWidth(area, section);
    let gableDepth = this.getGableDepth(area, section);

    // Calculate door dimensions
    let doorHeight =
      section.Height -
      Constants.swingBottomOffset -
      2 * Constants.swingDoorSpace;
    let totalDoorWidth =
      area.insideRect.Width + gableWidth - Constants.swingDoorSpace;
    let doorWidth =
      (totalDoorWidth - (quantity - 1) * Constants.swingDoorSpace) / quantity;
    let doorDepth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Create item(s)
    for (let i = 0; i < quantity; i++) {
      // Create item
      let doorItem = this.createDoorConfigurationItem(
        section,
        product.Id,
        area.doorMaterial,
      );

      // Set dimensions
      doorItem.ActualHeight = doorHeight;
      doorItem.Height = doorHeight;
      doorItem.Width = doorWidth;
      doorItem.Depth = doorDepth;

      // Set position
      doorItem.X =
        area.insideRect.X -
        gableWidth / 2 +
        Constants.swingDoorSpace / 2 +
        i * (doorWidth + Constants.swingDoorSpace);
      doorItem.Y = Constants.swingBottomOffset + Constants.swingDoorSpace;
      doorItem.Z = gableDepth + Constants.swingDoorSpace / 2;
      doorItem.swingModuleProduct = swingModuleProduct;

      items.push(
        doorItem as Client.ConfigurationItem & {
          swingModuleProduct: Client.SwingModuleProduct;
        },
      );
    }

    return items;
  }

  /**
   * Creates ConfigurationItem(s) for backing(s), with dimensions and position set.
   * @param section
   * @param productNo
   * @param area
   * @param quantity Valid quantities are 1 and 2
   */
  private createBackingItems(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    quantity: number,
  ): (Client.ConfigurationItem & {
    swingModuleProduct: Client.SwingModuleProduct;
  })[] {
    let items: (Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    })[] = [];
    let product = swingModuleProduct.product;

    let backingMaterialNumber =
      product.PossibleMaterialIds.length > 0
        ? product.PossibleMaterialIds[0].Id
        : null;

    // Create item(s)
    for (let i = 0; i < quantity; i++) {
      // Create item
      let backingItem = this.createBackingConfigurationItem(
        section,
        swingModuleProduct,
        backingMaterialNumber,
      );

      // Set dimensions
      let height = section.Height - Constants.swingBottomOffset;
      let width = area.insideRect.Width / quantity;
      backingItem.ActualHeight = height;
      backingItem.Height = height;
      backingItem.Width = width;
      backingItem.Depth = ProductHelper.defaultDepth(
        product,
        section.cabinet.ProductLineId,
      );

      // Set position
      backingItem.X = area.insideRect.X + i * width;
      backingItem.Y = Constants.swingBottomOffset;
      backingItem.Z = 0;

      items.push(backingItem);
    }

    return items;
  }

  /**
   * Creates ConfigurationItem(s) for sub module(s), with dimensions and position set.
   * @param area
   * @param section
   */
  private createSubModuleItems(
    area: Client.SwingArea,
    section: Client.CabinetSection,
  ): (Client.ConfigurationItem & {
    swingModuleProduct: Client.SwingModuleProduct;
  })[] {
    let items: (Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    })[] = [];

    let swingSubModuleSetups = Enumerable.from(
      section.editorAssets.swingSubModuleSetups,
    ).where(
      (ssms) =>
        ssms.MainModuleProductNo === area.moduleNumber &&
        ssms.VariantOptionNumber === area.pullOut,
    );

    if (!swingSubModuleSetups.any()) {
      return items;
    }

    let subModuleNavTemplates = Enumerable.from(
      section.swing.editorAssets.SwingModuleProducts,
    )
      .where((nt) =>
        swingSubModuleSetups.any(
          (ssms) => ssms.SubModuleProductNo === nt.TemplateNo,
        ),
      )
      .orderByDescending((nt) => nt.TemplateNo);

    let gableTemplate = subModuleNavTemplates.firstOrDefault(
      (nt) =>
        nt.Placement ===
        Interface_Enums.SwingModuleProductPlacement.SubModuleGable,
    );
    let shelfTemplate = subModuleNavTemplates.firstOrDefault(
      (nt) =>
        nt.Placement ===
        Interface_Enums.SwingModuleProductPlacement.SubModuleTopShelf,
    );

    if (gableTemplate == null || shelfTemplate == null) {
      return items;
    }

    let gablePositionY =
      Constants.swingBottomOffset + this.getBottomShelfHeight(area, section);
    let shelfPositionY =
      gablePositionY +
      ProductHelper.defaultHeight(gableTemplate.product) -
      ProductHelper.defaultHeight(shelfTemplate.product);

    let posX = area.insideRect.X;

    let addItem = (
      swingModuleProduct: Client.SwingModuleProduct,
      positionY: number,
    ) => {
      let item = this.createSubModuleItem(section, swingModuleProduct, area, {
        X: posX,
        Y: positionY,
      });
      if (!!item) {
        items.push(item);
        posX += item.Width;
      }
    };
    let addGable = () => addItem(gableTemplate!, gablePositionY);
    let addShelf = () => addItem(shelfTemplate!, shelfPositionY);
    let addExtraItems = () => {
      let extraItemTemplates = subModuleNavTemplates.where(
        (nt) =>
          nt.Placement ===
          Interface_Enums.SwingModuleProductPlacement.SubModuleExtraItem,
      );
      for (let extraItemTemplate of extraItemTemplates.toArray()) {
        let item = this.createSubModuleExtraItem(
          section,
          extraItemTemplate,
          area,
          1,
        );
        if (!!item) {
          items.push(item);
        }
      }
    };
    let setInteriorItemPositionX = () => {
      let itemsInPulloutArea = Enumerable.from(
        SwingLogic.getInteriorItemsInArea(section, area),
      ).where((item) => item.Y < shelfPositionY);
      itemsInPulloutArea.forEach((item) => (item.X = posX));
    };

    switch (area.pullOut) {
      case Enums.SwingPulloutSingle.No:
        //case Enums.SwingPulloutDouble.None:
        // No pullout required;
        break;

      case Enums.SwingPulloutSingle.Yes:
        if (area.hingeSide === Enums.HingeSide.Left) {
          addGable();
        }

        setInteriorItemPositionX();

        addShelf();

        if (area.hingeSide === Enums.HingeSide.Right) {
          addGable();
        }

        break;

      case Enums.SwingPulloutDouble.Left:
        addGable();
        setInteriorItemPositionX();
        addShelf();
        addGable();

        addExtraItems();

        break;

      case Enums.SwingPulloutDouble.Right:
        // Add offset, so the pullout is on the right side
        posX +=
          ProductHelper.defaultWidth(gableTemplate.product) +
          ProductHelper.defaultWidth(shelfTemplate.product);

        addGable();
        setInteriorItemPositionX();
        addShelf();
        addGable();

        addExtraItems();

        break;

      case Enums.SwingPulloutDouble.Both:
        addGable();
        addShelf();
        addGable();
        addShelf();
        addGable();

        addExtraItems();

        break;

      default:
    }

    return items;
  }

  /**
   * Creates a ConfidurationItem for a sub module product, with dimensions set and position set.
   * Returns null if the product does not exist.
   * @param section
   * @param productNo
   * @param area
   */
  private createSubModuleItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    position: Interface_DTO_Draw.Vec2d,
  ):
    | (Client.ConfigurationItem & {
        swingModuleProduct: Client.SwingModuleProduct;
      })
    | null {
    let product = swingModuleProduct.product;
    if (!product) {
      return null;
    }

    // Create item
    let item = this.createSubModuleConfigurationItem(section, product.Id);

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    item.ActualHeight = height;
    item.Height = height;
    item.Width = ProductHelper.defaultWidth(product);
    item.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    item.X = position.X;
    item.Y = position.Y;
    item.Z = this.getBackingThickness(area, section);
    item.swingModuleProduct = swingModuleProduct;

    return item as any;
  }

  /**
   * Creates ConfigurationItem(s) for grip(s).
   * Dimensions are set, but position is set to the bottom of the area.
   * (The grips are not visualized)
   * @param section
   * @param productNo
   * @param area
   * @param quantity
   */
  private createGripItems(
    section: Client.CabinetSection,
    product: Client.Product,
    area: Client.SwingArea,
    quantity: number,
  ): Client.ConfigurationItem[] {
    let items: Client.ConfigurationItem[] = [];

    // Create item(s)
    for (let i = 0; i < quantity; i++) {
      // Create item
      let gripItem = this.createNonVisualizedConfigurationItem(
        section,
        product.Id,
        null,
      );

      // Set dimensions
      let height = ProductHelper.defaultHeight(product);
      gripItem.ActualHeight = height;
      gripItem.Height = height;
      gripItem.Width = ProductHelper.defaultWidth(product);
      gripItem.Depth = ProductHelper.defaultDepth(
        product,
        section.cabinet.ProductLineId,
      );

      // Set position
      gripItem.X = area.insideRect.X;
      gripItem.Y = Constants.swingBottomOffset;
      gripItem.Z = 0;
      items.push(gripItem);
    }

    return items;
  }

  /**
   * Creates a ConfidurationItem for an interior shelf or coat hanger, with dimensions and position set.
   * Returns null if the product does not exist.
   * @param section
   * @param productNo
   * @param area
   * @param bottom
   */
  private createInteriorItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    shelf: boolean,
  ):
    | (Client.ConfigurationItem & {
        swingModuleProduct: Client.SwingModuleProduct;
      })
    | null {
    let product = swingModuleProduct.product;
    if (!product) {
      return null;
    }

    // Create item
    let interiorItem = this.createInteriorConfigurationItem(
      section,
      product.Id,
    );

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    interiorItem.ActualHeight = height;
    interiorItem.Height = height;
    interiorItem.Width = area.insideRect.Width;
    interiorItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    let posY = Constants.swingInteriorShelfPositionY;
    let maxTopY =
      section.interior.cube.Y +
      section.interior.cube.Height -
      Constants.swingInteriorShelfMinSpaceAbove;
    if (posY > maxTopY) {
      posY = maxTopY;
    }
    if (!shelf) {
      // Coat hanger is positioned below the shelf
      posY -= Constants.swingInteriorCoatHangerSpaceAbove;
    }

    let productLine = section.cabinet.productLine;
    let drillStops = InteriorLogic.getDrillStops(
      productLine,
      Constants.swingBottomOffset,
      posY,
    );
    let drillStop = drillStops.pop();
    if (!!drillStop) {
      posY = drillStop - ProductHelper.getSnapOffsetY(product);
    }

    interiorItem.X = area.insideRect.X;
    interiorItem.Y = posY;
    interiorItem.Z =
      this.getBackingThickness(area, section) + (shelf ? 0 : section.Depth / 2);
    interiorItem.swingModuleProduct = swingModuleProduct;

    return interiorItem as Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    };
  }

  /**
   * Creates a ConfidurationItem for a module, with dimensions and position set.
   * Returns null if the product does not exist.
   * @param section
   * @param area
   */
  private createModuleItem(
    section: Client.CabinetSection,
    area: Client.SwingArea,
  ): Client.ConfigurationItem | null {
    let products = Enumerable.from(section.editorAssets.products);
    let product = products.firstOrDefault(
      (p) => p.ProductNo === area.moduleNumber,
    );
    if (!product) {
      return null;
    }

    // Create item
    let moduleItem = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingModule,
      product.Id,
      section.CorpusMaterialId,
      section,
    );

    // Set dimensions
    let height = area.insideRect.Height;
    moduleItem.ActualHeight = height;
    moduleItem.Height = height;
    moduleItem.Width = area.insideRect.Width;
    moduleItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    moduleItem.X = area.insideRect.X;
    moduleItem.Y = 0;
    moduleItem.Z = 0;

    moduleItem.IsHidden = true;

    // Set hinge side variant
    let hingeSideOptionNumber: string | undefined;
    if (area.hingeSide === Enums.HingeSide.Left) {
      hingeSideOptionNumber = VariantNumbers.Values.HingeSide_Left;
    } else if (area.hingeSide === Enums.HingeSide.Right) {
      hingeSideOptionNumber = VariantNumbers.Values.HingeSide_Right;
    }
    if (!!hingeSideOptionNumber) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        moduleItem,
        VariantNumbers.HingeSide,
        hingeSideOptionNumber,
      );
    }

    // Set sub module variant
    let pulloutOptionNumber: string | undefined;
    if (area.pullOut === Enums.SwingPulloutSingle.Yes) {
      pulloutOptionNumber = VariantNumbers.Values.Yes;
    } else if (area.pullOut === Enums.SwingPulloutSingle.No) {
      pulloutOptionNumber = VariantNumbers.Values.No;
    }
    if (!!pulloutOptionNumber) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        moduleItem,
        VariantNumbers.SwingPulloutSingle,
        pulloutOptionNumber,
      );
    } else {
      if (area.pullOut === Enums.SwingPulloutDouble.None) {
        pulloutOptionNumber = VariantNumbers.Values.SwingPulloutDouble_None;
      } else if (area.pullOut === Enums.SwingPulloutDouble.Left) {
        pulloutOptionNumber = VariantNumbers.Values.SwingPulloutDouble_Left;
      } else if (area.pullOut === Enums.SwingPulloutDouble.Right) {
        pulloutOptionNumber = VariantNumbers.Values.SwingPulloutDouble_Right;
      } else if (area.pullOut === Enums.SwingPulloutDouble.Both) {
        pulloutOptionNumber = VariantNumbers.Values.SwingPulloutDouble_Both;
      }
      if (!!pulloutOptionNumber) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          moduleItem,
          VariantNumbers.SwingPulloutDouble,
          pulloutOptionNumber,
        );
      }
    }

    return moduleItem;
  }

  /**
   * Creates a ConfigurationItem for sub module extra items(s).
   * Dimensions and quantity are set, but position is set to the bottom of the area.
   * (The extra items are not visualized)
   * @param section
   * @param productNo
   * @param area
   * @param quantity
   */
  private createSubModuleExtraItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    area: Client.SwingArea,
    quantity: number,
  ):
    | (Client.ConfigurationItem & {
        swingModuleProduct: Client.SwingModuleProduct;
      })
    | null {
    let product = swingModuleProduct.product;
    if (!product) {
      return null;
    }

    // Create item
    let extraItem = this.createNonVisualizedConfigurationItem(
      section,
      product.Id,
      null,
    );

    // Set dimensions
    let height = ProductHelper.defaultHeight(product);
    extraItem.ActualHeight = height;
    extraItem.Height = height;
    extraItem.Width = ProductHelper.defaultWidth(product);
    extraItem.Depth = ProductHelper.defaultDepth(
      product,
      section.cabinet.ProductLineId,
    );

    // Set position
    extraItem.X = area.insideRect.X;
    extraItem.Y = Constants.swingBottomOffset;
    extraItem.Z = 0;

    extraItem.Quantity = quantity;
    extraItem.swingModuleProduct = swingModuleProduct;

    return extraItem as Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    };
  }

  /**
   * Creates a corpus item for a specified ProductId
   * @param section
   * @param productId
   */
  private createCorpusConfigurationItem(
    section: Client.CabinetSection,
    productId: number,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingCorpus,
      productId,
      section.CorpusMaterialId,
      section,
    );
  }

  /**
   * Creates a door item for a specified ProductId
   * @param section
   * @param productId
   * @param material
   */
  private createDoorConfigurationItem(
    section: Client.CabinetSection,
    productId: number,
    material: Client.Material | null,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingDoor,
      productId,
      !!material ? material.Id : null,
      section,
    );
  }

  /**
   * Creates a backing item for a specified ProductId
   * @param section
   * @param productId
   * @param materialId
   */
  private createBackingConfigurationItem(
    section: Client.CabinetSection,
    swingModuleProduct: Client.SwingModuleProduct,
    materialId: number | null,
  ): Client.ConfigurationItem & {
    swingModuleProduct: Client.SwingModuleProduct;
  } {
    let result = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingBacking,
      swingModuleProduct.product.Id,
      materialId,
      section,
    );
    result.swingModuleProduct = swingModuleProduct;
    return result as Client.ConfigurationItem & {
      swingModuleProduct: Client.SwingModuleProduct;
    };
  }

  /**
   * Creates a sub module item for a specified ProductId
   * @param section
   * @param productId
   * @param materialId
   */
  private createSubModuleConfigurationItem(
    section: Client.CabinetSection,
    productId: number,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingCorpus,
      productId,
      section.InteriorMaterialId,
      section,
    );
  }

  /**
   * Creates a configuration item for a specified ProductId.
   * The item is should not be visualized in either 2D or 3D.
   * @param section
   * @param productId
   */
  private createNonVisualizedConfigurationItem(
    section: Client.CabinetSection,
    productId: number,
    materialId: number | null,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.SwingExtra,
      productId,
      materialId,
      section,
    );
  }

  /**
   * Creates an interior item for a specified ProductId
   * @param section
   * @param productId
   */
  private createInteriorConfigurationItem(
    section: Client.CabinetSection,
    productId: number,
  ): Client.ConfigurationItem {
    return this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.Interior,
      productId,
      section.InteriorMaterialId,
      section,
    );
  }

  //#endregion ConfigurationItem generation

  //#region Cleanup

  private resetDesiredChanges(section: Client.CabinetSection) {
    section.swing.areas.forEach(
      (a) => (a.desiredChange = Enums.SwingModuleChange.None),
    );
  }

  private updateAreaIndexes(section: Client.CabinetSection) {
    section.swing.areas.sort((a, b) => {
      return a.index - b.index;
    });
    section.swing.areas.forEach(
      (a) => (a.index = section.swing!.areas.indexOf(a)),
    );
  }

  private resetAreaProperties(section: Client.CabinetSection) {
    this.resetDesiredChanges(section);
    this.updateAreaIndexes(section);
  }

  //#endregion Cleanup
}
