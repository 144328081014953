import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { ProductListVm } from 'app/ts/viewmodels/components/ProductListVm';
import Enumerable from 'linq';
import { EditorTypeService } from '../editor-type.service';

@Component({
  selector: 'product-list-recursive',
  templateUrl: 'productListRecursive.html',
  styleUrls: ['../../../../style/productListRecursive.scss'],
})
export class ProductListRecursiveVm
  extends BaseVm
  implements OnInit, AfterViewInit
{
  private readonly pageSize = 30;

  private _searchString: string = '';
  private unfilteredProductCategories: {
    [productLineId: number]: ProductListRecursiveVm.CategoryVm[];
  } = {};
  private _selectedProductLine: Interface_DTO.ProductLine | undefined;
  private _selectedProductCategoryId = -1;
  private get showOtherMaterials() {
    return this.editorTypeService.floorPlan.FullCatalogAllowOtherMaterials;
  }

  @ViewChild('productSearch') private _productSearchElem!: ElementRef;

  public constructor(
    baseVmService: BaseVmService,
    private editorTypeService: EditorTypeService,
  ) {
    super(baseVmService);
  }

  private cache: Partial<{
    filteredProductCategories: ProductListRecursiveVm.CategoryVm[];
    visibleProducts: Pickable<Client.Product>[];
    selectedProductCategory: ProductListRecursiveVm.CategoryVm;
  }> = {};

  //#region angularJS Bindings

  @Output()
  public onProductClick = new EventEmitter<ProductListVm.ProductParams>();

  @Input()
  public productCategories!: Client.ProductCategory[];
  @Input()
  public productLines!: Interface_DTO.ProductLine[];
  @Input()
  public initialProductLine!: Interface_DTO.ProductLine;
  @Input()
  public fullCatalog = false;
  @Input()
  public cabinetSection!: Client.CabinetSection;

  //#endRegion

  public ngOnInit() {
    this.selectedProductLine = this.initialProductLine;
    this.unfilteredProductCategories = {};
    for (let productLine of this.productLines) {
      this.unfilteredProductCategories[productLine.Id] =
        this.getAllVmCategories(this.productCategories, productLine.Id);
    }
    this.searchString = '';
  }

  ngAfterViewInit(): void {
    this._productSearchElem.nativeElement.focus();
  }

  public get selectedProductCategoryId() {
    return this._selectedProductCategoryId;
  }

  public get filteredProductCategories(): ProductListRecursiveVm.CategoryVm[] {
    if (!this.cache.filteredProductCategories) {
      let upc = this.selectedProductLine
        ? this.unfilteredProductCategories[this.selectedProductLine.Id]
        : null;
      if (upc) {
        this.cache.filteredProductCategories = upc
          .map((cat) =>
            this.filterCategory(cat, this.searchString.toLocaleLowerCase()),
          )
          .filter((cat) => cat.products.length > 0);
      } else {
        //probably because the component isn't inited yet
        return [];
      }

      this.onProductClick.emit({ product: undefined, variants: undefined });
    }
    return this.cache.filteredProductCategories;
  }

  public trackByCategoryId(
    index: number,
    category: ProductListRecursiveVm.CategoryVm,
  ) {
    return category.id;
  }

  public trackByIndex(index: number, element: any) {
    return index;
  }

  public get searchString(): string {
    return this._searchString;
  }
  public set searchString(val: string) {
    this._searchString = val;
    this.clearCache();
  }

  @Input()
  public get selectedProductLine(): Interface_DTO.ProductLine | undefined {
    return this._selectedProductLine;
  }
  public set selectedProductLine(val: Interface_DTO.ProductLine | undefined) {
    this._selectedProductLine = val;
    this.clearCache();
    this.selectedProductLineChange.emit(this.selectedProductLine);
  }

  @Output()
  public readonly selectedProductLineChange = new EventEmitter<
    Interface_DTO.ProductLine | undefined
  >();

  public get selectedProductCategory():
    | ProductListRecursiveVm.CategoryVm
    | undefined {
    let spc: ProductListRecursiveVm.CategoryVm | undefined = undefined;
    for (let pc of this.filteredProductCategories) {
      if (pc.id === this.selectedProductCategoryId) {
        spc = pc;
        break;
      }
    }
    if (!spc) {
      let firstPc = this.filteredProductCategories[0];
      if (firstPc) {
        this.selectedProductCategory = firstPc;
        spc = firstPc;
      } else {
        this.selectedProductCategory = undefined;
      }
    }
    return spc;
  }
  public set selectedProductCategory(
    val: ProductListRecursiveVm.CategoryVm | undefined,
  ) {
    this._selectedProductCategoryId = val ? val.id : -1;
    this.clearCache();
  }

  public get visibleProducts(): Pickable<Client.Product>[] {
    if (!this.cache.visibleProducts) {
      if (!this.selectedProductCategory) {
        return [];
      }
      this.cache.visibleProducts = [];
      this.showMoreProducts();
    }
    return this.cache.visibleProducts!;
  }

  public get moreProductsAvailable(): boolean {
    if (!this.selectedProductCategory) return false;
    return (
      this.visibleProducts.length < this.selectedProductCategory.products.length
    );
  }

  public showMoreProducts() {
    if (!this.cache.visibleProducts) {
      this.cache.visibleProducts = [];
    }
    let fullList = this.selectedProductCategory
      ? this.selectedProductCategory.products
      : [];

    let newCount = this.cache.visibleProducts.length + this.pageSize;
    newCount = Math.min(newCount, fullList.length);

    this.cache.visibleProducts = fullList.slice(0, newCount);
  }

  public selectProduct(product: Client.Product) {
    this.onProductClick.emit({ product: product, variants: undefined });
  }

  public searchEnter() {
    let productPickable = this.visibleProducts[0];
    if (!productPickable) return;
    let product = productPickable.item;
    let vopts = ProductListVm.getVariantOptions(
      this.searchString,
      product,
      this.fullCatalog,
    );
    this.onProductClick.emit({ product: product, variants: vopts });
  }

  private filterCategory(
    cat: ProductListRecursiveVm.CategoryVm,
    searchStringLowerCase: string,
  ): ProductListRecursiveVm.CategoryVm {
    return new ProductListRecursiveVm.CategoryVm(
      cat.category,
      cat.level,
      cat.name,
      cat.products.filter((pickableProduct) =>
        this.filterProduct(pickableProduct.item, searchStringLowerCase),
      ),
      cat.id,
    );
  }

  private filterProduct(
    product: Client.Product,
    searchStringLowerCase: string,
  ): boolean {
    if (!product.Enabled || (!this.fullCatalog && product.OverrideChain)) {
      return false;
    }
    if (!this.showOtherMaterials && product.hasOnlyDisabledMaterials())
      return false;
    return (
      product.Name.toLocaleLowerCase().indexOf(searchStringLowerCase) >= 0 ||
      product.ProductNo.toLocaleLowerCase().indexOf(searchStringLowerCase) ===
        0 ||
      searchStringLowerCase.indexOf(product.ProductNo.toLocaleLowerCase()) === 0
    );
  }

  private getAllVmCategories(
    categories: Client.ProductCategory[],
    productLineId: number,
  ): ProductListRecursiveVm.CategoryVm[] {
    let level = 0;
    let childLists = categories.map((cat) =>
      this.getVmCategories(cat, level + 1, productLineId),
    );
    let children = Enumerable.from(childLists).selectMany(
      (childList) => childList,
    );
    let childProducts = children
      .where((child) => child.level === level + 1)
      .selectMany((child) => child.products);
    let rootCategory = new ProductListRecursiveVm.CategoryVm(
      undefined,
      0,
      this.translate('add_product_root_category_name', 'All'),
      childProducts.toArray(),
      -1,
    );

    return [rootCategory, ...children.toArray()];
  }

  private getVmCategories(
    src: Client.ProductCategory,
    level: number,
    productLineId: number,
  ): ProductListRecursiveVm.CategoryVm[] {
    let childLists = src.Children.map((child) =>
      this.getVmCategories(child, level + 1, productLineId),
    );
    let children = Enumerable.from(childLists).selectMany(
      (childList) => childList,
    );
    let childProducts = children
      .where((child) => child.level === level + 1)
      .selectMany((child) => child.products);

    let mainProducts: Enumerable.IEnumerable<Pickable<Client.Product>>;
    if (src.IncludeOnPieceList) {
      mainProducts = Enumerable.from(src.products)
        .selectMany((prod) => prod.productGroup)
        .where((prod) => prod.ProductLineIds.indexOf(productLineId) >= 0)
        .orderBy((p) => p.SortOrder)
        .select((p) => this.toPickable(p));
    } else {
      mainProducts = Enumerable.empty<Pickable<Client.Product>>();
    }

    let main = [
      new ProductListRecursiveVm.CategoryVm(
        src,
        level,
        src.Name,
        mainProducts.concat(childProducts).toArray(),
        src.Id,
      ),
      ...children.toArray(),
    ];
    return main;
  }

  private clearCache() {
    this.cache = {};
  }

  private toPickable(p: Client.Product): Pickable<Client.Product> {
    if (!p) debugger;
    return ProductHelper.getPickable(p);
  }
}

export module ProductListRecursiveVm {
  export class CategoryVm {
    constructor(
      public readonly category: Client.ProductCategory | undefined,
      public readonly level: number,
      public readonly name: string,
      public readonly products: Pickable<Client.Product>[],
      public readonly id: number,
    ) {}
  }
}
