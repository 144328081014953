import {
  ComponentRef,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenshotContainerService {
  private _screenShotContainer!: ViewContainerRef;

  /**
   * Sets the internal container in which to create a component.
   * This is set by @see ScreenshotContainerComponent and should not
   * by used by anyone else
   * @param container
   * The ViewContainerRef in which to create components
   */
  public setScreenShotContainer(container: ViewContainerRef) {
    this._screenShotContainer = container;
  }

  /**
   * Creates the component and returns a reference to that and
   * the underlying native DOM HTMLElement.
   * @param componentType
   * The type of component to create.
   * @param initialize
   * Used for initializing the component before it is rendered.
   * @returns
   */
  public createComponent<T>(
    componentType: Type<T>,
    initialize?: (c: T) => void,
  ): [ComponentRef<T>, HTMLElement] {
    this._screenShotContainer.clear();

    const component = this._screenShotContainer.createComponent(componentType);
    if (initialize) initialize(component.instance);

    component.changeDetectorRef.detectChanges();
    return [component, this._screenShotContainer.element.nativeElement];
  }
}
