import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { BaseSnapService } from 'app/ts/services/snap/BaseSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import Enumerable from 'linq';
import { SwingFlexComponent } from 'app/floor-plan-editing/swing-flex/swingFlexImage.component';
/**
 * A snapService for a single item that should snap like a
 * drawer or shelf, with a gable on both sides.
 */
export class SnapLeftRightService extends BaseSnapService<Client.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    if (items.length !== 1) return false;
    let item = items[0];
    return (
      !!item.Product &&
      item.snapLeftAndRight &&
      !item.isCornerDiagonalCut &&
      !item.isCorner2WayFlex
    );
  }

  private readonly possiblePositions: SnapLeftRightService.PossiblePositionInfo[];
  private otherItemCubes: Client.ItemCube[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;
  private hingesCubes: Interface_DTO_Draw.Rectangle[] | undefined;

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);

    let itemCubes: Client.ItemCube[];
    if (cabinetSection.isSwing) {
      itemCubes = cabinetSection.swing.items
        .filter(
          (swingItem) =>
            swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus,
        )
        .concat(cabinetSection.interior.items)
        .map(ConfigurationItemHelper.getItemCube);
    } else if (cabinetSection.isSwingFlex) {
      itemCubes = cabinetSection.swingFlex.items
        .filter(
          (swingItem) =>
            swingItem.ItemType ===
              Interface_Enums.ItemType.SwingFlexCorpusMovable ||
            swingItem.ItemType === Interface_Enums.ItemType.SwingFlexCorpus,
        )
        .filter((swingItem) => !swingItem.isFittingPanel && !swingItem.isGrip)
        .concat(cabinetSection.interior.items)
        .map(ConfigurationItemHelper.getItemCube);
    } else {
      itemCubes = cabinetSection.interior.itemCubes;
    }

    this.otherItemCubes = itemCubes
      .filter((cube) => cube.item !== this.item)
      .filter(
        (cube) =>
          !cube.item.isDummy &&
          !cube.item.isTemplate &&
          !cube.item.isFittingPanel &&
          !cube.item.isVerticalDivider &&
          !cube.item.isGrip,
      )
      .filter((cube) => cube.Z < item.frontZ);
    this.possiblePositions = this.getPossiblePositions();
    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );
  }

  protected overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): Client.SnapInfo | undefined {
    let rawItemPos = VectorHelper.subtract(pos, this.mouseOffset);

    let bestSuggestion = ObjectHelper.best(
      this.possiblePositions.filter(
        (pp) =>
          pp.gableGap.X <= pos.X && pp.gableGap.X + pp.gableGap.Width >= pos.X,
      ),
      (pos) => Math.abs(pos.Y - rawItemPos.Y),
    );

    if (bestSuggestion) {
      if (VectorHelper.dist(pos, bestSuggestion) < Constants.snapDist) {
        let pulloutSeverity = this.item.isPullout
          ? bestSuggestion.pulloutSeverity
          : Enums.PulloutWarningSeverity.None;

        let snapsTo: Client.ConfigurationItem[] = [];
        for (let gable of [
          bestSuggestion.gableGap.leftGable,
          bestSuggestion.gableGap.rightGable,
        ]) {
          if (!!gable) {
            snapsTo.push(gable);
          }
        }

        let result: Client.SnapInfo = {
          dropOffset: VectorHelper.subtract(bestSuggestion, this.item),
          pulloutRestriction: pulloutSeverity,
          rulers: bestSuggestion.rulers,
          suggestedItemOffsets: [], //No suggestions
          newSize: {
            X: bestSuggestion.Width,
            Y: bestSuggestion.Height,
            Z: bestSuggestion.Depth,
          },
          mirror: false,
          collisionAreas: bestSuggestion.collisionAreas,
          horizontalGuidelineHeights: [
            bestSuggestion.Y,
            bestSuggestion.Y + this.item.Height,
          ],
          snapsTo: snapsTo,
          snappingItems: [this.item],
        };

        return result;
      }
    }
    return;
  }

  protected overriddenPlace(
    lastSnapInfo: Client.SnapInfo,
  ): [Client.ConfigurationItem] {
    //do nothing, base service handles everything
    return [this.item];
  }

  private getPossiblePositions(): SnapLeftRightService.PossiblePositionInfo[] {
    let result: SnapLeftRightService.PossiblePositionInfo[] = [];

    let gableGaps = InteriorLogic.getGableGaps(this.cabinetSection, [
      this.item,
    ]);

    let allowAllProducts =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
    let allowAllMaterials =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    let productLineProperties =
      this.cabinetSection.swingFlex.productLineProperties;

    let spaceForBacking = productLineProperties.SwingFlexSpaceForBacking;

    let suitableGaps = gableGaps.filter((gap) =>
      this.item.Product!.isAvailableInWidth(
        gap.Width,
        allowAllProducts,
        this.cabinetSection.cabinet.ProductLineId,
        allowAllMaterials,
        this.item.MaterialId,
      ),
    );

    if (this.item.isPullout) {
      suitableGaps = suitableGaps.filter((gap) =>
        InteriorLogic.supportsPullout(gap),
      );
    }

    if (this.cabinetSection.isSwingFlex && !this.item.isShelf) {
      let fittingPanelGaps = InteriorLogic.getFittingPanelGaps(
        this.cabinetSection,
      );

      let suitableFittingPanelGaps = fittingPanelGaps.filter((gap) =>
        this.item.Product!.isAvailableInWidth(
          gap.Width,
          false,
          Interface_Enums.ProductLineId.SwingFlex22,
          allowAllMaterials,
          this.item.MaterialId,
        ),
      );

      suitableGaps = suitableGaps.concat(suitableFittingPanelGaps);
    }

    if (this.cabinetSection.isSwingFlex) {
      this.hingesCubes = [];

      let hingesItems = Enumerable.from(
        this.cabinetSection.swingFlex.areas,
      ).selectMany((area) => area.hinges);

      hingesItems.forEach((hinge) => {
        this.hingesCubes?.push(this.getHingeItemRectangle(hinge));
      });
    }

    for (let gap of suitableGaps) {
      let otherItemsInLane = this.otherItemCubes.filter((otherItemRect) =>
        VectorHelper.overlapsX(otherItemRect, gap),
      );
      if (this.otherItemCubes.length > 0) {
        otherItemsInLane.push(
          ...this.cabinetSection.interior.boundingBoxes.map<Client.ItemCube>(
            (cube) => {
              return {
                item: this.otherItemCubes[0].item,
                Depth: this.cabinetSection.interior.cube.Depth,
                Height: cube.Height,
                Width: cube.Width,
                X: cube.X,
                Y: cube.Y,
                Z: this.cabinetSection.interior.cube.Z,
              };
            },
          ),
        );
      }
      let otherItemsInLineWithoutHinges = [...otherItemsInLane];

      if (
        this.cabinetSection.isSwingFlex &&
        this.hingesCubes &&
        !this.item.snapDepthMiddle
      ) {
        if (this.otherItemCubes.length > 0) {
          otherItemsInLane.push(
            ...this.hingesCubes.map<Client.ItemCube>((cube) => {
              return {
                item: this.otherItemCubes[0].item,
                Depth: this.cabinetSection.interior.cube.Depth,
                Height: cube.Height,
                Width: cube.Width,
                X: cube.X,
                Y: cube.Y,
                Z: this.cabinetSection.interior.cube.Z,
              };
            }),
          );
        }
      }

      let maxTopY =
        this.cabinetSection.interior.cube.Y +
        this.cabinetSection.interior.cube.Height;
      let backZ = 0;
      let frontZ =
        this.cabinetSection.InteriorDepth + this.cabinetSection.backing.frontZ;

      if (!!gap.leftGable) {
        backZ = Math.max(backZ, gap.leftGable.Z);
        frontZ = Math.min(
          frontZ,
          gap.leftGable.snapDepthRight + gap.leftGable.Z,
        );
        maxTopY = Math.min(maxTopY, gap.leftGable.topY);
      }
      if (!!gap.rightGable) {
        backZ = Math.max(backZ, gap.rightGable.Z);
        frontZ = Math.min(
          frontZ,
          gap.rightGable.snapDepthLeft + gap.rightGable.Z,
        );
        maxTopY = Math.min(maxTopY, gap.rightGable.topY);
      }
      if (!!gap.leftGable && !!gap.rightGable) {
        backZ = Math.min(gap.leftGable.Z, gap.rightGable.Z);
        frontZ = Math.min(
          gap.leftGable.snapDepthRight + gap.leftGable.Z,
          gap.rightGable.snapDepthLeft + gap.rightGable.Z,
        );
      }

      if (frontZ <= backZ) continue;

      if (this.item.drilling600Left || this.item.drilling600Right) {
        //current item is shelf for installing inside module - only snap to special gables
        if (!gap.leftGable || !gap.leftGable.drilling600Right) continue;
        if (!gap.rightGable || !gap.rightGable.drilling600Left) continue;
      } else {
        if (gap.leftGable && !gap.leftGable.drilledRight) continue;
        if (gap.rightGable && !gap.rightGable.drilledLeft) continue;
      }

      // If SwingFlex - check if there is enough space for back panel
      if (this.cabinetSection.isSwingFlex && backZ < spaceForBacking) {
        backZ = spaceForBacking;
      }

      let depth = frontZ - backZ - this.item.depthReduction;
      depth = Math.max(this.item.minDepth, Math.min(this.item.maxDepth, depth));

      let z = frontZ - this.item.depthReduction - depth;
      if (this.item.snapDepthMiddle) {
        z = (frontZ + backZ) / 2 - this.item.Depth / 2;
      }

      for (let drillStop of gap.drillStops) {
        let candCube: Interface_DTO_Draw.Cube = {
          X: gap.X,
          Y: drillStop - this.item.snapOffsetY,
          Z: z,
          Width: gap.Width,
          Height: this.item.Height,
          Depth: depth,
        };

        if (candCube.Y < 0 || candCube.Y + candCube.Height > maxTopY) continue;

        const overlapsMoreThanThreshold = otherItemsInLane.some(
          (otherItemCube) => {
            // External drawers may overlap shelves a bit.
            // The bottom shelf may overlap a different amount, compared to other shelves.
            const thresholdY = this.cabinetSection.swingFlex.getAllowedOverlapY(
              this.item,
              otherItemCube.item,
              candCube.Y,
            );
            return VectorHelper.overlapsMoreThanThreshold(
              candCube,
              otherItemCube,
              0,
              thresholdY,
              0,
            );
          },
        );
        if (overlapsMoreThanThreshold) {
          continue;
        }

        let otherItemsAbove = otherItemsInLineWithoutHinges
          .filter(
            (otherItem) =>
              !otherItem.item.isGrip &&
              otherItem.Y >= candCube.Y + candCube.Height,
          )
          .sort((r1, r2) => r1.Y - r2.Y);
        let otherItemsBelow = otherItemsInLineWithoutHinges
          .filter(
            (otherItem) =>
              !otherItem.item.isGrip &&
              otherItem.Y + otherItem.Height <= candCube.Y,
          )
          .sort((r1, r2) => r2.Y + r2.Height - (r1.Y + r1.Height));
        let itemAbove = otherItemsAbove[0];
        let itemBelow = otherItemsBelow[0];

        let collisionAreas = InteriorLogic.getCollidingCollisionAreas(
          this.item,
          false,
          this.otherItemCubes,
          candCube,
        );
        let rulers = this.getRulers(candCube, otherItemsAbove, otherItemsBelow);

        let candidate: SnapLeftRightService.PossiblePositionInfo = {
          ...candCube,
          pulloutSeverity: gap.pulloutSeverity,
          itemAbove: itemAbove,
          itemBelow: itemBelow,
          collisionAreas: collisionAreas,
          gableGap: gap,
          rulers: rulers,
        };

        result.push(candidate);
      }
    }
    if (App.debug.showSnapInfo) console.debug('possible snap points: ', result);

    if (this.cabinetSection.isSwingFlex) {
      let fittingPanelGaps = result.filter((r) => r.gableGap.isFittingPanel);
      if (fittingPanelGaps.length > 0) {
        for (let index = 0; index < fittingPanelGaps.length; index++) {
          const element = fittingPanelGaps[index];

          let overlappingPositionIndex = result.findIndex(
            (ppi) =>
              !ppi.gableGap.isFittingPanel &&
              VectorHelper.overlaps(ppi, element),
          );
          if (overlappingPositionIndex >= 0) {
            result.splice(overlappingPositionIndex, 1);
            continue;
          }

          let sameStartX = result.findIndex(
            (c) =>
              !c.gableGap.isFittingPanel &&
              c.Y === element.Y &&
              c.X === element.X,
          );
          if (sameStartX > -1) {
            result.splice(sameStartX, 1);
            continue;
          }

          let withSpacerX = result.findIndex(
            (c) =>
              !c.gableGap.isFittingPanel &&
              c.Y === element.Y &&
              Math.abs(element.X - c.X) === 100,
          );
          if (withSpacerX > -1) {
            result.splice(withSpacerX, 1);
            continue;
          }
        }
      }
    }
    return result;
  }

  private getRulers(
    candCube: Interface_DTO_Draw.Rectangle,
    itemsAbove: Interface_DTO_Draw.Rectangle[],
    itemsBelow: Interface_DTO_Draw.Rectangle[],
  ): Client.Ruler[] {
    let rulers = [];
    let rulerX = candCube.X + candCube.Width / 2;

    let itemAbove = itemsAbove[0];
    let distanceAbove =
      (itemAbove ? itemAbove.Y : this.cabinetSection.Height) -
      candCube.Y -
      candCube.Height;
    let distToAboveRuler = new Client.Ruler(
      true,
      {
        X: rulerX,
        Y: candCube.Y + candCube.Height,
      },
      distanceAbove,
      this.cabinetSection.Height,
      false,
    );
    rulers.push(distToAboveRuler);

    if (itemAbove) {
      let nextItemAbove = itemsAbove[1];
      if (nextItemAbove) {
        let nextDistanceAbove =
          nextItemAbove.Y - itemAbove.Y - itemAbove.Height;
        if (nextDistanceAbove === distanceAbove) {
          let distToNextAboveRuler = new Client.Ruler(
            true,
            {
              X: rulerX,
              Y: itemAbove.Y + itemAbove.Height,
            },
            nextDistanceAbove,
            this.cabinetSection.Height,
            false,
          );
          rulers.push(distToNextAboveRuler);
        }
      }
    }

    let itemBelow = itemsBelow[0];
    let distanceBelow =
      candCube.Y -
      (itemBelow
        ? itemBelow.Y + itemBelow.Height
        : this.cabinetSection.sightOffsetY);
    let distToBelowRuler = new Client.Ruler(
      true,
      {
        X: rulerX,
        Y: itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.sightOffsetY,
      },
      candCube.Y -
        (itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.sightOffsetY),
      this.cabinetSection.Height,
      false,
    );
    rulers.push(distToBelowRuler);

    if (itemBelow) {
      let nextItemBelow = itemsBelow[1];
      if (nextItemBelow) {
        let nextDistanceBelow =
          itemBelow.Y - nextItemBelow.Y - nextItemBelow.Height;
        if (nextDistanceBelow === distanceBelow) {
          let distToNextBelowRuler = new Client.Ruler(
            true,
            {
              X: rulerX,
              Y: nextItemBelow.Y + nextItemBelow.Height,
            },
            nextDistanceBelow,
            this.cabinetSection.Height,
            false,
          );
          rulers.push(distToNextBelowRuler);
        }
      }
    }

    return rulers;
  }

  private getHingeItemRectangle(hingeData: {
    hingeType: Enums.SwingFlexHingeType;
    centerX: number;
    centerY: number;
    doorOffset: number;
  }): Interface_DTO_Draw.Rectangle {
    let hinge = new SwingFlexComponent.Hinge(
      hingeData.hingeType,
      hingeData.centerX,
      hingeData.centerY + hingeData.doorOffset,
    );

    let x: Interface_DTO_Draw.Rectangle = {
      X: hinge.x,
      Y: hinge.y,
      Width: hinge.width,
      Height: hinge.height,
    };

    return x;
  }
}

module SnapLeftRightService {
  export interface PossiblePositionInfo extends Interface_DTO_Draw.Cube {
    pulloutSeverity: Enums.PulloutWarningSeverity;
    itemAbove: Interface_DTO_Draw.Rectangle | undefined;
    itemBelow: Interface_DTO_Draw.Rectangle | undefined;
    collisionAreas: Client.CollisionArea[];
    gableGap: Client.GableGap;
    rulers: Client.Ruler[];
  }
}
