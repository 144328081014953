<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('template_save_modal_title', 'Save template')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <div
      class="container-fluid"
      style="display: flex; flex-direction: column; gap: 0.3rem"
    >
      <div class="row">
        <div class="col-md-4 col-name">
          <span
            [textContent]="translate('template_save_modal_name', 'Name')"
          ></span>
        </div>
        <div class="col-md-8">
          <input class="form-control" [(ngModel)]="saveRequest.Name" />
        </div>
      </div>

      <div class="row row-checkbox" *ngIf="showOverwriteTemplate">
        <div class="col-md-4 col-name">
          <span
            [textContent]="translate('template_save_modal_overwrite_template', 'Overwrite existing')"
          ></span>
        </div>
        <div class="col-md-8">
          <input type="checkbox" [(ngModel)]="overwriteTemplate" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-name">
          <span
            [textContent]="translate('template_save_modal_width', 'Width')"
          ></span>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MinWidth" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MaxWidth" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-name">
          <span
            [textContent]="translate('template_save_modal_height', 'Height')"
          ></span>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MinHeight" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MaxHeight" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-name">
          <span
            [textContent]="translate('template_save_modal_depth', 'Depth')"
          ></span>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MinDepth" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" [(ngModel)]="saveRequest.MaxDepth" />
            <span class="input-group-text">mm</span>
          </div>
        </div>
      </div>

      <ng-template [ngIf]="canSaveInCategory">
        <div class="row" *ngFor="let cat of categories; let first=first">
          <div class="col-md-4 col-name">
            <span
              *ngIf="first"
              [textContent]="translate('template_save_modal_categories', 'Categories')"
            ></span>
          </div>
          <div class="col-md-8">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="selectedCategories[cat.key]"
              />
              <span [textContent]="cat.value"></span>
            </label>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary-custom"
        type="submit"
        [disabled]="!canSave"
        (click)="save()"
        [textContent]="translate('button_save', 'Save')"
      ></button>

      <button
        class="btn btn-default"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
