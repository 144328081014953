import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { BaseSnapService } from 'app/ts/services/snap/BaseSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
export class CornerItemSnapService extends BaseSnapService<CornerItemSnapService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    if (items.length !== 1) return false;
    let item = items[0];
    return item.isCorner2WayFlex || item.isCornerDiagonalCut;
  }

  private readonly possibleSnapPositions: CornerItemSnapService.SnapInfo[];

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);
    this.possibleSnapPositions = this.getPossibleSnapPositions();
  }

  protected overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): CornerItemSnapService.SnapInfo | undefined {
    let posOffset = VectorHelper.subtract(pos, this.item);

    let side = pos.X > this.cabinetSection.Width / 2 ? 'right' : 'left';
    let snapPositions = this.possibleSnapPositions.filter(
      (si) => si.cornerSide === side,
    );
    let bestSuggestion = ObjectHelper.best(snapPositions, (snapPos) =>
      Math.abs(snapPos.dropOffset.Y - posOffset.Y),
    );

    return bestSuggestion;
  }

  protected overriddenPlace(
    lastSnapInfo: CornerItemSnapService.SnapInfo,
  ): [Client.ConfigurationItem] {
    if (lastSnapInfo.cornerSide === 'left') {
      //move item to neighboring section so it's easier to handle width2 and depth2
      let leftNeighbor = this.cabinetSection.leftNeighbor;
      if (!leftNeighbor)
        throw new Error(
          "I tried to place a corner item in a corner that doesn't exist",
        );

      this.item.moveToCabinetSection(leftNeighbor); //corner items are always placed in the right side of the corner -
      //this makes them simpler to draw and to collision check
      let itemIndex = this.cabinetSection.interior.items.indexOf(this.item);
      if (itemIndex >= 0) {
        this.cabinetSection.interior.items.splice(itemIndex, 1);
      }

      this.item.X = lastSnapInfo.posX2;
      //switch width2/depth2 with width/depth because item has been moved to left neighbor and rotated
      this.item.width2 = this.item.Width;
      this.item.Width = lastSnapInfo.width2;
      this.item.depth2 = this.item.Depth;
      this.item.Depth = lastSnapInfo.depth2;
    } else {
      let rightNeighbor = this.cabinetSection.rightNeighbor;
      if (!rightNeighbor)
        throw new Error("Where's the right neighbor? It was here a second ago");

      this.item.depth2 = lastSnapInfo.depth2;
      this.item.width2 = lastSnapInfo.width2;
    }
    return [this.item];
  }

  private getPossibleSnapPositions(): CornerItemSnapService.SnapInfo[] {
    let gableGaps = InteriorLogic.getGableGaps(this.cabinetSection, [
      this.item,
    ]);

    const allSnapPositions = this.getSnapPositionsLeft(gableGaps).concat(
      this.getSnapPositionsRight(gableGaps),
    );

    return allSnapPositions;
  }

  private getSnapPositionsRight(
    gableGaps: Client.GableGap[],
  ): CornerItemSnapService.SnapInfo[] {
    let result: CornerItemSnapService.SnapInfo[] = [];

    let neighbor = this.cabinetSection.rightNeighbor;
    if (!neighbor) return result;

    let neighborDepth = neighbor.InteriorDepth - this.item.reductionDepth;
    let neighborWidth = this.item.minWidth;
    {
      //get info for neighbor
      let neighborGaps = InteriorLogic.getGableGaps(neighbor, []);
      let leftGap = neighborGaps.filter((gap) => !gap.leftGable)[0];
      if (!leftGap) return result;
      neighborWidth = Math.max(
        neighborWidth,
        Math.min(leftGap.Width, this.item.maxWidth),
      );
      if (leftGap.rightGable) {
        neighborDepth = leftGap.rightGable.Depth - this.item.reductionDepth;
      }
    }

    let maxDrillStop = 0;
    for (let rightGap of Enumerable.from(gableGaps)
      .where((gap) => !gap.rightGable)
      .orderBy((gap) => gap.Height)
      .toArray()) {
      if (
        rightGap.Width > this.item.maxWidth ||
        rightGap.Width < this.item.minWidth
      )
        continue;

      let width = Math.min(this.item.maxWidth, rightGap.Width);
      let gableDepth = rightGap.leftGable
        ? rightGap.leftGable.Depth
        : this.cabinetSection.InteriorDepth;
      let itemDepth = gableDepth - this.item.depthReduction;

      let maxTopY =
        this.cabinetSection.interior.cube.Y +
        this.cabinetSection.interior.cube.Height;
      if (rightGap.leftGable) {
        maxTopY = Math.min(maxTopY, rightGap.leftGable.topY);
      }

      let rulerX = rightGap.X + rightGap.Width / 2;

      let cornerItems = this.cabinetSection.interior.items.filter(
        (item) => item !== this.item && item.X + item.Width > rightGap.X,
      );

      let cornerSize = Constants.defaultCornerWidth;

      cornerItems = cornerItems.concat(
        ...neighbor.interior.items.filter(
          (neighborItem) =>
            !neighborItem.isGable &&
            neighborItem !== this.item &&
            neighborItem.X < cornerSize,
        ),
      );

      for (let drillStop of rightGap.drillStops) {
        let offsetStop = drillStop - this.item.snapOffsetY;
        if (offsetStop + this.item.Height > maxTopY) {
          continue;
        }

        if (drillStop <= maxDrillStop) continue;
        else maxDrillStop = drillStop;

        let itemsAbove = cornerItems
          .filter((item) => item.Y > offsetStop + this.item.Height)
          .sort((itemA, itemB) => itemA.Y - itemB.Y);
        let itemAbove = itemsAbove[0];
        let rulerTopY = itemAbove ? itemAbove.Y : rightGap.Y + rightGap.Height;

        let itemsBelow = cornerItems
          .filter((item) => item.Y + item.Height < offsetStop)
          .sort(
            (itemA, itemB) =>
              itemB.Y + this.item.Height - (itemA.Y + itemA.Height),
          );
        let itemBelow = itemsBelow[0];
        let rulerBottomY = itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.interior.cube.Y;
        let rulerBelowHeight = offsetStop - rulerBottomY;
        let rulerAboveHeight = rulerTopY - offsetStop - this.item.Height;
        let rulers = [
          new Client.Ruler(
            true,
            { X: rulerX, Y: rulerBottomY },
            rulerBelowHeight,
            this.cabinetSection.Height,
            false,
          ), //bottom ruler
          new Client.Ruler(
            true,
            { X: rulerX, Y: offsetStop + this.item.Height },
            rulerAboveHeight,
            this.cabinetSection.Height,
            false,
          ), //Top ruler
        ];
        if (itemsAbove.length >= 2) {
          //check for extra ruler above
          let distBetweenTopItems = itemsAbove[1].Y - itemsAbove[0].topY;
          if (distBetweenTopItems === rulerAboveHeight) {
            rulers.push(
              new Client.Ruler(
                true,
                { X: rulerX, Y: itemsAbove[0].topY },
                rulerAboveHeight,
                this.cabinetSection.Height,
                false,
              ),
            );
          }
        }
        if (itemsBelow.length >= 2) {
          //check for extra ruler below
          let distBetweenBottomItems = itemsBelow[0].Y - itemsBelow[1].topY;
          if (distBetweenBottomItems === rulerBelowHeight) {
            rulers.push(
              new Client.Ruler(
                true,
                { X: rulerX, Y: itemsBelow[1].topY },
                rulerBelowHeight,
                this.cabinetSection.Height,
                false,
              ),
            );
          }
        }

        result.push({
          mouseSnapArea: {
            X: rightGap.X,
            Y: drillStop,
            Width: width,
            Height:
              this.cabinetSection.cabinet.productLine.GableDrillingSpacing,
          },
          collisionAreas: [],
          cornerSide: 'right',
          depth2: neighborDepth,
          width2: neighborWidth,
          dropOffset: {
            X: rightGap.X - this.item.X,
            Y: offsetStop - this.item.Y,
            Z: 0,
          },
          horizontalGuidelineHeights: [
            offsetStop,
            offsetStop + this.item.Height,
          ],
          mirror: false,
          newSize: { X: width, Z: itemDepth },
          posX2: rightGap.X,
          pulloutRestriction: Enums.PulloutWarningSeverity.None,
          rulers: rulers,
          snapsTo: rightGap.leftGable ? [rightGap.leftGable] : [],
          suggestedItemOffsets: [],
          snappingItems: [this.item],
        });
      }
    }

    return result;
  }

  private getSnapPositionsLeft(
    gableGaps: Client.GableGap[],
  ): CornerItemSnapService.SnapInfo[] {
    let result: CornerItemSnapService.SnapInfo[] = [];
    let neighbor = this.cabinetSection.leftNeighbor;
    if (!neighbor) return result;

    let neighborDepth = neighbor.InteriorDepth;
    let neighborWidth = this.item.minWidth;
    {
      //get info for neighbor
      let neighborGaps = InteriorLogic.getGableGaps(neighbor, []);
      let rightGap = neighborGaps.filter((gap) => !gap.rightGable)[0];
      if (!rightGap) return result;
      neighborWidth = Math.max(
        neighborWidth,
        Math.min(rightGap.Width, this.item.maxWidth),
      );
      if (rightGap.leftGable) {
        neighborDepth = rightGap.leftGable.Depth;
      }
    }
    let neighborPosX =
      neighbor.interior.cube.X + neighbor.interior.cube.Width - neighborWidth;

    let maxDrillStop = 0;
    for (let leftGap of Enumerable.from(gableGaps)
      .where((gap) => !gap.leftGable)
      .orderBy((gap) => gap.Height)
      .toArray()) {
      if (
        leftGap.Width > this.item.maxWidth ||
        leftGap.Width < this.item.minWidth
      )
        continue;

      let width = Math.min(this.item.maxWidth, leftGap.Width);

      let gableDepth = leftGap.rightGable
        ? leftGap.rightGable.Depth
        : this.cabinetSection.InteriorDepth;
      let itemDepth = gableDepth - this.item.depthReduction;

      let maxTopY =
        this.cabinetSection.interior.cube.Y +
        this.cabinetSection.interior.cube.Height;
      if (leftGap.rightGable) {
        maxTopY = Math.min(maxTopY, leftGap.rightGable.topY);
      }

      let rulerX = leftGap.X + leftGap.Width / 2;
      let cornerItems = this.cabinetSection.interior.items.filter(
        (item) => item !== this.item && item.X < leftGap.X + leftGap.Width,
      );

      let cornerWidth = Constants.defaultCornerWidth;

      cornerItems = cornerItems.concat(
        ...neighbor.interior.items.filter(
          (neighborItem) =>
            !neighborItem.isGable &&
            neighborItem !== this.item &&
            neighborItem.rightX + cornerWidth > neighbor!.interior.cube.Width,
        ),
      );

      for (let stop of leftGap.drillStops) {
        let offsetStop = stop - this.item.snapOffsetY;
        if (offsetStop + this.item.Height > maxTopY) {
          continue;
        }

        if (stop <= maxDrillStop) continue;
        else maxDrillStop = stop;

        let itemsAbove = cornerItems
          .filter((item) => item.Y > offsetStop + this.item.Height)
          .sort((itemA, itemB) => itemA.Y - itemB.Y);
        let itemAbove = itemsAbove[0];
        let rulerTopY = itemAbove ? itemAbove.Y : leftGap.Y + leftGap.Height;

        let itemsBelow = cornerItems
          .filter((item) => item.Y + item.Height < offsetStop)
          .sort(
            (itemA, itemB) =>
              itemB.Y + this.item.Height - (itemA.Y + itemA.Height),
          );
        let itemBelow = itemsBelow[0];
        let rulerBottomY = itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.interior.cube.Y;
        let rulerBelowHeight = offsetStop - rulerBottomY;
        let rulerAboveHeight = rulerTopY - offsetStop - this.item.Height;
        let rulers = [
          new Client.Ruler(
            true,
            { X: rulerX, Y: rulerBottomY },
            rulerBelowHeight,
            this.cabinetSection.Height,
            false,
          ), //bottom ruler
          new Client.Ruler(
            true,
            { X: rulerX, Y: offsetStop + this.item.Height },
            rulerAboveHeight,
            this.cabinetSection.Height,
            false,
          ), //Top ruler
        ];
        if (itemsAbove.length >= 2) {
          //check for extra ruler above
          let distBetweenTopItems = itemsAbove[1].Y - itemsAbove[0].topY;
          if (distBetweenTopItems === rulerAboveHeight) {
            rulers.push(
              new Client.Ruler(
                true,
                { X: rulerX, Y: itemsAbove[0].topY },
                rulerAboveHeight,
                this.cabinetSection.Height,
                false,
              ),
            );
          }
        }
        if (itemsBelow.length >= 2) {
          //check for extra ruler below
          let distBetweenBottomItems = itemsBelow[0].Y - itemsBelow[1].topY;
          if (distBetweenBottomItems === rulerBelowHeight) {
            rulers.push(
              new Client.Ruler(
                true,
                { X: rulerX, Y: itemsBelow[1].topY },
                rulerBelowHeight,
                this.cabinetSection.Height,
                false,
              ),
            );
          }
        }

        result.push({
          mouseSnapArea: {
            X: leftGap.X,
            Y: stop,
            Width: width,
            Height:
              this.cabinetSection.cabinet.productLine.GableDrillingSpacing,
          },
          dropOffset: {
            X: leftGap.X - this.item.X,
            Y: offsetStop - this.item.Y,
            Z: 0,
          },
          collisionAreas: [],
          horizontalGuidelineHeights: [
            offsetStop,
            offsetStop + this.item.Height,
          ],
          mirror: false,
          pulloutRestriction: Enums.PulloutWarningSeverity.None,
          rulers: rulers,
          snapsTo: [],
          suggestedItemOffsets: [],
          newSize: { X: width, Z: itemDepth }, //these dimensions should be flipped with depth2/width2 when placed in left side
          depth2: neighborDepth,
          width2: neighborWidth,
          posX2: neighborPosX,
          cornerSide: 'left',
          snappingItems: [this.item],
        });
      }
    }

    return result;
  }

  private static isInLeftCorner(
    item: Client.ConfigurationItem,
    otherSectionDepth: number,
  ): boolean {
    return item.X < otherSectionDepth;
  }

  private static isInRightCorner(
    item: Client.ConfigurationItem,
    otherSectionDepth: number,
  ): boolean {
    return item.rightX > otherSectionDepth;
  }
}

export module CornerItemSnapService {
  export interface SnapInfo extends Client.SnapInfo {
    mouseSnapArea: Interface_DTO_Draw.Rectangle;
    depth2: number;
    width2: number;
    posX2: number;
    cornerSide: 'left' | 'right';
  }
}
