import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/CabinetSection';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { observeNotification } from 'rxjs/internal/Notification';

@Component({
  selector: 'free-space-vm',
  templateUrl: './FreeSpace.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class FreeSpaceVm extends BaseVm implements OnInit {
  public freeSpaceLeft!: number;
  public freeSpaceRight!: number;
  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.freeSpaceLeft = this.cabinetSection!.InteriorFreeSpaceLeft;
    this.freeSpaceRight = this.cabinetSection!.InteriorFreeSpaceRight;
  }

  public ok(): void {
    this.cabinetSection.InteriorFreeSpaceLeft = Number(this.freeSpaceLeft);
    this.cabinetSection.InteriorFreeSpaceRight = Number(this.freeSpaceRight);
    this.activeModal.close(undefined);
  }

  public cancel(): void {
    this.activeModal.dismiss('user pressed cancel');
  }
}
