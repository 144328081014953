<img
  [src]="consumerFile('icons/info.png')"
  class="help-bubble--circle"
  (click)="click($event)"
/>
<div
  class="help-bubble--bubble"
  [ngClass]="{visible: showBubble}"
  (click)="click($event)"
>
  <div class="help-bubble--arrow"></div>
  <div class="help-bubble--content">
    <ng-content></ng-content>
    <h4 *ngIf="header" class="translation" [textContent]="header"></h4>
    <div [textContent]="text" class="translation"></div>
  </div>
</div>
<div
  class="help-bubble--bg"
  [ngClass]="{visible: showBubble}"
  (click)="click($event)"
></div>
