import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NModule } from 'app/i18n/i18n.module';
import { RestoreAutosaveVm } from './RestoreAutosaveVm';
import { TranslatePipe } from 'app/i18n/translate.pipe';
import { NewDeliveryAddressVm } from './NewDeliveryAddressVm';
import { DeliveryAddressEditMenuModule } from '../components/deliveryAddressEditMenu.module';
import { ProjectEditMenuModule } from '../components/projectEditMenu.module';
import { NewProjectVm } from './NewProjectVm';
import { NewCustomerVm } from './NewCustomerVm';
import { CustomerEditModule } from '../components/customer-edit.module';
import { EndUserSetupImportVm } from './EndUserSetupImportVm';
import { PasswordResetRequestVm } from './PasswordResetRequestVm';

@NgModule({
  declarations: [
    RestoreAutosaveVm,
    NewDeliveryAddressVm,
    NewProjectVm,
    NewCustomerVm,
    EndUserSetupImportVm,
    PasswordResetRequestVm,
  ],
  exports: [
    RestoreAutosaveVm,
    NewDeliveryAddressVm,
    NewProjectVm,
    NewCustomerVm,
    EndUserSetupImportVm,
  ],
  providers: [DatePipe, TranslatePipe],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18NModule,
    DeliveryAddressEditMenuModule,
    ProjectEditMenuModule,
    CustomerEditModule,
  ],
})
export class ModalsModule {}
