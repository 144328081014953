import { inject } from '@angular/core';
import { PermissionService } from 'app/identity-and-access/permission.service';

export enum Area {
  ConfirmOrders,
  Customers,
  Projects,
  DeliveryAddresses,
}

export function checkAccess(area: Area) {
  const permissions = inject(PermissionService);

  switch (area) {
    case Area.ConfirmOrders:
      return permissions.canSeeOrders && permissions.canUseOrderConfirmer;
    case Area.Customers:
    case Area.DeliveryAddresses:
    case Area.Projects:
      return permissions.canSeeOrders;
  }

  return false;
}
