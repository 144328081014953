import { EditorAssets, FloorPlan, Product } from 'app/ts/clientDto';
import { FillingId } from '../filling';
import { Door, DoorPlacement, Module, ModuleId } from '../module';
import { PartitionPlan } from '../partition-plan-data.service';
import { Section, SectionId } from '../section';
import { FillingsViewBar } from './bar';
import { FillingsViewFilling } from './filling';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import Enumerable from 'linq';
import { ProfileId } from '../profile';
import { FillingsViewSection } from './section';

export class FillingsViewModule {
  constructor(
    private _moduleId: ModuleId,
    private _posX: number,
    private _fillings: FillingsViewFilling[],
    private _bars: FillingsViewBar[],
    private _product: Product,
    private _width: number,
    private _height: number,
    private _depth: number,
    private _verticalBarOptionId: number,
    private _isDoor: boolean,
    private _positionNo: number,
    private _leftprofileId?: ProfileId | undefined,
    private _rightprofileId?: ProfileId | undefined,

    private _placement?: DoorPlacement,

    private _doorProductData?: Interface_DTO.ProductDoorData,

    private _previousModule?: FillingsViewModule,
    private _nextModule?: FillingsViewModule,
  ) {}

  private _forceFixedBars: boolean = false;
  public get id(): ModuleId {
    return this._moduleId;
  }

  public get posX(): number {
    return this._posX;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get depth(): number {
    return this._depth;
  }

  public get previousModule(): FillingsViewModule | undefined {
    return this._previousModule;
  }

  public set previousModule(value: FillingsViewModule | undefined) {
    this._previousModule = value;
  }

  public get nextModule(): FillingsViewModule | undefined {
    return this._nextModule;
  }

  public set nextModule(value: FillingsViewModule | undefined) {
    this._nextModule = value;
  }

  public get product(): Product {
    return this._product;
  }

  public get verticalBarOption(): Interface_DTO.VariantOption | undefined {
    return this.availableVerticalBarOptions.find(
      (opt) => opt.Id == this._verticalBarOptionId,
    )!;
  }

  public get isDoor(): boolean {
    return this._isDoor;
  }

  public get placement(): DoorPlacement | undefined {
    return this._placement;
  }

  public get positionNo(): number {
    return this._positionNo;
  }

  public get leftProfileId(): ProfileId | undefined {
    return this._leftprofileId;
  }

  public get rightProfileId(): ProfileId | undefined {
    return this._rightprofileId;
  }

  public get fillings(): Readonly<FillingsViewFilling[]> {
    return this._fillings;
  }

  public get bars(): Readonly<FillingsViewBar[]> {
    return this._bars;
  }

  public isDesignTypeAllowedForFilling(
    section: FillingsViewSection,
    fillingId: FillingId,
  ): boolean {
    if (
      !this._doorProductData ||
      !this._doorProductData.Bars ||
      !this._doorProductData.Bars.some(
        (b) =>
          b.BarType === Interface_Enums.BarType.Design &&
          b.Height === section.barHeight,
      )
    ) {
      // There are no design filling bars available
      return false;
    }
    let index = this.fillings.findIndex((fl) => fl.id == fillingId);
    let filling = this.fillings[index];

    if (
      !fillingId ||
      !this._bars ||
      !this._bars.some(
        (b) =>
          b.barType === Interface_Enums.BarType.Design &&
          b.height === filling.height,
      )
    ) {
      return false;
    }

    if (index <= 0 || index >= this.fillings.length - 1) {
      // The first and last fillings may not be design fillings.
      return false;
    }

    if (
      this.fillings[index - 1].isDesignFilling ||
      this.fillings[index + 1].isDesignFilling
    ) {
      // Design fillings are not allowed next to other design fillings
      return false;
    }

    return true;
  }

  public get availableVerticalBarOptions(): Interface_DTO.VariantOption[] {
    let allVariants = Enumerable.from(
      this._product.getVariants(Interface_Enums.ProductLineId.Partition),
    );
    let variant = allVariants.firstOrDefault(
      (v) => v.Number === VariantNumbers.VerticalBarCount,
    );
    if (variant) {
      return variant.VariantOptions;
    }

    return [];
  }

  public get forceFixedBars(): boolean {
    return this._forceFixedBars;
  }

  public set forceFixedBars(value: boolean) {
    this._forceFixedBars = this.mayForceFixedBars && value;
  }

  // TODO this method / functions needs to be reviewed. I'm not sure that is fully matches what is done with doors and I don't know if it needs to.
  public get mayForceFixedBars(): boolean {
    if (
      this._bars &&
      this._bars.some(
        (b) =>
          b.barType === Interface_Enums.BarType.Fixed &&
          b.height === this._height,
      )
    ) {
      return true;
    }

    // TODO update below, I could not find a way at the time to find productlineId, so it got hardcoded to move along.
    // I (TKP) don't think it is configurable as such, remember to check, though, whether it is the same in both
    // prod and test databases.
    if (
      this.product &&
      this.product
        .getVariants(Interface_Enums.ProductLineId.Partition)
        .some((v) => v.Number === VariantNumbers.ForceFixedBars && !v.OnlyAdmin)
    ) {
      return true;
    }

    return false;
  }
}

export function calculateModules(
  selectedSection: SectionId,
  partitionPlan: Readonly<PartitionPlan>,
  floorPlan: FloorPlan,
  doorModuleProductId: number,
  wallModuleProductId: number,
): Readonly<FillingsViewModule[]> {
  let section = partitionPlan.sections.find(
    (sec) => sec.id == selectedSection,
  )!;
  let barData = getBarData(section, floorPlan, doorModuleProductId)!;

  let modules = partitionPlan.modules;

  let mappedModules = modules
    .filter((module) => module.sectionId == selectedSection)
    .map((module) => {
      let fillings = module.fillings.map((fl) => {
        let material = floorPlan.editorAssets.materialsDict[fl.materialId];
        if (module.isWall) {
          return new FillingsViewFilling(
            fl.id,
            module.id,
            material,
            module.posX,
            calculateFillingY(
              module,
              fl.id,
              barData,
              floorPlan,
              wallModuleProductId,
            ),
            fl.width,
            fl.height,
          );
        } else {
          return new FillingsViewFilling(
            fl.id,
            module.id,
            material,
            module.posX,
            calculateFillingY(
              module,
              fl.id,
              barData,
              floorPlan,
              doorModuleProductId,
            ),
            fl.width,
            fl.height,
          );
        }
      });

      let bars = module.bars.map((bar) => {
        return new FillingsViewBar(
          bar.id,
          module.id,
          bar.position,
          bar.bartype,
          bar.width,
          bar.height,
          Module.fillingWidthReduction + Module.fillingInsertionDepth,
        );
      });

      let product =
        floorPlan.editorAssets.productsDict[module.references.productId];
      let doorProductData = Enumerable.from(
        floorPlan.editorAssets.productDoorData,
      ).firstOrDefault((pdd) => pdd.ProductId === module.references.productId);
      let isDoor = module instanceof Door;

      let placement = isDoor ? (module as Door).placement : undefined;

      return new FillingsViewModule(
        module.id,
        module.posX,
        fillings,
        bars,
        product,
        module.width,
        module.height,
        module.depth,
        module.references.verticalBarOptionId,
        isDoor,
        module.positionNo,
        module.references.leftProfileId,
        module.references.rightProfileId,
        placement,
        doorProductData,
      );
    });

  // Map module links
  mappedModules.forEach((md) => {
    let module = modules.find((dmd) => dmd.id == md.id)!;

    let prev = modules.find(
      (dmd) => dmd.id == module.references.previousSectionModuleId,
    );
    if (prev)
      md.previousModule = mappedModules.find((mmd) => mmd.id == prev!.id)!;

    let next = modules.find(
      (dmd) => dmd.id == module.references.nextSectionModuleId,
    );
    if (next) md.nextModule = mappedModules.find((mmd) => mmd.id == next!.id)!;
  });

  return mappedModules;
}

function getBarData(
  section: Section,
  floorPlan: FloorPlan,
  doorModuleProductId: number,
): Interface_DTO.ProductBarData | undefined {
  let fullCatalog =
    floorPlan.editorAssets.fullCatalog &&
    floorPlan.FullCatalogAllowOtherProducts;

  let doorData = Enumerable.from(
    floorPlan.editorAssets.productDoorData,
  ).firstOrDefault((pdd) => pdd.ProductId === doorModuleProductId);
  if (!doorData) return undefined;

  let availableBars = Enumerable.from(doorData.Bars).where(
    (bar) => fullCatalog || !bar.ChainOverride,
  );

  let barHeight = section.barHeight;
  let barType = Interface_Enums.BarType.Fixed; //door.forceFixedBars ? Interface_Enums.BarType.Fixed : Interface_Enums.BarType.Loose;

  // Find the ProductBarData with the right type and height
  return availableBars.firstOrDefault(
    (b) => b.BarType === barType && b.Height === barHeight,
  );
}

function calculateFillingY(
  module: Module,
  fillingId: FillingId,
  barData: Interface_DTO.ProductBarData,
  floorPlan: FloorPlan,
  moduleProductId: number,
): number {
  let sum =
    floorPlan.editorAssets.productDoorData.find(
      (pdd) => pdd.ProductId == moduleProductId,
    )?.FrameWidthTop! - Module.fillingInsertionDepth;
  for (let i = module.fillings.length - 1; i >= 0; i--) {
    let filling = module.fillings[i];
    if (filling.id == fillingId) break;

    sum += filling.height;
    sum += barData.BarMiddel;
  }

  return sum;
}
