import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import * as Client from 'app/ts/clientDto/index';
import { Interior2dVm } from './Interior2dVm';

@Injectable()
export class DragInfoService {
  public get dragInfo():
    | Interior2dVm.ItemDragInfo
    | Interior2dVm.RectangleSelectInfo
    | Interior2dVm.NeighborItemDragPrepareInfo
    | Interior2dVm.NeighborItemDragInfo
    | undefined {
    return this._dragInfo;
  }

  public set dragInfo(
    value:
      | Interior2dVm.ItemDragInfo
      | Interior2dVm.RectangleSelectInfo
      | Interior2dVm.NeighborItemDragPrepareInfo
      | Interior2dVm.NeighborItemDragInfo
      | undefined,
  ) {
    this._dragInfo = value;
  }

  private _dragInfo?:
    | Interior2dVm.ItemDragInfo
    | Interior2dVm.RectangleSelectInfo
    | Interior2dVm.NeighborItemDragPrepareInfo
    | Interior2dVm.NeighborItemDragInfo
    | undefined;

  private _dragItem$ = new BehaviorSubject<Client.ProductDragInfo | undefined>(
    undefined,
  );

  public get dragItem$(): Observable<Client.ProductDragInfo> {
    return this._dragItem$.pipe(
      filter((dragItem) => dragItem != null),
    ) as Observable<Client.ProductDragInfo>;
  }

  public get value(): Client.ProductDragInfo | undefined {
    return this._dragItem$.value;
  }

  public set value(item: Client.ProductDragInfo | undefined) {
    this._dragItem$.next(item);
  }
}
