<div class="input-group">
  <div class="input-group-btn btn-group dropup" ngbDropdown>
    <button
      class="btn btn-default"
      style="border-top-right-radius: 0; border-bottom-right-radius: 0"
      ngbDropdownToggle
      [disabled]="isDisabled"
      type="button"
    >
      <span [textContent]="phonePrefix"></span>
    </button>
    <div
      class="phone-dropdown-menu"
      ngbDropdownMenu
      [ngClass]="{'disabled': isDisabled}"
    >
      <button
        ngbDropdownItem
        *ngFor="let country of countries"
        (click)="phonePrefix = country.PhonePrefix"
        type="button"
      >
        <span [textContent]="country.PhonePrefix"></span>
        (<span [textContent]="country.Name"></span>)
      </button>
      <button
        class="blank"
        ngbDropdownItem
        (click)="phonePrefix = ''"
        type="button"
      >
        <span
          [textContent]="translate('phone_country_prefix_blank_name', 'Other...')"
        ></span>
      </button>
    </div>
  </div>
  <input
    class="form-control form-control-special"
    type="tel"
    maxlength="{{phoneMaxLength}}"
    [disabled]="isDisabled ?? false"
    [required]="required ?? false"
    [(ngModel)]="phoneMain"
  />
</div>
