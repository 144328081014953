import * as Enums from 'app/ts/clientDto/Enums';
import { SwingModuleChange } from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { ItemVariant } from 'app/ts/clientDto/ItemVariant';
import { SwingTemplate } from 'app/ts/clientDto/SwingTemplate';
import { SwingModuleSetup } from 'app/ts/clientDto/SwingModuleSetup';
export class SwingArea {
  constructor() {}

  public justAddedByUser = false;

  public swingTemplate: SwingTemplate | null = null;

  public index: number = -1;
  public moduleNumber: string = '';

  public get module(): SwingModuleSetup | null {
    if (this.swingTemplate == null) return null;
    return this.index === 0
      ? this.swingTemplate.startModule
      : this.swingTemplate.extendModule;
  }

  public desiredInsideWidth: number = -1;

  public hingeSide: Enums.HingeSide = Enums.HingeSide.None;
  public pullOut: Enums.SwingPulloutSingle | Enums.SwingPulloutDouble =
    Enums.SwingPulloutSingle.No;
  public variants: ItemVariant[] = [];

  public desiredChange: SwingModuleChange = SwingModuleChange.None;

  public doorMaterial: Client.Material | null = null;

  public items: Client.ConfigurationItem[] = [];

  public insideRect: Client.Rectangle = new Client.Rectangle();
}
