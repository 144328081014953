import { DestroyRef, inject, Injectable, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveUser } from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingData,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  ActiveStore,
  ActiveStoreInjector,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { HttpParamSerializerService } from 'app/ng/http-param-serializer.service';
import { ModelOptions } from 'app/ng/ModelOptions';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { CustomerBaseVmService } from 'app/ts/services/CustomerBaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { ModalService } from 'app/ts/services/ModalService';
import { ISelectable } from 'app/ts/util/ISelectable';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseCustomer } from 'app/ts/viewmodels/BaseCustomer';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import TopBarInteraction from '../interfaces/TopBarInteraction';

@Injectable()
export abstract class CustomerBaseVm<T>
  extends BaseVm
  implements OnInit, TopBarInteraction
{
  protected readonly modalService: ModalService;
  protected readonly customerService: CustomerService;
  protected readonly $httpParamSerializer: HttpParamSerializerService;

  private _customerSearchParams$ = new BehaviorSubject<CustomerSearchParams>(
    {},
  );
  protected get customerSearchParams$(): Observable<CustomerSearchParams> {
    return this._customerSearchParams$;
  }

  protected _customers$ = new BehaviorSubject<Client.Customer[]>([]);
  protected get customers$(): Observable<Client.Customer[]> {
    return this._customers$;
  }

  activeStore: ActiveStore = inject(ActiveStoreInjector);

  protected get allCustomers() {
    return this._allCustomers;
  }
  private _allCustomers: Client.Customer[] = [];

  private _quickSearchString: string = '';
  private _mineOnly: boolean = false;
  private _statusSearch: 'all' | 'delivered' | 'quote' | 'order' = 'all';
  public lastSearch: CustomerSearchParams = {};

  triggerSearch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public order: string = 'id';
  public reverse = false;
  public filterParams: CustomerSearchParams = {};

  protected clientSettingsValue!: ClientSettingData;
  constructor(
    private readonly customerBaseVmService: CustomerBaseVmService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    protected activeUser: ActiveUser,
    protected clientSettings: ClientSetting,
  ) {
    super(customerBaseVmService.baseVmService);

    super.ensureUnsubscribe(
      clientSettings.subscribe((value) => {
        const triggerSearch: boolean = this.clientSettingsValue !== value;
        this.clientSettingsValue = value;
        if (triggerSearch) this.triggerSearch$.next(true);
      }),
    );

    const snapShot = this.activatedRoute.snapshot.queryParamMap;
    this.filterParams = CustomerSearchParams.FromParams(snapShot);
    this.setFilterProperties(CustomerSearchParams.FromParams(snapShot));

    this.ensureUnsubscribe(
      activatedRoute.queryParamMap.subscribe((params) => {
        const search = CustomerSearchParams.FromParams(params);
        this._customerSearchParams$.next(search);
      }),
    );

    this.ensureUnsubscribe(
      clientSettings.subscribe((value) => (this.clientSettingsValue = value)),
    );

    this.modalService = customerBaseVmService.modalService;
    this.customerService = customerBaseVmService.customerService;
    this.$httpParamSerializer = customerBaseVmService.$httpParamSerializer;

    this.lastSearch = ObjectHelper.copy(this.filterParams);
  }

  ngOnInit(): void {
    combineLatest([
      this.customerService.customers$,
      this.triggerSearch$,
      this.customerSearchParams$,
      this.activeStore.data$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([customers, _, params, __]) => {
        const filteredCustomers = this.customerService.filterCustomers(
          params,
          this.activeUser.data.UserId,
          this.clientSettingsValue.showAllFloorplansOnEmptySearch,
          customers,
        );
        this._customers$.next(filteredCustomers);
        this._allCustomers = customers;
        this.onSearchUpdated(filteredCustomers);
      });

    if (this.filterParams.customerId) {
      let csId = parseInt(this.filterParams.customerId);
      let customer = new BaseCustomer(
        this._allCustomers.find((cs) => cs.Id == csId)!,
      );
      this._customerSearchString = customer.name;
    }
  }
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  protected abstract onSearchUpdated(customers: Client.Customer[]): void;

  private setFilterProperties(searchParams: CustomerSearchParams) {
    this._mineOnly = searchParams.owner == null;
    this._showAll = (searchParams.showAll ?? 'false') == 'true';
    this._quickSearchString = searchParams.quickSearch ?? '';
    this._statusSearch =
      <'all' | 'delivered' | 'quote' | 'order'>this.filterParams.orderStatus ||
      'all';
  }

  public highlightId = null;

  public get showAllFloorplansOnEmptySearch(): boolean {
    return this.clientSettingsValue.showAllFloorplansOnEmptySearch;
  }

  public get searchParamString(): string {
    let searchParamString =
      this.customerBaseVmService.$httpParamSerializer.serialize(
        this.filterParams,
      );
    if (searchParamString !== '') {
      return '?' + searchParamString;
    }
    return '';
  }

  public get quickSearchString(): string {
    if (!this._quickSearchString) return '';
    return this._quickSearchString;
  }

  public set quickSearchString(val: string) {
    this.filterParams.quickSearch = val;
    this._quickSearchString = val;
    this.search();
  }

  public get mineOnly() {
    return this._mineOnly;
  }
  public set mineOnly(value: boolean) {
    this._mineOnly = value;
    this.search();
    (document.activeElement as HTMLElement).blur();
  }

  public get statusSearch(): 'all' | 'delivered' | 'quote' | 'order' {
    return this._statusSearch;
  }
  public set statusSearch(val: 'all' | 'delivered' | 'quote' | 'order') {
    this._statusSearch = val;
    this.search();
    (document.activeElement as HTMLElement).blur();
  }

  public search() {
    const params: CustomerSearchParams = this.filterParams;
    if (this.quickSearchString) {
      params.quickSearch = this.quickSearchString;
    } else {
      delete params.quickSearch;
    }

    if (this.mineOnly) {
      delete params.owner;
    } else {
      params.owner = Constants.overview.anyOwnerSearchString;
    }

    if (this.showAll) {
      params.showAll = 'true';
    }

    if (this.statusSearch && this.statusSearch !== 'all') {
      params.orderStatus = this.statusSearch;
    } else {
      delete params.orderStatus;
    }

    const queryParams = CustomerSearchParams.ToQueryParams(params);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      skipLocationChange: false,
      replaceUrl: false,
      preserveFragment: true,
    });
  }

  public setSelected(obj: DtoContainer<T> & ISelectable) {
    let oldStatus = obj.selected;
    for (let otherObj of this.objects) {
      otherObj.selected = false;
    }
    obj.selected = !oldStatus;
    this.updateHash();
  }

  public get selectedObjects() {
    return this.objects.filter((obj) => obj.selected);
  }

  public get displayEditSingle() {
    return this.selectedObjects.length === 1;
  }

  public closeRightMenu() {
    for (let obj of this.objects) obj.selected = false;
    this.updateHash();
  }

  public resetSesarch() {
    this._quickSearchString = '';
    this._customerSearchString = '';
    this._mineOnly = true;
    this._statusSearch = 'all';
    delete this.filterParams.customerId;
    this.search();
    try {
      (<HTMLInputElement>(
        document.getElementById('customer-quicksearch')
      )).focus();
    } catch (e: any) {
      /*Do nothing*/
    }
  }

  protected updateQueryParams() {
    // let url = new URL(window.location.toString())
    let url = window.location.pathname + '?';
    let params = CustomerSearchParams.ToQueryParams(this.filterParams);
    for (let param of Object.keys(params)) {
      url += `${param}=${params[param]}&`;
    }

    history.pushState({}, '', url.substring(0, url.length - 1));
  }

  protected updateHash() {
    var ids = this.selectedObjects.map((o) =>
      this.getId(o.dtoObject).toString(),
    );
    this.filterParams.selected = ids;

    this.updateQueryParams();
  }

  protected get hashIds(): { [id: number]: true } {
    const result: { [id: number]: true } = {};
    this.filterParams.selected?.map((ns) => {
      const n = parseInt(ns);
      result[n] = true;
    });
    return result;
  }

  public readonly quickSearchModelOptions: ModelOptions = {
    updateOn: 'change',
  };

  public get isUpdatingCustomers(): boolean {
    return this.customerService.isUpdatingCustomers;
  }

  public async reload() {
    await this.customerService.updateCustomerList(true);
  }

  public abstract objects: (DtoContainer<T> & ISelectable)[];

  protected abstract getId(obj: T): number;

  private _customerSearchString: string = '';

  public customerSuggestions: BaseCustomer[] = [];

  private _customerSuggestionsOpen = false;
  public get customerSuggestionsOpen() {
    return this.customerSuggestions.length > 0 && this._customerSuggestionsOpen;
  }

  public set customerSuggestionsOpen(val: boolean) {
    this._customerSuggestionsOpen = val;
  }

  public get showAll(): boolean {
    return this._showAll;
  }

  public set showAll(val: boolean) {
    this._quickSearchString = '';
    this._customerSearchString = '';
    delete this.filterParams.customerId;
    this._mineOnly = true;
    this._statusSearch = 'all';
    this._showAll = val;

    this.search();
    (document.activeElement as HTMLElement).blur();
  }
  private _showAll = false;

  public get customerSearchString() {
    if (this.showAll) {
      return this.quickSearchString;
    } else {
      return this._customerSearchString;
    }
  }

  public set customerSearchString(val: string) {
    if (this.showAll) {
      this.quickSearchString = val;
      return;
    } else {
      this._customerSearchString = val;
      this.updateCustomerSuggestions();
      this.customerSuggestionsOpen = true;
    }
  }

  private updateCustomerSuggestions() {
    if (!this.customerSearchString || this.showAll) {
      this.customerSuggestions = [];
      return;
    }

    let maxSuggestions = 25;

    let suggestions: BaseCustomer[] = [];
    let searchLowerCase = this.customerSearchString
      .toLocaleLowerCase()
      .replace(/[\,\.\-\_\|\$\+\?\/]+/g, ' ') //replace special characters with space
      .split(/\s+/g) //split on spaces
      .filter((word) => word !== '');
    let strictMatchedCustomerIds: { [id: number]: true } = {};

    for (let customer of this._allCustomers) {
      if (this.customerMatch(customer, searchLowerCase, true)) {
        suggestions.push(new BaseCustomer(customer));
        strictMatchedCustomerIds[customer.Id] = true;
        if (suggestions.length >= maxSuggestions) break;
      }
    }
    if (suggestions.length < maxSuggestions) {
      for (let customer of this._allCustomers) {
        if (
          this.customerMatch(customer, searchLowerCase, false) &&
          !strictMatchedCustomerIds[customer.Id]
        ) {
          suggestions.push(new BaseCustomer(customer));
          if (suggestions.length >= maxSuggestions) break;
        }
      }
    }

    this.customerSuggestions = suggestions;
  }

  private customerMatch(
    customer: Client.Customer,
    searchWords: string[],
    strict: boolean,
  ) {
    let nonStrictMatch = (a: string, b: string) =>
      a.toLocaleLowerCase().indexOf(b) >= 0;
    let strictMatch = (a: string, b: string) =>
      a.toLocaleLowerCase().indexOf(b) === 0;
    let matchWords = [
      customer.Name,
      customer.Address1,
      customer.Email,
      customer.Phone,
      customer.DebitorNo,
    ];

    let orderNumbers = Enumerable.from(customer.Projects)
      .selectMany((proj) => proj.DeliveryAddresses)
      .selectMany((da) => da.FloorPlans)
      .select((fpo) => fpo.OrderNo)
      .where((orderNo) => !!orderNo)
      .toArray();

    matchWords.push(...orderNumbers);

    //all words should be matched
    wordSearch: for (let i = 0; i < searchWords.length; i++) {
      let searchWord = searchWords[i];
      //only the first word should be strictly matched
      let match = strict && i === 0 ? strictMatch : nonStrictMatch;

      for (let matchWord of matchWords) {
        if (match(matchWord, searchWord)) continue wordSearch; //searchWord was found, continue with the next searchWord
      }

      //the searchword was not found in any matchWords - customer doesn't match
      return false;
    }
    return true;
  }

  public customerSearchFocus() {
    window.setTimeout(() => (this.customerSuggestionsOpen = true), 10);
  }

  public setSelectedCustomer(val: BaseCustomer) {
    this._customerSearchString = val.name;
    this.customerSuggestionsOpen = false;
    this.filterParams.customerId = '' + val.id;
    this._mineOnly = false;
    this.search();
  }
}
