import { Component, Input } from '@angular/core';
import * as DTO from 'app/ts/interface_dto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DetailEditVm } from 'app/ts/viewmodels/components/DetailEditVm';

@Component({
  selector: 'project-edit',
  templateUrl: './projectEdit.html',
  styleUrls: [
    '../../../../style/rightMenu.scss',
    '../../../../style/modal.scss',
  ],
})
export class ProjectEditVm extends DetailEditVm<DTO.Project> {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  @Input()
  public get detailObject(): DTO.Project {
    return this._detailObject;
  }
  public set detailObject(value: DTO.Project | null) {
    if (value == null) return;
    this._detailObject = value;
  }
}
