import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NModule } from 'app/i18n/i18n.module';
import { LoginComponent } from './login.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { UserPanelData } from './user-panel/user-panel.data';
import { PermissionService } from './permission.service';
import { TranslatePipe } from 'app/i18n/translate.pipe';
import { AppVersionModule } from 'app/ts/viewmodels/components/app-version.module';
import { UserMessageService } from 'app/user-messages/user-message.service';

@NgModule({
  declarations: [LoginComponent, UserPanelComponent],
  exports: [LoginComponent, UserPanelComponent],
  providers: [
    PermissionService,
    UserPanelData,
    TranslatePipe,
    UserMessageService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppVersionModule,
    NgbDropdownModule,

    I18NModule,
  ],
})
export class IdentityAndAccessModule {}
