import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'folder',
  templateUrl: './folder.html',
  styleUrls: ['../../../../style/linkBrowser.scss'],
})
export class FolderVm extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  @Input()
  public folder!: FolderShow;

  @Input()
  public set startFolder(folder: Client.Folder) {
    this.folder = this.copyFolder(folder);
  }

  @Input()
  public selectedFolder?: Client.Folder;

  @Output()
  folderClicked: EventEmitter<Client.Folder> =
    new EventEmitter<Client.Folder>();

  public click(event: MouseEvent, folder: FolderShow) {
    event.preventDefault();
    this.folderClicked.emit(folder.folder);

    this.selectedFolder = folder.folder;
    folder.isOpen = !folder.isOpen;
  }

  subFolderClicked(folder: Client.Folder) {
    this.folderClicked.emit(folder);
  }

  private copyFolder(folder: Client.Folder): FolderShow {
    var folderShow = new FolderShow();
    folderShow.folder = folder;
    folderShow.Subfolders = folder.SubFolders.map((f) => this.copyFolder(f));
    return folderShow;
  }
}

class FolderShow {
  public get Name(): string {
    return this.folder.Name;
  }

  public folder!: Client.Folder;
  public isOpen: boolean = false;

  public Subfolders!: FolderShow[];
}
