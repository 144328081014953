import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { RecalculationQuestion } from 'app/ts/clientDto/RecalculationMessage';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { DoorHelper } from 'app/ts/util/DoorHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DoorLogic {
  public static readonly Name = 'doorLogic';

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly translationService: TranslationService,
  ) {}

  public accessTest() {
    return 'Door logic access test function';
  }

  public recalculate(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let messages: Client.RecalculationMessage[] = [];

    if (section.CabinetType === Interface_Enums.CabinetType.Swing) {
      // For swing cabinets, doors are handled elsewhere.
      return messages;
    }

    if (App.debug.showTimings) console.time('Recalculate Doors');

    let editorAssets = section.editorAssets;

    messages.push(...this.removeUnknownProducts(section));

    // Ensure we recalculate everything.
    section.doors.clearBackingVariables();

    // Ensure all values are set and valid
    this.ensureValuesAreValid(section);

    // The actual calculations start here...

    this.calculateDoorSubSection(section, editorAssets, messages);

    this.calculateIndividualDoors(section, editorAssets, messages);

    this.generateConfigurationItems(section);

    this.roundIntegerValues(section);

    // Update cabinet setion values
    this.updateCabinetSectionDto(section);

    if (App.debug.showTimings) console.timeEnd('Recalculate Doors');

    return messages;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let messages: Client.RecalculationMessage[] = [];

    section.doors.pulloutWarningAreas =
      CabinetSectionHelper.getPulloutWarningAreas(section);

    return messages;
  }

  private removeUnknownProducts(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let removedItems = ConfigurationItemHelper.removeItemsWithoutProducts(
      section.doors.doorItems,
    );
    removedItems.push(
      ...ConfigurationItemHelper.removeItemsWithoutProducts(
        section.doors.railItems,
      ),
    );

    if (removedItems.length > 0) {
      return [
        {
          cabinetSection: section,
          editorSection: Enums.EditorSection.Doors,
          severity: Enums.RecalculationMessageSeverity.Warning,
          key: 'door_items_removed_discontinued',
          defaultValue:
            'Some door items are discontinued and have been removed. Please ensure the solution is as expected.',
        },
      ];
    } else {
      return [];
    }
  }

  //#region Validation of values set by user

  private ensureValuesAreValid(section: Client.CabinetSection) {
    if (
      section.editorAssets.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ForceOnlyDoors
      ] === '1' &&
      section.CabinetType === Interface_Enums.CabinetType.Doors
    ) {
      section.doors.onlyDoor = true;
    }

    section.ExtraRailWidthLeft = Math.floor(section.ExtraRailWidthLeft);
    section.ExtraRailWidthRight = Math.floor(section.ExtraRailWidthRight);

    if (!this.isProfileValid(section.doors)) {
      this.setDefaultProfile(section.doors);
    }

    if (!this.isProfileMaterialValid(section.doors)) {
      this.setDefaultProfileMaterial(section.doors);
    }

    if (!this.isRailsetValid(section.doors)) {
      this.setDefaultRailset(section.doors);
    }

    if (!this.isRailsetMaterialValid(section.doors, true)) {
      this.setDefaultRailsetMaterial(section.doors, true);
    }
    if (!this.isRailsetMaterialValid(section.doors, false)) {
      this.setDefaultRailsetMaterial(section.doors, false);
    }

    if (section.doors.mustCalculateOptimalExtraRailWidths) {
      this.setOptimalExtraRailWidths(section);
    }

    if (!this.isNumberOfDoorsValid(section.doors)) {
      this.setDefaultNumberOfDoors(section.doors);
    }

    if (!this.isNumberOfOverlapsValid(section.doors)) {
      this.setDefaultNumberOfOverlaps(section.doors);
    }

    if (
      !this.isOverlapWidthValid(section.doors) ||
      section.doors.mustResetOverlapWidth
    ) {
      this.setDefaultOverlapWidth(section.doors);
      section.doors.mustResetOverlapWidth = false;
    }

    if (!this.isBarHeightValid(section.doors)) {
      this.setDefaultBarHeight(section.doors);
    }

    if (!this.isSingleRailDoorWidthValid(section.doors)) {
      this.setDefaultSingleRailDoorWidth(section.doors);
    }

    if (!this.isSingleRailDoorPositionValid(section.doors)) {
      this.setDefaultSingleRailDoorPosition(section.doors);
    }

    // Individual doors
    section.doors.doors.forEach((door) => {
      if (!this.isVerticalBarOptionValid(door)) {
        this.setDefaultVerticalBarOption(door);
      }

      if (!this.isForceFixedBarsValid(door)) {
        this.setDefaultForceFixedBars(door);
      }
    });
  }

  private isProfileValid(doors: Client.Doors): boolean {
    if (doors.profile !== null) {
      let id = doors.profile.Id;
      if (
        !doors.pickableProfiles.some((p) => p.item !== null && p.item.Id === id)
      ) {
        return false;
      }
    }

    return true;
  }
  private setDefaultProfile(doors: Client.Doors) {
    let pickableProfile = Enumerable.from(
      doors.pickableProfiles,
    ).firstOrDefault((pm) => pm.disabledReason === null);
    doors.profile = pickableProfile ? pickableProfile.item : null;
  }

  private isProfileMaterialValid(doors: Client.Doors): boolean {
    if (doors.profileMaterial === null) {
      return doors.profile === null;
    } else {
      let id = doors.profileMaterial.Id;
      if (!doors.profile) return false;
      return doors.profile.materials.some(
        (profileMaterial) => profileMaterial.Id === id,
      );
      //return doors.pickableProfileMaterials.some(pm => pm.item !== null && pm.item.Id === id);
    }
  }
  private setDefaultProfileMaterial(doors: Client.Doors) {
    // Find pickables, that actually have a material
    let pickableMaterials = Enumerable.from(
      doors.pickableProfileMaterials,
    ).where((pm) => pm.item !== null);

    // Sort the materials, and take the first one
    doors.profileMaterial =
      pickableMaterials
        .select((pm) => pm.item)
        .orderBy((m) => m!.SortOrder)
        .firstOrDefault() ?? null;
  }

  private isRailsetValid(doors: Client.Doors): boolean {
    if (doors.railSet !== null) {
      let id = doors.railSet.Id;
      if (doors.availableRailsets.some((rs) => rs.Id === id)) {
        return true;
      }
    }

    return doors.profile === null;
  }
  private setDefaultRailset(doors: Client.Doors) {
    let pickableRailset = Enumerable.from(
      doors.pickableRailsets,
    ).firstOrDefault((prs) => prs.disabledReason === null);
    doors.railSet = pickableRailset ? pickableRailset.item : null;
  }

  private isRailsetMaterialValid(doors: Client.Doors, top: boolean): boolean {
    let railsetMaterial = top
      ? doors.railMaterialTop
      : doors.railMaterialBottom;
    if (railsetMaterial === null) {
      return doors.railSet === null;
    } else {
      let id = railsetMaterial.Id;
      let availableRailMaterials = top
        ? doors.availableRailTopMaterials
        : doors.availableRailBottomMaterials;
      return availableRailMaterials.some(
        (railMaterial) => railMaterial.Id === id,
      );
    }
  }
  private setDefaultRailsetMaterial(doors: Client.Doors, top: boolean) {
    let availableRailMaterials = top
      ? doors.pickableRailTopMaterials
      : doors.pickableRailBottomMaterials;

    // Find pickables, that actually have a material
    let pickableMaterials = Enumerable.from(availableRailMaterials).where(
      (pm) => pm.item !== null,
    );

    // Sort the materials, and take the first one
    let railsetMaterial = pickableMaterials
      .select((pm) => pm.item)
      .orderBy((m) => m!.SortOrder)
      .firstOrDefault();
    if (top) {
      doors.railMaterialTop = railsetMaterial ?? null;
    } else {
      doors.railMaterialBottom = railsetMaterial ?? null;
    }
  }

  private isNumberOfDoorsValid(doors: Client.Doors): boolean {
    return doors.availableNumberOfDoors.some(
      (num) => num === doors.numberOfDoors,
    );
  }
  private setDefaultNumberOfDoors(doors: Client.Doors) {
    doors.numberOfDoors = doors.optimalNumberOfDoors;
  }

  private isNumberOfOverlapsValid(doors: Client.Doors): boolean {
    return doors.availableNumberOfOverlaps.some(
      (num) => num === doors.numberOfOverlaps,
    );
  }
  private setDefaultNumberOfOverlaps(doors: Client.Doors) {
    doors.numberOfOverlaps = doors.optimalNumberOfOverlaps;
  }

  private isOverlapWidthValid(doors: Client.Doors): boolean {
    return (
      doors.overlapWidth >= doors.overlapWidthMin &&
      doors.overlapWidth <= doors.overlapWidthMax
    );
  }
  private setDefaultOverlapWidth(doors: Client.Doors) {
    doors.overlapWidth = doors.overlapWidthDefault;
  }

  private isBarHeightValid(doors: Client.Doors): boolean {
    if (doors.availableBars.length > 0) {
      return (
        doors.barHeight !== undefined &&
        doors.availableBars.some((bh) => bh.height === doors.barHeight)
      );
    } else {
      return doors.barHeight === undefined;
    }
  }
  private setDefaultBarHeight(doors: Client.Doors) {
    if (doors.availableBars.length > 0) {
      doors.desiredBarHeight = doors.availableBars[0].height;
    } else {
      doors.desiredBarHeight = undefined;
    }
  }

  private isSingleRailDoorWidthValid(doors: Client.Doors) {
    if (doors.numberOfRailTracks !== 1 || doors.onlyDoor) {
      return true;
    }

    return (
      doors.extraRailWidthRight >= doors.extraRailWidthRightMin &&
      doors.extraRailWidthRight <= doors.extraRailWidthRightMax
    );
  }
  private setDefaultSingleRailDoorWidth(doors: Client.Doors) {
    doors.extraRailWidthRight = ObjectHelper.clamp(
      doors.extraRailWidthRightMin,
      doors.extraRailWidthRight,
      doors.extraRailWidthRightMax,
    );
  }

  private isSingleRailDoorPositionValid(doors: Client.Doors) {
    if (doors.numberOfRailTracks !== 1) {
      return true;
    }

    return (
      doors.extraRailWidthLeft >= doors.extraRailWidthLeftMin &&
      doors.extraRailWidthLeft <= doors.extraRailWidthLeftMax
    );
  }
  private setDefaultSingleRailDoorPosition(doors: Client.Doors) {
    doors.extraRailWidthLeft = ObjectHelper.clamp(
      doors.extraRailWidthLeftMin,
      doors.extraRailWidthLeft,
      doors.extraRailWidthLeftMax,
    );
  }

  private isVerticalBarOptionValid(door: Client.Door) {
    return door.verticalBarOption === undefined || door.mayHaveVerticalBars;
  }
  private setDefaultVerticalBarOption(door: Client.Door) {
    door.verticalBarOption = undefined;
  }

  private isForceFixedBarsValid(door: Client.Door) {
    if (door.numberOfVerticalBars > 0) {
      return door.forceFixedBars;
    }
    return !door.forceFixedBars || door.mayForceFixedBars;
  }
  private setDefaultForceFixedBars(door: Client.Door) {
    door.forceFixedBars = door.numberOfVerticalBars > 0;
  }

  //#endregion Validation of values set by user

  //#region Calculations, door subsection

  private calculateDoorSubSection(
    section: Client.CabinetSection,
    editorAssets: Client.EditorAssets,
    messages: Client.RecalculationMessage[],
  ) {
    if (section.doors.mustCalculateOptimalExtraRailWidths) {
      this.setOptimalExtraRailWidths(section);
    }

    this.setAmountOfDoors(section, editorAssets, messages);

    if (section.doors.numberOfDoors <= 0) {
      // If there are no doors, do a cleanup of the door subsection...

      section.doors.mustResetOverlapPosition = true;
      section.doors.cornerMoulding = null;

      return;
    }

    this.setDoorHeight(section, messages);
    this.setBarHeight(section, messages);

    this.calculateOverlaps(section, messages);
    this.setDoorWidths(section, editorAssets.doorWidths);
    this.calculateOverlapPositionRestrictions(section, editorAssets, messages);
    this.setOverlapWidth(section);
    this.setDoorPositions(section);

    this.copyDoor(section);
    this.setCornerMoulding(section);
  }

  /**
   * Calculates and set the optimal extra rail widths
   * @param section
   * @param editorAssets
   */
  private setOptimalExtraRailWidths(section: Client.CabinetSection) {
    if (!section.doors.mustCalculateOptimalExtraRailWidths) {
      return;
    }

    section.doors.mustCalculateOptimalExtraRailWidths = false;

    if (!section.doors.railSet) {
      section.doors.extraRailWidthLeft = 0;
      section.doors.extraRailWidthRight = 0;
    } else if (section.doors.onlyDoor) {
      section.doors.extraRailWidthLeft = 0;
      section.doors.extraRailWidthRight = section.SightWidth;
    } else {
      if (section.doors.railSet.NumberOfTracks === 1) {
        if (section.NumberOfDoors <= 1) {
          section.doors.extraRailWidthRight =
            CabinetSectionHelper.getRailWidth(section) / 2;
          section.doors.extraRailWidthLeft =
            CabinetSectionHelper.getRailWidth(section) / 2;
        } else {
          section.doors.extraRailWidthRight =
            CabinetSectionHelper.getRailWidth(section) / 2;
          section.doors.extraRailWidthLeft =
            CabinetSectionHelper.getRailWidth(section) / 4;
        }
      } else {
        section.doors.extraRailWidthLeft = 0;
        section.doors.extraRailWidthRight = 0;
      }
    }
  }

  /**
   * Ensures that the actual amount of doors match the desired number of doors.
   * @param doors
   */
  private setAmountOfDoors(
    section: Client.CabinetSection,
    editorAssets: Client.EditorAssets,
    messages: Client.RecalculationMessage[],
  ) {
    let doorSubSection = section.doors;
    if (!doorSubSection.profile) {
      return;
    }

    if (
      !section.doors.mustResetOverlapPosition &&
      doorSubSection.doors.length !== doorSubSection.numberOfDoors
    ) {
      section.doors.mustResetOverlapPosition = true;

      if (section.doors.manualOverlapPositioning) {
        section.doors.manualOverlapPositioning = false;

        let txt = 'The number of doors were changed.';
        txt +=
          '\r\nThe door widths and positions have been reset the their defaults.';
        messages.push({
          key: 'DoorOverlapsReset_CauseNumberOfDoors',
          defaultValue: txt,
          severity: Enums.RecalculationMessageSeverity.Warning,
        });
      } else if (
        doorSubSection.doors.length > 0 &&
        !doorSubSection.suppressNumDoorsChangeWarning
      ) {
        messages.push({
          key: 'NumberOfDoorsReset',
          defaultValue:
            'The number of doors has changed.' +
            '\r\nThe selected number of doors is no longer possible. The number has been reset to {0}',
          params: ['' + doorSubSection.numberOfDoors],
          severity: Enums.RecalculationMessageSeverity.Warning,
          cabinetSection: section,
          editorSection: Enums.EditorSection.Doors,
        });
      }
    }

    section.doors.suppressNumDoorsChangeWarning = false;

    // Remove door(s) if there are too many
    while (doorSubSection.doors.length > doorSubSection.numberOfDoors) {
      doorSubSection.doors.splice(doorSubSection.doors.length - 1, 1);
    }

    // Add door(s) if there are not enough
    while (doorSubSection.doors.length < doorSubSection.numberOfDoors) {
      let newDoor = this.copyDoor2(
        doorSubSection.doors[doorSubSection.doors.length - 1],
        section,
      );
      doorSubSection.doors.push(newDoor);
    }
  }

  /**
   * Sets the height of all doors, according to sight height and required space for the rails.
   * @param section
   */
  private setDoorHeight(
    section: Client.CabinetSection,
    messages: Client.RecalculationMessage[],
  ) {
    let doorSubSection = section.doors;
    if (!doorSubSection.profile) {
      return;
    }

    let railSpaceTop = doorSubSection.railSet
      ? doorSubSection.railSet.DoorReductionTop
      : 0;
    railSpaceTop += doorSubSection.doorData
      ? doorSubSection.doorData.HeightReductionTop
      : 0;

    let railSpaceBottom = doorSubSection.railSet
      ? doorSubSection.railSet.DoorReductionBottom
      : 0;
    railSpaceBottom += doorSubSection.doorData
      ? doorSubSection.doorData.HeightReductionBottom
      : 0;

    let newDoorHeight =
      section.corpus.sightHeight - (railSpaceTop + railSpaceBottom);
    if (doorSubSection.height !== newDoorHeight) {
      // If there are any doors, that have more than one filling, and don't have a bar design
      //          => ask if they should be spread evenly
      if (
        section.doors.doors.some((d) => d.numberOfFillings > 1 && !d.barDesign)
      ) {
        messages.push(this.getQuestionSpreadEvenly(section));
      }

      // If the doors have grips => ask if they should be centered vertically
      if (section.doors.grip) {
        messages.push(this.getQuestionCenterGrips(section));
      }
    }

    doorSubSection.height = newDoorHeight;
    doorSubSection.doors.forEach((door) => (door.height = newDoorHeight));

    //reset the bar design on all doors with a bar design
    for (let door of section.doors.doors) {
      if (!door.barDesign) {
        continue;
      }
      door.setBarDesign(door.barDesign);
    }
  }

  /**
   * Sets the height of all bars.
   * @param section
   */
  private setBarHeight(
    section: Client.CabinetSection,
    messages: Client.RecalculationMessage[],
  ) {
    let doorSubSection = section.doors;
    if (!doorSubSection.profile) {
      return;
    }

    if (doorSubSection.barHeight !== doorSubSection.desiredBarHeight) {
      // If there are any doors, that have more than one filling, and don't have a bar design
      //          => ask if they should be spread evenly
      if (
        section.doors.doors.some((d) => d.numberOfFillings > 1 && !d.barDesign)
      ) {
        messages.push(this.getQuestionSpreadEvenly(section));
      }
    }

    doorSubSection.barHeight = doorSubSection.desiredBarHeight;
  }

  private calculateOverlaps(
    section: Client.CabinetSection,
    messages: Client.RecalculationMessage[],
  ) {
    if (
      section.doors.mustResetOverlapPosition ||
      !section.doors.manualOverlapPositioning
    ) {
      section.doors.mustResetOverlapPosition = false;

      section.doors.overlaps = new Array<Client.DoorOverlap>();

      // If there are not at least two doors, there are no overlaps
      if (section.NumberOfDoors < 2) {
        return;
      }

      // Find left and right positions of door opening
      let posX = CabinetSectionHelper.getFirstDoorPositionX(section);

      let useWideDoors =
        section.editorAssets.fullCatalog &&
        section.cabinet.floorPlan.FullCatalogWideDoors;

      // Calculate door width
      let optimalDoorWidth = 0;
      if (section.doors.profile) {
        let tempDoorWidth =
          (section.corpus.sightWidth +
            section.doors.numberOfOverlaps * section.doors.overlapWidth) /
          section.doors.numberOfDoors;
        if (
          useWideDoors ||
          ProductHelper.supportsWidth(section.doors.profile, tempDoorWidth)
        ) {
          optimalDoorWidth = tempDoorWidth;
        }
      }

      // Loop through all places, where doors meet... ;-)
      let doorSetup = section.doors.doorSetup;
      for (let i = 1; i < doorSetup.railPositions.length; i++) {
        posX += optimalDoorWidth;

        let isReal = true;
        if (doorSetup.railPositions[i] === doorSetup.railPositions[i - 1]) {
          // Doors are on the same tracks, and they do NOT actually overlap
          // We add a "fake" overlap, to be used when adjusting door widths
          isReal = false;
        } else {
          posX -= section.doors.overlapWidth / 2;
        }

        section.doors.overlaps.push(
          new Client.DoorOverlap(
            posX,
            section.doors.overlapWidth,
            i - 1,
            isReal,
          ),
        );

        if (isReal) {
          posX -= section.doors.overlapWidth / 2;
        }
      }
    } else {
      for (var i = 0; i < section.doors.overlaps.length; i++) {
        let overlap = section.doors.overlaps[i];

        if (overlap.desiredPositionCenter < overlap.positionMin)
          overlap.desiredPositionCenter = overlap.positionMin;

        if (overlap.desiredPositionCenter > overlap.positionMax)
          overlap.desiredPositionCenter = overlap.positionMax;

        if (overlap.desiredPositionCenter !== overlap.positionCenter)
          overlap.positionCenter = overlap.desiredPositionCenter;

        overlap.width = section.doors.overlapWidth;
      }
    }
  }

  /**
   * Adjusts the width of the doors, according to sight width.
   * @param doors
   */
  private setDoorWidths(
    section: Client.CabinetSection,
    doorWidths: Interface_DTO.DoorWidth[],
  ) {
    let doorSubSection = section.doors;
    if (!doorSubSection.profile) {
      return;
    }

    // Standard doors only
    if (
      doorSubSection.profile.ProductType ===
      Interface_Enums.ProductType.DoorStandard
    ) {
      let absoluteMaxDoorWidth =
        (section.corpus.sightWidth +
          doorSubSection.numberOfOverlaps * doorSubSection.overlapWidthMin) /
        doorSubSection.numberOfDoors;
      let doorWidth = Enumerable.from(doorWidths)
        .where((dw) => dw.IsStandardDoor && dw.Width <= absoluteMaxDoorWidth)
        .orderBy((dw) => dw.Width)
        .lastOrDefault();
      if (doorWidth !== null) {
        doorSubSection.doors.forEach((door) => (door.width = doorWidth!.Width));
      }
    }
    // ... or all but standard doors
    else {
      // Find left and right positions of door opening
      let posX = CabinetSectionHelper.getFirstDoorPositionX(section);
      let maxPosX = posX + section.corpus.sightWidth;

      for (let i = 0; i < section.doors.doors.length; i++) {
        let door = section.doors.doors[i];
        let doorStart = posX;

        if (i < section.doors.overlaps.length) {
          let overlap = section.doors.overlaps[i];
          posX = overlap.isReal
            ? overlap.positionRight
            : overlap.positionCenter;

          door.width = posX - doorStart;

          if (overlap.isReal) posX -= overlap.width; // Adjust the start point for the next door
        } else {
          door.width = maxPosX - doorStart;
        }
      }
    }
  }

  private calculateOverlapPositionRestrictions(
    section: Client.CabinetSection,
    editorAssets: Client.EditorAssets,
    messages: Client.RecalculationMessage[],
  ) {
    if (!section.doors.profile) {
      // || section.NumberOfDoors !== section.doors.overlaps.length + 1) {
      return;
    }
    let useWideDoors =
      section.editorAssets.fullCatalog &&
      section.cabinet.floorPlan.FullCatalogWideDoors;

    let minDoorWidth = ProductHelper.minWidth(section.doors.profile);
    let maxDoorWidth = useWideDoors
      ? Number.MAX_VALUE
      : ProductHelper.maxWidth(section.doors.profile);

    for (var i = 0; i < section.doors.overlaps.length; i++) {
      let doorLeft = section.doors.doors[i];
      let doorRight = section.doors.doors[i + 1];

      // Possible move left
      let maxMoveLeft1 = doorLeft.width - minDoorWidth;
      let maxMoveLeft2 = maxDoorWidth - doorRight.width;
      let possibleMoveLeft = Math.min(maxMoveLeft1, maxMoveLeft2);

      // Possible move right
      let maxMoveRight1 = maxDoorWidth - doorLeft.width;
      let maxMoveRight2 = doorRight.width - minDoorWidth;
      let possibleMoveRight = Math.min(maxMoveRight1, maxMoveRight2);

      // Update min and max overlap positions
      let overlap = section.doors.overlaps[i];
      overlap.positionMin = overlap.positionCenter - possibleMoveLeft;
      overlap.positionMax = overlap.positionCenter + possibleMoveRight;

      overlap.widthLeftMin = Math.ceil(doorLeft.width - possibleMoveLeft);
      overlap.widthLeftMax = Math.floor(doorLeft.width + possibleMoveRight);

      overlap.widthRightMin = Math.ceil(doorRight.width - possibleMoveRight);
      overlap.widthRightMax = Math.floor(doorRight.width + possibleMoveLeft);

      if (overlap.desiredPositionCenter < overlap.positionMin)
        overlap.desiredPositionCenter = overlap.positionMin;

      if (overlap.desiredPositionCenter > overlap.positionMax)
        overlap.desiredPositionCenter = overlap.positionMax;

      if (overlap.desiredPositionCenter !== overlap.positionCenter) {
        overlap.positionCenter = overlap.desiredPositionCenter;
        this.setDoorWidths(section, editorAssets.doorWidths);

        if (section.doors.manualOverlapPositioning) {
          let txt = 'The overlap position was invalid.';
          txt += '\r\nThe door widths and overlap positions were recalculated.';
          messages.push({
            key: 'DoorWidthsRecalculated_CauseOverlapPosition',
            defaultValue: txt,
            severity: Enums.RecalculationMessageSeverity.Info,
          });
        }
      }

      overlap.setDoorWidths(doorLeft.width, doorRight.width);
    }
  }

  /**
   * Sets the overlap width based on sight width and total door width.
   * @param section
   */
  private setOverlapWidth(section: Client.CabinetSection) {
    let doorSubSection = section.doors;
    if (!doorSubSection.profile) {
      return;
    }

    let totalDoorWidth = 0;
    for (let door of doorSubSection.doors) {
      totalDoorWidth += door.width;
    }

    let newOverlapWidth =
      (totalDoorWidth - section.corpus.sightWidth) /
      doorSubSection.numberOfOverlaps;
    if (isNaN(newOverlapWidth)) newOverlapWidth = 0;
    doorSubSection.overlapWidth = newOverlapWidth;
  }

  /**
   * Update positions on all doors
   * @param section
   */
  private setDoorPositions(section: Client.CabinetSection) {
    // Get door setup
    let doorSetup = section.doors.doorSetup;

    // Door positions
    let doorPositions = CabinetSectionHelper.getDoorPositions(section);

    // Find door position Y
    let corpusSpaceBottom = section.corpus.heightBottom;
    let railSpaceBottom = section.doors.railSet
      ? section.doors.railSet.DoorReductionBottom
      : 0;
    railSpaceBottom += section.doors.doorData
      ? section.doors.doorData.HeightReductionBottom
      : 0;
    let posY = corpusSpaceBottom + railSpaceBottom;

    for (let i = 0; i < doorSetup.railPositions.length; i++) {
      let door = section.doors.doors[i];
      let railPos = doorSetup.railPositions[i];

      // Set previously calculated position X
      door.position.X = doorPositions[i];

      // Set the same position Y for all doors
      door.position.Y = posY;

      // Find door position Z
      let trackCenterOffset = CabinetSectionHelper.getRailTrackCenterOffsetZ(
        section,
        railPos,
      );
      let posZ = section.Depth - trackCenterOffset - door.depth / 2;
      door.position.Z = posZ;
    }
  }

  /**
   * Copies values from one door to all other doors
   * @param section
   */
  private copyDoor(section: Client.CabinetSection) {
    let donor = section.cabinet.donorDoor;
    if (!donor) {
      return;
    }

    for (
      let doorNumber = 0;
      doorNumber < section.doors.doors.length;
      doorNumber++
    ) {
      let door = section.doors.doors[doorNumber];

      // Do not process the donor door
      if (door === donor) {
        continue;
      }

      // Number of fillings
      door.numberOfFillings = donor.numberOfFillings;
      door.forceFixedBars = donor.forceFixedBars;
      door.verticalBarOption = donor.verticalBarOption;
      this.setNumberOfFillings(door);
      door.mustSpreadBarsEvenly = false;

      // Filling materials + design or normal
      for (
        let fillingNumber = 0;
        fillingNumber < donor.fillings.length;
        fillingNumber++
      ) {
        let donorFilling = donor.fillings[fillingNumber];
        let filling = door.fillings[fillingNumber];

        filling.materialNumber = donorFilling.materialNumber;
        filling.isDesignFilling = donorFilling.isDesignFilling;
      }

      // Bar positions
      for (let barNumber = 0; barNumber < donor.bars.length; barNumber++) {
        let donorBar = donor.bars[barNumber];
        let bar = door.bars[barNumber];

        bar.position = donorBar.position;
        bar.clearDesired();
      }

      door.mustSpreadBarsEvenly = false;
    }
  }

  private copyDoor2(
    donor: Client.Door | undefined,
    cabinetSection: Client.CabinetSection,
  ): Client.Door {
    const door = new Client.Door(
      cabinetSection.doors,
      cabinetSection.editorAssets,
    );
    if (!donor) return door;

    // Number of fillings
    door.numberOfFillings = donor.numberOfFillings;
    door.forceFixedBars = donor.forceFixedBars;
    door.verticalBarOption = donor.verticalBarOption;
    this.setNumberOfFillings(door);
    door.mustSpreadBarsEvenly = false;

    // Filling materials + design or normal
    for (
      let fillingNumber = 0;
      fillingNumber < donor.fillings.length;
      fillingNumber++
    ) {
      let donorFilling = donor.fillings[fillingNumber];
      let filling = door.fillings[fillingNumber];

      filling.materialNumber = donorFilling.materialNumber;
      filling.isDesignFilling = donorFilling.isDesignFilling;
    }

    // Bar positions
    for (let barNumber = 0; barNumber < donor.bars.length; barNumber++) {
      let donorBar = donor.bars[barNumber];
      let bar = door.bars[barNumber];

      bar.position = donorBar.position;
      bar.clearDesired();
    }

    door.mustSpreadBarsEvenly = false;
    return door;
  }

  /**
   * Creates the corner moulding for corner cabinets. (Sets it to null for non corner cabinets)
   * @param section
   */
  private setCornerMoulding(section: Client.CabinetSection) {
    section.doors.cornerMoulding = null;

    if (
      section.doors.doors.length <= 0 ||
      (!section.rightNeighbor && !section.leftNeighbor)
    ) {
      return;
    }

    let cornerMouldingProduct = section.doors.cornerMouldingProduct;
    let cornerMouldingMaterial = this.getCornerMouldingMaterial(section);

    if (cornerMouldingProduct) {
      let moulding = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Door,
        cornerMouldingProduct.Id,
        cornerMouldingMaterial ? cornerMouldingMaterial.Id : null,
        section,
      );

      if (!!section.rightNeighbor) {
        this.setMouldingPositionAndDimensions(section, moulding, false);
        section.doors.cornerMoulding = moulding;
      } else if (!!section.leftNeighbor) {
        this.setMouldingPositionAndDimensions(section, moulding, true);
        section.doors.cornerMoulding = moulding;
      }
    }
  }

  private setMouldingPositionAndDimensions(
    section: Client.CabinetSection,
    moulding: Client.ConfigurationItem,
    leftDoor: boolean,
  ) {
    let door = leftDoor
      ? section.doors.doors[0]
      : section.doors.doors[section.doors.doors.length - 1];

    moulding.Width = door.depth;
    moulding.Height =
      door.height -
      CabinetSectionHelper.getCornerMouldingHeightReduction(section);
    moulding.Depth = door.depth;
    moulding.X = door.position.X + (leftDoor ? -moulding.Width : door.width);
    moulding.Y = door.position.Y;
    moulding.Z = door.position.Z;
  }

  /**
   * Gets the best material for the corner moulding.
   * @param section
   */
  private getCornerMouldingMaterial(
    section: Client.CabinetSection,
  ): Interface_DTO.Material | null {
    let availableMaterials = Enumerable.from(
      section.doors.availableCornerMouldingMaterials,
    )
      .orderBy((mat) => mat.groupSortOrder)
      .thenBy((mat) => mat.SortOrder);

    let material: Interface_DTO.Material | null | undefined = null;

    if (section.doors.railMaterialTop) {
      // Try the same material as the top rail
      var temp = section.doors.railMaterialTop;
      material = availableMaterials.firstOrDefault(
        (mat) => mat.Number === temp.Number,
      );
    }
    if (!material && section.doors.railMaterialBottom) {
      // Try the same material as the top rail
      var temp = section.doors.railMaterialBottom;
      material = availableMaterials.firstOrDefault(
        (mat) => mat.Number === temp.Number,
      );
    }
    if (!material && section.doors.profileMaterial) {
      // Try the same material as the profile
      var temp = section.doors.profileMaterial;
      material = availableMaterials.firstOrDefault(
        (mat) => mat.Number === temp.Number,
      );
    }

    if (!material)
      // Try the default material
      material = availableMaterials.firstOrDefault(
        (mat) => mat.Number === Constants.defaultCornerMouldingMaterialNumber,
      );

    if (!material)
      // Take the first available material
      material = availableMaterials.firstOrDefault();

    return material ?? null;
  }

  //#endregion Calculations, door subsection

  //#region Calculations, individual doors

  private calculateIndividualDoors(
    section: Client.CabinetSection,
    editorAssets: Client.EditorAssets,
    messages: Client.RecalculationMessage[],
  ) {
    for (let door of section.doors.doors) {
      this.setNumberOfFillings(door);

      this.ensureFillingsAreValid(
        door,
        messages,
        editorAssets.fullCatalog &&
          section.cabinet.floorPlan.FullCatalogAllowOtherProducts,
      );
      this.resetFillingMaterialChanged(door);

      door.calculateMinMaxNumberOfFillings();
      this.setNumberOfFillings(door);

      if (door.mustSpreadBarsEvenly) {
        this.spreadFillingsEvenly(door);
      } else {
        this.calculateBars(door);
      }

      this.calculateFillings(door);
      this.calculateProfiles(door);

      this.calculateGrips(door);

      this.calculateVerticalBars(door);
    }
  }

  /**
   *
   * @param door
   */
  private setNumberOfFillings(door: Client.Door) {
    // Ensure number of fillings is valid.
    this.ensureNumberOfFillingsIsValid(door);

    // If the number of fillings is correct, we do not need to do anything here.
    if (door.fillings.length === door.numberOfFillings) {
      return;
    }

    // Add or remove fillings and bars until the number of fillings is correct.
    while (door.fillings.length > door.numberOfFillings) {
      this.removeLastFillingAndBar(door);
    }
    while (door.fillings.length < door.numberOfFillings) {
      this.addFillingAndBar(door);
    }

    // Spread fillings evenly
    door.mustSpreadBarsEvenly = true;
  }

  /**
   * Ensures that the number of fillings is not lower than minimum and not higher than maximum.
   * @param door
   */
  private ensureNumberOfFillingsIsValid(door: Client.Door) {
    if (door.numberOfFillings < door.numberOfFillingsMin) {
      door.numberOfFillings = door.numberOfFillingsMin;
    }
    if (door.numberOfFillings > door.numberOfFillingsMax) {
      door.numberOfFillings = door.numberOfFillingsMax;
    }
  }

  /**
   * Removes the last filling and the last bar from a door.
   * @param door
   */
  private removeLastFillingAndBar(door: Client.Door) {
    door.fillings.splice(door.fillings.length - 1);
    door.bars.splice(door.bars.length - 1);
  }

  /**
   * Adds a filling and a bar to a door
   * @param door
   */
  private addFillingAndBar(door: Client.Door): boolean {
    let material = DoorHelper.getNextFillingMaterial(door);
    if (!material) {
      return false;
    }

    // Add filling
    let newFilling = new Client.DoorFilling(door, door.getAssets());
    newFilling.material = material;
    door.fillings.push(newFilling);

    // Add bar
    if (door.fillings.length > 1) {
      door.bars.push(new Client.DoorBar(door));
    }

    return true;
  }

  /**
   * Ensures that all fillings are valid.
   * @param door
   */
  private ensureFillingsAreValid(
    door: Client.Door,
    messages: Client.RecalculationMessage[],
    fullCatalog: boolean,
  ) {
    let doorData = door.doorData;
    if (!doorData || !doorData.Bars) {
      return;
    }

    let fillings = Enumerable.from(door.fillings);

    let hasMultipleMaterials =
      fillings
        .select((f) => f.materialNumber)
        .distinct()
        .count() > 1;
    let hasDesignFilling = fillings.any((f) => f.isDesignFilling);
    let designFillingsAllowed = doorData.Bars.some((b) => {
      if (b.BarType !== Interface_Enums.BarType.Design) return false;
      if (b.Height !== door.doorSubSection.barHeight) return false;
      if (!fullCatalog) {
        if (b.ChainOverride) return false;
      }
      return true;
    });
    let fixedBarsAllowed = doorData.Bars.some((b) => {
      if (b.BarType !== Interface_Enums.BarType.Fixed) return false;
      if (b.Height !== door.doorSubSection.barHeight) return false;
      if (!fullCatalog) {
        if (b.ChainOverride) return false;
      }
      return true;
    });

    if (
      hasMultipleMaterials &&
      !fixedBarsAllowed &&
      (!hasDesignFilling || !designFillingsAllowed)
    ) {
      // With neether design fillings nor fixed bars, multiple filling materials are not allowed.
      let fillingMaterialChanged = fillings.any(
        (f) => f.fillingMaterialChanged,
      );
      fillings.forEach(
        (filling) => (filling.materialNumber = door.fallBackMaterial.Number),
      );

      // Add information for the user, as to why the fillings were changed
      if (hasDesignFilling && !designFillingsAllowed) {
        let txt = 'The setup contained design fillings.';
        txt += '\r\nThe selected bar height does not support design fillings.';
        txt +=
          '\r\nAll filling materials have been changed to the material of the bottom filling.';
        messages.push({
          key: 'BarHeightNoDesignFillings',
          defaultValue: txt,
          severity: Enums.RecalculationMessageSeverity.Warning,
        });
      } else if (!fillingMaterialChanged) {
        let txt = 'The setup contained different filling materials.';
        txt +=
          '\r\nThe selected bar height does not support different filling materials.';
        txt +=
          '\r\nAll filling materials have been changed to the same material.';
        messages.push({
          key: 'BarHeightNoMultipleFillingMaterials',
          defaultValue: txt,
          severity: Enums.RecalculationMessageSeverity.Warning,
        });
      }
    }

    // Handle designfillings
    if (hasDesignFilling) {
      /**
       * Sets a fillings material to non design. If possible with the same material, else default material.
       * @param filling
       */
      let setNonDesignMaterial = (filling: Client.DoorFilling) => {
        filling.isDesignFilling = false;

        let doorProduct = door.doorSubSection.profile;
        if (doorProduct) {
          let sameNonDesignPossible = doorProduct.PossibleMaterialIds.some(
            (matId) =>
              matId.Id === filling.materialId &&
              (matId.DesignType === Interface_Enums.MaterialDesignType.Normal ||
                matId.DesignType ===
                  Interface_Enums.MaterialDesignType.DesignAndNormal),
          );
          if (!sameNonDesignPossible) {
            // The same material does not exist as non design, so we need to change it to the default material.
            filling.material = DoorHelper.getDefaultFillingMaterial(door);

            //// Add information for the user, as to why the filling material was changed
            //let txt = "CHANGE THIS TEXT.";
            //txt += "\r\nThe selected bar height does not support design fillings.";
            //txt += "\r\nAll filling materials have been changed to the material of the bottom filling.";
            //messages.push({
            //    key: "BarHeightNoDEsignFillings",
            //    defaultValue: txt,
            //    severity: Enums.RecalculationMessageSeverity.Warning
            //});
          }
        }
      };

      for (var i = 0; i < door.fillings.length; i++) {
        let filling = door.fillings[i];
        if (!filling.isDesignFilling) {
          // It is not a designfilling, so we move on to the next filling
          continue;
        }

        // If designfillings are not allowed at all, change it now.
        if (!designFillingsAllowed) {
          setNonDesignMaterial(filling);
          continue;
        }

        // Okay, so designfillings are allowed. Now make sure it has a non-design filling on both sides
        // Handle the first filling
        if (i === 0) {
          setNonDesignMaterial(filling);
          continue;
        }

        // Handle the last filling
        if (i === door.fillings.length - 1) {
          setNonDesignMaterial(filling);
          continue;
        }

        // Handle all other fillings
        if (door.fillings[i - 1].isDesignFilling) {
          setNonDesignMaterial(filling);
          continue;
        }
      }
    }

    door.setFallBackMaterial(undefined);
  }

  private resetFillingMaterialChanged(door: Client.Door) {
    door.fillings.forEach((f) => (f.fillingMaterialChanged = false));
  }

  /**
   * Resize and reposition fillings, so that all non design fillings are the same (visual) height.
   * @param door
   */
  private spreadFillingsEvenly(door: Client.Door) {
    // If there is only one filling, there is no need to calculate anything
    if (door.fillings.length <= 1) {
      return;
    }

    let totalVisibleSpaceFlex = DoorHelper.getTotalVisualFillingHeight(door);

    //#region Special handling of design fillings

    let flexibleFillingCount = door.fillings.length;
    for (let i = 0; i < door.fillings.length; i++) {
      let currentFilling = door.fillings[i];

      if (currentFilling.isDesignFilling) {
        flexibleFillingCount--;

        currentFilling.height = Constants.designFillingHeight;

        let barDeductionBelow = door.bars[i - 1].barTop;
        let barDeductionAbove = door.bars[i].barBottom;
        let deductionTotal =
          currentFilling.height - (barDeductionBelow + barDeductionAbove);

        totalVisibleSpaceFlex -= deductionTotal;
      }
    }

    //#endregion Special handling of design fillings

    let visibleHeightSingle = totalVisibleSpaceFlex / flexibleFillingCount;

    for (let i = 0; i < door.bars.length; i++) {
      let currentBar = door.bars[i];

      if (door.fillings[i].isDesignFilling) {
        // Use the physical filling height to calculate the bar position
        let previousBar = door.bars[i - 1];
        let fillingPosition =
          previousBar.position + previousBar.barBottom + previousBar.barMiddle;
        let newBarPosition =
          fillingPosition +
          Constants.designFillingHeight -
          currentBar.barBottom;

        currentBar.position = newBarPosition;
        currentBar.clearDesired();
      } else {
        // Use the visible filling height to calculate the bar position
        let topPrevious =
          i === 0
            ? DoorHelper.getFrameSize(
                door,
                Interface_Enums.Direction.Bottom,
                false,
              )
            : door.bars[i - 1].position + door.bars[i - 1].height;

        let newBarPosition = topPrevious + visibleHeightSingle;

        currentBar.position = newBarPosition;
        currentBar.clearDesired();
      }
    }

    door.mustSpreadBarsEvenly = false;
  }

  /**
   * Calculates the correct positions of the bars
   * @param door
   */
  private calculateBars(door: Client.Door) {
    for (let i = 0; i < door.bars.length; i++) {
      let bar = door.bars[i];

      bar.position = bar.desiredPosition;

      // If an adjacent filling is a design filling, ensure the bar on the other side of the filling is positioned correctly.

      // Filling below
      if (door.fillings[i].isDesignFilling) {
        let otherBar = door.bars[i - 1];
        let fillingPositionTop = bar.position + bar.barBottom;
        let fillingPositionBottom =
          fillingPositionTop - Constants.designFillingHeight;
        let newPos =
          fillingPositionBottom - (otherBar.barMiddle + otherBar.barBottom);
        otherBar.position = newPos;
        otherBar.desiredPosition = newPos;
      }

      // Filling above
      if (door.fillings[i + 1].isDesignFilling) {
        let otherBar = door.bars[i + 1];
        let fillingPositionBottom =
          bar.position + bar.barBottom + bar.barMiddle;
        let fillingPositionTop =
          fillingPositionBottom + Constants.designFillingHeight;
        let newPos = fillingPositionTop - otherBar.barBottom;
        otherBar.position = newPos;
        otherBar.desiredPosition = newPos;
      }

      bar.clearDesired();
    }
  }

  /**
   * Calculates the sizes and positions of the fillings, based on bar positions
   * @param door
   */
  private calculateFillings(door: Client.Door) {
    for (let i = 0; i < door.fillings.length; i++) {
      // Position
      let bottomY =
        i === 0
          ? DoorHelper.getFrameSize(
              door,
              Interface_Enums.Direction.Bottom,
              true,
            )
          : door.bars[i - 1].position +
            door.bars[i - 1].barBottom +
            door.bars[i - 1].barMiddle;

      // PositionTop
      let topY =
        i === door.fillings.length - 1
          ? door.height -
            DoorHelper.getFrameSize(door, Interface_Enums.Direction.Top, true)
          : door.bars[i].position + door.bars[i].barBottom;

      // Height
      let height = topY - bottomY;

      // Set values on filling
      let currentFilling = door.fillings[i];
      currentFilling.positionY = bottomY;
      currentFilling.height = height;
    }
  }

  private calculateProfiles(door: Client.Door) {
    door.profiles.length = 0;

    if (door.frameHeightTop > 3) {
      let top = new Client.DoorProfile(door, Enums.DoorProfilePosition.Top);
      top.position = {
        X: door.frameWidthLeft,
        Y: door.height - door.frameHeightTop,
        Z: 0,
      };
      top.size = {
        X: door.width - door.frameWidthLeft - door.frameWidthRight,
        Y: door.frameHeightTop,
        Z: door.depth,
      };

      door.profiles.push(top);
    }

    if (door.frameHeightBottom > 3) {
      let bottom = new Client.DoorProfile(
        door,
        Enums.DoorProfilePosition.Bottom,
      );
      bottom.position = {
        X: door.frameWidthLeft,
        Y: 0,
        Z: 0,
      };
      bottom.size = {
        X: door.width - door.frameWidthLeft - door.frameWidthRight,
        Y: door.frameHeightBottom,
        Z: door.depth,
      };

      door.profiles.push(bottom);
    }

    if (door.frameWidthLeft > 3) {
      let left = new Client.DoorProfile(door, Enums.DoorProfilePosition.Left);
      left.position = {
        X: 0,
        Y: 0,
        Z: 0,
      };
      left.size = {
        X: door.frameWidthLeft,
        Y: door.height,
        Z: door.depth,
      };

      door.profiles.push(left);
    }

    if (door.frameWidthRight > 3) {
      let right = new Client.DoorProfile(door, Enums.DoorProfilePosition.Right);
      right.position = {
        X: door.width - door.frameWidthRight,
        Y: 0,
        Z: 0,
      };
      right.size = {
        X: door.frameWidthRight,
        Y: door.height,
        Z: door.depth,
      };

      door.profiles.push(right);
    }
  }

  private calculateGrips(door: Client.Door) {
    if (!door.gripsAvailable) {
      door.gripPlacementFront = undefined;
      door.gripPlacementBack = undefined;
    }

    if (door.mustCenterGrips) {
      // Center all grips vertically
      door.doorSubSection.gripPlacementHeight =
        door.doorSubSection.defaultGripPlacementHeight;
      door.mustCenterGrips = false;
    }

    // Reset existing grip items
    door.gripItemsFront.length = 0;
    door.gripItemsBack.length = 0;

    if (door.doorSubSection.grip) {
      let gripId = door.doorSubSection.grip.Id;
      let doorId = door.doorSubSection.profile!.Id;
      let gripMapItem = Enumerable.from(
        door.doorSubSection.editorAssets.doorGripItems,
      ).single(
        (mapItem) =>
          mapItem.DoorProductId === doorId && mapItem.GripProductId === gripId,
      );

      let gripWidth = ProductHelper.defaultWidth(door.doorSubSection.grip);
      let gripHeight = ProductHelper.defaultHeight(door.doorSubSection.grip);
      let gripDepth = ProductHelper.defaultDepth(
        door.doorSubSection.grip,
        door.doorSubSection.cabinetSection.cabinet.ProductLineId,
      );
      let reductionDepth =
        ProductHelper.reductionDepth(door.doorSubSection.grip) +
        gripMapItem.OffsetZ;

      let gripY = door.doorSubSection.gripPlacementHeight - gripHeight / 2;

      // Front grip(s)
      if (!!door.gripPlacementFront) {
        // Left front grip
        if (
          door.gripPlacementFront.Number === VariantNumbers.Values.Grip_Left ||
          door.gripPlacementFront.Number === VariantNumbers.Values.Grip_Both
        ) {
          let gripItem: Client.ConfigurationItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Door,
              door.doorSubSection.grip.Id,
              door.doorSubSection.profileMaterial
                ? door.doorSubSection.profileMaterial.Id
                : null,
              door.doorSubSection.cabinetSection,
            );
          door.gripItemsFront.push(gripItem);

          // Set dimensions
          gripItem.Height = gripHeight;
          gripItem.Width = gripWidth;
          gripItem.Depth = gripDepth;

          // Set position
          gripItem.X = gripMapItem.OffsetX - gripWidth / 2;
          gripItem.Y = gripY + gripMapItem.OffsetY;
          gripItem.Z = door.depth - reductionDepth;
        }

        // Right front grip
        if (
          door.gripPlacementFront.Number === VariantNumbers.Values.Grip_Right ||
          door.gripPlacementFront.Number === VariantNumbers.Values.Grip_Both
        ) {
          let gripItem: Client.ConfigurationItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Door,
              door.doorSubSection.grip.Id,
              door.doorSubSection.profileMaterial
                ? door.doorSubSection.profileMaterial.Id
                : null,
              door.doorSubSection.cabinetSection,
            );
          door.gripItemsFront.push(gripItem);

          // Set dimensions
          gripItem.Height = gripHeight;
          gripItem.Width = gripWidth;
          gripItem.Depth = gripDepth;

          // Set position
          gripItem.X = door.width - gripMapItem.OffsetX - gripWidth / 2;
          gripItem.Y = gripY + gripMapItem.OffsetY;
          gripItem.Z = door.depth - reductionDepth;

          gripItem.isMirrored = true;
        }
      }

      // Back grip(s)
      if (!!door.gripPlacementBack) {
        // Left back grip
        if (
          door.gripPlacementBack.Number === VariantNumbers.Values.Grip_Left ||
          door.gripPlacementBack.Number === VariantNumbers.Values.Grip_Both
        ) {
          let gripItem: Client.ConfigurationItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Door,
              door.doorSubSection.grip.Id,
              door.doorSubSection.profileMaterial
                ? door.doorSubSection.profileMaterial.Id
                : null,
              door.doorSubSection.cabinetSection,
            );
          door.gripItemsBack.push(gripItem);

          // Set dimensions
          gripItem.Height = gripHeight;
          gripItem.Width = gripWidth;
          gripItem.Depth = gripDepth;

          // Set position
          gripItem.X = gripMapItem.OffsetX - gripWidth / 2 + gripWidth;
          gripItem.Y = gripY + gripMapItem.OffsetY;
          gripItem.Z = reductionDepth;

          gripItem.isMirrored = true;
        }

        // Right back grip
        if (
          door.gripPlacementBack.Number === VariantNumbers.Values.Grip_Right ||
          door.gripPlacementBack.Number === VariantNumbers.Values.Grip_Both
        ) {
          let gripItem: Client.ConfigurationItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Door,
              door.doorSubSection.grip.Id,
              door.doorSubSection.profileMaterial
                ? door.doorSubSection.profileMaterial.Id
                : null,
              door.doorSubSection.cabinetSection,
            );
          door.gripItemsBack.push(gripItem);

          // Set dimensions
          gripItem.Height = gripHeight;
          gripItem.Width = gripWidth;
          gripItem.Depth = gripDepth;

          // Set position
          gripItem.X =
            door.width - gripMapItem.OffsetX - gripWidth / 2 + gripWidth;
          gripItem.Y = gripY + gripMapItem.OffsetY;
          gripItem.Z = reductionDepth;
        }
      }
    }
  }

  private calculateVerticalBars(door: Client.Door) {
    door.verticalBars.length = 0;

    if (door.numberOfVerticalBars > 0) {
      let fillingLeft = door.frameWidthLeft;
      let fillingRight = door.width - door.frameWidthRight;
      let totalFillingWidth = fillingRight - fillingLeft;
      let barWidth = new Client.DoorBarVertical(door).width;
      let totalBarWidth = barWidth * door.numberOfVerticalBars;
      let fillingWidth =
        (totalFillingWidth - totalBarWidth) / (door.numberOfVerticalBars + 1);

      for (let i = 0; i < door.numberOfVerticalBars; i++) {
        let vertBar = new Client.DoorBarVertical(door);
        vertBar.positionX = fillingLeft + (i + 1) * fillingWidth + i * barWidth;
        door.verticalBars.push(vertBar);
      }
    }
  }

  //#endregion Calculations, individual doors

  //#region ConfigurationItem generation

  private generateConfigurationItems(section: Client.CabinetSection) {
    this.generateRailItems(section);
    this.generateDoorItems(section);
  }

  private generateRailItems(section: Client.CabinetSection) {
    let doors = section.doors;

    // Clear existing itrems
    doors.railItems.length = 0;

    // We only generate rail items, if there is a railset with products and materials
    if (
      !doors.railSet ||
      !doors.railMaterialTop ||
      !doors.railMaterialBottom ||
      !doors.railSet.topProduct ||
      !doors.railSet.bottomProduct
    ) {
      return;
    }

    // Shared stuff for both parts of the railset
    let railPositionX =
      (CabinetSectionHelper.hasCornerLeft(section)
        ? CabinetSectionHelper.getCornerOffsetForRail(section)
        : CabinetSectionHelper.getCorpusWidthLeft(section)) -
      (doors.railSet.NumberOfTracks !== 1 ? section.ExtraRailWidthLeft : 0);
    let railWidth = CabinetSectionHelper.getRailWidth(section);

    //#region Top item

    // Create item
    let topItem = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.Door,
      doors.railSet.topProduct.Id,
      doors.railMaterialTop.Id,
      section,
    );
    doors.railItems.push(topItem);

    // Set dimensions
    let height = ProductHelper.defaultHeight(
      doors.railSet.topProduct,
      doors.railMaterialTop,
    );
    topItem.ActualHeight = height;
    topItem.Height = height;
    topItem.Width = railWidth;
    topItem.Depth = CabinetSectionHelper.getRailSetDepth(section, true);

    // Set position
    topItem.X = railPositionX;
    topItem.Y = section.Height - section.corpus.heightTop - height;
    let zOffsetTop =
      topItem.Depth + CabinetSectionHelper.getTopRailDistanceFromFront(section);
    topItem.Z = section.Depth - zOffsetTop;

    //#endregion Top item

    //#region Bottom item

    // Create item
    let bottomItem = this.configurationItemService.createConfigurationItem(
      Interface_Enums.ItemType.Door,
      doors.railSet.bottomProduct.Id,
      doors.railMaterialBottom.Id,
      section,
    );
    doors.railItems.push(bottomItem);

    // Compensate for different rail lengths in corner cabinets
    let zOffsetBottom =
      bottomItem.Depth +
      CabinetSectionHelper.getBottomRailDistanceFromFront(section);
    let bottomZ = section.Depth - zOffsetBottom;
    let cornerOffset = bottomZ - topItem.Z;

    // Set dimensions
    let bottomHeight = ProductHelper.defaultHeight(
      doors.railSet.bottomProduct,
      doors.railMaterialBottom,
    );
    bottomItem.ActualHeight = bottomHeight;
    bottomItem.Height = bottomHeight;
    bottomItem.Width =
      railWidth - (CabinetSectionHelper.hasCorner(section) ? cornerOffset : 0);
    bottomItem.Depth = CabinetSectionHelper.getRailSetDepth(section, false);

    // Set position
    bottomItem.X =
      railPositionX +
      (CabinetSectionHelper.hasCornerLeft(section) ? cornerOffset : 0);
    bottomItem.Y = section.corpus.heightBottom;
    bottomItem.Z = bottomZ;

    //#endregion Bottom item
  }

  private generateDoorItems(section: Client.CabinetSection) {
    let doorSubSection = section.doors;

    // We only generate door items, if there is a door profile and profile material
    if (!doorSubSection.profile) {
      return;
    }

    // Clear existing items
    doorSubSection.doorItems.length = 0;

    for (let door of doorSubSection.doors) {
      // Create item
      let doorItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Door,
        doorSubSection.profile.Id,
        doorSubSection.profileMaterial !== null
          ? doorSubSection.profileMaterial.Id
          : null,
        section,
      );
      if (!doorItem.Product)
        throw new Error('Unknown door id ' + doorSubSection.profile.Id);

      doorItem.Description = this.translationService.translate(
        'piecelist_door_name',
        '{0}  #{1}',
        doorItem.Description,
        door.index.toString(),
      );
      doorItem.isDescriptionLocked = true;

      let widthNumber = DoorHelper.getWidthNumber(
        door,
        doorItem.Product.ProductType ===
          Interface_Enums.ProductType.DoorStandard,
      );

      doorSubSection.doorItems.push(doorItem);

      // Set dimensions
      doorItem.ActualHeight = door.height;
      doorItem.Height = door.height;
      doorItem.Width = door.width;
      doorItem.Depth = door.depth;

      // Set position
      doorItem.X = door.position.X;
      doorItem.Y = door.position.Y;
      doorItem.Z = door.position.Z;

      // ItemNo
      //this.setItemNo(doorItem, door);

      // Frame material
      if (doorSubSection.profileMaterial !== null) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          VariantNumbers.FrameColor,
          doorSubSection.profileMaterial.Number,
        );
      } else {
        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.FrameColor,
        );
      }

      // Fillings
      if (door.fillings.length > 0) {
        let mostExpensiveFillingMaterial =
          DoorHelper.getMostExpensiveFillingMaterial(door);
        if (mostExpensiveFillingMaterial) {
          ConfigurationItemHelper.addVariantOptionByNumbers(
            doorItem,
            VariantNumbers.DoorFillingColor,
            mostExpensiveFillingMaterial.Number,
          );
        }

        // Design filling count
        let designFillingCount = door.fillings.filter(
          (f) => f.isDesignFilling,
        ).length;
        ConfigurationItemHelper.addDimensionVariantByNumber(
          doorItem,
          VariantNumbers.DoorDesignFillingCount,
          designFillingCount,
        );

        // Actual filling count
        let actualFillings = door.actualFillings;
        ConfigurationItemHelper.addDimensionVariantByNumber(
          doorItem,
          VariantNumbers.DoorFillingCount,
          actualFillings.length,
        );

        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.DoorFillingColorDetailed,
        );
        ConfigurationItemHelper.removeItemVariantsByVariantNumbers(
          doorItem,
          VariantNumbers.FillingSizes,
        );
        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.VisibleFillingSizes,
        );

        let fillingItems: Interface_DTO.ConfigurationItem[] = [];
        let fillingIndex = 0;
        for (let filling of actualFillings) {
          // Material number
          ConfigurationItemHelper.addDimensionVariantOption(
            doorItem,
            VariantNumbers.DoorFillingColorDetailed,
            fillingIndex,
            filling.materialNumber,
          );
          // Height
          ConfigurationItemHelper.addDimensionVariantByNumber(
            doorItem,
            VariantNumbers.FillingSizes[fillingIndex],
            filling.height,
            1,
          );

          let fillingItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.ManuallyAdded,
              doorSubSection.profile.Id,
              filling.materialId,
              section,
            ).dtoItem;
          fillingItem.ItemNo = '';
          fillingItem.Description = this.translationService.translate(
            'piecelist_door_filling_name',
            '{0} #{1}-{2}',
            doorItem.Product!.Name,
            door.index.toString(),
            (fillingIndex + 1).toString(),
          );
          fillingItem.Height = filling.height;
          fillingItem.ActualHeight = filling.height;
          fillingItem.Width = filling.width;
          fillingItem.Depth = 0;
          fillingItems.push(fillingItem);

          fillingIndex++;
        }

        fillingItems.reverse();
        doorItem.Children.push(...fillingItems);

        let intialExtraInfoIndex = VariantNumbers.ExtraInfoOffset;
        ConfigurationItemHelper.removeExtraInfoBelowNumber(
          doorItem,
          intialExtraInfoIndex,
        );

        fillingIndex = 0;
        for (let filling of door.fillings) {
          // Visual height
          let visualHeight = DoorHelper.getVisualFillingHeight(
            door,
            fillingIndex,
          );
          ConfigurationItemHelper.addDimensionVariantOptionByNumber(
            doorItem,
            VariantNumbers.VisibleFillingSizes,
            fillingIndex,
            visualHeight,
            2,
          );

          // Add extra info
          let extraInfoIndex = intialExtraInfoIndex;
          ConfigurationItemHelper.addExtraInfo(
            doorItem,
            extraInfoIndex--,
            filling.materialNumber,
          );

          let heightAsString = filling.height.toLocaleString('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false,
          });
          ConfigurationItemHelper.addExtraInfo(
            doorItem,
            extraInfoIndex--,
            heightAsString,
          );

          let positionYAsString = filling.positionYAbsolute.toLocaleString(
            'en-GB',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: false,
            },
          );
          ConfigurationItemHelper.addExtraInfo(
            doorItem,
            extraInfoIndex--,
            positionYAsString,
          );

          intialExtraInfoIndex += VariantNumbers.ExtraInfoOffset;
          fillingIndex++;
        }

        // Add additional product
      }

      // Bars
      if (doorSubSection.barHeight) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          VariantNumbers.BarHeight,
          '' + doorSubSection.barHeight,
        );
      }

      let forceFixedBarsVariantOptionValue = door.forceFixedBars
        ? VariantNumbers.Values.Yes
        : VariantNumbers.Values.No;
      let forceFixedBarsVariantOption = doorItem.editorAssets.variantsByNumber[
        VariantNumbers.ForceFixedBars
      ].VariantOptions.filter(
        (vo) => vo.Number === forceFixedBarsVariantOptionValue,
      )[0];
      ConfigurationItemHelper.addVariantOption(
        doorItem,
        forceFixedBarsVariantOption,
      );

      if (door.verticalBarOption) {
        ConfigurationItemHelper.addVariantOption(
          doorItem,
          door.verticalBarOption,
        );
      }

      if (door.bars.length > 0) {
        // Loose bar count
        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.LooseBarCount,
        );
        let looseBarCount = door.bars.filter(
          (b) => b.barType === Interface_Enums.BarType.Loose,
        ).length;
        ConfigurationItemHelper.addDimensionVariantByNumber(
          doorItem,
          VariantNumbers.LooseBarCount,
          looseBarCount,
        );

        // Fixed bar count
        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.FixedBarCount,
        );
        let fixedBarCount = door.bars.length - looseBarCount;
        ConfigurationItemHelper.addDimensionVariantByNumber(
          doorItem,
          VariantNumbers.FixedBarCount,
          fixedBarCount,
        );

        // Bar positions
        ConfigurationItemHelper.removeItemVariantByVariantNumber(
          doorItem,
          VariantNumbers.BarPlacement,
        );
        ConfigurationItemHelper.removeItemVariantsByVariantNumbers(
          doorItem,
          VariantNumbers.BarPlacementPositionTypes,
        );
        ConfigurationItemHelper.removeItemVariantsByVariantNumbers(
          doorItem,
          VariantNumbers.BarPlacementPositions,
        );
        let index = 0;
        let designFilling = false;
        let barPlacementCount = 0;
        for (let bar of door.bars) {
          if (!designFilling) {
            ConfigurationItemHelper.addDimensionVariantOption(
              doorItem,
              VariantNumbers.BarPlacement,
              barPlacementCount,
              bar.barTypeString(false),
            );
            barPlacementCount++;
            designFilling = bar.barType === Interface_Enums.BarType.Design;
          } else {
            designFilling = false;
          }

          ConfigurationItemHelper.addDimensionVariant(
            doorItem,
            VariantNumbers.BarPlacementPositionTypes[index],
            bar.barTypeString(true),
          );
          ConfigurationItemHelper.addDimensionVariantByNumber(
            doorItem,
            VariantNumbers.BarPlacementPositions[index],
            bar.centerY,
            2,
          );
          index++;
          // Add additional product
        }
      }

      // Corner cut
      if (door.cornerCut) {
        ConfigurationItemHelper.addVariantOption(doorItem, door.cornerCut);
      }

      // Grips
      let gripVariant = doorItem.Product.getVariants(
        section.cabinet.ProductLineId,
      ).filter((v) => v.Number.indexOf(VariantNumbers.Grip) === 0)[0];
      let gripVariantNumber = gripVariant
        ? gripVariant.Number
        : VariantNumbers.Grip;

      if (doorSubSection.grip && (door.hasGripsFront || door.hasGripsBack)) {
        let doorProductId = doorItem.Product.Id;
        let gripProductId = doorSubSection.grip.Id;

        let gripMapItem = Enumerable.from(
          doorSubSection.editorAssets.doorGripItems,
        ).firstOrDefault(
          (mapItem) =>
            mapItem.DoorProductId === doorProductId &&
            mapItem.GripProductId === gripProductId,
        );

        if (gripMapItem) {
          ConfigurationItemHelper.addVariantOptionByNumbers(
            doorItem,
            gripVariantNumber,
            gripMapItem.VariantOptionNumber,
          );
        }

        ConfigurationItemHelper.addDimensionVariantByNumber(
          doorItem,
          VariantNumbers.GripPlacementHeight,
          doorSubSection.gripPlacementHeight,
        );

        if (door.gripPlacementFront) {
          ConfigurationItemHelper.addVariantOption(
            doorItem,
            door.gripPlacementFront,
          );
        } else {
          ConfigurationItemHelper.addDimensionVariant(
            doorItem,
            VariantNumbers.GripPlacementFrontside,
            VariantNumbers.Values.Grip_None,
          );
        }
        if (door.gripPlacementBack) {
          ConfigurationItemHelper.addVariantOption(
            doorItem,
            door.gripPlacementBack,
          );
        } else {
          ConfigurationItemHelper.addDimensionVariant(
            doorItem,
            VariantNumbers.GripPlacementBackside,
            VariantNumbers.Values.Grip_None,
          );
        }
      } else {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          gripVariantNumber,
          '0',
        );
        ConfigurationItemHelper.addDimensionVariant(
          doorItem,
          VariantNumbers.GripPlacementHeight,
          '0',
        );
        ConfigurationItemHelper.addDimensionVariant(
          doorItem,
          VariantNumbers.GripPlacementFrontside,
          VariantNumbers.Values.Grip_None,
        );
        ConfigurationItemHelper.addDimensionVariant(
          doorItem,
          VariantNumbers.GripPlacementBackside,
          VariantNumbers.Values.Grip_None,
        );
      }

      //#region Door specific variants

      let doorIndex = doorSubSection.doors.indexOf(door);

      let doorNumber = doorIndex + 1;
      ConfigurationItemHelper.addDimensionVariantByNumber(
        doorItem,
        VariantNumbers.DoorNumber,
        doorNumber,
      );

      ConfigurationItemHelper.addDimensionVariantByNumber(
        doorItem,
        VariantNumbers.DoorHeight,
        door.height,
        1,
      );

      ConfigurationItemHelper.addDimensionVariantByNumber(
        doorItem,
        VariantNumbers.DoorWidth,
        Math.ceil(door.width),
        1,
      );

      ConfigurationItemHelper.addVariantOptionByNumbers(
        doorItem,
        VariantNumbers.DoorWidthMax,
        widthNumber,
      ); // Bars also need DoorWidthMax, but it should be calculated on the server...

      if (
        doorItem.Product.ProductType ===
        Interface_Enums.ProductType.DoorStandard
      ) {
        let widthNumberStandardDoor = DoorHelper.getWidthNumber(door, true);
        ConfigurationItemHelper.addVariantOptionByNumbers(
          doorItem,
          VariantNumbers.DoorWidthMaxStandardDoor,
          widthNumberStandardDoor,
        );
      }

      // The softclose variant indicates, that the door must have softclose on the side facing the outside of the cabinet.
      let softCloseSide = this.softCloseVariantValue(doorSubSection, doorIndex);
      ConfigurationItemHelper.addVariantOptionByNumbersWithValue(
        doorItem,
        VariantNumbers.SoftClose,
        softCloseSide,
      );

      //#endregion Door specific variants
    }

    // Softclose and position stops
    let doorData = doorSubSection.doorData;
    if (doorData) {
      if (doorSubSection.canAddSoftclose && doorSubSection.addSoftClose) {
        for (
          let doorNumber = 0;
          doorNumber < doorSubSection.doors.length;
          doorNumber++
        ) {
          let door = doorSubSection.doors[doorNumber];
          let softCloseVariant = this.softCloseVariantValue(
            doorSubSection,
            doorNumber,
          );
          if (
            softCloseVariant === VariantNumbers.SoftCloseValues.SoftClose_None
          ) {
            continue;
          }
          if (
            doorData.SoftCloseType ===
            Interface_Enums.SoftCloseType.MagneticIncluded
          ) {
            continue;
          }
          if (
            doorData.SoftCloseType ===
            Interface_Enums.SoftCloseType.SpringPoweredIncluded
          ) {
            continue;
          }
          let softClose = doorData.SoftCloseProducts.filter(
            (scp) => scp.MinWidth <= door.width,
          )[0];
          if (!softClose || !softClose.ProductId) {
            console.warn(
              'Could not add softclose on door: no product specified',
              { door, doorData },
            );
            continue;
          }
          let softcloseItem =
            this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Door,
              softClose.ProductId,
              null,
              section,
            );
          softcloseItem.Quantity = 1;
          doorSubSection.doorItems.push(softcloseItem);
        }
      }

      //position stop
      if (
        doorSubSection.canHavePositionStop &&
        doorSubSection.numberOfPositionStops > 0
      ) {
        let posStopItem = this.configurationItemService.createConfigurationItem(
          Interface_Enums.ItemType.Door,
          doorData.PositionStopProductId,
          null,
          section,
        );
        posStopItem.Quantity = doorSubSection.numberOfPositionStops;
        doorSubSection.doorItems.push(posStopItem);
      }

      let cornerMoulding = doorSubSection.cornerMoulding;
      if (cornerMoulding) {
        doorSubSection.doorItems.push(cornerMoulding);
      }
    }
  }

  private roundIntegerValues(section: Client.CabinetSection) {
    section.ExtraRailWidthLeft = Math.floor(section.ExtraRailWidthLeft);
    section.ExtraRailWidthRight = Math.ceil(section.ExtraRailWidthRight);
  }

  private softCloseVariantValue(
    doorSubSection: Client.Doors,
    doorIndex: number,
  ): VariantNumbers.SoftCloseValues {
    let doorData = doorSubSection.doorData;
    if (!doorData) return VariantNumbers.SoftCloseValues.SoftClose_None;
    if (doorData.SoftCloseType === Interface_Enums.SoftCloseType.Unused)
      return VariantNumbers.SoftCloseValues.SoftClose_None;

    const addSoftClose =
      doorSubSection.canAddSoftclose && doorSubSection.addSoftClose;

    if (doorData.SoftCloseType === Interface_Enums.SoftCloseType.Magnetic) {
      if (!addSoftClose) return VariantNumbers.SoftCloseValues.SoftClose_None;

      //there should be one softclose on the first door, and one on the last door
      if (doorIndex === 0) return VariantNumbers.SoftCloseValues.SoftClose_Left;
      if (doorIndex === doorSubSection.doors.length - 1)
        return VariantNumbers.SoftCloseValues.SoftClose_Right;
      return VariantNumbers.SoftCloseValues.SoftClose_None;
    }
    if (
      doorData.SoftCloseType === Interface_Enums.SoftCloseType.SpringPowered
    ) {
      if (!addSoftClose) return VariantNumbers.SoftCloseValues.SoftClose_None;

      //there should be one on the first and last door of each rail
      let railPos = doorSubSection.doorSetup.railPositions[doorIndex];
      let isFirstDoorOnRail =
        doorSubSection.doorSetup.railPositions.indexOf(railPos) === doorIndex;
      if (isFirstDoorOnRail)
        return VariantNumbers.SoftCloseValues.SoftClose_Left;
      let isLastDoorOnRail =
        doorSubSection.doorSetup.railPositions.lastIndexOf(railPos) ===
        doorIndex;
      if (isLastDoorOnRail)
        return VariantNumbers.SoftCloseValues.SoftClose_Right;
      return VariantNumbers.SoftCloseValues.SoftClose_None;
    }
    if (
      doorData.SoftCloseType === Interface_Enums.SoftCloseType.MagneticIncluded
    ) {
      //softclose is always included
      //there should be one softclose on the first door, and one on the last door
      if (doorIndex === 0) return VariantNumbers.SoftCloseValues.SoftClose_Left;
      if (doorIndex === doorSubSection.doors.length - 1)
        return VariantNumbers.SoftCloseValues.SoftClose_Right;
      return VariantNumbers.SoftCloseValues.SoftClose_None;
    }
    if (
      doorData.SoftCloseType ===
      Interface_Enums.SoftCloseType.SpringPoweredIncluded
    ) {
      //there should be one on the first and last door of each rail
      const is2RailsWith2Doors =
        doorSubSection.doors.length == 2 &&
        doorSubSection.doorSetup.railPositions.length == 2;

      if (is2RailsWith2Doors) {
        if (doorSubSection.doors[0].index == doorIndex)
          return VariantNumbers.SoftCloseValues.SoftClose_Left;
        return VariantNumbers.SoftCloseValues.SoftClose_Right;
      }

      // railPositions determines on what rail a door is positioned
      let railPos = doorSubSection.doorSetup.railPositions[doorIndex];
      let isFirstDoorOnRail =
        doorSubSection.doorSetup.railPositions.indexOf(railPos) === doorIndex;
      let isLastDoorOnRail =
        doorSubSection.doorSetup.railPositions.lastIndexOf(railPos) ===
        doorIndex;

      if (isFirstDoorOnRail)
        return VariantNumbers.SoftCloseValues.SoftClose_Left;
      if (isLastDoorOnRail)
        return VariantNumbers.SoftCloseValues.SoftClose_Right;
      return VariantNumbers.SoftCloseValues.SoftClose_None;
    }
    throw new Error('Unknown softCloseType: ' + doorData.SoftCloseType);
  }

  //#endregion ConfigurationItem generation

  //#region Calculations, Cabinet section

  /**
   * Updates values, that are dependent on doors, on cabinet section
   * @param section
   */
  private updateCabinetSectionDto(section: Client.CabinetSection) {
    this.setSightDimensions(section);
  }

  /**
   * Calculates and set sight dimensions
   * @param section
   */
  private setSightDimensions(section: Client.CabinetSection) {
    section.SightHeight =
      section.Height - (section.corpus.heightBottom + section.corpus.heightTop);
    section.SightWidth = CabinetSectionHelper.getSightWidth(section);
  }

  //#endregion Calculations, Cabinet section

  //#region Private helpers

  /**
   * Creates a question for the user, asking wether or not to spread the fillings evenly
   * @param section
   */
  private getQuestionSpreadEvenly(
    section: Client.CabinetSection,
  ): RecalculationQuestion {
    return {
      key: 'Spread bars and fillings evenly on doors?',
      defaultValue: 'Spread bars and fillings evenly on doors?',
      question: Enums.RecalculationQuestionType.RepositionDoorBars,
      severity: Enums.RecalculationMessageSeverity.Question,
      action: (answer) => {
        if (answer) {
          section.cabinet.cabinetSections.forEach((sec) =>
            sec.doors.doors.forEach(
              (door) => (door.mustSpreadBarsEvenly = true),
            ),
          );
        }
      },
    };
  }

  /**
   * Creates a question for the user, asking wether or not to center grips vertically
   * @param section
   */
  private getQuestionCenterGrips(
    section: Client.CabinetSection,
  ): RecalculationQuestion {
    return {
      key: 'Reposition grips on doors?',
      defaultValue: 'Reposition grips on doors?',
      question: Enums.RecalculationQuestionType.RepositionDoorGrips,
      severity: Enums.RecalculationMessageSeverity.Question,
      action: (answer) => {
        if (answer) {
          section.cabinet.cabinetSections.forEach((sec) =>
            sec.doors.doors.forEach((door) => (door.mustCenterGrips = true)),
          );
        }
      },
    };
  }

  //#endregion Private helpers
}
