import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'piece-list',
  templateUrl: './pieceList.html',
  styleUrls: ['../../../../style/Picelist.scss'],
})
export class PieceListVm extends BaseVm implements OnInit {
  constructor(
    baseVmService: BaseVmService,
    private readonly notificationService: NotificationService,
  ) {
    super(baseVmService);
  }

  private static readonly dimensionNumberFormat: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  };

  @Input()
  public floorPlan!: Client.FloorPlan;

  @Output()
  public onItemClick = new EventEmitter<Client.ConfigurationItem>();

  @Input()
  public searchString$!: Observable<string>;

  private expandedPositions!: Array<Interface_DTO.PieceListItem>;

  public pieceList: Interface_DTO.PieceList | undefined = undefined;
  public filteredPieceList: Interface_DTO.PieceList | undefined = undefined;
  public pieceListGroups: PieceListVm.PieceListGroup[] = [];

  public async ngOnInit() {
    if (!this.searchString$) {
      this.searchString$ = new BehaviorSubject<string>('');
    }
    try {
      this.expandedPositions = [];

      combineLatest([this.floorPlan.pieceList$, this.searchString$]).subscribe(
        ([pieceList, searchString]) => {
          if (pieceList == null) return;
          this.pieceList = pieceList;
          for (let g of pieceList.Groups)
            for (let c of g.Children) {
              for (let i of c.Items) {
                if (this.startExpanded(c, i)) this.expandedPositions.push(i);
              }
            }

          this.filter(searchString);
          this.setGroups();
        },
      );

      // let pieceListObservable = this.floorPlan.pieceList$;

      // let combinedObservable = Observable.combine({
      //     pieceList: pieceListObservable,
      //     searchString: this.searchString$
      // });

      // this.subscribeToOld(combinedObservable, async (combined) => {
      //     this.pieceList = combined.pieceList;
      //     if (combined.pieceList) {
      //         for (let g of combined.pieceList.Groups)
      //             for (let c of g.Children) {
      //                 for (let i of c.Items) {
      //                     if (this.startExpanded(c, i))
      //                         this.expandedPositions.push(i);
      //                 }
      //             }
      //     }
      //     await this.$timeout.setTimeout(0);
      //     this.filter(combined.searchString);
      //     await this.$timeout.setTimeout(0);
      //     this.setGroups();
      // })
    } catch (e: any) {
      this.notificationService.exception(
        'piecelist_load_error',
        e,
        'Could not load piecelist',
      );
    }
  }

  public formatDimension(item: Interface_DTO.PieceListItem): string {
    let numbers1 = [
      item.Dimensions.X,
      item.Dimensions.Y,
      item.Dimensions.Z,
    ].filter((n) => n > 0);
    if (numbers1.length === 0) {
      return '';
    }

    let numbers2 = [item.Dimensions2.X, item.Dimensions2.Y, item.Dimensions2.Z];

    let formattedDimensions: string[] = [];
    for (var i = 0; i < numbers1.length; i++) {
      let dimString = numbers1[i].toLocaleString(
        undefined,
        PieceListVm.dimensionNumberFormat,
      );

      if (numbers2[i] > 0) {
        dimString +=
          '/' +
          numbers2[i].toLocaleString(
            undefined,
            PieceListVm.dimensionNumberFormat,
          );
      }

      formattedDimensions.push(dimString);
    }

    return formattedDimensions.join(' x ') + ' mm';
  }

  public getRowClass(item: Interface_DTO.PieceListItem): string {
    if (!item.Position) return 'subItem';
    let configurationItem = this.getClickableConfigurationItem(item);
    let clickable = configurationItem ? ' clickable' : '';
    return 'mainItem' + clickable;
  }

  private getClickableConfigurationItem(
    pieceListItem: Interface_DTO.PieceListItem,
  ): Client.ConfigurationItem | null {
    if (!pieceListItem.Position) return null;
    if (
      pieceListItem.ItemType === Interface_Enums.ItemType.Custom ||
      pieceListItem.ItemType === Interface_Enums.ItemType.CustomPercent ||
      pieceListItem.ItemType === Interface_Enums.ItemType.ManuallyAdded ||
      pieceListItem.ItemType === Interface_Enums.ItemType.AddedByCondition
    ) {
      return (
        Enumerable.from(this.floorPlan.cabinets)
          .selectMany((cab) => cab.cabinetSections)
          .selectMany((sec) => sec.configurationItems)
          .firstOrDefault(
            (item) =>
              item.PositionNumber === pieceListItem.Position &&
              (item.ItemType === Interface_Enums.ItemType.ManuallyAdded ||
                item.ItemType === Interface_Enums.ItemType.Custom ||
                item.ItemType === Interface_Enums.ItemType.CustomPercent),
          ) ?? null
      );
    }
    return null;
  }

  public showChevronDown(
    group: Interface_DTO.PieceListGroup,
    item: Interface_DTO.PieceListItem,
  ): boolean {
    if (item.Position) {
      for (let c of group.Items) {
        if (c.Position != item.Position && c.ParentPosition == item.Position) {
          if (this.expandedPositions.indexOf(item) >= 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private filter(searchString: string) {
    searchString = searchString.toLocaleLowerCase();
    if (!searchString || !this.pieceList) {
      this.filteredPieceList = this.pieceList;
      return;
    } else {
      let newPl = ObjectHelper.copy(this.pieceList);
      let newGroups: Interface_DTO.PieceListGroup[] = [];
      for (let group of newPl.Groups) {
        let grp = this.filterGroup(searchString, group);
        if (grp) newGroups.push(grp);
      }
      newPl.Groups = newGroups;
      this.filteredPieceList = newPl;
    }
  }

  private filterGroup(
    searchString: string,
    group: Interface_DTO.PieceListGroup,
  ): Interface_DTO.PieceListGroup | null {
    if (group.Name.toLocaleLowerCase().indexOf(searchString) >= 0) return group;
    group.Items = group.Items.filter(
      (i) =>
        i.Description.toLocaleLowerCase().indexOf(searchString) >= 0 ||
        i.ItemNo.indexOf(searchString) === 0,
    );
    let newChildren: Interface_DTO.PieceListGroup[] = [];
    for (let child of group.Children) {
      let filteredChild = this.filterGroup(searchString, child);
      if (filteredChild) {
        newChildren.push(filteredChild);
      }
    }
    group.Children = newChildren;

    if (group.Children.length > 0 || group.Items.length > 0) {
      return group;
    } else {
      return null;
    }
  }

  private setGroups() {
    this.pieceListGroups = [];
    if (!this.pieceList) {
      return;
    }
    for (let plCabinet of this.pieceList.Groups) {
      for (let plSection of plCabinet.Children) {
        this.pieceListGroups.push({
          name: plCabinet.Name + ' - ' + plSection.Name,
          cabinet: plCabinet,
          section: plSection,
          items: plSection.Items.filter((plItem) =>
            this.showRow(plSection, plItem),
          ).map<PieceListVm.PieceListLine>((plItem) => {
            return {
              item: plItem,
              class: this.getRowClass(plItem),
              position: (plItem.Position || '').toString(),
              productNumber: plItem.ItemNo,
              description: plItem.Description || '',
              material: plItem.NaterialName,
              dimensions: this.formatDimension(plItem),
              amount: plItem.Amount.toString(),
              unitPrice: this.toMoneyFormat(plItem.UnitPrice),
              totalPrice: this.toMoneyFormat(plItem.Amount * plItem.UnitPrice),
            };
          }),
        });
      }
    }
  }

  public showChevronUp(
    group: Interface_DTO.PieceListGroup,
    item: Interface_DTO.PieceListItem,
  ): boolean {
    if (item.Position) {
      for (let c of group.Items) {
        if (c.Position != item.Position && c.ParentPosition == item.Position) {
          if (this.expandedPositions.indexOf(item) < 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public startExpanded(
    group: Interface_DTO.PieceListGroup,
    item: Interface_DTO.PieceListItem,
  ): boolean {
    if (item.Position) {
      for (let c of group.Items) {
        if (c.Position != item.Position && c.ParentPosition == item.Position) {
          return true;
        }
      }
    }
    return false;
  }

  public colapse(item: Interface_DTO.PieceListItem) {
    let index: number = this.expandedPositions.indexOf(item);
    if (index >= 0) this.expandedPositions.splice(index, 1);
  }

  public expand(item: Interface_DTO.PieceListItem) {
    let index: number = this.expandedPositions.indexOf(item);
    if (index < 0) this.expandedPositions.push(item);
  }

  public showRow(
    group: Interface_DTO.PieceListGroup,
    item: Interface_DTO.PieceListItem,
  ) {
    if (!item.Position) {
      for (let parent of this.expandedPositions) {
        if (
          parent.Position == item.ParentPosition &&
          group.Items.indexOf(parent) >= 0
        ) {
          return true;
        }
      }
      return false;
    }

    return true;
  }

  public async rowClicked(pieceListItem: Interface_DTO.PieceListItem) {
    const configurationItem = this.getClickableConfigurationItem(pieceListItem);
    if (configurationItem) {
      this.onItemClick.emit(configurationItem);
    }
  }

  public toMoneyFormat(num: number): string {
    if (!num) num = 0;
    return ObjectHelper.roundMoney(num);
  }
}

export module PieceListVm {
  export interface PieceListLine {
    item: Interface_DTO.PieceListItem;
    class: string;
    position: string;
    productNumber: string;
    description: string;
    material: string;
    dimensions: string;
    amount: string;
    unitPrice: string;
    totalPrice: string;
  }

  export interface PieceListGroup {
    name: string;
    items: PieceListLine[];
    section: Interface_DTO.PieceListGroup;
    cabinet: Interface_DTO.PieceListGroup;
  }
}
