import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClientSetting,
  ClientSettingData,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  Languages,
  LanguagesInjector,
  languagesProvider,
} from 'app/functional-core/ambient/i8n/Languages';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
  UserSettingsValue,
} from 'app/functional-core/ambient/userdata/UserData';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from '@Services/LoginService';
import {
  Notification,
  NotificationService,
} from 'app/ts/services/NotificationService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  templateUrl: 'userSetting.html',
  providers: [clientSettingProvider, languagesProvider, userDataProvider],
  styleUrls: ['../../../../style/modal.scss'],
})
export class UserSettingVm extends BaseVm {
  public oldPassword = '';
  public newPassword = '';

  public formattedLanguages: { key: string; value: string }[] = [];

  private changePasswordNotification: Notification | undefined = undefined;

  public userSettingsValue!: UserSettingsValue;
  public clientSetting: ClientSettingData;
  public title: string = '';

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
    readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    @Inject(UserSettingsInjector) public readonly userSettings: UserSettings,
    @Inject(ClientSettingInjector)
    private readonly clientSettingSetter: ClientSetting,
    @Inject(LanguagesInjector) languages: Languages,
  ) {
    super(baseVmService);
    this.userSettingsValue = userSettings.value;
    if (!this.userSettingsValue.PriceFactor)
      this.userSettingsValue.PriceFactor = 1;

    this.clientSetting = clientSettingSetter.copy();
    this.formattedLanguages = languages.data.map((language) => {
      return {
        key: language.NavisionCode,
        value: translateService.translate(
          'userSetting_language_name_template{0}_{1}',
          '{0} ({1})',
          language.DisplayName,
          language.EnglishName,
        ),
      };
    });
  }

  public async changePassword() {
    let model: Interface_DTO.PasswordChangeRequest = {
      OldPassword: this.oldPassword,
      NewPassword: this.newPassword,
    };
    if (this.changePasswordNotification) {
      this.notificationService.remove(this.changePasswordNotification);
      this.changePasswordNotification = undefined;
    }
    try {
      await this.loginService.changePassword(model);
      window.alert(
        this.translate('userSetting_password_changed', 'Password Changed'),
      );
    } catch (e: any) {
      if (
        e instanceof Exception.ServerDomainException &&
        e.Type === Interface_DTO_DomainError.ErrorType.WrongPassword
      ) {
        window.alert(
          this.translate(
            'userSetting_password_changed_wrong_password',
            'Wrong Password',
          ),
        );
      } else {
        this.changePasswordNotification =
          await this.notificationService.exceptionWithDefaultText(e);
      }
    }
  }

  public clearAll() {
    for (let storage of [localStorage, sessionStorage]) {
      storage.clear();
    }
    document.cookie = '';
    location.reload();
  }

  public async save() {
    if (this.clientSetting != null) {
      this.clientSettingSetter.set(this.clientSetting);
    }
    if (this.userSettings != null) {
      this.userSettings.set(this.userSettingsValue);
    }

    this.activeModal.close(void 0);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }
}
