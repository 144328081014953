import { Injectable, Input } from '@angular/core';
import { Component } from '@angular/core';
import { activeUserProvider } from 'app/functional-core/ambient/activeUser/ActiveUser';
import { CustomerService } from 'app/ts/services/CustomerService';
import { ModalService } from 'app/ts/services/ModalService';
import { UserPanelData } from './user-panel.data';
import { Constants } from 'app/ts/Constants';
import { ApplicationState } from 'app/functional-core/ApplicationState';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { LoginService } from '@Services/LoginService';

@Component({
  selector: 'user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['user-panel.component.scss'],
  providers: [UserPanelData, activeUserProvider],
})
@Injectable()
export class UserPanelComponent {
  @Input()
  public allowChangeStore: boolean = false;

  // TODO: Replace any with a decent interface of some kind
  // parent can be a CustomersVm, DeliveryAddressesVm, FloorplansVm, ProjectsVm,
  // which all inherit from CustomerBase<T>, which should be the one
  // implementing said interface, e.g. TopBarInteraction, or maybe they should
  // probably share a service... That might also stop some of the
  // many unnecessary renders
  @Input()
  public parent!: any;

  constructor(
    private data: FunctionalCoreService,
    private modalService: ModalService,
    private oldLoginService: LoginService,
    private customerService: CustomerService,
    public readonly userData: UserPanelData,
  ) {}

  public async changeUserSettings() {
    try {
      let modal = this.modalService.getEditUserSettingsModal();
      await modal.result;
    } catch (e: any) {
      //User cancelled modal - don't care
    }
  }

  public async changeStoreSettings() {
    try {
      let modal = this.modalService.getEditStoreSettingsModal();
      await modal.result;
    } catch (e: any) {
      //User cancelled modal - don't care
    }
  }

  public async changeStore() {
    await this.modalService.selectStore();
    await this.customerService.updateCustomerList();
  }

  setCustomPriceDate() {
    this.modalService.setCustomPriceDate();
  }

  logout() {
    localStorage.removeItem(Constants.localStorageKeys.rememberMeChecked);
    localStorage.removeItem(Constants.localStorageKeys.loginToken);
    this.data.ambient.applicationState = ApplicationState.Initializing;
    this.data.ambient.activeUser.activeUser = undefined;
    this.oldLoginService.logout();
    window.location.pathname = '/login';
  }
}
