import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'restore-autosave',
  templateUrl: './restoreAutosave.html',
})
export class RestoreAutosaveVm extends BaseVm {
  public readonly serverDate!: Date;
  public readonly localDate!: Date;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public answer(ans: boolean) {
    this.activeModal.close(ans);
  }
}
