import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DeliveryAddressEditMenuVm } from './DeliveryAddressEditMenuVm';
import { DeliveryAddressEditVm } from './DeliveryAddressEditVm';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeliveryAddressEditMenuVm, DeliveryAddressEditVm],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    NgbModule,
  ],
  exports: [DeliveryAddressEditMenuVm, DeliveryAddressEditVm],
})
export class DeliveryAddressEditMenuModule {}
