import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as App from 'app/ts/app';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanLogic } from 'app/ts/services/ConfigurationLogic/FloorPlanLogic';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { EndUserImportService } from 'app/ts/services/EndUserImportService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { RouteService } from 'app/ts/services/RouteService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import Enumerable from 'linq';
import { CornerVm } from './floor-plan-property-sheet.component';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { MeasurementService } from 'app/measurement/measurement.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SecretService } from 'app/measurement/hidden-secret/secret.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { SectionId } from 'app/partition/section';
import { FloorPlanHelper } from 'app/ts/util/FloorPlanHelper';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';

export type LeftMenuDragInfo =
  | Client.FloorPlanProductDragInfo
  | Client.FloorPlanMeasurementDragInfo;

@Component({
  selector: 'floor-plan-vm',
  templateUrl: './floorPlan.html',
  styleUrls: ['../../style/editor.scss'],
  providers: [DeliveryAddressService],
})
export class FloorPlanVm extends EditorVm {
  public log(text: string) {
    console.log(text);
  }
  public readonly maxFloorPlanNameLength = Constants.maxfloorPlanNameLength;

  public showFloorPlanDetails$: Subject<boolean> = new Subject<boolean>();

  constructor(
    baseVmService: BaseVmService,
    floorPlanService: FloorPlanService,
    floorSavePlanService: FloorPlanSaveService,
    $window: WindowService,
    routeService: RouteService,
    routing: AppRoutingModule,
    private readonly loginService: LoginService,
    private readonly permissions: PermissionService,
    private readonly modalService: ModalService,
    private readonly clearPlanService: SecretService,
    private readonly endUserImportService: EndUserImportService,
    deliveryAddressService: DeliveryAddressService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    public readonly measurementService: MeasurementService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorSavePlanService,
      routeService,
      routing,
      deliveryAddressService,
    );

    super.floorplanLoaded$.subscribe((floorplan) => {
      this.floorplanLoaded(floorplan);
    });

    this.showFloorPlanDetails$.subscribe((val) => {
      this._showFloorPlanDetails = val;
      if (val) {
        this.selectedItem$.next(undefined);
      }
    });
  }

  private floorplanLoaded(floorplan: Client.FloorPlan) {
    this.prefillTranslation();
    this.floorPlanChanged();

    this._editorAssets = floorplan.editorAssets;
    this.products = floorplan.editorAssets.floorPlanProducts;
    this.selectedProductLine = this.getDefaultProductLine(
      floorplan.editorAssets,
    );

    this.selectedItem$.subscribe((item) => {
      this._selectedItem = item;

      if (item?.CabinetType == Interface_Enums.CabinetType.Partition) {
        let partitions = this.partitionQueryService.getAllPartitions();
        let cabinetPartitions = partitions.map((p) => {
          let parCab = {
            Id: p.id.toString(),
            Name: p.name,
            Path: 'partition/' + p.id.toString() + '/fillings',
            MaxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
            CabinetType: Interface_Enums.CabinetType.Partition,
            floorPlan: floorplan,
          };
          return parCab;
        });

        let selectedPar = this.partitionQueryService.getPartitionForSection(
          item.partition!.section,
        );
        let selectedPartition = cabinetPartitions.filter(
          (par) => par.Id == selectedPar.id.toString(),
        )[0] as unknown as Client.Cabinet;
        if (partitions.length == 1) {
          selectedPartition = cabinetPartitions[0] as unknown as Client.Cabinet;
        }

        let partition = this.partitionQueryService.getPartitionForSection(
          item.CabinetIndex as SectionId,
        );

        let parSecs =
          this.partitionQueryService.getAllSectionsForPartiion(partition); // Filter so it is only for selected Partition...
        let partitionSections = parSecs.map((s) => {
          let parSec = {
            Id: s.id,
            CabinetIndex: s.id as number,
            Name: s.id.toString(),
            Path: 'partition/' + s.id.toString() + '/fillings', // /Partition/id/fillings
            CabinetType: Interface_Enums.CabinetType.Partition,
          };
          return parSec;
        }) as unknown as Client.CabinetSection[];

        selectedPartition.cabinetSections = partitionSections;

        this._selectedProduct = item ? this.products[item.ItemType] : null;
        this._selectedCabinet = selectedPartition
          ? selectedPartition
          : undefined;
      } else {
        this._selectedProduct = item ? this.products[item.ItemType] : null;
        this._selectedCabinet = item
          ? item.CabinetIndex !== null
            ? this.floorPlan.cabinets.filter(
                (cab) => cab.CabinetIndex == item.CabinetIndex,
              )[0]
            : undefined
          : undefined;
      }

      this._selectedCabinetVm = this.selectedCabinet
        ? new FloorPlanVm.CabinetVm(
            this.selectedCabinet,
            item!,
            this.baseVmService.translationService,
            () => this.setChanged(),
          )
        : undefined;

      if (item) {
        this.showFloorPlanDetails = false;
      }
    });

    super.ensureUnsubscribe(
      this.loginService.salesChainSettings$.subscribe((settings) => {
        this.displayImportEndUser =
          settings[Interface_Enums.SalesChainSettingKey.AllowWebImport] == '1';
      }),
    );

    super.ensureUnsubscribe(
      this.baseVmService.translationService.translationsUpdated$.subscribe(
        () => {
          this.cache.productPickables = undefined;
          this.changeDetector.detectChanges();
        },
      ),
    );
  }

  @HostListener('window:keydown', ['$event'])
  protected override handleKeyboardEvent(evt: KeyboardEvent | null) {
    if (this.isDragging) return;

    if (evt == null) return;

    if (evt.code === 'Delete' || evt.key === 'Del') {
      this.deleteSelectedItem();
    }

    super.handleKeyboardEvent(evt);
  }

  public override undo() {
    if (this.isDragging) return;

    this.selectedItem$.next(undefined);
    super.undo();
  }

  public override redo() {
    if (this.isDragging) return;

    this.selectedItem$.next(undefined);
    super.redo();
  }

  // Updated from FloorPlanImageVM via Emitter
  public isDragging: boolean = false;

  private isVisible(product: Client.FloorPlanProduct): boolean {
    if (!this._editorAssets || !this.selectedProductLine) {
      return false;
    }
    let settings = this._editorAssets.salesChainSettings;
    if (product.visibilitySettingKey !== undefined) {
      //check if salesChain allows the cabinet type
      if (settings[product.visibilitySettingKey] !== '1') return false;
    }
    if (product.cabinetType !== null) {
      return FloorPlanLogic.isValidCombination(
        this.selectedProductLine,
        product.cabinetType,
      );
    } else {
      return (
        this.selectedProductLine === Enums.PseudoProductLine.RoomAccessories
      );
    }
  }

  public readonly selectedItem$ = new BehaviorSubject<
    Interface_DTO_FloorPlan.Item | undefined
  >(undefined);
  private _selectedItem: Interface_DTO_FloorPlan.Item | undefined;

  private cache: {
    cornerVms?: CornerVm[];
    productPickables?: Pickable<Client.FloorPlanProduct>[];
  } = {};

  public displayRightMenu: boolean = true;
  public displayImportEndUser: boolean = false;
  public products!: { [id: number]: Client.FloorPlanProduct };

  public readonly currentFloorPlanDragInfo = new BehaviorSubject<
    LeftMenuDragInfo | undefined
  >(undefined);
  public selectedProductLinePickable: Pickable<
    Interface_DTO.ProductLine | undefined | Enums.PseudoProductLine
  > = new Client.DynamicPickable({
    disabledReason: () => null,
    id: () => '',
    imageUrl: () => {
      let spl = this.selectedProductLine;
      if (!spl) {
        return this.baseVmService.translationService.translateProductLineImageUrl(
          null,
        );
      } else if (typeof spl === 'object') {
        return this.baseVmService.translationService.translateProductLineImageUrl(
          spl.Id,
        );
      } else {
        return this.baseVmService.translationService.translatePseudoProductLineImageUrl(
          spl,
        );
      }
    },
    isSelected: () => true,
    item: () => this.selectedProductLine,
    name: () => {
      let spl = this.selectedProductLine;
      if (!spl) {
        return '';
      } else if (typeof spl === 'object') {
        return spl.Name;
      } else {
        return this.translate('productline_selector_pseudo_name_' + spl, spl);
      }
    },
    override: () => false,
    description: () => undefined,
    groupName: () => undefined,
    warnReason: () => undefined,
  });

  private _selectedProduct: Client.FloorPlanProduct | null = null;
  private _selectedCabinet: Client.Cabinet | undefined = undefined;
  private _selectedCabinetVm: FloorPlanVm.CabinetVm | undefined = undefined;
  private _selectedProductLine:
    | Interface_DTO.ProductLine
    | undefined
    | Enums.PseudoProductLine = undefined;
  private _showFloorPlanDetails: boolean = true;
  private _editorAssets!: Client.EditorAssets;

  public showMeasurements: boolean = true;
  public showOrigin: boolean = false;

  public floorPlanChanged() {
    this.cache = {};
    this.selectedItem$.next(this.selectedItem$.value);
  }

  public get selectedProductLine() {
    return this._selectedProductLine;
  }
  public set selectedProductLine(
    val: Interface_DTO.ProductLine | undefined | Enums.PseudoProductLine,
  ) {
    this._selectedProductLine = val;
    this.cache = {};
  }

  public get productPickables(): Pickable<Client.FloorPlanProduct>[] {
    if (!this.cache.productPickables) {
      this.cache.productPickables = [];
      for (let productId in this.products) {
        let product = this.products[productId];
        if (this.isVisible(product)) {
          let name;
          if (product.itemType == Interface_DTO_FloorPlan.ItemType.Partition) {
            let partitionProductLine = this._editorAssets.productLines.find(
              (pl) => pl.Id == Interface_Enums.ProductLineId.Partition,
            );
            name = this.translate(
              'floorplan_product_name_' + productId + ' {0}',
              '{0}',
              partitionProductLine?.Name ?? 'Partition',
            );
          } else {
            name = this.translate(
              'floorplan_product_name_' + productId,
              product.name,
            );
          }

          this.cache.productPickables.push({
            id: ' ',
            imageUrl: product.picturePath, // WTF: imageUrl = picturePath
            item: product,
            isSelected: false,
            name: name,
            disabledReason: null,
            override: false,
          });
        }
      }
    }
    return this.cache.productPickables;
  }

  public get selectedItem() {
    return this._selectedItem;
  }

  public get isCabinetSelected(): boolean {
    return (
      this.selectedItem != undefined &&
      this.selectedItem.ItemType !=
        Interface_DTO_FloorPlan.ItemType.Partition &&
      this.selectedItem.ItemType != Interface_DTO_FloorPlan.ItemType.Measurement
    );
  }

  public get selectedProduct() {
    return this._selectedProduct;
  }
  public get selectedProductName(): string {
    if (this._selectedProduct)
      return this.translate(
        'floorplan_product_name_' + this._selectedProduct.name,
        this._selectedProduct.name,
      );
    else return '';
  }
  public get selectedCabinet(): Client.Cabinet | undefined {
    return this._selectedCabinet;
  }
  public get selectedCabinetVm() {
    return this._selectedCabinetVm;
  }

  public get showFloorPlanDetails() {
    return this._showFloorPlanDetails;
  }
  public set showFloorPlanDetails(val: boolean) {
    this._showFloorPlanDetails = val;
    if (val) {
      this.selectedItem$.next(undefined);
      // this.selectedItem$.value = undefined;
    }
  }

  public get showFullCatalogFeatures() {
    return this.floorPlan.UsesFullCatalog && this.permissions.canUseFullCatalog;
  }

  public get fullCatalogTallCabinets() {
    return this.floorPlan.FullCatalogTallCabinets;
  }
  public set fullCatalogTallCabinets(val: boolean) {
    this.floorPlan.FullCatalogTallCabinets = val;
    this.setChanged();
  }

  public get fullCatalogMuteErrors() {
    return this.floorPlan.FullCatalogMuteErrors;
  }
  public set fullCatalogMuteErrors(val: boolean) {
    this.floorPlan.FullCatalogMuteErrors = val;
    this.setChanged();
  }

  public get fullCatalogAllowOtherProducts() {
    return this.floorPlan.FullCatalogAllowOtherProducts;
  }
  public set fullCatalogAllowOtherProducts(val: boolean) {
    this.floorPlan.FullCatalogAllowOtherProducts = val;
    if (val) this.floorPlan.FullCatalogAllowOtherMaterials = true;
    this.setChanged();
  }

  public get fullCatalogAllowOtherMaterials() {
    return this.floorPlan.FullCatalogAllowOtherMaterials;
  }
  public set fullCatalogAllowOtherMaterials(val: boolean) {
    this.floorPlan.FullCatalogAllowOtherMaterials = val;
    if (!val) this.floorPlan.FullCatalogAllowOtherProducts = false;
    this.setChanged();
  }

  public get fullCatalogWideDoors() {
    return this.floorPlan.FullCatalogWideDoors;
  }
  public set fullCatalogWideDoors(val: boolean) {
    this.floorPlan.FullCatalogWideDoors = val;
    this.setChanged();
  }

  public selectedItemChanged() {
    if (
      this.selectedItem &&
      this.selectedProduct &&
      this.selectedProduct.depthFollowsLength
    ) {
      this.selectedItem.Height = this.selectedItem.Width;
    }
    this.setChanged();
  }

  public editSelectedCabinet() {
    let cab = this.selectedCabinet;
    if (!cab) return;

    let editorType = 'corpus';
    if (cab.CabinetType == Interface_Enums.CabinetType.Swing) {
      editorType = 'swing';
    }

    let url = `/floorPlan/${this.floorPlan.Id}/${cab.CabinetIndex}/${cab.cabinetSections[0].CabinetSectionIndex}/${editorType}`;
    this.router.navigateByUrl(url);
  }

  public async selectProductLine() {
    let productLines = this._editorAssets.productLines;
    if (!this._editorAssets.fullCatalog) {
      const now = new Date();

      productLines = productLines.filter((pl) => {
        if (pl.OverrideChain) return false;
        if (!pl.Expires) return true;
        const expires = new Date(pl.Expires);
        expires.setDate(expires.getDate() + 1);
        if (expires > now) return true;
        return false;
      });
    }
    let productLine;

    try {
      productLine = await this.modalService.getSelectProductLineModal([
        ...productLines,
        Enums.PseudoProductLine.RoomAccessories,
      ]);
    } catch (e: any) {
      //modal cancelled
      return;
    }

    this.selectedProductLine = productLine;
  }

  public get floorPlanName() {
    return this.floorPlan.Name;
  }

  public set floorPlanName(val: string) {
    val = ObjectHelper.sanitizeString(val);
    if (val != this.floorPlanName) {
      this.floorPlan.Name = val;
      this.setChanged();
    }
  }

  public get floorPlanSizeX() {
    return this.floorPlan.Size.X;
  }
  public set floorPlanSizeX(val: number) {
    if (val != this.floorPlanSizeX) {
      this.floorPlan.Size.X = val;
      this.setChanged();
    }
  }

  public get floorPlanSizeY() {
    return this.floorPlan.Size.Y;
  }
  public set floorPlanSizeY(val: number) {
    if (val != this.floorPlanSizeY) {
      this.floorPlan.Size.Y = val;
      this.setChanged();
    }
  }

  public get floorPlanSizeZ() {
    return this.floorPlan.Size.Z;
  }

  public set floorPlanSizeZ(val: number) {
    if (val == this.floorPlanSizeZ) return;

    const displayHeightReminder = this.floorPlan.actualCabinets.some(
      (c) =>
        c.cabinetSections[0].Height >= this.floorPlan.Size.Z ||
        c.CabinetSections[0].Height > val,
    );
    this.floorPlan.Size.Z = val;
    this.partitionCommandService.updatePartitionHeights(val);
    this.setChanged();

    if (displayHeightReminder) {
      let msg = this.translate(
        'floorplan_room_height_reminder',
        'Ceiling height changed. Remember to change the height of cabinets as needed.',
      );
      window.alert(msg);
    }
  }

  public get corners(): CornerVm[] {
    if (!this.cache.cornerVms) {
      this.cache.cornerVms = this.floorPlan.corners.map(
        (corn) => new CornerVm(corn, () => this.setChanged()),
      );
    }
    return this.cache.cornerVms;
  }

  public get clearPlanText(): string {
    return this.clearPlanService.isRunning$.value ? 'Stop' : 'Start';
  }

  public clearPlan() {
    this.clearPlanService.setIsRunning(!this.clearPlanService.isRunning$.value);
  }

  public get showClearPlan(): boolean {
    return this.clearPlanService.isRunning$.value;
  }

  public measurementMouseDown(evt: MouseEvent) {
    evt.preventDefault();
    this.currentFloorPlanDragInfo.next({
      touchObservable: null,
    } as Client.FloorPlanMeasurementDragInfo);
  }
  public productMouseDown(
    product: Client.FloorPlanProduct,
    evt: MouseEvent,
  ): void {
    evt.preventDefault();
    if (!this.selectedProductLine) {
      throw new Error('No product line selected');
    }
    this.currentFloorPlanDragInfo.next({
      product: product,
      touchObservable: null,
      productLine: this.selectedProductLine,
    });
  }

  public productMouseUp(evt: MouseEvent) {
    this.currentFloorPlanDragInfo.next(undefined);
  }

  // #region touch events

  private ongoingTouchObservable?: BehaviorSubject<TouchEvent | undefined>;
  public touchStart(evt: TouchEvent, product?: Client.FloorPlanProduct) {
    if (App.debug.showTouchMessages) {
      console.debug('floorPlanVm touchStart');
    }
    if (!this.selectedProductLine) {
      throw new Error('No product line selected');
    }
    if (this.ongoingTouchObservable) {
      this.ongoingTouchObservable.next(evt);
    } else {
      this.ongoingTouchObservable = new BehaviorSubject<TouchEvent | undefined>(
        evt,
      );
      if (product)
        this.currentFloorPlanDragInfo.next({
          product: product,
          touchObservable: this.ongoingTouchObservable,
          productLine: this.selectedProductLine,
        });
      else
        this.currentFloorPlanDragInfo.next({
          touchObservable: this.ongoingTouchObservable,
        } as Client.FloorPlanMeasurementDragInfo);
    }
  }
  public touchMove(evt: TouchEvent) {
    if (this.ongoingTouchObservable) {
      this.ongoingTouchObservable.next(evt);
    }
  }
  public touchEnd(evt: TouchEvent) {
    if (App.debug.showTouchMessages) {
      console.debug('FloorPlanVm touchEnd');
    }
    if (evt.touches.length > 0) {
      //Touch is not done, but a finger has been lifted
      return;
    }
    if (this.ongoingTouchObservable) {
      this.ongoingTouchObservable = undefined;
    }
  }
  public touchCancel(evt: TouchEvent) {
    this.touchEnd(evt);
  }

  // #endregion touch events

  public deleteSelectedItem(): void {
    if (!this.selectedItem) return;
    if (
      this.selectedItem.CabinetIndex !== null ||
      this.selectedItem.partition ||
      this.selectedItem.measurement
    ) {
      let sure = confirm(
        this.translate(
          'floorPlan_confirm_delete_question',
          'Are you sure you want to delete this?',
        ),
      );
      if (!sure) return;
    }

    if (this.selectedItem.measurement) {
      this.measurementService.deleteSelected();
    } else if (this.selectedItem.partition) {
      //delete partition
      this.partitionCommandService.deleteSection(
        this.selectedItem.partition.section,
      );
    } else {
      //delete cabinet
      this.floorPlanService.deleteItem(this.floorPlan, this.selectedItem);
    }
    console.log('Delete selected section');
    this.selectedItem$.next(undefined);
    this.showFloorPlanDetails = true;
    this.setChanged();
  }

  public async endUserImport() {
    let setupOverview = await this.modalService.importEndUserSetup();
    let cabinet = await this.baseVmService.notificationService.loadPromise(
      this.endUserImportService.importIntoFloorPlan(
        this.floorPlan,
        setupOverview,
      ),
    );

    this.setChanged();
  }

  protected override dispose() {
    super.dispose();
  }

  private getDefaultProductLine(
    editorAssets: Client.EditorAssets,
  ): Interface_DTO.ProductLine {
    let result = Enumerable.from(editorAssets.productLines).singleOrDefault(
      (pl) => pl.IsDefault,
    );
    if (!result) result = editorAssets.productLines[0];
    return result;
  }

  private prefillTranslation() {
    this.translate('floorPlan_corner_position_0', 'North East');
    this.translate('floorPlan_corner_position_1', 'North West');
    this.translate('floorPlan_corner_position_2', 'South West');
    this.translate('floorPlan_corner_position_3', 'South East');
    this.translate('floorplan_corner_type_0', '90 degree');
    this.translate('floorplan_corner_type_1', '45 degree cut');
    this.translate('floorplan_corner_type_2', '90 degree cut');
  }

  public trackCabinetSectionByIndex(index: number, section: any) {
    return index;
  }

  public updateSectionWidth(event: any, section: FloorPlanVm.CabinetSectionVm) {
    section.width = event.target.valueAsNumber;
    event.target.value = section.width;
  }
  public updateSectionHeight(
    event: any,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    section.height = event.target.valueAsNumber;
    event.target.value = section.height;
  }
  public updateSectionDepth(event: any, section: FloorPlanVm.CabinetSectionVm) {
    section.depth = event.target.valueAsNumber;
    event.target.value = section.depth;
  }
  public updateSectionInteriorDepth(
    event: any,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    section.interiorDepth = event.target.valueAsNumber;
    event.target.value = section.interiorDepth;
  }

  public testPrint(test: Interface_DTO_FloorPlan.Item) {
    console.log(test);
    this.floorPlanService.deleteItem(this.floorPlan, test);
  }

  public valueForLeft(
    val: Vec2d,
    section: FloorPlanVm.CabinetSectionVm,
  ): number {
    let actualValue = 0;
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem?.Width) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          actualValue = val.Y;
          break;
        }
        case 90: {
          actualValue = val.X + this.selectedCabinet.wall.Begin.X;
          break;
        }
        case 180: {
          actualValue = this.floorPlan.Size.X - section.depth - val.Y;
          break;
        }
        case 270: {
          actualValue =
            this.floorPlan.Size.X -
            this.selectedItem.Width -
            val.X -
            (this.floorPlan.Size.X - this.selectedCabinet.wall.Begin.X);
          break;
        }
      }
    }
    return Math.round(actualValue);
  }

  public valueForRight(
    val: Vec2d,
    section: FloorPlanVm.CabinetSectionVm,
  ): number {
    let actualValue = 0;
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem?.Width) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          actualValue = this.floorPlan.Size.X - section.depth - val.Y;
          break;
        }
        case 90: {
          actualValue =
            this.floorPlan.Size.X -
            this.selectedItem.Width -
            val.X -
            this.selectedCabinet.wall.Begin.X;
          break;
        }
        case 180: {
          actualValue = val.Y;
          break;
        }
        case 270: {
          actualValue =
            val.X + (this.floorPlan.Size.X - this.selectedCabinet.wall.Begin.X);
          break;
        }
      }
    }
    return Math.round(actualValue);
  }

  public valueForTop(
    val: Vec2d,
    section: FloorPlanVm.CabinetSectionVm,
  ): number {
    let actualValue = 0;
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem?.Width) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          actualValue =
            this.floorPlan.Size.Y -
            this.selectedItem.Width -
            val.X -
            (this.floorPlan.Size.Y - this.selectedCabinet.wall.Begin.Y);
          break;
        }
        case 90: {
          actualValue = val.Y;
          break;
        }
        case 180: {
          actualValue = val.X + this.selectedCabinet.wall.Begin.Y;
          break;
        }
        case 270: {
          actualValue = this.floorPlan.Size.Y - section.depth - val.Y;
          break;
        }
      }
    }
    return Math.round(actualValue);
  }

  public valueForBottom(
    val: Vec2d,
    section: FloorPlanVm.CabinetSectionVm,
  ): number {
    let actualValue = 0;
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          actualValue =
            val.X + (this.floorPlan.Size.Y - this.selectedCabinet.wall.Begin.Y);
          break;
        }
        case 90: {
          actualValue = this.floorPlan.Size.Y - section.depth - val.Y;
          break;
        }
        case 180: {
          actualValue =
            this.floorPlan.Size.Y -
            this.selectedItem.Width -
            val.X -
            this.selectedCabinet.wall.Begin.Y;
          break;
        }
        case 270: {
          actualValue = val.Y;
          break;
        }
      }
    }
    return Math.round(actualValue);
  }

  public updateCabinetPositionLeft(
    val: number,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          this.selectedItem.Y = val;
          break;
        }
        case 90: {
          this.selectedItem.X = val - this.selectedCabinet.wall.Begin.X;
          break;
        }
        case 180: {
          this.selectedItem.Y = this.floorPlan.Size.X - section.depth - val;
          break;
        }
        case 270: {
          this.selectedItem.X =
            this.floorPlan.Size.X -
            this.selectedItem.Width -
            val -
            (this.floorPlan.Size.X - this.selectedCabinet.wall.Begin.X);
          break;
        }
      }
      this.floorPlan.triggerUpdate();
    }
  }

  public updateCabinetPositionRight(
    val: number,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          this.selectedItem.Y = this.floorPlan.Size.X - section.depth - val;
          break;
        }
        case 90: {
          this.selectedItem.X =
            this.floorPlan.Size.X -
            this.selectedItem.Width -
            this.selectedCabinet.wall.Begin.X -
            val;
          break;
        }
        case 180: {
          this.selectedItem.Y = val;
          break;
        }
        case 270: {
          this.selectedItem.X =
            val - (this.floorPlan.Size.X - this.selectedCabinet.wall.Begin.X);
          break;
        }
      }
      this.floorPlan.triggerUpdate();
    }
  }

  public updateCabinetPositionTop(
    val: number,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          this.selectedItem.X =
            this.selectedCabinet.wall.Begin.Y - this.selectedItem.Width - val;
          break;
        }
        case 90: {
          this.selectedItem.Y = val;
          break;
        }
        case 180: {
          this.selectedItem.X = val - this.selectedCabinet.wall.Begin.Y;
          break;
        }
        case 270: {
          this.selectedItem.Y = this.floorPlan.Size.Y - section.depth - val;
          break;
        }
      }
      this.floorPlan.triggerUpdate();
    }
  }

  public updateCabinetPositionBottom(
    val: number,
    section: FloorPlanVm.CabinetSectionVm,
  ) {
    let rotation = 0;
    if (this.selectedCabinet && this.selectedItem) {
      rotation =
        FloorPlanHelper.getRotation(this.selectedCabinet.wall) *
        (180 / Math.PI);
      switch (rotation) {
        case 0: {
          this.selectedItem.X =
            val - (this.floorPlan.Size.Y - this.selectedCabinet.wall.Begin.Y);
          break;
        }
        case 90: {
          this.selectedItem.Y = this.floorPlan.Size.Y - section.depth - val;
          break;
        }
        case 180: {
          this.selectedItem.X =
            this.floorPlan.Size.Y -
            this.selectedCabinet.wall.Begin.Y -
            this.selectedItem.Width -
            val;
          break;
        }
        case 270: {
          this.selectedItem.Y = val;
          break;
        }
      }
      this.floorPlan.triggerUpdate();
    }
  }
}

export module FloorPlanVm {
  export class CabinetVm {
    constructor(
      private readonly cabinet: Client.Cabinet,
      private readonly item: Interface_DTO_FloorPlan.Item,
      private readonly translationService: TranslationService,
      public readonly setChanged: () => void,
    ) {
      let actualCabinets = this.cabinet.cabinetSections.filter((section) => {
        if (section.CabinetType === Interface_Enums.CabinetType.None)
          return false;
        if (section.CabinetType === Interface_Enums.CabinetType.SharedItems)
          return false;
        return true;
      });
      this.sections = actualCabinets.map((cs, index) => {
        let sectionItem = index === 0 ? item : undefined;
        return new CabinetSectionVm(cs, () => this.setChanged(), sectionItem);
      });

      // this.sections = this.cabinet.actualCabinetSections.map((cs, index) => {
      //   let sectionItem = index === 0 ? item : undefined;
      //   return new CabinetSectionVm(cs, () => this.setChanged(), sectionItem)
      // });
    }

    // TODO: Figure out where this is supposed to get the value from
    // It is referred from floorPlan.html but doesn't exist anywhere else
    public get isMirrorable(): boolean {
      return false;
    }

    // TODO: Figure out where this is supposed to get the value from
    // It is referred from floorPlan.html but doesn't exist anywhere else
    public isMirrored: boolean = false;

    public get showInteriorDepth(): boolean {
      if (this.cabinet.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
        return false;
      }

      return true;
    }

    public get name() {
      return this.cabinet.Name;
    }
    public set name(val: string) {
      val = ObjectHelper.sanitizeString(val);
      this.cabinet.Name = val;
      this.setChanged();
    }

    public readonly sections: CabinetSectionVm[];

    public rotateCCW() {
      this.cabinet.Rotation += 90;
      this.setChanged();
    }
    public rotateCW() {
      this.cabinet.Rotation -= 90;
      this.setChanged();
    }
  }

  export class CabinetSectionVm {
    constructor(
      private readonly section: Client.CabinetSection,
      public readonly setChanged: () => void,
      private readonly item?: Interface_DTO_FloorPlan.Item,
    ) {}

    public get cabinetSectionIndex() {
      return this.section.CabinetSectionIndex;
    }
    public get width() {
      return this.section.Width;
    }
    public set width(val: number) {
      if (this.item) this.item.Width = val;
      this.section.Width = val;
      this.setChanged();
    }

    public get depth() {
      return this.section.Depth;
    }
    public set depth(val: number) {
      this.section.Depth = val;
      if (this.item) this.item.Height = this.section.Depth;
      this.setChanged();
    }

    public get minDepth(): number {
      return this.section.minDepth;
    }
    public get maxDepth(): number {
      return this.section.maxDepth;
    }

    public get isDepthUserAdjustable(): boolean {
      return (
        this.section.cabinet.minDepth < this.section.cabinet.maxDepth &&
        this.section.CabinetType !== Interface_Enums.CabinetType.Doors
      );
    }

    public get height() {
      return this.section.Height;
    }
    public set height(val: number) {
      this.section.Height = val;
      this.setChanged();
    }

    public get interiorDepth() {
      return this.section.InteriorDepth;
    }
    public set interiorDepth(val: number) {
      this.section.InteriorDepth = val;
      this.setChanged();
    }
    public get minInteriorDepth() {
      return this.section.minInteriorDepth;
    }
    public get maxInteriorDepth() {
      return this.section.maxInteriorDepth;
    }
  }
}
