import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BackingService {
  constructor() {}

  public static getStandardSectionDepth(
    backingType: Interface_Enums.BackingType,
    backingDepth: number,
  ): number {
    switch (backingType) {
      case Interface_Enums.BackingType.None:
        return Constants.defaultCabinetDepth;
      case Interface_Enums.BackingType.Hidden:
        return (
          Constants.defaultCabinetDepth + Constants.BackingFittingExtraDepth
        );
      case Interface_Enums.BackingType.Visible:
        return (
          Constants.defaultCabinetDepth +
          backingDepth +
          Constants.BackingFittingExtraDepth
        );
      default:
        throw new Error('Unknown backing type: ' + backingType);
    }
  }
}
