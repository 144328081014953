import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { Product } from 'app/ts/clientDto/Product';
import { LightingProduct } from 'app/ts/clientDto/LightingProduct';
export class CorpusPanel {
  public readonly overrideChain: boolean;

  public constructor(
    private readonly dto: Interface_DTO.CorpusPanel,
    public readonly products: Product[],
    public readonly lightingProducts: LightingProduct[],
  ) {
    this.overrideChain = products.some((p) => p.overrideChain);
  }

  //#region DTO mappings

  /*
        The following regex + replacement string was used to generate this in notepad++: But from where...??????? (sigh)

         ^([a-zA-Z0-9]+)\: ([^\;]+)\;$
         get $1\(\) { return this.dto.$1; }\nset $1\(val: $2\) { if \(val === this.dto.$1\) return; this.dto.$1 = val; this.isDirty = true;}\n
        */

  get CorpusPanelProducts() {
    return this.dto.CorpusPanelProducts;
  }

  get DefaultName() {
    return this.dto.DefaultName;
  }

  get ExteriorSize() {
    return this.dto.ExteriorSize;
  }

  get Id() {
    return this.dto.Id;
  }

  get InteriorSize() {
    return this.dto.InteriorSize;
  }

  /**
   * Top or bottom panel is full size when it covers exterior gables
   * */
  get IsFullSize() {
    return this.dto.IsFullSize;
  }

  get LightingProducts() {
    return this.dto.LightingProducts;
  }

  get MaxSize() {
    return this.dto.MaxSize;
  }

  get Number() {
    return this.dto.Number;
  }

  get Position() {
    return this.dto.Position;
  }

  get SortOrder() {
    return this.dto.SortOrder;
  }

  //#endregion DTO mappings

  get isFlexHeight(): boolean {
    return this.products.some((p) => ProductHelper.isFlexHeight(p, undefined));
  }
  public minHeight(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the height is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // For side panels, find the minimum of available minimum heights. For top or bottom panels, find the sum of all available minimum heights
    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? Math.min(
          ...this.products.map((p) => ProductHelper.minHeight(p, material)),
        )
      : this.products
          .map((p) => ProductHelper.minHeight(p, material))
          .reduce((a, b) => a + b);
  }
  public maxHeight(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the height is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // For side panels, find the minimum of available maximum heights. For top or bottom panels, find the sum of all available minimum heights
    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? Math.min(
          ...this.products.map((p) => ProductHelper.maxHeight(p, material)),
        )
      : this.products
          .map((p) => ProductHelper.maxHeight(p, material))
          .reduce((a, b) => a + b);
  }
  public defaultHeight(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the default height is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? Math.min(
          ...this.products.map((p) => ProductHelper.defaultHeight(p, material)),
        )
      : this.products
          .map((p) => ProductHelper.defaultHeight(p, material))
          .reduce((a, b) => {
            return a + b;
          });
  }

  get isFlexWidth(): boolean {
    return this.products.some((p) => ProductHelper.isFlexWidth(p));
  }
  public minWidth(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the width is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // For side panels, find the sum of all available minimum widths. For top or bottom panels, find the minimum of available minimum widths.
    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? this.products
          .map((p) => ProductHelper.minWidth(p, material))
          .reduce((a, b) => a + b)
      : Math.min(
          ...this.products.map((p) => ProductHelper.minWidth(p, material)),
        );
  }
  public maxWidth(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the width is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // For side panels, find the sum of all available maximum widths. For top or bottom panels, find the maximum of available maximum widths.
    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? this.products
          .map((p) => ProductHelper.maxWidth(p, material))
          .reduce((a, b) => a + b)
      : Math.max(
          ...this.products.map((p) => ProductHelper.maxWidth(p, material)),
        );
  }
  public defaultWidth(material: Interface_DTO.Material): number {
    // If there are no corpus panel products, the default width is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    return this.Position == Interface_Enums.CorpusPanelPosition.Side
      ? this.products
          .map((p) => ProductHelper.defaultWidth(p, material))
          .reduce((a, b) => {
            return a + b;
          })
      : Math.min(
          ...this.products.map((p) => ProductHelper.defaultWidth(p, material)),
        );
  }

  isFlexDepth2(productLineId: Interface_Enums.ProductLineId): boolean {
    return this.products.some((p) =>
      ProductHelper.isFlexDepth(p, productLineId),
    );
  }
  public minDepth(
    material: Interface_DTO.Material,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    // If there are no corpus panel products, the depth is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // Corpus items are never "stacked" front-to-back, only side-to-side or over-under
    // Therefore the minimum total depth is the maximum of all minimum depths
    return Math.max(
      ...this.products.map((p) =>
        ProductHelper.minDepth(p, productLineId, material),
      ),
    );
  }
  public maxDepth(
    material: Interface_DTO.Material,
    productLineId: Interface_Enums.ProductLineId,
  ): number {
    // If there are no corpus panel products, the depth is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    // Corpus items are never "stacked" front-to-back, only side-to-side or over-under
    // Therefore the maximum total depth is the maximum of all maximum depths
    return Math.max(
      ...this.products.map((p) =>
        ProductHelper.maxDepth(p, productLineId, material),
      ),
    );
  }
  public defaultDepth(
    productLineId: Interface_Enums.ProductLineId,
    material: Interface_DTO.Material,
  ): number {
    // If there are no corpus panel products, the default depth is 0...
    if (!this.products || this.products.length <= 0) {
      return 0;
    }

    return Math.max(
      ...this.products.map((p) =>
        ProductHelper.defaultDepth(p, productLineId, material),
      ),
    );
  }

  public extraDepth(
    productLineId: Interface_Enums.ProductLineId,
    material: Interface_DTO.Material,
  ): number {
    return Math.max(
      this.defaultDepth(productLineId, material) -
        Constants.defaultCabinetDepth,
      0,
    );
  }

  public isFullSize() {
    return this.dto.IsFullSize;
  }

  public isFullDepth(section: Client.CabinetSection): boolean {
    if (!section.corpus.material) return false;
    if (section.CabinetType === Interface_Enums.CabinetType.Doors) return false;
    return (
      this.maxDepth(section.corpus.material, section.cabinet.ProductLineId) >=
      section.Depth - section.backing.frontZ
    );
  }

  public get isNone(): boolean {
    return this.products.length <= 0;
  }

  public isHeightReductionPossible(
    productLineId: Interface_Enums.ProductLineId,
    override: boolean,
  ) {
    return this.products.some((p) =>
      ProductHelper.isHeightReductionPossible(p, productLineId, override),
    );
  }
}
