import { Component } from '@angular/core';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';

@Component({
  selector: 'validation-vm',
  templateUrl: 'validation.html',
  styleUrls: ['../../style/editor.scss', '../../style/errorInfo.scss'],
  providers: [DeliveryAddressService],
})
export class ValidationVm extends EditorVm {
  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
  }

  protected floorPlanChanged() {}

  public get anyErrors(): boolean {
    if (this.floorPlan.errorInfos.length > 0) return true;

    for (let cabinet of this.floorPlan.cabinets) {
      if (cabinet.errorInfos.length > 0) return true;

      for (let section of cabinet.cabinetSections)
        if (section.errorInfos.length > 0) return true;
    }
    return false;
  }

  public showCabinet(cabinet: Client.Cabinet): boolean {
    return (
      cabinet.errorInfos.length > 0 ||
      cabinet.cabinetSections.some((cs) => cs.errorInfos.length > 0)
    );
  }

  public hasCabinetSectionErrors(cabinet: Client.Cabinet): boolean {
    return cabinet.cabinetSections.some((cs) => cs.errorInfos.length > 0);
  }
}
