import { Injectable } from '@angular/core';
import {
  getWallLines,
  Line,
} from 'app/measurement/measurement-length-calculations';
import { Side } from 'app/ts/clientDto/Enums';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import { Rectangle, Vec2d } from 'app/ts/Interface_DTO_Draw';
import { AssetService } from 'app/ts/services/AssetService';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { Module, ModuleId } from './module';
import { PartitionPlan } from './partition-plan-data.service';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import {
  CornerProfile,
  ProfileId,
  SectionConnection,
  TProfile,
} from './profile';
import { Rail } from './rail';
import { SectionId } from './section';

@Injectable()
export class PartitionPlanGeometryQueryService {
  constructor(
    private query: PartitionPlanQueryService,
    private assetService: AssetService,
    private editorTypeService: EditorTypeService,
  ) {}

  public get plan(): PartitionPlan {
    return this.query.plan;
  }

  public isSectionsParallel(
    section1Id: SectionId,
    section2Id: SectionId,
  ): boolean {
    let section1 = this.query.getSection(section1Id);
    let section2 = this.query.getSection(section2Id);

    let rotationDiff = Math.abs(section1.rotation - section2.rotation);
    return rotationDiff == 0 || rotationDiff == 180;
  }

  /**
   * Gets the depth of a section depending on the module types
   */
  public getSectionDepth(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let modules = this.query
      .getAllModules()
      .filter((md) => md.sectionId == sectionId);

    let door = modules.find((md) => md.isDoor);
    // TODO: Needs refining of numbers vvv to match exact value
    return includeDoors && door ? door.depth * 2 : modules[0].depth;
  }

  public getRotationDiff(section1Id: SectionId, section2Id: SectionId): number {
    let section1 = this.query.getSection(section1Id);
    let section2 = this.query.getSection(section2Id);

    let diff = section1.rotation - section2.rotation;

    if (diff == 270) {
      diff -= 360;
    } else if (diff == -270) {
      diff += 360;
    }

    return diff;
  }

  public getSectionProfileRelativeXOffset(
    profileId: ProfileId,
    sectionId: SectionId,
  ): number {
    let section = this.query.getSection(sectionId);
    let profile = this.query.getProfile(profileId);

    let isJoint = this.query.isJointProfile(profile.id);

    let offset: number = 0;
    switch (section.rotation) {
      case 0:
        offset = profile.posX - section.posX;
        break;

      case 90:
        offset = profile.posY - section.posY;
        break;

      case 180:
        offset = section.posX - profile.posX;
        break;

      case 270:
        offset = section.posY - profile.posY;
        break;
    }

    let rotDiff = section.rotation - profile.rotation;
    if (!isJoint && (rotDiff == 180 || rotDiff == -180)) {
      offset -= this.query.getProfileSize(profile.id);
    } else if (isJoint) {
      let connection = profile.sections.find(
        (secCon) => secCon.sectionId == section.id,
      )!;

      if (connection.side == Side.Right) {
        offset -= this.query.getProfileSize(profile.id);
      }
    }

    return offset;
  }

  public getSectionMinAllowedX(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let modules = this.query.getModulesForSection(sectionId);
    let leftAngle = this.leftAngleOffset(sectionId, modules[0].id);
    let rightAngle = this.rightAngleOffset(sectionId, modules[0].id);
    switch (section.rotation) {
      default: //0
        return 0 + leftAngle;

      case 90:
        return this.getSectionDepth(sectionId) + Rail.railOverhang;

      case 180:
        return section.width + rightAngle;

      case 270:
        return Rail.railOverhang;
    }
  }

  public getSectionMaxAllowedX(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let modules = this.query.getModulesForSection(sectionId);
    let leftAngle = this.leftAngleOffset(sectionId, modules[0].id);
    let rightAngle = this.rightAngleOffset(sectionId, modules[0].id);
    switch (section.rotation) {
      default: //0
        return (
          this.editorTypeService.floorPlan.Size.X - section.width - rightAngle
        );

      case 90:
        return (
          this.editorTypeService.floorPlan.Size.X -
          Math.ceil(Rail.railOverhangPrecise)
        );

      case 180:
        return this.editorTypeService.floorPlan.Size.X - leftAngle;

      case 270:
        return (
          this.editorTypeService.floorPlan.Size.X -
          this.getSectionDepth(sectionId) -
          Math.ceil(Rail.railOverhangPrecise)
        );
    }
  }

  public getSectionMinAllowedY(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let modules = this.query.getModulesForSection(sectionId);
    let leftAngle = this.leftAngleOffset(sectionId, modules[0].id);
    let rightAngle = this.rightAngleOffset(sectionId, modules[0].id);
    switch (section.rotation) {
      default: //0
        return Rail.railOverhang;

      case 90:
        return 0 + leftAngle;

      case 180:
        return this.getSectionDepth(sectionId) + Rail.railOverhang;

      case 270:
        return section.width + rightAngle;
    }
  }

  public getSectionMaxAllowedY(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let modules = this.query.getModulesForSection(sectionId);
    let leftAngle = this.leftAngleOffset(sectionId, modules[0].id);
    let rightAngle = this.rightAngleOffset(sectionId, modules[0].id);
    switch (section.rotation) {
      default: //0
        return (
          this.editorTypeService.floorPlan.Size.Y -
          this.getSectionDepth(sectionId) -
          Math.ceil(Rail.railOverhangPrecise)
        );

      case 90:
        return (
          this.editorTypeService.floorPlan.Size.Y - section.width - rightAngle
        );

      case 180:
        return (
          this.editorTypeService.floorPlan.Size.Y -
          Math.ceil(Rail.railOverhangPrecise)
        );

      case 270:
        return this.editorTypeService.floorPlan.Size.Y - leftAngle;
    }
  }

  public calculateMultiDragMoveRangeX(draggedSectionId: SectionId): {
    min?: number;
    max?: number;
  } {
    let draggedSection = this.query.getSection(draggedSectionId);
    let { side1, side2 } =
      this.query.getMultiDragAffectedSections(draggedSectionId);

    let smaller: Omit<SectionConnection, 'profileId'>[] = [];
    let larger: Omit<SectionConnection, 'profileId'>[] = [];
    if (side1.length > 0) {
      let side1Section = this.query.getSection(side1[0].sectionId);
      smaller = side1Section.posX <= draggedSection.posX ? side1 : side2;
      larger = side1Section.posX <= draggedSection.posX ? side2 : side1;
    } else if (side2.length > 0) {
      let side2Section = this.query.getSection(side2[0].sectionId);
      smaller = side2Section.posX <= draggedSection.posX ? side2 : side1;
      larger = side2Section.posX <= draggedSection.posX ? side1 : side2;
    }

    let smallXVals = smaller.map(
      (connection) =>
        this.calculateOrthogonalX(
          draggedSectionId,
          connection.sectionId,
          connection.side,
        ) +
        (draggedSection.isInverted ? 0 : Module.fullJointWidth) +
        Module.elementSpacing,
    );
    let largeXVals = larger.map(
      (connection) =>
        this.calculateOrthogonalX(
          draggedSectionId,
          connection.sectionId,
          connection.side,
        ) -
        (draggedSection.isInverted ? Module.fullJointWidth : 0) -
        Module.elementSpacing,
    );

    let smallerThan = smallXVals.filter((v) => v <= draggedSection.posX);
    let largerThan = largeXVals.filter((v) => v >= draggedSection.posX);
    return {
      min: smallerThan.length > 0 ? Math.max(...smallerThan) : undefined,
      max: largerThan.length > 0 ? Math.min(...largerThan) : undefined,
    };
  }

  public calculateMultiDragMoveRangeY(draggedSectionId: SectionId): {
    min?: number;
    max?: number;
  } {
    let draggedSection = this.query.getSection(draggedSectionId);
    let { side1, side2 } =
      this.query.getMultiDragAffectedSections(draggedSectionId);

    let smaller: Omit<SectionConnection, 'profileId'>[] = [];
    let larger: Omit<SectionConnection, 'profileId'>[] = [];
    if (side1.length > 0) {
      let side1Section = this.query.getSection(side1[0].sectionId);
      smaller = side1Section.posY <= draggedSection.posY ? side1 : side2;
      larger = side1Section.posY <= draggedSection.posY ? side2 : side1;
    } else if (side2.length > 0) {
      let side2Section = this.query.getSection(side2[0].sectionId);
      smaller = side2Section.posY <= draggedSection.posY ? side2 : side1;
      larger = side2Section.posY <= draggedSection.posY ? side1 : side2;
    }

    let smallYVals = smaller.map(
      (connection) =>
        this.calculateOrthogonalY(
          draggedSectionId,
          connection.sectionId,
          connection.side,
        ) +
        (draggedSection.isInverted ? Module.fullJointWidth : 0) +
        Module.elementSpacing,
    );
    let largeYVals = larger.map(
      (connection) =>
        this.calculateOrthogonalY(
          draggedSectionId,
          connection.sectionId,
          connection.side,
        ) -
        (draggedSection.isInverted ? 0 : Module.fullJointWidth) -
        Module.elementSpacing,
    );

    let smallerThan = smallYVals.filter((v) => v <= draggedSection.posY);
    let largerThan = largeYVals.filter((v) => v >= draggedSection.posY);
    return {
      min: smallerThan.length > 0 ? Math.max(...smallerThan) : undefined,
      max: largerThan.length > 0 ? Math.min(...largerThan) : undefined,
    };
  }

  /**
   * Calculates the x value for section 2 with the biggest distance from section 1
   *
   * Eg.
   *
   *   |  <- section 1
   *   |
   *   #-----
   *   |
   *   |
   *   #-----
   *		^
   *		|
   * 		section 2
   *
   * @param section1 The vertical section
   * @param section2 The horizontal section
   * @param section2JoinSide The side at which section 2 is joined to the line of section 1
   * @returns The x value of the point on section 2 furthest away from section 1, unless the sections aren't orthogonal, then it returns -1
   */
  public calculateOrthogonalX(
    section1Id: SectionId,
    section2Id: SectionId,
    section2JoinSide: Side,
  ): number {
    let section1 = this.query.getSection(section1Id);
    let section2 = this.query.getSection(section2Id);

    if (!section1.isVertical || !section2.isHorizontal)
      // Sections are not orthogonal
      return -1;

    let minWidth = this.query.getSectionMinWidth(section2Id);
    let section2Dir = Math.sign(section2.posX - section1.posX);
    if (section2JoinSide == Side.Right)
      return section2.posX + section2Dir * -1 * minWidth;
    else {
      //Width needs to get added/subtracted to get outer most edge away from sec 1
      let widthModification = section2Dir * section2.width;
      return section2.posX + widthModification + section2Dir * -1 * minWidth;
    }
  }

  /**
   * Calculates the y value for section 2 with the biggest distance from section 1
   *
   * Eg.
   *
   * 	    |     | <-- section 2
   * 		|     |
   * -----#-----#
   *  ^
   *  |
   *  section 1
   *
   * @param section1 The horizontal section
   * @param section2 The vertical section
   * @param section2JoinSide The side at which section 2 is joined to the line of section 1
   * @returns The y value of the point on section 2 furthest away from section 1, unless the sections aren't orthogonal, then it returns -1
   */
  public calculateOrthogonalY(
    section1Id: SectionId,
    section2Id: SectionId,
    section2JoinSide: Side,
  ): number {
    let section1 = this.query.getSection(section1Id);
    let section2 = this.query.getSection(section2Id);

    if (!section1.isHorizontal || !section2.isVertical)
      // Sections are not orthogonal
      return -1;

    let minWidth = this.query.getSectionMinWidth(section2Id);
    let section2Dir = Math.sign(section2.posY - section1.posY);
    if (section2JoinSide == Side.Right)
      return section2.posY + section2Dir * -1 * minWidth;
    else {
      //Width needs to get added/subtracted to get outer most edge away from sec 1
      let widthModification = section2Dir * section2.width;
      return section2.posY + widthModification + section2Dir * -1 * minWidth;
    }
  }

  public calculateTPieceMiddleSectionMinX(sectionId: SectionId) {
    let xPositions = this.query
      .getAllProfiles()
      .filter(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
      .map((p) => {
        let profile = p as TProfile;
        let leftSection = this.query.getSection(profile.leftSection.sectionId);
        let rightSection = this.query.getSection(
          profile.rightSection.sectionId,
        );

        let leftSectionRotated =
          leftSection.rotation == 180 || leftSection.rotation == 270;
        let rightSectionRotated =
          rightSection.rotation == 180 || rightSection.rotation == 270;

        let leftSectionX = leftSectionRotated
          ? leftSection.posX - leftSection.width
          : leftSection.posX;
        let rightSectionX = rightSectionRotated
          ? rightSection.posX - rightSection.width
          : rightSection.posX;

        let minXPos = Math.min(leftSectionX, rightSectionX);

        let chosenSection =
          minXPos == leftSectionX ? leftSection.id : rightSection.id;

        let section = this.query.getSection(sectionId);
        let addAmount =
          section.rotation == 90 ? this.getSectionDepth(section.id) : 0;
        return (
          minXPos +
          this.query.getSectionMinWidth(chosenSection) +
          addAmount +
          Module.elementSpacing
        );
      });

    return Math.max(...xPositions);
  }

  public calculateTPieceMiddleSectionMaxX(sectionId: SectionId) {
    let xPositions = this.query
      .getAllProfiles()
      .filter(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
      .map((p) => {
        let profile = p as TProfile;
        let leftSection = this.query.getSection(profile.leftSection.sectionId);
        let rightSection = this.query.getSection(
          profile.rightSection.sectionId,
        );

        let leftSectionRotated =
          leftSection.rotation == 180 || leftSection.rotation == 270;
        let rightSectionRotated =
          rightSection.rotation == 180 || rightSection.rotation == 270;

        let leftSectionX = leftSectionRotated
          ? leftSection.posX
          : leftSection.posX + leftSection.width;
        let rightSectionX = rightSectionRotated
          ? rightSection.posX
          : rightSection.posX + rightSection.width;

        let maxXPos = Math.max(leftSectionX, rightSectionX);

        let chosenSection =
          maxXPos == leftSectionX ? leftSection.id : rightSection.id;

        let section = this.query.getSection(sectionId);
        let reduceAmount =
          section.rotation == 90 ? 0 : this.getSectionDepth(section.id);
        return (
          maxXPos -
          this.query.getSectionMinWidth(chosenSection) -
          reduceAmount -
          Module.elementSpacing
        );
      });

    return Math.min(...xPositions);
  }

  public calculateTPieceMiddleSectionMinY(sectionId: SectionId) {
    let yPositions = this.query
      .getAllProfiles()
      .filter(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
      .map((p) => {
        let profile = p as TProfile;
        let leftSection = this.query.getSection(profile.leftSection.sectionId);
        let rightSection = this.query.getSection(
          profile.rightSection.sectionId,
        );

        let leftSectionRotated =
          leftSection.rotation == 180 || leftSection.rotation == 270;
        let rightSectionRotated =
          rightSection.rotation == 180 || rightSection.rotation == 270;

        let leftSectionY = leftSectionRotated
          ? leftSection.posY - leftSection.width
          : leftSection.posY;
        let rightSectionY = rightSectionRotated
          ? rightSection.posY - rightSection.width
          : rightSection.posY;

        let minYPos = Math.min(leftSectionY, rightSectionY);

        let chosenSection =
          minYPos == leftSectionY ? leftSection.id : rightSection.id;

        let section = this.query.getSection(sectionId);
        let addAmount =
          section.rotation == 180 ? this.getSectionDepth(section.id) : 0;
        return (
          minYPos +
          this.query.getSectionMinWidth(chosenSection) +
          addAmount +
          Module.elementSpacing
        );
      });
    return Math.max(...yPositions);
  }

  public calculateTPieceMiddleSectionMaxY(sectionId: SectionId) {
    let yPositions = this.query
      .getAllProfiles()
      .filter(
        (p) => p instanceof TProfile && p.middleSection.sectionId == sectionId,
      )
      .map((p) => {
        let profile = p as TProfile;
        let leftSection = this.query.getSection(profile.leftSection.sectionId);
        let rightSection = this.query.getSection(
          profile.rightSection.sectionId,
        );

        let leftSectionRotated =
          leftSection.rotation == 180 || leftSection.rotation == 270;
        let rightSectionRotated =
          rightSection.rotation == 180 || rightSection.rotation == 270;

        let leftSectionY = leftSectionRotated
          ? leftSection.posY
          : leftSection.posY + leftSection.width;
        let rightSectionY = rightSectionRotated
          ? rightSection.posY
          : rightSection.posY + rightSection.width;

        let maxYPos = Math.max(leftSectionY, rightSectionY);

        let chosenSection =
          maxYPos == leftSectionY ? leftSection.id : rightSection.id;

        let section = this.query.getSection(sectionId);
        let reduceAmount =
          section.rotation == 180 ? 0 : this.getSectionDepth(section.id);
        return (
          maxYPos -
          this.query.getSectionMinWidth(chosenSection) -
          reduceAmount -
          Module.elementSpacing
        );
      });

    return Math.min(...yPositions);
  }

  public calculateSectionPosMovedAlongRelativeAxes(
    sectionId: SectionId,
    offsetX: number,
    offsetY: number,
  ): Vec2d {
    let section = this.query.getSection(sectionId);
    let pos: Vec2d = {
      X: section.posX,
      Y: section.posY,
    };

    switch (section.rotation) {
      case 0:
        pos.X += offsetX;
        pos.Y += offsetY;
        break;
      case 90:
        pos.X -= offsetY;
        pos.Y += offsetX;
        break;
      case 180:
        pos.X -= offsetX;
        pos.Y -= offsetY;
        break;
      case 270:
        pos.X += offsetY;
        pos.Y -= offsetX;
        break;
    }

    return pos;
  }

  calculateSectionPosXFromGlobalLeft(
    sectionId: SectionId,
    globalLeftX: number,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return globalLeftX + sectionDepth;
      case 180:
        return globalLeftX + section.width;
      case 270:
        return globalLeftX;
      default: //0 deg
        return globalLeftX;
    }
  }

  calculateSectionPosXFromGlobalRight(
    sectionId: SectionId,
    globalRightX: number,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return globalRightX;
      case 180:
        return globalRightX;
      case 270:
        return globalRightX - sectionDepth;
      default: //0 deg
        return globalRightX - section.width;
    }
  }

  calculateSectionPosYFromGlobalTop(
    sectionId: SectionId,
    globalTopY: number,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return globalTopY;
      case 180:
        return globalTopY + sectionDepth;
      case 270:
        return globalTopY + section.width;
      default: //0 deg
        return globalTopY;
    }
  }

  calculateSectionPosYFromGlobalBottom(
    sectionId: SectionId,
    globalBottomY: number,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return globalBottomY - section.width;
      case 180:
        return globalBottomY;
      case 270:
        return globalBottomY;
      default: //0 deg
        return globalBottomY - sectionDepth;
    }
  }

  /**
   * Always returns the x value of the left side of the section, no matter the rotation
   */
  getSectionGlobalLeftSideX(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posX - sectionDepth - Rail.railOverhang;
      case 180:
        return section.posX - section.width;
      case 270:
        return section.posX - Rail.railOverhang;
      default: //0 deg
        return section.posX;
    }
  }

  /**
   * Always returns the x value of the right side of the section, no matter the rotation
   */
  getSectionGlobalRightSideX(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posX + Rail.railOverhang; //Math.floor(Rail.railOverhangPrecise)
      case 180:
        return section.posX;
      case 270:
        return section.posX + sectionDepth + Rail.railOverhang; //Math.floor(Rail.railOverhangPrecise)
      default: //0 deg
        return section.posX + section.width;
    }
  }

  /**
   * Always returns the y value of the top side of the section, no matter the rotation
   */
  getSectionGlobalTopSideY(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);

    switch (section.rotation) {
      case 90:
        return section.posY;
      case 180:
        return section.posY - sectionDepth - Rail.railOverhang;
      case 270:
        return section.posY - section.width;
      default: //0 deg
        return section.posY - Rail.railOverhang;
    }
  }

  /**
   * Always returns the y value of the bottom side of the section, no matter the rotation
   */
  getSectionGlobalBottomSideY(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posY + section.width;
      case 180:
        return section.posY + Math.floor(Rail.railOverhang);
      case 270:
        return section.posY;
      default: //0 deg
        return section.posY + sectionDepth + Math.floor(Rail.railOverhang);
    }
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative bottom side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeBottomPoint(
    sectionId: SectionId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let section = this.query.getSection(sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) - offsetY,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetX,
        };
      case 180:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetX,
          Y: this.getSectionGlobalTopSideY(sectionId) - offsetY,
        };
      case 270:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) + offsetY,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetX,
          Y: this.getSectionGlobalBottomSideY(sectionId) + offsetY,
        };
    }
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative top side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeTopPoint(
    sectionId: SectionId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let section = this.query.getSection(sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetY,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetX,
        };
      case 180:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetX,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetY,
        };
      case 270:
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetY,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetX,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetY,
        };
    }
  }

  /**
   * Returns the relative rect along the relative bottom side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeBottomRect(
    sectionId: SectionId,
    width: number,
    height: number,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Rectangle {
    let section = this.query.getSection(sectionId);

    let relativeBottomPoint = this.getSectionRelativeBottomPoint(
      sectionId,
      offsetX,
      offsetY,
    );

    switch (section.rotation) {
      case 90:
        return {
          X: relativeBottomPoint.X - height,
          Y: relativeBottomPoint.Y,
          Width: height,
          Height: width,
        };

      case 180:
        return {
          X: relativeBottomPoint.X - width,
          Y: relativeBottomPoint.Y - height,
          Width: width,
          Height: height,
        };

      case 270:
        return {
          X: relativeBottomPoint.X,
          Y: relativeBottomPoint.Y - width,
          Width: height,
          Height: width,
        };

      default: //0 deg
        return {
          X: relativeBottomPoint.X,
          Y: relativeBottomPoint.Y,
          Width: width,
          Height: height,
        };
    }
  }

  /**
   * Returns the relative rect along the relative top side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeTopRect(
    sectionId: SectionId,
    width: number,
    height: number,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Rectangle {
    let section = this.query.getSection(sectionId);

    let relativeTopPoint = this.getSectionRelativeTopPoint(
      sectionId,
      offsetX,
      offsetY,
    );

    switch (section.rotation) {
      case 90:
        return {
          X: relativeTopPoint.X,
          Y: relativeTopPoint.Y,
          Width: height,
          Height: width,
        };

      case 180:
        return {
          X: relativeTopPoint.X - width,
          Y: relativeTopPoint.Y,
          Width: width,
          Height: height,
        };

      case 270:
        return {
          X: relativeTopPoint.X - height,
          Y: relativeTopPoint.Y - width,
          Width: height,
          Height: width,
        };

      default: //0 deg
        return {
          X: relativeTopPoint.X,
          Y: relativeTopPoint.Y - height,
          Width: width,
          Height: height,
        };
    }
  }

  /**
   * Always returns the x value ofthe left side of the module, no matter the rotation
   */
  getModuleGlobalLeftSideX(moduleId: ModuleId): number {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return section.posX - module.depth;
      case 180:
        return section.posX - module.posX - module.width;
      case 270:
        return section.posX;
      default: //0 deg
        return section.posX + module.posX;
    }
  }

  /**
   * Always returns the x value ofthe right side of the module, no matter the rotation
   */
  getModuleGlobalRightSideX(moduleId: ModuleId): number {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return section.posX;
      case 180:
        return section.posX - module.posX;
      case 270:
        return section.posX + module.depth;
      default: //0 deg
        return section.posX + module.posX + module.width;
    }
  }

  /**
   * Always returns the y value ofthe top side of the module, no matter the rotation
   */
  getModuleGlobalTopSideY(moduleId: ModuleId): number {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return section.posY + module.posX;
      case 180:
        return section.posY - module.depth;
      case 270:
        return section.posY - module.posX - module.width;
      default: //0 deg
        return section.posY;
    }
  }

  /**
   * Always returns the y value ofthe bottom side of the module, no matter the rotation
   */
  getModuleGlobalBottomSideY(moduleId: ModuleId): number {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return section.posY + module.posX + module.width;
      case 180:
        return section.posY;
      case 270:
        return section.posY - module.posX;
      default: //0 deg
        return section.posY + module.depth;
    }
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative left side of the module.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getModuleRelativeLeftPoint(
    moduleId: ModuleId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getModuleGlobalRightSideX(moduleId) - offsetY,
          Y: this.getModuleGlobalTopSideY(moduleId) + offsetX,
        };
      case 180:
        return {
          X: this.getModuleGlobalRightSideX(moduleId) - offsetX,
          Y: this.getModuleGlobalBottomSideY(moduleId) - offsetY,
        };
      case 270:
        return {
          X: this.getModuleGlobalLeftSideX(moduleId) + offsetY,
          Y: this.getModuleGlobalBottomSideY(moduleId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getModuleGlobalLeftSideX(moduleId) + offsetX,
          Y: this.getModuleGlobalTopSideY(moduleId) + offsetY,
        };
    }
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative right side of the module.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getModuleRelativeRightPoint(
    moduleId: ModuleId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let module = this.query.getModule(moduleId);
    let section = this.query.getSection(module.sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getModuleGlobalLeftSideX(moduleId) - offsetY,
          Y: this.getModuleGlobalBottomSideY(moduleId) + offsetX,
        };
      case 180:
        return {
          X: this.getModuleGlobalLeftSideX(moduleId) - offsetX,
          Y: this.getModuleGlobalTopSideY(moduleId) - offsetY,
        };
      case 270:
        return {
          X: this.getModuleGlobalRightSideX(moduleId) + offsetY,
          Y: this.getModuleGlobalTopSideY(moduleId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getModuleGlobalRightSideX(moduleId) + offsetX,
          Y: this.getModuleGlobalBottomSideY(moduleId) + offsetY,
        };
    }
  }

  public verticalBarsPositions(moduleId: ModuleId): number[] {
    let module = this.query.getAllModules().find((mod) => mod.id == moduleId)!;
    let section = this.query.getSection(module.sectionId);
    let product =
      this.assetService.editorAssets.productsDict[
        this.query.getDoorModuleProductId()
      ];
    let variant = product
      .getVariants(ProductLineIds.Partition)
      .find((v) => v.Number == VariantNumbers.VerticalBarCount)!;
    let selectedOption = variant.VariantOptions.find(
      (vo) => vo.Id == module.references.verticalBarOptionId,
    );

    let numBars = selectedOption ? parseInt(selectedOption.Number) : 0;
    if (numBars == 0) return [];

    let fillingWidth =
      (module.width - numBars * section.barHeight) / (numBars + 1);

    let positions: number[] = [];
    for (let i = 0; i < numBars; i++) {
      positions.push(fillingWidth * (i + 1) + section.barHeight * i);
    }

    return positions;
  }

  public getDistanceFromLeftWallToLeftSide(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let profile = section.isInverted
      ? this.query.getSectionRightProfile(sectionId)
      : this.query.getSectionLeftProfile(sectionId);

    // Add includes extra space for gap + joint profile when relevant
    let extra =
      section.isHorizontal &&
      (profile instanceof CornerProfile ||
        (profile instanceof TProfile &&
          profile.middleSection.sectionId == sectionId))
        ? Module.elementSpacing + Module.fullJointWidth + Rail.railOverhang
        : 0;

    let leftSide = this.getSectionGlobalLeftSideX(sectionId) - extra;

    let walls = getWallLines(this.editorTypeService.floorPlan.walls);
    let halfWidth = section.width / 2;
    let halfDepth = this.getSectionDepth(section.id) / 2;
    let sectionPoint: Vec2d = {
      X: leftSide + 10,
      Y:
        section.posY +
        (section.isVertical ? halfWidth : halfDepth) *
          (section.isInverted ? -1 : 1),
    };

    let sectionLine: Line = {
      start: {
        X: -100,
        Y: sectionPoint.Y,
      },
      end: sectionPoint,
    };

    let intersetionPoints: Vec2d[] = walls
      .map((line) => GeometryHelper.lineIntersectionPoint(sectionLine, line))
      .filter((point) => point != null) as Vec2d[];

    let point = intersetionPoints.sort((a, b) => b.X - a.X)[0];

    // outside floorplan, try looking towards middle
    if (point == undefined)
      point = this.getIntersectionTowardsCenter(walls, sectionPoint, false);

    // Still no luck
    if (point == undefined) return NaN;

    return leftSide - point.X;
  }

  public getDistanceFromRightWallToRightSide(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let profile = section.isInverted
      ? this.query.getSectionLeftProfile(sectionId)
      : this.query.getSectionRightProfile(sectionId);

    // Add includes extra space for gap + joint profile when relevant
    let extra =
      section.isHorizontal &&
      (profile instanceof CornerProfile ||
        (profile instanceof TProfile &&
          profile.middleSection.sectionId == sectionId))
        ? Module.elementSpacing + Module.fullJointWidth + Rail.railOverhang
        : 0;

    let rightSide = this.getSectionGlobalRightSideX(sectionId) + extra;

    let walls = getWallLines(this.editorTypeService.floorPlan.walls);
    let halfWidth = section.width / 2;
    let halfDepth = this.getSectionDepth(section.id) / 2;
    let sectionPoint: Vec2d = {
      X: rightSide - 10,
      Y:
        section.posY +
        (section.isVertical ? halfWidth : halfDepth) *
          (section.isInverted ? -1 : 1),
    };

    let sectionLine: Line = {
      start: sectionPoint,
      end: {
        X: this.editorTypeService.floorPlan.Size.X + 100,
        Y: sectionPoint.Y,
      },
    };

    let intersetionPoints: Vec2d[] = walls
      .map((line) => GeometryHelper.lineIntersectionPoint(sectionLine, line))
      .filter((point) => point != null) as Vec2d[];

    let point = intersetionPoints.sort((a, b) => a.X - b.X)[0];

    if (point == undefined)
      point = this.getIntersectionTowardsCenter(walls, sectionPoint, false);

    // Still no luck
    if (point == undefined) return NaN;

    return point.X - rightSide;
  }

  public getDistanceFromTopWallToTopSide(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let profile = section.isInverted
      ? this.query.getSectionRightProfile(sectionId)
      : this.query.getSectionLeftProfile(sectionId);

    // Add includes extra space for gap + joint profile when relevant
    let extra =
      section.isVertical &&
      (profile instanceof CornerProfile ||
        (profile instanceof TProfile &&
          profile.middleSection.sectionId == sectionId))
        ? Module.elementSpacing + Module.fullJointWidth + Rail.railOverhang
        : 0;

    let topSide = this.getSectionGlobalTopSideY(sectionId) - extra;

    let walls = getWallLines(this.editorTypeService.floorPlan.walls);
    let halfWidth = section.width / 2;
    let halfDepth = this.getSectionDepth(section.id) / 2;
    let direction;
    if (section.isHorizontal) direction = section.isInverted ? -1 : 1;
    else direction = section.isInverted ? 1 : -1;
    let sectionPoint: Vec2d = {
      X:
        section.posX +
        (section.isHorizontal ? halfWidth : halfDepth) * direction,
      Y: topSide + 10,
    };

    let sectionLine: Line = {
      start: {
        X: sectionPoint.X,
        Y: -100,
      },
      end: sectionPoint,
    };

    let intersetionPoints: Vec2d[] = walls
      .map((line) => GeometryHelper.lineIntersectionPoint(sectionLine, line))
      .filter((point) => point != null) as Vec2d[];

    let point = intersetionPoints.sort((a, b) => b.Y - a.Y)[0];

    if (point == undefined)
      point = this.getIntersectionTowardsCenter(walls, sectionPoint, true);

    // Still no luck
    if (point == undefined) return NaN;

    return topSide - point.Y;
  }

  public getDistanceFromBottomWallToBottomSide(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let profile = section.isInverted
      ? this.query.getSectionLeftProfile(sectionId)
      : this.query.getSectionRightProfile(sectionId);

    // Add includes extra space for gap + joint profile when relevant
    let extra =
      section.isVertical &&
      (profile instanceof CornerProfile ||
        (profile instanceof TProfile &&
          profile.middleSection.sectionId == sectionId))
        ? Module.elementSpacing + Module.fullJointWidth + Rail.railOverhang
        : 0;

    let bottomSide = this.getSectionGlobalBottomSideY(sectionId) + extra;

    let walls = getWallLines(this.editorTypeService.floorPlan.walls);
    let halfWidth = section.width / 2;
    let halfDepth = this.getSectionDepth(section.id) / 2;
    let direction;
    if (section.isHorizontal) direction = section.isInverted ? -1 : 1;
    else direction = section.isInverted ? 1 : -1;
    let sectionPoint: Vec2d = {
      X:
        section.posX +
        (section.isHorizontal ? halfWidth : halfDepth) * direction,
      Y: bottomSide - 10,
    };

    let sectionLine: Line = {
      start: sectionPoint,
      end: {
        X: sectionPoint.X,
        Y: this.editorTypeService.floorPlan.Size.Y + 100,
      },
    };

    let intersetionPoints: Vec2d[] = walls
      .map((line) => GeometryHelper.lineIntersectionPoint(sectionLine, line))
      .filter((point) => point != null) as Vec2d[];

    let point = intersetionPoints.sort((a, b) => a.Y - b.Y)[0];

    if (point == undefined)
      point = this.getIntersectionTowardsCenter(walls, sectionPoint, true);

    // Still no luck
    if (point == undefined) return NaN;

    return point.Y - bottomSide;
  }

  private getIntersectionTowardsCenter(
    walls: Line[],
    sectionPoint: Vec2d,
    vertical: boolean,
  ): Vec2d {
    let sectionLine: Line = {
      start: sectionPoint,
      end: vertical
        ? {
            X: sectionPoint.X,
            Y: this.editorTypeService.floorPlan.Size.Y / 2,
          }
        : {
            X: this.editorTypeService.floorPlan.Size.X / 2,
            Y: sectionPoint.Y,
          },
    };

    let intersetionPoints: Vec2d[] = walls
      .map((line) => GeometryHelper.lineIntersectionPoint(sectionLine, line))
      .filter((point) => point != null) as Vec2d[];

    return intersetionPoints[0];
  }

  public getDistanceFromRelativeLeftSide(sectionId: SectionId): number {
    let section = this.query.getSection(sectionId);
    switch (section.rotation) {
      case 0:
        return this.getDistanceFromLeftWallToLeftSide(sectionId);
      case 90:
        return this.getDistanceFromTopWallToTopSide(sectionId);
      case 180:
        return this.getDistanceFromRightWallToRightSide(sectionId);
      case 270:
        return this.getDistanceFromBottomWallToBottomSide(sectionId);
    }

    return -1;
  }

  public getDistanceFromRelativeRightSide(sectionId: SectionId): number {
    let section = this.query.getSection(sectionId);
    switch (section.rotation) {
      case 0:
        return this.getDistanceFromRightWallToRightSide(sectionId);
      case 90:
        return this.getDistanceFromBottomWallToBottomSide(sectionId);
      case 180:
        return this.getDistanceFromLeftWallToLeftSide(sectionId);
      case 270:
        return this.getDistanceFromTopWallToTopSide(sectionId);
    }

    return -1;
  }

  public getDistanceFromRelativeTopSide(sectionId: SectionId): number {
    let section = this.query.getSection(sectionId);
    switch (section.rotation) {
      case 0:
        return this.getDistanceFromTopWallToTopSide(sectionId);
      case 90:
        return this.getDistanceFromRightWallToRightSide(sectionId);
      case 180:
        return this.getDistanceFromBottomWallToBottomSide(sectionId);
      case 270:
        return this.getDistanceFromLeftWallToLeftSide(sectionId);
    }

    return -1;
  }

  public getDistanceFromRelativeBottomSide(sectionId: SectionId): number {
    let section = this.query.getSection(sectionId);
    switch (section.rotation) {
      case 0:
        return this.getDistanceFromBottomWallToBottomSide(sectionId);
      case 90:
        return this.getDistanceFromLeftWallToLeftSide(sectionId);
      case 180:
        return this.getDistanceFromTopWallToTopSide(sectionId);
      case 270:
        return this.getDistanceFromRightWallToRightSide(sectionId);
    }

    return -1;
  }

  public isSectionOutsideBoundary(sectionId: SectionId): boolean {
    return (
      this.getDistanceFromLeftWallToLeftSide(sectionId) < 0 ||
      this.getDistanceFromRightWallToRightSide(sectionId) < 0 ||
      this.getDistanceFromTopWallToTopSide(sectionId) < 0 ||
      this.getDistanceFromBottomWallToBottomSide(sectionId) < 0
    );
  }

  public leftAngleOffset(sectionId: SectionId, moduleId: ModuleId) {
    let section = this.query.getSection(sectionId);
    let module = this.query.getModule(moduleId);
    let value = 0;
    switch (section.rotation) {
      case 0: {
        if (
          section.posY <=
          this.editorTypeService.floorPlan.LeftWall.End.Y - module.depth
        )
          value = this.editorTypeService.floorPlan.TopWall.Begin.X;
        if (section.posY >= this.editorTypeService.floorPlan.LeftWall.Begin.Y)
          value = this.editorTypeService.floorPlan.BottomWall.End.X;
        break;
      }
      case 90: {
        if (section.posX <= this.editorTypeService.floorPlan.TopWall.Begin.X)
          value = this.editorTypeService.floorPlan.LeftWall.End.Y;
        if (
          section.posX >=
          this.editorTypeService.floorPlan.TopWall.End.X + module.depth
        )
          value = this.editorTypeService.floorPlan.RightWall.Begin.Y;
        break;
      }
      case 180: {
        if (section.posY <= this.editorTypeService.floorPlan.RightWall.Begin.Y)
          value =
            this.editorTypeService.floorPlan.Size.X -
            this.editorTypeService.floorPlan.TopWall.End.X;
        if (
          section.posY >=
          this.editorTypeService.floorPlan.RightWall.End.Y + module.depth
        )
          value =
            this.editorTypeService.floorPlan.Size.X -
            this.editorTypeService.floorPlan.BottomWall.Begin.X;
        break;
      }
      case 270: {
        if (
          section.posX <=
          this.editorTypeService.floorPlan.BottomWall.End.X - module.depth
        )
          value =
            this.editorTypeService.floorPlan.Size.Y -
            this.editorTypeService.floorPlan.LeftWall.Begin.Y;
        if (section.posX >= this.editorTypeService.floorPlan.BottomWall.Begin.X)
          value =
            this.editorTypeService.floorPlan.Size.Y -
            this.editorTypeService.floorPlan.RightWall.End.Y;
        break;
      }
    }
    return value;
  }

  public rightAngleOffset(sectionId: SectionId, moduleId: ModuleId) {
    let section = this.query.getSection(sectionId);
    let module = this.query.getModule(moduleId);
    let value = 0;
    switch (section.rotation) {
      case 0: {
        if (
          section.posY <=
          this.editorTypeService.floorPlan.RightWall.Begin.Y - module.depth
        )
          value =
            this.editorTypeService.floorPlan.Size.X -
            this.editorTypeService.floorPlan.TopWall.End.X;
        if (section.posY >= this.editorTypeService.floorPlan.RightWall.End.Y)
          value =
            this.editorTypeService.floorPlan.Size.X -
            this.editorTypeService.floorPlan.BottomWall.Begin.X;
        break;
      }
      case 90: {
        if (section.posX <= this.editorTypeService.floorPlan.BottomWall.End.X)
          value =
            this.editorTypeService.floorPlan.Size.Y -
            this.editorTypeService.floorPlan.LeftWall.Begin.Y;
        if (
          section.posX >=
          this.editorTypeService.floorPlan.BottomWall.Begin.X + module.depth
        )
          value =
            this.editorTypeService.floorPlan.Size.Y -
            this.editorTypeService.floorPlan.RightWall.End.Y;
        break;
      }
      case 180: {
        if (section.posY <= this.editorTypeService.floorPlan.LeftWall.End.Y)
          value = this.editorTypeService.floorPlan.TopWall.Begin.X;
        if (
          section.posY >=
          this.editorTypeService.floorPlan.LeftWall.Begin.Y + module.depth
        )
          value = this.editorTypeService.floorPlan.BottomWall.End.X;
        break;
      }
      case 270: {
        if (
          section.posX <=
          this.editorTypeService.floorPlan.TopWall.Begin.X - module.depth
        )
          value = this.editorTypeService.floorPlan.LeftWall.End.Y;
        if (section.posX >= this.editorTypeService.floorPlan.TopWall.End.X)
          value = this.editorTypeService.floorPlan.RightWall.Begin.Y;
        break;
      }
    }
    return value;
  }
}
