<div class="modal-content user-settings">
  <div class="modal-header">
    <h3 class="modal-title" [textContent]="title"></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <form>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('userSetting_label_language', 'Language')"
            ></span>
          </div>
          <div class="col-md-6">
            <select
              class="form-select"
              [(ngModel)]="userSettingsValue.LanguageCode"
              name="languageCode"
            >
              <option
                *ngFor="let lang of formattedLanguages"
                [ngValue]="lang.key"
              >
                {{lang.value}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="override-currency">
              <span
                [textContent]="translate('userSetting_label_use_custom_currency', 'Use custom price factor/currency')"
              ></span>
            </label>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              id="override-currency"
              [(ngModel)]="userSettingsValue.OverrideCurrency"
              name="overrideCurrency"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('userSetting_label_price_factor', 'Price factor')"
            ></span>
          </div>
          <div class="col-md-6">
            <input
              type="number"
              [disabled]="!userSettingsValue.OverrideCurrency"
              class="form-control"
              [attr.placeholder]="translate('userSetting_placeholder_priceFactor', 'Price factor')"
              [(ngModel)]="userSettingsValue.PriceFactor"
              name="priceFactor"
              step="0.01"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('userSetting_label_currency', 'Currency')"
            ></span>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              [disabled]="!userSettingsValue.OverrideCurrency"
              class="form-control"
              [attr.placeholder]="translate('userSetting_placeholder_currency', 'Currency code')"
              [(ngModel)]="userSettingsValue.Currency"
              name="curency"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <span
              [textContent]="translate('userSetting_label_show_extra_overview_tabs', 'Show extra overview tabs')"
            ></span>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              [(ngModel)]="clientSetting.showExtraOverviewTabs"
              name="showExtraOverviewTabs"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="display-price-in-editor">
              <span
                [textContent]="translate('userSetting_display_price_in_editor', 'Display price in editor')"
              ></span>
            </label>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              id="display-price-in-editor"
              [(ngModel)]="userSettingsValue.DisplayTotalPriceInEditor"
              name="displayTotalPriceInEditor"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="display-display-all-solutions-on-empty-search">
              <span
                [textContent]="translate('userSetting_display_all_solutions_on_empty_search', 'Display all solutions on empty search')"
              ></span>
            </label>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              id="display-display-all-solutions-on-empty-search"
              [(ngModel)]="clientSetting.showAllFloorplansOnEmptySearch"
              name="showAllFloorplansOnEmptySearch"
            />
          </div>
        </div>
      </div>
    </form>
    <form>
      <div class="container-fluid">
        <div class="card" style="margin-top: 16px">
          <div class="row">
            <div class="col-md-6">
              <span
                [textContent]="translate('userSetting_label_old_password', 'Old Password')"
              ></span>
            </div>
            <div class="col-md-6">
              <input
                type="password"
                class="form-control"
                autocomplete="current-password"
                [(ngModel)]="oldPassword"
                name="oldPassword"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span
                [textContent]="translate('userSetting_label_new_password', 'New Password')"
              ></span>
            </div>
            <div class="col-md-6">
              <input
                type="password"
                class="form-control"
                autocomplete="new-password"
                [(ngModel)]="newPassword"
                name="newPassword"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <button
                class="btn btn-primary btn-block"
                (click)="changePassword()"
                [textContent]="translate('userSetting_button_change_password', 'Change Password')"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <app-version></app-version>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-danger"
        [textContent]="translate('userSetting_button_clear_all_client_settings','Clear all user settings from this computer')"
        (click)="clearAll()"
        type="button"
      ></button>
    </div>
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="save()"
        [textContent]="translate('button_save', 'Save')"
      ></button>

      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
