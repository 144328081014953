<div
  class="corner-name-bubble"
  [ngClass]="{ 'store-overridden': isStoreOverridden }"
>
  <div *ngIf="floorPlanNumber" [textContent]="floorPlanNumber"></div>
  <div *ngIf="floorPlanName" [textContent]="floorPlanName"></div>

  <div *ngIf="store" class="store">
    <span [textContent]="store.Name" class="store-name"></span>
    <br />
    <span [textContent]="postalCode" class="store-postal-code"></span>
    &nbsp;
    <span [textContent]="city" class="store-city"></span>
  </div>
</div>
