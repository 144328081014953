import { inject, InjectionToken } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { CommandInterpreter } from 'app/imperative-shell/command-interpreter';
import { Store } from 'app/ts/Interface_DTO';
import { Observable, Subscription } from 'rxjs';
import { SetActiveStore } from './SetActiveStore.command';

export interface ActiveStore {
  get data(): Readonly<Store>;
  set: (value: Store) => void;

  /**
   * Subscribes to changes in active store. The subscriber
   * will be called immediately with the current value
   * @param next
   * @returns A Subscription
   */
  subscribe: (next: (value: Store) => void) => Subscription;

  get data$(): Observable<Store>;
}

function activeStoreFactory(
  data: FunctionalCoreService,
  interpreter: CommandInterpreter,
): ActiveStore {
  return {
    data: data.ambient.storeData.activeStore!,
    set(value: Store) {
      interpreter.execute(new SetActiveStore(value));
    },
    subscribe(next: (value: Store) => void) {
      return data.ambient.storeData.subscribeActiveStore(next);
    },
    data$: data.ambient.storeData.activeStore$,
  };
}

export const ActiveStoreInjector = new InjectionToken<ActiveStore>(
  'ActiveStore',
  {
    factory: () => {
      const data = inject(FunctionalCoreService);
      const interpreter = inject(CommandInterpreter);

      return activeStoreFactory(data, interpreter);
    },
  },
);

/** Use the provider for components, together with ActiveStoreInjector, to ensure
 * that useFactory is called on each instantiation.
 */
export const activeStoreProvider = {
  provide: ActiveStoreInjector,
  useFactory: activeStoreFactory,
  deps: [FunctionalCoreService, CommandInterpreter],
};
