import { ISelectable } from 'app/ts/util/ISelectable';
import { ModuleId } from '../module';
import { SectionId } from '../section';
import { Module } from './section';

export class Wall implements ISelectable, Module {
  constructor(
    private _id: ModuleId,
    private _sectionId: SectionId,

    private _posX: number,
    private _posY: number,
    private _width: number,
    private _depth: number,
    private _hasError: boolean,
  ) {}

  private _selected = false;
  public get selected(): boolean {
    return this._selected;
  }

  public get id(): ModuleId {
    return this._id;
  }

  public get sectionId(): SectionId {
    return this._sectionId;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get depth(): number {
    return this._depth;
  }

  public get hasError(): boolean {
    return this._hasError;
  }
}
