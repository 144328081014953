import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  StoreSetting,
  StoreSettingData,
  StoreSettingInjector,
  storeSettingProvider,
} from 'app/functional-core/ambient/stores/StoreSetting';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  templateUrl: 'storeSetting.html',
  styleUrls: ['../../../../style/modal.scss'],
  providers: [storeSettingProvider],
})
export class StoreSettingVm extends BaseVm {
  public title: string = '';

  public storeData: StoreSettingData;
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    @Inject(StoreSettingInjector) private readonly storeSetting: StoreSetting,
  ) {
    super(baseVmService);
    this.storeData = storeSetting.copy();
  }

  public async save() {
    this.storeSetting.set(this.storeData);
    this.activeModal.close();
  }

  public cancel(): void {
    this.activeModal.dismiss('Cancel');
  }
}
