<div class="overview-content" *ngIf="isInitialized">
  <customer-topbar [parent]="this"></customer-topbar>

  <div class="overview-minus-topbar">
    <dark-side-bar [activeLink]="'floorPlans'"></dark-side-bar>
    <div class="overview-minus-dark-side-bar">
      <customer-nav
        [selection]="'floorPlans'"
        [customerCounts]="customers$ | async | updateCounts"
      ></customer-nav>

      <div class="overview-minus-customer-nav main-content floorplans-body">
        <div class="floorplans-body-left">
          <div class="floorplans-top-buttons">
            <div class="btn-group">
              <button
                class="btn btn-light"
                (click)="newCustomer()"
                [attr.title]="translate('customers_button_new_customer', 'New customer')"
              >
                <span class="bi bi-person-fill"></span>
                <span
                  [textContent]="translate('customers_button_new_customer', 'New customer')"
                ></span>
              </button>
            </div>

            <button
              class="btn btn-light"
              (click)="selectColumns()"
              [attr.title]="translate('floorplans_button_select_columns', 'Columns')"
            >
              <span class="bi bi-funnel-fill"></span>
              <span
                [textContent]="translate('floorplans_button_select_columns', 'Columns')"
              ></span>
            </button>

            <button
              class="btn btn-light"
              (click)="collapseAll()"
              [attr.title]="translate('floorplans_button_collapse_all', 'Collapse all')"
              *ngIf="!allCollapsed"
            >
              <span class="bi bi-dash-lg"></span>
              <span
                [textContent]="translate('floorplans_button_collapse_all', 'Collapse all')"
              ></span>
            </button>
            <button
              class="btn btn-light"
              (click)="uncollapseAll()"
              [attr.title]="translate('floorplans_button_uncollapse_all', 'Expand all')"
              *ngIf="allCollapsed"
            >
              <span class="bi bi-plus-lg"></span>
              <span
                [textContent]="translate('floorplans_button_uncollapse_all', 'Expand all')"
              ></span>
            </button>

            <button
              class="btn btn-primary btn-user-message"
              *ngFor="let userMessage of userMessages"
              (click)="displayUserMessage(userMessage)"
              [textContent]="userMessage.Header"
            ></button>
          </div>

          <div
            class="brief customer"
            *ngFor="let customer of customers; trackBy:trackCustomerById"
            [ngClass]="{'collapsed': customer.collapsed, 'expanded': !customer.collapsed }"
          >
            <div
              class="brief-header"
              (click)="customer.collapsed = !customer.collapsed"
            >
              <button class="btn btn-xs btn-collapser">
                <!-- NBS: 22_03_2023:  "i { -webkit-text-stroke: 1px; }" makes the collaps/expand-icons bold. -->
                <i
                  class=""
                  [ngClass]="{'bi bi-plus-lg': customer.collapsed, 'bi-dash-lg': !customer.collapsed}"
                >
                  <style>
                    i {
                      -webkit-text-stroke: 1px;
                    }
                  </style>
                </i>
              </button>

              <span
                class="brief-text brief-type-text"
                [textContent]="translate('floorplans_brief_customer_prefix', 'Customer:')"
              ></span>
              <span class="brief-text" [textContent]="customer.name"></span>

              <div class="flex-spacer"></div>
              <div
                class="brief-text child-count project-count btn-margin"
                [attr.title]="translate('floorplans_brief_project_count_title', 'number of projects')"
                [textContent]="translate('floorplans_brief_project_count', '({0})', customer.projects.length)"
              ></div>

              <div
                class="btn-group btn-group-xs btn-group-nav"
                *ngIf="showBackButtons"
              >
                <a
                  class="btn btn-primary"
                  [attr.title]="translate('floorplans_brief_back_customer_title', 'Show Customer')"
                  (click)="customerBack(customer)"
                  onclick="event.stopPropagation()"
                >
                  <span class="bi bi-person-fill"></span>
                  <span class="bi bi-caret-left-fill"></span>
                </a>
              </div>

              <div
                class="btn-group btn-group-xs btn-group-nav btn-margin"
                *ngIf="showEditButtons"
              >
                <a
                  class="btn btn-primary"
                  [attr.title]="translate('floorplans_brief_edit_customer_title', 'Edit Customer')"
                  (click)="editCustomer(customer)"
                  onclick="event.stopPropagation()"
                >
                  <span class="bi bi-person-fill"></span>
                  <span class="bi bi-wrench-adjustable"></span>
                </a>
              </div>

              <div class="btn-new-container">
                <button
                  class="btn btn-xs btn-new"
                  (click)="newProject(customer.clientCustomer); $event.stopPropagation()"
                  [attr.title]="translate('floorPlans_create_project', 'New project')"
                >
                  <span class="bi bi-folder-fill"></span>
                  <span
                    [textContent]="translate('floorPlans_create_project', 'New project')"
                  ></span>
                </button>
              </div>
            </div>
            <!-- end customer brief-header -->
            <div class="brief-body" *ngIf="!customer.collapsed">
              <div
                class="brief project"
                *ngFor="let project of customer.projects"
                [ngClass]="{'collapsed': project.collapsed, 'expanded': !project.collapsed }"
              >
                <div
                  class="brief-header"
                  (click)="project.collapsed = !project.collapsed"
                >
                  <button class="btn btn-xs btn-collapser">
                    <i
                      class=""
                      [ngClass]="{'bi bi-plus-lg': project.collapsed, 'bi bi-dash-lg': !project.collapsed}"
                    >
                      <style>
                        i {
                          -webkit-text-stroke: 1px;
                        }
                      </style>
                    </i>
                  </button>

                  <div
                    class="brief-text brief-type-text"
                    [textContent]="translate('floorplans_brief_project_prefix', 'Project: ')"
                  ></div>
                  <div class="brief-text" [textContent]="project.name"></div>

                  <div class="flex-spacer"></div>

                  <div
                    class="brief-text child-count delivery-address-count btn-margin"
                    [attr.title]="translate('floorplans_brief_delivery_address_count_title', 'number of delivery addresses')"
                    [textContent]="translate('floorplans_brief_delivery_address_count', '({0})', project.deliveryAddresses.length)"
                  ></div>

                  <div
                    class="btn-group btn-group-xs btn-group-nav"
                    *ngIf="showBackButtons"
                  >
                    <a
                      class="btn btn-primary"
                      [attr.title]="translate('floorplans_brief_back_project_title', 'Show Project')"
                      (click)="projectBack(project)"
                      onclick="event.stopPropagation()"
                    >
                      <span class="bi bi-folder-fill"></span>
                      <span class="bi bi-caret-left-fill"></span>
                    </a>
                  </div>

                  <div
                    class="btn-group btn-group-xs btn-group-nav btn-margin"
                    *ngIf="showEditButtons"
                  >
                    <a
                      class="btn btn-primary"
                      [attr.title]="translate('floorplans_brief_edit_project_title', 'Edit Project')"
                      (click)="editProject(project)"
                      onclick="event.stopPropagation()"
                    >
                      <span class="bi bi-folder-fill"></span>
                      <span class="bi bi-wrench-adjustable"></span>
                    </a>
                  </div>

                  <div class="btn-new-container">
                    <button
                      class="btn btn-xs btn-new"
                      (click)="newDeliveryAddress(project.clientProject); $event.stopPropagation()"
                      [attr.title]="translate('floorPlans_create_delivery_address', 'New Delivery Address')"
                    >
                      <span class="bi bi-geo-alt-fill"></span>
                      <span
                        [textContent]="translate('floorPlans_create_delivery_address', 'New Delivery Address')"
                      ></span>
                    </button>
                  </div>
                </div>
                <!-- end project brief header -->

                <div class="brief-body" *ngIf="!project.collapsed">
                  <div
                    class="brief delivery-address"
                    *ngFor="let deliveryAddress of project.deliveryAddresses; trackBy: trackDeliveryAddressById"
                    [ngClass]="{'collapsed': deliveryAddress.collapsed, 'expanded': !deliveryAddress.collapsed }"
                  >
                    <div
                      class="brief-header"
                      (click)="deliveryAddress.collapsed = !deliveryAddress.collapsed"
                    >
                      <button class="btn btn-xs btn-collapser">
                        <i
                          class=""
                          [ngClass]="{'bi bi-plus-lg': deliveryAddress.collapsed, 'bi bi-dash-lg': !deliveryAddress.collapsed}"
                        >
                          <style>
                            i {
                              -webkit-text-stroke: 1px;
                            }
                          </style>
                        </i>
                      </button>
                      <div
                        class="brief-text brief-type-text"
                        [textContent]="translate('floorplans_brief_delivery_address_prefix', 'Delivery Address:')"
                      ></div>
                      <div
                        class="brief-text"
                        [textContent]="deliveryAddress.name"
                      ></div>

                      <div class="flex-spacer"></div>
                      <div
                        class="brief-text child-count floorplan-count btn-margin"
                        [attr.title]="translate('floorplans_brief_floorplan_count_title', 'number of floorplans')"
                        [textContent]="translate('floorplans_brief_floorplan_count', '({0})', deliveryAddress.floorPlans.length)"
                      ></div>
                      <div
                        class="btn-group btn-group-xs btn-group-nav"
                        *ngIf="showBackButtons"
                      >
                        <a
                          class="btn btn-primary"
                          [attr.title]="translate('floorplans_brief_back_delivery_address_title', 'Show Delivery Address')"
                          (click)="deliveryAddressBack(deliveryAddress)"
                          onclick="event.stopPropagation()"
                        >
                          <span class="bi bi-geo-alt-fill"></span>
                          <span class="bi bi-caret-left-fill"></span>
                        </a>
                      </div>

                      <div
                        class="btn-group btn-group-xs btn-group-nav"
                        *ngIf="showEditButtons"
                      >
                        <a
                          class="btn btn-primary btn-margin"
                          [attr.title]="translate('floorplans_brief_edit_delivery_address_title', 'Edit Delivery Address')"
                          (click)="editDeliveryAddress(deliveryAddress)"
                          onclick="event.stopPropagation()"
                        >
                          <span class="bi bi-geo-alt-fill"></span>
                          <span class="bi bi-wrench-adjustable"></span>
                        </a>
                      </div>

                      <div class="btn-new-container">
                        <button
                          class="btn btn-xs btn-new"
                          (click)="newFloorPlan(deliveryAddress.clientDeliveryAddress); $event.stopPropagation()"
                          [attr.title]="translate('floorPlans_create_floorplan', 'New Floor Plan')"
                        >
                          <span class="bi bi-pencil-square"></span>
                          <span
                            [textContent]="translate('floorPlans_create_floorplan', 'New Floor Plan')"
                          ></span>
                        </button>
                      </div>
                    </div>

                    <div class="brief-body" *ngIf="!deliveryAddress.collapsed">
                      <table
                        class="main-table"
                        *ngIf="deliveryAddress.floorPlans.length > 0"
                      >
                        <colgroup>
                          <col class="select-col" />
                          <col
                            *ngFor="let colName of selectedColumnNames"
                            [ngClass]="columns[colName].colClass"
                            class="repeat-col"
                          />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                [(ngModel)]="deliveryAddress.isAllSelected"
                                (change)="selectionChanged()"
                              />
                            </th>
                            <th
                              *ngFor="let colName of selectedColumnNames"
                              [textContent]="columns[colName].displayName"
                              [ngClass]="{'order-col': colName === deliveryAddress.order && !deliveryAddress.reverseOrder, 'order-col-reverse': colName === deliveryAddress.order && deliveryAddress.reverseOrder}"
                              (click)="deliveryAddress.order = colName"
                              [attr.title]="columns[colName].displayName"
                              class="bi orderable-col"
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            *ngFor="let floorPlan of deliveryAddress.floorPlans"
                            (click)="setSelection(floorPlan.clientFloorPlan)"
                            class="floor-plan-overview clickable"
                            [ngClass]="floorPlan.cssClass"
                            id="floorPlanIdPrefix + floorPlan.id"
                          >
                            <td>
                              <input
                                type="checkbox"
                                [checked]="updateSelected()"
                                [ngModel]="floorPlan.clientFloorPlan.selected"
                                onclick="event.stopPropagation()"
                                (ngModelChange)="selectionChangedWithParams($event, floorPlan.clientFloorPlan)"
                              />
                              <!--(change)="selectionChanged()"-->
                              <!-- <input type="checkbox"
                                                                   (ngModel)="deliveryAddress.selectedFloorplan(floorPlan)"     
                                                                   (ngModelChange)="selectionChanged()" 
                                                                   onclick="event.stopPropagation()" /> -->
                            </td>

                            <td
                              *ngFor="let cell of floorPlan.cells"
                              [ngClass]="cell.cssClass"
                              [textContent]="cell.content"
                              [attr.title]="cell.title"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul class="pagination" *ngIf="numPages > 1">
            <li
              *ngFor="let pageNumber of range(1, numPages + 1)"
              class="page-item"
              [ngClass]="{'active': pageNumber === pageNum}"
            >
              <a
                (click)="setPageNum(pageNumber)"
                [textContent]="pageNumber"
              ></a>
            </li>
          </ul>
        </div>

        <div class="floorplans-body-right">
          <div
            class="floorplan-menu right-menu tabbed wide"
            *ngIf="selectedObjects.length > 0"
          >
            <div class="title">
              <div style="flex: 0 0 auto" class="title-left">
                <order-status
                  class=""
                  [floorPlans]="selectedFloorPlans"
                ></order-status>
              </div>
              <span
                [textContent]="selectionName"
                style="text-align: center"
                class="title-center"
              ></span>
              <div class="title-right">
                <a
                  class="download-button"
                  (click)="downloadPieceList(selectedObjects[0].dtoObject)"
                  [attr.title]="translate('floorPlans_download_piecelist', 'Get piecelist as .pdf')"
                >
                  <span class="bi bi-download"></span>
                </a>
                <button class="close" (click)="closeRightMenu()">
                  &times;
                </button>
              </div>
            </div>

            <div class="body">
              <ul class="nav nav-tabs nav-tabs-sm">
                <li
                  class="nav-item"
                  [ngClass]="{'active': detailTabNumber === 0}"
                >
                  <a
                    class="nav-link"
                    [textContent]="translate('floorplans_rightMenu_tab_floorplan', 'Floor plan')"
                    (click)="detailTabNumber = 0"
                  ></a>
                </li>
                <li
                  class="nav-item"
                  [ngClass]="{'active': detailTabNumber === 1}"
                >
                  <a
                    class="nav-link"
                    [textContent]="translate('floorplans_rightMenu_tab_order', 'Order')"
                    (click)="detailTabNumber = 1"
                  ></a>
                </li>
                <li
                  class="nav-item"
                  [ngClass]="{'active': detailTabNumber === 2}"
                >
                  <a
                    class="nav-link"
                    [textContent]="translate('floorplans_rightMenu_tab_overview', 'overview')"
                    (click)="detailTabNumber = 2"
                  ></a>
                </li>
              </ul>

              <!--floorplan tab body-->
              <div
                class="tab-body floorplan-tab-body"
                *ngIf="detailTabNumber === 0"
              >
                <div
                  class="btn-toolbar"
                  *ngIf="isFloorPlanCompatible(selectedObjects[0])"
                >
                  <div class="input-group-btn">
                    <button
                      class="btn"
                      (click)="downloadPieceList(selectedObjects[0].dtoObject)"
                      [attr.title]="translate('floorPlans_download_piecelist', 'Get piecelist as .pdf')"
                    >
                      <span class="bi bi-download"></span>
                    </button>
                  </div>
                  <div class="input-group-btn">
                    <button
                      class="btn"
                      (click)="moveSelection()"
                      [ngClass]="{'active disabled': isActionInProgress, 'disabled': !isMovable}"
                      [attr.title]="translate('floorPlans_move_floorPlan', 'Move')"
                    >
                      <span class="bi bi-clipboard"></span>
                    </button>
                  </div>
                  <div class="input-group" style="width: 110px">
                    <input
                      type="number"
                      class="form-control"
                      min="1"
                      [(ngModel)]="numCopies"
                      [attr.title]="translate('floorPlans_copy_numCopies','Number of copies')"
                    />
                    <span class="input-group-btn">
                      <button
                        class="btn btn-primary"
                        (click)="copySelection()"
                        [ngClass]="{'active disabled': isActionInProgress}"
                        [attr.title]="translate('floorPlans_copy_floorPlan', 'Copy')"
                      >
                        <span class="bi bi-journals"></span>
                      </button>
                    </span>
                  </div>
                  <div class="input-group-btn">
                    <button
                      class="btn-danger"
                      (click)="deleteSelected()"
                      [attr.title]="translate('button_delete', 'Delete')"
                    >
                      <span class="bi bi-trash3"></span>
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="selectedObjects.length === 1 && isFloorPlanCompatible(selectedObjects[0])"
                  class="btn-toolbar"
                >
                  <a
                    class="btn btn-primary"
                    [ngClass]="{'disabled': !isSelectionEditable()}"
                    (click)="navigateToEditor()"
                  >
                    <span class="bi bi-pencil-square"></span>
                    <span
                      [textContent]="translate('floorPlans_edit_floorPlan', 'Edit floor plan')"
                    ></span>
                  </a>
                </div>
                <div class="btn-toolbar" *ngIf="showResendScreenshots">
                  <button
                    class="btn btn-primary"
                    (click)="resendScreenshots()"
                    [textContent]="translate('floorPlans_resend_screenshots', 'Re-generate element list')"
                  ></button>
                </div>
                <div
                  *ngIf="selectedObjects.length === 1"
                  class="floorplan-compatibility-warning"
                >
                  <span
                    *ngIf="!isFloorPlanCompatible(selectedObjects[0])"
                    [textContent]="translate('floorPlans_imcompatible_for_open','The selected solution cannot be opened in this version of the program')"
                  ></span>
                </div>

                <div
                  *ngIf="selectedObjects.length === 1 && !selectedObjects[0].dtoObject.IsLegacyFloorPlan"
                  class="menu-center"
                >
                  <img
                    class="floorplan-preview-image preview-image"
                    [src]="selectedObjects[0].dtoObject.ImageUrl + '?ts=' + timestamp"
                  />
                </div>

                <div
                  *ngIf="selectedObjects.length === 1 && selectedObjects[0].dtoObject.IsLegacyFloorPlan"
                  class="menu-center"
                >
                  <img
                    class="floorplan-preview-image preview-image missing-preview-image"
                    src="assets/images/missing_floorplan.jpg"
                  />
                </div>

                <div class="menu-center">
                  <h3>
                    <!-- <order-status class="label label-default" [floorPlans]="selectedFloorPlans"></order-status> -->
                  </h3>
                </div>

                <div class="flex-spacer"></div>
              </div>

              <!--order tab-body-->
              <div
                class="tab-body order-tab-body with-footer"
                *ngIf="detailTabNumber === 1"
              >
                <floor-plan-detail-edit
                  [floorPlanOverviews]="selectedFloorPlans"
                ></floor-plan-detail-edit>
              </div>

              <div
                class="tab-body overview-tab-body"
                *ngIf="detailTabNumber === 2"
              >
                <floorplan-overview-box
                  [floorplans]="selectedFloorPlans"
                ></floorplan-overview-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <customer-edit-menu
    *ngIf="selection.type==='customer'"
    [customer]="selection.customer"
    (onClose)="closeRightMenu()"
  >
  </customer-edit-menu>
  <project-edit-menu
    *ngIf="selection.type==='project'"
    [project]="selection.project"
    (onClose)="closeRightMenu()"
  >
  </project-edit-menu>
  <delivery-address-edit-menu
    *ngIf="selection.type==='deliveryAddress'"
    [clientDeliveryAddress]="selection.deliveryAddress"
    (onClose)="closeRightMenu()"
  >
  </delivery-address-edit-menu>

  <corner-name-bubble></corner-name-bubble>
</div>
