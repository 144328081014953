import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { EditorAssets } from 'app/ts/clientDto/EditorAssets';

export class ChainSettingHelper {
  public static getDefaultCorpusMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    let setting =
      editorAssets.chainSettings[
        Interface_Enums.ChainSettingKey.DefaultCorpusMaterial
      ];
    return setting !== undefined ? setting : null;
  }

  public static getDefaultInteriorMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    let setting =
      editorAssets.chainSettings[
        Interface_Enums.ChainSettingKey.DefaultInteriorMaterial
      ];
    return setting !== undefined ? setting : null;
  }

  public static getDefaultBackingMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    let setting =
      editorAssets.chainSettings[
        Interface_Enums.ChainSettingKey.DefaultBackingMaterial
      ];
    return setting !== undefined ? setting : null;
  }

  public static getDefaultSwingCorpusMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    return ChainSettingHelper.getDefaultCorpusMaterialNumber(editorAssets);
  }

  public static getDefaultSwingDoorMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    return Constants.swingDefaultDoorMaterialNumber;
  }

  public static getDefaultSwingBackingMaterialNumber(
    editorAssets: Client.EditorAssets,
  ): string | null {
    return ChainSettingHelper.getDefaultBackingMaterialNumber(editorAssets);
  }
}
