import * as Client from 'app/ts/clientDto/index';
import { Door } from 'app/ts/clientDto/Door';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class SingleDoorVm {
  private cache = new Client.Cache<{
    selectedFillingMaterial: Client.FillingMaterial | null;
  }>();
  constructor(
    public readonly name: string,
    public readonly door: Client.Door,
    public readonly availableFillingMaterials: Pickable<Client.FillingMaterial>[],
    public readonly availableBarDesigns: Pickable<Client.BarDesign>[],
    private readonly setChanged: () => void,
  ) {}

  //region filling materials
  get selectedFillingMaterial(): Client.FillingMaterial | null {
    return this.cache.get('selectedFillingMaterial', () => {
      let id: number | undefined = undefined;
      for (let filling of this.door.actualFillings) {
        if (id === undefined) {
          //first filling on first door
          id = filling.materialId;
          continue;
        }
        if (id !== filling.materialId) {
          //not all fillings are the same color - no colors are selected
          return null;
        }
      }
      if (id === undefined) return null;
      let pickableMat = this.availableFillingMaterials.filter(
        (pfm) => pfm.item.material.Id === id,
      )[0];
      if (pickableMat === undefined) return null;
      return pickableMat.item;
    });
  }

  set selectedFillingMaterial(val: Client.FillingMaterial | null) {
    if (val === null) return;
    let material = this.availableFillingMaterials.filter(
      (fc) => fc.item === val,
    )[0];
    if (!material) return;
    for (let filling of this.door.fillings) {
      filling.fillingMaterial = material.item;
    }

    this.setChanged();
  }

  //region bar designs

  public get selectedBarDesign() {
    return this.door.barDesign;
  }

  public set selectedBarDesign(val: Client.BarDesign | undefined) {
    if (!val) {
      return;
    }
    this.door.setBarDesign(val);
    this.setChanged();
  }
}
