import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendService } from './backend-service';
import { PromisingBackendService } from './promising-backend-service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [BackendService, PromisingBackendService],
  exports: [],
})
export class BackendServiceModule {}
