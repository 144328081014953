import { ISnapInfoService } from '@Services/snap/ISnapInfoService';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Client from 'app/ts/clientDto/index';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';
import * as App from 'app/ts/app';
import * as Enums from 'app/ts/clientDto/Enums';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { VectorHelper } from '@Util/VectorHelper';
import { ObjectHelper } from '@Util/ObjectHelper';
import { Constants } from 'app/ts/Constants';
import { ProductHelper } from '@Util/ProductHelper';
import { ProductLineProperties } from 'app/ts/Interface_DTO_ProductLineProperties';
import { ItemType } from 'app/ts/Interface_Enums';

export class SwingFlexCorpusSnapService implements ISnapInfoService {
  private lastSnapInfo: Client.SnapInfo | undefined;

  private readonly productLineProperties: ProductLineProperties;
  private readonly possiblePositions: SwingFlexCorpusSnapService.PossiblePositionInfo[];
  private readonly otherItemCubes: Client.ItemCube[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly cabinetSection: Client.CabinetSection,
    private readonly item: Client.ConfigurationItem,
    private readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
  ) {
    this.productLineProperties =
      this.cabinetSection.swingFlex.productLineProperties;

    let itemCubes: Client.ItemCube[] = cabinetSection.swingFlex.items
      .map(ConfigurationItemHelper.getItemCube)
      .concat(cabinetSection.interior.itemCubes);

    // If the cabinet has a plinth, and the doors cover the plinth, a drawer may be placed where the plinth would normally be. (The plinth is then removed)
    let ignorePlinth =
      this.cabinetSection.swingFlex.plinth &&
      this.cabinetSection.swingFlex.doorCoversPlinth &&
      this.item.isPullout;

    this.otherItemCubes = itemCubes
      .filter((cube) => cube.item !== this.item)
      .filter(
        (cube) =>
          !cube.item.isDummy && !cube.item.isTemplate && !cube.item.isGrip,
      )
      .filter(
        (cube) =>
          cube.item.ItemType !== ItemType.SwingFlexDoor &&
          (!ignorePlinth || cube.item.ItemType !== ItemType.SwingFlexCorpus),
      )
      .filter((cube) => cube.Z < item.frontZ);

    this.possiblePositions = this.getPossiblePositions();
    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );
  }

  // Public interface
  //#region

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    let rawItemPos = VectorHelper.subtract(pos, this.mouseOffset);

    let bestSuggestion = ObjectHelper.best(
      this.possiblePositions.filter(
        (pp) =>
          pp.gableGap.X <= pos.X && pp.gableGap.X + pp.gableGap.Width >= pos.X,
      ),
      (pos) => Math.abs(pos.Y - rawItemPos.Y),
    );

    if (
      bestSuggestion &&
      VectorHelper.dist(pos, bestSuggestion) < Constants.snapDist
    ) {
      let pulloutSeverity = this.item.isPullout
        ? bestSuggestion.pulloutSeverity
        : Enums.PulloutWarningSeverity.None;

      let snapsTo: Client.ConfigurationItem[] = [];
      for (let gable of [
        bestSuggestion.gableGap.leftGable,
        bestSuggestion.gableGap.rightGable,
      ])
        if (!!gable) snapsTo.push(gable);

      let result: Client.SnapInfo = {
        dropOffset: VectorHelper.subtract(bestSuggestion, this.item),
        pulloutRestriction: pulloutSeverity,
        rulers: bestSuggestion.rulers,
        suggestedItemOffsets: [],
        newSize: {
          X: bestSuggestion.Width,
          Y: bestSuggestion.Height,
          Z: bestSuggestion.Depth,
        },
        mirror: false,
        collisionAreas: bestSuggestion.collisionAreas,
        horizontalGuidelineHeights: [
          bestSuggestion.Y,
          bestSuggestion.Y + this.item.Height,
        ],
        snapsTo: snapsTo,
        snappingItems: [this.item],
      };
      this.lastSnapInfo = result;
      return result;
    } else {
      let offset = VectorHelper.subtract(pos, this.dragStartPoint);
      let result: Client.SnapInfo = {
        dropOffset: {
          ...offset,
          Z: 0,
        },
        pulloutRestriction: Enums.PulloutWarningSeverity.None,
        rulers: [],
        suggestedItemOffsets: [],
        mirror: false,
        horizontalGuidelineHeights: [],
        snapsTo: [],
        snappingItems: [this.item],
      };
      this.lastSnapInfo = result;
      return result;
    }
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (this.lastSnapInfo && this.item.Product) {
      // Add or move item in cabinet
      const newWidth = this.lastSnapInfo.newSize?.X ?? this.item.Width;
      const centerPoint = {
        X: this.item.X + newWidth / 2 + this.lastSnapInfo.dropOffset.X,
        Y: this.item.centerY + this.lastSnapInfo.dropOffset.Y,
      };
      const area = this.cabinetSection.swingFlex.areas.find((area) =>
        area.fullHeightRect.isPointInside(centerPoint),
      );
      if (!!area) {
        // Check if the item is already in the area. If not, it must be added (or moved, if already in any of the other areas).
        if (area.areaSplitItems.indexOf(this.item) < 0) {
          // Check if the item is in any af the other areas
          for (let a of this.cabinetSection.swingFlex.areas) {
            a.removeAreaSplitItem(this.item);
          }

          // Add the item to the area
          area.addAreaSplitItem(this.item);
        }

        // Update position
        this.item.X += this.lastSnapInfo.dropOffset.X;
        this.item.Y += this.lastSnapInfo.dropOffset.Y;
        this.item.Z += this.lastSnapInfo.dropOffset.Z;
        this.item.X = Math.round(this.item.X);
        this.item.Y = Math.round(this.item.Y);
        this.item.Z = Math.round(this.item.Z);

        // Update size
        if (this.lastSnapInfo.newSize) {
          let newDepth = this.lastSnapInfo.newSize.Z ?? this.item.Depth;

          if (newWidth != this.item.Width || newDepth != this.item.Depth) {
            let optimalProduct = ProductHelper.getOptimalProduct(
              this.item.Product,
              newWidth,
              newDepth,
              this.cabinetSection.cabinet.ProductLineId,
              this.cabinetSection.swingFlex.getDrillingPattern(),
            );

            if (!!optimalProduct) {
              this.item.Product = optimalProduct;
              this.item.Width = newWidth;

              // The optimal product may not support the desired depth. In that case, the depth is set top the maximum supported depth.
              if (!ProductHelper.supportsDepth(optimalProduct, newDepth)) {
                newDepth = ProductHelper.maxDepth(
                  optimalProduct,
                  this.cabinetSection.cabinet.ProductLineId,
                );
              }
              this.item.Depth = newDepth;

              const frontZ =
                this.cabinetSection.swingFlex.depth - this.item.depthReduction;
              const z = frontZ - this.item.Depth;
              this.item.Z = z;
            }
          }
        }
      }

      if (App.debug.showSnapInfo) {
        console.debug('Snapped by me: ', this);
      }
    }
    return { items: [this.item], recalculationMessages: [] };
  }

  public dispose() {}

  //#endregion

  // Private helpers
  //#region

  private getPossiblePositions(): SwingFlexCorpusSnapService.PossiblePositionInfo[] {
    const result: SwingFlexCorpusSnapService.PossiblePositionInfo[] = [];

    const gableGaps = this.cabinetSection.swingFlex.getGableGaps([this.item]);
    const allowAllProducts =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
    const allowAllMaterials =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
    const suitableGaps = gableGaps.filter((gap) =>
      this.item.Product!.isAvailableInWidth(
        gap.Width,
        allowAllProducts,
        this.cabinetSection.cabinet.ProductLineId,
        allowAllMaterials,
        this.item.MaterialId,
      ),
    );

    const maxTopY =
      this.cabinetSection.interior.cube.Y +
      this.cabinetSection.interior.cube.Height;
    const frontZ = this.cabinetSection.swingFlex.depth;
    const backingFrontZ =
      this.cabinetSection.swingFlex.productLineProperties
        .SwingFlexSpaceForBacking;

    for (let gap of suitableGaps) {
      if (!gap.leftGable || !gap.rightGable) continue;

      let backZ = 0;
      backZ = Math.max(gap.leftGable.Z, gap.rightGable.Z, backingFrontZ);

      if (frontZ <= backZ) continue;

      const otherItemsInLane = this.otherItemCubes.filter((otherItemRect) =>
        VectorHelper.overlapsX(otherItemRect, gap),
      );

      const depth = frontZ - backZ - this.item.depthReduction;

      let z = frontZ - this.item.depthReduction - depth;
      if (this.item.snapDepthMiddle) {
        z = (frontZ + backZ) / 2 - this.item.Depth / 2;
      }

      for (let drillStop of gap.drillStops) {
        const candidateCube: Interface_DTO_Draw.Cube = {
          X: gap.X,
          Y: drillStop - this.item.snapOffsetY,
          Z: z,
          Width: gap.Width,
          Height: this.item.Height,
          Depth: depth,
        };

        if (
          candidateCube.Y < 0 ||
          candidateCube.Y + candidateCube.Height > maxTopY
        )
          continue;

        const overlapsMoreThanThreshold = otherItemsInLane.some(
          (otherItemCube) => {
            // External drawers may overlap shelves a bit, depending on the shelf type.
            const thresholdY = this.cabinetSection.swingFlex.getAllowedOverlapY(
              this.item,
              otherItemCube.item,
              candidateCube.Y,
            );

            return VectorHelper.overlapsMoreThanThreshold(
              candidateCube,
              otherItemCube,
              0,
              thresholdY,
              0,
            );
          },
        );
        if (overlapsMoreThanThreshold) {
          continue;
        }

        const otherItemsAbove = otherItemsInLane
          .filter(
            (otherItem) =>
              otherItem.Y >= candidateCube.Y + candidateCube.Height,
          )
          .sort((r1, r2) => r1.Y - r2.Y);
        const otherItemsBelow = otherItemsInLane
          .filter(
            (otherItem) => otherItem.Y + otherItem.Height <= candidateCube.Y,
          )
          .sort((r1, r2) => r2.Y + r2.Height - (r1.Y + r1.Height));
        const itemAbove = otherItemsAbove[0];
        const itemBelow = otherItemsBelow[0];

        const collisionAreas =
          this.cabinetSection.swingFlex.getCollidingCollisionAreas(
            this.item,
            false,
            this.otherItemCubes,
            candidateCube,
          );
        const rulers = this.getRulers(
          candidateCube,
          otherItemsAbove,
          otherItemsBelow,
        );

        const candidate: SwingFlexCorpusSnapService.PossiblePositionInfo = {
          ...candidateCube,
          pulloutSeverity: gap.pulloutSeverity,
          itemAbove: itemAbove,
          itemBelow: itemBelow,
          collisionAreas: collisionAreas,
          gableGap: gap,
          rulers: rulers,
        };

        result.push(candidate);
      }
    }
    if (App.debug.showSnapInfo) console.debug('possible snap points: ', result);
    return result;
  }

  private getRulers(
    candCube: Interface_DTO_Draw.Rectangle,
    itemsAbove: Interface_DTO_Draw.Rectangle[],
    itemsBelow: Interface_DTO_Draw.Rectangle[],
  ): Client.Ruler[] {
    let rulers = [];
    let rulerX = candCube.X + candCube.Width / 2;

    let itemAbove = itemsAbove[0];
    let distanceAbove =
      (itemAbove ? itemAbove.Y : this.cabinetSection.Height) -
      candCube.Y -
      candCube.Height;
    let distToAboveRuler = new Client.Ruler(
      true,
      {
        X: rulerX,
        Y: candCube.Y + candCube.Height,
      },
      distanceAbove,
      this.cabinetSection.Height,
      false,
    );
    rulers.push(distToAboveRuler);

    if (itemAbove) {
      let nextItemAbove = itemsAbove[1];
      if (nextItemAbove) {
        let nextDistanceAbove =
          nextItemAbove.Y - itemAbove.Y - itemAbove.Height;
        if (nextDistanceAbove === distanceAbove) {
          let distToNextAboveRuler = new Client.Ruler(
            true,
            {
              X: rulerX,
              Y: itemAbove.Y + itemAbove.Height,
            },
            nextDistanceAbove,
            this.cabinetSection.Height,
            false,
          );
          rulers.push(distToNextAboveRuler);
        }
      }
    }

    let itemBelow = itemsBelow[0];
    let distanceBelow =
      candCube.Y -
      (itemBelow
        ? itemBelow.Y + itemBelow.Height
        : this.cabinetSection.sightOffsetY);
    let distToBelowRuler = new Client.Ruler(
      true,
      {
        X: rulerX,
        Y: itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.sightOffsetY,
      },
      candCube.Y -
        (itemBelow
          ? itemBelow.Y + itemBelow.Height
          : this.cabinetSection.sightOffsetY),
      this.cabinetSection.Height,
      false,
    );
    rulers.push(distToBelowRuler);

    if (itemBelow) {
      let nextItemBelow = itemsBelow[1];
      if (nextItemBelow) {
        let nextDistanceBelow =
          itemBelow.Y - nextItemBelow.Y - nextItemBelow.Height;
        if (nextDistanceBelow === distanceBelow) {
          let distToNextBelowRuler = new Client.Ruler(
            true,
            {
              X: rulerX,
              Y: nextItemBelow.Y + nextItemBelow.Height,
            },
            nextDistanceBelow,
            this.cabinetSection.Height,
            false,
          );
          rulers.push(distToNextBelowRuler);
        }
      }
    }

    return rulers;
  }

  //#endregion
}

module SwingFlexCorpusSnapService {
  export interface PossiblePositionInfo extends Interface_DTO_Draw.Cube {
    pulloutSeverity: Enums.PulloutWarningSeverity;
    itemAbove: Interface_DTO_Draw.Rectangle | undefined;
    itemBelow: Interface_DTO_Draw.Rectangle | undefined;
    collisionAreas: Client.CollisionArea[];
    gableGap: Client.GableGap;
    rulers: Client.Ruler[];
  }
}
