<div class="client-version">
  <span
    class="version-label"
    [textContent]="translate('app_version_client_version', 'Client version: ')"
  ></span>
  <span class="version-string" [textContent]="clientVersion"></span>
</div>
<div class="server-version">
  <span
    class="version-label"
    [textContent]="translate('app_version_status', 'Version status: ')"
  ></span>
  <span class="version-string" [textContent]="versionStatus"></span>
</div>
