import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/index';
import { Product } from 'app/ts/clientDto/Product';
export class RailSet implements Readonly<Interface_DTO.DoorRailSet> {
  constructor(
    private readonly dto: Interface_DTO.DoorRailSet,
    public readonly topProduct: Client.Product | null,
    public readonly bottomProduct: Client.Product | null,
  ) {}

  // #region DTO mappings

  get BottomProductId() {
    return this.dto.BottomProductId;
  }

  get DefaultName() {
    return this.dto.DefaultName;
  }

  get DoorReductionBottom() {
    return this.dto.DoorReductionBottom;
  }

  get DoorReductionTop() {
    return this.dto.DoorReductionTop;
  }

  get Id() {
    return this.dto.Id;
  }

  get NumberOfTracks() {
    return this.dto.NumberOfTracks;
  }

  get ProductId() {
    return this.dto.ProductId;
  }

  get TopProductId() {
    return this.dto.TopProductId;
  }

  get Type() {
    return this.dto.Type;
  }

  // #endregion DTO mappings

  // #region Properties

  // #endregion Properties

  // #region Functions

  public isValidForDoorProduct(doorProduct: Product): boolean {
    // Does it fit the door product
    if (this.ProductId !== doorProduct.Id) return false;

    // Validate products
    if (this.topProduct === null || this.bottomProduct === null) {
      return false;
    }

    // Validate materials
    if (
      this.topProduct.PossibleMaterialIds.length < 1 ||
      this.bottomProduct.PossibleMaterialIds.length < 1
    ) {
      return false;
    }

    return true;
  }

  // #endregion Functions
}
