import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Exception from 'app/ts/exceptions';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TemplateService } from 'app/ts/services/TemplateService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'favourite-selector-vm',
  templateUrl: './favouriteSelector.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class FavouriteSelectorVm extends BaseVm implements OnInit {
  public templatePickables: Pickable<Interface_DTO.Template>[] = [];

  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly templateService: TemplateService,
    private readonly notificationService: NotificationService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.updateList();
  }

  public async updateList() {
    let templates = await this.notificationService.loadPromise(
      this.templateService.getTemplates(this.cabinetSection),
    );
    this.templatePickables = templates.map<Pickable<Interface_DTO.Template>>(
      (template) => {
        return {
          imageUrl: template.ImageUrl,
          disabledReason: null,
          isSelected: false,
          item: template,
          name: template.Name,
          override: false,
          description: '' + template.CabinetSectionId,
        };
      },
    );
  }

  public select(selection: Interface_DTO.Template) {
    this.activeModal.close(selection);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public async delete(item: Interface_DTO.Template) {
    let question = this.translate(
      'select_favourite_modal_delete_favourite_question',
      "Are you sure you want to delete the favourite '" + item.Name + "'?",
    );
    let answer = window.confirm(question);
    if (!answer) {
      return;
    }
    try {
      await this.notificationService.loadPromise(
        this.templateService.deleteTemplate(item),
      );
    } catch (e: any) {
      let handled = false;
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ReadOnlyObject) {
          handled = true;
          this.notificationService.userError(
            'delete_favourite_error_read_only',
            'This favourite is used in end-user configurator and cannot deleted until it is removed from the end-user configurator.',
          );
        }
      }
      if (!handled)
        this.notificationService.exception(
          'select_favourite_modal_delete_favourite_unknown_error',
          e,
          'Could not delete favourite',
        );
    }
    await this.updateList();
  }
}
