import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { Line } from 'app/measurement/measurement-length-calculations';
export class GeometryHelper {
  public static arePointsEqual(
    point1: Interface_DTO_Draw.Vec2d,
    point2: Interface_DTO_Draw.Vec2d,
  ): boolean {
    return point1.X === point2.X && point1.Y === point2.Y;
  }

  public static isPointInPolygon(
    point: Interface_DTO_Draw.Vec2d,
    polygon: Interface_DTO_Draw.Vec2d[],
  ): boolean {
    if (polygon.some((p) => GeometryHelper.arePointsEqual(p, point))) {
      return true;
    }

    var j = polygon.length - 1;
    var oddNodes = false;

    for (var i = 0; i < polygon.length; i++) {
      if (
        VectorHelper.distToSegment(point, polygon[i], polygon[j]) <
        Constants.sizeAndPositionTolerance
      ) {
        return true;
      }

      if (
        (polygon[i].Y < point.Y && polygon[j].Y >= point.Y) ||
        (polygon[j].Y < point.Y && polygon[i].Y >= point.Y)
      ) {
        if (
          polygon[i].X +
            ((point.Y - polygon[i].Y) / (polygon[j].Y - polygon[i].Y)) *
              (polygon[j].X - polygon[i].X) <
          point.X
        ) {
          oddNodes = !oddNodes;
        }
      }
      j = i;
    }

    return oddNodes;
  }

  public static areAllPointsInPolygon(
    points: Interface_DTO_Draw.Vec2d[],
    polygon: Interface_DTO_Draw.Vec2d[],
  ): boolean {
    for (var i = 0; i < points.length; i++) {
      if (!GeometryHelper.isPointInPolygon(points[i], polygon)) {
        //console.log("Point not in polygon: " + points[i].X + "," + + points[i].Y);
        return false;
      }
    }

    return true;
  }

  public static polygonsCollideSimple(
    polygon1: Interface_DTO_Draw.Vec2d[],
    polygon2: Interface_DTO_Draw.Vec2d[],
  ): boolean {
    for (var i = 0; i < polygon1.length; i++) {
      if (this.isPointInPolygon(polygon1[i], polygon2)) {
        return true;
      }
    }

    for (var i = 0; i < polygon2.length; i++) {
      if (this.isPointInPolygon(polygon2[i], polygon1)) {
        return true;
      }
    }

    return false;
  }

  public static polygonsCollide(
    polygon1: Interface_DTO_Draw.Vec2d[],
    polygon2: Interface_DTO_Draw.Vec2d[],
  ): boolean {
    let edgeCountA = polygon1.length;
    let edgeCountB = polygon2.length;

    if (edgeCountA === 0 || edgeCountB === 0) {
      return false;
    }

    let edge;

    // Loop through all the edges of both polygons
    for (let edgeIndex = 0; edgeIndex < edgeCountA + edgeCountB; edgeIndex++) {
      if (edgeIndex < edgeCountA) {
        edge = polygon1[edgeIndex];
      } else {
        edge = polygon2[edgeIndex - edgeCountA];
      }

      // Find the axis perpendicular to the current edge
      let axis = VectorHelper.getNormalizedVector({ X: -edge.Y, Y: edge.X });

      // Find the projection of the polygon on the current axis
      let projectionA = GeometryHelper.projectPolygon(axis, polygon1);
      let projectionB = GeometryHelper.projectPolygon(axis, polygon2);

      // Check if the polygon projections are currentlty intersecting
      if (
        GeometryHelper.intervalDistance(
          projectionA.min,
          projectionA.max,
          projectionB.min,
          projectionB.max,
        ) > 0
      ) {
        return false;
      }
    }

    return true;
  }

  private static intervalDistance(
    minA: number,
    maxA: number,
    minB: number,
    maxB: number,
  ): number {
    if (minA < minB) {
      return minB - maxA;
    } else {
      return minA - maxB;
    }
  }

  private static projectPolygon(
    axis: Interface_DTO_Draw.Vec2d,
    polygon: Interface_DTO_Draw.Vec2d[],
  ): { min: number; max: number } {
    // To project a point on an axis use the dot product
    let d = VectorHelper.dotProduct(axis, polygon[0]);
    let min = d;
    let max = d;
    for (let i = 0; i < polygon.length; i++) {
      d = VectorHelper.dotProduct(polygon[i], axis);
      if (d < min) {
        min = d;
      } else {
        if (d > max) {
          max = d;
        }
      }
    }

    return { min: min, max: max };
  }

  private static projectionPointIsOnLine(
    lineStart: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    point: Interface_DTO_Draw.Vec2d,
  ): boolean {
    let abDelta = { X: lineEnd.X - lineStart.X, Y: lineEnd.Y - lineStart.Y };
    let acDelta = { X: point.X - lineStart.X, Y: point.Y - lineStart.Y };

    let numerator = acDelta.X * abDelta.X + acDelta.Y * abDelta.Y;
    let denomenator = abDelta.X * abDelta.X + abDelta.Y * abDelta.Y;

    return numerator / denomenator >= 0 && numerator / denomenator <= 1;
  }

  private static locateProjection(
    lineStart: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    point: Interface_DTO_Draw.Vec2d,
  ): Enums.ProjectionLocation {
    let abDelta = { X: lineEnd.X - lineStart.X, Y: lineEnd.Y - lineStart.Y };
    let acDelta = { X: point.X - lineStart.X, Y: point.Y - lineStart.Y };

    let numerator = acDelta.X * abDelta.X + acDelta.Y * abDelta.Y;
    let denomenator = abDelta.X * abDelta.X + abDelta.Y * abDelta.Y;

    let result;
    if (numerator < 0) {
      result = Enums.ProjectionLocation.RayA;
    } else if (numerator > denomenator) {
      result = Enums.ProjectionLocation.RayB;
    } else {
      result = Enums.ProjectionLocation.SegmentAB;
    }

    return result;
  }

  public static getProjectionInfo(
    lineStart: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    point: Interface_DTO_Draw.Vec2d,
  ): Client.ProjectionInfo {
    let abDelta = { X: lineEnd.X - lineStart.X, Y: lineEnd.Y - lineStart.Y };
    let acDelta = { X: point.X - lineStart.X, Y: point.Y - lineStart.Y };

    let numerator = acDelta.X * abDelta.X + acDelta.Y * abDelta.Y;
    let denomenator = abDelta.X * abDelta.X + abDelta.Y * abDelta.Y;

    let projectionLocation;
    if (numerator < 0) {
      projectionLocation = Enums.ProjectionLocation.RayA;
    } else if (numerator > denomenator) {
      projectionLocation = Enums.ProjectionLocation.RayB;
    } else {
      projectionLocation = Enums.ProjectionLocation.SegmentAB;
    }

    let distanceFromStart =
      (numerator / denomenator) *
      GeometryHelper.distanceBetweenPoints(lineStart, lineEnd);
    let projectionPoint = GeometryHelper.getPointOnLine(
      lineStart,
      lineEnd,
      distanceFromStart,
    );

    return {
      projectedPoint: point,
      projectionLocation: projectionLocation,
      projectionPoint: projectionPoint,
    };
  }

  public static distanceBetweenPoints(
    p1: Interface_DTO_Draw.Vec2d,
    p2: Interface_DTO_Draw.Vec2d,
  ): number {
    let part1 = Math.pow(p2.X - p1.X, 2);
    let part2 = Math.pow(p2.Y - p1.Y, 2);
    let underRadical = part1 + part2;
    return Math.sqrt(underRadical);
  }

  public static getPointOnLine(
    lineStart: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    distanceFromStart: number,
  ): Interface_DTO_Draw.Vec2d {
    let radians = GeometryHelper.calculateLineAngle(lineStart, lineEnd);

    return {
      X: lineStart.X + Math.cos(radians) * distanceFromStart,
      Y: lineStart.Y + Math.sin(radians) * distanceFromStart,
    };
  }

  private static calculateLineAngle(
    startPoint: Interface_DTO_Draw.Vec2d,
    endPoint: Interface_DTO_Draw.Vec2d,
  ): number {
    let isVertical = endPoint.X == startPoint.X;
    let isHorizontal = endPoint.Y == startPoint.Y;
    let isGoingUp = startPoint.Y < endPoint.Y;
    let isGoingRight = startPoint.X < endPoint.X;

    if (isVertical) {
      if (isGoingUp) {
        return Math.PI * 0.5;
      } else {
        return Math.PI * 1.5;
      }
    } else if (isHorizontal) {
      if (isGoingRight) {
        return 0;
      } else {
        return Math.PI;
      }
    } else {
      let slope = isVertical
        ? Number.POSITIVE_INFINITY
        : (endPoint.Y - startPoint.Y) / (endPoint.X - startPoint.X);

      let radians = Math.atan(slope);
      if (!isGoingRight) radians += Math.PI;
      return radians % (Math.PI * 2);
    }
  }

  public static lineIntersectionPoint(
    lineA: Line,
    lineB: Line,
  ): Interface_DTO_Draw.Vec2d | null {
    if (!lineA.start || !lineA.end || !lineB.start || !lineB.end)
      //incomplete line -> no intersection
      return null;

    let lineAX = lineA.end.X - lineA.start.X;
    let lineAY = lineA.end.Y - lineA.start.Y;
    let lineBX = lineB.end.X - lineB.start.X;
    let lineBY = lineB.end.Y - lineB.start.Y;

    let denomerator = lineAX * lineBY - lineBX * lineAY;

    if (denomerator == 0)
      // lines are collinear
      return null;

    let lineABX = lineA.start.X - lineB.start.X;
    let lineABY = lineA.start.Y - lineB.start.Y;

    let s_numerator = lineAX * lineABY - lineAY * lineABX;
    if (s_numerator < 0 == denomerator > 0)
      // no intersection
      return null;

    let t_numerator = lineBX * lineABY - lineBY * lineABX;
    if (t_numerator < 0 == denomerator > 0)
      // no intersection
      return null;

    if (
      s_numerator > denomerator == denomerator > 0 ||
      t_numerator > denomerator == denomerator > 0
    )
      // no intersection
      return null;

    // intersection exists
    let t = t_numerator / denomerator;
    return {
      X: lineA.start.X + t * lineAX,
      Y: lineA.start.Y + t * lineAY,
    };
  }

  public static distanceToClosestSideOfPolygon(
    point: Interface_DTO_Draw.Vec2d,
    polygon: Interface_DTO_Draw.Vec2d[],
  ) {
    let distance = Number.MAX_VALUE;

    if (polygon.some((p) => GeometryHelper.arePointsEqual(p, point))) {
      return 0;
    }

    var j = polygon.length - 1;
    for (var i = 0; i < polygon.length; i++) {
      distance = Math.min(
        distance,
        VectorHelper.distToSegment(point, polygon[i], polygon[j]),
      );
      j = i;
    }

    return distance;
  }

  public static cubesOverlap(
    cube1: Interface_DTO_Draw.Cube,
    cube2: Interface_DTO_Draw.Cube,
  ): boolean {
    if (cube1.X + cube1.Width <= cube2.X) return false;
    if (cube2.X + cube2.Width <= cube1.X) return false;

    if (cube1.Y + cube1.Height <= cube2.Y) return false;
    if (cube2.Y + cube2.Height <= cube1.Y) return false;

    if (cube1.Z + cube1.Depth <= cube2.Z) return false;
    if (cube2.Z + cube2.Depth <= cube1.Z) return false;

    return true;
  }
}
