import { inject } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { Command } from './command';

/**
 * A request for posting http data
 */
export class HttpPost implements Command {
  constructor(
    private relativeUrl: string,
    private data: any,
  ) {}

  public async execute(): Promise<Command[]> {
    const httpClient = inject(PromisingBackendService);
    await httpClient.post(this.relativeUrl, this.data, {
      responseType: 'void',
    });
    return [];
  }
}
