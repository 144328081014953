<div class="canvas-overlay camera-controls">
  <table (mouseleave)="stopIntervalAction()">
    <tr>
      <td>
        <button
          class="btn btn-default btn-reset-camera"
          (click)="resetCamera()"
        >
          <span class="bi bi-arrow-clockwise"></span>
        </button>
      </td>
      <td></td>
      <td>
        <button
          class="btn btn-default btn-move-camera-up btn-move-camera"
          (mousedown)="moveUp()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-arrow-up-circle-fill"></span>
        </button>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        <button
          class="btn btn-default btn-zoom-camera btn-zoom-camera-in"
          (mousedown)="zoomIn()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-plus"></span>
        </button>
      </td>
      <td>
        <button
          class="btn btn-default btn-move-camera-left btn-move-camera"
          (mousedown)="moveLeft()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-arrow-left-circle-fill"></span>
        </button>
      </td>
      <td></td>
      <td>
        <button
          class="btn btn-default btn-move-camera-right btn-move-camera"
          (mousedown)="moveRight()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-arrow-right-circle-fill"></span>
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <button
          class="btn btn-default btn-zoom-camera btn-zoom-camera-out"
          (mousedown)="zoomOut()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-dash"></span>
        </button>
      </td>
      <td></td>
      <td>
        <button
          class="btn btn-default btn-move-camera-down btn-move-camera"
          (mousedown)="moveDown()"
          (mouseup)="stopIntervalAction()"
        >
          <span class="bi bi-arrow-down-circle-fill"></span>
        </button>
      </td>
    </tr>
  </table>
</div>

<div class="canvas-overlay scene-controls">
  <button
    class="scene-controls--btn scene-controls--toggle-rulers"
    (click)="toggleRulers()"
    *ngIf="showRulers === 'toggle'"
    [attr.title]="translate('consumer_3d_help_toggle_rulers', 'Show/hide Rulers')"
  >
    <img [src]="consumerFile('icons/ruler.png')" />
  </button>

  <button
    class="scene-controls--btn scene-controls--toggle-doors"
    (click)="toogleDoors()"
    *ngIf="showDoors === 'toggle'"
    [attr.title]="translate('consumer_3d_help_toggle_doors', 'Show/hide Doors')"
  >
    <img [src]="consumerFile('icons/doors.png')" />
  </button>
  <button
    class="scene-controls--btn scene-controls--toggle-pullout"
    (click)="togglePullout()"
    *ngIf="showPullout === 'toggle'"
    [attr.title]="translate('consumer_3d_help_toggle_pullout', 'Show/hide Pullout Warnings')"
  >
    <img [src]="consumerFile('icons/pullout.png')" />
  </button>

  <button
    class="scene-controls--btn scene-controls--undo"
    (click)="undo()"
    *ngIf="showUndoRedo"
    [ngClass]="{'disabled': !isUndoPossible}"
    [attr.title]="translate('consumer_3d_help_undo', 'Undo')"
  >
    <img [src]="consumerFile('icons/undo.png')" />
  </button>
  <button
    class="scene-controls--btn scene-controls--redo"
    (click)="redo()"
    *ngIf="showUndoRedo"
    [ngClass]="{'disabled': !isRedoPossible}"
    [attr.title]="translate('consumer_3d_help_redo', 'Redo')"
  >
    <img [src]="consumerFile('icons/redo.png')" />
  </button>
</div>

<div
  class="canvas-container"
  style="max-width: 100%"
  id="d3-canvas-container"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp($event)"
  (mouseleave)="onMouseLeave($event)"
  (mouseenter)="onMouseEnter($event)"
  (touchmove)="onMouseMove($event)"
  (touchend)="onMouseUp($event)"
  (touchstart)="onMouseDown($event)"
></div>
<div id="index-loading-overlay" *ngIf="showSpinner">
  <div id="index-loading-spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="spinner-border"
      viewBox="0 0 100 100"
    >
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(0   50 50)"
        style="animation-delay: calc(1500ms / 12 * (0 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(30  50 50)"
        style="animation-delay: calc(1500ms / 12 * (1 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(60  50 50)"
        style="animation-delay: calc(1500ms / 12 * (2 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(90  50 50)"
        style="animation-delay: calc(1500ms / 12 * (3 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(120 50 50)"
        style="animation-delay: calc(1500ms / 12 * (4 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(150 50 50)"
        style="animation-delay: calc(1500ms / 12 * (5 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(180 50 50)"
        style="animation-delay: calc(1500ms / 12 * (6 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(210 50 50)"
        style="animation-delay: calc(1500ms / 12 * (7 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(240 50 50)"
        style="animation-delay: calc(1500ms / 12 * (8 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(270 50 50)"
        style="animation-delay: calc(1500ms / 12 * (9 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(300 50 50)"
        style="animation-delay: calc(1500ms / 12 * (10 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(330 50 50)"
        style="animation-delay: calc(1500ms / 12 * (11 - 2))"
      ></line>
    </svg>
  </div>
</div>
