<div class="flex-all vflex route-login public-page" *ngIf="showUi">
  <div class="flex-center">
    <form class="form-like" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="container">
        <div class="col-9">
          <div class="cards">
            <div class="row spaced-row">
              <div class="col-lg-12">
                <div class="input-group lg">
                  <span class="input-group-text">
                    <span class="bi bi-person-fill"> </span>
                  </span>
                  <input
                    type="email"
                    autocomplete="username email"
                    autofocus
                    tabindex="1"
                    class="form-control"
                    placeholder="Email"
                    name="username"
                    formControlName="username"
                  />
                </div>
              </div>
            </div>

            <div class="row spaced-row">
              <div class="col-lg-12">
                <div class="input-group lg">
                  <span class="input-group-text">
                    <span class="bi bi-lock-fill"> </span>
                  </span>
                  <input
                    type="password"
                    autocomplete="password"
                    class="form-control"
                    placeholder="Password"
                    tabindex="2"
                    name="password"
                    formControlName="password"
                  />
                </div>
              </div>
            </div>

            <div class="row spaced-row">
              <div class="col-md-6">
                <div class="input-group lg">
                  <input
                    type="checkbox"
                    tabindex="3"
                    name="rememberMe"
                    onclick="event.stopPropagation()"
                    formControlName="rememberMe"
                    id="rememberMe"
                  />
                  <label for="rememberMe">{{
                    "login_remember_me" | translate: "Remember me"
                  }}</label>
                </div>
              </div>
              <div class="col-md-6" id="login-forgot-password-link">
                <a
                  (click)="resetPassword()"
                  [textContent]="
                    'login_forgot_password' | translate: 'Forgot Password?'
                  "
                ></a>
              </div>
            </div>

            <div class="row spaced-row login-button-row">
              <div class="col-2"></div>
              <div class="col-8">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  tabindex="4"
                  i8n
                >
                  {{ "login_login_button" | translate: "Login" }}
                </button>
              </div>
              <div class="col-2"></div>
            </div>

            <div class="row spaced-row teamviewer-support-row">
              <div class="col-2"></div>
              <div class="col-8">
                <a
                  class="btn btn-info btn-block"
                  tabindex="5"
                  href="https://get.teamviewer.com/j5896hw"
                  target="_blank"
                  >Teamviewer Support</a
                >
              </div>
              <div class="col-2"></div>
            </div>

            <div class="row spaced-row version-row">
              <div class="col-2"></div>
              <div class="col-10">
                <div class="row">
                  <app-version></app-version>
                </div>
              </div>
            </div>

            <div
              class="row spaced-row-x2"
              *ngIf="
                newAppVersionMessageDK.length > 0 ||
                newAppVersionMessageEN.length > 0
              "
            >
              <div class="new-major-app-version">
                <span
                  class="app-version-string-en"
                  *ngIf="newAppVersionMessageEN.length > 0"
                  [textContent]="newAppVersionMessageEN"
                ></span>
                <ng-container *ngIf="newAppVersionMessageDK.length > 0">
                  <br />
                  <br />
                  <span
                    class="app-version-string-dk"
                    [textContent]="newAppVersionMessageDK"
                  ></span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div><!--Spacer--></div>
  </div>
</div>
