import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from '@Services/LoginService';
import {
  Notification,
  NotificationService,
} from 'app/ts/services/NotificationService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'password-reset-landing-vm',
  templateUrl: './passwordResetLanding.html',
  styleUrls: [
    '../../identity-and-access/login.component.scss',
    '../../../style/form-like.scss',
  ],
})
export class PasswordResetLandingVm extends BaseVm implements OnInit {
  public static readonly Name: string = 'passwordResetLandingVm';

  private notification: Notification | null = null;

  public username: string = '';
  public newPassword = '';
  public confirmPassword = '';

  constructor(
    baseVmService: BaseVmService,
    private readonly notificationService: NotificationService,
    private readonly loginService: LoginService,
    private readonly $http: PromisingBackendService,
    private routing: AppRoutingModule,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(baseVmService);
  }

  ngOnInit(): void {
    this.username = this.activatedRoute.snapshot.paramMap.get('username') ?? '';
  }

  public async resetPassword(): Promise<void> {
    let newPassword = this.newPassword;

    if (!newPassword) {
      this.newNotification(
        this.notificationService.warning(
          'password_reset_nopassword_notification',
          'You must fill out your new password',
        ),
      );
      return;
    }
    if (newPassword != this.confirmPassword) {
      this.newNotification(
        this.notificationService.warning(
          'password_reset_mismatch_notification',
          'Passwords must be the same',
        ),
      );
      return;
    }

    try {
      let resetSuccess = await this.$http.post<boolean>('api/passwordReset', <
        Interface_DTO.PasswordResetRequest
      >{
        NewPassword: newPassword,
        ResetTokenString:
          this.activatedRoute.snapshot.paramMap.get('tokenString'),
      });

      if (!resetSuccess) {
        this.newNotification(
          this.notificationService.userError(
            'password_reset_fail_notification',
            'Resetting password failed. Please request a new password reset mail.',
          ),
        );
      } else {
        this.newNotification(
          this.notificationService.success(
            'password_reset_success_notification',
            'Password successfully reset',
          ),
        );

        try {
          const token = await this.loginService.login(
            this.username,
            newPassword,
            true,
          );
          this.loginService.loginOk(token, this.username, false, '/floorplans');
          this.notificationService.success(
            'login_success_notification_{0}',
            'You are now logged in as {0}',
            this.username,
          );
          this.router.navigate(['/floorplans']);
        } catch {
          this.routing.Login();
        }
      }
    } catch (e: any) {
      this.notificationService.exceptionWithDefaultText(e);
    }
  }

  private newNotification(newNotification: Notification) {
    if (this.notification) {
      this.notificationService.remove(this.notification);
    }
    this.notification = newNotification;
  }
}
