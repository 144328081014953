import { Component, Inject } from '@angular/core';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  UserSettings,
  UserSettingsInjector,
} from 'app/functional-core/ambient/userdata/UserData';
import * as Client from 'app/ts/clientDto/index';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { ITranslationService } from 'app/ts/services/TranslationService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { TableVm } from 'app/ts/viewmodels/components/TableVm';
import Enumerable from 'linq';

@Component({
  selector: 'customers-table',
  templateUrl: './customersBaseTable.html',
  styleUrls: [
    '../../../../style/overviews.scss',
    '../../../../style/IE-hacks.scss',
    '../../../../style/floorplans.scss',
    '../../../../style/rightMenu.scss',
    '../../../../style/tables.scss',
    '../../../../style/nav.scss',
    '../../../../style/briefStyling.scss',
  ],
  providers: [clientSettingProvider],
})
export class CustomersTableVm extends TableVm<Client.Customer> {
  constructor(
    baseVmService: BaseVmService,
    formattingService: FormattingService,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
    @Inject(UserSettingsInjector) private userData: UserSettings,
  ) {
    super(
      baseVmService,
      CustomersTableVm.getColumns(
        baseVmService.translationService,
        formattingService,
      ),
    );

    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.selectedColumns = settings.columnsCustomer;
      }),
    );
    super.ensureUnsubscribe(
      this.userData.subscribe((userData) => {
        this.columns = CustomersTableVm.getColumns(
          baseVmService.translationService,
          formattingService,
        );
      }),
    );

    this.reverseOrder = true;
  }

  public static getColumns(
    translationService: ITranslationService,
    formattingService: FormattingService,
  ): { [name: string]: Readonly<TableColumn<Client.Customer>> } {
    return {
      id: {
        displayName: translationService.translate(
          'customers_col_name_id',
          'Id',
        ),
        removable: true,
        colClass: 'id-col',
        val: (cust) => cust.Id,
      },
      debitorNo: {
        displayName: translationService.translate(
          'customers_col_name_debitorNo',
          'DebitorNo',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust) => cust.DebitorNo,
      },
      name: {
        displayName: translationService.translate(
          'customers_col_name_name',
          'Name',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust) => cust.Name,
      },
      address: {
        displayName: translationService.translate(
          'customers_col_name_address1',
          'Address',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust: Client.Customer) => cust.Address1,
      },
      address2: {
        displayName: translationService.translate(
          'customers_col_name_address2',
          'Address extra',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust: Client.Customer) => cust.Address2,
      },
      postalCodeCity: {
        displayName: translationService.translate(
          'customers_col_name_postalCodeCity',
          'City',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust: Client.Customer) => {
          let result = cust.PostalCode;
          result += result && cust.City ? ' ' + cust.City : cust.City || '';
          return result;
        },
      },
      phone: {
        displayName: translationService.translate(
          'customers_col_name_phone',
          'Phone nr',
        ),
        removable: true,
        colClass: 'text-col',
        val: (cust: Client.Customer) => cust.Phone,
      },
      numProjects: {
        displayName: translationService.translate(
          'customers_col_name_num_projects',
          'Projects',
        ),
        removable: true,
        colClass: 'number-col',
        val: (cust: Client.Customer) => cust.Projects.length,
      },
      numAddresses: {
        displayName: translationService.translate(
          'customers_col_name_num_addrs',
          'Delivery addresses',
        ),
        removable: true,
        colClass: 'number-col',
        val: (cust) =>
          cust.Projects.map((proj) => proj.DeliveryAddresses.length).reduce(
            (prev, curr) => prev + curr,
            0,
          ),
      },
      numFloorPlans: {
        displayName: translationService.translate(
          'customers_col_name_num_floorplans',
          'Floor Plans',
        ),
        removable: true,
        colClass: 'number-col',
        val: (cust) =>
          Enumerable.from(cust.Projects)
            .selectMany((proj) => proj.DeliveryAddresses)
            .select((da) => da.FloorPlans.length)
            .sum(),
      },
      createdDate: {
        displayName: translationService.translate(
          'customers_col_last_change',
          'Last changed date',
        ),
        removable: true,
        colClass: 'datetime-col',
        val: (cust) => {
          let date = DateHelper.fromIsoString(cust.CreatedDate);
          return formattingService.formatDate(date, true);
        },
        sortVal: (cust) => cust.CreatedDate,
      },
    };
  }

  public selectedColumns: string[] = [];

  public order = 'createdDate';

  public readonly leftClass = '';
  public readonly rightClass = 'bi bi-folder-fill';
}

export module CustomersTableVm {
  export class Columns {}
}
