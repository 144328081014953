import { Component, Inject } from '@angular/core';
import * as ClientSetting from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  ClientSettingData,
  ClientSettingInjector,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as App from 'app/ts/app';
import { BarHeightEx } from 'app/ts/Client/BarHeightEx';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { RouteService } from 'app/ts/services/RouteService';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'door-vm',
  templateUrl: 'doors.html',
  styleUrls: ['../../style/editor.scss'],
  providers: [ClientSetting.clientSettingProvider, DeliveryAddressService],
})
export class DoorVm extends EditorVm {
  public isAdvancedMenuOpen = false;

  public readonly doorProduct: Pickable<Client.Product | null>;
  public readonly profileMaterial: Pickable<Interface_DTO.Material | null>;
  public readonly railSet: Pickable<Client.RailSet | null>;
  public readonly railMaterialTop: Pickable<Interface_DTO.Material | null>;
  public readonly railMaterialBottom: Pickable<Interface_DTO.Material | null>;

  public readonly selectedFillingMaterial: Pickable<Interface_DTO.Material | null>;

  public readonly grip: Pickable<Client.Product | null>;

  public selectedFilling: Client.DoorFilling | null = null;
  public selectedBar: Client.DoorBar | null = null;
  public selectedOverlap: Client.DoorOverlap | null = null;

  private allClientSettings!: ClientSetting.ClientSettingData;

  private setDoorSettings(setting: Partial<ClientSetting.DoorSetting>) {
    if (this.allClientSettings.doors) {
      const doorClientSetting = {
        ...this.allClientSettings.doors,
        ...setting,
      };

      const allSettings = {
        ...this.allClientSettings,
      } as ClientSettingData;
      allSettings.doors = doorClientSetting;

      this.clientSettings.set(allSettings);
    }
  }

  public get showRulers(): boolean {
    return this.allClientSettings.doors
      ? this.allClientSettings.doors.displayRulers
      : false;
  }
  public set showRulers(val: boolean) {
    this.setDoorSettings({ displayRulers: val });
  }

  public get showPullout(): boolean {
    return this.allClientSettings.doors
      ? this.allClientSettings.doors.displayPulloutWarnings
      : false;
  }
  public set showPullout(val: boolean) {
    this.setDoorSettings({ displayPulloutWarnings: val });
  }
  public get showTopDown(): boolean {
    return this.allClientSettings.doors
      ? this.allClientSettings.doors.displayTopdown
      : false;
  }
  public set showTopDown(val: boolean) {
    this.setDoorSettings({ displayTopdown: val });
  }

  public get allowManualOverlaps(): boolean {
    return this.cabinetSection
      ? this.cabinetSection.doors.allowIndividualDoorWidths
      : false;
  }
  public get showAdminOnlyAttributes(): boolean {
    return this.cabinetSection
      ? this.cabinetSection.editorAssets.fullCatalog
      : false;
  }

  public get manualOverlaps(): boolean {
    return this.cabinetSection
      ? this.cabinetSection.doors.manualOverlapPositioning
      : false;
  }
  public set manualOverlaps(value: boolean) {
    if (this.cabinetSection) {
      this.cabinetSection.doors.manualOverlapPositioning = value;
      this.setChanged();
      if (value) {
        this._selectedPart$.next(this.cabinetSection.doors.overlaps[0]);
      } else {
        this.closeRightMenu();
      }
    }
  }

  public get currentBarHeight(): number {
    if (this.cabinetSection!.doors.desiredBarHeight === undefined) {
      return 0;
    }
    return this.cabinetSection!.doors.desiredBarHeight;
  }
  public set currentBarHeight(val: number) {
    if (!val) return;
    this.cabinetSection!.doors.desiredBarHeight = val;
    this.setChanged();
  }

  public get availableBars() {
    return this.cabinetSection!.doors.availableBars;
  }

  public get numOverlaps(): number {
    return this.cabinetSection!.doors.numberOfOverlaps;
  }
  public set numOverlaps(val: number) {
    if (!val) return;
    this.cabinetSection!.doors.numberOfOverlaps = val;
    this.setChanged();
  }

  public get numDoors(): number {
    return this.cabinetSection!.doors.numberOfDoors;
  }
  public set numDoors(val: number) {
    if (val === this.cabinetSection!.doors.numberOfDoors) return;
    this.cabinetSection!.doors.suppressNumDoorsChangeWarning = true;
    this.cabinetSection!.doors.numberOfDoors = val;
    this.setChanged();
  }
  public get canAddSoftclose() {
    return this.cabinetSection!.doors.canAddSoftclose;
  }

  public get useSoftclose() {
    return this.cabinetSection!.doors.addSoftClose;
  }
  public set useSoftclose(val: boolean) {
    this.cabinetSection!.doors.addSoftClose = val;
    if (this.canHavePositionStop) {
      if (val) {
        this.cabinetSection!.doors.numberOfPositionStops =
          this.cabinetSection!.NumberOfDoors - 2;
      } else {
        this.cabinetSection!.doors.numberOfPositionStops = 0;
      }
    }
    this.setChanged();
  }

  public get selectedPart$(): BehaviorSubject<
    Client.DoorBar | Client.DoorFilling | Client.DoorOverlap | null
  > {
    return this._selectedPart$;
  }
  private _selectedPart$ = new BehaviorSubject<
    Client.DoorBar | Client.DoorFilling | Client.DoorOverlap | null
  >(null);

  constructor(
    baseVmService: BaseVmService,
    $window: WindowService,
    floorPlanService: FloorPlanService,
    floorSavePlanService: FloorPlanSaveService,
    routeService: RouteService,
    routing: AppRoutingModule,
    private readonly modalService: ModalService,
    @Inject(ClientSettingInjector)
    private clientSettings: ClientSetting.ClientSetting,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorSavePlanService,
      routeService,
      routing,
      deliveryAddressService,
    );

    this.doorProduct = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.profile,
      (prod) => {
        return prod
          ? {
              ...ProductHelper.getPickable(prod),
              isSelected: true,
            }
          : {
              name: this.translate('door_type_no_door_name', 'No Door'),
              disabledReason: null,
              groupName: this.translate('door_type_no_door_group', 'No Door'),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );
    this.profileMaterial = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.profileMaterial,
      (mat) => {
        return mat
          ? {
              ...MaterialHelper.getPickable(mat),
              isSelected: true,
            }
          : {
              name: this.translate(
                'door_type_no_profile_name',
                'No profile material',
              ),
              disabledReason: null,
              groupName: this.translate(
                'door_type_no_profile_group',
                'No Profile',
              ),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );
    this.railSet = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.railSet,
      (rs) => {
        return rs
          ? {
              ...RailSetHelper.getPickable(rs),
              isSelected: true,
            }
          : {
              name: this.translate('door_type_no_railset_name', 'No Railset'),
              disabledReason: null,
              groupName: this.translate(
                'door_type_no_railset_group',
                'No Railset',
              ),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );
    this.railMaterialTop = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.railMaterialTop,
      (mat) => {
        return mat
          ? {
              ...MaterialHelper.getPickable(mat),
              isSelected: true,
            }
          : {
              name: this.translate(
                'door_type_no_rail_material_top_name',
                'No top rail material',
              ),
              disabledReason: null,
              groupName: this.translate(
                'door_type_no_rail_material_group',
                'No Rail Material',
              ),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );
    this.railMaterialBottom = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.railMaterialBottom,
      (mat) => {
        return mat
          ? {
              ...MaterialHelper.getPickable(mat),
              isSelected: true,
            }
          : {
              name: this.translate(
                'door_type_no_rail_material_bottom_name',
                'No bottom rail material',
              ),
              disabledReason: null,
              groupName: this.translate(
                'door_type_no_rail_material_group',
                'No Rail Material',
              ),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );
    this.selectedFillingMaterial =
      new Client.ConverterPickable<Client.Material | null>(
        () => (this.selectedFilling ? this.selectedFilling.material : null),
        (mat) => {
          return mat
            ? {
                ...MaterialHelper.getPickable(mat),
                isSelected: true,
              }
            : {
                name: this.translate(
                  'door_filling_no_material_name',
                  'No filling',
                ),
                disabledReason: null,
                item: null,
                isSelected: true,
                override: false,
              };
        },
      );
    this.grip = new Client.ConverterPickable(
      () => this.cabinetSection!.doors.grip,
      (prod) => {
        return prod
          ? {
              ...ProductHelper.getPickable(prod),
              isSelected: true,
            }
          : {
              name: this.translate('door_grip_no_grip_name', 'No Grip'),
              disabledReason: null,
              groupName: this.translate('door_grip_no_grip_group', 'No Grip'),
              isSelected: true,
              item: null,
              override: false,
            };
      },
    );

    this.subscribeTo(this.selectedPart$, (part) => {
      this.closeRightMenu();
      if (App.debug.showSelectionChanges) {
        console.debug('selected door part changed: ', part);
      }
      if (!part) {
        return;
      } else if (part.type === Client.DoorBar.type) {
        this.selectedBar = part;
      } else if (part.type === Client.DoorFilling.type) {
        this.selectedFilling = part;
      } else if (part.type === Client.DoorOverlap.type) {
        this.selectedOverlap = part;
      } else {
        console.error('unknonw door part selected');
      }
    });

    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.allClientSettings = settings;
      }),
    );
  }

  public override undo() {
    if (this.isUndoEnabled) this.closeRightMenu();

    super.undo();
  }

  public override redo() {
    if (this.isRedoEnabled) this.closeRightMenu();

    super.undo();
  }

  // Called from EditorVm when the underlying floorplan has changed
  protected floorPlanChanged() {}

  public get showFixedBarsCheckbox(): boolean {
    if (this.selectedDoor == null) return false;

    // For some reason, which is absolutely not clear at all,
    // we need to check for the existence of the forceFixedBarVariantText
    // Probably because this text is only available for doors where it is relevant
    return (
      this.selectedDoor.mayForceFixedBars &&
      this.selectedDoor.doorSubSection.forceFixedBarVariantText != null &&
      this.selectedDoor.doorSubSection.forceFixedBarVariantText.length > 0
    );
  }

  public get showDoorOptions(): boolean {
    return this.cabinetSection!.doors.profile !== null;
  }

  public get showProfileMaterialSelector(): boolean {
    return this.cabinetSection!.doors.pickableProfileMaterials.length > 1;
  }

  public get showRailsetSelector(): boolean {
    return this.cabinetSection!.doors.pickableRailsets.length > 1;
  }

  public get showRailMaterialTopSelector(): boolean {
    return this.cabinetSection!.doors.pickableRailTopMaterials.length > 1;
  }

  public get showRailMaterialBottomSelector(): boolean {
    return this.cabinetSection!.doors.pickableRailBottomMaterials.length > 1;
  }

  public get selectedDoor(): Client.Door | null {
    return this.selectedFilling
      ? this.selectedFilling.door
      : this.selectedBar
        ? this.selectedBar.door
        : null;
  }

  public get selectedDoorNumFillings(): number | null {
    let door = this.selectedDoor;
    if (!door) return null;
    return door.numberOfFillings;
  }

  public set selectedDoorNumFillings(val: number | null) {
    if (!val) return;
    let door = this.selectedDoor;
    if (!door) return;
    door.numberOfFillings = val;

    let filling = this.selectedFilling;
    if (filling) {
      if (filling.index > val) {
        this._selectedPart$.next(door.fillings[val - 1]);
      }
    }

    let bar = this.selectedBar;
    if (bar) {
      if (bar.index >= val) {
        if (val === 1) {
          this._selectedPart$.next(door.fillings[0]);
        } else {
          this._selectedPart$.next(door.bars[0]);
        }
      }
    }
  }

  public async selectDoorProduct() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableProfiles,
      this.translate('door_type_selector_header', 'Door Type'),
      'product door-product',
    );
    let prod: Client.Product | null;
    try {
      prod = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.closeRightMenu();
    this.cabinetSection!.doors.profile = prod;
    this.setChanged();
  }

  public async selectProfileMaterial() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableProfileMaterials,
      this.translate(
        'door_profile_material_selector_header',
        'Door Profile Material',
      ),
      'material door-profile',
    );
    let material: Client.Material | null;
    try {
      material = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.doors.profileMaterial = material;
    this.setChanged();
  }

  public get onlyDoorVisible(): boolean {
    return (
      this.cabinetSection!.CabinetType === Interface_Enums.CabinetType.Doors
    );
  }

  public get onlyDoorForced(): boolean {
    return (
      this.cabinetSection!.editorAssets.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ForceOnlyDoors
      ] === '1'
    );
  }

  public get onlyDoor() {
    return this.cabinetSection!.doors.onlyDoor;
  }
  public set onlyDoor(val: boolean) {
    this.cabinetSection!.doors.onlyDoor = val;
    this.setChanged();
  }

  public async selectRailset() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableRailsets,
      this.translate('door_railset_selector_header', 'Rail sets'),
      'railset',
    );

    let railSet: Client.RailSet | null;
    try {
      railSet = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.doors.railSet = railSet;
    this.setChanged();
  }

  public async selectRailMaterialTop() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableRailTopMaterials,
      this.translate('door_rail_material_selector_header', 'Rail Material'),
      'material rail-material',
    );
    let material: Client.Material | null;
    try {
      material = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.doors.railMaterialTop = material;
    this.setChanged();
  }

  public async selectRailMaterialBottom() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableRailBottomMaterials,
      this.translate('door_rail_material_selector_header', 'Rail Material'),
      'material rail-material',
    );
    let material: Client.Material | null;
    try {
      material = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.doors.railMaterialBottom = material;
    this.setChanged();
  }

  public closeRightMenu(): void {
    this.selectedBar = null;
    this.selectedFilling = null;
    this.selectedOverlap = null;
  }

  public async selectFillingMaterial() {
    if (!this.selectedFilling) {
      throw new Error(
        "No filling selected - don't know which filling to select material for",
      );
    }
    let filling = this.selectedFilling;
    let material: Client.FillingMaterial;
    let modal = this.modalService.getPickableSelector(
      filling.pickableMaterials,
      this.translate('door_filling_material_header', 'Filling material'),
      'material door-filling',
    );
    try {
      material = await modal.result;
    } catch (e: any) {
      //User cancelled - do nothing
      return;
    }
    filling.fillingMaterial = material;
    this.setChanged();
  }

  public async selectGrip() {
    let modal = this.modalService.getPickableSelector(
      this.cabinetSection!.doors.pickableGrips,
      this.translate('door_grip_selector_header', 'Grip Type'),
      'product door-grip',
    );
    let grip: Client.Product | null;
    try {
      grip = await modal.result;
    } catch (e: any) {
      //user cancelled - do nothing
      return;
    }
    this.cabinetSection!.doors.grip = grip;
    this.setChanged();
  }

  public useSelectedFillingOnAll() {
    if (!this.selectedFilling)
      throw new Error(
        "No filling selected - don't know which filling to copy materials from",
      );
    let mat = this.selectedFilling.material;
    for (let filling of this.selectedFilling.door.fillings) {
      filling.material = mat;
    }
    this.setChanged();
  }

  public spreadFillingsEvenly() {
    if (!this.selectedDoor) {
      throw new Error(
        "No door selected - don't know which door to spread fillings evenly on",
      );
    }
    this.selectedDoor.mustSpreadBarsEvenly = true;
    this.setChanged();
  }

  public copyDoor() {
    if (!this.selectedDoor) {
      throw new Error(
        "No door selected - don't know which door to copy values from",
      );
    }
    this.selectedDoor.setCopyFrom();
    this.setChanged();
  }

  public get advancedRailLeftText(): string {
    if (this.cabinetSection!.doors.numberOfRailTracks === 1) {
      return this.translate('doors_label_door_space_left', 'Door space left');
    } else {
      return this.translate(
        'doors_label_extra_rail_width_left',
        'Extra rail width left',
      );
    }
  }

  public get advancedRailRightText(): string {
    if (this.cabinetSection!.doors.numberOfRailTracks === 1) {
      return this.translate('doors_label_total_door_width', 'Total door width');
    } else {
      return this.translate(
        'doors_label_extra_rail_width_right',
        'Extra rail width right',
      );
    }
  }

  public get canHavePositionStop() {
    return this.cabinetSection!.doors.canHavePositionStop;
  }

  public get selectedDoorVerticalBarOptionNumber() {
    if (!this.selectedDoor) return undefined;
    if (!this.selectedDoor.verticalBarOption) return undefined;
    return this.selectedDoor.verticalBarOption.Number;
  }
  public set selectedDoorVerticalBarOptionNumber(val: string | undefined) {
    if (!this.selectedDoor) return;
    let vbos = this.availableVerticalBarOptions;
    let vbo = vbos.filter((o) => o.Number === val)[0];
    this.selectedDoor.verticalBarOption = vbo;
  }

  public barHeightDisabledReason(
    barHeight: BarHeightEx,
  ): Client.Translatable | null {
    let isVerticalBarsUsed = this.cabinetSection!.doors.doors.some(
      (d) => d.numberOfVerticalBars > 0,
    );
    if (isVerticalBarsUsed) {
      if (!barHeight.isCompatibleWithVerticalBars) {
        return new Client.Translatable(
          'doors_bar_loose_unavailable_vertical_bars',
          'Loose bars are not available when using vertical bars',
        );
      }
    }
    let requiresSplitFillings = this.cabinetSection!.doors.doors.some(
      (door) => door.numberOfFillingsMin > 1,
    );
    if (requiresSplitFillings) {
      if (!barHeight.isCompatibleWithSplitFillings) {
        return new Client.Translatable(
          'doors_bar_loose_unavailable_split_fillings',
          'Loose bars are not available when using very large doors',
        );
      }
    }

    return null;
  }

  public get availableVerticalBarOptions() {
    return this.cabinetSection!.doors.availableVerticalBarOptions;
  }

  public verticalBarDisabledReason(
    verticalBar: Interface_DTO.VariantOption,
  ): Client.Translatable | null {
    if (verticalBar.Number === VariantNumbers.Values.NoVerticalBars)
      return null;
    let barHeight = this.cabinetSection!.doors.currentBarHeight;
    if (barHeight) {
      if (!barHeight.isCompatibleWithVerticalBars) {
        return new Client.Translatable(
          'doors_vertical_bars_unavailable_loose_bars',
          'Vertical bars are not available with the current bar height',
        );
      }
    }
    return null;
  }
}
