import * as Enums from 'app/ts/clientDto/Enums';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';

export class FloorPlanLogic {
  constructor() {}

  public static isValidCombination(
    productLine: Interface_DTO.ProductLine | Enums.PseudoProductLine,
    cabinetType: Interface_Enums.CabinetType,
  ): boolean {
    if (typeof productLine === 'object') {
      if (productLine.Id == ProductLineIds.Partition) {
        return cabinetType == Interface_Enums.CabinetType.Partition;
      }

      if (productLine.Id === ProductLineIds.Swing) {
        return cabinetType === Interface_Enums.CabinetType.Swing;
      }

      if (
        productLine.Id === ProductLineIds.Swing22 ||
        productLine.Id === ProductLineIds.Swing19
      ) {
        switch (cabinetType) {
          case Interface_Enums.CabinetType.SwingFlex:
            return true;
          case Interface_Enums.CabinetType.SwingFlexCornerCabinet:
            return productLine.AllowCornerCabinet;
          case Interface_Enums.CabinetType.SwingFlexUShapedCabinet:
            return productLine.AllowWalkIn;
          default:
            return false;
        }
      } else {
        switch (cabinetType) {
          case Interface_Enums.CabinetType.CornerCabinet:
            return productLine.AllowCornerCabinet;
          case Interface_Enums.CabinetType.WalkIn:
            return productLine.AllowWalkIn;
          case Interface_Enums.CabinetType.Doors:
            // Walk in productLine is not available for Door-cabinet
            return (
              productLine.Id !== ProductLineIds.WalkIn &&
              productLine.Id != ProductLineIds.Partition
            );
          case Interface_Enums.CabinetType.Swing:
            return false;
          case Interface_Enums.CabinetType.SwingFlex:
            return false;
          case Interface_Enums.CabinetType.SwingFlexCornerCabinet:
            return false;
          case Interface_Enums.CabinetType.SwingFlexUShapedCabinet:
            return false;
          case Interface_Enums.CabinetType.Partition:
            return productLine.Id == ProductLineIds.Partition;

          default:
            return true;
        }
      }

      return true;
    } else {
      return false;
    }
  }
}
