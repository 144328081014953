import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // A password reset url contains a jwt token string which
    // is case sensitive, wherefore we have this special case
    // TODO: The correct solution is to encode the jwt token string
    // in a case-insensitive way.
    if (url.includes('passwordReset') || url.includes('passwordreset'))
      return super.parse(url.replace('passwordReset', 'passwordreset'));

    let urlTree = super.parse(url);
    let lowerUrlTree = super.parse(url.toLocaleLowerCase());

    if (lowerUrlTree.queryParamMap.has('quicksearch')) {
      let quicksearch = urlTree.queryParamMap.get('quickSearch');
      lowerUrlTree.queryParams['quicksearch'] = quicksearch;
    }

    return lowerUrlTree;
  }
}
