<div class="editor-all no-left-menu no-right-menu" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'piecelist'"
    [floorPlan]="editorType.floorPlan"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div class="ribbon-menu">
    <div class="ribbon-block">
      <div class="btn-group">
        <button
          class="btn btn-default"
          (click)="openAddProduct()"
          [textContent]="translate('editor_piecelist_add_item', 'Add Item')"
        ></button>
        <button
          class="btn btn-default"
          (click)="openAddFittings()"
          [textContent]="translate('editor_piecelist_add_custom_item', 'Add Custom Item')"
        ></button>
      </div>
    </div>

    <div class="ribbon-block">
      <button
        class="btn btn-default"
        (click)="downloadPdf()"
        [textContent]="translate('editor_piecelist_download_as_pdf', 'Download as .pdf')"
      ></button>
    </div>

    <div class="ribbon-block">
      <input
        class="form-control"
        type="search"
        [attr.placeholder]="translate('editor_piecelist_search_placeholder', 'Search')"
        [(ngModel)]="searchString"
      />
    </div>
  </div>
  <div class="editor-center scrollable">
    <piece-list
      class="align-self-stretch"
      width="100%"
      [floorPlan]="floorPlan"
      (onItemClick)="itemClicked($event)"
      [searchString$]="searchString$"
    ></piece-list>
  </div>
  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
