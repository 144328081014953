import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AddressService } from 'app/ts/services/AddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from 'app/ts/services/LoginService';
import { Observable } from 'app/ts/util/Observable';
import { AddressEditVm } from 'app/ts/viewmodels/components/AddressEditVm';

@Component({
  selector: 'customer-edit',
  templateUrl: 'customer-edit.component.html',
  styleUrls: [
    '../../../../style/rightMenu.scss',
    '../../../../style/modal.scss',
    '../../../../style/tables.scss',
  ],
})
export class CustomerEditComponent
  extends AddressEditVm<DTO.Customer>
  implements OnChanges
{
  public readonly phoneObservable = new Observable<string | null>('');
  public defaultPhonePrefix: string = '';
  public requireEmail = false;
  public notEnabled = false;

  constructor(
    baseVmService: BaseVmService,
    addressService: AddressService,
    loginService: LoginService,
  ) {
    super(baseVmService, loginService, addressService);
    this.subscribeToOld(this.phoneObservable, (phoneNumber) => {
      if (this.detailObject) {
        if (phoneNumber) this.detailObject.Phone = phoneNumber;
      }
    });

    super.subscribeTo(
      loginService.salesChainSettings$,
      (settings) =>
        (this.requireEmail =
          settings[
            Interface_Enums.SalesChainSettingKey.RequireEmailWhenOrdering
          ] == '1'),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.detailObject) {
      this.phoneObservable.value = this.detailObject.Phone;

      this.defaultPhonePrefix =
        this.addressService.getPhonePrefix(this.detailObject.Country) || '';
    }
  }

  @Input()
  public get detailObject(): DTO.Customer {
    return this._detailObject;
  }
  public set detailObject(value: DTO.Customer | null) {
    if (value == null) return;
    this._detailObject = value;
  }

  public async countryChanged() {
    this.detailObject.PostalCode = '';
    this.detailObject.City = '';

    this.defaultPhonePrefix =
      this.addressService.getPhonePrefix(this.detailObject.Country) || '';

    window.setTimeout(() => this.phoneObservable.triggerUpdate(), 10);
  }

  protected phoneNumberChange(phoneNumber: string) {
    this.phoneObservable.value = phoneNumber;
  }
}
