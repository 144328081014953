import { Component, Input } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'link-browser',
  templateUrl: './linkBrowser.html',
  styleUrls: ['../../../../style/linkBrowser.scss'],
})
export class LinkBrowserVm extends BaseVm {
  constructor(baseVmService: BaseVmService, assetService: AssetService) {
    super(baseVmService);
    this.rootFolderContents = assetService.folders.map((dtoFolder) =>
      this.mapToClientFolder(dtoFolder),
    );
    if (this.rootFolderContents.length > 0) {
      this.rootFolderContents[0].isOpen = true;
      this.linkBrowserLinks = this.rootFolderContents[0].Links;
    }
    this._linkBrowserSelectedFolder = this.rootFolderContents[0];
  }

  public rootFolderContents: Client.Folder[] = [];

  @Input()
  private _linkBrowserSelectedFolder: Client.Folder;
  public get linkBrowserSelectedFolder(): Client.Folder {
    return this._linkBrowserSelectedFolder;
  }
  public set linkBrowserSelectedFolder(value: Client.Folder) {
    this._linkBrowserSelectedFolder = value;
    this.linkBrowserLinks = this._linkBrowserSelectedFolder.Links;
  }

  public linkBrowserLinks: Interface_DTO.Link[] = [];

  private mapToClientFolder(dtoFolder: Interface_DTO.Folder): Client.Folder {
    let result: Client.Folder = {
      ...dtoFolder,
      isOpen: false,
      SubFolders: dtoFolder.Subfolders.map((dtoSubFolder) =>
        this.mapToClientFolder(dtoSubFolder),
      ),
    };
    return result;
  }
}
