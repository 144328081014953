import * as Interface_DTO from 'app/ts/Interface_DTO';
/**this exception is thrown when a domain error happens serverside (the exception to throw serverside is Interface.Exceptions.DomainErrorException)
 * Can be checked for clientside by using instanceof:
 * try {
 *      await $http.get('api/domainError')
 * } catch (e: any){
 *      if (e instanceof Exception.ServerDomainException) {
 *           //...handle error
 *      }
 * }*/
export class ServerDomainException implements Interface_DTO.DomainError {
  constructor(private readonly domainError: Interface_DTO.DomainError) {}
  public get Type() {
    return this.domainError.Type;
  }
  public get Text() {
    return this.domainError.Text;
  }
}
