import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { ModalService } from 'app/ts/services/ModalService';

@Component({
  selector: 'item-picker',
  templateUrl: './itemPicker.html',
  styleUrls: ['./item-picker.scss'],
})
export class ItemPickerVm<T> {
  private cache: Partial<{
    visibleItems: Pickable<T>[];
  }> = {};

  constructor(private readonly modalService: ModalService) {}

  //region AngularJS bindings
  private _items: Pickable<T>[] | undefined;
  @Input()
  get items(): Pickable<T>[] | undefined {
    return this._items;
  }
  set items(val: Pickable<T>[] | undefined) {
    this._items = val;
    this.clearCache();
  }

  private _selectedItem: T | undefined;
  @Input()
  get selectedItem(): T | undefined {
    return this._selectedItem;
  }
  set selectedItem(val: T | undefined) {
    let hasChanged = val != this._selectedItem && this._selectedItem != null;
    this._selectedItem = val;
    this.clearCache();
    if (hasChanged == true) {
      this.selectedItemChange.emit(val);
    }
  }

  @Output()
  public selectedItemChange = new EventEmitter<T>();

  private _maxItems: number | undefined;
  @Input()
  get maxItems(): number | undefined {
    return this._maxItems;
  }
  set maxItems(val: number | undefined) {
    this._maxItems = val;
    this.clearCache();
  }

  private _displayHeader: number | undefined;
  get displayHeader() {
    return this._displayHeader;
  }
  set displayHeader(val: number | undefined) {
    this._displayHeader = val;
    this.clearCache();
  }

  @Input()
  public name: string = '';
  @Input()
  displayId?: boolean;
  displayMode: 'cover' | 'contain' | undefined;

  //endregion

  public isSelected(pickable: Pickable<T>): boolean {
    return pickable.item === this.selectedItem || pickable.isSelected;
  }

  public get actualDisplayHeader() {
    if (this.displayHeader === undefined) return true;
    return this.displayHeader;
  }

  private get actualMaxItems() {
    return this.maxItems === undefined ? 6 : this.maxItems;
  }

  get visibleItems(): Pickable<T>[] {
    if (this.cache.visibleItems === undefined) {
      if (!this.items) {
        this.cache.visibleItems = [];
      } else {
        let maxItems = this.actualMaxItems;
        if (this.items.length <= maxItems) {
          this.cache.visibleItems = this.items;
        } else {
          this.cache.visibleItems = this.items.slice(0, maxItems - 1);
        }
      }
    }
    return this.cache.visibleItems;
  }

  getStyle(p: Pickable<T>) {
    const result: any = {};
    if (p.imageUrl) {
      result['background-image'] = `url(${encodeURI(p.imageUrl)})`;
    }
    return result;
  }

  get isPickerVisible(): boolean {
    if (!this.items) return false;
    return this.items.length > this.actualMaxItems;
  }

  async showPicker() {
    if (!this.items) return;
    let modal = this.modalService.getPickableSelector<T>(
      this.items,
      this.name,
      '',
    );
    try {
      let result = await modal.result;
      this.selectedItem = result;
    } catch (ex) {
      //user cancelled - Ignore
    }
  }

  private clearCache() {
    this.cache = {};
  }
}
