import * as Client from 'app/ts/clientDto/index';
import { ProductData } from 'app/ts/Interface_DTO';
import { Module, ModuleType } from './module';
import { PartitionPlanGeometryQueryService } from './partition-plan-geometry-query.service';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import { PartitionRailService } from './partition-rail.service';
import { Rail, RailId, RailPlacement } from './rail';
import { Section, SectionId } from './section';

export class RailBuilder {
  private xOffsetFromOrigion: number = 0;
  private currentLength: number = 0;
  private coveredSections: SectionId[] = [];

  private lastCreatedRailId: RailId | null = null;

  constructor(
    private railService: PartitionRailService,
    private query: PartitionPlanQueryService,
    private queryGeometry: PartitionPlanGeometryQueryService,
    private product: Client.Product,
    private originSection: Section,
    private placement: RailPlacement,
  ) {}

  public addLength(sectionId: SectionId, amount: number) {
    this.currentLength += amount;
    this.addCoveredSection(sectionId);

    while (this.isMaxLengthOrLonger()) {
      let { relativeX, relativeY } =
        this.railService.calculateRailRelativeOffsets(
          this.originSection,
          this.xOffsetFromOrigion,
          this.getYOffset(),
          this.railProductData!.DefaultDepth,
        );
      let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
        this.originSection.id,
        relativeX,
        relativeY,
      );

      this.lastCreatedRailId = this.railService.addRail({
        relativeX: this.xOffsetFromOrigion,
        relativeY: this.getYOffset(),
        posX: pos.X,
        posY: pos.Y,
        positionedFromSection: this.originSection.id,
        width: this.getRailMaxLength(),
        depth: this.railProductData.DefaultDepth,
        height: this.railProductData.DefaultHeight,
        rotation: this.originSection.isHorizontal ? 0 : 90,
        productId: this.product.Id,
        placement: this.placement,
        moduleType: ModuleType.Wall,
        materialId: this.railService.getRailMaterialId(
          this.coveredSections,
          this.product.Id,
        ),
        positionNo: -1,
      });

      this.coveredSections.forEach((covSecId) =>
        this.query.getSection(covSecId).rails.push(this.lastCreatedRailId!),
      );

      this.currentLength -= this.getRailMaxLength();
      this.coveredSections = this.currentLength > 0 ? [sectionId] : []; // If any length left of the current section, it is covered by the next rail

      this.xOffsetFromOrigion += this.getRailMaxLength();
    }
  }

  private addCoveredSection(sectionId: SectionId) {
    if (!this.coveredSections.includes(sectionId)) {
      this.coveredSections.push(sectionId);
    }
  }

  private isMaxLengthOrLonger() {
    return this.currentLength >= this.getRailMaxLength();
  }

  private getRailMaxLength(): number {
    return this.railProductData.MaxWidth;
  }

  private getRailMinLength(): number {
    return this.railProductData.MinWidth;
  }

  private getYOffset(): number {
    return (this.railProductData!.Model3DDepth - Module.fullJointWidth) / 2;
  }

  public get railProductData(): ProductData {
    let data = this.product.getProductData(
      this.railService.getRailMaterialId(this.coveredSections, this.product.Id),
    );

    if (data) return data;
    else return this.product.getProductData()!;
  }

  public startRail(coverSectionId: SectionId, xOffset: number) {
    this.xOffsetFromOrigion = xOffset;
    this.currentLength = 0;
    this.coveredSections = [coverSectionId];
  }

  public endRail(allowOutsideSizeRange: boolean = false) {
    if (this.currentLength == 0) return;

    if (!allowOutsideSizeRange) this.ensureMinWidth();

    let { relativeX, relativeY } =
      this.railService.calculateRailRelativeOffsets(
        this.originSection,
        this.xOffsetFromOrigion,
        this.getYOffset(),
        this.railProductData!.DefaultDepth,
      );
    let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
      this.originSection.id,
      relativeX,
      relativeY,
    );

    this.lastCreatedRailId = this.railService.addRail({
      relativeX: this.xOffsetFromOrigion,
      relativeY: this.getYOffset(),
      posX: pos.X,
      posY: pos.Y,
      positionedFromSection: this.originSection.id,
      width: this.currentLength,
      depth: this.railProductData.DefaultDepth,
      height: this.railProductData.DefaultHeight,
      rotation: this.originSection.isHorizontal ? 0 : 90,
      productId: this.product.Id,
      placement: this.placement,
      moduleType: ModuleType.Wall,
      materialId: this.railService.getRailMaterialId(
        this.coveredSections,
        this.product.Id,
      ),
      positionNo: -1,
    });

    this.coveredSections.forEach((covSecId) =>
      this.query.getSection(covSecId).rails.push(this.lastCreatedRailId!),
    );
  }

  public ensureMinWidth() {
    if (this.currentLength < this.getRailMinLength()) {
      let missing = this.getRailMinLength() - this.currentLength;

      let lastRail = this.query.getRail(this.lastCreatedRailId!) as Rail;

      lastRail.width -= missing;
      this.xOffsetFromOrigion -= missing;
      this.currentLength += missing;
    }
  }
}
