<div id="index-loading-overlay">
  <div id="index-loading-spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="spin spinning"
      viewBox="0 0 100 100"
    >
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(0   50 50)"
        style="animation-delay: calc(1500ms / 12 * (0 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(30  50 50)"
        style="animation-delay: calc(1500ms / 12 * (1 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(60  50 50)"
        style="animation-delay: calc(1500ms / 12 * (2 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(90  50 50)"
        style="animation-delay: calc(1500ms / 12 * (3 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(120 50 50)"
        style="animation-delay: calc(1500ms / 12 * (4 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(150 50 50)"
        style="animation-delay: calc(1500ms / 12 * (5 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(180 50 50)"
        style="animation-delay: calc(1500ms / 12 * (6 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(210 50 50)"
        style="animation-delay: calc(1500ms / 12 * (7 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(240 50 50)"
        style="animation-delay: calc(1500ms / 12 * (8 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(270 50 50)"
        style="animation-delay: calc(1500ms / 12 * (9 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(300 50 50)"
        style="animation-delay: calc(1500ms / 12 * (10 - 2))"
      ></line>
      <line
        class="spinline"
        x1="50"
        x2="50"
        y1="10"
        y2="25"
        transform="rotate(330 50 50)"
        style="animation-delay: calc(1500ms / 12 * (11 - 2))"
      ></line>
    </svg>
  </div>
</div>
