import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-errors-modal-vm',
  templateUrl: './consumerErrorsModal.html',
})
export class ConsumerErrorsModalVm extends BaseVm {
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public readonly errors!: Client.ErrorInfo[];

  public select(err: Client.ErrorInfo) {
    this.activeModal.close(err);
  }

  public close() {
    this.activeModal.close(null);
  }
}
