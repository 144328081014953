import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from '@Util/VectorHelper';

export abstract class BaseSnapService<T extends Client.SnapInfo>
  implements ISnapInfoService
{
  protected lastSnapInfo: T | undefined = undefined;

  constructor(
    protected readonly cabinetSection: Client.CabinetSection,
    protected readonly item: Client.ConfigurationItem,
    protected readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
    private readonly fallbackSnapService: ISnapInfoService,
  ) {}

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    this.lastSnapInfo = this.overriddenGetSnapInfo(pos);
    if (this.lastSnapInfo) return this.lastSnapInfo;
    else return this.fallbackSnapService.getSnapInfo(pos);
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (!this.lastSnapInfo || !this.item.Product) {
      return this.fallbackSnapService.place();
    }

    if (App.debug.showSnapInfo) console.debug('snapped by me', this);

    this.item.X += this.lastSnapInfo.dropOffset.X;
    this.item.Y += this.lastSnapInfo.dropOffset.Y;
    this.item.Z += this.lastSnapInfo.dropOffset.Z;

    if (this.item.cabinetSection !== this.cabinetSection) {
      let oldIndex = this.item.cabinetSection.interior.items.indexOf(this.item);
      if (oldIndex >= 0) {
        this.item.cabinetSection.interior.items.splice(oldIndex, 1);
      }
    }
    if (this.cabinetSection.interior.items.indexOf(this.item) < 0) {
      this.cabinetSection.interior.items.push(this.item);
    }

    if (this.lastSnapInfo.newSize) {
      if (this.lastSnapInfo.newSize.X) {
        let newWidth = this.lastSnapInfo.newSize.X;
        if (newWidth && newWidth !== this.item.Width) {
          let productGroup = Enumerable.from(this.item.Product.productGroup);
          let newProduct = productGroup.firstOrDefault(
            (prod) => ProductHelper.defaultWidth(prod) === newWidth,
          );
          if (!newProduct) {
            newProduct = productGroup.firstOrDefault((prod) => {
              if (!ProductHelper.isFlexWidth(prod)) return false;
              if (ProductHelper.minWidth(prod) > newWidth) return false;
              return ProductHelper.maxWidth(prod) >= newWidth;
            });
          }
          if (newProduct) {
            this.item.Product = newProduct;
            this.item.Width = newWidth;
            this.item.trySetWidth(newWidth);
          } else {
            console.error(
              'could not find a suitable product for width ' + newWidth,
              this.item,
            );
          }
        }
      }

      if (!this.item.IsLocked) {
        if (this.lastSnapInfo.newSize.Y) {
          this.item.Height = this.lastSnapInfo.newSize.Y;
        }
        if (this.lastSnapInfo.newSize.Z) {
          this.item.Depth = this.lastSnapInfo.newSize.Z;
        }
      }
    }

    if (this.cabinetSection.isSwingFlex) {
      for (let i = 0; i < this.cabinetSection.swingFlex.areas.length; i++) {
        let area = this.cabinetSection.swingFlex.areas[i];

        if (this.lastSnapInfo) {
          let rawItemPos = VectorHelper.subtract(area.insideRect, this.item);

          if (this.lastSnapInfo.snapsTo.find((c) => c.isFittingPanel)) {
            const fittingPanels = this.lastSnapInfo.snapsTo.filter(
              (c) => c.isFittingPanel,
            );
            const fittingPanelLeft = fittingPanels.find((c) => c.drilledRight);
            const swingFlexProperties =
              this.item.cabinetSection.swingFlex.productLineProperties;

            let swingFlexFittingPanelSpacerWidth =
              swingFlexProperties.SwingFlexFittingPanelSpacerWidth;

            if (rawItemPos.X === 0) {
              this.item.swingFlexAreaIndex = area.index;
            } else if (fittingPanelLeft) {
              let diff = Math.abs(
                rawItemPos.X +
                  (swingFlexFittingPanelSpacerWidth + fittingPanelLeft.Width),
              );
              if (diff >= 0 && diff < fittingPanelLeft.Width) {
                this.item.swingFlexAreaIndex = area.index;
              }
            }
          } else {
            if (rawItemPos.X === 0) {
              this.item.swingFlexAreaIndex = area.index;
            }
          }
        }
      }
    }

    return {
      items: this.overriddenPlace(this.lastSnapInfo),
      recalculationMessages: [],
    };
  }

  protected abstract overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): T | undefined;
  protected abstract overriddenPlace(
    lastSnapInfo: T,
  ): [Client.ConfigurationItem];

  public dispose() {}
}
