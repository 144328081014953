import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AddressService } from 'app/ts/services/AddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from 'app/ts/services/LoginService';
import { DetailEditVm } from 'app/ts/viewmodels/components/DetailEditVm';
import { debounceTime, filter, from, switchMap, tap } from 'rxjs';
@Injectable()
export abstract class AddressEditVm<
  T extends Interface_DTO.IPostalAddress,
> extends DetailEditVm<T> {
  private salesChainAllowsFloorSelection: boolean = true;
  private firstRun = true;
  private _hadFloor = false;

  public countries: Interface_DTO.Country[] = [];

  private cityCache: AddressEditVm.CityCache | undefined = undefined;

  public addressSuggestions?: Interface_DTO.AddressAutocompleteResponse;

  private _addressSuggestionsOpen = false;
  public selectedAddressSuggestion: number | undefined = undefined;

  public address1Control: FormControl;

  constructor(
    baseVmService: BaseVmService,
    loginService: LoginService,
    protected readonly addressService: AddressService,
  ) {
    super(baseVmService);

    this.address1Control = new FormControl('');

    this.setCountries(null);
    this.subscribeTo(loginService.salesChainSettings$, (settings) => {
      this.salesChainAllowsFloorSelection =
        settings[Interface_Enums.SalesChainSettingKey.AllowFloorSelection] ===
        '1';
      this.setCountries(
        settings[
          Interface_Enums.SalesChainSettingKey.AvailableCustomerCountries
        ],
      );
    });

    this.ensureUnsubscribe(
      this.address1Control.valueChanges
        .pipe(
          filter(
            (value) =>
              this._detailObject.Address1.length >= 3 &&
              value != this._detailObject.Address1,
          ),
          tap((value) => (this._detailObject.Address1 = value)),
          debounceTime(300),
          switchMap(() => {
            return from(
              this.addressService
                .getAutocomplete({
                  Address1: this._detailObject?.Address1 ?? '',
                  City: this._detailObject?.City ?? '',
                  PostalCode: this._detailObject?.PostalCode ?? '',
                  CountryCode: this._detailObject?.Country ?? '',
                  CaretPos: this._detailObject?.Address1
                    ? this._detailObject.Address1.length
                    : 0,
                })
                .then((response) => {
                  this._addressSuggestionsOpen = true;
                  this.selectedAddressSuggestion = 0;
                  this.addressSuggestions = response;
                }),
            );
          }),
        )
        .subscribe(),
    );
  }

  public onPasteAddress(event: ClipboardEvent) {
    if (!event.clipboardData) return;

    event.preventDefault();
    this._detailObject.Address1 = event.clipboardData.getData('text/plain');

    this.addressService
      .getAutocomplete({
        Address1: this._detailObject?.Address1 ?? '',
        City: this._detailObject?.City ?? '',
        PostalCode: this._detailObject?.PostalCode ?? '',
        CountryCode: this._detailObject?.Country ?? '',
        CaretPos: this._detailObject?.Address1
          ? this._detailObject.Address1.length
          : 0,
      })
      .then((response) => {
        this._addressSuggestionsOpen = true;
        this.selectedAddressSuggestion = 0;
        this.addressSuggestions = response;
      });
  }

  private setCountries(countryCodes: string | null) {
    let splitCodes: string[] = [];
    if (countryCodes) {
      splitCodes = countryCodes.split(/\,\s*/).map((cc) => cc.toLowerCase());
    }
    this.addressService.countryPromises.then((sourceCountries) => {
      let countries = sourceCountries.map((country) => ({
        ...country,
        Name: this.translate('country_name_' + country.Code, country.Name),
      }));

      if (splitCodes.length > 0) {
        countries = countries.filter(
          (c) => splitCodes.indexOf(c.Code.toLowerCase()) >= 0,
        );
      }
      this.countries = countries;
    });
  }

  public get cities(): Interface_DTO.City[] {
    let cache = this.getCityCache();
    return cache ? cache.cities : [];
  }

  private getCityCache(): AddressEditVm.CityCache | undefined {
    if (!this._detailObject || !this._detailObject.PostalCode) return;
    if (this.cityCache) {
      if (
        this.cityCache.country !== this._detailObject.Country ||
        this.cityCache.postalCode !== this._detailObject.PostalCode
      ) {
        this.cityCache = undefined;
      }
    }
    if (!this.cityCache) {
      let cityCache: AddressEditVm.CityCache = {
        country: this._detailObject.Country,
        postalCode: this._detailObject.PostalCode,
        loaded: false,
        cities: [],
      };
      this.cityCache = cityCache;
      let postalCodeLower = this._detailObject.PostalCode.toLowerCase();
      this.addressService
        .getCitiesAsync(this._detailObject.Country, postalCodeLower, false)
        .then((cities) => {
          cityCache.loaded = true;
          cityCache.cities = cities.filter(
            (city) => city.ZipCodeNoPrefix.toLowerCase() === postalCodeLower,
          );
        });
    }
    return this.cityCache;
  }

  private get postalCodeAllowsFloorSelection(): boolean {
    if (!this._detailObject) return true;

    let cities = this.cities;
    if (!this.cityCache || !this.cityCache.loaded) return true;

    if (!this._detailObject.City) return true;

    let city: Interface_DTO.City | undefined = undefined;

    if (this._detailObject && this._detailObject.City && cities) {
      if (cities.length === 0) {
        city = cities[0];
      } else {
        city = this.getBestNameMatch(cities, this._detailObject.City);
      }
    }

    if (!city) return false;

    return !city.DisallowFloorOption;
  }

  private getBestNameMatch(
    cities: Interface_DTO.City[],
    cityName: string,
  ): Interface_DTO.City | undefined {
    let nameLower = cityName.toLowerCase();
    let maxDist = 3;
    let bestDist = Number.MAX_VALUE;
    let result: Interface_DTO.City | undefined = undefined;
    for (let c of cities) {
      let dist = this.damerauLevenshteinDistance(
        c.Name.toLowerCase(),
        nameLower,
        maxDist,
      );
      if (dist < bestDist) {
        result = c;
        bestDist = dist;
        if (dist === 0) break;
      }
    }
    return result;
  }

  private damerauLevenshteinDistance(
    source: string,
    target: string,
    threshold: number | null,
  ): number {
    // from https://stackoverflow.com/a/9454016
    let length1 = source.length;
    let length2 = target.length;
    if (threshold !== null) {
      if (Math.abs(length1 - length2) > threshold) {
        // Return trivial case - difference in string lengths exceeds threshhold
        return Number.MAX_VALUE;
      }
    }

    // Ensure arrays [i] / length1 use shorter length
    if (length1 > length2) {
      let lSwap = length1;
      length1 = length2;
      length2 = lSwap;
      let sSwap = source;
      source = target;
      target = sSwap;
    }

    let maxi = length1;
    let maxj = length2;
    let dcurrent = new Array(maxi + 1);
    let dMinus1 = new Array(maxi + 1);
    let dMinus2 = new Array(maxi + 1);
    let dSwap: number[];

    for (let i = 0; i <= maxi; i++) {
      dcurrent[i] = i;
    }

    let jm1 = 0;
    let im1, im2;
    for (let j = 1; j <= maxj; j++) {
      //rotate
      dSwap = dMinus2;
      dMinus2 = dMinus1;
      dMinus1 = dcurrent;
      dcurrent = dSwap;

      //initialize
      let minDistance = Number.MAX_VALUE;
      dcurrent[0] = j;
      im1 = 0;
      im2 = -1;

      for (let i = 1; i <= maxi; i++) {
        let cost = source[im1] === target[jm1] ? 0 : 1;
        let del = dcurrent[im1] + 1;
        let ins = dMinus1[i] + 1;
        let sub = dMinus1[im1] + cost;

        //Fastest execution for min value of 3 integers
        let min = del < ins ? (ins > sub ? sub : ins) : del > sub ? sub : del;
        if (
          i < 1 &&
          source[im2] === target[jm1] &&
          source[im1] === target[j - 2]
        ) {
          min = Math.min(min, dMinus2[im2] + cost);
        }
        dcurrent[i] = min;
        if (min < minDistance) {
          minDistance = min;
        }
        im1++;
        im2++;
      }
      jm1++;
      if (threshold !== null && minDistance > threshold) {
        return Number.MAX_VALUE;
      }
    }
    let result = dcurrent[maxi];
    if (threshold !== null && result > threshold) {
      return Number.MAX_VALUE;
    }
    return result;
  }

  public get addressSuggestionsOpen(): boolean {
    return this._addressSuggestionsOpen;
  }
  public set addressSuggestionsOpen(val: boolean) {
    this._addressSuggestionsOpen = val;
  }

  private get hadFloor(): boolean {
    if (!this._detailObject) return true;
    else if (this.firstRun) {
      this._hadFloor = this._detailObject.Floor > 0;
      this.firstRun = false;
    }
    return this._hadFloor;
  }

  public get showFloorEditor(): boolean {
    return (
      this.hadFloor ||
      (this.salesChainAllowsFloorSelection &&
        this.postalCodeAllowsFloorSelection)
    );
  }

  public get showFloorEditorWarning(): boolean {
    return (
      !!this._detailObject &&
      this._detailObject.Floor > 0 &&
      this.hadFloor &&
      !(
        this.salesChainAllowsFloorSelection &&
        this.postalCodeAllowsFloorSelection
      )
    );
  }

  public get showFloorRow(): boolean {
    return this.hadFloor || this.salesChainAllowsFloorSelection;
  }

  public address1Key(evt: KeyboardEvent) {
    if (evt.key == 'Enter') {
      evt.preventDefault();
      this.useSelectedAddressSuggestion();
      return;
    } else if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      this.moveAddressSuggestion(1);
    } else if (evt.key === 'ArrowUp') {
      evt.preventDefault();
      this.moveAddressSuggestion(-1);
    } else {
      //this.address1Change(evt)
    }
  }

  private moveAddressSuggestion(amount: number) {
    if (
      !this.addressSuggestions ||
      this.addressSuggestions.Suggestions.length === 0
    ) {
      return;
    }
    if (this.selectedAddressSuggestion === undefined) {
      this.selectedAddressSuggestion = 0;
      return;
    }
    this.selectedAddressSuggestion += amount;
    if (this.selectedAddressSuggestion < 0) {
      this.selectedAddressSuggestion +=
        this.addressSuggestions.Suggestions.length;
    }
    this.selectedAddressSuggestion %=
      this.addressSuggestions.Suggestions.length;
  }

  public useSelectedAddressSuggestion() {
    if (
      this.selectedAddressSuggestion === undefined ||
      !this.addressSuggestions
    ) {
      console.warn('Could not use address suggestion, none exists');
      return;
    }
    let sugg =
      this.addressSuggestions.Suggestions[this.selectedAddressSuggestion];
    this.useSuggestion(sugg);
  }

  public async address1Blur() {
    window.setTimeout(() => (this.addressSuggestionsOpen = false), 200);
  }

  public useSuggestion(
    suggestion: Interface_DTO.AddressAutocompleteSuggestion,
  ) {
    if (suggestion.IsPartial) {
      if (this._detailObject) {
        this._detailObject.Address1 = suggestion.SuggestedText + ' ';
        this.addressService
          .getAutocomplete({
            Address1: this._detailObject?.Address1 ?? '',
            City: this._detailObject?.City ?? '',
            PostalCode: this._detailObject?.PostalCode ?? '',
            CountryCode: this._detailObject?.Country ?? '',
            CaretPos: this._detailObject?.Address1
              ? this._detailObject.Address1.length
              : 0,
          })
          .then((response) => {
            this._addressSuggestionsOpen = true;
            this.selectedAddressSuggestion = 0;
            this.addressSuggestions = response;
          });
      }
      return;
    } else {
      this._addressSuggestionsOpen = false;
      this.selectedAddressSuggestion = undefined;
      if (this._detailObject) {
        this._detailObject.Address1 = suggestion.Address1;
        this._detailObject.Address2 = suggestion.Address2;
        this._detailObject.PostalCode = suggestion.PostalCode;
        this._detailObject.City = suggestion.City;
        this._detailObject.Country = suggestion.Country;
        this._detailObject.Floor = suggestion.Floor;
      }
    }
  }
}

module AddressEditVm {
  export interface CityCache {
    country: string;
    postalCode: string;
    loaded: boolean;
    cities: Interface_DTO.City[];
  }
}
