import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { activeUserProvider } from 'app/functional-core/ambient/activeUser/ActiveUser';
import * as DTO from 'app/ts/interface_dto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  selector: 'new-customer-vm',
  templateUrl: './newCustomer.html',
  styleUrls: [
    '../../../../style/rightMenu.scss',
    '../../../../style/modal.scss',
    '../../../../style/widgets.scss',
  ],
  providers: [activeUserProvider, CustomerService],
})
export class NewCustomerVm extends BasicModalVm<DTO.Customer> {
  public dtoObject: DTO.Customer | null = null;

  constructor(
    baseVmService: BaseVmService,
    activeModal: NgbActiveModal,
    private readonly customerService: CustomerService,
  ) {
    super(baseVmService, activeModal);
    customerService.getEmptyCustomer().then((customer) => {
      this.dtoObject = customer.dtoObject;
    });
  }

  public async save() {
    if (!this.dtoObject) return;
    let clientCustomer = await this.customerService.createCustomer(
      this.dtoObject,
    );
    this.dtoObject.Id = clientCustomer.Id;
    this.close(this.dtoObject);
  }
}
