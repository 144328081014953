import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/Group';
import { Constants } from 'app/ts/Constants';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'group-selector-vm',
  styleUrls: [
    '../../../../style/product-list.scss',
    //, '../../../../style/pickable.scss'
    '../../../../style/modal.scss',
  ],
  templateUrl: './groupSelector.html',
})
export class GroupSelectorVm<T> extends BaseVm implements OnInit {
  public groups!: Client.Group<T>[];
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public readonly title!: string;
  public readonly items!: Pickable<T>[];
  public readonly pickableClass!: string;
  public displayTableOfContents!: boolean | null;

  public ngOnInit() {
    this.groups = Enumerable.from(this.items)
      .groupBy((p) => p.groupName)
      .select(
        (grp, i) => new Client.Group<T>(grp.key() || '', grp.toArray(), i),
      )
      .toArray();

    if (this.displayTableOfContents === null) {
      this.displayTableOfContents =
        this.groups.length > 2 && this.items.length > 20; //Pooma - seems reasonable
    }
  }

  public select(selection: Pickable<T>) {
    if (selection && selection.disabledReason === null)
      this.activeModal.close(selection.item);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public goto(group: Client.Group<T>) {
    let gElem = document.getElementById('group' + group.number) as HTMLElement;
    gElem.scrollIntoView();
  }
}
