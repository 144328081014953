import { User } from 'app/identity-and-access/User';
import { Subject, Observable, Subscription, BehaviorSubject } from 'rxjs';

export class ActiveUserContainer {
  private _activeUser: User | undefined;

  /** Returns undefined when no user is logged in */
  public get activeUser(): User | undefined {
    return this._activeUser;
  }

  public set activeUser(value: User | undefined) {
    this._activeUser = value;
    if (value != null) this._activeUser$.next(value);
  }

  private _activeUser$ = new BehaviorSubject<User | null>(null);
  public get activeUser$(): Observable<User | null> {
    return this._activeUser$;
  }

  public subscribeActiveUser(next: (user: User | null) => void): Subscription {
    const subscription = this.activeUser$.subscribe(next);
    return subscription;
  }
}
