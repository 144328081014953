import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import Enumerable from 'linq';
export class MaterialHelper {
  public static absoluteMaxFillingWidth(
    material: Interface_DTO.Material,
  ): number {
    return material.Rotatable
      ? Math.max(material.MaxFillingWidth, material.MaxFillingHeight)
      : material.MaxFillingWidth;
  }

  /**
   * Checks if a material supports a given size, and if it requires rotation of the material
   * @param material The material to check for filling size
   * @param doorWidth The width of the filling (well, actually the door width...)
   * @param height (Optional) The height of the filling
   *
   * NOTE: There is some DRY-violation in this code. See DoorValidationService.fillingFits
   */
  public static supportsFillingSize(
    material: Client.ProductMaterial,
    doorWidth: number,
    height?: number,
  ): { supported: boolean; mustBeRotated: boolean } {
    let defaultResult = { supported: true, mustBeRotated: false };
    if (material.designType == Interface_Enums.MaterialDesignType.Design) {
      return defaultResult;
    }
    if (
      material.designType == Interface_Enums.MaterialDesignType.DesignAndNormal
    ) {
      return defaultResult;
    }
    if (
      material.MaxFillingWidth >= doorWidth &&
      (!height || material.MaxFillingHeight >= height)
    ) {
      return defaultResult;
    }

    if (material.Rotatable && material.MaxFillingHeight >= doorWidth) {
      const fitsIfRotated = !height || material.MaxFillingWidth >= height;

      return {
        supported: fitsIfRotated,
        mustBeRotated: true,
      };
    }
    return { supported: false, mustBeRotated: false };
  }

  public static getPickable(
    mat: Client.ProductMaterial,
    disabledReason?: string,
    override?: boolean,
  ): Pickable<Client.ProductMaterial>;
  public static getPickable(
    mat: Client.Material,
    disabledReason?: string,
    override?: boolean,
  ): Pickable<Client.Material>;
  public static getPickable(
    mat: Client.Material | Client.ProductMaterial,
    disabledReason?: string,
    override?: boolean,
  ): Pickable<Client.Material | Client.ProductMaterial> {
    if (override === undefined) {
      override = (mat as Client.ProductMaterial).isOverride || false;
    }
    return {
      disabledReason: disabledReason ? disabledReason : null,
      groupName: mat.GroupName,
      id: mat.Number,
      imageUrl: mat.ImagePath ? mat.ImagePath : undefined,
      isSelected: false,
      item: mat,
      name: mat.DefaultName,
      override: override,
    };
  }

  public static getPickableForFilling(
    mat: Client.ProductMaterial,
    design?: boolean,
    disabledReason?: string,
  ): Pickable<Client.FillingMaterial> {
    return {
      disabledReason: disabledReason ? disabledReason : null,
      groupName: design ? mat.DesignGroupName : mat.GroupName,
      id: mat.Number,
      imageUrl: mat.ImagePath ? mat.ImagePath : undefined,
      isSelected: false,
      item: new Client.FillingMaterial(mat, !!design),
      name: mat.DefaultName,
      override: mat.isOverride,
    };
  }

  public static sortPickableMaterials(
    materials: Pickable<Client.Material | null>[],
  ) {
    materials.sort((a, b) => {
      if (b.item === null) return 1;
      if (a.item === null) return -1;
      return (
        this.comparer(a.item.groupSortOrder, b.item.groupSortOrder) ||
        this.comparer(a.item.SortOrder, b.item.SortOrder) ||
        this.comparer(a.item.Number, b.item.Number)
      );
    });
  }

  public static sortPickableFillingMaterials(
    fillingMaterials: Pickable<Client.FillingMaterial>[],
  ) {
    fillingMaterials.sort((a, b) => {
      return (
        this.comparer(a.item.groupSortOrder, b.item.groupSortOrder) ||
        this.comparer(a.item.material.SortOrder, b.item.material.SortOrder) ||
        this.comparer(a.item.material.Number, b.item.material.Number)
      );
    });
  }

  private static comparer(a: string | number, b: string | number): number {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  }

  public static getDefaultMaterial(
    materials: Client.ProductMaterial[] | null | undefined,
  ): Client.ProductMaterial | null;
  public static getDefaultMaterial(
    materials: Client.Material[] | null | undefined,
  ): Client.Material | null;
  public static getDefaultMaterial<T extends Client.Material>(
    materials: T[] | null | undefined,
  ): T | null {
    if (!materials) return null;
    return (
      Enumerable.from(materials)
        .where((m) => !!m)
        .orderBy((m) => m.SortOrder)
        .thenBy((m) => m.Number)
        .firstOrDefault() ?? null
    );
  }
}
