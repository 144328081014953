import { inject } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { Command } from 'app/imperative-shell';
import { Store } from 'app/ts/Interface_DTO';

export class SetActiveStore implements Command {
  constructor(private storeData: Store) {}

  public execute(): Promise<Command[]> {
    const permissions = inject(PermissionService);
    if (!permissions.canChangeStore) return Promise.resolve([]);

    const data = inject(FunctionalCoreService);
    data.ambient.storeData.setActiveStore(this.storeData);

    return Promise.resolve([]);
  }
}
