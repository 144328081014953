import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { CommandInterpreter } from 'app/imperative-shell/command-interpreter';
import { Subscription } from 'rxjs';
import { SetStoreSetting } from './SetStoreSetting.command';

export interface StoreSettingData {
  DefaultProjectDeliveryAddressId?: number;
  NumberOfDaysConfigurationIsValid?: number;
  Terms?: string;
}

type Mutable<StoreSettingData> = {
  -readonly [P in keyof StoreSettingData]: StoreSettingData[P];
};

export interface StoreSetting {
  get data(): Readonly<StoreSettingData>;
  copy(): Mutable<StoreSettingData>;
  set: (value: StoreSettingData) => void;
  subscribe(next: (value: StoreSettingData) => void): Subscription;
}

function storeSettingFactory(
  data: FunctionalCoreService,
  interpreter: CommandInterpreter,
) {
  return {
    data: data.ambient.storeData.storeSetting!,
    copy(): Mutable<StoreSettingData> {
      return { ...data.ambient.storeData.storeSetting };
    },
    set: (value: StoreSettingData) => {
      interpreter.execute(new SetStoreSetting(value));
    },
    subscribe(next: (value: StoreSettingData) => void) {
      return data.ambient.storeData.subscribeStoreSetting(next);
    },
  };
}

/** Use this together with @Inject() in constructors for Services, e.g.
 *    @Inject(StoreSettingInjector) private readonly storeSettings: storeSetting
 */
export const StoreSettingInjector = new InjectionToken<StoreSetting>(
  'storedata',
  {
    factory() {
      const data = inject(FunctionalCoreService);
      const interpreter = inject(CommandInterpreter);

      return storeSettingFactory(data, interpreter);
    },
  },
);

/** Use the provider for components, together with StoreSettingInjector, to ensure that useFactory is called on each instantiation */
export const storeSettingProvider: Provider = {
  provide: StoreSettingInjector,
  useFactory: storeSettingFactory,
  deps: [FunctionalCoreService, CommandInterpreter],
};
