import { inject } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { Command } from 'app/imperative-shell';
import { HttpPost } from 'app/imperative-shell/http-post.command';
import { UserSettingsValue } from './UserData';

export class SetUserSettingsCommand implements Command {
  constructor(private userSettings: UserSettingsValue) {}

  async execute(): Promise<Command[]> {
    const permissions = inject(PermissionService);
    if (!permissions.canChangeUserSettings) return [];

    const data = inject(FunctionalCoreService);

    const httpClient = inject(PromisingBackendService);
    await httpClient.post('api/user/userData', this.userSettings, {
      responseType: 'void',
    });

    data.ambient.userData.setUserData(this.userSettings);
    return [];
  }
}
