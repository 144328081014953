import * as Interface_DTO from 'app/ts/Interface_DTO';
import { Material } from 'app/ts/clientDto/Material';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class PickableMaterial
  implements Readonly<Pickable<Interface_DTO.Material | null>>
{
  constructor(
    private readonly func: () => Interface_DTO.Material | null,
    private readonly _override: () => boolean,
  ) {}

  disabledReason = null;
  isSelected = true;
  get item() {
    return this.func();
  }
  get name() {
    let mat = this.func();
    return mat ? mat.DefaultName : '';
  }
  get id() {
    let mat = this.func();
    return mat ? mat.Number : '?';
  }
  get imageUrl() {
    let mat = this.func();
    return mat ? mat.ImagePath || undefined : undefined;
  }
  get override() {
    return this._override();
  }
}
