import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';

/**
 * Has some issues, with not updating when the language is changed
 */
@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: string, defaultValue?: string, ...args: (string | number)[]) {
    if (defaultValue) {
      if (args.length > 0) {
        return this.translateService.translate(key, defaultValue, ...args);
      }

      return this.translateService.translate(key, defaultValue);
    } else return key;
  }
}
