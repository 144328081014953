import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  userDataProvider,
  UserSettings,
  UserSettingsInjector,
  UserSettingsValue,
} from 'app/functional-core/ambient/userdata/UserData';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { NavigationService } from 'app/ts/services/NavigationService';
import { RouteService } from 'app/ts/services/RouteService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { EditorVm } from 'app/floor-plan-editing/EditorVm';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { SectionId } from 'app/partition/section';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { EditorTypeService } from '../editor-type.service';
import { CustomerService } from 'app/ts/services/CustomerService';

@Component({
  selector: 'editor-topbar',
  templateUrl: './editorTopbar.html',
  styleUrls: [
    '../../../../style/errorInfo.scss',
    '../../../../style/smallScreenHacks.scss',
    'topbar.scss',
  ],
  providers: [userDataProvider],
})
export class EditorTopbarVm extends BaseVm implements OnInit {
  @Input()
  public selection!:
    | 'corpus'
    | 'backing'
    | 'doors'
    | 'swing'
    | 'swing-flex'
    | 'interior'
    | 'd3'
    | 'floorPlan'
    | 'piecelist'
    | 'validation'
    | 'fillings';

  /**
   * Is undefined on floorplan, since only the whole cabinet can be selected or something other than a cabinet is selected
   */
  @Input()
  public cabinetSection?: Client.CabinetSection;

  @Input()
  public cabinet!: Client.Cabinet;

  @Input()
  public floorPlan!: Client.FloorPlan;

  @Input()
  public vm!: EditorVm;

  public paths: Path[] | null = null;
  public sections: Section[] | null = null;
  public cabinets: Cabinet[] | null = null;
  public sectionSelectorOpen = false;
  public cabinetSelectorOpen = false;
  public pathSelectorOpen = false;
  public priceWithVat: string = '';
  public get floorPlanId(): string {
    return this.floorPlan?.Id?.toString() ?? '';
  }
  public showPrice = false;
  public price: string = '';
  public priceCurrency: string = '';

  public pieceList: Interface_DTO.PieceList | null = null;

  private userSettingsValue!: UserSettingsValue;

  constructor(
    baseVmService: BaseVmService,
    private readonly permissions: PermissionService,
    private readonly customerService: CustomerService,
    private readonly navigator: NavigateService,
    private readonly navigationService: NavigationService,
    @Inject(UserSettingsInjector) private userSettings: UserSettings,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly editorTypeService: EditorTypeService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    if (this.cabinetSection) {
      this.cabinet = this.cabinetSection.cabinet;
    }

    this.floorPlan = this.editorTypeService.floorPlan;

    this.subscribeTo(this.floorPlan.floorPlan$, (fp) => {
      this.setChildVms(fp);
    });

    this.ensureUnsubscribe(
      this.userSettings.subscribe((value) => {
        this.userSettingsValue = value;
        this.showPrice =
          this.userSettingsValue.DisplayTotalPriceInEditor ?? false;
      }),
    );

    this.subscribeTo(this.floorPlan.pieceList$, (pieceList) => {
      if (pieceList != null) {
        this.price = ObjectHelper.roundMoney(pieceList?.PriceWithVat);
        this.priceCurrency = pieceList?.Currency;
      }
    });
  }

  private setChildVms(fp: Client.FloorPlan) {
    // If we dont have any cabinets and no partition is selected and we have sections, selected the first section
    if (
      this.cabinetSection?.CabinetType ==
        Interface_Enums.CabinetType.SharedItems &&
      this.partitionQueryService.selectedSection == undefined &&
      this.partitionQueryService.getAllSections().length > 0
    ) {
      this.partitionCommandService.setSelection(
        this.partitionQueryService.getAllSections()[0].id,
      );
    }

    if (this.partitionQueryService.selectedSection) {
      this.paths = EditorTopbarVm.getPartitionPaths(
        this.floorPlanId,
        fp,
        this.partitionQueryService.selectedSection!.id,
        this.selection,
      );
      this.cabinets = this.getPartitionCabinets();
      let selectedpartition = this.partitionQueryService.getPartitionForSection(
        this.partitionQueryService.selectedSection.id,
      );
      let holding = this.cabinets.filter(
        (c) => c.id == selectedpartition.id.toString(),
      )[0];
      let selection = {
        Id: holding.id,
        Name: holding.name,
        Path:
          '/floorplan/' +
          this.floorPlanId +
          '/partition/' +
          holding.id.toString() +
          '/fillings',
        MaxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
        CabinetType: Interface_Enums.CabinetType.Partition,
        floorPlan: this.floorPlan,
      } as unknown as Client.Cabinet;
      let partition = this.partitionQueryService.getPartitionForSection(
        this.partitionQueryService.selectedSection.id,
      );
      let parSecs =
        this.partitionQueryService.getAllSectionsForPartiion(partition);
      let partitionSections = parSecs.map((s) => {
        let parSec = {
          Id: s.id,
          CabinetIndex: s.id as number,
          Name:
            this.translate('Section', 'Section') +
            ' ' +
            this.partitionQueryService.getPartitionSectionIndex(s.id),
          Path: 'partition/' + s.id.toString() + '/fillings',
          CabinetType: Interface_Enums.CabinetType.Partition,
        };
        return parSec;
      }) as unknown as Client.CabinetSection[];

      selection.cabinetSections = partitionSections;
      this.cabinet = selection;
      let sections = EditorTopbarVm.getSections(
        this.baseVmService.translationService,
        this.floorPlanId,
        this.cabinet,
        this.selection,
      );
      if (sections != null && sections?.length > 1) {
        this.sections = sections;
      }
    } else {
      let fpId = this.floorPlan?.oldCabinetId
        ? 'o-' + this.floorPlan.oldCabinetId
        : this.floorPlanId;
      this.paths = EditorTopbarVm.getCabinetPaths(
        fpId,
        fp,
        this.cabinetSection,
        this.selection,
      );
      this.cabinets = this.getCabinets();
      this.sections = EditorTopbarVm.getSections(
        this.baseVmService.translationService,
        fpId,
        this.cabinet,
        this.selection,
      );
    }

    let partitions = this.partitionQueryService.getAllPartitions();
    let cabinetPartitions = partitions.map((p) => {
      let parCab = {
        id: p.id.toString(),
        name: p.name,
        path:
          '/floorplan/' +
          this.floorPlanId +
          '/partition/' +
          p.id.toString() +
          '/fillings',
        maxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
      };
      return parCab;
    });

    this.cabinets = this.getCabinets();
    this.cabinets.push(...(cabinetPartitions as Cabinet[])); // This is just experimental...
    let sections = EditorTopbarVm.getSections(
      this.baseVmService.translationService,
      this.floorPlanId,
      this.cabinet,
      this.selection,
    );
    if (sections != null && sections?.length > 1) {
      this.sections = sections;
    }
  }

  public undo(): void {
    this.vm.undo();
  }

  public redo(): void {
    this.vm.redo();
  }

  public get isRedoEnabled(): boolean {
    return this.vm.isRedoEnabled;
  }

  public get isUndoEnabled(): boolean {
    return this.vm.isUndoEnabled;
  }

  public save() {
    this.vm.save();
  }

  public goToFloorPlan() {
    this.partitionCommandService.deselectSection();

    let fpId = this.floorPlan?.oldCabinetId
      ? this.floorPlan.oldCabinetId
      : parseInt(this.floorPlanId);

    this.navigator.floorPlan(
      fpId,
      {},
      this.floorPlan?.oldCabinetId != undefined,
    );
  }

  public async gotoOverview() {
    this.partitionCommandService.deselectSection();
    await this.customerService.updateCustomerList(true);
    this.navigator.floorPlans(this.navigationService.getLastSearch());
  }

  public get isFloorPlanActive(): boolean {
    return this.selection === 'floorPlan';
  }

  public get canSeeOverview(): boolean {
    return this.permissions.canSeeOrders;
  }

  public get currentSectionName(): string {
    let section = this.currentSection;

    return section == null ? '' : section.name;
  }

  public get currentSection(): Section | null {
    if (!this.cabinetSection && !this.partitionQueryService.selectedSection)
      return null;
    if (!this.sections) return null;

    if (this.partitionQueryService.selectedSection) {
      for (let vmSection of this.sections) {
        if (vmSection.index === this.partitionQueryService.selectedSection.id) {
          return vmSection;
        }
      }
    } else if (this.cabinetSection) {
      for (let vmSection of this.sections) {
        if (vmSection.index === this.cabinetSection.CabinetSectionIndex)
          return vmSection;
      }
    }
    return null;
  }

  public get floorPlanOrderStateClass() {
    return EditorTopbarVm.getFloorPlanOrderStateClass(this.floorPlan);
  }

  public static getFloorPlanOrderStateClass(
    floorPlan: Client.FloorPlan,
  ): 'not-ordered' | 'ordered-editable' | 'ordered-deadline-exceeded' {
    if (!floorPlan.correctionDeadline) {
      return 'not-ordered';
    }
    let now = new Date();
    if (
      floorPlan.correctionDeadline >= now &&
      floorPlan.DeliveryStatus !== Interface_Enums.DeliveryStatus.Cancelled &&
      floorPlan.DeliveryStatus !== Interface_Enums.DeliveryStatus.Cancel
    ) {
      return 'ordered-editable';
    } else {
      return 'ordered-deadline-exceeded';
    }
  }

  public get saveButtonClasses(): string {
    let results: string[] = [];
    if (this.vm.isDirty) {
      results.push('btn-alt-primary');
    } else {
      results.push('btn-default');
    }
    if (this.vm.isSaving) {
      results.push('btn-busy');
    }
    results.push(this.floorPlanOrderStateClass);
    return results.join(' ');
  }

  public get saveButtonText(): string {
    if (this.floorPlanOrderStateClass === 'ordered-editable') {
      return this.translate('topbar_save_reorder', 'Reorder');
    }
    return this.translate('floorPlan_save_button', 'Save');
  }

  public static getCabinetPaths(
    floorPlanId: string,
    floorPlan: Client.FloorPlan,
    cabinetSection: Client.CabinetSection | undefined,
    selection: string,
  ): Path[] | null {
    let cabinetIndex: number;
    let cabinetSectionIndex: number;
    if (!floorPlan) {
      throw new Error('No floorPlan set');
    }
    if (!cabinetSection) {
      if (floorPlan.actualCabinets.length < 1) {
        let cabinet = floorPlan.cabinets[0];
        cabinetSection = cabinet.cabinetSections[0];
      } else {
        let cabinet = floorPlan.actualCabinets[0];
        cabinetSection = cabinet.cabinetSections[0];
      }
    }

    cabinetSectionIndex = cabinetSection.CabinetSectionIndex;
    cabinetIndex = cabinetSection.CabinetIndex;

    let errorInfos = cabinetSection.errorInfos;

    let corpusPath: Path = {
      id: 'corpus',
      defaultName: 'Corpus',
      active: selection === 'corpus',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Corpus,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'corpus',
      editorSection: Enums.EditorSection.Corpus,
    };

    let doorPath: Path = {
      id: 'doors',
      defaultName: 'Doors',
      active: selection === 'doors',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Doors,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'doors',
      editorSection: Enums.EditorSection.Doors,
    };

    let swingPath: Path = {
      id: 'swing',
      defaultName: 'Swing',
      active: selection === 'swing',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Swing,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'swing',
      editorSection: Enums.EditorSection.Swing,
    };

    let swingFlexPath: Path = {
      id: 'swing-flex',
      defaultName: 'SwingFlex',
      active: selection === 'swing-flex',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.SwingFlex,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'swing-flex',
      editorSection: Enums.EditorSection.SwingFlex,
    };

    let interiorPath: Path = {
      id: 'interior',
      defaultName: 'Interior',
      active: selection === 'interior',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Interior,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'interior',
      editorSection: Enums.EditorSection.Interior,
    };
    let backingPath: Path = {
      id: 'backing',
      defaultName: 'Backing',
      active: selection === 'backing',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Backing,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'backing',
      editorSection: Enums.EditorSection.Backing,
    };
    let d3Path: Path = {
      id: 'd3',
      defaultName: '3D',
      active: selection === 'd3',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.D3,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'd3',
      editorSection: Enums.EditorSection.D3,
    };
    let pieceListPath: Path = {
      id: 'piecelist',
      defaultName: 'Piece List',
      active: selection === 'piecelist',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.PieceList,
        errorInfos,
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'piecelist',
      editorSection: Enums.EditorSection.PieceList,
    };

    let allPaths = [
      corpusPath,
      doorPath,
      swingPath,
      swingFlexPath,
      interiorPath,
      backingPath,
      d3Path,
      pieceListPath,
    ];
    let validEditors = RouteService.getValidEditors(cabinetSection);
    let validPaths = allPaths.filter(
      (p) => validEditors.indexOf(p.editorSection!) >= 0,
    );

    if (cabinetSection.doors.availableProfiles.length <= 0) {
      validPaths = validPaths.filter(
        (p) => p.editorSection !== Enums.EditorSection.Doors,
      );
    }

    let validationPath: Path = {
      id: 'validation',
      defaultName: 'Validation',
      active: selection === 'validation',
      errorLevel: cabinetSection.cabinet.floorPlan.maxErrorLevel,
      path:
        '/floorplan/' +
        floorPlanId +
        '/' +
        cabinetIndex +
        '/' +
        cabinetSectionIndex +
        '/' +
        'validation',
      editorSection: null,
    };

    if (validationPath.errorLevel > Interface_Enums.ErrorLevel.None) {
      validPaths.push(validationPath);
    }
    return validPaths;
  }

  public static getPartitionPaths(
    floorPlanId: string,
    floorPlan: Client.FloorPlan,
    sectionId: SectionId,
    selection: string,
  ): Path[] | null {
    if (!floorPlan) {
      throw new Error('No floorPlan set');
    }

    let fillingsPath: Path = {
      id: 'fillings',
      defaultName: 'Fillings',
      active: selection === 'fillings',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.Fillings,
        [],
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/partition' +
        '/' +
        sectionId +
        '/' +
        'fillings',
      editorSection: Enums.EditorSection.Fillings,
    };
    let d3Path: Path = {
      id: 'd3',
      defaultName: '3D',
      active: selection === 'd3',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.D3,
        [],
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/partition' +
        '/' +
        sectionId +
        '/' +
        'd3',
      editorSection: Enums.EditorSection.D3,
    };
    let pieceListPath: Path = {
      id: 'piecelist',
      defaultName: 'Piece List',
      active: selection === 'piecelist',
      errorLevel: EditorTopbarVm.getHighestErrorLevel(
        Enums.EditorSection.PieceList,
        [],
      ),
      path:
        '/floorplan/' +
        floorPlanId +
        '/partition' +
        '/' +
        sectionId +
        '/' +
        'piecelist',
      editorSection: Enums.EditorSection.PieceList,
    };

    let allPaths = [fillingsPath, d3Path, pieceListPath];

    let validationPath: Path = {
      id: 'validation',
      defaultName: 'Validation',
      active: selection === 'validation',
      errorLevel: floorPlan.maxErrorLevel,
      path:
        '/floorplan/' +
        floorPlanId +
        '/partition' +
        '/' +
        sectionId +
        '/' +
        'validation',
      editorSection: null,
    };

    if (validationPath.errorLevel > Interface_Enums.ErrorLevel.None) {
      allPaths.push(validationPath);
    }
    return allPaths;
  }

  public static getHighestErrorLevel(
    editorSection: Enums.EditorSection,
    errorInfos: Client.ErrorInfo[],
  ) {
    return Math.max(
      Interface_Enums.ErrorLevel.None,
      ...errorInfos
        .filter((ei) => ei.editorSection === editorSection)
        .map((ei) => ei.level),
    );
  }

  public static getSections(
    translateService: TranslationService,
    floorPlanId: string,
    cabinet?: Client.Cabinet,
    selection?: string,
  ): Section[] | null {
    if (!cabinet) return null;
    switch (cabinet.CabinetType) {
      case Interface_Enums.CabinetType.SingleCabinet:
      case Interface_Enums.CabinetType.Doors:
      case Interface_Enums.CabinetType.SharedItems:
      case Interface_Enums.CabinetType.Swing:
        return null; //There is only one section - no need to show it

      case Interface_Enums.CabinetType.SwingFlex:
        return null; // For now, there is only one section

      case Interface_Enums.CabinetType.CornerCabinet:
        return [
          {
            id: '1',
            index: 1,
            imageUrl: 'assets/images/cabinetSections/corner-1.png',
            maxErrorLevel: cabinet.cabinetSections[0].maxErrorLevel,
            name: translateService.translate(
              'section_selector_corner_section_1',
              'Left Section',
            ),
            path:
              '/floorplan/' +
              floorPlanId +
              '/' +
              cabinet.CabinetIndex +
              '/1/' +
              (selection || 'corpus'),
          },
          {
            id: '2',
            index: 2,
            imageUrl: 'assets/images/cabinetSections/corner-2.png',
            maxErrorLevel: cabinet.cabinetSections[1].maxErrorLevel,
            name: translateService.translate(
              'section_selector_corner_section_2',
              'Right Section',
            ),
            path:
              '/floorplan/' +
              floorPlanId +
              '/' +
              cabinet.CabinetIndex +
              '/2/' +
              (selection || 'corpus'),
          },
        ];
      case Interface_Enums.CabinetType.WalkIn: {
        return [
          {
            id: '1',
            index: 1,
            imageUrl: 'assets/images/cabinetSections/walkin-1.png',
            maxErrorLevel: cabinet.cabinetSections[0].maxErrorLevel,
            name: translateService.translate(
              'section_selector_walkin_section_1',
              'Left section',
            ),
            path:
              '/floorplan/' +
              floorPlanId +
              '/' +
              cabinet.CabinetIndex +
              '/1/' +
              (selection || 'corpus'),
          },
          {
            id: '2',
            index: 2,
            imageUrl: 'assets/images/cabinetSections/walkin-2.png',
            maxErrorLevel: cabinet.cabinetSections[1].maxErrorLevel,
            name: translateService.translate(
              'section_selector_walkin_section_2',
              'Middle section',
            ),
            path:
              '/floorplan/' +
              floorPlanId +
              '/' +
              cabinet.CabinetIndex +
              '/2/' +
              (selection || 'corpus'),
          },
          {
            id: '3',
            index: 3,
            imageUrl: 'assets/images/cabinetSections/walkin-3.png',
            maxErrorLevel: cabinet.cabinetSections[2].maxErrorLevel,
            name: translateService.translate(
              'section_selector_walkin_section_3',
              'Right section',
            ),
            path:
              '/floorplan/' +
              floorPlanId +
              '/' +
              cabinet.CabinetIndex +
              '/3/' +
              (selection || 'corpus'),
          },
        ];
      }
      case Interface_Enums.CabinetType.Partition: {
        let partitionCabinetSections: Section[] = [];
        for (let i = 0; i < cabinet.cabinetSections.length; i++) {
          let cab = cabinet.cabinetSections[i];
          let newParCab = {
            id: cab.Id.toString(),
            index: cab.Id,
            imageUrl: '',
            maxErrorLevel: Interface_Enums.ErrorLevel.None,
            name: cab.Name,
            path: `/floorplan/${floorPlanId}/partition/${cab.Id}/${selection ? selection : 'fillings'}`,
          };
          partitionCabinetSections.push(newParCab);
          partitionCabinetSections.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
        return partitionCabinetSections;
      }
      default:
        throw new Error(
          'Cannot create a section selector dropdown for this type of cabinet',
        );
    }
  }

  private getCabinets(): Cabinet[] {
    let selection: string;
    if (this.cabinetSection) {
      selection = this.selection;
    } else {
      selection = 'corpus';
    }

    let fpId = this.floorPlan?.oldCabinetId
      ? 'o-' + this.floorPlan.oldCabinetId
      : this.floorPlanId;
    return this.floorPlan.actualCabinets.map((clientCabinet) => {
      let cabinetSection = clientCabinet.getSectionByIndex(1);

      // Get the cabinet valid paths and check if selection exists in available paths. If not found, take first valid path as default
      let cabinetPaths = EditorTopbarVm.getCabinetPaths(
        fpId,
        this.floorPlan,
        cabinetSection,
        'corpus',
      );
      if (cabinetPaths?.findIndex((c) => c.id === selection) === -1) {
        selection = cabinetPaths[0].id;
      }

      return {
        id: clientCabinet.CabinetIndex.toString(),
        maxErrorLevel: clientCabinet.maxErrorLevel,
        name: clientCabinet.Name,
        path:
          '/floorplan/' +
          fpId +
          '/' +
          clientCabinet.CabinetIndex +
          '/1/' +
          selection,
      };
    });
  }

  private getPartitionCabinets(): Cabinet[] {
    let partitions = this.partitionQueryService.getAllPartitions();
    let cabinetPartitions = partitions.map((p) => {
      let parCab = {
        id: p.id.toString(),
        name: p.name,
        path:
          '/floorplan/' +
          this.floorPlanId +
          '/partition/' +
          p.id.toString() +
          '/fillings',
        maxErrorLevel: Interface_Enums.ErrorLevel.None, // Needs to be actual maxErrorLevel
        CabinetType: Interface_Enums.CabinetType.Partition,
      };
      return parCab;
    });
    return cabinetPartitions as unknown as Cabinet[];
  }

  public get showMainDropdown(): boolean {
    return this.cabinets != null && this.cabinets.length > 0;
  }
}

export interface Cabinet {
  id: string;
  name: string;
  path: string;
  maxErrorLevel: Interface_Enums.ErrorLevel;
}

export interface Section {
  id: string;
  name: string;
  imageUrl: string;
  path: string;
  index: number;
  maxErrorLevel: Interface_Enums.ErrorLevel;
}

export interface Path {
  id: string;
  defaultName: string;
  active: boolean;
  path: string;
  errorLevel: Interface_Enums.ErrorLevel;
  editorSection: Enums.EditorSection | null;
}
