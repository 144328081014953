import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Client from 'app/ts/clientDto/index';

@Injectable()
export class SelectedItemsService {
  private _selectedItems$ = new BehaviorSubject<Client.ConfigurationItem[]>([]);

  public get selectedItems$(): Observable<Client.ConfigurationItem[]> {
    return this._selectedItems$;
  }

  public get value(): Client.ConfigurationItem[] {
    return this._selectedItems$.value;
  }

  public set value(items: Client.ConfigurationItem[]) {
    //When switching between items in the left menu
    if (
      this.isSingleProductSelected(this.value) &&
      this.isSingleProductSelected(items)
    ) {
      this._selectedItems$.next(items);
      return;
    }

    const currentSelection = this.value
      .map((item) => item.dtoItem.ConfigurationItemIndex)
      .sort()
      .join(',');
    const newSelection = items
      .map((item) => item.dtoItem.ConfigurationItemIndex)
      .sort()
      .join(',');
    if (newSelection != currentSelection) {
      this._selectedItems$.next(items);

      let query = '';

      let url = new URL(location.href);
      if (items.length > 0) {
        query = '?selected=' + items[0].ProductId;
      }

      history.pushState(null, '', url.pathname + query);
    }
  }

  /**
   * Checks if a single item from the left menu is selected.
   * @param items
   */
  private isSingleProductSelected(items: Client.ConfigurationItem[]): boolean {
    return items.length == 1 && items[0].PositionNumber == -1;
  }
}
