import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  ActiveUserInjector,
  ActiveUser,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import { Permission } from './Permission';
import { User } from './User';

@Injectable()
export class PermissionService {
  private user: User;
  constructor(@Inject(ActiveUserInjector) activeUser: ActiveUser) {
    this.user = activeUser.data;

    activeUser.subscribe((user) => {
      if (user != null) this.user = user;
    });
  }

  public get canChangeStore(): boolean {
    return this.hasPermission(Permission.ChangeStore);
  }

  public get canChangeStoreSettings(): boolean {
    return this.hasPermission(Permission.ChangeStoreSettings);
  }

  public get canChangeUserSettings(): boolean {
    return this.hasPermission(Permission.ChangeUserSettings);
  }

  public get canEditFloorplanOwner(): boolean {
    return this.hasPermission(Permission.EditFloorPlanOwner);
  }

  public get canEditLogisticsComment(): boolean {
    return this.hasPermission(Permission.EditLogisticsComment);
  }

  public get canEditOrderComment(): boolean {
    return this.hasPermission(Permission.EditOrderComment);
  }

  public get canEditSendReminderEmails(): boolean {
    return this.hasPermission(Permission.EditSendReminderEmails);
  }

  public get canEditUseManualOrderHandling(): boolean {
    return this.hasPermission(Permission.EditUseManualOrderHandling);
  }

  public get canOverwriteFavourites(): boolean {
    return this.hasPermission(Permission.OverwriteFavourites);
  }

  public get canResendScreeenshots(): boolean {
    return this.hasPermission(Permission.ResendScreenshots);
  }

  public get canSaveFavouriteInGroup(): boolean {
    return this.hasPermission(Permission.SaveFavouriteInGroup);
  }

  public get canSeeAllStores(): boolean {
    return this.hasPermission(Permission.SeeAllStores);
  }

  public get canSeeChainStores(): boolean {
    return this.hasPermission(Permission.SeeChainStores);
  }

  public get canSeeOrders(): boolean {
    return this.hasPermission(Permission.SeeOrders);
  }

  public get canSeeSalesChainStores(): boolean {
    return this.hasPermission(Permission.SeeSalesChainStores);
  }

  public get canSetManualDeliveryStatus(): boolean {
    return this.hasPermission(Permission.SetManualDeliveryStatus);
  }

  public get canSetSupporter(): boolean {
    return this.hasPermission(Permission.SetSupporter);
  }

  public get canShowOnlyAdminVariants(): boolean {
    return this.hasPermission(Permission.ShowOnlyAdminVariants);
  }

  public get canUseCustomPriceDate(): boolean {
    return this.hasPermission(Permission.UseCustomPriceDate);
  }

  public get canUseFullCatalog(): boolean {
    return this.hasPermission(Permission.UseFullCatalog);
  }

  public get canUseOrderConfirmer(): boolean {
    return this.hasPermission(Permission.UseOrderConfirmer);
  }

  private hasPermission(permission: Permission): boolean {
    return this.user?.Permissions.indexOf(permission) >= 0;
  }
}
