import { PartitionPlanData } from '../partition-plan-data.service';
import PartitionArrayMigration from './partition-array-migration';

export namespace JsonMigrator {
  let migrations: Migration[] = [new PartitionArrayMigration()];

  /**
   * Is responsible for capturing changes of the json structure, to preserve compatibility with old floorplans
   * @param plan
   */
  export function checkAndDoMigrations(plan: PartitionPlanData) {
    migrations.forEach((mig) => {
      if (mig.needsMigration(plan)) {
        mig.migrate(plan);
      }
    });
  }
}

export interface Migration {
  needsMigration: (plan: PartitionPlanData) => boolean;
  migrate: (plan: PartitionPlanData) => void;
}
