<div class="product-list-recursive">
  <div class="col product-category-col">
    <select class="form-select" [(ngModel)]="selectedProductLine">
      <option *ngFor="let pl of productLines" [ngValue]="pl">
        {{pl.Name}}
      </option>
    </select>
    <div class="input-group">
      <input
        #productSearch
        type="search"
        class="form-control"
        id="plr_product_search"
        [attr.placeholder]="translate('add_product_search_placeholder','Search')"
        (keydown.enter)=" searchEnter()"
        [(ngModel)]="searchString"
      />
      <span
        class="bi bi-search input-group-text"
        (click)="searchString = ''"
        onclick="document.getElementById('plr_product_search').focus()"
      ></span>
    </div>

    <div class="product-categories btn-group btn-group-vertical">
      <button
        class="btn btn-default product-category"
        *ngFor="let cat of filteredProductCategories; trackBy: trackByCategoryId"
        (click)="selectedProductCategory = cat"
        [textContent]="cat.name"
        [attr.level]="cat.level"
        [ngStyle]="{'padding-left': (cat.level*10+10)+'px'}"
        [ngClass]="{'active': cat.id === selectedProductCategoryId}"
      ></button>
    </div>
  </div>
  <div class="col product-col">
    <div class="products" *ngIf="selectedProductCategory">
      <ng-container
        *ngFor="let product of visibleProducts; trackBy: trackByIndex"
      >
        <pickable
          class="product clickable"
          (click)="selectProduct(product.item)"
          [pickable]="product"
        ></pickable>
      </ng-container>
      <button
        class="btn btn-default btn-sm more-products"
        (click)="showMoreProducts()"
        *ngIf="moreProductsAvailable"
        [textContent]="translate('add_product_showmore_products',' Show More Products...')"
      ></button>
    </div>
  </div>
</div>
