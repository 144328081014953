import * as Interface_DTO from 'app/ts/Interface_DTO';
import { NotificationService } from 'app/ts/services/NotificationService';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AddressService {
  private _countryPromises!: Promise<Interface_DTO.Country[]>;
  private countries: Interface_DTO.Country[] = [];
  private matchCache: { [key: string]: Interface_DTO.City[] } = {};

  constructor(
    private readonly $http: PromisingBackendService,
    private readonly notificationService: NotificationService,
  ) {}

  public async loadCountries(): Promise<Interface_DTO.Country[]> {
    try {
      this._countryPromises =
        this.$http.get<Interface_DTO.Country[]>('api/country');
      this.countries = await this._countryPromises;
    } catch (e: any) {
      //reload once more
      try {
        window.setTimeout(async () => {
          this._countryPromises =
            this.$http.get<Interface_DTO.Country[]>('api/country');
          this.countries = await this._countryPromises;
        }, 5000);
      } catch (e2) {
        this.notificationService.exception(
          'addressService_error_loading_country_list',
          e2,
          'Could not load list of countries',
        );
      }
    }
    return this._countryPromises;
  }

  public get countryPromises() {
    return this._countryPromises;
  }

  public async getCitiesAsync(
    countryCode: string,
    zipPrefixWithoutCountry: string | undefined,
    exact: boolean,
  ): Promise<Interface_DTO.City[]> {
    if (!zipPrefixWithoutCountry) return [];
    zipPrefixWithoutCountry = zipPrefixWithoutCountry.toLowerCase();

    let country: Interface_DTO.Country | undefined = undefined;
    for (let c of this.countries) {
      if (c.Code.toLocaleLowerCase() === countryCode.toLowerCase()) {
        country = c;
        break;
      }
    }
    if (!country) {
      return [];
    }
    let key = countryCode + '/' + zipPrefixWithoutCountry;
    let result: Interface_DTO.City[] = this.matchCache[key];

    if (!result) {
      //get and cache if results are not cached
      //this._countryPromises = this.$http.get<Interface_DTO.Country[]>("api/country");
      //this.countries = await this._countryPromises
      //this.matchCache[key] = result;

      result = await this.$http.get<Interface_DTO.City[]>('api/country/' + key);
      this.matchCache[key] = result;
    }

    if (exact) {
      result = result.filter(
        (city) =>
          city.ZipCodeNoPrefix.toLowerCase() === zipPrefixWithoutCountry,
      );
    }

    return result;
  }

  public getPhonePrefix(countryCode: string): string | undefined {
    for (let c of this.countries) {
      if (c.Code === countryCode) return c.PhonePrefix || undefined;
    }
    return undefined;
  }

  public async getAutocomplete(
    request: Interface_DTO.AddressAutocompleteRequest,
  ): Promise<Interface_DTO.AddressAutocompleteResponse | undefined> {
    return this.$http.post<Interface_DTO.AddressAutocompleteResponse>(
      'api/address/autocomplete',
      request,
    );
  }
}
