<div class="editor-all editor-doors" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'fillings'"
    [floorPlan]="editorType.floorPlan"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div class="ribbon-menu">
    <!-- Show Rulers -->
    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{'editor_label_show_rulers' | translate: 'Rulers'}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showTopdown" />
        {{translate('editor_label_show_topdown', 'Topdown')}}
      </label>
    </div>

    <div class="ribbon-block ribbon-block-vertical">
      <div class="input-group input-group-sm input-group-long">
        <span
          class="input-group-text long-addon"
          [textContent]="'doors_label_bar_height' | translate: 'Bar height'"
        ></span>
        <select
          #barHeightSelect
          id="lm-door-barHeight"
          class="form-select"
          [value]="section.barHeight"
          (change)="updateSectionBarHeight(barHeightSelect.selectedOptions[0].value)"
        >
          <option
            *ngFor="let bar of availableBars"
            [ngValue]="bar.height"
            [disabled]="!!barHeightDisabledReason(bar)"
            [attr.title]="translate(barHeightDisabledReason(bar))"
          >
            {{bar.height}} mm
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="tool-menu left-tool-menu">
    <div class="panel door-profile">
      <h4
        [textContent]="translate('fillings_header_door_railset','Door Rails')"
      ></h4>
      <pickable
        [pickable]="railSet"
        (click)="selectPartitionDoorProfile()"
      ></pickable>
    </div>

    <div class="panel door-profile">
      <h4
        [textContent]="translate('door_rail_material_selector_header', 'Rail colour')"
      ></h4>
      <pickable
        class="modal-opener material filling-material"
        (click)="selectProfileMaterial()"
        [pickable]="selectedProfileMaterial"
      ></pickable>
    </div>
  </div>

  <fillings-image
    class="editor-center"
    [floorPlan]="floorPlan"
    [showRulers]="showRulers"
    [showTopdown]="showTopdown"
    (selectedItemChanged)="selection = $event"
    [modules]="modules"
    [section]="section"
  />

  <div class="tool-menu right-tool-menu">
    <!-- If something is selected -->
    <filling-property-sheet
      *ngIf="isModuleSelected() &amp;&amp; isFillingSelected()"
      [selectedSection]="section"
      [selectedFilling]="selection.filling!"
      [selectedModule]="selectedModule"
      [floorPlan]="editorType.floorPlan"
    />

    <bar-property-sheet
      *ngIf="isModuleSelected() &amp;&amp; isBarSelected()"
      [selectedBar]="selection.bar!"
      [selectedModule]="selectedModule"
      [floorPlan]="editorType.floorPlan"
      [section]="section"
    />
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
