import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { Backing as Client_Backing } from 'app/ts/clientDto/SubSectionBacking';
import { Interior as Client_Interior } from 'app/ts/clientDto/SubSectionInterior';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { CabinetSectionValidationService } from 'app/ts/services/Validation/CabinetSectionValidationService';
import { BehaviorSubject, Observable } from 'rxjs';
import { CabinetSectionProperties } from '../properties/CabinetSectionProperties';

export class CabinetSection {
  private _cache: {
    errorInfos?: Client.ErrorInfo[];
    maxErrorLevel?: Interface_Enums.ErrorLevel;
  } = {};

  public get ruler$(): Observable<CabinetSection> {
    return this._ruler$;
  }
  private _ruler$: BehaviorSubject<CabinetSection>;
  private _properties?: CabinetSectionProperties;

  constructor(
    private dto: Interface_DTO.CabinetSection,
    public readonly cabinet: Client.Cabinet,
  ) {
    this.corpus = new Client.Corpus(this, dto);
    this.doors = new Client.Doors(this);
    this.swing = new Client.Swing(this, dto);
    this.swingFlex = new Client.SwingFlex(this);
    this.interior = new Client_Interior(this);
    this.backing = new Client_Backing(this, dto);

    dto.AddedByServiceItems = dto.AddedByServiceItems.filter(
      (i) => i.ItemType !== Interface_Enums.ItemType.AddedByCondition,
    );

    this._ruler$ = new BehaviorSubject<CabinetSection>(this);
  }

  triggerRulerUpdate() {
    this._ruler$.next(this._ruler$.value);
  }

  public saveTo(): Interface_DTO.CabinetSection {
    this.dto.CabinetIndex = this.CabinetIndex;
    for (let subSection of [
      this.corpus,
      this.doors,
      this.interior,
      this.backing,
      this.swing,
      this.swingFlex,
    ]) {
      subSection.saveTo(this.dto, this.properties);
    }
    this.dto.ManuallyAddedItems = this.ManuallyAddedItems.map((mai) =>
      mai.save(),
    );
    this.dto.CustomItems = this.CustomItems.map((ci) => ci.save());
    this.dto.PropertiesJson = this._properties
      ? JSON.stringify(this._properties)
      : null;
    return this.dto;
  }
  private _isDirty = false;
  public get isDirty() {
    return this._isDirty;
  }
  public set isDirty(b: boolean) {
    this._isDirty = b;
    if (b) {
      this.cabinet.isDirty = true;
      this.clearBackingVariables();
    }
  }

  public clearBackingVariables() {
    this._cache = {};

    this.corpus.clearBackingVariables();

    this.doors.clearBackingVariables();

    this.interior.clearBackingVariables();

    this.backing.clearBackingVariables();

    this.swing.clearBackingVariables();

    this.swingFlex.clearBackingVariables();
  }

  public loadFromDto() {
    this.loadFrom(this.dto);
  }

  public loadFrom(dtoSection: Interface_DTO.CabinetSection) {
    this.clearBackingVariables();
    this._properties = undefined;
    this.dto = dtoSection;

    if (
      this.dto.CabinetType != Interface_Enums.CabinetType.Partition &&
      this.dto.CabinetType != Interface_Enums.CabinetType.PartitionTemp
    ) {
      let corpusMaterial = this.editorAssets.materials.filter(
        (mat) =>
          mat.Number === this.dto.CorpusMaterialNumber &&
          mat.Type === Interface_Enums.MaterialType.Normal,
      )[0];
      if (corpusMaterial) {
        this.CorpusMaterialId = corpusMaterial.Id;
      } else {
        this.CorpusMaterialId = null;
      }

      let interiorMaterial = this.editorAssets.materials.filter(
        (mat) =>
          mat.Number === this.dto.InteriorMaterialNumber &&
          mat.Type === Interface_Enums.MaterialType.Normal,
      )[0];
      if (interiorMaterial) {
        this.InteriorMaterialId = interiorMaterial.Id;
      } else {
        this.InteriorMaterialId = null;
      }

      for (let s of [
        this.corpus,
        this.doors,
        this.interior,
        this.swing,
        this.swingFlex,
      ]) {
        s.loadFrom(dtoSection, this.properties);
      }

      this.interior.items = dtoSection.InteriorItems.map((dtoItem) =>
        this.restoreInteriorItem(dtoItem, this.interior.items),
      );

      this.backing.loadFrom(dtoSection);
      this.backing.items = dtoSection.BackingItems.map((dtoItem) =>
        this.restoreItem(dtoItem, this.backing.items),
      );
    }

    this.AddedByServiceItems = dtoSection.AddedByServiceItems.map((dtoItem) =>
      this.restoreItem(dtoItem, this.AddedByServiceItems),
    );
    this.CustomItems = dtoSection.CustomItems.map((dtoItem) =>
      this.restoreItem(dtoItem, this.CustomItems),
    );
    this.ManuallyAddedItems = dtoSection.ManuallyAddedItems.map((dtoItem) =>
      this.restoreItem(dtoItem, this.ManuallyAddedItems),
    );
    //this.ModuleItems = dtoSection.ModuleItems.map(dtoItem => this.restoreItem(dtoItem, this.ModuleItems));
    this.clearBackingVariables();
  }

  private restoreItem(
    dtoItem: Interface_DTO.ConfigurationItem,
    existingItems: Client.ConfigurationItem[],
  ): Client.ConfigurationItem {
    let oldItem: Client.ConfigurationItem | null = null;
    for (let item of this.configurationItems) {
      if (item.ConfigurationItemIndex === dtoItem.ConfigurationItemIndex) {
        oldItem = item;
        break;
      }
    }
    if (!oldItem) {
      oldItem = new Client.ConfigurationItem(dtoItem, this);
    }
    oldItem.restoreFrom(dtoItem);
    return oldItem;
  }

  private restoreInteriorItem(
    dtoItem: Interface_DTO.ConfigurationItem,
    existingItems: Client.ConfigurationItem[],
  ): Client.ConfigurationItem {
    let oldItem: Client.ConfigurationItem | null = null;
    for (let item of existingItems) {
      if (item.ConfigurationItemIndex === dtoItem.ConfigurationItemIndex) {
        oldItem = new Client.ConfigurationItem(item.dtoItem, this);
        break;
      }
    }
    if (!oldItem) {
      oldItem = new Client.ConfigurationItem(dtoItem, this);
    }
    oldItem.restoreFrom(dtoItem);
    return oldItem;
  }

  //#region PropertiesJson

  public get properties(): CabinetSectionProperties {
    if (!this._properties) {
      this._properties = CabinetSectionProperties.fromJson(
        this.dto.PropertiesJson,
      );
    }
    return this._properties;
  }

  //#endregion PropertiesJson

  //#region DTO mappings

  get Id() {
    return this.dto.Id;
  }
  set Id(val: number) {
    if (val === this.dto.Id) return;
    this.dto.Id = val;
    this.isDirty = true;
  }

  get BottomHeight() {
    return this.dto.BottomHeight;
  }
  set BottomHeight(val: number) {
    if (val === this.dto.BottomHeight) return;
    this.dto.BottomHeight = val;
    this.isDirty = true;
  }

  get CabinetSectionIndex() {
    return this.dto.CabinetSectionIndex;
  }
  set CabinetSectionIndex(val: number) {
    if (val === this.dto.CabinetSectionIndex) return;
    this.dto.CabinetSectionIndex = val;
    this.isDirty = true;
  }

  get CabinetIndex() {
    return this.cabinet.CabinetIndex;
  }

  get CabinetType() {
    return this.dto.CabinetType;
  }
  set CabinetType(val: Interface_Enums.CabinetType) {
    if (val === this.dto.CabinetType) return;
    this.dto.CabinetType = val;
    this.isDirty = true;
  }

  get CorpusMaterialId() {
    return this.dto.CorpusMaterialId;
  }
  set CorpusMaterialId(val: number | null) {
    if (val === this.dto.CorpusMaterialId) return;
    this.dto.CorpusMaterialId = val;
    this.isDirty = true;
  }

  get CorpusMaterialNumber() {
    return this.dto.CorpusMaterialNumber;
  }
  set CorpusMaterialNumber(val: string) {
    if (val === this.dto.CorpusMaterialNumber) return;
    this.dto.CorpusMaterialNumber = val;
    this.isDirty = true;
  }

  get Depth() {
    return this.dto.Depth;
  }
  set Depth(val: number) {
    if (val === this.dto.Depth) return;
    this.dto.Depth = ObjectHelper.clamp(this.minDepth, val, this.maxDepth);
    this.isDirty = true;
  }

  get Description() {
    return this.dto.Description;
  }
  set Description(val: string) {
    if (val === this.dto.Description) return;
    this.dto.Description = val;
    this.isDirty = true;
  }

  get ExtraRailWidthLeft() {
    return this.dto.ExtraRailWidthLeft;
  }
  set ExtraRailWidthLeft(val: number) {
    if (isNaN(val)) val = 0;

    if (val === this.dto.ExtraRailWidthLeft) return;

    let newValue = Math.max(val, this.doors.extraRailWidthLeftMin);
    newValue = Math.min(newValue, this.doors.extraRailWidthLeftMax);

    if (newValue === this.dto.ExtraRailWidthLeft) return;

    this.dto.ExtraRailWidthLeft = newValue;
    this.isDirty = true;
  }

  get ExtraRailWidthRight() {
    return this.dto.ExtraRailWidthRight;
  }
  set ExtraRailWidthRight(val: number) {
    if (isNaN(val)) val = 0;

    if (val === this.dto.ExtraRailWidthRight) return;

    let newValue = Math.max(val, this.doors.extraRailWidthRightMin);
    newValue = Math.min(newValue, this.doors.extraRailWidthRightMax);

    if (newValue === this.dto.ExtraRailWidthRight) return;

    this.dto.ExtraRailWidthRight = newValue;
    this.isDirty = true;
  }

  get Height() {
    return this.dto.Height;
  }
  set Height(val: number) {
    if (val === this.dto.Height) return;

    this.cabinet.setHeight(this, val);
  }
  public setHeight(newHeight: number) {
    let minHeight = this.isSwing
      ? Constants.swingMinCabinetHeight + Constants.swingBottomOffset
      : Constants.minCabinetHeight;
    let maxHeight: number;
    if (this.isSwing) {
      maxHeight = Constants.swingMaxCabinetHeight + Constants.swingBottomOffset;
    } else {
      if (this.cabinet.floorPlan.FullCatalogTallCabinets) {
        maxHeight = Constants.maxTallCabinetHeight;
      } else {
        maxHeight = Constants.maxCabinetHeight;
      }
    }
    this.dto.Height = Math.max(minHeight, Math.min(maxHeight, newHeight));
    this.dto.InteriorHeight = this.dto.Height;
    this.isDirty = true;
  }

  get HeightReductionBacking() {
    return this.dto.HeightReductionBacking;
  }
  set HeightReductionBacking(val: boolean) {
    if (val === this.dto.HeightReductionBacking) return;
    this.dto.HeightReductionBacking = val;
    this.isDirty = true;
  }

  get HeightReductionCorpusLeft() {
    return this.dto.HeightReductionCorpusLeft;
  }
  set HeightReductionCorpusLeft(val: boolean) {
    if (val === this.dto.HeightReductionCorpusLeft) return;
    this.dto.HeightReductionCorpusLeft = val;
    this.isDirty = true;
  }

  get HeightReductionCorpusRight() {
    return this.dto.HeightReductionCorpusRight;
  }
  set HeightReductionCorpusRight(val: boolean) {
    if (val === this.dto.HeightReductionCorpusRight) return;
    this.dto.HeightReductionCorpusRight = val;
    this.isDirty = true;
  }

  get InteriorFreeSpaceLeft() {
    return this.dto.InteriorFreeSpaceLeft;
  }
  set InteriorFreeSpaceLeft(val: number) {
    if (val === this.dto.InteriorFreeSpaceLeft) return;
    this.dto.InteriorFreeSpaceLeft = val;
    this.isDirty = true;
  }

  get InteriorFreeSpaceRight() {
    return this.dto.InteriorFreeSpaceRight;
  }
  set InteriorFreeSpaceRight(val: number) {
    if (val === this.dto.InteriorFreeSpaceRight) return;
    this.dto.InteriorFreeSpaceRight = val;
    this.isDirty = true;
  }

  get InteriorHeight() {
    return this.dto.InteriorHeight;
  }
  set InteriorHeight(val: number) {
    if (val === this.dto.InteriorHeight) return;
    this.dto.InteriorHeight = val;
    this.isDirty = true;
  }

  get InteriorMaterialId() {
    return this.dto.InteriorMaterialId;
  }
  set InteriorMaterialId(val: number | null) {
    if (val === this.dto.InteriorMaterialId) return;
    this.dto.InteriorMaterialId = val;
    this.isDirty = true;
  }

  get InteriorMaterialNumber() {
    return this.dto.InteriorMaterialNumber;
  }
  set InteriorMaterialNumber(val: string) {
    if (val === this.dto.InteriorMaterialNumber) return;
    this.dto.InteriorMaterialNumber = val;
    this.isDirty = true;
  }

  get InteriorWidth() {
    return this.dto.InteriorWidth;
  }
  set InteriorWidth(val: number) {
    if (val === this.dto.InteriorWidth) return;
    this.dto.InteriorWidth = val;
    this.isDirty = true;
  }

  get LeftWidth() {
    return this.dto.LeftWidth;
  }
  set LeftWidth(val: number) {
    if (val === this.dto.LeftWidth) return;
    this.dto.LeftWidth = val;
    this.isDirty = true;
  }

  get ManualFishJointPositioning() {
    return this.dto.ManualFishJointPositioning;
  }
  set ManualFishJointPositioning(val: boolean) {
    if (val === this.dto.ManualFishJointPositioning) return;
    this.dto.ManualFishJointPositioning = val;
    this.isDirty = true;
  }

  get Name() {
    return this.dto.Name;
  }
  set Name(val: string) {
    if (val === this.dto.Name) return;
    this.dto.Name = val;
    this.isDirty = true;
  }

  get NumberOfDoors() {
    return this.dto.NumberOfDoors;
  }
  set NumberOfDoors(val: number) {
    if (val === this.dto.NumberOfDoors) return;
    this.dto.NumberOfDoors = val;
    this.isDirty = true;
  }

  get NumberOfOverlaps() {
    return this.dto.NumberOfOverlaps;
  }
  set NumberOfOverlaps(val: number) {
    if (val === this.dto.NumberOfOverlaps) return;
    this.dto.NumberOfOverlaps = val;
    this.isDirty = true;
  }

  get OverlapWidth() {
    return this.dto.OverlapWidth;
  }
  set OverlapWidth(val: number) {
    if (isNaN(val)) val = 0;

    if (val === this.dto.OverlapWidth) return;

    let newValue = Math.max(val, this.doors.overlapWidthMin);
    newValue = Math.min(newValue, this.doors.overlapWidthMax);

    if (newValue === this.dto.OverlapWidth) return;

    this.dto.OverlapWidth = newValue;
    this.isDirty = true;
  }

  get PositionX() {
    return this.dto.PositionX;
  }
  set PositionX(val: number) {
    if (val === this.dto.PositionX) return;
    this.dto.PositionX = val;
    this.isDirty = true;
  }

  get PositionY() {
    return this.dto.PositionY;
  }
  set PositionY(val: number) {
    if (val === this.dto.PositionY) return;
    this.dto.PositionY = val;
    this.isDirty = true;
  }

  get PreviousConfigurationId() {
    return this.dto.PreviousConfigurationId;
  }
  set PreviousConfigurationId(val: number) {
    if (val === this.dto.PreviousConfigurationId) return;
    this.dto.PreviousConfigurationId = val;
    this.isDirty = true;
  }

  get RightWidth() {
    return this.dto.RightWidth;
  }
  set RightWidth(val: number) {
    if (val === this.dto.RightWidth) return;
    this.dto.RightWidth = val;
    this.isDirty = true;
  }

  get Rotation() {
    return this.dto.Rotation;
  }
  set Rotation(val: number) {
    if (val === this.dto.Rotation) return;
    this.dto.Rotation = val;
    this.isDirty = true;
  }

  get SightHeight() {
    return this.dto.SightHeight;
  }
  set SightHeight(val: number) {
    if (val === this.dto.SightHeight) return;
    this.dto.SightHeight = val;
    this.isDirty = true;
  }

  get SightWidth() {
    return this.dto.SightWidth;
  }
  set SightWidth(val: number) {
    if (val === this.dto.SightWidth) return;
    this.dto.SightWidth = val;
    this.isDirty = true;
  }

  get Width() {
    return this.dto.Width;
  }
  set Width(val: number) {
    if (val === this.dto.Width) return;

    this.dto.Width = Math.max(
      Constants.minCabinetWidth,
      Math.min(Constants.maxCabinetWidth, val),
    );
    this.dto.InteriorWidth = this.dto.Width;
    this.isDirty = true;
  }

  get Images() {
    return this.dto.Images;
  }
  set Images(val: Interface_DTO.CabinetSectionImage[]) {
    this.dto.Images = val;
    this.isDirty = true;
  }

  public AddedByServiceItems: Client.ConfigurationItem[] = [];
  public CustomItems: Client.ConfigurationItem[] = [];
  public ManuallyAddedItems: Client.ConfigurationItem[] = [];
  //public ModuleItems: Client.ConfigurationItem[] = [];

  //#endregion DTO mappings

  public get configurationItems(): Client.ConfigurationItem[] {
    return this.doors.doorItems
      .concat(this.doors.railItems)
      .concat(this.corpus.itemsTop)
      .concat(this.corpus.itemsBottom)
      .concat(this.corpus.itemsLeft)
      .concat(this.corpus.itemsRight)
      .concat(this.corpus.lightingItems)
      .concat(this.swing.items)
      .concat(this.swingFlex.items)
      .concat(this.interior.items)
      .concat(this.backing.items)
      .concat(this.ManuallyAddedItems)
      .concat(this.CustomItems)
      .concat(this.AddedByServiceItems);
  }

  //#region Dimensions

  public get cabinetPosition(): Interface_DTO_Draw.Vec3d {
    return {
      X: this.PositionX,
      Y: 0,
      Z: this.PositionY,
    };
  }

  public get maxDepth(): number {
    let extraDepth = 0;
    if (this.isSwingFlex) {
      if (this.swingFlex.corpusCoversDoors) {
        extraDepth += this.swingFlex.getDoorDepth({
          includeSpacingBehindDoors: true,
        });
      }
    } else {
      extraDepth = this.backing ? this.backing.frontZ : 0;
    }

    return this.cabinet.maxDepth + extraDepth;
  }

  public get minDepth(): number {
    let extraDepth = 0;
    if (this.isSwingFlex) {
      if (this.swingFlex.corpusCoversDoors) {
        extraDepth += this.swingFlex.getDoorDepth({
          includeSpacingBehindDoors: true,
        });
      }
    }
    return this.cabinet.minDepth + extraDepth;
  }

  public get isDepthUserAdjustable(): boolean {
    return this.CabinetType !== Interface_Enums.CabinetType.Doors;
  }

  public get isSwing(): boolean {
    return this.CabinetType === Interface_Enums.CabinetType.Swing;
  }

  public get isSwingFlex(): boolean {
    return this.CabinetType === Interface_Enums.CabinetType.SwingFlex;
  }

  public get cabinetRotation() {
    return (this.Rotation / 180) * Math.PI;
  }

  public get floorPlanPosition(): Interface_DTO_Draw.Vec3d {
    return VectorHelper.add(
      this.cabinet.floorPlanPosition,
      VectorHelper.rotate3d(
        this.cabinetPosition,
        this.cabinet.floorPlanRotation,
      ),
    );
  }

  public get floorPlanRotation() {
    return this.cabinet.floorPlanRotation - this.cabinetRotation;
  }

  public get sightOffsetX() {
    return CabinetSectionHelper.hasCornerLeft(this)
      ? CabinetSectionHelper.getCornerOffsetForDoors(this)
      : this.corpus.widthLeft;
  }
  public get sightOffsetY() {
    return this.corpus.heightBottom;
  }

  public get minInteriorDepth(): number {
    return this.cabinet.productLine.MinDepthInterior;
  }
  public get maxInteriorDepth(): number {
    return this.cabinet.productLine.MaxDepthInterior;
  }

  get InteriorDepth() {
    return this.dto.InteriorDepth;
  }
  set InteriorDepth(val: number) {
    val = ObjectHelper.clamp(this.minInteriorDepth, val, this.maxInteriorDepth);
    if (val === this.dto.InteriorDepth) return;
    this.dto.InteriorDepth = val;
    this.isDirty = true;
  }

  //#endregion Dimensions

  public get errorInfos(): Client.ErrorInfo[] {
    if (!this._cache.errorInfos) {
      this._cache.errorInfos = CabinetSectionValidationService.validate(this);
    }
    return this._cache.errorInfos;
  }

  public get maxErrorLevel(): Interface_Enums.ErrorLevel {
    if (this._cache.maxErrorLevel === undefined) {
      if (!this.errorInfos) {
        //this should be very temporary, errorinfos should be populated shortly
        //do not cache this result
        return Interface_Enums.ErrorLevel.None;
      }
      this._cache.maxErrorLevel = Math.max(
        Interface_Enums.ErrorLevel.None,
        ...this.errorInfos.map((info) => info.level),
      );
    }
    return this._cache.maxErrorLevel;
  }

  public get editorAssets() {
    return this.cabinet.editorAssets;
  }

  public get leftNeighbor(): CabinetSection | undefined {
    let result = this.cabinet.getSectionByIndex(this.CabinetSectionIndex - 1);
    if (result && result.CabinetType === this.CabinetType) {
      return result;
    }
    return;
  }
  public get rightNeighbor(): CabinetSection | undefined {
    let result = this.cabinet.getSectionByIndex(this.CabinetSectionIndex + 1);
    if (result && result.CabinetType === this.CabinetType) {
      return result;
    }
    return;
  }

  public readonly corpus: Client.Corpus;
  public readonly doors: Client.Doors;
  public readonly swing: Client.Swing;
  public readonly swingFlex: Client.SwingFlex;
  public readonly interior: Client_Interior;
  public readonly backing: Client_Backing;

  public getAbsolutePoint(
    point: Interface_DTO_Draw.Vec2d,
  ): Interface_DTO_Draw.Vec2d {
    let rotation = this.floorPlanRotation;
    let rotatedPoint = VectorHelper.rotate2d(point, rotation);
    let absolutePoint = VectorHelper.add(rotatedPoint, {
      X: this.floorPlanPosition.X,
      Y: this.floorPlanPosition.Z,
    });

    return { X: absolutePoint.X, Y: absolutePoint.Y };
  }

  public getPoints(includeRail: boolean): Interface_DTO_Draw.Vec2d[] {
    let points = new Array<Interface_DTO_Draw.Vec2d>();

    let railDepth = CabinetSectionHelper.getMaximumRailSetDepth(this);
    let extraRailWidthLeft =
      this.doors.numberOfRailTracks > 1 ? this.doors.extraRailWidthLeft : 0;
    let extraRailWidthRight =
      this.doors.numberOfRailTracks > 1 ? this.doors.extraRailWidthRight : 0;

    points.push(this.getAbsolutePoint({ X: 0, Y: 0 }));
    points.push(this.getAbsolutePoint({ X: this.Width, Y: 0 }));

    if (includeRail && extraRailWidthRight > 0) {
      points.push(
        this.getAbsolutePoint({ X: this.Width, Y: this.Depth - railDepth }),
      );
      points.push(
        this.getAbsolutePoint({
          X: this.Width + extraRailWidthRight,
          Y: this.Depth - railDepth,
        }),
      );
      points.push(
        this.getAbsolutePoint({
          X: this.Width + extraRailWidthRight,
          Y: this.Depth,
        }),
      );
    }

    points.push(this.getAbsolutePoint({ X: this.Width, Y: this.Depth }));
    points.push(this.getAbsolutePoint({ X: 0, Y: this.Depth }));

    if (includeRail && extraRailWidthLeft > 0) {
      points.push(
        this.getAbsolutePoint({ X: 0 - extraRailWidthLeft, Y: this.Depth }),
      );
      points.push(
        this.getAbsolutePoint({
          X: 0 - extraRailWidthLeft,
          Y: this.Depth - railDepth,
        }),
      );
      points.push(this.getAbsolutePoint({ X: 0, Y: this.Depth - railDepth }));
    }

    return points;
  }

  public getBackPoints(withTolerance: boolean): Interface_DTO_Draw.Vec2d[] {
    let points = new Array<Interface_DTO_Draw.Vec2d>();

    points.push(
      this.getAbsolutePoint({
        X: 0 + (withTolerance ? Constants.sizeAndPositionTolerance : 0),
        Y: 0,
      }),
    );
    points.push(
      this.getAbsolutePoint({
        X:
          this.Width - (withTolerance ? Constants.sizeAndPositionTolerance : 0),
        Y: 0,
      }),
    );

    return points;
  }

  public getFrontPoints(withTolerance: boolean): Interface_DTO_Draw.Vec2d[] {
    let points = new Array<Interface_DTO_Draw.Vec2d>();

    if (!!this.rightNeighbor) {
      points.push(
        this.getAbsolutePoint({
          X:
            this.Width -
            this.rightNeighbor.Depth -
            (withTolerance ? Constants.sizeAndPositionTolerance : 0),
          Y: this.Depth,
        }),
      );
    } else {
      points.push(
        this.getAbsolutePoint({
          X:
            this.Width -
            (withTolerance ? Constants.sizeAndPositionTolerance : 0),
          Y: this.Depth,
        }),
      );
    }

    if (!!this.leftNeighbor) {
      points.push(
        this.getAbsolutePoint({
          X:
            0 +
            this.leftNeighbor.Depth +
            (withTolerance ? Constants.sizeAndPositionTolerance : 0),
          Y: this.Depth,
        }),
      );
    } else {
      points.push(
        this.getAbsolutePoint({
          X: 0 + (withTolerance ? Constants.sizeAndPositionTolerance : 0),
          Y: this.Depth,
        }),
      );
    }

    return points;
  }

  public getBackCenterPoint() {
    return this.getAbsolutePoint({ X: this.Width / 2, Y: 0 });
  }

  public get isEmpty(): boolean {
    return (
      this.corpus.isEmpty &&
      this.doors.isEmpty &&
      this.interior.isEmpty &&
      this.backing.isEmpty &&
      this.CustomItems.length === 0 &&
      this.ManuallyAddedItems.length === 0
    );
  }
}
