import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import * as Exception from 'app/ts/exceptions';
import { LoginService } from 'app/ts/services/LoginService';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { AddressService } from 'app/ts/services/AddressService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import {
  ActiveStoreInjector,
  ActiveStore,
} from 'app/functional-core/ambient/stores/ActiveStore';
import {
  StoreSetting,
  StoreSettingInjector,
} from 'app/functional-core/ambient/stores/StoreSetting';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { Store } from 'app/ts/Interface_DTO';
import { ActiveUser } from 'app/functional-core/ambient/activeUser/ActiveUser';
import { ClientSetting } from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { NotificationService } from './NotificationService';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private userId?: number;

  private _customers$ = new BehaviorSubject<Client.Customer[]>([]);
  public get customers$(): Observable<Client.Customer[]> {
    return this._customers$;
  }

  private setCustomers(value: Client.Customer[]) {
    this._customers$.next(value);
  }

  private requireEmail = false;
  private isUpdating = false;
  private store!: Store;
  constructor(
    private readonly httpClient: PromisingBackendService,
    loginService: LoginService,
    private readonly translationService: TranslationService,
    private readonly permissions: PermissionService,
    private readonly addressService: AddressService,
    private notificationService: NotificationService,
    @Inject(StoreSettingInjector) public readonly storeSetting: StoreSetting,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    loginService.salesChainSettings$.subscribe(
      (settings) =>
        (this.requireEmail =
          settings[
            Interface_Enums.SalesChainSettingKey.RequireEmailWhenOrdering
          ] == '1'),
    );
    activeStore.subscribe((value) => {
      this.store = value;
    });
  }

  public loadCustomers(): Promise<void> {
    this.isUpdating = true;
    return this.httpClient
      .get<DTO.Customer[]>('api/customers')
      .then((customers) => {
        this.setCustomers(customers.map((c) => new Client.Customer(c)));
      })
      .finally(() => {
        this.isUpdating = false;
      });
  }

  public async getCustomersBySearch(
    search: CustomerSearchParams,
  ): Promise<Client.Customer[]> {
    const allCustomers = firstValueFrom(
      this.getAll().pipe(
        map((customers) =>
          CustomerService.mapCustomer1(search, customers, this.userId, true),
        ),
      ),
    );
    return allCustomers;
  }

  public filterCustomers(
    filter: CustomerSearchParams,
    currentUserId: number,
    showAllFloorplansOnEmptySearch: boolean,
    customers: Client.Customer[],
  ): Client.Customer[] {
    return CustomerService.mapCustomer1(
      filter,
      customers,
      currentUserId,
      showAllFloorplansOnEmptySearch,
    );
  }

  public getObservableBySearchObservable(
    search: Observable<CustomerSearchParams>,
    activeUser: ActiveUser,
    clientSettings: ClientSetting,
  ): Observable<Client.Customer[]> {
    return combineLatest({
      customers: this.customers$,
      search: search,
      clientSettings: clientSettings.data$,
    }).pipe(
      map((c) =>
        CustomerService.mapCustomer1(
          c.search,
          c.customers,
          activeUser.data.UserId,
          c.clientSettings.showAllFloorplansOnEmptySearch,
        ),
      ),
    );
  }

  public static mapCustomer1(
    search: CustomerSearchParams,
    customers: Client.Customer[],
    currentUserId: number | undefined,
    showAllFloorplansOnEmptySearch: boolean,
  ): Client.Customer[] {
    search = ObjectHelper.copy(search);
    if (!search.owner) {
      search.owner = Constants.overview.onlyMineOwnerSearchString;
    }
    if (
      !showAllFloorplansOnEmptySearch &&
      !search.showAll &&
      !search.customerId &&
      !search.quickSearch &&
      !search.projectId &&
      !search.deliveryAddressId
    ) {
      return [];
    }

    if (CustomerService.hasCustomerRelatedFields(search)) {
      if (App.debug.showTimings) console.time('filter Customers');
      let result = <Client.Customer[]>customers
        .map((cust) =>
          CustomerService.mapCustomer2(cust, search, currentUserId),
        )
        .filter((cust) => !!cust)
        .sort((c1, c2) =>
          c1!.ChildModifiedDate.localeCompare(c2!.ChildModifiedDate),
        );
      if (App.debug.showTimings) console.timeEnd('filter Customers');
      return result;
    } else {
      //no search parameters
      if (App.debug.showTimings) console.time('copying customers');
      let result = ObjectHelper.copy(customers).sort((c1, c2) =>
        c2.ChildModifiedDate.localeCompare(c2.ChildModifiedDate),
      );
      if (App.debug.showTimings) console.timeEnd('copying customers');
      return result;
    }
  }

  private static mapCustomer2(
    cust: Client.Customer,
    search: CustomerSearchParams,
    currentUserId: number | undefined,
  ): Client.Customer | null {
    if (search.customerId !== undefined) {
      let idInts = search.customerId
        .split(Constants.overview.idSearchSplitter)
        .map((daIdStr) => parseInt(daIdStr));
      if (idInts.indexOf(cust.Id) < 0) return null;
    }

    let matchedFields: Partial<Record<keyof CustomerSearchParams, boolean>> =
      {};

    if (search.quickSearch) {
      let quickSearch = search.quickSearch as string;
      let haystacks = [
        cust.Name,
        cust.Phone,
        cust.Address1,
        cust.Address2,
        cust.Email,
        cust.DebitorNo,
        cust.PostalCode,
        cust.City,
      ];
      let quickSearchMatches = haystacks.some((haystack) =>
        CustomerService.containsCaseInsensitive(haystack, quickSearch),
      );
      //if quicksearch matches the customer, all its children should be shown, even if the children don't match the search by themselves
      matchedFields['quickSearch'] = quickSearchMatches;
    }

    if (!CustomerService.hasProjectRelatedFields(search)) {
      //customer and all its descendents should be shown
      return ObjectHelper.copy(cust);
    } else {
      //customer should only be shown if come of its projects match the search
      let matchingProjects = cust.Projects.map(
        (proj) =>
          CustomerService.mapProject2(
            proj,
            search,
            matchedFields,
            currentUserId,
          ) as Client.Project,
      )
        .filter((proj) => !!proj)
        .sort((c1, c2) =>
          c1!.ChildModifiedDate.localeCompare(c2!.ChildModifiedDate),
        );

      if (matchingProjects.length > 0) {
        //some of the customer's descendents matched the search - return the customer with only some of its descendents
        let result = ObjectHelper.copy(cust);
        result.Projects = matchingProjects;
        return result;
      } else {
        //no descendents matched the filter
        if (search.owner == Constants.overview.onlyMineOwnerSearchString) {
          //mine only is turned on
          let search2 = ObjectHelper.copy(search);
          search2.owner = Constants.overview.anyOwnerSearchString;
          if (
            currentUserId === cust.UserId &&
            !CustomerService.hasProjectRelatedFields(search2)
          ) {
            let result = ObjectHelper.copy(cust);
            result.Projects = [];
            return result;
          }
        }
        if (cust.Projects.length === 0 && matchedFields['quickSearch']) {
          return ObjectHelper.copy(cust);
        } else {
          return null;
        }
      }
    }
  }

  private static mapProject2(
    proj: Client.Project,
    search: CustomerSearchParams,
    matchedFields: Partial<Record<keyof CustomerSearchParams, boolean>>,
    currentUserId: number | undefined,
  ): Client.Project | null {
    if (search.projectId !== undefined) {
      let idInts = search.projectId
        .split(Constants.overview.idSearchSplitter)
        .map((daIdStr) => parseInt(daIdStr));
      if (idInts.indexOf(proj.Id) < 0) return null;
    }

    matchedFields = ObjectHelper.copy(matchedFields);

    if (search.quickSearch && !matchedFields['quickSearch']) {
      let quickSearch = search.quickSearch as string;
      let haystacks = [proj.Name, proj.Description];
      let quickSearchMatches = haystacks.some((haystack) =>
        CustomerService.containsCaseInsensitive(haystack, quickSearch),
      );
      matchedFields['quickSearch'] = quickSearchMatches;
    }

    if (!CustomerService.hasDeliveryAddressRelatedFields(search)) {
      return ObjectHelper.copy(proj);
    } else {
      let matchingDeliveryAddresses = proj.DeliveryAddresses.map(
        (da) =>
          CustomerService.mapDeliveryAddress2(
            da,
            search,
            matchedFields,
            currentUserId,
          ) as Client.DeliveryAddress,
      )
        .filter((da) => !!da)
        .sort((c1, c2) =>
          c1!.ChildModifiedDate.localeCompare(c2!.ChildModifiedDate),
        );

      if (matchingDeliveryAddresses.length > 0) {
        proj = ObjectHelper.copy(proj);
        proj.DeliveryAddresses = matchingDeliveryAddresses;
        return proj;
      } else {
        if (search.owner === Constants.overview.onlyMineOwnerSearchString) {
          //mine only is turned on

          let search2 = ObjectHelper.copy(search);
          search2.owner = Constants.overview.anyOwnerSearchString;
          if (
            currentUserId === proj.UserId &&
            !CustomerService.hasDeliveryAddressRelatedFields(search2)
          ) {
            let result = ObjectHelper.copy(proj);
            result.DeliveryAddresses = [];
            return result;
          }
        }
        if (
          proj.DeliveryAddresses.length === 0 &&
          matchedFields['quickSearch']
        ) {
          return ObjectHelper.copy(proj);
        } else {
          return null;
        }
      }
    }
  }

  private static mapDeliveryAddress2(
    da: Client.DeliveryAddress,
    search: CustomerSearchParams,
    matchedFields: Partial<Record<keyof CustomerSearchParams, boolean>>,
    currentUserId: number | undefined,
  ): Client.DeliveryAddress | null {
    if (search.deliveryAddressId !== undefined) {
      let idInts = search.deliveryAddressId
        .split(Constants.overview.idSearchSplitter)
        .map((daIdStr) => parseInt(daIdStr));
      if (idInts.indexOf(da.ProjectDeliveryAddressId) < 0) return null;
    }

    matchedFields = ObjectHelper.copy(matchedFields);

    if (search.quickSearch && !matchedFields['quickSearch']) {
      let quickSearch = search.quickSearch as string;
      let haystacks = [
        da.Address1,
        da.Address2,
        da.Email,
        da.Name,
        da.Phone,
        da.PostalCode,
        da.City,
      ];
      let quickSearchMatches = haystacks.some((haystack) =>
        CustomerService.containsCaseInsensitive(haystack, quickSearch),
      );
      matchedFields['quickSearch'] = quickSearchMatches;
    }

    if (!CustomerService.hasFloorPlanRelatedFields(search)) {
      return ObjectHelper.copy(da);
    } else {
      let matchingFloorPlans = da.FloorPlans.map(
        (fpo) =>
          CustomerService.mapFloorPlan2(
            fpo,
            search,
            matchedFields,
            currentUserId,
          ) as Client.FloorPlanOverview,
      ).filter((fpo) => !!fpo);

      if (matchingFloorPlans.length > 0) {
        da = ObjectHelper.copy(da);
        da.FloorPlans = matchingFloorPlans;
        return da;
      } else {
        if (search.owner === Constants.overview.onlyMineOwnerSearchString) {
          //mine only is turned on

          let search2 = ObjectHelper.copy(search);
          search2.owner = Constants.overview.anyOwnerSearchString;
          if (
            currentUserId === da.UserId &&
            !CustomerService.hasFloorPlanRelatedFields(search2)
          ) {
            let result = ObjectHelper.copy(da);
            result.FloorPlans = [];
            return result;
          }
        }
        if (da.FloorPlans.length === 0 && matchedFields['quickSearch']) {
          return ObjectHelper.copy(da);
        } else {
          return null;
        }
      }
    }
  }

  private static mapFloorPlan2(
    fpo: Client.FloorPlanOverview,
    search: CustomerSearchParams,
    matchedFields: Partial<Record<keyof CustomerSearchParams, boolean>>,
    currentUserId: number | undefined,
  ): Client.FloorPlanOverview | null {
    if (search.owner === 'anyone') {
      //do nothing
    } else if (
      search.owner === Constants.overview.onlyMineOwnerSearchString ||
      !search.owner
    ) {
      if (!fpo.OwnedByCurrentUser) return null;
    } else {
      let lowerOwner = search.owner.toLocaleLowerCase();
      if (fpo.UserName.toLocaleLowerCase().indexOf(lowerOwner) < 0) {
        //userName didn't match

        if (fpo.SupporterName) {
          if (fpo.SupporterName.toLocaleLowerCase().indexOf(lowerOwner) < 0)
            return null; //supportername didn't match either
        } else {
          return null; //there is no supporter name to match on
        }
      }
    }

    switch (search.orderStatus) {
      case 'quote':
        if (
          !(
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Quote ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Rejected ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Unknown ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Invalid
          )
        )
          return null;
        break;
      case 'order':
        if (
          !(
            fpo.CurrentStatus ===
              Interface_Enums.DeliveryStatus.PendingConfirmation ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Order ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Overridden ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Confirmed ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Cancel ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Cancelled ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Received ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Manufactured ||
            fpo.CurrentStatus === Interface_Enums.DeliveryStatus.Blocked
          )
        )
          return null;
        break;
      case 'delivered':
        if (fpo.CurrentStatus !== Interface_Enums.DeliveryStatus.Delivered)
          return null;
        break;
      default:
    }

    let fpoMatches = true;

    if (search.quickSearch && !matchedFields['quickSearch']) {
      let quickSearch = search.quickSearch as string;
      let quickSearchMatches =
        CustomerService.containsCaseInsensitive(fpo.Name, quickSearch) ||
        fpo.OrderNo === quickSearch ||
        fpo.Id + '' === quickSearch ||
        CustomerService.StartsWithCaseInsensitive(fpo.Reference, quickSearch);
      fpoMatches = quickSearchMatches;
    }

    if (fpoMatches) {
      return ObjectHelper.copy(fpo);
    } else {
      return null;
    }
  }

  private static hasCustomerRelatedFields(
    searchParams: CustomerSearchParams,
  ): boolean {
    return !!(
      searchParams.quickSearch ||
      searchParams.customerId ||
      CustomerService.hasProjectRelatedFields(searchParams)
    );
  }
  private static hasProjectRelatedFields(
    searchParams: CustomerSearchParams,
  ): boolean {
    return !!(
      searchParams.quickSearch ||
      searchParams.projectId ||
      CustomerService.hasDeliveryAddressRelatedFields(searchParams)
    );
  }
  private static hasDeliveryAddressRelatedFields(
    searchParams: CustomerSearchParams,
  ): boolean {
    return !!(
      searchParams.quickSearch ||
      searchParams.deliveryAddressId ||
      CustomerService.hasFloorPlanRelatedFields(searchParams)
    );
  }
  private static hasFloorPlanRelatedFields(
    searchParams: CustomerSearchParams,
  ): boolean {
    if (searchParams.quickSearch || searchParams.orderStatus) {
      return true;
    }
    if (searchParams.owner !== Constants.overview.anyOwnerSearchString) {
      return true;
    }

    return false;
  }

  private static getCutoffDate(searchString: string): Date {
    let cutoffDate = new Date(Date.now());
    let dateFilters = {
      hr: (d: Date, n: number) => d.setHours(d.getHours() - n),
      day: (d: Date, n: number) => d.setDate(d.getDate() - n),
      wk: (d: Date, n: number) => d.setDate(d.getDate() - 7 * n),
      mon: (d: Date, n: number) => d.setMonth(d.getMonth() - n),
      yr: (d: Date, n: number) => d.setFullYear(d.getFullYear() - n),
    };

    let dateRegex = /(\d+)(hr|day|wk|mon|yr)/;

    let dateRegexResult = dateRegex.exec(searchString);
    if (dateRegexResult) {
      let number = parseInt(dateRegexResult[1]);
      let unit = dateRegexResult[2] as 'hr' | 'day' | 'wk' | 'mon' | 'yr';
      dateFilters[unit](cutoffDate, number);
    }
    return cutoffDate;
  }

  public getAll(): Observable<Client.Customer[]> {
    return this.customers$.pipe(
      map((cs) => cs.map((c) => ObjectHelper.copy(c))),
    );
  }

  public updateCustomerList(foreground: boolean = true): Promise<any> {
    if (!this.permissions.canSeeOrders) return Promise.resolve(true);
    return this.notificationService.loadPromise(this.loadCustomers());
  }

  public get isUpdatingCustomers() {
    return this.isUpdating;
  }

  public async getEmptyCustomer(): Promise<Client.Customer> {
    const storeId = this.store.Id;
    if (!storeId) throw new Error('cannot create customer unless logged in');

    // Next
    let store = this.store;
    let countryCode = store ? store.Address.CountryCode : '';
    let countries = await this.addressService.countryPromises;
    let storeCountry = countries.filter((c) => c.Code === countryCode)[0];
    let phonePrefix = storeCountry ? storeCountry.PhonePrefix : '';

    let dto: DTO.Customer = {
      Address1: '',
      Address2: '',
      City: '',
      Country: countryCode,
      CustomerType: 0,
      DebitorNo: '',
      Email: '',
      Floor: 0,
      Id: 0,
      Name: '',
      MajorCustomerId: 0,
      Phone: phonePrefix || '',
      PostalCode: '',
      SENumber: '',
      Unit: '',
      Projects: [],
      StoreId: storeId,
      CreatedDate: DateHelper.toIsoString(new Date(Date.now())),
      UserId: this.userId || null,
    };
    return new Client.Customer(dto);
  }

  public searchExternalCustomers(
    searchString: string,
    serverId: number,
  ): Promise<DTO.Customer[]> {
    return this.httpClient.get<DTO.Customer[]>(
      'api/customers/external/search',
      {
        params: {
          customerImportSource: serverId,
          externalCustomerId: searchString,
        },
      },
    );
  }

  public async importExternalCustomer(
    customer: DTO.Customer,
  ): Promise<Client.Customer> {
    let clientCustomer = await this.createCustomer(customer);
    return clientCustomer;
  }

  public async createCustomer(
    customer: DTO.Customer,
  ): Promise<Client.Customer> {
    await this.assertCustomerIsValidAsync(customer);
    let newCustomer = await this.httpClient.put<DTO.Customer>(
      'api/customers/',
      customer,
    );

    await this.updateCustomerList(true);
    return new Client.Customer(newCustomer);
  }

  public async updateCustomer(
    customer: DTO.Customer,
  ): Promise<Client.Customer> {
    await this.assertCustomerIsValidAsync(customer);
    let copy = ObjectHelper.copy(customer);
    copy.Projects = []; //All child objects are ignored on server anyway, no need to send them
    let editedCustomer = await this.httpClient.post<DTO.Customer>(
      'api/customers/',
      copy,
    );

    return new Client.Customer(editedCustomer);
  }

  private async assertCustomerIsValidAsync(
    customer: DTO.Customer,
  ): Promise<void> {
    let requiredProperties: (keyof DTO.Customer)[] = [
      'Name',
      'Address1',
      'Country',
      'City',
      'PostalCode',
    ];
    if (this.requireEmail) {
      requiredProperties.push('Email');
    }
    for (let propName of requiredProperties) {
      if (!customer[propName])
        throw new Exception.PropertyInvalid<DTO.Customer>(customer, propName);
    }
  }

  public async updateBulkCustomers(
    customer: Partial<DTO.Customer>,
    customerIds: number[],
  ) {
    throw new Error('Not Implemented');
  }

  public async delete(customers: DTO.Customer[]) {
    let idsToDelete = customers.map((c) => c.Id);
    await this.httpClient.post('api/customers/delete', idsToDelete, {
      responseType: 'void',
    });
    await this.updateCustomerList(true);
  }

  public async getDefaultProjectDeliveryAddressIdForCurrentStore(): Promise<number> {
    let pdaId = await this.httpClient.get<number>(
      'api/deliveryaddresses/defaultProjectDeliveryAddressId',
    );
    return pdaId;
  }

  public async createDefaultCustomerForStore(
    store: Interface_DTO.Store,
  ): Promise<Client.Customer> {
    let customer = (await this.getEmptyCustomer()).dtoObject;
    customer.Address1 = store.Address.Address1;
    customer.Address2 = store.Address.Address2;
    customer.City = store.Address.City;
    customer.Country = store.Address.CountryCode;
    customer.Email = store.Email;
    customer.Name = this.translationService.translate(
      'default_customer_default_name_for_store_{0}',
      'Default customer for {0}',
      store.Name,
    );
    customer.Phone = store.Address.Phone;
    customer.PostalCode = store.Address.PostalCode;
    customer.Projects = [
      {
        CustomerId: -1,
        Id: -1,
        CreatedDate: customer.CreatedDate,
        Name: this.translationService.translate(
          'projects_default_project_name_{0}',
          'Default project for {0}',
          customer.Name,
        ),
        Description: '',
        UserId: this.userId || null,
        DeliveryAddresses: [
          {
            Id: -1,
            ProjectDeliveryAddressId: -1,
            ProjectId: -1,
            FloorPlans: [],
            Address1: customer.Address1,
            Address2: customer.Address2,
            City: customer.City,
            Country: customer.Country,
            CreatedDate: customer.CreatedDate,
            Email: customer.Email,
            Floor: customer.Floor,
            Name: customer.Name,
            Phone: customer.Phone,
            PostalCode: customer.PostalCode,
            Unit: customer.Unit,
            StoreId: null,
            ERPCode: null,
            UserId: this.userId || null,
          },
        ],
      },
    ];
    let clientCustomer = await this.createCustomer(customer);

    const storeData = this.storeSetting.copy();
    storeData.DefaultProjectDeliveryAddressId =
      clientCustomer.Projects[0].DeliveryAddresses[0].ProjectDeliveryAddressId;
    this.storeSetting.set(storeData);

    return clientCustomer;
  }

  private static containsCaseInsensitive(
    haystack: string | null,
    needle: string,
  ): boolean {
    if (!haystack) return false;
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;
  }

  private static StartsWithCaseInsensitive(
    haystack: string | null,
    needle: string,
  ): boolean {
    if (!haystack) return false;
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) === 0;
  }
}
