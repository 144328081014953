import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ProjectEditMenuVm } from './ProjectEditMenuVm';
import { DeliveryAddressEditMenuModule } from './deliveryAddressEditMenu.module';
import { ProjectEditVm } from './ProjectEditVm';
import { I18NModule } from 'app/i18n/i18n.module';

@NgModule({
  declarations: [ProjectEditMenuVm, ProjectEditVm],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    DeliveryAddressEditMenuModule,
    I18NModule,
  ],
  exports: [ProjectEditMenuVm, ProjectEditVm],
})
export class ProjectEditMenuModule {}
