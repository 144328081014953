<table class="main-table">
  <colgroup>
    <col *ngIf="showCheckBox" class="select-col" />
    <col *ngIf="showRadioButton" class="select-col" />
    <col
      *ngFor="let colName of selectedColumns"
      [ngClass]="columns[colName].colClass"
      class="repeat-col"
    />
    <col
      class="btn-col"
      *ngIf="displayNavigationLeft || displayNavigationRight"
    />
  </colgroup>
  <thead>
    <tr>
      <th *ngIf="showCheckBox"></th>
      <th *ngIf="showRadioButton"></th>

      <th
        *ngFor="let colName of selectedColumns"
        [textContent]="columns[colName].displayName"
        [ngClass]="{'order-col': colName === order && !reverseOrder, 'order-col-reverse': colName === order && reverseOrder}"
        (click)="setOrder(colName)"
        class="orderable-col"
      ></th>

      <th
        [textContent]="translate('table_col_nav', 'Nav')"
        *ngIf="displayNavigationLeft || displayNavigationRight"
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let object of getSortedObjects()"
      (click)="onClick(object)"
      [ngClass]="{'selected':object.selected, 'highlight': shouldHighlight(object)}"
      [id]="'id' + object.Id"
    >
      <td *ngIf="showCheckBox">
        <input
          type="checkbox"
          name="ObjectSelected"
          [(ngModel)]="object.selected"
          style="pointer-events: none"
        />
      </td>
      <td *ngIf="showRadioButton">
        <input
          type="radio"
          name="ObjectSelectedRadio"
          [(ngModel)]="object.selected"
          [value]="true"
          style="pointer-events: none"
        />
      </td>

      <td
        *ngFor="let colName of selectedColumns"
        [textContent]="getCellValue(colName, object)"
        [ngClass]="getCellClass(colName, object)"
        [attr.title]="getColumnTitle(colName, object)"
      ></td>

      <td *ngIf="displayNavigationLeft || displayNavigationRight">
        <div class="btn-group btn-group-xs btn-group-nav">
          <button
            class="btn btn-primary"
            *ngIf="displayNavigationLeft"
            (click)="onLeft(object)"
            onclick="event.stopPropagation()"
          >
            <span class="bi bi-caret-left-fill"></span>
            <span [ngClass]="leftClass"></span>
          </button>
          <button
            class="btn btn-primary"
            *ngIf="displayNavigationRight"
            (click)="onRight(object)"
            onclick="event.stopPropagation()"
          >
            <span [ngClass]="rightClass"></span>
            <span class="bi bi-caret-right-fill"></span>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
