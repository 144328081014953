<div class="modal-content">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('add_fitting_modal_header', 'Add fitting')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <!--<div class="load-area" [ngClass]="{'loading': !ready}">
            <div class="load-spinner"></div>
        </div>-->

    <div>
      <div>
        {{translate('add_fitting_modal_description', 'Description')}}
        <input
          class="form-control"
          type="text"
          autofocus
          [(ngModel)]="description"
          [attr.placeholder]="translate('add_fitting_modal_Description', 'Description')"
        />
      </div>
      <br />
      <div>
        {{translate('add_fitting_modal_price', 'Price')}}
        <input class="form-control" type="number" [(ngModel)]="price" />
      </div>
      <br />
      <div>
        {{translate('add_fitting_modal_price_type', 'Select calculation
        type')}}<br />
        <select
          [(ngModel)]="valueType"
          style="min-width: 150px"
          class="form-select"
        >
          <option value="5">
            {{translate('add_fitting_modal_ammount', 'Amount')}}
          </option>
          <option value="8" *ngIf="showPercentOption">
            {{translate('add_fitting_modal_percent', 'Percent')}}
          </option>
        </select>
      </div>
      <br />
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-danger"
      (click)="delete()"
      [ngClass]="{'disabled': !okPossible}"
      [hidden]="!showDelete"
    >
      {{translate('modal_button_remove', 'remove')}}
    </button>

    <button
      class="btn btn-primary"
      (click)="ok()"
      [ngClass]="{'disabled': !okPossible}"
      [hidden]="action!=0"
    >
      {{translate('modal_button_add', 'add')}}
    </button>

    <button
      class="btn btn-primary"
      (click)="ok()"
      [ngClass]="{'disabled': !okPossible}"
      [hidden]="action!=1"
    >
      {{translate('modal_button_update', 'update')}}
    </button>

    <button type="button" class="btn btn-light" (click)="cancel()">
      {{translate('modal_button_cancel', 'cancel')}}
    </button>
  </div>
</div>
