import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
export class CameraAngle {
  private static isPerspectiveCamera(
    camera: THREE.Camera,
  ): camera is THREE.PerspectiveCamera {
    return (camera as any).isPerspectiveCamera;
  }

  public static fromOrbitControls(orbit: OrbitControls): CameraAngle {
    let camera = orbit.object;
    let pos: Interface_DTO_Draw.Vec3d = {
      X: camera.position.x,
      Y: camera.position.y,
      Z: camera.position.z,
    };
    let target: Interface_DTO_Draw.Vec3d = {
      X: orbit.target.x,
      Y: orbit.target.y,
      Z: orbit.target.z,
    };
    let fov = 50;
    if (CameraAngle.isPerspectiveCamera(camera)) {
      fov = camera.fov;
    }
    return new CameraAngle(pos, target, fov);
  }

  public constructor(
    public position: Interface_DTO_Draw.Vec3d,
    public target: Interface_DTO_Draw.Vec3d,
    public fov: number,
  ) {}

  public toOrbitControls(orbit: OrbitControls) {
    let camera = orbit.object;
    camera.position.set(this.position.X, this.position.Y, this.position.Z);
    orbit.target.set(this.target.X, this.target.Y, this.target.Z);
    if (CameraAngle.isPerspectiveCamera(camera)) {
      camera.fov = this.fov;
    }

    orbit.update();
  }
}
