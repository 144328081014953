export class DoorOverlap {
  public static readonly type: 'doorOverlap' = 'doorOverlap';
  public readonly type = DoorOverlap.type;

  private static readonly minVisualWidth = 40;

  private _desiredPositionCenter: number;

  private _doorWidthLeft: number = -1;
  private _doorWidthRight: number = -1;

  constructor(
    public positionCenter: number,
    public width: number,
    public readonly index: number,
    public readonly isReal: boolean,
  ) {
    this._desiredPositionCenter = positionCenter;
  }

  public positionMin: number = this.positionCenter;
  public positionMax: number = this.positionCenter;

  public get positionMinRounded(): number {
    return Math.ceil(this.positionMin);
  }
  public get positionMaxRounded(): number {
    return Math.floor(this.positionMax);
  }

  /**
        The desired position of the overlap.
        **/
  get desiredPositionCenter(): number {
    return this._desiredPositionCenter;
  }
  set desiredPositionCenter(value: number) {
    if (value === this.positionCenter) {
      return;
    }

    this._desiredPositionCenter = value;
  }

  get desiredPositionCenterRounded(): number {
    return Math.round(this._desiredPositionCenter);
  }
  set desiredPositionCenterRounded(value: number) {
    if (value === this.positionCenter) {
      return;
    }

    this._desiredPositionCenter = Math.round(value);
  }

  public get positionLeft(): number {
    return this.positionCenter - this.width / 2;
  }
  public get positionRight(): number {
    return this.positionCenter + this.width / 2;
  }

  public get visualLeft(): number {
    return (
      this.positionCenter - Math.max(this.width, DoorOverlap.minVisualWidth) / 2
    );
  }
  public get visualRight(): number {
    return (
      this.positionCenter + Math.max(this.width, DoorOverlap.minVisualWidth) / 2
    );
  }

  //#region Door related

  public widthLeftMin: number = -1;
  public widthLeftMax: number = -1;

  public widthRightMin: number = -1;
  public widthRightMax: number = -1;

  public get doorWidthLeft(): number {
    return this._doorWidthLeft;
  }
  public set doorWidthLeft(value: number) {
    let delta = value - this.doorWidthLeft;
    this.desiredPositionCenterRounded = this.positionCenter + delta;
  }

  public get doorWidthRight(): number {
    return this._doorWidthRight;
  }
  public set doorWidthRight(value: number) {
    let delta = this.doorWidthRight - value;
    this.desiredPositionCenterRounded = this.positionCenter + delta;
  }

  public setDoorWidths(widthLeft: number, widthRight: number) {
    this._doorWidthLeft = Math.floor(widthLeft);
    this._doorWidthRight = Math.floor(widthRight);
  }

  //#endregion Door related
}
