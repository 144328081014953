<div class="right-menu full wide" *ngIf="deliveryAddress">
  <div class="title">
    <div class="title-left"></div>
    <div
      class="title-center"
      [textContent]="translate('deliveryaddresses_menu_single_header_{0}', 'Delivery Address: {0}', deliveryAddress.Address1)"
    ></div>
    <div class="title-right">
      <button class="close" (click)="close()">&times;</button>
    </div>
  </div>
  <div class="body">
    <div class="btn-toolbar">
      <div class="input-group" style="width: 110px">
        <input
          type="number"
          class="form-control"
          [ngClass]="{'disabled': isActionInProgress}"
          min="1"
          [(ngModel)]="numCopies"
          [attr.title]="translate('deliveryAddresses_copy_numCopies','Number of copies')"
        />
        <span class="input-group-btn">
          <button
            class="btn input-group-btn"
            (click)="copy()"
            [ngClass]="{'disabled': isActionInProgress}"
            [attr.title]="translate('deliveryAddresses_copy_deliveryAddresses', 'Copy')"
          >
            <span class="bi bi-journals"></span>
          </button>
        </span>
      </div>
      <div class="input-group-btn">
        <button
          class="btn-danger"
          [ngClass]="{'disabled': isActionInProgress}"
          (click)="delete()"
          [attr.title]="translate('button_delete', 'Delete')"
        >
          <span class="bi bi-trash3"></span>
        </button>
      </div>
    </div>

    <form class="object-detail delivery-address-detail" autocomplete="off">
      <delivery-address-edit [detailObject]="deliveryAddress">
      </delivery-address-edit>

      <div class="btn-group btn-group-right">
        <button
          class="btn btn-primary"
          type="submit"
          [ngClass]="{'disabled': isActionInProgress}"
          (click)="save()"
          [textContent]="translate('detail_button_save', 'Save')"
        ></button>

        <button
          class="btn btn-secondary"
          type="button"
          [ngClass]="{'disabled': isActionInProgress}"
          (click)="close()"
          [textContent]="translate('detail_button_cancel', 'Cancel')"
        ></button>
      </div>
    </form>

    <button
      class="btn btn-primary"
      (click)="showChildren()"
      *ngIf="showShowChildren"
      style="margin-top: 16px"
      [textContent]="translate('delivery_addresses_button_view_floorPlans', 'view floor plans')"
    ></button>
  </div>
</div>
