import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { CommandInterpreter } from 'app/imperative-shell/command-interpreter';
import { Observable, Subject, Subscription } from 'rxjs';
import { SetUserSettingsCommand } from './SetUserSettings.command';

export interface UserSettingsValue {
  Currency?: string;
  DisplayTotalPriceInEditor?: boolean;
  LanguageCode?: string;
  OverrideCurrency?: boolean;
  PriceFactor?: number;
}

type Mutable<UserSettingsValue> = {
  -readonly [P in keyof UserSettingsValue]: UserSettingsValue[P];
};

export interface UserSettings {
  /** Returns a read only (shallow) copy of the data */
  get value(): Readonly<UserSettingsValue>;

  /** Returns a mutable copy of @see value*/
  copy(): Mutable<UserSettingsValue>;

  /** Update the @see value, which also, synchronously causes subscribes to be called */
  set(value: UserSettingsValue): void;
  subscribe(next: (value: Readonly<UserSettingsValue>) => void): Subscription;
}

function userSettingsFactory(
  data: FunctionalCoreService,
  interpreter: CommandInterpreter,
): UserSettings {
  return {
    get value() {
      return { ...data.ambient.userData.userData } as const;
    },
    copy() {
      return { ...data.ambient.userData.userData };
    },
    set: (value: UserSettingsValue) => {
      interpreter.execute(new SetUserSettingsCommand(value));
    },
    subscribe(next: (value: Readonly<UserSettingsValue>) => void) {
      return data.ambient.userData.subscribeUserData(next);
    },
  };
}

export const UserSettingsInjector = new InjectionToken<UserSettings>(
  'usersettings',
  {
    factory() {
      const data = inject(FunctionalCoreService);
      const interpreter = inject(CommandInterpreter);

      return userSettingsFactory(data, interpreter);
    },
  },
);

export const userDataProvider: Provider = {
  provide: UserSettingsInjector,
  useFactory: userSettingsFactory,
  deps: [FunctionalCoreService, CommandInterpreter],
};
