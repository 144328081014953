<table>
  <tbody>
    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Name', 'Name')"
    >
      <td
        class="deta il-type"
        [textContent]="translate('DeliveryAddress_detail_title_Name', 'Name')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Name"
          name="DetailObjectName"
          type="text"
          [disabled]="notEnabled"
          ngModel
          required
          [(ngModel)]="detailObject.Name"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Name', 'Name')"
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Address1', 'Address1')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Address1', 'Address1')"
      ></td>
      <td class="detail-value">
        <div
          class="dropdown"
          ngbDropdown
          [open]="addressSuggestionsOpen"
          autoClose="outsideClick"
        >
          <input
            class="form-control detail-Address1"
            name="Address1"
            [formControl]="address1Control"
            type="text"
            [disabled]="notEnabled"
            ngModel
            required
            [(ngModel)]="detailObject.Address1"
            maxlength="50"
            autocomplete="off"
            (blur)="address1Blur()"
            (keydown)="address1Key($event)"
            [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Address1', 'Address1')"
          />
          <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
            <li
              role="menuitem"
              ngbDropdownItem
              *ngFor="let suggestion of addressSuggestions?.Suggestions; index as $index"
              (click)="useSuggestion(suggestion)"
              class="address-suggestion-item"
              [ngClass]="{'selected': selectedAddressSuggestion === $index}"
            >
              {{suggestion.SuggestedText}}
            </li>
          </ul>
        </div>
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Address2', 'Address2')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Address2', 'Address2')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Address2"
          name="Address2"
          type="text"
          maxlength="50"
          [disabled]="notEnabled"
          [(ngModel)]="detailObject.Address2"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Address2', 'Address2')"
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Floor', 'Floor')"
      *ngIf="showFloorRow"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Floor', 'Floor')"
      ></td>
      <td class="detail-value" *ngIf="showFloorEditor">
        <input
          class="form-control detail-Floor"
          name="Floor"
          [ngStyle]="{'border-color': showFloorEditorWarning ? 'red' : ''}"
          type="number"
          [disabled]="notEnabled"
          min="0"
          [(ngModel)]="detailObject.Floor"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Floor', 'Floor')"
        />
      </td>
      <td class="detail-value" *ngIf="!showFloorEditor">
        <span
          [textContent]="translate('DeliveryAddress_detail_floor_service_not_available', 'floor service not available for this postal code')"
        ></span>
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Country', 'Country')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Country', 'Country')"
      ></td>
      <td class="detail-value">
        <select
          class="form-control detail-Country"
          name="Country"
          ngModel
          required
          [disabled]="notEnabled"
          (ngModelChange)="countryChanged()"
          [(ngModel)]="detailObject.Country"
        >
          <option
            *ngFor="let country of countries"
            [ngValue]="country.Code"
            [textContent]="country.Name"
          ></option>
        </select>
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_PostalCode', 'PostalCode')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_PostalCode', 'PostalCode')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-PostalCode"
          name="PostalCode"
          type="text"
          ngModel
          required
          [disabled]="notEnabled"
          [(ngModel)]="detailObject.PostalCode"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_PostalCode', 'PostalCode')"
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_City', 'City')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_City', 'City')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Email"
          type="text"
          nam="City"
          [(ngModel)]="detailObject.City"
          ngModel
          required
          [disabled]="notEnabled"
          [attr.placeholder]="translate('Customer_detail_placeholder_City', 'City')"
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Phone', 'Phone')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Phone', 'Phone')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Phone"
          name="Phone"
          type="tel"
          [attr.pattern]="validPhoneRegex.source"
          ngModel
          required
          [(ngModel)]="detailObject.Phone"
          [disabled]="notEnabled"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Phone', 'Phone')"
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('DeliveryAddress_detail_title_Email', 'Email')"
    >
      <td
        class="detail-type"
        [textContent]="translate('DeliveryAddress_detail_title_Email', 'Email')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Email"
          name="Email"
          type="email"
          maxlength="50"
          [(ngModel)]="detailObject.Email"
          [required]="isEmailRequired"
          [disabled]="notEnabled"
          [attr.placeholder]="translate('DeliveryAddress_detail_placeholder_Email', 'Email')"
        />
      </td>
    </tr>
  </tbody>
</table>
