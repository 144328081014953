import { Injectable } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/CabinetSection';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Route } from 'app/ts/util/Route';
@Injectable({ providedIn: 'root' })
export class RouteService {
  public static readonly Name = 'routeService';

  private _lastCustomerSearchParams: CustomerSearchParams = {};

  public constructor(private data: FunctionalCoreService) {
    // TODO: It is rather unclear what they were used for and whether
    // we need something like this
    // $rootScope.$on("$routeChangeSuccess", this.routeChangeSuccess.bind(this));
    // $rootScope.$on("$routeUpdate", this.routeChangeSuccess.bind(this));
  }

  public get lastCustomerSearchParams(): CustomerSearchParams {
    return this._lastCustomerSearchParams;
  }

  public setCustomerIdForLastSearch(id: number) {
    if (
      this.data.ambient.clientSetting.clientSetting
        ?.showAllFloorplansOnEmptySearch
    )
      return;
    if (!this._lastCustomerSearchParams) return;

    this._lastCustomerSearchParams.customerId = id + '';
  }

  // TODO: Currently not used due to the event subscriptions above
  // being commented out
  private routeChangeSuccess(
    current: RouteService.RouteInfo,
    previous?: RouteService.RouteInfo,
  ): void {
    if (this.isOverviewRoute(current.$$route)) {
      this._lastCustomerSearchParams = ObjectHelper.copy(current.params);
    }
  }

  private isOverviewRoute(route?: Route): boolean {
    if (!route) return false;
    // TODO: We have no idea what en overview route is, so for now we
    // assume it is not an overview route
    return false;
    //return App.Config.overviewRoutes.map(r => r.path).indexOf(route.path) >= 0;
  }

  public static getEditorUrl(
    editorSection: Enums.EditorSection.FloorPlan,
    floorPlanId: number,
  ): EditorUrl;
  public static getEditorUrl(
    editorSection: Enums.EditorSection,
    floorPlanId: number,
    cabinetId: number,
    cabinetSectionId: number,
    configurationItemId?: number,
  ): EditorUrl;
  public static getEditorUrl(
    editorSection: Enums.EditorSection,
    floorPlanId: number,
    cabinetIndex?: number,
    cabinetSectionIndex?: number,
    configurationItemIndex?: number,
  ): EditorUrl {
    let prefix = '/floorplan/' + floorPlanId;
    if (editorSection === Enums.EditorSection.FloorPlan)
      return {
        url: prefix,
        fragment: '',
      };

    if (typeof cabinetIndex !== 'number')
      throw new Error('ArgumentException: cabinetIndex is not set');
    if (typeof cabinetSectionIndex !== 'number')
      throw new Error('ArgumentException: cabinetSectionIndex is not set');

    prefix += '/' + cabinetIndex! + '/' + cabinetSectionIndex! + '/';

    let url: string;

    if (editorSection === Enums.EditorSection.Backing) {
      url = prefix + 'backing';
    } else if (editorSection === Enums.EditorSection.Corpus) {
      url = prefix + 'corpus';
    } else if (editorSection === Enums.EditorSection.D3) {
      url = prefix + 'd3';
    } else if (editorSection === Enums.EditorSection.Doors) {
      url = prefix + 'doors';
    } else if (editorSection === Enums.EditorSection.PieceList) {
      url = prefix + 'piecelist';
    } else if (editorSection === Enums.EditorSection.Interior) {
      url = prefix + 'interior';
    } else if (editorSection === Enums.EditorSection.Swing) {
      url = prefix + 'swing';
    } else if (editorSection === Enums.EditorSection.SwingFlex) {
      url = prefix + 'swing-flex';
    } else if (editorSection === Enums.EditorSection.Validation) {
      url = prefix + 'validation';
    } else {
      throw new Error(
        'NotImplementedException: cannot create editor url for editorSection ' +
          editorSection,
      );
    }

    if (typeof configurationItemIndex === 'number') {
      return {
        url: url,
        fragment: configurationItemIndex.toString(),
      };
    }

    return {
      url: url,
      fragment: '',
    };
  }

  public static getValidEditors(
    section: Client.CabinetSection,
  ): Enums.EditorSection[] {
    let editorSections: Enums.EditorSection[] = [];

    switch (section.CabinetType) {
      case Interface_Enums.CabinetType.Partition:
        editorSections.push(Enums.EditorSection.Fillings);
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;

      case Interface_Enums.CabinetType.Doors:
        editorSections.push(Enums.EditorSection.Corpus);
        editorSections.push(Enums.EditorSection.Doors);
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;

      case Interface_Enums.CabinetType.WalkIn:
        editorSections.push(Enums.EditorSection.Corpus);
        editorSections.push(Enums.EditorSection.Interior);
        if (RouteService.isBackingEnabled(section)) {
          editorSections.push(Enums.EditorSection.Backing);
        }
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;

      case Interface_Enums.CabinetType.Swing:
        editorSections.push(Enums.EditorSection.Swing);
        editorSections.push(Enums.EditorSection.Interior);
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;

      case Interface_Enums.CabinetType.SwingFlex:
        editorSections.push(Enums.EditorSection.Corpus);
        editorSections.push(Enums.EditorSection.SwingFlex);
        editorSections.push(Enums.EditorSection.Interior);
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;

      case Interface_Enums.CabinetType.SharedItems:
        editorSections.push(Enums.EditorSection.PieceList);
        break;
      default:
        editorSections.push(Enums.EditorSection.Corpus);
        if (section.doors.availableProfiles.length > 0) {
          editorSections.push(Enums.EditorSection.Doors);
        }
        editorSections.push(Enums.EditorSection.Interior);
        if (RouteService.isBackingEnabled(section)) {
          editorSections.push(Enums.EditorSection.Backing);
        }
        editorSections.push(Enums.EditorSection.D3);
        editorSections.push(Enums.EditorSection.PieceList);
        break;
    }

    return editorSections;
  }

  private static isBackingEnabled(section: Client.CabinetSection): boolean {
    if (section.backing.availableProducts.count() > 0) return true;

    //solution might contain backing items from before backing was disabled.
    if (section.backing.backingType != Interface_Enums.BackingType.None)
      return true;
    return false;
  }
}

module RouteService {
  export interface RouteInfo {
    locals: any;
    params: any;
    pathParams: any;
    loadedTemplateUrl: string;
    $$route: Route;
  }
}

export interface EditorUrl {
  url: string;
  fragment: string;
}
