import { Injectable } from '@angular/core';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  /**
   *
   */
  constructor() {}

  private lastSearch: CustomerSearchParams = {};

  public getLastSearch(): CustomerSearchParams {
    return this.lastSearch;
  }

  public setLastSearch(newSearch: CustomerSearchParams) {
    this.lastSearch = newSearch;
  }

  private selectionStart: number = 0;
  private selectionEnd: number = 0;
  public getCaratPosition(): [number, number] {
    return [this.selectionStart, this.selectionEnd];
  }

  public setCaratPosition(selectionStart: number, selectionEnd: number) {
    this.selectionStart = selectionStart;
    this.selectionEnd = selectionEnd;
  }
}
