<div class="container-fluid">
  <div class="row">
    <div class="col p-0">
      <partition-properties
        [name]="name"
        [section]="section"
        (nameChange)="nameChange.emit($event)"
        (deletePartition)="deletePartition.emit()"
      ></partition-properties>
    </div>
  </div>
  <div class="row">
    <div class="col p-0">
      <section-properties
        [floorPlan]="floorPlan"
        [partitions]="
          partitionQueryService.partitionPlan$
            | async
            | calculatePlan: floorPlan
        "
        [section]="section"
        (widthChange)="sectionWidthChange.emit($event)"
        [numberOfModules]="section.numberOfModules"
        (numberOfModulesChange)="numberOfModulesChange.emit($event)"
        (deleteSection)="deleteSection.emit($event)"
        (rotateSectionLeft)="rotateSectionLeft.emit()"
        (rotateSectionRight)="rotateSectionRight.emit()"
        (reverseSection)="reverseSection.emit()"
        [unevenLeftWall]="section.unevenLeftWall"
        [unevenRightWall]="section.unevenRightWall"
        (unevenLeftWallChange)="unevenLeftWall.emit($event)"
        (unevenRightWallChange)="unevenRightWall.emit($event)"
        (distanceToLeftWallChange)="distanceToLeftWallChange.emit($event)"
        (distanceToRightWallChange)="distanceToRightWallChange.emit($event)"
        (distanceToTopWallChange)="distanceToTopWallChange.emit($event)"
        (distanceToBottomWallChange)="distanceToBottomWallChange.emit($event)"
      ></section-properties>
    </div>
  </div>
  <div class="row">
    <div class="col p-0">
      <module-properties></module-properties>
    </div>
  </div>
</div>
