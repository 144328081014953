import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-save-floor-plan-vm',
  templateUrl: './consumerSaveFloorPlan.html',
})
export class ConsumerSaveFloorPlanVm extends BaseVm implements OnInit {
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public suggestedName!: string;

  ngOnInit() {
    if (this.suggestedName) {
      this.name = this.suggestedName;
    }

    let nameInputField = document.getElementById(
      'floorplan-name',
    ) as HTMLInputElement;
    if (nameInputField) {
      nameInputField.select();
    }
  }

  public name = '';

  public close() {
    this.activeModal.dismiss('user cancelled');
  }

  public save() {
    if (!this.name) return;
    this.activeModal.close(this.name);
  }
}
