export class DateHelper {
  public static earliestSaneDate: Date = new Date(0); //1970-1-1
  public static latestSaneDate = new Date(2098, 11, 30); //2098-dec-30

  public static GetIsoWeek(date: Date): DateHelper.WeekYear {
    // Adapted from:
    // http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html

    let dayTarget = new Date(date.valueOf());
    let thisWeekDay = (date.getDay() + 6) % 7;

    //Find thursday in this week
    dayTarget.setDate(dayTarget.getDate() - thisWeekDay + 3);

    let epochVal = dayTarget.valueOf();

    //set to jan 1st
    dayTarget.setMonth(0, 1);

    //then set to first thursday in year
    if (dayTarget.getDay() !== 4)
      dayTarget.setMonth(0, 1 + ((4 - dayTarget.getDay() + 7) % 7));

    let weekNumber =
      1 +
      Math.ceil((epochVal - dayTarget.valueOf()) / (7 * 24 * 60 * 60 * 1000));

    let yearTarget = new Date(date.valueOf());
    yearTarget.setDate(yearTarget.getDate() - ((date.getDay() + 6) % 7) + 3);

    return {
      weekNumber: weekNumber,
      year: yearTarget.getFullYear(),
    };
  }

  public static fromIsoString(str: string): Date {
    if (str[str.length - 6] !== '+' && str[str.length - 6] !== '-') {
      //don't fuck with timezones
      if (str[str.length - 1] !== 'Z') {
        str = str + 'Z';
      }
    }
    return new Date(Date.parse(str));
  }

  public static toIsoString(date: Date): string {
    return (
      date.getUTCFullYear() +
      '-' +
      DateHelper.pad(date.getUTCMonth() + 1) +
      '-' +
      DateHelper.pad(date.getUTCDate()) +
      'T' +
      DateHelper.pad(date.getUTCHours()) +
      ':' +
      DateHelper.pad(date.getUTCMinutes()) +
      ':' +
      DateHelper.pad(date.getUTCSeconds()) +
      '.' +
      (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
    );
  }

  private static pad(number: number): string {
    if (number < 10) return '0' + number;
    return number.toString();
  }

  public static maxDate(
    firstIsoDateString: string,
    ...isoDateStrings: string[]
  ): string {
    for (let d of isoDateStrings) {
      if (d > firstIsoDateString) {
        firstIsoDateString = d;
      }
    }
    return firstIsoDateString;
  }
}

export module DateHelper {
  export class WeekYear {
    public weekNumber: number = -1;
    public year: number = -1;
  }
}
