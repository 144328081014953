import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { DoorHelper } from 'app/ts/util/DoorHelper';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
import { SalesChainSettingHelper } from 'app/ts/util/SalesChainSettingHelper';
import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';

export class DoorValidationService {
  public static readonly Name = 'doorValidationService';

  constructor() {}

  public accessTest() {
    return 'Door Validation Service access test function';
  }

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    if (
      section.CabinetType === Interface_Enums.CabinetType.Swing ||
      section.CabinetType === Interface_Enums.CabinetType.SwingFlex ||
      section.CabinetType === Interface_Enums.CabinetType.SharedItems
    ) {
      return errorInfos;
    }

    errorInfos.push(
      ...DoorValidationService.validateDoorsOnSingleTrack(section),
    );
    errorInfos.push(...DoorValidationService.validateOnlyDoors(section));
    errorInfos.push(...DoorValidationService.validateRailDepth(section));
    errorInfos.push(...DoorValidationService.validateDoorCount(section));
    errorInfos.push(...DoorValidationService.validateDoorHeight(section));
    errorInfos.push(...DoorValidationService.validateDoorType(section));
    errorInfos.push(...DoorValidationService.validateSpaceBehindDoors(section));
    errorInfos.push(...DoorValidationService.validateProducts(section));
    errorInfos.push(...DoorValidationService.validateMaterials(section));
    errorInfos.push(
      ...DoorValidationService.validateDoorFillingMaterials(section),
    );
    errorInfos.push(...DoorValidationService.validateOnlyDoor(section));
    errorInfos.push(...DoorValidationService.validateFillingSizes(section));
    errorInfos.push(...DoorValidationService.validateMaxDoorHeight(section));
    errorInfos.push(
      ...DoorValidationService.validateWarnOnVerticalBars(section),
    );
    errorInfos.push(
      ...DoorValidationService.validateGripsCollidingWithBars(section),
    );
    errorInfos.push(...DoorValidationService.validateFixedBars(section));

    return errorInfos;
  }
  static validateFixedBars(section: Client.CabinetSection): Client.ErrorInfo[] {
    //if more than one filling is required, the door must not have loose bars.

    for (let door of section.doors.doors) {
      if (door.numberOfFillingsMin < 2) continue;
      if (
        door.bars.some((bar) => bar.barType === Interface_Enums.BarType.Loose)
      ) {
        return [
          new Client.ErrorInfo(
            Enums.EditorSection.Doors,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validation_doors_requiring_multiple_fillings_has_loose_bars_short',
              'Invalid filling type',
            ),
            new Client.Translatable(
              'validation_doors_requiring_multiple_fillings_has_loose_bars_long',
              'The selected filling height is not possible for this door.',
            ),
            section,
          ),
        ];
      }
    }
    return [];
  }

  static validateWarnOnVerticalBars(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (!section.doors.profile) return result;
    if (!section.doors.doorData) return result;
    if (!section.doors.doorData.WarnOnVerticalBars) return result;
    if (
      !section.doors.doors.some(
        (door) =>
          !!door.verticalBarOption &&
          door.verticalBarOption.Number != VariantNumbers.Values.NoVerticalBars,
      )
    )
      return result;

    result.push(
      new Client.ErrorInfo(
        Enums.EditorSection.Doors,
        Interface_Enums.ErrorLevel.Warning,
        new Client.Translatable(
          'validation_doors_with_vertical_bars_' +
            section.doors.profile.ProductNo +
            '_short',
          'Doors with vertical bars',
        ),
        new Client.Translatable(
          'validation_doors_with_vertical_bars_' +
            section.doors.profile.ProductNo +
            '_long',
          'Due to a recent change in production methods, the final product may be different from what is shown.',
        ),
        section,
      ),
    );

    return result;
  }

  private static validateOnlyDoor(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    if (section.doors.onlyDoor) {
      return [
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Info,
          'Doors without rails',
          'The doors are delivered without top and bottom rails. Please make sure that the door dimensions are correct and will fit in the existing cabinet.',
          section,
        ),
      ];
    } else {
      return [];
    }
  }

  /**
   * Validates if door(s) on single track can open fully
   */
  public static validateDoorsOnSingleTrack(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (section.doors.numberOfRailTracks === 1 && !section.doors.onlyDoor) {
      let leftDoor = section.doors.doors[0];
      let rightDoor = section.doors.doors[1];

      if (leftDoor) {
        let noSpace: boolean =
          leftDoor.width > section.doors.extraRailWidthLeft;

        if (noSpace && !rightDoor) {
          noSpace = leftDoor.width > section.doors.actualExtraRailWidthRight;
        }

        if (noSpace) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.Doors,
              Interface_Enums.ErrorLevel.Warning,
              'Door can not open fully',
              'The door may be too wide for it to open fully',
              section,
            ),
          );
        }
      }

      if (
        rightDoor &&
        rightDoor.width > section.doors.actualExtraRailWidthRight
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Doors,
            Interface_Enums.ErrorLevel.Warning,
            'Door can not open fully',
            'The door may be too wide for it to open fully',
            section,
          ),
        );
      }
    }

    return result;
  }

  /**
   * Checks if door(s) without rails is selected, and adds an information if so.
   */
  public static validateOnlyDoors(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (
      SalesChainSettingHelper.forceOnlyDoors(section.editorAssets) &&
      section.CabinetType === Interface_Enums.CabinetType.Doors
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Info,
          'No rails selected',
          'Door(s) without rails selected',
          section,
        ),
      );
    }
    return result;
  }

  /**
   * Validates railset depth in relation to corpus panels.
   */
  public static validateRailDepth(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // The following was removed, because now corpus may be added to CabinetType.Doors
    // If there is only doors, we are in the clear
    //if (section.CabinetType === Interface_Enums.CabinetType.Doors)
    //    return result;

    if (section.doors.profile && section.doors.railSet) {
      let minRailDepth = CabinetSectionHelper.getMaximumRailSetDepth(section);

      if (minRailDepth > CabinetSectionHelper.getMinSidePanelDepth(section)) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Doors,
            Interface_Enums.ErrorLevel.Warning,
            'Railset depth',
            'The railset depth is bigger than the depth of one or more corpus side panels',
            section,
          ),
        );
      }

      if (
        minRailDepth > CabinetSectionHelper.getMinTopBottomPanelDepth(section)
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Doors,
            Interface_Enums.ErrorLevel.Critical,
            'Railset depth',
            'The railset depth is bigger than the depth of top and/or bottom panel',
            section,
          ),
        );
      }
    }
    return result;
  }

  /**
   * Validates the number of doors.
   */
  public static validateDoorCount(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (section.doors.profile && section.NumberOfDoors == 0)
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Critical,
          'Number of doors not valid.',
          'The selected doortype does not allow zero doors. Please select another doortype.',
          section,
        ),
      );
    return result;
  }

  /**
   * Validates the height of the door(s)
   */
  public static validateDoorHeight(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // If the door's height is not flexible, it must be the same as the product max height.
    if (
      section.doors.profile &&
      !ProductHelper.isFlexHeight(section.doors.profile, undefined) &&
      section.doors.height != ProductHelper.maxHeight(section.doors.profile)
    )
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Critical,
          'Door height not supported',
          'The selected doortype does not support the current height.',
          section,
        ),
      );
    return result;
  }

  /**
   * Validates the type of door(s)
   */
  public static validateDoorType(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (!section.doors.profile) return result;

    let doorProductId = section.doors.profile.Id;

    if (
      section.doors.pickableProfiles
        .filter((p) => p.disabledReason !== null)
        .some(
          (dp) =>
            dp !== null && dp.item !== null && dp.item.Id === doorProductId,
        )
    )
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Critical,
          'Doortype disabled',
          'The selected doortype is no longer valid. Please select another doortype.',
          section,
        ),
      );
    return result;
  }

  /**
   * Validates the space behind the doors (space between doors and interior)
   */
  public static validateSpaceBehindDoors(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // If there is only doors, we are in the clear
    if (section.CabinetType === Interface_Enums.CabinetType.Doors)
      return result;

    // If there are no doors or railsets, we are in the clear
    if (
      section.doors.profile == null ||
      section.doors.profile.isNone ||
      section.doors.railSet == null
    )
      return result;

    // Check the available space
    let spaceForRail =
      CabinetSectionHelper.getDifferenceBetweenSectionDepthAndFrontOfInterior(
        section,
      ) - CabinetSectionHelper.getTopRailDistanceFromFront(section);
    let railDepth = RailSetHelper.minimumDepth(
      section.doors.railSet,
      section.cabinet.ProductLineId,
    );
    let spaceBehindDoors = spaceForRail - railDepth;
    if (spaceBehindDoors < Constants.minSpaceBehindDoors)
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Warning,
          'Space behind doors',
          'Very little space between doors and interior',
          section,
        ),
      );
    return result;
  }

  /**
   * Validates the product for all door and rail items
   */
  private static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let items: Client.ConfigurationItem[] = section.doors.doorItems.concat(
      section.doors.railItems,
    );
    return ItemValidationService.validateProducts(
      items,
      Enums.EditorSection.Doors,
      section,
    );
  }

  /**
   * Validates the material for all door and rail items
   */
  private static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let items: Client.ConfigurationItem[] = section.doors.doorItems.concat(
      section.doors.railItems,
    );
    return ItemValidationService.validateMaterials(
      items,
      Enums.EditorSection.Doors,
      section,
    );
  }

  private static validateDoorFillingMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let fullCatalog =
      section.editorAssets.fullCatalog &&
      section.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
    let profile = section.doors.profile;
    return Enumerable.from(section.doors.doors)
      .selectMany((door) => door.fillings)
      .select((filling) => filling.material)
      .where((material) => !!material)
      .cast<Client.ProductMaterial>()
      .distinct((mat) => mat.Id)
      .where((mat) => mat.isDiscontinued || (!fullCatalog && mat.isOverride))
      .select(
        (discontinuedMat) =>
          new Client.ErrorInfo(
            Enums.EditorSection.Doors,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_door_discontinued_filling_short',
              'Door filling discontinued',
              discontinuedMat.DefaultName,
              discontinuedMat.Number,
              profile ? profile.Name : ' -- ',
              profile ? profile.ProductNo : '?',
            ),
            new Client.Translatable(
              'validate_door_discontinued_filling_long',
              'The filling "{0}" ({1}) is discontinued for the door "{2}" ({3})',
              discontinuedMat.DefaultName,
              discontinuedMat.Number,
              profile ? profile.Name : ' -- ',
              profile ? profile.ProductNo : '?',
            ),
            section,
          ),
      )
      .toArray();
  }

  static validateMaxDoorHeight(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    let profile = section.doors.profile;
    if (!profile) return result;
    let doordata = profile.getProductData(
      section.doors.profileMaterial
        ? section.doors.profileMaterial.Id
        : undefined,
    );
    if (!doordata) doordata = profile.getProductData();
    if (!doordata) return result;
    let firstDoor = section.doors.doors[0];
    if (!firstDoor) return result;

    if (
      firstDoor.height > doordata.MaxHeight ||
      firstDoor.height < doordata.MinHeight
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Doors,
          Interface_Enums.ErrorLevel.Critical,
          new Client.Translatable(
            'validate_door_height_unsupported_short',
            'The selected door does not support the cabinet height',
          ),
          new Client.Translatable(
            'validate_door_height_unsupported_long',
            'The selected door does not support the cabinet height',
          ),
          section,
        ),
      );
    }
    return result;
  }

  /**
   * Checks if a door filling fits in a door
   * @param material The material to check for filling size
   * @param doorWidth The width of the filling (well, actually the door width...)
   * @param height (Optional) The height of the filling
   *
   * NOTE: There is some DRY-violation in this code. See MaterialHelper.supportsFillingSize.
   */
  private static fillingFits(
    filling: Client.DoorFilling,
    doorWidth: number,
    height?: number,
  ): boolean {
    let material = filling.material;
    if (material == null) return false;

    if (
      filling.isDesignFilling &&
      (material.designType == Interface_Enums.MaterialDesignType.Design ||
        material.designType ==
          Interface_Enums.MaterialDesignType.DesignAndNormal)
    ) {
      return true;
    }

    if (
      material.MaxFillingWidth >= doorWidth &&
      (!height || material.MaxFillingHeight >= height)
    ) {
      return true;
    }

    if (material.Rotatable && material.MaxFillingHeight >= doorWidth) {
      const fitsIfRotated = !height || material.MaxFillingWidth >= height;

      return fitsIfRotated;
    }

    return false;
  }

  private static validateFillingSizes(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    for (let door of section.doors.doors) {
      for (let filling of door.fillings) {
        if (filling.material) {
          const actualFillingHeight = door.mayForceFixedBars
            ? filling.height
            : door.height - DoorHelper.getFrameDeductionForFilling(door, true);

          if (!this.fillingFits(filling, door.width, actualFillingHeight)) {
            result.push(
              new Client.ErrorInfo(
                Enums.EditorSection.Doors,
                Interface_Enums.ErrorLevel.Critical,
                new Client.Translatable(
                  'validate_door_filling_dimension_error_short',
                  'Filling material not valid.',
                  filling.material.DefaultName,
                  door.index + '',
                  filling.index + '',
                ),
                new Client.Translatable(
                  'validate_door_filling_dimension_error_long',
                  "The material '{0}' ({1}) does not fit on door {2}. The door is too large. Use a smaller door or choose another material.",
                  filling.material.DefaultName,
                  filling.material.Number,
                  door.index + '',
                  filling.index + '',
                ),
                section,
              ),
            );
            break; //Don't report more errors on this door
          }
        }
      }
    }

    return result;
  }

  private static validateGripsCollidingWithBars(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    for (let door of section.doors.doors) {
      for (let bar of door.bars) {
        for (let grip of [...door.gripItemsFront, ...door.gripItemsBack]) {
          if (GeometryHelper.cubesOverlap(bar.cube, grip)) {
            result.push(
              new Client.ErrorInfo(
                Enums.EditorSection.Doors,
                Interface_Enums.ErrorLevel.Critical,
                'Bars collide with grip',
                'Bars are in the way of the grips',
                section,
              ),
            );
            break;
          }
        }
      }
    }
    return result;
  }
}
