import { ModuleId, ModuleType } from './module';
import { ProfileId } from './profile';
import { RailId } from './rail';

export class Section {
  constructor(
    id: SectionId,
    posX: number,
    posY: number,
    width: number,
    height: number,
    barHeight: number,
    rotation: number,
    rails: RailId[],
    references: SectionIdReferences,
    unevenLeftWall: boolean,
    unevenRightWall: boolean,
  ) {
    this._data = {
      id,
      posX,
      posY,
      width,
      height,
      barHeight,
      modules: [],
      rails,
      rotation,
      references,

      unevenLeftWall,
      unevenRightWall,
    };
  }

  private _data: SectionData;
  public get data(): SectionData {
    return this._data;
  }

  public get id(): SectionId {
    return this.data.id;
  }

  public get posX() {
    return this.data.posX;
  }

  public set posX(pos: number) {
    this.data.posX = pos;
  }

  public get posY() {
    return this.data.posY;
  }

  public set posY(pos: number) {
    this.data.posY = pos;
  }

  public get width() {
    return this.data.width;
  }
  public set width(width: number) {
    this.data.width = width;
  }

  public get height(): number {
    return this.data.height;
  }

  public set height(val: number) {
    this.data.height = val;
  }

  /** In degrees */
  public get rotation(): number {
    return this.data.rotation;
  }

  public set rotation(value: number) {
    this.data.rotation = value;
  }

  public get unevenLeftWall(): boolean {
    return this.data.unevenLeftWall;
  }

  public set unevenLeftWall(value: boolean) {
    this.data.unevenLeftWall = value;
  }

  public get unevenRightWall(): boolean {
    return this.data.unevenRightWall;
  }

  public set unevenRightWall(value: boolean) {
    this.data.unevenRightWall = value;
  }

  public get references(): SectionIdReferences {
    return this.data.references;
  }

  public get isHorizontal(): boolean {
    return this.rotation == 0 || this.rotation == 180;
  }

  public get isVertical(): boolean {
    return this.rotation == 90 || this.rotation == 270;
  }

  public get isInverted(): boolean {
    return this.rotation == 180 || this.rotation == 270;
  }

  /**
   * Total height in mm
   */
  public get barHeight(): number {
    return this.data.barHeight;
  }

  public set barHeight(value: number) {
    this.data.barHeight = value;
  }

  public get doorRailSetId(): number {
    return this.data.references.doorRailSetId;
  }

  public set doorRailSetId(value: number) {
    this.data.references.doorRailSetId = value;
  }

  public get numberOfModules(): number {
    return this.data.modules.length;
  }

  public get modules(): ModuleId[] {
    return this.data.modules;
  }

  public get rails(): RailId[] {
    return this.data.rails;
  }

  //Used to highlight in floorplan
  public hasError: boolean = false;

  public addWall(wallId: ModuleId) {
    this.data.modules.push(wallId);
  }

  public removeWall(wallId: ModuleId) {
    this.data.modules = this.data.modules.filter((wl) => wl != wallId);
  }

  public addDoor(doorId: ModuleId) {
    this.data.modules.push(doorId);
  }

  public addModule(moduleId: ModuleId) {
    this.data.modules.push(moduleId);
  }

  public removeDoor(doorId: ModuleId) {
    this.data.modules = this.data.modules.filter((dr) => dr != doorId);
  }

  public clearRails() {
    this.data.rails = [];
  }

  //#region static variables
  public static get spacingFromCeilingToTopModuleFrame(): number {
    return 27; // Read from 'partition system subtract.pdf'
  }

  public static spacingFromFloorToBottomModuleFrame(
    moduleType: ModuleType,
  ): number {
    switch (moduleType) {
      case ModuleType.Wall:
        return 10; // Read from 'partition system subtract.pdf'
      case ModuleType.Door:
        return 13; // Read from 'partition system subtract.pdf'
    }
  }
  //#endregion
}

export interface SectionData {
  id: SectionId;
  posX: number;
  posY: number;
  width: number;
  height: number;
  rotation: number;

  unevenLeftWall: boolean;
  unevenRightWall: boolean;

  barHeight: number;
  modules: ModuleId[];
  rails: RailId[];

  references: SectionIdReferences;
}

interface SectionIdReferences {
  profileMaterialId: number;
  wallRailSetId: number;
  doorRailSetId: number;
  leftNicheProfileId?: ProfileId;
  rightNicheProfileId?: ProfileId;
}

export type SectionId = number & { _sectionIds: never };
