import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  templateUrl: 'userMessage.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class UserMessageVm extends BasicModalVm<void> {
  constructor(baseVmService: BaseVmService, activeModal: NgbActiveModal) {
    super(baseVmService, activeModal);
  }

  public readonly userMessage!: Interface_DTO.UserMessage;
}
