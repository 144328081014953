import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { EditorSection } from 'app/ts/clientDto/Enums';
import { EditorUrl, RouteService } from 'app/ts/services/RouteService';
import { ITranslationService } from '../services/TranslationService';
export class ErrorInfo {
  public readonly shortDescription: string;
  public readonly longDescription: string;
  public readonly originalLongKey: string;

  public constructor(
    public readonly editorSection: EditorSection,
    public readonly level: Interface_Enums.ErrorLevel,
    shortDescription: Client.Translatable | string,
    longDescription: Client.Translatable | string,
    public readonly cabinetSection:
      | Client.CabinetSection
      | {
          FloorPlanId: number;
          CabinetIndex: number;
          CabinetSectionIndex: number;
        },
    public readonly configurationItem?: Client.ConfigurationItem,
    public readonly transService?: ITranslationService,
  ) {
    let translationService: ITranslationService | null = null;

    this.shortDescription = '';
    this.longDescription = '';
    this.originalLongKey = '';

    if (cabinetSection instanceof Client.CabinetSection) {
      if (transService) {
        translationService = transService;
      } else {
        translationService = cabinetSection.editorAssets.translationService;
      }

      if (shortDescription instanceof Client.Translatable) {
        this.shortDescription = translationService.translate(shortDescription);
      } else {
        this.shortDescription = translationService.translate(
          'val_err_short_' + shortDescription,
          shortDescription,
        );
      }

      if (longDescription instanceof Client.Translatable) {
        this.longDescription = translationService.translate(longDescription);
        this.originalLongKey = longDescription.key;
      } else {
        this.originalLongKey = 'val_err_full_' + longDescription;
        this.longDescription = translationService.translate(
          this.originalLongKey,
          longDescription,
        );
      }
    } else {
      if (transService) {
        translationService = transService;
      } else {
        translationService = null;
      }

      if (shortDescription instanceof Client.Translatable) {
        if (translationService != null) {
          this.shortDescription =
            translationService.translate(shortDescription);
        }
      } else {
        if (translationService != null) {
          this.shortDescription = translationService.translate(
            'val_err_short_' + shortDescription,
            shortDescription,
          );
        } else {
          this.shortDescription = shortDescription;
        }
      }

      if (longDescription instanceof Client.Translatable) {
        if (translationService != null) {
          this.longDescription = translationService.translate(longDescription);
          this.originalLongKey = longDescription.key;
        }
      } else {
        if (translationService != null) {
          this.originalLongKey = 'val_err_full_' + longDescription;
          this.longDescription = translationService.translate(
            this.originalLongKey,
            longDescription,
          );
        } else {
          this.originalLongKey = 'val_err_full_' + longDescription;
          this.longDescription = longDescription;
        }
      }
    }
  }

  public get section() {
    if (this.cabinetSection instanceof Client.CabinetSection) {
      return this.cabinetSection.Name;
    } else {
      return 'Section ' + this.cabinetSection.CabinetIndex;
    }
  }

  public get url(): EditorUrl | null {
    if (this.cabinetSection instanceof Client.CabinetSection) {
      if (this.cabinetSection.cabinet.floorPlan.Id === null) return null;
      let ciId: number | undefined = this.configurationItem
        ? this.configurationItem.ConfigurationItemIndex
        : undefined;
      return RouteService.getEditorUrl(
        this.editorSection,
        this.cabinetSection.cabinet.floorPlan.Id!,
        this.cabinetSection.CabinetIndex,
        this.cabinetSection.CabinetSectionIndex,
        ciId,
      );
    } else {
      let ciId: number | undefined = this.configurationItem
        ? this.configurationItem.ConfigurationItemIndex
        : undefined;
      return RouteService.getEditorUrl(
        this.editorSection,
        this.cabinetSection.FloorPlanId,
        this.cabinetSection.CabinetIndex,
        this.cabinetSection.CabinetSectionIndex,
        ciId,
      );
    }
  }
}
