import { inject } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { Command } from 'app/imperative-shell';
import { HttpPost } from 'app/imperative-shell/http-post.command';
import { StoreSettingData } from './StoreSetting';

export class SetStoreSetting implements Command {
  constructor(private storeSetting: StoreSettingData) {}

  public execute(): Promise<Command[]> {
    const data = inject(FunctionalCoreService);

    data.ambient.storeData.setStoreSetting(this.storeSetting);

    // TODO: The url should probably come from somewhere else?
    // Or maybe it makes perfect sense that it resides in the command?
    const postCmd = new HttpPost('api/store/storeData', this.storeSetting);
    return Promise.resolve([postCmd]);
  }
}
