<div class="modal-content accept-delivery-info">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('modal_confirm_order_title_{0}', 'Confirm Order: {0}', name)"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6" *ngIf="deliveryAddress">
          <h5
            [textContent]="translate('modal_confirm_order_address_header', 'Delivery Address')"
          ></h5>
          <ul>
            <li
              style="list-style: none"
              [textContent]="deliveryAddress.Name"
            ></li>
            <li
              style="list-style: none"
              [textContent]="deliveryAddress.Address1"
            ></li>
            <li
              style="list-style: none"
              [textContent]="deliveryAddress.Address2"
            ></li>
            <li
              style="list-style: none"
              [textContent]="translate('address_city_short', '{2}-{0}, {1}', deliveryAddress.PostalCode, deliveryAddress.City, deliveryAddress.Country)"
            ></li>
            <li
              style="list-style: none"
              [textContent]="deliveryAddress.Email"
            ></li>
            <li
              style="list-style: none"
              [textContent]="deliveryAddress.Phone"
            ></li>
          </ul>
          <div class="row" *ngIf="!isAddressValid">
            <div class="col-md-12">
              <div class="alert alert-warning">
                <label>
                  <span
                    [textContent]="translate('modal_confirm_order_invalid_address_warning','Warning: the delivery address is invalid. Please correct the delivery address or check here to accept the address:')"
                  ></span>
                  <input
                    type="checkbox"
                    [(ngModel)]="isInvalidAddressAccepted"
                    name="ChekAddress"
                  />
                </label>
              </div>
            </div>
          </div>

          <span
            [textContent]="translate('order_warning_delivery_floor_0', 'We deliver to ground floor, no carry up.')"
            *ngIf="deliveryAddress.Floor === 0"
          ></span>
          <span
            [textContent]="translate('order_warning_delivery_floor_x', 'We deliver to floor {0}, including carry up.', deliveryAddress.Floor)"
            *ngIf="deliveryAddress.Floor !== 0"
          ></span>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-8 label-col"
              [textContent]="translate('modal_confirm_order_delivery_week', 'Delivery week')"
            ></div>
            <div
              class="col-md-4 value-col"
              [ngStyle]="{'font-weight': wasDeliveryWeekChanged ? '800' : '400'}"
              [textContent]="deliveryWeek"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-md-8 label-col"
              [textContent]="translate('modal_confirm_order_delivery_days', 'Delivery days')"
            ></div>
            <div class="col-md-4 value-col" [textContent]="deliveryDays"></div>
          </div>
          <div class="row">
            <div
              class="col-md-8 label-col"
              [textContent]="translate('modal_confirm_order_correction_deadline', 'Correction Deadline')"
            ></div>
            <div
              class="col-md-4 value-col"
              [textContent]="correctionDeadlineDate"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-md-8 label-col"
              [textContent]="translate('modal_confirm_order_freight_price', 'Freight price')"
            ></div>
            <div class="col-md-4 value-col" [textContent]="freightPrice"></div>
          </div>
          <div class="row grand-total-row">
            <div
              class="col-md-8 label-col"
              [textContent]="translate('modal_confirm_order_total_price', 'Grand Total')"
            ></div>
            <div class="col-md-4 value-col" [textContent]="totalPrice"></div>
          </div>

          <div class="row">
            <div class="col-md-12" style="margin-top: 35px">
              <span
                [textContent]="translate('modal_confirm_order_additional_text', 'Note: The delivery date is subject to change. The customer will receive a text message with final date of delivery')"
              ></span>
            </div>
          </div>
          <div
            class="row modal-warning-note"
            *ngIf="showExternalChangesWarning"
          >
            <div class="col-md-12">
              <div
                class="alert alert-warning"
                style="margin-bottom: 0"
                [textContent]="translate('modal_confirm_order_override_external_changes_warning', 'Warning: This order has been changed by KA. Those changes are not visible here, but will be overridden if you re-commit this order.')"
              ></div>
            </div>
          </div>
          <div class="row modal-warning-note" *ngIf="wasDeliveryWeekChanged">
            <div class="col-md-12">
              <div
                class="alert alert-warning"
                style="margin-bottom: 0"
                [textContent]="translate('modal_confirm_order_delivery_week_changed', 'Warning: The requested delivery week is no longer possible.')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-group btn-group-right">
      <button
        class="btn btn-success"
        type="submit"
        #focus
        [ngClass]="{'disabled':!isValid}"
        (click)="save()"
        [textContent]="translate('button_order', 'Confirm')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
