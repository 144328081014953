<div class="modal-content">
  <div class="modal-header">
    <table>
      <tr>
        <button type="button" class="close" (click)="cancel()">&times;</button>
      </tr>
      <tr>
        <h3 class="modal-title" [textContent]="DialogTitle()"></h3>
      </tr>
    </table>
  </div>

  <div class="modal-body print-dialog-modal">
    <div class="radio">
      <label>
        <input
          type="radio"
          [(ngModel)]="printTypeString"
          value="1"
          name="OptradioOffer"
        />
        <span [textContent]="translate('print_dialog_Offer', 'Offer')"></span>
      </label>
    </div>
    <div class="radio">
      <label>
        <input
          type="radio"
          [(ngModel)]="printTypeString"
          value="2"
          name="OptradioConf"
        />
        <span
          [textContent]="translate('print_dialog_Order_confirmation', 'Order confirmation')"
        ></span>
      </label>
    </div>
    <div class="radio">
      <label>
        <input
          type="radio"
          [(ngModel)]="printTypeString"
          value="0"
          name="OptradioOrderConf"
        />
        <span
          [textContent]="translate('print_dialog_No_quotation/orderconfirmation', 'No quotation/orderconfirmation')"
        ></span>
      </label>
    </div>

    <div *ngIf="allowItemsWithoutPrice || allowItemsWithoutPrice">
      <span [textContent]="translate('print_dialog_Prices', 'Prices:')"></span>
      <div class="checkbox" *ngIf="allowItemsWithPrice">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.ItemsWithPrice"
            name="CheckPrices"
          />
          <span
            [textContent]="translate('print_dialog_Items_with_prices', 'Items with prices')"
          ></span>
        </label>
      </div>
      <div class="checkbox" *ngIf="allowItemsWithoutPrice">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.ItemsWithoutPrice"
            name="CheckNoPrices"
          />
          <span
            [textContent]="translate('print_dialog_Items_without_prices', 'Items without prices')"
          ></span>
        </label>
      </div>
    </div>

    <div>
      <span [textContent]="translate('print_dialog_Images', 'Images:')"></span>

      <div class="checkbox" *ngFor="let subject of visibleSubjects">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="checkedSubjects[subject.subject]"
            name="CheckSubjName"
          />
          <span [textContent]="subject.name"></span>
        </label>
      </div>
    </div>

    <div>
      <label>{{translate('print_dialog_Terms', 'Terms')}}</label>
      <div class="checkbox">
        <label
          ><input
            type="checkbox"
            [(ngModel)]="options.ShowTerms"
            name="CheckTerms"
          />{{translate('print_dialog_Include_terms', 'Include terms')}}</label
        >
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="ok()"
      [ngClass]="{'disabled': !okPossible}"
    >
      {{translate('modal_button_print', 'print')}}
    </button>

    <button type="button" class="btn btn-secondary" (click)="cancel()">
      {{translate('modal_button_cancel', 'cancel')}}
    </button>
  </div>
</div>
