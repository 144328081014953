import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/ts/Constants';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
export abstract class BasicModalVm<T> extends BaseVm {
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public close(arg: T) {
    this.activeModal.close(arg);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }
}
