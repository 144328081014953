import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import * as Client from 'app/ts/clientDto/index';

@Injectable({
  providedIn: 'root',
})
export class CabinetSectionService {
  constructor(private floorPlans: FloorPlanService) {
    this.init();
  }

  private async init(): Promise<void> {
    const floorplan = await this.floorPlans.getCurrentConsumerFloorPlan();
    for (const cabinet of floorplan.actualCabinets) {
      if (cabinet.CabinetIndex !== 1) continue;
      for (const section of cabinet.actualCabinetSections) {
        if (section.CabinetSectionIndex !== 1) continue;
        this._cabinetSection$.next(section);
      }
    }
  }

  private _cabinetSection$ = new BehaviorSubject<
    Client.CabinetSection | undefined
  >(undefined);

  public get cabinetSection$(): Observable<Client.CabinetSection> {
    const filtered = this._cabinetSection$.pipe(
      filter((cabinetSection) => cabinetSection != null),
    ) as Observable<Client.CabinetSection>;
    return filtered;
  }
}
