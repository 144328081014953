<div class="editor-all editor-swing" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'swing'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>
  <div class="tool-menu left-tool-menu">
    <h3
      [textContent]="translate('editor_swing_template_list_header','Swing Templates')"
    ></h3>
    <div class="swing-template-pickables">
      <pickable
        *ngFor="let swingTemplatePickable of swingTemplatePickables"
        [pickable]="swingTemplatePickable"
        (click)="addSwingTemplate(swingTemplatePickable.item)"
        class="clickable"
      ></pickable>
    </div>
  </div>

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block visibility">
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{translate('editor_swing_show_rulers', 'Show rulers')}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showDoors" />
        {{translate('editor_swing_show_doors', 'Show doors')}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showTopdown" />
        {{translate('editor_swing_show_topdown', 'Show doors top-down')}}
      </label>
    </div>

    <div class="ribbon-block editor-swing-big-only">
      <pickable
        [pickable]="corpusMaterialPickable"
        class="modal-opener material corpus-material"
        (click)="selectCorpusMaterial()"
      ></pickable>
    </div>

    <div class="ribbon-block editor-swing-big-only">
      <pickable
        [pickable]="doorMaterialPickable"
        class="modal-opener material door-material"
        (click)="selectDoorMaterial()"
      ></pickable>
    </div>

    <div class="ribbon-block editor-swing-big-only">
      <pickable
        [pickable]="gripPickable"
        class="modal-opener product grip-product"
        (click)="selectGrip()"
      ></pickable>
    </div>
    <div class="ribbon-block ribbon-block-vertical editor-swing-big-only">
      <div class="input-group input-group-sm">
        <span
          class="input-group-text medium-addon"
          [textContent]="translate('swing_cabinet_section_label_width', 'Width')"
        ></span>
        <input
          #widthInput
          type="number"
          class="form-control"
          min="0"
          [value]="cabinetSectionWidth"
          (change)="cabinetSectionWidth = widthInput.valueAsNumber"
        />
        <span class="input-group-text">mm</span>
      </div>
      <div class="input-group input-group-sm">
        <span
          class="input-group-text medium-addon"
          [textContent]="translate('swing_cabinet_section_label_height', 'Height')"
        ></span>
        <input
          #heightInput
          type="number"
          min="0"
          class="form-control"
          [value]="cabinetSectionHeight"
          (change)="cabinetSectionHeight = heightInput.valueAsNumber"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>
    <div class="ribbon-block editor-swing-big-only">
      <div class="btn-group btn-group-sm btn-group-vertical">
        <button
          class="btn btn-default"
          [textContent]="translate('editor_swing_swing_area_fit_section_width_to_contents', '->| Shrink section |<-')"
          (click)="fitSectionWidthToContents()"
          [disabled]="!canFitSectionWidthToContents"
        ></button>
        <button
          class="btn btn-default"
          [textContent]="translate('editor_swing_swing_area_fit_contents_to_section_width', '|<- Expand contents ->|')"
          (click)="fitContentsToSectionWidth()"
          [disabled]="!canfitContentsToSectionWidth"
        ></button>
      </div>
    </div>

    <div class="ribbon-block editor-swing-small-only">
      <div class="btn-group" ngbDropdown>
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_swing_small_material_dropdown', 'Materials')"
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <ul class="dropdown-menu" ngbDropdownMenu role="menu">
          <li role="menuitem">
            <pickable
              [pickable]="corpusMaterialPickable"
              style="width: 220px; height: 100px"
              class="modal-opener material corpus-material"
              (click)="selectCorpusMaterial()"
            ></pickable>
          </li>
          <li role="menuitem">
            <pickable
              [pickable]="doorMaterialPickable"
              style="width: 220px; height: 100px"
              class="modal-opener material door-material"
              (click)="selectDoorMaterial()"
            ></pickable>
          </li>
          <li role="menuitem">
            <pickable
              [pickable]="gripPickable"
              style="width: 220px; height: 100px"
              class="modal-opener product grip-product"
              (click)="selectGrip()"
            ></pickable>
          </li>
        </ul>
      </div>
    </div>

    <div class="ribbon-block editor-swing-small-only">
      <div
        class="btn-group"
        ngbDropdown
        autoClose="outsideClick"
        [open]="isAdvancedMenuOpen"
      >
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_swing_small_advanced_dropdown', 'Advanced')"
          ></span>
          <span class="bi bi-caret-down-fill"></span>
        </button>
        <ul
          class="dropdown-menu dropdown-menu-right dropdown-menu-editable"
          ngbDropdownMenu
          role="menu"
        >
          <li role="menuitem" style="padding: 2px 8px">
            <div class="input-group input-group-sm" style="width: 100%">
              <span
                class="input-group-text medium-addon"
                [textContent]="translate('swing_cabinet_section_label_width', 'Width')"
              ></span>
              <input
                type="number"
                class="form-control"
                min="0"
                [ngModelOptions]="itemPropertyModelOptions"
                [(ngModel)]="cabinetSectionWidth"
              />
              <span class="input-group-text">mm</span>
            </div>
          </li>
          <li role="menuitem" style="padding: 2px 8px">
            <div class="input-group input-group-sm" style="width: 100%">
              <span
                class="input-group-text medium-addon"
                [textContent]="translate('swing_cabinet_section_label_height', 'Height')"
              ></span>
              <input
                type="number"
                min="0"
                class="form-control"
                [ngModelOptions]="itemPropertyModelOptions"
                [(ngModel)]="cabinetSectionHeight"
              />
              <span class="input-group-text">mm</span>
            </div>
          </li>
          <li role="menuitem" style="padding: 2px 8px">
            <button
              class="btn btn-default btn-block"
              [textContent]="translate('editor_swing_swing_area_fit_section_width_to_contents', '->| Shrink section |<-')"
              (click)="fitSectionWidthToContents(); isAdvancedMenuOpen = false"
              [disabled]="!canFitSectionWidthToContents"
            ></button>
          </li>
          <li role="menuitem" style="padding: 2px 8px">
            <button
              class="btn btn-default btn-block"
              [textContent]="translate('editor_swing_swing_area_fit_contents_to_section_width', '|<- Expand contents ->|')"
              (click)="fitContentsToSectionWidth(); isAdvancedMenuOpen = false"
              [disabled]="!canfitContentsToSectionWidth"
            ></button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <swing-image
    class="editor-center center-svg"
    [cabinetSection]="cabinetSection!"
    [showRulers]="showRulers"
    [showDoors]="showDoors"
    [showTopdown]="showTopdown"
    [selectionObservable]="selectionObservable"
  ></swing-image>

  <div class="tool-menu right-tool-menu">
    <div *ngIf="selectedSwingArea != null" class="swing-area-details">
      <h3
        [textContent]="selectedSwingArea.module?.product?.Name"
        [attr.title]="selectedSwingArea.module?.product?.Name"
      ></h3>

      <h4
        [textContent]="translate('editor_swing_swing_area_details_door_material','Door Material')"
      ></h4>
      <pickable
        [pickable]="selectedSwingAreaDoorMaterialPickable"
        class="material door-material modal-opener"
        (click)="selectDoorMaterial(selectedSwingArea)"
      ></pickable>

      <div>
        <h4
          [textContent]="translate('editor_swing_swing_area_details_width', 'Width')"
        ></h4>
        <input
          type="number"
          class="form-control"
          [(ngModel)]="selectedAreaWidth"
          [ngModelOptions]="itemPropertyModelOptions"
          [attr.min]="selectedSwingArea.swingTemplate?.minWidth ?? null"
          [attr.max]="selectedSwingArea.swingTemplate?.maxWidth ?? null"
          [disabled]="!selectedSwingArea.swingTemplate?.isFlex"
        />
      </div>

      <div>
        <h4
          [textContent]="translate('editor_swing_swing_area_details_hinge_side_header', 'Hinge Side')"
        ></h4>
        <select
          class="form-control"
          [disabled]="selectedSwingArea.swingTemplate ?  selectedSwingArea.swingTemplate.possibleHingeSides.length < 2 : false"
          [(ngModel)]="selectedAreaHingeSide"
        >
          <option
            *ngFor="let hingeSide of selectedSwingArea.swingTemplate?.possibleHingeSides"
            [ngValue]="hingeSide"
          >
            {{translateHingeSide(hingeSide)}}
          </option>
        </select>
      </div>

      <div>
        <h4
          [textContent]="translate('editor_swing_swing_area_details_pullout_sides', 'Pullout module')"
        ></h4>

        <select
          class="form-select"
          [(ngModel)]="selectedSwingArea.pullOut"
          (ngModelChange)="setChanged()"
          [disabled]="availableSwingAreaSubModules.length < 2"
        >
          <option
            *ngFor="let pullOut of availableSwingAreaSubModules"
            [ngValue]="pullOut.val"
          >
            {{pullOut.name}}
          </option>
        </select>
      </div>

      <div class="small-spacer"></div>

      <div class="btn-group btn-group-labeled">
        <button
          class="btn btn-light"
          (click)="moveSwingAreaLeft(selectedSwingArea)"
        >
          <span class="bi bi-arrow-left"></span>
        </button>
        <span
          class="btn btn-light btn-label"
          [textContent]="translate('editor_swing_swing_area_details_move_area', 'Move area')"
        ></span>
        <button
          class="btn btn-light"
          (click)="moveSwingAreaRight(selectedSwingArea)"
        >
          <span class="bi bi-arrow-right"></span>
        </button>
      </div>

      <div class="small-spacer"></div>

      <button
        class="btn btn-danger btn-block"
        [textContent]="translate('editor_swing_swing_area_details_delete_area', 'Delete area')"
        (click)="deleteSwingArea(selectedSwingArea)"
      ></button>
    </div>
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
