import * as Client from 'app/ts/clientDto/index';
import { ObjectHelper } from './ObjectHelper';
import { ProductHelper } from './ProductHelper';

export class CabinetHelper {
  private static readonly preferredCornerOwnerBitfieldRanking: number[] = [
    0b10, 0b01, 0b11, 0b00,
  ];
  public static getCornerOwners(
    cabinet: Client.Cabinet,
    top: boolean,
  ): Client.CorpusCorner[] {
    let sections = cabinet.actualCabinetSections;
    let numCorners = sections.length - 1;
    let firstSection = sections[0];
    let panel = top
      ? firstSection.corpus.panelTop
      : firstSection.corpus.panelBottom;
    //let otherPanel = top ? otherSection.corpus.panelTop : otherSection.corpus.panelBottom;
    let panelProduct = panel.products.filter(
      (p) => !ProductHelper.isFlexHeight(p, cabinet.ProductLineId),
    )[0];
    if (!panelProduct) {
      panelProduct = panel.products[0];
    }

    //if (!panelProduct || ProductHelper.supportsMiter(panelProduct)) {
    if (panel.products.every((p) => ProductHelper.supportsMiter(p))) {
      let corners: Client.CorpusCorner[] = [];
      for (let corn = 0; corn < numCorners; corn++) {
        corners.push(new Client.CorpusCorner(true, true));
      }
      return corners;
    }
    let maxWidth = ProductHelper.maxWidth(panelProduct);
    let minWidth = ProductHelper.minWidth(panelProduct);

    let numCombinations = 1 << numCorners;

    let bestCombinationBitfield = this.preferredCornerOwnerBitfieldRanking[0];
    let fewestItemsNeeded = Infinity;

    //test all combination of corner owners. Go for the combination that results in the fewest number of items.
    //the cornerOwnerBitfield represents who owns each corner. cornerOwnerBitfield bit 0 is the corner between the first two sections,
    //bit 1 is the corner between the next two sections etc.
    //If the bit is 1, the corner is owned by the left section. Otherwise it's owned by the right section.
    for (
      let cornerOwnerBitfield = 0;
      cornerOwnerBitfield < numCombinations;
      cornerOwnerBitfield++
    ) {
      let totalItemsNeeded = 0;
      let cornerOwnerBitfieldIsValid = true;

      for (
        let sectionNumber = 0;
        sectionNumber < sections.length;
        sectionNumber++
      ) {
        let testSection = sections[sectionNumber];
        let ownsLeftCorner: boolean;
        if (sectionNumber === 0) {
          ownsLeftCorner = true;
        } else {
          ownsLeftCorner =
            (cornerOwnerBitfield & (1 << (sectionNumber - 1))) === 0;
        }

        let ownsRightCorner: boolean;
        if (sectionNumber === sections.length - 1) {
          ownsRightCorner = true;
        } else {
          ownsRightCorner = (cornerOwnerBitfield & (1 << sectionNumber)) !== 0;
        }
        let deduction =
          this.getPanelDeduction(testSection, top, true) +
          this.getPanelDeduction(testSection, top, false);

        let widthNeeded = testSection.Width - deduction;
        if (!ownsLeftCorner)
          widthNeeded -= this.getCorpusPanelDepth(
            sections[sectionNumber - 1],
            top,
          );
        if (!ownsRightCorner)
          widthNeeded -= this.getCorpusPanelDepth(
            sections[sectionNumber + 1],
            top,
          );

        if (widthNeeded < minWidth) {
          //current section is too narrow for this configuration.
          cornerOwnerBitfieldIsValid = false;
          break;
        }

        let itemsNeeded = Math.ceil(widthNeeded / maxWidth);
        totalItemsNeeded += itemsNeeded;
      }
      if (!cornerOwnerBitfieldIsValid) {
        continue;
      }

      if (
        totalItemsNeeded < fewestItemsNeeded || //this combo results in fewer parts needed
        (totalItemsNeeded === fewestItemsNeeded && //or this combo results in the same number of parts,
          //but the combo is preferred over the previous one
          this.preferredCornerOwnerBitfieldRanking.indexOf(
            cornerOwnerBitfield,
          ) <
            this.preferredCornerOwnerBitfieldRanking.indexOf(
              bestCombinationBitfield,
            ))
      ) {
        fewestItemsNeeded = totalItemsNeeded;
        bestCombinationBitfield = cornerOwnerBitfield;
      }
    }
    let result: Client.CorpusCorner[] = [];
    for (let corner = 0; corner < numCorners; corner++) {
      let ownedByLeft = !!(bestCombinationBitfield & (1 << corner));
      result.push(new Client.CorpusCorner(ownedByLeft, !ownedByLeft));
    }
    return result;
  }

  private static getPanelDeduction(
    section: Client.CabinetSection,
    top: boolean,
    left: boolean,
  ): number {
    if (!section.corpus.material) return 0;
    let panel = top ? section.corpus.panelTop : section.corpus.panelBottom;
    let sidePanel = left ? section.corpus.panelLeft : section.corpus.panelRight;
    if (!panel) {
      return 0;
    }
    if (
      panel.isFullSize() ||
      panel.defaultDepth(
        section.cabinet.ProductLineId,
        section.corpus.material,
      ) >
        sidePanel.defaultDepth(
          section.cabinet.ProductLineId,
          section.corpus.material,
        )
    ) {
      return 0;
    } else {
      return left ? section.corpus.widthLeft : section.corpus.widthRight;
    }
  }

  private static getCorpusPanelDepth(
    section: Client.CabinetSection,
    top: boolean,
  ): number {
    if (!section.corpus.material) return section.Depth;

    let panel = top ? section.corpus.panelTop : section.corpus.panelBottom;
    return ObjectHelper.clamp(
      panel.minDepth(section.corpus.material, section.cabinet.ProductLineId),
      section.Depth,
      panel.maxDepth(section.corpus.material, section.cabinet.ProductLineId),
    );
  }
}
