<div class="modal-content">
  <div class="modal-header">
    <table>
      <td>
        <h4 [textContent]="currentHeader"></h4>
        <div class="ribbon-menu">
          <div class="ribbon-block">
            <button
              class="btn btn-default btn-block"
              [attr.title]="translate('project_selector_back','Back')"
              [ngClass]="{'disabled': !isBackEnabled}"
              (click)="back()"
            >
              <span class="bi bi-caret-left-fill"></span>
            </button>
          </div>
          <div class="ribbon-block">
            <button
              class="btn btn-default"
              [attr.title]="translate('project_selector_new_customer','New Customer')"
              (click)="newCustomer()"
              *ngIf="!selectedCustomer"
            >
              <span class="bi bi-plus-lg"></span>
              <span class="bi bi-ka-customer"></span>
            </button>
            <button
              class="btn btn-default"
              [attr.title]="translate('project_selector_new_project','New Project')"
              *ngIf="selectedCustomer"
              (click)="newProject()"
            >
              <span class="bi bi-plus-lg"></span>
              <span class="bi bi-folder-fill"></span>
            </button>
          </div>
          <div class="ribbon-block">
            <input
              type="search"
              name="Search"
              class="form-control"
              [(ngModel)]="quickSearchString"
              [ngModelOptions]="quickSearchModelOptions"
              [placeholder]="translate('project_selector_quick_search_placeholder', 'Search')"
              autofocus
            />
          </div>
        </div>
      </td>
    </table>
  </div>

  <div class="modal-body">
    <customers-table
      [objects]="visibleCustomers"
      *ngIf="!selectedCustomer"
      (onItemSelected)="selectCustomer($event)"
      [_displayNavigationLeft]="displayNavigationLeft"
      [_displayNavigationRight]="displayNavigationRight"
    >
    </customers-table>
    <projects-table
      [objects]="selectedCustomer.Projects"
      [_displayNavigationLeft]="displayNavigationLeft"
      [_displayNavigationRight]="displayNavigationRight"
      *ngIf="selectedCustomer"
      (onItemSelected)="selectProject($event.Id)"
      [showCheckBox]="_showCheckBox"
    >
    </projects-table>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-Nav disabled"
        (click)="ok()"
        [ngClass]="{'disabled': !isOkEnabled}"
        [textContent]="translate('button_ok', 'OK')"
      ></button>
      <button
        class="btn btn-secondary"
        (click)="cancel()"
        [textContent]="translate('button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
