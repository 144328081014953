import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { Observable as OldObservable } from 'app/ts/util/Observable';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { IConsumerPage } from 'app/ts/viewmodels/consumer/pages/IConsumerPage';
import { CabinetSectionService } from './cabinet-section.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
type Category = Client.InteriorProductCategory;

@Component({
  selector: 'consumer-interior-vm',
  templateUrl: './interior.html',
  styleUrls: [
    'common.scss',
    'dimensions.scss',
    'interior.scss',
    '../../../../../style/d3.scss',
  ],
})
export class InteriorVm extends BaseVm implements IConsumerPage {
  private cache: Partial<{
    categories: Pickable<Client.InteriorProductCategory>[];
    materials: Pickable<Client.Material>[];
    maxErrorLevel: number;
  }> = {};

  public get selectedItems$(): Observable<Client.ConfigurationItem[]> {
    return this._selectedItems$;
  }

  private readonly _selectedItems$ = new BehaviorSubject<
    Client.ConfigurationItem[]
  >([]);
  public readonly currentDragInfoObservable = new OldObservable<
    Client.ProductDragInfo | undefined
  >(undefined);

  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    cabinetSections: CabinetSectionService,
    private readonly floorPlanService: FloorPlanService,
    private readonly modalService: ModalService,
    private routing: AppRoutingModule,
    private readonly configurationItemService: ConfigurationItemService,
  ) {
    super(baseVmService);

    cabinetSections.cabinetSection$
      .pipe(takeUntilDestroyed())
      .subscribe((cabinetSection) => {
        this.cabinetSection = cabinetSection;
      });

    this.selectedCategory = this.categories[0].item;
  }

  public get categories(): Pickable<Client.InteriorProductCategory>[] {
    if (this.cache.categories === undefined) {
      this.cache.categories =
        this.cabinetSection.interior.availableInteriorProductCategories.map<
          Pickable<Client.InteriorProductCategory>
        >((cat) => ({
          item: cat,
          name: cat.name,
          disabledReason: null,
          isSelected: this.selectedCategory === cat,
          override: false,
          imageUrl: cat.imageUrl,
        }));
    }
    return this.cache.categories;
  }
  public selectedCategory: Client.InteriorProductCategory;

  public get materials(): Pickable<Client.Material | null>[] {
    if (this.cache.materials === undefined) {
      this.cache.materials =
        this.cabinetSection.interior.pickableMaterials.filter(
          (pm) => !pm.disabledReason && !pm.override && !pm.warnReason,
        );
    }
    return this.cache.materials;
  }
  public get selectedMaterial(): Client.Material | null {
    return this.cabinetSection.interior.material;
  }
  public set selectedMaterial(val: Client.Material | null) {
    this.cabinetSection.interior.setMaterial(val, true);
    this.setChanged();
  }
  public get showMaterialPicker() {
    return false; //UNSILVANIZE
    //return this.materials.length > 1;
  }

  public selectedItemChanged() {
    this.setChanged();
  }

  public itemDeleted(item: Client.ConfigurationItem) {
    this.configurationItemService.deleteInteriorItems([item]);
    this._selectedItems$.next([]);
  }

  public itemsSelected(items: Client.ConfigurationItem[]) {
    this._selectedItems$.next(items);
  }

  public get selectedItem(): Client.ConfigurationItem | null {
    let items = this._selectedItems$.value;
    if (!items || items.length !== 1) return null;
    return items[0];
  }

  public get maxErrorLevel(): number {
    let errorLevels = this.cabinetSection.interior.items.map(
      (i) => i.maxErrorLevel,
    );
    let result = Math.max(
      Interface_Enums.ErrorLevel.None,
      ...errorLevels,
    ) as Interface_Enums.ErrorLevel;
    return result;
  }

  public getErrorPopupClass(): string {
    if (this.maxErrorLevel == 5) {
      return 'error-popup--info';
    } else if (this.maxErrorLevel == 10) {
      return 'error-popup--warning';
    } else {
      return 'error-popup--danger';
    }
  }

  public async showErrors() {
    //let errors = Enumerable.from(this.cabinetSection.interior.items)
    //    .selectMany(item => item.errors)
    //    .toArray();
    let errors = this.cabinetSection.errorInfos;
    let selectedError = await this.modalService.consumerErrorsModal(errors);
    if (!selectedError) return;
    if (selectedError.editorSection !== Enums.EditorSection.Interior) return;
    if (!selectedError.configurationItem) return;
    this._selectedItems$.next([selectedError.configurationItem]);
  }

  public async setChanged() {
    await this.floorPlanService.setChanged(
      this.cabinetSection.cabinet.floorPlan,
    );
    this.clearCache();
  }

  public get floorPlan() {
    return this.cabinetSection.cabinet.floorPlan;
  }
  public get cabinet() {
    return this.cabinetSection.cabinet;
  }

  private clearCache() {
    this.cache = {};
  }

  public next() {
    this.routing.Consumer_basket();
  }

  public previous() {
    this.routing.Consumer_doors();
  }
}
