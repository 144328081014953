import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, map, of, timeout } from 'rxjs';
import { RequestConfig } from './RequestConfig';
import { BackendService } from './backend-service';

@Injectable()
export class PromisingBackendService {
  constructor(private backendService: BackendService) {}

  public delete(
    relativeUrl: string,
    config?: Omit<RequestConfig, 'params'>,
  ): Promise<Object>;
  public delete<T>(
    relativeUrl: string,
    config?: Omit<RequestConfig, 'params'>,
  ): Promise<T>;
  public delete<T>(
    relativeUrl: string,
    config?: Omit<RequestConfig, 'params'>,
  ): Promise<T | string | Blob | void> {
    return this.toData(
      relativeUrl,
      this.backendService.delete<T>(relativeUrl),
      config,
    );
  }

  public get(relativeUrl: string, config?: RequestConfig): Promise<string>;
  public get<T>(relativeUrl: string, config?: RequestConfig): Promise<T>;
  public get<T>(
    relativeUrl: string,
    config?: RequestConfig,
  ): Promise<T | string | Blob | void> {
    return this.toData(
      relativeUrl,
      this.backendService.get<T>(relativeUrl, config),
      config,
    );
  }

  public post(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Promise<void>;
  public post<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Promise<T>;
  public post<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Promise<T | string | Blob | void> {
    return this.toData(
      relativeUrl,
      this.backendService.post<T>(relativeUrl, body, config),
      config,
    );
  }

  public put<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Promise<T>;
  public put<T>(
    relativeUrl: string,
    body: any,
    config?: RequestConfig,
  ): Promise<T | string | Blob | void> {
    return this.toData(
      relativeUrl,
      this.backendService.put<T>(relativeUrl, body, config),
      config,
    );
  }

  private async toData<T>(
    relativeUrl: string,
    response: Observable<HttpResponse<T>>,
    config?: RequestConfig,
  ): Promise<T | void> {
    const timeoutLength =
      typeof config?.timeout == 'number' ? config.timeout : 50000;
    const p = response.pipe(
      timeout({ first: timeoutLength, with: () => of(undefined) }),
      map((e) => e),
      // catchError(error => {
      //     console.error(error)
      //     return of(undefined)
      // })
    );

    return await firstValueFrom(p).then((value) => {
      if (config?.responseType == 'void') {
        return;
      }
      if (value?.body == null)
        throw new Error('null data from endpoint: ' + relativeUrl);
      return value.body;
    });
  }
}
