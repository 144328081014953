import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from 'app/ts/services/LoginService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-new-user-vm',
  templateUrl: './consumerNewUser.html',
})
export class ConsumerNewUserVm extends BaseVm implements OnInit {
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.username = this.suggestedUsername ? this.suggestedUsername : '';
    this.password = this.suggestedPassword ? this.suggestedPassword : '';
  }
  public suggestedUsername!: string;
  public suggestedPassword!: string;

  public isActive = false;
  public showError = false;
  public username = '';
  public password = '';
  public name = '';
  public email = '';

  public close() {
    this.activeModal.dismiss('close');
  }

  public async createUser() {
    if (this.isActive) return;
    if (!this.username) return;
    if (!this.password) return;
    if (!this.name) return;
    if (!this.email) return;

    try {
      this.isActive = true;
      let success = await this.loginService.createConsumerUser({
        Username: this.username,
        Password: this.password,
        Name: this.name,
        Email: this.email,
      });
      if (!success) {
        this.showError = true;
      } else {
        this.activeModal.close({
          username: this.username,
        });
      }
    } finally {
      this.isActive = false;
    }
  }
}
