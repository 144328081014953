import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FloorPlan } from 'app/ts/clientDto';
import { Section } from '../floor-plan';
import { Module } from '../module';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { PartitionPlanQueryService } from '../partition-plan-query.service';

/**
 * The presentational component for partition properties.
 * Another word for the type of component is Composer - since
 * it composes several other views.
 */
@Component({
  selector: 'partition-property-sheet-ui',
  templateUrl: 'property-sheet.component.html',
  styleUrls: ['../../../style/editor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySheetComponent {
  constructor(public partitionQueryService: PartitionPlanQueryService) {}

  @Input()
  public name!: string;

  @Input()
  public floorPlan!: FloorPlan;

  @Input({ required: true })
  public get section(): Readonly<Section> {
    return this._section;
  }
  public set section(section: Readonly<Section>) {
    this._section = section;
  }
  private _section!: Readonly<Section>;

  @Input({ required: true })
  public get module(): Readonly<Module> {
    return this._module;
  }
  public set module(module: Readonly<Module>) {
    this._module = module;
  }
  private _module!: Readonly<Module>;

  @Output()
  public nameChange = new EventEmitter<string>();

  @Output()
  public sectionWidthChange = new EventEmitter<{
    width: number;
    angle?: number;
  }>();

  @Output()
  public numberOfModulesChange = new EventEmitter<number>();

  @Output()
  public deletePartition = new EventEmitter<void>();

  @Output()
  public deleteSection = new EventEmitter<void>();

  @Output()
  public rotateSectionLeft = new EventEmitter<void>();

  @Output()
  public rotateSectionRight = new EventEmitter<void>();

  @Output()
  public reverseSection = new EventEmitter<void>();

  @Output()
  public unevenLeftWall = new EventEmitter<boolean>();

  @Output()
  public unevenRightWall = new EventEmitter<boolean>();

  @Output()
  public distanceToLeftWallChange = new EventEmitter<number>();
  @Output()
  public distanceToRightWallChange = new EventEmitter<number>();
  @Output()
  public distanceToTopWallChange = new EventEmitter<number>();
  @Output()
  public distanceToBottomWallChange = new EventEmitter<number>();
}
