import { Constants } from 'app/ts/Constants';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  distinctUntilChanged,
} from 'rxjs';
import { ClientSettingData } from './ClientSetting';
import { User } from 'app/identity-and-access/User';

export class ClientSettingContainer {
  private userId: number = -1;
  constructor(activeUser: Observable<User | null>) {
    activeUser.subscribe((user) => {
      if (user != null) this.userId = user.UserId;
    });
  }

  private _customPriceDate?: Date | undefined = undefined;
  public get customPriceDate(): Date | undefined {
    return this._customPriceDate;
  }

  private _customPriceDate$ = new BehaviorSubject<Date | undefined>(undefined);
  public get customPriceDate$() {
    return this._customPriceDate$;
  }

  public setCustomPriceDate(date: Date | undefined) {
    this._customPriceDate = date;
    this._customPriceDate$.next(date);
  }

  private _useFullCatalog: boolean = false;
  public get useFullCatalog(): boolean {
    return this._useFullCatalog;
  }

  private readonly _useFullCatalog$ = new BehaviorSubject<boolean>(false);
  public get useFullCatalog$(): Observable<boolean> {
    return this._useFullCatalog$.pipe(distinctUntilChanged());
  }

  public setUseFullCatalog(value: boolean): void {
    this._useFullCatalog = value;
    this._useFullCatalog$.next(value);
  }

  public subscribeUseFullCatalog(next: (value: boolean) => void): Subscription {
    const subscription = this.useFullCatalog$.subscribe(next);
    return subscription;
  }

  private _clientSetting: ClientSettingData = this.getDefaultClientSettings();
  public get clientSetting(): ClientSettingData {
    return this._clientSetting;
  }

  private readonly _clientSetting$ = new BehaviorSubject<ClientSettingData>(
    this.getDefaultClientSettings(),
  );
  public get clientSetting$(): Observable<ClientSettingData> {
    return this._clientSetting$;
  }

  public setClientSetting(value: ClientSettingData) {
    // First load existing settings from local storage
    const allClientSettingsJson = localStorage.getItem(
      Constants.localStorageKeys.clientSetting,
    );
    let allClientSettings: { [userId: number]: Partial<ClientSettingData> } =
      {};
    if (allClientSettingsJson)
      allClientSettings = JSON.parse(allClientSettingsJson);

    let oldUserSettings = allClientSettings[this.userId];
    if (!oldUserSettings) oldUserSettings = this.getDefaultClientSettings();

    const newUserSettings = {
      ...oldUserSettings,
      ...value,
    };

    allClientSettings[this.userId] = newUserSettings;
    localStorage.setItem(
      Constants.localStorageKeys.clientSetting,
      JSON.stringify(allClientSettings),
    );

    this._clientSetting = value;
    this._clientSetting$.next(value);
  }

  public subscribeClientSetting(
    next: (value: ClientSettingData) => void,
  ): Subscription {
    const subscription = this._clientSetting$.subscribe(next);
    return subscription;
  }

  public loadClientSettingFromLocalStorage(userId: number): ClientSettingData {
    const allClientSettingsJson = localStorage.getItem(
      Constants.localStorageKeys.clientSetting,
    );
    if (!allClientSettingsJson) return this.getDefaultClientSettings();

    const allSettings = JSON.parse(allClientSettingsJson) as {
      [userId: number]: Partial<ClientSettingData>;
    };

    const defaultSettings = this.getDefaultClientSettings();
    let userSettings = allSettings[userId];
    if (userSettings) {
      return {
        ...defaultSettings,
        ...userSettings,
      };
    }
    return defaultSettings;
  }

  private getDefaultClientSettings(): ClientSettingData {
    let screenWidthPx = window.innerWidth;
    return {
      columnsCustomer: ['name', 'address', 'postalCodeCity'],
      columnsProject: ['name', 'description'],
      columnsDeliveryAddress: ['name', 'address', 'postalCodeCity'],
      columnsFloorPlan: this.getDefaultFloorPlanColumns(screenWidthPx),
      overrideChain: false,
      showExtraOverviewTabs: false,
      showAllFloorplansOnEmptySearch: true,
      interior: {
        displayPulloutWarnings: false,
        displayRulers: false,
        displayCorpus: true,
      },
      doors: {
        displayPulloutWarnings: false,
        displayRulers: true,
        displayTopdown: true,
      },
      corpus: {
        displayDoors: false,
        displayInterior: false,
        displayOtherCabinets: true,
      },
      d3: {
        displayCorpus: true,
        displayDoors: false,
        displayInterior: true,
        displayOtherCabinets: true,
      },
      backing: {
        displayRulers: true,
      },
      swing: {
        displayDoors: false,
        displayRulers: true,
        displayTopdown: true,
      },
      swingFlex: {
        displayDoors: false,
        displayRulers: true,
        displayCorpus: true,
        displayTopdown: true,
        displayHinges: true,
        displayInterior: false,
      },
    };
  }
  private getDefaultFloorPlanColumns(windowWidthPx: number): string[] {
    let smallScreenMaxWidth = 1280; //pooma
    if (windowWidthPx <= smallScreenMaxWidth) {
      return ['name', 'orderNumber', 'editDeadline', 'id'];
    } else {
      return [
        'name',
        'orderNumber',
        'deliveryWeek',
        'editDeadline',
        'id',
        'reference',
        'status',
      ];
    }
  }
}
