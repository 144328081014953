import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { GableSnapService } from 'app/ts/services/snap/GableSnapService';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import Enumerable from 'linq';
export class Ruler {
  public readonly serifSize: number = 20;

  private readonly _svgStart: Interface_DTO_Draw.Vec2d = { X: 0, Y: 0 };

  constructor(
    public readonly isVertical: boolean,
    public start: Interface_DTO_Draw.Vec2d,
    public readonly length: number,
    public readonly svgBaseline: number,
    public readonly isCustom: boolean,
  ) {}

  public get svgStart(): Interface_DTO_Draw.Vec2d {
    this._svgStart.X = this.start.X;
    this._svgStart.Y = this.svgBaseline - this.start.Y;
    return this._svgStart;
  }

  public static GetRulers(
    cabinetSection: Client.CabinetSection,
  ): Ruler.RulerList {
    let railOffsetLeft =
      cabinetSection.doors.railSet &&
      cabinetSection.doors.railSet.NumberOfTracks > 1
        ? cabinetSection.ExtraRailWidthLeft
        : 0;

    let leftNeighborCabinetBounds = 0;
    let leftNeighborInteriorBounds = 0;
    let rightNeighborCabinetBounds = 0;
    let rightNeighborInteriorBounds = 0;

    let leftCorner = cabinetSection.interior.leftCorner;
    let rightCorner = cabinetSection.interior.rightCorner;

    if (!!leftCorner) {
      let firstGable = cabinetSection.interior.gables[0];
      leftNeighborCabinetBounds = Math.max(
        firstGable ? firstGable.X : leftCorner.acceptableSpace,
        leftCorner.minimumSpace,
      );
      leftNeighborInteriorBounds = leftCorner.minimumSpace;
    }

    if (!!rightCorner) {
      let lastGable =
        cabinetSection.interior.gables[
          cabinetSection.interior.gables.length - 1
        ];
      rightNeighborCabinetBounds = Math.max(
        lastGable
          ? cabinetSection.Width - lastGable.rightX
          : rightCorner.acceptableSpace,
        rightCorner.minimumSpace,
      );
      rightNeighborInteriorBounds = rightCorner.minimumSpace;
    }

    return {
      cabinetHeightRuler: new Client.Ruler(
        true,
        {
          X: -((Constants.rulerWidth * 3) / 2 + railOffsetLeft),
          Y: 0,
        },
        cabinetSection.Height,
        cabinetSection.Height,
        false,
      ),
      cabinetSightHeightRuler: new Client.Ruler(
        true,
        {
          X: -(Constants.rulerWidth / 2 + railOffsetLeft),
          Y: cabinetSection.sightOffsetY,
        },
        cabinetSection.SightHeight,
        cabinetSection.Height,
        false,
      ),
      interiorHeightRuler: new Client.Ruler(
        true,
        {
          X: -(Constants.rulerWidth / 2 + railOffsetLeft),
          Y: cabinetSection.interior.cube.Y,
        },
        cabinetSection.interior.cube.Height,
        cabinetSection.Height,
        false,
      ),
      cabinetWidthRuler: new Client.Ruler(
        false,
        {
          X: 0,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        cabinetSection.Width,
        0,
        false,
      ),
      cabinetSightWidthRuler: new Client.Ruler(
        false,
        {
          X:
            cabinetSection.sightOffsetX +
            (cabinetSection.doors.numberOfRailTracks === 1
              ? cabinetSection.ExtraRailWidthLeft
              : 0),
          Y: Constants.rulerWidth / 2,
        },
        cabinetSection.SightWidth,
        0,
        false,
      ),
      interiorWidthRuler: new Client.Ruler(
        false,
        {
          X:
            leftNeighborCabinetBounds > 0
              ? leftNeighborCabinetBounds
              : cabinetSection.interior.cube.X,
          Y: Constants.rulerWidth / 2,
        },
        cabinetSection.interior.cube.Width -
          (leftNeighborCabinetBounds + rightNeighborCabinetBounds) +
          (leftNeighborCabinetBounds > 0 ? cabinetSection.interior.cube.X : 0),
        0,
        false,
      ),
      leftNeighborCabinetBoundsRuler: new Client.Ruler(
        false,
        {
          X: leftNeighborInteriorBounds,
          Y: Constants.rulerWidth / 2,
        },
        leftNeighborCabinetBounds - leftNeighborInteriorBounds,
        0,
        false,
      ),
      leftNeighborInteriorBoundsRuler: new Client.Ruler(
        false,
        {
          X: 0,
          Y: Constants.rulerWidth / 2,
        },
        leftNeighborInteriorBounds,
        0,
        false,
      ),
      rightNeighborCabinetBoundsRuler: new Client.Ruler(
        false,
        {
          X: cabinetSection.interior.cubeRightX - rightNeighborCabinetBounds,
          Y: Constants.rulerWidth / 2,
        },
        rightNeighborCabinetBounds - rightNeighborInteriorBounds,
        0,
        false,
      ),
      rightNeighborInteriorBoundsRuler: new Client.Ruler(
        false,
        {
          X: cabinetSection.interior.cubeRightX - rightNeighborInteriorBounds,
          Y: Constants.rulerWidth / 2,
        },
        rightNeighborInteriorBounds,
        0,
        false,
      ),
      extraRailWidthLeftRuler: new Client.Ruler(
        false,
        {
          X:
            cabinetSection.sightOffsetX -
            (cabinetSection.doors.numberOfRailTracks > 1
              ? cabinetSection.ExtraRailWidthLeft
              : 0),
          Y: Constants.rulerWidth / 2,
        },
        cabinetSection.ExtraRailWidthLeft,
        0,
        false,
      ),
      extraRailWidthRightRuler: new Client.Ruler(
        false,
        {
          X:
            cabinetSection.sightOffsetX +
            (cabinetSection.doors.numberOfRailTracks === 1
              ? cabinetSection.ExtraRailWidthLeft
              : 0) +
            cabinetSection.SightWidth,
          Y: Constants.rulerWidth / 2,
        },
        cabinetSection.doors.actualExtraRailWidthRight,
        0,
        false,
      ),
    };
  }

  public static GetSwingRulers(
    cabinetSection: Client.CabinetSection,
  ): Client.Ruler[] {
    let areaItems = Enumerable.from(cabinetSection.swing.areas)
      .selectMany((area) => area.items)
      .toArray();
    let minX = Math.min(0, ...areaItems.map((item) => item.X));
    let maxX =
      areaItems.length > 0
        ? Math.max(...areaItems.map((item) => item.rightX))
        : cabinetSection.Width;

    let rulers = [
      // current cabinet width
      new Client.Ruler(
        false,
        {
          X: 0,
          Y: (Constants.rulerWidth * 5) / 2,
        },
        cabinetSection.Width,
        0,
        false,
      ),

      //current actual width
      new Client.Ruler(
        false,
        {
          X: minX,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        maxX - minX,
        0,
        false,
      ),
      //remaining width
      new Client.Ruler(
        false,
        {
          X: maxX,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        cabinetSection.Width - maxX,
        0,
        false,
      ),

      //with of swingArea insides
      ...cabinetSection.swing.areas.map(
        (area) =>
          new Client.Ruler(
            false,
            {
              X: area.insideRect.X,
              Y: (Constants.rulerWidth * 1) / 2,
            },
            area.insideRect.Width,
            0,
            false,
          ),
      ),

      //vertical rulers
      //cabinetSection height
      new Client.Ruler(
        true,
        {
          X: (-Constants.rulerWidth * 3) / 2,
          Y: 0,
        },
        cabinetSection.Height,
        cabinetSection.Height,
        false,
      ),
    ];

    let firstArea = cabinetSection.swing.areas[0];
    if (firstArea) {
      rulers.push(
        new Client.Ruler(
          true,
          {
            X: (-Constants.rulerWidth * 1) / 2,
            Y: firstArea.insideRect.Y,
          },
          firstArea.insideRect.Height,
          cabinetSection.Height,
          false,
        ),
      );
    }

    // Area corpus gable widths
    cabinetSection.swing.areas.forEach((area) => {
      Enumerable.from(area.items)
        .where((item) => item.isSwingCorpusGable)
        .forEach((gable) =>
          rulers.push(
            new Client.Ruler(
              false,
              {
                X: gable.X,
                Y: (Constants.rulerWidth * 1) / 2,
              },
              gable.Width,
              0,
              false,
            ),
          ),
        );
    });

    return rulers;
  }

  public static GetSwingFlexRulers(
    cabinetSection: Client.CabinetSection,
  ): Client.Ruler[] {
    const areaItems = cabinetSection.swingFlex.items.filter(
      (item) => !item.isGrip,
    );

    const minX = Math.min(...areaItems.map((item) => item.X));
    const maxX =
      areaItems.length > 0
        ? Math.max(...areaItems.map((item) => item.rightX))
        : cabinetSection.SightWidth - cabinetSection.sightOffsetX;

    const minY = Math.min(...areaItems.map((item) => item.Y));
    const maxY =
      areaItems.length > 0
        ? Math.max(...areaItems.map((item) => item.topY))
        : cabinetSection.SightHeight;

    let rulers = [
      // SwingFlex width
      new Client.Ruler(
        false,
        {
          X: minX,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        maxX - minX,
        0,
        false,
      ),

      // SwingFlex height
      new Client.Ruler(
        true,
        {
          X: (-Constants.rulerWidth * 1) / 2,
          Y: minY,
        },
        maxY - minY,
        cabinetSection.Height,
        false,
      ),

      // With of swingFlexArea insides
      ...cabinetSection.swingFlex.areas.map(
        (area) =>
          new Client.Ruler(
            false,
            {
              X: area.insideRect.X,
              Y: (Constants.rulerWidth * 1) / 2,
            },
            area.insideRect.Width,
            0,
            false,
          ),
      ),

      // CabinetSection height
      new Client.Ruler(
        true,
        {
          X: (-Constants.rulerWidth * 3) / 2,
          Y: 0,
        },
        cabinetSection.Height,
        cabinetSection.Height,
        false,
      ),

      // CabinetSection width
      new Client.Ruler(
        false,
        {
          X: 0,
          Y: (Constants.rulerWidth * 5) / 2,
        },
        cabinetSection.Width,
        0,
        false,
      ),
    ];

    return rulers;
  }

  public static GetColumnRulers(
    cabinetSection: Client.CabinetSection,
  ): Ruler[] {
    let rulerStartY = -Constants.rulerWidth / 2;
    let gaps = InteriorLogic.getGableGaps(cabinetSection, []).filter(
      (g) => g.startsAtBottom,
    );
    let result = gaps.map(
      (gap) =>
        new Client.Ruler(
          false,
          { X: gap.X, Y: rulerStartY },
          gap.Width,
          cabinetSection.Height,
          GableSnapService.normalSpacings.indexOf(gap.Width) < 0,
        ),
    );
    return result;
  }
}

export module Ruler {
  export interface RulerList {
    cabinetHeightRuler: Ruler;
    cabinetSightHeightRuler: Ruler;
    cabinetWidthRuler: Ruler;
    cabinetSightWidthRuler: Ruler;
    interiorHeightRuler: Ruler;
    interiorWidthRuler: Ruler;
    leftNeighborCabinetBoundsRuler: Ruler;
    leftNeighborInteriorBoundsRuler: Ruler;
    rightNeighborCabinetBoundsRuler: Ruler;
    rightNeighborInteriorBoundsRuler: Ruler;
    extraRailWidthLeftRuler: Ruler;
    extraRailWidthRightRuler: Ruler;
  }
}
