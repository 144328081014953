import * as Modals from 'app/ts/viewmodels/consumer/modals';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { TableColumn } from 'app/ts/interfaces/TableColumn';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EndUserSetupOverview } from '../EndUserSetupOverview';
import { FloorPlanSaveService } from './FloorPlanSaveService';
import { GroupSelectorVm } from '../viewmodels/modals/GroupSelectorVm';
import { DeliveryAddressSelectorVm } from '../viewmodels/modals/DeliveryAddressSelectorVm';
import { addFittingVm } from '../viewmodels/modals/addFittingVm';
import { AddProductVm } from '../viewmodels/modals/AddProductVm';
import { EditManualItemVm } from '../viewmodels/modals/EditManualItemVm';
import { FavouriteSelectorVm } from '../viewmodels/modals/FavouriteSelectorVm';
import { AcceptDeliveryInfoVm } from '../viewmodels/modals/AcceptDeliveryInfoVm';
import { EndUserSetupImportVm } from '../viewmodels/modals/EndUserSetupImportVm';
import { ItemDepthVm } from '../viewmodels/modals/ItemDepthVm';
import { FreeSpaceVm } from '../viewmodels/modals/FreeSpaceVm';
import { JointPositionsVm } from '../viewmodels/modals/JointPositionsVm';
import { CustomPriceDateVm } from '../viewmodels/modals/CustomPriceDateVm';
import { FloorPlanTemplateVm } from '../viewmodels/modals/FloorPlanTemplateVm';
import { DeliveryInfoEditorVm } from '../viewmodels/modals/DeliveryInfoEditorVm';
import { NewCustomerVm } from '../viewmodels/modals/NewCustomerVm';
import { NewProjectVm } from '../viewmodels/modals/NewProjectVm';
import { NewDeliveryAddressVm } from '../viewmodels/modals/NewDeliveryAddressVm';
import { UserSettingVm } from '../viewmodels/modals/UserSettingVm';
import { StoreSettingVm } from '../viewmodels/modals/StoreSettingVm';
import { SelectBackingVm } from '../viewmodels/modals/SelectBackingVm';
import { RestoreAutosaveVm } from '../viewmodels/modals/RestoreAutosaveVm';
import { PropertySelectorVm } from '../viewmodels/modals/PropertySelectorVm';
import { ProjectSelectorVm } from '../viewmodels/modals/ProjectSelectorVm';
import { StoreSelectorVm } from '../viewmodels/modals/StoreSelectorVm';
import { PrintDialogVm } from '../viewmodels/modals/PrintDialogVm';
import { TemplateSaveVm } from '../viewmodels/modals/TemplateSaveVm';
import { LinkBrowserModalVm } from '../viewmodels/modals/LinkBrowserModalVm';
import { PasswordResetRequestVm } from '../viewmodels/modals/PasswordResetRequestVm';
import { UserMessageVm } from '../viewmodels/modals/UserMessageVm';
import { ConsumerDoorProfileVm } from '../viewmodels/consumer/modals/ConsumerDoorProfileVm';
import { ConsumerErrorsModalVm } from '../viewmodels/consumer/modals/ConsumerErrorsModalVm';
import { ConsumerLoginVm } from '../viewmodels/consumer/modals/ConsumerLoginVm';
import { ConsumerNewUserVm } from '../viewmodels/consumer/modals/ConsumerNewUserVm';
import { ConsumerSaveFloorPlanVm } from '../viewmodels/consumer/modals/ConsumerSaveFloorPlanVm';

@Injectable({ providedIn: 'root' })
export class ModalService {
  public static readonly Name = 'modalService';

  constructor(
    private readonly modalService: NgbModal,
    private readonly translationService: TranslationService,
    private readonly floorPlanService: FloorPlanService,
    private readonly floorPlanSaveService: FloorPlanSaveService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  public getNewCustomerModal(): NgbModalRef {
    return this.open(NewCustomerVm, {
      size: 'lg',
      windowClass: 'detail-modal',
      backdrop: 'static',
    });
  }

  public async getNewCustomerModalChained(
    chainStop: 'customer' | 'project' | 'deliveryAddress' | 'floorplan',
  ): Promise<ModalService.CustomerModalResult> {
    try {
      let customerModal = this.getNewCustomerModal();
      let customer = await customerModal.result;
      let customerChildren: ModalService.ProjectModalResult;
      if (chainStop === 'customer') {
        customerChildren = { type: 'none' };
      } else {
        customerChildren = await this.getNewProjectModalChained(
          customer,
          chainStop,
        );
      }

      if (customerChildren.type === 'none') {
        return {
          type: 'customer',
          customerId: customer.Id,
        };
      } else {
        return {
          ...customerChildren,
          customerId: customer.Id,
        };
      }
    } catch (e: any) {
      //customer modal was closed
      return { type: 'none' };
    }
  }

  public async getNewProjectModalChained(
    customer: DTO.Customer,
    chainStop: 'project' | 'deliveryAddress' | 'floorplan',
  ): Promise<ModalService.ProjectModalResult> {
    try {
      let projectModal = this.getNewProjectModal(customer);
      let project = await projectModal.result;
      let projectChildren: ModalService.DeliveryAddressModalResult;

      if (chainStop === 'project') {
        projectChildren = { type: 'none' };
      } else {
        projectChildren = await this.getNewDeliveryAddressModalChained(
          project,
          customer,
          chainStop,
        );
      }

      if (projectChildren.type === 'none') {
        return {
          type: 'project',
          projectId: project.Id,
        };
      } else {
        return {
          ...projectChildren,
          projectId: project.Id,
        };
      }
    } catch (e: any) {
      //project modal was closed
      return { type: 'none' };
    }
  }

  public async getNewDeliveryAddressModalChained(
    project: DTO.Project,
    customer: DTO.Customer,
    chainStop: 'deliveryAddress' | 'floorplan',
  ): Promise<ModalService.DeliveryAddressModalResult> {
    try {
      let projectDeliveryAddressId = await this.getNewDeliveryAddressModal(
        project,
        customer,
      );
      let deliveryAddressChildren: ModalService.FloorPlanModalResult;
      if (chainStop === 'deliveryAddress') {
        deliveryAddressChildren = { type: 'none' };
      } else {
        deliveryAddressChildren = await this.getNewFloorPlanModalChained(
          projectDeliveryAddressId,
          chainStop,
        );
      }

      if (deliveryAddressChildren.type === 'none') {
        return {
          type: 'deliveryAddress',
          projectDeliveryAddressId: projectDeliveryAddressId,
        };
      } else {
        return {
          ...deliveryAddressChildren,
          projectDeliveryAddressId: projectDeliveryAddressId,
        };
      }
    } catch (e: any) {
      //delivery address modal was cancelled
      return { type: 'none' };
    }
  }

  public async getNewFloorPlanModalChained(
    projectDeliveryAddressId: number,
    chainStop: 'floorplan',
  ): Promise<ModalService.FloorPlanModalResult> {
    let name = window.prompt(
      this.translationService.translate(
        'floorPlan_new_floorPlan_name',
        'Name: ',
      ),
      this.translationService.translate(
        'floorPlan_new_floorPlan_defaultName',
        'New Room',
      ),
    );
    if (!name) {
      return { type: 'none' };
    }
    let floorPlanPromise = this.floorPlanSaveService.createFloorPlan(
      name,
      projectDeliveryAddressId,
    );
    this.notificationService.loadPromise(floorPlanPromise);
    let floorPlan = await floorPlanPromise;

    return {
      type: 'floorPlan',
      floorPlanId: floorPlan.Id!,
    };
  }

  public getNewProjectModal(customer: DTO.Customer): NgbModalRef {
    return this.open(
      NewProjectVm,
      {
        size: 'md',
        backdrop: 'static',
        windowClass: 'detail-modal',
      },
      {
        customer: customer,
      },
    );
  }

  public getNewDeliveryAddressModal(
    project: DTO.Project,
    customer: DTO.Customer,
  ): Promise<number> {
    let modal = this.open(
      NewDeliveryAddressVm,
      {
        size: 'md',
        backdrop: 'static',
      },
      {
        project: project,
        customer: customer,
      },
    );
    return modal.result;
  }

  public getEditUserSettingsModal() {
    return this.open(UserSettingVm, {
      size: 'md',
      backdrop: 'static',
    });
  }

  public getEditStoreSettingsModal() {
    return this.open(StoreSettingVm, {
      size: 'md',
      backdrop: 'static',
    });
  }

  public getSelectProductLineModal(
    productLines: Interface_DTO.ProductLine[],
  ): Promise<Interface_DTO.ProductLine>;
  public getSelectProductLineModal(
    productLines: (Interface_DTO.ProductLine | Enums.PseudoProductLine)[],
  ): Promise<Interface_DTO.ProductLine | Enums.PseudoProductLine>;
  public getSelectProductLineModal(
    productLines: (Interface_DTO.ProductLine | Enums.PseudoProductLine)[],
  ): Promise<Interface_DTO.ProductLine | Enums.PseudoProductLine> {
    let productLinePickables = productLines.map<
      Pickable<Interface_DTO.ProductLine | Enums.PseudoProductLine>
    >((pl) => {
      if (typeof pl === 'object') {
        let expires = pl.Expires ? new Date(pl.Expires) : undefined;
        return {
          item: pl,
          name: pl.Name,
          id: '',
          disabledReason: null,
          isSelected: false,
          imageUrl: this.translationService.translateProductLineImageUrl(pl.Id),
          override: pl.OverrideChain,
          warnReason: expires
            ? this.translationService.translate(
                'productLine_selector_expires_name_date',
                'The product line {0} expires on {1}. After this date, the solution can no longer be ordered',
                pl.Name,
                expires.getDate() +
                  '/' +
                  (expires.getMonth() + 1) +
                  '/' +
                  expires.getFullYear(),
              )
            : undefined,
        };
      } else {
        //pseudoProductLine
        return {
          item: pl,
          name: this.translationService.translate(
            'productline_selector_pseudo_name_' + pl,
            pl,
          ),
          id: '',
          disabledReason: null,
          isSelected: false,
          imageUrl:
            this.translationService.translatePseudoProductLineImageUrl(pl),
          override: false,
        };
      }
    });

    let modal = this.open(
      GroupSelectorVm,
      {
        size: 'lg',
      },
      {
        items: productLinePickables,
        title: this.translationService.translate(
          'productline_selector_title',
          'Select Product Line',
        ),
        pickableClass: 'product-line',
        displayTableOfContents: false,
      },
    );
    return modal.result;
  }

  public getSelectBackingModal(
    section: Client.CabinetSection,
  ): Promise<Client.BackingSetting> {
    let modal = this.open(
      SelectBackingVm,
      {
        size: 'md',
      },
      {
        section: section,
      },
    );
    return modal.result;
  }

  public getRestoreAutosaveModal(
    serverDate: Date,
    localDate: Date,
  ): NgbModalRef {
    return this.open(
      RestoreAutosaveVm,
      {
        size: 'md',
        keyboard: false,
        backdrop: 'static',
      },
      {
        serverDate: serverDate,
        localDate: localDate,
      },
    );
  }

  public async selectColumns<T>(
    possibleColumns: { [key: string]: Readonly<TableColumn<T>> },
    selectedColumns: string[],
  ): Promise<string[] | undefined> {
    var possibleColDict: { [key: string]: string } = {};
    for (let colName in possibleColumns) {
      let col = possibleColumns[colName];
      if (col.removable) possibleColDict[colName] = col.displayName;
    }

    let modal = this.getPropertySelector(possibleColDict, selectedColumns);
    try {
      return (await modal.result).map((s: (keyof T)[]) => s.toString());
    } catch (e: any) {
      return undefined;
    }
  }

  public getPropertySelector<T extends { [key: string]: string }>(
    propertyNames: T,
    selectedKeys: (keyof T)[],
  ): NgbModalRef {
    return this.open(
      PropertySelectorVm,
      {
        size: 'md',
      },
      {
        propertyNames: propertyNames,
        selectedKeys: ObjectHelper.copy(selectedKeys),
      },
    );
  }

  public getPickableSelector<T>(
    items: Pickable<T>[],
    title: string,
    pickableClass: string,
    displayTableOfContents?: boolean,
  ): NgbModalRef & { result: Promise<T> } {
    return this.open(
      GroupSelectorVm,
      {
        size: 'lg',
      },
      {
        title: title,
        items: items,
        pickableClass: pickableClass,
        displayTableOfContents:
          displayTableOfContents === undefined ? null : displayTableOfContents,
      },
    );
  }

  public getDeliveryAddressSelector(
    startingPoint: Client.Project | Client.Customer | null,
    allowMultiple: boolean,
    displayNavigationLeft: boolean = true,
    displayNavigationRight: boolean = true,
  ) {
    return this.open(
      DeliveryAddressSelectorVm,
      {
        size: 'lg',
      },
      {
        startingPoint: startingPoint,
        allowMultiple: allowMultiple,
        displayNavigationRight: displayNavigationRight,
        displayNavigationLeft: displayNavigationLeft,
      },
    );
  }

  public getProjectSelector(
    startCustomerId: number | null,
    allowMultiple: boolean,
    displayNavigationLeft: boolean = true,
    displayNavigationRight: boolean = true,
  ): Promise<[Client.Project]> {
    let modal = this.open(
      ProjectSelectorVm,
      {
        size: 'lg',
      },
      {
        startCustomerId: startCustomerId,
        allowMultiple: allowMultiple,
        displayNavigationRight: displayNavigationRight,
        displayNavigationLeft: displayNavigationLeft,
      },
    );
    return modal.result;
  }

  private getStoreSelector() {
    return this.open(StoreSelectorVm, {
      size: 'md',
    });
  }

  public async selectStore(): Promise<StoreSelectorVm.ReturnType | undefined> {
    let ret;
    try {
      ret = await this.getStoreSelector().result;
    } catch (e: any) {
      //user pressed cancel
      return;
    }
    if (ret.floorPlanId !== null) {
      let url = '/floorplans?owner=anyone&selected=' + ret.floorPlanId;
      this.router.navigateByUrl(url);
    }
    return ret;
  }

  public getAddFittingDialog(
    showPercentOption: boolean,
    piecelistItem: Client.ConfigurationItem | undefined,
  ) {
    return this.open(
      addFittingVm,
      {
        size: 'md',
        backdrop: 'static',
      },
      {
        showPercentOption: showPercentOption,
        piecelistItem: piecelistItem,
      },
    );
  }

  public AddProducts(cabinetSection: Client.CabinetSection): Promise<void> {
    return this.open(
      AddProductVm,
      {
        size: 'lg',
        backdrop: 'static',
      },
      {
        cabinetSection: cabinetSection,
      },
    ).result;
  }

  public EditItem(
    configurationItem: Interface_DTO.ConfigurationItem,
  ): Promise<void> {
    const modal = this.open(
      EditManualItemVm,
      {
        size: 'sm',
        backdrop: 'static',
      },
      {
        configurationItem: configurationItem,
      },
    );
    return modal.result;
  }

  private getAddProductDialog(
    cabinetSection: Client.CabinetSection,
    updateProduct: Interface_DTO.ConfigurationItem | null,
  ) {}

  public getPrintDialog(floorPlan: Client.FloorPlan | undefined) {
    return this.open(
      PrintDialogVm,
      {
        size: 'md',
        backdrop: 'static',
      },
      {
        floorPlan: floorPlan,
      },
    );
  }

  public getTemplateSelector(cabinetSection: Client.CabinetSection) {
    return this.open(
      FavouriteSelectorVm,
      {
        size: 'lg',
      },
      {
        cabinetSection: cabinetSection,
      },
    );
  }

  public getTemplateSaver(cabinetSection: Client.CabinetSection) {
    return this.open(
      TemplateSaveVm,
      {
        size: 'md',
        backdrop: 'static',
      },
      {
        cabinetSection: cabinetSection,
      },
    );
  }

  public getLinkBrowser() {
    return this.open(LinkBrowserModalVm, {
      size: 'xl',
      windowClass: 'xl-modal',
      scrollable: true,
    });
  }

  public async acceptDeliveryInfo(
    deliveryInfo: Interface_DTO.DeliveryInfo,
    name: string,
    wasDeliveryWeekChanged: boolean,
    currentDeliveryStatus: Interface_Enums.DeliveryStatus,
    deliveryAddress?: Interface_DTO.DeliveryAddress,
  ): Promise<boolean> {
    if (!deliveryInfo.CorrectionDeadlineDate) {
      throw new Error(
        'Unable to calculate correction deadline - acceptance of delivery info not possible',
      );
    }
    try {
      let modal = this.open(
        AcceptDeliveryInfoVm,
        {
          size: 'lg',
        },
        {
          deliveryInfo: deliveryInfo,
          currentDeliveryStatus: currentDeliveryStatus,
          name: name,
          wasDeliveryWeekChanged: wasDeliveryWeekChanged,
          deliveryAddress,

          // deliveryAddress: =>
          // deliveryAddress || deliveryAddressService.getDeliveryAddressForDeliveryInfoId(deliveryInfo.Id)
        },
      );
      let result = await modal.result;
      return result;
    } catch (e: any) {
      console.warn('modal error: ', e);
      //User probably clicked outside modal
      return false;
    }
  }

  public importEndUserSetup(): Promise<EndUserSetupOverview> {
    let modal = this.open(EndUserSetupImportVm, {
      size: 'md',
    });
    return modal.result;
  }

  public getItemDepths(items: Client.ConfigurationItem[]): Promise<void> {
    let modal = this.open(
      ItemDepthVm,
      {
        size: 'sm',
      },
      {
        items: items,
      },
    );
    return modal.result;
  }

  public setFreeSpace(cabinetSection: Client.CabinetSection): Promise<void> {
    let modal = this.open(
      FreeSpaceVm,
      {
        size: 'sm',
        backdrop: 'static',
      },
      {
        cabinetSection: cabinetSection,
      },
    );
    return modal.result;
  }

  public resetPassword(): Promise<void> {
    console.log('RESET');
    let modal = this.open(PasswordResetRequestVm, {
      size: 'md',
      backdrop: 'static',
    });
    return modal.result;
  }

  public setJointPositions(
    cabinetSection: Client.CabinetSection,
  ): Promise<void> {
    let modal = this.open(
      JointPositionsVm,
      {
        size: 'sm',
        backdrop: 'static',
      },
      {
        cabinetSection: cabinetSection,
      },
    );
    return modal.result;
  }

  public setCustomPriceDate(): Promise<void> {
    let modal = this.open(CustomPriceDateVm, {
      size: 'lg',
      backdrop: 'static',
    });
    return modal.result;
  }

  public async displaySaveAsDialog(
    floorPlan: Client.FloorPlan,
  ): Promise<Client.DeliveryAddress> {
    let modal = this.getDeliveryAddressSelector(null, false);
    let deliveryAddress = (await modal.result)[0];

    let movePromise = this.floorPlanService.moveFloorPlans(
      [{ Id: floorPlan.Id!, IsLegacyFloorPlan: false }],
      deliveryAddress.ProjectDeliveryAddressId,
      (deliveryInfo) => Promise.resolve(true),
    );
    await this.notificationService.loadPromise(movePromise);
    floorPlan.isAddressSet = true;
    floorPlan.ProjectDeliveryAddressId =
      deliveryAddress.ProjectDeliveryAddressId;
    return deliveryAddress;
  }

  public async displayUserMessages(userMessages: Interface_DTO.UserMessage[]) {
    for (let um of userMessages) {
      try {
        let modal = this.open(
          UserMessageVm,
          {
            size: 'lg',
          },
          {
            userMessage: um,
          },
        );
        await modal.result;
      } catch (e: any) {
        //user cancelled
      }
    }
  }

  public async getFloorPlanTemplateModal(): Promise<Interface_DTO.FloorPlanTemplate> {
    let modal = this.open(FloorPlanTemplateVm, {
      size: 'md',
    });
    return await modal.result;
  }

  public async editDeliveryInfo(
    deliveryInfoChanges: Client.DeliveryInfoChanges,
  ) {
    let modal = this.open(
      DeliveryInfoEditorVm,
      {
        size: 'md',
      },
      {
        deliveryInfoChanges: deliveryInfoChanges,
      },
    );

    return modal.result;
  }

  public async consumerLogin(): Promise<boolean> {
    let modal = this.open(ConsumerLoginVm, {
      size: 'sm',
    });

    try {
      let r1 = await modal.result;
      if (r1.value === 'success') {
        return true;
      } else if (r1.value === 'newUser') {
        let createUserSuccess = await this.consumerNewUser(
          r1.username,
          r1.password,
        );
        if (createUserSuccess) return true;
        return await this.consumerLogin();
      }
      throw new Error('Not implemented');
    } catch (e: any) {
      //user cancelled
      return false;
    }
  }

  public async consumerNewUser(
    suggestedUsername: string,
    suggestedPassword: string,
  ): Promise<boolean> {
    let modal = this.open(
      ConsumerNewUserVm,
      {
        size: 'sm',
      },
      {
        suggestedUsername: suggestedUsername,
        suggestedPassword: suggestedPassword,
      },
    );
    await modal.result;
    return true;
  }

  public async customerSelectDoorProfile(
    doorProfiles: Pickable<Client.Product | null>[],
    currentProfile: Client.Product | null,
  ): Promise<Modals.ConsumerDoorProfileVmReturnType | null> {
    const modal = this.open(
      ConsumerDoorProfileVm,
      {
        size: 'lg',
      },
      {
        doorProfilesParameter: doorProfiles,
        currentProfile: currentProfile,
      },
    );

    try {
      return await modal.result;
    } catch (ex) {
      //user cancelled
      return null;
    }
  }

  public async consumerErrorsModal(
    errors: Client.ErrorInfo[],
  ): Promise<Client.ErrorInfo | null> {
    let modal = this.open(
      ConsumerErrorsModalVm,
      {
        size: 'md',
      },
      {
        errors: errors,
      },
    );
    try {
      return await modal.result;
    } catch (ex) {
      //user cancelled
      return null;
    }
  }

  public async consumerSaveFloorPlan(
    suggestedName: string,
  ): Promise<string | null> {
    let modal = this.open(
      ConsumerSaveFloorPlanVm,
      {
        size: 'sm',
      },
      {
        suggestedName: suggestedName,
      },
    );
    try {
      return await modal.result;
    } catch (e: any) {
      //user cancelled
      return null;
    }
  }

  private open(
    content: any,
    options?: NgbModalOptions,
    properties?: object,
  ): NgbModalRef {
    let dialog = this.modalService.open(content, options);

    if (properties != null) {
      for (const [propName, value] of Object.entries(properties)) {
        dialog.componentInstance[propName] = value;
      }
    }

    return dialog;
  }
}

export module ModalService {
  export type FloorPlanModalResult =
    | { type: 'none' }
    | {
        type: 'floorPlan';
        floorPlanId: number;
      };
  export type DeliveryAddressModalResult =
    | { type: 'none' }
    | {
        type: 'deliveryAddress';
        projectDeliveryAddressId: number;
      }
    | (FloorPlanModalResult & { projectDeliveryAddressId: number });

  export type ProjectModalResult =
    | { type: 'none' }
    | {
        type: 'project';
        projectId: number;
      }
    | (DeliveryAddressModalResult & { projectId: number });
  export type CustomerModalResult =
    | { type: 'none' }
    | {
        type: 'customer';
        customerId: number;
      }
    | (ProjectModalResult & { customerId: number });
}
