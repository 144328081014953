import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModelOptions } from 'app/ng/ModelOptions';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { EditorVm } from './EditorVm';
import { WindowService } from 'app/ng/window.service';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { FloorPlanSaveService } from 'app/ts/services/FloorPlanSaveService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { RouteService } from 'app/ts/services/RouteService';

@Component({
  selector: 'floor-plan-properties',
  templateUrl: 'floor-plan-property-sheet.component.html',
  styleUrls: ['../../style/editor.scss'],
})
export class FloorPlanPropertySheetComponent extends EditorVm {
  constructor(
    private partitionQuery: PartitionPlanQueryService,
    baseVmService: BaseVmService,
    floorPlanService: FloorPlanService,
    floorPlanSaveService: FloorPlanSaveService,
    $window: WindowService,
    routeService: RouteService,
    routing: AppRoutingModule,
    deliveryAddressService: DeliveryAddressService,
  ) {
    super(
      baseVmService,
      $window,
      floorPlanService,
      floorPlanSaveService,
      routeService,
      routing,
      deliveryAddressService,
    );
  }

  protected floorPlanChanged() {}

  @Input()
  public get floorPlanName() {
    return this._floorPlanName;
  }
  public set floorPlanName(value: string) {
    const hasChanged = value != this.floorPlanName;
    this._floorPlanName = value;
    if (hasChanged) this.floorPlanNameChange.emit(value);
  }
  private _floorPlanName: string = '';

  @Output()
  public floorPlanNameChange = new EventEmitter<string>();

  @Input()
  public get floorPlanSizeX() {
    return this._floorPlanSizeX;
  }
  public set floorPlanSizeX(value: number) {
    this._floorPlanSizeX = value;
    this.floorPlanSizeXChange.emit(value);
  }
  private _floorPlanSizeX: number = 0;

  @Output()
  public floorPlanSizeXChange = new EventEmitter<number>();

  @Input()
  public get floorPlanSizeY() {
    return this._floorPlanSizeY;
  }
  public set floorPlanSizeY(value: number) {
    this._floorPlanSizeY = value;
    this.floorPlanSizeYChange.emit(value);
  }
  private _floorPlanSizeY: number = 0;

  @Output()
  public floorPlanSizeYChange = new EventEmitter<number>();

  @Input()
  public get floorPlanSizeZ() {
    return this._floorPlanSizeZ;
  }
  public set floorPlanSizeZ(value: number) {
    this._floorPlanSizeZ = value;
    this.floorPlanSizeZChange.emit(value);
  }
  private _floorPlanSizeZ: number = 0;

  @Output()
  public floorPlanSizeZChange = new EventEmitter<number>();

  @Input()
  public get corners(): CornerVm[] {
    return this._corners;
  }

  public set corners(corners: CornerVm[]) {
    this._corners = corners;
  }

  private _corners: CornerVm[] = [];

  public override readonly modelOptions: ModelOptions = {
    updateOn: 'change',
  };

  public override readonly modelOptionsBlur: ModelOptions = {
    updateOn: 'blur',
  };

  public getCornerImagePath(index: number) {
    return `assets/images/corners/Corner${index}.png`;
  }

  public readonly cornerTypes = [
    Interface_Enums.CornerType.Normal,
    Interface_Enums.CornerType.Half,
    Interface_Enums.CornerType.Inverted,
  ];

  public trackByCornerType(
    index: number,
    cornerType: Interface_Enums.CornerType,
  ) {
    return cornerType;
  }

  public updateFloorPlanHeight(event: any) {
    if (this.partitionQuery.getAllSections().length > 0) {
      let min = this.partitionQuery.getModuleMinHeight();
      let max = this.partitionQuery.getModuleMaxHeight();
      if (max < min) max = min;
      this.floorPlanSizeZ = Math.min(
        Math.max(min, event.target.valueAsNumber),
        max,
      );
      event.target.value = this.floorPlanSizeZ;
    } else {
      this.floorPlanSizeZ = event.target.valueAsNumber;
      event.target.value = this.floorPlanSizeZ;
    }
  }
}

export class CornerVm {
  constructor(
    private readonly corner: Interface_DTO_FloorPlan.Corner,
    public readonly setChanged: () => void,
  ) {}

  public get type() {
    return this.corner.CornerType;
  }
  public set type(val: Interface_Enums.CornerType) {
    this.corner.CornerType = val;
    this.setChanged();
  }

  public get sizeX() {
    return this.corner.Size.X;
  }
  public set sizeX(val: number) {
    this.corner.Size.X = val;
    this.setChanged();
  }

  public get sizeY() {
    return this.corner.Size.Y;
  }
  public set sizeY(val: number) {
    this.corner.Size.Y = val;
    this.setChanged();
  }
}
