import { Injectable } from '@angular/core';
import { Constants } from 'app/ts/Constants';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import Enumerable from 'linq';
import { Door, DoorPlacement, Module, ModuleId } from './module';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import { Section, SectionId } from './section';
import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import { Rail } from './rail';
import { DoorOpenSide } from 'app/ts/clientDto/Enums';

@Injectable({
  providedIn: 'root',
})
export class PartitionValidationCalculationService {
  constructor(private query: PartitionPlanQueryService) {}

  public getPointsForSection(
    sectionId: SectionId,
    withTolerance: boolean,
  ): { X: number; Y: number }[] {
    let section = this.query.getSection(sectionId);
    let modules = this.query.getModulesForSection(section.id);

    let hasBackDoor = modules.some(
      (md) => md instanceof Door && md.placement == DoorPlacement.Back,
    );
    let topYOffset = hasBackDoor ? -Module.fullJointWidth : 0;
    let hasFrontDoor = modules.some(
      (md) => md instanceof Door && md.placement == DoorPlacement.Front,
    );
    let bottomYOffset = hasFrontDoor ? Module.fullJointWidth : 0;

    let points: { X: number; Y: number }[] = [
      this.getSectionRelativeTopPoint(sectionId, 0, topYOffset),
      this.getSectionRelativeTopPoint(sectionId, section.width, topYOffset),
      this.getSectionRelativeBottomPoint(
        sectionId,
        section.width,
        bottomYOffset,
      ),
      this.getSectionRelativeBottomPoint(sectionId, 0, bottomYOffset),
    ];

    return points;
  }

  public getDoorPoints(moduleId: ModuleId): Vec2d[] {
    let module = this.query.getModule(moduleId) as Door;
    let section = this.query.getSection(module.sectionId);

    let xOffset = module.posX;
    let yOffset = 0;

    let leftOffset = 0;
    let rightOffset = 0;

    if (module.openDirection == DoorOpenSide.Left) {
      leftOffset = -module.leftSlideExtensionAmount;
    } else if (module.openDirection == DoorOpenSide.Right) {
      rightOffset = module.rightSlideExtensionAmount;
    } else if (module.openDirection == DoorOpenSide.Both) {
      leftOffset = -module.leftSlideExtensionAmount;
      rightOffset = module.rightSlideExtensionAmount;
    }

    let isFrontDoor =
      module instanceof Door && module.placement == DoorPlacement.Front;
    let isBackDoor =
      module instanceof Door && module.placement == DoorPlacement.Back;
    if (isFrontDoor) yOffset = Module.fullJointWidth;
    else if (isBackDoor) yOffset = -Module.fullJointWidth;

    let points: { X: number; Y: number }[] = [
      this.getSectionRelativeTopPoint(
        section.id,
        xOffset + leftOffset,
        yOffset,
      ),
      this.getSectionRelativeTopPoint(
        section.id,
        xOffset + module.width + rightOffset,
        yOffset,
      ),
      this.getSectionRelativeBottomPoint(
        section.id,
        xOffset + module.width + rightOffset,
        yOffset,
      ),
      this.getSectionRelativeBottomPoint(
        section.id,
        xOffset + leftOffset,
        yOffset,
      ),
    ];

    return points;
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative bottom side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeBottomPoint(
    sectionId: SectionId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let section = this.query.getSection(sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) - offsetY,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetX,
        };
      case 180:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetX,
          Y: this.getSectionGlobalTopSideY(sectionId) - offsetY,
        };
      case 270:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) + offsetY,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetX,
          Y: this.getSectionGlobalBottomSideY(sectionId) + offsetY,
        };
    }
  }

  /**
   * Returns the point corresponding to the origin of a rotated 3d model,
   * along the relative top side of the section.
   * @param offsetX How much to offset on the x-axis of the rotated section
   */
  getSectionRelativeTopPoint(
    sectionId: SectionId,
    offsetX: number = 0,
    offsetY: number = 0,
  ): Vec2d {
    let section = this.query.getSection(sectionId);

    switch (section.rotation) {
      case 90:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetY,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetX,
        };
      case 180:
        return {
          X: this.getSectionGlobalRightSideX(sectionId) - offsetX,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetY,
        };
      case 270:
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetY,
          Y: this.getSectionGlobalBottomSideY(sectionId) - offsetX,
        };
      default: //0 deg
        return {
          X: this.getSectionGlobalLeftSideX(sectionId) + offsetX,
          Y: this.getSectionGlobalTopSideY(sectionId) + offsetY,
        };
    }
  }

  /**
   * Always returns the x value ofthe left side of the section, no matter the rotation
   */
  getSectionGlobalLeftSideX(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posX - sectionDepth;
      case 180:
        return section.posX - section.width;
      case 270:
        return section.posX;
      default: //0 deg
        return section.posX;
    }
  }

  /**
   * Always returns the x value ofthe right side of the section, no matter the rotation
   */
  getSectionGlobalRightSideX(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posX;
      case 180:
        return section.posX;
      case 270:
        return section.posX + sectionDepth;
      default: //0 deg
        return section.posX + section.width;
    }
  }

  /**
   * Always returns the y value ofthe top side of the section, no matter the rotation
   */
  getSectionGlobalTopSideY(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posY;
      case 180:
        return section.posY - sectionDepth;
      case 270:
        return section.posY - section.width;
      default: //0 deg
        return section.posY;
    }
  }

  /**
   * Always returns the y value ofthe bottom side of the section, no matter the rotation
   */
  getSectionGlobalBottomSideY(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let section = this.query.getSection(sectionId);
    let sectionDepth = this.getSectionDepth(sectionId, includeDoors);
    switch (section.rotation) {
      case 90:
        return section.posY + section.width;
      case 180:
        return section.posY;
      case 270:
        return section.posY;
      default: //0 deg
        return section.posY + sectionDepth;
    }
  }

  public getSectionDepth(
    sectionId: SectionId,
    includeDoors: boolean = false,
  ): number {
    let modules = this.query
      .getAllModules()
      .filter((md) => md.sectionId == sectionId);

    let door = modules.find((md) => md.isDoor);
    // TODO: Needs refining of numbers vvv to match exact value
    return includeDoors && door ? door.depth * 2 : modules[0].depth;
  }
}
