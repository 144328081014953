import * as Interface_DTO from 'app/ts/Interface_DTO';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
export class DoorSetup {
  constructor(readonly railPositions: number[]) {}

  public get rails(): number {
    return this.railPositions.filter((v, i, a) => a.indexOf(v) === i).length;
  }

  public get doors(): number {
    return this.railPositions.length;
  }

  public get overlaps(): number {
    let count = 0;

    for (var i = 0; i < this.railPositions.length - 1; i++) {
      if (this.railPositions[i] !== this.railPositions[i + 1]) {
        count++;
      }
    }

    return count;
  }

  public get alternating(): boolean {
    return this.overlaps === this.doors - 1;
  }

  public get validForCornerLeft(): boolean {
    return Enumerable.from(this.railPositions).first() === this.rails;
  }

  public get validForCornerRight(): boolean {
    return Enumerable.from(this.railPositions).last() === this.rails;
  }

  public totalOverlapWidth(overlapWidth: number): number {
    return overlapWidth * this.overlaps;
  }

  public getMinPossibleWidth(
    product: Client.Product,
    overlapWidth: number,
  ): number {
    return 0;
  }

  public getMaxPossibleWidth(
    product: Client.Product,
    overlapWidth: number,
    maxFillingWidth: number,
  ): number {
    return 0;
  }

  public supportsWidthForStandardDoors(
    width: number,
    doorWidths: Interface_DTO.DoorWidth[],
    minOverlap: number,
    maxOverlap: number,
  ): boolean {
    let minTotalOverlap = this.totalOverlapWidth(minOverlap);
    let maxTotalOverlap = this.totalOverlapWidth(maxOverlap);

    for (let doorWidth of doorWidths.filter((dw) => dw.IsStandardDoor)) {
      if (
        width <= doorWidth.Width * this.doors - minTotalOverlap &&
        width >= doorWidth.Width * this.doors - maxTotalOverlap
      ) {
        return true;
      }
    }

    return false;
  }

  public supportsWidth(
    width: number,
    minDoorWidth: number,
    maxDoorWidth: number,
    overlapWidth: number,
    ignoreMaxWidth: boolean,
  ): boolean {
    let minPossibleWidth =
      minDoorWidth * this.doors - this.totalOverlapWidth(overlapWidth);
    let maxPossibleWidth =
      maxDoorWidth * this.doors - this.totalOverlapWidth(overlapWidth);

    return (
      width >= minPossibleWidth && (ignoreMaxWidth || width <= maxPossibleWidth)
    );
  }

  public reversedClone(): DoorSetup {
    return new DoorSetup(this.railPositions.slice().reverse());
  }
}
