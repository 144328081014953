import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { NavigateService } from 'app/routing/navigate-service';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { CustomerBaseVmService } from 'app/ts/services/CustomerBaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ProjectService } from 'app/ts/services/ProjectService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ProjectsTableVm } from 'app/ts/viewmodels/components/ProjectsTableVm';
import { CustomerBaseVm } from 'app/ts/viewmodels/CustomerBaseVm';
import Enumerable from 'linq';

@Component({
  selector: 'projects-vm',
  templateUrl: './projects.html',
  styleUrls: [
    '../../../style/briefStyling.scss',
    '../../../style/overviews.scss',
    '../../../style/IE-hacks.scss',
    '../../../style/floorplans.scss',
    '../../../style/rightMenu.scss',
    '../../../style/tables.scss',
    '../../../style/nav.scss',
  ],
  providers: [activeUserProvider, clientSettingProvider],
})
export class ProjectsVm extends CustomerBaseVm<DTO.Project> {
  public objects: Client.Project[] = [];

  public editingProject: DTO.Project | undefined;

  constructor(
    customerBaseVmService: CustomerBaseVmService,
    router: Router,
    activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly notificationService: NotificationService,
    private readonly translationService: TranslationService,
    private readonly formattingService: FormattingService,
    private readonly navigate: NavigateService,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(ClientSettingInjector) clientSettings: ClientSetting,
  ) {
    super(
      customerBaseVmService,
      router,
      activatedRoute,
      activeUser,
      clientSettings,
    );
  }

  public override setSelected(clientProject: Client.Project) {
    super.setSelected(clientProject);
    if (!this.editingProject || this.editingProject.Id !== clientProject.Id) {
      this.editingProject = ObjectHelper.copy(clientProject.dtoObject);
    }
  }

  public override closeRightMenu() {
    super.closeRightMenu();
    this.editingProject = undefined;
  }

  protected onSearchUpdated(customers: Client.Customer[]) {
    this.objects = Enumerable.from(customers)
      .selectMany((c) => c.Projects)
      .toArray();
    let hashIds = this.hashIds;
    for (let obj of this.objects) {
      if (hashIds[obj.Id]) {
        obj.selected = true;
        if (!this.editingProject || this.editingProject.Id !== obj.Id) {
          this.editingProject = ObjectHelper.copy(obj.dtoObject);
        }
      }
    }
  }

  public get parentObject(): Client.Customer | null {
    let customerId = this.filterParams.customerId;
    if (!customerId) return null;
    let customerIdNumber = parseInt(customerId);
    for (let customer of this.allCustomers) {
      if (customer.Id === customerIdNumber) {
        return customer;
      }
    }
    return null;
  }

  public async newObject() {
    let customer = this.parentObject;
    if (!customer)
      throw new Error("Don't know which customer to add project to");
    try {
      let modalResult = await this.modalService.getNewProjectModalChained(
        customer,
        'floorplan',
      );

      switch (modalResult.type) {
        case 'none':
          return;
        case 'project':
          const deliveryAddressesQuery = {
            ...this.filterParams,
            projectId: modalResult.projectId.toString(),
          };
          this.navigate.deliveryAddresses(deliveryAddressesQuery);
          return;

        case 'deliveryAddress':
          const query = {
            ...this.filterParams,
            deliveryAddressId: modalResult.projectDeliveryAddressId.toString(),
          };
          this.navigate.floorPlans(query);
          return;

        case 'floorPlan':
          this.navigate.floorPlan(modalResult.floorPlanId);
          return;

        default:
          throw new Error(
            'Unknown modal result type: ' + (<any>modalResult).type,
          );
      }
    } catch (e: any) {
      //ignore - user closed modal
    }
  }

  public async selectColumns() {
    let possibleColumns = ProjectsTableVm.getColumns(
      this.translationService,
      this.formattingService,
    );

    let currentColumnNames = this.clientSettingsValue.columnsProject;
    let newCols = await this.modalService.selectColumns(
      possibleColumns,
      currentColumnNames,
    );
    if (newCols) {
      const newSettings = {
        ...this.clientSettings.data,
      };
      newSettings.columnsProject = newCols;
      this.clientSettings.set(newSettings);
    }
  }

  public showAncestor(project: Client.Project) {
    this.navigate.customers({
      ...this.filterParams,
      projectId: undefined,
      customerId: undefined,
      selected: [project.customer.Id.toString()],
    });
  }

  public showChildren(project: Client.Project) {
    this.navigate.deliveryAddresses({
      ...this.filterParams,
      projectId: project.Id.toString(),
    });
  }

  public async saveSingle(project: Client.Project) {
    try {
      await this.projectService.updateProject(project);
      this.notificationService.success(
        'projects_update_complete',
        'Project saved',
      );
      this.closeRightMenu();
    } catch (e: any) {
      if (e instanceof Exception.PropertyInvalid) {
        this.notificationService.userError(
          'project_edit_error_invalid_property_' + e.propertyName,
          'Invalid property: {0}',
          e.propertyName,
        );
      }
    }
  }

  public async deleteSelected() {
    let sure = confirm(
      this.translate(
        'project_confirm_delete',
        'Are you sure you want to delete this project?',
      ),
    );
    if (!sure) return;

    let projectIds = this.selectedObjects.map((proj) => proj.dtoObject.Id);
    try {
      await this.notificationService.loadPromise(
        this.projectService.deleteProjects(projectIds),
      );
      this.closeRightMenu();
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          this.notificationService.userError(
            'project_delete_fail_active_order',
            'Project cannot be deleted because it has active orders',
          );
          return;
        }
      }
      this.notificationService.exception(
        'project_delete_unknown_error',
        e,
        'Unknown error while deleting project',
      );
    }
  }

  protected getId(o: DTO.Project): number {
    return o.Id;
  }
}
