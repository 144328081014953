import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Exception from 'app/ts/exceptions';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ProjectService } from 'app/ts/services/ProjectService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'project-edit-menu',
  templateUrl: './projectEditMenu.html',
  providers: [clientSettingProvider, CustomerSearchParams],
  styleUrls: [
    '../../../../style/rightMenu.scss',
    '../../../../style/modal.scss',
  ],
})
export class ProjectEditMenuVm extends BaseVm {
  public showShowChildren: boolean = false;

  constructor(
    baseVmService: BaseVmService,
    private readonly customerService: CustomerService,
    private readonly projectService: ProjectService,
    private readonly notificationService: NotificationService,
    private readonly $routeParams: CustomerSearchParams,
    private routing: AppRoutingModule,
    @Inject(ClientSettingInjector) private clientSettings: ClientSetting,
  ) {
    super(baseVmService);
    super.ensureUnsubscribe(
      this.clientSettings.subscribe((settings) => {
        this.showShowChildren = settings.showExtraOverviewTabs;
      }),
    );
  }

  @Input()
  public project: DTO.Project | undefined;

  @Output()
  public onClose = new EventEmitter<void>();

  public async save() {
    if (!this.project) {
      return;
    }
    let success = false;
    try {
      await this.projectService.updateProject(this.project);
      success = true;
    } catch (e: any) {
      if (e instanceof Exception.PropertyInvalid) {
        this.notificationService.userError(
          'projects_update_failed_invalid_property',
          'Missing property',
        );
      } else {
        this.notificationService.exception(
          'projects_update_failed_unknown',
          e,
          'Updating project failed for unkown reasons',
        );
      }
    }
    if (success) {
      this.notificationService.success(
        'projects_update_complete',
        'Project saved',
      );
      this.close();
      this.customerService.updateCustomerList();
    }
  }

  public async delete() {
    if (!this.project) {
      return;
    }
    let sure = confirm(
      this.translate(
        'project_confirm_delete',
        'Are you sure you want to delete this project?',
      ),
    );
    if (!sure) return;
    try {
      let deleteTask = this.projectService.deleteProjects([this.project.Id]);
      this.notificationService.loadPromise(deleteTask);
      await deleteTask;
      this.close();
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          this.notificationService.userError(
            'project_delete_fail_active_order',
            'Project cannot be deleted because there are active orders',
          );
          return;
        }
      }
      this.notificationService.exception(
        'project_delete_unknown_error',
        e,
        'Unknown error',
        { projectId: this.project.Id },
      );
    }
  }

  public close() {
    this.onClose.emit();
  }

  public showChildren() {
    if (!this.project) return;
    this.routing.navigate.deliveryAddresses({
      ...this.$routeParams,
      customerId: this.project.Id.toString(),
    });
  }
}
