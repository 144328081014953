import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import * as Enums from 'app/ts/clientDto/Enums';
import { Translatable } from 'app/ts//clientDto/Translatable';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface ITranslationService {
  translate(key: string, defaultValue: string, ...args: string[]): string;
  translate(translation: Client.Translatable): string;
}

@Injectable({ providedIn: 'root' })
export class TranslationService implements ITranslationService {
  private loaded: boolean = false;

  private dictionary: { [id: string]: string } = {};
  private missingKeys: string[] = [];
  private get debugTranslations() {
    return App.debug.showRawTranslationKeys;
  }

  private loadTranslationsPromise?: Promise<void> = undefined;

  private _translationsUpdated$: Subject<void> = new Subject();
  public get translationsUpdated$(): Observable<void> {
    return this._translationsUpdated$;
  }

  constructor(private readonly $http: PromisingBackendService) {}

  public async loadTranslations(): Promise<void> {
    return this.$http
      .get<Interface_DTO.TranslationLine[]>('api/translation')
      .then((lines) => {
        this.dictionary = {};
        for (var line of lines) {
          if (line) this.dictionary[line.Key.toLowerCase()] = line.Value;
        }

        this.loaded = true;
        this._translationsUpdated$.next();
      });
  }

  public async translateAsync(
    key: string | null | undefined,
    defaultValue?: string,
    ...args: (string | number)[]
  ): Promise<string> {
    if (!this.loaded) await this.loadTranslations();
    else if (this.loadTranslationsPromise) await this.loadTranslationsPromise;

    return this.translate(key ?? null, defaultValue, ...args);
  }

  public translate(
    key: null,
    defaultValue?: string,
    ...args: (string | number | null)[]
  ): string;
  public translate(
    translation: Translatable | null,
    defaultValue?: string,
    ...args: (string | number | null)[]
  ): string;
  public translate(
    key: string | null,
    defaultValue?: string,
    ...args: (string | number | null)[]
  ): string;
  public translate(
    key: string | null | Translatable,
    defaultValue?: string,
    ...args: (string | number | null)[]
  ): string;
  public translate(
    key: string | Client.Translatable | null,
    defaultValue?: string,
    ...args: (string | number)[]
  ): string {
    if (!key) {
      return '';
    }

    if (key instanceof Client.Translatable) {
      return this.translate(key.key, key.defaultText, ...key.params);
    }

    let translation = '';

    if (this.debugTranslations) return key;

    if (!this.dictionary) {
      translation = typeof defaultValue === 'string' ? defaultValue : key;
    } else if (!App.debug.useDefaultTranslationsAlways) {
      let lKey = key.toLowerCase();
      translation = this.dictionary[lKey];

      if (translation === undefined) {
        if (typeof defaultValue === 'string') {
          this.dictionary[lKey] = defaultValue;
        }
        this.keyMiss(key, defaultValue);

        translation = typeof defaultValue === 'string' ? defaultValue : key;
      }
    } else {
      translation = defaultValue || key;
    }

    return this.replaceArgs(translation, args);
  }

  public keyExists(key: string): boolean {
    if (!this.dictionary) return false;
    let translation = this.dictionary[key.toLowerCase()];
    return typeof translation !== 'undefined';
  }

  private replaceArgs(s: string, args?: (string | number)[]): string {
    if (!args || args.length === 0) return s;
    return s.replace(/\{(\d+)\}/g, (match, digits) => {
      let num = parseInt(digits);
      let arg = args[num];
      if (arg == null) return match;
      if (typeof arg == 'number') return arg.toString();
      return arg;
    });
  }

  private async keyMiss(key: string, defaultValue?: string) {
    if (this.missingKeys.indexOf(key) < 0) {
      this.missingKeys.push(key);

      let newValue = await this.$http.post<string>(
        'api/translation',
        <Interface_DTO.MissingTranslation>{
          Key: key,
          SuggestedValue: defaultValue || null,
        },
        { responseType: 'text' },
      );
      this.dictionary[key.toLowerCase()] = newValue;
    }
  }

  public loadLanguages(): Promise<Interface_DTO.Language[]> {
    return this.$http.get<Interface_DTO.Language[]>('api/language');
  }

  public translateDeliveryStatus(
    deliveryStatus: Interface_Enums.DeliveryStatus,
  ): string {
    return this.translate(
      'floorplan_status_name_' + deliveryStatus,
      'Status ' + deliveryStatus,
    );
  }

  public translateProductLineImageUrl(
    id: Interface_Enums.ProductLineId | null,
  ): string {
    const imagesRoot = 'staticAssets/';
    return (
      imagesRoot +
      this.translate(
        'productline_selector_imageUrl_id_' + (id?.toString() ?? ''),
        'images/productline_pickable_default.jpg',
      )
    );
  }

  public translatePseudoProductLineImageUrl(
    id: Enums.PseudoProductLine,
  ): string {
    const imagesRoot = 'staticAssets/';
    return (
      imagesRoot +
      this.translate(
        'productline_selector_pseudo_imageUrl_' + id,
        'images/productline_pickable_default.jpg',
      )
    );
  }

  /**
   * Base dir for all chain-specific consumer files. Normally `/consumer_files/default` without trailing slash
   */
  public getConsumerBaseDir(): string {
    return (
      '/staticAssets/images' +
      this.translate('consumer_base_dir', '/consumer_files/default')
    );
  }
  public getConsumerFilePath(...pathSegments: string[]): string {
    let result = this.getConsumerBaseDir() + '/';
    for (let p of pathSegments) {
      result = result + p;
    }
    return result;
  }
}
