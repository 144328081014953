import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { RecalculationQuestionType } from 'app/ts/clientDto/Enums';
export interface RecalculationMessage {
  readonly key: string;
  readonly defaultValue: string;
  readonly params?: string[];
  readonly severity: Enums.RecalculationMessageSeverity;
  readonly cabinetSection?: Client.CabinetSection;
  readonly editorSection?: Enums.EditorSection;
  readonly item?: Client.ConfigurationItem;
}

export interface RecalculationQuestion extends RecalculationMessage {
  question: RecalculationQuestionType;
  action: (answer: boolean) => void;
  severity: Enums.RecalculationMessageSeverity.Question;
}

export module RecalculationMessage {
  export function isQuestion(
    message: RecalculationMessage,
  ): message is RecalculationQuestion {
    let q = message as RecalculationQuestion;
    return !!q.question && !!q.action;
  }
}
