import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { ModelOptions } from 'app/ng/ModelOptions';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { ModalService } from 'app/ts/services/ModalService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'delivery-address-selector-vm',
  templateUrl: './deliveryAddressSelector.html',
  styleUrls: ['../../../../style/tables.scss', '../../../../style/ribbon.scss'],
  providers: [
    activeStoreProvider,
    activeUserProvider,
    clientSettingProvider,
    DeliveryAddressService,
  ],
})
export class DeliveryAddressSelectorVm extends BaseVm implements OnInit {
  public selectionMarker: 'radio' | 'checkbox' | undefined;
  private currentStore: Interface_DTO.Store | null = null;

  private _customers: Client.Customer[] = [];
  public get customers() {
    return this._customers;
  }
  public set customers(val: Client.Customer[]) {
    this._customers = val;
  }
  public isCreatingNewCustomer = false;
  public isCreatingNewProject = false;
  public isCreatingNewDeliveryAddress = false;
  public selectedCustomer: Client.Customer | null = null;
  public selectedProject: Client.Project | null = null;
  public selectedDeliveryAddreses: Client.DeliveryAddress[] = [];
  public displayNavigationLeft!: boolean;
  public displayNavigationRight!: boolean;
  public _showRadioButton: boolean = true;
  get showRadioButtont() {
    return this._showRadioButton;
  }

  @Input()
  set showRadioButton(showRadio: boolean) {
    this._showRadioButton = showRadio;
  }
  public startingPoint!: Client.Project | Client.Customer;
  public allowMultiple!: boolean;

  public quickSearchModelOptions: ModelOptions = {
    updateOn: 'change',
  };
  public storeDeliveryAddress: Interface_DTO.DeliveryAddress | null = null;

  private searchObservable$ = new BehaviorSubject<CustomerSearchParams>({
    owner: Constants.overview.anyOwnerSearchString,
  });

  constructor(
    baseVmService: BaseVmService,
    private readonly customerService: CustomerService,
    private readonly modalService: ModalService,
    private readonly activeModal: NgbActiveModal,
    private readonly deliveryAddressService: DeliveryAddressService,
    @Inject(ActiveStoreInjector) private readonly activeStore: ActiveStore,
    @Inject(ActiveUserInjector) private readonly activeUser: ActiveUser,
    @Inject(ClientSettingInjector)
    private readonly clientSettings: ClientSetting,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    if (this.startingPoint instanceof Client.Project) {
      this.selectedProject = this.startingPoint;
      this.selectedCustomer = this.startingPoint.customer;
    } else if (this.startingPoint instanceof Client.Customer) {
      this.selectedProject = null;
      this.selectedCustomer = this.startingPoint;
    } else {
      this.selectedProject = null;
      this.selectedCustomer = null;
    }
    this.selectionMarker = this.allowMultiple ? 'checkbox' : 'radio';
    const customerObservable =
      this.customerService.getObservableBySearchObservable(
        this.searchObservable$,
        this.activeUser,
        this.clientSettings,
      );

    this.currentStore = this.activeStore.data;
    this.storeDeliveryAddress = this.activeStore.data.DeliveryAddresses[0];

    this.subscribeTo(customerObservable, (cs) => {
      let newCustomers = ObjectHelper.copy(cs);

      if (this.selectedCustomer) {
        this.selectedCustomer =
          Enumerable.from(newCustomers).firstOrDefault(
            (c) => c.Id === this.selectedCustomer!.Id,
          ) ?? null;

        if (this.selectedProject) {
          this.selectedProject =
            Enumerable.from(this.selectedCustomer!.Projects).firstOrDefault(
              (proj) => proj.Id === this.selectedProject!.Id,
            ) ?? null;
        }
      }

      //copy selection status to new delivery addresses
      for (let oldCust of this.customers)
        for (let oldProj of oldCust.Projects)
          for (let oldDa of oldProj.DeliveryAddresses) {
            if (!oldDa.selected) continue;
            let newDa = Enumerable.from(newCustomers)
              .selectMany((c) => c.Projects)
              .selectMany((p) => p.DeliveryAddresses)
              .firstOrDefault(
                (d) =>
                  d.ProjectDeliveryAddressId === oldDa.ProjectDeliveryAddressId,
              );
            if (newDa) {
              newDa.selected = true;
            }
          }
      this.customers = newCustomers;
      this.recalc();
    });
  }

  public get currentHeader(): string {
    if (this.selectedProject)
      return this.translate(
        'modal_header_select_delivery_address',
        'Select Delivery Address',
      );
    else if (this.selectedCustomer)
      return this.translate('modal_header_select_project', 'Select Project');
    else
      return this.translate('modal_header_select_customer', 'Select Customer');
  }

  private _quickSearchString: string = '';
  public get quickSearchString() {
    return this._quickSearchString;
  }
  public set quickSearchString(val: string) {
    this._quickSearchString = val;
    this.search();
  }

  public search() {
    let search = this.searchObservable$.value;
    if (!this.quickSearchString) {
      delete search.quickSearch;
    } else {
      search.quickSearch = this.quickSearchString;
    }
    this.searchObservable$.next(search);
  }

  public async newCustomer() {
    try {
      this.isCreatingNewCustomer = true;
      let creations =
        await this.modalService.getNewCustomerModalChained('deliveryAddress');
      if (creations.type == 'none') {
        return;
      } else {
        this.setCustomer(creations.customerId);
        if (creations.type !== 'customer') {
          this.setProject(creations.projectId);
          if (creations.type !== 'project') {
            await this.selectNewDeliveryAddress(
              creations.projectDeliveryAddressId,
            );
          }
        }
      }
    } finally {
      this.isCreatingNewCustomer = false;
    }
  }

  public async newProject() {
    try {
      this.isCreatingNewProject = true;
      let creations = await this.modalService.getNewProjectModalChained(
        this.selectedCustomer!,
        'deliveryAddress',
      );
      if (creations.type !== 'none') {
        this.setProject(creations.projectId);
        if (creations.type !== 'project') {
          await this.selectNewDeliveryAddress(
            creations.projectDeliveryAddressId,
          );
        }
      }
    } finally {
      this.isCreatingNewProject = false;
    }
  }

  public async newDeliveryAddress() {
    try {
      this.isCreatingNewDeliveryAddress = true;
      let creations = await this.modalService.getNewDeliveryAddressModalChained(
        this.selectedProject!,
        this.selectedProject!.customer,
        'deliveryAddress',
      );
      if (creations.type !== 'none') {
        await this.selectNewDeliveryAddress(creations.projectDeliveryAddressId);
      }
    } finally {
      this.isCreatingNewDeliveryAddress = false;
    }
  }

  //the delivery address has been created, but the list of delivery addresses hasn't benn updated yet.
  private async selectNewDeliveryAddress(projectDeliveryAddressId: number) {
    if (!this.allowMultiple) {
      let allCustomers = await firstValueFrom(this.customerService.getAll());
      for (let cust of allCustomers) {
        for (let proj of cust.Projects) {
          for (let da of proj.DeliveryAddresses) {
            if (da.ProjectDeliveryAddressId === projectDeliveryAddressId) {
              this.selectedCustomer = cust;
              this.selectedProject = proj;
              da.selected = true;
              this.activeModal.close([da]);
            }
          }
        }
      }
    }

    c: for (let cust of this.customers) {
      for (let proj of cust.Projects) {
        for (let da of proj.DeliveryAddresses) {
          if (da.ProjectDeliveryAddressId === projectDeliveryAddressId) {
            this.selectedCustomer = cust;
            this.selectedProject = proj;
            da.selected = true;
            break c;
          }
        }
      }
    }
    this.recalc();
  }

  private setCustomer(customerId: number) {
    for (let cust of this.customers) {
      if (cust.Id === customerId) {
        this.selectedCustomer = cust;
        break;
      }
    }
  }

  private setProject(projectId: number) {
    if (!this.selectedCustomer) return;
    for (let proj of this.selectedCustomer.Projects) {
      if (proj.Id === projectId) {
        this.selectedProject = proj;
        break;
      }
    }
  }

  private setDeliveryAddress(
    projectDeliveryAddressId: number,
    autoClose = false,
  ) {
    if (!this.selectedProject) {
      return;
    }
    for (let da of this.selectedProject.DeliveryAddresses) {
      if (da.ProjectDeliveryAddressId === projectDeliveryAddressId) {
        this.selectDeliveryAddress(da);
        if (autoClose && !this.allowMultiple) {
          this.ok();
        }
        break;
      }
    }
  }

  public get canCreateStoreAddress(): boolean {
    if (!this.currentStore) return false;
    if (!this.storeDeliveryAddress) return false;
    if (!this.selectedProject) return false;
    if (
      this.selectedProject.DeliveryAddresses.map((da) => da.Id).indexOf(
        this.storeDeliveryAddress.Id,
      ) >= 0
    )
      return false; // selected project already has the delivery address attached
    return true;
  }

  public async createStoreAddress() {
    if (!this.canCreateStoreAddress) return;
    if (!this.storeDeliveryAddress) return;
    let newAddress: Interface_DTO.DeliveryAddress = {
      ...this.storeDeliveryAddress,
      ProjectId: this.selectedProject!.Id,
    };
    let pda =
      await this.deliveryAddressService.createDeliveryAddress(newAddress);
    let newDa = Enumerable.from(this.customers)
      .selectMany((cust) => cust.Projects)
      .selectMany((proj) => proj.DeliveryAddresses)
      .firstOrDefault(
        (da) => da.ProjectDeliveryAddressId === pda.ProjectDeliveryAddressId,
      );

    if (newDa) {
      this.selectDeliveryAddress(newDa);
    }
  }

  public selectProject(project: Client.Project) {
    this.selectedProject = project;
  }

  public selectCustomer(customer: Client.Customer) {
    this.selectedCustomer = customer;
  }

  public selectDeliveryAddress(address: Client.DeliveryAddress) {
    if (!this.allowMultiple) {
      for (let cust of this.customers)
        for (let proj of cust.Projects)
          for (let da of proj.DeliveryAddresses) da.selected = false;
    }
    address.selected = !address.selected;
    this.recalc();
  }

  public get isBackEnabled(): boolean {
    return !!this.selectedCustomer;
  }

  public back() {
    if (this.selectedProject) {
      this.selectedProject = null;
    } else {
      this.selectedCustomer = null;
    }
  }

  public recalc() {
    this.selectedDeliveryAddreses = Enumerable.from(this.customers)
      .selectMany((cust) => cust.Projects)
      .selectMany((proj) => proj.DeliveryAddresses)
      .where((da) => da.selected)
      .toArray();
  }

  public ok() {
    this.recalc();
    if (this.selectedDeliveryAddreses.length === 0) return;
    this.activeModal.close(this.selectedDeliveryAddreses);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }
}
