import { Component } from '@angular/core';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-logo',
  templateUrl: './consumerLogo.html',
})
export class EmptyVm extends BaseVm {
  public static readonly Name: string = 'emptyVm';

  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }
}
