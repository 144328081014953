<div *ngIf="userData.loggedIn" class="d-inline-block" ngbDropdown>
  <button id="app-settings-dropdown" ngbDropdownToggle class="btn">
    <span [textContent]="userData.username"></span>
    <br />
    <span [textContent]="userData.displayName">Test</span>
  </button>
  <div
    ngbDropdownMenu
    class="dropdown-menu-end"
    aria-labelledby="app-settings-dropdown"
  >
    <button
      ngbDropdownItem
      *ngIf="userData.canChangeUserSettings"
      (click)="changeUserSettings()"
      [textContent]="
        parent.translate(
          'settings_dropdown_label_user_settings',
          'User Settings'
        )
      "
    ></button>
    <button
      ngbDropdownItem
      *ngIf="userData.canChangeStoreSettings"
      (click)="changeStoreSettings()"
      [textContent]="
        parent.translate(
          'settings_dropdown_label_store_settings',
          'Store Settings'
        )
      "
    ></button>
    <button
      ngbDropdownItem
      *ngIf="allowChangeStore && userData.canChangeStore"
      (click)="changeStore()"
      [textContent]="
        parent.translate('settings_dropdown_label_change_store', 'Change Store')
      "
    ></button>
    <button
      ngbDropdownItem
      *ngIf="userData.canSetCustomPriceDate"
      (click)="setCustomPriceDate()"
    >
      <span
        *ngIf="userData.customPriceDate"
        [textContent]="
          parent.translate(
            'settings_dropdown_label_custom_price_date_set_{0}',
            'Custom Price Date ({0})',
            userData.customPriceDate.toLocaleDateString()
          )
        "
      ></span>
      <span
        *ngIf="!userData.customPriceDate"
        [textContent]="
          parent.translate(
            'settings_dropdown_label_custom_price_date_unset',
            'Custom Price Date'
          )
        "
      >
      </span>
    </button>
    <button
      ngbDropdownItem
      *ngIf="userData.loggedIn"
      (click)="logout()"
      [textContent]="parent.translate('topbar_dropdown_logout', 'Log out')"
    ></button>
  </div>
</div>
<li *ngIf="!userData.loggedIn">
  <a href="#/login">{{ parent.translate("navbar_log_in", "Log in") }}</a>
</li>
