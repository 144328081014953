import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DragInfoService } from 'app/floor-plan-editing/drag-info.service';
import { InteriorVm } from 'app/floor-plan-editing/InteriorVm';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { Observable as OldObservable } from 'app/ts/util/Observable';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';

@Component({
  selector: 'product-list',
  templateUrl: './productList.html',
  styleUrls: [
    '../../../../style/editor.scss',
    // '../../../../style/pickable.scss',
    '../../../../style/product-list.scss',
  ],
})
export class ProductListVm extends BaseVm implements OnInit, OnChanges {
  constructor(
    baseVmService: BaseVmService,
    private readonly dragInfo: DragInfoService,
  ) {
    super(baseVmService);
  }

  private _selectedProductCategory: Client.ProductCategory | undefined =
    undefined;
  private _selectedSubCategory: Client.ProductCategory | undefined;

  private cache: {
    filteredProductCategories?: Client.ProductCategory[];
    subCategories?: Client.ProductCategory[];
    products?: Client.Product[];
    productPickables?: Pickable<Client.Product>[];
  } = {};

  @Output()
  public onProductClick = new EventEmitter<ProductListVm.ProductParams>();
  @Output()
  public onCategoryChange = new EventEmitter<Client.ProductCategory>();
  @Input()
  public cabinetSection!: Client.CabinetSection;

  public get fullCatalog() {
    return (
      this.cabinetSection.editorAssets.fullCatalog &&
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts
    );
  }
  private get showOtherMaterials() {
    return this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
  }
  public get showProductLineSelector() {
    return this.fullCatalog;
  }

  private _selectedProductLine: Interface_Enums.ProductLineId | undefined;
  public get selectedProductLineId(): Interface_Enums.ProductLineId {
    return (
      this._selectedProductLine || this.cabinetSection.cabinet.ProductLineId
    );
  }
  public set selectedProductLineId(val: Interface_Enums.ProductLineId) {
    this._selectedProductLine = val;
    this.selectedSubCategory = undefined;
  }

  public get productLines() {
    return this.cabinetSection.editorAssets.productLines;
  }

  private ongoingTouch: { observable: OldObservable<TouchEvent> } | undefined;
  private _productFilter: string = '';

  public ngOnInit() {
    if (this.onCategoryChange && this.productCategories) {
      this.onCategoryChange.emit(this.selectedProductCategory);
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    this.clearCache();
  }
  public get productCategories() {
    //return this.cabinetSection.interior.availableProductCategories;
    var editorAssets = this.cabinetSection.editorAssets;
    return editorAssets.productCategoriesByProductLineAndCabinetType[
      this.selectedProductLineId
    ][this.cabinetSection.CabinetType];
  }

  public get filteredProductCategories(): Client.ProductCategory[] {
    if (!this.cache.filteredProductCategories) {
      this.cache.filteredProductCategories = this.productCategories.filter(
        (pg) => this.filterProductCategory(pg, this.productFilter),
      );
    }
    return this.cache.filteredProductCategories;
  }

  public get subCategories(): Client.ProductCategory[] {
    if (!this.cache.subCategories) {
      if (!this.selectedProductCategory) {
        this.cache.subCategories = [];
      } else {
        let sgs = this.selectedProductCategory.Children;
        this.cache.subCategories = sgs.filter((sg) =>
          this.filterProductCategory(sg, this.productFilter),
        );
      }
    }
    return this.cache.subCategories;
  }

  public get products(): Client.Product[] {
    if (!this.cache.products) {
      if (!this.selectedSubCategory) {
        this.cache.products = [];
      } else {
        let products = this.selectedSubCategory.products;
        this.cache.products = products.filter((prod) =>
          this.filterProductGroup(prod, this.productFilter),
        );
      }
    }
    return this.cache.products;
  }

  public get productPickables(): Pickable<Client.Product>[] {
    if (!this.cache.productPickables) {
      this.cache.productPickables = this.products.map((p) => {
        var pick = ProductHelper.getPickableAsProductGroup(p);
        if (
          p.ProductLineIds.indexOf(this.cabinetSection.cabinet.ProductLineId) <
          0
        ) {
          let existingWarning = pick.warnReason ? pick.warnReason + '\n' : '';
          let warning = this.translate(
            'productlist_product_not_in_line_tooltip',
            'Product not in product line',
          );
          pick.warnReason = existingWarning + warning;
        }
        return pick;
      });
    }
    return this.cache.productPickables;
  }

  @Input()
  public isItemsDraggable: boolean = true;

  private _selectedCategoryId?: number;

  public get selectedCategoryId(): number {
    if (
      this._selectedCategoryId === null ||
      this._selectedCategoryId === undefined
    ) {
      if (this.productCategories) {
        let category = this.findSuitableCategory();

        if (category) return category.Id;
      } else this.selectedCategoryId = -1;

      this.selectedCategoryId = this.productCategories
        ? this.productCategories[0].Id
        : -1;
    }
    return this._selectedCategoryId!;
  }
  public set selectedCategoryId(val: number) {
    this._selectedCategoryId = val;
    this._selectedSubCategory = undefined;
    this.clearCache();
    if (this.onCategoryChange && this.productCategories) {
      this.onCategoryChange.emit(this.selectedProductCategory);
    }
  }

  private findSuitableCategory(): Client.ProductCategory | undefined {
    return this.filteredProductCategories.find((cat) => {
      let gable = InteriorVm.getFirstGable(cat);
      if (gable) {
        let minDepth = ProductHelper.minDepth(
          gable,
          this.cabinetSection!.cabinet.ProductLineId,
        );
        let maxDepth = ProductHelper.maxDepth(
          gable,
          this.cabinetSection!.cabinet.ProductLineId,
        );
        let result =
          minDepth <= this.cabinetSection!.InteriorDepth &&
          this.cabinetSection!.InteriorDepth <= maxDepth;
        return result;
      }

      return false;
    });
  }

  public get selectedProductCategory(): Client.ProductCategory {
    let result: Client.ProductCategory | undefined = undefined;
    for (let cat of this.productCategories) {
      if (cat.Id == this.selectedCategoryId) {
        result = cat;
        break;
      }
    }
    if (!result) {
      result = this.productCategories[0];
      if (result) this.selectedCategoryId = result.Id;
    }
    return result;
  }

  public get selectedSubCategory(): Client.ProductCategory | undefined {
    if (
      !this._selectedSubCategory ||
      this.subCategories.indexOf(this._selectedSubCategory) < 0
    ) {
      this._selectedSubCategory = this.selectedProductCategory
        ? this.selectedProductCategory.Children[0]
        : undefined;
    }
    return this._selectedSubCategory;
  }
  public set selectedSubCategory(val: Client.ProductCategory | undefined) {
    this._selectedSubCategory = val;
    this.clearCache();
    this.scrollProductsToTop();
  }

  private scrollProductsToTop() {
    try {
      window.document.getElementsByClassName('product-list')[0].scrollTo(0, 0);
    } catch (e: any) {
      //ignore
    }
  }

  private clearCache(): void {
    this.cache = {};
    if (
      this._selectedProductCategory &&
      this.productCategories.indexOf(this._selectedProductCategory) < 0
    ) {
      this._selectedProductCategory = this.productCategories[0];
    }
    if (
      this._selectedSubCategory &&
      this.subCategories.indexOf(this._selectedSubCategory) < 0
    ) {
      this._selectedSubCategory = this.subCategories[0];
    }
  }

  static getVariantOptions(
    filter: string,
    product: Client.Product,
    useDisabledColors: boolean,
  ): Interface_DTO.VariantOption[] {
    filter = filter.toLowerCase();
    if (!ProductListVm.numberSearch(filter, product.ProductNo)) return [];
    let remainingfFilter = filter.substr(product.ProductNo.length);

    let options: Interface_DTO.VariantOption[] = [];
    let variantIds = [
      product.AddVariant1,
      product.AddVariant2,
      product.AddVariant3,
    ];

    nextAddVariant: for (let id of variantIds) {
      if (id < 0) continue;
      for (let variant of product.getAllVariants()) {
        if (variant.Id == id) {
          for (let vo of variant.VariantOptions) {
            let needle = vo.Number;
            if (remainingfFilter.indexOf(needle) === 0) {
              remainingfFilter = remainingfFilter.substr(needle.length);

              //check if the variant option is valid for the product (ie. the option is not discontinued)
              if (!useDisabledColors) {
                if (!vo.Enabled) continue nextAddVariant;

                if (
                  variant.Number === VariantNumbers.Color ||
                  variant.Number === VariantNumbers.FrameColor ||
                  variant.Number === VariantNumbers.SpotColor ||
                  variant.Number === VariantNumbers.DoorFillingColor ||
                  variant.Number === VariantNumbers.DoorFillingColorDetailed
                ) {
                  var mat = Enumerable.from(product.materials).firstOrDefault(
                    (m) => m.Number === vo.Number,
                  );
                  if (!mat || mat.isDiscontinued || mat.isOverride) {
                    continue nextAddVariant;
                  }
                }
              }

              options.push(vo);
              continue nextAddVariant;
            }
          }
        }
      }
    }

    return options;
  }

  private filterProductCategory(
    pg: Client.ProductCategory,
    searchString: string,
  ): boolean {
    if (pg.Number === Constants.ModulePartsCategoryNumber) {
      return false;
    }
    if (pg.ParentCategoryId === -1) {
      return (
        pg.Children.some((childPg) =>
          this.filterProductCategory(childPg, searchString),
        ) && this.hasCategorySpecificProducts(pg)
      );
    } else {
      return (
        pg.products.some((prod) =>
          this.filterProductGroup(prod, searchString),
        ) ||
        pg.Children.some((childPg) =>
          this.filterProductCategory(childPg, searchString),
        )
      );
    }
  }

  hasCategorySpecificProducts(pg: Client.ProductCategory): boolean {
    return pg.anyChildCategory(
      (childCat) =>
        childCat.products.length > 0 && !childCat.hasMultipleParents,
    );
  }

  private filterProductGroup(
    prod: Client.Product,
    searchString: string,
  ): boolean {
    return prod.productGroup.some((subProduct) =>
      this.filterProduct(subProduct, searchString),
    );
  }
  private filterProduct(
    subProduct: Client.Product,
    searchString: string,
  ): boolean {
    return (
      this.isAvailable(subProduct) &&
      ProductListVm.productMatchesSearch(
        subProduct,
        searchString,
        this.fullCatalog,
      )
    );
  }

  private isAvailable(product: Client.Product): boolean {
    if (!this.fullCatalog) {
      if (product.overrideChain) return false;

      const productData = product.getProductData();
      if (
        productData &&
        productData.Status === Interface_Enums.ProductDataStatus.Discontinued
      )
        return false;
    }
    if (!product.Enabled) return false;
    if (product.ProductLineIds.indexOf(this.selectedProductLineId) < 0)
      return false;
    if (!this.showOtherMaterials && product.hasOnlyDisabledMaterials())
      return false;
    return true;
  }

  private static productMatchesSearch(
    prod: Client.Product,
    searchString: string,
    fullCatalog: boolean,
  ): boolean {
    if (!searchString) return true;
    return (
      ProductListVm.nameSearch(prod.Name, searchString) ||
      ProductListVm.numberSearch(prod.ProductNo, searchString) ||
      ProductListVm.getVariantOptions(searchString, prod, fullCatalog).length >
        0
    );
  }
  private static nameSearch(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;
  }
  private static numberSearch(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) === 0;
  }

  public startDrag(
    evt: MouseEvent | TouchEvent,
    product: Client.Product,
  ): void {
    this.dragInfo.value = {
      productId: product.Id,
      touchEventObservable: null,
    };
    if (!(window as any).TouchEvent || !(evt instanceof TouchEvent)) {
      evt.preventDefault();
    }
    this.onProductClick.emit({ product: product, variants: [] });
  }

  public stopDrag(evt: MouseEvent | TouchEvent, product: Client.Product) {
    this.dragInfo.value = undefined;
  }

  // #region touch events
  public touchStart(evt: TouchEvent, product: Client.Product): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    } else {
      let observable = new OldObservable(evt);
      this.ongoingTouch = {
        observable: observable,
      };
      this.dragInfo.value = {
        productId: product.Id,
        touchEventObservable: observable,
      };
    }
  }
  public touchMove(evt: TouchEvent): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    }
  }
  public touchEnd(evt: TouchEvent): void {
    if (evt.touches.length > 0) {
      //Touch is not done, but a finger has been lifted
      return;
    }
    if (this.ongoingTouch) {
      this.ongoingTouch = undefined;
    }
  }
  public touchCancel(evt: TouchEvent): void {
    this.touchEnd(evt);
  }

  // #endregion touch events

  public clickItem(item: Client.Product) {
    this.onProductClick.emit({ product: item, variants: [] });
  }

  public get productFilter(): string {
    return this._productFilter;
  }

  public set productFilter(val: string) {
    this._productFilter = val;
    this.clearCache();
  }

  public searchEnter() {
    if (this.products.length > 0) {
      let product = this.products[0].productGroup.filter((p) =>
        this.filterProduct(p, this.productFilter),
      )[0];
      let variantOptions = ProductListVm.getVariantOptions(
        this.productFilter,
        product,
        this.fullCatalog,
      );

      this.onProductClick.emit({ product: product, variants: variantOptions });
    }
  }

  public override dispose() {
    super.dispose();
    if (this.ongoingTouch) {
      this.ongoingTouch = undefined;
    }
  }
}

export module ProductListVm {
  export type ProductParams = {
    product: Client.Product | undefined;
    variants: Interface_DTO.VariantOption[] | undefined;
  };
  export type ProductParamsAction = (params: ProductParams) => void;

  export type CategoryParams = { category: Client.ProductCategory };
  export type CategoryParamsAction = (params: CategoryParams) => void;
}
