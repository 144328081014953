import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
} from '@angular/core';
import { FillingsViewBar } from 'app/partition/fillings/bar';
import { FillingsViewFilling } from 'app/partition/fillings/filling';
import { FillingsViewModule } from 'app/partition/fillings/module';
import { FillingsViewSection } from 'app/partition/fillings/section';
import {
  Door,
  DoorPlacement,
  Module,
  ModuleId,
  ModuleType,
} from 'app/partition/module';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ProperSvgElement } from 'app/ts/util/ProperSvgElement';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { Selection } from '../../partition/fillings/selection';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { AssetService } from 'app/ts/services/AssetService';
import { Rail, RailPlacement } from 'app/partition/rail';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { PartitionPlanGeometryQueryService } from 'app/partition/partition-plan-geometry-query.service';
import {
  CornerProfile,
  NicheProfile,
  Profile,
  ProfileType,
  StartStopProfile,
  TProfile,
} from 'app/partition/profile';

@Component({
  selector: 'fillings-image',
  templateUrl: './fillingsImage.svg',
  styleUrls: ['../../../style/d2.scss'],
})
export class FillingsImageVm extends BaseVm implements OnInit {
  //region angular component bindings
  @Input() floorPlan!: Client.FloorPlan;
  @Input() showRulers: boolean = false;
  @Input() showTopdown: boolean = false;
  @Input() modules!: Readonly<FillingsViewModule[]>;
  @Input() public section!: Readonly<FillingsViewSection>;
  @Output() selectedItemChanged = new EventEmitter<Selection>();
  public selection: Selection = {};
  //endRegion angular component bindings

  private static readonly padding = 50;
  private static readonly topDownPadding = 120;
  private static readonly DRAG_ACTIVATE_DIST = 20;

  public rulers: Client.Ruler[] = [];

  private dragInfo?: BarDragInfo = undefined;
  private svgCanvas!: ProperSvgElement;
  private svgMousePoint!: SVGPoint;

  constructor(
    baseVmService: BaseVmService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionGeometryQueryService: PartitionPlanGeometryQueryService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly editorType: EditorTypeService,
    private readonly floorplanService: FloorPlanService,
    private readonly assetService: AssetService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.createRulers();

    this.svgCanvas = window.document.getElementById('fillings-image') as any;
    this.svgMousePoint = this.svgCanvas.createSVGPoint();
  }

  private createRulers() {
    this.rulers = [
      new Client.Ruler(
        true, //Height
        {
          X: -((Constants.rulerWidth * 3) / 2),
          Y: 0,
        },
        this.section.height,
        this.section.height,
        false,
      ),
      new Client.Ruler(
        false, //Width
        {
          X: 0,
          Y: (Constants.rulerWidth * 3) / 2,
        },
        this.section.width,
        0,
        false,
      ),
    ];
  }

  public isFrontDoor(module: FillingsViewModule): boolean {
    return module.isDoor && module.placement == DoorPlacement.Front;
  }

  public get selectedModule(): FillingsViewModule {
    return this.modules.find(
      (md) => md.id == this.partitionQueryService.selectedModule!.id,
    )!;
  }

  public get sortedModules(): FillingsViewModule[] {
    let moduleArr = [...this.modules];

    moduleArr.sort((a, b) => {
      function getSortLayer(module: FillingsViewModule): number {
        if (module.isDoor && module.placement == DoorPlacement.Back) return 1;
        else if (module.isDoor && module.placement == DoorPlacement.Front)
          return 3;
        else return 2;
      }

      return getSortLayer(a) - getSortLayer(b);
    });

    return moduleArr;
  }

  public get sectionTopWallRails(): Rail[] {
    return this.section.rails
      .map((railId) => this.partitionQueryService.getRail(railId))
      .filter(
        (rl) =>
          rl.placement == RailPlacement.Ceiling &&
          rl.doorPlacement != DoorPlacement.Back,
      );
  }

  public get sectionTopRails(): Rail[] {
    return this.section.rails
      .map((railId) => this.partitionQueryService.getRail(railId))
      .filter((rl) => rl.placement == RailPlacement.Ceiling);
  }

  public getRailXOffsetFromSectionTopRail(rail: Rail): number {
    let maxExtend = this.maxRailExtend;

    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    ) as Profile;
    // Only when left or right side section of t-profile
    if (
      (leftProfile instanceof TProfile &&
        leftProfile.middleSection.sectionId != this.section.id) ||
      leftProfile instanceof CornerProfile
    ) {
      maxExtend += this.getProfileSize(leftProfile) + Module.elementSpacing;
    }

    return -Math.min(this.getRailExtionsionOverSectionLeft(rail), maxExtend);
  }

  public getRailYOffset(rail: Rail) {
    if (
      rail.moduleType == ModuleType.Door &&
      rail.doorPlacement == DoorPlacement.Back
    )
      return -80;
    return 0;
  }

  public getRailXOffsetFromSectionBottomRail(rail: Rail): number {
    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    ) as Profile;
    let leftProfileSize = 0;
    // Only when left or right side section of t-profile
    if (
      (leftProfile instanceof TProfile &&
        leftProfile.middleSection.sectionId != this.section.id) ||
      leftProfile instanceof CornerProfile
    ) {
      leftProfileSize =
        this.getProfileSize(leftProfile) + Module.elementSpacing;
    } else if (
      leftProfile instanceof TProfile &&
      leftProfile.middleSection.sectionId == this.section.id
    ) {
      let extensions = this.partitionQueryService.getSectionRailExtensions(
        this.section.id,
        RailPlacement.Floor,
      );
      leftProfileSize = extensions.leftExtension;
    }

    let maxExtend = this.maxRailExtend + leftProfileSize;

    return -Math.min(
      // leftRailExtension,
      this.getRailExtionsionOverSectionLeft(rail),
      maxExtend,
    );
  }

  public get sectionRails(): Rail[] {
    return this.section.rails.map((railId) =>
      this.partitionQueryService.getRail(railId),
    );
  }

  public get sectionBottomRails(): Rail[] {
    return this.section.rails
      .map((railId) => this.partitionQueryService.getRail(railId))
      .filter(
        (rl) =>
          rl.placement == RailPlacement.Floor &&
          rl.doorPlacement != DoorPlacement.Back,
      );
  }

  public get sectionBottomDoorBackRails(): Rail[] {
    return this.section.rails
      .map((railId) => this.partitionQueryService.getRail(railId))
      .filter(
        (rl) =>
          rl.placement == RailPlacement.Floor &&
          rl.doorPlacement == DoorPlacement.Back,
      );
  }

  private maxRailExtend: number = 50;

  private getRailExtionsionOverSectionLeft(rail: Rail): number {
    if (this.section.isHorizontal) {
      if (this.section.isInverted) {
        return rail.posX + rail.width - this.section.posX;
      } else {
        return this.section.posX - rail.posX;
      }
    } else {
      // vertical
      if (this.section.isInverted) {
        return rail.posY + rail.width - this.section.posY;
      } else {
        return this.section.posY - rail.posY;
      }
    }
  }

  private getRailExtionsionOverSectionRight(rail: Rail): number {
    if (this.section.isHorizontal) {
      if (this.section.isInverted) {
        return this.section.posX - this.section.width - rail.posX;
      } else {
        return (
          rail.posX + rail.width - (this.section.posX + this.section.width)
        );
      }
    } else {
      // vertical
      if (this.section.isInverted) {
        return this.section.posY - this.section.width - rail.posY;
      } else {
        return (
          rail.posY + rail.width - (this.section.posY + this.section.width)
        );
      }
    }
  }

  public shouldRailFadeInLeft(rail: Rail): boolean {
    return this.getRailExtionsionOverSectionLeft(rail) > this.maxRailExtend;
  }

  public shouldRailFadeInRight(rail: Rail): boolean {
    return this.getRailExtionsionOverSectionRight(rail) > this.maxRailExtend;
  }

  public shouldRailFade(rail: Rail): boolean {
    return this.shouldRailFadeInLeft(rail) || this.shouldRailFadeInRight(rail);
  }
  public getTopRailWidth(rail: Rail): number {
    let width = this.section.width;

    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    ) as Profile;
    let leftProfileSize = 0;
    // Only when left or right side section of t-profile
    if (
      (leftProfile instanceof TProfile &&
        leftProfile.middleSection.sectionId != this.section.id) ||
      leftProfile instanceof CornerProfile
    ) {
      leftProfileSize =
        this.getProfileSize(leftProfile) + Module.elementSpacing;
    }

    let rightProfile = this.partitionQueryService.getSectionRightProfile(
      this.section.id,
    ) as Profile;
    let rightProfileSize = 0;
    // Only when left or right side section of t-profile
    if (
      (rightProfile instanceof TProfile &&
        rightProfile.middleSection.sectionId != this.section.id) ||
      rightProfile instanceof CornerProfile
    ) {
      rightProfileSize =
        this.getProfileSize(rightProfile) + Module.elementSpacing;
    }

    width += leftProfileSize + rightProfileSize;

    width += Math.min(
      this.getRailExtionsionOverSectionLeft(rail) - leftProfileSize,
      this.maxRailExtend,
    );

    width += Math.min(
      this.getRailExtionsionOverSectionRight(rail) - rightProfileSize,
      this.maxRailExtend,
    );

    return width;
  }

  public getBottomRailWidth(rail: Rail): number {
    let leftProfile = this.partitionQueryService.getSectionLeftProfile(
      this.section.id,
    ) as Profile;
    let leftProfileSize = 0;
    // Only when left or right side section of t-profile
    if (
      (leftProfile instanceof TProfile &&
        leftProfile.middleSection.sectionId != this.section.id) ||
      leftProfile instanceof CornerProfile
    ) {
      leftProfileSize =
        this.getProfileSize(leftProfile) + Module.elementSpacing;
    }

    let rightProfile = this.partitionQueryService.getSectionRightProfile(
      this.section.id,
    ) as Profile;
    let rightProfileSize = 0;
    // Only when left or right side section of t-profile
    if (
      (rightProfile instanceof TProfile &&
        rightProfile.middleSection.sectionId != this.section.id) ||
      rightProfile instanceof CornerProfile
    ) {
      rightProfileSize =
        this.getProfileSize(rightProfile) + Module.elementSpacing;
    }

    let sectionPos = this.section.isHorizontal
      ? this.section.posX
      : this.section.posY;

    let mostLeftAllowedCoord = this.section.isInverted
      ? sectionPos + leftProfileSize + this.maxRailExtend
      : sectionPos - leftProfileSize - this.maxRailExtend;

    let mostRightAllowedCoord = this.section.isInverted
      ? sectionPos - this.section.width - rightProfileSize - this.maxRailExtend
      : sectionPos + this.section.width + rightProfileSize + this.maxRailExtend;

    let width: number = 0;
    if (this.section.isInverted) {
      let leftCoord = Math.min(
        mostLeftAllowedCoord,
        this.section.isHorizontal
          ? rail.posX + rail.width
          : rail.posY + rail.width,
        // this.section.isHorizontal ? rail.posX : rail.posY
      );

      let rightCoord = Math.max(
        mostRightAllowedCoord,
        this.section.isHorizontal ? rail.posX : rail.posY,
      );

      width = Math.abs(leftCoord - rightCoord);
    } else {
      let leftCoord = Math.max(
        mostLeftAllowedCoord,
        this.section.isHorizontal ? rail.posX : rail.posY,
      );

      let rightCoord = Math.min(
        mostRightAllowedCoord,
        this.section.isHorizontal
          ? rail.posX + rail.width
          : rail.posY + rail.width,
      );

      width = Math.abs(leftCoord - rightCoord);
    }

    return width;
  }

  public getRailHeight(rail: Rail) {
    let product = this.assetService.editorAssets.productsDict[rail.productId];
    let productData = product.getProductData()!;
    return productData.DefaultHeight;
  }

  public getRailStrokePath(rail: Rail, width: number, height: number): string {
    let path = `M 0 0 l ${width} 0 `;

    path += this.shouldRailFadeInRight(rail) ? 'm ' : 'l ';
    path += `0 ${height} `;

    path += `l ${-width} 0 `;

    path += !this.shouldRailFadeInLeft(rail) ? `l 0 ${-height}` : '';

    return path;
  }

  //#region ViewBox Calculations
  public get viewBoxPosition(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: -FillingsImageVm.padding,
      Y: -FillingsImageVm.padding,
    };

    result.X -= this.rulerSpacing.X;
    result.Y -= this.rulerSpacing.Y;

    return result;
  }

  private get rulerSpacing(): Interface_DTO_Draw.Vec2d {
    return this.showRulers
      ? { X: 2 * Constants.rulerWidth, Y: 2 * Constants.rulerWidth }
      : { X: 0, Y: 0 };
  }

  public get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: this.partitionQueryService.selectedSection?.width ?? 1500,
      Y: this.floorPlan.Size.Z,
    };

    result.X += this.rulerSpacing.X;
    result.Y += this.rulerSpacing.Y;

    result.X += 2 * FillingsImageVm.padding;
    result.Y += 2 * FillingsImageVm.padding;

    if (this.showTopdown) {
      result.Y += 2 * FillingsImageVm.topDownPadding;
      // result.Y += (this.cabinetSection.Depth - this.cabinetSection.interior.cube.Depth - this.cabinetSection.interior.cube.Z);
    }

    return result;
  }

  public get viewBoxString(): string {
    let pos = this.viewBoxPosition;
    let size = this.viewBoxSize;
    return pos.X + ' ' + pos.Y + ' ' + size.X + ' ' + size.Y;
  }

  public get topDownAreaPosX() {
    return 0;
  }
  public get topDownAreaPosY() {
    return this.modules[0].height + FillingsImageVm.topDownPadding;
  }
  //#endregion

  public verticalBarsPositions(moduleId: ModuleId): number[] {
    return this.partitionGeometryQueryService.verticalBarsPositions(moduleId);
  }

  public getTopDownModuleY(module: FillingsViewModule): number {
    if (module.isDoor && module.placement == DoorPlacement.Back) return 0;
    else if (module.isDoor && module.placement == DoorPlacement.Front)
      return 80;
    else return 40;
  }

  public getTopBottomFrameWidth(module: FillingsViewModule): number {
    let normalWidth = module.width - Module.frameTopBottomWidthReduction * 2;
    return module.isDoor ? normalWidth - Door.extraWidth : normalWidth;
  }

  public getTopBottomFrameOffset(module: FillingsViewModule): number {
    let normalOffset =
      Module.fillingWidthReduction + Module.fillingInsertionDepth;

    return module.isDoor ? normalOffset + 15 : normalOffset;
  }

  public getBottomFrameHeight(module: FillingsViewModule): number {
    return module.isDoor
      ? this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getDoorModuleProductId(),
        )!.FrameWidthBottom
      : this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getWallModuleProductId(),
        )!.FrameWidthBottom;
  }

  public getLeftFrameWidth(module: FillingsViewModule): number {
    return module.isDoor
      ? this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getDoorModuleProductId(),
        )!.FrameWidthLeft
      : this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getWallModuleProductId(),
        )!.FrameWidthLeft;
  }

  public getRightFrameWidth(module: FillingsViewModule): number {
    return module.isDoor
      ? this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getDoorModuleProductId(),
        )!.FrameWidthRight
      : this.floorPlan.editorAssets.productDoorData.find(
          (pdd) =>
            pdd.ProductId ==
            this.partitionQueryService.getWallModuleProductId(),
        )!.FrameWidthRight;
  }

  public useCorrectlySizedMaterials(filling: FillingsViewFilling): boolean {
    if (!filling.material) return false;
    if (!filling.material.TexturePath) return false;
    if (!filling.material.TextureWidthMm) return false;
    if (!filling.material.TextureHeightMm) return false;
    return true;
  }

  public getFillingImagePath(filling: FillingsViewFilling): string {
    if (!filling) return '';
    if (!filling.material) return '';
    if (this.useCorrectlySizedMaterials(filling)) {
      return filling.material.TexturePath;
    }

    if (!filling.material.ImagePath) return '';
    return filling.material.ImagePath;
  }

  public getTextureUrl(material: Client.Material) {
    if (!material.TexturePath) return '';
    return (
      '/StaticAssets/images/Materials2/' + material.TexturePath + '/map.png'
    );
  }

  public getFillingPatternId(
    module: FillingsViewModule,
    filling: FillingsViewFilling,
  ): string {
    return 'filling_pattern_' + module.id + '_' + filling.id;
  }

  public getFillingTransformString(
    filling: FillingsViewFilling,
    module: FillingsViewModule,
  ) {
    let result = 'scale( ' + filling.width + ' ' + filling.height + ' )';
    if (filling.isDesignFilling) {
      result += ' rotate( 90 ) translate( 0 -1 )';
    }

    return result;
  }

  public getRailPositionRect(rail: Rail) {
    let newPos = 0;
    if (this.section.isInverted) {
      if (this.section.isHorizontal)
        newPos = this.section.posX - (rail.posX + rail.width);
      else newPos = this.section.posY - (rail.posY + rail.width);
    } else {
      if (this.section.isHorizontal) newPos = rail.posX - this.section.posX;
      else newPos = rail.posY - this.section.posY;
    }

    if (rail.placement == RailPlacement.Floor) {
      if (this.shouldRailFadeInLeft(rail))
        return (
          'translate(' +
          (this.getBottomRailWidth(rail) - 80) / 2 +
          ',' +
          (this.section.height - 52) +
          ')'
        );
      else
        return (
          'translate(' +
          ((this.getBottomRailWidth(rail) - 80) / 2 + newPos) +
          ',' +
          (this.section.height - 52) +
          ')'
        );
    } else {
      let y = rail.moduleType == ModuleType.Door ? -100 : -20;
      if (this.shouldRailFadeInLeft(rail))
        return (
          'translate(' + (this.getTopRailWidth(rail) - 80) / 2 + ',' + y + ')'
        );
      else
        return (
          'translate(' +
          ((this.getTopRailWidth(rail) - 80) / 2 + newPos) +
          ',' +
          y +
          ')'
        );
    }
  }

  public getRailPositionNumber(rail: Rail) {
    let newPos = 0;
    if (this.section.isInverted) {
      if (this.section.isHorizontal)
        newPos = this.section.posX - (rail.posX + rail.width);
      else newPos = this.section.posY - (rail.posY + rail.width);
    } else {
      if (this.section.isHorizontal) newPos = rail.posX - this.section.posX;
      else newPos = rail.posY - this.section.posY;
    }

    if (rail.placement == RailPlacement.Floor) {
      if (this.shouldRailFadeInLeft(rail))
        return (
          'translate(' +
          this.getBottomRailWidth(rail) / 2 +
          ',' +
          this.section.height +
          ')'
        );
      else
        return (
          'translate(' +
          (this.getBottomRailWidth(rail) / 2 + newPos) +
          ',' +
          this.section.height +
          ')'
        );
    } else {
      let y = rail.moduleType == ModuleType.Door ? -48 : 32;
      if (this.shouldRailFadeInLeft(rail))
        return 'translate(' + this.getTopRailWidth(rail) / 2 + ',' + y + ')';
      else
        return (
          'translate(' +
          (this.getTopRailWidth(rail) / 2 + newPos) +
          ',' +
          y +
          ')'
        );
    }
  }

  public get fillingOffset(): number {
    return Module.fillingWidthReduction;
  }

  public getLabelWidth(filling: FillingsViewFilling): number {
    const lengthPerChar = 22;
    const margin = 56;
    const label =
      filling.material.Number + ' - ' + filling.material.DefaultName;
    return label.length * lengthPerChar + margin;
  }

  public selectFilling(evt: MouseEvent, filling: FillingsViewFilling) {
    evt.stopPropagation();

    this.selection = {
      filling,
    };

    this.partitionCommandService.setSelectedModule(filling.moduleId);
    this.selectedItemChanged.emit(this.selection);
  }

  public selectBar(evt: MouseEvent, bar: FillingsViewBar) {
    evt.stopPropagation();

    this.selection = {
      bar,
    };

    this.partitionCommandService.setSelectedModule(bar.moduleId);
    this.selectedItemChanged.emit(this.selection);
  }

  public deselect() {
    this.selection = {};

    this.partitionCommandService.setSelectedModule(null);
    this.selectedItemChanged.emit(this.selection);
  }

  public isFillingSelected(filling: FillingsViewFilling) {
    return (
      this.partitionQueryService.selectedModule?.id == filling.moduleId &&
      this.selection.filling?.id == filling.id
    );
  }

  public isBarSelected(bar: FillingsViewBar) {
    return (
      this.partitionQueryService.selectedModule?.id == bar.moduleId &&
      this.selection.bar?.id == bar.id
    );
  }

  //#region dragging bars
  public startDrag(evt: MouseEvent, bar: FillingsViewBar) {
    this.selectBar(evt, bar);

    let module = this.partitionQueryService.getModule(bar.moduleId);
    let { minOffset, maxOffset } = this.calculateMinMaxOffset(module, bar);

    this.dragInfo = {
      active: false,
      bar: bar,
      dragStartY: this.getSvgPoint(evt).Y,
      offset: 0,
      minOffset,
      maxOffset,
    };
    evt.preventDefault();
  }

  private calculateMinMaxOffset(
    module: Readonly<Module>,
    bar: FillingsViewBar,
  ) {
    let barIndex = module.bars.findIndex((b) => b.id == bar.id);

    let fillingAbove = module.fillings[barIndex + 1];
    let fillingAboveMaterial =
      this.assetService.editorAssets.materialsDict[fillingAbove.materialId];
    let fillingBelow = module.fillings[barIndex];
    let fillingBelowMaterial =
      this.assetService.editorAssets.materialsDict[fillingBelow.materialId];

    let aboveMin = fillingAbove.height - fillingAboveMaterial.MinFillingHeight;
    let aboveMax = fillingAboveMaterial.MaxFillingHeight - fillingAbove.height;
    let belowMin = fillingBelow.height - fillingBelowMaterial.MinFillingHeight;
    let belowMax = fillingBelowMaterial.MaxFillingHeight - fillingBelow.height;

    return {
      minOffset: -Math.min(aboveMin, belowMax),
      maxOffset: Math.min(aboveMax, belowMin),
    };
  }

  public onDrag(evt: MouseEvent) {
    if (!this.dragInfo) return;

    let offset = ObjectHelper.clamp(
      this.dragInfo.minOffset,
      this.getSvgPoint(evt).Y - this.dragInfo.dragStartY,
      this.dragInfo.maxOffset,
    );
    this.dragInfo.active =
      this.dragInfo.active ||
      Math.abs(offset) > FillingsImageVm.DRAG_ACTIVATE_DIST;

    if (offset < this.dragInfo.minOffset || offset > this.dragInfo.maxOffset)
      return;

    if (this.dragInfo.active) {
      // Offset is subtracted as position is from botton, whereas y coordinate is from the top
      this.partitionCommandService.updateBarYPosition(
        this.dragInfo.bar.id,
        this.dragInfo.bar.position + offset,
      );
    }
  }

  private getSvgPoint(evt: MouseEvent | TouchEvent): Interface_DTO_Draw.Vec2d {
    let clientX: number;
    let clientY: number;
    if (evt instanceof MouseEvent) {
      clientX = evt.clientX;
      clientY = evt.clientY;
    } else if (evt instanceof TouchEvent) {
      clientX = evt.changedTouches[0].clientX;
      clientY = evt.changedTouches[0].clientY;
    } else {
      throw new Error('unknown event type');
    }

    this.svgMousePoint.x = clientX;
    this.svgMousePoint.y = clientY;
    let pt = this.svgMousePoint.matrixTransform(
      this.svgCanvas.getScreenCTM().inverse(),
    );

    return {
      X: pt.x,
      Y: pt.y,
    };
  }

  public barDragOffsetTranslate(bar: FillingsViewBar): string {
    if (
      !this.dragInfo ||
      this.dragInfo.bar.id != bar.id ||
      !this.dragInfo.active
    )
      return '';

    if (
      this.dragInfo.offset < this.dragInfo.minOffset ||
      this.dragInfo.offset > this.dragInfo.maxOffset
    )
      return '';

    return `translate(0 ${this.dragInfo.offset})`;
  }

  public mouseDown(evt: MouseEvent) {
    this.mouseDownInsideCanvas = true;
  }

  private mouseDownInsideCanvas: boolean = false;
  public mouseUp(evt: MouseEvent) {
    if (!this.dragInfo && this.mouseDownInsideCanvas) {
      this.deselect();
      this.mouseDownInsideCanvas = false;
      return;
    }

    this.floorplanService.setChanged(this.editorType.floorPlan);
    this.dragInfo = undefined;
    this.mouseDownInsideCanvas = false;
  }
  //#endregion

  public roundUp(n: number): number {
    return Math.ceil(n);
  }

  public get sectionProfiles(): Profile[] {
    let profiles = [
      this.partitionQueryService.getSectionLeftProfile(this.section.id),
      this.partitionQueryService.getSectionRightProfile(this.section.id),
    ].filter((p) => p != undefined) as Profile[];

    // Get all profiles in front of doors
    this.modules.forEach((md) => {
      if (md.isDoor && md.placement == DoorPlacement.Back) {
        if (md.previousModule && md.previousModule.rightProfileId) {
          profiles.push(
            this.partitionQueryService.getProfile(
              md.previousModule.rightProfileId,
            ) as Profile,
          );
        }

        if (md.nextModule && md.nextModule.leftProfileId) {
          profiles.push(
            this.partitionQueryService.getProfile(
              md.nextModule.leftProfileId,
            ) as Profile,
          );
        }
      }
    });

    return profiles;
  }

  public getProfileSize(profile: Profile) {
    return this.partitionQueryService.getProfileSize(profile.id);
  }

  public getProfileX(profile: Profile) {
    return this.partitionGeometryQueryService.getSectionProfileRelativeXOffset(
      profile.id,
      this.section.id,
    );
  }

  public shouldShowProfile(profile: Profile): boolean {
    let show = profile != undefined;

    // If it is a joint profile only show, if the section is the profiles left section
    if (show && this.partitionQueryService.isJointProfile(profile!.id)) {
      if (profile instanceof CornerProfile)
        show = profile.leftSection.sectionId == this.section.id;
      if (profile instanceof TProfile)
        show = profile.leftSection.sectionId == this.section.id;
    }

    return show;
  }
}

interface BarDragInfo {
  active: boolean;
  bar: FillingsViewBar;
  dragStartY: number;
  offset: number;
  minOffset: number;
  maxOffset: number;
}
