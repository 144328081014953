import { Injectable } from '@angular/core';
import { User } from '../../identity-and-access/User';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { UserToken } from 'app/ts/UserToken';
import { Constants } from 'app/ts/Constants';
import { ConvertFromUserToken } from '../../identity-and-access/User';
import { NavigationExtras, Router } from '@angular/router';
import { ApplicationState } from 'app/functional-core/ApplicationState';

import * as Interface_DTO_Consumer from 'app/ts/Interface_DTO_Consumer';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Constants from 'app/ts/InterfaceConstants';
import * as App from 'app/ts/app';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ErrorService } from 'app/ts/services/ErrorService';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { UserSettingsValue } from 'app/functional-core/ambient/userdata/UserData';
import { timeoutAsync } from '@Util/timeoutAsync';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { UserMessageService } from 'app/user-messages/user-message.service';

interface Token {
  Token: string;
}

@Injectable({ providedIn: 'root' })
export class LoginService {
  public static sessionOnlyLoginToken: string | null = null;

  private readonly _userToken$: Subject<UserToken> = new Subject<UserToken>();

  public get userTokenObservable(): Observable<UserToken> {
    return this._userToken$.pipe(map((ut) => ObjectHelper.copy(ut)));
  }

  private readonly _salesChainSettings$ = new BehaviorSubject<{
    [salesChainSetting: number]: string;
  }>([]);
  public get salesChainSettings$(): Observable<{
    [salesChainSetting: number]: string;
  }> {
    return this._salesChainSettings$;
  }

  private _salesChainSettings: { [salesChainSetting: number]: string } = {};
  public get salesChainSettings(): { [salesChainSetting: number]: string } {
    return this._salesChainSettings;
  }

  private _chainSettings: { [chainSetting: number]: string } = {};
  public get chainSettings() {
    return this._chainSettings;
  }

  private readonly _chainSettings$ = new BehaviorSubject<{
    [chainSetting: number]: string;
  }>([]);
  public get chainSettings$(): Observable<{ [chainSetting: number]: string }> {
    return this._chainSettings$;
  }

  public constructor(
    private readonly httpClient: PromisingBackendService,
    private readonly errorService: ErrorService,
    private readonly router: Router,
    private readonly data: FunctionalCoreService,
    private readonly userMessageService: UserMessageService,
  ) {
    //Don't await this, just get a new token for next time
    this.updateUserToken();

    const userToken = this.getUserToken();
    if (userToken != null) this._userToken$.next(userToken);

    if (LoginService.tokenString) {
      this.setLoginCookie(LoginService.tokenString, true);
    } else {
      this.logout();
    }
  }

  public async loadUserData(): Promise<UserSettingsValue> {
    return this.httpClient.get<UserSettingsValue>('api/user/userData');
  }

  public async loadSalesChainSettings(): Promise<void> {
    const salesChainSettings = await this.httpClient
      .get<Interface_DTO.SalesChainSetting[]>('api/saleschainsetting')
      .then((settings) => {
        let result: { [key: number]: string } = {};
        for (let sc of settings) {
          if (sc?.Value != null) {
            result[sc.IntKey] = sc.Value;
          }
        }
        return result;
      });
    this._salesChainSettings = salesChainSettings;
    this._salesChainSettings$.next(salesChainSettings);
  }

  public async loadChainSettings(): Promise<void> {
    const chainSettings = await this.httpClient
      .get<Interface_DTO.ChainSetting[]>('api/chainsetting')
      .then((settings) => {
        let result: { [key: number]: string } = {};
        for (let sc of settings) {
          if (sc?.Value != null) {
            result[sc.IntKey] = sc.Value;
          }
        }
        return result;
      });
    this._chainSettings = chainSettings;
    this._chainSettings$.next(chainSettings);
  }

  public setUserToken(token: UserToken): void {
    this._userToken$.next(token);
  }

  public async setToken(token: string) {
    LoginService.sessionOnlyLoginToken = token;
    this.setLoginCookie(token, false);
    let userToken = this.getUserToken();
    if (userToken != null) this._userToken$.next(userToken);
  }

  public get rememberMeChecked(): boolean {
    let val = localStorage.getItem(
      Constants.localStorageKeys.rememberMeChecked,
    );
    return val === '1';
  }

  private setLoginCookie(tokenString: string, rememberMe: boolean) {
    let userToken = this.parseLoginToken(tokenString);
    let expires = DateHelper.fromIsoString(userToken.Expires);

    let cookie = Interface_Constants.LoginCookieName + '=' + tokenString;
    if (rememberMe) {
      cookie += '; expires=' + expires.toUTCString();
    }
    cookie += '; path=/;';

    document.cookie = cookie;
  }

  private static get tokenString() {
    let tokenString = localStorage.getItem(
      Constants.localStorageKeys.loginToken,
    );
    if (!tokenString) {
      tokenString = sessionStorage.getItem(
        Constants.localStorageKeys.loginToken,
      );
      if (!tokenString) tokenString = LoginService.sessionOnlyLoginToken;
    }
    return tokenString;
  }

  public logout() {
    LoginService.logout();
  }

  public static logout(): void {
    App.log('Logging out user');
    localStorage.removeItem(Constants.localStorageKeys.loginToken);
    sessionStorage.removeItem(Constants.localStorageKeys.loginToken);
    document.cookie =
      Interface_Constants.LoginCookieName +
      '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    LoginService.sessionOnlyLoginToken = null;
  }

  private getUserToken(): UserToken | null {
    let tokenString = LoginService.tokenString;
    let result = this.decodeTokenString(tokenString);
    if (tokenString && !result) {
      //there was a token string, but it was not valid. Maybe an old-style token.
      //clear it.
      this.logout();
    }
    return result;
  }

  private decodeTokenString(tokenString: string | null): UserToken | null {
    if (!tokenString) {
      return null;
    }
    try {
      let base64Url = tokenString.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');
      let json = this.b64DecodeUnicode(base64);
      let userToken = JSON.parse(json) as UserToken;
      if (userToken.Type === undefined) {
        //this is an old user token. Discard and delete.
        return null;
      }
      return userToken;
    } catch (e: any) {
      try {
        this.errorService.reportError('failed to decode tokenString', e, {
          tokenString: tokenString,
        });
      } catch (reportError) {
        /* ignore reporting error */
      }
      return null;
    }
  }

  async updateUserToken() {
    await timeoutAsync(100);
    try {
      let newTokenString = await this.httpClient.get<string | null>(
        'api/user/token',
        { responseType: 'text' },
      );

      if (newTokenString) {
        let newUserToken = this.decodeTokenString(newTokenString);

        if (newUserToken) {
          let rememberMe: boolean = this.rememberMeChecked;
          this.setLoginCookie(newTokenString, rememberMe);
          if (rememberMe) {
            localStorage.setItem(
              Constants.localStorageKeys.loginToken,
              newTokenString,
            );
          } else {
            sessionStorage.setItem(
              Constants.localStorageKeys.loginToken,
              newTokenString,
            );
          }
        }
      } else {
        this.logout();
      }
    } catch (e: any) {
      try {
        this.logout();
        this.errorService.reportError('failed to get updated tokenString', e, {
          tokenString: LoginService.tokenString,
        });
      } catch (reportError) {
        /* ignore reporting error */
      }
    }
  }

  //adapted from StackOverflow:
  // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  private b64DecodeUnicode(b64String: string): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(b64String), function (c: string) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  }

  public changePassword(
    changeRequest: Interface_DTO.PasswordChangeRequest,
  ): Promise<void> {
    return this.httpClient.post<void>(
      'api/user/changePassword',
      changeRequest,
      { responseType: 'void' },
    );
  }

  private _consumerUser: Interface_DTO_Consumer.ConsumerUser | undefined;
  public get consumerUser(): Interface_DTO_Consumer.ConsumerUser | undefined {
    return this._consumerUser;
  }

  public async loginConsumerUser(
    username: string,
    password: string,
  ): Promise<boolean> {
    let req: Interface_DTO.LoginRequest = {
      Username: username,
      Password: password,
    };
    try {
      let token = await this.httpClient.post<string>(
        'api/user/consumerToken',
        req,
      );

      if (token) {
        this.setToken(token);
        let consumerUser =
          await this.httpClient.get<Interface_DTO_Consumer.ConsumerUser>(
            'api/user/consumerUser',
          );

        this._consumerUser = consumerUser;
        return true;
      }
      return false;
    } catch (e: any) {
      return false;
    }
  }

  public async createConsumerUser(
    req: Interface_DTO_Consumer.CreateConsumerUserRequest,
  ): Promise<boolean> {
    try {
      let consumerUser =
        await this.httpClient.post<Interface_DTO_Consumer.ConsumerUser>(
          'api/user/createConsumerUser',
          req,
        );

      this._consumerUser = consumerUser;
      return true;
    } catch (ex) {
      return false;
    }
  }
  private loginCookieName: string = 'loginToken';

  public get isAuthenticated(): boolean {
    if (this.hasLoginCookie()) {
      const cookie = this.getCookie(this.loginCookieName);
      if (cookie) {
        const user = this.parseLoginToken(cookie);
        const expireDate = DateHelper.fromIsoString(user.Expires);
        if (expireDate < new Date()) return false;
        return true;
      }
    }
    return (
      this.hasLoginCookie() ||
      this.hasLoginInLocalStorage() ||
      this.hasLoginInSessionStorage()
    );
  }

  redirectUrl: string | null = null;

  public getCookieUserToken(): UserToken | null {
    var loginCookie = this.getCookie(this.loginCookieName);
    if (loginCookie == null) return null;
    const decodedToken = this.decodeTokenString(loginCookie);
    return decodedToken;
  }

  getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  public async login(
    username: string,
    password: string,
    rememberMe: boolean,
  ): Promise<UserToken> {
    let loginRequest = {
      Username: username,
      Password: password,
      RememberMe: rememberMe,
    };

    const token = await this.httpClient.post<Token | undefined>(
      'api/user/token',
      loginRequest,
    );

    let result;
    if (token) {
      result = this.decodeTokenString(token.Token);
    }
    if (result) {
      this.updateUserToken();
    } else {
      throw new Error('User not found');
    }
    return result;
  }

  public loginOk(
    userToken: UserToken,
    username: string,
    rememberMe: boolean,
    returnUrl: string,
  ) {
    const user = ConvertFromUserToken(userToken, username);

    this.data.ambient.activeUser.activeUser = user;
    this.data.ambient.applicationState = ApplicationState.Initializing;

    // To make all those dependent on that userTokenObservable-thingy work
    this.setUserToken(userToken);

    localStorage.setItem(
      Constants.localStorageKeys.rememberMeChecked,
      rememberMe ? '1' : '0',
    );
    if (rememberMe) {
      localStorage.setItem(
        Constants.localStorageKeys.loginToken,
        userToken.Token,
      );
    } else {
      sessionStorage.setItem(
        Constants.localStorageKeys.loginToken,
        userToken.Token,
      );
    }

    this.userMessageService.loadUserMessages();

    const navigationExtras: NavigationExtras = {
      preserveFragment: false,
      skipLocationChange: false,
      replaceUrl: true,
    };

    if (returnUrl != null) {
      this.router.navigate([returnUrl], navigationExtras);
    } else {
      this.router.navigate(['/floorplans'], navigationExtras);
    }

    return [];
  }

  private hasLoginInLocalStorage(): boolean {
    return localStorage.getItem(Constants.localStorageKeys.loginToken) != null;
  }
  private hasLoginCookie(): boolean {
    return document.cookie.indexOf(this.loginCookieName) >= 0;
  }
  private hasLoginInSessionStorage(): boolean {
    return (
      sessionStorage.getItem(Constants.localStorageKeys.loginToken) != null
    );
  }

  private parseLoginToken(tokenString: string): User {
    let base64Url = tokenString.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    let userToken = JSON.parse(window.atob(base64)) as User;
    return userToken;
  }
}
