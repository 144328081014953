export class Translatable {
  public readonly params: string[];

  public constructor(
    public readonly key: string,
    public readonly defaultText: string,
    ...params: string[]
  ) {
    this.params = params || [];
  }
}
