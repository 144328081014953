import * as Interface_DTO from 'app/ts/Interface_DTO';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as VariantNumbers from 'app/ts/VariantNumbers';
export function isUserSelectable(
  variant: Interface_DTO.Variant | Client.ProductVariant,
): boolean {
  if (variant.TypeNumber === '0') return false;
  if (VariantNumbers.ExludeFromManualSelectionList.indexOf(variant.Number) >= 0)
    return false;
  return true;
}

export function getVariantOptionValueAsNumber(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
): number {
  let variant = Enumerable.from(variants).firstOrDefault(
    (v) => v.Number === variantNumber,
  );
  if (variant) {
    let itemVariant = Enumerable.from(itemVariants).firstOrDefault(
      (option) => option.VariantId === variant!.Id,
    );
    if (itemVariant) {
      let variantOption = Enumerable.from(
        variant.VariantOptions,
      ).firstOrDefault((vo) => vo.Id === itemVariant!.VariantOptionId);
      if (variantOption) {
        return Number(variantOption.Number);
      }
    }
  }

  return 0;
}

/**
 * Gets VariantOption.Number
 * @param variants
 * @param itemVariants
 * @param variantNumber
 */
export function getVariantOptionNumber(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
) {
  var variant = Enumerable.from(variants).firstOrDefault(
    (v) => v.Number === variantNumber,
  );
  if (variant) {
    let itemVariant = Enumerable.from(itemVariants).firstOrDefault(
      (option) => option.VariantId === variant!.Id,
    );
    if (itemVariant) {
      let variantOption = Enumerable.from(
        variant.VariantOptions,
      ).firstOrDefault((v) => v.Id === itemVariant!.VariantOptionId);
      if (variantOption) {
        return variantOption.Number;
      }
    }
  }

  return '';
}

export function getVariantOptionValueByNumber(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
  optionNumber: number,
) {
  var variant = Enumerable.from(variants).firstOrDefault(
    (v) => v.Number === variantNumber,
  );
  if (variant && variant.VariantOptions.length > optionNumber) {
    let variantOptions = Enumerable.from(variant.VariantOptions)
      .where((vo) => vo.Enabled)
      .orderBy((vo) => vo.Number)
      .toArray();
    let variantOption = variantOptions[optionNumber];
    let itemVariant = Enumerable.from(itemVariants).firstOrDefault(
      (iv) => iv.VariantOptionId === variantOption.Id,
    );

    if (itemVariant) {
      return itemVariant.ActualValue;
    }
  }

  return '';
}

export function getItemVariantValueAsNumber(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
): number {
  let itemVariant = getItemVariant(variants, itemVariants, variantNumber);
  if (itemVariant) {
    return Number(itemVariant.ActualValue);
  }

  return 0;
}

export function getItemVariantValue(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
): string {
  let itemVariant = getItemVariant(variants, itemVariants, variantNumber);
  if (itemVariant) {
    return itemVariant.ActualValue;
  }

  return '';
}

export function getItemVariant(
  variants: Interface_DTO.Variant[],
  itemVariants: Interface_DTO.ItemVariant[],
  variantNumber: string,
): Interface_DTO.ItemVariant | null {
  let variant = Enumerable.from(variants).firstOrDefault(
    (v) => v.Number === variantNumber,
  );
  if (variant) {
    const itemVariant = Enumerable.from(itemVariants).firstOrDefault(
      (option) => option.VariantId === variant!.Id,
    );

    if (itemVariant === undefined) {
      return null;
    }

    return itemVariant;
  }

  return null;
}
