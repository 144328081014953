import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class FloorPlanTemplateService {
  constructor(private readonly $http: PromisingBackendService) {}

  public async getFloorPlanTemplates() {
    let result = await this.$http.get<Interface_DTO.FloorPlanTemplate[]>(
      'api/floorPlanTemplate',
    );
    return result;
  }
}
