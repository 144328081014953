import { Injectable } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { ISnapInfoService } from '@Services/snap/ISnapInfoService';
import { SwingFlexCorpusSnapService } from '../snap/SwingFlexCorpus/SwingFlexCorpusSnapService';
import { ConfigurationItemService } from '@Services/ConfigurationItemService';

@Injectable({ providedIn: 'root' })
export class SwingFlexService {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  /**
   * Gets a snapInfoService, valid for the current drag
   * @param cabinetSection the section to drag into
   * @param items the items to move. May or may not be part of the cabinetSection
   * @param pos the starting position of the mouse, in the cabinet section's coordinate system
   */
  public getSnapInfoService(
    cabinetSection: Client.CabinetSection,
    items: Client.ConfigurationItem[],
    pos: Interface_DTO_Draw.Vec2d,
  ): ISnapInfoService {
    if (items.length < 1) {
      throw new Error("Can't drag no items");
    }

    if (items.length > 1) {
      throw new Error("Can't drag multiple items");
    }

    let item = items[0];
    return new SwingFlexCorpusSnapService(
      this.configurationItemService,
      cabinetSection,
      item,
      pos,
    );
  }
}
