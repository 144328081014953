import { Component, Input } from '@angular/core';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'pickable',
  templateUrl: 'pickable.html',
  styleUrls: ['../../style/pickable.scss'],
})
export class PickableVm<T> extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  @Input()
  public pickable: Pickable<T> | undefined;

  public get title(): string {
    if (!this.pickable) return '';

    let lines: string[] = [
      this.pickable.name!,
      this.pickable.description!,
      this.pickable.warnReason!,
      this.pickable.disabledReason!,
    ];
    if (this.pickable.override) {
      lines.push(
        this.translate(
          'pickable_catalog_override',
          "This product is not available from the current store's standard catalog",
        ),
      );
    }

    let str = lines.filter((s) => !!s).join('\n');
    return str;
  }
}
