import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloorPlansVm } from './FloorPlansVm';
import { DarkSideBarModule } from './components/dark-sidebar-module';
import { CustomerNavModule } from './components/customer-nav.module';
import { CustomerTopbarModule } from 'app/customer-topbar/customer.topbar.module';
import { BrowserModule } from '@angular/platform-browser';
import { OrderStatusModule } from './components/orderstatus.module';
import { FloorplanDetailEditModule } from './components/floorplan-detail-edit.module';
import { FloorplanOverviewBoxModule } from './components/floorplan-overview-box.module';
import { RouterModule } from '@angular/router';
import { ProjectEditMenuModule } from './components/projectEditMenu.module';
import { DeliveryAddressEditMenuModule } from './components/deliveryAddressEditMenu.module';
import { CornerNameBubbleModule } from './components/corner-name-bubble.module';
import { CustomerEditModule } from './components/customer-edit.module';

@NgModule({
  declarations: [FloorPlansVm],
  exports: [FloorPlansVm],
  providers: [AsyncPipe],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    CornerNameBubbleModule,
    CustomerEditModule,
    CustomerNavModule,
    CustomerTopbarModule,
    DarkSideBarModule,
    DeliveryAddressEditMenuModule,
    FloorplanDetailEditModule,
    FloorplanOverviewBoxModule,
    OrderStatusModule,
    ProjectEditMenuModule,
  ],
})
export class FloorPlansModule {}
