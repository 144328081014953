import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  exports: [],
  providers: [
    // { provide: StoreSettingInjector, useFactory: StoreSettingFactory, deps:[CommandInterpreter, FunctionalCoreService]},
  ],
  imports: [],
})
export class FunctionalCoreModule {}
