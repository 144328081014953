import { Component, DestroyRef, inject, Inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import {
  ClientSetting,
  ClientSettingData,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { DeliveryAddress } from 'app/ts/clientDto';
import { Customer } from 'app/ts/clientDto/Customer';
import { Project } from 'app/ts/clientDto/Project';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { filter } from 'rxjs';
import { CustomerCounts } from './update-counts.pipe';

@Component({
  selector: 'customer-nav',
  templateUrl: 'customerNav.html',
  styleUrls: ['../../../../style/nav.scss'],
  providers: [activeStoreProvider, clientSettingProvider],
})
export class CustomerNavVm extends BaseVm {
  public clientSetting: ClientSettingData;

  private searchParams!: CustomerSearchParams;

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public constructor(
    baseVmService: BaseVmService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly customerService: CustomerService,
    @Inject(ActiveStoreInjector) private readonly activeStore: ActiveStore,
    @Inject(ClientSettingInjector) clientSetting: ClientSetting,
  ) {
    super(baseVmService);

    super.ensureUnsubscribe(
      clientSetting.subscribe(
        (clientSetting) => (this.clientSetting = clientSetting),
      ),
    );
    this.clientSetting = clientSetting.data;

    this.activatedRoute.queryParamMap
      .pipe(
        filter((queryParams) => queryParams != null),
        takeUntilDestroyed(),
      )
      .subscribe((queryParams) => {
        this.searchParams = CustomerSearchParams.FromParams(queryParams);
      });
  }

  @Input({ required: true })
  public customerCounts!: CustomerCounts;

  @Input()
  public selection!:
    | 'customers'
    | 'projects'
    | 'deliveryAddresses'
    | 'floorPlans';

  public customer: Customer | null = null;
  public project: Project | null = null;
  public deliveryAddress: DeliveryAddress | null = null;

  public numCustomers: number = 0;
  public numProjects = 0;
  public numDeliveryAddresses = 0;
  public numFloorPlans = 0;

  public get showExtraOverviewTabs(): boolean {
    return this.clientSetting.showExtraOverviewTabs;
  }

  public get httpParams(): Params {
    return CustomerSearchParams.ToQueryParams(this.searchParams);
  }

  private setSelection(allCustomers: Customer[]) {
    this.deliveryAddress = null;
    if (this.searchParams.deliveryAddressId === undefined) {
      this.deliveryAddress = null;
    } else {
      let addrId = parseInt(this.searchParams.deliveryAddressId);
      this.deliveryAddress =
        Enumerable.from(allCustomers)
          .selectMany((cust) => cust.Projects)
          .selectMany((proj) => proj.DeliveryAddresses)
          .firstOrDefault((da) => da.ProjectDeliveryAddressId === addrId) ??
        null;
    }

    if (this.deliveryAddress) {
      this.project = this.deliveryAddress.project;
    } else if (this.searchParams.projectId === undefined) {
      this.project = null;
    } else {
      let projectId = parseInt(this.searchParams.projectId);
      this.project =
        Enumerable.from(allCustomers)
          .selectMany((cust) => cust.Projects)
          .firstOrDefault((proj) => proj.Id === projectId) ?? null;
    }

    if (this.project) {
      this.customer = this.project.customer;
    } else if (this.searchParams.customerId === undefined) {
      this.customer = null;
    } else {
      let custId = parseInt(this.searchParams.customerId);
      this.customer = null;
      for (let cust of allCustomers)
        if (cust.Id === custId) {
          this.customer = cust;
          break;
        }
    }
  }

  public updateCounts(customers: Customer[]) {
    this.numCustomers = customers.length;
    this.numProjects = customers
      .map((c) => c.Projects.length)
      .reduce((prevProjLen, currProjLen) => prevProjLen + currProjLen, 0);
    this.numDeliveryAddresses = Enumerable.from(customers)
      .selectMany((c) => c.Projects)
      .sum((p) => p.DeliveryAddresses.length);
    this.numFloorPlans = Enumerable.from(customers)
      .selectMany((c) => c.Projects)
      .selectMany((p) => p.DeliveryAddresses)
      .sum((da) => da.FloorPlans.length);
    this.setSelection(customers);
  }
}
