import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ModalService } from 'app/ts/services/ModalService';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { IConsumerPage } from 'app/ts/viewmodels/consumer/pages/IConsumerPage';
import { CabinetSectionService } from './cabinet-section.service';
import { SingleDoorVm } from './SingleDoorVm';

@Component({
  selector: 'doors-vm',
  templateUrl: './doors.html',
  styleUrls: [
    'common.scss',
    'dimensions.scss',
    './doorsVm.scss',
    '../../../../../style/d3.scss',
  ],
})
export class DoorsVm extends BaseVm implements IConsumerPage {
  private readonly cache = new Client.Cache<{
    availableDoorProfiles: Pickable<Client.Product | null>[];
    selectedDoorProfile: Client.Product | null;
    availableNumberOfDoors: Pickable<number>[];
    availableFillingMaterials: Pickable<Client.FillingMaterial>[];
    selectedFillingMaterial: Client.FillingMaterial | null;
    availableProfileMaterials: Pickable<Client.ProductMaterial | null>[];
    availableRailSets: Pickable<Client.RailSet | null>[];
    availableRailMaterials: Pickable<Client.RailMaterial>[];
    availableBarDesigns: Pickable<Client.BarDesign>[];
    doors: SingleDoorVm[];
  }>();

  public cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    cabinetSections: CabinetSectionService,
    private readonly floorPlanService: FloorPlanService,
    private readonly modalService: ModalService,
    private routing: AppRoutingModule,
  ) {
    super(baseVmService);
    cabinetSections.cabinetSection$
      .pipe(takeUntilDestroyed())
      .subscribe((cabinetSection) => {
        this.cabinetSection = cabinetSection;
        this.subscribeTo(cabinetSection.cabinet.floorPlan.floorPlan$, (fp) =>
          this.cache.clear(),
        );

        this.init();
      });

    // this.ngOnInit();
  }

  public init() {
    if (!this.floorPlan.hasSeenConsumerDoorModal) {
      this.openDoorProfileModal(true);
    }
    this.floorPlan.hasSeenConsumerDoorModal = true;
  }

  public get showHelp() {
    return this.cabinetSection.doors.profile === null;
  }

  //region doorProfiles
  public get availableDoorProfiles(): Pickable<Client.Product | null>[] {
    return this.cache.get('availableDoorProfiles', () =>
      this.cabinetSection.doors.pickableProfiles
        .filter((dp) => dp.disabledReason === null)
        .map<Pickable<Client.Product | null>>((dp) => ({
          ...dp,
          imageUrl: this.consumerFile(
            'doors/',
            dp.item ? dp.item.ProductNo.toString() : 'null',
            '/icon.png',
          ),
        })),
    );
  }

  public get selectedDoorProfile(): Client.Product | null {
    return this.cache.get(
      'selectedDoorProfile',
      () => this.cabinetSection.doors.profile,
    );
  }
  public set selectedDoorProfile(val: Client.Product | null) {
    if (val != this.cabinetSection.doors.profile) {
      this.cabinetSection.doors.profile = val;
      this.setChanged();
    }
  }

  public async openDoorProfileModal(firstTime: boolean = false) {
    let startProfile = this.selectedDoorProfile;

    if (firstTime) {
      //show "the most expensive door" as default.
      let defaultDoor = this.availableDoorProfiles.filter(
        (dp) => dp.item && dp.item.ProductNo === '27',
      )[0]; //UNSILVANIZE
      if (!defaultDoor) {
        defaultDoor = this.availableDoorProfiles[1];
      }
      if (defaultDoor) {
        startProfile = defaultDoor.item;
      }
    }
    let newProfile = this.modalService.customerSelectDoorProfile(
      this.availableDoorProfiles.map((dp) => {
        return {
          ...dp,
          largeImageUrl: '',
          smallImageUrl: dp.imageUrl ? dp.imageUrl : '',
          productId: dp && dp.item && dp.item.Id ? dp.item.Id : null,
          description: '',
        };
      }),
      startProfile,
    );
    let choice = await newProfile;
    if (choice !== null) {
      this.selectedDoorProfile = choice.selection;
    }
  }

  //region numberOfDoors
  public get showNumberOfDoors(): boolean {
    return this.availableNumberOfDoors.some((n) => n.item > 0);
  }

  public get availableNumberOfDoors(): Pickable<number>[] {
    return this.cache.get('availableNumberOfDoors', () =>
      this.cabinetSection.doors.availableNumberOfDoors.map((n) => ({
        disabledReason: null,
        isSelected: this.numberOfDoors === n,
        item: n,
        name: this.translate(
          'consumer_doors_numDoors_option',
          '{0} doors',
          n.toString(),
        ),
        override: false,
        id: n.toString(),
      })),
    );
  }

  public get numberOfDoors(): number {
    return this.cabinetSection.doors.numberOfDoors;
  }
  public set numberOfDoors(val: number) {
    if (val === this.cabinetSection.doors.numberOfDoors) return;
    this.cabinetSection.doors.suppressNumDoorsChangeWarning = true;
    this.cabinetSection.doors.numberOfDoors = val;
    if (this.useGalleryLamps) {
      this.mathcGalleryLampCountToDoorCount();
    }
    this.setChanged();
  }

  //#region filling colors
  public get showFillingMaterials() {
    return this.availableFillingMaterials.length > 0;
  }
  public get availableFillingMaterials(): Pickable<Client.FillingMaterial>[] {
    return this.cache.get('availableFillingMaterials', () =>
      this.getFillingMaterials().map((fm) => {
        let pickable = MaterialHelper.getPickableForFilling(fm.material);
        pickable.isSelected =
          (this.selectedFillingMaterial &&
            pickable.item.equals(this.selectedFillingMaterial)) ||
          false;
        return pickable;
      }),
    );
  }
  private getFillingMaterials(): Client.FillingMaterial[] {
    let doorProduct = this.selectedDoorProfile;
    let firstDoor: Client.Door | undefined = this.cabinetSection.doors.doors[0];
    if (!doorProduct || !firstDoor) {
      return [];
    }
    let mats = doorProduct.materials
      .filter(
        (mat) =>
          mat.Type === Interface_Enums.MaterialType.Normal &&
          !mat.isDiscontinued &&
          !mat.isOverride &&
          (mat.designType === Interface_Enums.MaterialDesignType.Normal ||
            mat.designType ===
              Interface_Enums.MaterialDesignType.DesignAndNormal) &&
          MaterialHelper.supportsFillingSize(mat, firstDoor!.width),
      )
      .map((mat) => new Client.FillingMaterial(mat, false));
    return mats;
  }

  public get selectedFillingMaterial(): Client.FillingMaterial | null {
    return this.cache.get('selectedFillingMaterial', () =>
      this.getSelectedFillingMaterial(),
    );
  }
  private getSelectedFillingMaterial(): Client.FillingMaterial | null {
    //checks if all filling materials are the same
    let id: number | undefined = undefined;
    for (let door of this.cabinetSection.doors.doors) {
      for (let filling of door.actualFillings) {
        if (id === undefined) {
          //first filling on first door
          id = filling.materialId;
          continue;
        }
        if (id !== filling.materialId) {
          //not all fillings are the same color - no colors are selected
          return null;
        }
      }
    }
    if (id === undefined) return null;
    let mat = this.getFillingMaterials().filter(
      (fm) => fm.material.Id === id,
    )[0];
    if (mat === undefined) return null;
    return mat;
  }

  public set selectedFillingMaterial(val: Client.FillingMaterial | null) {
    if (val === null) return;
    let material = this.availableFillingMaterials.filter(
      (fc) => fc.item === val,
    )[0];
    if (!material) return;
    for (let door of this.cabinetSection.doors.doors) {
      for (let filling of door.fillings) {
        filling.fillingMaterial = material.item;
      }
    }
    this.setChanged();
  }

  //#endregion

  //#region bar design

  public get showBarDesigns() {
    return this.showFillingMaterials;
  }

  public get availableBarDesigns(): Pickable<Client.BarDesign>[] {
    return this.cache.get('availableBarDesigns', () =>
      this.cabinetSection.doors.availableBarDesigns.map((bd) => ({
        disabledReason: null,
        isSelected: false,
        item: bd,
        name: this.translate(bd.translationKey, bd.defaultName),
        override: false,
        id: bd.id.toString(),
        //imageUrl: this.translate('consumer_base_dir','consumer_files/default') + bd.imageUrlSuffix,
        imageUrl: this.consumerFile(bd.imageUrlSuffix),
      })),
    );
  }

  public get selectedBarDesign() {
    let door1 = this.cabinetSection.doors.doors[0];
    if (!door1) {
      return;
    }
    let barDesign = door1.barDesign;
    if (!barDesign) {
      return;
    }
    let areDesignsTheSame = this.cabinetSection.doors.doors.every(
      (door) => !!door.barDesign && door.barDesign.id === barDesign!.id,
    );
    if (!areDesignsTheSame) {
      return;
    }
    return door1.barDesign;
  }
  public set selectedBarDesign(val: Client.BarDesign | undefined) {
    if (!val) {
      return;
    }
    this.cabinetSection.doors.setBarDesign(val);
    this.setChanged();
  }

  //#endregion

  //#region profile colors
  public get availableProfileMaterials(): Pickable<Client.ProductMaterial | null>[] {
    return this.cache.get('availableProfileMaterials', () =>
      this.cabinetSection.doors.pickableProfileMaterials.filter(
        (pickable) => !pickable.disabledReason,
      ),
    );
  }
  public get showProfileMaterialSelector(): boolean {
    return this.availableProfileMaterials.length > 1;
  }

  public get selectedProfileMaterial(): Client.ProductMaterial | null {
    let pickable = this.availableProfileMaterials.filter(
      (pm) => pm.isSelected,
    )[0];
    if (!pickable) return null;
    return pickable.item;
  }
  public set selectedProfileMaterial(val: Client.ProductMaterial | null) {
    this.cabinetSection.doors.profileMaterial = val;
    this.setChanged();
  }

  //#endregion

  //#region railset
  public get availableRailSets(): Pickable<Client.RailSet | null>[] {
    return this.cache.get('availableRailSets', () =>
      this.cabinetSection.doors.pickableRailsets.filter(
        (rs) => !rs.disabledReason,
      ),
    );
  }

  public get showRailsetSelector(): boolean {
    return this.availableRailSets.length > 1;
  }

  public get selectedRailSet(): Client.RailSet | null {
    let pickable = this.availableRailSets.filter((pm) => pm.isSelected)[0];
    if (!pickable) return null;
    return pickable.item;
  }
  public set selectedRailSet(val: Client.RailSet | null) {
    this.cabinetSection.doors.railSet = val;
    this.setChanged();
  }
  //#endregion

  //#region railset material
  public get showRailMaterials(): boolean {
    return this.availableRailMaterials.length > 1;
  }
  public get availableRailMaterials(): Pickable<Client.RailMaterial>[] {
    return this.cache.get('availableRailMaterials', () => {
      let mats = this.cabinetSection.doors.getAvailableRailMaterials();
      let pickables = mats.map<Pickable<Client.RailMaterial>>((mat) => ({
        ...MaterialHelper.getPickable(mat.topMaterial),
        item: mat,
        isSelected:
          (this.cabinetSection.doors.railMaterialTop &&
            mat.topMaterial.Id ===
              this.cabinetSection.doors.railMaterialTop.Id) ||
          false,
      }));
      return pickables;
    });
  }

  public get selectedRailMaterial(): Client.RailMaterial | null {
    let pickable = this.availableRailMaterials.filter((pm) => pm.isSelected)[0];
    if (!pickable) return null;
    return pickable.item;
  }
  public set selectedRailMaterial(val: Client.RailMaterial | null) {
    let materialTopChanged = false;
    let materialBottomChanged = false;

    if (val) {
      materialTopChanged =
        this.cabinetSection.doors.railMaterialTop != val.topMaterial;
      materialBottomChanged =
        this.cabinetSection.doors.railMaterialBottom != val.bottomMaterial;
    }

    this.cabinetSection.doors.railMaterialTop = val ? val.topMaterial : null;
    this.cabinetSection.doors.railMaterialBottom = val
      ? val.bottomMaterial
      : null;

    if (materialBottomChanged || materialTopChanged) {
      this.setChanged();
    }
  }

  //#endregion

  public showAllDoors = false;

  public setAllDoorsTrue() {
    this.showAllDoors = true;
  }

  public setAllDoorsFalse() {
    this.showAllDoors = false;
  }

  public get showToggleAdvancedButton() {
    return this.selectedDoorProfile !== null;
  }

  public get doors(): SingleDoorVm[] {
    return this.cache.get('doors', () =>
      this.cabinetSection.doors.doors.map(
        (door, idx) =>
          new SingleDoorVm(
            this.translate(
              'consumer_doors_door_name',
              'Door {0}',
              (idx + 1).toString(),
            ),
            door,
            this.getFillingMaterials().map((fm) => {
              let pickable = MaterialHelper.getPickableForFilling(fm.material);
              pickable.isSelected =
                fm.material.Id === door.fillings[0].materialId;
              return pickable;
            }),
            this.availableBarDesigns,
            () => {
              this.setChanged();
            },
          ),
      ),
    );
  }

  //#region gallery Lamp

  public get showUseGalleryLamps() {
    return (
      this.numberOfDoors > 0 &&
      this.cabinetSection.corpus.numberOfLightsMax > 0 &&
      this.cabinetSection.corpus.availableLightingProducts.length > 0
    );
  }

  public get useGalleryLamps(): boolean {
    return this.cabinetSection.corpus.numberOfLights > 0;
  }

  public set useGalleryLamps(val: boolean) {
    if (this.useGalleryLamps === val) return;
    if (val) {
      this.mathcGalleryLampCountToDoorCount();
    } else {
      this.cabinetSection.corpus.lightingProduct = null;
      this.cabinetSection.corpus.numberOfLights = 0;
    }
    this.setChanged();
  }

  private mathcGalleryLampCountToDoorCount() {
    let lp = this.cabinetSection.corpus.availableLightingProducts[0];
    if (!lp) {
      this.cabinetSection.corpus.numberOfLights = 0;
      return;
    }
    this.cabinetSection.corpus.lightingProduct = lp;
    this.cabinetSection.corpus.numberOfLights = ObjectHelper.clamp(
      this.cabinetSection.corpus.numberOfLightsMin,
      this.numberOfDoors,
      this.cabinetSection.corpus.numberOfLightsMax,
    );
  }

  //#endregion

  //#region softclose

  public get showUseSoftClose() {
    return this.cabinetSection.doors.canAddSoftclose;
  }

  public get useSoftclose() {
    return this.cabinetSection.doors.addSoftClose;
  }
  public set useSoftclose(val: boolean) {
    this.cabinetSection.doors.addSoftClose = val;
    this.setChanged();
  }

  //#endregion softclose

  //#region misc
  private setChanged() {
    this.floorPlanService.setChanged(this.cabinetSection.cabinet.floorPlan);
  }
  public get floorPlan() {
    return this.cabinetSection.cabinet.floorPlan;
  }
  public get cabinet() {
    return this.cabinetSection.cabinet;
  }

  public next() {
    this.routing.Consumer_interior();
  }
  public previous() {
    this.routing.Consumer_corpus();
  }
  //#endregion
}
