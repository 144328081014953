<form
  class="modal-content"
  [ngClass]="{'touched': showUntouchedInvalidValues}"
  autocomplete="off"
>
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('create_delivery_address_modal_header', 'Create Delivery Address')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="form-check">
      <label class="form-check-label" for="AddressSourceStore">
        <input
          class="form-check-input"
          type="radio"
          id="AddressSourceStore"
          name="AddressSourceStore"
          [(ngModel)]="addressSource"
          value="customer"
        />
        <span
          [textContent]="translate('create_delivery_address_modal_address_source_customer', 'Customer')"
        ></span>
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label" for="AddressSourceStore1">
        <input
          class="form-check-input"
          type="radio"
          id="AddressSourceStore1"
          name="AddressSourceStore"
          [(ngModel)]="addressSource"
          value="store"
        />
        <span
          [textContent]="translate('create_delivery_address_modal_address_source_store', 'Store')"
        ></span>
      </label>
      <select
        name="SelectedStoreDeliveryAddress2"
        [(ngModel)]="selectedStoreDeliveryAddress"
        [hidden]="!(storeDeliveryAddresses.length > 1)"
        onfocus="document.getElementById('addr-source-store').click()"
      >
        <option *ngFor="let addr of storeDeliveryAddresses" [ngValue]="addr">
          {{addr.Name}}
        </option>
      </select>
    </div>
    <div class="form-check">
      <label class="form-check-label" for="AddressSourceStore2">
        <input
          class="form-check-input"
          type="radio"
          name="AddressSourceStore"
          id="AddressSourceStore2"
          [(ngModel)]="addressSource"
          value="other"
        />
        <span
          [textContent]="translate('create_delivery_address_modal_address_source_other', 'Other:')"
        ></span>
      </label>
    </div>

    <div *ngIf="addressSource">
      <delivery-address-edit
        [detailObject]="address"
        [notEnabled]="!addressEditable"
      ></delivery-address-edit>
    </div>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="close()"
        [textContent]="translate('create_delivery_address_modal_button_save', 'Create')"
      ></button>
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()"
        [textContent]="translate('create_delivery_address_modal_button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</form>
