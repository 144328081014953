<div class="overview-content">
  <customer-topbar [parent]="this"></customer-topbar>

  <div class="overview-minus-topbar">
    <dark-side-bar></dark-side-bar>
    <div class="overview-minus-dark-side-bar">
      <customer-nav
        [selection]="'deliveryAddresses'"
        [customerCounts]="customers$ | async | updateCounts"
      ></customer-nav>
      <div class="overview-minus-customer-nav">
        <button
          class="btn btn-default"
          (click)="newObject()"
          *ngIf="!!parentObject"
          [attr.title]="translate('deliveryaddresses_button_new_deliveryaddress', 'New Address')"
        >
          <span class="bi bi-plus"></span>
          <span class="bi bi-geo-alt-fill"></span>
          <span
            [textContent]="translate('deliveryaddresses_button_new_deliveryaddress', 'New Address')"
          ></span>
        </button>
        <button
          class="btn btn-light"
          (click)="selectColumns()"
          [attr.title]="translate('deliveryaddresses_button_select_columns', 'Columns')"
        >
          <span class="bi bi-funnel-fill"></span>
        </button>

        <delivery-addresses-table
          [objects]="objects"
          (onItemSelected)="setSelected($event)"
          (onBackward)="showAncestor($event)"
          (onForward)="showChildren($event)"
          [highlightId]="highlightId"
        >
        </delivery-addresses-table>
      </div>
    </div>
  </div>
  <corner-name-bubble></corner-name-bubble>
</div>

<delivery-address-edit-menu
  [clientDeliveryAddress]="editingDeliveryAddress"
  (onClose)="closeRightMenu()"
>
</delivery-address-edit-menu>
