<div
  class="editor-all editor-d3 no-left-menu no-right-menu"
  *ngIf="editorType.ready"
>
  <editor-topbar
    [vm]="this"
    [selection]="'d3'"
    [floorPlan]="floorPlan"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>
  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showDoors" />
        <span
          [textContent]="translate('editor_label_show_doors', 'Show Doors')"
        ></span>
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showInterior" />
        <span
          [textContent]="translate('editor_label_show_interior', 'Show Interior')"
        ></span>
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showCorpus" />
        <span
          [textContent]="translate('editor_label_show_corpus', 'Show corpus')"
        ></span>
      </label>
    </div>

    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showOtherCabinets" />
        <span
          [textContent]="translate('editor_label_show_other_cabinets', 'Show other cabinets')"
        ></span>
      </label>
    </div>
  </div>
  <div class="editor-center d3-display-container">
    <d3-display
      [cabinetSection]="cabinetSection"
      [showDoors]="showDoors"
      [showInterior]="showInterior"
      [showCorpus]="showCorpus"
      [showRulers]="false"
      [showOtherCabinets]="showOtherCabinets"
      [allowMoveInterior]="false"
      [showCollisions]="false"
      [PartitionPlan]="partitions.partitionPlan$ | async"
    ></d3-display>
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
