<div class="info-property property-delivery-week">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_delivery_week', 'Delivery Week')"
  ></div>
  <div class="property-value" [textContent]="deliveryWeek"></div>
</div>
<div
  class="info-property property-delivery-weekdays"
  *ngIf="!!deliveryWeekdays"
>
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_delivery_weekdays', 'Delivery Weekdays')"
  ></div>
  <div class="property-value" [textContent]="deliveryWeekdays"></div>
</div>
<div class="info-property property-planned-delivery-day">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_planned_delivery_day', 'Delivery Day')"
  ></div>
  <div class="property-value" [textContent]="plannedDeliveryDay"></div>
</div>

<div class="info-property property-edit-deadline">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_edit_deadline', 'Edit deadline')"
  ></div>
  <div class="property-value" [textContent]="editDeadline"></div>
</div>
<div class="info-property property-num-cabinets">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_num_cabinets', 'Cabinets')"
  ></div>
  <div class="property-value" [textContent]="numCabinets"></div>
</div>
<div class="info-property property-freight-price money-property">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_freight_price', 'Shipping and handling')"
  ></div>
  <div class="property-value" [textContent]="freightPrice"></div>
</div>
<div class="info-property property-total-price money-property">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_total_price', 'Total Price')"
  ></div>
  <div class="property-value" [textContent]="totalPrice"></div>
</div>
<div class="info-property property-order-no">
  <div
    class="property-name"
    [textContent]="translate('floorplan_overview_order_no', 'Order number')"
  ></div>
  <div class="property-value" [textContent]="orderNo"></div>
</div>
