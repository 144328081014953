import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Defaults } from './defaults';
import {
  Door,
  DoorData,
  Module,
  ModuleData,
  ModuleId,
  ModuleType,
  Wall,
  WallData,
} from './module';
import {
  CornerProfile,
  CornerProfileData,
  Profile,
  ProfileData,
  ProfileType,
  StartStopProfile,
  TProfile,
  TProfileData,
  NicheProfile,
  ProfileId,
} from './profile';
import { Section, SectionData, SectionId } from './section';
import { PartitionDefaultsService } from './partition-defaults.service';
import { Filling, FillingData } from './filling';
import * as Client from 'app/ts/clientDto/index';
import { Partition, PartitionId } from './partition';
import { Rail, RailId } from './rail';
import { JsonMigrator } from './migrations/json-migrator';

/**
 * Contains the actual data for the partition plan.
 * Can be seen as sort of a repository for
 * @see PartitionPlanQueryService and
 * @see PartitionPlanCommandService
 * which should be the only consumers of this service.
 */
@Injectable()
export class PartitionPlanDataService {
  constructor(private readonly _defaults: PartitionDefaultsService) {
    this._plan = {
      partitions: [],
      sections: [],
      modules: [],
      profiles: [],
      rails: [],
    };
    this._partitionPlan$ = new BehaviorSubject<Readonly<PartitionPlan>>(
      this.plan,
    );
  }

  public get plan(): PartitionPlan {
    return this._plan;
  }
  private _plan: PartitionPlan;

  //#region ID Counters
  public setPartitionIdCounter(next: number) {
    this._nextPartitionId = next as PartitionId;
  }
  public nextPartitionId(): PartitionId {
    return this._nextPartitionId++ as PartitionId;
  }
  private _nextPartitionId: PartitionId = 1 as PartitionId;
  public nextSectionId(): SectionId {
    return this._nextSectionId++ as SectionId;
  }
  private _nextSectionId: SectionId = 1 as SectionId;

  public nextModuleId(): ModuleId {
    return this._nextModuleId++ as ModuleId;
  }
  private _nextModuleId: ModuleId = 1 as ModuleId;

  public nextProfileId(): ProfileId {
    return this._nextProfileId++ as ProfileId;
  }
  private _nextProfileId: ProfileId = 1 as ProfileId;

  public nextRailId(): RailId {
    return this._nextRailId++ as RailId;
  }
  private _nextRailId: RailId = 1 as RailId;

  //#endregion

  //#region Save & Load
  public hydratePartition(partitionJsonData?: string) {
    this.selectedSection = undefined;
    this.selectedModule = undefined;

    if (partitionJsonData == null || partitionJsonData == '') {
      this.newPartitionPlan();
      return;
    }

    try {
      const hydratedPlan = JSON.parse(partitionJsonData) as PartitionPlanData;
      JsonMigrator.checkAndDoMigrations(hydratedPlan);

      this.setPlan(hydratedPlan);

      // Set id counters to continue from last
      if (hydratedPlan.sections.length > 0) {
        //Math.max returns 0, on empty arrays (perfect)

        if (hydratedPlan.partitions) {
          const partitionIds = hydratedPlan.partitions.map((prt) => prt.id);
          this._nextPartitionId = (Math.max(...partitionIds) +
            1) as PartitionId;
        } else {
          this._nextPartitionId = 1 as PartitionId;
        }

        const sectionIds = hydratedPlan.sections.map((sec) => sec.id);
        this._nextSectionId = (Math.max(...sectionIds) + 1) as SectionId;

        const modules = hydratedPlan.modules;
        this._nextModuleId = (Math.max(...modules.map((md) => md.id)) +
          1) as ModuleId;

        const profiles = hydratedPlan.profiles;
        this._nextProfileId = (Math.max(...profiles.map((p) => p.id)) +
          1) as ProfileId;

        if (hydratedPlan.rails) {
          const rails = hydratedPlan.rails;
          this._nextRailId = (Math.max(...rails.map((r) => r.id)) +
            1) as RailId;
        } else {
          this._nextRailId = 1 as RailId;
        }
      } else {
        this.resetIdCounters();

        // The fun way, but Asmus said no

        // this._nextPartitionId = (
        //     this._nextSectionId = (
        //         this._nextModuleId = (
        //             this._nextProfileId = 1 as ProfileId
        //         ) as Number as ModuleId
        //     ) as Number as SectionId
        // ) as Number as PartitionId
      }
    } catch (e) {
      throw new Error('Failed to load partition data:\n' + e);
    }
  }

  public newPartitionPlan() {
    this.setPlan({
      partitions: [],
      sections: [],
      modules: [],
      profiles: [],
      rails: [],
    });
    this.resetIdCounters();
  }

  private resetIdCounters() {
    this._nextPartitionId = 1 as PartitionId;
    this._nextSectionId = 1 as SectionId;
    this._nextModuleId = 1 as ModuleId;
    this._nextProfileId = 1 as ProfileId;
    this._nextRailId = 1 as RailId;
  }

  /**
   * Exchanges the underlying plan data with the given plan.
   * Primarily inteded for when a plan is loaded.
   * @param plan
   */
  private setPlan(newPlan: PartitionPlanData) {
    const sections = newPlan.sections.map((s) => {
      const section = new Section(
        s.id,
        s.posX,
        s.posY,
        s.width,
        s.height,
        s.barHeight,
        s.rotation,
        s.rails,
        s.references,
        s.unevenLeftWall,
        s.unevenRightWall,
      );
      s.modules.forEach((d) => section.addModule(d));
      return section;
    });

    const modules =
      newPlan?.modules == null
        ? []
        : newPlan.modules.map((m) => {
            let fillings = m.fillings.map((fl) => new Filling(fl));
            let moduleData = {
              ...m,
              fillings,
            };
            return m.type == ModuleType.Wall
              ? new Wall(moduleData as WallData)
              : new Door(moduleData as DoorData);
          });

    const profiles =
      newPlan?.profiles == null
        ? []
        : newPlan.profiles.map((p) => {
            switch (p.type) {
              case ProfileType.StartStop:
                return new StartStopProfile(p as ProfileData);
              case ProfileType.Corner:
                return new CornerProfile(p as CornerProfileData);
              case ProfileType.TPiece:
                return new TProfile(p as TProfileData);
              case ProfileType.Niche:
                return new NicheProfile(p as ProfileData);
            }
          });

    const plan = {
      partitions: newPlan.partitions ?? [], // Handles migration from plans not including partitions
      sections,
      modules,
      profiles,
      rails: newPlan.rails ?? [], // Handles migration from plans having rails on sections
    };
    this._plan = plan;
    this._partitionPlan$.next(this._plan);
  }

  public getDataForSave(): PartitionPlanData {
    const sections = this.plan.sections.map((s) => s.data);

    const modules = this.plan.modules.map((m) => {
      return {
        ...m.data,
        fillings: m.fillings.map((fl) => fl.data),
      };
    });

    const profiles = this.plan.profiles.map((p) => p.data);
    return {
      partitions: this.plan.partitions,
      sections: sections,
      modules: modules,
      profiles: profiles,
      rails: this.plan.rails,
    };
  }
  //#endregion

  public setSelection(sectionId: SectionId, moduleId?: ModuleId) {
    if (moduleId) {
      this.selectedModule = this.plan.modules.find((m) => m.id == moduleId)!;
    }

    this.selectedSection = this.plan.sections.find((s) => s.id == sectionId)!;
  }

  //#region Selected Module
  public get selectedModule(): Module | undefined {
    return this._selectedModule;
  }
  public set selectedModule(module: Module | undefined) {
    this._selectedModule = module;
  }
  private _selectedModule?: Module;
  //#endregion

  //#region Selected Section
  public get selectedSection(): Section | undefined {
    return this._selectedSection;
  }
  public set selectedSection(section: Section | undefined) {
    this._selectedSection = section;
  }
  private _selectedSection?: Section;
  //#endregion

  public get profile(): Profile | undefined {
    return this._profile;
  }

  public set profile(profile: Profile | undefined) {
    this._profile = profile;
  }

  private _profile?: Profile;

  public get partitionPlan$(): Observable<PartitionPlan> {
    return this._partitionPlan$;
  }

  public triggerNextPlan() {
    this._partitionPlan$.next(this._plan);
  }

  private _partitionPlan$: BehaviorSubject<PartitionPlan>;

  public get defaults(): Readonly<Defaults> {
    return this._defaults.defaults;
  }
}

export interface PartitionPlan {
  partitions: Partition[];
  sections: Section[];
  modules: Module[];
  profiles: Profile[];
  rails: Rail[];
}

type ModuleSaveData = Omit<ModuleData, 'fillings'> & {
  fillings: FillingData[];
};

/** TODO: Rubbish name ;) */
export interface PartitionPlanData {
  partitions: Partition[];
  sections: SectionData[];
  modules: ModuleSaveData[];

  profiles: ProfileData[];
  rails: Rail[];
}
