import {
  EnvironmentInjector,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { Command } from './command';

@Injectable({
  providedIn: 'root',
})
/**
 * Inject the @see CommandInterpreter into
 * a component or service to execute commands
 * directly
 */
export class CommandInterpreter {
  constructor(private readonly injector: EnvironmentInjector) {}

  /**
   * @function
   * Executes the given command and any
   * resulting commands
   */
  public async execute(command: Command): Promise<void> {
    await this.executeCommands([command]);
  }

  /** Executes the given list of commands
   * in a depth-first manner. That is, for each
   * command, the resulting commands are executed
   * before moving on to the next command
   */
  public async executeMultiple(commands: Command[]): Promise<void> {
    await this.executeCommands(commands);
  }

  private async executeCommands(commands: Command[]): Promise<void> {
    commands.forEach((cmd) => {
      runInInjectionContext(this.injector, async () => {
        await this.executeCommands(await cmd.execute());
      });
    });
  }

  // private nested(commands: Command[]) {
  //     commands
  //         .then(nestedCommands => this.executeCommands(nestedCommands))
  // }
}
