<table class="piecelist-table consumer-piecelist-table" *ngIf="pieceList">
  <colgroup>
    <col class="col-item-no" />
    <col class="col-description" />
    <col class="col-material" />
    <col class="col-dimensions" />
    <col class="col-money" />
    <col class="col-money" />
  </colgroup>
  <thead>
    <tr>
      <th
        [textContent]="translate('consumer_piecelist_Item_No', 'Item No.')"
      ></th>
      <th
        [textContent]="translate('consumer_piecelist_Description', 'Description')"
      ></th>
      <th
        [textContent]="translate('consumer_piecelist_Material', 'Material')"
      ></th>
      <th
        [textContent]="translate('consumer_piecelist_Dimensions', 'Dimensions')"
      ></th>
      <th
        class="col-money"
        [textContent]="translate('consumer_piecelist_item_unit_price_incl_vat', 'Unit price')"
      ></th>
      <th
        class="col-money"
        [textContent]="translate('consumer_piecelist_item_price_incl_vat', 'Price')"
      ></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let item of items">
      <td [textContent]="item.productNumber" class="piecelist-item-itemno"></td>
      <td
        [textContent]="item.description"
        class="piecelist-item-description"
      ></td>
      <td
        [textContent]="item.material"
        class="piecelist-item-material-name"
      ></td>
      <td
        [textContent]="item.dimensions"
        class="piecelist-item-dimensions"
      ></td>
      <td class="col-money piecelist-item-unitprice">
        <span *ngIf="item.amount !== 1">
          <span [textContent]="item.amount"></span><span> x </span>
        </span>
        <span [textContent]="item.unitPrice"></span>
      </td>
      <td
        class="col-money piecelist-item-totalprice"
        [textContent]="item.totalPrice"
      ></td>
    </tr>
  </tbody>

  <tfoot>
    <tr>
      <th [textContent]="translate('piecelist_Rounding', 'Rounding')"></th>
      <th
        [textContent]="translate('piecelist_Subject_to_VAT', 'Subject to VAT')"
      ></th>
      <th [textContent]="translate('piecelist_VAT_rate', 'VAT rate')"></th>
      <th [textContent]="translate('piecelist_VAT_amount', 'VAT amount')"></th>
      <th></th>
      <th
        class="col-money"
        [textContent]="translate('piecelist_total_incl_VAT', 'Total incl VAT')"
      ></th>
    </tr>

    <tr>
      <td>
        <span class="currency" [textContent]="pieceList.Currency"></span>
        <span [textContent]="rounding"></span>
      </td>
      <td>
        <span class="currency" [textContent]="pieceList.Currency"></span>
        <span [textContent]="toMoneyFormat(pieceList.PriceWithoutVat)"></span>
      </td>
      <td>
        <span [textContent]="pieceList.VatRate.toFixed(2)"></span>
        %
      </td>
      <td>
        <span class="currency" [textContent]="pieceList.Currency"></span>
        <span [textContent]="toMoneyFormat(pieceList.Vat)"></span>
      </td>
      <td></td>
      <td class="col-money">
        <span class="currency" [textContent]="pieceList.Currency"></span>
        <span [textContent]="toMoneyFormat(pieceList.PriceWithVat)"></span>
      </td>
    </tr>
  </tfoot>
</table>
