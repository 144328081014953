<div class="consumer-load consumer consumer-page">
  <div class="consumer-nav">
    <navbar></navbar>
  </div>

  <div class="consumer-left"></div>

  <div class="consumer-main">
    <div *ngIf="showLoadSpinner" class="load-spinner"></div>

    <div *ngIf="showTable">
      <table
        class="table table-striped table-bordered table-hover floorplan-table"
      >
        <colgroup>
          <col class="name-col" />
          <col class="date-col" />
          <col class="load-col" />
        </colgroup>
        <thead>
          <tr>
            <th
              class="name-col"
              [textContent]="translate('consumer_load_table_header_name', 'Name')"
            ></th>
            <th
              class="date-col"
              [textContent]="translate('consumer_load_table_header_created_date', 'Created')"
            ></th>
            <th class="load-col"><!-- Load button --></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let floorplan of floorPlanInfos">
            <td class="name-col" [textContent]="floorplan.Name"></td>
            <td class="date-col" [textContent]="getCreatedDate(floorplan)"></td>
            <td class="load-col">
              <button
                class="btn btn-primary"
                (click)="load(floorplan)"
                [ngClass]="{'disabled': isLoadingFloorPlan}"
                [textContent]="translate('consumer_load_table_btn_load', 'Load')"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="consumer-bottom"></div>
</div>
