import * as THREE from 'three';
import * as Client from 'app/ts/clientDto/index';
import { ClickedItemInfo } from 'app/ts/Client/ClickedItemInfo';
import { Injectable } from '@angular/core';

type ReturnValue = { material: THREE.Material; height: number };
type RulerType = 'normal' | 'custom';
type RulerCache = { [length: number]: ReturnValue };
type RulerColorCache = { [color: string]: RulerCache | undefined };
@Injectable({ providedIn: 'root' })
export class D3RulerService {
  private readonly cache: RulerColorCache = {};

  constructor() {}

  public getRulerObject(
    ruler: Client.Ruler,
    rulerDepth: number,
  ): THREE.Object3D {
    let grp = new THREE.Group();
    if (ruler.length <= 5) {
      return grp;
    }
    if (ruler.isVertical) {
      let r = this.getHorizontalRulerObject(ruler, rulerDepth);
      r.rotateZ(-Math.PI / 2);
      let newY = r.position.y + ruler.length;
      r.position.set(r.position.x, newY, r.position.z);
      grp.add(r);
    } else {
      let r = this.getHorizontalRulerObject(ruler, rulerDepth);
      grp.add(r);
    }
    return grp;
  }

  public getHorizontalRulerObject(ruler: Client.Ruler, depth: number) {
    let width = ruler.length;
    let rulerMaterial = this.getRulerMaterial(
      width,
      ruler.isCustom ? 'custom' : 'normal',
    );
    let height = rulerMaterial.height;

    let plane = new THREE.PlaneGeometry(width, height);
    plane.translate(width / 2, height / 2, 0);

    let mesh = new THREE.Mesh(plane, rulerMaterial.material);
    mesh.position.set(ruler.start.X, ruler.start.Y, depth);

    return mesh;
  }

  public getCabinetSectionRulers(section: Client.CabinetSection): THREE.Group {
    let result = new THREE.Group();

    let rulers = Client.Ruler.GetRulers(section);

    rulers.cabinetWidthRuler.start.Y -= 200;

    let columnRulers = Client.Ruler.GetColumnRulers(section);
    if (columnRulers.length === 1) {
      columnRulers = []; //do not show section rulers if there are no sections
    }
    for (let cr of columnRulers) {
      cr.start.Y += 100;
    }

    let floorRulers = [rulers.cabinetWidthRuler, ...columnRulers];

    floorRulers.sort((r1, r2) => r1.start.Y - r2.start.Y); //rendering becomes strange if rulers are rendered in reverse order

    //width rulers on floor
    let floorRulerNumber = 10;
    for (let r of floorRulers) {
      let rg = this.getRulerObject(r, 0);
      rg.rotateX(-Math.PI / 2);
      rg.position.set(
        rg.position.x,
        rg.position.y + floorRulerNumber,
        rg.position.z + 900,
      );
      result.add(rg);
      floorRulerNumber++;
    }

    rulers.cabinetHeightRuler.start.X -= 100;
    //height rulers
    let heightRulers = [rulers.cabinetHeightRuler];

    for (let r of heightRulers) {
      let rg = this.getRulerObject(r, 0);
      rg.rotateY(Math.PI / 2);
      rg.position.set(rg.position.x + 3, rg.position.y, section.Depth + 20);
      result.add(rg);
    }

    //depth rulers
    {
      let r = new Client.Ruler(false, { X: 0, Y: 0 }, section.Depth, 0, false);
      let rg = this.getRulerObject(r, 0);
      rg.rotateY(Math.PI / 2);
      rg.position.set(2, section.Height + 100, section.Depth);
      result.add(rg);
    }

    return result;
  }

  public getSnapRulers(
    snapInfo: any,
    clickedItemInfo: ClickedItemInfo,
  ): THREE.Group {
    let result = new THREE.Group();
    const rulerDepth = clickedItemInfo.item.Z + clickedItemInfo.pos.Z + 12;
    for (let ruler of snapInfo.rulers) {
      let rulerObj = this.getRulerObject(ruler, rulerDepth);
      result.add(rulerObj);
    }
    return result;
  }
  public getRulerMaterial(length: number, type: RulerType): ReturnValue {
    let color = this.getColor(type);
    //return this.getRulerMaterialUncached(length, color);
    let cache = this.getCache(type);
    let cachedValue = cache[length];
    if (!cachedValue) {
      cachedValue = this.getRulerMaterialUncached(length, color);
      cache[length] = cachedValue;
    }
    return cachedValue;
  }

  private getCache(type: RulerType): RulerCache {
    let result = this.cache[type];
    if (!result) {
      result = {};
      this.cache[type] = result;
    }
    return result;
  }

  private getColor(type: RulerType): string {
    switch (type) {
      default:
      case 'normal':
        return 'black';
      case 'custom':
        return '#cccc00'; //yellow
    }
  }

  private getRulerMaterialUncached(length: number, color: string): ReturnValue {
    let width = length; //this.totalSize;
    let height = 128; //pooma

    let textPosY = 90; //pooma
    let rulerY = height - 20; //pooma
    let serifHeight = 20; //pooma
    let margin = 10; //pooma
    let textPosX = width / 2;
    let text = length.toLocaleString(undefined, { maximumFractionDigits: 0 });

    let canvas = this.getCanvas(width, height);
    let g = canvas.getContext('2d')!;

    //ruler text
    g.font = '80px Arial';
    g.textAlign = 'center';
    g.fillStyle = 'black';
    g.fillText(text, textPosX, textPosY);

    //draw ruler lines
    g.fillStyle = 'white';
    g.strokeStyle = color;
    g.lineWidth = 8;
    g.beginPath();
    //left serif
    g.moveTo(margin, rulerY - serifHeight / 2);
    g.lineTo(margin, rulerY + serifHeight / 2);
    //mainline
    g.lineTo(margin, rulerY);
    g.lineTo(width - margin, rulerY);
    //right serif
    g.lineTo(width - margin, rulerY - serifHeight / 2);
    g.lineTo(width - margin, rulerY + serifHeight / 2);
    g.stroke();

    let texture = new THREE.Texture(canvas);
    texture.anisotropy = 8;
    texture.needsUpdate = true;
    let material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    return { material: material, height: height };
  }

  private getCanvas(width: number, height: number) {
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }

  private nextPowerOf2(n: number): number {
    if (n > 1 << 30) {
      throw new Error('Not implemented for numbers > ' + (1 << 30));
    }
    for (let i = 29; i >= 0; i--) {
      const checkNumber = 1 << i;
      if (n > checkNumber) {
        return checkNumber << 1;
      }
    }
    throw new Error('Not implemented for numbers < 1');
  }
}
