import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
/**
 * A snapService for multiple items, where none are gables or templates.
 * The items are only moved vertically
 */
export class MultipleVerticalSnapService implements ISnapInfoService {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return (
      items.length > 1 &&
      !(items.some((i) => i.isGable) || items.some((i) => i.isTemplate))
    );
  }

  private readonly topItem: Client.ConfigurationItem;
  private readonly bottomItem: Client.ConfigurationItem;

  private readonly gableGap: Client.GableGap;
  private readonly possiblePositions: Interface_DTO_Draw.Rectangle[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  private lastSnapInfo: Client.SnapInfo | undefined;

  constructor(
    private readonly cabinetSection: Client.CabinetSection,
    private readonly items: [Client.ConfigurationItem],
    private readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
  ) {
    let orderedItems = Enumerable.from(this.items).orderBy((i) => i.Y);
    this.topItem = orderedItems.last();
    this.bottomItem = orderedItems.first();

    this.gableGap = InteriorLogic.getGableGap(cabinetSection, this.topItem);

    this.possiblePositions = this.getPossiblePositions();
    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      this.bottomItem as Interface_DTO_Draw.Vec2d,
    );
  }

  getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    let result;

    let rawItemPos = VectorHelper.subtract(pos, this.mouseOffset);
    let snapsTo: Client.ConfigurationItem[] = [];
    for (let gable of [this.gableGap.leftGable, this.gableGap.rightGable])
      if (!!gable) snapsTo.push(gable);

    let sortedSuggestions = this.possiblePositions.sort(
      (pp1, pp2) =>
        Math.abs(pp1.Y - rawItemPos.Y) - Math.abs(pp2.Y - rawItemPos.Y),
    );
    let bestSuggestion = sortedSuggestions[0];
    if (bestSuggestion) {
      if (VectorHelper.dist(pos, bestSuggestion) < Constants.snapDist) {
        result = {
          dropOffset: {
            ...VectorHelper.subtract(bestSuggestion, this.bottomItem),
            Z: 0,
          },
          pulloutRestriction: Enums.PulloutWarningSeverity.None,
          rulers: [],
          suggestedItemOffsets: [],
          horizontalGuidelineHeights: [],
          snapsTo: snapsTo,
          snappingItems: this.items,
        };
      }
    }

    if (!result) {
      result = {
        dropOffset: { X: 0, Y: 0, Z: 0 },
        pulloutRestriction: Enums.PulloutWarningSeverity.None,
        rulers: [],
        suggestedItemOffsets: [],
        horizontalGuidelineHeights: [],
        snapsTo: snapsTo,
        snappingItems: this.items,
      };
    }

    this.lastSnapInfo = result;
    return result;
  }
  place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (this.lastSnapInfo) {
      for (let item of this.items) {
        item.X += this.lastSnapInfo.dropOffset.X;
        item.Y += this.lastSnapInfo.dropOffset.Y;
        if (this.cabinetSection.interior.items.indexOf(item) < 0) {
          this.cabinetSection.interior.items.push(item);
        }
      }
    }
    if (App.debug.showSnapInfo) {
      console.debug('Snapped by me: ', this);
    }
    return { items: this.items, recalculationMessages: [] };
  }

  public dispose() {}

  //#region Private helpers

  private getPossiblePositions(): Interface_DTO_Draw.Rectangle[] {
    let result: Interface_DTO_Draw.Rectangle[] = [];

    let totalItemSnapHeight = this.topItem.Y - this.bottomItem.Y;

    for (let drillStop of this.gableGap.drillStops) {
      let itemY = drillStop - this.bottomItem.snapOffsetY;

      if (itemY < this.gableGap.Y) continue;

      if (
        itemY >
        this.gableGap.Y +
          this.gableGap.Height -
          totalItemSnapHeight -
          this.topItem.Height
      )
        continue;

      let candRect: Interface_DTO_Draw.Rectangle = {
        X: this.gableGap.X,
        Y: drillStop - this.bottomItem.snapOffsetY,
        Width: this.gableGap.Width,
        Height: totalItemSnapHeight,
      };
      result.push(candRect);
    }

    return result;
  }

  //#endregion Private helpers
}
