import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { Project } from 'app/ts/clientDto/Project';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as DTO from 'app/ts/interface_dto/index';
import { AddressService } from 'app/ts/services/AddressService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { DeliveryAddressService } from 'app/ts/services/DeliveryAddressService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  selector: 'new-delivery-address-vm',
  templateUrl: './newDeliveryAddress.html',
  styleUrls: ['../../../../style/modal.scss'],
  providers: [activeStoreProvider, DeliveryAddressService],
})
export class NewDeliveryAddressVm
  extends BasicModalVm<number>
  implements OnInit
{
  private customerAddress: Interface_DTO.DeliveryAddress | null = null;
  private otherAddress: Interface_DTO.DeliveryAddress | null = null;

  public storeDeliveryAddresses: Interface_DTO.DeliveryAddress[] = [];
  public selectedStoreDeliveryAddress: Interface_DTO.DeliveryAddress | null =
    null;
  public _addressSource: 'store' | 'customer' | 'other' = 'customer';

  public countries: Interface_DTO.Country[] = [];

  public showUntouchedInvalidValues = false;

  constructor(
    baseVmService: BaseVmService,
    activeModal: NgbActiveModal,
    private readonly deliveryAddressService: DeliveryAddressService,
    private readonly addressService: AddressService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    super(baseVmService, activeModal);
  }

  public project!: Project;
  public customer!: DTO.Customer;

  public ngOnInit() {
    this.addressService.countryPromises.then(
      (countries) => (this.countries = countries),
    );

    this.storeDeliveryAddresses = this.activeStore.data.DeliveryAddresses;
    this.selectedStoreDeliveryAddress = this.storeDeliveryAddresses[0];
    if (!this.selectedStoreDeliveryAddress && this.addressSource === 'store') {
      this.addressSource = 'customer';
    }

    this.customerAddress = this.deliveryAddressService.createCustomerAddress(
      this.project,
      this.customer,
    );
    this.otherAddress = this.deliveryAddressService.createCustomerAddress(
      this.project,
      this.customer,
    );
    this.otherAddress.Address1 = this.joinNonEmpty(
      this.otherAddress.Address1,
      this.otherAddress.Address2,
      this.otherAddress.PostalCode,
      this.otherAddress.City,
    );
    this.otherAddress.Address2 = '';
    this.otherAddress.PostalCode = '';
    this.otherAddress.City = '';
  }

  private joinNonEmpty(...strs: string[]): string {
    return strs.filter((s) => !!s).join(', ');
  }

  public get addressSource() {
    return this._addressSource;
  }
  public set addressSource(val: 'store' | 'customer' | 'other') {
    this._addressSource = val;
    if (this.addressEditable && this.otherAddress != null) {
      this.otherAddress.Phone = this.address ? this.address.Phone : '';
    }
  }

  public get addressEditable(): boolean {
    return this.addressSource === 'other';
  }

  public get address() {
    switch (this.addressSource) {
      case 'store':
        return this.selectedStoreDeliveryAddress;
      case 'customer':
        return this.customerAddress;
      case 'other':
        return this.otherAddress;
      default:
        return null;
        throw new Error('No address source selected');
    }
  }

  public get canClose(): boolean {
    switch (this.addressSource) {
      case 'customer':
        return true;
      case 'store':
        return !!this.selectedStoreDeliveryAddress;
      case 'other':
        try {
          if (this.otherAddress != null) {
            this.deliveryAddressService.validateDeliveryAddress(
              this.otherAddress,
            );
          }
          return true;
        } catch (e: any) {
          return false;
        }
      default:
        throw new Error('No address source selected');
    }
  }

  private get country(): Interface_DTO.Country | undefined {
    if (!this.address) return;
    let countryCode = this.address.Country;
    for (let c of this.countries) {
      if (c.Code === countryCode) return c;
    }
    return;
  }

  public zipCodeUpdated() {
    if (!this.address) return;
    let citiesPromise = this.addressService.getCitiesAsync(
      this.address.Country,
      this.address.PostalCode,
      true,
    );
    citiesPromise.then((cities) => {
      if (!this.address) return;
      this.address.City = cities && cities.length > 0 ? cities[0].Name : '';
    });
  }

  public override async close() {
    if (!this.canClose) {
      this.showUntouchedInvalidValues = true;
      return;
    }
    this.baseVmService.notificationService.displayExceptionMessagesAsync(
      async () => {
        let pdaId: number | null = null;
        if (this.addressSource === 'store') {
          if (this.selectedStoreDeliveryAddress) {
            let newAddress: Interface_DTO.DeliveryAddress = {
              ...this.selectedStoreDeliveryAddress,
              ProjectId: this.project.Id,
              ProjectDeliveryAddressId: -1,
            };
            let pda =
              await this.deliveryAddressService.createDeliveryAddress(
                newAddress,
              );
            pdaId = pda.ProjectDeliveryAddressId;
          }
        } else if (this.addressSource === 'customer') {
          if (this.customerAddress != null) {
            let pda = await this.deliveryAddressService.createDeliveryAddress(
              this.customerAddress,
            );
            pdaId = pda.ProjectDeliveryAddressId;
          }
        } else {
          if (this.otherAddress != null) {
            let pda = await this.deliveryAddressService.createDeliveryAddress(
              this.otherAddress,
            );
            pdaId = pda.ProjectDeliveryAddressId;
          }
        }

        if (pdaId !== null) {
          super.close(pdaId);
        }
      },
    );
  }
}
