export class Cache<T extends object> {
  private cache: Partial<T> = {};
  constructor(initialCache: Partial<T> = {}) {
    this.cache = { ...(initialCache as object) };
  }

  public clear() {
    this.cache = {};
  }

  public get<K extends keyof T>(key: K, getter: () => T[K]): T[K] {
    let val = this.cache[key];
    if (val !== undefined) {
      return val as T[K];
    }
    let newVal = getter();
    this.cache[key] = newVal;
    if (newVal === undefined) {
      console.error('undefined values are not cacheable ', { key });
    }
    return newVal;
  }
}
