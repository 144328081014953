<div class="row">
  <div class="col">
    <h4 class="right-menu-header" [textContent]="sectionHeader"></h4>
  </div>
</div>

<!-- Edit Fillings -->
<div class="row mt-2">
  <div class="col">
    <button
      class="btn btn-default btn-semi-block"
      [textContent]="'edit-fillings' | translate: 'Edit fillings'"
      (click)="routeToFillings()"
    ></button>
  </div>
</div>

<!-- Module Type -->
<div class="row mt-2">
  <div class="col">
    <label [textContent]="'module_type' | translate: 'Module type'"></label>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="module_type"
        id="module_type_1"
        [checked]="isWallSelected"
        (change)="ModuleTypeChange('Wall')"
      />
      <label
        class="form-check-label"
        for="module_type_1"
        [textContent]="'wall' | translate: 'Wall'"
      ></label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="module_type"
        id="module_type_2"
        [disabled]="isDoorModuleTypeDisabled"
        [checked]="isDoorSelected"
        (change)="ModuleTypeChange('Door')"
      />
      <label
        class="form-check-label"
        for="module_type_2"
        [ngStyle]="{ cursor: isDoorModuleTypeDisabled ? 'default' : 'pointer' }"
        [textContent]="'door' | translate: 'Door'"
      ></label>
    </div>
  </div>
</div>

<!-- Door Placement -->
<div class="row mt-2" *ngIf="isDoorSelected">
  <div class="col">
    <label
      [textContent]="'door_placement_select_label' | translate: 'Placement'"
    ></label>
  </div>
  <div class="btn-group" role="group" style="isolation: isolate">
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-primary': isDoorPlacedFront,
        'btn-secondary': isDoorPlacedBack,
      }"
      [textContent]="'door_placement_front_value' | translate: 'Front'"
      (click)="setDoorPlacement('front')"
    ></button>
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-secondary': isDoorPlacedFront,
        'btn-primary': isDoorPlacedBack,
      }"
      [textContent]="'door_placement_back_value' | translate: 'Back'"
      (click)="setDoorPlacement('back')"
    ></button>
  </div>
</div>

<!-- Door Open Direction -->
<div class="row mt-2" *ngIf="isDoorSelected">
  <div class="col">
    <label
      [textContent]="'door_open_side_select_label' | translate: 'Opening'"
    ></label>
  </div>
  <div class="btn-group" role="group" style="isolation: isolate">
    <button
      type="button"
      class="btn"
      [disabled]="isDoorOpenSideChangeDisabled"
      [ngClass]="{
        'btn-primary': isDoorOpeningLeft,
        'btn-secondary': isDoorOpeningRight || isDoorOpeningBothWays,
      }"
      [textContent]="'door_open_side_left_value' | translate: 'Left'"
      (click)="setDoorOpenDirection('left')"
    ></button>
    <button
      type="button"
      class="btn"
      [disabled]="isDoorOpenBothSidesChangeDisabled"
      [ngClass]="{
        'btn-primary': isDoorOpeningBothWays,
        'btn-secondary': !isDoorOpeningBothWays,
      }"
      [textContent]="'door_open_side_both_value' | translate: 'Both'"
      (click)="setDoorOpenDirection('both')"
    ></button>
    <button
      type="button"
      class="btn"
      [disabled]="isDoorOpenSideChangeDisabled"
      [ngClass]="{
        'btn-secondary': isDoorOpeningLeft || isDoorOpeningBothWays,
        'btn-primary': isDoorOpeningRight,
      }"
      [textContent]="'door_open_side_right_value' | translate: 'Right'"
      (click)="setDoorOpenDirection('right')"
    ></button>
  </div>
</div>

<!-- Door Slide Extension Amount -->
<div class="row mt-2" *ngIf="isDoorSelected">
  <div class="col">
    <div *ngIf="isDoorOpeningLeft || isDoorOpeningBothWays">
      <label
        [textContent]="
          'door_open_left_amount_label' | translate: 'Left Extension Amount'
        "
      ></label>
      <div class="input-group input-group-sm">
        <input
          #leftSlideExtensionAmountInput
          type="number"
          class="form-control"
          [value]="leftSlideExtensionAmount"
          (change)="
            setLeftDoorSlideExtensionAmount(
              leftSlideExtensionAmountInput.valueAsNumber
            )
          "
          [min]="minSlideExtensionAmount"
          [max]="maxLeftSlideExtensionAmount"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>

    <div *ngIf="isDoorOpeningRight || isDoorOpeningBothWays">
      <label
        [textContent]="
          'door_open_right_amount_label' | translate: 'Right Extension Amount'
        "
      ></label>
      <div class="input-group input-group-sm">
        <input
          #rightSlideExtensionAmountInput
          type="number"
          class="form-control"
          [value]="rightSlideExtensionAmount"
          (change)="
            setRightDoorSlideExtensionAmount(
              rightSlideExtensionAmountInput.valueAsNumber
            )
          "
          [min]="minSlideExtensionAmount"
          [max]="maxRightSlideExtensionAmount"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>
  </div>
</div>

<!-- Door Rail End Stop -->
<div class="row mt-2" *ngIf="isDoorSelected">
  <div class="col">
    <label [textContent]="'door_endstop_label' | translate: 'End Stop'"></label>
  </div>
  <div class="btn-group" role="group" style="isolation: isolate">
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-primary': doorHasLeftEndStop,
        'btn-secondary': !doorHasLeftEndStop,
      }"
      [textContent]="'door_end_stop_left_value' | translate: 'Left'"
      (click)="setEndStopState('left', !doorHasLeftEndStop)"
    ></button>
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-primary': doorHasRightEndStop,
        'btn-secondary': !doorHasRightEndStop,
      }"
      [textContent]="'door_end_stop_right_value' | translate: 'Right'"
      (click)="setEndStopState('right', !doorHasRightEndStop)"
    ></button>
  </div>
</div>

<div class="row extra-spacer" *ngIf="isDoorSelected"></div>
