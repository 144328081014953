import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from '@Services/LoginService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';

@Component({
  selector: 'print-dialog-vm',
  templateUrl: './PrintDialog.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class PrintDialogVm extends BaseVm implements OnInit {
  public ready: boolean = true;
  private floorPlanId: number | null = null;

  public options: Interface_DTO.PrintOptions;

  public allowItemsWithPrice = false;
  public allowItemsWithoutPrice = false;

  public printTypeString: string = '1';

  public floorPlan!: Client.FloorPlan;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly assetService: AssetService,
  ) {
    super(baseVmService);
    this.options = {
      ShowSMS: false,
      ShowTerms: false,
      Terms: '',
      PrintType: Interface_Enums.PrintType.Offer,
      ItemsWithoutPrice: false,
      ItemsWithPrice: false,
      NoHeaderFooter: false,
      ImageSubjects: [],
    };
  }

  public ngOnInit() {
    const chainSettings = this.loginService.chainSettings;
    this.allowItemsWithoutPrice =
      chainSettings[
        Interface_Enums.ChainSettingKey.AllowPrintItemsWithoutPrice
      ] === '1';
    this.allowItemsWithPrice =
      chainSettings[
        Interface_Enums.ChainSettingKey.AllowPrintItemsWithPrice
      ] === '1';

    this.allowItemsWithoutPrice =
      chainSettings[
        Interface_Enums.ChainSettingKey.AllowPrintItemsWithoutPrice
      ] === '1';
    this.allowItemsWithPrice =
      chainSettings[
        Interface_Enums.ChainSettingKey.AllowPrintItemsWithPrice
      ] === '1';

    let sections: Client.CabinetSection[] | undefined;
    if (this.floorPlan) {
      sections = Enumerable.from(this.floorPlan.cabinets)
        .selectMany((cab) => cab.cabinetSections)
        .toArray();
    } else {
      sections = undefined;
    }
    this.visibleSubjects = this.getSubjects().filter((sub) => {
      if (
        !ScreenshotService.isInterestingPartition(
          this.partitionQueryService.plan,
          sub.subject,
        )
      ) {
        return false;
      } else if (sections) {
        if (
          !sections.some((section) =>
            ScreenshotService.isInteresting(section, sub.subject),
          )
        )
          return false;
      }
      if (sub.allowSetting === undefined) return true;
      return chainSettings[sub.allowSetting] === '1';
    });
  }

  public getSubjects(): PrintDialogVm.Subject[] {
    var partitionProductLine = this.assetService.editorAssets.productLines.find(
      (pl) => pl.Id == ProductLineIds.Partition,
    ) ?? { Name: 'Partition' };

    return [
      {
        name: this.translate('print_dialog_floor_plan', 'Floor Plan'),
        subject: Interface_Enums.ImageSubject.FloorPlan,
      },
      {
        name: this.translate(
          'print_dialog_floor_plan_partition_only {0}',
          'Floor Plan - {0} Only',
          partitionProductLine.Name,
        ),
        subject: Interface_Enums.ImageSubject.FloorPlanPartitionsOnly,
      },
      {
        name: this.translate('print_dialog_swing', 'Swing'),
        subject: Interface_Enums.ImageSubject.Swing,
        allowSetting: Interface_Enums.ChainSettingKey.AllowPrintSwing,
      },
      {
        name: this.translate(
          'print_dialog_swing_with_measure',
          'Swing with measurements',
        ),
        subject: Interface_Enums.ImageSubject.SwingWithMeasurements,
        allowSetting: Interface_Enums.ChainSettingKey.AllowPrintSwing,
      },
      {
        name: this.translate('print_dialog_swing_doors', 'Swing doors'),
        subject: Interface_Enums.ImageSubject.SwingDoors,
        allowSetting: Interface_Enums.ChainSettingKey.AllowPrintSwing,
      },
      {
        name: this.translate('print_dialog_door_appearence', 'Door appearence'),
        subject: Interface_Enums.ImageSubject.Doors,
        allowSetting: Interface_Enums.ChainSettingKey.AllowPrintDoorAppearance,
      },
      {
        name: this.translate(
          'print_dialog_doors_With_Measure',
          'Doors with measure',
        ),
        subject: Interface_Enums.ImageSubject.DoorsWithMeasurements,
        allowSetting: Interface_Enums.ChainSettingKey.AllowDoorsWithMeasure,
      },
      {
        name: this.translate('print_dialog_Interrior', 'Interior'),
        subject: Interface_Enums.ImageSubject.Interior,
        allowSetting: Interface_Enums.ChainSettingKey.AllowPrintInterior,
      },
      {
        name: this.translate(
          'print_dialog_Interrior_With_Measure',
          'Interior With Measure',
        ),
        subject: Interface_Enums.ImageSubject.InteriorWithMeasurements,
        allowSetting:
          Interface_Enums.ChainSettingKey.AllowPrintInteriorWithMeasure,
      },
      {
        name: this.translate(
          'print_dialog_interior_with_dummies',
          'Interior with dummies',
        ),
        subject: Interface_Enums.ImageSubject.InteriorWithDummyItems,
        allowSetting:
          Interface_Enums.ChainSettingKey.AllowPrintInteriorWithDummies,
      },
      {
        name: this.translate(
          'print_dialog_backing_With_Measure',
          'Backing With measure',
        ),
        subject: Interface_Enums.ImageSubject.BackingDrawingWithMeasurements,
        allowSetting:
          Interface_Enums.ChainSettingKey.AllowPrintBackingWithMeasure,
      },
      {
        name: this.translate('print_dialog_3D_Picture', '3D Picture'),
        subject: Interface_Enums.ImageSubject.ThreeD,
      },
      {
        name: this.translate(
          'print_dialog_3D_Picture_nodoors',
          '3D Picture - no doors',
        ),
        subject: Interface_Enums.ImageSubject.ThreeDNoDoors,
      },
      {
        name: this.translate(
          'print_dialog_3D_Picture_partition {0}',
          '3D Picture - {0}',
          partitionProductLine.Name,
        ),
        subject: Interface_Enums.ImageSubject.ThreeDPartition,
      },
      {
        name: this.translate(
          'print_dialog_partition_fillings {0}',
          'Fillings for {0}',
          partitionProductLine.Name,
        ),
        subject: Interface_Enums.ImageSubject.PartitionFillings,
      },
      {
        name: this.translate(
          'print_dialog_partition_fillings_with_measurements {0}',
          'Fillings for {0} with measurements',
          partitionProductLine.Name,
        ),
        subject: Interface_Enums.ImageSubject.PartitionFillingsWithMeasurements,
      },
      {
        name: this.translate('print_dialog_SwingFlex_Doors', 'SwingFlex Doors'),
        subject: Interface_Enums.ImageSubject.SwingFlexDoors,
      },
      {
        name: this.translate(
          'print_dialog_SwingFlex_Measurements',
          'SwingFlex Measurements',
        ),
        subject: Interface_Enums.ImageSubject.SwingFlexMeasurements,
      },
      {
        name: this.translate(
          'print_dialog_SwingFlex_DoorMeasurements',
          'SwingFlex Doors with measurements',
        ),
        subject: Interface_Enums.ImageSubject.SwingFlexDoorMeasurements,
      },
    ];
  }

  public visibleSubjects: PrintDialogVm.Subject[] = [];
  public checkedSubjects: { [subject: number]: boolean | undefined } = {};

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  public get okPossible(): boolean {
    return true;
  }

  public ok() {
    if (!this.okPossible) return;

    this.options.ImageSubjects = this.getImageSubjects();
    this.options.PrintType = parseInt(this.printTypeString);

    this.activeModal.close(this.options);
  }

  public getImageSubjects(): Interface_Enums.ImageSubject[] {
    return this.visibleSubjects
      .filter((s) => this.checkedSubjects[s.subject])
      .map((s) => s.subject);
  }

  public DialogTitle(): string {
    let title = this.translate('PrintDialog_modal_header', 'Print');

    return title;
  }

  public productFilter: string = '';
}

export module PrintDialogVm {
  export interface Subject {
    readonly name: string;
    readonly subject: Interface_Enums.ImageSubject;
    readonly allowSetting?: Interface_Enums.ChainSettingKey;
  }
}
