import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

export abstract class DetailEditVm<T> extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  protected _detailObject!: T;
}
