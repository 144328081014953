import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { FunctionalCoreService } from '../functional-core/functional-core.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard {
  constructor(
    private data: FunctionalCoreService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const user = this.data.ambient.activeUser.activeUser;

    const isAuthenticated = user !== undefined;

    if (isAuthenticated) {
      if (route.url[0].path === 'login') {
        this.router.navigate(['/floorplans']);
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      // if (route.url[0].path === 'login') {
      //     return true
      // }
      // else {
      //     return this.router.parseUrl('/login')
      // }
    }

    return false;
  }
}
