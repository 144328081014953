import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { ErrorInfo } from 'app/ts/clientDto/ErrorInfo';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Copyable } from 'app/ts/interfaces/Copyable';
import { FloorplanValidationService } from 'app/ts/services/Validation/FloorplanValidationService';
import { BehaviorSubject, Observable } from 'rxjs';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionValidationCalculationService } from 'app/partition/partition-validation-calculation.service';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';
import { FloorPlanProperties } from '../properties/FloorPlanProperties';

export class FloorPlan implements Copyable<FloorPlan> {
  constructor(
    private dto: Interface_DTO.FloorPlan,
    public editorAssets: Client.EditorAssets,
    private readonly pieceListFunc: (
      floorPlan$: FloorPlan,
    ) => Observable<Interface_DTO.PieceList | undefined>,
    private readonly _partitionServices: PartitionServices,
  ) {
    this._floorPlan$ = new BehaviorSubject<FloorPlan>(this);
    this.pieceList$ = pieceListFunc(this);
  }

  private _isDirty: boolean = false;
  private _isDisposed = false;
  public isAutosaveChecked: boolean = false;
  public cabinets: Client.Cabinet[] = [];
  public oldCabinetId?: number;
  public deliveryInfo?: Interface_DTO.DeliveryInfo;
  public hasSeenConsumerDoorModal = false;

  /** Determines if the "save floorplan as..." dialog box should be displayed when saving */
  public isAddressSet: boolean = true;
  private readonly _floorPlan$: BehaviorSubject<FloorPlan>;
  public get floorPlan$(): Observable<FloorPlan> {
    return this._floorPlan$;
  }

  public finishInitialization() {
    this._floorPlan$.next(this);
  }

  public readonly pieceList$: Observable<Interface_DTO.PieceList | undefined>;

  private _cache: Partial<{
    errorInfos: ErrorInfo[];
    corners: Interface_DTO_FloorPlan.Corner[];
    walls: Interface_DTO_FloorPlan.WallSection[];
    maxErrorLevel: Interface_Enums.ErrorLevel;
    correctionDeadline: Date | null;
    isEmpty: boolean;
    productLineExpires: {
      date: Date;
      productLine: Interface_DTO.ProductLine;
    } | null;
  }> = {};

  private _properties?: FloorPlanProperties;
  public get dtoObject() {
    return this.dto;
  }

  public get lastEdit(): Date {
    return DateHelper.fromIsoString(this.CreatedDate);
  }

  public save(): Interface_DTO.FloorPlan {
    this.dto.MaxErrorLevel = this.mutedErrorLevel;
    this.dto.PropertiesJson = this._properties
      ? JSON.stringify(this._properties)
      : null;
    return this.dto;
  }

  public restoreFrom(
    completeFloorPlan: Interface_DTO.FloorPlan,
    callBack: (floorplan: FloorPlan) => void,
  ) {
    this.dto = completeFloorPlan;
    this._properties = undefined;
    this.isDirty = true;
    let clientCabinets: Client.Cabinet[] = [];
    for (let dtoCab of completeFloorPlan.Cabinets) {
      let oldCab: Client.Cabinet | null = null;
      for (let cab of this.cabinets)
        if (cab.CabinetIndex === dtoCab.CabinetIndex) {
          oldCab = cab;
          break;
        }
      if (!oldCab) {
        oldCab = new Client.Cabinet(this, dtoCab);
      }
      clientCabinets.push(oldCab);
      oldCab.restoreFrom(dtoCab);
    }
    this.cabinets = clientCabinets;

    callBack(this);

    this._floorPlan$.next(this);
  }

  public get isDirty() {
    return this._isDirty;
  }
  public set isDirty(val: boolean) {
    this._isDirty = val;
    if (val) {
      this.clearCachedValues();
    }
  }

  public get isDisposed(): boolean {
    return this._isDisposed;
  }

  public triggerUpdate() {
    this._floorPlan$.next(this);
  }

  private clearCachedValues() {
    this._cache = {};
  }

  //#region PropertiesJson
  private get properties(): FloorPlanProperties {
    if (!this._properties) {
      const propertiesJson = this.dto.PropertiesJson ?? '{}';
      this._properties = {
        ...FloorPlanProperties.defaultValue,
        ...(JSON.parse(propertiesJson) as Partial<FloorPlanProperties>),
      };
    }
    return this._properties;
  }
  //#endregion PropertiesJson

  //#region DTO mappings
  get BottomLeftCorner() {
    return this.dto.BottomLeftCorner;
  }
  set BottomLeftCorner(val: Interface_DTO_FloorPlan.Corner) {
    if (val === this.dto.BottomLeftCorner) return;
    this.dto.BottomLeftCorner = val;
    this.isDirty = true;
  }

  get BottomRightCorner() {
    return this.dto.BottomRightCorner;
  }
  set BottomRightCorner(val: Interface_DTO_FloorPlan.Corner) {
    if (val === this.dto.BottomRightCorner) return;
    this.dto.BottomRightCorner = val;
    this.isDirty = true;
  }

  get BottomWall() {
    return this.dto.BottomWall;
  }
  set BottomWall(val: Interface_DTO_FloorPlan.WallSection) {
    if (val === this.dto.BottomWall) return;
    this.dto.BottomWall = val;
    this.isDirty = true;
  }

  get CreatedDate() {
    return this.dto.CreatedDate;
  }
  set CreatedDate(val: string) {
    if (val === this.dto.CreatedDate) return;
    this.dto.CreatedDate = val;
    this.isDirty = true;
  }

  get Id() {
    return this.dto.Id;
  }
  set Id(val: number | null) {
    if (val === this.dto.Id) return;
    this.dto.Id = val;
    this.isDirty = true;
  }

  get LeftWall() {
    return this.dto.LeftWall;
  }
  set LeftWall(val: Interface_DTO_FloorPlan.WallSection) {
    if (val === this.dto.LeftWall) return;
    this.dto.LeftWall = val;
    this.isDirty = true;
  }

  get Name() {
    return this.dto.Name;
  }
  set Name(val: string) {
    if (val === this.dto.Name) return;
    this.dto.Name = val;
    this.isDirty = true;
  }

  get ProjectDeliveryAddressId() {
    return this.dto.ProjectDeliveryAddressId;
  }
  set ProjectDeliveryAddressId(val: number | null) {
    if (val === this.dto.ProjectDeliveryAddressId) return;
    this.dto.ProjectDeliveryAddressId = val;
    this.isDirty = true;
  }

  get RightWall() {
    return this.dto.RightWall;
  }
  set RightWall(val: Interface_DTO_FloorPlan.WallSection) {
    if (val === this.dto.RightWall) return;
    this.dto.RightWall = val;
    this.isDirty = true;
  }

  get Size() {
    return this.dto.Size;
  }
  set Size(val: Interface_DTO_Draw.Vec3d) {
    if (val === this.dto.Size) return;
    this.dto.Size = val;
    this.isDirty = true;
  }

  get TopLeftCorner() {
    return this.dto.TopLeftCorner;
  }
  set TopLeftCorner(val: Interface_DTO_FloorPlan.Corner) {
    if (val === this.dto.TopLeftCorner) return;
    this.dto.TopLeftCorner = val;
    this.isDirty = true;
  }

  get TopRightCorner() {
    return this.dto.TopRightCorner;
  }
  set TopRightCorner(val: Interface_DTO_FloorPlan.Corner) {
    if (val === this.dto.TopRightCorner) return;
    this.dto.TopRightCorner = val;
    this.isDirty = true;
  }

  get TopWall() {
    return this.dto.TopWall;
  }
  set TopWall(val: Interface_DTO_FloorPlan.WallSection) {
    if (val === this.dto.TopWall) return;
    this.dto.TopWall = val;
    this.isDirty = true;
  }

  get DeliveryInfoId() {
    return this.dto.DeliveryInfoId;
  }
  set DeliveryInfoId(val: number) {
    if (val === this.dto.DeliveryInfoId) return;
    this.dto.DeliveryInfoId = val;
    this.isDirty = true;
  }

  get DeliveryStatus() {
    return this.dto.DeliveryStatus;
  }

  get UsesFullCatalog() {
    return this.dto.UsesFullCatalog;
  }
  set UsesFullCatalog(val: boolean) {
    if (val === this.dto.UsesFullCatalog) return;
    this.dto.UsesFullCatalog = val;
    this.isDirty = true;
  }

  get FullCatalogTallCabinets() {
    return this.dto.FullCatalogTallCabinets;
  }
  set FullCatalogTallCabinets(val: boolean) {
    if (val === this.dto.FullCatalogTallCabinets) return;
    this.dto.FullCatalogTallCabinets = val;
    this.isDirty = true;
  }

  get FullCatalogMuteErrors() {
    return this.dto.FullCatalogMuteErrors;
  }
  set FullCatalogMuteErrors(val: boolean) {
    if (val === this.dto.FullCatalogMuteErrors) return;
    this.dto.FullCatalogMuteErrors = val;
    this.isDirty = true;
  }

  get FullCatalogWideDoors() {
    return this.dto.FullCatalogWideDoors;
  }
  set FullCatalogWideDoors(val: boolean) {
    if (val === this.dto.FullCatalogWideDoors) return;
    this.dto.FullCatalogWideDoors = val;
    this.isDirty = true;
  }

  get FullCatalogAllowOtherProducts() {
    return this.dto.FullCatalogAllowOtherProducts;
  }
  set FullCatalogAllowOtherProducts(val: boolean) {
    if (val === this.dto.FullCatalogAllowOtherProducts) return;
    this.dto.FullCatalogAllowOtherProducts = val;
    this.isDirty = true;
  }

  get FullCatalogAllowOtherMaterials() {
    return this.dto.FullCatalogAllowOtherMaterials;
  }
  set FullCatalogAllowOtherMaterials(val: boolean) {
    if (val === this.dto.FullCatalogAllowOtherMaterials) return;
    this.dto.FullCatalogAllowOtherMaterials = val;
    this.isDirty = true;
  }

  get UserId() {
    return this.dto.UserId;
  }
  set UserId(val: number) {
    if (val === this.dto.UserId) return;
    this.dto.UserId = val;
    this.isDirty = true;
  }
  get SupporterId() {
    return this.dto.SupporterId;
  }
  set SupporterId(val: number | null) {
    if (val === this.dto.SupporterId) return;
    this.dto.SupporterId = val;
    this.isDirty = true;
  }

  get InternalNote() {
    return this.dto.InternalNote;
  }
  set InternalNote(val: string) {
    if (val === this.dto.InternalNote) return;
    this.dto.InternalNote = val;
    this.isDirty = true;
  }

  get RuleSet() {
    return this.dto.RuleSet;
  }
  set RuleSet(val: number) {
    if (val === this.dto.RuleSet) return;
    this.dto.RuleSet = val;
    this.isDirty = true;
  }

  //#endregion DTO mappings

  /** Allow height reduction on products and productlines where the variant has been disabled */
  public get FullCatalogAllowHeightReduction() {
    return this.FullCatalogAllowOtherProducts;
  }

  public get actualCabinets(): Client.Cabinet[] {
    return this.cabinets.filter(
      (c) => c.CabinetType !== Interface_Enums.CabinetType.SharedItems,
    );
  }

  get corners(): Interface_DTO_FloorPlan.Corner[] {
    if (this._cache.corners) return this._cache.corners;

    return (this._cache.corners = [
      this.TopLeftCorner,
      this.TopRightCorner,
      this.BottomLeftCorner,
      this.BottomRightCorner,
    ]);
  }

  get walls(): Interface_DTO_FloorPlan.WallSection[] {
    if (this._cache.walls) return this._cache.walls;
    let result: Interface_DTO_FloorPlan.WallSection[] = [];

    result.push(
      this.TopWall,
      ...this.getCornerWalls(this.TopRightCorner),
      this.RightWall,
      ...this.getCornerWalls(this.BottomRightCorner),
      this.BottomWall,
      ...this.getCornerWalls(this.BottomLeftCorner),
      this.LeftWall,
      ...this.getCornerWalls(this.TopLeftCorner),
    );
    this._cache.walls = result;
    return result;
  }

  private getCornerWalls(
    corner: Interface_DTO_FloorPlan.Corner,
  ): Interface_DTO_FloorPlan.WallSection[] {
    let result: Interface_DTO_FloorPlan.WallSection[] = [];
    if (corner.Wall1) result.push(corner.Wall1);
    if (corner.Wall2) result.push(corner.Wall2);
    return result;
  }

  private _floorPlanItems: Interface_DTO_FloorPlan.Item[] | undefined;
  get floorPlanItems(): Interface_DTO_FloorPlan.Item[] {
    if (this._floorPlanItems) return this._floorPlanItems;
    let result: Interface_DTO_FloorPlan.Item[] = [];
    for (let wall of this.walls) {
      result.push(...wall.Items);
    }
    this._floorPlanItems = result;
    return result;
  }

  public getPoints(): Interface_DTO_Draw.Vec2d[] {
    return this.walls.map((wall) => wall.Begin);
  }

  public get errorInfos(): ErrorInfo[] {
    if (!this._cache.errorInfos) {
      this._cache.errorInfos = FloorplanValidationService.validate(
        this,
        false,
        true,
      ); // TODO: Verify last true parameter
    }
    return this._cache.errorInfos;
  }

  public get correctionDeadline(): Date | null {
    if (this._cache.correctionDeadline === undefined) {
      if (
        this.DeliveryStatus === Interface_Enums.DeliveryStatus.Quote ||
        !this.dtoObject.CorrectionDeadline
      ) {
        this._cache.correctionDeadline = null;
      } else {
        this._cache.correctionDeadline = DateHelper.fromIsoString(
          this.dtoObject.CorrectionDeadline,
        );
      }
    }
    return this._cache.correctionDeadline;
  }

  public get isEmpty(): boolean {
    if (this._cache.isEmpty === undefined) {
      this._cache.isEmpty = this.cabinets.every((cab) => cab.isEmpty);
    }
    return this._cache.isEmpty;
  }

  /**
   * Returns the maximum errorlevel for the entire floorplan - including any descendents (cabinets, sections and items)
   * */
  public get maxErrorLevel(): Interface_Enums.ErrorLevel {
    if (this._cache.maxErrorLevel === undefined) {
      this._cache.maxErrorLevel = Math.max(
        Interface_Enums.ErrorLevel.None,
        ...this.errorInfos.map((info) => info.level),
        ...this.cabinets.map((cabinet) => cabinet.maxErrorLevel),
      );
    }
    return this._cache.maxErrorLevel;
  }

  public get mutedErrorLevel(): Interface_Enums.ErrorLevel {
    return this.editorAssets.fullCatalog && this.FullCatalogMuteErrors
      ? Math.min(this.maxErrorLevel, Interface_Enums.ErrorLevel.Warning)
      : this.maxErrorLevel;
  }

  public get partitionServices(): PartitionServices {
    return this._partitionServices;
  }

  public copy() {
    let dtoCopy = ObjectHelper.copy(this.dto);
    return new FloorPlan(
      dtoCopy,
      this.editorAssets,
      this.pieceListFunc,
      this.partitionServices,
    );
  }

  public dispose() {
    this._isDisposed = true;
  }
}

interface PartitionServices {
  data: PartitionPlanDataService;
  query: PartitionPlanQueryService;
  validationCalculation: PartitionValidationCalculationService;
}
