<h4 *ngIf="actualDisplayHeader">{{name}}</h4>
<div class="pickables" [ngClass]="displayMode">
  <div
    *ngFor="let pickable of visibleItems"
    [ngClass]="{'selected': isSelected(pickable)}"
    (click)="selectedItem = pickable.item"
    [style]="getStyle(pickable)"
    title="pickable.name"
  >
    <div *ngIf="displayId" class="id">{{pickable.id}}</div>
  </div>
  <div *ngIf="isPickerVisible" (click)="showPicker()">
    <div class="ellipse id">...</div>
  </div>
</div>
