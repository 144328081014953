<h3>
  {{
    "fillings_header_selected_bar_{0}_{1}"
      | translate: "Module {0} - Bar {1}" : moduleSectionIndex : selectedBar.id
  }}
</h3>

<div class="form-group form-group-sm">
  <label
    for="rm-fillings-bar-above"
    [textContent]="'doors_label_bar_above_height' | translate: 'Above Height'"
  ></label>
  <input
    #heightAbove
    id="rm-fillings-bar-above"
    class="form-control"
    type="number"
    [value]="visibleFillingHeightAbove"
    (change)="visibleFillingHeightAbove = heightAbove.valueAsNumber"
  />
</div>

<div class="form-group form-group-sm">
  <label
    for="rm-filling-bar-above-percent"
    [textContent]="
      'doors_label_bar_above_height_percent' | translate: 'Above Height Percent'
    "
  ></label>
  <input
    #heightAbovePercent
    id="rm-filling-bar-above-percent"
    class="form-control"
    type="number"
    step=".1"
    [value]="visibleFillingHeightAbovePercent"
    (change)="
      visibleFillingHeightAbovePercent = heightAbovePercent.valueAsNumber
    "
  />
</div>

<hr />

<div class="form-group form-group-sm">
  <label
    for="rm-filling-bar-position"
    [textContent]="'doors_label_bar_position' | translate: 'Position'"
  ></label>
  <input
    #position
    id="rm-filling-bar-position"
    class="form-control"
    type="number"
    [value]="positionY"
    (change)="positionY = position.valueAsNumber"
  />
</div>

<hr />

<div class="form-group form-group-sm">
  <label
    for="rm-filling-bar-below"
    [textContent]="'doors_label_bar_below_height' | translate: 'Below Height'"
  ></label>
  <input
    #heightBelow
    id="rm-filling-bar-below"
    class="form-control"
    type="number"
    [value]="visibleFillingHeightBelow"
    (change)="visibleFillingHeightBelow = heightBelow.valueAsNumber"
  />
</div>

<div class="form-group form-group-sm">
  <label
    for="rm-filling-bar-below-percent"
    [textContent]="
      'doors_label_bar_below_height_percent' | translate: 'Below Height Percent'
    "
  ></label>
  <input
    #heightBelowPercent
    id="rm-filling-bar-below-percent"
    class="form-control"
    type="number"
    step=".1"
    [value]="visibleFillingHeightBelowPercent"
    (change)="
      visibleFillingHeightBelowPercent = heightBelowPercent.valueAsNumber
    "
  />
</div>
