import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private _onbeforeunload:
    | (((this: WindowEventHandlers, ev: BeforeUnloadEvent) => any) &
        ((this: Window, ev: BeforeUnloadEvent) => any))
    | null = null;
  public get onbeforeunload():
    | (((this: WindowEventHandlers, ev: BeforeUnloadEvent) => any) &
        ((this: Window, ev: BeforeUnloadEvent) => any))
    | null {
    return this._onbeforeunload;
  }
  public set onbeforeunload(
    v:
      | (((this: WindowEventHandlers, ev: BeforeUnloadEvent) => any) &
          ((this: Window, ev: BeforeUnloadEvent) => any))
      | null,
  ) {
    this._onbeforeunload = v;
  }

  public get location(): Location {
    return window.location;
  }
}
