import { Material } from 'app/ts/clientDto';
import { MaterialType } from 'app/ts/Interface_Enums';
import { FillingId } from '../filling';
import { ModuleId } from '../module';

export class D3ViewFilling {
  constructor(
    private _fillingId: FillingId,
    private _moduleId: ModuleId,
    private _materialId: number,
    private _posX: number,
    private _posY: number,
    private _width: number,
    private _height: number,
  ) {}

  public get id(): FillingId {
    return this._fillingId;
  }

  public get moduleId(): ModuleId {
    return this._moduleId;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get materialId(): number {
    return this._materialId;
  }
}
