// ****
// NOTE: Not Used YET!!!
// ****
export class ChainSettingsContainer {
  private _chainSettings: { [key: number]: string } = {};
  public get chainSettings(): { [key: number]: string } {
    return this._chainSettings;
  }

  public setChainSettings(settings: { [key: number]: string }) {
    this._chainSettings = settings;
  }
}
