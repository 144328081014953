<div class="floorplan-details tool-menu editor-menu right-tool-menu">
  <div class="form-group form-group-sm">
    <label>
      <span
        [textContent]="translate('floorPlan_plan_name_label', 'Name')"
      ></span>
      <input
        type="text"
        class="form-control"
        maxlength="maxFloorPlanNameLength"
        [(ngModel)]="floorPlanName"
        [ngModelOptions]="modelOptions"
      />
    </label>
  </div>
  <div class="form-group form-group-sm">
    <label>
      <span [textContent]="translate('floorPlan_width_label', 'Width')"></span>
      <span class="input-group">
        <input
          #sizeX
          type="number"
          class="form-control"
          [value]="floorPlanSizeX"
          (change)="floorPlanSizeX = sizeX.valueAsNumber"
          min="0"
        />
        <span class="input-group-text">mm</span>
      </span>
    </label>
  </div>
  <div class="form-group form-group-sm">
    <label>
      <span
        [textContent]="translate('floorPlan_height_label', 'Height')"
      ></span>

      <span class="input-group">
        <input
          #sizeY
          type="number"
          class="form-control"
          [value]="floorPlanSizeY"
          (change)="floorPlanSizeY = sizeY.valueAsNumber"
          min="0"
        />
        <span class="input-group-text">mm</span>
      </span>
    </label>
  </div>
  <div class="form-group form-group-sm">
    <label>
      <span
        [textContent]="
          translate('floorPlan_ceiling_height_label', 'Ceiling Height')
        "
      ></span>

      <span class="input-group">
        <input
          #sizeZ
          type="number"
          class="form-control"
          [value]="floorPlanSizeZ"
          (change)="updateFloorPlanHeight($event)"
          min="0"
        />
        <span class="input-group-text">mm</span>
      </span>
    </label>
  </div>

  <div class="floorplan-corners">
    <div
      class="floorplan-corner"
      *ngFor="let corner of corners; index as cornerPosition"
    >
      <div class="corner-header">
        <img class="corner-image" [src]="getCornerImagePath(cornerPosition)" />
        <h3
          [textContent]="
            translate('floorPlan_corner_position_' + cornerPosition)
          "
          class="floorplan-corner-name"
        ></h3>
      </div>
      <select class="form-select" [(ngModel)]="corner.type">
        <option
          *ngFor="let n of cornerTypes; trackBy: trackByCornerType"
          [ngValue]="n"
        >
          {{ translate("floorplan_corner_type_" + n) }}
        </option>
      </select>

      <div class="form-group form-group-sm" *ngIf="corner.type === 1">
        <label>
          <span
            [textContent]="translate('floorPlan_corner_type_1_label', 'Size')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [(ngModel)]="corner.sizeX"
              [ngModelOptions]="modelOptionsBlur"
              min="0"
            />
          </span>
        </label>
      </div>

      <div class="form-group form-group-sm" *ngIf="corner.type === 2">
        <label>
          <span
            [textContent]="
              translate('floorPlan_corner_type_2_label_1', 'Width')
            "
          ></span>

          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [(ngModel)]="corner.sizeX"
              [ngModelOptions]="modelOptionsBlur"
              min="0"
            />
          </span>
        </label>
      </div>

      <div class="form-group form-group-sm" *ngIf="corner.type === 2">
        <label>
          <span
            [textContent]="
              translate('floorPlan_corner_type_2_label_2', 'Height')
            "
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [(ngModel)]="corner.sizeY"
              [ngModelOptions]="modelOptionsBlur"
              min="0"
            />
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
