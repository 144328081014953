import * as DTO from 'app/ts/interface_dto/index';
import * as Client from 'app/ts/clientDto/index';
import { DeliveryAddress } from 'app/ts/clientDto/DeliveryAddress';
import { Collapsible } from 'app/ts/util/Collapsible';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ISelectable } from 'app/ts/util/ISelectable';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Copyable } from 'app/ts/interfaces/Copyable';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
export class Project
  implements
    DTO.Project,
    Copyable<Project>,
    ISelectable,
    DtoContainer<DTO.Project>,
    Collapsible
{
  private _deliveryAddresses: DeliveryAddress[] = [];
  private _childModifiedDate: string = '';
  public selected: boolean = false;
  public collapsed = true;

  constructor(
    public readonly dtoObject: DTO.Project,
    public readonly customer: Client.Customer,
  ) {
    if (!dtoObject) throw new Error('ArgumentNull: dtoObject');
    if (!customer) throw new Error('ArgumentNull: customer');
    this.DeliveryAddresses = dtoObject.DeliveryAddresses.map(
      (dtoDa) => new Client.DeliveryAddress(dtoDa, this),
    );
  }

  public get CustomerId() {
    return this.dtoObject.CustomerId;
  }
  public get CreatedDate() {
    return this.dtoObject.CreatedDate;
  }
  public get Description() {
    return this.dtoObject.Description;
  }
  public get Id() {
    return this.dtoObject.Id;
  }
  public get Name() {
    return this.dtoObject.Name;
  }
  public get UserId() {
    return this.dtoObject.UserId;
  }

  public get DeliveryAddresses() {
    return this._deliveryAddresses;
  }
  public set DeliveryAddresses(val: Client.DeliveryAddress[]) {
    this._deliveryAddresses = val;
    this.updateDate();
  }
  get ChildModifiedDate() {
    return this._childModifiedDate;
  }

  public get shortName() {
    return this.Name;
  }

  private updateDate() {
    this._childModifiedDate = DateHelper.maxDate(
      this.dtoObject.CreatedDate,
      ...this.DeliveryAddresses.map((da) => da.ChildModifiedDate),
    );
  }

  public copy() {
    return new Project(ObjectHelper.copy(this.dtoObject), this.customer);
  }
}
