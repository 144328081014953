import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { LoginService } from 'app/ts/services/LoginService';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
/**
 * Helper service for filtering lists
 */
export class FilterService {
  public static readonly Name = 'filterService';

  constructor(private readonly loginService: LoginService) {}

  public static filterCorpusPanels(
    assets: Client.EditorAssets,
    productLineId: Interface_Enums.ProductLineId,
    position: Interface_Enums.CorpusPanelPosition,
    cabinetType: Interface_Enums.CabinetType = Interface_Enums.CabinetType.None,
    selectedPanelId: number,
    fullCatalog: boolean,
  ): Client.CorpusPanel[] {
    let corpusPanels: Client.CorpusPanel[] = new Array<Client.CorpusPanel>();

    // Filter by ProductLine
    corpusPanels = assets.corpusPanels.filter((cp) => {
      return cp.products.every(
        (p) => p.ProductLineIds.indexOf(productLineId) >= 0,
      );
    });

    // Filter by position
    corpusPanels = corpusPanels.filter((cp) => cp.Position === position);

    // Remove panels with unavailable products
    corpusPanels = corpusPanels.filter((cp) => {
      return cp.CorpusPanelProducts.every((cpp) =>
        cp.products.some((p) => p.Id === cpp.ProductId),
      );
    });
    corpusPanels = corpusPanels.filter(
      (cp) => cp.Id === selectedPanelId || cp.products.every((p) => p.Enabled),
    );

    // Filter by CabinetType
    if (
      assets.productConfigurationTypeExclusions.some(
        (exclusion) => exclusion.CabinetType === cabinetType,
      )
    ) {
      let exclusions = assets.productConfigurationTypeExclusions.filter(
        (exclusion) => exclusion.CabinetType === cabinetType,
      );
      corpusPanels = corpusPanels.filter((cp) => {
        if (
          exclusions.some((excl) =>
            cp.products.some((p) => p.ProductNo === excl.ProductNo),
          )
        ) {
          return false;
        }
        return true;
      });
    }

    //filter by licensed products
    if (!fullCatalog) {
      corpusPanels = corpusPanels.filter(
        (cp) =>
          selectedPanelId === cp.Id ||
          !cp.products.some((product) => product.overrideChain),
      );
    }

    //filter by discontinued product
    corpusPanels = corpusPanels.filter(
      (cp) =>
        selectedPanelId === cp.Id ||
        !cp.products.some((product) => ProductHelper.isDiscontinued(product)),
    );

    corpusPanels.sort((a, b) => a.SortOrder - b.SortOrder);

    return corpusPanels;
  }

  public static getFilteredProducts(
    unfilteredProducts: Client.Product[],
    assets: Client.EditorAssets,
    productLineId: number,
    allowDiscontinued: boolean = false,
    cabinetType: Interface_Enums.CabinetType = Interface_Enums.CabinetType.None,
  ): Client.Product[] {
    // Filter by exclusions
    let exclusions = assets.productConfigurationTypeExclusions.filter(
      (exclusion) => exclusion.CabinetType === cabinetType,
    );
    let products = unfilteredProducts.filter((p) => {
      if (exclusions.some((excl) => p.ProductNo === excl.ProductNo)) {
        return false;
      }
      return true;
    });

    // Filter by discontinued
    if (!allowDiscontinued) {
      products = products.filter(
        (p) =>
          !ProductHelper.isDiscontinued(p) && p.Enabled && !p.overrideChain,
      );
    }

    // Filter by productLine
    products = products.filter(
      (p) => p.ProductLineIds.indexOf(productLineId) >= 0,
    );

    return products;
  }
}
