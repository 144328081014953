import {
  BehaviorSubject,
  filter,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { Store } from 'app/ts/Interface_DTO';
import { StoreSetting, StoreSettingData } from './StoreSetting';

export class StoresContainer {
  //// Store Setting Data
  private _storeSetting: StoreSettingData | undefined;

  public get storeSetting(): StoreSettingData | undefined {
    return this._storeSetting;
  }

  public setStoreSetting(value: StoreSettingData) {
    this._storeSetting = value;
    if (this._storeSetting != null)
      this._storeSetting$.next(this._storeSetting);
  }

  // observable pattern
  private _storeSetting$ = new Subject<StoreSettingData>();

  public get storeSettings$(): Observable<StoreSettingData> {
    return this._storeSetting$;
  }

  public subscribeStoreSetting(
    next: (Value: StoreSettingData) => void,
  ): Subscription {
    const subscription = this._storeSetting$.subscribe(next);
    if (this.storeSetting != null) this._storeSetting$.next(this.storeSetting);
    return subscription;
  }

  //// Store
  private _activeStore: Store | undefined;

  public get activeStore(): Store | undefined {
    return this._activeStore;
  }

  public setActiveStore(store: Store) {
    this._activeStore = store;
    this._activeStore$.next(this._activeStore);
  }

  // observable pattern
  private _activeStore$ = new BehaviorSubject<Store | undefined>(undefined);

  public get activeStore$(): Observable<Store> {
    return this._activeStore$.pipe(
      filter((s) => s != null),
    ) as Observable<Store>;
  }

  public subscribeActiveStore(next: (value: Store) => void): Subscription {
    const subscription = this.activeStore$.subscribe(next);
    return subscription;
  }

  //// Stores
  private _stores: Store[] = [];

  public get stores(): Store[] {
    return this._stores;
  }

  public setStores(value: Store[]) {
    this._stores = value;
    this._stores$.next(this._stores);
  }

  // observable pattern
  private _stores$ = new Subject<Store[]>();

  public get stores$(): Observable<Store[]> {
    return this._stores$;
  }

  public subscribeStores(next: (value: Store[]) => void): Subscription {
    const subscription = this._stores$.subscribe(next);
    if (this.stores != null) this._stores$.next(this.stores);
    return subscription;
  }
}
