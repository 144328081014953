import { AfterViewInit, Component } from '@angular/core';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ModalService } from 'app/ts/services/ModalService';
import { UserMessageService } from './user-message.service';
import { LoginService } from '@Services/LoginService';

@Component({
  selector: 'notifications',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements AfterViewInit {
  constructor(
    private loginService: LoginService,
    private modalService: ModalService,
    public notifications: NotificationService,
    private userMessageService: UserMessageService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.userMessageService.userMessages$.subscribe((messages) => {
      if (messages != null && messages.length > 0) {
        this.modalService.displayUserMessages(messages);
      }
    });

    if (this.loginService.isAuthenticated) {
      this.userMessageService.loadUserMessages();
    }
  }
}
