import { Injectable } from '@angular/core';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { environment } from 'environments/environment';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VersionService {
  constructor(private readonly $http: PromisingBackendService) {
    setInterval(() => this.checkVersion(), 1000 * 60);
  }

  /**
   * The current client version when originally loaded from the server.
   */
  public get clientVersion(): string {
    return environment.clientVersion;
  }

  private _serverVersion = new BehaviorSubject<string | undefined>(undefined);
  // Format of serverVersion - e.g.: 1.2.3.0
  public get serverVersion$(): Observable<string | undefined> {
    return this._serverVersion.pipe(distinctUntilChanged());
  }

  private _displayHasNewAppVersion = true;
  public get displayHasNewAppVersion(): boolean {
    return this._displayHasNewAppVersion && this.hasNewAppVersion;
  }

  private _hasNewAppVersion = false;
  public get hasNewAppVersion(): boolean {
    return this._hasNewAppVersion;
  }

  public checkVersion() {
    this.$http
      .get<string>('api/version', { responseType: 'text' })
      .then(async (serverVersion) => {
        this._serverVersion.next(serverVersion);

        if (this.clientVersion != null && this.clientVersion != serverVersion) {
          this._hasNewAppVersion = true;
        }
      });
  }
}
