import { inject, InjectionToken, Provider } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { Store } from 'app/ts/Interface_DTO';
import { Subscription } from 'rxjs';

export interface Stores {
  stores: Store[];
  subscribe: (next: (value: Store[]) => void) => Subscription;
}

export function storesFactory(data: FunctionalCoreService): Stores {
  return {
    stores: data.ambient.storeData.stores,
    subscribe: (next) => {
      return data.ambient.storeData.subscribeStores(next);
    },
  };
}

export const StoresInjector = new InjectionToken<Stores>('stores', {
  factory() {
    const data = inject(FunctionalCoreService);

    return storesFactory(data);
  },
});

export const storesProvider: Provider = {
  provide: StoresInjector,
  useFactory: storesFactory,
  deps: [FunctionalCoreService],
};
