import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { ModelOptions } from 'app/ng/ModelOptions';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { CustomerSearchParams } from 'app/ts/params/CustomerSearchParams';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { CustomerService } from 'app/ts/services/CustomerService';
import { ModalService } from 'app/ts/services/ModalService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import Enumerable from 'linq';
import { BehaviorSubject } from 'rxjs';
import { BasicModalVm } from './BasicModalVm';

@Component({
  selector: 'project-selector-vm',
  templateUrl: './projectSelector.html',
  styleUrls: ['../../../../style/tables.scss', '../../../../style/ribbon.scss'],
  providers: [activeUserProvider, clientSettingProvider],
})
export class ProjectSelectorVm
  extends BasicModalVm<Client.Project[]>
  implements OnInit
{
  private allCustomers: Client.Customer[] = [];
  public selectedCustomer: Client.Customer | null = null;
  public selectedProjectIds: { [key: number]: true } = {};
  public visibleCustomers: Client.Customer[] = [];
  private readonly searchObservable = new BehaviorSubject<CustomerSearchParams>(
    { owner: Constants.overview.anyOwnerSearchString },
  );
  public quickSearchModelOptions: ModelOptions = {
    updateOn: 'change',
  };
  private isCreatingNewCustomer = false;
  private isCreatingNewProject = false;
  public displayNavigationLeft!: boolean;
  public displayNavigationRight!: boolean;
  public startCustomerId!: number | null;
  private allowMultiple!: boolean;
  public _showCheckBox: boolean = true;
  get showCheckBox() {
    return this._showCheckBox;
  }

  @Input()
  set showCheckBox(showCheckBox: boolean) {
    this._showCheckBox = showCheckBox;
  }
  constructor(
    baseVmService: BaseVmService,
    activeModal: NgbActiveModal,
    private readonly customerService: CustomerService,
    private readonly modalService: ModalService,
    @Inject(ActiveUserInjector) private readonly activeUser: ActiveUser,
    @Inject(ClientSettingInjector)
    private readonly clientSettings: ClientSetting,
  ) {
    super(baseVmService, activeModal);
  }

  public ngOnInit() {
    const customerObservable =
      this.customerService.getObservableBySearchObservable(
        this.searchObservable,
        this.activeUser,
        this.clientSettings,
      );
    this.subscribeTo<Client.Customer[]>(customerObservable, (customers) => {
      let newCustomers = ObjectHelper.copy(customers);
      if (this.selectedCustomer) {
        this.selectedCustomer =
          Enumerable.from(newCustomers).firstOrDefault(
            (cust) => cust.Id === this.selectedCustomer!.Id,
          ) ?? null;
      } else if (this.startCustomerId) {
        this.selectedCustomer =
          Enumerable.from(newCustomers).firstOrDefault(
            (cust) => cust.Id === this.startCustomerId,
          ) ?? null;
        this.startCustomerId = null;
      }
      this.visibleCustomers = newCustomers;
      this.setSelection();
    });
    this.subscribeTo(
      this.customerService.getAll(),
      (allCustomers) => (this.allCustomers = allCustomers),
    );
  }

  public get currentHeader(): string {
    return this.selectedCustomer
      ? this.translate('project_selector_select_project', 'Select project')
      : this.translate('project_selector_select_customer', 'Select customer');
  }

  // TODO: Probably not used
  public get quickSearchString() {
    return this.searchObservable.value.quickSearch || '';
  }
  public set quickSearchString(val: string) {
    let search = this.searchObservable.value;
    if (!val) {
      delete search.quickSearch;
    } else {
      search.quickSearch = val;
    }
    this.searchObservable.next(search);
    //this.searchObservable.value = search;
  }

  public get isOkEnabled(): boolean {
    for (let key in this.selectedProjectIds) {
      if (this.selectedProjectIds[key]) {
        return true;
      }
    }
    return false;
  }

  public get isBackEnabled(): boolean {
    return !!this.selectedCustomer;
  }

  private setSelection() {
    for (let cust of this.visibleCustomers) {
      for (let proj of cust.Projects) {
        proj.selected = this.selectedProjectIds[proj.Id] || false;
      }
    }
  }

  public selectCustomer(customer: Client.Customer) {
    this.selectedCustomer = customer;
  }

  public selectProject(projectId: number) {
    if (!this.allowMultiple) {
      this.selectedProjectIds = {};
    }
    if (this.selectedProjectIds[projectId]) {
      delete this.selectedProjectIds[projectId];
    } else {
      this.selectedProjectIds[projectId] = true;
    }
    this.setSelection();
  }

  public back() {
    this.selectedCustomer = null;
  }

  public async newCustomer() {
    try {
      this.isCreatingNewCustomer = true;
      let creations =
        await this.modalService.getNewCustomerModalChained('project');
      if (creations.type == 'none') {
        return;
      } else {
        this.setCustomer(creations.customerId);
        if (creations.type !== 'customer') {
          this.selectProject(creations.projectId);
        }
      }
    } finally {
      this.isCreatingNewCustomer = false;
    }
  }

  public async newProject() {
    if (!this.selectedCustomer) {
      return;
    }
    try {
      this.isCreatingNewProject = true;
      let creations = await this.modalService.getNewProjectModalChained(
        this.selectedCustomer,
        'project',
      );
      if (creations.type !== 'none') {
        this.selectProject(creations.projectId);
      }
    } finally {
      this.isCreatingNewProject = false;
    }
  }

  private setCustomer(customerId: number) {
    this.selectedCustomer =
      Enumerable.from(this.visibleCustomers).firstOrDefault(
        (cust) => cust.Id == customerId,
      ) || null;
  }

  public ok() {
    if (!this.isOkEnabled) return;
    let selectedProjects = Enumerable.from(this.allCustomers)
      .selectMany((cust) => cust.Projects)
      .where((proj) => this.selectedProjectIds[proj.Id])
      .toArray();
    if (selectedProjects.length < 1) return;
    this.close(selectedProjects);
  }
}
