<div class="modal-header">
  <h4
    class="modal-title"
    [textContent]="'property_selector_header' | translate: 'Columns'"
  ></h4>
  <button type="button" class="close" (click)="cancel()">&times;</button>
</div>
<div class="modal-body">
  <div class="container-fluid property-selector">
    <div class="row">
      <div class="col-md-6">
        <h4
          [textContent]="'property_selector_header_available' | translate: 'Available'"
        ></h4>
        <ul class="property-list possible-property-list">
          <li
            *ngFor="let key of selectableKeys; index as $index"
            [textContent]="propertyNames[key]"
            (click)="addIndex($index)"
          ></li>
        </ul>
      </div>
      <div class="col-md-6">
        <h4
          [textContent]="'property_selector_header_selected' | translate: 'Selected'"
        ></h4>
        <ul class="property-list selected-property-list">
          <li
            *ngFor="let key of selectedKeys; index as $index"
            [textContent]="propertyNames[key]"
            (click)="removeIndex($index)"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn-group">
    <button
      class="btn btn-secondary"
      (click)="cancel()"
      [textContent]="'property_selector_button_cancel' | translate: 'Cancel'"
    ></button>
    <button
      class="btn btn-primary"
      (click)="save()"
      [textContent]="'property_selector_button_save' | translate: 'Save'"
    ></button>
  </div>
</div>
