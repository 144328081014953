import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  selector: 'link-browser-modal-vm',
  templateUrl: './linkBrowserModal.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class LinkBrowserModalVm extends BasicModalVm<void> {
  constructor(baseVmService: BaseVmService, activeModal: NgbActiveModal) {
    super(baseVmService, activeModal);
  }
}
