import { Subscription } from 'app/ts/util/Subscription';
import { Defer } from 'app/ts/util/Defer';
export class Observable<T> implements IObservable<T> {
  private readonly _subscribers: Subscription<T>[] = [];
  private _editCount = 0;
  private _readyDefer: Defer<T> | null = null;

  constructor(private _value: T) {}

  public get value(): T {
    //For compatility with subclasses - inheritance of getters/setter is wonky in TS
    return this.getValue();
  }
  public set value(value: T) {
    //For compatility with subclasses - inheritance of getters/setter is wonky in TS
    this.setValue(value);
  }

  public triggerUpdate() {
    this._editCount++;
    for (let sub of this._subscribers) sub.action(this._value);
    if (this._readyDefer !== null) {
      this._readyDefer.resolve(this._value);
      this._readyDefer = null;
    }
  }

  protected getValue(): T {
    return this._value;
  }
  protected setValue(val: T) {
    this._value = val;
    this.triggerUpdate();
  }

  public ready(): Promise<T> {
    return this._readyDefer
      ? this._readyDefer.promise
      : Promise.resolve(this.value);
  }

  public observe(lambda: (obj: T) => void): ISubscription {
    let sub = new Subscription<T>(lambda, this);
    this._subscribers.push(sub);
    sub.action(this._value);
    return sub;
  }

  public unsubscribe(sub: Subscription<T>): void {
    let index = this._subscribers.indexOf(sub);
    if (index >= 0) {
      this._subscribers.splice(index, 1);
    }
  }
}

export interface IObservable<T> {
  readonly value: T;
  ready(): Promise<T>;
  observe(lambda: (obj: T) => void): ISubscription;
  triggerUpdate(): void;
}

export interface ISubscription {
  dispose(): void;
}
