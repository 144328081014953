<div *ngIf="category">
  <h4>{{category.name}}</h4>
  <div *ngFor="let product of category.products">
    <interior-product
      [product]="product"
      [currentDragInfoObservable]="currentDragInfoObservable"
    >
    </interior-product>
  </div>
</div>
