import { Pickable } from 'app/ts/interfaces/Pickable';
export class ConverterPickable<T> implements Pickable<T> {
  constructor(
    private readonly getter: () => T,
    private readonly converter: (o: T) => Pickable<T>,
  ) {}

  get description() {
    return this.converter(this.getter()).description;
  }
  get disabledReason() {
    return this.converter(this.getter()).disabledReason;
  }
  get groupName() {
    return this.converter(this.getter()).groupName;
  }
  get id() {
    return this.converter(this.getter()).id;
  }
  get imageUrl() {
    return this.converter(this.getter()).imageUrl;
  }
  get item() {
    return this.converter(this.getter()).item;
  }
  get isSelected() {
    return this.converter(this.getter()).isSelected;
  }
  get name() {
    return this.converter(this.getter()).name;
  }
  get override() {
    return this.converter(this.getter()).override;
  }
}
