import { Side } from 'app/ts/clientDto/Enums';
import { Module } from './module';
import { RailPlacement } from './rail';
import { SectionId } from './section';

export class Profile {
  constructor(data: ProfileData) {
    this._data = data;
  }

  protected _data: ProfileData;
  public get data(): ProfileData {
    return this._data;
  }

  public get id(): ProfileId {
    return this.data.id;
  }

  public get type(): ProfileType {
    return this.data.type;
  }

  public get sections(): SectionConnection[] {
    return this.data.sections;
  }

  public set sections(value: SectionConnection[]) {
    this.data.sections = value;
  }

  public get posX(): number {
    return this.data.PosX;
  }

  public set posX(pos: number) {
    this.data.PosX = pos;
  }

  public get posY(): number {
    return this.data.PosY;
  }

  public set posY(pos: number) {
    this.data.PosY = pos;
  }

  public get width(): number {
    return this.data.width;
  }

  public get height(): number {
    return this.data.height;
  }

  public set height(height: number) {
    this.data.height = height;
  }

  public get depth(): number {
    return this.data.depth;
  }

  public get rotation(): number {
    return this.data.rotation;
  }

  public set rotation(rotation: number) {
    this.data.rotation = rotation;
  }

  public get productId(): number {
    return this.data.productId;
  }

  public get positionNo(): number {
    return this.data.positionNo;
  }

  public set positionNo(val: number) {
    this.data.positionNo = val;
  }

  //#region Static Vars

  /**
   * returns how much reduction is needed of the section width for a given profile.
   *
   * NOTE: Only start/stop includes the spacing between it and the module within the section width
   * @param profile
   * @returns
   */
  public static getReductionForProfile(profile: Profile): number {
    // Numbers calculated from production drawings of profiles and given how much the section width covers in the different cases

    if (profile instanceof StartStopProfile)
      // The full profile is included in the section width
      return StartStopProfile.size + Module.elementSpacing;

    return 0;
  }

  public static getRailWidthExtension(
    module: Module,
    profile: Profile,
    placement: RailPlacement,
    scope: CalculationScope,
  ): number {
    // Numbers calculated from production drawings of profiles and given how much the section width covers in the different cases

    if (profile instanceof StartStopProfile)
      // The full profile is included in the section width
      return scope == CalculationScope.Module
        ? StartStopProfile.size + Module.elementSpacing
        : 0;

    if (profile instanceof CornerProfile) {
      // The full profile is included in the section width
      let extra = placement == RailPlacement.Ceiling ? 4 : 2; // extra is added because the corner joined diagonally together, different because of rails differs in width
      return CornerProfile.size + Module.elementSpacing + extra;
    }

    if (profile instanceof TProfile) {
      // only partionally included
      if (module.sectionId == profile.middleSection.sectionId) {
        if (placement == RailPlacement.Ceiling)
          return 1.5; // Module.elementSpacing - (48 - 41) / 2
        // 48 - is top rail depth
        else return 3.5; // Module.elementSpacing - (44 - 41) / 2
        // 44 - is bottom rail depth
      } else return TProfile.size / 2 + Module.elementSpacing;
    }

    return 0;
  }

  public static get profileRailReduction(): number {
    return 6; //Gotten from DoorCalculations KA Ver 19 excel
  }
  //#endregion
}

export enum CalculationScope {
  Section,
  Module,
}

export interface ProfileData {
  id: ProfileId;
  type: ProfileType;
  sections: SectionConnection[];
  PosX: number;
  PosY: number;
  width: number;
  height: number;
  depth: number;
  rotation: number;
  productId: number;

  positionNo: number; // position number as assigned on the piecelist
}

export interface SectionConnection {
  sectionId: SectionId;
  side: Side;
  profileId?: ProfileId; // If a door is against a joint, the joint should have a start/stop profile on the side
}

export type ProfileId = number & { _profileIds: never };

export enum ProfileType {
  StartStop = 'start-stop',
  Corner = 'corner',
  TPiece = 't-piece',
  Niche = 'niche',
}

export class TProfile extends Profile {
  public static createNew(
    id: ProfileId,
    leftSection: SectionConnection,
    middleSection: SectionConnection,
    rightSection: SectionConnection,
    PosX: number,
    PosY: number,
    width: number,
    height: number,
    depth: number,
    rotation: number,
    productId: number,
  ): TProfile {
    var sections: SectionConnection[] = [
      leftSection,
      middleSection,
      rightSection,
    ];

    let profile = new TProfile({
      id,
      type: ProfileType.TPiece,
      sections,
      PosX,
      PosY,
      width,
      height,
      depth,
      rotation,
      productId,
      positionNo: -1,
    });

    return profile;
  }

  constructor(data: TProfileData) {
    super(data);
  }

  // Seen like this:
  //
  // left          right
  // ---------|---------
  //          |
  //          |
  //        middle

  public get leftSection(): SectionConnection {
    return this.sections[0];
  }

  public get middleSection(): SectionConnection {
    return this.sections[1];
  }

  public get rightSection(): SectionConnection {
    return this.sections[2];
  }

  /**
   * Size does not include any spacing
   */
  public static get size(): number {
    return Module.fullJointWidth;
  }
}

export interface TProfileData extends ProfileData {}

export class CornerProfile extends Profile {
  public static createNew(
    id: ProfileId,
    leftSection: SectionConnection,
    rightSection: SectionConnection,
    PosX: number,
    PosY: number,
    width: number,
    height: number,
    depth: number,
    rotation: number,
    productId: number,
  ): CornerProfile {
    var sections: SectionConnection[] = [leftSection, rightSection];

    let profile = new CornerProfile({
      id,
      type: ProfileType.Corner,
      sections,
      PosX,
      PosY,
      width,
      height,
      depth,
      rotation,
      productId,
      positionNo: -1,
    });

    return profile;
  }

  constructor(data: CornerProfileData) {
    super(data);
  }

  // Seen like this:
  //
  //      right
  // ----------
  // |
  // |
  // |
  // left

  public get leftSection(): SectionConnection {
    return this.sections[0];
  }

  public get rightSection(): SectionConnection {
    return this.sections[1];
  }

  /**
   * Size does not include any spacing
   */
  public static get size(): number {
    return Module.fullJointWidth;
  }
}

export interface CornerProfileData extends ProfileData {}

export class StartStopProfile extends Profile {
  public override get data(): ProfileData {
    return this._data as ProfileData;
  }

  public static createNew(
    id: ProfileId,
    section: SectionConnection,
    PosX: number,
    PosY: number,
    width: number,
    height: number,
    depth: number,
    rotation: number,
    productId: number,
  ): StartStopProfile {
    var sections: SectionConnection[] = [section];

    let profile = new StartStopProfile({
      id,
      type: ProfileType.StartStop,
      sections,
      PosX,
      PosY,
      width,
      height,
      depth,
      rotation,
      productId,
      positionNo: -1,
    });

    return profile;
  }

  constructor(data: ProfileData) {
    super(data);
  }

  public get section(): SectionConnection {
    return this.sections[0];
  }

  /**
   * Size does not include any spacing
   */
  public static get size(): number {
    return 15;
  }
}

export class NicheProfile extends Profile {
  public override get data(): ProfileData {
    return this._data as ProfileData;
  }

  public static createNew(
    id: ProfileId,
    section: SectionConnection,
    PosX: number,
    PosY: number,
    width: number,
    height: number,
    depth: number,
    rotation: number,
    productId: number,
  ): NicheProfile {
    var sections: SectionConnection[] = [section];

    let profile = new NicheProfile({
      id,
      type: ProfileType.Niche,
      sections,
      PosX,
      PosY,
      width,
      height,
      depth,
      rotation,
      productId,
      positionNo: -1,
    });

    return profile;
  }

  constructor(data: ProfileData) {
    super(data);
  }

  public get section(): SectionConnection {
    return this.sections[0];
  }

  public static get heightReduction(): number {
    return 30; // gotten from excel with calculations
  }

  public static get distFromWallToStartStop(): number {
    return 15;
  }
}
