import { Component, Input, OnInit } from '@angular/core';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';
import { Subject } from 'rxjs';
import { SwingFlexComponent } from './swing-flex/swingFlexImage.component';

@Component({
  selector: 'swing-image',
  styleUrls: ['../../style/editor.scss', '../../style/d2.scss'],
  templateUrl: './swingImage.svg',
})
export class SwingImageVm extends BaseVm implements OnInit {
  private static padding = 30;
  private static topDownPadding = 30;

  public static readonly doorRotationDegrees = 25;

  public readonly itemRulerBackgroundWidth = 85;
  public readonly itemRulerBackgroundHeight = 70;

  private cache: Partial<{
    doors: SwingImageVm.Door[];
    rulers: Client.Ruler[];
    swingItems: Client.ConfigurationItem[];
  }> = {};

  private selectedSwingArea: Client.SwingArea | null = null;

  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  @Input()
  public cabinetSection!: Client.CabinetSection;
  @Input()
  public showDoors = false;
  @Input()
  public showRulers = false;
  @Input()
  public showTopdown = true;
  @Input()
  public selectionObservable!: Subject<Client.SwingArea | null>;

  public ngOnInit() {
    // if (!this.selectionObservable) {
    //     this.selectionObservable = new Subject<Client.SwingArea | null>();
    // }
    this.ensureUnsubscribe(
      this.selectionObservable.subscribe(
        (selection) => (this.selectedSwingArea = selection),
      ),
    );
    this.subscribeTo(this.cabinetSection.cabinet.floorPlan.floorPlan$, (fp) =>
      this.clearCache(),
    );
  }

  public get sectionHeight(): number {
    return this.cabinetSection.Height || 0;
  }

  public get swingAreas() {
    return this.cabinetSection.swing.areas;
  }

  public get doors(): SwingImageVm.Door[] {
    if (!this.cache.doors) {
      this.cache.doors = SwingImageVm.createDoors(this.swingAreas);
    }
    return this.cache.doors;
  }

  public get swingItems(): Client.ConfigurationItem[] {
    if (!this.cache.swingItems) {
      this.cache.swingItems = Enumerable.from(this.swingAreas)
        .selectMany((area) =>
          Enumerable.from(area.items).orderBy((item) => item.frontZ),
        )
        .toArray();
    }
    return this.cache.swingItems;
  }

  public static createDoors(areas: Client.SwingArea[]): SwingImageVm.Door[] {
    let doors: SwingImageVm.Door[] = [];
    for (let area of areas) {
      switch (area.hingeSide) {
        case Enums.HingeSide.Left:
          doors.push(
            new SwingImageVm.Door(
              area.insideRect.X,
              area.insideRect.Width,
              area.hingeSide,
            ),
          );
          break;
        case Enums.HingeSide.Right:
          doors.push(
            new SwingImageVm.Door(
              area.insideRect.topRight.X,
              area.insideRect.Width,
              area.hingeSide,
            ),
          );
          break;
        case Enums.HingeSide.Both:
          doors.push(
            new SwingImageVm.Door(
              area.insideRect.X,
              area.insideRect.Width / 2,
              Enums.HingeSide.Left,
            ),
          );
          doors.push(
            new SwingImageVm.Door(
              area.insideRect.topRight.X,
              area.insideRect.Width / 2,
              Enums.HingeSide.Right,
            ),
          );
          break;
      }
    }
    return doors;
  }

  public get rulers(): Client.Ruler[] {
    if (!this.cache.rulers) {
      this.cache.rulers = Client.Ruler.GetSwingRulers(this.cabinetSection);
    }
    return this.cache.rulers;
  }

  public isItemDisplayed(item: Client.ConfigurationItem): boolean {
    if (
      item.ItemType === Interface_Enums.ItemType.SwingModule ||
      item.ItemType === Interface_Enums.ItemType.SwingExtra
    ) {
      return false;
    }

    if (this.showDoors) {
      return true;
    }
    return item.ItemType !== Interface_Enums.ItemType.SwingDoor;
  }

  public shouldDisplayRulerFor(item: Client.ConfigurationItem): boolean {
    if (!this.showRulers) {
      return false;
    }
    if (this.showDoors) {
      return item.ItemType === Interface_Enums.ItemType.SwingDoor;
    } else {
      return this.isItemDisplayed(item);
    }
  }

  public selectSwingArea(swingArea: Client.SwingArea | null) {
    this.selectionObservable.next(swingArea);
  }

  public isSwingAreaSelected(swingArea: Client.SwingArea): boolean {
    return this.selectedSwingArea === swingArea;
  }

  public getItemTransformString(item: Client.ConfigurationItem): string {
    let result = '';
    let x = item.X;
    let y = this.cabinetSection.Height - item.topY;
    result += ' translate( ' + x + ' , ' + y + ' ) ';
    return result;
  }

  // #region viewbox
  public get viewBoxPosition(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: -SwingImageVm.padding,
      Y: -SwingImageVm.padding,
    };

    result.X -= this.rulerSpacing.X;
    result.Y -= this.rulerSpacing.Y;

    return result;
  }

  private get rulerSpacing(): Interface_DTO_Draw.Vec2d {
    return this.showRulers
      ? { X: 2 * Constants.rulerWidth, Y: 3 * Constants.rulerWidth }
      : { X: 0, Y: 0 };
  }

  private get doorSpacing(): Interface_DTO_Draw.Vec2d {
    let doorPadding = 30;
    return !this.showTopdown
      ? { X: 0, Y: 0 }
      : {
          X: 0,
          Y: Math.max(0, ...this.doors.map((d) => d.pos2.Y)) + doorPadding,
        };
  }

  public get viewBoxSize(): Interface_DTO_Draw.Vec2d {
    let result = {
      X: this.cabinetSection.Width + 2 * SwingImageVm.padding,
      Y: this.cabinetSection.Height + 2 * SwingImageVm.padding,
    };

    result = VectorHelper.add(result, this.rulerSpacing, this.doorSpacing);

    return result;
  }

  public get viewBoxString(): string {
    let pos = this.viewBoxPosition;
    let size = this.viewBoxSize;
    return pos.X + ' ' + pos.Y + ' ' + size.X + ' ' + size.Y;
  }

  // #endregion viewbox

  private clearCache() {
    this.cache = {};
  }

  public static createHinges(
    cabinetSection: Client.CabinetSection,
  ): SwingFlexComponent.Hinge[] {
    let hingesItems = Enumerable.from(
      cabinetSection.swingFlex.areas,
    ).selectMany((area) => area.hinges);

    let hinges: SwingFlexComponent.Hinge[] = [];

    for (let hingeData of hingesItems) {
      hinges.push(
        new SwingFlexComponent.Hinge(
          hingeData.hingeType,
          hingeData.centerX,
          hingeData.centerY + hingeData.doorOffset,
        ),
      );
    }

    return hinges;
  }
}

export module SwingImageVm {
  export class Door {
    public readonly pos1: Readonly<Interface_DTO_Draw.Vec2d>;
    public readonly pos2: Readonly<Interface_DTO_Draw.Vec2d>;
    constructor(
      public readonly hingeXPosition: number,
      public readonly width: number,
      public readonly hingeSide: Enums.HingeSide,
    ) {
      let rotation =
        hingeSide === Enums.HingeSide.Left
          ? SwingImageVm.doorRotationDegrees
          : 180 - SwingImageVm.doorRotationDegrees;
      this.pos1 = { X: hingeXPosition, Y: 0 };
      this.pos2 = {
        X: hingeXPosition + width * Math.cos((rotation / 180) * Math.PI),
        Y: width * Math.sin((rotation / 180) * Math.PI),
      };
    }
  }
}
