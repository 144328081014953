<div class="editor-all editor-doors" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'doors'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>

  <div class="tool-menu left-tool-menu">
    <div class="panel">
      <h4 [textContent]="translate('doors_label_door_profile', 'Profile')"></h4>
      <pickable
        (click)="selectDoorProduct()"
        [pickable]="doorProduct"
        class="product door-product modal-opener"
      ></pickable>
    </div>

    <div *ngIf="showDoorOptions" class="door-options">
      <div *ngIf="showProfileMaterialSelector" class="panel">
        <h4
          [textContent]="translate('doors_label_door_profile_material', 'Profile material')"
        ></h4>
        <pickable
          (click)="selectProfileMaterial()"
          [pickable]="profileMaterial"
          class="material door-material modal-opener"
        ></pickable>
      </div>

      <div *ngIf="showRailsetSelector" class="panel">
        <h4 [textContent]="translate('doors_label_door_railset', 'Rails')"></h4>
        <pickable
          (click)="selectRailset()"
          [pickable]="railSet"
          class="railset modal-opener"
        ></pickable>
      </div>

      <div *ngIf="showRailMaterialTopSelector" class="panel">
        <h4
          [textContent]="translate('doors_label_door_rail_material_top', 'Rail material top')"
        ></h4>
        <pickable
          (click)="selectRailMaterialTop()"
          [pickable]="railMaterialTop"
          class="material rail-material modal-opener"
        ></pickable>
      </div>

      <div *ngIf="showRailMaterialBottomSelector" class="panel">
        <h4
          [textContent]="translate('doors_label_door_rail_material_bottom', 'Rail material bottom')"
        ></h4>
        <pickable
          (click)="selectRailMaterialBottom()"
          [pickable]="railMaterialBottom"
          class="material rail-material modal-opener"
        ></pickable>
      </div>

      <div class="panel">
        <div class="form-group form-group-sm">
          <label
            for="lm-door-numDoors"
            [textContent]="translate('doors_label_num_doors', 'Number of doors')"
          ></label>
          <select
            id="lm-door-numDoors"
            class="form-select"
            [(ngModel)]="numDoors"
          >
            <option
              *ngFor="let doors of cabinetSection!.doors.availableNumberOfDoors"
              [ngValue]="doors"
            >
              {{doors}}
            </option>
          </select>
        </div>

        <div class="form-group form-group-sm">
          <label
            for="lm-door-numOverlaps"
            [textContent]="translate('doors_label_num_overlaps', 'Number of overlaps')"
          ></label>
          <select
            id="lm-door-numOverlaps"
            class="form-select"
            [(ngModel)]="numOverlaps"
          >
            <option
              *ngFor="let overlaps of cabinetSection!.doors.availableNumberOfOverlaps"
              [ngValue]="overlaps"
            >
              {{overlaps}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{translate('editor_label_show_rulers', 'Rulers')}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showPullout" />
        {{translate('editor_label_show_pullout', 'Pullout Warnings')}}
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showTopDown" />
        {{translate('editor_label_show_topdown', 'Topdown')}}
      </label>
    </div>
    <div *ngIf="allowManualOverlaps" class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="manualOverlaps" />
        {{translate('editor_label_manualOverlaps', 'Manual overlaps')}}
      </label>
    </div>

    <div class="ribbon-block ribbon-block-vertical">
      <div class="input-group input-group-sm input-group-long">
        <span
          class="input-group-text long-addon"
          [textContent]="translate('doors_label_bar_height', 'Bar height')"
        ></span>
        <select
          id="lm-door-barHeight"
          class="form-select"
          [(ngModel)]="currentBarHeight"
          (ngModelChange)="setChanged()"
        >
          <option
            *ngFor="let bar of availableBars"
            [ngValue]="bar.height"
            [disabled]="!!barHeightDisabledReason(bar)"
            [attr.title]="translate(barHeightDisabledReason(bar))"
          >
            {{bar.height}} mm
          </option>
        </select>
      </div>

      <div class="input-group input-group-sm input-group-long">
        <span
          class="input-group-text long-addon"
          [textContent]="translate('doors_label_overlap_width', 'Overlap Width')"
        ></span>
        <input
          id="lm-door-overlapWidth"
          type="number"
          class="form-control"
          [(ngModel)]="cabinetSection!.doors.overlapWidth"
          [ngModelOptions]="modelOptions"
          [attr.min]="cabinetSection!.doors.overlapWidthMin"
          [attr.max]="cabinetSection!.doors.overlapWidthMax"
          (ngModelChange)="setChanged()"
        />
      </div>
    </div>

    <div
      class="ribbon-block checkbox-ribbon-block"
      *ngIf="cabinetSection!.doors.canAddSoftclose"
    >
      <label>
        <input
          type="checkbox"
          [(ngModel)]="useSoftclose"
          (ngModelChange)="setChanged()"
        />
        <span
          [textContent]="translate('doors_label_softclose', 'Softclose')"
        ></span>
      </label>

      <div
        class="input-group input-group-sm input-group-long"
        *ngIf="canHavePositionStop"
      >
        <span
          class="input-group-text"
          [textContent]="translate('doors_label_num_posSteps', 'Position stops')"
        ></span>
        <input
          id="lm-door-numPosSteps"
          class="form-control"
          type="number"
          [(ngModel)]="cabinetSection!.doors.numberOfPositionStops"
          [ngModelOptions]="modelOptions"
          [attr.min]="cabinetSection!.doors.numberOfPositionStopsMin"
          [attr.max]="cabinetSection!.doors.numberOfPositionStopsMax"
          (ngModelChange)="setChanged()"
        />
      </div>
    </div>

    <div
      class="ribbon-block checkbox-ribbon-block"
      *ngIf="!cabinetSection!.doors.canAddSoftclose"
    >
      <label>
        <input type="checkbox" [disabled]="true" checked />
        <span
          [textContent]="translate('doors_label_softclose', 'Softclose')"
        ></span>
      </label>
    </div>

    <div class="ribbon-block">
      <div
        class="btn-group"
        ngbDropdown
        autoClose="outsideClick"
        [open]="isAdvancedMenuOpen"
      >
        <button class="btn btn-default" ngbDropdownToggle>
          <span
            [textContent]="translate('editor_door_ribbon_advanced', 'Advanced')"
          ></span>
          <span class="caret"></span>
        </button>
        <ul
          class="dropdown-menu dropdown-menu-right dropdown-menu-editable"
          ngbDropdownMenu
        >
          <li role="menuitem">
            <div class="input-group input-group-sm">
              <span
                class="input-group-text extra-long-addon"
                [textContent]="advancedRailLeftText"
              ></span>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="cabinetSection!.doors.extraRailWidthLeft"
                [ngModelOptions]="modelOptions"
                [attr.min]="cabinetSection!.doors.extraRailWidthLeftMin"
                [attr.max]="cabinetSection!.doors.extraRailWidthLeftMax"
                (ngModelChange)="setChanged()"
              />
              <span class="input-group-text">mm</span>
            </div>
          </li>
          <li role="menuitem">
            <div class="input-group input-group-sm">
              <span
                class="input-group-text extra-long-addon"
                [textContent]="advancedRailRightText"
              ></span>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="cabinetSection!.doors.extraRailWidthRight"
                [ngModelOptions]="modelOptions"
                [attr.min]="cabinetSection!.doors.extraRailWidthRightMin"
                [attr.max]="cabinetSection!.doors.extraRailWidthRightMax"
                (ngModelChange)="setChanged()"
              />
              <span class="input-group-text">mm</span>
            </div>
          </li>
          <li role="menuitem" *ngIf="onlyDoorVisible">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="onlyDoor"
                [disabled]="onlyDoorForced"
              />
              <span
                [textContent]="translate('doors_advanced_only_doors', 'Disable top and bottom rails')"
              ></span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <door-image
    class="editor-center"
    [cabinetSection]="cabinetSection!"
    [showPullout]="showPullout"
    [showRulers]="showRulers"
    [showTopDown]="showTopDown"
    [selectedPart$]="selectedPart$"
  ></door-image>

  <div class="tool-menu right-tool-menu">
    <div *ngIf="selectedDoor && selectedFilling">
      <h3
        [textContent]="translate('doors_header_selected_door_{0}', 'Door {0}', selectedFilling.door.index)"
      ></h3>

      <div class="form-group form-group-sm" *ngIf="showAdminOnlyAttributes">
        <label for="rm-door-corner-type">
          <span
            [attr.title]="translate('editor_door_corner_only_admin', 'This can only be changed by support')"
            class="bi bi-exclamation-triangle-fill"
            style="color: goldenrod"
          ></span>
          <span
            [textContent]="translate('doors_label_door_corner_cut_type', 'Corner type')"
          ></span>
        </label>
        <select
          id="rm-door-corner-type"
          class="form-select"
          [(ngModel)]="selectedDoor.cornerCut"
          (ngModelChange)="setChanged()"
        >
          <option
            *ngFor="let cornerCut of selectedDoor.availableCornerCuts"
            [ngValue]="cornerCut"
          >
            {{cornerCut.DefaultName}}
          </option>
        </select>
      </div>

      <div *ngIf="selectedDoor == null || selectedDoor.numberOfFillingsMax > 1">
        <div class="form-group form-group-sm">
          <label
            for="rm-door-num-fillings"
            [textContent]="translate('doors_label_num_fillings', 'Number of fillings')"
          ></label>
          <input
            id="rm-door-num-fillings"
            type="number"
            class="form-control"
            [(ngModel)]="selectedDoorNumFillings"
            [ngModelOptions]="modelOptions"
            [attr.min]="selectedDoor.numberOfFillingsMin"
            [attr.max]="selectedDoor.numberOfFillingsMax"
            (ngModelChange)="setChanged()"
          />
        </div>
      </div>

      <label *ngIf="showFixedBarsCheckbox">
        <input
          type="checkbox"
          [(ngModel)]="selectedDoor.forceFixedBars"
          (ngModelChange)="setChanged()"
        />
        <span
          [textContent]="selectedDoor.doorSubSection.forceFixedBarVariantText"
        ></span>
      </label>

      <div *ngIf="selectedDoor.mayHaveVerticalBars">
        <span
          [textContent]="selectedDoor.doorSubSection.verticalBarVariantText"
        ></span>
        <select
          id="rm-door-vertical-bars"
          class="form-select"
          [(ngModel)]="selectedDoorVerticalBarOptionNumber"
          (ngModelChange)="setChanged()"
        >
          <option
            *ngFor="let vbo of availableVerticalBarOptions"
            [ngValue]="vbo.Number"
            [disabled]="!!verticalBarDisabledReason(vbo)"
            [attr.title]="translate(verticalBarDisabledReason(vbo))"
            [textContent]="vbo.DefaultName"
          >
            {{vbo.Number}}
          </option>
        </select>
      </div>

      <div *ngIf="selectedDoor.gripsAvailable">
        <pickable
          (click)="selectGrip()"
          [pickable]="grip"
          class="product door-grip modal-opener"
        >
        </pickable>

        <div *ngIf="selectedDoor.hasGrip">
          <div class="form-group form-group-sm">
            <label
              for="rm-door-grip-height"
              [textContent]="translate('doors_label_grip_height', 'Grip height')"
            ></label>
            <input
              id="rm-door-grip-height"
              #gripHeight
              type="number"
              class="form-control"
              [value]="selectedDoor.doorSubSection.gripPlacementHeight"
              [attr.min]="selectedDoor.doorSubSection.minGripPlacementHeight"
              [attr.max]="selectedDoor.doorSubSection.maxGripPlacementHeight"
              (change)="selectedDoor.doorSubSection.gripPlacementHeight = gripHeight.valueAsNumber;setChanged()"
            />
          </div>

          <div class="form-group form-group-sm">
            <label
              for="rm-door-grip-front"
              [textContent]="translate('doors_label_grip_placement_front', 'Grip position front')"
            ></label>
            <select
              id="rm-door-grip-front"
              class="form-select"
              [(ngModel)]="selectedDoor.gripPlacementFront"
              (ngModelChange)="setChanged()"
            >
              <option
                *ngFor="let grip of selectedDoor.doorSubSection.availableGripPlacementsFront"
                [ngValue]="grip"
              >
                {{grip.DefaultName}}
              </option>
            </select>
          </div>

          <div class="form-group form-group-sm">
            <label
              for="rm-door-grip-back"
              [textContent]="translate('doors_label_grip_placement_back', 'Grip position back')"
            ></label>
            <select
              id="rm-door-grip-back"
              class="form-control"
              [(ngModel)]="selectedDoor.gripPlacementBack"
              (ngModelChange)="setChanged()"
            >
              <option
                *ngFor="let grip of selectedDoor.doorSubSection.availableGripPlacementsBack"
                [ngValue]="grip"
              >
                {{grip.DefaultName}}
              </option>
            </select>
          </div>

          <hr />
        </div>
      </div>

      <h3
        [textContent]="translate('doors_header_selected_filling_{0}_{1}', 'Door {0} filling {1}', selectedFilling.door.index, selectedFilling.index)"
      ></h3>

      <pickable
        (click)="selectFillingMaterial()"
        [pickable]="selectedFillingMaterial"
        class="modal-opener material filling-material"
      ></pickable>

      <div *ngIf="selectedDoor.numberOfFillingsMax > 1">
        <button
          class="btn btn-block btn-light"
          (click)="useSelectedFillingOnAll()"
          [textContent]="translate('doors_filling_button_use_material_on_all', 'Use on all fillings')"
        ></button>
      </div>
      <hr />

      <div *ngIf="selectedDoor.numberOfFillingsMax > 1">
        <button
          class="btn btn-block btn-light"
          (click)="spreadFillingsEvenly()"
          [textContent]="translate('doors_button_spred_fillings_evenly', 'Spread fillings evenly')"
        ></button>
      </div>

      <button
        class="btn btn-block btn-light"
        (click)="copyDoor()"
        [textContent]="translate('doors_button_copy_door', 'Copy to all doors')"
      ></button>
    </div>

    <div *ngIf="selectedBar">
      <h3
        [textContent]="translate('doors_header_selected_bar_{0}_{1}', 'Door {0} bar {1}', selectedBar.door.index, selectedBar.index)"
      ></h3>

      <div class="form-group form-group-sm">
        <label
          for="rm-door-bar-above"
          [textContent]="translate('doors_label_bar_above_height', 'Above Height')"
        ></label>
        <input
          #heightAbove
          id="rm-door-bar-above"
          class="form-control"
          type="number"
          [value]="selectedBar.aboveHeight"
          (change)="selectedBar.aboveHeight = heightAbove.valueAsNumber; setChanged()"
          [attr.min]="selectedBar.aboveHeightMin"
          [attr.max]="selectedBar.aboveHeightMax"
        />
      </div>

      <div class="form-group form-group-sm">
        <label
          for="rm-door-bar-above-percent"
          [textContent]="translate('doors_label_bar_above_height_percent', 'Above Height Percent')"
        ></label>
        <input
          #heightAbovePercent
          id="rm-door-bar-above-percent"
          class="form-control"
          type="number"
          step=".1"
          [value]="selectedBar.aboveHeightPercent"
          (change)="selectedBar.aboveHeightPercent = heightAbovePercent.valueAsNumber; setChanged()"
          [attr.min]="selectedBar.aboveHeightPercentMin"
          [attr.max]="selectedBar.aboveHeightPercentMax"
        />
      </div>

      <hr />

      <div class="form-group form-group-sm">
        <label
          for="rm-door-bar-position"
          [textContent]="translate('doors_label_bar_position', 'Position')"
        ></label>
        <input
          #position
          id="rm-door-bar-position"
          class="form-control"
          type="number"
          [value]="selectedBar.desiredCenterY"
          (change)="selectedBar.desiredCenterY = position.valueAsNumber; setChanged()"
          [attr.min]="selectedBar.centerYMin"
          [attr.max]="selectedBar.centerYMax"
        />
      </div>

      <hr />

      <div class="form-group form-group-sm">
        <label
          for="rm-door-bar-below"
          [textContent]="translate('doors_label_bar_below_height', 'Below Height')"
        ></label>
        <input
          #belowHeight
          id="rm-door-bar-below"
          class="form-control"
          type="number"
          [value]="selectedBar.belowHeight"
          (change)="selectedBar.belowHeight = belowHeight.valueAsNumber; setChanged()"
          [attr.min]="selectedBar.belowHeightMin"
          [attr.max]="selectedBar.belowHeightMax"
        />
      </div>

      <div class="form-group form-group-sm">
        <label
          for="rm-door-bar-below-percent"
          [textContent]="translate('doors_label_bar_below_height_percent', 'Below Height Percent')"
        ></label>
        <input
          #belowHeightPercent
          id="rm-door-bar-below-percent"
          class="form-control"
          type="number"
          step=".1"
          [value]="selectedBar.belowHeightPercent"
          (change)="selectedBar.belowHeightPercent = belowHeightPercent.valueAsNumber; setChanged()"
          [attr.min]="selectedBar.belowHeightPercentMin"
          [attr.max]="selectedBar.belowHeightPercentMax"
        />
      </div>
    </div>

    <div *ngIf="selectedOverlap">
      <h3
        [textContent]="translate('doors_header_selected_overlap_{0}', 'Overlap {0}', selectedOverlap.index + 1)"
      ></h3>

      <div class="form-group form-group-sm">
        <label
          for="rm-overlap-door-left"
          [textContent]="translate('overlap_label_door_left_width', 'Left door width')"
        ></label>
        <input
          #overlapLeft
          id="rm-overlap-door-left"
          class="form-control"
          type="number"
          [value]="selectedOverlap.doorWidthLeft"
          (change)="selectedOverlap.doorWidthLeft = overlapLeft.valueAsNumber; setChanged()"
          [(ngModel)]="selectedOverlap.doorWidthLeft"
          [attr.min]="selectedOverlap.widthLeftMin"
          [attr.max]="selectedOverlap.widthLeftMax"
        />
      </div>

      <div class="form-group form-group-sm">
        <label
          for="rm-overlap-position"
          [textContent]="translate('overlap_label_center_position', 'Overlap center position')"
        ></label>
        <input
          #overlapCenter
          id="rm-overlap-position"
          class="form-control"
          type="number"
          [value]="selectedOverlap.desiredPositionCenterRounded"
          (change)="selectedOverlap.desiredPositionCenterRounded = overlapCenter.valueAsNumber; setChanged()"
          [attr.min]="selectedOverlap.positionMinRounded"
          [attr.max]="selectedOverlap.positionMaxRounded"
        />
      </div>

      <div class="form-group form-group-sm">
        <label
          for="rm-overlap-door-right"
          [textContent]="translate('overlap_label_door_right_width', 'Right door width')"
        ></label>
        <input
          #overlapRight
          id="rm-overlap-door-right"
          class="form-control"
          type="number"
          [value]="selectedOverlap.doorWidthRight"
          (change)="selectedOverlap.doorWidthRight = overlapRight.valueAsNumber; setChanged()"
          [attr.min]="selectedOverlap.widthRightMin"
          [attr.max]="selectedOverlap.widthRightMax"
        />
      </div>
    </div>
  </div>
  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
