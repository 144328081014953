import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { ChainSettingKey } from 'app/ts/Interface_Enums';
import { LoginService } from '@Services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { UserToken } from 'app/ts/UserToken';
import { ConvertFromUserToken } from 'app/identity-and-access/User';
import { NotificationService } from 'app/ts/services/NotificationService';
import { ApplicationState } from 'app/functional-core/ApplicationState';
import { Constants } from 'app/ts/Constants';

interface LoginDetails {
  username: string;
  password: string;
  rememberMe: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../style/form-like.scss'],
  providers: [activeUserProvider],
})
export class LoginComponent implements OnInit {
  public loginForm = this.formBuilder.group({
    username: '',
    password: '',
    rememberMe: false,
  } as LoginDetails);

  public showUi: boolean = false;

  public errorText: string = '';

  public showError: boolean = false;

  public newAppVersionMessageEN: string = '';
  public newAppVersionMessageDK: string = '';

  constructor(
    private data: FunctionalCoreService,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private loginService: LoginService,
    private readonly notifications: NotificationService,
  ) {
    const isAuthenticated = activeUser?.data != null;
    this.showUi = !isAuthenticated;
    if (isAuthenticated) {
      const returnUrl = this.route.snapshot.queryParams['returnUrl'];
      if (returnUrl != null) router.navigateByUrl(returnUrl);
      else router.navigate(['/floorplans'], { replaceUrl: true });
    }

    loginService.chainSettings$.subscribe(
      (settings: { [chainSetting: number]: string }) => {
        if (!settings || !settings[ChainSettingKey.LoginInfo]) return;

        let texts = settings[ChainSettingKey.LoginInfo].split('<>');
        if (texts.length > 0) {
          this.newAppVersionMessageEN = texts[0];
        }
        if (texts.length > 1) {
          this.newAppVersionMessageDK = texts[1];
        }
      },
    );
  }

  ngOnInit(): void {}

  onSubmit(): void {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    const loginDetails = this.loginForm.value as LoginDetails;
    this.executeLogin(
      loginDetails.username,
      loginDetails.password,
      loginDetails.rememberMe,
      returnUrl,
    );
  }

  resetPassword(): void {
    try {
      this.modalService.resetPassword();
    } catch (e) {
      //user cancelled
    }
  }

  public async executeLogin(
    username: string,
    password: string,
    rememberMe: boolean,
    returnUrl: string,
  ) {
    try {
      const loginResult = await this.loginService.login(
        username,
        password,
        rememberMe,
      );

      if (loginResult) {
        this.notifications.success(
          'login_success_notification_{0}',
          'You are now logged in as {0}',
          username,
        );
        this.loginService.loginOk(loginResult, username, rememberMe, returnUrl);
      }
    } catch (error) {
      this.notifications.userError(
        'login_failed_notification',
        'Wrong username or password',
      );
    }
  }

  private ok(
    userToken: UserToken,
    username: string,
    rememberMe: boolean,
    returnUrl: string,
  ) {
    const user = ConvertFromUserToken(userToken, username);

    this.data.ambient.activeUser.activeUser = user;
    this.data.ambient.applicationState = ApplicationState.Initializing;

    // To make all those dependent on that userTokenObservable-thingy work
    this.loginService.setUserToken(userToken);

    localStorage.setItem(
      Constants.localStorageKeys.rememberMeChecked,
      rememberMe ? '1' : '0',
    );
    if (rememberMe) {
      localStorage.setItem(
        Constants.localStorageKeys.loginToken,
        userToken.Token,
      );
    } else {
      sessionStorage.setItem(
        Constants.localStorageKeys.loginToken,
        userToken.Token,
      );
    }

    const navigationExtras: NavigationExtras = {
      preserveFragment: false,
      skipLocationChange: false,
      replaceUrl: true,
    };

    if (returnUrl != null) {
      this.router.navigate([returnUrl], navigationExtras);
    } else {
      this.router.navigate(['/floorplans'], navigationExtras);
    }

    return [];
  }
}
