import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import IDataAccess from 'app/ts/interfaces/IDataAccess';
import ITranslator from 'app/ts/interfaces/ITranslator';
import TopBarInteraction from 'app/ts/interfaces/TopBarInteraction';
import { NavigationService } from 'app/ts/services/NavigationService';

@Component({
  selector: 'customer-topbar',
  templateUrl: './customer.topbar.component.html',
  styleUrls: [
    'customer.topbar.component.scss',
    '../../app/ts/viewmodels/components/topbar.scss',
  ],
  viewProviders: [],
})
export class CustomerTopBarComponent implements AfterViewInit {
  @ViewChild('quicksearch')
  quickSearchElement!: ElementRef;

  @ViewChild('customerquicksearch')
  customerQuickSearchElement!: ElementRef;

  constructor(private naviService: NavigationService) {}
  ngAfterViewInit(): void {
    if (this.quickSearchElement == null) return;
    setTimeout(() => {
      if (this.parent.showAllFloorplansOnEmptySearch) {
        const [start, end] = this.naviService.getCaratPosition();
        this.quickSearchElement.nativeElement.focus();
        this.quickSearchElement.nativeElement.setSelectionRange(start, end);
        // It is possible to set the cursor position, we just need to have it somewhere ;)
        // Of course it would be better to somehow avoid re-rendering...
      } else {
        this.customerQuickSearchElement.nativeElement.focus();
      }
    }, 0);
  }

  public updateCarat() {
    const input: HTMLInputElement = this.quickSearchElement.nativeElement;
    if (input.selectionStart != null && input.selectionEnd != null)
      this.naviService.setCaratPosition(
        input.selectionStart,
        input.selectionEnd,
      );
  }

  public floorplan: undefined = undefined;

  // TODO: Replace any with a decent interface of some kind
  // parent can be a CustomersVm, DeliveryAddressesVm, FloorplansVm, ProjectsVm,
  // which all inherit from CustomerBase<T>, which should be the one
  // implementing said interface, e.g. TopBarInteraction, or maybe they should
  // probably share a service... That might also stop some of the
  // many unnecessary renders
  @Input()
  public parent!: TopBarInteraction & IDataAccess & ITranslator;

  public get displayCreateNew() {
    return !this.floorplan;
  }

  public get quickSearchString() {
    return this.parent.quickSearchString;
  }

  public set quickSearchString(value: string) {
    this.parent.quickSearchString = value;
  }

  public mineOnlyClicked() {
    this.parent.mineOnly = !this.parent.mineOnly;
  }

  public showAllClicked() {
    this.parent.showAll = !this.parent.showAll;
  }
}

// TODO: Weird choice of name
export class BaseCustomer {
  public constructor(
    id: number,
    name: string,
    address1: string,
    postalCode: string,
    city: string,
  ) {
    this.id = id;
    this.name = name + ' | ' + address1 + ' | ' + postalCode + ' ' + city;
  }
  id: number;
  name: string;
}
