<div class="modal-content select-backing-modal">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('select_backing_modal_header', 'Select Backing')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="backings">
      <div
        *ngFor="let backing of backings"
        class="backing"
        [ngClass]="{'selected': backing.backingType === backingType}"
      >
        <pickable
          [pickable]="backing.pickable"
          (click)="backingType = backing.backingType"
          class="clickable"
        >
        </pickable>
        <div class="description" [textContent]="backing.description"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <label>
      <input type="checkbox" [(ngModel)]="adjustSectionDepth" />
      <span
        [textContent]="translate('select_backing_modal_adjust_total_depth', 'Adjust depth of cabinet to keep same internal space')"
        [attr.title]="translate('select_backing_modal_adjust_total_depth_tooltip', 'Adjust depth of cabinet to keep same internal space')"
        style="font-weight: 600; color: red"
      ></span>
    </label>
    <div class="btn-group">
      <button
        class="btn btn-primary"
        (click)="ok()"
        [textContent]="translate('modal_button_ok', 'OK')"
      ></button>

      <button
        class="btn btn-secondary"
        (click)="cancel()"
        [textContent]="translate('modal_button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
