import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'help-bubble',
  templateUrl: './helpBubble.html',
  styleUrls: ['./helpBubble.scss'],
})
export class HelpBubbleVm extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  private _header: string | null = null;
  @Input()
  get header(): string | null {
    return this._header;
  }
  set header(val: string | null) {
    this._header = val;
  }
  private _text: string = '';
  @Input()
  get text(): string {
    return this._text;
  }
  set text(val: string) {
    this._text = val;
  }

  @Input()
  get passThroughClick(): boolean {
    return this._passThroughClick;
  }
  set passThroughClick(value: boolean) {
    this._passThroughClick = value;
  }
  private _passThroughClick: boolean = false;

  @Output()
  public onClick = new EventEmitter<void>();

  public showBubble = false;

  public async click(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.passThroughClick) {
      this.onClick.emit();
    } else {
      this.toggleBubble();
    }
  }

  private toggleBubble() {
    this.showBubble = !this.showBubble;
  }
}
