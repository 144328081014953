import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import * as DTO from 'app/ts/interface_dto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ProjectService } from 'app/ts/services/ProjectService';
import { BasicModalVm } from 'app/ts/viewmodels/modals/BasicModalVm';

@Component({
  selector: 'new-project-vm',
  templateUrl: './newProject.html',
  styleUrls: ['../../../../style/modal.scss'],
  providers: [activeUserProvider, ProjectService],
})
export class NewProjectVm extends BasicModalVm<DTO.Project> implements OnInit {
  public dtoObject: DTO.Project | null = null;

  constructor(
    baseVmService: BaseVmService,
    activeModal: NgbActiveModal,
    private readonly projectService: ProjectService,
    @Inject(ActiveUserInjector) readonly activeUser: ActiveUser,
  ) {
    super(baseVmService, activeModal);
  }

  public ngOnInit() {
    this.dtoObject = this.projectService.getEmptyProject(this.customer);
  }

  public readonly customer!: DTO.Customer;

  public async save() {
    if (!this.dtoObject) return;
    let newProject = await this.projectService.createProject(this.dtoObject);
    this.close(newProject);
  }
}
