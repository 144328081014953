import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from 'app/ts/services/LoginService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'consumer-login-vm',
  templateUrl: './consumerLogin.html',
  styleUrls: ['\\consumerLogin.scss'],
})
export class ConsumerLoginVm extends BaseVm implements OnInit {
  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
  ) {
    super(baseVmService);
  }

  ngOnInit(): void {
    this.password = '';
    this.username = '';
  }

  public isActive = false;

  private _username = '';
  public get username() {
    return this._username;
  }
  public set username(val: string) {
    this._username = val;
    this.showError = false;
  }

  private _password = '';
  public get password() {
    return this._password;
  }
  public set password(val: string) {
    this._password = val;
    this.showError = false;
  }

  public showError = false;

  public close() {
    this.activeModal.dismiss('close');
  }

  public newUser() {
    this.activeModal.close({
      value: 'newUser',
      username: this.username,
      password: this.password,
    });
  }

  public async login() {
    if (!this.username) return;
    if (!this.password) return;
    if (this.isActive) return;

    try {
      this.isActive = true;
      let success = await this.loginService.loginConsumerUser(
        this.username,
        this.password,
      );
      if (success) {
        this.activeModal.close({
          value: 'success',
        });
        return;
      }
      this.showError = true;
    } finally {
      this.isActive = false;
    }
  }
}

/*
    export module ConsumerLoginVm {
        export type SuccessType = {
            value: "success";
        }
        export type NewUserType = {
            value: "newUser";
            username: string;
            password: string;
        }
        export type ReturnType = SuccessType | NewUserType;
    }*/
