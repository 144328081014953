import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PickableVm } from './PickableVm';

@NgModule({
  declarations: [PickableVm],
  exports: [PickableVm],
  imports: [BrowserModule, CommonModule, FormsModule, NgbModule],
  providers: [],
})
export class PickableModule {}
