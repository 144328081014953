import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/Product';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
type DescriptionDict = { [productNo: string]: Description };

@Component({
  selector: 'consumer-door-profile-vm',
  templateUrl: './consumerDoorProfile.html',
  styleUrls: ['consumerDoorProfile.scss'],
})
export class ConsumerDoorProfileVm extends BaseVm implements OnInit {
  public doorProfiles!: Pickable<Client.Product | null>[];

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.doorProfiles = this.doorProfilesParameter.map((dp) => ({
      ...dp,
      isSelected: false,
    }));
    this.doorDescriptionsByProductNo = this.getProfileDescriptions(
      this.doorProfiles,
    );
  }

  public currentProfile!: Client.Product | null;
  public doorProfilesParameter!: Pickable<Client.Product | null>[];

  public select() {
    this.activeModal.close({
      selection: this.currentProfile,
    });
  }

  public cancel() {
    this.activeModal.dismiss('user cancelled');
  }

  public isSelected(pickable: Pickable<Client.Product | null>): boolean {
    return pickable.item === this.currentProfile;
  }

  public get currentProfileDescription(): Description {
    let productNo = this.getProductNo(this.currentProfile);
    return this.doorDescriptionsByProductNo[productNo];
  }
  private getProductNo(profile: Client.Product | null): string {
    let productNo = profile ? profile.ProductNo : 'null';
    return productNo;
  }

  private getProfileDescriptions(
    profiles: Pickable<Client.Product | null>[],
  ): DescriptionDict {
    let result: DescriptionDict = {};
    for (let profile of profiles) {
      let productNo = this.getProductNo(profile.item);
      let description = this.getProfileDescription(productNo);
      result[productNo] = description;
    }
    return result;
  }

  private getProfileDescription(productNo: string): Description {
    let bigImagePath = this.consumerFile('doors/', productNo, '/big_image.jpg');
    let translationKeyBase = 'consumer_door_text_' + productNo + '_';

    let loremIpsum =
      "\n\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.\n\nIt has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
    return {
      productNo,
      header: this.translate(
        translationKeyBase + 'header',
        'Door ' + productNo,
      ),
      longText: this.translate(
        translationKeyBase + 'longText',
        'Long descriptive text for door ' + productNo + loremIpsum,
      ),
      bigImageUrl: bigImagePath,
    };
  }

  private doorDescriptionsByProductNo!: DescriptionDict;
}

export interface Description {
  productNo: string | null;
  header: string;
  longText: string;
  bigImageUrl: string;
}
