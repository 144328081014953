<table>
  <tbody>
    <tr
      [attr.title]="'Project_detail_title_Name' | translate: 'Name'"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="'Project_detail_title_Name' | translate: 'Name'"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Name"
          name="DetailObjectName"
          type="text"
          required
          [(ngModel)]="detailObject.Name"
          [attr.placeholder]="'Project_detail_placeholder_Name' | translate: 'Name'"
        />
      </td>
    </tr>

    <tr
      [attr.title]="'Project_detail_title_Description' | translate: 'Description'"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="'Project_detail_title_Description' | translate: 'Description'"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Description"
          name="Description"
          type="text"
          [(ngModel)]="detailObject.Description"
          [attr.placeholder]="'Project_detail_placeholder_Description' | translate: 'Description'"
        />
      </td>
    </tr>
  </tbody>
</table>
