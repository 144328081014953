import { Vec2d } from 'app/ts/Interface_DTO_Draw';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { Line } from './measurement-length-calculations';

export class Measurement {
  constructor(private _data: MeasurementData) {}

  public get data(): MeasurementData {
    return this._data;
  }

  public get id(): MeasurementId {
    return this._data.id;
  }

  public get posX(): number {
    return this._data.posX;
  }

  public get posY(): number {
    return this._data.posY;
  }

  public get line(): Line {
    return this._data.line;
  }

  public get horizontal(): boolean {
    return this._data.horizontal;
  }

  public get length(): number {
    if (!this.line.start || !this.line.end) return 0;

    return VectorHelper.dist(this.line.start, this.line.end);
  }

  public get withinFloorPlan(): boolean {
    return this.line.start != null && this.line.end != null;
  }
}

export interface MeasurementData {
  id: MeasurementId;
  posX: number;
  posY: number;
  line: Line;
  horizontal: boolean;
}

export type MeasurementId = number & { _measurementId: never };
