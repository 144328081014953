<div class="editor-all editor-backing no-right-menu" *ngIf="editorType.ready">
  <editor-topbar
    [vm]="this"
    [selection]="'backing'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>
  <div class="tool-menu left-tool-menu">
    <div class="panel">
      <h4
        [textContent]="translate('editor_backing_backing_type', 'Backing Type')"
      ></h4>
      <pickable
        (click)="selectBackingType()"
        [pickable]="backingType"
        class="backing-type modal-opener"
      ></pickable>
    </div>
  </div>

  <div class="ribbon-menu">
    <div
      *ngIf="backingType.item"
      class="ribbon-block checkbox-ribbon-block visibility"
    >
      <label>
        <input type="checkbox" [(ngModel)]="showRulers" />
        {{ translate("editor_label_show_rulers", "Rulers") }}
      </label>
    </div>

    <div *ngIf="backingType.item" class="ribbon-block material">
      <pickable
        (click)="selectBackingMaterial()"
        [pickable]="backingMaterial"
        class="material modal-opener"
      ></pickable>
    </div>

    <div
      *ngIf="backingType.item"
      class="ribbon-block checkbox-ribbon-block visibility"
    >
      <label>
        <input type="checkbox" [(ngModel)]="heightReduction" />
        <span
          [textContent]="
            translate('editor_backing_height_reduction', 'Height Reduction')
          "
        ></span>
      </label>
    </div>

    <div class="flex-spacer"></div>
  </div>
  <backing-image
    class="editor-center center-svg"
    [showRulers]="showRulers"
    [cabinetSection]="cabinetSection!"
    (backingArea)="onBackingAreaClick($event)"
  ></backing-image>
  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
