import { Injectable } from '@angular/core';
import { Side } from 'app/ts/clientDto/Enums';
import { Rectangle, Vec2d } from 'app/ts/Interface_DTO_Draw';
import { WallSection } from 'app/ts/Interface_DTO_FloorPlan';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Module } from './module';
import { PartitionPlanCommandService } from './partition-plan-command.service';
import { PartitionPlanGeometryQueryService } from './partition-plan-geometry-query.service';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import { PartitionRailService } from './partition-rail.service';
import { CornerProfile, Profile, StartStopProfile, TProfile } from './profile';
import { Rail } from './rail';
import { Section, SectionId } from './section';

@Injectable({
  providedIn: 'root',
})
export class PartitionSnapService {
  private static readonly snapDistance = 200;

  constructor(
    private commands: PartitionPlanCommandService,
    private query: PartitionPlanQueryService,
    private queryGeometry: PartitionPlanGeometryQueryService,
  ) {}

  public calculateFloorPlanWallSnapRegions(
    leftWalls: WallSection[],
    rightWalls: WallSection[],
    topWalls: WallSection[],
    bottomWalls: WallSection[],
  ): SnapRegion[] {
    let regions: SnapRegion[] = [];

    for (let wall of leftWalls) {
      regions.push({
        rect: {
          X: wall.End.X,
          Y: wall.End.Y,
          Width: PartitionSnapService.snapDistance,
          Height: wall.Begin.Y - wall.End.Y,
        },
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          let section = this.query.getSection(snappedSectionId);
          if (section.rotation != 0 && section.rotation != 180)
            // must be horizontal
            return null;

          let snapPos = {
            X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
              snappedSectionId,
              wall.Begin.X,
            ),
            Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
              snappedSectionId,
              mousePosition.Y -
                this.queryGeometry.getSectionDepth(snappedSectionId) / 2,
            ),
          };
          return {
            snapType: PartitionSnapType.Wall,
            snapPosition: snapPos,
            snappedSectionId: snappedSectionId,
          };
        },
      });
    }

    for (let wall of rightWalls) {
      regions.push({
        rect: {
          X: wall.Begin.X - PartitionSnapService.snapDistance,
          Y: wall.Begin.Y,
          Width: PartitionSnapService.snapDistance,
          Height: wall.End.Y - wall.Begin.Y,
        },
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          let section = this.query.getSection(snappedSectionId);
          if (section.rotation != 0 && section.rotation != 180)
            // must be horizontal
            return null;

          let snapPos = {
            X: this.queryGeometry.calculateSectionPosXFromGlobalRight(
              snappedSectionId,
              wall.Begin.X,
            ),
            Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
              snappedSectionId,
              mousePosition.Y -
                this.queryGeometry.getSectionDepth(snappedSectionId) / 2,
            ),
          };
          return {
            snapType: PartitionSnapType.Wall,
            snapPosition: snapPos,
            snappedSectionId: snappedSectionId,
          };
        },
      });
    }

    for (let wall of topWalls) {
      regions.push({
        rect: {
          X: wall.Begin.X,
          Y: wall.Begin.Y,
          Width: wall.End.X - wall.Begin.X,
          Height: PartitionSnapService.snapDistance,
        },
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          let section = this.query.getSection(snappedSectionId);
          if (section.rotation != 90 && section.rotation != 270)
            // must be vertical
            return null;

          let snapPos = {
            X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
              snappedSectionId,
              mousePosition.X -
                this.queryGeometry.getSectionDepth(snappedSectionId) / 2,
            ),
            Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
              snappedSectionId,
              wall.Begin.Y,
            ),
          };
          return {
            snapType: PartitionSnapType.Wall,
            snapPosition: snapPos,
            snappedSectionId: snappedSectionId,
          };
        },
      });
    }

    for (let wall of bottomWalls) {
      regions.push({
        rect: {
          X: wall.End.X,
          Y: wall.End.Y - PartitionSnapService.snapDistance,
          Width: wall.Begin.X - wall.End.X,
          Height: PartitionSnapService.snapDistance,
        },
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          let section = this.query.getSection(snappedSectionId);
          if (section.rotation != 90 && section.rotation != 270)
            // must be vertical
            return null;

          let snapPos = {
            X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
              snappedSectionId,
              mousePosition.X -
                this.queryGeometry.getSectionDepth(snappedSectionId) / 2,
            ),
            Y: this.queryGeometry.calculateSectionPosYFromGlobalBottom(
              snappedSectionId,
              wall.Begin.Y,
            ),
          };
          return {
            snapType: PartitionSnapType.Wall,
            snapPosition: snapPos,
            snappedSectionId: snappedSectionId,
          };
        },
      });
    }

    return regions;
  }

  public calculateSectionRelativeBottomSnapRegions(
    sectionId: SectionId,
  ): SnapRegion[] {
    let section = this.query.getSection(sectionId);

    // sides and directions are seen from 0 rotation perspective
    let leftCornerRect = this.queryGeometry.getSectionRelativeBottomRect(
      sectionId,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      -PartitionSnapService.snapDistance / 2,
      -this.queryGeometry.getSectionDepth(sectionId) / 2,
    );
    let bottomRect = this.queryGeometry.getSectionRelativeBottomRect(
      sectionId,
      section.width - PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance / 2,
      -this.queryGeometry.getSectionDepth(sectionId) / 2,
    );
    let rightCornerRect = this.queryGeometry.getSectionRelativeBottomRect(
      sectionId,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      section.width - PartitionSnapService.snapDistance / 2,
      -this.queryGeometry.getSectionDepth(sectionId) / 2,
    );

    return [
      {
        rect: leftCornerRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (this.query.hasTProfile(section.id, Side.Left)) return null;

          let profile = this.query.getSectionLeftProfile(section.id);
          if (
            profile instanceof CornerProfile &&
            profile.rightSection.sectionId == section.id
          ) {
            return null;
          }

          if (
            this.queryGeometry.getDistanceFromRelativeBottomSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          if (
            section.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(section.id)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let bottomRelativePoint =
            this.queryGeometry.getSectionRelativeBottomPoint(
              section.id,
              Rail.railOverhang,
              -CornerProfile.size - Rail.railOverhang * 2,
            );
          let snapType = PartitionSnapType.Corner;

          if (this.query.hasCornerProfile(section.id, Side.Left)) {
            bottomRelativePoint =
              this.queryGeometry.getSectionRelativeBottomPoint(
                section.id,
                -CornerProfile.size - Module.elementSpacing,
                -CornerProfile.size - Module.elementSpacing,
              );
            snapType = PartitionSnapType.CornerToTPiece;
          }

          let snapPos = this.relativeBottomPointToSectionPos(
            section.rotation,
            snapSection as Section,
            bottomRelativePoint,
          );
          return {
            snapType: snapType,
            snapPosition: snapPos,
            section1: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Left
                  : Side.Right,
            },
            section2: {
              id: section.id,
              snapSide: Side.Left,
            },
            snappedToSide: SnappedSide.Bottom,
            snappedSectionId: snappedSectionId,
          };
        },
      },
      {
        rect: bottomRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
          mouseOffset: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (
            this.queryGeometry.getDistanceFromRelativeBottomSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - TProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let movingSection = this.query.getSection(snappedSectionId);
          let xOffset = this.calculateTPieceOffset(
            section as Section,
            movingSection as Section,
            mousePosition,
            mouseOffset,
          );

          let snapRanges = this.calculateValidSnapRanges(section as Section);
          let currentRange = snapRanges.find(
            (range) => range.minX < xOffset && xOffset < range.maxX,
          );
          if (!currentRange) {
            // Not within range, find closest
            let lowerRange = snapRanges.find((range) => range.maxX < xOffset);
            let upperRange = snapRanges.find((range) => range.minX > xOffset);

            let lowerDist = lowerRange ? xOffset - lowerRange.maxX : 999999999;
            let upperDist = upperRange ? upperRange.minX - xOffset : 999999999;

            currentRange = lowerDist < upperDist ? lowerRange : upperRange;

            if (!currentRange)
              // no ranges exist
              return null;
          }

          let clampedOffset = ObjectHelper.clamp(
            currentRange.minX,
            xOffset,
            currentRange.maxX,
          );
          let bottomRelativePoint =
            this.queryGeometry.getSectionRelativeBottomPoint(
              section.id,
              clampedOffset,
              -TProfile.size - Rail.railOverhang * 2,
            );

          let snapPos = this.relativeBottomPointToSectionPos(
            section.rotation,
            movingSection as Section,
            bottomRelativePoint,
          );
          return {
            snapType: PartitionSnapType.TPiece,
            snapPosition: snapPos,
            section1: {
              id: section.id,
              snapSide: Side.Right,
            },
            section2: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Left
                  : Side.Right,
            },
            offset: clampedOffset,
            snappedToSide: SnappedSide.Bottom,
            snappedSectionId: snappedSectionId,
          };
        },
      },
      {
        rect: rightCornerRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (this.query.hasTProfile(section.id, Side.Right)) return null;

          let profile = this.query.getSectionRightProfile(section.id);
          if (
            profile instanceof CornerProfile &&
            profile.leftSection.sectionId == section.id
          ) {
            return null;
          }

          if (
            this.queryGeometry.getDistanceFromRelativeBottomSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          if (
            section.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(section.id)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let bottomRelativePoint =
            this.queryGeometry.getSectionRelativeBottomPoint(
              section.id,
              section.width - CornerProfile.size - Rail.railOverhang,
              -CornerProfile.size - Rail.railOverhang * 2,
            );
          let snapType = PartitionSnapType.Corner;

          if (this.query.hasCornerProfile(section.id, Side.Right)) {
            bottomRelativePoint =
              this.queryGeometry.getSectionRelativeBottomPoint(
                section.id,
                section.width + Module.elementSpacing,
                -CornerProfile.size - Rail.railOverhang,
              );
            snapType = PartitionSnapType.CornerToTPiece;
          }

          let snapPos = this.relativeBottomPointToSectionPos(
            section.rotation,
            snapSection as Section,
            bottomRelativePoint,
          );
          return {
            snapType: snapType,
            snapPosition: snapPos,
            section1: {
              id: section.id,
              snapSide: Side.Right,
            },
            section2: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Left
                  : Side.Right,
            },
            snappedToSide: SnappedSide.Bottom,
            snappedSectionId: snappedSectionId,
          };
        },
      },
    ];
  }

  private relativeBottomPointToSectionPos(
    sectionRotation: number,
    movingSection: Section,
    relativePoint: Vec2d,
  ) {
    switch (sectionRotation) {
      case 90:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalRight(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      case 180:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalRight(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalBottom(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      case 270:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalBottom(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      default:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
            movingSection.id,
            relativePoint.Y,
          ),
        };
    }
  }

  public calculateSectionRelativeTopSnapRegions(
    sectionId: SectionId,
  ): SnapRegion[] {
    let section = this.query.getSection(sectionId);

    // sides and directions are seen from 0 rotation perspective
    let leftCornerRect = this.queryGeometry.getSectionRelativeTopRect(
      sectionId,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      -PartitionSnapService.snapDistance / 2,
      this.queryGeometry.getSectionDepth(sectionId) / 2,
    );
    let topRect = this.queryGeometry.getSectionRelativeTopRect(
      sectionId,
      section.width - PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance / 2,
      this.queryGeometry.getSectionDepth(sectionId) / 2,
    );
    let rightCornerRect = this.queryGeometry.getSectionRelativeTopRect(
      sectionId,
      PartitionSnapService.snapDistance,
      PartitionSnapService.snapDistance,
      section.width - PartitionSnapService.snapDistance / 2,
      this.queryGeometry.getSectionDepth(sectionId) / 2,
    );

    return [
      {
        rect: leftCornerRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (this.query.hasTProfile(section.id, Side.Left)) return null;

          let profile = this.query.getSectionLeftProfile(section.id);
          if (
            profile instanceof CornerProfile &&
            profile.leftSection.sectionId == section.id
          ) {
            return null;
          }

          if (
            this.queryGeometry.getDistanceFromRelativeTopSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          if (
            section.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(section.id)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let topRelativePoint = this.queryGeometry.getSectionRelativeTopPoint(
            section.id,
            Rail.railOverhang,
            CornerProfile.size + Rail.railOverhang * 2,
          );
          let snapType = PartitionSnapType.Corner;

          if (this.query.hasCornerProfile(section.id, Side.Left)) {
            topRelativePoint = this.queryGeometry.getSectionRelativeTopPoint(
              section.id,
              -CornerProfile.size - Module.elementSpacing,
              CornerProfile.size + Rail.railOverhang,
            );
            snapType = PartitionSnapType.CornerToTPiece;
          }

          let snapPos = this.relativeTopPointToSectionPos(
            section.rotation,
            snapSection as Section,
            topRelativePoint,
          );
          return {
            snapType: snapType,
            snapPosition: snapPos,
            section1: {
              id: section.id,
              snapSide: Side.Left,
            },
            section2: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Right
                  : Side.Left,
            },
            snappedToSide: SnappedSide.Top,
            snappedSectionId: snappedSectionId,
          };
        },
      },
      {
        rect: topRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
          mouseOffset: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (
            this.queryGeometry.getDistanceFromRelativeTopSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - TProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let movingSection = this.query.getSection(snappedSectionId);
          let xOffset = this.calculateTPieceOffset(
            section as Section,
            movingSection as Section,
            mousePosition,
            mouseOffset,
          );

          let snapRanges = this.calculateValidSnapRanges(section as Section);
          let currentRange = snapRanges.find(
            (range) => range.minX < xOffset && xOffset < range.maxX,
          );
          if (!currentRange) {
            // Not within range, find closest
            let lowerRange = snapRanges.find((range) => range.maxX < xOffset);
            let upperRange = snapRanges.find((range) => range.minX > xOffset);

            let lowerDist = lowerRange ? xOffset - lowerRange.maxX : 999999999;
            let upperDist = upperRange ? upperRange.minX - xOffset : 999999999;

            currentRange = lowerDist < upperDist ? lowerRange : upperRange;

            if (!currentRange)
              // no ranges exist
              return null;
          }

          let clampedOffset = ObjectHelper.clamp(
            currentRange.minX,
            xOffset,
            currentRange.maxX,
          );
          let topRelativePoint = this.queryGeometry.getSectionRelativeTopPoint(
            section.id,
            clampedOffset,
            TProfile.size + Rail.railOverhang * 2,
          );

          let snapPos = this.relativeTopPointToSectionPos(
            section.rotation,
            movingSection as Section,
            topRelativePoint,
          );
          return {
            snapType: PartitionSnapType.TPiece,
            snapPosition: snapPos,
            section1: {
              id: section.id,
              snapSide: Side.Right,
            },
            section2: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Right
                  : Side.Left,
            },
            offset: clampedOffset,
            snappedToSide: SnappedSide.Top,
            snappedSectionId: snappedSectionId,
          };
        },
      },
      {
        rect: rightCornerRect,
        calculateSectionPosition: (
          snappedSectionId: SectionId,
          mousePosition: Vec2d,
        ) => {
          if (
            this.queryGeometry.isSectionsParallel(snappedSectionId, section.id)
          )
            return null;

          if (this.query.hasTProfile(section.id, Side.Right)) return null;

          let profile = this.query.getSectionRightProfile(section.id);
          if (
            profile instanceof CornerProfile &&
            profile.rightSection.sectionId == section.id
          ) {
            return null;
          }

          if (
            this.queryGeometry.getDistanceFromRelativeTopSide(sectionId) <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          if (
            section.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(section.id)
          )
            return null;

          let snapSection = this.query.getSection(snappedSectionId);
          if (
            snapSection.width - CornerProfile.size - Module.elementSpacing <
            this.query.getSectionMinWidth(snappedSectionId)
          )
            return null;

          let topRelativePoint = this.queryGeometry.getSectionRelativeTopPoint(
            section.id,
            section.width - Module.fullJointWidth - Rail.railOverhang,
            CornerProfile.size + Rail.railOverhang * 2,
          );
          let snapType = PartitionSnapType.Corner;

          if (this.query.hasCornerProfile(section.id, Side.Right)) {
            topRelativePoint = this.queryGeometry.getSectionRelativeTopPoint(
              section.id,
              section.width + Module.elementSpacing,
              CornerProfile.size + Rail.railOverhang,
            );
            snapType = PartitionSnapType.CornerToTPiece;
          }

          let snapPos = this.relativeTopPointToSectionPos(
            section.rotation,
            snapSection as Section,
            topRelativePoint,
          );
          return {
            snapType: snapType,
            snapPosition: snapPos,
            section1: {
              id: snappedSectionId,
              snapSide:
                this.queryGeometry.getRotationDiff(
                  snappedSectionId,
                  section.id,
                ) == 90
                  ? Side.Right
                  : Side.Left,
            },
            section2: {
              id: section.id,
              snapSide: Side.Right,
            },
            snappedToSide: SnappedSide.Top,
            snappedSectionId: snappedSectionId,
          };
        },
      },
    ];
  }

  private relativeTopPointToSectionPos(
    sectionRotation: number,
    movingSection: Section,
    relativePoint: Vec2d,
  ) {
    switch (sectionRotation) {
      case 90:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      case 180:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalRight(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalTop(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      case 270:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalRight(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalBottom(
            movingSection.id,
            relativePoint.Y,
          ),
        };

      default:
        return {
          X: this.queryGeometry.calculateSectionPosXFromGlobalLeft(
            movingSection.id,
            relativePoint.X,
          ),
          Y: this.queryGeometry.calculateSectionPosYFromGlobalBottom(
            movingSection.id,
            relativePoint.Y,
          ),
        };
    }
  }

  private calculateTPieceOffset(
    stationarySection: Section,
    snappedSection: Section,
    mousePosition: Vec2d,
    mouseOffset: Vec2d,
  ): number {
    if (stationarySection.rotation == 0 || stationarySection.rotation == 180) {
      let inverseFactor = stationarySection.rotation == 180 ? -1 : 1;
      let snappedLeftX =
        mousePosition.X -
        (this.queryGeometry.getSectionDepth(snappedSection.id) / 2) *
          inverseFactor;
      return Math.abs(snappedLeftX - stationarySection.posX);
    } else {
      // 90 and 270
      let inverseFactor = stationarySection.rotation == 270 ? -1 : 1;
      let snappedTopY =
        mousePosition.Y -
        (this.queryGeometry.getSectionDepth(snappedSection.id) / 2) *
          inverseFactor;
      return Math.abs(snappedTopY - stationarySection.posY);
    }
  }

  private calculateValidSnapRanges(
    stationarySection: Section,
  ): { minX: number; maxX: number }[] {
    let sectionModules = this.query.getModulesForSection(stationarySection.id);
    let moduleIndex = sectionModules.findIndex((md) => md.isWall);

    let ranges: { minX: number; maxX: number }[] = [];
    while (
      moduleIndex != -1 &&
      moduleIndex < stationarySection.numberOfModules
    ) {
      let currentRange: { minX: number; maxX: number } = {
        minX:
          sectionModules[moduleIndex].posX +
          this.query.getSectionMinWidth(stationarySection.id),
        maxX: 0,
      };

      let endIndex = sectionModules.findIndex(
        (md, idx) => idx > moduleIndex && md.isDoor,
      );

      if (endIndex != -1) {
        endIndex--; // Go one module back
        currentRange.maxX =
          sectionModules[endIndex].posX +
          sectionModules[endIndex].width -
          this.query.getSectionMinWidth(stationarySection.id);
        moduleIndex = sectionModules.findIndex(
          (md, idx) => idx > endIndex && md.isWall,
        );
      } else {
        // reached end of section
        let lastModule = sectionModules[sectionModules.length - 1];
        currentRange.maxX =
          lastModule.posX +
          lastModule.width -
          this.query.getSectionMinWidth(stationarySection.id) -
          Module.fullJointWidth -
          Module.elementSpacing;
        moduleIndex = endIndex;
      }

      if (currentRange.minX < currentRange.maxX) {
        // Check ends do not overlap
        ranges.push(currentRange);
      }
    }

    return ranges;
  }

  public executeSnap(context: SnapContext) {
    if (context.snapType == PartitionSnapType.Corner) {
      this.executeCornerSnap(context);
      this.copyPartitionPropertiesSnap(context);
    } else if (context.snapType == PartitionSnapType.TPiece) {
      this.executeTPieceSnap(context);
      this.copyPartitionPropertiesSnap(context);
    } else if (context.snapType == PartitionSnapType.CornerToTPiece) {
      this.executeCornerToTPieceSnap(context);
      this.copyPartitionPropertiesSnap(context);
    }

    // Wall snappings, needs no extra logic
  }

  public copyPartitionPropertiesSnap(context: SnapContext) {
    let sourceSection =
      context.snappedSectionId == context.section1!.id
        ? context.section2!
        : context.section1!;
    let destinationSection =
      context.snappedSectionId == context.section1!.id
        ? context.section1!
        : context.section2!;
    this.commands.copyPartitionProperties(
      sourceSection.id,
      destinationSection.id,
    );
  }

  private executeCornerSnap(context: SnapContext) {
    let section1 = this.query.getSection(context.section1!.id); // Relative Left
    let section2 = this.query.getSection(context.section2!.id); // Relative Right

    // Delete section 1 start/stop profile
    let section1ProfileToDelete =
      context.section1!.snapSide == Side.Left
        ? this.query.getSectionLeftProfile(context.section1!.id)
        : this.query.getSectionRightProfile(context.section1!.id);
    if (section1ProfileToDelete)
      this.commands.removeProfile(section1ProfileToDelete.id);

    // Delete section 2 start/stop profile
    let section2ProfileToDelete =
      context.section2!.snapSide == Side.Left
        ? this.query.getSectionLeftProfile(context.section2!.id)
        : this.query.getSectionRightProfile(context.section2!.id);
    if (section2ProfileToDelete)
      this.commands.removeProfile(section2ProfileToDelete.id);

    // Add corner profile
    let profilePos =
      context.section1!.snapSide == Side.Left
        ? this.queryGeometry.getModuleRelativeLeftPoint(
            this.query.getHeadModuleForSection(context.section1!.id).id,
            -(CornerProfile.size + Module.elementSpacing),
          )
        : this.queryGeometry.getModuleRelativeRightPoint(
            this.query.getTailModuleForSection(context.section1!.id).id,
            CornerProfile.size + Module.elementSpacing,
          );
    let height = section1.height - Profile.profileRailReduction;
    let rotation = this.getProfileRotation(context.section1!);
    let profileId = this.commands.addCornerProfile(
      context.section1!.id,
      context.section1!.snapSide,
      context.section2!.id,
      context.section2!.snapSide,
      profilePos.X,
      profilePos.Y,
      height,
      rotation,
    );

    for (let section of [context.section1!, context.section2!]) {
      if (section.snapSide == Side.Left) {
        let module = this.query.getHeadModuleForSection(section.id);
        module.references.leftProfileId = profileId;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);

          let jointProfile = this.query.getProfile(
            module.references.leftProfileId,
          );
          let connection = jointProfile.sections.find(
            (secCon) => secCon.sectionId == module.sectionId,
          )!;
          let rotation =
            (connection.side == Side.Left
              ? section.rotation + 180
              : section.rotation) % 360;

          let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
            module.id,
            StartStopProfile.size + Module.elementSpacing,
            Module.fullJointWidth,
          );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeLeftPoint.X,
            relativeLeftPoint.Y,
            height,
            rotation,
          );
        }
      } else {
        let module = this.query.getTailModuleForSection(section.id);
        module.references.rightProfileId = profileId;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);

          let jointProfile = this.query.getProfile(
            module.references.rightProfileId,
          );
          let connection = jointProfile.sections.find(
            (secCon) => secCon.sectionId == module.sectionId,
          )!;
          let rotation =
            (connection.side == Side.Left
              ? section.rotation + 180
              : section.rotation) % 360;

          let relativeRightPoint =
            this.queryGeometry.getModuleRelativeRightPoint(
              module.id,
              -(StartStopProfile.size + Module.elementSpacing),
              -Module.fullJointWidth,
            );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeRightPoint.X,
            relativeRightPoint.Y,
            height,
            rotation,
          );
        }
      }
    }

    // recalculate section lengths - Outer length should stay the same
    let leftSectionExtendFrom = Side.inverse(context.section1!.snapSide);
    this.commands.setSectionWidth(
      section1.id,
      section1.width -
        CornerProfile.size -
        Module.elementSpacing -
        Rail.railOverhang,
      leftSectionExtendFrom,
    );
    let rightSectionExtendFrom = Side.inverse(context.section2!.snapSide);
    this.commands.setSectionWidth(
      section2.id,
      section2.width -
        CornerProfile.size -
        Module.elementSpacing -
        Rail.railOverhang,
      rightSectionExtendFrom,
    );

    this.commands.updateProfilePositionAndRotation(section1.id);
    this.commands.updateProfilePositionAndRotation(section2.id);

    let destinationSection =
      context.snappedSectionId == context.section1!.id
        ? context.section1!
        : context.section2!;
    this.commands.ensureSectionWithinFloorPlan(destinationSection.id);
  }

  private getProfileRotation(context: SectionContext): number {
    let section = this.query.getSection(context.id);
    let rotation =
      context.snapSide == Side.Left ? section.rotation : section.rotation + 180;
    return rotation % 360;
  }

  private executeTPieceSnap(context: SnapContext) {
    let section1 = this.query.getSection(context.section1!.id); // Relative Left
    let section2 = this.query.getSection(context.section2!.id); // Relative Middle
    let leftSectionProfile = this.query.getSectionLeftProfile(
      section1.id,
    ) as Profile;
    let rightSectionProfile = this.query.getSectionRightProfile(
      section1.id,
    ) as Profile;

    // Delete section 1 start/stop profile
    let section1ProfileToDelete =
      context.section1!.snapSide == Side.Left
        ? leftSectionProfile
        : rightSectionProfile;
    if (
      section1ProfileToDelete &&
      !this.query.isJointProfile(section1ProfileToDelete.id)
    )
      this.commands.removeProfile(section1ProfileToDelete.id);

    // Delete section 2 start/stop profile
    let section2ProfileToDelete =
      context.section2!.snapSide == Side.Left
        ? this.query.getSectionLeftProfile(context.section2!.id)
        : this.query.getSectionRightProfile(context.section2!.id);
    if (section2ProfileToDelete)
      this.commands.removeProfile(section2ProfileToDelete.id);

    // Add new section
    let correction = section1.isVertical && !section1.isInverted ? -1 : 0;
    let rightSectionPos =
      context.section1!.snapSide == Side.Right
        ? this.queryGeometry.getSectionRelativeTopPoint(
            section1.id,
            context.offset! + Module.fullJointWidth + Module.elementSpacing,
            Rail.railOverhang + correction,
          )
        : { X: section1.posX, Y: section1.posY + Rail.railOverhang };
    let newSection = this.commands.addSection(
      rightSectionPos.X,
      rightSectionPos.Y,
      section1.height,
      section1.rotation,
      context.section1!.snapSide == Side.Left &&
        !this.query.isJointProfile(leftSectionProfile.id),
      context.section1!.snapSide == Side.Right &&
        !this.query.isJointProfile(rightSectionProfile.id),
    );
    this.commands.copyPartitionProperties(section1.id, newSection.id);

    // copy profile reference to new section, if corner or tpiece
    if (
      context.section1!.snapSide == Side.Right &&
      this.query.isJointProfile(rightSectionProfile.id)
    ) {
      let rightModuleSection1 = this.query.getTailModuleForSection(section1.id);
      let rightModuleNewSection = this.query.getTailModuleForSection(
        newSection.id,
      );

      // Remove start/stop on profile from door
      rightSectionProfile.sections.forEach((secCon) => {
        if (secCon.sectionId != context.section1?.id) return;

        if (secCon.profileId) this.commands.removeProfile(secCon.profileId);
      });

      rightModuleNewSection.references.rightProfileId =
        rightModuleSection1.references.rightProfileId;
      let profile = this.query.getProfile(
        rightModuleNewSection.references.rightProfileId!,
      ) as TProfile;
      profile.sections = profile.sections.map((secCon) =>
        secCon.sectionId == section1.id
          ? { sectionId: newSection.id, side: secCon.side }
          : secCon,
      );
    }
    if (
      context.section1!.snapSide == Side.Left &&
      this.query.isJointProfile(leftSectionProfile.id)
    ) {
      let leftModuleSection1 = this.query.getHeadModuleForSection(section1.id);
      let leftModuleNewSection = this.query.getHeadModuleForSection(
        newSection.id,
      );

      // Remove start/stop on profile from door
      leftSectionProfile.sections.forEach((secCon) => {
        if (secCon.sectionId != context.section1?.id) return;

        if (secCon.profileId) this.commands.removeProfile(secCon.profileId);
      });

      leftModuleNewSection.references.leftProfileId =
        leftModuleSection1.references.leftProfileId;
      let profile = this.query.getProfile(
        leftModuleNewSection.references.leftProfileId!,
      ) as TProfile;
      profile.sections = profile.sections.map((secCon) =>
        secCon.sectionId == section1.id
          ? { sectionId: newSection.id, side: secCon.side }
          : secCon,
      );
    }

    // Add T Profile
    let profilePos =
      context.section1!.snapSide == Side.Right
        ? this.queryGeometry.getSectionRelativeTopPoint(
            section1.id,
            context.offset! + Module.fullJointWidth,
          )
        : this.queryGeometry.getSectionRelativeBottomPoint(
            section1.id,
            context.offset! - Module.fullJointWidth,
          );
    let height = section1.height - Profile.profileRailReduction;
    let rotation = this.getProfileRotation(context.section2!);

    let leftSectionId =
      context.snappedToSide! == SnappedSide.Bottom
        ? context.section1!.id
        : newSection.id;
    let rightSectionId =
      context.snappedToSide! == SnappedSide.Bottom
        ? newSection.id
        : context.section1!.id;
    let leftSectionSide =
      context.snappedToSide! == SnappedSide.Bottom
        ? context.section1!.snapSide
        : Side.inverse(context.section1!.snapSide);
    let rightSectionSide =
      context.snappedToSide! == SnappedSide.Bottom
        ? Side.inverse(context.section1!.snapSide)
        : context.section1!.snapSide;

    let profileId = this.commands.addTProfile(
      leftSectionId,
      leftSectionSide,
      context.section2!.id,
      context.section2!.snapSide,
      rightSectionId,
      rightSectionSide,
      profilePos.X,
      profilePos.Y,
      height,
      rotation,
    );

    // Update profile references
    let rightSectionContext = {
      id: newSection.id,
      snapSide:
        context.section1!.snapSide == Side.Left ? Side.Right : Side.Left,
    };
    for (let section of [
      context.section1!,
      context.section2!,
      rightSectionContext,
    ]) {
      if (section.snapSide == Side.Left) {
        let module = this.query.getHeadModuleForSection(section.id);
        module.references.leftProfileId = profileId;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);

          let jointProfile = this.query.getProfile(
            module.references.leftProfileId,
          );
          let connection = jointProfile.sections.find(
            (secCon) => secCon.sectionId == module.sectionId,
          )!;
          let rotation =
            (connection.side == Side.Left
              ? section.rotation + 180
              : section.rotation) % 360;

          let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
            module.id,
            StartStopProfile.size + Module.elementSpacing,
            Module.fullJointWidth,
          );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeLeftPoint.X,
            relativeLeftPoint.Y,
            height,
            rotation,
          );
        }
      } else {
        let module = this.query.getTailModuleForSection(section.id);
        module.references.rightProfileId = profileId;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);

          let jointProfile = this.query.getProfile(
            module.references.rightProfileId,
          );
          let connection = jointProfile.sections.find(
            (secCon) => secCon.sectionId == module.sectionId,
          )!;
          let rotation =
            (connection.side == Side.Left
              ? section.rotation + 180
              : section.rotation) % 360;

          let relativeRightPoint =
            this.queryGeometry.getModuleRelativeRightPoint(
              module.id,
              -(StartStopProfile.size + Module.elementSpacing),
              -Module.fullJointWidth,
            );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeRightPoint.X,
            relativeRightPoint.Y,
            height,
            rotation,
          );
        }
      }
    }

    // Update Widths and position
    let section1Width = section1.width - Module.fullJointWidth;
    let section1ExtendFrom = Side.inverse(context.section1!.snapSide);
    this.commands.setSectionWidth(
      section1.id,
      context.offset! - Module.elementSpacing,
      section1ExtendFrom,
    );
    let newSectionExtendFrom = context.section1!.snapSide;
    this.commands.setSectionWidth(
      newSection.id,
      section1Width - context.offset! - Module.elementSpacing,
      newSectionExtendFrom,
    );

    let section2ExtendFrom = Side.inverse(context.section2!.snapSide);
    this.commands.setSectionWidth(
      section2.id,
      section2.width -
        TProfile.size -
        Module.elementSpacing -
        Rail.railOverhang,
      section2ExtendFrom,
    );

    this.commands.updateSectionPosition(
      section2.id,
      section2.posX,
      section2.posY,
    );
    this.commands.updateSectionPosition(
      newSection.id,
      rightSectionPos.X,
      rightSectionPos.Y,
    );
    this.commands.updateProfilePositionAndRotation(context.section1!.id);
    this.commands.updateProfilePositionAndRotation(context.section2!.id);
    this.commands.updateProfilePositionAndRotation(newSection.id);

    let destinationSection =
      context.snappedSectionId == context.section1!.id
        ? context.section1!
        : context.section2!;
    this.commands.ensureSectionWithinFloorPlan(destinationSection.id);
  }

  public executeCornerToTPieceSnap(context: SnapContext) {
    let section1 = this.query.getSection(this.query.selectedSection!.id);
    let section2 =
      context.section1!.id == section1.id
        ? this.query.getSection(context.section2!.id)
        : this.query.getSection(context.section1!.id);
    let sectionBool = section2.id == context.section1!.id;

    if (sectionBool) {
      let contextHolder = context.section1;
      context.section1 = context.section2;
      context.section2 = contextHolder;
    }

    let profile =
      context.section2!.snapSide == Side.Left
        ? this.query.getSectionLeftProfile(section2.id)
        : this.query.getSectionRightProfile(section2.id);
    let section3 = this.query.getSection(
      profile?.sections.find((s) => s.sectionId != section2.id)?.sectionId!,
    );

    // Delete Start/Stop Profile & CornerProfile
    let section1ProfileToDelete =
      context.section1!.snapSide == Side.Left
        ? this.query.getSectionLeftProfile(section1.id)
        : this.query.getSectionRightProfile(section1.id);
    if (profile && section1ProfileToDelete) {
      this.commands.removeProfile(section1ProfileToDelete.id);
      this.commands.removeProfile(profile.id);
    }

    // Add TProfile
    let height = section1.height - Profile.profileRailReduction;
    let rotation = this.getProfileRotation(context.section2!);

    let leftSectionId =
      context.snappedToSide! == SnappedSide.Bottom ? section1.id : section3.id;
    let rightSectionId =
      context.snappedToSide! == SnappedSide.Bottom ? section3.id : section1.id;
    let leftSectionSide =
      context.snappedToSide! == SnappedSide.Bottom
        ? context.section1!.snapSide
        : Side.inverse(context.section1!.snapSide);
    let rightSectionSide =
      context.snappedToSide! == SnappedSide.Bottom
        ? Side.inverse(context.section1!.snapSide)
        : context.section1!.snapSide;

    if (
      (sectionBool && context.snappedToSide == SnappedSide.Bottom) ||
      (!sectionBool && context.snappedToSide == SnappedSide.Top)
    ) {
      let sectionHolder = leftSectionId;
      leftSectionId = rightSectionId;
      rightSectionId = sectionHolder;
    }

    let profileId = this.commands.addTProfile(
      leftSectionId,
      leftSectionSide,
      context.section2!.id,
      context.section2!.snapSide,
      rightSectionId,
      rightSectionSide,
      0,
      0,
      height,
      rotation,
    );

    // Update profile references
    let contextSection3 = {
      id: section3.id,
      snapSide:
        context.section1!.snapSide == Side.Left ? Side.Right : Side.Left,
    };
    if (section1.rotation != section3.rotation)
      contextSection3.snapSide =
        context.section1!.snapSide == Side.Left ? Side.Left : Side.Right;
    for (let section of [
      context.section1!,
      context.section2!,
      contextSection3,
    ]) {
      if (section.snapSide == Side.Left) {
        let module = this.query.getHeadModuleForSection(section.id);
        module.references.leftProfileId = profileId;

        let jointProfile = this.query.getProfile(
          module.references.leftProfileId,
        );
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == module.sectionId,
        )!;
        connection.side = section.snapSide;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);
          let rotation =
            (connection.side == Side.Left
              ? section.rotation + 180
              : section.rotation) % 360;

          let relativeLeftPoint = this.queryGeometry.getModuleRelativeLeftPoint(
            module.id,
            StartStopProfile.size + Module.elementSpacing,
            Module.fullJointWidth,
          );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeLeftPoint.X,
            relativeLeftPoint.Y,
            height,
            rotation,
          );
        }
      } else {
        let module = this.query.getTailModuleForSection(section.id);
        module.references.rightProfileId = profileId;

        let jointProfile = this.query.getProfile(
          module.references.rightProfileId,
        );
        let connection = jointProfile.sections.find(
          (secCon) => secCon.sectionId == module.sectionId,
        )!;
        connection.side = section.snapSide;

        if (module.isDoor) {
          // Insert start stop on joint
          let section = this.query.getSection(module.sectionId);
          let rotation = section.rotation % 360;

          let relativeRightPoint =
            this.queryGeometry.getModuleRelativeRightPoint(
              module.id,
              -(StartStopProfile.size + Module.elementSpacing),
              -Module.fullJointWidth,
            );
          connection.profileId = this.commands.addStartStopProfile(
            module.sectionId,
            Side.Right,
            relativeRightPoint.X,
            relativeRightPoint.Y,
            height,
            rotation,
          );
        }
      }
    }

    // Update Widths and position
    let leftSectionExtendFrom = Side.inverse(context.section1!.snapSide);
    this.commands.setSectionWidth(
      section1.id,
      section1.width - CornerProfile.size - Module.elementSpacing,
      leftSectionExtendFrom,
    );

    this.commands.updateProfilePositionAndRotation(section1.id);
    this.commands.updateProfilePositionAndRotation(section2.id);

    let destinationSection =
      context.snappedSectionId == context.section1!.id
        ? context.section1!
        : context.section2!;
    this.commands.ensureSectionWithinFloorPlan(destinationSection.id);
  }
}

export interface SnapRegion {
  rect: Rectangle;
  calculateSectionPosition: (
    snappedSectionId: SectionId,
    mousePosition: Vec2d,
    mouseOffset: Vec2d,
  ) => SnapContext | null;
}

export enum PartitionSnapType {
  Wall,
  Corner,
  TPiece,
  CornerToTPiece,
}

export enum SnappedSide {
  Bottom,
  Top,
}

export interface SnapContext {
  snapType: PartitionSnapType;
  section1?: SectionContext;
  section2?: SectionContext;
  snapPosition: Vec2d; // used for display
  offset?: number; //Used for T-snap
  snappedToSide?: SnappedSide;
  snappedSectionId: SectionId;
}

interface SectionContext {
  id: SectionId;
  snapSide: Side;
}
