import { FloorPlan, Material, Product } from 'app/ts/clientDto';
import { PartitionPlan } from '../partition-plan-data.service';
import { SectionId } from '../section';
import { RailId } from '../rail';

export class FillingsViewSection {
  constructor(
    private _id: SectionId,

    private _posX: number,
    private _posY: number,
    private _width: number,
    private _height: number,
    private _rotation: number,
    private _profileProduct: Product,
    private _profileMaterial: Material,
    private _barHeight: number,
    private _doorRailSetId: number,
    private _rails: readonly RailId[],
    private _wallRailSetId: number,
    private _unevenLeftWall: boolean,
    private _unevenRightWall: boolean,
  ) {}

  public get id(): SectionId {
    return this._id;
  }

  public get posX(): number {
    return this._posX;
  }

  public get posY(): number {
    return this._posY;
  }

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public get profileProduct(): Product {
    return this._profileProduct;
  }

  public get profileMaterial(): Material {
    return this._profileMaterial;
  }

  public get barHeight(): number {
    return this._barHeight;
  }

  public get doorRailsetId(): number {
    return this._doorRailSetId;
  }

  public get rails(): readonly RailId[] {
    return this._rails;
  }

  public get wallRailSetId(): number {
    return this._wallRailSetId;
  }

  public get unevenLeftWall(): boolean {
    return this._unevenLeftWall;
  }

  public get unevenRightWall(): boolean {
    return this._unevenRightWall;
  }

  public get isHorizontal(): boolean {
    return this._rotation == 0 || this._rotation == 180;
  }

  public get isVertical(): boolean {
    return this._rotation == 90 || this._rotation == 270;
  }

  public get isInverted(): boolean {
    return this._rotation == 180 || this._rotation == 270;
  }
}

export function calculateSection(
  selectedSection: SectionId,
  partitionPlan: Readonly<PartitionPlan>,
  floorPlan: FloorPlan,
) {
  let section = partitionPlan.sections.find((s) => s.id == selectedSection)!;

  return new FillingsViewSection(
    section.id,
    section.posX,
    section.posY,
    section.width,
    section.height,
    section.rotation,
    floorPlan.editorAssets.railSetsDict[
      section.references.wallRailSetId
    ].topProduct!,
    floorPlan.editorAssets.materialsDict[section.references.profileMaterialId],
    section.barHeight,
    section.doorRailSetId,
    section.rails,
    section.references.wallRailSetId,
    section.unevenLeftWall,
    section.unevenRightWall,
  );
}
