<div class="modal-content user-message-modal">
  <div class="modal-header">
    <h3 class="modal-title" [textContent]="userMessage.Header"></h3>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <span class="user-message-text" [textContent]="userMessage.Text"></span>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="close()"
        [textContent]="translate('button_close', 'Close')"
      ></button>
    </div>
  </div>
</div>
