import * as Interface_DTO from 'app/ts/Interface_DTO';
import { Product } from 'app/ts/clientDto/Product';
export class LightingProduct extends Product {
  constructor(
    product: Product,
    variantDict: { [id: number]: Interface_DTO.Variant },
    public readonly spotType: string,
  ) {
    super(product.dto, variantDict, product.materials);
  }
}
