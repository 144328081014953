import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_Enums from 'app/ts/Interface_Enums';

/**
 * Represents an item on the floor plan (I think), that is, mostly a
 * cabinet, but also a door or window (in the wall), and even a
 * a Partition.
 *
 * Note: A lot of the fields does not seem to be referred to, but
 * they probably are in svg or html.
 */
export interface FloorPlanProduct {
  name: string;
  size: Interface_DTO_Draw.Vec2d;
  displayOffset: Interface_DTO_Draw.Vec2d;

  /** Used in floor plan image to show an image instead of just a rectangl */
  imageUrl?: string;
  visibilitySettingKey?: Interface_Enums.SalesChainSettingKey;
  canFlipSide: boolean;
  canFlipDirection: boolean;
  canEditWidth: boolean;
  positioning: Positioning;
  depthFollowsLength: boolean; //For doors and such. The door image should always be square
  spawnable: boolean; // ?Not used
  cabinetType: Interface_Enums.CabinetType | null;
  itemType: Interface_DTO_FloorPlan.ItemType;

  /** Used in @see Pickable to show an image of the product - see
   * the code in FloorPlanVm.productPickables
   */
  picturePath: string;
}

export enum Positioning {
  AlongWall, // false
  OnWall, // true
  InsideRoom,
}
