import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import * as ArrayHelper from 'app/ts/util/ArrayHelper';
import { SwingModuleProduct } from 'app/ts/clientDto/SwingModuleProduct';
export class SwingModuleSetup
  implements Readonly<Interface_DTO.SwingModuleSetup>
{
  public readonly chainOverride: boolean = false;
  public readonly possibleHingeSides: Enums.HingeSide[];

  constructor(
    public readonly dto: Interface_DTO.SwingModuleSetup,
    public readonly product: Client.Product,
    public readonly subProducts: { [placement: number]: SwingModuleProduct[] },
  ) {
    for (let placement in subProducts) {
      if (
        subProducts[placement].some((product) => product.product.overrideChain)
      ) {
        this.chainOverride = true;
        break;
      }
    }
    let doorProducts =
      this.subProducts[Interface_Enums.SwingModuleProductPlacement.Door];
    let doorCount = doorProducts
      ? ArrayHelper.sum(...doorProducts.map((p) => p.Quantity))
      : 0;
    if (doorCount > 1) {
      this.possibleHingeSides = [Enums.HingeSide.Both];
    } else if (doorCount === 1) {
      this.possibleHingeSides = [Enums.HingeSide.Left, Enums.HingeSide.Right];
    } else {
      this.possibleHingeSides = [Enums.HingeSide.None];
    }
  }

  public get ModuleProductNo() {
    return this.dto.ModuleProductNo;
  }
  public get AlternateModuleProductNo() {
    return this.dto.AlternateModuleProductNo;
  }
  public get Type() {
    return this.dto.Type;
  }
}
