import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CornerNameBubbleComponent } from './corner-name-bubble.component';

@NgModule({
  declarations: [CornerNameBubbleComponent],
  imports: [BrowserModule, FormsModule, RouterModule],
  exports: [CornerNameBubbleComponent],
})
export class CornerNameBubbleModule {}
