<div [ngClass]="{'selected': selectedFolder == folder.folder}">
  <div (click)="click($event, folder)" class="folder-name">
    <span
      class="bi"
      [ngClass]="{'bi-folder2-open' : folder.isOpen, 'bi-folder2': !folder.isOpen}"
    ></span>
    &nbsp;
    <span [textContent]="folder.Name"></span>
  </div>
  <div class="subfolder-list" *ngIf="folder.isOpen">
    <folder
      *ngFor="let subfolder of folder.Subfolders"
      [folder]="subfolder"
      [selectedFolder]="selectedFolder"
      (folderClicked)="subFolderClicked($event)"
    ></folder>
  </div>
</div>
