import * as Client from 'app/ts/clientDto/index';
export class BarDesign {
  constructor(
    public readonly id: number,
    public readonly defaultName: string,
    fillings: [number, 'fr' | 'mm'][],
  ) {
    this.translationKey = 'bar_design_' + id;
    this.fillings = fillings.map((f) => new BarDesign.Filling(f[0], f[1]));
    this.imageUrlSuffix = '/barDesigns/' + id + '.png';
  }

  readonly translationKey: string;
  readonly fillings: BarDesign.Filling[];
  readonly imageUrlSuffix: string;

  public static getDefaultDesigns() {
    return [
      new Client.BarDesign(1, 'No bars', [[1, 'fr']]),
      new Client.BarDesign(2, 'Band in middle', [
        [1, 'fr'],
        [400, 'mm'],
        [1, 'fr'],
      ]),
      new Client.BarDesign(3, '3 equal parts', [
        [1, 'fr'],
        [1, 'fr'],
        [1, 'fr'],
      ]),
      new Client.BarDesign(4, '4 equal parts', [
        [1, 'fr'],
        [1, 'fr'],
        [1, 'fr'],
        [1, 'fr'],
      ]),
      new Client.BarDesign(5, 'Big middle part', [
        [1, 'fr'],
        [2, 'fr'],
        [1, 'fr'],
      ]),
      new Client.BarDesign(6, 'Bands in top and bottom', [
        [400, 'mm'],
        [1, 'fr'],
        [400, 'mm'],
      ]),
    ];
  }
}

export module BarDesign {
  export class Filling {
    constructor(
      public readonly height: number,
      public readonly unit: 'fr' | 'mm',
    ) {}
  }
}
