import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private translateService: TranslateService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    let titleKey = this.title.getTitle();

    if (titleKey == null || titleKey == '') {
      titleKey = 'index_title';
      this.translateService
        .translateAsync(titleKey, 'Cabinet Manager')
        .then((title) => {
          if (title == null || title == '') title = titleKey;
          this.title.setTitle(title);
        });
    }
  }
}
