import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/FloorPlan';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';

@Injectable()
export class PieceListService {
  public static readonly Name = 'pieceListService';

  constructor(
    private readonly $http: PromisingBackendService,
    private readonly floorPlanService: FloorPlanService,
    private readonly screenshotService: ScreenshotService,
    private readonly translationService: TranslationService,
  ) {}

  public async downloadPiecelist(
    floorPlan: Client.FloorPlan,
    options: Interface_DTO.PrintOptions,
  ): Promise<void> {
    let imageSubjects = options.ImageSubjects;
    let images = await Promise.all(
      this.screenshotService.getScreenshots(floorPlan, imageSubjects),
    );

    let requestInfo: Interface_DTO.PieceListGenerationOptions = {
      FloorPlan: this.floorPlanService.getCompleteFloorPlan(floorPlan),
      Images: images,
      Options: options,
    };

    let pdfData = await this.$http.post<Blob>('api/pdf', requestInfo, {
      responseType: 'blob',
    });

    this.downloadPieceListPdf(pdfData, floorPlan.Name);
  }

  public async downloadPiecelistFromId(
    floorPlanId: number,
    options: Interface_DTO.PrintOptions,
    name: string,
  ): Promise<void> {
    let pdfData = await this.$http.post<Blob>(
      'api/pdf/' + floorPlanId,
      options,
      { responseType: 'blob' },
    );
    this.downloadPieceListPdf(pdfData, name);
  }

  private downloadPieceListPdf(pdfBlob: Blob, name: string) {
    let filename = this.translationService.translate(
      'download_pdf_filename_{0}',
      'Piecelist {0}.pdf',
      name,
    );
    const nav = window.navigator as any;
    if (!!nav.msSaveOrOpenBlob && nav.msSaveBlob) {
      //MSIE handling
      nav.msSaveBlob(pdfBlob, filename);
    } else {
      let dataUrl = window.URL.createObjectURL(pdfBlob);
      var linkElem = document.createElement('a');
      linkElem.href = dataUrl;
      linkElem.download = filename;
      document.body.appendChild(linkElem);
      linkElem.click();
      document.body.removeChild(linkElem);
      window.URL.revokeObjectURL(dataUrl);
    }
  }
}
