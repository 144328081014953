<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <a [routerLink]="'/'" class="navbar-brand">
        <img class="navbar-header-image" [attr.src]="logoUrl" src="" />
      </a>

      <svg
        class="diagonal-topbar-divider"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polyline points="0,0 100,0 100,100 60,100"></polyline>
      </svg>
    </div>
    <div class="customer-topbar">
      <ng-content></ng-content>
    </div>
    <div class="navbar-right">
      <ul class="navbar-nav">
        <li *ngIf="displayCreateNew">
          <div class="btn-group btn-group-sm">
            <a
              class="btn btn-default"
              (click)="newFloorPlanOnDefaultDeliveryAddress()"
              [textContent]="translate('topbar_quick_create', 'Create New...')"
            ></a>
            <a
              class="btn btn-default"
              *ngIf="showFloorPlanTemplate"
              (click)="newFloorPlanFromTemplate()"
              [textContent]="translate('topbar_create_from_template', 'Create from template')"
            ></a>
          </div>
        </li>
      </ul>
      <user-panel
        [parent]="this"
        [allowChangeStore]="allowChangeStore"
      ></user-panel>
    </div>
  </div>
  <div
    style="
      position: absolute;
      top: 60px;
      right: 12px;
      z-index: 6;
      cursor: pointer;
    "
    class="alert alert-info"
    *ngIf="displayNewAppVersion"
    (click)="updateApp()"
  >
    {{translate('topbar_new_version_alert', 'There is a new version available.
    Please save your changes, then click here to update.')}}
  </div>
</nav>
