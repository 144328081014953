<div class="consumer-basket consumer-page">
  <div class="consumer-nav">
    <navbar current-id="'basket'"></navbar>
  </div>

  <div class="consumer-left">
    <div class="flex-spacer"></div>

    <div>
      <button class="btn btn-secondary btn-block" (click)="save()">
        {{translate('consumer_basket_btn_save', 'Save solution for later')}}
      </button>

      <!--<button class="btn btn-primary btn-block" (click)="order()">
                {{translate('consumer_basket_btn_order', 'Order solution')}}
            </button>-->

      <button class="btn btn-primary btn-block" (click)="addToBasket()">
        {{translate('consumer_basket_btn_add_to_basket', 'Add to basket')}}
      </button>
    </div>
  </div>

  <div class="consumer-main">
    <consumer-logo></consumer-logo>

    <!--<piece-list class="align-self-stretch" width="100%"
                floor-plan="floorPlan">

    </piece-list>-->
    <consumer-piece-list-table
      class="align-self-stretch"
      width="100%"
      [floorPlan]="floorPlan"
    >
    </consumer-piece-list-table>
  </div>

  <div class="consumer-bottom">
    <div class="btn-group prev-next-btns">
      <button class="btn btn-secondary btn-prev" (click)="previous()">
        {{translate('consumer_btn_prev', 'Back')}}
      </button>

      <button style="visibility: hidden" class="btn btn-primary">
        {{translate('consumer_btn_next', 'Next')}}
      </button>
    </div>

    <consumer-price [floorPlan]="floorPlan"></consumer-price>
  </div>
</div>
