import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { Door } from 'app/ts/clientDto/Door';
import { EditorAssets } from 'app/ts/clientDto/EditorAssets';
import { DoorHelper } from 'app/ts/util/DoorHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class DoorFilling {
  public static readonly type: 'doorFilling' = 'doorFilling';
  public readonly type = DoorFilling.type;

  //#region Backing variables

  private _size: Interface_DTO_Draw.Vec3d = { X: 0, Y: 0, Z: 0 };
  private _position: Interface_DTO_Draw.Vec3d = { X: 0, Y: 0, Z: 0 };

  private _pickableFillingMaterials:
    | Pickable<Client.FillingMaterial>[]
    | undefined;

  private _material: Client.ProductMaterial | null = null;
  private _materialNumber: string = '';

  private _isDesignFilling: boolean = false;
  private _rotated: boolean = false;
  public fillingMaterialChanged: boolean = false;

  //#endregion Backing variables

  constructor(
    public readonly door: Door,
    private readonly editorAssets: EditorAssets,
  ) {
    let fillingPosX = DoorHelper.getFrameSize(
      door,
      Interface_Enums.Direction.Left,
      true,
    );
    let frameSizeTotal = DoorHelper.getFrameSize(
      door,
      Interface_Enums.Direction.Left,
      false,
    );
    this._position.X = fillingPosX;
    this.depth = frameSizeTotal > 3 ? Constants.fillingDepth : door.depth;
    this._position.Z = door.fillingPositionZ;
  }

  //#region Public properties

  /**
        The fillings index in the fillings list on the door (one based)
        **/
  public get index(): number {
    let index = this.door.fillings.indexOf(this);
    return index + 1;
  }

  public get width(): number {
    return (
      this.door.width - DoorHelper.getFrameDeductionForFilling(this.door, false)
    );
  }
  public set width(value: number) {
    this._size.X = value;
  }

  public get height(): number {
    return this._size.Y;
  }
  public set height(value: number) {
    this._size.Y = value;
  }

  public get depth(): number {
    return this._size.Z;
  }
  public set depth(value: number) {
    this._size.Z = value;
  }

  /**
        The relative position of the filling in relation to the door.
        **/

  public get positionX(): number {
    return this._position.X;
  }

  public get positionY(): number {
    return this._position.Y;
  }
  public set positionY(value: number) {
    this._position.Y = value;
  }

  public get positionZ(): number {
    return this._position.Z;
  }

  /**
        The absolute position of the filling in relation to the floor.
        **/
  get positionYAbsolute(): number {
    return this.door.position.Y + this._position.Y;
  }
  set positionYAbsolute(value: number) {
    this._position.Y = value - this.door.position.Y;
  }

  public get pickableMaterials(): Pickable<Client.FillingMaterial>[] {
    if (this._pickableFillingMaterials === undefined) {
      let doorProduct = this.door.doorSubSection.profile;
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.door.doorSubSection.cabinetSection.cabinet.floorPlan
          .FullCatalogAllowOtherMaterials;

      if (doorProduct) {
        let allowDesign = this.door.designAllowedForFilling(this);

        let pickablesNormal = new Array<Pickable<Client.FillingMaterial>>();
        let pickablesDesign = new Array<Pickable<Client.FillingMaterial>>();
        let doorSupportsFixedBars =
          this.door.doorData &&
          this.door.doorData.Bars.some(
            (bar) => bar.BarType === Interface_Enums.BarType.Fixed,
          );

        let requiredFillingHeight: number | undefined = doorSupportsFixedBars
          ? undefined
          : this.door.height -
            DoorHelper.getFrameDeductionForFilling(this.door, true);

        for (let productMaterial of doorProduct.materials) {
          if (productMaterial.Type !== Interface_Enums.MaterialType.Normal) {
            continue;
          }
          if (productMaterial.isDiscontinued) {
            continue;
          }
          if (!fullCatalog && productMaterial.isOverride) {
            continue;
          }
          // Normal filling material
          if (
            productMaterial.designType ===
              Interface_Enums.MaterialDesignType.Normal ||
            productMaterial.designType ===
              Interface_Enums.MaterialDesignType.DesignAndNormal
          ) {
            let pickable =
              MaterialHelper.getPickableForFilling(productMaterial);
            pickable.isSelected = this.materialId === productMaterial.Id;
            pickable.override = productMaterial.isOverride;

            if (
              !MaterialHelper.supportsFillingSize(
                productMaterial,
                this.door.width,
                requiredFillingHeight,
              ).supported
            ) {
              pickable.disabledReason =
                'The door is too big for this material.';
            }

            pickablesNormal.push(pickable);
          }

          // Design filling material
          if (
            productMaterial.designType ===
              Interface_Enums.MaterialDesignType.Design ||
            productMaterial.designType ===
              Interface_Enums.MaterialDesignType.DesignAndNormal
          ) {
            let disabledReason = allowDesign
              ? undefined
              : 'Design filling not allowed here.';

            let pickable = MaterialHelper.getPickableForFilling(
              productMaterial,
              true,
              disabledReason,
            );
            pickable.isSelected = this.materialId === productMaterial.Id;
            pickablesDesign.push(pickable);
          }
        }

        MaterialHelper.sortPickableFillingMaterials(pickablesNormal);
        MaterialHelper.sortPickableFillingMaterials(pickablesDesign);

        this._pickableFillingMaterials =
          pickablesNormal.concat(pickablesDesign);
      } else {
        this._pickableFillingMaterials = [];
      }
    }

    return this._pickableFillingMaterials;
  }

  //this. is the main way of setting / getting filling material. Other getters/setters should use this as a reference.
  public get materialNumber(): string {
    return this._materialNumber;
  }
  public set materialNumber(value: string) {
    this._material = null;
    this._materialNumber = value;
  }

  public get materialId(): number {
    if (!this.material) {
      return 0;
    }

    return this.material.Id;
  }
  public set materialId(val: number) {
    let material = this.editorAssets.materialsDict[val];
    if (material) {
      this.materialNumber = material.Number;
    } else {
      this.materialNumber = '';
    }
  }

  public get material(): Client.ProductMaterial | null {
    if (!this._material) {
      let door = this.door.doorSubSection.profile;
      if (door) {
        let mats = door.materials.filter(
          (mat) => mat.Type === Interface_Enums.MaterialType.Normal,
        );
        this._material = mats.filter(
          (mat) => mat.Number === this.materialNumber,
        )[0];
        if (!this._material) {
          this.materialNumber = mats[0].Number;
          return this.material;
        }
      }
    }

    return this._material;
  }
  public set material(value: Client.ProductMaterial | null) {
    if (value) {
      let doorProduct = this.door.doorSubSection.profile;
      if (doorProduct) {
        for (let matId of doorProduct.PossibleMaterialIds) {
          if (matId.Id === value.Id) {
            if (
              this.isDesignFilling &&
              (matId.DesignType === Interface_Enums.MaterialDesignType.Design ||
                matId.DesignType ===
                  Interface_Enums.MaterialDesignType.DesignAndNormal)
            ) {
              this._material = value;
              break;
            } else if (
              !this.isDesignFilling &&
              (matId.DesignType === Interface_Enums.MaterialDesignType.Normal ||
                matId.DesignType ===
                  Interface_Enums.MaterialDesignType.DesignAndNormal)
            ) {
              this._material = value;
              break;
            }
          }
        }
      }
    }

    if (!this._material) {
      this._material = DoorHelper.getDefaultFillingMaterial(this.door);
    }

    if (this._material) {
      this._materialNumber = this._material.Number;
    }
  }
  public set fillingMaterial(value: Client.FillingMaterial) {
    if (value.isDesign && !this.door.designAllowedForFilling(this)) {
      return;
    }

    this.isDesignFilling = value.isDesign;
    this.material = value.material;

    this.fillingMaterialChanged = true;
    this.door.setFallBackMaterial(this.material || undefined);
  }

  public get isDesignFilling(): boolean {
    return this._isDesignFilling;
  }
  public set isDesignFilling(value: boolean) {
    if (value && !this._isDesignFilling) this.door.mustSpreadBarsEvenly = true;

    this._isDesignFilling = value;
  }

  public get rotated(): boolean {
    return this._rotated && this.isRotatable;
  }
  public set rotated(value: boolean) {
    this._rotated = value && this.isRotatable;
  }

  private get isRotatable(): boolean {
    return (
      this.material !== null && this.material.Rotatable && !this.isDesignFilling
    );
  }

  // Rustic areas removed 29-01-2018. Approved by JLF
  //public get isRustic(): boolean {
  //    return this.material !== null && this.material.RusticAreas > 0;
  //}

  public get minHeight(): number {
    if (this.material === null) {
      return 0;
    }

    if (this.isDesignFilling) {
      return Constants.designFillingHeight;
    }

    return this.material.MinFillingHeight;
  }

  public get maxHeight(): number {
    if (this.material === null) {
      return 0;
    }

    if (this.isDesignFilling) {
      return Constants.designFillingHeight;
    }

    return this.rotated
      ? this.material.MaxFillingWidth
      : this.material.MaxFillingHeight;
  }

  public get maxWidth(): number {
    if (this.material === null) {
      return 0;
    }

    return this.rotated
      ? this.material.MaxFillingHeight
      : this.material.MaxFillingWidth;
  }

  //#endregion Public properties

  //#region Public functions

  public clearBackingVariables() {
    this._pickableFillingMaterials = undefined;
  }

  public clone(): DoorFilling {
    let fillingClone = new DoorFilling(this.door, this.editorAssets);
    fillingClone.height = this.height;
    fillingClone.materialNumber = this.materialNumber;

    return fillingClone;
  }

  //#endregion Public functions

  //#region Private helpers

  //#endregion Private helpers
}
