import * as Enums from '@ClientDto/Enums';
import { ReadonlyDeep } from '@Interfaces/ReadonlyDeep';
import { PartialDeep } from 'app/ts/interfaces/PartialDeep';

export interface CabinetSectionProperties {
  swingFlex?: CabinetSectionProperties.SwingFlex;
}

export module CabinetSectionProperties {
  export const defaultValue: ReadonlyDeep<CabinetSectionProperties> = {};

  export interface SwingFlexGableInfo {
    variantOptions: { [variantNumber: string]: string | undefined };
  }

  export module SwingFlexGableInfo {
    export const defaultValue: ReadonlyDeep<SwingFlexGableInfo> = {
      variantOptions: {},
    };
  }
  export interface SwingFlex {
    corpusMaterialId: number | null;
    doorMaterialId: number | null;
    backingMaterialId: number | null;
    doorGripId: number | null;
    doorGripMaterialId: number | null;
    doorOpeningMethod: Enums.SwingFlexOpeningMethod;
    drawerGripId: number | null;
    drawerGripMaterialId: number | null;
    rightGable: SwingFlexGableInfo;
    areas: CabinetSectionProperties.SwingFlexArea[];
    corpusCoversDoors: boolean;
    plinth: boolean | null;
    doorCoversPlinth: boolean | null;
  }
  export module SwingFlex {
    export const defaultValue: ReadonlyDeep<SwingFlex> = {
      rightGable: { ...SwingFlexGableInfo.defaultValue },
      corpusMaterialId: null,
      doorMaterialId: null,
      backingMaterialId: null,
      doorGripId: null,
      doorGripMaterialId: null,
      doorOpeningMethod: Enums.SwingFlexOpeningMethod.PushToOpen,
      drawerGripId: null,
      drawerGripMaterialId: null,
      areas: [],
      corpusCoversDoors: true,
      plinth: null,
      doorCoversPlinth: null,
    };
  }

  export interface SwingFlexArea {
    hingeSide: Enums.HingeSide;
    desiredWidth: number | null;
    leftGable: SwingFlexGableInfo;
    subAreas: SwingFlexSubArea[];
  }

  export module SwingFlexArea {
    export const defaultValue: ReadonlyDeep<SwingFlexArea> = {
      desiredWidth: null,
      hingeSide: Enums.HingeSide.None,
      leftGable: { ...SwingFlexGableInfo.defaultValue },
      subAreas: [],
    };
  }

  export interface SwingFlexSubArea {
    hasDoor: boolean;
    doorMaterialId: number | null;
    hingeType: Enums.SwingFlexHingeType;
    hingeTypeLeft: Enums.SwingFlexHingeType;
    hingeTypeRight: Enums.SwingFlexHingeType;
    openingMethod: Enums.SwingFlexOpeningMethod;
    gripPosition: Enums.SwingFlexGripPosition;
    doorGripId: number | null;
    doorGripMaterialId: number | null;
    hasMiddlePanel: boolean;
    middlePanelPosition: number | null;
    middlePanelHeight: number | null;
  }

  export module SwingFlexSubArea {
    export const defaultValue: ReadonlyDeep<SwingFlexSubArea> = {
      hasDoor: true,
      doorMaterialId: null,
      hingeType: Enums.SwingFlexHingeType.Standard,
      hingeTypeLeft: Enums.SwingFlexHingeType.Standard,
      hingeTypeRight: Enums.SwingFlexHingeType.Standard,
      openingMethod: Enums.SwingFlexOpeningMethod.PushToOpen,
      gripPosition: Enums.SwingFlexGripPosition.Auto,
      doorGripId: null,
      doorGripMaterialId: null,
      hasMiddlePanel: false,
      middlePanelPosition: null,
      middlePanelHeight: null,
    };
  }

  export function fromJson(json?: string | null): CabinetSectionProperties {
    let parsed: PartialDeep<CabinetSectionProperties> = JSON.parse(
      json ?? '{}',
    );
    let result = {
      ...defaultValue,
      ...parsed,
    };
    let partialSwingFlex = parsed.swingFlex;
    if (partialSwingFlex) {
      let swingFlex = {
        ...SwingFlex.defaultValue,
        ...partialSwingFlex,
        rightGable: {
          ...SwingFlexGableInfo.defaultValue,
          ...partialSwingFlex.rightGable,
        },
      };

      if (partialSwingFlex?.areas) {
        let areaResults = partialSwingFlex.areas.map((psfa) => ({
          ...SwingFlexArea.defaultValue,
          ...psfa,
          leftGable: {
            ...SwingFlexGableInfo.defaultValue,
            ...psfa.leftGable,
          },
          subAreas: psfa.subAreas?.map((partialSubArea) => ({
            ...SwingFlexSubArea.defaultValue,
            ...partialSubArea,
          })),
        }));
        swingFlex.areas = areaResults;
      }
      result.swingFlex = swingFlex;
    }

    return result as CabinetSectionProperties;
  }
}
