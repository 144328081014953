import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import * as DTO from 'app/ts/interface_dto/index';
import * as Interface_DTO_DomainError from 'app/ts/Interface_DTO_DomainError';
import { CustomerBaseVmService } from 'app/ts/services/CustomerBaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { CustomersTableVm } from 'app/ts/viewmodels/components/CustomersTableVm';
import { CustomerBaseVm } from 'app/ts/viewmodels/CustomerBaseVm';

@Component({
  selector: 'customers-vm',
  templateUrl: 'customers.html',
  styleUrls: [
    '../../../style/overviews.scss',
    '../../../style/rightMenu.scss',
    '../../../style/tables.scss',
  ],
  providers: [activeUserProvider, clientSettingProvider],
})
export class CustomersVm extends CustomerBaseVm<DTO.Customer> {
  public objects: Client.Customer[] = [];
  public editingCustomer: DTO.Customer | undefined;

  constructor(
    customerBaseVmService: CustomerBaseVmService,
    private readonly notificationService: NotificationService,

    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(ClientSettingInjector) clientSettings: ClientSetting,
    private readonly translationService: TranslationService,
    private readonly formattingService: FormattingService,
    private readonly navigate: NavigateService,
    private routing: AppRoutingModule,

    activatedRoute: ActivatedRoute,
    router: Router,
  ) {
    super(
      customerBaseVmService,
      router,
      activatedRoute,
      activeUser,
      clientSettings,
    );
  }

  public override setSelected(clientCustomer: Client.Customer) {
    super.setSelected(clientCustomer);
    if (
      !this.editingCustomer ||
      this.editingCustomer.Id !== clientCustomer.Id
    ) {
      this.editingCustomer = ObjectHelper.copy(clientCustomer.dtoObject);
    }
  }

  public override closeRightMenu() {
    super.closeRightMenu();
    this.editingCustomer = undefined;
  }

  public async openNewCustomer() {
    let modalResult =
      await this.modalService.getNewCustomerModalChained('floorplan');
    switch (modalResult.type) {
      case 'none':
        return;
      case 'customer':
        this.navigate.projects({
          ...this.filterParams,
          customerId: modalResult.customerId.toString(),
        });
        return;
      case 'project':
        this.navigate.deliveryAddresses({
          ...this.filterParams,
          projectId: modalResult.projectId.toString(),
        });
        return;
      case 'deliveryAddress':
        this.navigate.floorPlans({
          ...this.filterParams,
          deliveryAddressId: modalResult.projectDeliveryAddressId.toString(),
        });
        return;
      case 'floorPlan':
        this.navigate.floorPlan(modalResult.floorPlanId);
        return;

      default:
        throw new Error(
          'Unknown modal result type: ' + (<any>modalResult).type,
        );
    }
  }

  public async selectColumns() {
    const possibleColumns = CustomersTableVm.getColumns(
      this.translationService,
      this.formattingService,
    );
    const currentColumnNames = this.clientSettings.data.columnsCustomer;
    const newCols = await this.modalService.selectColumns(
      possibleColumns,
      currentColumnNames,
    );
    if (newCols) {
      const newSettings = {
        ...this.clientSettings.data,
      };
      newSettings.columnsCustomer = newCols;
      this.clientSettings.set(newSettings);
    }
  }

  public showChildren(customer: DTO.Customer) {
    this.routing.navigate.projects({
      ...this.filterParams,
      customerId: customer.Id.toString(),
    });
  }

  public async deleteSelected() {
    let sure = confirm(
      this.translate(
        'customer_confirm_delete',
        'Are you sure you want to delete this customer?',
      ),
    );
    if (!sure) return;
    let customers = this.selectedObjects.map(
      (container) => container.dtoObject,
    );
    try {
      let deleteTask = this.customerService.delete(customers);
      this.notificationService.loadPromise(deleteTask);
      await deleteTask;
      this.closeRightMenu();
    } catch (e: any) {
      if (e instanceof Exception.ServerDomainException) {
        if (e.Type === Interface_DTO_DomainError.ErrorType.ActiveOrder) {
          this.notificationService.userError(
            'customer_delete_fail_active_order',
            'Customer cannot be deleted because there are active orders',
          );
          return;
        }
      }
      this.notificationService.exception(
        'customer_delete_unknown_error',
        e,
        'Unknown error',
        { customerIds: customers.map((c) => c.Id) },
      );
    }
  }

  public onSearchUpdated(customers: Client.Customer[]) {
    let hashIds = this.hashIds;
    this.objects = customers.sort((custA, custB) =>
      custA.ChildModifiedDate.localeCompare(custB.ChildModifiedDate),
    );
    for (let obj of this.objects) {
      if (hashIds[obj.Id]) {
        obj.selected = true;
        if (!this.editingCustomer || this.editingCustomer.Id !== obj.Id) {
          this.editingCustomer = ObjectHelper.copy(obj.dtoObject);
        }
      }
    }
  }

  protected getId(o: DTO.Customer): number {
    return o.Id;
  }
}
