import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CustomerEditComponent } from './customer-edit.component';
import { PhoneInputModule } from './phone-input.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerEditMenuComponent } from './customer-edit-menu.component';

@NgModule({
  declarations: [CustomerEditComponent, CustomerEditMenuComponent],
  exports: [CustomerEditComponent, CustomerEditMenuComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    NgbModule,

    PhoneInputModule,
  ],
})
export class CustomerEditModule {}
