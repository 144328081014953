import * as Interface_DTO from 'app/ts/Interface_DTO';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { DoorHelper } from 'app/ts/util/DoorHelper';
export class DoorBarVertical {
  public positionX = 0;

  private cache: {
    barData?: Interface_DTO.ProductBarData | null;
    product?: Client.Product | null;
  } = {};

  constructor(public readonly door: Client.Door) {}

  private get barData() {
    if (this.cache.barData === undefined) {
      this.cache.barData = DoorHelper.getCorrectBar(this.door, null, null);
    }
    return this.cache.barData;
  }

  public get product(): Client.Product | null {
    if (this.cache.product === undefined) {
      if (this.barData) {
        this.cache.product =
          this.door.doorSubSection.editorAssets.productsDict[
            this.barData.ProductId
          ];
      }
      if (!this.cache.product) {
        this.cache.product = null;
      }
    }
    return this.cache.product;
  }

  get height(): number {
    return (
      this.door.height -
      (this.door.frameHeightTop + this.door.frameHeightBottom)
    );
  }
  get width(): number {
    return this.barData ? this.barData.Height : 0;
  }
  get depth(): number {
    // Depth is decreased to make it clearer in 3D, that vertical bars sit between horizontal bars and door profile.
    return this.model3dDepth - 2;
  }

  public get positionY(): number {
    return this.door.frameHeightBottom;
  }

  public get positionZ(): number {
    let fillingCenter = this.door.fillingPositionZ + Constants.fillingDepth / 2;
    return fillingCenter - this.depth / 2;
  }

  public get rightX(): number {
    return this.positionX + this.width;
  }

  public get model3dDepth(): number {
    let barDepth = !!this.product ? this.product.model3dDepth : 0;
    if (barDepth === null || barDepth <= 0) {
      barDepth = this.door.depth;
    }
    return barDepth;
  }

  public get material() {
    return this.door.doorSubSection.profileMaterial;
  }

  public get model3dPath(): string | null {
    if (this.product) {
      return this.product.model3dPath;
    } else {
      return null;
    }
  }
}
