import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnchorScrollService {
  private _yOffSet: number = 0;

  public scrollToHash(hash: string | undefined = undefined): void {}

  public set yOffset(value: number) {
    this._yOffSet = value;
  }
}
