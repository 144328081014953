import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
export class VectorHelper {
  //From https://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
  public static distSquared(
    v: Interface_DTO_Draw.Vec2d,
    w: Interface_DTO_Draw.Vec2d,
  ) {
    return (v.X - w.X) * (v.X - w.X) + (v.Y - w.Y) * (v.Y - w.Y);
  }

  private static distToSegmentSquared(
    p: Interface_DTO_Draw.Vec2d,
    v: Interface_DTO_Draw.Vec2d,
    w: Interface_DTO_Draw.Vec2d,
  ) {
    var l2 = VectorHelper.distSquared(v, w);
    if (l2 == 0) return VectorHelper.distSquared(p, v);
    var t = ((p.X - v.X) * (w.X - v.X) + (p.Y - v.Y) * (w.Y - v.Y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return VectorHelper.distSquared(p, {
      X: v.X + t * (w.X - v.X),
      Y: v.Y + t * (w.Y - v.Y),
    });
  }

  public static dist(
    v: Interface_DTO_Draw.Vec2d,
    rect: Interface_DTO_Draw.Rectangle,
  ): number;
  public static dist(
    v: Interface_DTO_Draw.Vec3d,
    w: Interface_DTO_Draw.Vec3d,
  ): number;
  public static dist(
    v: Interface_DTO_Draw.Vec2d,
    w: Interface_DTO_Draw.Vec2d,
  ): number;
  public static dist(
    v: Interface_DTO_Draw.Vec2d | Interface_DTO_Draw.Vec3d,
    w:
      | Interface_DTO_Draw.Vec2d
      | Interface_DTO_Draw.Rectangle
      | Interface_DTO_Draw.Vec3d,
  ): number {
    if (VectorHelper.isRectangle(w)) {
      //w was a rectangle
      if (VectorHelper.contains(v, w)) return 0; //inside
      if (w.X <= v.X && v.X <= w.X + w.Width) {
        //directly above or below
        if (v.Y <= w.Y) return w.Y - v.Y; //directly below
        return v.Y - (w.Y + w.Height); //directly above
      } else if (w.Y <= v.Y && v.Y <= w.Y + w.Height) {
        //directly besides
        if (v.X <= w.X) return w.X - v.X; //directly to the left
        return v.X - (w.X + w.Width); //directly to the right
      } else {
        return Math.min(
          VectorHelper.dist(v, { X: w.X, Y: w.Y }),
          VectorHelper.dist(v, { X: w.X, Y: w.Y + w.Height }),
          VectorHelper.dist(v, { X: w.X + w.Width, Y: w.Y }),
          VectorHelper.dist(v, { X: w.X + w.Width, Y: w.Y + w.Height }),
        );
      }
    } else if (VectorHelper.isVec3d(v) && VectorHelper.isVec3d(w)) {
      return Math.sqrt(
        [v.X - w.X, v.Y - w.Y, v.Z - w.Z]
          .map((coord) => coord * coord)
          .reduce((a, b) => a + b, 0),
      );
    } else {
      return Math.sqrt(
        VectorHelper.distSquared(v, w as Interface_DTO_Draw.Vec2d),
      );
    }
  }
  public static distToSegment(
    p: Interface_DTO_Draw.Vec2d,
    v: Interface_DTO_Draw.Vec2d,
    w: Interface_DTO_Draw.Vec2d,
  ): number {
    return Math.sqrt(VectorHelper.distToSegmentSquared(p, v, w));
  }

  public static add(
    v: Interface_DTO_Draw.Vec3d,
    ...ws: Interface_DTO_Draw.Vec3d[]
  ): Interface_DTO_Draw.Vec3d;
  public static add(
    v: Interface_DTO_Draw.Vec2d,
    ...ws: Interface_DTO_Draw.Vec2d[]
  ): Interface_DTO_Draw.Vec2d;
  public static add(v: any, ...ws: any[]): any {
    let result = {
      X: v.X,
      Y: v.Y,
      Z: v.Z,
    };
    for (let w of ws) {
      result.X += w.X;
      result.Y += w.Y;
      if (result.Z !== undefined && w.Z !== undefined) {
        result.Z += w.Z;
      } else {
        delete result.Z;
      }
    }
    return result;
  }

  public static subtract(
    v: Interface_DTO_Draw.Vec3d,
    w: Interface_DTO_Draw.Vec3d,
  ): Interface_DTO_Draw.Vec3d;
  public static subtract(
    v: Interface_DTO_Draw.Vec2d,
    w: Interface_DTO_Draw.Vec2d,
  ): Interface_DTO_Draw.Vec2d;
  public static subtract(v: any, w: any): any {
    if (typeof v.Z === 'number' && typeof w.Z === 'number') {
      return {
        X: v.X - w.X,
        Y: v.Y - w.Y,
        Z: v.Z - w.Z,
      };
    } else {
      return {
        X: v.X - w.X,
        Y: v.Y - w.Y,
      };
    }
  }

  public static isInRectangle(
    point: Interface_DTO_Draw.Vec2d,
    rp1: Interface_DTO_Draw.Vec2d,
    rp2: Interface_DTO_Draw.Vec2d,
  ): boolean {
    return (
      VectorHelper.isBetween(point.X, rp1.X, rp2.X) &&
      VectorHelper.isBetween(point.Y, rp1.Y, rp2.Y)
    );
  }
  private static isBetween(q: number, a: number, b: number): boolean {
    return (q >= a && q <= b) || (q >= b && q <= a);
  }

  public static getClosestPointOnLineSegment(
    lineBegin: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    point: Interface_DTO_Draw.Vec2d,
  ): Interface_DTO_Draw.Vec2d {
    let angle1 = VectorHelper.angle(point, lineEnd, lineBegin);
    let angle2 = VectorHelper.angle(point, lineBegin, lineEnd);

    if (angle1 > Math.PI / 2 && angle1 < (Math.PI * 3) / 2) {
      //point is closest to LineBegin
      return ObjectHelper.copy(lineBegin);
    } else if (angle2 > Math.PI / 2 && angle2 < (Math.PI * 3) / 2) {
      //Point is closest to lineEnd
      return ObjectHelper.copy(lineEnd);
    } else {
      //point is somewhere along the line
      return VectorHelper.getClosestPointOnLine(lineBegin, lineEnd, point);
    }
  }

  public static rotate3d(
    v: Interface_DTO_Draw.Vec3d,
    rotationRad: number,
  ): Interface_DTO_Draw.Vec3d {
    let result = ObjectHelper.copy(v);
    result.X = v.X * Math.cos(rotationRad) - v.Z * Math.sin(rotationRad);
    result.Z = v.X * Math.sin(rotationRad) + v.Z * Math.cos(rotationRad);
    return result;
  }
  public static rotate2d(
    v: Interface_DTO_Draw.Vec2d,
    rotationRad: number,
  ): Interface_DTO_Draw.Vec2d {
    let result = ObjectHelper.copy(v);
    result.X = v.X * Math.cos(rotationRad) - v.Y * Math.sin(rotationRad);
    result.Y = v.X * Math.sin(rotationRad) + v.Y * Math.cos(rotationRad);
    return result;
  }

  private static getClosestPointOnLine(
    lineBegin: Interface_DTO_Draw.Vec2d,
    lineEnd: Interface_DTO_Draw.Vec2d,
    point: Interface_DTO_Draw.Vec2d,
  ): Interface_DTO_Draw.Vec2d {
    let e1 = {
      X: lineEnd.X - lineBegin.X,
      Y: lineEnd.Y - lineBegin.Y,
    };
    let e2 = {
      X: point.X - lineBegin.X,
      Y: point.Y - lineBegin.Y,
    };
    let dotProduct = VectorHelper.dotProduct(e1, e2);
    let lenSquared = e1.X * e1.X + e1.Y * e1.Y;
    return {
      X: lineBegin.X + (dotProduct * e1.X) / lenSquared,
      Y: lineBegin.Y + (dotProduct * e1.Y) / lenSquared,
    };
  }

  public static dotProduct(
    p1: Interface_DTO_Draw.Vec2d,
    p2: Interface_DTO_Draw.Vec2d,
  ): number {
    return p1.X * p2.X + p1.Y * p2.Y;
  }

  /**
   * Calculates the angle (in radians) between two vectors pointing outward from one center
   *
   * @param p0 first point
   * @param p1 second point
   * @param c center point
   */
  public static angle(
    p0: Interface_DTO_Draw.Vec2d,
    p1: Interface_DTO_Draw.Vec2d,
    c: Interface_DTO_Draw.Vec2d,
  ) {
    var p0c = Math.sqrt(Math.pow(c.X - p0.X, 2) + Math.pow(c.Y - p0.Y, 2)); // p0->c (b)
    var p1c = Math.sqrt(Math.pow(c.X - p1.X, 2) + Math.pow(c.Y - p1.Y, 2)); // p1->c (a)
    var p0p1 = Math.sqrt(Math.pow(p1.X - p0.X, 2) + Math.pow(p1.Y - p0.Y, 2)); // p0->p1 (c)
    return Math.acos((p1c * p1c + p0c * p0c - p0p1 * p0p1) / (2 * p1c * p0c));
  }

  public static getNormalizedVector(
    vector: Interface_DTO_Draw.Vec2d,
  ): Interface_DTO_Draw.Vec2d {
    let magnitude = Math.sqrt(vector.X * vector.X + vector.Y * vector.Y);
    return {
      X: vector.X / magnitude,
      Y: vector.Y / magnitude,
    };
  }

  public static contains(
    point: Interface_DTO_Draw.Vec2d,
    rect: Interface_DTO_Draw.Rectangle,
  ): boolean;
  public static contains(
    r: Interface_DTO_Draw.Rectangle,
    container: Interface_DTO_Draw.Rectangle,
  ): boolean;
  public static contains(
    c: Interface_DTO_Draw.Cube,
    container: Interface_DTO_Draw.Cube,
  ): boolean;

  public static contains(
    param1:
      | Interface_DTO_Draw.Vec2d
      | Interface_DTO_Draw.Rectangle
      | Interface_DTO_Draw.Cube,
    container: Interface_DTO_Draw.Rectangle | Interface_DTO_Draw.Cube,
  ): boolean {
    if (VectorHelper.isCube(param1) && VectorHelper.isCube(container)) {
      if (param1.X < container.X) return false;
      if (param1.Y < container.Y) return false;
      if (param1.Z < container.Z) return false;
      if (param1.X + param1.Width > container.X + container.Width) return false;
      if (param1.Y + param1.Height > container.Y + container.Height)
        return false;
      if (param1.Z + param1.Depth > container.Z + container.Depth) return false;
      return true;
    } else if (VectorHelper.isRectangle(param1)) {
      return (
        VectorHelper.contains({ X: param1.X, Y: param1.Y }, container) &&
        VectorHelper.contains(
          {
            X: param1.X + param1.Width,
            Y: param1.Y + param1.Height,
          },
          container,
        )
      );
    } else {
      let point = param1 as Interface_DTO_Draw.Vec2d;
      return (
        container.X <= point.X &&
        point.X <= container.X + container.Width &&
        container.Y <= point.Y &&
        point.Y <= container.Y + container.Height
      );
    }
  }

  /**
   * Calculates if two cubes are overlapping. Note: Touching is not overlapping.
   */
  public static overlaps(
    r1: Interface_DTO_Draw.Cube,
    r2: Interface_DTO_Draw.Cube,
  ): boolean;
  /**
   * Calculates if two rectangles are overlapping. Note: Touching is not overlapping.
   */
  public static overlaps(
    r1: Interface_DTO_Draw.Rectangle,
    r2: Interface_DTO_Draw.Rectangle,
  ): boolean;

  public static overlaps(
    c1: Interface_DTO_Draw.Rectangle | Interface_DTO_Draw.Cube,
    c2: Interface_DTO_Draw.Rectangle | Interface_DTO_Draw.Cube,
  ): boolean {
    if (!this.overlaps2d(c1, c2)) return false;

    if (this.isCube(c1) && this.isCube(c2)) {
      //both are at least 3D
      if (c1.Z + c1.Depth <= c2.Z) return false; //c1 is behind c2
      if (c2.Z + c2.Depth <= c1.Z) return false; //c2 is behind c1
    }

    return true;
  }

  public static overlaps2d(
    r1: Interface_DTO_Draw.Rectangle,
    r2: Interface_DTO_Draw.Rectangle,
  ): boolean {
    if (r1.X + r1.Width <= r2.X) return false; //r1 is left of r2
    if (r2.X + r2.Width <= r1.X) return false; //r2 is left of r1

    if (r1.Y + r1.Height <= r2.Y) return false; //r1 is below r2
    if (r2.Y + r2.Height <= r1.Y) return false; //r2 is below r1

    return true;
  }

  public static overlapsMoreThanThreshold(
    c1: Interface_DTO_Draw.Rectangle | Interface_DTO_Draw.Cube,
    c2: Interface_DTO_Draw.Rectangle | Interface_DTO_Draw.Cube,
    thresholdX: number,
    thresholdY: number,
    thresholdZ: number,
  ): boolean {
    if (c1.X + c1.Width - thresholdX <= c2.X) return false; //r1 is left of r2
    if (c2.X + c2.Width - thresholdX <= c1.X) return false; //r2 is left of r1

    if (c1.Y + c1.Height - thresholdY <= c2.Y) return false; //r1 is below r2
    if (c2.Y + c2.Height - thresholdY <= c1.Y) return false; //r2 is below r1

    if (this.isCube(c1) && this.isCube(c2)) {
      //both are at least 3D
      if (c1.Z + c1.Depth - thresholdZ <= c2.Z) return false; //c1 is behind c2
      if (c2.Z + c2.Depth - thresholdZ <= c1.Z) return false; //c2 is behind c1
    }

    return true;
  }

  public static overlapsX(
    r1: Interface_DTO_Draw.Rectangle,
    r2: Interface_DTO_Draw.Rectangle,
  ): boolean {
    if (r1.X >= r2.X + r2.Width) return false;
    if (r2.X >= r1.X + r1.Width) return false;
    return true;
  }

  public static isCube(input: any): input is Interface_DTO_Draw.Cube {
    for (let prop of ['Width', 'Height', 'Depth'])
      if (typeof input[prop] !== 'number') return false;
    return this.isVec3d(input);
  }
  public static isRectangle(input: any): input is Interface_DTO_Draw.Rectangle {
    for (let prop of ['Width', 'Height'])
      if (typeof input[prop] !== 'number') return false;
    return this.isVec2d(input);
  }

  public static isVec3d(input: any): input is Interface_DTO_Draw.Vec3d {
    return typeof input.Z === 'number' && this.isVec2d(input);
  }

  public static isVec2d(input: any): input is Interface_DTO_Draw.Vec2d {
    return typeof input.X === 'number' && typeof input.Y === 'number';
  }
}
