<div
  class="editor-all"
  [ngClass]="{'no-right-menu': !showPanel}"
  *ngIf="editorType.ready"
>
  <editor-topbar
    [vm]="this"
    [selection]="'corpus'"
    [cabinetSection]="cabinetSection"
  ></editor-topbar>

  <dark-side-bar [floorplan]="floorPlan"></dark-side-bar>
  <div class="tool-menu left-tool-menu">
    <div class="panel corpus-panel-top">
      <h4 [textContent]="translate('corpus_panel_Top_name', 'Top')"></h4>

      <label>
        <select
          class="form-select"
          [(ngModel)]="cabinetSection!.corpus.panelTop"
          (ngModelChange)="setChanged(); panel = 'top'"
        >
          <option
            *ngFor="let type of cabinetSection!.corpus.availablePanelsTop"
            [ngValue]="type"
          >
            {{getPanelName(type)}}
          </option>
        </select>
      </label>

      <button
        class="btn btn-default btn-block"
        (click)="panel = 'top'"
        [textContent]="translate('corpus_panel_edit_top', 'Edit')"
      ></button>
    </div>

    <div class="panel corpus-panel-left">
      <h4 [textContent]="translate('corpus_panel_left_name', 'Left')"></h4>

      <label>
        <select
          class="form-select"
          [(ngModel)]="leftCorpus.panelLeft"
          (ngModelChange)="setChanged(); panel = 'left'"
        >
          <option
            *ngFor="let type of cabinetSection!.corpus.availablePanelsLeft"
            [ngValue]="type"
          >
            {{getPanelName(type)}}
          </option>
        </select>
      </label>

      <button
        class="btn btn-default btn-block"
        (click)="panel = 'left'"
        [textContent]="translate('corpus_panel_edit_left', 'Edit')"
      ></button>
    </div>

    <div class="panel corpus-panel-right">
      <h4 [textContent]="translate('corpus_panel_right_name', 'Right')"></h4>
      <label>
        <select
          class="form-select"
          [(ngModel)]="rightCorpus.panelRight"
          (ngModelChange)="setChanged(); panel = 'right'"
        >
          <option
            *ngFor="let type of cabinetSection!.corpus.availablePanelsRight"
            [ngValue]="type"
          >
            {{getPanelName(type)}}
          </option>
        </select>
      </label>

      <button
        class="btn btn-default btn-block"
        (click)="panel = 'right'"
        [textContent]="translate('corpus_panel_edit_right', 'Edit')"
      ></button>
    </div>

    <div class="panel corpus-panel-bottom">
      <h4 [textContent]="translate('corpus_panel_bottom_name', 'Bottom')"></h4>

      <label>
        <select
          class="form-select"
          [(ngModel)]="cabinetSection!.corpus.panelBottom"
          (ngModelChange)="setChanged(); panel='bottom'"
        >
          <option
            *ngFor="let type of cabinetSection!.corpus.availablePanelsBottom"
            [ngValue]="type"
          >
            {{getPanelName(type)}}
          </option>
        </select>
      </label>
      <button
        class="btn btn-default btn-block"
        (click)="panel = 'bottom'"
        [ngClass]="{'disabled': !cabinetSection!.corpus.panelBottom}"
        [textContent]="translate('corpus_panel_edit_bottom', 'Edit')"
      ></button>
    </div>

    <div class="corpus-panel corpus-panel-light" *ngIf="showLightSelection">
      <h4 [textContent]="translate('corpus_panel_light_name', 'Lights')"></h4>

      <label>
        <select
          class="form-select"
          [(ngModel)]="pickableLightingProduct"
          (ngModelChange)="setChanged(); panel = 'lights'"
        >
          <option
            *ngFor="let plp of cabinetSection!.corpus.pickableLightingProducts"
            [ngValue]="plp"
          >
            {{plp.name}}
          </option>
        </select>
      </label>
      <button
        class="btn btn-default btn-block"
        (click)="panel = 'lights'"
        [ngClass]="{'disabled': !pickableLightingProduct.item}"
        [textContent]="translate('corpus_panel_edit_lights', 'Edit')"
      ></button>
    </div>
  </div>

  <div class="ribbon-menu">
    <div class="ribbon-block checkbox-ribbon-block">
      <label>
        <input type="checkbox" [(ngModel)]="showDoors3d" />
        <span
          [textContent]="translate('editor_label_show_doors', 'Show Doors')"
        ></span>
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showInterior3d" />
        <span
          [textContent]="translate('editor_label_show_interior', 'Show Interior')"
        ></span>
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showOtherCabinets3d" />
        <span
          [textContent]="translate('editor_label_show_other_cabinets', 'Show other cabinets')"
        ></span>
      </label>
    </div>

    <div class="ribbon-block ribbon-block-material">
      <pickable
        (click)="selectCorpusMaterial()"
        [pickable]="corpusMaterial"
        class="material corpus-material modal-opener"
      ></pickable>
    </div>

    <div class="ribbon-block ribbon-block-vertical xsmall">
      <div class="input-group input-group-sm">
        <span
          class="input-group-text medium-addon"
          [textContent]="translate('corpus_cabinet_section_label_width', 'Width')"
        ></span>
        <input
          type="number"
          class="form-control"
          min="0"
          [value]="cabinetSectionWidth"
          (change)="updateCabinetSectionWidth($event)"
        />
        <span class="input-group-text">mm</span>
      </div>

      <div class="input-group input-group-sm">
        <span
          class="input-group-text medium-addon"
          [textContent]="translate('corpus_cabinet_section_label_height', 'Height')"
        ></span>
        <input
          type="number"
          min="0"
          class="form-control"
          [value]="cabinetSectionHeight"
          (change)="updateCabinetSectionHeight($event)"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>

    <div
      class="ribbon-block ribbon-block-vertical small"
      *ngIf="!cabinetIsSwingFlex"
    >
      <div class="input-group input-group-sm">
        <span
          class="input-group-text medium-addon"
          [textContent]="translate('corpus_cabinet_section_label_depth', 'Depth')"
        ></span>
        <input
          type="number"
          min="0"
          class="form-control"
          [value]="cabinetSectionDepth"
          (change)="updateCabinetSectionDepth($event)"
          [attr.min]="cabinetSection?.minDepth"
          [attr.max]="cabinetSection?.maxDepth"
          [disabled]="!cabinetSectionDepthEditable"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>

    <div
      class="ribbon-block ribbon-block-vertical small"
      *ngIf="cabinetIsSwingFlex"
    >
      <div class="input-group input-group-sm">
        <span
          class="input-group-text long-addon"
          [textContent]="translate('corpus_cabinet_section_label_corpusDepth', 'Corpus Depth')"
        ></span>
        <input
          type="number"
          min="0"
          class="form-control"
          [value]="cabinetSectionDepth"
          (change)="updateCabinetSectionDepth($event)"
          [attr.min]="cabinetSection?.minDepth"
          [attr.max]="cabinetSection?.maxDepth"
        />
        <span class="input-group-text">mm</span>
      </div>

      <div class="input-group input-group-sm">
        <span
          class="input-group-text long-addon"
          [textContent]="translate('corpus_cabinet_section_label_interiorDepth', 'Interior Depth')"
        ></span>
        <input
          type="number"
          class="form-control"
          [value]="interiorDepth"
          [disabled]="true"
        />
        <span class="input-group-text">mm</span>
      </div>
    </div>

    <div
      class="ribbon-block ribbon-block-vertical small checkbox-ribbon-block"
      *ngIf="cabinetIsSwingFlex"
    >
      <div class="input-group input-group-sm">
        <span
          class="input-group-text long-addon"
          [textContent]="translate('corpus_cabinet_swingFlex_label_depth', 'SwingFlex Depth')"
        ></span>
        <input
          type="number"
          class="form-control"
          [value]="swingFlexDepth"
          (change)="updateSwingFlexDepth($event)"
        />
        <span class="input-group-text">mm</span>
      </div>

      <label>
        <input type="checkbox" [(ngModel)]="coverDoors" />
        <span
          [textContent]="translate('corpus_cabinet_swingFlex_label_coverDoors', 'Extend corpus to cover doors')"
        ></span>
      </label>
    </div>

    <div class="flex-spacer"></div>

    <div class="ribbon-block right-menu-visibility-ribbon-block">
      <button class="btn btn-default" (click)="showPanel = !showPanel">
        <span
          class="glyphicon"
          [ngClass]="showPanel ? 'bi-chevron-right' : 'bi-chevron-left'"
        ></span>
      </button>
    </div>
  </div>
  <div class="editor-center">
    <d3-display
      [cabinetSection]="cabinetSection"
      [showDoors]="showDoors3d"
      [showInterior]="showInterior3d"
      [showOtherCabinets]="showOtherCabinets3d"
      [showCorpus]="true"
      [showRulers]="false"
      [showPartitions]="false"
      [resizer]="d3Resizer"
      [allowMoveInterior]="false"
    ></d3-display>
    <div
      class="corpus-measurements-overlay"
      *ngIf="!cabinetSection?.isSwingFlex"
    >
      <div>
        <table>
          <tr>
            <td
              [textContent]="translate('corpus_specs_interior_width', 'Interior width')"
            ></td>
            <td [textContent]="interiorWidth"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_sight_width', 'Sight width')"
            ></td>
            <td [textContent]="sightWidth"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_interior_height', 'Interior height')"
            ></td>
            <td [textContent]="interiorHeight"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_doors_height', 'Sight height')"
            ></td>
            <td [textContent]="sightHeight"></td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="corpus-measurements-overlay"
      *ngIf="cabinetSection?.isSwingFlex"
    >
      <div>
        <table>
          <tr>
            <td
              [textContent]="translate('corpus_specs_swingflex_total_width', 'Total width')"
            ></td>
            <td [textContent]="swingFlexTotalWidth"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_swingflex_swing_width', 'Swing width')"
            ></td>
            <td [textContent]="swingFlexSwingWidth"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_swingflex_total_height', 'Total height')"
            ></td>
            <td [textContent]="swingFlexTotalHeight"></td>
          </tr>
          <tr>
            <td
              [textContent]="translate('corpus_specs_swingflex_swing_height', 'Swing height')"
            ></td>
            <td [textContent]="swingFlexSwingHeight"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="tool-menu right-tool-menu" *ngIf="showPanel">
    <div class="card">
      <div *ngIf="panel === 'top'">
        <h4 [textContent]="translate('corpus_panel_Top_name', 'Top')"></h4>

        <label>
          <span
            [textContent]="translate('corpus_panel_Top_size_label', 'Height')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [value]="heightTop"
              [disabled]="heightTopFixed"
              [attr.min]="cabinetSection!.corpus.minHeightTop"
              [attr.max]="cabinetSection!.corpus.maxHeightTop"
              (change)="setHeightTop($event)"
            />
            <span class="input-group-text">mm</span>
          </span>
        </label>

        <label *ngFor="let itemVariant of itemVariantsTop">
          <span
            *ngIf="itemVariant.OnlyAdmin"
            [attr.title]="translate('editor_variant_only_admin', 'This variant can only be changed by support')"
            class="bi bi-exclamation-triangle-fill"
            style="color: goldenrod"
          ></span>
          <span [textContent]="itemVariant.variant?.DefaultName"></span>
          <select
            class="form-select"
            [(ngModel)]="itemVariant.variantOption"
            (ngModelChange)="setChanged()"
          >
            <option
              *ngFor="let opt of itemVariant.variant?.VariantOptions"
              [ngValue]="opt"
            >
              {{opt.DefaultName}}
            </option>
          </select>
        </label>
      </div>

      <div *ngIf="panel === 'bottom'">
        <h4
          [textContent]="translate('corpus_panel_bottom_name', 'Bottom')"
        ></h4>

        <label>
          <span
            [textContent]="translate('corpus_panel_bottom_size_label', 'Height')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [value]="heightBottom"
              [disabled]="heightBottomFixed"
              [min]="cabinetSection!.corpus.minHeightBottom"
              [max]="cabinetSection!.corpus.maxHeightBottom"
              (change)="setHeightBottom($event)"
            />
            <span class="input-group-text">mm</span>
          </span>
        </label>
        <label *ngFor="let itemVariant of itemVariantsBottom">
          <span [textContent]="itemVariant.variant?.DefaultName"></span>
          <select
            class="form-select"
            [(ngModel)]="itemVariant.variantOption"
            (ngModelChange)="setChanged()"
          >
            <option
              *ngFor="let opt of itemVariant.variant?.VariantOptions"
              [ngValue]="opt"
            >
              {{opt.DefaultName}}
            </option>
          </select>
        </label>
      </div>

      <div *ngIf="panel === 'left'">
        <h4 [textContent]="translate('corpus_panel_left_name', 'Left')"></h4>

        <label>
          <span
            [textContent]="translate('corpus_panel_left_size_label', 'Width')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [disabled]="widthLeftFixed"
              [value]="widthLeft"
              [min]="leftCorpus.minWidthLeft"
              [max]="leftCorpus.maxWidthLeft"
              (change)="setWidthLeft($event)"
            />
            <span class="input-group-text">mm</span>
          </span>
        </label>

        <label *ngIf="leftCorpus.heightReductionLeftPossible">
          <input type="checkbox" [(ngModel)]="heightReductionLeft" />
          <span
            [textContent]="translate('corpus_panel_heightreduction', 'Height reduction')"
          ></span>
          <span
            *ngIf="leftCorpus.showHeightReductionOverrideWarningLeft"
            [attr.title]="translate('editor_variant_only_admin', 'This variant can only be changed by support')"
            class="bi bi-exclamation-triangle-fill"
            style="color: goldenrod"
          ></span>
        </label>

        <label *ngFor="let itemVariant of itemVariantsLeft">
          <span [textContent]="itemVariant.variant?.DefaultName"></span>
          <select
            class="form-select"
            [(ngModel)]="itemVariant.variantOption"
            (ngModelChange)="setChanged()"
          >
            <option
              *ngFor="let opt of itemVariant.variant?.VariantOptions"
              [ngValue]="opt"
            >
              {{opt.DefaultName}}
            </option>
          </select>
        </label>
      </div>

      <div *ngIf="panel === 'right'">
        <h4 [textContent]="translate('corpus_panel_right_name', 'Right')"></h4>

        <label>
          <span
            [textContent]="translate('corpus_panel_right_size_label', 'Width')"
          ></span>
          <span class="input-group">
            <input
              type="number"
              class="form-control"
              [value]="widthRight"
              [disabled]="widthRightFixed"
              [min]="rightCorpus.minWidthRight"
              [max]="rightCorpus.maxWidthRight"
              (change)="setWidthRight($event)"
            />
            <span class="input-group-text">mm</span>
          </span>
        </label>

        <label *ngIf="rightCorpus.heightReductionRightPossible">
          <input type="checkbox" [(ngModel)]="heightReductionRight" />
          <span
            [textContent]="translate('corpus_panel_heightreduction', 'Height reduction')"
          ></span>
          <span
            *ngIf="rightCorpus.showHeightReductionOverrideWarningRight"
            [attr.title]="translate('editor_variant_only_admin', 'This variant can only be changed by support')"
            class="bi bi-exclamation-triangle-fill"
            style="color: goldenrod"
          ></span>
        </label>

        <label *ngFor="let itemVariant of itemVariantsRight">
          <span [textContent]="itemVariant.variant?.DefaultName"></span>
          <select
            class="form-select"
            [(ngModel)]="itemVariant.variantOption"
            (ngModelChange)="setChanged()"
          >
            <option
              *ngFor="let opt of itemVariant.variant?.VariantOptions"
              [ngValue]="opt"
            >
              {{opt.DefaultName}}
            </option>
          </select>
        </label>
      </div>

      <div *ngIf="panel === 'lights'">
        <h4 [textContent]="translate('corpus_panel_light_name', 'Lights')"></h4>

        <pickable
          class="product lighting-product"
          [pickable]="pickableLightingProduct"
        ></pickable>

        <div class="input-group">
          <span
            class="input-group-text"
            [textContent]="translate('editor_corpus_num_lights', 'Lights')"
          ></span>
          <input
            class="form-control"
            type="number"
            [(ngModel)]="cabinetSection!.corpus.numberOfLights"
            [ngModelOptions]="modelOptions"
            [min]="cabinetSection!.corpus.numberOfLightsMin"
            [max]="cabinetSection!.corpus.numberOfLightsMax"
            (ngModelChange)="setChanged()"
          />
        </div>

        <div
          *ngIf="cabinetSection!.corpus.pickableLightingMaterials.length > 1"
        >
          <label>
            <span
              [textContent]="translate('corpus_panel_light_material', 'Material')"
            ></span>
            <pickable
              (click)="selectLightingMaterial()"
              [pickable]="lightingMaterial"
              [attr.title]="translate('editor_corpus_light_material', 'Light material')"
              *ngIf="cabinetSection!.corpus.pickableLightingMaterials.length > 1"
              class="material lighting-material modal-opener"
            ></pickable>
          </label>
        </div>
      </div>
    </div>
  </div>

  <corner-name-bubble [floorPlan]="floorPlan"></corner-name-bubble>
</div>
