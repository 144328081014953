import { Pipe, PipeTransform } from '@angular/core';
import { FloorPlan } from 'app/ts/clientDto';
import { PartitionPlan } from '../partition-plan-data.service';
import { calculatePlan, Plan } from './plan';

@Pipe({
  name: 'calculatePlan',
  pure: false,
})
export class CalculatePlanPipe implements PipeTransform {
  constructor() {}

  transform(
    partitionPlan: Readonly<PartitionPlan> | null,
    floorPlan: FloorPlan,
  ): Plan {
    if (partitionPlan == null) return new Plan([], []);
    return calculatePlan(partitionPlan, floorPlan);
  }
}
