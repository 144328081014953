<topbar
  [allowChangeStore]="true"
  [allowChangeFullCatalog]="true"
  [parent]="this"
>
  <ul class="nav navbar-nav">
    <li>
      <div *ngIf="parent.showAllFloorplansOnEmptySearch">
        <form class="navbar-form">
          <div class="input-group">
            <input
              type="text"
              #quicksearch
              autofocus
              class="form-control search-input"
              accesskey="s"
              [placeholder]="
                parent.translate(
                  'customers_quicksearch_placeholder',
                  'Quick Search (alt-s)'
                )
              "
              [ngModelOptions]="parent.quickSearchModelOptions"
              [(ngModel)]="quickSearchString"
              (ngModelChange)="updateCarat()"
              name="quickSearch"
            />
          </div>
          <div class="btn-group">
            <button
              class="btn btn-default"
              type="button"
              [ngClass]="{ active: parent.mineOnly }"
              (click)="mineOnlyClicked()"
            >
              <span
                [ngClass]="parent.mineOnly ? 'bi-check2-square' : 'bi-square'"
              ></span>
              <span
                [textContent]="
                  parent.translate('customers_search_mine_only', 'Mine only')
                "
              ></span>
            </button>
          </div>

          <div class="btn-group">
            <button
              class="btn btn-default"
              type="button"
              (click)="parent.statusSearch = 'all'"
              [ngClass]="{ active: parent.statusSearch === 'all' }"
              [textContent]="
                parent.translate(
                  'customers_search_order_status_all',
                  'All orders'
                )
              "
            ></button>
            <button
              class="btn btn-default"
              type="button"
              (click)="parent.statusSearch = 'quote'"
              [ngClass]="{ active: parent.statusSearch === 'quote' }"
              [textContent]="
                parent.translate('customers_search_order_status_quote', 'Quote')
              "
            ></button>
            <button
              class="btn btn-default"
              type="button"
              (click)="parent.statusSearch = 'order'"
              [ngClass]="{ active: parent.statusSearch === 'order' }"
              [textContent]="
                parent.translate(
                  'customers_search_order_status_order',
                  'Orders'
                )
              "
            ></button>
            <button
              class="btn btn-default"
              type="button"
              (click)="parent.statusSearch = 'delivered'"
              [ngClass]="{ active: parent.statusSearch === 'delivered' }"
              [textContent]="
                parent.translate(
                  'customers_search_order_status_delivered',
                  'Delivered'
                )
              "
            ></button>
          </div>
        </form>
      </div>
      <div *ngIf="!parent.showAllFloorplansOnEmptySearch">
        <form class="navbar-form" autocomplete="off">
          <div
            class="input-group"
            ngbDropdown
            [open]="parent.customerSuggestionsOpen"
          >
            <input
              #customerquicksearch
              type="text"
              class="form-control search-input"
              accesskey="s"
              onfocus="this.select()"
              autofocus
              (click)="parent.customerSearchFocus()"
              [placeholder]="
                parent.translate('customers_search', 'Search for customer')
              "
              [(ngModel)]="parent.customerSearchString"
              name="customerSearch"
            />

            <ul
              class="dropdown-menu customer-suggestion-menu"
              ngbDropdownMenu
              role="menu"
            >
              <li
                role="menuitem"
                ngbDropdownItem
                class="customer-suggestion-item"
                *ngFor="let customer of parent.customerSuggestions"
                (click)="parent.setSelectedCustomer(customer)"
                [textContent]="customer.name"
              ></li>
            </ul>
          </div>

          <div class="btn-group">
            <button
              class="btn btn-default"
              type="button"
              [ngClass]="{ active: parent.showAll }"
              (click)="showAllClicked()"
            >
              <span
                class="bi bi-check-square"
                [ngClass]="parent.showAll ? 'bi-check-square' : 'bi-square'"
                style="margin-right: 5px"
              ></span>
              <span
                [textContent]="
                  parent.translate('customers_search_show_all', 'Show all')
                "
              ></span>
            </button>
          </div>

          <div class="btn-group">
            <button
              class="btn btn-default"
              type="button"
              (click)="parent.resetSesarch()"
            >
              <span
                [textContent]="
                  parent.translate('customers_search_reset', 'Reset search')
                "
              ></span>
            </button>
          </div>
        </form>
      </div>
    </li>
  </ul>
  <ul class="nav navbar-nav">
    <li>
      <div class="btn-group">
        <a
          class="bi refresh btn btn-default"
          [ngClass]="{
            'btn-loading': parent.isUpdatingCustomers,
            active: parent.isUpdatingCustomers,
          }"
          (click)="parent.reload()"
          [attr.title]="
            parent.translate('customers_update_button_title', 'Update')
          "
        >
          <span class="bi refresh bi-arrow-clockwise"></span>
        </a>
      </div>
    </li>
  </ul>
  <ng-content></ng-content>
</topbar>
