import { ObjectHelper } from 'app/ts/util/ObjectHelper';
export class UndoStack<T> {
  constructor() {
    this.resetStack(null);
  }

  private currentId: number | null = null;
  private stack: T[] = [];
  private currentPos: number = 0;

  public begin(floorPlanId: number | null) {
    this.resetStack(floorPlanId, true);
  }

  public push(floorPlanId: number | null, cfp: T) {
    this.resetStack(floorPlanId);
    this.stack = this.stack.slice(0, this.currentPos + 1);
    this.stack.push(ObjectHelper.copy(cfp));
    this.currentPos = this.stack.length - 1;
  }

  public back(id: number | null): T | null {
    this.resetStack(id);
    if (this.currentPos <= 0) return null;
    this.currentPos--;
    return ObjectHelper.copy(this.stack[this.currentPos]);
  }

  public forward(id: number | null): T | null {
    this.resetStack(id);
    if (this.currentPos >= this.stack.length - 1) return null;
    this.currentPos++;
    return ObjectHelper.copy(this.stack[this.currentPos]);
  }

  public canUndo(id: number | null): boolean {
    if (id !== this.currentId) return false;
    return this.currentPos > 0;
  }
  public canRedo(id: number | null): boolean {
    if (id !== this.currentId) return false;
    return this.currentPos < this.stack.length - 1;
  }

  private resetStack(id: number | null, force: boolean = false) {
    if (force || id !== this.currentId) {
      this.stack = [];
      this.currentPos = -1;
      this.currentId = id;
    }
  }
}
