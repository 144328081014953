import { Component, Input } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { Observable } from 'app/ts/util/Observable';

@Component({
  selector: 'interior-product-picker',
  templateUrl: './interiorProductPicker.html',
  styleUrls: ['./interiorProductPicker.scss'],
})
export class InteriorProductPicker {
  constructor() {}

  //region angular properties
  @Input()
  public category: Client.InteriorProductCategory | undefined;
  @Input()
  public currentDragInfoObservable = new Observable<
    Client.ProductDragInfo | undefined
  >(undefined);
}
