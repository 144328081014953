import { ModuleData } from '../module';
import { Partition } from '../partition';
import { PartitionPlanData } from '../partition-plan-data.service';
import { Profile, ProfileData } from '../profile';
import { SectionId } from '../section';
import { Migration } from './json-migrator';

export default class PartitionArrayMigration implements Migration {
  public needsMigration(plan: PartitionPlanData): boolean {
    return plan.partitions == undefined;
  }
  public migrate(plan: PartitionPlanData): void {
    let connectedSections = plan.sections.map((sec) =>
      this.getAllSectionsConnectedToSection(plan, sec.id).sort(),
    );

    // Remove duplicates
    let stringifiedConnections = connectedSections.map((ls) =>
      JSON.stringify(ls),
    );
    let dupeFreeList = new Set(stringifiedConnections);
    let unique = [...dupeFreeList].map((str) => JSON.parse(str));

    let nameDict: { [key: string]: number } = {};

    let partitions: Partition[] = unique.map((un, idx) => {
      let oldPartition = this.getPartitionForSection(plan, un[0]);

      let name = oldPartition.name;
      if (name in nameDict) {
        let newName = name + ' ' + nameDict[name];
        nameDict[name] += 1;
        name = newName;
      } else {
        nameDict[name] = 1;
      }

      return {
        id: idx,
        name: name,
        sections: un,
      } as Partition;
    });

    plan.partitions = partitions;
  }

  private getAllSectionsConnectedToSection(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): SectionId[] {
    let checkedSections: SectionId[] = [];
    let sectionsToCheck: SectionId[] = [sectionId];

    while (sectionsToCheck.length > 0) {
      let curSection: SectionId | null = sectionsToCheck.shift()!;
      checkedSections.push(curSection);

      let leftProfile = this.getSectionLeftProfile(plan, curSection);
      if (leftProfile) {
        leftProfile.sections.forEach((secCon) => {
          if (
            !checkedSections.includes(secCon.sectionId) &&
            !sectionsToCheck.includes(secCon.sectionId)
          ) {
            sectionsToCheck.push(secCon.sectionId);
          }
        });
      }

      let rightProfile = this.getSectionRightProfile(plan, curSection);
      if (rightProfile) {
        rightProfile.sections.forEach((secCon) => {
          if (
            !checkedSections.includes(secCon.sectionId) &&
            !sectionsToCheck.includes(secCon.sectionId)
          ) {
            sectionsToCheck.push(secCon.sectionId);
          }
        });
      }
    }

    return checkedSections;
  }

  private getSectionLeftProfile(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): ProfileData | undefined {
    let leftModule = this.getHeadModuleForSection(plan, sectionId);
    return plan.profiles.find(
      (prf) => prf.id == leftModule.references.leftProfileId,
    );
  }

  private getSectionRightProfile(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): ProfileData | undefined {
    let rightModule = this.getTailModuleForSection(plan, sectionId);
    return plan.profiles.find(
      (prf) => prf.id == rightModule.references.rightProfileId,
    );
  }

  /**
   * Get the left outermost module of the section
   * @param sectionId
   * @returns
   */
  public getTailModuleForSection(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): ModuleData {
    const allModules = plan.modules;
    return allModules
      .filter((md) => md.sectionId == sectionId)!
      .find(
        (md) => md.references.nextSectionModuleId == undefined,
      )! as ModuleData;
  }

  /**
   * Get the right outermost module of the section
   * @param sectionId
   * @returns
   */
  public getHeadModuleForSection(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): ModuleData {
    let allModules = plan.modules;
    return allModules
      .filter((md) => md.sectionId == sectionId)!
      .find(
        (md) => md.references.previousSectionModuleId == undefined,
      )! as ModuleData;
  }

  private getPartitionForSection(
    plan: PartitionPlanData,
    sectionId: SectionId,
  ): Partition {
    return plan.partitions.find((pt) => pt.sections.includes(sectionId))!;
  }
}
