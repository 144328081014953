import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActiveUser,
  ActiveUserInjector,
  activeUserProvider,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import {
  ClientSetting,
  ClientSettingInjector,
  clientSettingProvider,
} from 'app/functional-core/ambient/clientSetting/ClientSetting';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { NavigateService } from 'app/routing/navigate-service';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { CustomerBaseVmService } from 'app/ts/services/CustomerBaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { DeliveryAddressesTableVm } from 'app/ts/viewmodels/components/DeliveryAddressesTableVm';
import { CustomerBaseVm } from 'app/ts/viewmodels/CustomerBaseVm';
import Enumerable from 'linq';

@Component({
  selector: 'delivery-addresses-vm',
  templateUrl: './deliveryAddresses.html',
  styleUrls: [
    '../../../style/overviews.scss',
    '../../../style/tables.scss',
    '../../../style/nav.scss',
  ],
  providers: [clientSettingProvider, activeUserProvider],
})
export class DeliveryAddressesVm extends CustomerBaseVm<Interface_DTO.DeliveryAddress> {
  public objects: Client.DeliveryAddress[] = [];
  public editingDeliveryAddress: Client.DeliveryAddress | undefined;
  public numCopies: number = 1;

  public object!: Client.DeliveryAddress;

  private readonly actions: { [action: string]: boolean } = {
    copy: false,
    save: false,
    delete: false,
  };

  constructor(
    customerBaseVmService: CustomerBaseVmService,
    router: Router,
    activatedRoute: ActivatedRoute,
    private readonly navigate: NavigateService,
    private readonly translationService: TranslationService,
    private readonly formattingService: FormattingService,
    private readonly routing: AppRoutingModule,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    @Inject(ClientSettingInjector) clientSettings: ClientSetting,
  ) {
    super(
      customerBaseVmService,
      router,
      activatedRoute,
      activeUser,
      clientSettings,
    );
  }

  public override setSelected(clientDeliveryAddress: Client.DeliveryAddress) {
    super.setSelected(clientDeliveryAddress);
    if (
      !this.editingDeliveryAddress ||
      this.editingDeliveryAddress.Id !== clientDeliveryAddress.Id
    ) {
      this.editingDeliveryAddress = clientDeliveryAddress;
    }
  }

  public override closeRightMenu() {
    super.closeRightMenu();
    this.editingDeliveryAddress = undefined;
  }

  protected onSearchUpdated(customers: Client.Customer[]) {
    this.objects = Enumerable.from(customers)
      .selectMany((cust) => cust.Projects)
      .selectMany((proj) => proj.DeliveryAddresses)
      .toArray();
    let hashIds = this.hashIds;
    for (let obj of this.objects) {
      if (hashIds[obj.ProjectDeliveryAddressId]) {
        obj.selected = true;
        if (
          !this.editingDeliveryAddress ||
          this.editingDeliveryAddress.Id !== obj.Id
        ) {
          this.editingDeliveryAddress = obj;
        }
      }
    }
  }

  public get parentObject(): Client.Project | null {
    const projectId = this.filterParams.projectId;
    if (!projectId) return null;
    const projectIdNumber = parseInt(projectId);
    for (let customer of this.allCustomers) {
      for (let project of customer.Projects)
        if (project.Id === projectIdNumber) {
          return project;
        }
    }
    return null;
  }

  public async newObject() {
    let parentProject = this.parentObject;
    if (!parentProject)
      throw new Error(
        "Don't know which project to create a delivery address for",
      );
    let modalResult = await this.modalService.getNewDeliveryAddressModalChained(
      parentProject,
      parentProject.customer,
      'floorplan',
    );

    switch (modalResult.type) {
      case 'none':
        return;
      case 'deliveryAddress':
        this.navigate.floorPlans({
          ...this.filterParams,
          deliveryAddressId: modalResult.projectDeliveryAddressId.toString(),
        });
        return;
      case 'floorPlan':
        this.navigate.floorPlan(modalResult.floorPlanId);
        return;

      default:
        throw new Error(
          'Unknown modal result type: ' + (<any>modalResult).type,
        );
    }
  }

  public async selectColumns() {
    const possibleColumns = DeliveryAddressesTableVm.getColumns(
      this.translationService,
      this.formattingService,
    );
    let currentColumnNames = this.clientSettings.data.columnsDeliveryAddress;
    let newCols = await this.modalService.selectColumns(
      possibleColumns,
      currentColumnNames,
    );
    if (newCols) {
      const newSettings = {
        ...this.clientSettings.data,
      };
      newSettings.columnsDeliveryAddress = newCols;
      this.clientSettings.set(newSettings);
    }
  }

  public showAncestor(address: Client.DeliveryAddress) {
    const query = {
      ...this.filterParams,
      selected: [address.project.Id.toString()],
    };
    this.routing.navigate.projects(query);
  }

  public showChildren(address: Client.DeliveryAddress) {
    const query = {
      ...this.filterParams,
      deliveryAddressId: address.ProjectDeliveryAddressId.toString(),
    };

    this.routing.navigate.floorPlans(query);
  }

  public async deleteSelected() {}

  protected getId(o: Interface_DTO.DeliveryAddress) {
    return o.ProjectDeliveryAddressId;
  }
}
