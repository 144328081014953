import { Component } from '@angular/core';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { VersionService } from 'app/ts/services/VersionService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'app-version',
  templateUrl: './appVersion.html',
})
export class AppVersionVm extends BaseVm {
  constructor(
    baseVmService: BaseVmService,
    private readonly versionService: VersionService,
  ) {
    super(baseVmService);

    this.clientVersion = this.versionService.clientVersion;

    this.versionService.checkVersion();
  }

  public readonly clientVersion: string;

  public get versionStatus(): string {
    return this.versionService.displayHasNewAppVersion
      ? this.translate(
          'app_version_outdated',
          'Outdated - Please pefresh the page to get the newest version',
        )
      : this.translate('app_version_uptodate', 'You have the newest version');
  }
}
