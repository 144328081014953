<div class="right-menu wide full" *ngIf="project">
  <div class="title">
    <div class="title-left"></div>
    <div
      class="title-center"
      [textContent]="translate('projects_menu_single_header_{0}', 'Project: {0}', project.Name)"
    ></div>
    <div class="title-right">
      <button class="close" (click)="close()">&times;</button>
    </div>
  </div>
  <div class="body">
    <form class="object-detail project-detail">
      <project-edit [detailObject]="project"> </project-edit>
      <div class="btn-group btn-group-right">
        <button
          class="btn btn-primary"
          type="submit"
          (click)="save()"
          [textContent]="translate('detail_button_save', 'Save')"
        ></button>

        <button
          class="btn btn-secondary"
          type="button"
          (click)="close()"
          [textContent]="translate('detail_button_cancel', 'Cancel')"
        ></button>
      </div>
    </form>

    <button
      class="btn btn-default"
      (click)="showChildren()"
      *ngIf="showShowChildren"
      [textContent]="translate('projects_button_view_delivery_addresses', 'view delivery addresses')"
    ></button>

    <button
      class="btn-danger"
      (click)="delete()"
      [attr.title]="translate('button_delete', 'Delete')"
    >
      <span class="bi bi-trash3"></span>
    </button>
  </div>
</div>
