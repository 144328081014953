<partition-property-sheet-ui
  *ngIf="query.selectedSection && query.selectedModule"
  [name]="partitionName"
  [floorPlan]="floorPlan"
  [section]="selectedSection"
  [module]="query.selectedModule"
  (nameChange)="nameChange($event)"
  (sectionWidthChange)="sectionWidthChange($event)"
  (numberOfModulesChange)="numberOfModulesChange($event)"
  (deletePartition)="deletePartition()"
  (deleteSection)="deleteSection()"
  (rotateSectionLeft)="rotateSectionLeft()"
  (rotateSectionRight)="rotateSectionRight()"
  (reverseSection)="reverseSection()"
  (unevenLeftWall)="unevenLeftWall($event)"
  (unevenRightWall)="unevenRightWall($event)"
  (distanceToLeftWallChange)="updateDistanceToLeftWall($event)"
  (distanceToRightWallChange)="updateDistanceToRightWall($event)"
  (distanceToTopWallChange)="updateDistanceToTopWall($event)"
  (distanceToBottomWallChange)="updateDistanceToBottomWall($event)"
></partition-property-sheet-ui>
