import { Component, Input } from '@angular/core';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.html',
  styleUrls: ['../pages/consumer.scss'],
})
export class NavbarVm extends BaseVm {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  //region angular properties

  @Input()
  currentId: string = '';

  //endregion

  locations: Loc[] = [
    {
      id: 'new',
      name: this.translate('consumer_navbar_page_start', 'Start'),
      url: '/dimensions',
    },
    {
      id: 'corpus',
      name: this.translate('consumer_navbar_page_corpus', 'Outer parts'),
      url: '/corpus',
    },
    {
      id: 'doors',
      name: this.translate('consumer_navbar_page_doors', 'Doors'),
      url: '/doors',
    },
    {
      id: 'interior',
      name: this.translate('consumer_navbar_page_interior', 'Interior'),
      url: '/interior',
    },
    {
      id: 'basket',
      name: this.translate('consumer_navbar_page_basket', 'Basket'),
      url: '/basket',
    },
  ];

  isCurrent(loc: Loc): boolean {
    return loc.id === this.currentId;
  }
}

interface Loc {
  id: string;
  name: string;
  url: string;
}
