import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { LoginService } from 'app/ts/services/LoginService';
import { ModalService } from 'app/ts/services/ModalService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TemplateService } from 'app/ts/services/TemplateService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { IConsumerPage } from 'app/ts/viewmodels/consumer/pages/IConsumerPage';
import { CabinetSectionService } from './cabinet-section.service';

@Component({
  selector: 'dimensions-vm',
  templateUrl: './dimensions.html',
  styleUrls: ['common.scss', 'dimensions.scss'],
})
export class DimensionsVm extends BaseVm implements IConsumerPage {
  private static isWidthSet = false;
  private static isHeightSet = false;

  public displayDimensionHelp = false;

  private cabinetSection!: Client.CabinetSection;

  constructor(
    baseVmService: BaseVmService,
    private readonly cabinetSections: CabinetSectionService,
    private readonly floorPlanService: FloorPlanService,
    private readonly templateService: TemplateService,
    private readonly loginService: LoginService,
    private readonly modalService: ModalService,
    private routing: AppRoutingModule,
  ) {
    super(baseVmService);

    this.cabinetSections.cabinetSection$
      .pipe(takeUntilDestroyed())
      .subscribe((cabinetSection) => {
        this.cabinetSection = cabinetSection;
        this.reloadTemplates();
      });
  }

  get width() {
    if (!DimensionsVm.isWidthSet) {
      return '';
    }
    return this.cabinetSection.Width;
  }
  set width(val: number | '') {
    if (typeof val !== 'number') {
      return;
    }
    DimensionsVm.isWidthSet = true;
    this.cabinetSection.Width = val;
    this.setDimensionsChanged();
  }

  get height() {
    if (!DimensionsVm.isHeightSet) {
      return '';
    }
    return this.cabinetSection.Height;
  }
  set height(val: number | '') {
    if (typeof val !== 'number') {
      return;
    }
    DimensionsVm.isHeightSet = true;
    this.cabinetSection.Height = val;
    this.floorPlan.Size = {
      ...this.floorPlan.Size,
      Z: Math.max(2100, val),
    };
    this.setDimensionsChanged();
  }

  get depth() {
    return this.cabinetSection.Depth;
  }
  set depth(val: number) {
    this.cabinetSection.Depth = val;
    this.setDimensionsChanged();
  }

  private _selectedTemplateId: number | undefined = undefined;
  get selectedTemplateId() {
    return this._selectedTemplateId;
  }

  public templates: Interface_DTO.Template[] | undefined = undefined;

  public setTemplate(template: Interface_DTO.Template) {
    this.templateService.applyTemplate(
      this.cabinetSection,
      template,
      505, //TODO soft-code
    );
    this.setChanged();
    this.next();
  }

  public setDefaultTemplate() {
    this.cabinetSection.interior.items = [];
    this.setChanged();
    this.next();
  }

  private setDimensionsChanged() {
    this.setChanged();
    this.reloadTemplates();
  }
  private setChanged() {
    this.floorPlanService.setChanged(this.floorPlan);
  }

  private async reloadTemplates() {
    this.templates = undefined;
    let newTemplates = await this.templateService.getTemplates(
      this.cabinetSection,
    );
    let newTemplate = newTemplates.filter(
      (t) => t.Id === this.selectedTemplateId,
    )[0];
    if (!newTemplate) {
      this._selectedTemplateId = undefined;
    }
    this.templates = newTemplates;
  }

  public get floorPlan() {
    return this.cabinetSection.cabinet.floorPlan;
  }

  public async loadFloorPlans() {
    if (!(await this.tryEnsureLoggedIn())) return;
    this.routing.Consumer_load();
  }

  private async tryEnsureLoggedIn(): Promise<boolean> {
    if (this.loginService.consumerUser) return true;
    let loginSuccess = await this.modalService.consumerLogin();
    return loginSuccess;
  }

  public next() {
    if (DimensionsVm.isHeightSet && DimensionsVm.isWidthSet) {
      this.routing.Consumer_corpus();
    } else {
      this.displayDimensionHelp = true;
    }
  }

  public previous() {
    //do nothing
  }
}
