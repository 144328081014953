import * as Enums from 'app/ts/clientDto/Enums';
import { PulloutWarningSeverity } from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { Backing } from 'app/ts/clientDto/SubSectionBacking';
import { ConfigurationItem } from 'app/ts/clientDto/ConfigurationItem';
import { Corner } from 'app/ts/clientDto/Corner';
import { Corpus } from 'app/ts/clientDto/Corpus';
import { CorpusPanel } from 'app/ts/clientDto/CorpusPanel';
import { Door } from 'app/ts/clientDto/Door';
import { Doors } from 'app/ts/clientDto/SubSectionDoors';
import { Swing } from 'app/ts/clientDto/SubSectionSwing';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
export class CabinetSectionHelper {
  //#region Other sections and corner

  /**
   * Determines if a given section has a least one corner
   */
  public static hasCorner(section: Client.CabinetSection): boolean {
    return (
      section.CabinetType == Interface_Enums.CabinetType.CornerCabinet ||
      section.CabinetType == Interface_Enums.CabinetType.WalkIn
    );
  }

  /**
   * Gets the section to the left of a given section
   */
  public static getSectionLeft(
    section: Client.CabinetSection,
  ): Client.CabinetSection | null {
    return section.leftNeighbor || null;
  }
  /**
   * Gets the section to the right of a given section
   */
  public static getSectionRight(
    section: Client.CabinetSection,
  ): Client.CabinetSection | null {
    return section.rightNeighbor || null;
  }

  public static hasCornerOpeningAffectingGableInCornerLeft(
    section: Client.CabinetSection,
  ): boolean {
    let sectionLeft = this.getSectionLeft(section);
    if (!sectionLeft) {
      return false;
    }

    let cornerMinX = this.getInteriorDeductionLeft(section);
    let cornerMaxX = sectionLeft.InteriorDepth;

    if (
      Enumerable.from(section.interior.items).any(
        (ii) =>
          ii.isGable &&
          ii.leftX < cornerMaxX &&
          ii.leftX > cornerMinX + Constants.sizeAndPositionTolerance,
      )
    ) {
      return true;
    }

    return false;
  }

  public static hasCornerOpeningAffectingGableInCornerRight(
    section: Client.CabinetSection,
  ): boolean {
    let sectionRight = this.getSectionRight(section);
    if (!sectionRight) {
      return false;
    }

    let cornerMinX = section.Width - sectionRight.InteriorDepth;
    let cornerMaxX = section.Width - this.getInteriorDeductionRight(section);

    if (
      Enumerable.from(section.interior.items).any(
        (ii) =>
          ii.isGable &&
          ii.rightX > cornerMinX &&
          ii.rightX < cornerMaxX - Constants.sizeAndPositionTolerance,
      )
    ) {
      return true;
    }

    return false;
  }

  public static getPartialCornerOpeningLeft(
    section: Client.CabinetSection,
  ): number {
    let partialOpening = 0;
    let sectionLeft = this.getSectionLeft(section);

    if (sectionLeft == null) {
      return partialOpening;
    }

    let minimumLeftX =
      this.getCornerAffectingDepthForInterior(sectionLeft) -
      this.backingOffsetZforInterior(sectionLeft) +
      this.getInteriorDeductionLeft(section);
    let closestGable = Enumerable.from(section.interior.items)
      .where((ii) => ii.isGable && ii.rightX > minimumLeftX)
      .orderBy((ii) => ii.centerX)
      .firstOrDefault();
    if (closestGable != null) {
      partialOpening = closestGable.leftX - minimumLeftX;
    } else {
      partialOpening = section.InteriorWidth - minimumLeftX;
      if (this.hasCornerRight(section)) {
        let sectionRight = this.getSectionRight(section);
        if (sectionRight != null) {
          partialOpening -=
            this.getCornerAffectingDepthForInterior(sectionRight);
        }
      }
    }

    return partialOpening;
  }

  public static getPartialCornerOpeningRight(
    section: Client.CabinetSection,
  ): number {
    let partialOpening = 0;
    let sectionRight = this.getSectionRight(section);

    if (sectionRight == null) {
      return partialOpening;
    }

    let maximumRightX =
      this.getInteriorDeductionLeft(section) +
      section.InteriorWidth +
      this.backingOffsetZforInterior(sectionRight) -
      this.getCornerAffectingDepthForInterior(sectionRight);
    let closestGable = Enumerable.from(section.interior.items)
      .where((ii) => ii.isGable && ii.leftX < maximumRightX)
      .orderBy((ii) => ii.centerX)
      .lastOrDefault();
    if (closestGable != null) {
      partialOpening = maximumRightX - closestGable.rightX;
    } else {
      partialOpening =
        section.InteriorWidth -
        this.getCornerAffectingDepthForInterior(sectionRight);
      if (this.hasCornerLeft(section)) {
        let sectionLeft = this.getSectionLeft(section);
        if (sectionLeft != null) {
          partialOpening -=
            this.getCornerAffectingDepthForInterior(sectionLeft);
        }
      }
    }

    return partialOpening;
  }

  public static hasCornerLeft(section: Client.CabinetSection): boolean {
    if (!this.hasCorner(section)) return false;

    let sectionLeft = this.getSectionLeft(section);
    return (
      sectionLeft != null && sectionLeft.CabinetType == section.CabinetType
    );
  }

  public static hasCornerRight(section: Client.CabinetSection): boolean {
    if (!this.hasCorner(section)) return false;

    let sectionRight = this.getSectionRight(section);
    return (
      sectionRight != null && sectionRight.CabinetType == section.CabinetType
    );
  }

  public static hasCornerLeftWithFreeSpace(
    section: Client.CabinetSection,
  ): boolean {
    let sectionLeft = this.getSectionLeft(section);
    if (sectionLeft == null) {
      return false;
    }

    return (
      section.InteriorFreeSpaceLeft > 0 ||
      sectionLeft.InteriorFreeSpaceRight > 0
    );
  }

  public static hasCornerRightWithFreeSpace(
    section: Client.CabinetSection,
  ): boolean {
    let sectionRight = this.getSectionRight(section);
    if (sectionRight == null) {
      return false;
    }

    return (
      section.InteriorFreeSpaceRight > 0 ||
      sectionRight.InteriorFreeSpaceLeft > 0
    );
  }

  public static getAdjacentSectionDepth(
    section: Client.CabinetSection,
    left: boolean,
  ): number {
    let otherSection = left
      ? this.getSectionLeft(section)
      : this.getSectionRight(section);

    if (otherSection !== null) {
      return otherSection.Depth;
    }

    return 0;
  }

  //#endregion Other sections and corner

  //#region Corpus

  public static getCorpusWidthLeft(section: Client.CabinetSection): number {
    return section.corpus.panelLeft != null ? section.corpus.widthLeft : 0;
  }
  public static getCorpusWidthRight(section: Client.CabinetSection): number {
    return section.corpus.panelRight != null ? section.corpus.widthRight : 0;
  }
  public static getCorpusHeightTop(section: Client.CabinetSection): number {
    return section.corpus.panelTop != null ? section.corpus.heightTop : 0;
  }
  public static getCorpusHeightBottom(section: Client.CabinetSection): number {
    return section.corpus.panelBottom != null ? section.corpus.heightBottom : 0;
  }

  public static getMinSidePanelDepth(section: Client.CabinetSection): number {
    let depthLeft =
      section.corpus.itemsLeft.length > 0
        ? section.corpus.depthLeft
        : Number.MAX_VALUE;

    let depthRight =
      section.corpus.itemsRight.length > 0
        ? section.corpus.depthRight
        : Number.MAX_VALUE;

    return Math.min(depthLeft, depthRight);
  }

  public static getMinTopBottomPanelDepth(
    section: Client.CabinetSection,
  ): number {
    let depthTop =
      section.corpus.itemsTop.length > 0
        ? section.corpus.depthTopIncludingExtra
        : Number.MAX_VALUE;

    let depthBottom =
      section.corpus.itemsBottom.length > 0
        ? section.corpus.depthBottomIncludingExtra
        : Number.MAX_VALUE;

    return Math.min(depthTop, depthBottom);
  }

  public static lightOffsetForCorner(section: Client.CabinetSection): number {
    if (!section.doors.railSet) {
      return 0;
    }

    let railsetDepth = RailSetHelper.minimumDepth(
      section.doors.railSet,
      section.cabinet.ProductLineId,
    );

    return (
      railsetDepth +
      this.getTopRailDistanceFromFront(section) -
      this.getCornerMouldingReduction(section)
    );
  }

  /**
   * Determines if a corpus panel is to be considered "full depth", depending on the cabinet section it belongs to
   */
  public static isCorpusPanelFullDepth(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): boolean {
    let corpusPanel: Client.CorpusPanel | null = null;

    switch (direction) {
      case Interface_Enums.Direction.Top:
        corpusPanel = section.corpus.panelTop;
        break;
      case Interface_Enums.Direction.Bottom:
        corpusPanel = section.corpus.panelBottom;
        break;
      case Interface_Enums.Direction.Left:
        corpusPanel = section.corpus.panelLeft;
        break;
      case Interface_Enums.Direction.Right:
        corpusPanel = section.corpus.panelRight;
        break;
    }

    if (!section.corpus.material) throw new Error('No corpusMaterial');

    return (
      corpusPanel != null &&
      corpusPanel.maxDepth(
        section.corpus.material,
        section.cabinet.ProductLineId,
      ) >= section.Depth
    );
  }

  /**
   * Calculates the available space behind a corpus panel
   */
  public static getSpaceBehindCorpusPanel(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    let corpusPanelDepth = 0;

    switch (direction) {
      case Interface_Enums.Direction.Top:
        corpusPanelDepth = section.corpus.depthTop;
        break;

      case Interface_Enums.Direction.Bottom:
        corpusPanelDepth = section.corpus.depthBottom;
        break;

      case Interface_Enums.Direction.Left:
        corpusPanelDepth = section.corpus.depthLeft;
        break;

      case Interface_Enums.Direction.Right:
        corpusPanelDepth = section.corpus.depthRight;
        break;
    }

    return section.Depth - corpusPanelDepth;
  }

  public static getAdjacentCorpusProductDepth(
    section: Client.CabinetSection,
    left: boolean,
    top: boolean,
  ): number {
    let otherSection = left
      ? this.getSectionLeft(section)
      : this.getSectionRight(section);

    if (otherSection !== null) {
      return top
        ? otherSection.corpus.depthTop
        : otherSection.corpus.depthBottom;
    }

    return 0;
  }

  //#endregion Corpus

  //#region Door

  public static getRailSetDepth(
    section: Client.CabinetSection,
    top: boolean,
  ): number {
    // Do we even have any doors?
    if (section.NumberOfDoors <= 0) {
      return 0;
    }

    // Check railset and products
    if (
      !(
        section.doors.railSet &&
        section.doors.railSet.topProduct &&
        section.doors.railSet.bottomProduct
      )
    ) {
      return 0;
    }

    // Check rail materials
    if (!(section.doors.railMaterialTop && section.doors.railMaterialBottom)) {
      return 0;
    }

    return top
      ? ProductHelper.defaultDepth(
          section.doors.railSet.topProduct,
          section.cabinet.ProductLineId,
          section.doors.railMaterialTop,
        )
      : ProductHelper.defaultDepth(
          section.doors.railSet.bottomProduct,
          section.cabinet.ProductLineId,
          section.doors.railMaterialBottom,
        );
  }

  public static getMaximumRailSetDepth(section: Client.CabinetSection): number {
    let depthTop = this.getRailSetDepth(section, true);
    let depthBottom = this.getRailSetDepth(section, false);

    return Math.max(depthTop, depthBottom);
  }

  public static getCornerOffsetForRail(section: Client.CabinetSection): number {
    let depthOther = 0;

    // Left
    let sectionLeft = this.getSectionLeft(section);
    if (sectionLeft) {
      depthOther +=
        sectionLeft.Depth -
        this.getTopRailDistanceFromFront(sectionLeft) -
        this.getRailSetDepth(section, true);
    }

    // Right
    let sectionRight = this.getSectionRight(section);
    if (sectionRight) {
      depthOther +=
        sectionRight.Depth -
        this.getTopRailDistanceFromFront(sectionRight) -
        this.getRailSetDepth(section, true);
    }

    return depthOther;
  }

  public static getCornerOffsetForDoors(
    section: Client.CabinetSection,
  ): number {
    let offset = this.getCornerOffsetForRail(section);

    if (section.CabinetType === Interface_Enums.CabinetType.CornerCabinet) {
      offset += this.getCornerMouldingReduction(section);
    }

    return offset;
  }

  public static getSightWidth(section: Client.CabinetSection): number {
    if (
      section.doors.railSet &&
      section.doors.railSet.NumberOfTracks === 1 &&
      !section.doors.onlyDoor
    ) {
      return section.doors.extraRailWidthRight;
    }

    var sightWidth = section.Width;

    sightWidth -= this.getCorpusWidthLeft(section);

    sightWidth -= this.getCorpusWidthRight(section);

    sightWidth -= this.getCornerOffsetForDoors(section);

    return sightWidth;
  }

  public static getRailWidth(section: Client.CabinetSection): number {
    var railWidth = section.Width;

    railWidth -= this.getCorpusWidthLeft(section);
    railWidth -= this.getCorpusWidthRight(section);

    if (section.doors.railSet && section.doors.railSet.NumberOfTracks !== 1) {
      railWidth += section.doors.extraRailWidthLeft;
      railWidth += section.doors.extraRailWidthRight;
    }

    railWidth -= this.getCornerOffsetForRail(section);

    return railWidth;
  }

  public static getFirstDoorPositionX(section: Client.CabinetSection): number {
    let posX = this.hasCornerLeft(section)
      ? CabinetSectionHelper.getCornerOffsetForDoors(section)
      : 0;
    posX += section.corpus.widthLeft;

    if (section.doors.railSet && section.doors.railSet.NumberOfTracks === 1) {
      posX += section.doors.extraRailWidthLeft;
    }

    return posX;
  }

  public static getDoorPositions(section: Client.CabinetSection): number[] {
    let positions = new Array<number>();

    // Find left position of door opening (based on corpus and corner left)
    let posX = CabinetSectionHelper.getFirstDoorPositionX(section);
    positions.push(posX);

    // Get door setup
    let doorSetup = section.doors.doorSetup;
    for (let i = 0; i < doorSetup.railPositions.length; i++) {
      // Find door position X for next door
      posX += section.doors.doors[i].width;

      if (
        i + 1 < doorSetup.railPositions.length &&
        doorSetup.railPositions[i] != doorSetup.railPositions[i + 1]
      ) {
        // Doors are on different tracks, and must overlap each other
        posX -= section.doors.overlapWidth;
      }

      positions.push(posX);
    }

    return positions;
  }

  public static getPulloutWarningAreas(
    section: Client.CabinetSection,
  ): Client.PulloutWarningArea[] {
    let areas: Client.PulloutWarningArea[] = [];

    // Corner left
    let otherSection = this.getSectionLeft(section);
    if (otherSection) {
      let width =
        section.NumberOfDoors > 0
          ? otherSection.Depth
          : this.getCornerAffectingDepthForInterior(otherSection);
      let position = 0;

      areas.push(
        new Client.PulloutWarningArea(
          position,
          position + width,
          Enums.PulloutWarningSeverity.Critical,
        ),
      );
    }

    // Corner right
    otherSection = this.getSectionRight(section);
    if (otherSection) {
      let width =
        section.NumberOfDoors > 0
          ? otherSection.Depth
          : this.getCornerAffectingDepthForInterior(otherSection);
      let position = section.Width - width;

      areas.push(
        new Client.PulloutWarningArea(
          position,
          position + width,
          Enums.PulloutWarningSeverity.Critical,
        ),
      );
    }

    areas.push(...this.getSwingPulloutAreas(section));

    // If there is no railset or no doors, do not add any more warnings
    if (!section.doors.railSet || section.NumberOfDoors < 1) {
      return areas;
    }

    // pullout warnings do not make sense for 1-track rails
    if (section.doors.numberOfRailTracks < 2) {
      return areas;
    }

    // Get door overlap positions
    let realOverlaps = section.doors.overlaps.filter((o) => o.isReal);

    let cornerStopEffectOnPullOut =
      RailSetHelper.minimumDepth(
        section.doors.railSet,
        section.cabinet.ProductLineId,
      ) /
        section.doors.railSet.NumberOfTracks -
      this.getCornerMouldingReduction(section) +
      this.getCornerStopWidth(section);
    let cornerMouldingWidth = this.getCornerMouldingWidth(section);

    // Add warnings for all overlaps
    for (let i = 0; i < realOverlaps.length; i++) {
      let position = realOverlaps[i].positionLeft;
      let width = section.doors.overlapWidth + Constants.fishEyeWidth;

      // First overlap with corner left
      if (i == 0 && this.hasCornerLeft(section)) {
        position -= cornerMouldingWidth;
        width += cornerMouldingWidth + cornerStopEffectOnPullOut;
      }
      // Last overlap with corner right
      else if (i == realOverlaps.length - 1 && this.hasCornerRight(section)) {
        position -= cornerStopEffectOnPullOut;
        width += cornerMouldingWidth + cornerStopEffectOnPullOut;
      }
      // All other situations
      else {
        position -= Constants.fishEyeWidth;
        width += Constants.fishEyeWidth;
      }

      areas.push(
        new Client.PulloutWarningArea(
          position,
          position + width,
          Enums.PulloutWarningSeverity.Warning,
        ),
      );
    }

    // If two doors in succession are on the same track, add a warning area (for the fish eyes)
    for (let i = 1; i < section.doors.doorSetup.railPositions.length; i++) {
      let overlap = section.doors.overlaps[i - 1];
      if (!overlap) continue;
      if (
        section.doors.doorSetup.railPositions[i] ==
        section.doors.doorSetup.railPositions[i - 1]
      ) {
        let width = 2 * Constants.fishEyeWidth;
        let position =
          overlap.positionLeft + overlap.width / 2 - Constants.fishEyeWidth;
        areas.push(
          new Client.PulloutWarningArea(
            position,
            position + width,
            Enums.PulloutWarningSeverity.Warning,
          ),
        );
      }
    }

    // Calculate areas, where a door will always block or where more than one door must be moved
    for (let i = 0; i < section.doors.doors.length; i++) {
      let currentDoor = section.doors.doors[i];
      let leftDoor = section.doors.getAdjacentDoorOnSameTrack(
        currentDoor,
        true,
      );
      let rightDoor = section.doors.getAdjacentDoorOnSameTrack(
        currentDoor,
        false,
      );

      // The door that meets the corner has a corner moulding, which affects the area blocked by the door, when the door is opened.
      let hasCornerMouldingLeft = i === 0 && this.hasCornerLeft(section);
      let hasCornerMouldingRight =
        i === section.doors.doors.length - 1 && this.hasCornerRight(section);
      let cornerMouldingEffectOnArea =
        hasCornerMouldingLeft || hasCornerMouldingRight
          ? cornerMouldingWidth
          : 0;

      // The possible movement of the door closest to the corner (per rail), except for the door that has the corner moulding, is affected by the corner stop.
      let hasCornerStopLeft =
        i > 0 && leftDoor === null && this.hasCornerLeft(section);
      let hasCornerStopRight =
        i < section.doors.doors.length - 1 &&
        rightDoor === null &&
        this.hasCornerRight(section);
      let cornerStopEffectOnMovement =
        hasCornerStopLeft || hasCornerStopRight ? cornerStopEffectOnPullOut : 0;

      //#region Calculate if more than one door must be moved

      // Area start
      let maxMoveLeft =
        leftDoor === null
          ? currentDoor.leftX -
            section.doors.doors[0].leftX -
            cornerStopEffectOnMovement
          : currentDoor.leftX - leftDoor.rightX - 2 * Constants.fishEyeWidth;
      let areaRight =
        currentDoor.rightX - maxMoveLeft + cornerMouldingEffectOnArea;

      // Area end
      let maxMoveRight =
        rightDoor === null
          ? section.doors.doors[section.doors.doors.length - 1].rightX -
            currentDoor.rightX -
            cornerStopEffectOnMovement
          : rightDoor.leftX - currentDoor.rightX - 2 * Constants.fishEyeWidth;
      let areaLeft =
        currentDoor.leftX + maxMoveRight - cornerMouldingEffectOnArea;

      // Add the area
      if (areaRight > areaLeft) {
        areas.push(
          new Client.PulloutWarningArea(
            areaLeft,
            areaRight,
            Enums.PulloutWarningSeverity.Warning,
          ),
        );
      }

      //#endregion Calculate if more than one door must be moved

      //#region Calculate if door will always block

      // Area start
      let doorsToTheLeft = Enumerable.from(
        section.doors.getOtherDoorsOnSameTrack(currentDoor, true),
      );
      let minLeftX =
        section.doors.doors[0].leftX +
        cornerStopEffectOnMovement +
        (doorsToTheLeft.any()
          ? doorsToTheLeft.sum((d) => d.width + Constants.fishEyeWidth * 2)
          : 0);

      maxMoveLeft = currentDoor.leftX - minLeftX;
      areaRight = currentDoor.rightX - maxMoveLeft + cornerMouldingEffectOnArea;

      // Area end
      let doorsToTheRight = Enumerable.from(
        section.doors.getOtherDoorsOnSameTrack(currentDoor, false),
      );
      let maxRightX =
        section.doors.doors[section.doors.doors.length - 1].rightX -
        cornerStopEffectOnMovement -
        (doorsToTheRight.any()
          ? doorsToTheRight.sum((d) => d.width + Constants.fishEyeWidth * 2)
          : 0);
      maxMoveRight = maxRightX - currentDoor.rightX;
      areaLeft = currentDoor.leftX + maxMoveRight - cornerMouldingEffectOnArea;

      // Add the area
      if (areaRight > areaLeft) {
        areas.push(
          new Client.PulloutWarningArea(
            areaLeft,
            areaRight,
            Enums.PulloutWarningSeverity.Critical,
          ),
        );
      }

      //#endregion Calculate if door will always block
    }

    // Update overlapping areas based on severity
    areas.forEach((area) => {
      // Is any other area with higher severity overlapping?
      let overlappingAreas = areas.filter(
        (a) => a.severity > area.severity && a.overlaps(area.start, area.end),
      );
      overlappingAreas.forEach((highSeverityArea) => {
        // If the critical area is "surrounded" by the non critical area, we need to make one more non critical area, and adjust accordingly
        if (
          area.start <= highSeverityArea.start &&
          area.end >= highSeverityArea.end
        ) {
          // Add a new area after the high severity area
          areas.push(
            new Client.PulloutWarningArea(
              highSeverityArea.end,
              area.end,
              area.severity,
            ),
          );
          // Adjust the existing low severity area
          area.end = highSeverityArea.start;
        } else {
          if (highSeverityArea.end > area.start) {
            area.start = highSeverityArea.end;
          }
          if (highSeverityArea.start < area.end) {
            area.end = highSeverityArea.start;
          }
        }
      });

      // Also check area against other areas with the same severity
      overlappingAreas = areas.filter(
        (a) =>
          a !== area &&
          a.severity === area.severity &&
          a.width > 0 &&
          a.overlaps(area.start, area.end),
      );
      overlappingAreas.forEach((overlappingArea) => {
        area.start = Math.min(area.start, overlappingArea.start);
        area.end = Math.max(area.end, overlappingArea.end);
        overlappingArea.start = overlappingArea.end + 1; // This makes sure that it is removed later.
      });
    });

    // Remove areas that have severity None or are to narrow
    areas = areas.filter(
      (a) => a.severity > 0 && a.width > Constants.sizeAndPositionTolerance,
    );

    return areas;
  }

  public static getSwingPulloutAreas(
    section: Client.CabinetSection,
  ): Client.PulloutWarningArea[] {
    let result: Client.PulloutWarningArea[] = [];
    if (!section.isSwing) return result;

    const warningAreaWidth = 10;
    for (let swingArea of section.swing.areas) {
      if (
        swingArea.hingeSide == Enums.HingeSide.Left ||
        swingArea.hingeSide == Enums.HingeSide.Both
      ) {
        let x = swingArea.insideRect.X;
        result.push(
          new Client.PulloutWarningArea(
            x,
            x + warningAreaWidth,
            Enums.PulloutWarningSeverity.Critical,
          ),
        );
      }

      if (
        swingArea.hingeSide == Enums.HingeSide.Right ||
        swingArea.hingeSide == Enums.HingeSide.Both
      ) {
        let x =
          swingArea.insideRect.X +
          swingArea.insideRect.Width -
          warningAreaWidth;
        result.push(
          new Client.PulloutWarningArea(
            x,
            x + warningAreaWidth,
            Enums.PulloutWarningSeverity.Critical,
          ),
        );
      }
    }
    return result;
  }

  public static getPulloutSafeAreas(section: Client.CabinetSection): {
    [id: number]: Client.PulloutWarningArea[];
  } {
    let pulloutSafeAreas: { [id: number]: Client.PulloutWarningArea[] } = {};

    if (!section.doors.railSet) {
      return pulloutSafeAreas;
    }

    // The possible movement of the door closest to the corner (per rail), except for the door that has the corner moulding, is affected by the corner stop.
    let cornerStopEffectOnPullOut =
      RailSetHelper.minimumDepth(
        section.doors.railSet,
        section.cabinet.ProductLineId,
      ) /
        section.doors.railSet.NumberOfTracks -
      this.getCornerMouldingReduction(section) +
      this.getCornerStopWidth(section);

    // The door that meets the corner has a corner moulding, which affects the area blocked by the door, when the door is opened.
    let cornerMouldingWidth = this.getCornerMouldingWidth(section);

    for (let i = 0; i < section.doors.doors.length; i++) {
      let currentDoor = section.doors.doors[i];
      let leftDoor = section.doors.getAdjacentDoorOnSameTrack(
        currentDoor,
        true,
      );
      let rightDoor = section.doors.getAdjacentDoorOnSameTrack(
        currentDoor,
        false,
      );

      let track = section.doors.doorSetup.railPositions[i];
      if (!pulloutSafeAreas[track]) {
        pulloutSafeAreas[track] = new Array<Client.PulloutWarningArea>();
      }

      // Left
      let doorsToTheLeft = Enumerable.from(
        section.doors.getOtherDoorsOnSameTrack(currentDoor, true),
      );
      let doorWidthLeft = doorsToTheLeft.any()
        ? doorsToTheLeft.sum((d) => d.width) + Constants.fishEyeWidth
        : 0;
      let minLeftX = section.doors.doors[0].leftX + doorWidthLeft;

      // Right
      let doorsToTheRight = Enumerable.from(
        section.doors.getOtherDoorsOnSameTrack(currentDoor, false),
      );
      let doorWidthRight = doorsToTheRight.any()
        ? doorsToTheRight.sum((d) => d.width) + Constants.fishEyeWidth
        : 0;
      let maxRightX =
        section.doors.doors[section.doors.doors.length - 1].rightX -
        doorWidthRight;

      let currentDoorWidth = currentDoor.width;
      if (!leftDoor && !section.leftNeighbor) {
        currentDoorWidth += Constants.fishEyeWidth;
      }
      if (!rightDoor && !section.rightNeighbor) {
        currentDoorWidth += Constants.fishEyeWidth;
      }

      //#region Left side of the current door

      let nonBlockingAreaLeft = minLeftX;
      let nonBlockingAreaRight = maxRightX - currentDoorWidth;
      if (this.hasCornerLeft(section)) {
        if (!!leftDoor) {
          nonBlockingAreaLeft += cornerStopEffectOnPullOut;
        } else if (i === 0) {
          nonBlockingAreaRight -= cornerMouldingWidth;
        }
      }
      if (this.hasCornerRight(section)) {
        if (!!rightDoor && track < section.doors.numberOfRailTracks) {
          // The inner most track (with the highest number) has no corner stop, but has the corner moulding.
          nonBlockingAreaRight -= cornerStopEffectOnPullOut;
        }
      }

      pulloutSafeAreas[track].push(
        new Client.PulloutWarningArea(
          nonBlockingAreaLeft,
          nonBlockingAreaRight,
          Enums.PulloutWarningSeverity.None,
        ),
      );

      //#endregion Left side of the current door

      //#region Right side of the last door

      if (!rightDoor) {
        nonBlockingAreaLeft = minLeftX + currentDoorWidth;
        nonBlockingAreaRight = maxRightX;
        if (this.hasCornerLeft(section)) {
          if (track < section.doors.numberOfRailTracks) {
            // The inner most track (with the highest number) has no corner stop, but has the corner moulding.
            nonBlockingAreaLeft += cornerStopEffectOnPullOut;
          }
        }
        if (this.hasCornerRight(section)) {
          if (i === section.doors.doors.length - 1) {
            nonBlockingAreaLeft += cornerMouldingWidth;
          }
        }

        pulloutSafeAreas[track].push(
          new Client.PulloutWarningArea(
            nonBlockingAreaLeft,
            nonBlockingAreaRight,
            Enums.PulloutWarningSeverity.None,
          ),
        );
      }

      //#endregion Right side of the last door
    }

    return pulloutSafeAreas;
  }

  public static getTopRailDistanceFromFront(
    section: Client.CabinetSection,
  ): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    return productDoorData.DepthTrackRails;
  }

  public static getBottomRailDistanceFromFront(
    section: Client.CabinetSection,
  ): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    return productDoorData.BottomRailPositionFromFront;
  }

  public static getRailTrackCenterOffsetZ(
    section: Client.CabinetSection,
    trackNumber: number,
  ): number {
    if (!section.doors.railSet) {
      return 0;
    }

    let railOffset = this.getTopRailDistanceFromFront(section);
    let trackOffset = RailSetHelper.railTrackCenter(
      section.doors.railSet,
      trackNumber,
      section.cabinet.ProductLineId,
    );

    return railOffset + trackOffset;
  }

  public static getCornerMouldingReduction(
    section: Client.CabinetSection,
  ): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    return productDoorData.CornerMouldingReduction;
  }

  public static getCornerMouldingHeightReduction(
    section: Client.CabinetSection,
  ): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    return productDoorData.CornerMouldingHeightReduction;
  }

  public static getCornerMouldingWidth(section: Client.CabinetSection): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    let cornerMouldingProduct =
      section.editorAssets.productsDict[
        productDoorData.CornerMouldingProductId
      ];
    if (!cornerMouldingProduct) {
      return 0;
    }

    return ProductHelper.defaultWidth(cornerMouldingProduct);
  }

  public static getCornerStopWidth(section: Client.CabinetSection): number {
    let productDoorData = this.getProductDoorData(section);
    if (!productDoorData) {
      return 0;
    }

    return productDoorData.CornerStopWidth;
  }

  //#endregion Door

  //#region Interior

  /**
   * Determines if an item is in the left corner of its section
   */
  public static isItemInCornerAreaLeft(
    item: Client.ConfigurationItem,
  ): boolean {
    let section = item.cabinetSection;
    if (!section.leftNeighbor) return false;

    let cornerEndX =
      section.interior.cube.X + section.leftNeighbor.InteriorDepth;
    return item.X < cornerEndX;
  }
  /**
   * Determines if an item is in the right corner of a given section
   */
  public static isItemInCornerAreaRight(
    item: Client.ConfigurationItem,
  ): boolean {
    let section = item.cabinetSection;
    if (!section.rightNeighbor) return false;
    let cornerStartX =
      section.interior.cube.X +
      section.interior.cube.Width -
      section.rightNeighbor.InteriorDepth;
    return item.rightX > cornerStartX;
  }

  public static getCornerItemsRightFromSectionLeft(
    section: Client.CabinetSection,
  ): Client.ConfigurationItem[] {
    let sectionLeft = this.getSectionLeft(section);
    if (sectionLeft == null) {
      return Array<Client.ConfigurationItem>();
    }

    return sectionLeft.interior.items.filter((ii) =>
      this.isItemInCornerAreaRight(ii),
    );
  }

  public static getCornerItemsLeftFromSectionRight(
    section: Client.CabinetSection,
  ): Client.ConfigurationItem[] {
    let sectionRight = this.getSectionRight(section);
    if (sectionRight == null) {
      return Array<Client.ConfigurationItem>();
    }

    let temp = sectionRight;
    return sectionRight.interior.items.filter((ii) =>
      this.isItemInCornerAreaLeft(ii),
    );
  }

  /**
   * Deprecated: By convention, all corner shelves are placed in the right side of the left section of the corner.
   */
  public static hasDiagonalCutItemInCornerLeft(
    section: Client.CabinetSection,
  ): boolean {
    //By convention, all corner shelves are placed in the right side of the left section of the corner.
    //Therefore, there are never any corner shelves in the left corner
    return false;
  }
  /**
   * Determines if there is a diagonal cut item right corner of a given section
   */
  public static hasDiagonalCutItemInCornerRight(
    section: Client.CabinetSection,
  ): boolean {
    //By convention, all corner shelves are placed in the right side of the left section of the corner.
    //So is a cabinetSection has a diagonally cut item, the item is assumed to be in the right side.
    return section.interior.items.some((i) => i.isCornerDiagonalCut);
  }

  /**
   * Determines if only diagonal cut items are allowed in the left corner of a given section
   */
  public static onlyDiagonalCutAllowedInCornerLeft(
    section: Client.CabinetSection,
  ): boolean {
    if (!section.leftNeighbor) {
      return false;
    }

    return this.hasDiagonalCutItemInCornerRight(section.leftNeighbor);
  }
  /**
   * Determines if only diagonal cut items are allowed in the right corner of a given section
   */
  public static onlyDiagonalCutAllowedInCornerRight(
    section: Client.CabinetSection,
  ): boolean {
    //By convention, all corner shelves are placed in the right side of the left section of the corner.
    //It should be enough to check the right corner of the current section

    return this.hasDiagonalCutItemInCornerRight(section);
  }

  public static getInteriorDeductionTop(
    section: Client.CabinetSection,
  ): number {
    if (
      section.corpus.panelTop &&
      (section.corpus.panelTop.isFlexDepth2(section.cabinet.ProductLineId) ||
        (section.corpus.material !== null &&
          section.corpus.panelTop.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) > this.getDifferenceBetweenSectionDepthAndFrontOfInterior(section)))
    ) {
      return section.corpus.heightTop;
    }

    return 0;
  }
  public static getInteriorDeductionBottom(
    section: Client.CabinetSection,
  ): number {
    if (
      section.corpus.panelBottom &&
      (section.corpus.panelBottom.isFlexDepth2(section.cabinet.ProductLineId) ||
        (section.corpus.material !== null &&
          section.corpus.panelBottom.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) > this.getDifferenceBetweenSectionDepthAndFrontOfInterior(section)))
    ) {
      return section.corpus.heightBottom;
    }

    return 0;
  }
  public static getInteriorDeductionLeft(
    section: Client.CabinetSection,
  ): number {
    return (
      this.getBackingDeductionLeft(section) +
      this.getInteriorOffsetLeft(section)
    );
  }
  public static getInteriorDeductionRight(
    section: Client.CabinetSection,
  ): number {
    return (
      this.getBackingDeductionRight(section) +
      this.getInteriorOffsetRight(section)
    );
  }

  /**
   * Calculates the left interior offset caused by free space and (if applicable) backing in adjacent section
   */
  public static getInteriorOffsetLeft(section: Client.CabinetSection): number {
    return (
      section.InteriorFreeSpaceLeft +
      this.interiorOffsetCausedByBackingInAdjacentSection(
        section,
        Interface_Enums.Direction.Left,
      )
    );
  }
  /**
   * Calculates the right interior offset caused by free space and (if applicable) backing in adjacent section
   */
  public static getInteriorOffsetRight(section: Client.CabinetSection): number {
    return (
      section.InteriorFreeSpaceRight +
      this.interiorOffsetCausedByBackingInAdjacentSection(
        section,
        Interface_Enums.Direction.Right,
      )
    );
  }

  public static getCornerAffectingDepthForInterior(
    section: Client.CabinetSection,
  ): number {
    return section.InteriorDepth + this.backingOffsetZforInterior(section);
  }

  /**
   * Calculates the offset needed, when positioning interior.
   */
  public static interiorOffset(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    return (
      this.interiorOffsetCausedByCorpus(section, direction) +
      this.interiorOffsetCausedBySwing(section, direction) +
      this.interiorOffsetCausedByInteriorFreeSpace(section, direction) +
      this.interiorOffsetCausedByBackingInAdjacentSection(section, direction)
    );
  }

  /**
   * Calculates how much the corpus panels affect where interior can be positioned.
   */
  public static interiorOffsetCausedByCorpus(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    let spaceInFrontOfInterior =
      CabinetSectionHelper.getDifferenceBetweenSectionDepthAndFrontOfInterior(
        section,
      );

    switch (direction) {
      case Interface_Enums.Direction.Top:
        return section.corpus.depthTop > spaceInFrontOfInterior
          ? section.corpus.heightTop
          : 0;

      case Interface_Enums.Direction.Bottom:
        //return section.corpus.depthBottom > spaceInFrontOfInterior ? section.corpus.heightBottom : 0;
        return section.corpus.panelBottom.isFullDepth(section)
          ? section.corpus.heightBottom
          : 0;

      case Interface_Enums.Direction.Left:
        return section.corpus.depthLeft > spaceInFrontOfInterior
          ? section.corpus.widthLeft
          : 0;

      case Interface_Enums.Direction.Right:
        return section.corpus.depthRight > spaceInFrontOfInterior
          ? section.corpus.widthRight
          : 0;
    }
    throw new Error('Unknown direction');
  }

  public static interiorOffsetCausedBySwing(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    if (section.CabinetType !== Interface_Enums.CabinetType.Swing) return 0;

    switch (direction) {
      case Interface_Enums.Direction.Top: {
        let shelfHeight = 0;
        let area = section.swing.areas[0];
        if (area) {
          let shelfMPs =
            area.swingTemplate?.startModule.subProducts[
              Interface_Enums.SwingModuleProductPlacement.TopShelf
            ];
          if (shelfMPs == null) return 0;
          for (let shelfMP of shelfMPs) {
            shelfHeight = Math.max(
              shelfHeight,
              ProductHelper.defaultHeight(shelfMP.product),
            );
          }
        }
        return shelfHeight;
      }
      case Interface_Enums.Direction.Bottom: {
        let shelfHeight = 0;
        let area = section.swing.areas[0];
        if (area) {
          let shelfMPs =
            area.swingTemplate?.startModule.subProducts[
              Interface_Enums.SwingModuleProductPlacement.BottomShelf
            ];
          if (shelfMPs == null) return 0;
          for (let shelfMP of shelfMPs) {
            shelfHeight = Math.max(
              shelfHeight,
              ProductHelper.defaultHeight(shelfMP.product),
            );
          }
        }
        return Constants.swingBottomOffset + shelfHeight;
      }

      case Interface_Enums.Direction.Left: {
        let gableWidth = 0;
        let area = section.swing.areas[0];
        if (area) {
          let gableMPs =
            area.swingTemplate?.startModule.subProducts[
              Interface_Enums.SwingModuleProductPlacement.LeftGable
            ];
          if (gableMPs == null) return 0;
          for (let gableMP of gableMPs) {
            gableWidth = Math.max(
              gableWidth,
              ProductHelper.defaultWidth(gableMP.product),
            );
          }
        }
        return gableWidth;
      }

      case Interface_Enums.Direction.Right: {
        let gableWidth = 0;
        let area = section.swing.areas[0];
        if (area) {
          let gableMPs =
            area.swingTemplate?.startModule.subProducts[
              Interface_Enums.SwingModuleProductPlacement.RightGable
            ];
          if (gableMPs == null) return 0;
          for (let gableMP of gableMPs) {
            gableWidth = Math.max(
              gableWidth,
              ProductHelper.defaultWidth(gableMP.product),
            );
          }
        }
        return gableWidth;
      }

      default:
        throw new Error('Unknown direction: ' + direction);
    }
  }

  /**
   * Calculates how much the interior free space affect where interior can be positioned.
   */
  public static interiorOffsetCausedByInteriorFreeSpace(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    switch (direction) {
      case Interface_Enums.Direction.Left:
        return section.InteriorFreeSpaceLeft;

      case Interface_Enums.Direction.Right:
        return section.InteriorFreeSpaceRight;

      case Interface_Enums.Direction.Top:
      case Interface_Enums.Direction.Bottom:
      default:
        return 0;
    }
  }

  /**
   * Calculates how much the backing in adjacent section affects where interior can be positioned.
   */
  public static interiorOffsetCausedByBackingInAdjacentSection(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ): number {
    let otherSection: Client.CabinetSection | undefined;

    switch (direction) {
      case Interface_Enums.Direction.Left:
        otherSection = section.leftNeighbor;
        break;

      case Interface_Enums.Direction.Right:
        otherSection = section.rightNeighbor;
        break;

      case Interface_Enums.Direction.Top:
      case Interface_Enums.Direction.Bottom:
      default:
        return 0;
    }

    if (otherSection) {
      switch (otherSection.backing.backingType) {
        case Interface_Enums.BackingType.None:
          return 0;
        case Interface_Enums.BackingType.Visible:
        case Interface_Enums.BackingType.Hidden:
          return otherSection.backing.frontZ;
        default:
          throw new Error(
            'Unknown backing type: ' + otherSection.backing.backingType,
          );
      }
    }

    return 0;
  }

  /**
   * Calculates the height available for interior
   */
  public static availableInteriorHeight(
    section: Client.CabinetSection,
  ): number {
    let offsetTop = this.interiorOffset(section, Interface_Enums.Direction.Top);
    let offsetBottom = this.interiorOffset(
      section,
      Interface_Enums.Direction.Bottom,
    );

    return section.Height - (offsetTop + offsetBottom);
  }

  /**
   * Calculates the width available for interior
   */
  public static availableInteriorWidth(section: Client.CabinetSection): number {
    let offsetLeft = this.interiorOffset(
      section,
      Interface_Enums.Direction.Left,
    );
    let offsetRight = this.interiorOffset(
      section,
      Interface_Enums.Direction.Right,
    );

    return section.Width - (offsetLeft + offsetRight);
  }

  /**
   * Calculates the depth available for interior
   */
  public static availableInteriorDepth(section: Client.CabinetSection): number {
    let offsetBack = this.backingOffsetZforInterior(section);

    let offsetFrontTop =
      this.getRailSetDepth(section, true) +
      this.getTopRailDistanceFromFront(section);
    let offsetFrontBottom =
      this.getRailSetDepth(section, false) +
      this.getBottomRailDistanceFromFront(section);
    let offsetFront = Math.max(offsetFrontTop, offsetFrontBottom);

    return section.Depth - (offsetBack + offsetFront);
  }

  /**
   * Calculates the Z offset affecting interior, caused by the backing
   */
  public static backingOffsetZforInterior(
    section: Client.CabinetSection | null,
  ): number {
    return section != null ? section.backing.frontZ : 0;
  }

  /**
   * Gets the difference between the depth of the section and the front most interior
   */
  public static getDifferenceBetweenSectionDepthAndFrontOfInterior(
    section: Client.CabinetSection,
  ): number {
    let frontZ = Math.max(0, ...section.interior.items.map((i) => i.frontZ));
    if (frontZ === 0) {
      frontZ = section.InteriorDepth;
    }
    return section.Depth - frontZ;
  }

  //#endregion Interior

  //#region Backing

  /**
   * Calculates the top backing offset caused by the top corpus panel
   */
  public static getBackingDeductionTop(section: Client.CabinetSection): number {
    return this.getInteriorDeductionTop(section);
  }
  /**
   * Calculates the bottom backing offset caused by the bottom corpus panel
   */
  public static getBackingDeductionBottom(
    section: Client.CabinetSection,
  ): number {
    return this.getInteriorDeductionBottom(section);
  }
  /**
   * Calculates the left backing offset caused by left corpus panel
   */
  public static getBackingDeductionLeft(
    section: Client.CabinetSection,
  ): number {
    if (
      section.corpus.panelLeft &&
      (section.corpus.panelLeft.isFlexDepth2(section.cabinet.ProductLineId) ||
        (section.corpus.material !== null &&
          section.corpus.panelLeft.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) > this.getDifferenceBetweenSectionDepthAndFrontOfInterior(section)))
    ) {
      return section.corpus.widthLeft;
    }
    return 0;
  }
  /**
   * Calculates the right backing offset caused by right corpus panel
   */
  public static getBackingDeductionRight(
    section: Client.CabinetSection,
  ): number {
    if (
      section.corpus.panelRight &&
      (section.corpus.panelRight.isFlexDepth2(section.cabinet.ProductLineId) ||
        (section.corpus.material !== null &&
          section.corpus.panelRight.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) > this.getDifferenceBetweenSectionDepthAndFrontOfInterior(section)))
    ) {
      return section.corpus.widthRight;
    }

    return 0;
  }

  /**
   * Calculates the space (width) available for backing
   * @param section
   */
  public static getSpaceForBacking(section: Client.CabinetSection): number {
    let backingWidth =
      section.Width -
      this.getBackingDeductionLeft(section) -
      this.getBackingDeductionRight(section);

    if (section.leftNeighbor) {
      backingWidth -= section.leftNeighbor.backing.frontZ;
    }

    return backingWidth;
  }

  //#endregion Backing

  //#region Private helpers

  private static getProductDoorData(
    section: Client.CabinetSection,
  ): Interface_DTO.ProductDoorData | null {
    let doorProduct = section.doors.profile;
    if (!doorProduct) {
      return null;
    }

    const productDoorData = Enumerable.from(
      section.editorAssets.productDoorData,
    ).firstOrDefault((pdd) => pdd.ProductId === doorProduct!.Id);

    if (productDoorData === undefined) return null;
    return productDoorData;
  }

  //#endregion Private helpers
}
