<div class="modal-content" style="min-width: 700px">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('add_product_modal_header', 'Add product')"
    ></h3>
    <button type="button" class="close" data-dismiss="modal" (click)="cancel()">
      &times;
    </button>
  </div>

  <div class="modal-body add-product-modal">
    <div class="col1">
      <product-list-recursive
        [productCategories]="productCategories"
        [productLines]="productLines"
        (onProductClick)="createItemFromProduct($event)"
        [fullCatalog]="fullCatalog"
        [cabinetSection]="cabinetSection"
        [initialProductLine]="defaultProductLine"
        [(selectedProductLine)]="selectedProductLine"
      ></product-list-recursive>
    </div>

    <div class="col2" *ngIf="selectedItem">
      <item-editor
        [item]="selectedItem"
        [selectedProductLine]="selectedProductLine"
      ></item-editor>
    </div>
  </div>

  <div
    class="modal-footer"
    style="display: flex; flex-flow: row nowrap; justify-content: flex-end"
  >
    <div class="input-group">
      <input
        type="number"
        name="Quantity"
        class="form-control add-amount"
        min="1"
        style="width: 60px"
        [(ngModel)]="quantity"
      />
      <button
        class="btn btn-primary-custom"
        (click)="ok()"
        [ngClass]="{'disabled': !okPossible}"
        [textContent]="translate('modal_button_add', 'Add')"
        style="display: block"
      ></button>

      <button
        type="button"
        style="margin-left: 0"
        class="btn btn-light"
        (click)="cancel()"
        [textContent]="translate('modal_button_close', 'Close')"
      ></button>
    </div>
  </div>
</div>
