/**
 * Navigations paths for the applation.
 * Please keep it sorted alphabetacally
 */
export class AppPaths {
  public static get ConsumerCorpus(): string {
    return 'corpus';
  }
  public static get ConsumerLoad(): string {
    return 'load';
  }
  public static get ConsumerInterior(): string {
    return 'interior';
  }
  public static get Customers(): string {
    return 'customers';
  }
  public static get DeliveryAddresses(): string {
    return 'deliveryaddresses';
  }
  public static get FloorPlan(): string {
    return 'floorplan';
  }
  public static get FloorPlans(): string {
    return 'floorplans';
  }
  public static get Home(): string {
    return 'floorplans';
  }
  public static get ConfirmOrders(): string {
    return 'confirmorders';
  }
  public static get Load(): string {
    return 'projects';
  }
  public static get Login(): string {
    return 'login/';
  }
  public static get Projects(): string {
    return 'projects';
  }
  public static get ConsumerDimensions(): string {
    return 'dimensions';
  }
}
