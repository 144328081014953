import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActiveStore,
  ActiveStoreInjector,
  activeStoreProvider,
} from 'app/functional-core/ambient/stores/ActiveStore';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'accept-delivery-info-vm',
  templateUrl: './acceptDeliveryInfo.html',
  styleUrls: [
    '../../../../style/acceptDeliveryInfo.scss',
    '../../../../style/modal.scss',
  ],
  providers: [activeStoreProvider],
})
export class AcceptDeliveryInfoVm
  extends BaseVm
  implements OnInit, AfterViewInit
{
  public deliveryWeek!: number;
  public deliveryDays!: string;
  public correctionDeadlineDate!: string;

  public freightPrice!: string;
  public totalPrice!: string;
  public fittingPrice!: string;
  public showExternalChangesWarning: boolean = false;
  public isAddressValid = false;
  public isInvalidAddressAccepted = false;
  @ViewChild('focus') focus!: ElementRef<HTMLButtonElement>;

  public readonly deliveryInfo!: Interface_DTO.DeliveryInfo;
  public readonly currentDeliveryStatus!: Interface_Enums.DeliveryStatus;
  public readonly name!: string;
  public readonly wasDeliveryWeekChanged!: boolean;
  public readonly deliveryAddress!: Interface_DTO.DeliveryAddress | undefined;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly notificationService: NotificationService,
    private readonly formattingService: FormattingService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.deliveryWeek = this.deliveryInfo.DeliveryWeek;
    {
      let activeDeliveryDays = ObjectHelper.getWeekDays(
        this.deliveryInfo.ExpectedDeliveryDays,
      );
      let translatedDayNames = activeDeliveryDays.map((add) =>
        this.translate('weekday_' + add, add),
      );
      this.deliveryDays = translatedDayNames.join(', ');
    }
    if (!this.deliveryInfo.CorrectionDeadlineDate) {
      throw new Error(
        'Unable to calculate correction deadline - acceptance of delivery info not possible',
      );
    }
    this.isAddressValid = this.deliveryInfo.IsAddressValid !== false;

    let corrDate = DateHelper.fromIsoString(
      this.deliveryInfo.CorrectionDeadlineDate,
    );
    this.correctionDeadlineDate = this.formattingService.formatDate(
      corrDate,
      false,
    );
    this.freightPrice = ' -- ';
    this.totalPrice = ' -- ';
    this.fittingPrice = ' -- ';
    this.showExternalChangesWarning =
      this.currentDeliveryStatus === Interface_Enums.DeliveryStatus.Blocked;
    this.setPrices();
  }

  ngAfterViewInit(): void {
    this.focus.nativeElement.focus();
  }

  private async setPrices() {
    const storeInfo = this.activeStore;
    if (!storeInfo) {
      this.notificationService.systemError(
        'error_no_store_no_vat_rate',
        "I Don't know what store this is - which VAT rate should I use?",
      );
      return;
    }
    this.freightPrice = ObjectHelper.roundMoney(
      this.addVAT(this.deliveryInfo.TotalFreightAndFees, storeInfo.data),
    );
    if (this.deliveryInfo.FittingPrice === null) {
      throw new Error('Fitting price unknown');
    }
    let fittingPriceInclVat = this.addVAT(
      this.deliveryInfo.FittingPrice,
      storeInfo.data,
    );
    this.fittingPrice = ObjectHelper.roundMoney(fittingPriceInclVat);

    this.totalPrice = ObjectHelper.roundMoney(
      this.addVAT(this.deliveryInfo.TotalPrice, storeInfo.data) +
        fittingPriceInclVat,
    );
  }

  private addVAT(price: number, store: Interface_DTO.Store): number {
    return price * (1 + store.SalesChain.VatPercent / 100);
  }

  public get isValid() {
    return this.isAddressValid || this.isInvalidAddressAccepted;
  }

  public save() {
    if (!this.isValid) return;
    this.activeModal.close(true);
  }
  public cancel() {
    this.activeModal.close(false);
  }
}
