export async function timeoutAsync(
  timeoutMs: number,
  opts?: { abortSignal?: AbortSignal },
) {
  let timerId = 0;
  if (opts?.abortSignal) {
    opts.abortSignal.onabort = () => {
      if (timerId) {
        window.clearTimeout(timerId);
      }
      throw new AbortError();
    };
  }
  await new Promise<null>((resolveFunc) => {
    window.setTimeout(() => resolveFunc(null), timeoutMs);
  });
}

export class AbortError extends Error {
  constructor() {
    super('timeout aborted');
  }
}
