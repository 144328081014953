import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { AssetService } from 'app/ts/services/AssetService';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { LoginService } from 'app/ts/services/LoginService';
import { Observable } from 'app/ts/util/Observable';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import Enumerable from 'linq';

@Component({
  selector: 'delivery-info-editor-vm',
  templateUrl: './DeliveryInfoEditor.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class DeliveryInfoEditorVm extends BaseVm implements OnInit {
  public readonly maxfloorPlanNameLength = Constants.maxfloorPlanNameLength;
  public deliveryInfo!: Client.DeliveryInfoChanges;
  public readonly smsObservable: Observable<string | null> = new Observable<
    string | null
  >(null);

  private allSalesTypes: Interface_DTO.SalesType[] = [];
  public salesChainShippingAgents: Interface_DTO.SalesChainShippingAgent[] = [];
  public salesTypes: Interface_DTO.SalesType[] = [];
  public users: { id: number; name: string }[] = [];

  public canEdit: boolean = true;
  public defaultPhonePrefix = '';
  public showShippingAgents = false;
  public requireSmsOnOrdering = false;
  public requireReferenceOnOrdering = false;
  public requireMarketOrderOnOrdering = false;
  public showSmsField = false;
  public showIntegrationInputFields = false;
  public showMarketOrderNumber = false;
  public isReferenceFieldReadOnly = false;
  public showOrderComment = false;
  public showLogisticsComment = false;
  public showManualOrderHandling = false;
  public showSendReminderEmails = false;

  constructor(
    baseVmService: BaseVmService,
    private assetService: AssetService,
    private loginService: LoginService,
    private permssions: PermissionService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.deliveryInfo = {
      ...this.deliveryInfoChanges,
    };

    this.subscribeTo(
      this.assetService.users$,
      (users) =>
        (this.users = users.map((user) => ({ id: user.Id, name: user.Name }))),
    );
    this.subscribeTo(this.assetService.salesChainShippingAgents$, (sts) => {
      if (sts != null && sts.length > 0) {
        let blankShippingAgent: Interface_DTO.SalesChainShippingAgent = {
          Id: -1,
          SalesChainId: -1,
          ShippingAgentCode: '',
          ShippingAgentName: this.translate(
            'Integration_direct_delivery',
            'Direct delivery',
          ),
        };
        if (sts[0].Id != -1) sts.splice(0, 0, blankShippingAgent);
        this.showShippingAgents = true;
      }
      this.salesChainShippingAgents = sts;
    });

    this.requireSmsOnOrdering =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.RequireSMSWhenOrdering
      ] == '1';
    this.requireReferenceOnOrdering =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.RequireRequisitionWhenOrdering
      ] == '1';
    this.requireMarketOrderOnOrdering =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.RequireMarketOrder
      ] == '1';
    this.showSmsField =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ShowSms
      ] == '1';
    this.showIntegrationInputFields =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ShowIntegrationInputFields
      ] == '1';
    this.showMarketOrderNumber =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ShowMarketOrderNumber
      ] == '1';
    this.isReferenceFieldReadOnly =
      this.loginService.salesChainSettings[
        Interface_Enums.SalesChainSettingKey.ReferenceFieldReadOnly
      ] == '1';

    this.smsObservable.value = this.deliveryInfo.SmsNumber || '';
    this.subscribeToOld(this.smsObservable, (smsNumber) => {
      this.deliveryInfo.SmsNumber = smsNumber || '';
    });
    this.subscribeTo(this.assetService.salesTypes$, (sts) => {
      this.allSalesTypes = sts;
      this.setSalesTypes();
    });
    this.showOrderComment = this.permssions.canEditOrderComment;
    this.showLogisticsComment = this.permssions.canEditLogisticsComment;
    this.showManualOrderHandling =
      this.permssions.canEditUseManualOrderHandling;
    this.showSendReminderEmails = this.permssions.canEditSendReminderEmails;
  }

  public deliveryInfoChanges!: Client.DeliveryInfoChanges;

  public setDefaultSalesTypeCaseNumber() {
    if (this.deliveryInfo) {
      let salesType = Enumerable.from(this.salesTypes).firstOrDefault(
        (st) => st.Number === this.deliveryInfo!.SalesTypeNumber,
      );

      this.deliveryInfo.SalesTypeCaseNumber =
        salesType && salesType.SalesTypeCases[0]
          ? salesType.SalesTypeCases[0].Number
          : '';
    }
  }

  public get selectedSalesType(): Interface_DTO.SalesType | undefined {
    if (!this.deliveryInfo) return;
    let stn = this.deliveryInfo.SalesTypeNumber;
    return (
      Enumerable.from(this.salesTypes).firstOrDefault(
        (st) => st.Number === stn,
      ) || undefined
    );
  }

  private setSalesTypes(): void {
    let salesTypeNumber = this.deliveryInfo.SalesTypeNumber;
    this.salesTypes = this.allSalesTypes.filter(
      (st) => st.Enabled || st.Number === salesTypeNumber,
    );
  }

  public ok() {
    if (!this.isValid()) return;
    this.activeModal.close(this.deliveryInfo);
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  private isValid(): boolean {
    if (this.requireReferenceOnOrdering && !this.deliveryInfo.Reference)
      return false;
    if (this.requireSmsOnOrdering && !this.deliveryInfo.SmsNumber) return false;

    return true;
  }

  protected phoneNumberChange(phoneNumber: string) {
    this.smsObservable.value = phoneNumber;
  }
}
