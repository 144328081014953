import { NgModule } from '@angular/core';
import { AnchorScrollService } from './anchor-scroll.service';
import { HttpParamSerializerService } from './http-param-serializer.service';
import { LogService } from './log.service';
import { WindowService } from './window.service';

@NgModule({
  declarations: [],
  exports: [],
  imports: [],
  providers: [
    AnchorScrollService,
    HttpParamSerializerService,
    LogService,
    WindowService,
  ],
})
export class NgProxyModule {}
