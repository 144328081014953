import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as App from 'app/ts/app';
import { IItemGrab } from 'app/ts/Client.IItemGrab';
import { ClickedItemInfo } from 'app/ts/Client/ClickedItemInfo';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorService } from 'app/ts/services/ConfigurationLogic/InteriorService';
import { D3Service } from 'app/ts/services/D3Service';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';
import { Injectable } from '@angular/core';
import { PartitionPlan } from 'app/partition/partition-plan-data.service';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import {
  ISubscription,
  Observable as OldObservable,
} from 'app/ts/util/Observable';
import { Observable } from 'rxjs';

type BooleanToggle = boolean | 'toggle';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'd3-display',
  templateUrl: './d3Display.html',
  styleUrls: ['../../style/d3.scss'],
})
export class D3DisplayVm extends BaseVm implements OnInit {
  private static readonly moveDist = 50;
  private static readonly zoomAmount = 75;

  private canvasContainer: HTMLElement | null = null;
  private canvas!: HTMLCanvasElement;

  private renderer!: D3Service.Renderer;

  private buttonTriggerInterval: number | undefined = undefined;

  private _partitionPlan!: PartitionPlan;

  @Input()
  public get PartitionPlan(): PartitionPlan {
    return this._partitionPlan;
  }

  public set PartitionPlan(partitionPlan: PartitionPlan | null) {
    if (partitionPlan == null) {
      return;
    }
    this._partitionPlan = partitionPlan;
  }

  @Input()
  public cabinetSection?: Client.CabinetSection;
  @Input()
  public resizer: Observable<void> | undefined;

  private _selectedItems!: Client.ConfigurationItem[];

  @Input()
  public get selectedItems(): Client.ConfigurationItem[] {
    return this._selectedItems;
  }

  public set selectedItems(items: Client.ConfigurationItem[] | null) {
    if (items == null) {
      return;
    }
    if (this.renderer != null) {
      this.renderer.currentSelection = items;
    }
    this._selectedItems = items;
  }

  @Output()
  public itemsSelected = new EventEmitter<Client.ConfigurationItem[]>();

  @Input()
  public currentDragInfoObservable!: OldObservable<
    Client.ProductDragInfo | undefined
  >;
  public isInteriorItemSelectable = true;
  public isInteriorItemMovable = true;

  @Input()
  public showUndoRedo = false;

  constructor(
    baseVmService: BaseVmService,
    private readonly d3Service: D3Service,
    private readonly interiorService: InteriorService,
    private readonly floorPlanService: FloorPlanService,
    private readonly configurationItemService: ConfigurationItemService,
    private readonly editorType: EditorTypeService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this._showSpinner = true;
    this.canvasContainer = window.document.getElementsByClassName(
      'canvas-container',
    )[0] as HTMLElement;

    this.d3Service.clearRenderer();
    this.renderer = this.d3Service.getRenderer(
      this.editorType.floorPlan,
      this.cabinetSection,
      this.PartitionPlan,
    );
    this.oldSubscriptions.push(this.renderer);
    this.canvas = this.renderer.canvas;
    this.canvasContainer.appendChild(this.canvas);
    this.handleCanvasResize(null);

    this.showCorpus = this._showCorpus;
    this.showInterior = this._showInterior;
    this.showOtherCabinets = this._showOtherCabinets;
    this.showCollisions = this._showCollisions;
    this.showErrorLevel = this._showErrorLevel;
    this.updateDoors();
    this.updateRulers();
    this.updatePullout();

    window.addEventListener(
      'resize',
      this.handleCanvasResize.bind(this),
      false,
    );

    if (App.debug.showD3ChangeReasons) {
      this.subscribeTo(this.renderer.changes$, (change) => {
        console.debug('d3Service changed: ' + change);
      });
    }

    this.subscribeTo(this.renderer.changes$, (changeReason) => {
      this.renderer.render();
      if (changeReason == 'contents updated') {
        this.showSpinner = false;
      }
    });

    if (this.resizer) {
      this.subscribeTo(this.resizer, () => this.handleCanvasResize(null));
    }

    if (this.currentDragInfoObservable) {
      this.subscribeToOld(this.currentDragInfoObservable, (dragInfo) => {
        this.handleDragInfo(dragInfo);
      });
    }
  }

  private get cabinet() {
    return this.cabinetSection && this.cabinetSection.cabinet;
  }
  private get floorPlan() {
    return this.cabinet && this.cabinet.floorPlan;
  }

  private _showPartitions = true;
  @Input()
  public get showPartitions() {
    if (this.renderer) return this.renderer.getVisible('partitions');
    return this._showPartitions;
  }
  public set showPartitions(v: boolean) {
    if (this.renderer) this.renderer.setVisible('partitions', v);
    this._showPartitions = v;
  }

  private _showCorpus = true;
  @Input()
  public get showCorpus() {
    if (this.renderer) return this.renderer.getVisible('corpus');
    return this._showCorpus;
  }
  public set showCorpus(v: boolean) {
    if (this.renderer) this.renderer.setVisible('corpus', v);
    this._showCorpus = v;
  }

  private _showInterior = true;
  @Input()
  public get showInterior() {
    if (this.renderer) return this.renderer.getVisible('interior');
    return this._showInterior;
  }
  public set showInterior(v: boolean) {
    if (this.renderer) this.renderer.setVisible('interior', v);
    this._showInterior = v;
  }

  private _showOtherCabinets = false;
  @Input()
  public get showOtherCabinets() {
    if (this.renderer) return this.renderer.getVisible('otherCabinets');
    return this._showOtherCabinets;
  }
  public set showOtherCabinets(v: boolean) {
    if (this.renderer) this.renderer.setVisible('otherCabinets', v);
    this._showOtherCabinets = v;
  }

  private _showCollisions = true;
  @Input()
  public get showCollisions() {
    if (this.renderer) {
      return this.renderer.getVisible('collision');
    }
    return this._showCollisions;
  }
  public set showCollisions(v: boolean | undefined) {
    if (v === undefined) {
      return;
    }
    if (this.renderer) {
      this.renderer.setVisible('collision', v);
    }
    this._showCollisions = v;
  }

  private _showErrorLevel = false;
  public get showErrorLevel() {
    if (this.renderer) {
      return this.renderer.getVisible('errorLevel');
    }
    return this._showErrorLevel;
  }
  public set showErrorLevel(v: boolean | undefined) {
    if (v === undefined) return;
    if (this.renderer) this.renderer.setVisible('errorLevel', v);
    this._showErrorLevel = v;
  }

  // #region doors
  private _showDoors: BooleanToggle = true;
  private static _actualShowDoors = false;
  @Input()
  public get showDoors(): BooleanToggle {
    return this._showDoors;
  }
  public set showDoors(v: BooleanToggle) {
    this._showDoors = v;
    this.updateDoors();
  }

  public toogleDoors() {
    D3DisplayVm._actualShowDoors = !D3DisplayVm._actualShowDoors;
    this.updateDoors();
  }

  private updateDoors() {
    if (!this.renderer) {
      return;
    }
    let val =
      this.showDoors === 'toggle'
        ? D3DisplayVm._actualShowDoors
        : this.showDoors;
    this.renderer.setVisible('doors', val);
    this.renderer.setVisible('rails', val);
  }

  // #endregion doors

  // #region rulers
  private _showRulers: BooleanToggle = false;
  private static _actualShowRulers = true;
  @Input()
  public get showRulers(): BooleanToggle {
    return this._showRulers;
  }
  public set showRulers(val: BooleanToggle) {
    this._showRulers = val;
    this.updateRulers();
  }

  public toggleRulers() {
    D3DisplayVm._actualShowRulers = !D3DisplayVm._actualShowRulers;
    this.updateRulers();
  }

  private updateRulers() {
    if (!this.renderer) {
      return;
    }
    let val =
      this.showRulers === 'toggle'
        ? D3DisplayVm._actualShowRulers
        : this.showRulers;
    this.renderer.setVisible('rulers', val);
  }

  // #endregion rulers

  // #region pullout warnings

  private _showPullout: BooleanToggle | undefined = false;
  private static _actualShowPullout = false;
  @Input()
  public get showPullout() {
    return this._showPullout;
  }
  public set showPullout(val: BooleanToggle | undefined) {
    this._showPullout = val;
    this.updatePullout();
  }
  public togglePullout() {
    D3DisplayVm._actualShowPullout = !D3DisplayVm._actualShowPullout;
    this.updatePullout();
  }
  private updatePullout() {
    if (!this.renderer) {
      return;
    }
    let val: boolean;
    let sp = this.showPullout;
    if (sp === true) {
      val = true;
    } else if (sp === false) {
      val = false;
    } else if (sp === undefined) {
      val = false;
    } else if (sp === 'toggle') {
      val = D3DisplayVm._actualShowPullout;
    } else {
      throw new Error('Not implemented');
    }
    this.renderer.setVisible('pullout', val);
  }

  // #endregion

  private _showSpinner = false;
  public get showSpinner() {
    return this._showSpinner;
  }

  public set showSpinner(val: boolean) {
    this._showSpinner = val;
  }

  @Input()
  public allowMoveInterior: boolean = false;

  private dragInfo:
    | {
        isDragStarted: boolean;
        readonly itemGrab: IItemGrab;
        readonly mouseStartPos: Interface_DTO_Draw.Vec2d;
        readonly grabType: 'existingItem' | 'itemCopy' | 'newItem';
      }
    | undefined = undefined;

  public onMouseDown(mouseEvent: MouseEvent | TouchEvent) {
    if (!this.allowMoveInterior) return;

    if (this.dragInfo) {
      this.dragInfo.itemGrab.dispose();
      this.dragInfo = undefined;
    }
    if (mouseEvent instanceof MouseEvent) {
      if (!(mouseEvent.buttons & 1)) {
        //Not using LMB
        return;
      }
    } else if (TouchEvent && mouseEvent instanceof TouchEvent) {
      if (mouseEvent.touches.length > 1) {
        //ignore 2finger-click
        return;
      }
    }
    const mousePos = this.getMousePosition(mouseEvent);
    let clickedItem = this.renderer.getClickedItemInfo(mousePos);

    if (
      clickedItem &&
      clickedItem.item.ItemType !== Interface_Enums.ItemType.Interior
    ) {
      clickedItem = undefined;
    }
    let selection = clickedItem ? [clickedItem.item] : [];

    this.itemsSelected.emit(selection);
    if (selection.length == 0) return;
    if (!clickedItem) return;

    let clickPosition = {
      X: clickedItem.pos.X + clickedItem.item.X,
      Y: clickedItem.pos.Y + clickedItem.item.Y,
    };
    let snapService = this.interiorService.getSnapInfoService(
      selection[0].cabinetSection,
      selection,
      clickPosition,
    );

    let itemGrab = this.renderer.grabItem(clickedItem, snapService);
    this.dragInfo = {
      itemGrab,
      isDragStarted: false,
      mouseStartPos: mousePos,
      grabType: 'existingItem',
    };
  }

  public onMouseMove(evt: MouseEvent | TouchEvent) {
    const mousePos = this.getMousePosition(evt);
    if (this.currentDragInfoObservable && !this.dragInfo) {
      let dragData = this.currentDragInfoObservable.value;
      if (dragData) {
        let grabType: 'itemCopy' | 'newItem';
        //user is (as fas as we can tell) dragging in a product from productList or similar, set dragInfo
        let items: [Client.ConfigurationItem];
        if (dragData.item) {
          grabType = 'itemCopy';
          items = this.configurationItemService.createInteriorItemFromOtherItem(
            dragData.item,
            this.cabinetSection!,
          ).items;
        } else {
          grabType = 'newItem';
          items = this.configurationItemService.createInteriorItem(
            dragData.productId,
            dragData.materialId || null,
            this.cabinetSection!,
          ).items;
        }
        let mainItem = items[0];

        let pos = this.renderer.getCabinetSectionPos(mousePos);

        if (pos) {
          // Moving an item from one section to another is hard.
          // IMPROVE by allowing this.
          // for now, user must select the section to insert into before doing the insert.
          if (pos.cabinetIndex !== this.cabinetSection!.CabinetIndex) {
            return;
          }
          if (
            pos.cabinetSectionIndex !== this.cabinetSection!.CabinetSectionIndex
          ) {
            return;
          }

          mainItem.X = 0;
          mainItem.Y = 0;

          let snapService = this.interiorService.getSnapInfoService(
            this.cabinetSection!,
            [mainItem],
            mainItem,
          );

          let clickItemInfo: ClickedItemInfo = {
            item: mainItem,
            pos: {
              X: mainItem.Width / 2,
              Y: mainItem.Height / 2,
              Z: mainItem.Depth,
            },
          };
          let itemGrab = this.renderer.grabItem(clickItemInfo, snapService);

          this.dragInfo = {
            itemGrab,
            isDragStarted: true,
            mouseStartPos: mousePos,
            grabType,
          };
          this.itemsSelected.emit([mainItem]);
        }
      }
    }

    if (!this.dragInfo) {
      return;
    }

    if (!this.dragInfo.isDragStarted) {
      let dist = VectorHelper.dist(mousePos, this.dragInfo.mouseStartPos);
      const initailMinimumDragDist = 6; //mouse must move at least 6 px to start a drag
      this.dragInfo.isDragStarted = dist > initailMinimumDragDist;
    }
    if (!this.dragInfo.isDragStarted) {
      return;
    }

    this.dragInfo.itemGrab.setMouse(mousePos);
  }

  public onMouseUp(evt: MouseEvent | TouchEvent) {
    evt.preventDefault();
    if (this.currentDragInfoObservable) {
      this.currentDragInfoObservable.value = undefined;
    }
    if (!this.dragInfo) return;
    //evt.stopPropagation();
    if (this.dragInfo.isDragStarted) {
      const mousePos = this.getMousePosition(evt);
      this.dragInfo.itemGrab.setMouse(mousePos);
      let placeResult = this.dragInfo.itemGrab.place();
      this.itemsSelected.emit(placeResult.items);
      this.floorPlanService.setChanged(this.cabinetSection!.cabinet.floorPlan);
    }
    this.dragInfo.itemGrab.dispose();
    this.dragInfo = undefined;
  }

  public onMouseLeave(evt: MouseEvent) {}

  public onMouseEnter(evt: MouseEvent) {
    if (!this.dragInfo) return;
    if (evt.buttons & 1) {
      //LMB is pressed
      //TODO handle dragging in new products
    } else {
      //LMB is not pressed
      this.dragInfo.itemGrab.dispose();
      this.dragInfo = undefined;
    }
  }

  private getMousePosition(
    evt: MouseEvent | TouchEvent,
  ): Interface_DTO_Draw.Vec2d {
    if (evt instanceof MouseEvent) {
      return {
        X: evt.offsetX,
        Y: evt.offsetY,
      };
    } else {
      //TouchEvents don't have offsetX/Y
      let canvasElement = this.canvas;
      //let canvasElement = evt.target as Element;
      let canvasRect = canvasElement.getBoundingClientRect();

      let result = {
        X: evt.changedTouches[0].clientX - canvasRect.left,
        Y: evt.changedTouches[0].clientY - canvasRect.top,
      };
      return result;
    }
  }

  private handleDragInfo(dragInfo?: Client.ProductDragInfo) {
    if (
      dragInfo &&
      dragInfo.touchEventObservable &&
      dragInfo.touchEventObservable.value
    ) {
      //touch event is starting
      let lastEvent: TouchEvent | undefined;
      //this.mouseMove(dragInfo.touchEventObservable.value);
      this.touchEventSubscription = dragInfo.touchEventObservable.observe(
        (touchEvent) => {
          if (touchEvent) {
            let touchPos = this.getMousePosition(touchEvent);
            if (touchPos.X > 0 && touchPos.X < this.cabinetSection!.Width) {
              //quick check to see if touch is inside canvas bounds
              lastEvent = touchEvent;
              this.onMouseMove(touchEvent);
            } else {
              if (touchPos.X < -400) {
                console.debug('touchEvent was outside of bounds: ', touchPos);
              }
            }
          } else {
            this.touchEventSubscription = undefined;
            if (lastEvent) this.onMouseUp(lastEvent);
          }
        },
      );
    } else {
      //touch drag event is ending
      this.touchEventSubscription = undefined;
    }
  }

  private _touchEventSubscription: ISubscription | undefined;
  private set touchEventSubscription(val: ISubscription | undefined) {
    if (this._touchEventSubscription) {
      this._touchEventSubscription.dispose();
    }
    this._touchEventSubscription = val;
  }

  // #region camera movements
  public moveLeft() {
    this.startIntervalAction(() =>
      this.renderer.offsetCameraRight(-D3DisplayVm.moveDist),
    );
  }

  public moveRight() {
    this.startIntervalAction(() =>
      this.renderer.offsetCameraRight(D3DisplayVm.moveDist),
    );
  }

  public moveUp() {
    this.startIntervalAction(() =>
      this.renderer.offsetCameraUp(D3DisplayVm.moveDist),
    );
  }

  public moveDown() {
    this.startIntervalAction(() =>
      this.renderer.offsetCameraUp(-D3DisplayVm.moveDist),
    );
  }

  public resetCamera() {
    this.renderer.resetCamera();
    if (document.activeElement) (<HTMLElement>document.activeElement).blur();
  }

  public zoomIn() {
    this.startIntervalAction(() => this.renderer.zoom(D3DisplayVm.zoomAmount));
  }

  public zoomOut() {
    this.startIntervalAction(() => this.renderer.zoom(-D3DisplayVm.zoomAmount));
  }

  public stopIntervalAction() {
    clearInterval(this.buttonTriggerInterval);
  }

  private startIntervalAction(action: () => void) {
    action();
    this.buttonTriggerInterval = setInterval(action, 150);
  }

  // #endregion camera movements

  private resizeDelayHandle?: number;
  private handleCanvasResize(event: Event | null): void {
    if (this.disposed) return;

    if (
      !this.canvasContainer ||
      !this.canvas ||
      this.canvas.parentElement !== this.canvasContainer
    )
      return;

    let width = this.canvasContainer.clientWidth;
    let height = this.canvasContainer.clientHeight;

    if (width === 0 || height === 0) {
      //some browsers have this problem sometimes - give the container element a chance to find its size and try again
      window.setTimeout(() => this.handleCanvasResize(event), 2000);
      return;
    }

    this.clearResizeHandle();

    // Wait a bit before actually resizing, so the resize event handler is smooth
    this.resizeDelayHandle = window.setTimeout(() => {
      this.renderer.setRendererSize({ X: width, Y: height });
      this.renderer.restoreCamera();
    }, 200);
  }

  private clearResizeHandle() {
    if (this.resizeDelayHandle) {
      window.clearTimeout(this.resizeDelayHandle);
      this.resizeDelayHandle = undefined;
    }
  }

  // #region undo/redo

  public get isUndoPossible() {
    return this.floorPlanService.isUndoPossible(
      this.editorType.floorPlan ??
        this.floorPlanService.getCurrentConsumerFloorPlan(),
    );
  }
  public undo() {
    if (!this.isUndoPossible) {
      return;
    }
    this.floorPlanService.undo(
      this.editorType.floorPlan ??
        this.floorPlanService.getCurrentConsumerFloorPlan(),
    );
  }

  public get isRedoPossible() {
    return this.floorPlanService.isRedoPossible(
      this.editorType.floorPlan ??
        this.floorPlanService.getCurrentConsumerFloorPlan(),
    );
  }
  public redo() {
    if (!this.isRedoPossible) {
      return;
    }
    this.floorPlanService.redo(
      this.editorType.floorPlan ??
        this.floorPlanService.getCurrentConsumerFloorPlan(),
    );
  }

  // #endregion

  protected override dispose() {
    super.dispose();
    this.clearResizeHandle();
    if (this.canvasContainer) {
      this.canvasContainer.removeChild(this.canvas);
    }
  }
}
