import { inject } from '@angular/core';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { Command } from 'app/imperative-shell';

export class SetUseFullCatalog implements Command {
  constructor(private useFullCatalog: boolean) {}

  public execute(): Promise<Command[]> {
    const permissions = inject(PermissionService);
    const data = inject(FunctionalCoreService);

    if (permissions.canUseFullCatalog) {
      data.ambient.clientSetting.setUseFullCatalog(this.useFullCatalog);
    }
    return Promise.resolve([]);
  }
}
