<h3>
  {{
    "fillings_header_selected_module_{0}"
      | translate: "Module {0}" : moduleSectionIndex
  }}
</h3>

<!-- If fillings can be selected -->
<div *ngIf="true">
  <div class="form-group form-group-sm">
    <label
      for="rm-door-num-fillings"
      [textContent]="
        'doors_label_num_fillings' | translate: 'Number of fillings'
      "
    ></label>
    <input
      #numFillingsInput
      id="rm-door-num-fillings"
      type="number"
      class="form-control"
      min="1"
      max="7"
      [value]="numberOfFillings"
      (change)="numberOfFillings = numFillingsInput.valueAsNumber"
    />
  </div>
</div>

<div>
  <span
    [textContent]="'fillings_label_vertical_bars' | translate: 'Vertical bars'"
  ></span>
  <select
    id="module-vertical-bars"
    class="form-select"
    [(ngModel)]="selectedModuleVerticalBarOptionNumber"
  >
    <option
      *ngFor="let vbo of selectedModule.availableVerticalBarOptions"
      [ngValue]="vbo.Number"
      [textContent]="vbo.DefaultName"
    >
      {{ vbo.Number }}
    </option>
  </select>
</div>

<hr class="section-divider" />

<h3>
  {{
    "fillings_header_selected_filling_{0}_{1}"
      | translate
        : "Module {0} - Filling {1}"
        : moduleSectionIndex
        : selectedFilling.id
  }}
</h3>

<pickable
  class="modal-opener material filling-material"
  [pickable]="selectedFillingMaterial"
  (click)="selectFillingMaterial()"
></pickable>

<!-- If it can have more than 1 filling -->
<div *ngIf="true">
  <button
    class="btn btn-block btn-light"
    (click)="useSelectedFillingOnAll()"
    [textContent]="
      'fillings_button_use_material_on_all' | translate: 'Use on all fillings'
    "
  ></button>
</div>

<hr class="section-divider" />

<!-- If have more than 1 filling -->
<button
  class="btn btn-block btn-light mt-2"
  (click)="spreadFillingsEvenly()"
  [textContent]="
    'fillings_button_spred_fillings_evenly'
      | translate: 'Spread fillings evenly'
  "
></button>

<button
  class="btn btn-block btn-light mt-1"
  (click)="copyModuleConfigurationToOthers()"
  [textContent]="
    'fillings_button_copy_module' | translate: 'Copy to all modules'
  "
></button>
