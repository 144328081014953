import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from 'app/ts/clientDto/Product';
import { ProductDragInfo } from 'app/ts/clientDto/ProductDragInfo';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { Observable } from 'app/ts/util/Observable';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'interior-product',
  templateUrl: './interiorProduct.html',
  styleUrls: ['./interiorProduct.scss'],
})
export class InteriorProductVm extends BaseVm implements OnChanges {
  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }

  //region angular properties

  @Input()
  public product!: Product;
  @Input()
  public currentDragInfoObservable = new Observable<
    ProductDragInfo | undefined
  >(undefined);

  //endregion

  public ngOnChanges(changes: SimpleChanges): void {
    let pick = ProductHelper.getPickableAsProductGroup(this.product);
    this.name = pick.name;
    this.imageUrl = pick.imageUrl || '';
    this.imgStyle = {
      'background-image': pick.imageUrl ? "url('" + pick.imageUrl + "')" : '',
    };
    this.minPrice = this.formatPrice(this.product.minPrice);
    this.showMinPrice = this.product.minPrice !== null;
    let mainProduct = this.product.productGroup.filter(
      (p) =>
        p.ProductGroupingNo === p.ProductNo ||
        p.ProductNo === '' ||
        p.ProductGroupingNo === '',
    )[0];
    this.productName = mainProduct.ProductGroupingName;
  }

  public name: string = '';
  public imageUrl: string = '';
  public imgStyle: any = {}; //css style
  public minPrice: string | null = null;
  public showMinPrice: boolean = false;
  public productName: string = '';

  private ongoingTouch: { observable: Observable<TouchEvent> } | undefined;

  public startDrag(evt: MouseEvent | TouchEvent): void {
    if (!this.currentDragInfoObservable) return;
    this.currentDragInfoObservable.value = {
      productId: this.product.Id,
      touchEventObservable: null,
    };
    if (!(window as any).TouchEvent || !(evt instanceof TouchEvent)) {
      evt.preventDefault();
    }
    //this.onProductClick({ product: product, variants: [] })
  }

  public stopDrag(evt: MouseEvent | TouchEvent) {
    if (!this.currentDragInfoObservable) return;
    this.currentDragInfoObservable.value = undefined;
  }

  public touchStart(evt: TouchEvent): void {
    if (!this.currentDragInfoObservable) return;
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    } else {
      let observable = new Observable(evt);
      this.ongoingTouch = {
        observable: observable,
      };
      this.currentDragInfoObservable.value = {
        productId: this.product.Id,
        touchEventObservable: observable,
      };
    }
  }
  public touchMove(evt: TouchEvent): void {
    if (this.ongoingTouch) {
      this.ongoingTouch.observable.value = evt;
    }
  }
  public touchEnd(evt: TouchEvent): void {
    if (evt.touches.length > 0) {
      //Touch is not done, but a finger has been lifted
      return;
    }
    if (this.ongoingTouch) {
      this.ongoingTouch = undefined;
    }
  }
  public touchCancel(evt: TouchEvent): void {
    this.touchEnd(evt);
  }

  private formatPrice(p: number | null): string | null {
    if (p === null || p === 0) return null;
    return p.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  }
}
