import { FloorPlan } from 'app/ts/clientDto';
import { PartitionPlan } from '../partition-plan-data.service';
import { Door } from './door';
import { Section } from './section';
import { Wall } from './wall';
import { Module, ModuleId, Door as DoorModule } from '../module';
import { Profile } from '../profile';

/** This is the basis for the SVG showing
 * partitions.
 */
export class Plan {
  constructor(
    private _sections: Section[],
    private _profiles: Profile[],
  ) {}

  public get sections(): Section[] {
    return this._sections;
  }

  public get walls(): Wall[] {
    return this._sections.flatMap((sec) => sec.walls);
  }

  public get doors(): Door[] {
    return this._sections.flatMap((sec) => sec.doors);
  }

  public get profiles(): Profile[] {
    return this._profiles;
  }
}

export function calculatePlan(
  partitionPlan: Readonly<PartitionPlan>,
  floorPlan: FloorPlan,
): Plan {
  const moduleLookup: { [id: ModuleId]: Module } = Object.assign(
    {},
    ...partitionPlan.modules.map((module) => ({ [module.id]: module })),
  );

  const sections: Section[] = partitionPlan.sections.map((sec) => {
    const walls: Wall[] = sec.modules
      .map((moduleId) => moduleLookup[moduleId])
      .filter((module) => module.isWall)
      .map((wall) => {
        return new Wall(
          wall.id,
          wall.sectionId,
          wall.posX,
          wall.posY,
          wall.width,
          wall.depth,
          wall.hasError,
        );
      });

    const doors: Door[] = sec.modules
      .map((moduleId) => moduleLookup[moduleId])
      .filter((module) => module.isDoor)
      .map((d) => {
        let door = d as DoorModule;
        return new Door(
          door.id,
          door.sectionId,
          door.posX,
          door.posY,
          door.width,
          door.depth,
          door.hasError,
          door.placement,
          door.openDirection,
          door.leftSlideExtensionAmount,
          door.rightSlideExtensionAmount,
        );
      });

    return new Section(
      sec.id,
      sec.posX,
      sec.posY,
      sec.rotation,
      sec.width,
      sec.height,
      sec.unevenLeftWall,
      sec.unevenRightWall,
      floorPlan.editorAssets.railSetsDict[
        sec.references.wallRailSetId
      ].topProduct!,
      floorPlan.editorAssets.materialsDict[sec.references.profileMaterialId],
      floorPlan.editorAssets.railSetsDict[sec.references.wallRailSetId],
      walls,
      doors,
      sec.hasError,
    );
  });

  const plan = new Plan(sections, partitionPlan.profiles);
  return plan;
}
