import {
  CabinetSection,
  Cabinet,
  FloorPlan,
  Product,
  ConfigurationItem,
} from '../ts/clientDto';
import { calculateDovetailJointVariants } from './JointVariantCalculations';

export function calculateExtraVariants(floorplan: FloorPlan) {
  floorplan.cabinets.forEach((cabinet) =>
    calculateExtraCabinetVariants(cabinet),
  );

  //Here variants which should be calculated on the floorplan can be added
}

function calculateExtraCabinetVariants(cabinet: Cabinet) {
  cabinet.cabinetSections.forEach((section) =>
    calculateExtraCabinetSectionVariants(section),
  );

  calculateDovetailJointVariants(cabinet);

  //Here variants which should be calculated on the cabinet can be added
}

function calculateExtraCabinetSectionVariants(cabinetSection: CabinetSection) {
  //Here variants which should be calculated on the cabinetSection can be added
}
