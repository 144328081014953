<ng-container *ngIf="show">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMinYMin"
    id="floor-plan-canvas"
    width="100%"
    viewBox="0 0 1 1"
    [attr.viewBox]="'-400 -400 ' + overlayWidth + ' ' + overlayHeight"
  >
    <rect
      x="-400"
      y="-400"
      [attr.width]="overlayWidth"
      [attr.height]="overlayHeight"
      fill="white"
      fill-opacity="80%"
    />

    <!-- Border -->
    <path
      attr.d="M0 0 L{{ floorPlanWidth }} 0 L{{ floorPlanWidth }} {{
        floorPlanHeight
      }} L0 {{ floorPlanHeight }} L0 0"
      stroke="black"
      stroke-width="5"
      fill="transparent"
    />

    <!-- Scores -->
    <text
      [attr.x]="floorPlanWidth / 2"
      [attr.y]="-200"
      [attr.dx]="-150"
      [attr.dy]="0"
      [textContent]="p1Score"
      class="score-text"
    />

    <text
      [attr.x]="floorPlanWidth / 2"
      [attr.y]="-200"
      [textContent]="'-'"
      class="score-text"
    />

    <text
      [attr.x]="floorPlanWidth / 2"
      [attr.y]="-200"
      [attr.dx]="100"
      [attr.dy]="0"
      [textContent]="p2Score"
      class="score-text"
    />

    <!-- Count Down -->
    <g *ngIf="showCountDown">
      <text
        [attr.x]="floorPlanWidth / 2"
        [attr.y]="floorPlanHeight / 2 - 500"
        [textContent]="countDownCount"
        class="count-down-text"
      />
    </g>

    <!-- Player 1 -->
    <rect
      [attr.x]="p1X"
      [attr.y]="p1Y"
      [attr.width]="batThickness"
      [attr.height]="batWidth"
      fill="black"
    />

    <!-- Player 2 -->
    <rect
      [attr.x]="p2X"
      [attr.y]="p2Y"
      [attr.width]="batThickness"
      [attr.height]="batWidth"
      fill="black"
    />

    <!-- Ball -->
    <circle
      [attr.cx]="ballPos.X"
      [attr.cy]="ballPos.Y"
      [attr.r]="ballSize"
      fill="black"
    />
  </svg>
</ng-container>
