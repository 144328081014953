<div
  class="pickable"
  [ngClass]="{'disabled': pickable.disabledReason, 'selected': pickable.isSelected, 'warn': pickable.warnReason || pickable.override}"
  [attr.title]="title"
  data-pickable-id="componentVm.pickable.id"
  *ngIf="pickable"
>
  <div class="image" *ngIf="pickable.imageUrl">
    <img [src]="pickable.imageUrl" />
  </div>
  <span
    *ngIf="pickable.overlay"
    class="overlay"
    [textContent]="pickable.overlay"
  ></span>
  <div class="head">
    <span class="id" [textContent]="pickable.id"></span>
    <span class="name" [textContent]="pickable.name"></span>
    <span class="bi"></span>
  </div>
</div>
