<div class="modal-content object-detail project-detail">
  <div class="modal-header">
    <h3
      class="modal-title"
      [textContent]="translate('Project_detail_modal_create_header', 'New Project')"
    ></h3>
    <button type="button" class="close" (click)="cancel()">&times;</button>
  </div>
  <div class="modal-body">
    <project-edit [detailObject]="dtoObject"> </project-edit>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        class="btn btn-primary-custom"
        type="submit"
        (click)="save()"
        [textContent]="translate('Project_detail_modal_create_button_save', 'Create')"
      ></button>
      <button
        class="btn btn-default"
        type="button"
        (click)="cancel()"
        [textContent]="translate('Project_detail_modal_create_button_cancel', 'Cancel')"
      ></button>
    </div>
  </div>
</div>
