<table>
  <tbody>
    <tr
      [attr.title]="translate('Customer_detail_title_DebitorNo', 'DebitorNo')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="
          translate('Customer_detail_title_DebitorNo', 'DebitorNo')
        "
      ></td>
      <td class="detail-value">
        <input
          class="form-control"
          type="text"
          name="DebitorNo"
          [(ngModel)]="detailObject.DebitorNo"
        />
      </td>
    </tr>

    <tr
      [attr.title]="translate('Customer_detail_title_Name', 'Name')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Name', 'Name')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control"
          type="text"
          name="detailObjectName"
          [(ngModel)]="detailObject.Name"
          maxlength="50"
          ngModel
          required
        />
      </td>
    </tr>

    <tr
      [attr.title]="translate('Customer_detail_title_Address1', 'Address1')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Address1', 'Address1')"
      ></td>
      <td class="detail-value">
        <div
          class="dropdown"
          ngbDropdown
          [open]="addressSuggestionsOpen"
          autoClose="outsideClick"
        >
          <input
            class="form-control detail-Address1"
            name="Address1"
            [formControl]="address1Control"
            type="text"
            autocomplete="off"
            ngModel
            required
            [(ngModel)]="detailObject.Address1"
            maxlength="50"
            (blur)="address1Blur()"
            (keydown)="address1Key($event)"
            (paste)="onPasteAddress($event)"
            [attr.placeholder]="
              translate('Customer_detail_placeholder_Address1', 'Address1')
            "
          />
          <ul class="dropdown-menu" role="menu" ngbDropdownMenu>
            <li
              role="menuitem"
              ngbDropdownItem
              *ngFor="
                let suggestion of addressSuggestions?.Suggestions;
                let idx = index
              "
              (click)="useSuggestion(suggestion)"
              class="address-suggestion-item"
              [ngClass]="{ selected: selectedAddressSuggestion === idx }"
            >
              {{ suggestion.SuggestedText }}
            </li>
          </ul>
        </div>
      </td>
    </tr>

    <tr
      [attr.title]="translate('Customer_detail_title_Address2', 'Address2')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Address2', 'Address2')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Address2"
          name="Address2"
          type="text"
          maxlength="50"
          [(ngModel)]="detailObject.Address2"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_Address2', 'Address2')
          "
        />
      </td>
    </tr>

    <tr
      [attr.title]="translate('Customer_detail_title_country', 'Country')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_country', 'Country')"
      ></td>
      <td class="detail-value">
        <select
          class="form-select detail-Country"
          name="Country"
          ngModel
          required
          (ngModelChange)="countryChanged()"
          [(ngModel)]="detailObject.Country"
        >
          <option
            *ngFor="let country of countries"
            [ngValue]="country.Code"
            [textContent]="country.Name"
          ></option>
        </select>
      </td>
    </tr>

    <tr
      [attr.title]="translate('Customer_detail_title_PostalCode', 'PostalCode')"
      class="detail-property"
    >
      <td
        class="detail-type"
        [textContent]="
          translate('Customer_detail_title_PostalCode', 'PostalCode')
        "
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-PostalCode"
          name="PostalCode"
          autocomplete="off"
          type="text"
          ngModel
          required
          [disabled]="notEnabled"
          [(ngModel)]="detailObject.PostalCode"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_PostalCode', 'PostalCode')
          "
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('Customer_detail_title_City', 'City')"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_City', 'City')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Email"
          type="text"
          name="City"
          [(ngModel)]="detailObject.City"
          ngModel
          required
          [disabled]="notEnabled"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_City', 'City')
          "
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('Customer_detail_title_Email', 'Email')"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Email', 'Email')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-Email"
          type="text"
          name="Email"
          [(ngModel)]="detailObject.Email"
          [required]="requireEmail"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_Email', 'Email')
          "
        />
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('Customer_detail_title_Floor', 'Floor')"
      *ngIf="showFloorRow"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Floor', 'Floor')"
      ></td>
      <td class="detail-value" *ngIf="showFloorEditor">
        <input
          class="form-control detail-Floor"
          name="Floor"
          [ngStyle]="{ 'border-color': showFloorEditorWarning ? 'red' : '' }"
          type="number"
          [disabled]="notEnabled"
          min="0"
          [(ngModel)]="detailObject.Floor"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_Floor', 'Floor')
          "
        />
      </td>
      <td class="detail-value" *ngIf="!showFloorEditor">
        <span
          [textContent]="
            translate(
              'DeliveryAddress_detail_floor_service_not_available',
              'floor service not available for this postal code'
            )
          "
        ></span>
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('Customer_detail_title_Phone', 'Phone')"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_Phone', 'Phone')"
      ></td>
      <td class="detail-value">
        <phone-input
          [phoneNumberObservable]="phoneObservable"
          [defaultPrefix]="defaultPhonePrefix"
          [required]="true"
          (phoneNumberChange)="phoneNumberChange($event)"
        ></phone-input>
      </td>
    </tr>

    <tr
      class="detail-property"
      [attr.title]="translate('Customer_detail_title_SENumber', 'SENumber')"
    >
      <td
        class="detail-type"
        [textContent]="translate('Customer_detail_title_SENumber', 'SENumber')"
      ></td>
      <td class="detail-value">
        <input
          class="form-control detail-SENumber"
          name="SENumber"
          type="text"
          [(ngModel)]="detailObject.SENumber"
          [attr.placeholder]="
            translate('Customer_detail_placeholder_SENumber', 'SENumber')
          "
        />
      </td>
    </tr>
  </tbody>
</table>
