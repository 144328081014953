import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Client from 'app/ts/clientDto/index';
import { Project } from 'app/ts/clientDto/Project';
import { FloorPlanOverview } from 'app/ts/clientDto/FloorPlanOverview';
import { Collapsible } from 'app/ts/util/Collapsible';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ISelectable } from 'app/ts/util/ISelectable';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Copyable } from 'app/ts/interfaces/Copyable';
import { DtoContainer } from 'app/ts/interfaces/DtoContainer';
export class DeliveryAddress
  implements
    Interface_DTO.DeliveryAddress,
    Copyable<DeliveryAddress>,
    ISelectable,
    DtoContainer<Interface_DTO.DeliveryAddress>,
    Collapsible
{
  public selected: boolean = false;
  private _floorplans: FloorPlanOverview[] = [];
  private _childModifiedDate: string = '';
  private _collapsed = true;

  constructor(
    public readonly dtoObject: Interface_DTO.DeliveryAddress,
    public readonly project: Project,
  ) {
    if (!dtoObject) throw new Error('ArgumentNull: dtoObject');
    if (!project) throw new Error('ArgumentNull: project');

    this.FloorPlans = dtoObject.FloorPlans.map(
      (dtoFp) => new Client.FloorPlanOverview(dtoFp, this),
    );
  }

  //#region DTO Mappings
  get Address1() {
    return this.dtoObject.Address1;
  }
  get Address2() {
    return this.dtoObject.Address2;
  }
  get City() {
    return this.dtoObject.City;
  }
  get Country() {
    return this.dtoObject.Country;
  }
  get CreatedDate() {
    return this.dtoObject.CreatedDate;
  }
  get Email() {
    return this.dtoObject.Email;
  }
  get Floor() {
    return this.dtoObject.Floor;
  }
  get Id() {
    return this.dtoObject.Id;
  }
  get Name() {
    return this.dtoObject.Name;
  }
  get Phone() {
    return this.dtoObject.Phone;
  }
  get PostalCode() {
    return this.dtoObject.PostalCode;
  }
  get ProjectDeliveryAddressId() {
    return this.dtoObject.ProjectDeliveryAddressId;
  }
  get ProjectId() {
    return this.dtoObject.ProjectId;
  }
  get Unit() {
    return this.dtoObject.Unit;
  }
  get StoreId() {
    return this.dtoObject.StoreId;
  }
  get ERPCode() {
    return this.dtoObject.ERPCode;
  }
  get UserId() {
    return this.dtoObject.UserId;
  }

  //#endregion

  get ChildModifiedDate() {
    return this._childModifiedDate;
  }

  public get collapsed() {
    return this._collapsed;
  }
  public set collapsed(val: boolean) {
    this._collapsed = val;
  }

  public get shortName() {
    return this.Address1;
  }

  public get allChildrenSelected() {
    return (
      this.FloorPlans.length > 0 && this.FloorPlans.every((fp) => fp.selected)
    );
  }
  public set allChildrenSelected(val: boolean) {
    for (let fp of this.FloorPlans) fp.selected = val;
  }

  public get FloorPlans(): FloorPlanOverview[] {
    return this._floorplans;
  }
  public set FloorPlans(val: FloorPlanOverview[]) {
    this._floorplans = val;
    this.updateDate();
  }

  private updateDate() {
    this._childModifiedDate = DateHelper.maxDate(
      this.dtoObject.CreatedDate,
      ...this.FloorPlans.map((fp) => fp.CreatedDate),
    );
  }

  public copy() {
    return new DeliveryAddress(ObjectHelper.copy(this.dtoObject), this.project);
  }
}
