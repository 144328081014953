<div class="consumer-dimensions consumer consumer-page">
  <div class="consumer-nav">
    <navbar current-id="'new'"></navbar>
  </div>

  <div class="consumer-left">
    <h4>{{translate('consumer_dimensions_header', 'Base settings')}}</h4>
    <div class="consumer-dimension">
      <h6>{{translate('consumer_dimensions_width', 'Width')}}</h6>
      <div class="input-group" style="width: 12em">
        <input
          [(ngModel)]="width"
          class="form-control"
          [ngModelOptions]="{updateOn: 'blur'}"
          autofocus
          type="number"
        />
        <span class="input-group-text">mm</span>
      </div>
      <div class="help-text">
        {{translate('consumer_dimensions_width_help', 'Help text about measuring
        twice, ordering once' )}}
      </div>
    </div>
    <div class="consumer-dimension">
      <h6>{{translate('consumer_dimensions_height', 'Height')}}</h6>
      <div class="input-group" style="width: 12em">
        <input
          [(ngModel)]="height"
          class="form-control"
          [ngModelOptions]="{updateOn: 'blur'}"
          type="number"
        />
        <span class="input-group-text">mm</span>
      </div>
      <div class="help-text">
        {{translate('consumer_dimensions_height_help', 'Help text about
        measuring twice, ordering once' )}}
      </div>
    </div>

    <div class="consumer-dimension">
      <h6>{{translate('consumer_dimensions_depth', 'Depth')}}</h6>
      <div class="input-group" style="width: 12em">
        <input
          [(ngModel)]="depth"
          class="form-control"
          [ngModelOptions]="{updateOn: 'blur'}"
          type="number"
        />
        <span class="input-group-text">mm</span>
      </div>
      <div class="help-text">
        {{translate('consumer_dimensions_depth_help', 'Help text about measuring
        twice, ordering once' )}}
      </div>
    </div>

    <button (click)="next()" class="btn btn-primary btn-next">
      {{translate('consumer_btn_next', 'Next')}}
    </button>

    <div class="spacer"></div>

    <button
      class="btn btn-secondary btn-special btn-block"
      (click)="loadFloorPlans()"
      [textContent]="translate('consumer_dimensions_load_existing', 'Load existing solution')"
    ></button>

    <div class="input-group input-group-lg"></div>
  </div>

  <div class="consumer-main">
    <div class="help-box" *ngIf="displayDimensionHelp">
      <h3
        [textContent]="translate('consumer_dimensions_help_1_header', 'Cabinet measurements')"
      ></h3>
      <p
        class="help-text conserve-whitespace"
        [textContent]="translate('consumer_dimensions_help_1_p', 'Start by entering how big you want your cabinet to be.')"
      ></p>

      <h4
        [textContent]="translate('consumer_dimensions_help_2_header', 'Width')"
      ></h4>
      <p
        class="help-text conserve-whitespace"
        [textContent]="translate('consumer_dimensions_help_2_p', 'Measure the width at both top and bottom, and enter the smallest measurement.')"
      ></p>

      <h4
        [textContent]="translate('consumer_dimensions_help_3_header', 'Height')"
      ></h4>
      <p
        class="help-text conserve-whitespace"
        [textContent]="translate('consumer_dimensions_help_3_p', 'Measure the height at left and right side, and in the middle. Enter the smallest measurement.')"
      ></p>

      <h4
        [textContent]="translate('consumer_dimensions_help_4_header', 'Depth')"
      ></h4>
      <p
        class="help-text conserve-whitespace"
        [textContent]="translate('consumer_dimensions_help_4_p', 'Standard depth is 680 mm.')"
      ></p>
    </div>

    <div class="splash-screen">
      <div class="splash-screen--headers">
        <h1
          class="splash-screen--h1 conserve-whitespace"
          [textContent]="translate('consumer_dimensions_header_1', 'Design\nyour\ncabinet')"
        ></h1>
        <h2
          class="splash-screen--h2 conserve-whitespace"
          [textContent]="translate('consumer_dimensions_header_2', 'Enter the cabinet measurements and choose your favourite.')"
        ></h2>
      </div>
      <div
        class="splash-screen--img1 splash-screen--img"
        [ngStyle]="{'background-image': 'url(' + consumerFile('splash/img1.jpg') + ')'}"
      ></div>
      <div
        class="splash-screen--img2 splash-screen--img"
        [ngStyle]="{'background-image': 'url(' + consumerFile('splash/img2.jpg') + ')'}"
      ></div>
      <div
        class="splash-screen--img3 splash-screen--img"
        [ngStyle]="{'background-image': 'url(' + consumerFile('splash/img3.jpg') + ')'}"
      ></div>

      <h2
        class="splash-screen--h3 conserve-whitespace"
        [textContent]="translate('consumer_dimensions_header_3', 'Choose your favourite or design your own solution.')"
      ></h2>
      <div class="splash-screen--selector">
        <div class="normal-templates">
          <div
            class="template"
            *ngFor="let template of templates"
            [ngClass]="{'selected': template.Id === selectedTemplateId}"
            (click)="setTemplate(template)"
          >
            <div
              class="template--img"
              [ngStyle]="{'background-image': 'url(' + template.ImageUrl + ')'}"
            ></div>
            <div class="template--title">
              <span [textContent]="template.Name"></span>
            </div>
          </div>
        </div>
        <div class="template custom-template" (click)="setDefaultTemplate()">
          <div
            class="template--img"
            [ngStyle]="{'background-image': 'url(' + consumerFile('splash/defaultTemplate.jpg') + ')'}"
          ></div>
          <div class="template--title">
            <span
              [textContent]="translate('consumer_dimensions_default_template_name', 'Create your own...')"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="consumer-bottom">
        <div class="btn-group prev-next-btns">
            

        </div>
    </div> -->

  <consumer-logo></consumer-logo>
</div>
