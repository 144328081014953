import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Client from 'app/ts/clientDto/index';
export class DoorProfile {
  constructor(
    public readonly door: Client.Door,
    public readonly type: Enums.DoorProfilePosition,
  ) {}

  public position: Interface_DTO_Draw.Vec3d = { X: -1, Y: -1, Z: -1 };

  public size: Interface_DTO_Draw.Vec3d = { X: -1, Y: -1, Z: -1 };

  public get material() {
    return this.door.doorSubSection.profileMaterial;
  }

  public get product() {
    return this.door.doorSubSection.profile;
  }

  public get model3DPath() {
    if (this.product) {
      let productData = this.product.getProductData(
        this.material ? this.material.Id : undefined,
      );
      if (!productData) productData = this.product.getProductData();
      if (!productData) return null;
      if (!productData.Model3DPath) return null;
      return productData.Model3DPath + '_' + this.type;
    }
    return null;
  }
}
