import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { RailSetHelper } from 'app/ts/util/RailSetHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { ItemValidationService } from 'app/ts/services/Validation/ItemValidationService';
import { ProductHelper } from '../../util/ProductHelper';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { SwingFlexLogic } from '@Services/ConfigurationLogic/SwingFlexLogic';

export class InteriorValidationService {
  public static readonly Name = 'interiorValidationService';

  constructor() {}

  public accessTest() {
    return 'Interior Validation Service access test function';
  }

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    if (section.CabinetType !== Interface_Enums.CabinetType.SharedItems) {
      errorInfos.push(...InteriorValidationService.validateCollisions(section));
      //errorInfos.push(...InteriorValidationService.validateCollisionBetweenItemsInCorners(section));
      //errorInfos.push(...InteriorValidationService.validateCollisionBetweenItemsAndCabinet(section));
      errorInfos.push(
        ...InteriorValidationService.validateCollisionBetweenItemsAndDoors(
          section,
        ),
      );
      errorInfos.push(
        ...InteriorValidationService.validateItemDimensions(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateItemSnapSides(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateItemSnapLeftOrRight(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateSnappedAgainstWallOrFreeSpace(
          section,
        ),
      );
      errorInfos.push(
        ...InteriorValidationService.validateSnappedCorpus(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateRelationsToGables(section),
      );
      errorInfos.push(...InteriorValidationService.validatePullOut(section));
      errorInfos.push(
        ...InteriorValidationService.validateSnapUnderShelf(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateSnapUnderCoathanger(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateAllowedInCorner(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateCornerFreeSpace(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateCornerShelvesAreInCorner(section),
      );
      errorInfos.push(...InteriorValidationService.validateProducts(section));
      errorInfos.push(...InteriorValidationService.validateMaterials(section));
      errorInfos.push(
        ...InteriorValidationService.validateShelfDepthMatchesDrilling(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateItemsNotStickingOutBack(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateShelfAndGableSnapped(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateHingeCollision(section),
      );
      errorInfos.push(
        ...InteriorValidationService.validateItemWithGripNotBehindDoor(section),
      );

      errorInfos.push(
        ...InteriorValidationService.validateHingeSpecialCollision(section),
      );
    }

    return errorInfos;
  }

  private static validateHingeCollision(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    const result: Client.ErrorInfo[] = [];
    const hinges = section.swingFlex.areas.flatMap((area) => area.hinges);

    for (let item of section.interior.items) {
      for (let hinge of hinges) {
        let _hinge = { ...hinge };

        if (section.isSwingFlex) {
          _hinge.Y += hinge.doorOffset;
        }

        if (VectorHelper.overlaps(item, _hinge)) {
          const err = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_interior_item_hinge_collision_short',
              'Item placed on top of hinge',
            ),
            new Client.Translatable(
              'validate_interior_item_hinge_collision_long',
              'Item is placed on top of hinge. Move the item or place a spacer.',
            ),
            section,
            item,
          );
          this.addError(err, result, [item]);

          break; //ignore the rest of the hinges
        }
      }
    }
    return result;
  }

  /**
   * Validates if there are any collisions between special hinges and interior items in a swing flex cabinet section.
   * Specifically checks for conflicts between pull down coat hangers and 155 degree hinges in the same sub-area.
   * @param section The cabinet section to validate
   * @returns Array of error info objects if collisions are found
   */
  private static validateHingeSpecialCollision(section: Client.CabinetSection) {
    const result: Client.ErrorInfo[] = [];
    if (!section.isSwingFlex) {
      return result;
    }

    for (let i = 0; i < section.swingFlex.areas.length; i++) {
      let area = section.swingFlex.areas[i];

      if (area.subAreas) {
        Enumerable.from(area.subAreas).forEach((subArea) => {
          const subAreaHasSpecialHinges =
            subArea.hasDoor &&
            (subArea.actualHingeTypeLeft === Enums.SwingFlexHingeType.Special ||
              subArea.actualHingeTypeRight ===
                Enums.SwingFlexHingeType.Special);

          if (subAreaHasSpecialHinges) {
            const interiorItems = SwingFlexLogic.getInteriorItemsInSubArea(
              section.swingFlex.cabinetSection,
              subArea,
            );

            const areaInteriorDepthMiddle = interiorItems.find(
              (item) =>
                item.swingFlexAreaIndex === area.index &&
                item.snapDepthMiddle &&
                item.isPullout,
            );

            if (areaInteriorDepthMiddle) {
              const err = new Client.ErrorInfo(
                Enums.EditorSection.Interior,
                Interface_Enums.ErrorLevel.Critical,
                new Client.Translatable(
                  'validate_interior_item_pulldown_coat_hanger_hinge_collision_short',
                  'Pull down coat hanger invalid hinge',
                ),
                new Client.Translatable(
                  'validate_interior_item_pulldown_coat_hanger_hinge_collision_long',
                  'Pull down coat hanger collide with special hinges. Move the item or remove the pull out item.',
                ),
                section,
                areaInteriorDepthMiddle,
              );
              this.addError(err, result, [areaInteriorDepthMiddle]);

              return;
            }
          }
        });
      }
    }

    return result;
  }
  static validateItemsNotStickingOutBack(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    let interiorBack = section.interior.cube.Z;
    if (section.isSwingFlex)
      interiorBack =
        section.swingFlex.productLineProperties.SwingFlexSpaceForBacking;

    for (let item of section.interior.items) {
      if (item.Z < interiorBack) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            new Client.Translatable(
              'validate_interior_item_too_deep_short',
              'Item is too deep',
            ),
            new Client.Translatable(
              'validate_interior_item_too_deep_long',
              '{0} is too deep to fit inside cabinet',
              item.Product ? item.Product.Name : item.Description,
            ),
            section,
            item,
          ),
        );
      }
    }

    return result;
  }

  private static validateCollisions(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errors: Client.ErrorInfo[] = [];
    let items = section.interior.items;

    for (let item of items) {
      let error = this.getOverlapError(item, section);
      if (!error) {
        error = this.getOverlapInteriorBoundsError(item, section);
      }
      if (error) this.addError(error, errors, [item]);
    }

    if (section.isSwingFlex) {
      for (let item of section.swingFlex.items) {
        if (item.isGrip && ProductHelper.edgeMounted(item.Product!)) {
          continue;
        }

        let error = this.getOverlapError(
          item,
          section,
          Enums.EditorSection.SwingFlex,
        );
        if (error) {
          this.addError(error, errors, [item]);
        }
      }
    }

    return errors;
  }
  private static getOverlapError(
    item: Client.ConfigurationItem,
    section: Client.CabinetSection,
    editorSection: Enums.EditorSection = Enums.EditorSection.Interior,
  ): Client.ErrorInfo | null {
    let collisions = item.collisions.filter((ca) => !!ca.offender); //only get collisions with other items, not with bounding box

    let worstCollision = ObjectHelper.best(
      collisions,
      (collision) => -collision.severity,
    );
    if (!worstCollision) return null;

    switch (worstCollision.severity) {
      case Enums.CollisionSeverity.Overlap:
        return new Client.ErrorInfo(
          editorSection,
          Interface_Enums.ErrorLevel.Critical,
          'Items overlapping',
          'Items are overlapping',
          section,
          item,
        );
      case Enums.CollisionSeverity.Required:
        return new Client.ErrorInfo(
          editorSection,
          Interface_Enums.ErrorLevel.Critical,
          'Required space',
          'Space around item does not meet requirements',
          section,
          item,
        );
      case Enums.CollisionSeverity.Recommended:
        return new Client.ErrorInfo(
          editorSection,
          Interface_Enums.ErrorLevel.Warning,
          'Recommended space',
          'Space around item does not meet recommendations',
          section,
          item,
        );
      case Enums.CollisionSeverity.None:
        return null;
      default:
        throw new Error('Unknown collision severity');
    }
  }
  private static getOverlapInteriorBoundsError(
    item: Client.ConfigurationItem,
    section: Client.CabinetSection,
  ): Client.ErrorInfo | null {
    let severties = item.collisions
      .filter((ca) => !ca.offender) //only get collisions with bounding box
      .map((ca) => ca.severity);
    let maxSeverity = Math.max(Enums.CollisionSeverity.None, ...severties);

    switch (maxSeverity) {
      case Enums.CollisionSeverity.Overlap:
        return new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item exceeds bounds of interior',
          'Item stretches outside the bounds of the cabinet',
          section,
          item,
        );
      case Enums.CollisionSeverity.Required:
        return new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Required space outside cabinet',
          'Space around item does not meet requirements',
          section,
          item,
        );
      case Enums.CollisionSeverity.Recommended:
        return new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Warning,
          'Recommended space outside cabinet',
          'Space around item does not meet recommendations',
          section,
          item,
        );
      case Enums.CollisionSeverity.None:
        return null;
      default:
        throw new Error('Unknown collision severity ' + maxSeverity);
    }
  }

  /**
   * Validates if items collide with one another
   */
  private static validateCollisionBetweenItems(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    for (var i = 0; i < section.interior.items.length; i++) {
      let item1 = section.interior.items[i];

      if (!item1.Product) continue;

      // Ignore dummies and parents
      if (item1.isDummy || item1.Product.IsTemplate) continue;

      for (var j = i + 1; j < section.interior.items.length; j++) {
        let item2 = section.interior.items[j];

        if (!item2.Product) continue;
        // Ignore dummies and parents
        if (item2.isDummy || item2.Product.IsTemplate) continue;

        // Ignore collision between coathanges and items that must hang on coathangers
        if (
          (item1.isCoatHanger && item2.snapCoatHanger) ||
          (item2.isCoatHanger && item1.snapCoatHanger)
        )
          continue;

        // Do the actual validation

        let error12 = InteriorValidationService.validateSpace(
          section,
          item1,
          item2,
          false,
        );
        if (error12) this.addError(error12, result, [item1, item2]);

        let error21 = InteriorValidationService.validateSpace(
          section,
          item1,
          item2,
          false,
        );
        if (error21) this.addError(error21, result, [item1, item2]);
      }
    }
    return result;
  }

  private static validateSpace(
    section: Client.CabinetSection,
    item1: Client.ConfigurationItem,
    item2: Client.ConfigurationItem,
    onlyVertical: boolean,
  ): Client.ErrorInfo | null {
    let err = InteriorValidationService.validateRequiredSpace(
      section,
      item1,
      item2,
      onlyVertical,
    );
    if (!err)
      err = InteriorValidationService.validateRecommendedSpace(
        section,
        item1,
        item2,
      );
    return err;
  }
  /**
   * Validates if any item is within the required space of another item
   */
  private static validateRequiredSpace(
    section: Client.CabinetSection,
    item1: Client.ConfigurationItem,
    item2: Client.ConfigurationItem,
    onlyVertical: boolean,
  ): Client.ErrorInfo | null {
    if (item1.isDummy || item2.isDummy) {
      return null;
    }

    if (
      (!onlyVertical &&
        (item1.requiredLeft > item2.rightX ||
          item1.requiredRight < item2.leftX)) ||
      item1.requiredTop < item2.bottomY ||
      item1.requiredBottom > item2.topY
    ) {
      return null;
    }

    // If we get here, there is a collision
    return new Client.ErrorInfo(
      Enums.EditorSection.Interior,
      Interface_Enums.ErrorLevel.Critical,
      'Required space',
      'Space around item does not meet requirements',
      section,
      item1,
    );
  }

  /**
   * Validates if any item is within the recommended space of another item
   */
  private static validateRecommendedSpace(
    section: Client.CabinetSection,
    item1: Client.ConfigurationItem,
    item2: Client.ConfigurationItem,
    onlyVertical: boolean = false,
  ): Client.ErrorInfo | null {
    if (item1.isDummy || item2.isDummy) {
      return null;
    }

    if (
      (!onlyVertical &&
        (item1.recommendedLeft > item2.rightX ||
          item1.recommendedRight < item2.leftX)) ||
      item1.recommendedTop < item2.bottomY ||
      item1.recommendedBottom > item2.topY
    ) {
      return null;
    }

    // If we get here, there is a collision
    return new Client.ErrorInfo(
      Enums.EditorSection.Interior,
      Interface_Enums.ErrorLevel.Warning,
      'Recommended space',
      'Space around item does not meet recommendations',
      section,
      item1,
    );
  }

  /**
   * Validates if items in a corner collide with item in the same corner, but in the adjacent section.
   */
  private static validateCollisionBetweenItemsInCorners(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // Local function for collision validation
    let validateCollision = (
      currentItem: Client.ConfigurationItem,
      otherItem: Client.ConfigurationItem,
    ): void => {
      // Items are not allowed in the corner, if the adjacent section has a gable in the corner
      if (otherItem.isGable) {
        let cornerError = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Collision in corner',
          'Item collides with gable in adjacent section',
          section,
          currentItem,
        );
        this.addError(cornerError, result, [currentItem, otherItem]);
      }

      // Regular validation of required and recommended space
      let spaceError = InteriorValidationService.validateSpace(
        section,
        currentItem,
        otherItem,
        true,
      );
      if (spaceError) {
        this.addError(spaceError, result, [currentItem, otherItem]);
      }
    };

    for (let item of section.interior.items) {
      if (item.isDummy) continue;

      if (CabinetSectionHelper.isItemInCornerAreaLeft(item)) {
        for (let otherItem of CabinetSectionHelper.getCornerItemsRightFromSectionLeft(
          section,
        )) {
          validateCollision(item, otherItem);
        }
      }

      if (CabinetSectionHelper.isItemInCornerAreaRight(item)) {
        for (let otherItem of CabinetSectionHelper.getCornerItemsLeftFromSectionRight(
          section,
        )) {
          validateCollision(item, otherItem);
        }
      }
    }
    return result;
  }

  /**
   * Determines if an item is inside a range(min & max) in a given dimension
   */
  private static isPositionInside(
    dimension: Enums.Dimension,
    required: boolean,
    item: Client.ConfigurationItem,
    min: number,
    max: number,
  ): boolean {
    let itemMin: number;
    let itemMax: number;

    switch (dimension) {
      case Enums.Dimension.X:
        itemMin = required ? item.requiredLeft : item.recommendedLeft;
        itemMax = required ? item.requiredRight : item.recommendedRight;
        break;
      case Enums.Dimension.Y:
        itemMin = required ? item.requiredBottom : item.recommendedBottom;
        itemMax = required ? item.requiredTop : item.recommendedTop;
        break;
      case Enums.Dimension.Z:
      default:
        return false;
    }

    return itemMin > min && itemMax < max;
  }

  /**
   * Validates if any item is partially or completely outside the cabinet
   */
  private static validateCollisionBetweenItemsAndCabinet(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let item of section.interior.items.filter((i) => !i.isDummy)) {
      if (!VectorHelper.contains(item, section.interior.cube)) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item required space exceed closet',
          'Item required space exceeds the boundries of the closet',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }
    }

    return result;
  }

  /**
   * Validates if any item is too close to the doors
   */
  private static validateCollisionBetweenItemsAndDoors(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    if (section.isSwingFlex) return result;
    let railSetDepth = CabinetSectionHelper.getMaximumRailSetDepth(section);
    let maxFrontZ = section.Depth - railSetDepth;

    for (let item of section.interior.items.filter((i) => !i.isDummy)) {
      if (item.frontZ > maxFrontZ) {
        if (railSetDepth > 0) {
          let doorCollisionError = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Collision with doors',
            'Interior collides with doors',
            section,
            item,
          );
          this.addError(doorCollisionError, result, [item]);
        } else {
          let requiredSpaceError = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item required space exceed closet',
            'Item required space exceeds the boundries of the closet',
            section,
            item,
          );
          this.addError(requiredSpaceError, result, [item]);
        }
      }
    }
    return result;
  }

  /**
   * Validates the dimensions of every item
   */
  private static validateItemDimensions(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // Validate all three dimensions, if applicaple

    for (let item of section.interior.items) {
      // #region Height

      // #endregion Height

      // #region Width

      // Very wide items should be supported (> 1250mm)
      if (item.snapLeftAndRight) {
        if (
          item.Width > Constants.itemWarningWidth ||
          item.width2 > Constants.itemWarningWidth
        ) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Warning,
            'Item is very wide',
            'Item support is recommended',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }

      // Corner shelves: Widths must be at least xx mm more than the opposite depth
      if (item.isCorner2WayFlex || item.isCornerDiagonalCut) {
        if (
          item.Width < item.depth2 + Constants.minimumCornerShelfExtraWidth ||
          item.width2 < item.Depth + Constants.minimumCornerShelfExtraWidth
        ) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item width invalid',
            'Item width is not valid',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }

      // #endregion Width

      // #region Depth

      // #endregion Depth
    }
    return result;
  }

  /**
   * Validates if all items, that need to snap on both sides, are positioned correctly against other items
   */
  private static validateItemSnapSides(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let item of section.interior.items) {
      if (
        !item.Product ||
        !item.snapLeftAndRight ||
        item.isDummy ||
        item.Product.IsTemplate
      )
        continue;

      let itemLeftX = item.leftX;
      let itemRightX = itemLeftX + (item.hasWidth2 ? item.width2 : item.Width);

      if (item.snapLeftAndRight) {
        let snappedLeft = false;
        let snappedRight = false;
        let otherItems = section.interior.items;
        if (section.isSwing) {
          otherItems = otherItems.concat(
            section.swing.items.filter(
              (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
            ),
          );
        } else if (section.isSwingFlex) {
          otherItems = otherItems.concat(section.swingFlex.items);
        }
        let gables = otherItems.filter((ii) => ii.isGable);

        // Check if the item is snapped to a gable with the same drilling
        for (let gable of gables) {
          if (gable.topY < item.topY) {
            continue;
          }

          if (
            (item.drilling600Left
              ? gable.drilling600Right
              : gable.drilledRight) &&
            Math.abs(itemLeftX - gable.rightX) <
              Constants.sizeAndPositionTolerance
          )
            snappedLeft = true;
          else if (
            (item.drilling600Right
              ? gable.drilling600Left
              : gable.drilledLeft) &&
            Math.abs(gable.leftX - itemRightX) <
              Constants.sizeAndPositionTolerance
          )
            snappedRight = true;

          if (snappedLeft && snappedRight) break;
        }

        if (!snappedLeft || !snappedRight) {
          if (item.isCorner2WayFlex || item.isCornerDiagonalCut) {
            //corner shelves only

            //check left side against edge of section
            if (
              Math.abs(itemLeftX - section.interior.cube.X) <
              Constants.sizeAndPositionTolerance
            )
              snappedLeft = true;

            //no need to check if shelf touches gable on the left, already checked for.
            //just find the right gable

            if (section.rightNeighbor) {
              let interiorStartX = section.rightNeighbor.interior.cube.X;
              let distanceToGable = Enumerable.from(
                section.rightNeighbor.interior.gables,
              )
                .where((gable) => gable.topY >= item.topY)
                .select((gable) => gable.X - interiorStartX)
                .concat([section.rightNeighbor.interior.cube.Width])
                .orderBy((gableX) => gableX)
                .first();
              snappedRight =
                Math.abs(distanceToGable - item.width2) <
                Constants.sizeAndPositionTolerance;
            }
          } else {
            // Snapped against corpus or wall

            let interiorLeft = section.interior.cube.X;
            let interiorRight = section.interior.cubeRightX;

            if (Math.abs(itemLeftX - interiorLeft) < 1) snappedLeft = true;
            if (Math.abs(interiorRight - itemRightX) < 1) snappedRight = true;
          }
        }

        if ((!snappedLeft || !snappedRight) && item.snapAboveGables) {
          // Items that may be snapped on top of gables
          gables = section.interior.items
            .filter((ii) => ii.isGable && Math.abs(ii.topY - item.bottomY) < 1)
            .sort((n1, n2) => n1.centerX - n2.centerX);
          for (let gable of gables) {
            if (Math.abs(itemLeftX - gable.leftX) < 1) {
              snappedLeft = true;
            } else if (Math.abs(gable.rightX - itemRightX) < 1) {
              snappedRight = true;
            }

            if (snappedLeft && snappedRight) {
              break;
            }
          }
        }

        // If the item is not snapped correctly, create the error
        if (!snappedLeft || !snappedRight) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item not snapped correctly left/right',
            'Item not snapped correctly left and/or right',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }
    }

    return result;
  }

  /**
   * Validates if all items, that need to snap on one side, are positioned correctly against other items
   * @param section
   */
  private static validateItemSnapLeftOrRight(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let item of section.interior.items) {
      if (
        !item.Product ||
        item.isDummy ||
        item.Product.IsTemplate ||
        !(item.snapLeft || item.snapRight)
      )
        continue;

      let itemIsSnapped = false;
      let snappedGable: Client.ConfigurationItem | undefined = undefined;

      let otherItems = section.interior.items;
      if (section.isSwing) {
        otherItems = otherItems.concat(
          section.swing.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
          ),
        );
      }
      let gables = otherItems.filter((ii) => ii.isGable);

      // Check if the item is snapped to a gable with the same drilling
      for (let gable of gables) {
        if (
          (item.snapLeft && item.drilling600Left
            ? gable.drilling600Right
            : gable.drilledRight) &&
          Math.abs(item.leftX - gable.rightX) < 1
        ) {
          itemIsSnapped = true;
          snappedGable = gable;
        } else if (
          (item.snapRight && item.drilling600Right
            ? gable.drilling600Left
            : gable.drilledLeft) &&
          Math.abs(gable.leftX - item.rightX) < 1
        ) {
          itemIsSnapped = true;
          snappedGable = gable;
        }

        if (itemIsSnapped) break;
      }

      if (!itemIsSnapped) {
        // Snapped against corpus or wall

        let interiorLeft = section.interior.cube.X;
        let interiorRight = section.interior.cubeRightX;

        if (item.snapLeft && Math.abs(item.leftX - interiorLeft) < 1)
          itemIsSnapped = true;
        if (item.snapRight && Math.abs(interiorRight - item.rightX) < 1)
          itemIsSnapped = true;
      }

      if (section.isSwingFlex) {
        let gables = section.swingFlex.items.filter((ii) => ii.isGable);
        gables = gables.concat(
          section.interior.items.filter((ii) => ii.isGable),
        );

        itemIsSnapped = Enumerable.from(gables).any(
          (g) =>
            (item.snapLeft && Math.abs(item.leftX - g.rightX) < 1) ||
            (item.snapRight && Math.abs(g.leftX - item.rightX) < 1),
        );
      }

      if (!itemIsSnapped) {
        // The item is not snapped correctly, so we create an error
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item not snapped correctly left or right',
          'Item not snapped correctly left or right',
          section,
          item,
        );
        this.addError(error, result, [item]);
      } else {
        let maxTopY = !!snappedGable
          ? snappedGable.topY
          : section.interior.cube.Y + section.interior.cube.Height;

        if (item.topY > maxTopY) {
          // The item is positioned too high, so we create an error
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item positioned too high',
            'Item positioned higher than the item it is positioned against',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }
    }

    return result;
  }

  /**
   * Validates if items are positioned against a wall or "free space"
   */
  private static validateSnappedAgainstWallOrFreeSpace(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // Handle corner items (width2)

    for (let item of section.interior.items.filter((i) => !i.isDummy)) {
      if (!item.snapToWallMustWarn) {
        continue;
      }

      let itemLeftX = item.leftX;
      let itemRightX = itemLeftX + (item.hasWidth2 ? item.width2 : item.Width);

      if (itemLeftX < 1 || itemRightX > section.Width - 1) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Warning,
          'Item positioned against wall',
          'Item positioned directly against a wall.',
          section,
          item,
        );
        this.addError(error, result, [item]);
      } else if (
        itemLeftX < section.InteriorFreeSpaceLeft + 1 ||
        itemRightX > section.Width - section.InteriorFreeSpaceRight - 1
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item positioned against free space',
          'Item positioned directly against free space.',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }
    }

    return result;
  }

  /**
   * Validates if items are positioned correctly against corpus
   */
  private static validateSnappedCorpus(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let corpusPanelLeft = section.corpus.panelLeft;
    let corpusPanelRight = section.corpus.panelRight;
    let gables = section.interior.items.filter((ii) => ii.isGable);

    for (let item of section.interior.items.filter((i) => !i.isDummy)) {
      // Left
      if (
        corpusPanelLeft != null &&
        !corpusPanelLeft.isNone &&
        section.corpus.itemsLeft.length > 0 &&
        item.frontZ >
          CabinetSectionHelper.getSpaceBehindCorpusPanel(
            section,
            Interface_Enums.Direction.Left,
          )
      ) {
        if (
          !corpusPanelLeft.isFullDepth(section) &&
          Math.abs(item.leftX - section.corpus.itemsLeft[0].rightX) < 1 &&
          !gables.some((g) => g.rightX <= item.leftX)
        ) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item snapped to wrong corpus item',
            'Item positioned directly against corpus item, that is not full depth.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }

        if (item.leftX < section.corpus.itemsLeft[0].rightX) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item collides with corpus item',
            'Item does not fit behind corpus item. Depth too large.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }

      // Right
      if (
        corpusPanelRight != null &&
        !corpusPanelRight.isNone &&
        section.corpus.itemsRight.length > 0 &&
        item.frontZ >
          CabinetSectionHelper.getSpaceBehindCorpusPanel(
            section,
            Interface_Enums.Direction.Right,
          )
      ) {
        if (
          !corpusPanelRight.isFullDepth(section) &&
          Math.abs(item.rightX - section.corpus.itemsRight[0].leftX) < 1 &&
          !gables.some((g) => g.leftX >= item.rightX)
        ) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item snapped to wrong corpus item',
            'Item positioned directly against corpus item, that is not full depth.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }

        if (item.rightX > section.corpus.itemsRight[0].leftX) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item collides with corpus item',
            'Item does not fit behind corpus item. Depth too large.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }

      // Top
      if (
        section.corpus.depthTop > 0 &&
        item.topY > section.Height - section.corpus.heightTop &&
        item.frontZ >
          CabinetSectionHelper.getSpaceBehindCorpusPanel(
            section,
            Interface_Enums.Direction.Top,
          )
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item collides with corpus item',
          'Item does not fit behind corpus item. Depth too large.',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }

      // Bottom
      if (
        section.corpus.depthBottom > 0 &&
        item.bottomY < section.corpus.heightBottom &&
        item.frontZ >
          CabinetSectionHelper.getSpaceBehindCorpusPanel(
            section,
            Interface_Enums.Direction.Bottom,
          )
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item collides with corpus item',
          'Item does not fit behind corpus item. Depth too large.',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }
    }

    return result;
  }

  /**
   * Validates items in relation to the gables, they are snapped to
   */
  private static validateRelationsToGables(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let item of section.interior.items) {
      if (
        item.isDummy ||
        item.isGable ||
        item.isTemplate ||
        item.isFittingPanel
      )
        continue;

      let gableLeft: Client.ConfigurationItem | null = null;
      let gableRight: Client.ConfigurationItem | null = null;
      let isCornerItem: boolean = false;

      // Calculate relevant gables and wether or not the item is in a corner
      let gables = Enumerable.from(
        section.interior.items.filter(
          (ii) => ii.isGable && item.bottomY < ii.topY && !ii.isFittingPanel,
        ),
      ).orderBy((ii) => ii.centerX);
      for (let gable of gables.toArray()) {
        if (Math.abs(gable.rightX - item.leftX) < 1) gableLeft = gable;
        else if (Math.abs(gable.leftX - item.rightX) < 1) gableRight = gable;

        if (gableLeft != null && gableRight != null) break;
      }
      if (
        gableLeft == null &&
        item.hasWidth2 &&
        CabinetSectionHelper.isItemInCornerAreaLeft(item)
      ) {
        var sectionLeft = CabinetSectionHelper.getSectionLeft(section);
        if (sectionLeft != null) {
          isCornerItem = true;
          gableLeft =
            Enumerable.from(
              sectionLeft.interior.items.filter(
                (ii) =>
                  ii.isGable && item.bottomY < ii.topY && !ii.isFittingPanel,
              ),
            )
              .orderBy((ii) => ii.centerX)
              .lastOrDefault() ?? null;
        }
      } else if (
        gableRight == null &&
        item.hasWidth2 &&
        CabinetSectionHelper.isItemInCornerAreaRight(item)
      ) {
        var sectionRight = CabinetSectionHelper.getSectionRight(section);
        if (sectionRight != null) {
          isCornerItem = true;
          gableRight =
            Enumerable.from(
              sectionRight.interior.items.filter(
                (ii) =>
                  ii.isGable && item.bottomY < ii.topY && !ii.isFittingPanel,
              ),
            )
              .orderBy((ii) => ii.centerX)
              .firstOrDefault() ?? null;
        }
      }

      // Do the validations
      if (gableLeft != null) {
        this.validateParentProductCategory(section, item, gableLeft).forEach(
          (error) => this.addError(error, result, [item]),
        );
        this.validatePositionZ(
          section,
          item,
          gableLeft,
          false,
          isCornerItem,
        ).forEach((error) => this.addError(error, result, [item]));

        let depthError = InteriorValidationService.validateDepth(
          section,
          item,
          gableLeft,
          false,
        );
        if (depthError) this.addError(depthError, result, [item]);
      }
      if (gableRight != null) {
        this.validateParentProductCategory(section, item, gableRight).forEach(
          (error) => this.addError(error, result, [item]),
        );
        this.validatePositionZ(
          section,
          item,
          gableRight,
          true,
          isCornerItem,
        ).forEach((error) => this.addError(error, result, [item]));

        let depthError = InteriorValidationService.validateDepth(
          section,
          item,
          gableRight,
          isCornerItem,
        );
        if (depthError) this.addError(depthError, result, [item]);
      }
    }

    return result;
  }

  /**
   * Checks if the parent product category of an item matches that of a gable
   */
  private static validateParentProductCategory(
    section: Client.CabinetSection,
    item: Client.ConfigurationItem,
    gable: Client.ConfigurationItem,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (
      item == null ||
      gable == null ||
      !item.Product ||
      !gable.Product ||
      item.isDummy
    )
      return result;

    if (
      !item.ignoreSnapPointValidation &&
      !item.isCoatHanger &&
      item.Product.ParentCategoryId != gable.Product.ParentCategoryId
    ) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Parent product category mismatch',
          'Item parent product category does not match gable parent product category',
          section,
          item,
        ),
      );
    }
    return result;
  }

  /**
   * Validates the depth of an item in relation to a gable
   */
  private static validateDepth(
    section: Client.CabinetSection,
    item: Client.ConfigurationItem,
    gable: Client.ConfigurationItem,
    checkDepth2: boolean,
  ): Client.ErrorInfo | null {
    if (item == null || gable == null || item.isDummy) return null;

    let gableSnapDepth =
      gable.X < item.X ? gable.snapDepthRight : gable.snapDepthLeft;

    if (
      Math.abs(
        gableSnapDepth -
          (checkDepth2 ? item.depth2 : item.Depth) +
          item.depthReduction,
      ) < 1
    ) {
      return new Client.ErrorInfo(
        Enums.EditorSection.Interior,
        Interface_Enums.ErrorLevel.Critical,
        'Item depth not correct',
        'Item depth does not match gable depth',
        section,
        item,
      );
    } else {
      return null;
    }
  }

  /**
   * Validates the Z-position of an item, in relation to a gable
   */
  private static validatePositionZ(
    section: Client.CabinetSection,
    item: Client.ConfigurationItem,
    gable: Client.ConfigurationItem,
    itemIsLeftOfGable: boolean,
    isCornerItem: boolean,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    if (item == null || gable == null || item.snapDepthMiddle || item.isDummy)
      return result;

    let frontZ: number = 0;
    if (isCornerItem) {
      if (CabinetSectionHelper.isItemInCornerAreaLeft(item)) {
        if (itemIsLeftOfGable)
          frontZ =
            CabinetSectionHelper.backingOffsetZforInterior(section) +
            item.depth2;
        else
          frontZ =
            CabinetSectionHelper.backingOffsetZforInterior(
              CabinetSectionHelper.getSectionLeft(section),
            ) + item.Depth;
      } else {
        if (itemIsLeftOfGable)
          frontZ =
            CabinetSectionHelper.backingOffsetZforInterior(
              CabinetSectionHelper.getSectionRight(section),
            ) + item.depth2;
        else
          frontZ =
            CabinetSectionHelper.backingOffsetZforInterior(section) +
            item.Depth;
      }
    } else {
      frontZ = item.frontZ;
    }

    let targetSnapZ =
      gable.frontZ -
      (itemIsLeftOfGable
        ? gable.snapDepthDifferenceLeft
        : gable.snapDepthDifferenceRight);

    let actualSnapZ = frontZ + item.depthReduction;
    let snapZDiff = Math.abs(targetSnapZ - actualSnapZ);
    if (snapZDiff > Constants.sizeAndPositionTolerance) {
      result.push(
        new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item not snapped correctly Z',
          'Item not snapped correctly in Z direction',
          section,
          item,
        ),
      );
    }
    return result;
  }

  /**
   * Validates if any pull-out item is in a position with pull-out restriction
   */
  private static validatePullOut(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let cornerDeduction =
      section.NumberOfDoors > 0
        ? CabinetSectionHelper.getCornerOffsetForDoors(section)
        : CabinetSectionHelper.getCornerAffectingDepthForInterior(section);

    let minX = CabinetSectionHelper.getCorpusWidthLeft(section);
    var sectionLeft = CabinetSectionHelper.getSectionLeft(section);
    if (sectionLeft != null) {
      minX += cornerDeduction;
    }

    let maxX =
      section.Width - CabinetSectionHelper.getCorpusWidthRight(section);
    var sectionRight = CabinetSectionHelper.getSectionRight(section);
    if (sectionRight != null) {
      maxX -= cornerDeduction;
    }

    let minY = CabinetSectionHelper.getCorpusHeightBottom(section);
    let maxY =
      section.Height - CabinetSectionHelper.getCorpusHeightTop(section);
    let bottomRailHeight =
      section.doors.railSet != null
        ? RailSetHelper.heightBottom(section.doors.railSet)
        : 0;
    if (bottomRailHeight > 0) bottomRailHeight += 3; //there must be at least 3 mm clearance
    let minRailY = minY + bottomRailHeight;
    let maxRailY =
      maxY -
      (section.doors.railSet != null
        ? RailSetHelper.heightTop(section.doors.railSet)
        : 0);

    let pulloutSafeAreas = CabinetSectionHelper.getPulloutSafeAreas(section);
    let pulloutWarningAreas =
      CabinetSectionHelper.getPulloutWarningAreas(section);
    let warningAreas = pulloutWarningAreas.filter(
      (a) => a.severity === Enums.PulloutWarningSeverity.Warning,
    );
    let criticalAreas = pulloutWarningAreas.filter(
      (a) => a.severity === Enums.PulloutWarningSeverity.Critical,
    );
    for (let item of section.interior.items) {
      if (item.isDummy || !item.isPullout) continue;

      let leftX = item.leftX;
      let rightX = item.rightX;
      if (item.ProductData && item.ProductData.RequiredSpaceSide > 0) {
        if (
          item.ProductData.SnapPlacement &
            Interface_Enums.SnapPlacementType.Left &&
          !item.isMirrored
        ) {
          rightX += item.ProductData.RequiredSpaceSide;
        } else if (
          item.ProductData.SnapPlacement &
            Interface_Enums.SnapPlacementType.Right &&
          item.isMirrored
        ) {
          leftX -= item.ProductData.RequiredSpaceSide;
        } else {
          leftX -= item.ProductData.RequiredSpaceSide;
          rightX += item.ProductData.RequiredSpaceSide;
        }
      }

      // Corpus
      if (
        leftX < minX ||
        rightX > maxX ||
        item.bottomY < minY ||
        item.topY > maxY
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Pull out warning',
          'Pull out item placed behind carcass.',
          section,
          item,
        );
        this.addError(error, result, [item]);
        continue;
      }

      // Rails
      if (item.bottomY < minRailY || item.topY > maxRailY) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Pull out warning',
          'Pull out item placed behind door rail.',
          section,
          item,
        );
        this.addError(error, result, [item]);
        continue;
      }

      // Warning areas
      let overlaps = 0;
      let wasWarned = false;
      for (let warningArea of warningAreas) {
        if (warningArea.overlaps(leftX, rightX)) {
          overlaps++;
        }

        // If the item overlaps as many areas as half the amount of doors, we need a warning
        if (overlaps >= section.NumberOfDoors / 2) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Pull out warning',
            'Pull out item placed in pull out warning area',
            section,
            item,
          );
          this.addError(error, result, [item]);

          wasWarned = true;
          continue;
        }
      }

      // If there is an error already, we do not need any more validation for this item
      if (wasWarned) {
        continue;
      }

      if (section.doors.railSet != null) {
        let numberOfTracks = section.doors.railSet.NumberOfTracks;

        // Check safe areas
        let blocked = false;
        for (var i = 1; i <= numberOfTracks; i++) {
          let safeAreas = pulloutSafeAreas[i];

          if (
            safeAreas &&
            !safeAreas.some((area) => area.surrounds(leftX, rightX))
          ) {
            blocked = true;
            continue;
          }
        }
        if (blocked) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Pull out warning',
            'Pull out item will always be blocked by a door',
            section,
            item,
          );
          this.addError(error, result, [item]);
          continue;
        }
      }
      // Check critical areas
      if (criticalAreas.some((a) => a.overlaps(leftX, rightX))) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Pull out warning',
          'Pull out item placed in pull out warning area',
          section,
          item,
        );
        this.addError(error, result, [item]);
        continue;
      }

      // Check warning areas
      if (warningAreas.some((a) => a.overlaps(leftX, rightX))) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Warning,
          'Pull out warning',
          'Pull out item requires moving more than one door',
          section,
          item,
        );
        this.addError(error, result, [item]);
        continue;
      }
    }
    return result;
  }

  /**
   * Validates if items, that must be positioned under another item, are positioned correctly
   */
  private static validateSnapUnderShelf(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let otherItems = section.interior.items;
    if (section.isSwing) {
      otherItems = otherItems.concat(
        section.swing.items.filter(
          (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
        ),
      );
    }
    if (section.isSwingFlex) {
      let shelfs = Enumerable.from(section.swingFlex.areas)
        .selectMany((area) => area.areaItems)
        .where((item) => item.isShelf);

      otherItems = otherItems.concat(shelfs.toArray());
    }
    let possibleSnapTargets = otherItems.filter((ii) => ii.isSnapUnderTarget);
    let neighborSnapTargets: {
      x: number;
      y: number;
      depth: number;
      rightX: number;
    }[] = [];

    if (section.leftNeighbor) {
      neighborSnapTargets = section.leftNeighbor.interior.items
        .filter((neighborItem) => neighborItem.isSnapUnderTarget)
        .map((neighborItem) => ({
          x: neighborItem.Z,
          y: neighborItem.Y,
          depth: neighborItem.depth2,
          rightX: neighborItem.Z + neighborItem.width2,
        }));
    }

    for (let item of section.interior.items) {
      if (
        item.isDummy ||
        !item.snapUnder ||
        item.isFittingPanel ||
        item.isVerticalDivider ||
        item.isGrip
      )
        continue;

      // Width and depth must match, and the top of the item must be within 1mm of the bottom of the target
      if (
        !possibleSnapTargets.some(
          (pst) =>
            pst.Depth >= item.Depth &&
            Math.abs(item.topY - pst.bottomY) < 1 &&
            item.X >= pst.X &&
            item.rightX <= pst.rightX,
        )
      ) {
        // check for corner shelves

        if (
          !neighborSnapTargets.some(
            (nst) =>
              nst.depth >= item.Depth &&
              Math.abs(item.topY - nst.y) < 1 &&
              item.X >= nst.x &&
              item.rightX <= nst.rightX,
          )
        ) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item not snapped correctly under',
            'Item not snapped correctly under another item',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }
      }
    }
    return result;
  }

  /**
   * Validates if items, that must be positioned under a coat hanger, are positioned correctly
   */
  private static validateSnapUnderCoathanger(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let coatHangers = section.interior.items.filter((ii) => ii.isCoatHanger);

    for (let item of section.interior.items) {
      if (item.isDummy || !item.snapCoatHanger) continue;

      // The top of the item must be within 1mm of the top of the coat hanger
      if (
        !coatHangers.some(
          (ch) => Math.abs(item.topY - item.snapOffsetY - ch.topY) < 1,
        )
      ) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item not snapped correctly coat hanger',
          'Item not snapped correctly to a coat hanger',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }
    }
    return result;
  }

  /**
   * Validates if all items in corners are allowed in the corner. (including different corner item types)
   * Validates if items, that are only allowed in a corner, are positioned outside a corner.
   */
  private static validateAllowedInCorner(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    /*
    Not all items are allowed in a corner
    Some items are only allowed in a corner
    If there are any diagonal cut items in the corner, only diagonal cut items are allowed in the corner
    */

    let onlyDiagonalCutInCornerLeft =
      CabinetSectionHelper.onlyDiagonalCutAllowedInCornerLeft(section);
    let onlyDiagonalCutInCornerRight =
      CabinetSectionHelper.onlyDiagonalCutAllowedInCornerRight(section);

    for (let item of section.interior.items) {
      if (item.isDummy) continue;

      // Check if item is in a corner
      let itemIsInCornerLeft =
        CabinetSectionHelper.isItemInCornerAreaLeft(item);
      let itemIsInCornerRight =
        CabinetSectionHelper.isItemInCornerAreaRight(item);
      if (itemIsInCornerLeft || itemIsInCornerRight) {
        // Is the item valid in a corner
        if (!item.isAllowedInCorner) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Item in corner',
            'Item not allowed in corner',
            section,
            item,
          );
          this.addError(error, result, [item]);
        }

        // Check if item not allowed in a corner with diagonal cut is in such a corner
        if (
          (itemIsInCornerLeft && onlyDiagonalCutInCornerLeft) ||
          (itemIsInCornerRight && onlyDiagonalCutInCornerRight)
        ) {
          if (!item.isAllowedInCornerWithDiagonalCut) {
            let error = new Client.ErrorInfo(
              Enums.EditorSection.Interior,
              Interface_Enums.ErrorLevel.Critical,
              'Only diagonal cut allowed',
              'Corner contains corner cut items. Only other diagonal cut items allowed',
              section,
              item,
            );
            this.addError(error, result, [item]);
          }
        }
      }
      // Check if a corner only item is not in a corner
      else if (item.isCorner2WayFlex || item.isCornerDiagonalCut) {
        let error = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Item not in corner',
          'Item is only valid in a corner',
          section,
          item,
        );
        this.addError(error, result, [item]);
      }
    }
    return result;
  }

  /**
   * Validates if any items are in a corner with free space
   */
  public static validateCornerFreeSpace(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let item of section.interior.items) {
      if (item.isDummy) continue;

      if (
        CabinetSectionHelper.isItemInCornerAreaLeft(item) &&
        CabinetSectionHelper.hasCornerLeftWithFreeSpace(section)
      ) {
        // If the corner has free space, no diagonal cut or 2way flex are allowed
        if (item.isCorner2WayFlex || item.isCornerDiagonalCut) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Free space in corner',
            'Item is in a corner with free space.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        } else {
          // If the corner has free space in the other section, no items belonging to this section are allowed in the corner
          let sectionLeft = CabinetSectionHelper.getSectionLeft(section);
          if (sectionLeft != null && sectionLeft.InteriorFreeSpaceRight > 0) {
            let error = new Client.ErrorInfo(
              Enums.EditorSection.Interior,
              Interface_Enums.ErrorLevel.Critical,
              'Free space in corner',
              'Item in corner is colliding with free space in opposite section.',
              section,
              item,
            );
            this.addError(error, result, [item]);
          }
        }
      }

      if (
        CabinetSectionHelper.isItemInCornerAreaRight(item) &&
        CabinetSectionHelper.hasCornerRightWithFreeSpace(section)
      ) {
        // If the corner has free space, no diagonal cut or 2way flex are allowed
        if (item.isCorner2WayFlex || item.isCornerDiagonalCut) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Free space in corner',
            'Item is in a corner with free space.',
            section,
            item,
          );
          this.addError(error, result, [item]);
        } else {
          // If the corner has free space in the other section, no items belonging to this section are allowed in the corner
          let sectionRight = CabinetSectionHelper.getSectionRight(section);
          if (sectionRight != null && sectionRight.InteriorFreeSpaceLeft > 0) {
            let error = new Client.ErrorInfo(
              Enums.EditorSection.Interior,
              Interface_Enums.ErrorLevel.Critical,
              'Free space in corner',
              'Item in corner is colliding with free space in opposite section.',
              section,
              item,
            );
            this.addError(error, result, [item]);
          }
        }
      }
    }
    return result;
  }

  private static validateCornerShelvesAreInCorner(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    for (let cornerShelf of section.interior.items) {
      if (!cornerShelf.isCorner2WayFlex && !cornerShelf.isCornerDiagonalCut)
        continue;

      for (let gable of section.interior.gables.filter(
        (g) => g.topY > cornerShelf.Y,
      )) {
        if (gable.centerX > cornerShelf.centerX) {
          let error = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Corner shelf not in corner',
            'Corner shelf must be in corner, no gable allowed',
            section,
            cornerShelf,
          );
          this.addError(error, result, [cornerShelf]);
        }
      }
    }
    return result;
  }

  private static validateShelfDepthMatchesDrilling(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    //validation should only run when there are no internal gables
    for (let item of section.interior.items) {
      if (item.isGable) return result;
    }

    let drillingDepth = section.InteriorDepth;

    // If Swing Flex is enabled, the depth of the cabinet is reduced by the Swing Flex space for backing
    if (section.isSwingFlex) {
      drillingDepth =
        section.swingFlex.areas[0].gables[0].Depth -
        section.swingFlex.productLineProperties.SwingFlexSpaceForBacking;
    }

    for (let item of section.interior.items) {
      if (!item.snapLeftAndRight) continue;

      let itemDepth = item.Depth + item.depthReduction;

      if (itemDepth + Constants.sizeAndPositionTolerance < drillingDepth) {
        if (item.isFlexDepth) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.Interior,
              Interface_Enums.ErrorLevel.Warning,
              'Item too shallow',
              'Item does not reach the back end of the cabinet.',
              section,
              item,
            ),
          );
        }
      }
    }
    return result;
  }

  /**
   * Validates the product for all door and rail items
   */
  private static validateProducts(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateProducts(
      section.interior.items,
      Enums.EditorSection.Interior,
      section,
    );
  }

  /**
   * Validates the material for all door and rail items
   */
  private static validateMaterials(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    return ItemValidationService.validateMaterials(
      section.interior.items,
      Enums.EditorSection.Interior,
      section,
    );
  }

  /**
   * Validates if items are positioned correctly against corpus
   */
  private static validateShelfAndGableSnapped(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    let gables = section.interior.items
      .filter((i) => i.isGable && (i.isFittingPanel || i.isVerticalDivider))
      .sort((i1, i2) => i1.X - i2.X);

    let shelfs = Enumerable.from(section.swingFlex.items)
      .concat(section.interior.items)
      .where((i) => i.isShelf)
      .orderBy((i) => i.Y)
      .asEnumerable();

    gables.forEach((gable) => {
      let shelvesOverlappingX = shelfs.where(
        (shelf) => shelf.leftX <= gable.leftX && shelf.rightX >= gable.rightX,
      );

      let shelfSnappedToTop = shelvesOverlappingX.firstOrDefault(
        (shelf) =>
          Math.abs(shelf.bottomY - gable.topY) <
          Constants.sizeAndPositionTolerance,
      );

      let shelfSnappedToBottom = shelvesOverlappingX.firstOrDefault(
        (shelf) =>
          Math.abs(shelf.topY - gable.bottomY) <
          Constants.sizeAndPositionTolerance,
      );

      if (!shelfSnappedToTop || !shelfSnappedToBottom) {
        // The top and/or bottom of the "gable" is not snapped to a shelf.
        if (gable.isFittingPanel) {
          let fittingPanelError = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Fitting panel not attached',
            'Fitting panel is not attach to shelf. Try to insert or move shelf',
            section,
            gable,
          );
          this.addError(fittingPanelError, result, [gable]);
        } else if (gable.isVerticalDivider) {
          let verticalDividerError = new Client.ErrorInfo(
            Enums.EditorSection.Interior,
            Interface_Enums.ErrorLevel.Critical,
            'Vertical divider not attached',
            'Vertical divider is not attach to shelf. Try to insert or move shelf',
            section,
            gable,
          );
          this.addError(verticalDividerError, result, [gable]);
        }
      } else if (
        gable.isFittingPanel &&
        !!shelfSnappedToTop &&
        !shelfSnappedToTop.VariantOptions.find(
          (vo) =>
            vo.variant?.Number === VariantNumbers.GableAdaptionToFittingPanel,
        )
      ) {
        // The top of the "gable" is snapped to a shelf, that does not support being snapped to at gable or fittingpanel.
        let fittingPanelError = new Client.ErrorInfo(
          Enums.EditorSection.Interior,
          Interface_Enums.ErrorLevel.Critical,
          'Fitting panel not attached',
          'Fitting panel is not allowed attachment to top shelf',
          section,
          gable,
        );
        this.addError(fittingPanelError, result, [gable]);
      }
    });

    return result;
  }

  private static validateItemWithGripNotBehindDoor(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    if (section.cabinet.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      let doors = section.swingFlex.items.filter(
        (i) => i.ItemType === Interface_Enums.ItemType.SwingFlexDoor,
      );
      let itemsWithGrip = section.interior.items.filter((i) => i.gripProduct);
      let itemsWithDoorInFront = itemsWithGrip.map((item) => ({
        item,
        door: doors.find((door) => VectorHelper.overlaps2d(door, item)),
      }));
      let errors = itemsWithDoorInFront
        .filter((itemAndDoor) => !!itemAndDoor.door)
        .map(
          (itemAndDoor) =>
            new Client.ErrorInfo(
              Enums.EditorSection.Interior,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable(
                'val_err_item_grip_behind_door_short',
                'Not enough room for grip on drawer',
              ),
              new Client.Translatable(
                'val_err_item_grip_behind_door_short',
                'There is not enough room for grip on drawer. Either remove the grip or the door.',
              ),
              section,
              itemAndDoor.item,
            ),
        );
      return errors;
    } else {
      return [];
    }
  }

  private static addError(
    error: Client.ErrorInfo,
    errors: Client.ErrorInfo[],
    items: Client.ConfigurationItem[],
  ) {
    errors.push(error);
    items.forEach((item) => item.errors.push(error));
  }
}
