export enum Permission {
  Login = 0,
  PasswordReset = 1,
  ReadStoreFloorPlans = 2,
  EditStoreFloorPlans = 3,
  ChangeStore = 4,
  UseFullCatalog = 5,
  ShowOnlyAdminVariants = 6,
  UseCustomPriceDate = 7,
  SaveFavouriteInGroup = 8,
  SeeAllStores = 9,
  SeeChainStores = 10,
  SeeSalesChainStores = 11,
  SetManualDeliveryStatus = 12,
  UseOrderConfirmer = 13,
  ResendScreenshots = 14,
  EditOrderComment = 15,
  EditLogisticsComment = 16,
  EditUseManualOrderHandling = 17,
  SetSupporter = 18,
  SeeOrders = 19,
  IssueSubTokens = 20,
  UseExternalApi = 21,
  ChangeUserSettings = 22,
  ChangeStoreSettings = 23,
  OverwriteFavourites = 24,
  EditSendReminderEmails = 25,
  EditFloorPlanOwner = 26,
}
