import { Injectable } from '@angular/core';
import { calculatePlan } from 'app/partition/floor-plan';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { FloorPlan } from 'app/ts/clientDto';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Measurement, MeasurementData, MeasurementId } from './Measurement';
import {
  calculateMeasurementLength,
  Line,
} from './measurement-length-calculations';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  private _measurements: Measurement[] = [];

  private _measurementList$: BehaviorSubject<Readonly<Measurement[]>> =
    new BehaviorSubject<Readonly<Measurement[]>>([]);

  private _selectedMeasurementId?: MeasurementId;

  private nextId(): MeasurementId {
    return this._nextMeasurementId++ as MeasurementId;
  }
  private _nextMeasurementId: MeasurementId = 1 as MeasurementId;

  constructor(private partitionQueryService: PartitionPlanQueryService) {}

  //#region Save/Load
  public newPlan() {
    this._measurements = [];
  }

  public hydrateData(JsonData?: string) {
    this.deselect();

    if (JsonData == null || JsonData == '') {
      this._measurements = [];
      return;
    }

    const data = JSON.parse(JsonData) as MeasurementData[];
    this._measurements = data.map((dt) => new Measurement(dt));

    if (this._measurements.length > 0)
      this._nextMeasurementId = (Math.max(
        ...this._measurements.map((m) => m.id as number),
      ) + 1) as MeasurementId;

    this.triggerNextList();
  }

  public getDataForSave(): Readonly<MeasurementData[]> {
    return this.measurements.map((mm) => mm.data);
  }
  //#endregion

  public get measurements(): Readonly<Measurement[]> {
    return this._measurements;
  }

  public get measurementList$(): Observable<Readonly<Measurement[]>> {
    return this._measurementList$;
  }

  public get selectedMeasurement(): Readonly<Measurement> | undefined {
    return this.measurements.find((ms) => ms.id == this._selectedMeasurementId);
  }

  public selectMeasurement(id: MeasurementId) {
    this._selectedMeasurementId = id;

    this.triggerNextList();
  }

  public deselect() {
    this._selectedMeasurementId = undefined;

    this.triggerNextList();
  }

  public triggerNextList() {
    this._measurementList$.next(this.measurements);
  }

  public addMeasurement(posX: number, posY: number): MeasurementId {
    const data = {
      id: this.nextId(),
      posX,
      posY,
      line: {
        start: { X: 0, Y: 0 },
        end: { X: 0, Y: 0 },
      },
      horizontal: true,
    };
    this._measurements.push(new Measurement(data));

    this.triggerNextList();

    return data.id;
  }

  public deleteSelected() {
    if (!this._selectedMeasurementId) return;

    this._measurements = this._measurements.filter(
      (ms) => ms.id != this._selectedMeasurementId,
    );
    this.deselect();
    this.triggerNextList();
  }

  public updateMeasurementPosition(
    id: MeasurementId,
    posX: number,
    posY: number,
  ) {
    const measurement = this.measurements.find((ms) => ms.id == id)!;

    measurement.data.posX = posX;
    measurement.data.posY = posY;

    this.triggerNextList();
  }

  public updateMeasurementOrientation(id: MeasurementId, horizontal: boolean) {
    const measurement = this.measurements.find((ms) => ms.id == id)!;

    measurement.data.horizontal = horizontal;

    this.triggerNextList();
  }

  public updateMeasurementLinePoints(id: MeasurementId, line: Line) {
    const measurement = this.measurements.find((ms) => ms.id == id);
    if (measurement) measurement.data.line = line;

    this.triggerNextList();
  }

  public recalculateMeasurements(floorplan: FloorPlan) {
    let plan = calculatePlan(this.partitionQueryService.plan, floorplan);

    this.measurements.forEach((measurement) => {
      let newLength = calculateMeasurementLength(
        measurement,
        floorplan,
        plan.sections,
        plan.profiles,
      );
      this.updateMeasurementLinePoints(measurement.id, newLength);
    });
  }
}
