<div class="overview-content">
  <customer-topbar [parent]="this"></customer-topbar>

  <div class="overview-minus-topbar">
    <dark-side-bar></dark-side-bar>

    <div class="overview-minus-dark-side-bar">
      <customer-nav
        [selection]="'customers'"
        [customerCounts]="customers$ | async | updateCounts "
      ></customer-nav>

      <div class="overview-minus-customer-nav">
        <button
          class="btn btn-light"
          (click)="openNewCustomer()"
          accesskey="n"
          [attr.title]="translate('customers_button_new_customer', 'New customer')"
        >
          <span class="bi bi-plus"></span>
          <span class="bi bi-person-fill"></span>
          <span
            [textContent]="translate('customers_button_new_customer', 'New customer')"
          ></span>
        </button>
        <button
          class="btn btn-light"
          (click)="selectColumns()"
          [attr.title]="translate('customers_button_select_columns', 'Columns')"
        >
          <span class="bi bi-funnel-fill"></span>
        </button>

        <customers-table
          [objects]="objects"
          (onItemSelected)="setSelected($event)"
          (onForward)="showChildren($event)"
          [highlightId]="highlightId"
        ></customers-table>
      </div>
    </div>
  </div>
  <corner-name-bubble></corner-name-bubble>
</div>

<customer-edit-menu [customer]="editingCustomer" (onClose)="closeRightMenu()">
</customer-edit-menu>
