import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'edit-manual-item-vm',
  templateUrl: './EditManualItem.html',
  styleUrls: ['../../../../style/modal.scss'],
})
export class EditManualItemVm extends BaseVm implements OnInit {
  public configurationItem!: Client.ConfigurationItem;

  private originalItem!: Client.ConfigurationItem;
  public item!: Client.ConfigurationItem;
  public quantity = 1;

  constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
    private readonly floorPlanService: FloorPlanService,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.originalItem = this.configurationItem;
    let dtoItem = ObjectHelper.copy(this.configurationItem.save());
    this.item = new Client.ConfigurationItem(
      dtoItem,
      this.configurationItem.cabinetSection,
    );
    this.quantity = this.configurationItem.Quantity;
  }

  public async delete(): Promise<void> {
    this.removeOriginalItem();
    await this.floorPlanService.setChanged(
      this.originalItem.cabinetSection.cabinet.floorPlan,
    );
    this.activeModal.close(void 0);
  }

  public async ok(): Promise<void> {
    this.item.Quantity = this.quantity;
    this.removeOriginalItem();
    let floorPlan = this.originalItem.cabinetSection.cabinet.floorPlan;
    this.floorPlanService.addManualItem(floorPlan, this.item);
    await this.floorPlanService.setChanged(floorPlan);
    this.activeModal.close(void 0);
  }

  public cancel(): void {
    this.activeModal.dismiss(Constants.userCancelled);
  }

  private removeOriginalItem() {
    let manualItems = this.originalItem.cabinetSection.ManuallyAddedItems;
    for (let i = 0; i < manualItems.length; i++) {
      let item = manualItems[i];
      if (
        item.ConfigurationItemIndex === this.originalItem.ConfigurationItemIndex
      ) {
        manualItems.splice(i, 1);
        break;
      }
    }
  }

  public get okPossible(): boolean {
    if (this.item === null || this.item.Product === null) return false;

    return this.item.isValid;
  }
}
