import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto';
import { SubSection } from 'app/ts/clientDto/SubSection';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ConfigurationItem } from 'app/ts/clientDto/ConfigurationItem';
import { Corner } from 'app/ts/clientDto/Corner';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
export class Interior implements SubSection {
  public mustAdaptToWidth: boolean = false;
  public hasEmptyGapLeft: boolean = true;
  public hasEmptyGapRight: boolean = true;
  public template: Interface_DTO.Template | undefined = undefined;

  private _previousCubeX: number | undefined = undefined;
  public get previousCubeX(): number {
    if (this._previousCubeX === undefined) {
      this._previousCubeX = this.cube.X;
    }

    return this._previousCubeX;
  }
  public set previousCubeX(val: number) {
    this._previousCubeX = val;
  }

  private _previousCubeWidth: number | undefined = undefined;
  public get previousCubeWidth(): number {
    if (this._previousCubeWidth === undefined) {
      this._previousCubeWidth = this.cube.Width;
    }

    return this._previousCubeWidth;
  }
  public set previousCubeWidth(val: number) {
    this._previousCubeWidth = val;
  }

  //#region Actual values, backing variables

  //#endregion Actual values, backing variables

  //#region Availables, backing variables
  private cache: Partial<{
    material: Client.Material | null;
    pickableMaterials: Pickable<Client.Material>[];
    availableProductCategories: Client.ProductCategory[];
    availableGableSnapPositions: number[];
    boundingBoxes: Interface_DTO_Draw.Rectangle[];
    cube: Interface_DTO_Draw.Cube;
    itemCubes: Client.ItemCube[];
    isHeightReductionAllowed: boolean;
    availableInteriorProductCategories: Client.InteriorProductCategory[];
    noGripPickable: Pickable<null>;
    availableDrawerGrips?: Client.Product[];
  }> = {};

  //#endregion Availables, backing variables

  public items: Client.ConfigurationItem[] = [];

  public get isHeightReductionAllowed(): boolean {
    if (this.cache.isHeightReductionAllowed === undefined) {
      if (this.editorAssets.fullCatalog)
        this.cache.isHeightReductionAllowed = true;
      else if (this.cabinetSection.cabinet.productLine.DisableFittings)
        this.cache.isHeightReductionAllowed = false;
      else {
        const productLineId = this.cabinetSection.cabinet.productLine.Id;
        let anyProductsWithHeightReduction =
          this.availableProductCategories.some((category) => {
            return category.anyProduct((product, matchedCategory) => {
              if (
                matchedCategory.Number ===
                  Constants.ModulePartsCategoryNumber ||
                matchedCategory.ParentCategoryId === -1
              )
                return false;
              let heightReductionPossible =
                ProductHelper.isHeightReductionPossible(
                  product,
                  productLineId,
                  this.cabinetSection.cabinet.floorPlan
                    .FullCatalogAllowHeightReduction,
                );
              let success = heightReductionPossible && !product.IsTemplate;
              return success;
            });
          });
        this.cache.isHeightReductionAllowed = anyProductsWithHeightReduction;
      }
    }
    return this.cache.isHeightReductionAllowed;
  }

  private _heightReduction: boolean = false;
  public get heightReduction(): boolean {
    return this.isHeightReductionAllowed && this._heightReduction;
  }
  public set heightReduction(val: boolean) {
    if (!this.isHeightReductionAllowed) {
      this._heightReduction = false;
    } else {
      this._heightReduction = val;
    }
  }

  constructor(private readonly cabinetSection: Client.CabinetSection) {}

  public clearBackingVariables() {
    this.cache = {};
    for (let item of this.items) {
      item.clearBackingVariables();
    }
  }

  //#region Actual values, public properties

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  get material(): Client.Material | null {
    if (this.cabinetSection.InteriorMaterialId === null) return null;
    if (this.cache.material === undefined)
      this.cache.material =
        this.editorAssets.materialsDict[this.cabinetSection.InteriorMaterialId];

    return this.cache.material;
  }
  setMaterial(val: Client.Material | null, applyToItems: boolean) {
    if (ObjectHelper.equals(val, this.material)) {
      return;
    }

    this.cabinetSection.cabinet.setInteriorMaterial(
      this.cabinetSection,
      val,
      applyToItems,
    );
    this.cabinetSection.isDirty = true;
  }

  //#endregion Actual values, public properties

  public loadFrom(dtoSection: Interface_DTO.CabinetSection): void {
    this.heightReduction = dtoSection.HeightReductionInterior;
    this.clearBackingVariables();
  }

  private restoreInteriorItem(
    dtoItem: Interface_DTO.ConfigurationItem,
    existingItems: Client.ConfigurationItem[],
  ): Client.ConfigurationItem {
    let oldItem: Client.ConfigurationItem | null = null;
    for (let item of this.items) {
      if (item.ConfigurationItemIndex === dtoItem.ConfigurationItemIndex) {
        oldItem = new Client.ConfigurationItem(
          item as any,
          this.cabinetSection,
        );
        break;
      }
    }
    if (!oldItem) {
      oldItem = new Client.ConfigurationItem(dtoItem, this.cabinetSection);
    }
    oldItem.restoreFrom(dtoItem);
    return oldItem;
  }

  public saveTo(dtoSection: Interface_DTO.CabinetSection): void {
    dtoSection.HeightReductionInterior = this.heightReduction;
    dtoSection.InteriorItems = this.items.map((i) => i.save());
  }

  //#region Availables, public getters

  public get availableDrawerGrips(): Client.Product[] {
    if (this.cache.availableDrawerGrips === undefined) {
      var fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.availableDrawerGrips = Enumerable.from(
        this.editorAssets.products,
      )
        .where(
          (p) =>
            p.ProductType === Interface_Enums.ProductType.Grip &&
            p.ProductLineIds.some(
              (plId) => plId === this.cabinetSection.cabinet.ProductLineId,
            ) &&
            (fullCatalog || !p.overrideChain) &&
            ProductHelper.isGripForDrawer(p),
        )
        .toArray();
    }

    return this.cache.availableDrawerGrips;
  }

  public get noGripPickable(): Pickable<Client.Product | null> {
    if (this.cache.noGripPickable === undefined) {
      this.cache.noGripPickable = {
        name: this.editorAssets.translationService.translate(
          'pickable_grip_name_none',
          'No Grip',
        ),
        disabledReason: null,
        groupName: this.editorAssets.translationService.translate(
          'pickable_grip_group_none',
          'No Grip',
        ),
        isSelected: false,
        item: null,
        override: false,
      };
    }
    return this.cache.noGripPickable;
  }

  get pickableMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableMaterials === undefined) {
      let productLineId = this.cabinetSection.cabinet.ProductLineId;
      let products = Enumerable.from(this.availableProductCategories)
        .selectMany((pc) => pc.Children)
        .selectMany((spc) => spc.products);
      let fullCatalogProducts =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      let fullCatalogMaterials =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;
      if (!fullCatalogProducts) {
        products = products.where((p) => !p.overrideChain);
      }

      let availableProducts = products.where(
        (product) => product.Enabled && ProductHelper.isGable(product),
      );

      if (!availableProducts.any()) {
        availableProducts = products.where(
          (product) =>
            product.Enabled && ProductHelper.isShelf(product, productLineId),
        );
      }

      let availableMaterials = availableProducts
        .selectMany((prod) => prod.PossibleMaterialIds)
        .where((matId) => matId.IsEnabled)
        .groupBy((matId) => matId.Id)
        .select((matGrp) =>
          MaterialHelper.getPickable(
            this.editorAssets.materialsDict[matGrp.key()],
            undefined,
            matGrp.all((matId) => matId.IsOverride),
          ),
        );

      if (!fullCatalogMaterials) {
        availableMaterials = availableMaterials.where(
          (pickable) => !pickable.override,
        );
      }

      this.cache.pickableMaterials = availableMaterials.toArray();

      for (let pickable of this.cache.pickableMaterials) {
        if (this.material && pickable.item.Id === this.material.Id) {
          pickable.isSelected = true;
        }
      }

      MaterialHelper.sortPickableMaterials(this.cache.pickableMaterials);
    }

    return this.cache.pickableMaterials;
  }

  get availableProductCategories(): Client.ProductCategory[] {
    if (!this.cache.availableProductCategories) {
      let productLineId = this.cabinetSection.cabinet.ProductLineId;
      let proCats = productLineId
        ? this.editorAssets.productCategoriesByProductLineAndCabinetType[
            this.cabinetSection.cabinet.ProductLineId
          ][this.cabinetSection.CabinetType]
        : this.editorAssets.productCategories;
      if (
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts
      ) {
        proCats = this.editorAssets.productCategories;
      }
      this.cache.availableProductCategories = proCats.filter(
        (pc) => pc.hasChildProducts,
      );
    }
    return this.cache.availableProductCategories;
  }

  get availableInteriorProductCategories(): Client.InteriorProductCategory[] {
    if (!this.cache.availableInteriorProductCategories) {
      this.cache.availableInteriorProductCategories =
        this.editorAssets.interiorProductCategories;
    }
    return this.cache.availableInteriorProductCategories;
  }

  /**
   * four rectangles around the interior - used for collision detection
   ***/
  get boundingBoxes(): Interface_DTO_Draw.Rectangle[] {
    if (!this.cache.boundingBoxes) {
      this.cache.boundingBoxes = [
        {
          //top
          X: this.cube.X,
          Y: this.cube.Y + this.cube.Height,
          Width: this.cube.Width,
          Height: 0,
        },
        {
          //bottom
          X: this.cube.X,
          Y: this.cube.Y,
          Width: this.cube.Width,
          Height: 0,
        },
        {
          //left
          X: this.cube.X,
          Y: this.cube.Y,
          Width: 0,
          Height: this.cube.Height,
        },
        {
          //right
          X: this.cube.X + this.cube.Width,
          Y: this.cube.Y,
          Width: 0,
          Height: this.cube.Height,
        },
      ];
    }
    return this.cache.boundingBoxes;
  }

  get cube(): Interface_DTO_Draw.Cube {
    if (!this.cache.cube) {
      this.cache.cube = {
        X: CabinetSectionHelper.interiorOffset(
          this.cabinetSection,
          Interface_Enums.Direction.Left,
        ),
        Y: CabinetSectionHelper.interiorOffset(
          this.cabinetSection,
          Interface_Enums.Direction.Bottom,
        ),
        Z: CabinetSectionHelper.backingOffsetZforInterior(this.cabinetSection),
        Width: CabinetSectionHelper.availableInteriorWidth(this.cabinetSection),
        Height: CabinetSectionHelper.availableInteriorHeight(
          this.cabinetSection,
        ),
        Depth: CabinetSectionHelper.availableInteriorDepth(this.cabinetSection),
      };

      //console.info(this.cache.cube.X + ", " + this.cache.cube.Y + ", " + this.cache.cube.Z);
      //console.info(this.cache.cube.Height + ", " + this.cache.cube.Width + ", " + this.cache.cube.Depth);
    }

    return this.cache.cube;
  }

  get leftCorner(): Corner | null {
    let neighbor = this.cabinetSection.leftNeighbor;
    if (!neighbor) {
      return null;
    } else {
      let lastNeighborGable = ObjectHelper.best(
        neighbor.interior.gables,
        (gable) => -(gable.X + gable.Width),
      );
      let minCornerDepth = lastNeighborGable
        ? lastNeighborGable.Z + lastNeighborGable.Depth
        : neighbor.InteriorDepth;

      let thisFirstGable =
        this.gables.length > 0
          ? ObjectHelper.best(this.gables, (gab) => gab.X)
          : undefined;
      let thisZ = thisFirstGable
        ? thisFirstGable.Z + thisFirstGable.Depth
        : this.cabinetSection.InteriorDepth;

      let acceptableCornerWidth: number;
      if (lastNeighborGable) {
        acceptableCornerWidth =
          lastNeighborGable.Z +
          lastNeighborGable.Depth +
          thisZ -
          (neighbor.Width - lastNeighborGable.rightX) +
          Constants.minimumCornerAreaOpening;
      } else {
        acceptableCornerWidth = Constants.defaultCornerWidth;
      }
      return {
        acceptableSpace: acceptableCornerWidth,
        minimumSpace: minCornerDepth,
      };
    }
  }

  get rightCorner(): Corner | null {
    let neighbor = this.cabinetSection.rightNeighbor;
    if (!neighbor) {
      return null;
    } else {
      let firstNeighborGable =
        neighbor.interior.gables.length > 0
          ? ObjectHelper.best(neighbor.interior.gables, (gable) => gable.X)
          : undefined;
      let minCornerWidth = firstNeighborGable
        ? firstNeighborGable.Z + firstNeighborGable.Depth
        : neighbor.InteriorDepth;

      let thisLastGable =
        this.gables.length > 0
          ? ObjectHelper.best(this.gables, (gable) => -(gable.X + gable.Width))
          : undefined;
      let thisZ = thisLastGable
        ? thisLastGable.Z + thisLastGable.Depth
        : this.cabinetSection.InteriorDepth;

      let acceptableCornerWidth: number;
      if (firstNeighborGable) {
        acceptableCornerWidth =
          firstNeighborGable.Z +
          firstNeighborGable.Depth +
          thisZ -
          firstNeighborGable.X +
          Constants.minimumCornerAreaOpening;
      } else {
        acceptableCornerWidth = Constants.defaultCornerWidth;
      }
      return {
        acceptableSpace: acceptableCornerWidth,
        minimumSpace: minCornerWidth,
      };
    }
  }

  /**
   * Cubes representing the interior items in this cabinetSection, including the items in other sections of the cabinet that are fully or partially inside this section
   * */
  get itemCubes(): Client.ItemCube[] {
    if (!this.cache.itemCubes) {
      this.cache.itemCubes = this.items.map((i) => {
        return {
          item: i,
          X: i.X,
          Y: i.Y,
          Z: i.Z,
          Width: i.Width,
          Height: i.Height,
          Depth: i.Depth,
        };
      });
      if (this.cabinetSection.leftNeighbor) {
        let leftNeighbor = this.cabinetSection.leftNeighbor;
        this.cache.itemCubes.push(
          ...leftNeighbor.interior.items
            .filter(
              (i) =>
                i.X + i.Width >=
                leftNeighbor.Width - this.cabinetSection.InteriorWidth,
            )
            .map((i) => {
              return {
                item: i,
                X: i.Z,
                Y: i.Y,
                Z: leftNeighbor.Width - i.rightX,
                Width: i.Depth,
                Height: i.Height,
                Depth: i.Width,
              };
            }),
        );
      }
      if (this.cabinetSection.rightNeighbor) {
        let rightNeighbor = this.cabinetSection.rightNeighbor;
        this.cache.itemCubes.push(
          ...rightNeighbor.interior.items
            .filter((i) => i.X <= this.cabinetSection.InteriorDepth)
            .map((i) => {
              return {
                item: i,
                X: this.cabinetSection.Width - i.Z,
                Y: i.Y,
                Z: i.X,
                Width: i.Depth,
                Height: i.Height,
                Depth: i.Width,
              };
            }),
        );
      }
    }
    return this.cache.itemCubes;
  }

  //#endregion Availables, public getters

  public get gables(): ConfigurationItem[] {
    return this.items.filter((item) => item.isGable).sort((a, b) => a.X - b.X);
  }

  public get minItemX(): number {
    return Math.min(
      ...this.items.filter((item) => !item.isDummy).map((i) => i.X),
    );
  }

  public get maxItemRightX(): number {
    return Math.max(
      ...this.items.filter((item) => !item.isDummy).map((i) => i.rightX),
      0,
    );
  }

  public get cubeRightX(): number {
    return this.cube.X + this.cube.Width;
  }

  public get isEmpty(): boolean {
    return this.items.length === 0;
  }

  public isItemSnappedToGable(item: ConfigurationItem): boolean {
    if (item.isGable) {
      return false;
    }

    if (
      this.gables.some(
        (g) => Math.abs(g.rightX - item.X) < Constants.sizeAndPositionTolerance,
      )
    ) {
      return true;
    }

    if (
      this.gables.some(
        (g) => Math.abs(g.X - item.rightX) < Constants.sizeAndPositionTolerance,
      )
    ) {
      return true;
    }

    return false;
  }
}
