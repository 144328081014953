<div class="consumer-page consumer-interior">
  <div class="consumer-nav">
    <navbar current-id="'interior'"></navbar>
  </div>

  <div class="consumer-left">
    <item-picker
      [items]="materials"
      [(selectedItem)]="selectedMaterial"
      [name]="translate('consumer_interior_material', 'Interior Material')"
      *ngIf="showMaterialPicker"
    >
    </item-picker>

    <item-picker
      [items]="categories"
      [(selectedItem)]="selectedCategory"
      [name]="translate('consumer_interior_category', 'Category')"
    >
    </item-picker>

    <interior-product-picker
      [category]="selectedCategory"
      cabinetSection="cabinetSection"
      [currentDragInfoObservable]="currentDragInfoObservable"
    >
    </interior-product-picker>
  </div>

  <div class="consumer-main">
    <d3-display
      [cabinetSection]="cabinetSection"
      [showDoors]="'toggle'"
      [showInterior]="true"
      [showCorpus]="true"
      [showRulers]="'toggle'"
      [showPullout]="'toggle'"
      [showUndoRedo]="true"
      [selectedItems]="selectedItems$ | async"
      [currentDragInfoObservable]="currentDragInfoObservable"
      [allowMoveInterior]="true"
      (itemsSelected)="itemsSelected($event)"
    >
    </d3-display>

    <div class="item-popup" *ngIf="selectedItem">
      <consumer-item-editor
        [items]="selectedItems$ | async"
        (itemChanged)="selectedItemChanged()"
        (itemDeleted)="itemDeleted($event)"
      >
      </consumer-item-editor>
    </div>

    <div class="error-popups" *ngIf="maxErrorLevel" (click)="showErrors()">
      <div class="error-popup--popup" [ngClass]="getErrorPopupClass()">
        <div>
          <span class="bi bi-exclamation-triangle-fill"></span>
        </div>
        <div class="error-popup--text">
          <span
            [textContent]="translate(
                            'consumer_interior_warning_blue',
                            'There are things you need to be aware of regarding your solution.')"
            *ngIf="maxErrorLevel === 5"
            class="translation"
          ></span>
          <span
            [textContent]="translate(
                            'consumer_interior_warning_yellow',
                            'There are things you need to be aware of regarding your solution.')"
            *ngIf="maxErrorLevel === 10"
            class="translation"
          ></span>
          <span
            [textContent]="translate(
                            'consumer_interior_warning_red',
                            'There are things you need to be aware of regarding your solution.')"
            *ngIf="maxErrorLevel === 20"
            class="translation"
          ></span>
          <br />
          <span
            [textContent]="translate('consumer_interior_warning_click', 'Click here.')"
            class="translation error-popup--click"
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="consumer-bottom">
    <div class="btn-group prev-next-btns">
      <button class="btn btn-secondary btn-prev" (click)="previous()">
        {{translate('consumer_btn_prev', 'Back')}}
      </button>

      <button (click)="next()" class="btn btn-primary btn-next">
        {{translate('consumer_btn_next', 'Next')}}
      </button>
    </div>

    <consumer-price [floorPlan]="floorPlan"></consumer-price>
  </div>

  <consumer-logo></consumer-logo>
</div>
