import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Client from 'app/ts/clientDto/index';
import { Constants } from 'app/ts/Constants';
import { Pickable } from 'app/ts/interfaces/Pickable';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'select-backing-vm',
  templateUrl: './SelectBacking.html',
  styleUrls: [
    '../../../../style/modal.scss',
    '../../../../style/selectBacking.scss',
  ],
})
export class SelectBackingVm extends BaseVm implements OnInit {
  private _backingType!: Interface_Enums.BackingType;
  public adjustSectionDepth!: boolean;

  public backings: SelectBackingVm.Backing[] = [];

  public section!: Client.CabinetSection;

  public constructor(
    baseVmService: BaseVmService,
    private readonly activeModal: NgbActiveModal,
  ) {
    super(baseVmService);
  }

  public ngOnInit() {
    this.adjustSectionDepth = true;
    this.updateBackings();
    this._backingType = this.section.backing.backingType;
  }

  public get backingType() {
    return this._backingType;
  }
  public set backingType(val: Interface_Enums.BackingType) {
    this._backingType = val;
    this.updateBackings();
  }

  private updateBackings() {
    this.backings = this.section.backing.pickableTypes.map((op) => ({
      pickable: { ...op, isSelected: op.item === this.backingType },
      description: this.translate(
        'backing_modal_description_' + op.item,
        'Description for backing type ' + op.item,
      ),
      backingType: op.item,
    }));
  }

  public ok() {
    this.activeModal.close({
      backingType: this.backingType,
      adjustSectionDepth: this.adjustSectionDepth,
    });
  }

  public cancel() {
    this.activeModal.dismiss(Constants.userCancelled);
  }
}
export module SelectBackingVm {
  export interface Backing {
    pickable: Pickable<Interface_Enums.BackingType>;
    description: string;
    backingType: Interface_Enums.BackingType;
  }
}
